/* eslint-disable no-unused-vars */
import {createSlice} from '@reduxjs/toolkit';
import {configState} from "./initialState";

const initialState = {...configState}
export const configSlice = createSlice({
  name: 'appConf', initialState, // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    doResetConfigSlice: (state) => {
      Object.keys(initialState).map(key => state[key] = initialState[key])
    }, updatePatientFilters: (state, action) => {
      state.patientFilters = [...action.payload]
    }, updateArchivedPatientFilters: (state, action) => {
      state.archivedPatientFilters = [...action.payload]
    }, updatefArchivedPatientFilters: (state, action) => {
      state.farchivedPatientFilters = [...action.payload]
    }, updateProspectFilters: (state, action) => {
      state.prospectFilters = [...action.payload]
    }, updatefPatientFilters: (state, action) => {
      state.fpatientFilters = [...action.payload]
    }, updatefProspectFilters: (state, action) => {
      state.fprospectFilters = [...action.payload]
    }, updateAllergyFilters: (state, action) => {
      state.allergyFilters = [...action.payload]
    }, updateImmunizationFilters: (state, action) => {
      state.immunizationFilters = [...action.payload]
    }, updatePatientDocFilters: (state, action) => {
      state.patientDocFilters = [...action.payload]
    },updatePatientConsentFilters: (state, action) => {
      state.patientConsentFilters = [...action.payload]
    }, updateAppointmentFilters: (state, action) => {
      state.appointmentFilters = [...action.payload]
    }, updateOrganisationFilters: (state, action) => {
      state.organisationFilters = [...action.payload]
    }, updateEncounterListFilters: (state, action) => {
      state.encounterListFilters = [...action.payload]
    }, updateDraftListFilters: (state, action) => {
      state.draftListFilters = [...action.payload]
    }, updateFacilitiesFilters: (state, action) => {
      state.facilitiesFilters = [...action.payload]
    }, updateBiologicProductsFilters: (state, action) => {
      state.biologicProductsFilters = [...action.payload]
    }, updateOrgFacilitiesFilters: (state, action) => {
      state.orgFacilitiesFilters = [...action.payload]
    }, updateOrgUsersFilters: (state, action) => {
      state.orgUsersFilters = [...action.payload]
    }, updateOrgPermFilters: (state, action) => {
      state.orgPermFilters = [...action.payload]
    }, updateConsultantsFilters: (state, action) => {
      state.consultantsFilters = [...action.payload]
    }, updateConsultantMileagesFilters: (state, action) => {
      state.consultantMileagesFilters = [...action.payload]
    }, updateMedicationFilters: (state, action) => {
      state.medicationFilters = [...action.payload]
    },
    updatePrescriptionsFilters: (state, action) => {
      state.prescriptionsFilters = [...action.payload]
    }, updateAuditResourcesFilters: (state, action) => {
      state.auditResourcesFilters = [...action.payload]
    },
    
    updateNotesFilters: (state, action) => {
      state.notesFilters = [...action.payload]
    }, updatePatientAppointmentsFilters: (state, action) => {
      state.patientAppointmentsFilters = [...action.payload]
    },
    updatePatientOrdersFilters: (state, action) => {
      state.patientOrdersFilters = [...action.payload]
    },
    updatePatientBiologicsOrdersFilters: (state, action) => {
      state.patientBiologicsOrdersFilters = [...action.payload]
    },
    updatePatientDmeOrdersFilters: (state, action) => {
      state.patientDmeOrdersFilters = [...action.payload]
    }
    , updatePatientWoundsFilters: (state, action) => {
      state.patientWoundsFilters = [...action.payload]
    }, updatePatientDiagnosisFilters: (state, action) => {
      state.patientDiagnosisFilters = [...action.payload]
    }, updateDuplicateWoundsFilters: (state, action) => {
      state.duplicateWoundsFilters = [...action.payload]
    }, updateTaskFilters: (state, action) => {
      state.taskFilters = [...action.payload]
    }, updatePatientUpdateFilters: (state, action) => {
      state.patientUpdateFilters = [...action.payload]
    }, updatePatientOpenWoundsFilters: (state, action) => {
      state.patientOpenWoundsFilters = [...action.payload]
    }, updatePatientEncounterListFilters: (state, action) => {
      state.patientEncounterListFilters = [...action.payload]
    }, updatePatientAssessmentFilters: (state, action) => {
      state.patientAssessmentFilters = [...action.payload]
    }, updatePlanOfCareFilters: (state, action) => {
      state.planOfCareFilters = [...action.payload]
    }, updateScheduleFilters: (state, action) => {
      state.scheduleFilters = [...action.payload]
    },
    updateConsultantOrdersFilters: (state, action) => {
      state.consultantOrdersFilters = [...action.payload]
    },
    updatePreOrderFilters: (state, action) => {
      state.preOrderFilters = [...action.payload]
    },
    updateDmeOrderFilters: (state, action) => {
      state.dmeOrderFilters = [...action.payload]
    },
    updateBiologicOrderFilters: (state, action) => {
      state.biologicOrderFilters = [...action.payload]
    },
    updateFinalOrderFilters: (state, action) => {
      state.finalOrderFilters = [...action.payload]
    },
    updateInternalOrderFilters: (state, action) => {
      state.internalOrderFilters = [...action.payload]
    },
    updateOrderHistoryFilters: (state, action) => {
      state.orderHistoryFilters = [...action.payload]
    },
    updateInsuranceProvidersFilters: (state, action) => {
      state.insuranceProvidersFilters = [...action.payload]
    },
    updateBillingEntitiesFilters: (state, action) => {
      state.billingEntitiesFilters = [...action.payload]
    },
    updateCPTCodesFilters: (state, action) => {
      state.CPTCodesFilters = [...action.payload]
    },
    updatePpeKitsFilters: (state, action) => {
      state.ppeKitsFilters = [...action.payload]
    },
    updateWpRegionsFilters: (state, action) => {
      state.wpRegionsFilters = [...action.payload]
    },
    updateWpSubRegionsFilters: (state, action) => {
      state.wpSubRegionsFilters = [...action.payload]
    },
    updateWpStatesFilters: (state, action) => {
      state.wpStatesFilters = [...action.payload]
    },
    updateWpCitiesFilters: (state, action) => {
      state.wpCitiesFilters = [...action.payload]
    },
     updateDepartmentFilters: (state, action) => {
      state.departmentFilters = [...action.payload]
   },
   updateDepartmentUserFilters : (state, action) => {
    state.departmentUserFilters = [...action.payload]
    },
  //  billing filters
    updateBillingFilters: (state, action) => {
      state.billingFilters = [...action.payload]
    },
    // pending billing filters
    updatePendingBillingFilters: (state, action) => {

      state.pendingBillingFilters = [...action.payload]
    }
    ,
    // readybilling filters
    updateReadyBillingFilters: (state, action) => {
      state.readyBillingFilters = [...action.payload]
    },
    // submitted billing filters
    updateSubmittedBillingFilters: (state, action) => {
      state.submittedBillingFilters = [...action.payload]
    },
    // admission filters
     updateAdmissionFilters: (state, action) => {
       state.admissionFilters = [...action.payload]
    },
    // pending schedule filters
    updatePendingScheduleFilters: (state, action) => {
      state.pendingScheduleFilters = [...action.payload]
    },
    setMQTTSetupState: (state, action) => {
      state.MQTTSetupState = action.payload
    },
    // consconsultantsAssigment filters
     updateConsultantsAssigmentFilters: (state, action) => {
       state.consultantsAssigmentFilters = [...action.payload]
    },
  
    
    
  }
});
export const {updatePpeKitsFilters,updatePatientConsentFilters,setMQTTSetupState,
  updatePatientFilters, updateWpRegionsFilters, updateWpSubRegionsFilters, updateWpStatesFilters, updateWpCitiesFilters,updateFinalOrderFilters,
  updatefPatientFilters,
  updatefProspectFilters,
  updateProspectFilters,
  updateAllergyFilters,
  updateImmunizationFilters, updatePatientDocFilters,
  updateArchivedPatientFilters,
  updateAppointmentFilters,
  updateEncounterListFilters,
  updateFacilitiesFilters,
  updateConsultantsFilters,
  updatePatientOrdersFilters,
  updatePatientBiologicsOrdersFilters,
  updatePatientDmeOrdersFilters,
  updatefArchivedPatientFilters,
  updateMedicationFilters,
  updateNotesFilters,
  updatePatientAppointmentsFilters,
  updatePatientWoundsFilters,
  updatePatientDiagnosisFilters,
  updateDuplicateWoundsFilters,
  updateDraftListFilters,
  updatePreOrderFilters,
  updateConsultantMileagesFilters,
  updatePatientEncounterListFilters,
  updatePatientAssessmentFilters,
  doResetConfigSlice,
  updateScheduleFilters,
  updateConsultantOrdersFilters,
  updatePrescriptionsFilters,
  updatePlanOfCareFilters,
  updateAuditResourcesFilters,
  updateDmeOrderFilters,
  updateBiologicOrderFilters,
  updateInternalOrderFilters,
  updateOrderHistoryFilters,
  updateInsuranceProvidersFilters,updateBillingEntitiesFilters,updateCPTCodesFilters,
  updatePatientOpenWoundsFilters,
  updatePendingScheduleFilters,
  updateOrganisationFilters,updateOrgFacilitiesFilters,updateOrgUsersFilters,updateOrgPermFilters,
  updateDepartmentFilters, updateDepartmentUserFilters, updateTaskFilters, updatePatientUpdateFilters,
  updateBillingFilters, updatePendingBillingFilters, updateReadyBillingFilters, updateSubmittedBillingFilters,
  updateAdmissionFilters,
  updateConsultantsAssigmentFilters,updateBiologicProductsFilters
} = configSlice.actions;
export default configSlice.reducer;
