import React, {useEffect, useState} from 'react'
import {Button, Divider, Form, Menu, Modal, Table} from 'antd'
import {connect, useDispatch} from "react-redux"
import {Headline6, Subtitle1} from "../../../components"
import {createMedicalDirector, createSignatoryState, deleteMedicalDirector, editMedicalDirector, editWCCSignature, fetchMedicalDirectors} from '../../../app/Reducers'
import {
  appLog,
  appMessage,
  containedButton,
  createFormInputsFromTableColumns,
  dialogCloser,
  emptyFun,
  executeChunkFn,
  horStack,
  kebabMenus,
  makeSelectOptions,
  modalClose,
  outlinedButton,
  textButton
} from '../../../common/helpers'
import {StateArray} from '../MasterList'
import KebabMenu from '../../../components/KebabMenu'
import {errMsg, toastUp} from "../../../app/Reducers/reducerUtils";
import DynamicModalForm from "../../../components/DynamicModalForm";
import * as Sentry from "@sentry/react";
import {apiRequest} from "../../../app/Apis";
import SignatureUpload from "./SignatureUpload";
import {LeftOutlined, PlusOutlined} from "@ant-design/icons";

let billingRegionInputs = {
  fields: [
    {name: 'state', label: 'State', required: true, type: 'select', options: makeSelectOptions(StateArray), fullRow: true}
  ]
};

let nameRule = [
  {
    validator: (rule, value, callback) => {
      if (value) {
        if (value.length !== 1) {
          callback('Only 1 MD is allowed');
        } else if (value.length <= 5) {
          callback();
        }
      }
    }
  }
]

const AssessmentSignatories = ({medicalDirectors, stateBoundaries, availableSignatoryStates}) => {
  let available_states = availableSignatoryStates.map(item => item.name)
  const [wccs, setWccs] = useState([]);
  const [wcc, setWcc] = useState([]);
  const [searchedWcc, setSearchedWcc] = useState([]);
  const [selectedMd, setSelectedMd] = useState(undefined);
  const [signatureModal, setSignatureModal] = useState(false);
  const dateFormat = "YYYY-MM-DD";
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [medicalDirectorForm] = Form.useForm()
  // const [medicalDirectors, setMedicalDirectors] = useState(null)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [loadingMedicalDirectors, setLoadingMedicalDirectors] = useState(false)
  const [selected, setSelected] = useState(null)
  const [isExecuting, setisExecuting] = useState(false)
  const [editingForm, setEditingForm] = useState(false)
  const [datePopupOpen, setDatePopupOpen] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const [phoneValue, setPhoneValue] = useState(undefined);
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  const [dynamicFormComputedValues, setDynamicFormComputedValues] = useState(undefined);
  const [wccOptions, setWccOptions] = useState([])
  const [mdOptions, setMdOptions] = useState([])
  const [partialValues, setPartialValues] = useState([])
  const [state, setState] = useState(available_states[0])
  const [wccLoading, setWccLoading] = useState(false)
  //Sync variables after the updates
  useEffect(() => {
    if (!!selectedMd) {
      (async () => {
        appLog('medicalDirectors loaded')
        let updatedSelectedMD = medicalDirectors.find(item => item.id === selectedMd.id)
        //Sent new updated MD
        await setSelectedMd(updatedSelectedMD)
        if (!!wccs[0]) {
          //Sent new WCC list with the updated signature
          await setWccs([...updatedSelectedMD?.consultant_signatories]);
        }
      })()
    }
    return emptyFun
  }, [medicalDirectors])
  const onMDSelect = (value, option) => {
    // console.log({value, option})
    setDynamicFormComputedValues({...dynamicFormComputedValues, email: option.email, consultant_id: option.id})
  }
  const onMDDelect = (value, option) => {
    // appLog({onMDDelect: true, value, option})
    setDynamicFormComputedValues(prevVal => ({email: undefined}))
  }
  const handleMdSearch = async (value) => {
    // console.log({value})
    if (!value || value?.trim() === '') {
      await setMdOptions([])
      await setWccLoading(false)
      await setDynamicFormComputedValues(prevVal => ({email: undefined}))
      return
    }
    await setWccLoading(true)
    if (value) {
      await setMdOptions([])
      let query = new URLSearchParams({search: value, roles: ['WoundCareConsultant', 'PhysicianAssistant', 'MD', "RegionalClinicalCoordinate", "RegionalMedicalDirector"]}).toString()
      const {data} = await apiRequest.get(`/consultants?${query}`)
      // console.log({MDs:data.data})
      let ops = data.data.map(item => ({label: item.name, value: item.name, email: item.email, actualValue: item.name, id: item.id}))
      ops = [...ops.sort((a, b) => {
        if (a.label.toLowerCase().indexOf(value.toLowerCase()) < b.label.toLowerCase().indexOf(value.toLowerCase())) {
          return -1
        }
        if (a.label.toLowerCase().indexOf(value.toLowerCase()) > b.label.toLowerCase().indexOf(value.toLowerCase())) {
          return 1
        }
        return 0
      })]
      //console.log({value, data, ops})
      if (data) {
       await setMdOptions(ops);
      }
      await setWccLoading(false)
    } else {
      await setWccLoading(false)
      await setDynamicFormComputedValues(prevVal => ({email: undefined}))
      await setMdOptions([])
    }
  };
  const handleWccSearch = async (value) => {
    if (!value || value?.trim() === '') {
      await setWccLoading(false)
      setWccOptions([])
      return
    }
    await setWccLoading(true)
    if (value) {
      setWccOptions([])
      const {data} = await apiRequest.get(`/consultants?search=${value}`)
      let ops = data.data.map(item => ({label: item.name, value: item.id}))
      ops = [...ops.sort((a, b) => {
        if (a.label.toLowerCase().indexOf(value.toLowerCase()) < b.label.toLowerCase().indexOf(value.toLowerCase())) {
          return -1
        }
        if (a.label.toLowerCase().indexOf(value.toLowerCase()) > b.label.toLowerCase().indexOf(value.toLowerCase())) {
          return 1
        }
        return 0
      })]
      //console.log({value, data, ops})
      if (data) {
        setWccOptions(ops);
      }
      await setWccLoading(false)
    } else {
      await setWccLoading(false)
      setWccOptions([])
    }
  };
  const wcc_columns = [
    {title: 'First & Last Name', dataIndex: 'name', key: 'name', fltr: {filterable: false, type: 'text'}},
    {title: 'Email', dataIndex: 'email', key: 'email', fltr: {filterable: true, type: 'text'}},
    {
      title: 'Role', dataIndex: 'roles', key: 'role', render: (roles) => {
        // console.log('roles: ', roles)
        if (roles && Array.isArray(roles) && roles.length > 0) {
          return <p style={{maxWidth: 200}}>{roles.join(', ')}</p>
        } else {
          return roles
        }
      }, fltr: {filterable: false}
    },
    {
      title: 'Action',
      render: (record) =>
        (<KebabMenu
            menus={wccActionMenus}
            recordItem={record}
            handleMenuClick={kebabWccMenuClick}
            resource="WCC signatory"
            handleOk={() => handleOk(record)}
            showConfirmDialog={selected === record.id}
            handleCancel={handleCancel}
            confirmLoading={confirmLoading}
          />
        )
    }]
  let onCell = (record, rowIndex) => ({
    onClick: async event => {
      await setSelectedMd(record);
      //console.log({consultant_signatories: record?.consultant_signatories})
      await setWccs([...record?.consultant_signatories]);
      //If there is selectedMd, set initial values of wccOptions to help in autofill of the form when editing.
      let ops = selectedMd?.consultant_signatories?.map(item => ({label: item.name, value: item.id}))
      setWccOptions(ops);
    }
  })
  
  let selectableStates = []
  // appLog({stateBoundaries, available_states})
  for (const state of stateBoundaries) {
    if (!available_states.includes(state.name)) {
      selectableStates.push(state)
    }
  }
  
  let wccRule = [
    {
      validator: (rule, value, callback) => {
        if (value) {
          if (value.length < 1) {
            callback('At least 1 WCCs required');
          } else {
            callback();
          }
        }
      }
    }
  ]
  selectableStates = [...selectableStates.map(item => ({abbreviation: item.abbreviation, label: item.name, value: `${item.abbreviation}-${item.name}`}))]
  // appLog({selectableStates})
  const columns = [
    {
      onCell, title: 'State', dataIndex: 'state', key: 'state',
      fltr: {filterable: false, type: 'select', options: makeSelectOptions(available_states), editableCol: true, required: true, props: {disabled: true}}
    },
    {
      onCell, title: 'Name', dataIndex: 'name', key: 'name', fltr: {
        filterable: false, type: 'autocomplete', options: mdOptions, editableCol: true, required: true,
        props: {onSearch: handleMdSearch, placeholder: 'Search MDs', loading: wccLoading, onSelect: onMDSelect, onDeselect: onMDDelect, filterOption: false}
      }
    }, {
      onCell,
      title: 'Role', dataIndex: 'roles', key: 'role', render: (roles) => {
        // console.log('roles: ', roles)
        if (roles && Array.isArray(roles) && roles.length > 0) {
          return <p style={{maxWidth: 200}}>{roles.join(', ')}</p>
        } else {
          return roles
        }
      }, fltr: {filterable: false}
    },
    {onCell, title: 'Email', dataIndex: 'email', key: 'email', fltr: {filterable: false, type: 'text', editableCol: true, required: true, props: {disabled: true}}},
    {
      onCell, title: 'Title', dataIndex: 'title', key: 'title', fltr: {filterable: false, type: 'text', editableCol: true, required: false}
    },
    {
      onCell, title: 'Has signature', dataIndex: 'signature', key: 'signature',
      fltr: {filterable: false, type: 'file_upload', editableCol: true, required: false},
      render: (text, record) => (
        <div className="status-container">
          <div className="dot-circle-active" style={{background: !!record.signature ? '#50D2A0' : "#fc223e"}}/>
          <Subtitle1>{!!record.signature ? 'Yes' : 'No'}</Subtitle1>
        </div>
      )
    },
    {
      invisible:true, title: 'WCCs (5 WCCs recommended)', dataIndex: 'consultant_signatories', key: 'consultant_signatories',
      fltr: {filterable: false, editableCol: true, required: false, type: 'select', options: wccOptions, fullRow: true,
        fiprops: {rules: wccRule},
        props: {
          mode: 'multiple', showSearch: true, filterOption: false, autoClearSearchValue: true, onSearch: handleWccSearch, defaultActiveFirstOption: false, showArrow: !wccLoading,
          placeholder: 'Search WCCs', loading: wccLoading
        }},
      render: (text, record) => (
        <div className="status-container">
          <div className="dot-circle-active" style={{background: !!record.signature_id ? '#50D2A0' : "#fc223e"}}/>
          <Subtitle1>{!!record.signature_id ? 'Yes' : 'No'}</Subtitle1>
        </div>
      )
    },
    {
      title: 'Action',
      render: (record) =>
        (<KebabMenu
            menus={actionMenus}
            recordItem={record}
            handleMenuClick={kebabMenuClick}
            resource="medical director"
            handleOk={() => handleOk(record)}
            showConfirmDialog={selected === record.id}
            handleCancel={handleCancel}
            confirmLoading={confirmLoading}
          />
        )
    }
  ]
  let medicalDirectorFormInputs = {
    ...createFormInputsFromTableColumns(false, columns, phoneValue, setPhoneValue, null, null, dateFormat),
    exitOnFinish: false
  }
  
  
  let stateInputs = {fields: [{name: 'state', label: 'State', required: true, type: 'select', options: selectableStates, fullRow: true}]}
  // console.log({medicalDirectorFormInputs})
  //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
  let dynamicFormInputFields = {
    'Edit Medical Director': medicalDirectorFormInputs,
    'Add New Medical Director': medicalDirectorFormInputs,
    'Add State': stateInputs
  }
  /*useEffect(() => {
    if (!!selectedMd) {
      //If there is selectedMd, set initial values of wccOptions to help in autofill of the form when editing.
      let ops = selectedMd?.consultant_signatories?.map(item => ({label: item.name, value: item.id}))
      setWccOptions(ops);
    }
    return emptyFun
  }, [selectedMd])*/
  const handleClick = async e => await getMedicalDirectors(e.key);
  
  async function getMedicalDirectors(state) {
    await setState(state)
    setLoadingMedicalDirectors(true)
    await dispatch(fetchMedicalDirectors({state}))
    setLoadingMedicalDirectors(false)
  }
  
  const getItem = (label, key, icon, children, type) => ({key, icon, children, label, type});
  let menuItems = [...availableSignatoryStates.map((item) => getItem(item.name, item.name, null, null, null), [])]
  const items = menuItems.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0)
  //Only enable delete for MDs
  const actionMenus = kebabMenus(true, false, !wccs[0])
  const wccActionMenus = kebabMenus(false, false, !wccs[0])
  const showPopconfirm = (id) => setSelected(id);
  
  async function kebabMenuClick(e, record) {
    //setSelectedMd to be able to also set initial values of wcc options in useEffect
    await setSelectedMd(record)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await openDefaultMedicalDirectorDynamicForm(record)
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        break
      default:
    }
  }
  
  async function kebabWccMenuClick(e, record) {
    //setWCC to be able to also set initial values of wcc
    //console.log({record})
    await setWcc(record)
    switch (wccActionMenus[e.key].title) {
      case 'Edit':
        await openDefaultWCCDynamicForm(record)
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        break
      default:
    }
  }
  
  const handleOk = async (record) => {
    await setConfirmLoading(true)
    await setSelected(0)
    await dispatch(deleteMedicalDirector({id: record.id, state}))
    await getMedicalDirectors(record.state)
    await setConfirmLoading(false)
  }
  
  const handleCancel = () => setSelected(0);
  
  useEffect(() => {
    getMedicalDirectors(available_states[0]).catch(e => console.log(e))
    return emptyFun
  }, [])
  
  useEffect(() => {
    //console.log({wccOptions})
    return emptyFun
  }, [wccOptions])
  
  const openDefaultMedicalDirectorDynamicForm = async (record) => {
    // console.log({record})
    if (!!record) {
      //If there is selectedMd, set initial values of wccOptions to help in autofill of the form when editing.
      let ops = record?.consultant_signatories?.map(item => ({label: item.name, value: item.id}))
      await setWccOptions(ops);
    } else {
      await setWccOptions([])
    }
    //Add one MD record for the dropdown when editing to support the default value as per new Antd Autocomplete requirements
    if ((!mdOptions[0] || mdOptions.length === 1) && !!record) {
      await setMdOptions([{...record,label: record.name, value: record.name, actualValue: record.name}])
    } else {
      await setMdOptions([])
    }
    await setFormName(!!record ? 'Edit Medical Director' : 'Add New Medical Director');
    //Format default values and filter only IDs from consultant_signatories since they are initially wcc objects
    await setDynamicFormComputedValues(undefined)
    await setDefaultDynamicModalFormValues(!!record ? {...record, consultant_signatories: record?.consultant_signatories?.map(item => item.id)} : {state: state});
    await setDynamicFormOpen(true);
  };
  const openDefaultWCCDynamicForm = async (record) => {
    await setDynamicFormOpen(false)
    // console.log({record1: record})
    // await setWcc(record)
    //change formName to expect signature result
    await setFormName('WCC\'s signature upload')
    await setPartialValues({...record, state})
    await setSignatureModal(true)
  };
  
  const openStateDynamicForm = async () => {
    await setFormName('Add State');
    await setDefaultDynamicModalFormValues(undefined);
    await setDynamicFormOpen(true);
  };
  /**
   * This function is handling returned form entries from the dynamic form
   * */
  const onDynamicFormEntriesResult = async (entries) => {
    try {
      switch (formName) {
        case 'Add New Medical Director':
          // await setDynamicFormOpen(false)
          await setWcc(selectedMd)
          //console.log({onDynamicFormEntriesResult: entries})
          //change formName to expect signature result
          /*await setFormName('Director\'s signature upload')
          await setPartialValues({...entries.values})
          await setSignatureModal(true)*/

          await executeChunkFn(dispatch, createMedicalDirector, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen),
              async () => {
                await dispatch(fetchMedicalDirectors({state}));
                await setPartialValues(undefined)
              })
          break;
        case 'Director\'s signature upload':
          await setSignatureModal(false)
          await executeChunkFn(dispatch, createMedicalDirector, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen),
            async () => {
              await dispatch(fetchMedicalDirectors({state}));
              await setPartialValues(undefined)
            })
          break;
        case 'Edit Medical Director':
          // await setDynamicFormOpen(false)
          await setWcc(selectedMd)
          //change formName to expect signature result
          /*await setFormName('Edit Director\'s signature')
          await setPartialValues({...entries.values})
          await setSignatureModal(true)*/

          await executeChunkFn(dispatch, editMedicalDirector, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen),
              async () => {
                await dispatch(fetchMedicalDirectors({state}));
                await setPartialValues(undefined)
              })
          break;
        case 'Edit Director\'s signature':
          await setSignatureModal(false)
          await executeChunkFn(dispatch, editMedicalDirector, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen),
            async () => {
              await dispatch(fetchMedicalDirectors({state}));
              await setPartialValues(undefined)
            })
          break;
        case 'Add New WCC':
          // await setDynamicFormOpen(false)
          //change formName to expect signature result
          /*await setFormName('WCC\'s signature upload')
          await setPartialValues({...entries.values})
          await setSignatureModal(true)*/

          await executeChunkFn(dispatch, editWCCSignature, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen),
              async () => {
                await setPartialValues(undefined)
              })
          break;
        case 'WCC\'s signature upload':
          await setSignatureModal(false)
          await executeChunkFn(dispatch, editWCCSignature, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen),
            async () => {
              await setPartialValues(undefined)
            })
          break;
        case 'Add State':
          let arr = entries.values?.state?.split('-')
          let payload = {name: arr[1].trim(), code: arr[0].trim()}
          await executeChunkFn(dispatch, createSignatoryState, payload, setisExecuting, dialogCloser(setDynamicFormOpen))
          break;
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      toastUp(errMsg(e, false, formName), false);
    }
  };
  
  const cancelSignatureUpload = () => {
    setPartialValues(undefined)
    setSignatureModal(false)
  }
  
  return (
    <div style={{height: '100%', width: '100%'}} className="flex-boy-row">
      <Modal
        title="Signature Pad"
        open={signatureModal}
        onOk={cancelSignatureUpload}
        onCancel={cancelSignatureUpload}
        closeIcon={modalClose(cancelSignatureUpload)}
        maskClosable={false}
        destroyOnClose={true}
        width={600}
        footer={null}
        /*footer={[
          <div className="entity-filters flex-boy-row" style={{justifyContent: 'flex-end'}}>
            {horStack([
              outlinedButton(cancelSignatureUpload, 'Cancel', isExecuting),
              containedButton(() => onDynamicFormEntriesResult({values: partialValues}), 'Submit', isExecuting, 'Saving')
            ])}
          </div>
        ]}*/
      >
        <SignatureUpload wcc={wcc} onDynamicFormEntriesResult={onDynamicFormEntriesResult} values={partialValues} closeModal={dialogCloser(setSignatureModal)}/>
      </Modal>
      {(isDynamicFormOpen && formName) && (
        <DynamicModalForm
          setDynamicFormOpen={setDynamicFormOpen}
          isDynamicFormOpen={isDynamicFormOpen}
          inputFields={dynamicFormInputFields[formName]}
          onDynamicFormEntriesResult={onDynamicFormEntriesResult}
          closeModal={dialogCloser(setDynamicFormOpen)}
          isExecuting={isExecuting} phoneValue={phoneValue} setPhoneValue={setPhoneValue}
          defaultValues={defaultDynamicModalFormValues}
          formName={formName} computedValues={dynamicFormComputedValues}
        />
      )}
      {!wccs[0] && <div style={{width: '150px'}}>
        <div>
          <Headline6 style={{marginBottom: '10px'}}>States</Headline6>
          <Button type="primary" icon={<PlusOutlined/>} style={{marginBottom: '10px'}}
                  onClick={() => openStateDynamicForm()}
          >Add State</Button>
        </div>
        <Menu
          onClick={handleClick}
          style={{width: '105%', height: 0.85 * window.innerHeight, overflowY: 'scroll'}}
          defaultSelectedKeys={[...(!!state ? [state] : ['Alabama'])]}
          items={items}
          mode="inline"
        />
      </div>}
      <Divider type="vertical" style={{height: '100vh', color: '#696969'}}/>
      <div style={{flex: 1, width: '100%'}}>
        {!!wccs[0] && <div className="flexy-row" style={{width: '100%'}}>
          {/*<Button onClick={} type={"dashed"} icon={<LeftOutlined/>}>Back to MDs</Button>*/}
          {textButton(() => {
            setSelectedMd(null)
            setWccs([])
          }, 'Back to MDs', false, null, null, {icon: <LeftOutlined/>})}
          <Headline6>{`MD: ${selectedMd?.name}`}</Headline6>
          <span style={{minWidth: 250}}/>
        </div>}
        <div className="flex-boy-row-space" style={{width: '100%'}}>
          <Headline6>{!wccs[0] ? 'Medical Directors (MDs):' : 'WCCs:'}</Headline6>
          {containedButton(() => openDefaultMedicalDirectorDynamicForm(selectedMd), !wccs[0]?'Add MD':'Add Consultant', false, "loading", null, {icon: <PlusOutlined/>})}
        </div>
        {!wccs[0] && <div className="w-full"><Table loading={loadingMedicalDirectors} columns={columns.filter(value => !value.invisible)} dataSource={medicalDirectors}/></div>}
        {!!wccs[0] && <div className="w-full"><Table loading={loadingMedicalDirectors} columns={wcc_columns} dataSource={wccs}/></div>}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  medicalDirectors: state?.masters.medicalDirectors,
  stateBoundaries: state?.masters.stateBoundaries,
  availableSignatoryStates: state?.masters.availableSignatoryStates,
  loadingMedicalDirectors: state?.masters.loadingMedicalDirectors
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentSignatories)
