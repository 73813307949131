/* eslint-disable no-unused-vars */
import React from 'react';
import { AppointmentCard, Body2, Indicator, Subtitle1, Subtitle2 } from '../../general'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { selectAppointment } from '../../../app/Reducers';
import { setVisibleSchedule } from '../../../app/Reducers/mapSlice';
import {useLocation, useNavigate} from 'react-router-dom';

const AppointmentCardContainer = ({  color, wcc, appointment }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const goToAppointment = () => {
    if (appointment) {
      dispatch(setVisibleSchedule(false))
      dispatch(selectAppointment({ navigate, record: appointment }))
      navigate(`/appointments/${appointment.id}`)
    }
  }
  return (<AppointmentCard onClick={() => goToAppointment()} color={color}>
    <div className="flex-boy-column-space" style={{ width: "100%" }}>
      <div className="flex-boy-row-space" style={{ width: "100%" }}>
        <FontAwesomeIcon onClick={() => console.log("heh")} color="grey" icon={["fas", 'user']} />
        <Subtitle1 className="name" style={{ flex: 1, textAlign: 'left' }}>{appointment ? appointment.patient.name : "Patient Name"}</Subtitle1>
        <Indicator color={color || "#4B9BFF"} />
      </div>
      {appointment ? <div className="flex-boy-row-space" style={{ width: "100%", flexWrap: 'wrap' }}>
        {/* <Subtitle1 className="name" style={{ flex: 1, textAlign: 'left' }}>{appointment.consultant.name}</Subtitle1> */}
        <div className="flex-boy-row-space">
          <FontAwesomeIcon onClick={() => console.log("heh")} color="grey" icon={["fas", 'clock']} />
          <Body2 color="black" style={{
            textAlign: 'left', flex: 1
          }}>{dayjs(appointment.start_at).format("HH:mm a")} -{dayjs(appointment.end_at).format("HH:mm a")}</Body2>
        </div>
      </div> : <div className="flex-boy-row-space" style={{ width: "100%" }}>
        <Subtitle1 className="name" style={{ flex: 1, textAlign: 'left' }}>Patient Name</Subtitle1>
        <FontAwesomeIcon onClick={() => console.log("heh")} color="grey" icon={["fas", 'clock']} />
        <Subtitle2 className="name" style={{ flex: 1, textAlign: 'left' }}>6:00am-7:00pm</Subtitle2>
      </div>}
    </div>
  </AppointmentCard>);
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentCardContainer)
