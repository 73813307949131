import React, {useEffect, useState} from 'react';
import {Grid, Table} from "antd";
import {connect, useDispatch} from "react-redux";
import {fetchWPRegions, resetWpRegionsPagination} from "../../../app/Reducers";
import {updateWpRegionsFilters} from "../../../app/Reducers/ConfigSlice";
import TableFilters from "../../../components/InputComponents/TableFilters";
import {emptyFun, emptyTable, getSkels, onTableChange, responsiveTbl} from "../../../common/helpers";
const { useBreakpoint } = Grid;
const WPRegions = ({appConf, wpRegionsPagination, wpRegions}) => {
  const dispatch = useDispatch();
  const windowState=useBreakpoint()
  const dateFormat = "YYYY-MM-DD";
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [auditItem, setAuditItem] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [selected, setSelected] = useState(null)
  const [isExecuting, setisExecuting] = useState(false)
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  useEffect(() => {
    dispatch(fetchWPRegions());
    return emptyFun
  }, []);
  
  const columns = [
    {
      title: 'Name', dataIndex: 'name', key: 'name', fltr: {filterable: true, type: 'text', editableCol: true, required: true, fullRow: true}
    },
    {
      title: 'Sub-regions', dataIndex: 'sub_region_boundaries', key: 'subRegion',
      render: (record) => {
        //console.log({record})
        return record.map(item => item?.name).join(', ');
      }
    }
  ]
  
  
  async function handleFilterAction(action, values) {
    await dispatch(resetWpRegionsPagination())
    if (action === 'filter') {
      let pl = {filterData: {...values}, pagination: wpRegionsPagination}
      await dispatch(updateWpRegionsFilters([false, pl, true]))
      await dispatch(fetchWPRegions(pl))
      await dispatch(updateWpRegionsFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updateWpRegionsFilters([appConf.wpRegionsFilters[0], undefined, true]))
      await dispatch(fetchWPRegions())
      await dispatch(updateWpRegionsFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  return (
    <div className="w-full">
      <Table
        /*title={() => (<TableFilters datasource={columns} setIsVisible={setIsVisible} isVisible={isVisible}
                                    handleFilterAction={handleFilterAction} filters={appConf.wpRegionsFilters[1]}
                                    showClear={appConf.wpRegionsFilters[0]} loading={appConf.wpRegionsFilters[2]}
        />)}*/
        loading={appConf.wpRegionsFilters[2]} size={'small'} {...responsiveTbl(windowState)} locale={{emptyText: emptyTable('regions')}}
        onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchWPRegions, appConf.wpRegionsFilters, updateWpRegionsFilters, null)}
        onRow={(record) => ({
          onClick: async (event) => {
            await setAuditItem(record)
            await setShowAuditDialog(true)
          }
        })}
        {...getSkels((appConf.wpRegionsFilters[2]), columns, wpRegions)} pagination={wpRegionsPagination}
      />
    </div>
  
  );
};


const mapStateToProps = (state) => ({

  activeUser: state.auth?.activeUser,
  wpRegions: state.masters.wpRegions,
  wpRegionsPagination: state.masters.wpRegionsPagination,
  appConf: state.appConf
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(WPRegions);
