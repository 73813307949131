import React, { useCallback, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AutoComplete, DatePicker, Form, Input, Select, Typography } from 'antd'
import dayjs from "dayjs"
import { updatePatientDetails } from '../../../../app/Reducers';
import NumberFormat from "react-number-format";
import {getPhoneParams, executeChunkFn, inputRow, divInputRow} from "../../../../common/helpers";
import { handleActiveFacilitySearch } from "../../../../app/Apis/commonApi";
import AppPhoneInput from '../../../../components/InputComponents/AppPhoneInput';

export const PatienDetailsForm = ({ patient, closeModal, setisExecuting, activeUser, detailsType }) => {
    const dateFormat = 'MM/DD/YYYY';
    const { Option } = Select;
    
    const [form] = Form.useForm()
    const dispatch = useDispatch()
  
    const [facilities, setFacilities] = useState([]);
    const [facility, setFacility] = useState()
    const [phoneValue, setPhoneValue] = useState(!!patient ? { ...getPhoneParams(patient?.phone_no, 'phone_no') } : undefined);
    const [initialValues, setInitialValues] = useState(!!patient ? { ...patient, birth_date: dayjs(patient.birth_date), ...(!!patient?.phone_no && { ...getPhoneParams(patient?.phone_no, 'phone_no') }) } : {})
    const onFinish = async (values) => {
       

        let val = { ...values }
        delete val.facility_id
        let payload = {
            ...val, patient_id: patient.id, ...locationData,
            birth_date: dayjs(val.birth_date).format(dateFormat), ...(facility && { facility_id: facility.id }), ...phoneValue
        }
        console.log("closing2")
      await executeChunkFn(dispatch, updatePatientDetails, payload, setisExecuting, closeModal)
    };
    // location stuff
    const [viewport, setViewport] = useState({ latitude: 37.7577, longitude: -122.4376, zoom: 8 })
    const [locationData, setLocationData] = useState(null)
    const geocoderContainerRef = useRef();
    const mapRef = useRef();
    const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);
    const onResult = useCallback((result) => {
        // console.log(result)
        let center = result.result.center
        let context = result.result.context
        let state = context.find(item => item.id.includes('region'))
        let city = context.find(item => item.id.includes('place'))
        let zip_code = context.find(item => item.id.includes('postcode'))
        let data = {
            // city: city ? city.text : result.result.place_name, state: state ? state.text : null,
            // zip_code: zip_code ? zip_code.text : result.result.place_name,
            co_ordinates: `${center[0]},${center[1]}`,
            // address_1: result.result.place_name
        }

        setLocationData(data)
        form.setFieldsValue({
            address_1: result.result.place_name,
            city: city ? city.text : null,
            state: state ? state.text : null,
            zip_code: zip_code ? zip_code.text : null,
        });
    }, [])
    const onFacilitySelect = (value) => {
        let facility = facilities.find(item => item.name === value)
        setFacility(facility)
    };
    const disabledFutureDates = (current) => {
        let customDate = dayjs();
        let tooOld = dayjs("1869-12-31");
        return (current && current > dayjs(customDate, dateFormat)) || (current && current < dayjs(tooOld, dateFormat));
    }
    return (
        <Form
            name="new-patient"
            form={form}
            id="patientForm"
            labelCol={{ span: 16 }}
            onValuesChange={(changedValues, allValues) => {
                // console.log('changedValues:', changedValues)
                // console.log('allValues:', allValues)
            }}
            initialValues={{ ...initialValues }}
            layout="vertical"
            onFinish={onFinish}>
            <Form.Item
                style={{ width: '100%' }}
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Missing/Invalid name', pattern: /[a-zA-Z ]$/ }]}>
                <Input autoComplete={'off'} placeholder="Name" />
            </Form.Item>
            {divInputRow([
                <Form.Item
                    className={'flex-1 min-w-[160px]'}
                    name="birth_date"
                    label="Date of Birth"
                    rules={[{ required: true, message: 'Missing Date of Birth' }]}>
                    <DatePicker
                        format={dateFormat}
                        placeholder={dateFormat} disabledDate={disabledFutureDates} style={{ width: '100%' }} />
                </Form.Item>,
                <Form.Item
                    name="gender"
                    label="Gender"
                    className={'flex-1 min-w-[160px]'}
                    rules={[{ required: true, message: 'Missing Gender' }]}>
                    <Select
                        popupMatchSelectWidth={false}
                        placeholder="gender"
                        style={{ width: '100%' }}
                        allowClear>
                        <Option value="male">Male</Option>
                        <Option value="female">Female</Option>
                        <Option value="other">Other</Option>
                    </Select>
                </Form.Item>,
                <Form.Item
                    className={'flex-1 min-w-[160px]'}
                    name="ssn"
                    label="SSN"
                    rules={[{ max: 11, message: 'SSN length exceeds limit' }/*, {pattern: /^[1-9]./, message: 'Invalid SSN'}*/]}>
                    <NumberFormat autoComplete={'off'} customInput={Input} format="###-##-####" placeholder="###-##-####" />
                </Form.Item>
            ])}
            {inputRow([
                <Form.Item
                    style={{ width: '100%' }}
                    name="email"
                    label="Email"
                // rules={[{ required: true, message: 'Missing Email' }]}
                >
                    <Input autoComplete={'off'} placeholder="Email" type="email" />
                </Form.Item>,
                <AppPhoneInput name={'phone_no'} setPhoneValue={setPhoneValue} phoneValue={phoneValue} formItemProps={{ style: { width: '100%' }, required: true }} />
            ])}

            {activeUser?.facilityProfile ? null : <Form.Item
                style={{ width: '100%' }}
                name="facility_id"
                placeholder="Search for facility"
                label="Office"
                rules={[{ required: false, message: 'Missing Office' }]}>
                <AutoComplete style={{ width: "100%", borderRadius: "4px" }} popupMatchSelectWidth={false}
                    onSelect={onFacilitySelect}
                    placeholder="Search for facility"

                    onSearch={(value) => handleActiveFacilitySearch(value, setFacilities)}>
                    {facilities.length > 0 && facilities.map((item, index) =>
                        <AutoComplete.Option key={index} value={item.name}>{item.name}</AutoComplete.Option>)}

                </AutoComplete>
                {patient.medical_facility ?
                    <Typography variant={"caption"}><b>Current Office: </b>{patient.medical_facility}</Typography> : null}
            </Form.Item>}

            {/* {!activeUser?.facilityProfile && <Form.Item
                label="SmartSheet ID"
                name="smartsheet_id"
                rules={[{ required: false, message: 'Missing SmartSheet ID' }]}
            >
                <Input autoComplete={'off'} placeholder="SmartSheet ID" />
            </Form.Item>} */}
        </Form>
    );
}
const mapStateToProps = (state) => ({
     activeUser: state.auth?.activeUser
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PatienDetailsForm)
