import React, {useEffect, useState} from 'react';
import {Grid, Table, Tag, Typography} from "antd";
import {DeleteOutlined, EditOutlined, ExclamationOutlined, PlusOutlined} from "@ant-design/icons";
import {dialogCloser, emptyFun, emptyTable, getSkels, makeAutoCompleteOptions, makeSelectOptions, onTableChange, responsiveTbl, sentenceCaseNoDash} from "../../common/helpers";
import {connect, useDispatch} from "react-redux";
import {fetchAuditables, fetchAuditResources, resetAuditResourcesPagination, updateAuditResourcesFilters} from "../../app/Reducers";
import AuditLogViewer from "./AuditLogViewer";
import TableFilters from "../InputComponents/TableFilters";
import dayjs from 'dayjs';

const { Title, Paragraph, Text } = Typography;
const getEventColor = (event) => {
    switch (event) {
        case 'created':
            return 'green'
        case 'updated':
            // return '#108ee9'
            return 'blue'
        case 'deleted':
            return 'red'
        default:
            return '#363646'
    }
}
const getEventIcon = (event) => {
    switch (event) {
        case 'created':
            return <PlusOutlined/>
        case 'updated':
            return <EditOutlined/>
        case 'deleted':
            return <DeleteOutlined/>
        default:
            return <ExclamationOutlined/>
    }
}
const { useBreakpoint } = Grid;
const AuditLogList = ({ auditResources, appConf, auditResourcesPagination, auditables }) => {
    const dispatch = useDispatch();
    const windowState = useBreakpoint();
    const [showAuditDialog, setShowAuditDialog] = useState(false);
    const [auditItem, setAuditItem] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        loadAuditLogs().catch(console.log)
        return emptyFun
    }, []);
    const loadAuditLogs = async () => {
        await dispatch(updateAuditResourcesFilters([appConf.auditResourcesFilters[0], undefined, true]))
        await dispatch(fetchAuditables());
        await dispatch(fetchAuditResources());
        await dispatch(updateAuditResourcesFilters([false, undefined, false]))
    }
    const auditLogColumns = [
        {
            title: 'Resource Type', dataIndex: 'auditable_type', key: 'auditable', fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(auditables) },
            render: (resource) => <Text style={{ fontWeight: '600' }}> {sentenceCaseNoDash(resource)}</Text>
        },
        {
            title: 'Action', dataIndex: 'event', key: 'event', fltr: { filterable: true, type: 'select', options: makeSelectOptions(['created', 'updated', 'deleted']) },
            render: (event) => <Tag icon={getEventIcon(event)} color={getEventColor(event)} key={event}>{sentenceCaseNoDash(event)}</Tag>
        },
        {
            title: 'Actor', dataIndex: ['user', 'name'], key: 'user_id', fltr: { filterable: false, type: 'text' },
            render: (name) => <Text style={{ fontWeight: '600' }}> {name || 'Nameless'}</Text>
        },
        {
            title: 'Actor Role', dataIndex: ['user', 'role'], key: 'actor_role', fltr: { filterable: false, type: 'text' },
            render: (role) => role || 'Unknown'
        },
        {
            title: 'Actor Email', dataIndex: ['user', 'email'], key: 'actor_role', fltr: { filterable: false, type: 'text' },
            render: (email) => email || 'Unknown'
        },
        {
            title: 'Time Created',
            dataIndex: 'created_at',
            key: 'created_at',
            fltr: { filterable: true, type: 'date_range', keys: ['created_at[start_at]', 'created_at[end_at]'], baseKey: 'date_range_filter' }
        },
        {
            title: 'Time Updated',
            dataIndex: 'updated_at',
            key: 'updated_at',
            fltr: { filterable: true, type: 'date_range', keys: ['updated_at[start_at]', 'updated_at[end_at]'], baseKey: 'date_range_filter' }
        }
    ]

    async function handleFilterAction(action, values) {
        await dispatch(resetAuditResourcesPagination())
        if (action === 'filter') {
            let pl = { filterData: { ...values }, pagination: auditResourcesPagination }
            await dispatch(updateAuditResourcesFilters([false, pl, true]))
            await dispatch(fetchAuditResources(pl))
            await dispatch(updateAuditResourcesFilters([true, pl, false]))
        }
        if (action === 'reset') {
            await dispatch(updateAuditResourcesFilters([appConf.auditResourcesFilters[0], undefined, true]))
            await dispatch(fetchAuditResources())
            await dispatch(updateAuditResourcesFilters([false, undefined, false]))
        }
        setIsVisible(false)
    }


    return (
        <div className="w-full">
            {(showAuditDialog && !!auditItem) &&
                <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)}
                                loading={false} resourceDescription={'General Audit Logs'} resourceType={sentenceCaseNoDash(auditItem.auditable_type)}
                                defaultValues={{
                                    auditableId: auditItem.auditable_id,
                                    auditable: auditItem.auditable_type,
                                    format: 'json',
                                    "dateRange[start_at]": dayjs(auditItem.updated_at).format('YYYY-MM-DD') + ' 00:00:00',
                                    "dateRange[end_at]": auditItem.updated_at
                                }}/>}
            <Table
                title={() => (<TableFilters datasource={auditLogColumns} setIsVisible={setIsVisible} isVisible={isVisible}
                                            handleFilterAction={handleFilterAction} filters={appConf.auditResourcesFilters[1]}
                                            showClear={appConf.auditResourcesFilters[0]} loading={appConf.auditResourcesFilters[2]}/>)}
                loading={appConf.auditResourcesFilters[2]} size={'small'} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('patient wounds.') }}
                onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchAuditResources, appConf.auditResourcesFilters, updateAuditResourcesFilters, null)}
                // columns={auditLogColumns}
                // dataSource={auditResources}
                onRow={(record) => ({
                    onClick: async (event) => {
                        await setAuditItem(record)
                        await setShowAuditDialog(true)
                    }
                })}
                {...getSkels((appConf.auditResourcesFilters[2]), auditLogColumns, auditResources)} pagination={auditResourcesPagination}
            />
        </div>

    );
};


const mapStateToProps = (state) => ({

    activeUser: state.auth?.activeUser,
    auditResources: state.auditLogs.auditResources,
    auditables: state.auditLogs.auditables,
    auditResourcesPagination: state.auditLogs.auditResourcesPagination,
    appConf: state.appConf
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(AuditLogList);
