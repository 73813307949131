import React, {useEffect, useState} from 'react';
import {apiRequest} from '../../app/Apis';
import {appLog, createMenus, emptyFun, makeAutoCompleteOptions, skelBars} from '../../common/helpers';
import RoleCard from './RoleCard';
import {AutoComplete, Menu} from 'antd';
import {errMsg, toastUp} from '../../app/Reducers/reducerUtils';

const RolesList = () => {
  const [roles, setRoles] = useState([])
  const [rolesOptions, setRoleOptions] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)
  const [permissions, setPermissions] = useState([])
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState(null)
  useEffect(() => {
    fetchRoles().catch(appLog)
    return emptyFun
  }, []);
  useEffect(() => {
    let menuOptions = createMenus(roles.map(item => item.name))
    // console.log({menuOptions})
    setRoleOptions(menuOptions)
    return emptyFun
  }, [roles]);
  // console.log({ loading })
  const fetchRoles = async () => {
    await setLoading(true)
    const { data: permissions } = await apiRequest.get('/permissions').catch(function (err) {
      console.log(err)
      toastUp(errMsg(err), false)
    })
    if (permissions?.data) {
      await setPermissions(permissions.data)
    }
    const { data } = await apiRequest.get('/roles').catch(err => {
      console.log(err)
      toastUp(errMsg(err), false)
    })
    if (data?.data) {
      let roles = data.data.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
      await setRoles(roles)
      await setSelectedRole((roles[0]))
    }
    await setLoading(false)
  }
  
  return (
    <div className={'gap-4 flex flex-col bg-gray-200 p-4 h-[100%]'}>
      <div className={'gap-4 flex flex-row overflow-auto overscroll-contain flex-1 mb-8'}>
        <div style={{ width: 256 }} className={'flex flex-col gap-2 mb-4'}>
          <AutoComplete popupMatchSelectWidth={false}
                        onChange={(value, option) => {
                          console.log({ value })
                          setFilter(value)
                        }}
                        value={filter}
                        options={makeAutoCompleteOptions(roles.map(item => item.name))}
                        placeholder={'Filter role'}
                        onSelect={(value, option) => {
                          console.log({ value, option })
                          let role = roles.find(item => item.name === value)
                          setSelectedRole(role)
                        }}
                        rootClassName={'my-1.5'}
                        filterOption={true}
                        allowClear
            // value={filter}
          />
          {!loading ? <div style={{ width: 256 }} className={'overflow-y-auto overscroll-contain flex-1 pb-4'}>
            <Menu style={{ width: '100%' }}
                  selectable
                  selectedKeys={[selectedRole?.name]}
                  onClick={(item, key, keyPath, domEvent) => {
                    let role = roles.find(value => value.name === item.key)
                    setSelectedRole(role)
                    setFilter(null)
                  }}
                  mode="vertical"
                  items={rolesOptions}/>
          </div> : skelBars()}
        </div>
        <div className={'mb-4 w-full overflow-y-hidden'}><RoleCard role={selectedRole} permissions={permissions} loading={loading}/></div>
      
      </div>
    </div>
  );
};

export default RolesList;