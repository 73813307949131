/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-webpack-loader-syntax */
import React, {Fragment, useEffect, useRef, useState, useMemo} from 'react'
// mapboxgl.workerClass = MapboxWorker
// mapboxgl.accessToken = mapb
import {connect, useDispatch} from 'react-redux'
// import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import {mapboxToken, status_colors} from '../../configure/constants'
import MapMenu from './MapMenu';
import {Popover} from 'antd';
import MapGL, {FlyToInterpolator, Layer, LinearInterpolator, Marker, NavigationControl, Source, WebMercatorViewport} from 'react-map-gl';
import {PatientMarker, Primary, Subtitle1} from '../general';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PatientCard from './Components/PatientCard';
import FacilityCard from './Components/FacilityCard';
import MapSearch from './Components/MapSearch';
import ClusterMarker from './Components/ClusterMarker';
import CaliforniaMarker from './Components/CaliforniaNarker';
import {ConsultantMarker} from './Components/markers';
import {
  fetchAllAssetLocations,
  fetchAllConsultants,
  fetchAllFacilities,
  fetchAllPatients,
  fetchRegionConsultants,
  fetchRegionFacilities,
  fetchRegionPatients,
  setDistance,
  setVisibleSchedule
} from '../../app/Reducers/mapSlice'
import * as turf from '@turf/turf'
import bbox from '@turf/bbox';
import Polyline from '@mapbox/polyline';
import ConsultantMenu from './ConsultantMenu';
import HeaderTop from '../HeaderTop';
import {AppointmentPopover} from './Components/AppointmentPopover';
import {easeCubic} from 'd3-ease';
import {containedButton, emptyFun} from "../../common/helpers";
import useSupercluster from 'use-supercluster';
import background from "../../assets/map.png";
import {usePermissionCheck} from '../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
import {CompassOutlined} from '@ant-design/icons';

export const Map = ({ consultants, patients, facilities, consultantSchedule, visibleSchedule, selectedConslultant, activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const mapContainer = useRef();
  const [hotspots, setHotspots] = useState([])
  const [routing, setRouting] = useState(null)
  const can_view_map = usePermissionCheck('view_map', false, [])
  
  const [viewport, setViewport] = useState({
    width: '100%',
    height: '100%',
    latitude: 38.5,
    longitude: -98.0,
    zoom: 4
  });
  
  useEffect(() => {
    // console.log('activeUser', activeUser);
    dispatch(fetchAllConsultants());
    dispatch(fetchAllPatients());
  
    dispatch(fetchAllFacilities());
    return emptyFun;
  }, []);
  
  const fetchStationAPI = () => {
    fetch(`https://data.cityofnewyork.us/resource/yjub-udmw.json`)
      .then((res) => res.json())
      .then((hotspots) => {
        setHotspots(
          hotspots
            .filter((spot) => {
              return spot.type === 'Free';
            })
            .slice(0, 10)
        );
      });
  };
  const [visiblepop, handlevisiblePop] = useState(null);
  const goToNYC = (item) => {
    setViewport({
      ...viewport,
      longitude: item._geoloc.lng,
      latitude: item._geoloc.lat,
      zoom: 20,
      transitionDuration: 5000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: easeCubic
    });
    // handlevisiblePop(item)
  };
  const markers = React.useMemo(() => hotspots.map(spot => (<Marker key={spot.objectid}
                                                                    latitude={parseFloat(spot.latitude)}
                                                                    longitude={parseFloat(spot.longitude)}>
    {/* <Avatar
          src={<Image src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />} /> */}
    <ClusterMarker/>
  </Marker>)), [hotspots]);
  const onRegionClick = event => {
    const feature = event.features[0];
    console.log(feature);
    if (feature && feature.layer.source.includes('jsx')) {
      // calculate the bounding box of the feature
      // dispatch(fetchRegionPatients(feature.properties.name))
      dispatch(fetchRegionConsultants(feature.properties.name));
      dispatch(fetchRegionPatients(feature.properties.name));
      dispatch(fetchRegionFacilities(feature.properties.name));
      const [minLng, minLat, maxLng, maxLat] = bbox(feature);
      // construct a viewport instance from the current state
      const vp = new WebMercatorViewport(viewport);
      const { longitude, latitude, zoom } = vp.fitBounds([[minLng, minLat], [maxLng, maxLat]], {
        padding: 40
      });
      setViewport({
        ...viewport,
        longitude,
        latitude,
        zoom,
        transitionInterpolator: new LinearInterpolator({
          around: [event.offsetCenter.x, event.offsetCenter.y]
        }),
        transitionDuration: 1000
      });
    }
  };
  const caliMarker = React.useMemo(() => {
    return (
      <Marker
        latitude={parseFloat('36.7783')}
        longitude={parseFloat('-119.4179')}
      >
        {/* <Avatar
          src={<Image src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />} /> */}
        <CaliforniaMarker/>
      </Marker>)
  })
  const consultMarker = (lat,lng, item) => {
    return (
      <Marker
       
        latitude={parseFloat(lat)}
        longitude={parseFloat(lng)}
      >
        {/* <Avatar
          src={<Image src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />} /> */}
        {visibleSchedule ? (item.id === selectedConslultant.id ? <ConsultantMarker item={item} hideSchedule={() => dispatch(setVisibleSchedule(false))}/> : null) :
          <ConsultantMarker item={item} hideSchedule={() => dispatch(setVisibleSchedule(false))}/>}
      
      </Marker>)
  }
  const dispatch = useDispatch()
  
  
  useEffect(() => {
    // fetchStationAPI()
    if (consultantSchedule.length) {
      route();
    }
  }, [consultantSchedule]);
  const navControlStyle = {
    left: 20,
    bottom: 50
  };
  const dataLayer = {
    type: 'fill',
    paint: {
      'fill-color': Primary,
      'fill-opacity': 0.1
    }
  };
  const outline_layer = {
    type: 'fill',
    paint: {
      'fill-color': Primary,
      'fill-opacity': 0.09,
      'fill-outline-color': 'red'
    }
  };
  
  const building = {
    id: 'add-3d-buildings',
    source: 'composite',
    'source-layer': 'building',
    filter: ['==', 'extrude', 'true'],
    type: 'fill-extrusion',
    minzoom: 15,
    paint: {
      'fill-extrusion-color': '#aaa', // Use an 'interpolate' expression to
      // add a smooth transition effect to
      // the buildings as the user zooms in.
      'fill-extrusion-height': [
        'interpolate',
        ['linear'],
        ['zoom'],
        15,
        0,
        15.05,
        ['get', 'height']
      ],
      'fill-extrusion-base': [
        'interpolate',
        ['linear'],
        ['zoom'],
        15,
        0,
        15.05,
        ['get', 'min_height']
      ],
      'fill-extrusion-opacity': 0.6
    }
  };
  const genOperationRadius = (latlng, raduis) => {
    let person = turf.point(latlng);
    let radius = raduis;
    let options = { steps: 80, units: 'kilometers' };
    let personFeature = turf.circle(person, radius, options)
    return personFeature
  }
  
  function objectToArray(obj) {
    var keys = Object.keys(obj);
    var routeGeoJSON = keys.map(function (key) {
      return obj[key];
    });
    return routeGeoJSON;
  }
  
  const route = async () => {
    let route_data = null;
    let truckLocation = consultantSchedule[0].consultant.latlng;
    let warehouseLocation = consultantSchedule[0].consultant.latlng;
    let lastQueryTime = 0;
    let lastAtRestaurant = 0;
    let keepTrack = [];
    let currentSchedule = [];
    let currentRoute = null;
    let pointHopper = {};
    let pause = true;
    let speedFactor = 50;
    let shed = [...consultantSchedule].reverse();
    let list = [consultantSchedule[0].consultant, ...shed];
    let dropoffs = turf.featureCollection([]);
    let points = list
      .filter((item) => item.latlng)
      .map((item) => turf.point(item.latlng));
    dropoffs.features = [...points];
    let coordinates = list
      .filter((item) => item.latlng)
      .map((item) => `${item.latlng[0]},${item.latlng[1]};`);
    console.log(coordinates);
    await fetch(
      'https://api.mapbox.com/directions/v5/mapbox/driving/' +
      coordinates.join('').slice(0, -1) +
      '?access_token=' +
      mapboxToken
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.routes && data.routes.length) {
          
          
          let route_data = Polyline.toGeoJSON(data.routes[0].geometry)
          dispatch(setDistance((data.routes[0].distance / 1640).toFixed(1)))
          setRouting(route_data)
        }
      });
    console.log(route_data);
    return route_data;
  };
  const route_layer = {
    id: 'routearrows', type: 'line', 'layout': { 'line-join': 'round', 'line-cap': 'round' }, 'paint': {
      'line-color': '#3D5AF1', 'line-width': 3
      // 'line-dasharray': [1, 2],
    }
  };
  // console.log(personFeature)
  const [popoverContent, setPopContent] = useState("patient")
  
  
  // cluster stuff
  const mapRef = useRef();
  const people = patients ? patients.data : [];
  const persons = people?.map(patient => ({
    type: "Feature",
    properties: { cluster: false, ...patient },
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(patient.lng), parseFloat(patient.lat)]
    }
  }));
  
  // load and prepare data
  // get map bounds
  const bounds = mapRef.current
    ? mapRef.current?.getMap()?.getBounds()?.toArray().flat()
    : null;
  
  
  const hospices = facilities ? facilities.data : [];
  
  const hospice_points = hospices?.map(hospice => ({
    type: "Feature",
    properties: { cluster: false, ...hospice },
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(hospice.lng), parseFloat(hospice.lat)]
    }
  }));

  const wccs = consultants ? consultants.data : [];
  const consultant_points = wccs?.map(consultant => ({
    type: "Feature",
    properties: { cluster: false, ...consultant },
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(consultant.lng), parseFloat(consultant.lat)]
    }
  }));

 
  
  // load and prepare data
  // console.log(hospice_points)
  
  // get clusters
  
  
  // get clusters
  
  
  const ReuseSupercluster = (points) => {
    
    const { clusters, supercluster } = useSupercluster({
      points: points,
      bounds,
      zoom: viewport.zoom,
      options: { radius: 100, maxZoom: 20 }
    });
    
    return { clusters, supercluster }
  }
  const facilitiesCluster = ReuseSupercluster(hospice_points)
  const PatientsCluster = ReuseSupercluster(persons)
  const ConsultantsCluster = ReuseSupercluster(consultant_points)
  
  useEffect(() => {
    
    dispatch(setVisibleSchedule(false))
    return () => {
    
    }
  }, [])
  
  
  
  return (<div className="mapCont">
   
    <MapMenu/>
    <div className="map">
      {/* <div className="map-container" ref={mapContainer}>
                {loadwifiMarkers()}
                </div> */}
      <MapSearch goToNYC={goToNYC}/>
      {/* <MapStatsCard /> */}
      <div id="static" style={{ backgroundImage: `url(${background})` }}></div>
      <div className="map-container" ref={mapContainer}>
        <MapGL height={'100%'}
               {...viewport}
               mapStyle="mapbox://styles/mapbox/light-v10"
               onViewportChange={nextViewport => setViewport(nextViewport)}
               mapboxApiAccessToken={mapboxToken}
               onClick={onRegionClick}
               ref={mapRef}
        >
        
          
          
          {/* clustering attempt */}
          
          
          {patients.visible && !visibleSchedule ? PatientsCluster?.clusters?.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount
            } = cluster.properties;
            
            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  latitude={latitude}
                  longitude={longitude}
                >
                  <div
                    className="cluster-marker-patients"
                    style={{
                      width: `${40 + (pointCount / persons.length) * 20}px`,
                      height: `${40 + (pointCount / persons.length) * 20}px`
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        PatientsCluster?.supercluster?.getClusterExpansionZoom(cluster.id),
                        30
                      );
                      
                      setViewport({
                        ...viewport,
                        latitude,
                        longitude,
                        zoom: expansionZoom,
                        transitionDuration: 2500,
                        transitionInterpolator: new FlyToInterpolator(), transitionEasing: easeCubic
                      });
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }
            
            return (
              <Marker
                key={`patient-${cluster.properties.id}`}
                latitude={latitude}
                longitude={longitude}
                onClick={() => handlevisiblePop(cluster.properties)}
              
              >
                
                <Popover onVisibleChange={(e) => {
                  if (e === false) {
                    handlevisiblePop(null)
                  }
                }} visible={visiblepop?.id === cluster.properties.id} content={<PatientCard patient={cluster.properties}/>} title={cluster.properties.name} trigger="click">
                  <div className="icon-circle" style={{ backgroundColor: "#002f3b" }}>
                    <FontAwesomeIcon color="white" size="1x" icon={['fas', 'user-injured']}/>
                  </div>
                </Popover>
              </Marker>
            );
          }) : null}
          
          
          {patients.visible && !visibleSchedule && visiblepop?.id ? PatientsCluster?.clusters?.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount
            } = cluster.properties;
            
            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  latitude={latitude}
                  longitude={longitude}
                >
                  <div
                    className="cluster-marker-patients"
                    style={{
                      width: `${40 + (pointCount / persons.length) * 20}px`,
                      height: `${40 + (pointCount / persons.length) * 20}px`
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        PatientsCluster?.supercluster?.getClusterExpansionZoom(cluster.id),
                        30
                      );
                      
                      setViewport({
                        ...viewport,
                        latitude,
                        longitude,
                        zoom: expansionZoom,
                        transitionDuration: 2500,
                        transitionInterpolator: new FlyToInterpolator(), transitionEasing: easeCubic
                      });
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }
            
            return (
              <Marker
                key={`patient-${cluster.properties.id}`}
                latitude={latitude}
                longitude={longitude}
              
              
              >
                
                <Popover content={<PatientCard patient={cluster.properties}/>} title={cluster.properties.name} trigger="click">
                  <div className="icon-circle" style={{ backgroundColor: "#002f3b" }}>
                    <FontAwesomeIcon color="white" size="1x" icon={['fas', 'user-injured']}/>
                  </div>
                </Popover>
              </Marker>
            );
          }) : null}
          
          
          {/* clustering attempt */}
          
          
          {/* clustering facilities attempt */}
          
          
          {facilities.visible && !visibleSchedule ? facilitiesCluster?.clusters?.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount
            } = cluster.properties;
            
            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  latitude={latitude}
                  longitude={longitude}
                >
                  <div
                    className="cluster-marker-facilites"
                    style={{
                      width: `${40 + (pointCount / hospices.length) * 20}px`,
                      height: `${40 + (pointCount / hospices.length) * 20}px`
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        facilitiesCluster?.supercluster?.getClusterExpansionZoom(cluster.id),
                        30
                      );
                      
                      setViewport({
                        ...viewport,
                        latitude,
                        longitude,
                        zoom: expansionZoom,
                        transitionDuration: 2500,
                        transitionInterpolator: new FlyToInterpolator(), transitionEasing: easeCubic
                      });
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }
            
            return (
              <Marker
                key={`faciity-${cluster.properties.id}`}
                latitude={latitude}
                longitude={longitude}
                // onClick={() => handlevisiblePop(cluster.properties)}
              >
                <Popover content={<FacilityCard facility={cluster.properties}/>} title={cluster.properties.name} trigger="click">
                  <div className="icon-circle" style={{ background: "#EF8100" }}>
                    <FontAwesomeIcon size="1x" color="white" icon={['fas', 'star-of-life']}/>
                  </div>
                </Popover>
              </Marker>
            );
          }) : null}
          
          
          {/* clustering facilities attempt */}



          {/* clustering consultants attempt */}

          {consultants.visible && !visibleSchedule ? ConsultantsCluster?.clusters?.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount
            } = cluster.properties;
          
            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  latitude={latitude}
                  longitude={longitude}
                >
                  <div
                    className="cluster-marker-consultants"
                    style={{
                      width: `${40 + (pointCount / wccs.length) * 20}px`,
                      height: `${40 + (pointCount / wccs.length) * 20}px`
                    }}
                    
                    onClick={() => {
                      const expansionZoom = Math.min(
                        ConsultantsCluster?.supercluster?.getClusterExpansionZoom(cluster.id),
                        30
                      );
                    
                      setViewport({
                        ...viewport,
                        latitude,
                        longitude,
                        zoom: expansionZoom,
                        transitionDuration: 2500,
                        transitionInterpolator: new FlyToInterpolator(), transitionEasing: easeCubic
                      });
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }
          
            return (
            <Fragment key={`consultant-${cluster.properties.id}`}>
              {consultMarker(latitude,longitude ,cluster.properties)}
              {/* <Source type="geojson" data={genOperationRadius(consultant.latlng, consultant.radius)}>
                  <Layer {...dataLayer} />
                </Source> */}
            </Fragment>
            
            
            );
          }) : null}

          {/* clustering consultants attempt */}
          
          
          {viewport.zoom <= 30 && consultantSchedule.length && visibleSchedule ? consultantSchedule.map(item => {
            // let pt = `${item.latlng[0]},${item.latlng[1]};`
            return (<Marker
              key={item.id}
              latitude={parseFloat(item.latlng[1])}
              longitude={parseFloat(item.latlng[0])}>
              
              <Popover content={<AppointmentPopover appointment={item} status={item.status}/>} title="Appointment"
                       trigger="click">
                <PatientMarker color={status_colors[item.status] || "#4B9BFF"}>
                  <img src={require('../../assets/Icon-patient.png')} alt="patient"/>
                
                </PatientMarker>
              </Popover>
            </Marker>)
          }) : null}
          
          
          {routing && viewport.zoom <= 30 && consultantSchedule.length && visibleSchedule ?
            <Source type="geojson" data={routing}>
              <Layer {...route_layer} />
            </Source> : null}
          
          
          <NavigationControl style={navControlStyle}/>
        </MapGL>
        
      </div>
      
      <div className={' bg-amber-500'} style={{ bottom: 32, position: 'absolute', right: 100 }}>
        {containedButton(() => navigate('/routemap'), 'Open route map', false, null, null, { icon: <CompassOutlined /> })}
      </div>
    </div>
  </div>)
}
const mapStateToProps = (state) => ({
  consultants: state.map.consultants,
  patients: state.map.patients,
  facilities: state.map.facilities,
  consultantSchedule: state.map.consultantSchedule,
  visibleSchedule: state.map.visibleSchedule,
  selectedConslultant: state.map.selectedConslultant,
  activeUser: state.auth?.activeUser
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Map);
