import { map } from "lodash";

export const appointments = {
  appointments: [],
  encounters: [],
  schedule: [],
  drafts: [],
  admissions: [],
  selectedAppointment: null,
  selectedAppointmentPatient: null,
  timeBlockers: [],
  toastId: null,
  procedures: [],
  scheduleMode: 'list',
  scheduleLoading: false,
  scheduledAppointments: [],
  pagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  loading: false,
  assessment_form_loading: true,
  appointmentsPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  schedulePagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  admissionsPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  pendingSchedulePagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  timeblockersPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  encountersPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  draftsPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  treatmentSupplies: [],
  treatmentScenarios: [],
  planOfCareItems: [],
  filterProfiles: [],
  notifiedStakeholders: false,
  assessmentNotificationOpen: false,
  selectedAssessmentEncounter: null,
  pendingSchedule: [],
  selectedAdmission: null,
  selectedPendingSchedule: null,
  appointmentNotes:[]
};

export const organisations = {
  organisations: [],
  orgFacilities: [],
  organizationPermissions: [],
  orgUsers: [],
  orgPermGroups: [],
  selectedOrganisation: null,
  organisationsPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  orgFacilitiesPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  orgUsersPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  orgPermPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] }
};
export const orders = {
  orders: [],
  orderNotes: [],
  notesPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  ordersPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  bioOrdersPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  dmeOrdersPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  internalOrdersPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  finalOrdersPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  historyOrdersPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },

  selectedOrder: null,
  orderTracking: null,
  orderTrackingLoading: false,
  can_update_order_status: false,
};


// tasks
export const tasks = {
  tasks: [],
  selectedTask: null,
  loading: false,
  taskPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  taskTypes: [],
  taskStatuses: [],
  taskPriorities: [],
  taskCategories: [],
  taskSubCategories: [],
  taskAssignees: [],
  taskAssigneesLoading: false,
  taskAssigneesPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  taskAssigneesFilter: null,
  taskAssigneesFilterLoading: false,
  taskAssigneesFilterPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  taskAssigneesFilterList: [],
  taskAssigneesFilterListLoading: false,
  taskAssigneesFilterListPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  taskAssigneesFilterListFilter: null,
  comments: [],
  ViewTaskOpen: false
}

export const statistics = {
  statistics: [],
  scheduleStatistics: [],
  loadingStatistics: false,
  loadingSchedule: false,
  loadingPatients: false,
  consultants: null,
  patients: null,
  facilities: null,
  patientLocation: null,
  statisticsPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  selectedStatistic: null

}
export const chat = {
  videoIsOn: false,
  userName: null,
  password: null,
  selectedChat: null,
  incomingCall: null,
  chatList: [],
  singleChatMessages: [],
  groupchatChatMessages: [],
  channelName: null,
  isConnected: false,
  token: null
};
export const appWindow = {
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  xxl: false
};
export const configState = {
  prospectFilters: [false, undefined, false],
  fpatientFilters: [false, undefined, false], //First index; isFilterOn, second; filters, third; isFilterLoading
  fprospectFilters: [false, undefined, false],
  allergyFilters: [false, undefined, false],
  immunizationFilters: [false, undefined, false],
  patientDocFilters: [false, undefined, false],
  patientConsentFilters: [false, undefined, false],
  appointmentFilters: [false, undefined, false],
  organisationFilters: [false, undefined, false],
  encounterListFilters: [false, undefined, false],
  draftListFilters: [false, undefined, false],
  facilitiesFilters: [false, undefined, false],
  biologicProductsFilters: [false, undefined, false],
  orgFacilitiesFilters: [false, undefined, false],
  orgUsersFilters: [false, undefined, false],
  orgPermFilters: [false, undefined, false],
  consultantsFilters: [false, undefined, false],
  consultantMileagesFilters: [false, undefined, false],
  medicationFilters: [false, undefined, false],
  notesFilters: [false, undefined, false],
  patientAppointmentsFilters: [false, undefined, false],
  patientWoundsFilters: [false, undefined, false],
  patientDiagnosisFilters: [false, undefined, false],
  duplicateWoundsFilters: [false, undefined, false],
  taskFilters: [false, undefined, false],
  patientUpdateFilters: [false, undefined, false],
  patientOpenWoundsFilters: [false, undefined, false],
  patientEncounterListFilters: [false, undefined, false],
  patientAssessmentFilters: [false, undefined, false],
  planOfCareFilters: [false, undefined, false],
  patientOrdersFilters: [false, undefined, false],
  prescriptionsFilters: [false, undefined, false],
  consultantOrdersFilters: [false, undefined, false],
  preOrderFilters: [false, undefined, false],
  dmeOrderFilters: [false, undefined, false],
  biologicOrderFilters: [false, undefined, false],
  finalOrderFilters: [false, undefined, false],
  patientDmeOrdersFilters: [false, undefined, false],
  patientBiologicsOrdersFilters: [false, undefined, false],
  internalOrderFilters: [false, undefined, false],
  orderHistoryFilters: [false, undefined, false],
  scheduleFilters: [false, undefined, false],
  patientFilters: [false, undefined, false],
  archivedPatientFilters: [false, undefined, false],
  farchivedPatientFilters: [false, undefined, false],
  auditResourcesFilters: [false, undefined, false],
  insuranceProvidersFilters: [false, undefined, false],
  billingEntitiesFilters: [false, undefined, false],
  ppeKitsFilters: [false, undefined, false],
  wpRegionsFilters: [false, undefined, false],
  wpSubRegionsFilters: [false, undefined, false],
  wpStatesFilters: [false, undefined, false],
  wpCitiesFilters: [false, undefined, false],
  patientTaskfilters: [false, undefined, false],
  departmentFilters: [false, undefined, false],
  departmentUserFilters: [false, undefined, false],
  submittedBillingFilters: [false, undefined, false],
  pendingBillingFilters: [false, undefined, false],
  readyBillingFilters: [false, undefined, false],
  admissionFilters: [false, undefined, false],
  pendingScheduleFilters: [false, undefined, false],
  MQTTSetupState: false,
  consultantsAssigmentFilters: [false, undefined, false],
  CPTCodesFilters: [false, undefined, false],
};
export const masters = {
  openSettingsPath: ['Start Assessment', 'Assessments', 'Master'],
  masterList: [],
  billingEntities: [],
  CPTCodes: [],
  masterListRaw: [],
  loading: false,
  location: null,
  billingRegions: [],
  biologicProducts: [],
  billingGroups: [],
  loadingBillingGroups: false,
  insuranceProviders: [],
  medicalDirectors: [],
  stateBoundaries: [],
  availableSignatoryStates: [],
  wpRegions: [],
  wpSubRegions: [],
  wpStates: [],
  wpCities: [],
  ppeKits: [],
  insuranceProviderPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  billingEntityPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  ppeKitsPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  wpRegionsPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  wpSubRegionsPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  wpStatesPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  wpCitiesPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  CPTCodePagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  biologicProductsPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] }
};
export const auditLogs = {
  logs: undefined /*[
    {
      time: '2021-07-11T05:00:00.000000Z', actor: {"id": 1010000000603, "name": "Carla Turk", "email": "cturk_sa200151@rcopia.com", "phone_no": "+12345647589", "role": "WoundCareConsultant"},
      changes: [{name: 'ssn', before: '111', after: '222'}, {name: 'address_1', before: "North Carolina", after: 'South Carolina'}]
    },
    {
      time: '2021-07-10T05:00:00.000000Z', actor: {"id": 1010000000603, "name": "Carla Turk", "email": "cturk_sa200151@rcopia.com", "phone_no": "+12345647589", "role": "WoundCareConsultant"},
      changes: [{name: 'status', before: 'A', after: 'B'}, {name: 'phone_no', before: '1234', after: '4321'}]
    }
  ]*/,
  auditableColumns: {},
  auditResources: [],
  auditables: [],
  auditResourcesPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] }
};
export const notifications = {
  notifications: [],
  rcopianotifications: null,
  drawervisible: false
};
export const consultants = {
  consultants: [],
  mileages: [],
  selectedConsultant: null,
  prospects: [],
  vehicles: [],
  offices: [],
  selectedProspect: null,
  selectedConsultantDetails: {
    schedule: [],
    availabilityTimes: [],
    vehicles: []
  },
  consultantStateLicences:[],
  pagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  consultantsPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  mileagesPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  consultantSchedule: [],
  consultantVehicles: [],
  consultantOrders: [],
  consultantOrdersPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  selectedProvider: null,
  stateSignatories: null,
  consultantsAssigmentPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  consultantAssignments: [],
};
export const facilities = {
  facilities: [],
  patients: [],
  prospects: [],
  patientUpdates: [],
  FacilityLoading: false,
  allowedFacilities: [],
  selectedFacility: null,
  toastId: null,
  facilitiesPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  facilityPatientPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  facilityProspectPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  patientUpdatePagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },

  facilityStats: null,
  facilityPatients: null,
  facilityProspects: null,
  facilityAppointments: null,

  facilityPatientUpdatePagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },

  loadingFacilityPatients: false,
  loadingFacilityProspects: false,
  loadingFacilityAppointments: false,
  loadingFacilityNotes: false,

  totalProspects: null,
  totalAppointments: null, // should be an object
  totalPatients: null,
  loading: false,
  facilityNotes: null
};
export const mapState = {
  regions: [],
  scheduledAppointments: [],
  consultantSchedule: [],
  selectedConslultant: null,
  consultantDistance: 0,
  consultants: {
    visible: true,
    data: [],
    count: null
  },
  patients: {
    visible: true,
    data: [],
    count: null
  },
  facilities: {
    visible: true,
    data: [],
    count: null
  },
  alerts: {
    visible: true,
    data: [],
    count: null
  },
  pinnedConsultants: [],
  visibleSchedule: false,
  consultantTrips: [],
  tripConsultant: null,
//      { name: 'State Office', color: '#ff73c3' },
// { name: 'Lab Offices', color: '#ff5f5f' },
// { name: 'DME warehouses', color: '#4b9bff' },
  // { name: 'WP owned properties', color: '#af78ff' }
  state_offices: {
    visible: true,
    data: [],
    count: null
  },
  labs: {
    visible: true,
    data: [],
    count: null
  },
  warehouses: {
    visible: true,
    data: [],
    count: null
  },
 others: {
    visible: true,
    data: [],
    count: null
  },
  macs: {
    visible: true,
    data: [],
    count: null
  },
  sales_regions: {
    visible: true,
    data: [],
    count: null
  },
  sales_territories: {
    visible: true,
    data: [],
    count: null
  },
  zonalSelectionMenuVisible: 'Medicare MACS area',


};
export const navs = {
  patientCardNavs: { curMainTab: 'Dashboard' },
  orgNavs: 'Facilities',
  patientDetailsOwner: 0,
  allergiesLoaded: 0,
  medicationsLoaded: 0,
  vitalsLoaded: 0,
  vitalsLoading: false,
  insuranceLoaded: 0,
  insuranceLoading: false,
  shippingAddressesLoaded: 0,
  shippingAddressesLoading: false,
  adContactsLoaded: 0,
  adContactsLoading: false,
  patientAppointmentsLoaded: 0,
  woundsLoaded: 0,
  patientDetailsLoading: false,
  patientsLoading: false,
  archivedPatientsLoading: false,
  farchivedPatientsLoading: false,
  fPatientsAndProspectsLoaded: false, // For facilities
  fPatientsLoading: false, // For facilities
  fProspectsLoading: false, // For facilities
  facilitiesLoaded: false,
  consultantsLoaded: false,
  appointmentsLoaded: false,
  encountersLoaded: false,
  draftsLoaded: false,
  patientNavs: {
    firstTab: 'Dashboard',
    encounterTab: 'All',
    healthTab: 'Allergies',
    patientsTab: 'Patients',
    openChart: 'Measures'
  },
  appointmentNavs: { tab: 'Appointments' },
  facilityHomeNavs: { tab: 'Facilities' },
  consultantHomeNavs: { tab: 'Consultants' },
  roleNavs: 'Roles',
  facilityWoundNavs: { firstTab: 'Wound Track' },
  facilityNavs: { tab: 'information' }, //can either be information or stats
  consultantNavs: { tab: 'Summary' },
  selectedAppointmentID: 0,
  orderNavs: { tab: 'preorders' },
  selectedConsultantID: 0,
  selectedFacilityID: 0,
  taskNavs: { tab: 'list' },
  billingNavs: { tab: 'Pending review' },
  mapNavs: { tab: 'Live Map' },
};

export const settings = {
  settings: null,
  loadingSettings: false
}

export const patients = {
  patients: [],
  selectedPatient: null,
  prospects: [],
  selectedProspect: null,
  prospectNotificationOpen: false,
  patientNotificationOpen: false,
  patientAppointments: [],
  patientOrders: [],
  patientOrdersPagination: [],
  patientTasks: [],
  patientTasksPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  bioOrdersPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  dmeOrdersPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  patientEncounters: {
    All: [],
    DME: [],
    'Home healthcare': [],
    'Wound Debridement': [],
    Biologics: [],
    Podiatry: [],
    Vascular: [],
    Lab: [],
    Prescriptions: []
  },
  woundEncounters: [],
  woundPlansOfCare: [],
  patientDocuments: [],
  patientWounds: [],
  duplicateWounds: [],
  patientOpenWounds: [],
  selectedWound: null,
  selectedEncounter: null,
  patientProgressNotes: [],
  patientStickyNotes: [],
  vitals: null,
  shippingAddresses: [],

  insurances: [],
  woundsLoading: false,
  healthData: { allergies: null, immunizations: [], medication: null, diagnosis: null, prescriptions: null, socialHistory: null, familyHistory: null },
  pagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  prospectPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  patientWoundsPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  patientDiagnosisPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  duplicateWoundsPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  patientOpenWoundsPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  patientAppointmentPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  patientEncountersPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  allergiesPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  immunizationPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  patientDocPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  patientConsentPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  medicationPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  prescriptionsPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  archivedPatientPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  farchivedPatientPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  notesPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  stickynotesPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  tasks: [],
  selectedTask: null,
  viewTaskNotification: false,
  comments: [],
  patientConsentForms: []
};
export const authState = {
  activeUser: null,
  toastId: null,
  passwordReset: false,
  loadingUser: false,
  permissions: [],
  agoraToken: null,
  dynamicLink: null
};

export const departments = {
  departments: [],
  selectedDepartment: null,
  users: [],
  departmentUsersPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  pagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  loading: false
};
export const billing = {
  billingEncounters: [],
  selectedEncounter: {
    "id": 1100000002762,
    "appointment_id": 1090000001776,
    "procedure": "DME",
    "wound_id": 1070000001634,
    "image_id": 5000000039226,
    "original_image": {
      "id": 5000000039226,
      "mime_type": "image/jpeg",
      "annotation": null,
      "name": "20211122_131959 (2).jpg",
      "url": "https://woundpros-staging.s3.us-west-1.amazonaws.com/1010000000222/2023-06-12/GMeoAPBX3yX0eRUZQnCyhwtoMbur2Ut3IQe8a3n8.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQTDA44BQ45Z7RENM%2F20230614%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230614T121242Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Signature=238cd08eb465f2d1c60ccfc35c1b06d2b29fbbf4a5d033fc6fbbc6f09a108b95"
    },
    "masked_image_id": 5000000039227,
    "wound_location_file_id": 5000000039225,
    "treatment_number": null,
    "area": 3.82,
    "odor": "None",
    "size": "Large",
    "depth": 2,
    "stage": "Stage III",
    "width": 2,
    "length": 1.91,
    "status": "Improving",
    "volume": 7.64,
    "location": "Arm",
    "infection": "Yes",
    "new_depth": 0,
    "new_width": 0,
    "wound_bed": [
      "Hypergranulation Tissue"
    ],
    "new_length": 0,
    "pain_level": "None",
    "wound_side": "Left",
    "wound_type": "Venous Stasis Ulceration",
    "extra_notes": "None",
    "sinus_tract": 0,
    "undermining": "0",
    "wound_edges": "Distinct",
    "exudate_type": "None",
    "eschar_tissue": 0,
    "slough_tissue": 0,
    "visit_summary": "A doctor’s note, also known as a medical excuse note or doctor’s excuse letter, is often required by employers and educational institutions for individuals who need to miss work or school for medical reasons. A medical note is considered an official medical certificate that documents the presence of an illness or injury and authorizes the individual to take time off to recover. Our free doctor note templates allow you to quickly create a doctor form that can serve as an absence authorization.",
    "exudate_amount": "Moderate",
    "fibrous_tissue": 49.76,
    "tunneling_size": 0,
    "wound_duration": "Less than 30 days",
    "wound_position": "Anterior",
    "necrotic_tissue": 0,
    "periwound_color": "Normal ethnic",
    "wound_condition": "is improving",
    "debridement_type": [
      "N/A"
    ],
    "epithelialization": "Surface intact",
    "exposed_structures": "Tendon / Ligament",
    "granulation_tissue": 50.24,
    "other_interventions": [
      "Family conference"
    ],
    "response_to_therapy": "is responding",
    "tunneling_direction": "1 o'clock",
    "other_related_factors": [
      "Age"
    ],
    "conservative_treatment": [
      "compression therapy"
    ],
    "recommended_treatment_protocol": "17HHMODERATE DRAINAGE / VENOUS ULCER - LARGE",
    "woundImage": {
      "id": 5000000039227,
      "mime_type": "image/jpeg",
      "annotation": null,
      "name": "20211122_131959 (2).jpg",
      "url": "https://woundpro-ds.s3.us-west-1.amazonaws.com/664b5cc7-e45c-4a0e-918c-9b65c7f5e129.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQTDA44BQ45Z7RENM%2F20230614%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230614T121242Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Signature=5673825ff1dd27852bf21afff1fc91d5f19298eea28d58018f9a93abe6e4a545"
    },
    "woundLocationPath": {
      "id": 5000000039225,
      "mime_type": "image/svg+xml",
      "annotation": null,
      "name": "filename.svg",
      "url": "https://woundpros-staging.s3.us-west-1.amazonaws.com/1010000000222/2023-06-12/jbwTiEsMX6Yn4lBuVUIj5ABAQgL1GRYjssltJx1N.svg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQTDA44BQ45Z7RENM%2F20230614%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230614T121242Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Signature=afbade84d6d3ade927f74a8ee01c44fe5a0e10ea34b14ba837d60d99563fecaa"
    },
    "appointment": {
      "id": 1090000001776,
      "patient": {
        "id": 1020000000807,
        "user_id": 1010000001622,
        "name": "Vicky J",
        "email": "j@email.com",
        "medical_facility": "Karen",
        "phone_no": "+14804141794",
        "address_1": "12333 Exline Street, El Monte, California 91732, United States",
        "address_2": null,
        "state": "California",
        "lat": 34.06473,
        "lng": -118.00966699999998
      },
      "consultant": {
        "id": 1030000000382,
        "name": "Jack Dorsey",
        "user_id": 1010000001624,
        "email": "jack@email.com",
        "address_1": "213 Twin Oaks Avenue, Chula Vista, California 91910, United States",
        "address_2": null,
        "profile_image_url": null,
        "lat": 32.64519,
        "lng": -117.07767999999999
      },
      "status": "scheduled",
      "start_at": "2023-06-19 00:00:00",
      "end_at": "2023-06-19 00:15:00",
      "appointment_visit_type": "established visit",
      "telehealth_appointment": false,
      "call_link": null,
      "call_recording_link": null,
      "visit_reason": "Test",
      "recurring_appointments": [],
      "remaining_repeats": []
    },
    "billing_group": null,
    "plan_of_care_items": [
      {
        "id": 782,
        "step": "Cover with non-bordered foam 3x weekly",
        "scenario_number": "17HH",
        "treatment_code": null,
        "type": "Secondary Dressing",
        "step_number": "3",
        "step_number_order": 6,
        "kit_type": "Normal"
      },
      {
        "id": 783,
        "step": "Pat dry with gauze",
        "scenario_number": "17HH",
        "treatment_code": null,
        "type": "Primary Dressing",
        "step_number": "1B",
        "step_number_order": 3,
        "kit_type": "Normal"
      },
      {
        "id": 784,
        "step": "Cleanse with wound cleanser",
        "scenario_number": "17HH",
        "treatment_code": null,
        "type": "Primary Dressing",
        "step_number": "1A",
        "step_number_order": 2,
        "kit_type": "Normal"
      },
      {
        "id": 785,
        "step": "Secure with tape",
        "scenario_number": "17HH",
        "treatment_code": null,
        "type": "Primary Dressing",
        "step_number": "4B",
        "step_number_order": 11,
        "kit_type": "Normal"
      },
      {
        "id": 786,
        "step": "Wrap leg with roll gauze 3x weekly and as needed",
        "scenario_number": "17HH",
        "treatment_code": null,
        "type": "Secondary Dressing",
        "step_number": "4A",
        "step_number_order": 10,
        "kit_type": "Normal"
      }
    ],
    "treatment_order_items": [
      {
        "id": 37,
        "name": "A6211 Allevyn Life Non Bordered 8.3\"8.3\"",
        "unit_of_measure": "Pack",
        "default_pack_amount": 12,
        "default_unit_count": 1,
        "unit_count": 1,
        "pack_amount": 12,
        "type": "Secondary Dressing",
        "kit_type": "Normal",
        "is_available": true
      },
      {
        "id": 7,
        "name": "Gauze Sponge 4\"x4\", 8 ply",
        "unit_of_measure": "Pack",
        "default_pack_amount": 2,
        "default_unit_count": 1,
        "unit_count": 1,
        "pack_amount": 2,
        "type": "Secondary Dressing",
        "kit_type": "Normal",
        "is_available": true
      },
      {
        "id": 2,
        "name": "Dermal Wound Cleanser",
        "unit_of_measure": "Bottle(s)",
        "default_pack_amount": 1,
        "default_unit_count": 1,
        "unit_count": 1,
        "pack_amount": 1,
        "type": "Secondary Dressing",
        "kit_type": "Normal",
        "is_available": true
      },
      {
        "id": 15,
        "name": "Primafix Plus 4\"x10yds",
        "unit_of_measure": "Box",
        "default_pack_amount": 1,
        "default_unit_count": 1,
        "unit_count": 1,
        "pack_amount": 1,
        "type": "Secondary Dressing",
        "kit_type": "Normal",
        "is_available": true
      },
      {
        "id": 29,
        "name": "A6446 Bulk Roll Gauze",
        "unit_of_measure": "Pack",
        "default_pack_amount": 8,
        "default_unit_count": 1,
        "unit_count": 1,
        "pack_amount": 8,
        "type": "Secondary Dressing",
        "kit_type": "Normal",
        "is_available": true
      }
    ],
    "lab_tests": [],
    "review": {
      "id": null,
      "reviewed_by": null,
      "status": "pending_review",
      "notes": null
    },
    "biologic_kits": [],
    "previous_treatment_number": null,
    "previous_product": null,
    "created_at": "2023-06-12 19:21:23",
    "updated_at": "2023-06-12 19:21:42",
    "published_at": "2023-06-12 00:00:00",
    "previous_evaluation_date": null,
    "state": "published"
  },
  billingPendingPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  billingReadyPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  billingSubmittedPagination: { current: 1, pageSize: 15, showSizeChanger: false, position: ['none', 'bottomCenter'] },
  billingEncountersLoading: false
}


export const reports = {
  reports: [],
}