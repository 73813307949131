/* eslint-disable no-unused-vars */
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Checkbox, Form, Select, Space} from 'antd'
import {fetchSpecificConsultant} from '../../../app/Reducers'
import {Body2, RegularButtonCircle} from '../../../components/general'
import {toast} from 'react-toastify'
import {apiRequest} from '../../../app/Apis'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {addButton, emptyFun} from "../../../common/helpers";

const ConsultantLocations = ({ consultant, handleLocationsCancel }) => {
  const dateFormat = 'YYYY-MM-DD';
  const { Option } = Select;
  const dispatch = useDispatch();
  const [states, setStates] = useState([]);
  useEffect(() => {
    fetchStates();
    return emptyFun
  }, [consultant])
  const fetchStates = async () => {
    const response = await apiRequest.get(`/region-boundaries`).catch(function (err) {
      console.log(err)
    })
    console.log('fetchStates: ', response.data.data)
    if (response && response.data && response.data.data) {
      setStates(response.data.data)
      if (!response.data.data.length) {
        setLocationsError("Sorry there are no mapped out territories for consultant's state")
      }
    }
  }
  const [locationsError, setLocationsError] = useState(null)
  const onFinish = async (values) => {
    if (selectedDefault !== null) {
      setLocationsError(null)
      console.log(values)
      let data = [...values.regions]
      data = data.map((item, index) => ({
        ...item, default: selectedDefault === index
      }))
      const toastId = toast("Updating consultant territories", { type: "info", autoClose: false });
      const statusresponse = await apiRequest.post(`/consultants/${consultant.id}/territories`, { regions: data }).catch(function (err) {
        console.log(err)
        toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
      })
      if (statusresponse && statusresponse.data.data) {
        toast.update(toastId, { render: "Update complete", type: toast.TYPE.SUCCESS, autoClose: 5000 });
        dispatch(fetchSpecificConsultant(consultant.id))
        handleLocationsCancel()
      }
    } else {
      setLocationsError("Please select A default location")
    }
  };
  const [viewport, setViewport] = useState({
    latitude: 37.7577, longitude: -122.4376, zoom: 8
  });
  const [locationData, setLocationData] = useState(null)
  const geocoderContainerRef = useRef();
  const mapRef = useRef();
  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);
  const onResult = useCallback((result) => {
    // console.log(result)
    setLocationData(result.result)
  }, [])
  const switchChange = (e, key) => {
    console.log(key, e)
    if (e.target.checked === true) {
      setselectedDefault(key)
      setLocationsError(null)
    } else {
      setselectedDefault(null)
      setLocationsError("Please select A default location")
    }
  }
  const [selectedDefault, setselectedDefault] = useState(consultant.territories.findIndex(item => item.default_region === true))
  return (<Form
    name="consultantLocationForm"
    id="consultantLocationForm"
    labelCol={{ span: 24 }}
    layout="vertical"
    onFinish={onFinish}
    initialValues={{
      regions: consultant.territories.map(item => {
        return {
          region_id: item.zone_name,
          default: item.default_region
        }
      })
    
}} >
    {locationsError ? <Body2 color="crimson">{locationsError}</Body2> : null}
    <Form.List label="Regions" name="regions">
      {(fields, { add, remove }) => (<>
        {fields.map(({ key, name, fieldKey, ...restField }) => (
          <Space key={key} style={{ display: 'flex', marginBottom: 8, alignItems: 'center' }} align="center">
            <Form.Item
              style={{ flex: 1 }}
              label="Territory"
              {...restField}
              name={[name, 'region_id']}
              rules={[{ required: true, message: 'Missing territory' }]}
              {...restField}
              fieldKey={[fieldKey, 'region']}>
              <Select popupMatchSelectWidth={false}
                      style={{ width: 350 }}
              >
                {states?.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
              </Select>
            </Form.Item>
            <Form.Item style={{ flex: 1 }}
                       label="Default"
                       name={[name, 'default']}
                      //  valuePropName="checked"
                       rules={[{ required: false, message: 'Missing territory' }]}
                       {...restField}
                       fieldKey={[fieldKey, 'default']}>
              <Checkbox checked={key === selectedDefault} onChange={(e) => {
                switchChange(e, key)
              }}/>
            </Form.Item>
            <RegularButtonCircle borderColor="grey" color="transparent" onClick={() => remove(name)} height="30px">
              <FontAwesomeIcon color="grey" icon={["fas", "times"]}/>
            </RegularButtonCircle>
          </Space>))}
        <Form.Item>
          {addButton(() => add(), 'grey')}
        
        </Form.Item>
      </>)}
    </Form.List>
  </Form>)
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ConsultantLocations)
