import React, {useEffect, useState} from 'react';
import {apiRequest} from '../../app/Apis';
import {appLog, createMenus, emptyFun, emptyTable, makeAutoCompleteOptions, skelBars} from '../../common/helpers';
import {AutoComplete, Menu} from 'antd';
import {errMsg, toastUp} from '../../app/Reducers/reducerUtils';
import TeamCard from './TeamCard';

const sampleTeams = require('./sampleTeams.json')
const TeamList = () => {
  const [teams, setTeams] = useState([])
  const [roles, setRoles] = useState([])
  const [teamOptions, setTeamOptions] = useState([])
  const [selectedTeam, setSelectedTeam] = useState(null)
  const [permissions, setPermissions] = useState([])
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState(null)
  useEffect(() => {
    fetchRoles().catch(appLog)
    fetchTeams().catch(appLog)
    return emptyFun
  }, []);
  useEffect(() => {
    setSelectedTeam(teams[0])
    let menuOptions = createMenus(teams.map(item => item.name))
    setTeamOptions(menuOptions)
    return emptyFun
  }, [teams]);
  const fetchTeams = async () => {
    const { data } = await apiRequest.get('/teams').catch(function (err) {
      console.log(err)
      toastUp(errMsg(err), false)
    })
    let teams = data.data
    if (teams[0]) {
      await setTeams(teams)
    } else {
      await setTeams(sampleTeams)
    }
  }
  const fetchRoles = async () => {
    await setLoading(true)
    const { data: permissions } = await apiRequest.get('/permissions').catch(function (err) {
      console.log(err)
      toastUp(errMsg(err), false)
    })
    if (permissions?.data) {
      await setPermissions(permissions.data)
    }
    const { data } = await apiRequest.get('/roles').catch(err => {
      console.log(err)
      toastUp(errMsg(err), false)
    })
    if (data?.data) {
      let roles = data.data.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
      await setRoles(roles)
    }
    await setLoading(false)
  }
  
  return (
    <div className={'gap-4 flex flex-col bg-gray-200 p-4 h-[100%]'}>
      {/*<div className={'flexy-row flex-nowrap'}><Input placeholder={'Search permission'}/></div>*/}
      <div className={'gap-4 flex flex-row overflow-auto overscroll-contain flex-1 mb-8 h-full'}>
        <div style={{ width: 256 }} className={'flex flex-col gap-2 mb-4'}>
          <AutoComplete popupMatchSelectWidth={false}
                        onChange={(value, option) => {
                          setFilter(value)
                        }}
                        value={filter}
                        options={makeAutoCompleteOptions(['Team1'])}
                        placeholder={'Filter teams'}
                        onSelect={(value, option) => {
                          let team = teams.find(item => item.name === value)
                          setSelectedTeam(team)
                        }}
                        rootClassName={'my-1.5'}
                        filterOption={true}
                        allowClear
            // value={filter}
          />
          {!loading ? <div style={{ width: 256 }} className={'overflow-y-auto overscroll-contain flex-1 pb-4'}>
            {teamOptions[0] ? <Menu style={{ width: '100%', height: '100%' }}
                                  selectable
                                  selectedKeys={[selectedTeam?.name]}
                                  onClick={(item, key, keyPath, domEvent) => {
                                    let team = teams.find(value => value.name === item.key)
                                    setSelectedTeam(team)
                                    setFilter(null)
                                  }}
                                  mode="vertical"
                                  items={teamOptions}/> : emptyTable()}
          </div> : skelBars()}
        </div>
        <div className={'mb-4 w-full overflow-y-hidden'}><TeamCard roles={roles} team={selectedTeam} permissions={permissions} loading={loading}/></div>
      
      </div>
    </div>
  );
};

export default TeamList;