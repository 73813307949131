import React from 'react'
import {connect} from 'react-redux'
import HeaderTop from '../../../components/HeaderTop'
import {getHeaderLeftNav} from "../../../common/helpers";
import { useLocation, useNavigate} from "react-router-dom";
import AppointmentPage from './AppointmentDetailsPage';
import AppointmentPatientInfoPane from './AppointmentPatientInfoPane';
import {Col, Grid, Row} from 'antd';
import PatientInfoPaneCommon from '../../../components/PatientInfoPaneCommon';

const { useBreakpoint } = Grid;
const AppointmentHome = ({ appointment, activeUser, navs, masterList }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState = useBreakpoint();

  return (
    (<div className="full-page flex flex-col bg-gray-200">
      <HeaderTop child={getHeaderLeftNav(navigate, 'Appointment')}/>
      <Row className={'h-full'} gutter={[6,0]}>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <div className=" h-full">
            {/* <AppointmentPatientInfoPane setDetailsForm={null} patient={appointment?.patient}/> */}
            <PatientInfoPaneCommon patientProp={appointment?.patient} />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={18} xl={18} className={'h-full'}>
          <AppointmentPage/>
        </Col>
      </Row>
    </div>)
  );
}

const mapStateToProps = (state) => ({
  appointment: state.appointments.selectedAppointment,
  organisation: state.organisations.selectedOrganisation,
  organizationPermissions: state.organisations.organizationPermissions,
  activeUser: state.auth?.activeUser,
  navs: state.navs,
  masterList: state.masters.masterListRaw
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentHome)