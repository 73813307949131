import React, { useEffect, useState } from 'react';
import { Modal, Input } from 'antd';
import CustomReportPane from './Components/CustomReportPane';
import { connect, useDispatch } from 'react-redux';
import {
  dialogCloser,
  modalClose,
  outlinedButton,
  horStack,
  formSubmitButton,
} from '../../common/helpers';
import UpdateCustomReport from './Components/UpdateReports';

const AssessmentReport = ({ title, reports }) => {
  const [data, setData] = useState({});
  const [updateReport, setUpdateReport] = useState(false);
  const [isExecuting, setisExecuting] = useState(false);

  useEffect(() => {
    const res = reports.reports.find(
      (e) => e.report_type === title.replace(/ /g, '').toLowerCase()
    );
    setData(res);
    return ()=>{};
  }, [title]);
  return (
    <div>
      <Modal
        title='Update Report'
        visible={updateReport}
        onOk={dialogCloser(setUpdateReport)}
        destroyOnClose={true}
        width={600}
        onCancel={dialogCloser(setUpdateReport)}
        closeIcon={modalClose(dialogCloser(setUpdateReport))}
        maskClosable={false}
        footer={[
          <div
            className='entity-filters flex-boy-row'
            style={{ justifyContent: 'flex-end' }}
          >
            {horStack([
              outlinedButton(
                dialogCloser(setUpdateReport),
                'Cancel',
                isExecuting
              ),
              [
                formSubmitButton(
                  'updateReport',
                  'Update',
                  isExecuting,
                  'Updating'
                ),
              ],
            ])}
          </div>,
        ]}
      >
        <UpdateCustomReport
          setisExecuting={setisExecuting}
          closeModal={dialogCloser(setUpdateReport)}
          title={title} 
        />
      </Modal>
      <CustomReportPane title={title} setUpdateReport={setUpdateReport} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  reports: state.reports,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(AssessmentReport);

