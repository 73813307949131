/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Subtitle1} from '../../components'
import HeaderTop from '../../components/HeaderTop'
import AppointmentsList from './components/AppointmentsList';
import EncounterList from './components/EncounterList'
import Schedule from './components/Schedule';
import {setAppointmentNavs} from "../../app/Reducers";
import Drafts from './components/Drafts';
import {Tabs} from "antd";
import AddmissionList from './components/AddmissionList';
import PendingScheduleList from './components/PendingScheduleList';
import { usePermissionCheck } from '../../hooks/usePermissionCheck';

const { TabPane } = Tabs;
export const Index = ({ navs, activeUser }) => {
  const dispatch = useDispatch()
  const setActiveTab = async (tab) => {
    await dispatch(setAppointmentNavs({ tab: tab }))
  }
  
  useEffect(() => {
    if (activeUser?.facilityProfile?.id) {
      setActiveTab('Schedule')
    }
  }, [activeUser])
  // const can_view_admission = usePermissionCheck('view_admission', false, [])
  // const can_view_schedule = usePermissionCheck('view_pre_scheduled', false, [])

  const TabList = activeUser?.facilityProfile
    ? ['Schedule', 'Encounter List']
    :  [
      // can_view_admission && 'Admission',
      // can_view_schedule && 'Pending Schedule',
      'Appointments',
      'Schedule',
      'Encounter List',
      'Drafts',
    ].filter(Boolean);
  const Tabs_ = () => {
    return (<Tabs defaultActiveKey={navs.appointmentNavs.tab} onChange={setActiveTab} tabBarStyle={{ marginBottom: 0 }}>
      {TabList.map(item => <TabPane key={item} tab={<Subtitle1>{item}</Subtitle1>}>
      </TabPane>)}
    </Tabs>)
  }
  return (<div className="main-page ">
    <HeaderTop child={<Tabs_/>} customStyle={{ style: { paddingBottom: 0 } }}/>
    {navs.appointmentNavs.tab === 'Appointments' ? <AppointmentsList/> : navs.appointmentNavs.tab === 'Schedule' ?
      <Schedule /> : navs.appointmentNavs.tab === "Drafts" ? <Drafts /> : navs.appointmentNavs.tab === "Admission" ? <AddmissionList /> : navs.appointmentNavs.tab === "Pending Schedule" ? <PendingScheduleList/> :<EncounterList/>}
  </div>)
}

const mapStateToProps = (state) => ({  navs: state.navs, activeUser: state.auth?.activeUser
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Index)

