import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Avatar, Button, Divider, Dropdown, Grid, Menu, Modal, Popover, Spin, Table, Tag} from "antd"
import {Subtitle1} from '../../../components'
import {
  appLog,
  dialogCloser,
  emptyFun,
  emptyOrders,
  formSubmitButton,
  getOrderStatus, GetRawListOptions,
  getSkels,
  horStack,
  keyValItemOrders,
  makeAutoCompleteOptions,
  makeSelectOptions,
  modalClose,
  onTableChange,
  outlinedButton,
  responsiveTbl,
  secondaryButton,
  stateFilter
} from "../../../common/helpers";
import CreateOrder from './OrderForms/CreateOrder';
import {bulkUpdateOrderStatus, canUpdateOrderStatus, fetchOrders, resetBioOrdersPagination, selectOrder, updateBiologicOrderFilters} from '../../../app/Reducers';
import TableFilters from "../../../components/InputComponents/TableFilters";
import dayjs from 'dayjs';


import {CaretDownOutlined, EditOutlined, FieldTimeOutlined, LoadingOutlined} from '@ant-design/icons';
import ReportForm from './OrderForms/ReportForm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {apiRequest} from '../../../app/Apis';
import {getOrderFilterStatuses} from "../../../app/Apis/commonApi";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import _ from "lodash"
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useWoundLocationSearch} from '../../../hooks/useWoundLocationSearch';
import {useLocation, useNavigate} from 'react-router-dom';

var isoWeek = require('dayjs/plugin/isoWeek')
dayjs.extend(isoWeek)
const { useBreakpoint } = Grid;
const BiologicsList = ({ bioOrdersPagination, appConf, navs, orders,  activeUser, masterList }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState=useBreakpoint()
  const [isOpen, setOpen] = useState(false)
  const [previewisOpen, setpreviewOpen] = useState(false)
  const dispatch = useDispatch()
  const [isVisible, setIsVisible] = useState(false);
  const [isExecuting, setisExecuting] = useState(false)
  const [orderStatuses, setOrderStatuses] = useState([])
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [order, setOrder] = useState(null);
  
  const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_export_treatment_order = usePermissionCheck('export_treatment_order', false, [])
  const can_update_biologic_order_statusr = usePermissionCheck('update_biologic_order_status', false, [])
  const { woundLocationOptions, debouncedWLocationSearch, woundLocationLoading } = useWoundLocationSearch( [])
  const handleOrderCancel = async () => {
    await loadOrders()
    setOpen(false);
  }
  const handlePreviewOrderCancel = () => setpreviewOpen(false)
  useEffect(() => {
    getStatuses().catch(appLog)
    getOrderFilterStatuses(setOrderStatuses, 'biologics').catch(appLog)
    loadOrders().catch(appLog)
    return emptyFun
  }, [navs])
  
  
  const groupedItems = (data) => {
    const groupedByCompany = _.groupBy(data, 'biologic_product.company_name');
    
    // Get unique sizes for each company
    const uniqueSizes = _.mapValues(groupedByCompany, (items) => _.uniqBy(items, 'size'));
    
    // Add count of sizes to each object
    const result = _.mapValues(uniqueSizes, (items) => {
      return _.map(items, (item) => {
        return { ...item, count: _.filter(groupedByCompany[item.biologic_product.company_name], { 'size': item.size }).length }
      });
    });
    //console.log(result)
    return result
  }
  const [availableStatuses, setavailableStatuses] = useState([])
  const getStatuses = async () => {
    let data = await apiRequest.get(`/treatment-order-statuses?order_state=post-order&internal=false`).catch(e => appLog(e))
    if (data && data.data) {
      setavailableStatuses(data.data.data)
    }
  }
  
  let onCell = (record, rowIndex) => ({
    onClick: event => {
      (async () => {
        await dispatch(selectOrder(record))
        await dispatch(canUpdateOrderStatus(can_update_biologic_order_statusr))
        navigate(`/orders/${record.id}`)
      })().catch(appLog)
    }
  })
  let ReferralOrganizationOptions=makeSelectOptions(GetRawListOptions('referral_organization'))
  const columns = [
    {
      onCell,className:'unbreakable-table-column',
      title: 'Provider', dataIndex: ['consultant', 'name'], key: 'consultant', fltr: { filterable: true, type: 'text' },
      sorter: { multiple: 3, compare: (a, b) => a?.consultant?.name < b?.consultant?.name }
    },
    {
      onCell,className:'unbreakable-table-column',
      title: 'Patient', dataIndex: ['patient', 'name'], key: 'patient', fltr: { filterable: true, type: 'text' },
      sorter: { multiple: 3, compare: (a, b) => a?.patient?.name?.toLowerCase().localeCompare(b?.patient?.name?.toLowerCase()) }
    },
    {
      title: 'Order date', dataIndex: 'created_at', key: 'createdAt', fltr: { filterable: true, type: 'date' }, onCell,
      render: (created_at, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(created_at).format("MM/DD/YYYY")}</Subtitle1>)
    },
    //baseKey is used as form field name on the Filters form while keys attr is used to name filter API parameters
    {
      onCell,
      title: 'DOS', dataIndex: 'date_of_service', key: 'date_of_service',
      fltr: { filterable: true, type: 'date_range', keys: ['dateOfServiceRange[start_at]', 'dateOfServiceRange[end_at]'], baseKey: 'date_of_service_filter' },
      render: (date_of_service, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(date_of_service).format("MM/DD/YYYY")}</Subtitle1>)
    },
    { ...stateFilter, title: 'Patient State', dataIndex: 'state', key: 'state', onCell },
    {
      onCell,
      title: 'Week no', dataIndex: 'date_of_service', key: 'weekNumber', fltr: { filterable: true, type: 'number' },
      render: (date_of_service, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(date_of_service).isoWeek()}</Subtitle1>)
    },
    {
      onCell,className:'unbreakable-table-column',
      title: 'Office', dataIndex: ['patient', 'facility'], key: 'facility', fltr: { filterable: true, type: 'text' }
    },
    
    {
      title: 'Referral Organization', dataIndex: ['patient', 'facility_referral_source'], key: 'facilityReferralSource',
      fltr: {
        filterable: true,
        type: 'select',
        options: ReferralOrganizationOptions
      },
      render: (facility_referral_source, record) => (<Subtitle1 style={{ textAlign: 'left', color: 'black', fontWeight: 'normal' }}>{facility_referral_source || 'N/A'}</Subtitle1>),
      onCell
    },
    {
      title: 'Wound Location', key: 'wound_location',className:'unbreakable-table-column',
      fltr: {
        filterable: true, type: 'autocomplete', props: {
          showSearch: true,
          filterOption: false,
          onSearch: (value) => debouncedWLocationSearch(value),
          defaultActiveFirstOption: false,
          showArrow: true,
          placeholder: 'Search...'
        },
        options: woundLocationOptions
      }, onCell,
      
      
      render: (record) => record.visit_type === "Biologics" ? <Popover style={{ cursor: 'pointer' }}
                                                                       content={<div>
                                                                         {Object.keys(groupedItems(record.biologic_kits)).map(item => {
                                                                             return (<div>
                                                                               {keyValItemOrders('Treatment Number:', `${record.assessment_observations?.treatment_number}`)}
                                                                               
                                                                               {keyValItemOrders('Product Name:', `${item} ${record.biologic_kits[0]?.biologic_product?.product_name}`)}
                                                                               {keyValItemOrders('Wound Size (Area):', `${record.assessment_observations?.area}cm`, "2")}
                                                                               {keyValItemOrders('Recommended Grafts:', ([].concat(Object.values(groupedItems(record.biologic_kits)))[0].map(item => (`${item?.size}(${item.count})`))).toString())}
                                                                               <Divider/>
                                                                             </div>)
                                                                           }
                                                                         )}
                                                                       </div>
                                                                       }
      
      >
        
        <p style={{ cursor: 'pointer' }}>{record.wound_location}</p>
      </Popover> : <p style={{ cursor: 'pointer' }}>{record.wound_location}</p>
    },
    {
      onCell,
      key: 'visitType', title: 'Product Name',className:'unbreakable-table-column', fltr: { filterable: false, type: 'autocomplete', options: makeAutoCompleteOptions(['DME', 'Biologics', 'internal']) },
      render: (record) => <Popover style={{ cursor: 'pointer' }}
                                   content={<div>
                                     {Object.keys(groupedItems(record.biologic_kits)).map(item => {
                                         return (<div>
                                           {keyValItemOrders('Treatment Number:', `${record.assessment_observations?.treatment_number}`)}
                                           
                                           {keyValItemOrders('Product Name:', `${item} ${record.biologic_kits[0]?.biologic_product?.product_name}`)}
                                           {keyValItemOrders('Wound Size (Area):', `${record.assessment_observations?.area}cm`, "2")}
                                           {keyValItemOrders('Recommended Grafts:', ([].concat(Object.values(groupedItems(record.biologic_kits)))[0].map(item => (`${item?.size}(${item.count})`))).toString())}
                                           <Divider/>
                                         </div>)
                                       }
                                     )}
                                   </div>
                                   }
      
      >
        
        <p style={{ cursor: 'pointer' }}>{`${record.biologic_kits[0]?.biologic_product?.company_name} ${record.biologic_kits[0]?.biologic_product?.product_name}`}</p>
      </Popover>
    },
    
    {
      onCell,
      title: 'Status', dataIndex: 'status', key: 'status', fltr: {
        filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(orderStatuses)
      }, render: (status) => getOrderStatus(status?.toLowerCase())
    },
    ...(can_view_audit_logs ?
      [{
        title: '', dataIndex: '', key: 'x', render: (text, record, index) => <Button onClick={async () => {
          await setOrder(record)
          await setShowAuditDialog(true)
        }} type="dashed" icon={<FieldTimeOutlined/>}>Audit</Button>
      }] : []),
    ...(activeUser?.is_qa_user?[{ width:15,
      visible: true,
      title: <FontAwesomeIcon color="grey" icon={['fas', 'table']}/>,
      key: 'operation',
      render: (record) => <Tag className={`${(record.is_qa && activeUser?.is_qa_user)?'visible':'invisible'}`} icon={<EditOutlined/>} color="#55acee">QA</Tag>
    }]:[])
  
  ]
  const loadOrders = async () => {
    await dispatch(updateBiologicOrderFilters([appConf.biologicOrderFilters[0], appConf.biologicOrderFilters[1], true]))
    await dispatch(fetchOrders(appConf.biologicOrderFilters[0] ? { ...appConf.biologicOrderFilters[1] } : { filterData: { visitType: 'Biologics', orderState: "post-order" } }))
    await dispatch(updateBiologicOrderFilters([appConf.biologicOrderFilters[0], appConf.biologicOrderFilters[1], false]))
  }
  const handleFilterAction = async (action, values) => {
    //todo: static data
    await dispatch(resetBioOrdersPagination())
    if (action === 'filter') {
      let pl = { filterData: { ...values, "visitType": 'Biologics', "orderState": "post-order" }, pagination: bioOrdersPagination }
      await dispatch(updateBiologicOrderFilters([false, pl, true]))
      await dispatch(fetchOrders(pl))
      await dispatch(updateBiologicOrderFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updateBiologicOrderFilters([appConf.biologicOrderFilters[0], undefined, true]))
      await dispatch(fetchOrders({ "filterData": { "visitType": 'Biologics', "orderState": "post-order" } }))
      await dispatch(updateBiologicOrderFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys);
  const [selectedRowRecord, setSelectedRowRecord] = useState(null);
  
  const rowSelection = {
    selectedRowKeys, onChange: (selectedRowKeys, selectedRows) => {
      console.log({ selectedRows, selectedRowKeys })
      setSelectedRowKeys(selectedRowKeys);
    }, onSelect: (record, selected, selectedRows, nativeEvent) => {
      console.log({ record, selected, selectedRows, nativeEvent })
      setSelectedRowRecord((selectedRows.length === 1) ? record : null)
      setSelectedRowKeys(selectedRows.map(item => item.id));
    },
    columnWidth:15
  };
  /**
   * This is different from all other filters that use any character sequence to search.
   * Why @param {fullPatientName} is used and page is always reset to 1 in this filter:
   * The full patient name is used when the patient name has an initial, the existing patient filter does not allow to filter when name has initial i.e James K.
   * Recommendation by @Emmanuel Changole
   * Git Commit: WPBTB-4208: Order_when filtering by patient that has an initial in the name, no matching records are shown
   * */
  const loadPatientOrders = async (patient) => {
    let pl = appConf.biologicOrderFilters[0] ? {
        ...appConf.biologicOrderFilters[1],
        filterData: { fullPatientName: patient, ...appConf.biologicOrderFilters[1].filterData },
        pagination: { ...bioOrdersPagination, current: 1 }
      } :
      { filterData: { fullPatientName: patient, "visitType": 'Biologics', "orderState": "post-order" }, pagination: { ...bioOrdersPagination, current: 1 } }
    await dispatch(updateBiologicOrderFilters([false, appConf.biologicOrderFilters[1], true]))
    await dispatch(fetchOrders(pl))
    await dispatch(updateBiologicOrderFilters([true, pl, false]))
  }
  const [updatingStatus, setUpdatingStatus] = useState(false);
  
  const handleMenuClick = (e) => {
    let payload = { orders: [...selectedRowKeys], status: availableStatuses?.filter(item => !reasonableStatuses.includes(item))[e.key] }
    appLog(e, payload)
    let data = {
      payload,
      filters: { "filterData": { "visitType": 'Biologics', "orderState": "post-order" } }
    }
    
    dispatch(bulkUpdateOrderStatus(data))
  }
  useEffect(() => {
    setSelectedRowKeys([])
    
    return () => {
    
    }
  }, [orders])
  
  const reasonableStatuses = ["cancelled", "needs review", "returned"]
  const menu = (<Menu onClick={handleMenuClick}>
    {availableStatuses?.filter(item => !reasonableStatuses.includes(item)).map((item, index) => <Menu.Item key={index}
                                                                                                           style={{ textTransform: 'capitalize' }}> {item.replace('-', '').replace(/_/g, ' ')}  </Menu.Item>)}
  </Menu>);
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderExportForm, setOrderExportForm] = useState(false)
  const handleExportOrderCancel = () => {
    setOrderExportForm(false)
  }
  
  
  return (
    (<div className="main-page">
      {(showAuditDialog && !!order) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false} resourceDescription={'Treatment order'}
                                                       resourceType={'Treatment order'}
                                                       defaultValues={{ auditableId: order.id, auditable: 'treatment_order', format: 'json' }}/>}
      <Modal
        title="Order Report"
        open={orderExportForm}
        destroyOnClose
        onCancel={handleExportOrderCancel}
        closeIcon={modalClose(handleExportOrderCancel)}
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(() => setOrderExportForm(false), 'Cancel', isExecuting),
            formSubmitButton('new-order-report', 'Download', isExecuting, 'Downloading')])}
        </div>]}>
        <ReportForm closeModal={handleExportOrderCancel} setIsSubmitting={setIsSubmitting} type="biologics" state={"post-order"}/>
      </Modal>
      {isOpen && <CreateOrder setOpen={setOpen} OrderModal={isOpen} handleOrderCancel={handleOrderCancel} type="Biologics" loadOrders={loadOrders}/>}
      <div className="w-full px-2">
        
        <Table
          // sortDirections={['ascend', 'descend', 'ascend']}
          rowKey={(record) => record.id}
          rowSelection={availableStatuses?.filter(item => !reasonableStatuses.includes(item)).length > 0 && rowSelection}
          title={() => (
            <>
              <TableFilters datasource={[...columns]} setIsVisible={setIsVisible}
                            isVisible={isVisible}
                            showClear={appConf.biologicOrderFilters[0]}
                            handleFilterAction={handleFilterAction}
                            loading={appConf.biologicOrderFilters[2]}
                            filters={appConf.biologicOrderFilters[1]}
                            actionButton={
                              
                              <div className="flex-boy-row" style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                                {can_export_treatment_order &&
                                  <Button type={'default'} size={'middle'} onClick={() => setOrderExportForm(true)}
                                          icon={<FontAwesomeIcon icon={["far", 'file-excel']}/>}>Export to CSV
                                  </Button>}
                              
                              </div>
                            }
              />
              {selectedRowKeys.length > 0 && <div className="flex-boy-row-space" style={{ backgroundColor: 'teal', marginTop: '10px' }}>
                <Subtitle1 color="white">Selected: {selectedRowKeys.length}</Subtitle1>
                <div className={'flex flex-row gap-2'}>
                  {(!!selectedRowRecord && !!selectedRowRecord?.patient) && secondaryButton(() => loadPatientOrders(selectedRowRecord.patient.name), 'Filter by selected patient', updatingStatus, 'Filtering',
                    { backgroundColor: 'white' }, null)}
                  <Dropdown dropdownRender={()=>menu} trigger={['click']}>
                    {secondaryButton(null, "Change Status", updatingStatus, 'Updating',
                      { backgroundColor: 'white' }, {
                        endIcon: <CaretDownOutlined/>
                      })}
                  </Dropdown>
                </div>
              </div>}
            </>
          )}
          loading={appConf.biologicOrderFilters[2]} {...responsiveTbl(windowState)} locale={{ emptyText: emptyOrders('orders.') }}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchOrders, appConf.biologicOrderFilters, updateBiologicOrderFilters,
            { "visitType": 'Biologics', "orderState": "post-order" }, bioOrdersPagination)}
          {...getSkels(appConf.biologicOrderFilters[2], columns, orders)}
          pagination={bioOrdersPagination}
        />
      </div>
    </div>)
  );
}

const mapStateToProps = (state) => ({
  orders: state.orders.orders,
  loading: state.orders.loading, bioOrdersPagination: state.orders.bioOrdersPagination, appConf: state.appConf,
  navs: state.navs,
  activeUser: state.auth.activeUser,
  masterList: state.masters.masterListRaw
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BiologicsList)
