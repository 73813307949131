import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ReactImageAnnotate from "react-image-annotate"
import { toast } from 'react-toastify'
import { emptyFun } from '../../../common/helpers'
// never ever tounch this code it only works that way!!!!!!!!!!!
 const ManualAnnotation = ({ file, getManualWoundimentions }) => {
    const [rendered, setRendered] = useState(0)
    const renderLoop = () => {
        if (rendered < 12) {
            setRendered(rendered + 1)
            localStorage.setItem("__REACT_WORKSPACE_LAYOUT_EXPANDED", false)
        }
    }
    useEffect(() => {
        console.log(rendered)
        renderLoop()
        return emptyFun
    }, [rendered])
    const handleExit = (data) => {
        console.log(data)
        if (!data.images[0].regions) {
            toast("Please add a Marker and wound annotations", { type: "error", autoClose: 5000 });
        }
        // else if (!data.images[0].regions.some(item => item.cls === "Marker")) {
        //     toast("Please annotate the Marker", { type: "error", autoClose: 5000 });
        // }
        else if (!data.images[0].regions.some(item => item.cls === "Wound")) {
            toast("Please annotate the Wound", { type: "error", autoClose: 5000 });
        } else {
            getManualWoundimentions(data.images[0])
        }
        //
        // you can do anything with this data you can console.log(data) and see what it contains
        // I personally use data.images to get the image array and its data like annotation points or x and y
        // coordinates of points
    }

    return (
        <div style={{ width: "100%", height: "600px" }}>
            {rendered % 3 === 0 ? <ReactImageAnnotate

                onExit={handleExit}
                hideNext
                hidePrev


                selectedImage={file.original_image_url}
                taskDescription="# Draw region around the wound and marker"
                images={[{ src: file.original_image_url, name: "Wound Image" }]}
                regionClsList={["Wound"]}
                enabledTools={["select", "create-point", "create-box", "create-polygon"]}
            /> : null}
        </div>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ManualAnnotation)
