/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AutoComplete, Button, Checkbox, Col, DatePicker, Divider, Dropdown, Form, Image, Input, InputNumber, Menu, Modal, Radio, Row, Select, Spin, Steps, Tooltip, Typography, Upload } from 'antd';
import { CaretDownOutlined, CloudUploadOutlined, DeleteOutlined, MinusCircleOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { apiRequest } from '../../../app/Apis'
import { Body2, Headline6, RegularButton, Subtitle1 } from '../../../components'
import { toast } from 'react-toastify'
import { fetchAppointments, fetchDiagnoses, fetchSpecificAppointment } from '../../../app/Reducers';
import _ from "lodash"
import { WoundAssessmentmodule } from '../../../components/WoundAssessmentmodule';
import {addButton, appLog, appMessage, dialogCloser, dialogOpener, emptyFun, GetNumericalListOptions, modalClose, outlinedButton, roundToDp, secondaryButton} from "../../../common/helpers";
import ManualAnnotation from './ManualAnnotation';
import dayjs from 'dayjs';
import { errMsg, getToastId, toastUp, updateToast } from "../../../app/Reducers/reducerUtils";
import {
    calculateKitAreaUtil,
    canAddKitsUtil,
    changeSelectableProductsUtil,
    fetchAsssessmentForm,
    getBiologicKitsUtil,
    getGraftDetails, getManualWoundimentions,
    handleKitChangeUtil,
    handleKitSizeChangeUtil,
    onImageEditedUtil,
    onQtyChangeUtil,
    onTissueAndSerialNoChange,
    suggestGrafts
} from "./AssessmentUtil";
import querystring from "querystring";
import * as Sentry from "@sentry/react";
import RotateAndCrop from './RotateAndCrop';
import TreatmentRecommendation from './TreatmentRecommendation';

/* lab procedure to pcr mapping
 Lab-bacterial: Wound Panel With Antibiotic Resistance (ABR)

Lab-fungal: Fungal panel

Lab-nail: Nail pathogen panel */
const LAB_PROCEDURE_TO_PCR_MAPPING = {
    "Lab-Bacterial": "Wound Panel With Antibiotic Resistance (ABR)",
    "Lab-Fungal": "Fungal panel",
    "Lab-Nail": "Nail pathogen panel",
}
   
// convert "Lab-Bacterial" to this format  lab_bacterial_p_c_r_type
const getPCRType = (procedure) => {
    return procedure.toLowerCase().replace(/-/g, '_').replace(/ /g, '_') + "_p_c_r_type"
}

const { Title, Paragraph, Text, Link } = Typography;
export const AssessmentCard = ({ procedure, procedures, wound, appointment, setassessmentModal, setisExecuting, masterList, masterListRaw, diagnoses, setlabTestModal, labRegions }) => {
    const [billingGroups, setbillingGroups] = useState([])
    const [selectedTreatment, setSelectedTreatment] = useState(null)


    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]); else byteString = unescape(dataURI.split(',')[1]);
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    }


    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    let defaultOCR = [{ tissue_id: undefined, serial_number: undefined }]
    const [masterLocations, setMasterLocations] = useState(masterList[0] ? masterList.find(item => item.system_name === 'wound_location')?.options : [])
    const [woundLocations, setwoundLocations] = useState((!!masterLocations && masterLocations[0]) ? masterList.find(item => item.system_name === 'wound_location')?.options : [])
    const [activeTab, setTab] = useState("wound_details")
    const { Step } = Steps;
    const { TextArea } = Input;
    const dispatch = useDispatch()
    /** when set, form fields are displayed*/
    const [assessmentForm, setassessmentForm] = useState(null)
    const { Option } = Select;
    const [treatmentScenario, setScenario] = useState(null)
    // const [woundLocations, setwoundLocations] = useState([])
    const [selectedwoundlocation, setselectedwoundlocation] = useState(null)
    const [visitSummary, setVisitSummary] = useState(null)
    const [extraNotes, setExtraNotes] = useState('')
    const [validationerrors, setvalidationerrors] = useState({
        wound_image: false, treatmentorderItems: false, treatmentprotocol: false, planofcareItems: false,
        extra_notes: false, visit_summary: false, wound_location: false
    })
    // treatment protocols stuff
    const [treatmentprotocol, settreatmentprotocol] = useState(null)
    const [treatmentOptions, setOptions] = useState([])
    const [treatmentorderItems, setTreatmentorderItems] = useState([])
    const [newTreatmentSupply, setnewTreatmentSupply] = useState(false)
    const [additionalTreatmentSupply, setAdditionalTreatmentSupply] = useState(false)
    const [newPlanofCare, setnewPlanofCare] = useState(false)
    const [treatmentSupplies, setTreatmentSupplies] = useState([])
    const [planofcareSearch, setplanofCareSearch] = useState([])
    const [planofcareItems, setplanofCareItems] = useState([])
    const [assessmentKits, setAssessmentKits] = useState([]);
    const [biologicsProducts, setbiologicsProducts] = useState([])
    const [biologicProductOptions, setbiologicProductOptions] = useState([])
    const [recommendableProductOptions, setRecommendableProductOptions] = useState([]);
    const [maxArea, setMaxArea] = useState(0);
    const [maxKitArea, setMaxKitArea] = useState(0);
    const [maxUnitAreaWarnings, setMaxUnitAreaWarnings] = useState([]);
    const [kitAmountMessage, setKitAmountMessage] = useState(null);
    const [showBiologic, setShowBiologic] = useState(true);
    const [uploadLoading, setUploadLoading] = useState([-1, -1]);
    const [assessmentDates, setassessmentDates] = useState([])
    const [rotateImage, setRotateImage] = useState(false);
    const [newTreatmentKit, setnewTreatmentKit] = useState(false);
    const [woundImage, setwoundimage] = useState(null)
    const [woundDetails, setwoundDetails] = useState(null)
    // let unavailableBiologicProducts = []
    useEffect(() => {
        calculateKitAreaUtil(maxArea, assessmentKits, setKitAmountMessage, setMaxKitArea, biologicsProducts, setMaxUnitAreaWarnings, allformvalues)
        return emptyFun
    }, [assessmentKits])

    useEffect(() => {
        const filters = masterList.find(option => option.system_name === 'treatment_filter')?.options
        console.log("Treatment Filters:::", filters)
    }, [masterList])

    useEffect(() => {
        fetchBillingGroups()
        dispatch(fetchDiagnoses(appointment.patient.id))

        return emptyFun
    }, [appointment])

    useEffect(() => {
        fetchWoundPreviousBiologics(wound.id)
        return emptyFun
    }, [wound])

    const [woundsBiologicsDate, setwoundsBiologicsDate] = useState(null)

    const fetchWoundPreviousBiologics = async (id) => {
        try {
            const response = await apiRequest(`/wounds/${id}/latest-biologic-pre-assessment`)
            if (response && response.data.data) {
                setwoundsBiologicsDate(response.data.data.appointment.start_at)
            }
        } catch (e) {
            console.log(e)
        }
    }


    useEffect(() => {
        let recomendableProducts = biologicsProducts.filter(product => product.kits.length > 0 && !product.kits.some(kitSize => !kitSize.size || !kitSize.id || !kitSize.area))
        setRecommendableProductOptions(recomendableProducts.map(item => {
            return {
                key: `${item.company_name} ${item.product_name}`,
                icon: null,
                disabled: !!item.unavailablity_reason,
                label: !!item.unavailablity_reason ?
                    <Tooltip zIndex={9999} title={item.unavailablity_reason} placement="bottom">{`${item.company_name} ${item.product_name}`}</Tooltip> : `${item.company_name} ${item.product_name}`
            }
        }))
        return emptyFun
    }, [biologicsProducts])
    const handleProtocolsearch = async (value) => {
        let params = { ...(!!value && { search: value }) }
        const { data } = await apiRequest.get(`/treatment-scenarios?${querystring.stringify(params)}`)
        if (data) {
            //console.log(data.data)
            let other = { protocol_name: "Custom Kit", treatment_code: "" }
            let options = data.data
            options = options.map(item => ({
                ...item,
                protocol_name: item.treatment_code ? `${item.scenario_number} ${item.scenario_number ? "-" : null} ${item.treatment_code}-${item.protocol_name}` : `${item.scenario_number} ${item.scenario_number ? "-" : null}${item.protocol_name}`
            }))
            options.push(other)
            setOptions(options);
        }
    }

    const fetchLatestDetails = async () => {
        let payload = {
            procedure,
            wound_id: wound.id
        }
        const { data } = await apiRequest.post(`appointment/${appointment.id}/assessment-latest`, payload).catch(appLog)
        if (data) {
            //console.log({fetchLatestDetails: data.data})
            let formData = { ...data.data }
            let fields = procedures.find(item => item.name === procedure)?.form_inputs?.filter(item => (item.category === "Vascular Measurements" || item.category === "Neurology Measurements") && item.options.some(opt => opt.value === "Non-contrib")).map(field => field.field_name)
            fields?.map(item => formData[item] = "Non-contrib")

            form.setFieldsValue({ ...formData })
            await setwoundDetails({ ...formData })
            setVisitSummary(data?.data?.visit_summary)
            setwoundimage(data?.data?.woundImage)
            setDummyImage(data?.data?.woundLocationPath?.url)
            let area = parseFloat(data?.data?.area)
            setMaxArea(roundToDp(area, 2))
            setShowBiologic(true)

        }
    }


    // fetch healed-wound/default-observations in the event that healed is selected for wound status wound_status

    // fetch healed-wound/default-observations in the event that healed is selected for wound status wound_status


    const fetchHealed = async () => {

        const { data } = await apiRequest.get("/healed-wound/default-observations").catch(appLog)
        if (data && data.data) {
            let type = form.getFieldValue('wound_type')
            let stage = form.getFieldValue('stage')
            console.log(stage, type)
            form.setFieldsValue({
                ...allformvalues, ...data.data,
                upload_type: 'manual',
                stage: type === "Pressure Ulceration" ? form.getFieldValue('stage') : data.data.stage,
                wound_duration: form.getFieldValue('wound_duration')
            })
            setVisitSummary(data?.data?.visit_summary)

        }
    }

    const fetchLatestEvaluationDetails = async (date) => {
        let payload = {
            procedure,
            wound_id: wound.id,
            previous_evaluation_date: date
        }

        const { data } = procedure === 'Biologics Ordering' ? await apiRequest.get(`/wounds/${wound.id}/latest-assessment?procedure=Biologics Pre-Assessment&previous_evaluation_date=${date} `).catch(appLog) : procedure === "Biologics Application" ? await apiRequest.get(`/wounds/${wound.id}/latest-assessment?procedure=Biologics Ordering&previous_evaluation_date=${date}`).catch(appLog) : await apiRequest.post(`appointment/${appointment.id}/assessment-latest`, payload).catch(appLog)
        if (data && data.data) {
            //console.log(data.data)
            form.setFieldsValue({ ...data.data, previous_evaluation_date: date, procedure:procedure })
            await setwoundDetails({ ...data.data })
            setVisitSummary(data.data.visit_summary)
            setwoundimage(data.data.woundImage)

            setDummyImage(data?.data?.woundLocationPath?.url)
            let area = parseFloat(data.data.area)
            setMaxArea(roundToDp(area, 2))
            setShowBiologic(true)
        } else {
            toast("No evaluations found for selected date please select a different date", { type: "error", autoClose: 5000 });
            form.setFieldsValue({ previous_evaluation_date: '' })
        }
    }
    const fetch_list_of_dates = async () => {
        const data = await apiRequest.get(`wounds/${wound.id}/previous-biologic-evaluations`)
        if (data) {
            let dates = _.uniq(data.data.data.filter(item => item.procedure.includes("Biologics Ordering")).map(item => item ? dayjs(item.appointment.start_at).format("YYYY-MM-DD") : null))

            setassessmentDates(dates)
        }
    }
    const onprotocolSelect = (value) => {
        console.log("Selected Protocal::", value)
        if (value !== "Custom Kit") {
            let protocal = treatmentOptions.find(item => item.protocol_name === value)
            settreatmentprotocol(value)
            fetchtreatmentProtocol(value?.id)
        } else {
            settreatmentprotocol({ protocol_name: "Custom Kit" })
            setplanofCareItems([])
            setTreatmentorderItems([])
        }
    };
    const fetchtreatmentProtocol = async (id) => {
        const { data } = await apiRequest.get(`/treatment-scenarios/${id}`)
        if (data) {
            //console.log(data.data)
            setisExecuting(false)
            let item = data.data
            settreatmentprotocol({
                ...item,
                protocol_name: item.treatment_code ? `${item.scenario_number} ${item.scenario_number ? "-" : null} ${item.treatment_code}-${item.protocol_name}` : `${item.scenario_number} ${item.scenario_number ? "-" : null}${item.protocol_name}`
            })
            setplanofCareItems(data.data.plan_of_care.map(item => ({ ...item, checked: true })))
            setTreatmentorderItems(data.data.treatment_order_items.map(item => ({ ...item })))
        }
    }


    const fetchBillingGroups = async () => {
        const response = await apiRequest.get(`/billing-entity?state=${appointment?.patient?.state}`);

        if (response && response.data && response.data.data) {
            setdefaultBilllingGroup(response.data.data.find(item => item.is_default)?.name)

            form.setFieldValue("billing_group", response.data.data.find(item => item.is_default)?.name)

            setbillingGroups(response.data.data.map(item => item.name))

        }
    }
    const [defaultBilllingGroup, setdefaultBilllingGroup] = useState(null)
    // treatment supplies
    const handlesupplysearch = async (value) => {
        let supplies = [...treatmentorderItems].map(item => item.name)
        try {
            let params = { ...(!!value && { search: value }) }
            const { data } = await apiRequest.get(`/treatment-supplies?${querystring.stringify(params)}`)
            if (data) {
                setTreatmentSupplies(data.data.filter(item => !supplies.includes(item.name)));
            }
        } catch (e) {
            Sentry.captureException(e);
            //console.log(e)
            toastUp(errMsg(e, true, null, 'Failed to search treatment supplies'), false)
        }
    };


    //non billable treatment supplies
    const handleadditionalsupplysearch = async (value) => {
        let supplies = [...treatmentorderItems].map(item => item.name)
        try {
            let params = { ...({ searchAdditionalSupplies: value }) }
            const { data } = await apiRequest.get(`/treatment-supplies?${querystring.stringify(params)}`)
            if (data) {
                setTreatmentSupplies(data.data.filter(item => !supplies.includes(item.name)));
            }
        } catch (e) {
            Sentry.captureException(e);
            //console.log(e)
            toastUp(errMsg(e, true, null, 'Failed to search treatment supplies'), false)
        }
    };
    const onsupplySelect = (value) => {
        //console.log('onSelect', value);
        let supply = treatmentSupplies.find(item => item.name === value)
        supply.type = "Secondary Dressing"
        let supplies = [...treatmentorderItems]
        supplies.push(supply)
        setTreatmentorderItems(supplies)
        setnewTreatmentSupply(false)
    };
    const onPrimarysupplySelect = (value) => {
        //console.log('onSelect', value);
        let supply = treatmentSupplies.find(item => item.name === value)
        supply.type = "Primary Dressing"
        let supplies = [...treatmentorderItems]
        supplies.push(supply)
        setTreatmentorderItems(supplies)
        setnewTreatmentSupply(false)
    };

    const onAdditionalSupplySelect = (value) => {
        //console.log('onSelect', value);
        let supply = treatmentSupplies.find(item => item.name === value)
        supply.type = "Additional Dressing"
        let supplies = [...treatmentorderItems]
        supplies.push(supply)
        setTreatmentorderItems(supplies)
        setAdditionalTreatmentSupply(false)
    }
    const removeTreatmentItem = (item) => {
        let supplies = [...treatmentorderItems]
        let idx = supplies.findIndex(supply => supply.id === item.id)
        supplies.splice(idx, 1);
        setTreatmentorderItems(supplies)
    }
    const unitChange = (item, index, value) => {
        console.log(item, index, value)
        let supplies = []
        treatmentorderItems.forEach((item, index) => supplies[index] = { ...item })

        setTreatmentorderItems(supplies)


        // Map through all items and update those that match the condition
        let updatedSupplies = supplies.map(supply => {
            if (supply.id === item.id) {
                return { ...supply, unit_count: value }; // update the unit_count for matching items
            }
            return supply; // return the item unchanged if it doesn't match
        });

        console.log(updatedSupplies);
        setTreatmentorderItems(updatedSupplies);
    }

    //   plan of care
    const handleplanofCaresearch = async (value) => {
        let steps = [...planofcareItems].map(item => item.step)
        //console.log(steps)
        try {
            let params = { ...(!!value && { search: value }) }
            const { data } = await apiRequest.get(`plans-of-care?${querystring.stringify(params)}`)
            if (data) {
                setplanofCareSearch(data.data.filter(item => !steps.includes(item.step)));
            }
        } catch (e) {
            Sentry.captureException(e);
            //console.log(e)
            toastUp(errMsg(e, true, null, 'Failed to search plan of care'), false)
        }
    };
    const onplanofCareSelect = (value) => {
        //console.log('onSelect', value);
        let step = planofcareSearch.find(item => item.step === value)
        step.checked = false

        let steps = [...planofcareItems]
        step.step_number_order = steps.length + 1
        setplanofCareSearch([]);
        steps.push(step)
        setplanofCareItems(steps)
        setnewPlanofCare(false)
        setallPlansofCare(false)
    };

    const onplanofCarePrimaryselect = (value) => {
        //console.log('onSelect', value);
        let step = planofcareSearch.find(item => item.step === value)
        step.checked = false
        step.type = "Primary Dressing"
        let steps = [...planofcareItems]
        steps.push(step)
        setplanofCareItems(steps)
        setplanofCareSearch([]);
        setnewPlanofCare(false)
        setallPlansofCare(false)
    }
    const checkStep = (checked, item) => {
        let steps = [...planofcareItems]
        let idx = steps.findIndex(supply => supply.id === item.id)
        steps[idx].checked = checked
        if (!checked) {
            setallPlansofCare(false)

        }
        setplanofCareItems(steps)
    }
    const removeplanOfCareItem = (item) => {
        let supplies = [...planofcareItems]
        let idx = supplies.findIndex(supply => supply.id === item.id)
        supplies.splice(idx, 1);
        setplanofCareItems(supplies)
    }
    const [allPlansofCare, setallPlansofCare] = useState(false)
    const selectAllPlanItems = (value) => {
        //console.log(value)
        let steps = [...planofcareItems]
        steps = steps.map(item => ({ ...item, checked: value }))
        //console.log(steps)
        setallPlansofCare(value)
        setplanofCareItems(steps)
    }
    // end plan of care
    const [form] = Form.useForm();
    const getWoundimentions = async options => {
        const { onSuccess, onError, file, onProgress } = options;
        let formData = new FormData();
        // add one or more of your files in FormData
        // again, the original file is located at the `originFileObj` key
        const toastId = getToastId("Generating Wound Dimensions")
        formData.append("wound_image", file);
        try {
            const { data } = await apiRequest.post("/wound-measurements", formData)
            console.log({ getWoundimentions1: data })
            if (data && data.data.is_successfull) {
                updateToast(toastId, "Wound Dimensions Generated", true)
                onSuccess("Ok");
                setFileList([{
                    uid: 1,
                    status: 'done',
                    url: data.data.uploaded_image || data.data.original_image_url,
                    thumbUrl: data.data.uploaded_image || data.data.original_image_url,
                    masked_image_id: data.data.masked_image_id,
                    image_id: data.data.original_image_id,
                    original_image_url: data.data.original_image_url
                }])
                fetchBlob(!!data.data.uploaded_image ? data.data.uploaded_image : data.data.original_image_url)
                setdisplayAnnotation(true)
                let area = parseFloat(data.data.wound.area)
                setMaxArea(roundToDp(area, 2))
                form.setFieldsValue({
                    width: data.data.wound.width, length: data.data.wound.length, area: data.data.wound.area,
                    necrotic_tissue: data.data.tissue_percentage.black ? data.data.tissue_percentage.black : 0,
                    volume: area * parseFloat(form.getFieldValue('depth')),
                    granulation_tissue: data.data.tissue_percentage.red ? data.data.tissue_percentage.red : 0,
                    fibrous_tissue: data.data.tissue_percentage.yellow ? data.data.tissue_percentage.yellow : 0,
                    size: data.data.wound.size
                });
                // setDimensions({...data.data.wound})
            } else {
                updateToast(toastId, "Unable to generate wound dimensions please manualy annotate the wound or fill in the wound measurements manualy", false)
                onSuccess("Ok");
                setFileList([{
                    uid: 1,
                    status: 'done',
                    url: data.data.uploaded_image || data.data.original_image_url,
                    thumbUrl: data.data.uploaded_image || data.data.original_image_url,
                    masked_image_id: data.data.masked_image_id,
                    image_id: data.data.original_image_id,
                    original_image_url: data.data.original_image_url
                }])
                fetchBlob(!!data.data.uploaded_image ? data.data.uploaded_image : data.data.original_image_url)
                setdisplayAnnotation(true)
                let area = parseFloat(data.data.wound.area)
                setMaxArea(roundToDp(area, 2))
                form.setFieldsValue({
                    width: data.data.wound.width, length: data.data.wound.length, area: data.data.wound.area,
                    necrotic_tissue: data.data.tissue_percentage.black ? data.data.tissue_percentage.black : 0,
                    volume: area * parseFloat(form.getFieldValue('depth')),
                    granulation_tissue: data.data.tissue_percentage.red ? data.data.tissue_percentage.red : 0,
                    fibrous_tissue: data.data.tissue_percentage.yellow ? data.data.tissue_percentage.yellow : 0,
                    size: data.data.wound.size
                });
                setwoundAnnotation(true)
            }
        } catch (err) {
            updateToast(toastId, err.response?.data.message || "Server Error", false)
            setFileList([])

        }
        // console.log('data.data: ', data.data)
    }


    // manual appload
    const onChangeUploadType = (e) => {
        setupload_type(e.target.value)
    }
    const [upload_type, setupload_type] = useState("generate")
    const manualUpload = async options => {
        const { onSuccess, onError, file, onProgress } = options;
        let formData = new FormData();
        // add one or more of your files in FormData
        // again, the original file is located at the `originFileObj` key
        const toastId = toast("Uploading wound image", { type: "info", autoClose: false });
        formData.append("file", file);
        const { data } = await apiRequest.post("/files", formData)
        if (data && data.data) {
            toast.update(toastId, { render: "Wound Image uploaded", type: toast.TYPE.SUCCESS, autoClose: 5000 });
            onSuccess("Ok");
            setFileList([{
                uid: 1, status: 'done', url: data?.data?.url, thumbUrl: data?.data?.url, masked_image_id: null, image_id: data.data.id,
                original_image_url: data.data?.url, originFileObj: file.originFileObj
            }])
            setMaxArea(0)
            if (form.getFieldValue('status') !== "Healed") {
                form.setFieldsValue({
                    width: '', length: '', area: '',
                    necrotic_tissue: '',
                    volume: '',
                    granulation_tissue: '',
                    fibrous_tissue: ''
                });
            } else {
                form.setFieldsValue({
                    width: 0, length: 0, area: 0,
                    necrotic_tissue: 0,
                    volume: 0,
                    granulation_tissue: 0,
                    fibrous_tissue: 0
                });
            }

        }
    }


    // fetch image blob
    const fetchBlob = async (url) => {
        const config = { responseType: 'arraybuffer' };
        console.log({ url })
        let file = await apiRequest.get(url, config).then(response => new File([response.data], "wound"));
        setfiletobeUploaded(file)
        //console.log(file)
    }
    const [filetobeUploaded, setfiletobeUploaded] = useState(null)
    // manual annotation
    const [displayAnnotation, setdisplayAnnotation] = useState(false)
    const [woundAnnotation, setwoundAnnotation] = useState(false)
    useEffect(() => {
        fetchAsssessmentForm(setassessmentForm, procedure, procedures)
        // fetchWoundLocations(setwoundLocations)
        fetch_list_of_dates()
        if (procedure !== "Biologics Application") {
            fetchLatestDetails()
        } else {
        }


    }, [procedure])

    /*const fetchAsssessmentForm = async () => {
      let procedure_ = procedures.find(item => item.name === procedure)
      console.log('procedure_:  ', procedure_)
      setassessmentForm(procedure_.form_inputs)
      /!*try {
        dispatch(setFormLoadind(true))
        const response = await apiRequest.get(`procedures/${procedure}/assessment-form`)
        if (response && response.data && response.data.form) {
          console.log('assessmentForm:  ', response.data.form)
          setassessmentForm(response.data.form)
          dispatch(setFormLoadind(false))
        }
      } catch (e) {
        console.log(e)
        toastUp(errMsg(e, true, 'assessment form'), false)
      }*!/
    }*/
    const onFinish = (values) => {
        if (maxUnitAreaWarnings.some(value => value.includes('Please consider an alternative treatment'))) {
            let errMsg = maxUnitAreaWarnings.find(value => value.includes('Please consider an alternative treatment'))
            appMessage(errMsg, 'error')
            return
        }
        setisExecuting(true)
        console.log("Values", values)
        const data = { ...values }
        data.appointment_id = appointment.id
        data.wound_id = wound.id

        if (procedure === "DME") {
            // delete biologic_kits
            delete data.biologic_kits
            if (!treatmentScenario) {
                if (!fileList.length && !woundImage) {
                    let errors = { ...validationerrors }
                    errors.wound_image = true
                    setvalidationerrors(errors)
                    setisExecuting(false)
                    if (dummyRef.current) {
                        dummyRef.current.scrollIntoView({ behavior: 'smooth' })
                    }
                } else if (!dummyFile && !woundDetails?.woundLocationPath) {
                    let errors = { ...validationerrors }
                    errors.wound_location = true
                    setvalidationerrors(errors)
                    setisExecuting(false)
                    if (dummyRef.current) {
                        dummyRef.current.scrollIntoView({ behavior: 'smooth' })
                    }
                } else {
                    let errors = { ...validationerrors }
                    errors.wound_image = false
                    errors.wound_location = false
                    setvalidationerrors(errors)
                    delete data.biologic_kits
                    fetchAsssessmentTreatment(data)
                }
            } else {
                validateData(values)
            }
        } else {
            if (values.procedure.includes('Biologics')) {
                /*if ((Number(maxKitArea) < Number(maxArea)) && showBiologic) {
                  toastUp('Selected grafts are not sufficient to cover the wound', false, 10000)
                  setisExecuting(false)
                  return;
                }*/
                if (!dummyFile && !woundDetails?.woundLocationPath) {
                    //console.log('dummyfile')
                    let errors = { ...validationerrors }
                    errors.wound_location = true
                    setisExecuting(false)
                    setvalidationerrors(errors)
                    if (dummyRef.current) {
                        dummyRef.current.scrollIntoView({ behavior: 'smooth' })
                    }
                } else {
                    //console.log('ASSESSMENT_KITS: ', assessmentKits)
                    if (showBiologic && (!assessmentKits[0] || !assessmentKits[0].biologic_kits || !assessmentKits[0].biologic_kits[0]?.size)) {
                        console.error('BIO_ORDER_ERROR: ', assessmentKits)
                        setvalidationerrors(prevState => ({ ...prevState, biologic_products: true }))
                        setisExecuting(false)
                        if (biologicsRef.current) {
                            biologicsRef.current.scrollIntoView({ behavior: 'smooth' })
                        }
                        return
                    }
                    let bioKits = []
                    for (const item of assessmentKits) {

                        //  bioKits = [...bioKits, ...item?.biologic_kits.map(item => ({
                        //   id: item.id, quantity: item.quantity,
                        //   // ...((!!item.ocr_data && !!item.ocr_data[0]) ? { ocr_data: [...item.ocr_data] } : { ocr_data: defaultOCR }),
                        //   ...((!!item.ocr_data && !!item.ocr_data[0]?.serial_number) ? { ocr_data: [...item.ocr_data] } : { ocr_data: [] })
                        //  }))]

                        if (item?.biologic_kits) {
                            for (const biologicKit of item.biologic_kits) {
                                if (biologicKit?.ocr_data && biologicKit.ocr_data.length > 0) {
                                    for (const ocrData of biologicKit.ocr_data) {
                                        bioKits.push({
                                            id: biologicKit.id,
                                            quantity: biologicKit.quantity,
                                            ...ocrData

                                        });
                                    }
                                } else {
                                    bioKits.push({
                                        id: biologicKit.id,
                                        quantity: biologicKit.quantity,
                                        ocr_data: []
                                    });
                                }
                            }
                        }


                    }
                    let payload = { ...data, biologic_kits: bioKits }
                    delete payload.kits
                    //console.log('CREATEBIOLOGICSASSESSMENT:', payload)
                    createBiologicsAssessment(payload, payload)
                }
            } else if (values.procedure.includes('Lab')) {
                create_lab_assessment(values)

            } else {
                if (!dummyFile && !woundDetails?.woundLocationPath) {
                    let errors = { ...validationerrors }
                    errors.wound_location = true
                    setisExecuting(false)
                    setvalidationerrors(errors)
                    if (dummyRef.current) {
                        dummyRef.current.scrollIntoView({ behavior: 'smooth' })
                    }
                } else {
                    validateDebridement(values)
                }
            }
        }
    }
    const createBiologicsAssessment = async (post_data, payload) => {
        const toastId = getToastId("Creating Assessment")
        if (dummyFile) {
            let dataform = new FormData()
            dataform.append("file", dummyFile);
            const locationimage = await apiRequest.post('/files', dataform)
            post_data.wound_location_file_id = locationimage.data.data.id
        } else {
            post_data.wound_location_file_id = woundDetails?.woundLocationPath.id
        }
        post_data.image_id = fileList[0]?.image_id || woundDetails?.image_id
        post_data.masked_image_id = fileList[0] ? fileList[0].masked_image_id : woundDetails?.masked_image_id
        post_data.published_at = dayjs().format("YYYY-MM-DD")
        post_data.visit_summary = visitSummary || ''
        //console.log('createBiologicsAssessment: ', post_data)
        try {
            const response = await apiRequest.post(`/assessments`, post_data)
            if (response && response.data && response.data.data) {
                // await apiRequest.post(`/assessments/${response.data.data.id}/biologic-kits`, payload)
                await dispatch(fetchAppointments())
                await dispatch(fetchSpecificAppointment(appointment.id))
                updateToast(toastId, "Assessment created", true)
                setassessmentModal(false)
            }
            setisExecuting(false)
        } catch (e) {
            Sentry.captureException(e);
            setisExecuting(false)
            //console.log(e)
            updateToast(toastId, errMsg(e, false), false)
        }
    }

    const validateData = async (values) => {
        let data = { ...treatmentScenario }
        let errors = { ...validationerrors }
        if (!treatmentprotocol) {
            errors.treatmentprotocol = true
            setvalidationerrors(errors)
            setisExecuting(false)
        } else if (treatmentorderItems.some(e => !e.unit_count) || !treatmentorderItems) {
            errors.treatmentorderItems = true
            setisExecuting(false)
            setvalidationerrors(errors)
        } else if (!(planofcareItems.some(e => e.checked))) {
            errors.planofcareItems = true
            setisExecuting(false)
            setvalidationerrors(errors)
        } else {
            let errors = { ...validationerrors }
            errors = {
                wound_image: false, treatmentorderItems: false, treatmentprotocol: false, planofcareItems: false,
                extra_notes: false, visit_summary: false, wound_location: false
            }
            setvalidationerrors(errors)
            data.recommended_treatment_protocol = treatmentprotocol.protocol_name
            data.supply_order_items = treatmentorderItems.map(item => ({
                id: item.id,
                unit_count: item.unit_count,
                type: item.type,
                pack_amount: item.pack_amount || item.default_pack_amount
            }))
            data.plan_of_care_steps = _.uniqBy(planofcareItems, 'id').filter(item => item.checked).map(item => item.id)
            data.visit_summary = visitSummary || ""
            data.extra_notes = extraNotes || "No collagen"
            data.published_at = dayjs().format("YYYY-MM-D")
            // if treatment protocol adn treatment protocol is not custom add scenario number

            if (treatmentprotocol && treatmentprotocol?.protocol_name !== "Custom Kit") {
                data.scenario_number = treatmentprotocol.scenario_number
            }
            delete data.biologic_kits

            //console.log(data.plan_of_care)
            const toastId = toast("Creating Assessment", { type: "info", autoClose: false });
            const response = await apiRequest.patch(`/assessments/${data.id}`, data).catch(function (err) {
                setisExecuting(false)
                //console.log(err)
                toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 })
            })
            if (response && response.data) {
                await dispatch(fetchAppointments())
                await dispatch(fetchSpecificAppointment(appointment.id))
                toast.update(toastId, { render: "Assessment Created", type: toast.TYPE.SUCCESS, autoClose: 5000 });
                setisExecuting(false)

                setassessmentModal(false)

            }
        }
    }


    const validateDebridement = async (values) => {
        let post_data = { ...values }
        if (!fileList.length && !woundImage) {
            let errors = { ...validationerrors }
            errors.wound_image = true
            setvalidationerrors(errors)
            setisExecuting(false)
            if (dummyRef.current) {
                dummyRef.current.scrollIntoView({ behavior: 'smooth' })
            }
        } else {
            let errors = { ...validationerrors }
            errors = {
                wound_image: false, treatmentorderItems: false, treatmentprotocol: false, planofcareItems: false,
                extra_notes: false, visit_summary: false
            }
            setvalidationerrors(errors)
            let formData = new FormData();
            // add one or more of your files in FormData
            // again, the original file is located at the `originFileObj` key
            const toastId = toast("Creating Assessment", { type: "info", autoClose: false });
            // formData.append("file", fileList[0].originFileObj);
            // const { data } = await apiRequest.post('/files', formData)
            // const image_id = data.data.id
            // formData.append("file", fileList[0].originFileObj);
            if (dummyFile) {
                let dataform = new FormData()
                dataform.append("file", dummyFile);
                const locationimage = await apiRequest.post('/files', dataform)
                post_data.wound_location_file_id = locationimage.data.data.id
            } else {
                post_data.wound_location_file_id = woundDetails?.woundLocationPath.id
            }
            post_data.image_id = fileList[0]?.image_id || woundDetails?.image_id
            post_data.masked_image_id = fileList[0] ? fileList[0].masked_image_id : woundDetails?.masked_image_id
            post_data.published_at = dayjs().format("YYYY-MM-D")
            post_data.visit_summary = visitSummary || ""
            // post_data.extra_notes = extraNotes || "No collagen"
            post_data.appointment_id = appointment.id
            post_data.wound_id = wound.id
            const response = await apiRequest.post(`/assessments`, post_data).catch(function (err) {
                setisExecuting(false)
                //console.log(err)
                toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 })
            })
            if (response && response.data) {
                toast.update(toastId, { render: "Assessment Created", type: toast.TYPE.SUCCESS, autoClose: 5000 });
                dispatch(fetchAppointments())
                dispatch(fetchSpecificAppointment(appointment?.id))
                setisExecuting(false)
                if (post_data.infection === "Rule out infection" && procedure === "Wound Evaluation") {
                    setlabTestModal(true)
                }
                setassessmentModal(false)


            }
        }
    }
    const fetchAsssessmentTreatment = async (post_data) => {
        let formData = new FormData();
        // add one or more of your files in FormData
        // again, the original file is located at the `originFileObj` key
        const toastId = toast("Creating Assessment draft", { type: "info", autoClose: false });
        // formData.append("file", fileList[0].originFileObj);
        // const { data } = await apiRequest.post('/files', formData)
        // const image_id = data.data.id
        // formData.append("file", fileList[0].originFileObj);
        if (dummyFile) {
            let dataform = new FormData()
            dataform.append("file", dummyFile);
            const locationimage = await apiRequest.post('/files', dataform)
            post_data.wound_location_file_id = locationimage.data.data.id
        } else {
            post_data.wound_location_file_id = woundDetails?.woundLocationPath.id
        }
        post_data.image_id = fileList[0]?.image_id || woundDetails?.image_id
        post_data.masked_image_id = fileList[0] ? fileList[0].masked_image_id : woundDetails?.masked_image_id
        post_data.published_at = null
        const response = await apiRequest.post(`/assessments`, post_data).catch(function (err) {
            setisExecuting(false)
            //console.log(err)
            toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
        })
        if (response && response.data && response.data.data) {
            toast.update(toastId, { render: "Assessment Draft created", type: toast.TYPE.SUCCESS, autoClose: 5000 });
            settreatmentprotocol(response.data.data.recommended_treatment_protocol && response.data.data.recommended_treatment_protocol !== " - " ? { protocol_name: response.data.data.recommended_treatment_protocol } : null)
            setScenario(response.data.data)
            setTreatmentorderItems(response.data.data.treatment_order_items.map(item => ({ ...item })))
            setplanofCareItems(response.data.data.plan_of_care_items.length ? response.data.data.plan_of_care_items.map(item => ({
                ...item,
                checked: true
            })) : response.data.data.plan_of_care_items)

            setisExecuting(false)
            if (treatmentRef.current) {
                treatmentRef.current.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }
    const [fileList, setFileList] = useState([]);
    const onChange = ({ fileList: newFileList }) => {
        //console.log(newFileList)
        setFileList(newFileList);
        // set depth as empty
        if (form.getFieldValue('status') !== "Healed") {
            form.setFieldsValue({ depth: '0.01' })
        }

    };

    useEffect(() => {
        appLog({ woundDetails })
        if (!!woundDetails) {
            fetchBiologicsKit().catch(appLog)
        }
        return emptyFun
    }, [procedure, woundDetails])
    useEffect(() => {
        appLog({ fileList, woundImage })
        return emptyFun
    }, [fileList, woundImage])
    const fetchBiologicsKit = async () => {
        const { data } = await apiRequest.get(`/biologic-products`, { params: { patient_id: appointment.patient.id } })
        if (data) {
            //console.log('fetchBiologicsKit1: ', data.data)
            await setbiologicsProducts(data.data)
            await setbiologicProductOptions(data.data.map(item => ({
                ...item,
                value: `${item.company_name} ${item.product_name}`,
                label: !!item.unavailablity_reason ?
                    <Tooltip zIndex={9999} title={item.unavailablity_reason} placement="bottom">{`${item.company_name} ${item.product_name}`}</Tooltip> : `${item.company_name} ${item.product_name}`,
                // disabled: unavailableBiologicProducts_.includes(`${item.company_name} ${item.product_name}`)
                disabled: !!item.unavailablity_reason
            })))
            console.log({ 'kits procedure ': procedure,woundDetails })
            //console.log(woundDetails?.biologic_kits)
            if (procedure.includes('Biologics') && woundDetails?.biologic_kits) {

                let productNames = []
                let assessmentKits_ = []
                let kits_ = woundDetails.biologic_kits.map(item => {
                    let ocr_data = item.tissue_id ? [{ tissue_id: item.tissue_id, serial_number: item.serial_number }] : []
                    console.log("thiese are kits ocrs", ocr_data)
                    return {
                        ...item,
                        ocr_data
                    }
                })

                console.log("thiese are kits", kits_)

                for (const bioKit of kits_) {
                    let name = `${bioKit.biologic_product.company_name} ${bioKit.biologic_product.product_name}`
                    let product = data.data.find(item => `${item.company_name} ${item.product_name}` === name)
                    if (!productNames.includes(name)) {
                        let productKits = woundDetails.biologic_kits.filter(item => `${item.biologic_product.company_name} ${item.biologic_product.product_name}` === name)
                        let biologic_kits = productKits.map(item => {
                            let biokit = product.kits.find(kit => item.size === kit.size)
                            return {
                                id: item.id, size: item.size, quantity: item.quantity, area: biokit.area,
                                // ...(!!item.tissue_id && { tissue_id: item.tissue_id }), ...(!!item.serial_number && { serial_number: item.serial_number })
                                ...((!!item.ocr_data && !!item.ocr_data[0]?.tissue_id) ? { ocr_data: [...item.ocr_data] } : { ocr_data: defaultOCR })
                            }
                        })
                        //console.log('biologic_kits:  ', biologic_kits)
                        assessmentKits_ = [...assessmentKits_, { product: name, biologic_kits }]
                        productNames.push(name)
                    }
                }


                await setAssessmentKits([...assessmentKits_])
                console.log({assessmentKits_})
                let formValues = { ...form.getFieldsValue() }
                formValues.kits = assessmentKits_
                form.setFieldsValue(formValues)
                // console.log(assessmentKits_, assessmentKits)


            }
        }
    }


    const getBiologicKits = (index) => getBiologicKitsUtil(index, biologicsProducts, assessmentKits)
    const canAddKits = (bioKits, index) => canAddKitsUtil(bioKits, index, assessmentKits)
    /** Update Biologic kits' section of the UI every time there is a change in selected kits*/
    const syncKits = () => {
        let formValues = { ...form.getFieldsValue() }
        formValues.kits = assessmentKits
        form.setFieldsValue(formValues)
    }
    const handleKitChange = async (value, index) => await handleKitChangeUtil(value, index, setvalidationerrors, biologicsProducts, setAssessmentKits, assessmentKits, form)
    const handleKitSizeChage = async (value, productIndex, kitIndex) => await handleKitSizeChangeUtil(value, productIndex, kitIndex, setvalidationerrors, biologicsProducts, assessmentKits, setAssessmentKits, form)
    const onQtyChange = async (e, productIndex, kitIndex) => await onQtyChangeUtil(e, productIndex, kitIndex, assessmentKits, setAssessmentKits, syncKits)

    const calculateVolume = () => {
        const length = form.getFieldValue('length')
        const width = form.getFieldValue('width')
        const depth = form.getFieldValue('depth');
        let area = parseFloat(length) * parseFloat(width)
        setMaxArea(roundToDp(area, 2))
        form.setFieldsValue({ area, volume: parseFloat(length) * parseFloat(width) * parseFloat(depth) })
        // set wound_size to default value  based on area 1scm -8 scm: small size wound 8scm-32scm: medium size wound,32 scm- 48scm: large size wound 48scm - and larger: extra large size wound

        calculate_woundSize(area)

    }
    const calculate_woundSize = (area) => {
        if (area <= 8) {
            form.setFieldsValue({ size: 'Small' })
        } else if (area > 8 && area <= 32) {
            form.setFieldsValue({ size: 'Medium' })
        } else if (area > 32 && area <= 48) {
            form.setFieldsValue({ size: 'Large' })
        } else if (area > 48) {
            form.setFieldsValue({ size: 'Extra Large' })
        }
    }

    const [selectedProcedure, setselectedProcedure] = useState(procedure)
    const changeProcedure = async (value) => {
        let procedures = wound.recommended_procedure.filter(item => item !== procedure)
        let data = { recommended_procedure: [value, ...procedures], wound_id: wound.id }
        const toastId = toast("Updating wound procedures", { type: "info", autoClose: false });
        const statusresponse = await apiRequest.post(`/appointments/${appointment.id}/procedures`, data).catch(function (err) {
            setisExecuting(false)
            //console.log(err)
            toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
        })
        if (statusresponse && statusresponse.data.data) {
            toast.update(toastId, { render: "Update complete", type: toast.TYPE.SUCCESS, autoClose: 5000 });
            fetchAsssessmentForm(setassessmentForm, procedure, procedures)
            dispatch(fetchSpecificAppointment(appointment?.id))
            setselectedProcedure(value)
        }
    }
    const [changeProtocol, setchangeProtocol] = useState(false)
    const [currentPossition, setcurrentPossition] = useState(1)
    const handleChangeProtocal = () => {
        setchangeProtocol(true)
        setnewTreatmentKit(true)
    }
    const onPositionChange = (e) => {
        if (e === 'some value') {
            window.location.href = '#1' // change there
        }
    }
    const woundDetailsRef = useRef()
    const dateFormat = "YYYY-MM-DD"
    const treatmentRef = useRef()
    const biologicsRef = useRef()
    const summaryRef = useRef()
    const dummyRef = useRef()
    const [activeTabDiv, setactiveTabDiv] = useState(1)
    const [woundDummy, setwoundDummy] = useState(false)
    const [dummyImage, setDummyImage] = useState(null)
    const [dummyFile, setDummyFile] = useState(null)
    const [consultant, setConsultant] = useState(null)
    const [consultantOptions, setconsultantOptions] = useState([])
    const handleConsultantssearch = async (value) => {
        const { data } = await apiRequest.get(`/consultants?search=${value}`)
        // setOptions(data);
        if (data) {
            //console.log(data.data)
            setconsultantOptions(data.data);
        }
    };
    const onconsultantSelect = (value) => {
        //console.log('onSelect', value);
        let wcc = consultantOptions.find(item => item.name === value)
        setConsultant(wcc)
    };

    const onPreview = async file => {
        //console.log(file)
        if (!file?.url && !file.preview) {
            file.preview = await toBase64(file.originFileObj);
        }
        let src = file?.url;
        setPreviewImage(src);    //The path of this picture depends on your own situation
        setPreviewVisible(true)
    }
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [recommending, setRecommending] = useState(false)
    const graftMenuClick = async e => suggestGrafts(maxArea, biologicsProducts, setAssessmentKits, form, e.key,setRecommending);
    const graftMenu = (<Menu onClick={graftMenuClick} items={recommendableProductOptions} />);
    const [allformvalues, setallformvalues] = useState(null)


    const [LengthError, setLengthError] = useState(false);
    const [nodataError, setnodataError] = useState(false);
    const [problemListOptions, setproblemListOptions] = useState([]);
    const debouncedChangeHandlerDrug = useCallback(
        _.debounce(function (value) {
            getListAvailableDrugProblems(value);
        }, 1000),
        []
    );
    const getListAvailableDrugProblems = async (value) => {
        //console.log(value);
        setproblemListOptions([]);
        if (value.length > 0) {
            setLengthError(false);
            const { data } = await apiRequest.get(
                `/diagnoses/search_diagnoses?search=${value}`
            );
            // setOptions(data);
            if (data) {
                //console.log(data.data);
                if (data?.data?.length) {
                    setnodataError(false);
                    setproblemListOptions(data.data);
                } else {
                    setnodataError(true);
                    setproblemListOptions([]);
                }
            }
        } else {
            setLengthError(true);
        }
    };

    const [uploadingRotatedImage, setUploadingRotatedImage] = useState(false)
    const onImageEdited = (data) => onImageEditedUtil(data, setFileList, form, setMaxArea, setRotateImage, setdisplayAnnotation, setwoundAnnotation, setUploadingRotatedImage)


    // for lab
    const [labTests, setlabTests] = useState([
       {
                            sample_id: "",
                            id: _.uniqueId(),
                            pcr_type: [masterList.find(option => option.system_name === getPCRType(procedure))?.options[0]],
                            intake_date: dayjs(appointment?.start_at).format("YYYY-MM-DD"),
                            lab_region: null,
                            is_draft: true,
    }
    ])
    const handleLabChange = (name, e, index) => {
        let tests = [...labTests]
        tests[index][name] = e
        setlabTests(tests)
    }

    const create_lab_assessment = async (values) => {
        let post_data = { ...values }
        if (!fileList.length && !woundImage) {
            let errors = { ...validationerrors }
            errors.wound_image = true
            setvalidationerrors(errors)
            setisExecuting(false)
            if (dummyRef.current) {
                dummyRef.current.scrollIntoView({ behavior: 'smooth' })
            }
        } else {
            let errors = { ...validationerrors }
            errors = {
                wound_image: false, treatmentorderItems: false, treatmentprotocol: false, planofcareItems: false,
                extra_notes: false, visit_summary: false
            }
            setvalidationerrors(errors)
            let formData = new FormData();
            // add one or more of your files in FormData
            // again, the original file is located at the `originFileObj` key
            const toastId = toast("Creating Assessment", { type: "info", autoClose: false });
            // formData.append("file", fileList[0].originFileObj);
            // const { data } = await apiRequest.post('/files', formData)
            // const image_id = data.data.id
            // formData.append("file", fileList[0].originFileObj);
            if (dummyFile) {
                let dataform = new FormData()
                dataform.append("file", dummyFile);
                const locationimage = await apiRequest.post('/files', dataform)
                post_data.wound_location_file_id = locationimage.data.data.id
            } else {
                post_data.wound_location_file_id = woundDetails?.woundLocationPath.id
            }
            post_data.image_id = fileList[0]?.image_id || woundDetails?.image_id
            post_data.masked_image_id = fileList[0] ? fileList[0].masked_image_id : woundDetails?.masked_image_id

            post_data.visit_summary = visitSummary || ""
            // post_data.extra_notes = extraNotes || "No collagen"
            post_data.appointment_id = appointment.id
            post_data.wound_id = wound.id
            const response = await apiRequest.post(`/assessments`, post_data).catch(function (err) {
                setisExecuting(false)
                //console.log(err)
                toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 })
            })
            if (response && response.data) {
                updateLabAssessment(values, response.data.data)
                toast.update(toastId, { render: "Assessment Created", type: toast.TYPE.SUCCESS, autoClose: 5000 });



            }
        }
    }


    const updateLabAssessment = async (values, assessment) => {
        const toastId = getToastId("Adding Lab Tests")
        console.log(values)
        try {
            const data = labTests.map(item => apiRequest.post(`assessments/${assessment.id}/lab-tests`, { ...item, sample_id: values.sample_id, intake_date: dayjs(values.intake_date).format("YYYY-MM-DD") }))
            const result = await Promise.all(data)
            if (result?.length) {
                await dispatch(fetchSpecificAppointment(appointment?.id))
                updateToast(toastId, "Lab Test Added", true)

                const response = await apiRequest.patch(`/assessments/${assessment.id}`, { ...assessment, published_at: dayjs().format("YYYY-MM-D") }).catch(function (err) {
                    setisExecuting(false)
                    //console.log(err)


                    toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 })
                })
                if (response && response.data) {
                    setisExecuting(false)
                    toast.update(toastId, { render: "Assessment Updated", type: toast.TYPE.SUCCESS, autoClose: 5000 });
                    setassessmentModal(false)
                    dispatch(fetchAppointments())
                    dispatch(fetchSpecificAppointment(appointment?.id))

                }

            }
        } catch (e) {
            Sentry.captureException(e);
            //console.log(e)
            updateToast(toastId, errMsg(e, false, null, 'Unable to add lab tests'), false)
            setisExecuting(false)
            setassessmentModal(false)
                    dispatch(fetchAppointments())
                    dispatch(fetchSpecificAppointment(appointment?.id))
        }
    }

    return (
        <div>
            <Modal
                title="Wound Location"
                open={woundDummy}
                destroyOnClose={true}
                onOk={() => setwoundDummy(false)}
                onCancel={() => setwoundDummy(false)}
                closeIcon={modalClose(() => setwoundDummy(false))}
                maskClosable={false}
                width={700}
                footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
                    {outlinedButton(() => setwoundDummy(false), 'Cancel')}
                </div>]}>
                <WoundAssessmentmodule setDummyFile={setDummyFile} setDummyImage={setDummyImage} setwoundDummy={setwoundDummy} />
            </Modal>
            <Modal
                title="Wound Annotation"
                open={woundAnnotation}
                destroyOnClose={true}
                onOk={() => setwoundAnnotation(false)}
                onCancel={() => setwoundAnnotation(false)}
                closeIcon={modalClose(() => setwoundAnnotation(false))}
                maskClosable={false}
                width={1200}
                footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
                    {outlinedButton(() => setwoundAnnotation(false), 'Cancel')}
                </div>]}>
                <ManualAnnotation file={fileList[0]} getManualWoundimentions={(annotation) => getManualWoundimentions(annotation, fileList, setFileList, null, setwoundAnnotation, setMaxArea, form)} />
            </Modal>


            <Modal
                open={rotateImage}
                bodyStyle={{ padding: 0, margin: 0, minHeight: "800px", width: '100%' }}
                title="Rotate image"
                destroyOnClose={true}
                footer={null}
                onCancel={dialogCloser(setRotateImage)}>
                <RotateAndCrop image={!!fileList[0] ? fileList[0]?.url : !!woundImage?.url ? woundImage?.url : ''} onImageEdited={onImageEdited} loading={uploadingRotatedImage} />
            </Modal>
            <Modal open={previewVisible} title="Preview Image" footer={null} onCancel={() => setPreviewVisible(false)}> <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
            <Modal
                open={newTreatmentKit}
                title="Treatment Kit"
                destroyOnClose={true}
                onCancel={() => setnewTreatmentKit(false)}
                closeIcon={modalClose(() => setnewTreatmentKit(false))}
                maskClosable={false}
                width={900}
                height={900}
                footer={false}>
                <TreatmentRecommendation
                    appointment={appointment}
                    assessmentForm={form}
                    selectedTreatment={selectedTreatment}
                    setSelectedTreatment={setSelectedTreatment}
                    setnewTreatmentKit={setnewTreatmentKit}
                    settreatmentprotocol={settreatmentprotocol}
                    masterList={masterList}
                    isEditing={changeProtocol}
                    onprotocolSelect={onprotocolSelect}
                />
            </Modal>





            {procedure.includes("Biologics") && procedure !== "Biologics Pre-Assessment" && <div className="flex-boy-row" style={{ marginBottom: "10px" }}>
                {woundsBiologicsDate && <Subtitle1> Previous Biologics Assessment Date: {dayjs(woundsBiologicsDate).format("YYYY-MM-DD HH:MM A")}</Subtitle1>}
            </div>}
            {assessmentForm ? <Form 
                name="new-asssessment"
                id="appointmentForm"
                labelCol={{ span: 16 }}
                form={form}
                onValuesChange={(changedValues, allValues) => {
                    console.log('changedValues:', changedValues)
                    console.log('allValues:', allValues)
                    setallformvalues(allValues)
                    if (changedValues.exposed_structures) {
                        //Add/Remove exposed structures message
                        calculateKitAreaUtil(maxArea, assessmentKits, setKitAmountMessage, setMaxKitArea, biologicsProducts, setMaxUnitAreaWarnings, allValues)
                    }
                    // if changedValues has key status and is value is healed call fetch healed

                    if (changedValues.status && changedValues.status === "Healed" && procedure === "Wound Evaluation") {
                        fetchHealed()
                        setupload_type("manual")
                    }

                    // setShowBiologic(allValues.treatment_number && !allValues.treatment_number.includes("Pre-treatment Assessment"))
                }}
                layout="vertical"
                initialValues={{ procedure: selectedProcedure, wounds: [''], location: wound.location, upload_type: allformvalues?.status === "Healed" ? 'manual' : 'generate', pcr_type: [masterList.find(option => option.system_name === getPCRType(procedure))?.options[0]]  , ...{}}}
                scrollToFirstError={true}
                onFinish={onFinish}>
                <Row gutter={16}>
                    {/* <Col span={4}>
        <div className={activeTabDiv === 1 ? "active-tab-div tab-div" : "tab-div"} onClick={() => {
          setactiveTabDiv(1)
          woundDetailsRef.current.scrollIntoView({ behavior: 'smooth' })
        }}>
          <Subtitle1 style={{ textAlign: 'left' }}>Wound Details</Subtitle1>
        </div>
        {procedure !== "Wound Evaluation" && <div className={activeTabDiv === 2 ? "active-tab-div tab-div" : "tab-div"}>
          <Subtitle1 style={{ textAlign: 'left' }} onClick={() => {
            setactiveTabDiv(2)
            if (treatmentRef.current) {
              treatmentRef.current.scrollIntoView({ behavior: 'smooth' })
            }
          }}>Treatment</Subtitle1>
        </div>}

        <div className={activeTabDiv === 3 ? "active-tab-div tab-div" : "tab-div"}>
          <Subtitle1 style={{ textAlign: 'left' }} onClick={() => {
            setactiveTabDiv(3)
            summaryRef.current.scrollIntoView({ behavior: 'smooth' })
          }}>Summary</Subtitle1>
        </div>
      </Col> */}
                    <Col span={14} id="assessmentForm">
                        <div className="p-4 flex flex-col flex-start w-full h-auto border rounded-xl shadow">
                            <div className="p-2 flex flex-row w-full">
                                <Headline6 className="">Wound Details</Headline6>
                            </div>
                            <div>
                                <Row ref={woundDetailsRef}>
                                    {assessmentForm.filter(item => item.category === "Wound Details").map((item) => {
                                        return (
                                            (<Col
                                                span={item.classes.includes('span-full') ? 24 : item.classes.includes('span-1/2') ? 12 : item.classes.includes('span-1/3') ? 8 : 12}>
                                                <div className={'w-full flex flex-col'}>
                                                    <Form.Item className={'mb-0'}
                                                        label={`${item.label.replace('New', '').replace("Other interventions", "Orders/Recommendations")}${item.label_unit ? item.label_unit : ''}`}



                                                        name={item.field_name}
                                                        // trigger={"onSelect"}
                                                        validateTrigger={'onSelect'}
                                                        rules={[{ required: true, message: 'this field is required!' },
                                                        item.field_name === "location" && {
                                                            message: "Selected Location does not exist",
                                                            validator: (_, value) => {
                                                                if (value && !woundLocations.find(item => item.replace(/\s{2,}/g, ' ').trim() === value.replace(/\s{2,}/g, ' ').trim())) {
                                                                    return Promise.reject('Selected Location does not exist!');
                                                                } else {
                                                                    return Promise.resolve();
                                                                }
                                                            }
                                                        }
                                                        ]}

                                                        wrapperCol={{ span: 24 }}>
                                                        {item.field_name === "location" ?
                                                            <AutoComplete popupMatchSelectWidth={false} disabled={procedure !== "Wound Evaluation"} style={{ width: "100%", borderRadius: "4px" }}
                                                                // notFoundContent="Couldn't find any matches!"
                                                                placeholder="search for wound location"
                                                                filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                                                                {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                                    <Option value={option}>{option}</Option>)}
                                                            </AutoComplete> :

                                                            item.field_name === 'previous_evaluation_date' ?
                                                                <Select onSelect={(e) => fetchLatestEvaluationDetails(dayjs(e).format("YYYY-MM-DD"))}>
                                                                    {assessmentDates.map(item => <Option value={item}>{dayjs(item).format("YYYY-MM-DD")}</Option>)}
                                                                </Select>


                                                                : item.type === 'dropdown' ? item.field_name === "procedure" ?
                                                                    <Input disabled={true} value={procedure} style={{ color: 'black' }} /> :
                                                                    <Select

                                                                        popupMatchSelectWidth={false}
                                                                        disabled={!!treatmentScenario || (procedure !== "Wound Evaluation" && (item.field_name === "wound_side" || item.field_name === "wound_position"))}
                                                                        style={{ width: "100%", borderRadius: "4px" }}
                                                                        onChange={(e) => {
                                                                            if (item.field_name === "location") {
                                                                                // setselectedwoundlocation(woundLocations.find(item => item === e).image)
                                                                            } else if (item.field_name === "procedure") {
                                                                                changeProcedure(e)
                                                                            }
                                                                        }}
                                                                        defaultValue={item.field_name === "procedure" ? procedure : ''}>
                                                                        {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                                            <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value}
                                                                                value={option.value}>{option.value}</Option>)}

                                                                    </Select> : item.type === "textfield" ?
                                                                    <Input autoComplete={'off'} placeholder={item.label} /> : item.type === 'select' ?
                                                                        <Select disabled={procedure !== "Wound Evaluation" && (item.field_name === "wound_side" || item.field_name === "wound_position")}
                                                                            popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                                                                            {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                                                <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value}
                                                                                    value={option.value}>{option.value}</Option>)}
                                                                        </Select> : item.type === "textArea" ? <TextArea rows={4} /> : null}
                                                    </Form.Item>
                                                    <div className={'flex flex-row'}><p
                                                        className={'text-xs font-semibold'}>{(item.label === 'Treatment Number' && !!woundDetails?.previous_treatment_number) ?
                                                            `Previous treatment number: ` : ''}</p>
                                                        <p className={'text-xs ms-2'}>{(item.label === 'Treatment Number' && !!woundDetails?.previous_treatment_number) ?
                                                            `${woundDetails?.previous_treatment_number || 'Unknown'}` : ''}</p>
                                                    </div>
                                                </div>
                                            </Col>)
                                        );
                                    })}
                                </Row>
                            </div>
                        </div>
                        <div className="p-4 flex flex-col flex-start w-full h-auto border rounded-xl shadow mt-4 mb-4">
                            <div className="flex flex-row w-full">
                                <Headline6 className="">Visit Information</Headline6>
                            </div>
                            <div className="" ref={summaryRef}>
                                <Form.Item
                                    label={allformvalues?.status === "Healed" && procedure === "Wound Evaluation" ? "Visit Summary" : "Visit Addendum"}
                                    style={{ width: '100%' }}
                                    name="visit_summary"
                                    onChange={(e) => setVisitSummary(e.target.value)}
                                    rules={[{ required: false, message: 'this field is required!' }]}
                                    wrapperCol={{ span: 24 }}>
                                    <TextArea
                                        rows={4}
                                        autoSize={{ minRows: 6 }}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="p-4 flex flex-col flex-start w-full h-auto border rounded-xl shadow mt-4 mb-4" style={{ height: "fit-content" }}>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="diagnosis"
                                        label="Diagnosis"
                                        rules={[{ required: false, message: 'Missing Diagnosis' }]}>
                                        <Select
                                            mode="multiple"
                                            popupMatchSelectWidth={false}
                                            // value={selectedProblem}
                                            style={{ width: '100%', marginBottom: '10px' }}
                                            placeholder="search for diagnosis">
                                            {diagnoses?.filter(item => item.name).map((item, index) => (
                                                <Option key={index} value={item.name}>
                                                    {item.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <Row gutter={16}>
                                {assessmentForm.filter(item => item.category === "Wound Measurements").map((item) => {
                                    return (<Col span={item.classes.includes('span-full') ? 24 : item.classes.includes('span-1/2') ? 12 : item.classes.includes('span-1/3') ? 8 : 12}>
                                        <Form.Item
                                            label={`${item.label.replace('New', '').replace("Other interventions", "Orders/Recommendations")}${item.label_unit ? item.label_unit : ''}`}



                                            name={item.field_name}
                                            rules={[{ required: true, message: 'this field is required!' }]}
                                            wrapperCol={{ span: 24 }}>
                                            {item.type === 'dropdown' ? <Select popupMatchSelectWidth={false} style={{ width: "100%", borderRadius: "4px" }}>
                                                {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                    <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                                            </Select> : item.type === "textfield" ?
                                                <InputNumber autoComplete={'off'} onChange={() => calculateVolume()} type="number" precision={2} placeholder={item.label} style={{
                                                    width: "100%", borderRadius: "4px"
                                                }} /> : item.type === 'select' ?
                                                    <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}> {item.options.map((option) => <Option
                                                        key={option.value}
                                                        value={option.value}>{option.value}</Option>)}
                                                    </Select> : item.type === "textfield" ?
                                                        <Input autoComplete={'off'} onChange={() => calculateVolume()} type="number" placeholder={item.label} /> : item.type === 'select' ?
                                                            <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}> {item.options.map((option) => <Option
                                                                key={option.value}
                                                                value={option.value}>{option.value}</Option>)}
                                                            </Select> : item.type === "textArea" ? <TextArea rows={4} /> : null}
                                        </Form.Item>
                                    </Col>)
                                })}
                            </Row>
                            {assessmentForm.find(item => item.category === "Post Procedure Wound Measurements") ?
                                <div><Subtitle1>Post Debridement Wound Measurements</Subtitle1> <Divider /></div> : null}
                            <Row gutter={16}>
                                {assessmentForm.filter(item => item.category === "Post Procedure Wound Measurements").map((item) => {
                                    return (<Col
                                        span={item.classes.includes('span-full') ? 24 : item.classes.includes('span-1/2') ? 12 : item.classes.includes('span-1/3') ? 8 : 12}>
                                        <Form.Item
                                            label={`${item.label.replace('New', '').replace("Other interventions", "Orders/Recommendations")}${item.label_unit ? item.label_unit : ''}`}



                                            name={item.field_name} rules={[{ required: true, message: 'this field is required!' }]} wrapperCol={{ span: 24 }}>
                                            {item.type === 'dropdown' ?
                                                <Select popupMatchSelectWidth={false} style={{
                                                    width: "100%", borderRadius: "4px"
                                                }}>                       {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                    <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                                                </Select> : item.type === "textfield" ? <Input autoComplete={'off'} placeholder={item.label} /> : item.type === 'select' ?
                                                    <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>  {item.options.map((option) => <Option
                                                        key={option.value}
                                                        value={option.value}>{option.value}</Option>)}
                                                    </Select> : item.type === "textfield" ? <Input type="number" autoComplete={'off'} placeholder={item.label} /> : item.label_unit ?
                                                        <InputNumber autoComplete={'off'} onChange={() => calculateVolume()} type="number" precision={2} placeholder={item.label} style={{
                                                            width: "100%", borderRadius: "4px"
                                                        }} /> : item.type === 'select' ?
                                                            <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}> {item.options.map((option) => <Option
                                                                key={option.value}
                                                                value={option.value}>{option.value}</Option>)}
                                                            </Select> : item.type === "textArea" ? <TextArea rows={4} /> : null}
                                        </Form.Item>
                                    </Col>)
                                })}
                            </Row>
                            {/*<Divider/>*/}
                            <Row gutter={16} id="#1">
                                {assessmentForm.filter(item => item.category === "Wound Assessment").map((item) => {
                                    return (<Col
                                        span={item.classes.includes('span-full') ? 24 : item.classes.includes('span-1/2') ? 12 : item.classes.includes('span-1/3') ? 8 : 12}>
                                        <Form.Item
                                            label={`${item.label.replace('New', '').replace("Other interventions", "Orders/Recommendations")}${item.label_unit ? item.label_unit : ''}`}



                                            name={item.field_name}
                                            rules={[{
                                                required: !['other_interventions', "other_related_factors", "referral_recommendations", 'billing_group'].includes(item.field_name),
                                                message: 'this field is required!'
                                            }]}
                                            wrapperCol={24}>
                                            {item.type === 'dropdown' ? <Select popupMatchSelectWidth={false} style={{ width: "100%", borderRadius: "4px" }}>
                                                {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                    <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                                            </Select> : item.field_name === 'billing_group' ?
                                                <Select>
                                                    {billingGroups.map(item => <Option value={item}>{item}</Option>)}
                                                </Select> : item.type === "textfield" && !item.label_unit ?
                                                    <Input autoComplete={'off'} placeholder={item.label} /> : item.label_unit ?
                                                        <InputNumber autoComplete={'off'} type="number" placeholder={item.label} style={{
                                                            width: "100%", borderRadius: "4px"
                                                        }} /> : item.type === 'select' ?
                                                            <Select mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                                                                {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                                    <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value}
                                                                        value={option.value}>{option.value}</Option>)}

                                                            </Select> : item.type === "textArea" ? <TextArea rows={4} /> : null}
                                        </Form.Item>
                                    </Col>)
                                })}
                            </Row>
                            {assessmentForm.find(item => item.category === "Vascular Measurements") ?
                                <div><Subtitle1>Vascular Measurements</Subtitle1> <Divider /></div> : null
                            }
                            <Row gutter={16}>
                                {assessmentForm.filter(item => item.category === "Vascular Measurements").map((item) => {
                                    return (<Col
                                        span={item.classes.includes('span-full') ? 24 : item.classes.includes('span-1/2') ? 12 : item.classes.includes('span-1/3') ? 8 : 12}>
                                        <Form.Item
                                            label={`${item.label.replace('New', '').replace("Other interventions", "Orders/Recommendations")}${item.label_unit ? item.label_unit : ''}`}



                                            name={item.field_name}
                                            rules={[{ required: true, message: 'this field is required!' }]}
                                            wrapperCol={{ span: 24 }}>
                                            {item.type === 'dropdown' ?
                                                <Select popupMatchSelectWidth={false} style={{
                                                    width: "100%", borderRadius: "4px"
                                                }}>                       {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                    <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                                                </Select> : item.type === "textfield" ? <Input
                                                    placeholder={item.label} /> : item.type === 'select' ?
                                                    <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                                                        {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                            <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value}
                                                                value={option.value}>{option.value}</Option>)}

                                                    </Select> : item.type === "textfield" ? <Input placeholder={item.label} /> : item.type === 'select' ?
                                                        <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                                                            {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                                <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value}
                                                                    value={option.value}>{option.value}</Option>)}
                                                        </Select> : item.type === "textArea" ? <TextArea rows={4} /> : null}
                                        </Form.Item>
                                    </Col>)
                                })}
                            </Row>

                            {assessmentForm.find(item => item.category === "Neurology Measurements") ? <div><Subtitle1>Neurology Measurements</Subtitle1> <Divider /></div> : null}
                            <Row gutter={16}>
                                {assessmentForm.filter(item => item.category === "Neurology Measurements").map((item) => {
                                    return (<Col span={item.classes.includes('span-full') ? 24 : item.classes.includes('span-1/2') ? 12 : item.classes.includes('span-1/3') ? 8 : 12}>
                                        <Form.Item
                                            label={`${item.label.replace('New', '').replace("Other interventions", "Orders/Recommendations")}${item.label_unit ? item.label_unit : ''}`}



                                            name={item.field_name} rules={[{ required: true, message: 'this field is required!' }]} wrapperCol={{ span: 24 }}>
                                            {item.type === 'dropdown' ?
                                                <Select popupMatchSelectWidth={false} style={{
                                                    width: "100%", borderRadius: "4px"
                                                }}>                       {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                    <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                                                </Select> : item.type === "textfield" ? <Input placeholder={item.label} /> : item.type === 'select' ?
                                                    <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                                                        {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                            <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value}
                                                                value={option.value}>{option.value}</Option>)}
                                                    </Select> : item.type === "textfield" ?
                                                        <Input placeholder={item.label} /> : item.type === 'select' ?
                                                            <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                                                                {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                                    <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value}
                                                                        value={option.value}>{option.value}</Option>)}

                                                            </Select> : item.type === "textArea" ? <TextArea rows={4} /> : null}
                                        </Form.Item>
                                    </Col>)
                                })}
                            </Row>
                            {assessmentForm.find(item => item.category === "Assessment Observations") ? <div><Subtitle1>Assessment Observations</Subtitle1> <Divider /></div> : null}
                            <Row gutter={16}>
                                {assessmentForm.filter(item => item.category === "Assessment Observations").filter(item => form.getFieldValue('treatment_number') === "Pre-treatment Assessment - Upper Extremity" ? item.field_name !== 'circulation' && item.field_name !== "neurologic_status" : item).map((item) => {
                                    return (<Col
                                        span={item.classes.includes('span-full') ? 24 : item.classes.includes('span-1/2') ? 12 : item.classes.includes('span-1/3') ? 8 : 12}>
                                        <Form.Item
                                            label={`${item.label.replace('New', '').replace("Other interventions", "Orders/Recommendations")}${item.label_unit ? item.label_unit : ''}`}


                                            name={item.field_name}
                                            rules={[{ required: true, message: 'this field is required!' }]}
                                            wrapperCol={{ span: 24 }}>
                                            {item.type === 'dropdown' ? <Select style={{ width: "100%", borderRadius: "4px" }}>
                                                {/*                       {masterList.find(option => option.system_name === (  item.field_name === 'location' ? 'wound_location' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option => <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}
   */}
                                                {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                    <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                                            </Select> : item.type === "textfield" ? <Input placeholder={item.label} /> : item.type === 'select' ?
                                                <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>

                                                    {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                        <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value}
                                                            value={option.value}>{option.value}</Option>)}

                                                </Select> : item.type === "textfield" ? <Input placeholder={item.label} /> : item.type === 'select' ?
                                                    <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                                                        {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                            <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value}
                                                                value={option.value}>{option.value}</Option>)}

                                                    </Select> : item.type === "textArea" ? <TextArea rows={4} /> : null}
                                        </Form.Item>
                                    </Col>)
                                })}
                            </Row>
                            {
                                assessmentForm.find(item => item.category === "Wound Debridement") ?
                                    <div><Subtitle1>Wound Debridement</Subtitle1> <Divider /></div> : null
                            }
                            <Row gutter={16}>
                                {assessmentForm.filter(item => item.category === "Wound Debridement").map((item) => {
                                    return (<Col
                                        span={item.classes.includes('span-full') ? 24 : item.classes.includes('span-1/2') ? 12 : item.classes.includes('span-1/3') ? 8 : 12}>
                                        <Form.Item
                                            label={`${item.label.replace('New', '').replace("Other interventions", "Orders/Recommendations")}${item.label_unit ? item.label_unit : ''}`}



                                            name={item.field_name}
                                            rules={[{ required: true, message: 'this field is required!' }]}
                                            wrapperCol={{ span: 24 }}>
                                            {item.type === 'dropdown' ?
                                                <Select popupMatchSelectWidth={false} style={{
                                                    width: "100%", borderRadius: "4px"
                                                }}>                       {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                    <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                                                </Select> : item.type === "textfield" ? <Input
                                                    placeholder={item.label} /> : item.type === 'select' ?
                                                    <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                                                        {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                            <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value}
                                                                value={option.value}>{option.value}</Option>)}

                                                    </Select> : item.type === "textfield" ? <Input placeholder={item.label} /> : item.type === 'select' ?
                                                        <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                                                            {masterList.find(option => option.system_name === ( item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                                                <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value}
                                                                    value={option.value}>{option.value}</Option>)}
                                                        </Select> : item.type === "textArea" ? <TextArea rows={4} /> : null}
                                        </Form.Item>
                                    </Col>)
                                })}
                            </Row>
                            {selectedProcedure === "DME" ?
                                <Form.Item
                                    label="Treatment Filter"
                                    style={{ width: '100%' }}
                                    name="extra_notes"
                                    rules={[{ required: true, message: 'this field is required!' }]}
                                    wrapperCol={{ span: 24 }}>
                                    <Select popupMatchSelectWidth={false}
                                        onChange={(e) => setExtraNotes(e)}
                                        style={{ width: "100%", borderRadius: "4px" }}>
                                        {masterList.find(option => option.system_name === 'treatment_filter')?.options?.map(option => <Option value={option}>{option}</Option>)}
                                    </Select>
                                </Form.Item> : null
                            }
                        </div>
                        {!treatmentScenario && selectedProcedure === "DME" ?
                            <Button htmlType="submit" className="login-form-button" style={{ width: "fit-content", float: 'right', marginBottom: "20px", color: "white" }}> Get
                                Treatment </Button> : null
                        }
                    </Col>
                    <Col span={10}>
                        <div className="w-full h-auto border rounded-xl shadow m-4 mr-4">
                            <div className="flex flex-row w-full ml-4 mt-2">
                                <Headline6 className="">Image and Location</Headline6>
                            </div>
                            {/** ----- */}
                            <Row className="p-2">
                                {assessmentForm?.filter(item => item.category === "Wound Location Image").map((item) => {
                                    return (
                                        <Col span={item.classes.includes('span-full') ? 24 : item.classes.includes('span-1/2') ? 12 : item.classes.includes('span-1/3') ? 8 : 12}>
                                            <div className="flex-boy-row-space">

                                                <div style={{ flex: 1, position: 'relative' }}>
                                                    {(fileList?.[0]?.url || woundImage?.url) &&
                                                        <div style={{ width: "100%", height: '75%' }}>
                                                            <Image src={fileList?.[0]?.url || woundImage?.url} alt="wouldloc" height={250} width={"100%"}
                                                                style={{ width: '100%', top: 0, position: 'relative' }} />
                                                            {(woundImage?.url || fileList[0]?.url) &&
                                                                <Button onClick={dialogOpener(setRotateImage)} icon={<RedoOutlined />} type={'dashed'} style={{ width: '100%', marginTop: '2px' }}>
                                                                    Rotate
                                                                </Button>}
                                                        </div>}
                                                    {(displayAnnotation || woundImage?.url || fileList[0]?.url) && allformvalues?.status !== "Healed" &&
                                                        <div className='w-full'>
                                                            <Button onClick={() => setwoundAnnotation(true)} style={{ marginTop: '4px' }}>Manual Annotation</Button>
                                                        </div>}
                                                </div>

                                                <div style={{ flex: 1, marginLeft: '10px', width: '100%' }}>
                                                    <Form.Item
                                                        label="Upload Type"
                                                        name="upload_type"
                                                        rules={[{ required: false, message: 'this field is required!' }]}>
                                                        <Radio.Group onChange={onChangeUploadType} value={upload_type}>
                                                            {allformvalues?.status !== "Healed" && <Radio value="generate">Generate Dimensions</Radio>}
                                                            <Radio value="manual">Manual upload</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    <Upload showUploadList={false} accept="image/*" customRequest={upload_type === "manual" ? manualUpload : getWoundimentions} listType="picture-card"
                                                        fileList={fileList}
                                                        onChange={onChange} onPreview={onPreview}>
                                                        {/*Allow replacement or uploaded image. Reusing the image preview on the left*/}
                                                        {'+ Upload'}
                                                    </Upload>
                                                    {validationerrors.wound_image ? <Body2 style={{ fontSize: '1em' }} color="crimson">Please add a wound image !</Body2> : null}
                                                </div>

                                                <div style={{ width: "75%", height: '100%', marginRight: '10px' }}><img ref={dummyRef} src={dummyImage || require("../../../assets/dummy.svg").default}
                                                    alt="wouldloc" onClick={() => setwoundDummy(true)} height={250} />
                                                    {validationerrors.wound_location ? <Body2 style={{ fontSize: '1em' }} color="crimson">Please select the wound location !</Body2> : null}
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                            {/** ----- */}
                        </div>
                        {(selectedProcedure.includes("Biologics") && showBiologic) && <div className="w-full border rounded-xl shadow m-4 px-4" ref={biologicsRef}>
                            <div className={'flexy-column gap-2 '}>
                                {!!woundDetails?.previous_product && <div>
                                    <Text>ⓘ Previous biologic product: <b>{woundDetails?.previous_product || 'Unknown'}</b></Text><br />
                                </div>}
                                <div className={'flexy-row'}>
                                    <Headline6>Biologics:</Headline6>
                                    {selectedProcedure !== "Biologics Application" &&
                                        <Dropdown destroyPopupOnHide={true} dropdownRender={() => graftMenu} trigger={['click']} disabled={false}>
                                            {secondaryButton(null, 'Recommend', recommending, 'Recommending',  null,{ endIcon: <CaretDownOutlined />})}
                                        </Dropdown>
                                    }
                                </div>
                                <Form.List name="kits" style={{ width: '100%' }}>
                                    {(fields, { add, remove }) => {
                                        return (<>
                                            {fields.map((field, index) => (
                                                <div className={'flexy-column'} key={field.key}>
                                                    <div className={'flexy-row flex-nowrap'}>
                                                        <Form.Item
                                                            {...field}
                                                            className={'flex-1'}
                                                            labelAlign={"right"}
                                                            name={[field.name, 'product']}
                                                            rules={[{ required: true, message: 'Missing product' }]}>
                                                            <AutoComplete popupMatchSelectWidth={false}
                                                                options={biologicProductOptions}
                                                                allowClear
                                                                onChange={(value, options) => handleKitChange(value, index)}
                                                                placeholder={"Search Biologic "}>
                                                            </AutoComplete>
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <Button size={"middle"} style={{ marginLeft: 10, alignSelf: 'center' }}
                                                                onClick={async () => {
                                                                    await setAssessmentKits(prevState => {
                                                                        prevState.splice(index, 1)
                                                                        return [...prevState]
                                                                    })
                                                                    remove(field.name)
                                                                }} shape={'circle'} icon={<DeleteOutlined />} />
                                                        </Form.Item>
                                                    </div>
                                                    {assessmentKits[index] && (() => {
                                                        let bioKits = getBiologicKits(index);
                                                        let canAdd = false
                                                        if (bioKits && bioKits.length) return <div className={'flexy-row'}>
                                                            <Form.List name={[field.name, 'biologic_kits']} style={{ marginBottom: "2px", flexGrow: 1 }}>
                                                                {(fields, { add, remove }) => (
                                                                    <>
                                                                        {fields.map((field, kitIndex) =>
                                                                            <div className={`flexy-row flex-nowrap px-1 py-2  ${procedure !== "Biologics Application" ? 'justify-start' : ''}`} key={field.key}>
                                                                                <Form.Item className={'mb-0'}
                                                                                    {...field}

                                                                                    name={[field.name, 'size']}
                                                                                    rules={[{ required: true, message: 'Missing size' }]}>
                                                                                    <Select popupMatchSelectWidth={false}
                                                                                        style={{ alignSelf: 'center' }}
                                                                                        placeholder="Graft size"
                                                                                        onChange={(value, options) => handleKitSizeChage(value, index, kitIndex)}>
                                                                                        {(() => {
                                                                                            return bioKits.map(item => <Option disabled={!item.is_available} key={item.size}
                                                                                                value={item.size}> {item.size} </Option>)
                                                                                        })()}
                                                                                    </Select>
                                                                                </Form.Item>

                                                                                {(procedure === "Biologics Application" && !!assessmentKits?.[index]?.biologic_kits?.[0]) &&
                                                                                    <>
                                                                                        <Form.List name={[field.name, 'ocr_data']}>
                                                                                            {(fields, { add, remove }) => (
                                                                                                <>
                                                                                                    {fields.map((field, ocrIndex) =>
                                                                                                        <Row key={field.key} gutter={[4, 1]} align={"stretch"} justify={"start"}
                                                                                                            className={'w-full self-center m-0'}>
                                                                                                            <Col xs={24} sm={24} md={12} lg={12} xl={12} flex={"auto"}>
                                                                                                                <Form.Item className={'mb-0'}
                                                                                                                    {...field}
                                                                                                                    shouldUpdate
                                                                                                                    name={[field.name, 'tissue_id']}
                                                                                                                    rules={[{ required: true, message: 'Missing tissue ID' }]}>
                                                                                                                    <Input
                                                                                                                        onChange={async (e) => {
                                                                                                                            let ocr_data = form.getFieldsValue().kits[index].biologic_kits[kitIndex].ocr_data
                                                                                                                            onTissueAndSerialNoChange(ocr_data, index, kitIndex, assessmentKits, setAssessmentKits, ocrIndex, form).catch(appLog);
                                                                                                                        }}
                                                                                                                        autoComplete={'off'} placeholder="Tissue ID" />
                                                                                                                </Form.Item>
                                                                                                            </Col>
                                                                                                            <Col xs={24} sm={24} md={12} lg={12} xl={12} flex={"auto"} style={{ margin: 0 }}>
                                                                                                                <Form.Item className={'mb-0'}
                                                                                                                    {...field}
                                                                                                                    shouldUpdate
                                                                                                                    name={[field.name, 'serial_number']}
                                                                                                                    rules={[{ required: true, message: 'Missing serial No' }]}>
                                                                                                                    <Input
                                                                                                                        onChange={async e => {
                                                                                                                            let ocr_data = form.getFieldsValue().kits[index].biologic_kits[kitIndex].ocr_data
                                                                                                                            onTissueAndSerialNoChange(ocr_data, index, kitIndex, assessmentKits, setAssessmentKits, ocrIndex, form).catch(appLog);
                                                                                                                        }}
                                                                                                                        autoComplete={'off'} placeholder="Serial No." />
                                                                                                                </Form.Item>
                                                                                                            </Col>
                                                                                                        </Row>)}
                                                                                                </>)}
                                                                                        </Form.List>
                                                                                        <Form.Item className={'self-center mb-0'}>
                                                                                            <Tooltip title={'Upload allograft report'} placement="bottom">
                                                                                                <Upload children={<Button size={"middle"} loading={(uploadLoading[0] === index && uploadLoading[1] === kitIndex)}
                                                                                                    disabled={(uploadLoading[0] === index && uploadLoading[1] === kitIndex)}
                                                                                                    style={{ alignSelf: 'center' }} type={'text'} shape={'circle'} icon={<CloudUploadOutlined />} />}
                                                                                                    showUploadList={false}
                                                                                                    accept="image/*,.pdf" customRequest={(options) => {
                                                                                                        getGraftDetails(options, index, kitIndex, setUploadLoading, assessmentKits, setAssessmentKits, form).catch(appLog)
                                                                                                    }}>
                                                                                                </Upload>
                                                                                            </Tooltip>
                                                                                        </Form.Item>
                                                                                    </>
                                                                                }
                                                                                {(assessmentKits[index] && assessmentKits[index].biologic_kits && assessmentKits[index].biologic_kits.length > 1) &&
                                                                                    <Form.Item className={'self-center mb-0'}>
                                                                                        <Button size={"middle"} className={'self-center'}
                                                                                            onClick={async () => {
                                                                                                await setAssessmentKits(prevState => {
                                                                                                    let current = [...prevState]
                                                                                                    current[index].biologic_kits.splice(kitIndex, 1)
                                                                                                    return current
                                                                                                })
                                                                                                remove(field.name)
                                                                                            }} shape={'circle'} type={'text'} icon={<MinusCircleOutlined />} />
                                                                                    </Form.Item>
                                                                                }
                                                                            </div>)}
                                                                        <Form.Item style={{ marginBottom: '5px', alignSelf: 'center', marginLeft: '2px' }}> {addButton(() => {
                                                                            if (maxUnitAreaWarnings.some(value => value.includes('exceeds Max Unit Area limit of'))) {
                                                                                appMessage(`The maximum allowable usage (MUE) for this specific patient has been met for ${assessmentKits[index].product}. Please consider an alternative treatment, such as DME kits containing collagen.`, 'error')
                                                                                if (!maxUnitAreaWarnings.some(value => value.includes('Please consider an alternative treatment'))) {
                                                                                    setMaxUnitAreaWarnings(prevState => [...prevState, `The maximum allowable usage (MUE) for this specific patient has been met for ${assessmentKits[index].product}. Please consider an alternative treatment, such as DME kits containing collagen.`])
                                                                                }
                                                                                return
                                                                            } else {
                                                                                //Remove error message if kit is removed and MUE error is nolonger necessary
                                                                                let errorMessageIndex = maxUnitAreaWarnings.findIndex(value => value.includes('The maximum allowable usage (MUE)'))
                                                                                if (errorMessageIndex !== -1) {
                                                                                    let currentUnitAreaWarnings = [...maxUnitAreaWarnings]
                                                                                    currentUnitAreaWarnings.splice(errorMessageIndex, 1)
                                                                                    setMaxUnitAreaWarnings(currentUnitAreaWarnings)
                                                                                }
                                                                            }
                                                                            add()
                                                                        }, 'gray')} </Form.Item>
                                                                    </>)}
                                                            </Form.List>

                                                        </div>
                                                    })()}
                                                </div>))}
                                            {/*{<Form.Item className={'w-full self-center'}>
                                                    <Button
                                                        type="dashed" onClick={() => add()}
                                                        block
                                                        icon={<PlusOutlined />}>Add Biologic</Button></Form.Item>}*/}
                                        </>);
                                    }}
                                </Form.List>
                                {validationerrors.biologic_products && <Body2 style={{ fontSize: '1em' }} color="crimson">Missing biologic!</Body2>}
                            </div>
                            {
                                (maxKitArea - maxArea !== 0) &&
                                <Body2 style={{ fontSize: '1em' }}
                                    color="crimson">{(maxKitArea - maxArea < 0) ? kitAmountMessage : `Limit warning! Exceeding wound size by ${roundToDp(maxKitArea - maxArea, 2)}cm²`}
                                    <br /><br /></Body2>
                            }
                            {!!maxUnitAreaWarnings[0] && maxUnitAreaWarnings.map(item => <Body2 style={{ fontSize: '1em' }} color="crimson">{item} <br /></Body2>)}
                            <Divider />
                        </div>}

                        <div className="w-full h-auto border rounded-xl shadow m-4 mr-4">
                            <div className="w-full h-auto">
                                {treatmentScenario && procedure === "DME" ?
                                    <div className="p-4" ref={treatmentRef}>
                                        <div style={{ width: "100%" }} className="flex flex-col">
                                            <Headline6>Treatment Scenario</Headline6>

                                            <div style={{ width: '100%' }} className="flex-boy-row-space">
                                                <Subtitle1>{treatmentprotocol && treatmentprotocol.protocol_name}</Subtitle1>
                                                {!treatmentprotocol && !changeProtocol && addButton(() => setnewTreatmentKit(true))}
                                                {treatmentprotocol && <div onClick={() => setnewTreatmentKit(true)}>
                                                    <FontAwesomeIcon color="grey" icon={["fas", "pencil-alt"]} />
                                                </div>}
                                            </div>
                                        </div>
                                        <div style={{ width: "100%" }}>
                                            {validationerrors.treatmentprotocol ?
                                                <Body2 style={{ fontSize: '1em' }} color="crimson">Please select a treatment Protocol!</Body2> : null}
                                            <Headline6>Treatment Order Items</Headline6>

                                            <Headline6 color="grey" style={{ marginBottom: "10px", marginTop: '10px' }}>Primary Dressing</Headline6>
                                            {treatmentorderItems?.filter(item => item.type === "Primary Dressing").map((item, index) => <div key={index} className="order-item flex-boy-row-space">
                                                <div className="flex-boy-row-space" style={{
                                                    flex: 1, background: "#F1F2F5", border: "1px solid #F1F2F5", boxSizing: "border-box",
                                                    borderRadius: "4px", minHeight: "30px", height: 'fit-content'
                                                }}><Subtitle1 className='text[#6B6C7E] float-left'>{item.name}</Subtitle1></div>
                                                <div className="flex-boy-row-space" style={{
                                                    background: "#F1F2F5", border: "1px solid #F1F2F5", boxSizing: "border-box",
                                                    borderRadius: "4px", minHeight: "30px", height: 'fit-content', width: '100px'
                                                }}><Subtitle1>{item.pack_amount || item.default_pack_amount}{" "}{item.unit_of_measure}</Subtitle1></div>
                                                <div style={{ width: "80px", marginLeft: "20px" }}>
                                                    <Select trigger={['click']} className="w-full" defaultValue={item.unit_count}
                                                        disabled={treatmentprotocol?.protocol_name !== 'Custom Kit'}
                                                        onSelect={(e) => unitChange(item, index, e)}>
                                                        {GetNumericalListOptions(item.unit_count)}
                                                    </Select>
                                                </div>
                                                {treatmentprotocol?.protocol_name === 'Custom Kit' &&
                                                    <FontAwesomeIcon onClick={() => removeTreatmentItem(item)} style={{ marginLeft: "10px" }} color="grey" icon={["fas", "trash-alt"]} />}
                                            </div>)}
                                            {newTreatmentSupply && !treatmentorderItems?.filter(item => item.type === "Primary Dressing").length ? <AutoComplete
                                                popupMatchSelectWidth={false}
                                                style={{ width: 400, marginBottom: "20px" }}
                                                onSelect={onPrimarysupplySelect}
                                                onSearch={handlesupplysearch}
                                                placeholder="Search for supply"
                                            // filterOption={(inputValue, option) =>
                                            //     option.protocol_name.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            //   }
                                            >
                                                {treatmentSupplies.map(item => (<Option key={item.name} value={item.name}>
                                                    {item.name}
                                                </Option>))}
                                            </AutoComplete> : null}
                                                {(!treatmentprotocol || treatmentprotocol?.protocol_name === 'Custom Kit') &&
                                                <RegularButton style={{
                                                    width: "fit-content",
                                                    background: 'white',
                                                    borderRadius: '4px',
                                                    height: '40px',
                                                    border: '1px solid #CDCED9',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }} onClick={() => setnewTreatmentSupply(true)}>
                                                    <FontAwesomeIcon style={{ marginLeft: "10px", marginRight: '15px' }} color="#6B6C7E" icon={["fas", "plus"]} />
                                                    <p className="text-[#6B6C7E] font-bold mt-4">Add Primary Treatment Item</p>
                                                </RegularButton>}
                                            <Headline6 color="grey" style={{ marginBottom: "10px", marginTop: '10px' }}>Secondary Dressing</Headline6>
                                            {treatmentorderItems.filter(item => item.type === "Secondary Dressing").map((item, index) =>
                                                <div key={index} className="order-item flex-boy-row-space">
                                                    <div className="flex-boy-row-space" style={{
                                                        flex: 1, background: "#F1F2F5", border: "1px solid #F1F2F5", boxSizing: "border-box",
                                                        borderRadius: "4px", minHeight: "30px", height: 'fit-content'
                                                    }}><Subtitle1 className="text[#6B6C7E]">{item.name}</Subtitle1></div>
                                                    <div className="flex-boy-row-space" style={{
                                                        background: "#F1F2F5", border: "1px solid #F1F2F5", boxSizing: "border-box",
                                                        borderRadius: "4px", minHeight: "30px", height: 'fit-content', width: '100px'
                                                    }}><Subtitle1>{item.pack_amount || item.default_pack_amount}{" "}{item.unit_of_measure}</Subtitle1></div>
                                                    <div style={{ width: "80px", marginLeft: "20px" }}>
                                                        <Select disabled={treatmentprotocol?.protocol_name !== 'Custom Kit'} trigger={['click']} className="w-full" defaultValue={item.unit_count}
                                                            onSelect={(e) => unitChange(item, index, e)}>
                                                            {GetNumericalListOptions(item.unit_count)}
                                                        </Select>
                                                    </div>
                                                    {treatmentprotocol?.protocol_name === 'Custom Kit' &&
                                                        <FontAwesomeIcon onClick={() => removeTreatmentItem(item)} style={{ marginLeft: "10px" }} color="grey" icon={["fas", "trash-alt"]} />}
                                                </div>)}
                                            {validationerrors.treatmentorderItems ?
                                                <Body2 style={{ fontSize: '1em' }} color="crimson">Please ensure all Treatment order items have
                                                    values!</Body2> : null}
                                            {newTreatmentSupply ? <AutoComplete popupMatchSelectWidth={false}
                                                style={{ width: 400, marginBottom: "20px" }}
                                                onSelect={onsupplySelect}
                                                onSearch={handlesupplysearch}
                                                placeholder="Search for supply">
                                                {treatmentSupplies.map(item => (<Option key={item.name} value={item.name}> {item.name} </Option>))}
                                            </AutoComplete> : null}
                                            {(!treatmentprotocol || treatmentprotocol?.protocol_name === 'Custom Kit') &&
                                                <RegularButton style={{
                                                    width: "fit-content",
                                                    background: 'white',
                                                    borderRadius: '4px',
                                                    height: '40px',
                                                    border: '1px solid #CDCED9',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                                    onClick={() => setnewTreatmentSupply(true)}>
                                                    <FontAwesomeIcon style={{ marginLeft: "10px", marginRight: '15px' }} color="#6B6C7E" icon={["fas", "plus"]} />
                                                    <p className="text-[#6B6C7E] font-bold mt-4">Add Secondary Treatment order items</p>
                                                </RegularButton>
                                            }
                                            <Divider />
                                            <Headline6 color='grey' style={{ marginBottom: "10px", marginTop: '10px' }} >Additional Supplies</Headline6>
                                            {treatmentorderItems.filter(item => item.type === "Additional Dressing").map((item, index) =>
                                                <div key={index} className="order-item flex-boy-row-space">
                                                    <div className="flex-boy-row-space" style={{
                                                        flex: 1, background: "#F1F2F5", border: "1px solid #F1F2F5", boxSizing: "border-box",
                                                        borderRadius: "4px", minHeight: "30px", height: 'fit-content'
                                                    }}><Subtitle1 className='text[#6B6C7E]'>{item.name}</Subtitle1></div>
                                                    <div className="flex-boy-row-space" style={{
                                                        background: "#F1F2F5", border: "1px solid #F1F2F5", boxSizing: "border-box",
                                                        borderRadius: "4px", minHeight: "30px", height: 'fit-content', width: '100px'
                                                    }}><Subtitle1>{item.pack_amount || item.default_pack_amount}{" "}{item.unit_of_measure}</Subtitle1></div>
                                                    <div style={{ width: "80px", marginLeft: "20px" }}>
                                                        <Select trigger={['click']} className='w-full' defaultValue={item.unit_count}
                                                            onSelect={(e) => unitChange(item, index, e)} >
                                                            {GetNumericalListOptions(10)}
                                                        </Select>
                                                    </div>
                                                    <FontAwesomeIcon onClick={() => removeTreatmentItem(item)} style={{ marginLeft: "10px" }} color="grey" icon={["fas", "trash-alt"]} />
                                                </div>)
                                            }
                                            {validationerrors.treatmentorderItems ?
                                                <Body2 style={{ fontSize: '1em' }} color="crimson">Please ensure all Treatment order items have
                                                    values!</Body2> : null}
                                            {additionalTreatmentSupply ? <AutoComplete popupMatchSelectWidth={false}
                                                style={{ width: 400, marginBottom: "20px" }}
                                                onSelect={onAdditionalSupplySelect}
                                                onSearch={handleadditionalsupplysearch}
                                                placeholder="Search for supply">
                                                {treatmentSupplies.map(item => (<Option key={item.name} value={item.name}> {item.name} </Option>))}
                                            </AutoComplete> : null}
                                            {<RegularButton style={{ width: "fit-content", background: 'white', borderRadius: '4px', height: '40px', border: '1px solid #CDCED9', display: 'flex', alignItems: 'center' }}
                                                onClick={() => setAdditionalTreatmentSupply(true)}>
                                                <FontAwesomeIcon style={{ marginLeft: "10px", marginRight: '15px' }} color="#6B6C7E" icon={["fas", "plus"]} />
                                                <p className='text-[#6B6C7E] font-bold mt-4'>Add Additional Supplies</p>
                                            </RegularButton>}
                                            <Divider />

                                            <Headline6>Plan of Care</Headline6>
                                            {planofcareItems && planofcareItems.length ? <div className="flex-boy-row-space">
                                                <Checkbox checked={allPlansofCare} disabled={treatmentprotocol?.protocol_name !== 'Custom Kit'}
                                                    onChange={(e) => selectAllPlanItems(e.target.checked)}> </Checkbox>
                                                <div className="flex-boy-row-space"
                                                    style={{ flex: 1, boxSizing: "border-box", borderRadius: "4px", height: "30px" }}><Subtitle1>Select
                                                        All</Subtitle1></div>
                                            </div> : null}

                                            {_.orderBy(planofcareItems, "step_number_order").map((step, index) => <div key={index} className="order-item flex-boy-row-space">
                                                <Checkbox disabled={treatmentprotocol?.protocol_name !== 'Custom Kit'} checked={step.checked}
                                                    onChange={(e) => checkStep(e.target.checked, step)}> </Checkbox>
                                                <div className="flex-boy-row-space"
                                                    style={{ flex: 1, background: "#F1F2F5", border: "1px solid #F1F2F5", boxSizing: "border-box", borderRadius: "4px", height: "30px" }}>
                                                    <Subtitle1>{step.step}</Subtitle1></div>
                                                {treatmentprotocol?.protocol_name === 'Custom Kit' &&
                                                    <FontAwesomeIcon onClick={() => removeplanOfCareItem(step)} style={{ marginLeft: "10px" }} color="grey" icon={["fas", "trash-alt"]} />}
                                            </div>)}

                                            {validationerrors.planofcareItems ?
                                                <Body2 style={{ fontSize: '1em' }} color="crimson">Please check atleast one plan of care
                                                    item!</Body2> : null}
                                            {newPlanofCare ? <AutoComplete
                                                popupMatchSelectWidth={false}
                                                style={{ width: 400, marginBottom: "20px" }}
                                                onSelect={onplanofCareSelect}
                                                onSearch={handleplanofCaresearch}
                                                placeholder="Search for plan of care step"
                                            // filterOption={(inputValue, option) =>
                                            //     option.protocol_name.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            //   }
                                            >
                                                {planofcareSearch.map(item => (<Option key={item.step} value={item.step}>
                                                    {item.step}
                                                </Option>))}
                                            </AutoComplete> : null}
                                            {treatmentprotocol?.protocol_name === 'Custom Kit' && <RegularButton style={{
                                                width: "fit-content",
                                                background: 'white',
                                                borderRadius: '4px',
                                                height: '40px',
                                                border: '1px solid #CDCED9',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                                onClick={() => setnewPlanofCare(true)}>
                                                <FontAwesomeIcon style={{ marginLeft: "10px", marginRight: '15px' }} color="#6B6C7E" icon={["fas", "plus"]} />
                                                <p className="text-[#6B6C7E] font-bold mt-4">Add Plan of care Item</p>
                                            </RegularButton>}
                                        </div>
                                    </div> : null
                                }
                                {procedure.includes("Lab") ?

                                    (<div className="card-box flex-boy-row-space">
                                        <Subtitle1 style={{ marginBottom: "20px", textAlign: 'left', width: "100%" }}>Lab PCR Test:</Subtitle1>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Sample ID"
                                                    name={`sample_id`}
                                                    rules={[{ required: true, message: 'this field is required!' }]}
                                                    wrapperCol={{ span: 24 }}

                                                >

                                                    <Input
                                                        placeholder="Sample Id" />

                                                </Form.Item>

                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="PCR Type"
                                                    name={`pcr_type`}

                                                    rules={[{ required: true, message: 'this field is required!' }]}
                                                    wrapperCol={{ span: 24 }}

                                                   
                                                >
                                                    <Select mode='multple' disabled={true} onChange={e => handleLabChange("pcr_type", e, 0)} style={{
                                                        width: "100%", borderRadius: "4px"
                                                    }}>
                                                        {masterList.find(option => option.system_name === 'p_c_r_type')?.options?.map(item => <Option value={item}>{item}</Option>)}
                                                    </Select>

                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Lab region"
                                                    name={`lab_region`}
                                                    rules={[{ required: true, message: 'this field is required!' }]}
                                                    wrapperCol={{ span: 24 }}


                                                >
                                                    <Select onChange={e => handleLabChange("lab_region", e, 0)} style={{
                                                        width: "100%", borderRadius: "4px"
                                                    }}>
                                                        {masterList.find(option => option.system_name === 'lab_region')?.options?.map(item => <Option value={item}>{item}</Option>)}
                                                    </Select>

                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item
                                                    label="Intake Date"
                                                    name={`intake_date`}
                                                    disabled
                                                    rules={[{ required: false, message: 'this field is required!' }]}
                                                    wrapperCol={{ span: 24 }}
                                                    initialValue={dayjs(appointment.start_at, "YYYY-MM-DD")}


                                                >
                                                    <DatePicker disabled onChange={e => handleLabChange("intake_date", dayjs(e).format("YYYY-MM-DD"), 0)} format={"YYYY-MM-DD"} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                    )
                                    : null
                                }
                            </div>
                        </div>
                    </Col>
                </Row></Form> :
                <div className="card-box flex-boy-row-space" style={{ alignItems: "center", justifyContent: "center" }}>
                    <Spin tip="Loading form..."></Spin>
                </div>}
        </div>
    );
}
const mapStateToProps = (state) => ({
    masterList: state.masters.masterListRaw,
    procedures: state.appointments.procedures,
    woundLocations: state.appointments.woundLocations,
    masterListRaw: state.masters.masterListRaw,
    diagnoses: state.patients.healthData.diagnosis,
    labRegions: state.appointments.labRegions,
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(AssessmentCard)
