/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Body1, RegularButton, Subtitle1} from '../../../../components'
import {Checkbox, Col, Divider, Dropdown, Form, Modal, Row, Select, Skeleton, Spin} from 'antd';
import dayjs from 'dayjs';
import {CaretDownOutlined, LoadingOutlined} from '@ant-design/icons';
import {apiRequest} from '../../../../app/Apis';
import {
  appLog,
  boldKeyCapitalizedValue,
  boldKeyCapitalizedValueGrayBackground,
  dialogCloser,
  editButton,
  emptyFun,
  formSubmitButton,
  getOrderIndicator,
  getOrderIndicatorColor,
  GetRawListOptions,
  horStack,
  makeSelectOptions,
  modalClose,
  outlinedButton,
  textButton
} from "../../../../common/helpers";
import {errMsg, getToastId, updateToast} from "../../../../app/Reducers/reducerUtils";
import {fetchOrderNotes, fetchSpecificOrder, selectAssessment, updateOrder, updateTreatmentOrderStatus} from '../../../../app/Reducers';
import ShippingAddress from '../OrderForms/ShippingAddress';

import EditAssessmentCard from '../../../Appointments/components/EditAssessmentCard';
import _ from 'lodash'
import * as Sentry from "@sentry/react";
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import DynamicForm from '../../../../components/DynamicForm';
import OrderTimeLine from './OrderTimeLine';
import TrackingInformation from './OrderTracking';
import SetCookie from '../../../../hooks/setCookie';
import GetCookie from '../../../../hooks/GetCookie';
import CreateOrder from '../OrderForms/CreateOrder';


const OrderPreview = ({ orderTracking, orderTrackingLoading, order, patient, consultant, navs, masterList, selectedPatient, can_update_order_status }) => {
  const { Option } = Select;
  const dispatch = useDispatch()
  const treatmentRef = useRef()
  const [statusesForm] = Form.useForm();
  const [form] = Form.useForm();
  const [initialValues, setinitialValues] = useState({ ...order })
  const [treatmentorderItems, setTreatmentorderItems] = useState(order?.dme_supplies.map(item => ({ units: item.unit_count })))
  const [showStatusForm, setShowStatusForm] = useState(false);
  const [validationerrors, setvalidationerrors] = useState({ supplyItems: false, delivery_location: false })
  // biologic products
  const [biologicsProducts, setbiologicsProducts] = useState([])
  const [assessmentKits, setAssessmentKits] = useState([]);
  const [selectableProducts, setSelectableProducts] = useState([]);
  const [formValues, setsFormValues] = useState({ ...order })
  const [newOrderStatus, setNewOrderStatus] = useState(order?.status);
  // PPE treatment supplies
  const [ppetreatmentorderItems, setppeTreatmentorderItems] = useState([...order.ppe_kits])
  const [newppeTreatmentSupply, setnewppeTreatmentSupply] = useState(false)
  const [ppetreatmentSupplies, setppeTreatmentSupplies] = useState([])
  const [availableStatuses, setavailableStatuses] = useState([])
  const [isExecuting, setisExecuting] = useState(false)
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [changeOrderForm, setChangeOrderForm] = useState(false)
  const [woundAssessment, setWoundAssessment] = useState(null)
  const [editassessmentModal, seteditassessmentModal] = useState(false)
  const can_update_treatment_order = usePermissionCheck('update_treatment_order', false, [])
  const [isOpen, setOpen] = useState(false)
  const [menuOptions, setMenuOptions] = useState([]);
  
  useEffect(() => {
    console.log({ menuOptions, can_update_treatment_order, can_update_order_status, order })
    if (!!availableStatuses[0]) {
      let statuses = availableStatuses.map(item => ({
        key: item,
        icon: null,
        label: <p className={`text-start capitalize my-auto`}>{item.replace('-', '').replace(/_/g, ' ')}</p>,
        disabled: (!can_update_treatment_order || !can_update_order_status)
      }))
      console.log({ statuses })
      setMenuOptions(statuses)
    }
    return emptyFun
  }, [can_update_treatment_order, can_update_order_status, availableStatuses])
  useEffect(() => {
    fetchBiologicsKit().catch(appLog)
    return emptyFun
  }, [])
  
  useEffect(() => {
    getStatuses().catch(appLog)
    dispatch(fetchOrderNotes({ id: order?.id }))
    setTreatmentorderItems(order?.dme_supplies.map(item => ({ ...item, units: item.unit_count })))
    setsFormValues({ ...order })
    setinitialValues({ ...order })
    setNewOrderStatus(order?.status)
    if (order.visit_type === "Biologics") {
      // fetchBiologicsKit().catch(appLog)
    } else {
      form.setFieldsValue({ ...order })
    }
    
    return emptyFun
    
  }, [order])
  
  
  const fetchBiologicsKit = async () => {
    const { data } = await apiRequest.get(`/biologic-products`)
    if (data) {
      await setbiologicsProducts(data.data)
      await setSelectableProducts(data.data.map(item => `${item.company_name} ${item.product_name}`))
      if (order?.biologic_kits?.length) {
        let productNames = []
        let assessmentKits_ = []
        for (const bioKit of order?.biologic_kits) {
          let name = `${bioKit.biologic_product.company_name} ${bioKit.biologic_product.product_name}`
          if (!productNames.includes(name)) {
            let productKits = order?.biologic_kits.filter(item => `${item.biologic_product.company_name} ${item.biologic_product.product_name}` === name)
            let biologic_kits = productKits.map(item => ({ id: item.id, size: item.size, quantity: item.quantity }))
            assessmentKits_ = [...assessmentKits_, { product: name, biologic_kits }]
            productNames.push(name)
          }
        }
        //console.log("🏥", assessmentKits_,)
        await setAssessmentKits([...assessmentKits_])
        setsFormValues(prevState => {
          prevState.kits = assessmentKits_
          return { ...order }
        })
        form.setFieldsValue({ ...order, kits: assessmentKits_ })
      }
    }
  }
  
  
  const getStatuses = async () => {
    let { data } = await apiRequest.get(`/treatment-order-statuses?order_state=${(order?.visit_type === 'Biologics' && order?.status === "approved") ? "final" :
      (['DME', 'Biologics'].includes(order?.visit_type) && navs.orderNavs.tab !== 'preorders') ? 'post-order' : order?.order_state?.replace(/\s+/g, '-')}&current_order_status=${order.status}`)
    if (data && data.data) {
      let statuses = data.data
      console.log({ statuses })
      await setavailableStatuses(statuses)
    }
  }
  
  const editTreatmentOrderStatus = async (values_) => {
    const { patient_id, id } = order
    let payload = {
      patient_id,
      id,
      status: newOrderStatus,
      ...values_.values
    }
    await setUpdatingStatus(true)
    await dispatch(updateTreatmentOrderStatus(payload));
    await setShowStatusForm(false)
    await setUpdatingStatus(false)
    
  }
  const updateTreatmentOrder = async (values_) => {
    let payload = {
      ...order, status: newOrderStatus, biologic_kits: order.biologic_kits.map(kit => ({
        "id": kit.id,
        "biologic_product_id": kit.biologic_product.id,
        "quantity": kit.quantity
      })),
      ...values_.values
    }
    if (order.visit_type === "Internal") {
      if (order?.internal_order_resource_type === "DME") {
        payload.dme_supplies = treatmentorderItems.map(item =>
          (
            {
              id: item.id,
              unit_count: item.unit_count,
              pack_amount: item.pack_amount || item.default_pack_amount,
              type: "Primary Dressing"
            }
          ))
        
      } else {
        payload.ppe_kits = ppetreatmentorderItems.map(item =>
          (
            {
              id: item.id,
              unit_count: item.unit_count
            }
          ))
      }
      
    }
    await dispatch(updateOrder(_.omitBy(payload, v => _.isNil(v) || (_.isArray(v) && !v.length))));
    await setShowStatusForm(false)
  }
  
  const openAssessment = () => {
    let caution = GetCookie("order_caution")
    if (caution) {
      showassessmnt().catch(appLog)
    } else {
      setChangeOrderForm(true)
    }
    
  }
  const ondontshowChange = (e) => {
    SetCookie("order_caution", e.target.checked)
  };
  const [orderToEdit, setOrderToEdit] = useState(null)
  const editSelectedOrder = async (supplies) => {
    let defaultConsultantAddress = consultant?.addresses?.find(item => item.default)
    let consultantName = consultant.name || `${consultant?.first_name || ''} ${consultant?.last_name || ''}`
    let orderToEdit_ = {
      consultant: { ...consultant, name: consultantName },
      id: order.id,
      wcc: consultantName,
      reason: order.reason,
      consultant_id: consultant.id,
      internal_order_resource_type: order?.internal_order_resource_type,
      date_of_service: dayjs(order.date_of_service).format("YYYY-MM-DD HH:mm:ss"),
      shipping_address_1: defaultConsultantAddress.street_address,
      state: defaultConsultantAddress.state,
      city: defaultConsultantAddress.city,
      shipping_address_2: defaultConsultantAddress.street_address,
      visit_type: order?.visit_type,
      ppe_kits: ppetreatmentorderItems,
      dme_supplies: treatmentorderItems
      
    }
    await setOrderToEdit(orderToEdit_)
    await setOpen(true)
  }
  const showassessmnt = async () => {
    setChangeOrderForm(false)
    if (!order?.assessment_id) {
      // appMessage('Associated assessment not found', 'error')
      editSelectedOrder()
      return
    }
    // await setAllergiesForm(true)
    let toastId = getToastId('Loading assessment')
    try {
      
      const encounters = await apiRequest.get(`/assessments/${order?.assessment_id}`)
      let wAssessment = encounters.data.data
      await setWoundAssessment(wAssessment)
      updateToast(toastId, 'Success', true)
      if (!wAssessment) {
        updateToast(toastId, errMsg("Order Assessment", true, null, 'No assessment was found for this wound'), false)
      } else {
        await dispatch(selectAssessment(wAssessment))
        await seteditassessmentModal(true)
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e)
      updateToast(toastId, errMsg(e, true, 'wound assessment'), false)
    }
  }
  const statusesRequiringReason = ['cancelled', 'needs review', 'returned', 'label created', 'warehouse alert']
  const updateOrderInfo = async (key) => {
    await setNewOrderStatus(key);
    // I assume somewhere in your code newOrderStatus is updated,
    // either in setNewOrderStatus or elsewhere.
    console.log(newOrderStatus);
    if (!statusesRequiringReason.includes(key)) {
      await editTreatmentOrderStatus({ values: { status: key } });
    }
  }
  let returnOptions = makeSelectOptions(GetRawListOptions('return_reason'))
  let CancellationOptions = makeSelectOptions(GetRawListOptions('cancellation_reason'))
  let statusesInputFields = {
    fields: [
      //Show reason field only for statuses that require status from the backend
      newOrderStatus === 'cancelled' && {
        label: `Cancellation reason`,
        name: 'cancellation_reason', required: true, type: 'select', options: CancellationOptions, fullRow: true,
        props: { style: { backgroundColor: '#F7F8F9' } }
      },
      newOrderStatus === 'needs review' && {
        label: "Review Request Note",
        name: 'review_request_note', required: true, type: 'textarea', fullRow: true,
        props: { style: { backgroundColor: '#F7F8F9' } }
      },
      newOrderStatus === 'returned' && {
        label: "Return Reason",
        name: 'return_reason', required: true, type: 'select', options: returnOptions, fullRow: true,
        props: { style: { backgroundColor: '#F7F8F9' } }
      },
      newOrderStatus === 'label created' && {
        label: 'Tracking Number',
        name: 'tracking_number', required: true, type: 'text', options: returnOptions, fullRow: true,
        props: { style: { backgroundColor: '#F7F8F9' } }
      },
      newOrderStatus === 'warehouse alert' && {
        label: 'Sales Order Number',
        name: 'sales_order_number', required: true, type: 'text', options: returnOptions, fullRow: true,
        props: { style: { backgroundColor: '#F7F8F9' } }
      }
    ]
  }
  return <>
    <Modal
      title={"Change Order"}
      open={changeOrderForm}
      onOk={dialogCloser(setChangeOrderForm)}
      destroyOnClose
      onCancel={dialogCloser(setChangeOrderForm)}
      closeIcon={modalClose(dialogCloser(setChangeOrderForm))}
      maskClosable={false}
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        {horStack([
          outlinedButton(dialogCloser(setChangeOrderForm), 'Cancel', false),
          <RegularButton onClick={showassessmnt}>Continue</RegularButton>])}
      </div>]}>
      <div>
        <Body1 color="black">
          You will need to change the order in the patient record.
          Make sure you have the correct permission to do this.
        </Body1>
        <div className="flex-boy-row">
          <Checkbox onChange={ondontshowChange}>Don't Show again.</Checkbox>
        </div>
      
      
      </div>
    </Modal>
    <Modal
      title={`Assessment on ${order.patient?.name}`}
      open={editassessmentModal}
      destroyOnClose={true}
      onOk={() => seteditassessmentModal(false)}
      onCancel={() => seteditassessmentModal(false)}
      closeIcon={modalClose(() => seteditassessmentModal(false))}
      maskClosable={false}
      width={1200}
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        {horStack([outlinedButton(() => seteditassessmentModal(false), 'Cancel', isExecuting),
          formSubmitButton('updateappointmentForm', woundAssessment?.procedure === "Biologics Ordering" ? "Update order" : 'Update Assessment', isExecuting, 'Updating')])}
      </div>]}>
      {woundAssessment && <EditAssessmentCard setassessmentModal={() => {
        seteditassessmentModal()
        dispatch(fetchSpecificOrder(order.id))
      }} setisExecuting={setisExecuting} setVisit={() => dispatch(fetchSpecificOrder(order.id))}
        // assessment={woundAssessment}
                                              order={order}
                                              appointment={woundAssessment?.appointment} wound={woundAssessment?.wound} procedure={woundAssessment?.procedure}/>}
    </Modal>
    {isOpen && <CreateOrder setOpen={setOpen} OrderModal={isOpen} handleOrderCancel={dialogCloser(setOpen)} type={order?.visit_type.toLowerCase()} orderToEdit={orderToEdit}/>}
    <Row gutter={[8, 3]} className={'h-full'} justify={'start'}>
      
      <Col xs={24} sm={24} md={24} lg={24} xl={16} className={'h-fit'}>
        <ShippingAddress order={order} patient={patient} consultant={{ ...consultant }}/>
        <div className="shadow-card-box " ref={treatmentRef}>
          {order?.visit_type !== "Internal" &&
            <div style={{ width: "100%" }}>
              <div className="flex flex-row justify-between items-center w-full my-auto m-0 p-0 mb-4">
                <p className={'text-[14px] font-semibold my-auto'}>Supplies List</p>
                {can_update_treatment_order && order.visit_type !== "Internal" && editButton(() => openAssessment())}
              </div>
              {order.dme_supplies.length > 0 || order?.visit_type === "DME" ? <>
                <p className={'text-start px-4 font-semibold text-[14px]'}>Treatment Scenario : {order.treatment_protocol || "Other"}</p>
                
                {treatmentorderItems?.filter(item => item.type !== 'Additional Dressing').map((item, index) => <div key={index}
                                                                                                                    className={`flex flex-row py-1 my-1 px-4 items-center ${index % 2 === 0 ? 'bg-[#F7F8F9]' : ''}`}>
                  <div className=" flex-1 text-start items-center"><p className={'my-auto'}>{item.name}</p></div>
                  <div className="flex-1"><p className={'my-auto'}>{item.pack_amount || item.default_pack_amount} {item.unit_of_measure}</p></div>
                  <div className={'min-w-fit px-4 text-end'}>
                    <p className={'my-auto'}>{item.unit_count || item.units_count}</p>
                  </div>
                </div>)}
                
                {treatmentorderItems?.some(item => item.type === 'Additional Dressing') &&
                  <Subtitle1 className="my-2" style={{ textAlign: 'left', marginTop: '10px', marginLeft: '10px' }} color="grey">Additional Supplies</Subtitle1>}
                {treatmentorderItems?.filter(item => item.type === 'Additional Dressing').map((item, index) => <div key={index}
                                                                                                                    className={`flex flex-row py-1 my-1 px-4 items-center ${index % 2 === 0 ? 'bg-[#F7F8F9]' : ''}`}>
                  <div className=" flex-1 text-start items-center"><p className={'my-auto'}>{item.name}</p></div>
                  <div className="flex-1"><p className={'my-auto'}>{item.pack_amount || item.default_pack_amount} {item.unit_of_measure}</p></div>
                  <div className={'min-w-fit px-4 text-end'}>
                    <p className={'my-auto'}>{item.unit_count || item.units_count}</p>
                  </div>
                </div>)}
              </> : null}
            </div>
          }
          
          {(order.biologic_kits.length > 0 || order?.visit_type === "Biologics") && biologicsProducts.length ?
            (() => {
              for (const assessmentKit of assessmentKits) {
                return assessmentKit.biologic_kits.map((item, index) => {
                  return <div key={index} className={`flex w-full flex-row py-1 my-1 px-4 items-center ${index % 2 === 0 ? 'bg-[#F7F8F9]' : ''}`}>
                    <div className=" flex-1 text-start items-center"><p className={'my-auto font-semibold'}>{assessmentKit.product}</p></div>
                    <div className="flex-1"><p className={'my-auto'}>{item.size}</p></div>
                    <div className={'min-w-fit px-4  text-end'}>
                      <p className={'my-auto'}>{item.quantity}</p>
                    </div>
                  </div>
                })
              }
            })() : null}
          
          {(order?.visit_type === "Internal") ? <>
            {order?.internal_order_resource_type === "DME" && <div className={'w-full'}>
              <div className="flex flex-row justify-between items-center w-full my-auto m-0 p-0 mb-4">
                <p className={'text-[14px] font-semibold my-auto'}></p>
                {can_update_treatment_order && order?.status !== 'approved' ? editButton(() => showassessmnt()) : null}
              </div>
              {treatmentorderItems?.map((item, index) => <div key={index} className={`flex w-full flex-row py-1 my-1 px-4 items-center ${index % 2 === 0 ? 'bg-[#F7F8F9]' : ''}`}>
                <div className=" flex-1 text-start items-center"><p className={'my-auto'}>{item.name}</p></div>
                <div className="flex-1"><p className={'my-auto'}>{item.pack_amount || item.default_pack_amount} {item.unit_of_measure}</p></div>
                <div className={'min-w-fit px-4  text-end'}>
                  <p className={'my-auto'}>{item.unit_count}</p>
                </div>
              
              </div>)}
            </div>}
            {order?.internal_order_resource_type === "PPE" && <>
              <div className="flex flex-row justify-between items-center w-full my-auto m-0 p-0 mb-4">
                <p className={'text-[14px] font-semibold my-auto'}></p>
                {can_update_treatment_order && order?.status !== 'approved' ? editButton(() => showassessmnt()) : null}
              </div>
              {ppetreatmentorderItems?.map((item, index) => <div key={index} className={`flex w-full flex-row py-1 my-1 px-4 items-center ${index % 2 === 0 ? 'bg-[#F7F8F9]' : ''}`}>
                <div className=" flex-1 text-start items-center"><p className={'my-auto'}>{item.name}</p></div>
                <div className="flex-1 text-end"><p className={'my-auto'}>{item.unit_count} units</p>
                </div>
              </div>)}
            </>}
          
          
          </> : null}
        </div>
        
        
        {/*<OrderNotes order={order}/>*/}
        <OrderTimeLine patient={woundAssessment?.appointment} order={order}/>
        {/*<Form.Item wrapperCol={{ span: 24 }} style={{ width: '100%' }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              {can_update_treatment_order && <Button type="primary" htmlType="submit">
                Save
              </Button>}
            </div>
          </Form.Item>*/}
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={8} className={''}>
        <div className="shadow-card-box w-full" >
          <div className="mb-4">
            <Subtitle1>Order details</Subtitle1>
          </div>
          {menuOptions[0]?<Dropdown menu={{
            items: menuOptions, onClick: ({ key }) => updateOrderInfo(key)
          }}
            // dropdownRender={()=>com_menu}
                     trigger={['click']}>
            <div className={`appointment-status-box px-2 ${(can_update_treatment_order && can_update_order_status) ? 'cursor-pointer' : ''}`}
                 style={{ borderColor: getOrderIndicatorColor(newOrderStatus), backgroundColor: getOrderIndicatorColor(newOrderStatus) + '15' }}>
              {getOrderIndicator(newOrderStatus)}
              <Subtitle1>{!!newOrderStatus ? newOrderStatus.replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()) : 'Unknown'}</Subtitle1>
              <div className={'pe-2'} style={{ flexGrow: 1, textAlign: 'end' }}>
                {updatingStatus ? <Spin size={"small"} spinning={updatingStatus} indicator={<LoadingOutlined style={{ color: 'grey' }}/>}/> :
                  (can_update_treatment_order && can_update_order_status) ? <CaretDownOutlined style={{ color: 'gray' }}/> :
                    null}
              </div>
            </div>
          </Dropdown>:<Skeleton.Input active block size={'large'}/>}
          {((statusesRequiringReason.includes(newOrderStatus) && newOrderStatus !== order.status) || showStatusForm) &&
            <>
              <DynamicForm form={statusesForm}
                           formName={"statusesForm"}
                           formId={"statusesForm"}
                           onDynamicFormEntriesResult={editTreatmentOrderStatus}
                           inputFields={statusesInputFields}
                           phoneValue={null} setPhoneValue={null}
                // defaultValues={newOrderStatus === order?.status ? { status_reasons: order?.status_reasons } : { status_reasons: undefined }}
                           formFooter={
                             <div
                               className="flex flex-row p-4 justify-evenly w-full">
                               {horStack([
                                 textButton(async () => {
                                   await statusesForm.resetFields()
                                   await setNewOrderStatus(order?.status)
                                   await setShowStatusForm(false)
                                 }, 'Cancel', false, null),
                                 formSubmitButton('statusesForm', 'Update Status', updatingStatus, 'Updating')
                               ])}
                             </div>}
              />
              <Divider/>
            </>}
          
          {boldKeyCapitalizedValueGrayBackground('Ordered by', order?.consultant.name || 'Unknown')}
          {boldKeyCapitalizedValue('Order Date', dayjs(order?.date_of_service).format('MM-DD-YYYY'))}
          {boldKeyCapitalizedValueGrayBackground('Order Type', order?.visit_type || 'N/A')}
          {order.visit_type !== "Internal" && boldKeyCapitalizedValue('Wound Location', order?.wound_location)}
          {order.visit_type !== "Internal" && boldKeyCapitalizedValueGrayBackground('Billing Entity', order?.billingEntity?.name || '_')}
          {order.visit_type !== "Internal" && boldKeyCapitalizedValue('Office', order?.patient?.facility || '_')}
          {(!!order?.sales_order_number || newOrderStatus === 'warehouse alert') &&
            boldKeyCapitalizedValueGrayBackground('Sales Order Number', <p>{order?.sales_order_number || 'Unknown'}<span className={'me-4'}/>
              {(newOrderStatus === 'warehouse alert') && <i onClick={async () => {
                await setNewOrderStatus('warehouse alert')
                await setShowStatusForm(!showStatusForm);
              }}
                                                            className={`cursor-pointer text-gray-500 ${showStatusForm ? 'fa-solid fa-xmark' : 'fas fa-pen'}`}></i>}
            </p>)}
          {(!!order?.tracking_number || newOrderStatus === 'label created') &&
            boldKeyCapitalizedValueGrayBackground('Tracking number', <p>{order?.tracking_number || 'Unknown'}<span className={'me-4'}/>
              {(newOrderStatus === 'label created') && <i onClick={async () => {
                await setNewOrderStatus('label created')
                await setShowStatusForm(!showStatusForm);
              }}
                                                          className={`cursor-pointer text-gray-500 ${showStatusForm ? 'fa-solid fa-xmark' : 'fas fa-pen'}`}></i>}
            </p>)}
          {(!!order?.return_reason || newOrderStatus === 'returned') &&
            boldKeyCapitalizedValueGrayBackground('Return Reason', <p>{order?.return_reason || 'Unknown'}<span className={'me-4'}/>
              {(newOrderStatus === 'returned') && <i onClick={async () => {
                await setNewOrderStatus('returned')
                await setShowStatusForm(!showStatusForm);
              }}
                                                     className={`cursor-pointer text-gray-500 ${showStatusForm ? 'fa-solid fa-xmark' : 'fas fa-pen'}`}></i>}
            
            </p>)}
          {(!!order?.review_request_note || newOrderStatus === 'needs review') &&
            boldKeyCapitalizedValueGrayBackground('Review Request Note', <p>{order?.review_request_note || 'Unknown'}<span className={'me-4'}/>
              {(newOrderStatus === 'needs review') && <i onClick={async () => {
                await setNewOrderStatus('needs review')
                await setShowStatusForm(!showStatusForm);
              }}
                                                         className={`cursor-pointer text-gray-500 ${showStatusForm ? 'fa-solid fa-xmark' : 'fas fa-pen'}`}></i>}
            </p>)}
          {(!!order?.cancellation_reason || newOrderStatus === 'cancelled') &&
            boldKeyCapitalizedValueGrayBackground('Cancellation reason', <p>{order?.cancellation_reason || 'Unknown'}<span className={'me-4'}/>
              {(newOrderStatus === 'cancelled') && <i onClick={async () => {
                await setNewOrderStatus('cancelled')
                await setShowStatusForm(!showStatusForm);
              }}
                                                      className={`cursor-pointer text-gray-500 ${showStatusForm ? 'fa-solid fa-xmark' : 'fas fa-pen'}`}></i>}
            </p>)}
          {/*{order.status === "delivered" && order.visit_type === "Biologics" && getOrderAppliedStatus(order?.applied_status?.toLowerCase(), dayjs().diff(dayjs(order.delivered_date), 'days'))}*/}
        </div>
        {/*{order.status === "cancelled" && <div className="shadow-card-box">
          <Form.Item
            style={{ width: '100%' }}
            label=" Cancellation Reason"
            name="cancellation_reason"
            rules={[{ required: true, message: 'this field is required!' }]}
            wrapperCol={{ span: 24 }}
          >
            <Select
              // mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="Please select"
            
            >
               {cancellation_reasons.map(item => <Option value={item}>{item}</Option>)}
              {masterList.find(item => item.system_name === "cancellation_reason")?.options?.map(item => <Option
                value={item}>{item.toString().replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}</Option>) || []}
            </Select>
          </Form.Item>
        
        </div>}*/}
        
        {/*{order.status === "warehouse alert" && <div className="shadow-card-box">
          <Subtitle1>Sales Order Number</Subtitle1>
          <div className={'w-full mt-2'}>
            <p className="text-xs text-start">{order?.sales_order_number ||'Unknown'}</p>
          </div>
          </div>}*/}
        {/*{order.status === "shipped" && <div className="shadow-card-box">
            <Form.Item
              style={{ width: '100%' }}
              label="Tracking Number"
              name="tracking_number"
              rules={[{ required: false, message: 'this field is required!' }]}
              wrapperCol={{ span: 24 }}
            >
              <Input autoComplete={'off'}/>
            </Form.Item>
            <Form.Item wrapperCol={{ span: 24 }} style={{ width: '100%' }}>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                {can_update_treatment_order && <Button type="primary" htmlType="submit">
                  Save
                </Button>}
              </div>
            </Form.Item>
          </div>}*/}
        {/*{!!order?.notes[0] && <div className="shadow-card-box">
            <Subtitle1>Note</Subtitle1>
            <div style={{ background: '#F1F2F5', width: '100%', padding: '10px', textAlign: 'left', borderRadius: '2px' }}>
              {_.orderBy(order.notes, ["created_at", "desc"])[0].note}
            </div>
          </div>}*/}
        <TrackingInformation tracking={orderTracking} order={order} loading={orderTrackingLoading}/>
      </Col>
    </Row>
  </>;
}

const mapStateToProps = (state) => ({
  navs: state.navs,
  masterList: state.masters.masterListRaw,
  selectedPatient: state.patients.selectedPatient,
  order: state.orders.selectedOrder,
  can_update_order_status: state.orders.can_update_order_status,
  orderTracking: state.orders?.orderTracking,
  orderTrackingLoading: state.orders?.orderTrackingLoading
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OrderPreview)
