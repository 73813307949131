/* eslint-disable no-unused-vars */
import { Avatar, Image } from 'antd'
import React, { useEffect } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Body1 } from '../../general'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppointmentCard from './AppointmentCard';
import { fetchConsultantshedule } from '../../../app/Reducers/mapSlice'
import { status_colors } from '../../../configure/constants'
import { emptyFun } from "../../../common/helpers";

 const ConsultantCard = ({ consultant, appointments, consultantDistance }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchConsultantshedule(consultant.id))
    return emptyFun
  }, [consultant])
  return (<div className="flex-boy-column">

    <div className="card-body" style={{ width: "400px" }}>
      <div className="flex-boy-row-space">
        <div className="flex-boy-row" style={{ flex: 1 }}>
          <Avatar src={<Image src={require("../../../assets/user.png")} />} />
          <div className="flex-boy-column-space" style={{ alignItems: "normal", marginLeft: "10px" }}>
            <Body1>Appointments:{appointments.filter(item => item.status !== "scheduled").length} of {appointments.length}</Body1>
            <Body1>Mileage:{consultantDistance}ml</Body1>
          </div>
        </div>
        <FontAwesomeIcon size="1x" style={{ marginBottom: "2px" }} icon={['fas', 'chevron-double-right']} />
      </div>
      <div className="flex-boy-row" style={{ flex: 1 }}>
        <div className="icon-circle">
          <FontAwesomeIcon size="1x" style={{ marginBottom: "2px" }} icon={['fas', 'calendar-check']} />
        </div>
        <div className="checklists-appointments" style={{ flex: 1 }}>
          {appointments.map(item => <AppointmentCard key={item.id} appointment={item}
            color={status_colors[`${item.status}`]} />)}
        </div>

      </div>

    </div>


  </div>)
}
const mapStateToProps = (state) => ({ appointments: state.map.consultantSchedule,
  consultantDistance: state.map.consultantDistance
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ConsultantCard)
