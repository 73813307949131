/* eslint-disable no-unused-vars */
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {apiRequest} from '../Apis';
import querystring from 'querystring';
import {errMsg, getToastId, toastUp, updateToast} from './reducerUtils';
import {organisations} from './initialState';
import ReactGA from 'react-ga4';

const initialState = {...organisations};
export const fetchOrganisations = createAsyncThunk('fetchOrganisations', async (requestParams, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        let state = thunkAPI.getState();
        let pagination = requestParams?.pagination ? requestParams.pagination : state.organisations.organisationsPagination;
        let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : { treatementOrder: requestParams.id }
        params.page = pagination.current
        const response = await apiRequest.get(`/organizations`,{params});
        let data = response.data.data
        const payload = {data, pagination: {...pagination, total: response.data.meta?.total}};
        //console.log(payload);
        resolve(payload);
      } catch (e) {
        //console.log(e);
        reject(e);
        toastUp(errMsg(e, true, 'organisations'), false);
      }
    });
  }
);
export const fetchOrganisationById = createAsyncThunk('fetchOrganisationById', async (id, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        //console.log(props, state);
        const response = await apiRequest.get(`/organizations/${id}`);
        let payload = response.data.data
        console.log({payload});
        resolve(payload);
      } catch (e) {
        //console.log(e);
        reject(e);
        toastUp(errMsg(e, true, 'organisations'), false);
      }
    });
  }
);

export const deleteOrganisation = createAsyncThunk('deleteOrganisation', async (organisation_id, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Deleting Organisation');
      try {
        const response = await apiRequest.delete(`/organisations/${organisation_id}`);
        await thunkAPI.dispatch(fetchOrganisations(organisation_id))
        updateToast(toastId, 'Appointment deleted', true);
        ReactGA.event({
          category: "organisation_deleted",
          action: "organisation_deleted",
          label: "organisation_deleted",
          value: 1
        });
        resolve(response);
      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);
export const fetchOrgFacilities = createAsyncThunk('fetchOrgFacilities', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.organisations.organisationsPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const response = await apiRequest.get(`/organizations/${requestParams.organisationId}/medical-facilities`,{params});
      let data = response.data.data
      const payload = {data: data, pagination: {...pagination, total: response.data.meta.total}};
      //console.log(payload);
      resolve(payload);
    } catch (e) {
      //console.log(e);
      toastUp(errMsg(e, false), false);
      reject(e);
    }
  });
});
export const fetchOrgUsers = createAsyncThunk('fetchOrgUsers', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.organisations.orgUsersPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const response = await apiRequest.get(`/organizations/${requestParams.organisationId}/staff`,{params});
      let data = response.data.data
      const payload = {data: data, pagination: {...pagination, total: response.data.meta.total}};
      //console.log(payload);
      resolve(payload);
    } catch (e) {
      //console.log(e);
      toastUp(errMsg(e, false), false);
      reject(e);
    }
  });
});
export const fetchOrgPermGroups = createAsyncThunk('fetchOrgPermGroups', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.organisations.orgPermPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const response = await apiRequest.get(`/organizations/${requestParams.organisationId}/groups`,{params});
      let data = response.data.data
      const payload = {data: data, pagination: {...pagination, total: response.data.meta.total}};
      resolve(payload);
    } catch (e) {
      //console.log(e);
      toastUp(errMsg(e, false), false);
      reject(e);
    }
  });
});

export const fetchOrganizationPermissions = createAsyncThunk('fetchOrganizationPermissions', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get('/organization-permissions');
      console.log({response})
      const payload = response.data.data
      console.log({ fetchOrganizationPermissions: payload });
      resolve(payload);
    } catch (e) {
      //console.log(e);
      toastUp(errMsg(e, false), false);
      reject(e);
    }
  });
});

export const createOrganisation = createAsyncThunk('createOrganisation', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      await apiRequest.post(`/organizations`, payload);
      toastUp("Organisation created successfully", true)
      thunkAPI.dispatch(fetchOrganisations())
      resolve()
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const createOrganisationStaff = createAsyncThunk('createOrganisationStaff', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const {organisationId, ...rest} = payload
    try {
      await apiRequest.post(`/organizations/${organisationId}/staff`, rest);
      toastUp("Staff created successfully", true)
      thunkAPI.dispatch(fetchOrgUsers())
      resolve()
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const createPermissionGroup = createAsyncThunk('createPermissionGroup', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const {organisationId, ...rest} = payload
    try {
      await apiRequest.post(`/organizations/${organisationId}/groups`, rest);
      toastUp("Permission group created successfully", true)
      thunkAPI.dispatch(fetchOrgPermGroups({organisationId}))
      resolve()
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})

export const editOrganisation = createAsyncThunk('editOrganisation', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      await apiRequest.put(`/organisations/${payload?.id}`, payload);
      toastUp("Organisation edited successfully", true)
      thunkAPI.dispatch(fetchOrganisations())
      resolve()
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})

export const editOrganisationStaff = createAsyncThunk('editOrganisationStaff', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const {organisationId, ...rest} = payload
    try {
      await apiRequest.patch(`/organizations/${organisationId}/staff/${payload.id}`, rest);
      toastUp("Staff updated successfully", true)
      thunkAPI.dispatch(fetchOrgUsers())
      resolve()
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const editPermissionGroup = createAsyncThunk('editPermissionGroup', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const {organisationId, ...rest} = payload
    try {
      await apiRequest.patch(`/organizations/${organisationId}/groups/${payload.id}`, rest);
      toastUp("Permission group successfully", true)
      thunkAPI.dispatch(fetchOrgPermGroups({organisationId}))
      resolve()
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const deactivateOrganisationStaff = createAsyncThunk('editOrganisationStaff', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const {organisationId, ...rest} = payload
    try {
      await apiRequest.patch(`/organizations/${organisationId}/staff/${payload.id}/status`, {status: "Deactivated"});
      toastUp("Staff updated successfully", true)
      thunkAPI.dispatch(fetchOrgUsers())
      resolve()
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})

export const organisationSlice = createSlice({
  name: 'organisations',
  initialState, // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    selectOrganisation: (state, action) => {
      state.selectedOrganisation = action.payload;
    },
    resetOrganisationsPagination: (state) => {
      state.organisationsPagination = initialState.organisationsPagination;
    },
    resetOrgFacilitiesPagination: (state) => {
      state.orgFacilitiesPagination = initialState.orgFacilitiesPagination;
    },
    resetOrgUsersPagination: (state) => {
      state.orgUsersPagination = initialState.orgUsersPagination;
    },
    resetOrgPermPagination: (state) => {
      state.orgPermPagination = initialState.orgPermPagination;
    },
    doResetOrganisationSlice: (state, action) => {
      Object.keys(initialState).map((key) => (state[key] = initialState[key]));
    }
  },
  extraReducers: (builder)=>{
    builder.addCase(fetchOrganisations.fulfilled, (state, action) => {
      state.organisations = [...action.payload.data];
      state.organisationsPagination = action.payload.pagination;
    }).addCase(fetchOrgFacilities.fulfilled, (state, action) => {
      state.orgFacilities = [...action.payload.data];
      state.orgFacilitiesPagination = action.payload.pagination;
    }).addCase(fetchOrgUsers.fulfilled, (state, action) => {
      state.orgUsers = [...action.payload.data];
      state.orgUsersPagination = action.payload.pagination;
    }).addCase(fetchOrgPermGroups.fulfilled, (state, action) => {
      state.orgPermGroups = [...action.payload.data];
      state.orgPermPagination = action.payload.pagination;
    }).addCase(fetchOrganizationPermissions.fulfilled, (state, action) => {
      state.organizationPermissions = [...action.payload];
    }).addCase(fetchOrganisationById.fulfilled, (state, action) => {
      state.selectedOrganisation = {...action.payload}
    })
  }
});

export const {
  selectOrganisation,
  doResetOrganisationSlice,
  resetOrganisationsPagination, resetOrgFacilitiesPagination, resetOrgUsersPagination, resetOrgPermPagination
} = organisationSlice.actions;
export default organisationSlice.reducer;
