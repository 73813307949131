import { useState, useEffect } from 'react';
import { Subtitle1 } from '../../../../components';
import { connect } from 'react-redux';
import { isNull } from 'lodash';
import { Tooltip } from 'antd';

const color = ['#4B9BFF', '#7785FF', '#2E5AAC', '#f4e409', '#a8dcd9', '#bdfffd', '#315659', '#7cffc4', '#6abea7', '#5e6973'];

function changeTextColor(color) {
  let textColor = ""
  if (color === '#f4e409') { textColor = "#000" }
  else if (color === '#a8dcd9') { textColor = "#000" }
  else if (color === '#bdfffd') { textColor = "#000" }
  else if (color === '#6abea7') { textColor = "#000" }
  else if (color === '#7cffc4') { textColor = "#000" }
  else if (color === '#5e6973') { textColor = "#000" }
  else { textColor = "#fff" }
  return textColor;
}

function Bar({ color, width }) {
  let textColor = changeTextColor(color);
  return (
    <Tooltip placement='top' title={<Subtitle1 style={{ color: textColor }}>{`${width}%`}</Subtitle1>} color={color} arrowPointAtCenter>
      <div style={{ backgroundColor: `${color}`, width: `${width}%`, height: '40px', color: "#000" }} />
    </Tooltip>
  );
}

function Dot({ color, title }) {
  return (
    <div className='flex-boy-row-space'>
      <div style={{ backgroundColor: `${color}`, width: '10px', height: '10px', borderRadius: '50%', margin: '5px', }} />
      <Subtitle1>{title}</Subtitle1>
    </div>
  );
}

function getRemainingPercentage(data) {
  let remaining = 0;
  data?.forEach((item) => {
    remaining += item.percentage;
  });
  return Math.round(100 - remaining);
}

export default function BarGraph({ woundType }) {
  const [stats, setStats] = useState(null);
  const [remainingPercentage, setRemainingPercentage] = useState(isNull);

  return (
    <div className='flex-boy-column'>
      <div>
        <div className='flex-boy-row-space' style={{ borderRadius: '20px' }}>
          {woundType?.map((item, index) => (
            <Bar color={color[index]} width={item?.percentage} key={index} />
          ))}
        </div>
        <div className='flex-boy-row'>
          {woundType?.map((item, index) => (
            <Dot color={color[index]} title={item?.name} />
          ))}
        </div>
      </div>
    </div>
  );
}
