import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Form, Input } from 'antd'
import { createProgressNote, updateProgressNote } from '../../../app/Reducers';
import { emptyFun, executeChunkFn } from "../../../common/helpers";

export const NewInternalNote = ({ patient, closeModal, setIsSubmitting, editingForm, note }) => {
  const { TextArea } = Input
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState(editingForm ? { notes: note.note } : {});
  useEffect(() => {
    setInitialValues(editingForm ? { notes: note.note } : {})
    return emptyFun
  }, [note, editingForm])
  const onFinish = async (values) => {
    console.log(values)
    let payload, pl = { note: values.notes, patient_id: patient.id }
    if (editingForm) {
      payload = { data: pl, note_id: note.id }
    } else {
      payload = pl
    }
    await executeChunkFn(dispatch, editingForm ? updateProgressNote : createProgressNote, payload, setIsSubmitting, closeModal)
  }
  return (<Form
    name="new-progress-note"
    onFinish={onFinish}
    labelCol={{ span: 16 }}
    initialValues={{ ...initialValues }}
    layout="vertical">
    <Form.Item name="notes" label="Notes" rules={[{ required: true, message: 'Missing notes' },{ max: 1000, message: 'Cannot exceed 1000 characters' }]}>
      <TextArea placeholder="Type here" rows={6} />
    </Form.Item>
  </Form>)
}
const mapStateToProps = (state) => ({  patient: state.patients.selectedPatient })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(NewInternalNote)
