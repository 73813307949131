import {useEffect, useState} from "react"
import {apiRequest} from "../../../app/Apis"
import {Headline6, Subtitle1, Subtitle2span} from "../../../components"
import {Divider, Table} from "antd"
import {getDualStatus, getSkels, makeAutoCompleteOptions} from "../../../common/helpers"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useDispatch} from "react-redux"
import {useLocation, useNavigate} from 'react-router-dom';

export default function SignatoryConsultant({ consultantId, appConf, navs }) {
  const location = useLocation()
  const navigate = useNavigate();
  const [consultant, setConsultant] = useState([])
  const [loading, setLoading] = useState(false)
  const [consultantRoles, setConsultantRoles] = useState([]);
  const dispatch = useDispatch()
  
  const columns = [{
    title: 'First & Last Name', dataIndex: 'name', key: 'name',
    fltr: { filterable: true, type: 'text' }, /*...getColumnSearchProps('name'),*/ render: (name) => (<>
      <div className="flex-boy-row-space" style={{ justifyContent: 'normal' }}>
        
        <Subtitle1 style={{ textAlign: "left", marginLeft: '10px' }}>{name}</Subtitle1>
      </div>
    </>)
  }, {
    title: 'Role', dataIndex: 'roles', key: 'role', render: (roles) => {
      // console.log('roles: ', roles)
      if (roles && Array.isArray(roles) && roles.length > 0) {
        roles.map(item => {
          if (!consultantRoles.includes(item)) {
            consultantRoles.push(item)
            setConsultantRoles([...consultantRoles])
          }
        })
        return <p style={{ maxWidth: 200 }}>{roles.join(', ')}</p>
      } else {
        return roles
      }
    }, fltr: { filterable: true, type: 'autocomplete' }
  }, {
    title: 'Region', dataIndex: 'region', key: 'regionName'
  }, {
    title: 'Sub-region', dataIndex: 'sub_region', key: 'sub_region'
  }, {
    title: 'Phone Number', dataIndex: 'phone_no', key: 'phone_no', fltr: { filterable: true, type: 'phone' }
  }, {
    title: 'Email', dataIndex: 'email', key: 'email', fltr: { filterable: true, type: 'text' }
  }, {
    title: 'Status', dataIndex: 'status', key: 'status', fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(['active', 'in-active']) },
    render: (status) => getDualStatus(status)
  }, {
    title: <FontAwesomeIcon color="grey" icon={["fas", "table"]}/>, key: 'operation', width: 70
  }
  ]
  
  async function fetchConsultants() {
    setLoading(true)
    const response = await apiRequest.get(`/consultants?stateMDConsultantAccount=${consultantId}`)
    if (response.status === 200) {
      setConsultant(response.data.data)
    }
    setLoading(false)
  }
  
  useEffect(() => {
    fetchConsultants()
  }, [])
  
  function redirect(item) {
    navigate(`/consultants/${item}`,{replace:true})
  }
  
  function ConsultantTile({ item }) {
    return (
      <div className="flex flex-col flex-start cursor-pointer" key={item?.id} onClick={() => redirect(item?.id)}>
        <div className="flex-boy-row-space">
          <div className="flex flex-col justify-start items-start">
            <Subtitle1>{item?.name}</Subtitle1>
            <Subtitle2span>{item?.email}</Subtitle2span>
          </div>
          {getDualStatus(item?.status)}
        </div>
        {/* <FontAwesomeIcon icon={['fas', 'chevron-right']} /> */}
        <Divider/>
      </div>
    )
  }
  
  return (
    <div className="card-box overflow-x-scroll">
      
      {/* {consultant &&
        <List
          className="w-full"
          itemLayout='vertical'
          bordered={false}
          dataSource={consultant}
          renderItem={(item) => <ConsultantTile item={item} />}
        />} */}
      <Table
        title={() => <Headline6 className="mb-2">Signatory Consultants</Headline6>}
        className="w-full"
        columns={columns} dataSource={consultant}
        loading={loading}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: event => {
        //       (async () => {
        //         if (record.id !== navs.selectedConsultantID) {
        //           await dispatch(resetConsultantModuleState())
        //           await dispatch(fetchVehicles())
        //         }
        //         // await dispatch(setConsultantNavs({ tab: 'Summary' }))
        //         await dispatch(selectConslultant({ navigate, record: record }))
        //         if (can_view_consultant) {
        //           navigate(`/consultants/${record.id}`)
        //           // window.location.href = `/consultants/${record.id}`
        //         }
        //       })().catch(e => console.log(e))
        //     }
        //   }
        // }}
        {...getSkels(loading, columns, consultant)}
      />
    </div>
  )
}
