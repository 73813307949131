/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Body2, Headline6, RegularButton, Subtitle1 } from '../../components';
import HeaderTop from '../../components/HeaderTop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Breadcrumb, Dropdown, Image, Menu, Spin } from 'antd';
import FacilitySummary from './FacilitySummary';
import FacilityDashboard from './Dashboard/index';
import {useLocation, useNavigate} from 'react-router-dom';
import { fetchFacilityById, setFacilityNavs, updateFacilityDetails } from '../../app/Reducers';
import { emptyFun, getDualStatus, getHeaderLeftNav } from '../../common/helpers';
import { usePermissionCheck } from '../../hooks/usePermissionCheck';
import { FacilityLoadingdiv } from './utils';
// const TabList = ["Summary", "Availabilty", "Orders"]
export const FacilityPage = ({
  
  facility,
  activeUser,
  navs,
  FacilityLoading
}) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const can_deactivate_medical_facility_admin = usePermissionCheck('deactivate_medical_facility_admin', false, [])
  useEffect(
    () => {
      console.log('Office', facility);
      if (!facility) {
        navigate(`/facilities`);
      } else {
        dispatch(fetchFacilityById(facility.id));
      }
      return emptyFun
    },
    [
      /*location, facility*/
    ]
  ); //This was causing "Too many requests" error
  // const [activeTab, setActiveTab] = useState('Summary');
  async function handleMenuClick(e) {
    console.log(stati[e.key]);
    let pl = { status: stati[e.key], id: facility.id };
    console.log('click', e.key);
    await dispatch(updateFacilityDetails(pl));
  }

  const getIndicator = (text) => {
    return text === 'active' ? (
      <div className="dot-circle-active" />
    ) : text === 'in-active' ? (
      <div className="dot-circle-inactive" />
    ) : (
      <div className="dot-circle-inactive" />
    );
  };
  let stati = ['active', 'in-active'];
  const menu = (
    <Menu onClick={handleMenuClick}>
      {stati.map((item, index) => (
        <Menu.Item key={index} style={{ textTransform: 'capitalize' }}>
          {' '}
          {item.replace('-', '').replace(/_/g, ' ')}{' '}
        </Menu.Item>
      ))}
    </Menu>
  );

  const setActiveTab = async (tab) => {
    await dispatch(setFacilityNavs({ tab: tab }));
  };

  const Tabs = () => {
    return (
      <div className="flex-boy-row patients-tabs">
        <div
          onClick={() => setActiveTab('information')}
          style={{ height: '60px' }}
          className={
            (!navs?.facilityNavs?.tab || navs?.facilityNavs?.tab) ===
              'information'
              ? 'patient-tab active'
              : 'patient-tab'
          }
        >
          <Subtitle1>Details</Subtitle1>
        </div>
        {!activeUser?.facilityProfile && <div
          onClick={() => setActiveTab('stats')}
          style={{ height: '60px' }}
          className={
            navs?.facilityNavs?.tab === 'stats'
              ? 'patient-tab active'
              : 'patient-tab'
          }
        >
          <Subtitle1>Statistics</Subtitle1>
        </div>}
      </div>
    );
  };
  return (
    <div className="full-page bg-gray-200 flex flex-col h-full w-full">
      {facility ? (
        FacilityLoading ? <FacilityLoadingdiv /> :
        <>
          <HeaderTop
            child={
              <div className="flex-boy-row">
                {!activeUser?.facilityProfile && getHeaderLeftNav(navigate, 'Office List')}
                <Tabs />
              </div>
            }
          />
            <div className="page-header bg-white mb-4 ">
              <div className="flex-boy-row">
                <Breadcrumb>
                  {activeUser?.facilityProfile ? null : (
                    <Breadcrumb.Item>Office</Breadcrumb.Item>
                  )}
                  <Breadcrumb.Item>Office Details</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="flex-boy-row-space">
                <div className="flex-boy-row-space">
                  <Avatar
                    src={
                      <Image
                        src={
                          facility.logo?.logo_url ||
                          require('../../assets/user.png')
                        }
                      />
                    }
                  />
                  <div style={{ marginLeft: '10px' }}>
                    <Headline6>{facility.name}</Headline6>
                  <Body2 color="grey"> {facility.address}, {facility.zip_code}, {facility.city}, {facility.state}</Body2>
                  </div>
                </div>

                <div className="flex-boy-row-space">
                  {can_deactivate_medical_facility_admin ? (
                    <Dropdown dropdownRender={()=>menu} trigger={['click']}>
                      <RegularButton className="flex-boy-row-space" color="grey" background="transparent" style={{ marginRight: '10px' }} borderColor="gainsboro"       >
                        {getIndicator(facility.status ? facility.status : 'in-active')}   &nbsp; &nbsp;
                        <Subtitle1 style={{ textTransform: 'capitalize' }}>   {facility.status ? facility.status : 'Unspecified'}     </Subtitle1>
                        &nbsp; &nbsp;
                        <FontAwesomeIcon color="grey" icon={['fas', 'caret-down']} />
                      </RegularButton>
                    </Dropdown>
                  ) : getDualStatus(facility.status)}
                </div>
              </div>
            </div>
            {navs?.facilityNavs?.tab === 'information' ? (<div className="w-full h-full"><FacilitySummary/></div>) :
              (<div className="w-full h-full"><FacilityDashboard facilityId={facility?.id} showHeader={false}/></div>)}
        </>
      ) : null}
    </div>
  );
};
const mapStateToProps = (state) => ({
  facility: state.facilities.selectedFacility,
  consultants: state.consultants.consultants,
  activeUser: state.auth?.activeUser,
  navs: state.navs,
  FacilityLoading: state?.facilities.FacilityLoading
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(FacilityPage);
