import { Spin } from 'antd';
import { useEffect } from 'react';
import {connect, useDispatch} from 'react-redux';
import { LoginWithSSO } from '../../app/Reducers';
import {useLocation, useNavigate} from 'react-router-dom';
import {Login} from './Login';

  const SsoRedirect = ({dynamicLink}) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let rawDriver = location.search.split('&state')[0];
  let query = location.search;
  let driver = rawDriver.replace(/(.*)=/, '');

  useEffect(() => {
    if (driver) {
      console.log('driver', driver);
      let payload = {
        driver: driver,
        navigate: navigate,
        query: query,
      };
      handleSSOLogin(payload).catch(console.log)
    }
  }, [driver]);
const handleSSOLogin=async (payload) => {
  let user = await dispatch(LoginWithSSO(payload)).unwrap();
  console.log({ user })
  const permissions = user?.roles?.map((item) => item.permissions).flat(1).map(item => item.name)
  console.log('dynamicLink: ', dynamicLink)
  let initialPath
  console.log('Trying to navigate3')
  initialPath = permissions.includes("view_map") ? dynamicLink ? dynamicLink : '/map' : user?.facilityProfile ? `/facility/${user?.facilityProfile?.id}/stats` : '/patients';
  console.log({ navigate, initialPath })
  navigate(initialPath)
  
}
  return (
    <div className='auth-content'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
          margin: '20px 0',
          padding: '30px 50px',
          textAlign: 'center',
          borderRadius: '4px',
        }}
      >
        <Spin tip='Authenticating...' delay={500} style={{ color: 'red' }} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loadingUser: state.auth?.loadingUser,
  settings: state.settings?.settings,
  activeUser: state.auth?.activeUser,
  dynamicLink: state.auth?.dynamicLink,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SsoRedirect);