import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Form, Grid, Select, Table, Upload} from 'antd'
import {apiRequest} from '../../../../app/Apis';
import dayjs from 'dayjs'
import {fetchPatientById, fetchPatientConsentForms, resetPatientConsentPagination, setPatientDetailsLoading, updatePatientConsentFilters, updatePatientDetailsOwner} from '../../../../app/Reducers'
import {containedButton, dialogCloser, emptyDocuments, emptyFun, emptyTable, getSkels, kebabMenus, makeSelectOptions, onTableChange, responsiveTbl} from "../../../../common/helpers";

import PdfViewer from "../../../../components/PdfViewer";
import {errMsg, getToastId, updateToast} from "../../../../app/Reducers/reducerUtils";
import KebabMenu from "../../../../components/KebabMenu";
import AuditLogViewer from "../../../../components/AuditLogs/AuditLogViewer";
import {useSubscription} from "../../../../hooks/MqttHooks/useSubscription";
import * as Sentry from "@sentry/react";
import {saveAs} from "file-saver";
import TableFilters from '../../../../components/InputComponents/TableFilters';
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import {IoDocumentTextOutline} from 'react-icons/io5';
import {useLocation, useNavigate} from 'react-router-dom';
import {CloudUploadOutlined} from '@ant-design/icons';
const { useBreakpoint } = Grid;
const ConsentForms = ({ patientConsentForms, patient, activeUser, patientConsentPagination, appConf }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState=useBreakpoint()
  const [facesheetForm, setFacesheetForm] = useState(false);
  const [faceForm] = Form.useForm();
  const dispatch = useDispatch()
  const [pdfShowing, setPdfShowing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [pdf, setPdf] = useState(null);
  const patientId = patient.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const { message } = useSubscription(`view_patient_consent_form/${patientId}`, { qos: 2, nl: true });
  const [doc, setDoc] = useState(null);
  const [editingForm, setEditingForm] = useState(false);
  const [selected, setSelected] = React.useState(0);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  // appLog({appConf: appConf?.patientConsentFilters})
  const showPopconfirm = (id) => setSelected(id);
  const handleCancel = () => setSelected(0)
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await loadDocs(true)
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    (async () => {
      await loadDocs(false)
    })().catch(e => console.log(e))
    return emptyFun
  }, [])
  const loadDocs = async (isMQMessage) => {
    /*if (!!patientConsentForms[0] && !isMQMessage && patientConsentForms.every(item => item.patient_id === patientId)) {
      return
    }*/
    if (!patient || (patientId !== patient.id)) {
      await dispatch(setPatientDetailsLoading(!isMQMessage))
      await dispatch(fetchPatientById(Number(patientId)))
      await dispatch(setPatientDetailsLoading(false))
      await dispatch(updatePatientDetailsOwner(patientId))
    }
    await dispatch(fetchPatientConsentForms(patientId))
  }
  const handleOk = async (docID) => {
    await setConfirmLoading(true);
    await deleteDocument(docID)
    await dispatch(fetchPatientConsentForms(patientId))
    await setSelected(0)
    await setConfirmLoading(false);
  };
  
  const deleteDocument = async (docId) => {
    const toastId = getToastId("Deleting document")
    try {
      await apiRequest.delete(`/patients/${patientId}/consent-form/${docId}`)
      updateToast(toastId, "Document deleted successfully", true)
      await dispatch(fetchPatientConsentForms(patientId))
      faceForm.resetFields();
      setFacesheetForm(false);
    } catch (e) {
      Sentry.captureException(e);
      console.log(e)
      updateToast(toastId, errMsg(e, false, null, 'Unable to update document'), false)
    }
  }
  
  
  const actionMenus = kebabMenus(false, can_view_audit_logs, true)
  
  async function kebabMenuClick(e, doc) {
    await setDoc({ ...doc, ...(!!doc.expires_at ? { expires_at: dayjs(doc.expires_at) } : { expires_at: undefined }) })
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true)
        await setFacesheetForm(true)
        break
      case 'Delete':
        await setEditingForm(false)
        showPopconfirm(doc.id)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
        await setEditingForm(false)
    }
  }
  
  const addDoc = async () => {
    await setEditingForm(false)
    await setDoc(null)
    await setFacesheetForm(true)
  }
  /* const menu = (item) => {
     return <Menu onClick={(e) => handleMenuClick(e, item)}>
       {editMenus.map((item, index) => <Menu.Item key={index} icon={item.icon}> {item.title}  </Menu.Item>)}
     </Menu>
   }*/
  
  const downloadImage = (image) => {
    console.log({ image })
    const newBlob = new Blob([image.signed_form], { type: 'application/pdf' });
    saveAs(image.signed_form, image.name)
    console.log('blob', newBlob);
  }
  
  const columns2 = [
    { title: 'Name', dataIndex: 'name', key: 'name', fltr: { filterable: true, type: 'string' } },
    { title: 'Signed by', dataIndex: 'signed_by', key: 'signed_by', fltr: { filterable: true, type: 'string' } },
    { title: 'Relationship', dataIndex: 'relationship', key: 'relationship', fltr: { filterable: true, type: 'select', options: makeSelectOptions(['Self', 'Spouse', 'Sibling', 'Other']) } },
    
    
    {
      fltr: { filterable: false }, render: (record) => {
        return <a className={'text-gray-700 bg-gray-100 rounded-lg px-4 py-1.5'} href={record?.signed_form} target="_blank">Open Document <IoDocumentTextOutline/></a>
        // return <FontAwesomeIcon onClick={() => downloadImage(record)} color="grey" icon={["fas", "download"]}/>;
      }
    },
    {
      fltr: { filterable: false }, render: (record) => {
        return (record.resource_type !== 'consent_form') &&
          <KebabMenu menus={actionMenus}
                     recordItem={record}
                     handleMenuClick={kebabMenuClick} resource={'document'} handleOk={() => handleOk(record.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
                     showConfirmDialog={selected === record.id}/>
      }
    }
  ]
  
  async function handleFilterAction(action, values) {
    await dispatch(resetPatientConsentPagination())
    if (action === 'filter') {
      let pl = { filterData: { patient: patientId, ...values }, pagination: patientConsentPagination }
      await dispatch(updatePatientConsentFilters([false, pl, true]))
      await dispatch(fetchPatientConsentForms(pl))
      await dispatch(updatePatientConsentFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updatePatientConsentFilters([appConf.patientConsentFilters[0], undefined, true]))
      await dispatch(fetchPatientConsentForms(patientId))
      await dispatch(updatePatientConsentFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  const [uploading, setUploading] = useState(false)
  const uploadConsentForm = async options => {
    const { onSuccess, onError, file, onProgress } = options;
    let formData = new FormData();
    const toastId = getToastId("Uploading Consent Form")
    formData.append("file", file);
    await setUploading(true)
    try {
      await apiRequest.post(`/patients/${patientId}/consent-upload`, formData)
      await dispatch(fetchPatientConsentForms(patientId))
      onSuccess("Ok");
      updateToast(toastId, 'Upload successful', true)
    } catch (e) {
      onError('Failed to upload')
      updateToast(toastId, 'Upload failed', false)
    } finally {
      await setUploading(false)
    }
  }
  return <>
    {(!!doc && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false} resourceType={'Patient'}
                                                   resourceDescription={`${patient.name}'s consent form`}
                                                   defaultValues={{ auditableId: doc?.id, auditable: 'patient_document', format: 'json' }}/>}
    <PdfViewer pdf={pdf} setLoading={setLoading} loading={loading} onCancel={() => setPdfShowing(false)} visible={pdfShowing}/>
    
    {!pdfShowing && <div className="card-box m-2">
      
      <div className="w-full">
        <Table
          title={() => <TableFilters datasource={columns2} setIsVisible={setIsVisible}
                                     isVisible={isVisible} filters={appConf.patientConsentFilters[1]}
                                     actionButton={(can_update_patient_resource && !patient.deleted_at) &&
                                       <Upload accept="image/*,.pdf" customRequest={uploadConsentForm} maxCount={1}
                                               showUploadList={false}>
                                         {containedButton(null, 'Upload Consent Form', uploading, 'Uploading', null, { icon: <CloudUploadOutlined /> })}</Upload>
                                     }
                                     windowState={windowState}
                                     handleFilterAction={handleFilterAction}
                                     showClear={appConf.patientConsentFilters[0]} loading={appConf.patientConsentFilters[2]}/>}
          loading={appConf.patientConsentFilters[2]} size={'small'} {...responsiveTbl(windowState)}
          locale={{
            emptyText: !appConf.patientConsentFilters[0] ? emptyDocuments('Patient consent forms', null) : emptyTable('patient consent forms.')
          }}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchPatientConsentForms, appConf.patientConsentFilters, updatePatientConsentFilters, {})}
          onRow={(record, rowIndex) => ({
            onClick: event => {
            } // click row
          })}
          {...getSkels(appConf.patientConsentFilters[2], columns2, patientConsentForms)} pagination={patientConsentPagination}/>
      </div>
    
    
    </div>}
  </>;
}
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser,
  patientConsentForms: state.patients.patientConsentForms,
  patient: state.patients.selectedPatient,

  patientConsentPagination: state.patients.patientConsentPagination,
  appConf: state.appConf
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ConsentForms)
