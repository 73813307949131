/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Subtitle1 } from '../../../components/general'
import { Avatar, Image, Modal, Table } from "antd"
import NewInternalNote from './NewInternalNote'
import { updateNotesFilters } from "../../../app/Reducers/ConfigSlice";
import { deleteProgressNote, fetchPatientById, fetchPatientNotes, resetNotesPagination } from "../../../app/Reducers";
import { addButton, dialogCloser, emptyFun, emptyTable, formSubmitButton, getSkels, horStack, kebabMenus, modalClose, onTableChange, outlinedButton, responsiveTbl } from "../../../common/helpers";
import { setPatientDetailsLoading, updatePatientDetailsOwner } from "../../../app/Reducers/NavSlice";

import { EditFilled } from "@ant-design/icons";
import KebabMenu from "../../../components/KebabMenu";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import TableFilters from "../../../components/InputComponents/TableFilters";
import { useSubscription } from "../../../hooks/MqttHooks/useSubscription";
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';

const InternalNotes = ({ notes, patient, appConf, navs, notesPagination, appWindow,  activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [orderForm, setOrderForm] = useState(false)
  const [isVisible, setIsVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [selected, setSelected] = useState(null);
  const [editingForm, setEditingForm] = useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [note, setNote] = useState(null);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  const can_create_patient_progress_note = usePermissionCheck('create_patient_progress_note', false, [])
  const can_update_patient_progress_note = usePermissionCheck('update_patient_progress_note', false, [])
  const can_delete_patient_progress_note = usePermissionCheck('delete_patient_progress_note', false, [])
  const showPopconfirm = (note) => setSelected(note.id)
  const handleCancel = () => setSelected(null)
  const handleOk = async (id) => {
    await setConfirmLoading(true);
    let payload = { patient_id: patientId, note_id: id }
    await dispatch(deleteProgressNote(payload))
    await setSelected(null)
    await setConfirmLoading(false);
  }
  const actionMenus = kebabMenus(( can_update_patient_progress_note && !patient.deleted_at), can_view_audit_logs, can_delete_patient_progress_note)

  async function kebabMenuClick(e, note) {
    await setNote(note)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true)
        await setOrderForm(true)
        break
      case 'Delete':
        await setEditingForm(false)
        showPopconfirm(note)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
        await setEditingForm(false)
    }
  }

  /* const menu = (item) => {
     console.log(item)
     return <Menu onClick={(e) => handleMenuClick(e, item)}>
       {editMenus.map((item, index) => <Menu.Item key={index} icon={item.icon}> {item.title}  </Menu.Item>)}
     </Menu>
   }*/
  const columns = [{
    title: 'Staff', dataIndex: ['created_by', 'name'], key: 'staff', width: "12%",
    fltr: { filterable: true, type: 'text' }, render: (name) => (<>
      <div className="flex-boy-row-space">
        <Avatar src={<Image src={require("../../../assets/user.png")} />} />
        <Subtitle1>{name}</Subtitle1>
      </div>
    </>)
  }, {
    title: 'Date', dataIndex: 'created_at', key: 'date', fltr: { filterable: true, type: 'date' }
  }, {
    title: 'Note', dataIndex: 'note', key: 'note', fltr: { filterable: true, type: 'text' }
  }, {
    title: <EditFilled style={{ color: 'gray' }} />, fltr: { filterable: false },
    render: (record) => (

      /*<Dropdown dropdownRender={()=>menu(record)} trigger={['click']} disabled={selected === record.id}>
      {appConfirm(
        <MoreOutlined />, selected === record.id, null, 'Are you sure of deleting this note?', () => handleOk(record.id), handleCancel, confirmLoading)}
    </Dropdown>*/
      (<KebabMenu menus={actionMenus}
        recordItem={record}
        handleMenuClick={kebabMenuClick} resource={'internal note'} handleOk={() => handleOk(record.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
        showConfirmDialog={selected === record.id} />)
    )
  }]
  const { message } = useSubscription(`view_patient_progress_note/${patientId}`, { qos: 2, nl: true });
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await loadInternalNotes(true)
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    (async () => {
      await loadInternalNotes(false)
    })().catch(e => console.log(e))
    return emptyFun
  }, [])
  const loadInternalNotes = async (isMQ) => {
    /*if (!!notes && !!notes[0] && !isMQ && notes.every(item => item.patient_id === patientId)) {
      return
    }*/
    await dispatch(updateNotesFilters([appConf.notesFilters[0], appConf.notesFilters[1], !isMQ]))
    //In case of back navigations
    if (!patient || (patientId !== patient.id)) {
      await dispatch(setPatientDetailsLoading(!isMQ))
      await dispatch(fetchPatientById(Number(patientId)))
      await dispatch(setPatientDetailsLoading(false))
      await dispatch(updatePatientDetailsOwner(patientId))
    }
    await dispatch(fetchPatientNotes(appConf.notesFilters[0] ? appConf.notesFilters[1] : { id: patientId }))
    await dispatch(updateNotesFilters([appConf.notesFilters[0], appConf.notesFilters[1], false]))
  }

  async function handleFilterAction(action, values) {
    await dispatch(resetNotesPagination())
    if (action === 'filter') {
      let pl = { filterData: {...values, patient: patientId  }, pagination: notesPagination }
      await dispatch(updateNotesFilters([false, pl, true]))
      await dispatch(fetchPatientNotes(pl))
      await dispatch(updateNotesFilters([true, pl, false]))
    }
    if (action === 'reset' /*&& appConf.notesFilters[0]*/) {
      await dispatch(updateNotesFilters([appConf.notesFilters[0], undefined, true]))
      await dispatch(fetchPatientNotes(patient))
      await dispatch(updateNotesFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }

  return (
    (<div>
      {(!!note && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false} resourceDescription={`${patient.name}'s internal notes`}
        resourceType={'Patient'}
        defaultValues={{ auditableId: note?.id, auditable: 'progress_note', format: 'json' }} />}
      <Modal
        title={editingForm ? "Edit Note" : "Add Note"}
        open={orderForm}
        onOk={dialogCloser(setOrderForm)}
        destroyOnClose
        onCancel={dialogCloser(setOrderForm)}
        closeIcon={modalClose(dialogCloser(setOrderForm))}
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setOrderForm), 'Cancel', isSubmitting),
            formSubmitButton('new-progress-note', editingForm ? 'Update' : 'Add', isSubmitting, editingForm ? 'Updating' : 'Adding')])}
        </div>]}>
        <NewInternalNote note={note} editingForm={editingForm} setIsSubmitting={setIsSubmitting} closeModal={dialogCloser(setOrderForm)} />
      </Modal>
      <div className="w-full">
        <Table
          title={() => (<TableFilters datasource={columns} setIsVisible={setIsVisible}
            isVisible={isVisible} filters={appConf.notesFilters[1]} windowState={appWindow}
            actionButton={can_create_patient_progress_note?addButton(() => {
              setEditingForm(false)
              setOrderForm(true);
            }):null}
            handleFilterAction={handleFilterAction} showClear={appConf.notesFilters[0]}
            loading={appConf.notesFilters[2]} />)}
          loading={appConf.notesFilters[2] || navs.patientDetailsLoading} size={'small'} {...responsiveTbl(appWindow)} locale={{ emptyText: emptyTable('internal notes') }}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchPatientNotes, appConf.notesFilters, updateNotesFilters, { patient: patientId })}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
              }, // click row
            };
          }}
          {...getSkels((appConf.notesFilters[2] || navs.patientDetailsLoading), columns, notes)} pagination={notesPagination}
        />
      </div>
    </div>)
  );
}
const mapStateToProps = (state) => ({ activeUser: state.auth?.activeUser, notes: state.patients.patientProgressNotes,
  patient: state.patients.selectedPatient, appConf: state.appConf, navs: state.navs,
  notesPagination: state.patients.notesPagination, appWindow: state.appWindow
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(InternalNotes)
