/* eslint-disable no-unused-vars */
import React, {useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Col, Divider, Modal, Row,Button} from 'antd'
import {Body2, Headline6, Subtitle1} from '../../../components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import AvailabilityCard from './AvailabilityCard'
import AddAvailability from './AddAvailability'
import {toast} from "react-toastify"
import dayjs from 'dayjs'
import {apiRequest} from '../../../app/Apis'
import {fetchConsultantTimes} from '../../../app/Reducers'
import {addButton, formSubmitButton, horStack, modalClose, outlinedButton} from "../../../common/helpers";
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {EditOutlined} from '@ant-design/icons';

export const ProviderAvailabilty = ({ consultantDetails, consultant }) => {
  const daysoftheweek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isExecuting, setisExecuting] = useState(false)
  const [isAvailabilityModalVisible, setIsAvailabilityModalVisible] = useState(false);
  const dispatch = useDispatch()
  const [availabilitytype, setavailabilitytype] = useState("Availability")
  const can_view_availability_schedule = usePermissionCheck('view_availability_schedule', false, [])
  const can_delete_availability_datetimes = usePermissionCheck('delete_availability_datetimes', false, [])
  const can_update_availability_schedule = usePermissionCheck('update_availability_schedule', false, [])
  const can_view_availability_datetimes = usePermissionCheck('view_availability_datetimes', false, [])
  const can_create_availability_datetimes = usePermissionCheck('create_availability_datetimes', false, [])
  
  const handleOk = () => {
    setIsModalVisible(false);
    setIsAvailabilityModalVisible(false)
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setIsAvailabilityModalVisible(false)
  };
  const deleteAvailability = async (id) => {
    const toastId = toast("Deleting Availability", { type: "info", autoClose: false });
    const response = await apiRequest.delete(`/consultant-availability-datetimes/${id}`)
    // dispatch(fetchConsultantDaetails(consultant))
    if (response) {
      console.log(response)
      toast.update(toastId, { render: "Availability Deleted", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      dispatch(fetchConsultantTimes(consultant))
    }
  }
  return (
    (<div style={{ width: "100%" }}>
      {consultantDetails ? <>
        {/* edit modal */}
        <Modal title="Availabilty" open={isModalVisible} onOk={handleOk}
               onCancel={handleCancel}
               closeIcon={modalClose(handleCancel)}
               maskClosable={false}
               footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
                 {horStack([outlinedButton(() => setIsModalVisible(false), 'Cancel', isExecuting),
                   formSubmitButton('schedule-form', 'Save', isExecuting, 'Saving')])}
               </div>]}>
          <AvailabilityCard setIsModalVisible={setIsModalVisible} setisExecuting={setisExecuting}/>
        </Modal>
        
        {/* add modal */}
        <Modal width={600} destroyOnClose={true} title={`Add ${availabilitytype} Date and Time`}
               open={isAvailabilityModalVisible} onOk={handleOk}
               onCancel={handleCancel}
               closeIcon={modalClose(handleCancel)}
               maskClosable={false}
               footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
                 {horStack([outlinedButton(() => setIsAvailabilityModalVisible(false), 'Cancel', isExecuting),
                   formSubmitButton('availability-form', 'Save', isExecuting, 'Saving')])}
               </div>]}>
          <AddAvailability setIsAvailabilityModalVisible={setIsAvailabilityModalVisible} type={availabilitytype}
                           setisExecuting={setisExecuting}/>
        </Modal>
        <Row gutter={16}>
          {can_view_availability_schedule ? <Col span={10}>
            <div className="card-box">
              <div className="flex-boy-row-space" style={{ width: "100%" }}>
                <Headline6>Availability Schedule</Headline6>
                {can_update_availability_schedule ?
                  <Button type={"text"} size={"middle"} onClick={() => setIsModalVisible(true)} icon={<EditOutlined/>} shape={'circle'}/> : null}
              
              </div>
              {daysoftheweek.map(day => {
                return (<>
                  <div key={day} className="flex-boy-row-space" style={{ width: "100%" }}>
                    
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>{day}</Subtitle1>
                    <div style={{ flex: 1 }}>
                      {consultantDetails.schedule.filter(item => item.day_of_week === day).map(schedule => {
                        return schedule.start_time ? (<Body2 color="black"
                                                             style={{ flex: 1 }}>{dayjs(`12/12/2002 ${schedule.start_time}`).format("hh:mm a")}-{dayjs(`12/12/2002 ${schedule.end_time}`).format("hh:mm a")}</Body2>) : (
                          <Body2 color="black">Not Available</Body2>)
                      })}
                    
                    
                    </div>
                  
                  </div>
                  <Divider/>
                </>)
              })}
            
            </div>
          
          </Col> : null}
          {can_view_availability_datetimes ? <Col span={14}>
            <div className="card-box">
              <div className="flex-boy-row-space" style={{ width: "100%" }}>
                <Headline6>Availability Date And Time</Headline6>
                {can_create_availability_datetimes ? addButton(() => {
                  setavailabilitytype("Availability")
                  setIsAvailabilityModalVisible(true)
                }) : null}
              </div>
              {consultantDetails.availabilityTimes.filter(item => item.is_available === 1).map(item => {
                return (dayjs(item.end_at).diff(dayjs(item.start_at), 'days') === 0 ?
                  <div className="deletable-container" style={{ width: '100%' }}>
                    <div>
                      <Subtitle1
                        style={{ textAlign: "left" }}>{dayjs(item.start_at).format('ddd , MM/DD/YYYY')} </Subtitle1>
                      <Subtitle1
                        style={{ textAlign: 'left' }}>{dayjs(item.start_at).format('hh:mm a')} - {dayjs(item.end_at).format('hh:mm a')}</Subtitle1>
                    </div>
                    
                    {can_delete_availability_datetimes ?
                      <FontAwesomeIcon onClick={() => deleteAvailability(item.id)} color="grey"
                                       icon={["fas", "trash-alt"]}/> : null}
                  </div> : <div className="deletable-container" style={{ width: '100%' }}>
                    <div>
                      <Subtitle1>{dayjs(item.start_at).format('ddd , MM/DD/YYYY')} - {dayjs(item.end_at).format('ddd , MM/DD/YYYY')}</Subtitle1>
                    </div>
                    
                    <FontAwesomeIcon color="grey" icon={["fas", "trash-alt"]}
                                     onClick={() => deleteAvailability(item.id)}/>
                  </div>)
              })}
            
            </div>
            <div className="card-box">
              <div className="flex-boy-row-space" style={{ width: "100%" }}>
                <Headline6>Unvailability Date And Time</Headline6>
                
                
                {can_create_availability_datetimes ? addButton(() => {
                  setavailabilitytype("Unavailability")
                  setIsAvailabilityModalVisible(true)
                }) : null}
              </div>
              {consultantDetails.availabilityTimes.filter(item => item.is_available === 0).map(item => {
                return (dayjs(item.end_at).diff(dayjs(item.start_at), 'days') === 0 ?
                  <div className="deletable-container" style={{ width: '100%' }}>
                    <div>
                      <Subtitle1
                        style={{ textAlign: "left" }}>{dayjs(item.start_at).format('ddd , MM/DD/YYYY')} </Subtitle1>
                      <Subtitle1
                        style={{ textAlign: 'left' }}>{dayjs(item.start_at).format('hh:mm a')} - {dayjs(item.end_at).format('hh:mm a')}</Subtitle1>
                    </div>
                    
                    {can_delete_availability_datetimes ?
                      <FontAwesomeIcon onClick={() => deleteAvailability(item.id)} color="grey"
                                       icon={["fas", "trash-alt"]}/> : null}
                  </div> : <div className="deletable-container" style={{ width: '100%' }}>
                    <div>
                      <Subtitle1>{dayjs(item.start_at).format('ddd , MM/DD/YYYY')} - {dayjs(item.end_at).format('ddd , MM/DD/YYYY')}</Subtitle1>
                    </div>
                    
                    {can_delete_availability_datetimes ?
                      <FontAwesomeIcon onClick={() => deleteAvailability(item.id)} color="grey"
                                       icon={["fas", "trash-alt"]}/> : null}
                  </div>)
              })}
            </div>
          
          </Col> : null}
        </Row>
      </> : null}
    </div>)
  );
}
const mapStateToProps = (state) => ({ consultantDetails: state.consultants.selectedConsultantDetails,
  consultant: state.auth?.activeUser, activeUser: state.auth?.activeUser
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ProviderAvailabilty)
