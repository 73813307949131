import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AutoComplete, DatePicker, Form, Input, Select } from 'antd'
import dayjs from "dayjs"
import { updateConsultant } from '../../../app/Reducers'
import { RegularButtonCircle, Subtitle1 } from '../../../components/general'
import { apiRequest } from '../../../app/Apis'
import ReactMapGL from 'react-map-gl';
import Geocoder from "react-map-gl-geocoder";
import { mapboxToken } from '../../../configure/constants'
import {
  addButton,
  getPhoneParams,
  emptyFun,
  executeChunkFn,
  formatPhone,
  GetOptions,
  inputRow,
  showCurrentAddress,
  appLog,
  makeSelectOptions,
  makeAutoCompleteOptions,
  deleteButton
} from "../../../common/helpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { US_STATES } from '../../../common/States'
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {nationalLanguages} from '../../../common/lists';
import { findTimeZone } from '../../../app/Apis/commonApi'
import AppPhoneInput from '../../../components/InputComponents/AppPhoneInput';

const degree = ["DPM", "M.D.", "MBA", "MD", "NP", "PA", "Sales", "Consultant"]
const designations = ["D.P.M.", "L.V.N.", "M.D.", "N.P.", "Consultant", "R.N."]
const Roles = ['DPM', 'Facility Admin', 'MD', 'Front Desk Executive', 'Medical record associates', 'Nurse Practioner',
  'Physical Trainer', 'Regional clinical coordinate', 'Regional executive director', 'Regional medical director',
  'Human Resource', 'Billing', 'Scheduling']
const EditConsultant = ({ closeModal, setisExecuting, consultant, activeUser, masterList }) => {
  const dateFormat = 'MM/DD/YYYY';
  const { Option } = Select;
  const dispatch = useDispatch();
  const [states, setStates] = useState([]);
  const [phoneValue, setPhoneValue] = useState(!!consultant ? { ...getPhoneParams(consultant?.phone_no, 'phone_no') } : undefined);
  const [initialValues, setInitialValues] = useState(!!consultant ? { ...consultant, ...(!!consultant?.phone_no && { ...getPhoneParams(consultant?.phone_no, 'phone_no') }), practice_locations: consultant.office_location?.length ? [...consultant.office_location] : [''] } : {})
  const can_update_consultant_date_of_hire = usePermissionCheck('update_consultant_date_of_hire', false, [])
  const can_update_consultant_role = usePermissionCheck('update_consultant_role', false, [])
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    // fetchStates();
    fetchRoles()
    console.log("Consultant:", consultant)
    return emptyFun
  }, [])
 
  const fetchRoles = async () => {
    const response = await apiRequest.get('/roles').catch(function (err) {
      console.log(err)
    })
    console.log(response.data.data)
    if (response && response.data && response.data.data) {
      setRoles(response.data.data)
    }
  }
  const onFinish = async (values) => {
    setisExecuting(true)
    console.log(values)
    const payload = {
      ...values, ...locationData, timezone: "PDT",
      birth_date: dayjs(values.birth_date).format(dateFormat),
      date_of_hire: dayjs(values.date_of_hire).format(dateFormat), ...phoneValue,
      /*state_licenses: [
        ...values.state_licenses.filter(
          (item) => !Object.values(item).every((x) => x === null || x === '')
        ),
      ],*/
    }
    let locations = values.practice_locations?.length ? [...values.practice_locations.map(item => offices.find(office => item.office_name === office.value)?.key || item?.id)] : []
    payload.practice_locations = locations.map(item => parseFloat(item))
    let params = { data: payload, consultant_id: consultant.id }
    await executeChunkFn(dispatch, updateConsultant, params, setisExecuting, closeModal)
  }

  const [viewport, setViewport] = useState({ latitude: 37.7577, longitude: -122.4376, zoom: 8 });
  const [locationData, setLocationData] = useState(null)
  const geocoderContainerRef = useRef();
  const mapRef = useRef();
  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);
  const onResult = useCallback(async (result) => {
    // console.log(result)
    let center = result.result.center
    let context = result.result.context
    let state = context.find(item => item.id.includes('region'))
    let city = context.find(item => item.id.includes('place'))
    let zip_code = context.find(item => item.id.includes('postcode'))
    let timezone = await findTimeZone(city ? city.text : result.result.place_name)
    let data = {
      city: city ? city.text : result.result.place_name, state: state ? state.text : null,
      zip_code: zip_code ? zip_code.text : result.result.place_name, co_ordinates: `${center[0]},${center[1]}`,
      address_1: result.result.place_name,
      timezone: timezone ? timezone : null
    }
    setLocationData(data)
    form.setFieldsValue({
      address_1: result.result.place_name,
      city: city ? city.text : null,
      state: state ? state.text : null,
      zip_code: zip_code ? zip_code.text : null,
      timezone: timezone
    });
  }, [])
  const disabledFutureDates = (current) => {
    let customDate = dayjs();
    let tooOld = dayjs("1869-12-31")
    return (current && current > dayjs(customDate, dateFormat)) || (current && current < dayjs(tooOld, dateFormat));
  }
  const [acc_type, setAcc_type] = useState("clinical_staff")
  const handleTypeChange = (e) => {
    setAcc_type(e)
  }
  const [form] = Form.useForm();
  const [office_locations, setofficeLocations] = useState([...consultant.office_location.map(item => {
    return ({
      ...item,
      key: item.id,
      value: item.office_name
    })
  })])
  const search_offices = async (value) => {
    const data = await apiRequest.get(`/integrations/dr_first/practice_location?search=${value}`)
    if (data && data.data.data) {
      setofficeLocations(data.data.data)
    }
  }
  const addOffice = (option) => {
    let locs = [...offices]
    locs.push(option)
    setoffices(locs)
  }
  const [offices, setoffices] = useState([])
  
  return (
    <Form
      form={form}
      name="consultantForm"
      id="consultantForm"
      labelCol={{  span: 24 }}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        ...initialValues, /*state_licenses: initialValues.state_licenses?.length ? [...initialValues.state_licenses] : [
          {
            state_abbreviation: null,
            secondary_license_number: null,
            primary_license_number: null,
          },
        ],*/ birth_date: dayjs(initialValues.birth_date), date_of_hire: dayjs(initialValues.date_of_hire),
        languages: initialValues.languages?.[0] ? [...initialValues.languages] : undefined,
      }}
    >

      <div className="flex-boy-row-space">
        {inputRow([
          <Form.Item
              style={{ width: '100%' }}
              name="account_type"
              label="Account Type"
              rules={[{ required: true, message: 'Missing Account Type' }]}>
            <Select onChange={handleTypeChange} popupMatchSelectWidth={false} style={{ width: '100%' }}  disabled={can_update_consultant_role}>
              <Option value={"Provider"}> Provider</Option>
              <Option value={"Clinical Staff"}> Clinical Staff</Option>
            </Select>
          </Form.Item>,
          <Form.Item style={{ width: '100%' }} name='title' label='Title' rules={[{ required: false, message: 'Missing/Invalid title', pattern: /[a-zA-Z ]$/, }]} >
            <Input autoComplete={'off'} placeholder='Title' />
          </Form.Item>,
          /*<Form.Item style={{ width: '100%' }} name="name" label="Name" rules={[{ required: true, message: 'Missing name', pattern: /[a-zA-Z ]$/ }]}>
            <Input autoComplete={'off'} placeholder="Name" />
          </Form.Item>*/
        ])}
      </div>

      {inputRow([
        <Form.Item
            style={{ width: '100%' }}
            name="first_name"
            label="First Name"
            rules={[{ required: true, message: 'Missing/Invalid first name', pattern: /[a-zA-Z ]$/ }]}>
          <Input autoComplete={'off'} placeholder="First Name" />
        </Form.Item>,
        <Form.Item
            style={{ width: '100%' }}
            name="middle_name"
            label="Middle Name"
            rules={[{ required: false, message: 'Missing/Invalid name', pattern: /[a-zA-Z ]$/ }]}>
          <Input autoComplete={'off'} placeholder="Middle Name" />
        </Form.Item>,
        <Form.Item
            style={{ width: '100%' }}
            name="last_name"
            label="Last Name"
            rules={[{ required: true, message: 'Missing/Invalid Last Name', pattern: /[a-zA-Z ]$/ }]}>
          <Input autoComplete={'off'} placeholder="Last Name" />
        </Form.Item>
      ])}
      {inputRow([
        <Form.Item
          style={{ width: '100%' }}
          name="birth_date"
          label="Date of Birth"
          rules={[{ required: true, message: 'Missing Date of Birth' }]}>
          <DatePicker disabledDate={disabledFutureDates} style={{ width: '100%' }} format={dateFormat} />
        </Form.Item>,
        <Form.Item
          name="gender"
          label="Gender"
          style={{ width: '100%' }}
          rules={[{ required: true, message: 'Missing Gender' }]}>
          <Select popupMatchSelectWidth={false}
            placeholder="gender"
            style={{ width: '100%' }}
            allowClear>
            {GetOptions('gender')}
          </Select>
        </Form.Item>,
        <Form.Item
          style={{ width: '100%' }}
          name="date_of_hire"
          label="Date of Hire"
          rules={[{ required: true, message: 'Missing Date of hire' }]}>
          <DatePicker style={{ width: '100%' }} format={dateFormat} disabled={can_update_consultant_date_of_hire} />
        </Form.Item>
      ])}
      {inputRow([
        <Form.Item
          style={{ width: '100%' }}
          name="npi"
          label="NPI"
          rules={[{ required: form.getFieldValue('account_type') === 'Provider', message: 'Missing NPI' }]}>
          <Input autoComplete={'off'} placeholder="NPI" />
        </Form.Item>,
        <Form.Item
          style={{ width: '100%' }}
          name="caqh_id"
          label="CAQH ID"
          rules={[{ required: false, message: 'Missing CAQH ID' }]}>
          <Input autoComplete={'off'} placeholder="CAQH ID" />
        </Form.Item>,
        <Form.Item
          style={{ width: '100%' }}
          name="bhpn_practitioner_id"
          label="BHPN Practitioner ID"
          rules={[{ required: false, message: 'Missing BHPN' }]}>
          <Input autoComplete={'off'} placeholder="BHPN Practitioner ID" />
        </Form.Item>
      ])}
      {inputRow([
        <Form.Item
          style={{ width: '100%' }}
          name="email"
          label="Email"
          rules={[{ required: true, message: 'Missing Email' }]}>
          <Input autoComplete={'off'} placeholder="Email" type="email" />
        </Form.Item>,
        <AppPhoneInput name={'phone_no'} setPhoneValue={setPhoneValue} phoneValue={phoneValue} formItemProps={{ style: { width: '100%' } }} />
        /*<Form.Item
          style={{width: '100%'}}
          name="phone_no"
          label="Phone Number"
          rules={[{required: true, message: 'Missing Phone Number'},
            {max: 19, message: "Value should be less than 19 characters"}]}
          validateTrigger="onBlur">
          <NumberFormat autoComplete={'off'} customInput={Input} format="+(#)(###) ###-####" placeholder="Phone Number"/>
        </Form.Item>*/
      ])}
      {inputRow([
        <Form.Item
          style={{ width: '100%' }}
          name="degree"
          label="Degree"
          rules={[{ required: true, message: 'Missing Degree' }]}>
          <Select popupMatchSelectWidth={false}
            style={{ width: '100%' }}>
            {GetOptions('degree')}
          </Select>
        </Form.Item>,
        <Form.Item
          style={{ width: '100%' }}
          name="language"
          label="Language"
          rules={[{ required: true, message: 'Missing language' }]}>
          <AutoComplete filterOption={true} options={makeAutoCompleteOptions(nationalLanguages)}/>
        </Form.Item>
      ])}
      {inputRow([
        <Form.Item
          style={{ width: '100%' }}
          name="designation"
          label="Designation"
          rules={[{ required: true, message: 'Missing designation' }]}>
          <Select popupMatchSelectWidth={false}
            style={{ width: '100%' }}>
            {GetOptions('designation')}
          </Select>
        </Form.Item>,
        <Form.Item
          style={{ width: '100%' }}
          name="roles"
          label="Role"
          rules={[{ required: true, message: 'Missing Role' }]}>
          <Select popupMatchSelectWidth={false}
                  options={makeSelectOptions(roles.map(item =>item.name))}
                  mode="multiple" style={{ width: '100%' }} disabled={can_update_consultant_role}/>
        </Form.Item>
      ])}

      {/* {inputRow([
        <Form.Item
          style={{ width: '100%' }}
          name="primary_license_number"
          label="Primary Licence Number"
          rules={[{ required: form.getFieldValue('account_type') === 'Provider', message: 'Missing Primary Licence Number' }]}>
          <Input autoComplete={'off'} placeholder="Primary Licence Number" />
        </Form.Item>,
        <Form.Item
          style={{ width: '100%' }}
          name="secondary_license_number"
          label="Secondary Licence Number"
          rules={[{ required: false, message: 'Missing Role' }]}>
          <Input autoComplete={'off'} placeholder="Secondary Licence Number" />
        </Form.Item>
      ])} */}
      <Subtitle1 className="Validator" style={{ textAlign: 'left', marginBottom: '10px' }}>Address search</Subtitle1>
      <Form.Item
        style={{ width: '100%', ...(consultant.address_1 && { marginBottom: 0 }) }}
        name="default_region"
        label=""
        rules={[{ required: false, message: 'Missing Region' }]}>
        <div
          ref={geocoderContainerRef}
          style={{ position: "absolute", top: 0, left: 0, zIndex: 1, width: "100%" }} />
        <ReactMapGL
          ref={mapRef}
          {...viewport}
          width="100%"
          height="100%"
          onViewportChange={handleViewportChange}
          mapboxApiAccessToken={mapboxToken}>
          <Geocoder
            mapRef={mapRef}
            containerRef={geocoderContainerRef}
            onViewportChange={handleViewportChange}
            mapboxApiAccessToken={mapboxToken}
            countries="us,ke"
            onResult={onResult}
            marker={false}
            onError={onResult}
          />
        </ReactMapGL>
      </Form.Item>
     
      {(locationData || initialValues.address_1) && <>
        <Form.Item
          style={{ width: '100%', marginTop: "10px" }}
          name="address_1"
          label="Address"
          rules={[{ required: true, message: 'Missing location' }]}
        >
          <Input autoComplete={'off'} placeholder="Address" />
        </Form.Item>
        {inputRow([
          <Form.Item
            style={{ width: '100%' }}
            name="state"
            label="State"
            rules={[{ required: true, message: 'Missing State' }]}>
            <Input autoComplete={'off'} placeholder="State" />
          </Form.Item>,
          <Form.Item
            style={{ width: '100%' }}
            name="city"
            label="City"
            rules={[{ required: true, message: 'Missing City' }]}>
            <Input autoComplete={'off'} placeholder="City" />
          </Form.Item>,
          <Form.Item
            style={{ width: '100%' }}
            name="zip_code"
            label="Zip Code"
            rules={[
              {
                required: true,
                message: 'Missing Zip code',
              },
              // {
              //   pattern: /^\d{5}(?:[-\s]\d{4})?$/,
              //   message: 'Please enter a valid zip Code',
              // }
            ]}>
            <Input autoComplete={'off'} placeholder="zip" />
          </Form.Item>,
        ])}
        <Form.Item
          style={{ width: '100%' }}
          name="timezone"
          label="Time Zone"
          rules={[{ required: false, message: 'Missing Time Zone' }]}>

          <Input autoComplete={'off'} placeholder="timezone" />
        </Form.Item>
      </>}

      <Form.Item
        label="Address 2"
        name="address_2">
        <Input autoComplete={'off'} placeholder="App, suite, unit, building, floor, etc" />

      </Form.Item>

{/*
      <Form.List label='State licenses' name='state_licenses'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, index) => (
              <div
                key={key}
                style={{
                  display: 'flex',
                  marginBottom: 8,
                  alignItems: 'center',
                  flex: 1,
                }}
                align='center'
              >
                  {inputRow([
                    <Form.Item
                      {...restField}
                      name={[name, 'state_abbreviation']}
                      //
                      fieldKey={[fieldKey, 'state_abbreviation']}
                      rules={[
                        {
                          required:
                            form.getFieldValue('account_type') === 'Provider',
                          message: 'missing State',
                        },
                      ]}
                      label='State'
                    >
                      <Select placeholder='State'>
                        {US_STATES.map((state, index) => (
                          <Option key={index} value={state.Code}>
                            {state.State}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>,
                    <Form.Item
                      {...restField}
                      name={[name, 'primary_license_number']}
                      fieldKey={[fieldKey, 'primary_license_number']}
                      rules={[
                        {
                          required:
                            form.getFieldValue('account_type') === 'Provider',
                          message: 'Missing Primary Licence Number',
                        },
                      ]}
                      label='Primary Licence Number'
                    >
                      <Input
                        autoComplete={'off'}
                        placeholder='Primary Licence Number'
                      />
                    </Form.Item>,

                    <Form.Item
                      {...restField}
                      name={[name, 'secondary_license_number']}
                      fieldKey={[fieldKey, 'secondary_license_number']}
                      label='Secondary Licence Number'
                      style={{ width: '100%' }}
                      rules={[{ required: false, message: 'Missing Role' }]}
                    >
                      <Input
                        autoComplete={'off'}
                        placeholder='Secondary Licence Number'
                      />
                    </Form.Item>,
                  ])}
                
                {deleteButton(() => remove(name))}
              </div>
            ))}
            <Form.Item>{addButton(() => add(), 'grey')}</Form.Item>
          </>
        )}
      </Form.List>*/}

      <Subtitle1 style={{ textAlign: 'left', marginBottom: '10px' }}>Practice Locations</Subtitle1>
      <Form.List label='Practice Locations' name='practice_locations'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, index) => (
              <div
                key={key}
                style={{
                  display: 'flex',
                  marginBottom: 8,
                  alignItems: 'center',
                  flex: 1,
                }}
                align='center'
              >
                <div style={{ flex: 1 }}>
                  <Form.Item
                    name={[name, 'office_name']}
                    label="Office"
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: false,
                        whitespace: true,
                        message: "Please add a practice Location or delete this field.",
                      },
                    ]}
                    noStyle
                  >
                    <AutoComplete popupMatchSelectWidth={false}
                      notFoundContent="Couldn't find any matches!"
                      style={{ width: "100%" }}

                      onSelect={(value, option) => addOffice(option)}
                      onSearch={(value) => search_offices(value)}

                      placeholder="search for offices">
                      {office_locations.map(item => (<Option key={item.id} value={item.office_name}>{item.office_name}</Option>))}
                    </AutoComplete>

                  </Form.Item>
                </div>
                <Form.Item
                  style={{
                    marginBottom: '0px',
                    alignSelf: 'center',
                    marginLeft: '2px',
                  }}
                >
                  <RegularButtonCircle
                    borderColor='grey'
                    color='transparent'
                    onClick={() => remove(name)}
                    height='30px'
                  >
                    <FontAwesomeIcon color='grey' icon={['fas', 'times']} />
                  </RegularButtonCircle>
                </Form.Item>
              </div>
            ))}
            <Form.Item>{addButton(() => add(), 'grey')}</Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
}
const mapStateToProps = (state) => ({  activeUser: state.auth?.activeUser })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(EditConsultant)
