/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Grid, Table} from 'antd';
import {createPermissionGroup, deactivateOrganisationStaff, editPermissionGroup, fetchOrgPermGroups, resetOrgPermPagination, setFacilitiesLoaded, updateOrgPermFilters} from '../../../app/Reducers';
import {containedButton, createFormInputsFromTableColumns, dialogCloser, emptyFun, emptyTable, executeChunkFn, getSkels, kebabMenus, onTableChange, responsiveTbl} from "../../../common/helpers";
import TableFilters from "../../../components/InputComponents/TableFilters";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import KebabMenu from '../../../components/KebabMenu';
import * as Sentry from '@sentry/react';
import {errMsg, toastUp} from '../../../app/Reducers/reducerUtils';
import DynamicModalForm from '../../../components/DynamicModalForm';
import PermissionsForm from './components/PermissionsForm';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';
const { useBreakpoint } = Grid;
const OrganisationPermGroups = ({  organisation, orgPermGroups, orgPermPagination, appConf, organizationPermissions,  activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
    const windowState=useBreakpoint()
    const dispatch = useDispatch();
    const organisationId = organisation?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
    const { message } = useSubscription(`permissionGroups`, { qos: 2, nl: true });
    const [phoneValue, setPhoneValue] = useState(undefined);
    const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
    const [formName, setFormName] = useState(undefined);
    const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [permGroup, setPermGroup] = useState(null)
    const [selected, setSelected] = useState(null)
    const [isModalOpen, openModal] = useState(false)
    const dateFormat = "YYYY-MM-DD";
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
    const subRegionsRaw = useSelector(state => state.masters.masterListRaw.find(item => item.system_name === 'sub_region')?.options)
    useEffect(() => {
      if (!!message) {
        console.log('MQTT MESSAGE:  ', message);
        loadFacilities(true).catch(e => console.log(e));
      }
      return emptyFun;
    }, [message]);
    useEffect(() => {
      loadFacilities(false).catch(e => console.log(e));
      return emptyFun;
    }, []);
    
    const loadFacilities = async (isMQ) => {
      /*if (!!orgPermGroups[0] && !isMQ) {
        return
      }*/
      await dispatch(updateOrgPermFilters([appConf.orgPermFilters[0], appConf.orgPermFilters[1], !isMQ]));
      await dispatch(fetchOrgPermGroups(appConf.orgPermFilters[0] ? appConf.orgPermFilters[1] : { organisationId }));
      await dispatch(setFacilitiesLoaded(true));
      await dispatch(
        updateOrgPermFilters([
          appConf.orgPermFilters[0],
          appConf.orgPermFilters[1],
          false
        ])
      );
    };
    
    const actionMenus = kebabMenus(can_view_audit_logs, can_view_audit_logs, true)
    const columns = [
      { title: 'Name', dataIndex: 'name', key: 'name', fltr: { filterable: true, type: 'text', editableCol: true, required: true, fullRow: true } },
      { title: 'Description', dataIndex: 'description', key: 'description', fltr: { filterable: true, type: 'textarea', editableCol: true, required: true, fullRow: true } },
      {
        fltr: { filterable: false }, render: (record) => {
          return <KebabMenu menus={actionMenus}
                            recordItem={record}
                            handleMenuClick={kebabMenuClick} resource={'organisation permission group'} handleOk={() => handleOk(record.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
                            showConfirmDialog={selected === record.id}/>;
        }
      }
    
    ];
    const [patientForm, setPatientForm] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    
    let permGroupFormInputs = createFormInputsFromTableColumns(false, columns, phoneValue, setPhoneValue, null, null, dateFormat)
    //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
    let dynamicFormInputFields = {
      'Edit Permission Group': permGroupFormInputs,
      'Add New Permission Group': permGroupFormInputs
    }
    
    async function kebabMenuClick(e, record) {
      switch (actionMenus[e.key].title) {
        case 'Edit':
          await setPermGroup(record)
          await openModal(true)
          break
        case 'Delete':
          showPopconfirm(record.id)
          break
        case 'Audit':
          break
        default:
      }
    }
    
    const showPopconfirm = (id) => setSelected(id);
    const handleOk = async (id) => {
      await setConfirmLoading(true)
      await dispatch(deactivateOrganisationStaff({ organisationId, id }))
      await setSelected(0)
      await setConfirmLoading(false)
    }
    const handleCancel = () => setSelected(0);
    
    async function handleFilterAction(action, values) {
      await dispatch(resetOrgPermPagination());
      if (action === 'filter') {
        let pl = { filterData: { ...values, organisationId }, pagination: orgPermPagination };
        await dispatch(updateOrgPermFilters([false, pl, true]));
        await dispatch(fetchOrgPermGroups(pl));
        await dispatch(updateOrgPermFilters([true, pl, false]));
      }
      if (action === 'reset' /* && appConf.orgPermFilters[0]*/) {
        await dispatch(
          updateOrgPermFilters([appConf.orgPermFilters[0], undefined, true])
        );
        await dispatch(fetchOrgPermGroups({ filterData: { organisationId } }));
        await dispatch(updateOrgPermFilters([false, undefined, false]));
      }
      setIsVisible(false);
    }
    
    const [isExecuting, setisExecuting] = useState(false);
    // const formButtonText = isPreview ? 'Save Office' : 'Preview Office';
    
    const openPermGroupDynamicForm = async (record) => {
      //console.log({record})
      await setFormName(!!record ? 'Edit Permission Group' : 'Add New Permission Group');
      await setDefaultDynamicModalFormValues(!!record ? { ...record, organisationId } : { organisationId });
      await setDynamicFormOpen(true);
    };
    /**
     * This function is handling returned form entries from the dynamic form
     * */
    const onDynamicFormEntriesResult = async (entries) => {
      console.log('entries.values: ', entries.values)
      try {
        switch (formName) {
          case 'Add New Permission Group':
            // await executeChunkFn(dispatch, createPermissionGroup, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen), null)
            break;
          case 'Edit Permission Group':
            await executeChunkFn(dispatch, editPermissionGroup, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen), null)
            break;
          default:
            return
        }
      } catch (e) {
        Sentry.captureException(e);
        //console.log(e);
        toastUp(errMsg(e, false, formName), false);
      }
    };
    return (
      (<div className="main-page">
        {(isDynamicFormOpen && !!formName) && (
          <DynamicModalForm
            setDynamicFormOpen={setDynamicFormOpen}
            isDynamicFormOpen={isDynamicFormOpen}
            inputFields={dynamicFormInputFields[formName]}
            onDynamicFormEntriesResult={onDynamicFormEntriesResult}
            closeModal={dialogCloser(setDynamicFormOpen)}
            isExecuting={isExecuting} phoneValue={phoneValue}
            defaultValues={defaultDynamicModalFormValues}
            formName={formName}
          />
        )}
        <PermissionsForm isModalOpen={isModalOpen} openModal={openModal} organisation={organisation} permGroup={permGroup} organizationPermissions={organizationPermissions}/>
        <div className="w-full">
          <Table
            title={() => (<TableFilters
              datasource={columns}
              setIsVisible={setIsVisible}
              isVisible={isVisible}
              filters={appConf.orgPermFilters[1]}
              actionButton={
                containedButton(
                  async () => {
                    await setPermGroup(null)
                    await openModal(true)
                  },
                  'Add New', false, null, null, { icon: <PlusOutlined /> })}
              windowState={windowState}
              handleFilterAction={handleFilterAction}
              showClear={appConf.orgPermFilters[0]}
              loading={appConf.orgPermFilters[2]}
            />)}
            rowKey={'id'}
            loading={appConf.orgPermFilters[2]}
            {...responsiveTbl(windowState)}
            locale={{ emptyText: emptyTable('organisation facilities') }}
            onChange={(pagination, filters, sorter) =>
              onTableChange(pagination, filters, sorter, dispatch, fetchOrgPermGroups, appConf.orgPermFilters, updateOrgPermFilters, { organisationId })
            }
            {...getSkels(appConf.orgPermFilters[2], columns, orgPermGroups)}
            pagination={orgPermPagination}
          />
        </div>
      </div>)
    );
  }
const mapStateToProps = (state) => ({
  orgPermGroups: state.organisations.orgPermGroups,
  appConf: state.appConf,
  navs: state.navs, activeUser: state.auth?.activeUser,
  orgPermPagination: state.organisations.orgPermPagination,
  organizationPermissions: state.organisations.organizationPermissions,
   organisation: state.organisations.selectedOrganisation
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(OrganisationPermGroups);
