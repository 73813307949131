import React, {useEffect, useState} from 'react';
import {Headline6} from "../../../components";
import {Col, Divider, Grid, Row} from "antd";
import {connect, useDispatch} from "react-redux";

import {fetchOrganisationById, fetchOrganizationPermissions} from '../../../app/Reducers';
import {appMessage, dialogCloser, emptyFun, getOrgStatus, kebabMenus} from "../../../common/helpers";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import KebabMenu from "../../../components/KebabMenu";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
const { useBreakpoint } = Grid;
function OrganisationInfoPage({  organisation, setDetailsForm, navs, activeUser, insurances, organizationPermissions,...props }) {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState=useBreakpoint()
  const dispatch = useDispatch()
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const organisationId = organisation?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const { message } = useSubscription(`organisationProfile${organisation.id}`, { qos: 2, nl: true });
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  console.log({ organisation })
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await dispatch(fetchOrganisationById(Number(organisation.id)))
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    (async () => {
      if (!organisation || (organisationId !== organisation.id)) {
        await dispatch(fetchOrganisationById(Number(organisationId)))
      }
    })().catch(e => console.log(e))
    return emptyFun
  }, [organisation])
  useEffect(()=>{
    dispatch(fetchOrganizationPermissions())
    return emptyFun
  },[])
  const actionMenus = kebabMenus(can_view_audit_logs, can_view_audit_logs, false)
  
  async function kebabMenuClick(e) {
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setDetailsForm(true);
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      case 'Delete':
        appMessage('Operation not allowed', 'error')
        break
      default:
        await setShowAuditDialog(false);
        await setDetailsForm(false);
    }
  }
  
  return (<div className="card-box h-full">
    {showAuditDialog && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)}
                                        loading={false} resourceDescription={organisation.name} resourceType={'Patient'}
                                        defaultValues={{ auditableId: organisationId, auditable: 'organisation', format: 'json' }}/>}
    <div className="flex-boy-col items-center" style={{ width: "100%" }}>
      <div className="flex flex-col justify-center w-full">
        <Headline6 style={{ textAlign: 'center' }}>{organisation.name}</Headline6>
        <div className="flex flex-row justify-center w-full mt-4">
          {getOrgStatus(organisation.status)}
          <KebabMenu menus={actionMenus}
                     recordItem={organisation}
                     handleMenuClick={kebabMenuClick} resource={'organisation'} handleOk={null} handleCancel={null} confirmLoading={null}
                     showConfirmDialog={false}/>
        
        </div>
      </div>
    
    </div>
    <Divider/>
    {<Row gutter={[4, 4]} align={"stretch"} justify={"start"} style={{ width: '100%', margin: 0 }}>
      <Col className="text-left" xs={24} sm={24} md={24} lg={24} xl={24} flex={"auto"} style={{ width: '100%' }}>
        <div className=" my-2 flex flex-row justify-between w-full">
          <p className="text-xs">{organisation?.contact_person || 'No Contact person'}</p>
        </div>
        <p className="my-2  text-blue-500">{organisation?.email}</p>
        <p className="my-2">ID: {organisation?.pcc_org_id || 'N/A'}</p>
        <Divider/>
        <div className="my-4"></div>
        <p className="mb-3 text-xs">{`Description: ${organisation?.notes || 'N/A'}`}</p>
        <p className=" mb-3 font-semibold text-md">{organisation?.medical_facility}</p>
        <div className="my-4"></div>
        <Divider/>
      </Col>
    </Row>}
  </div>);
}

const mapStateToProps = (state) => ({ organisation: state.organisations.selectedOrganisation,
  appConf: state.appConf, navs: state.navs, activeUser: state.auth?.activeUser,
  organizationPermissions: state.organisations.organizationPermissions,

})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(OrganisationInfoPage);
