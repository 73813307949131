import React from 'react'
import { connect } from 'react-redux'
import { Subtitle1 } from '../../components'
import HeaderTop from '../../components/HeaderTop'

export const Help = (props) => {
  return (
      <div className="main-page">
          <HeaderTop child={<Subtitle1>Help</Subtitle1>} />
          <div className='rcopia-iframe' style={{ width: "100%" }}>
              <iframe id="iframe" src="https://tellanbusiness.atlassian.net/servicedesk/customer/portals" frameborder="0" title="Help"></iframe>
        </div>
          </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Help)