import { Divider, Form, Input, Avatar, Checkbox, Tag } from 'antd'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { apiRequest } from '../../../app/Apis'
import { Body1, Headline6, Subtitle1 } from '../../../components'
import _ from "lodash"
import { appLog } from "../../../common/helpers";
export const NewGroupChat = (props) => {
    const onFinish = () => {
        //console.log("aweh am done")
    }
    const onFinishFailed = () => {
        //console.log("aweh am not done")
    }
    const [consultantOptions, setSalesManagerOptions] = useState([])
    const [members, setmembers] = useState([])
    const handleConsultantssearch = async (value) => {
        let new_depth = [...consultantOptions]
        const { data } = await apiRequest.get(`/consultants?search=${value}`)
        if (data) {
            // console.log(data.data)
            new_depth = [...data.data]

            setSalesManagerOptions(_.uniqBy(new_depth, 'id'));
        }
    };
    const onconsultantSelect = (value) => {
        console.log(value, consultantOptions)
        if (value) {
            let wcc = value.map(item => consultantOptions.find(element => element.name === item.toString()))
            console.log(wcc)

        }
    };
    const onCheckChange = (checked, item) => {
        console.log(item)
        let steps = [...members]
        let idx = steps.findIndex(supply => supply.id === item.id)
        console.log(idx)
        if (idx && idx === -1) {

            steps.push(item)
            setmembers([...steps])
        } else {
            if (!checked) {
                steps.splice(idx, 1)
                setmembers([...steps])
            }
        }


    };
    return (
        <div style={{ padding: "10px" }}>

            <Headline6>New Group Chat</Headline6>
            <Divider />
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item

                    name="group_name"
                    rules={[{ required: true, message: 'Please input a groupname!' }]}

                >
                    <Input placeholder="Group Name" />
                </Form.Item>

                <Form.Item

                    name="group_members"

                    style={{ width: '100%' }}
                    rules={[{ required: false, message: 'Missing Sales Manager' }]}>
                    <Input onChange={(e) => handleConsultantssearch(e.target.value)} placeholder="Search People" />
                </Form.Item>
            </Form>
            <div className="flex-boy-row" style={{ width: '100%', flexWrap: 'wrap' }}>
                {members.map(item => {
                    return (
                        <Tag style={{ margin: "2px" }} closable onClose={() => onCheckChange(false, item)}>
                            {item.name}
                        </Tag>
                    )
                })}
            </div>
            <Divider />
            <div className="new-group-body" style={{ height: "50vh", overflowY: 'scroll' }}>
                {consultantOptions.map(item => {
                    return (
                        < >
                            <div key={item.id} className="wcc-chat-card flex-boy-row" style={{ alignItems: 'center', padding: '5px' }}>
                                <Avatar style={{ backgroundColor: '#EF8100' }} src={item.profile_image_url} alt={item.name.substring(0, 1)}>{item.name.substring(0, 1)}</Avatar>
                                <Subtitle1 style={{ flex: 1, textAlign: 'left', marginLeft: '2px' }}>{item.name}</Subtitle1>
                                <div className="flex-boy-row" style={{ alignItems: 'center' }}>
                                    <Body1 color='black' style={{ margin: '5px' }}>WCC</Body1>
                                    <Checkbox checked={members.find(member => member.id === item.id)} onChange={(e) => onCheckChange(e.target.checked, item)}></Checkbox>
                                </div>
                            </div>
                            <Divider />
                        </>
                    )
                })}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(NewGroupChat)
