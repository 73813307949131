/* eslint-disable no-unused-vars */
import React from 'react';
import { AppointmentCard, Body2, Indicator, Subtitle1, Subtitle2 } from '../../../components/general'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'

import { connect, useDispatch } from 'react-redux'
import { selectAppointment } from '../../../app/Reducers';
import { appLog } from "../../../common/helpers";
import { RetweetOutlined } from '@ant-design/icons';

const TimeBlockCard = ({ color, wcc, appointment,  mode, setevent }) => {
    const dispatch = useDispatch()
    const goToAppointment = () => {
        // if (appointment && can_view_appointment) {
        //   dispatch(selectAppointment({ navigate, record: appointment }))
        //   navigate(`/appointments/${appointment.id}`)

        // }
        setevent(appointment)
    }
    return (<AppointmentCard onClick={goToAppointment} className="flex-boy-column-space items-center" style={{ backgroundColor: '#E9BCC9', height: "inherit", overflow: 'hidden', minHeight: '40px', border: `1px solid crimson`, borderLeft: `5px solid crimson`, padding: '8px 0px' }}
        color={color}>
        <div className="flex-boy--space" style={{ width: "100%" }}>

            <div className="flex-boy-row-space" style={{ width: "100%" }}>
                <FontAwesomeIcon onClick={() => console.log("heh")} color="grey" icon={["fas", 'user']} />
                <Body2 color='black' className="name" style={{
                    flex: 1, textAlign: 'left', marginLeft: "5px"
                }}>{appointment ? appointment.consultant?.name : "Consultant Name"}</Body2>

            </div>
            {appointment ? <div onClick={goToAppointment} className="flex-boy-row"
                style={{ width: "100%", flexWrap: 'wrap', padding: "5px" }}>
                {/* <div className="flex-boy-row-space" style={{ padding: 0, marginBottom: "20px", marginRight: "10px" }} >
                        <FontAwesomeIcon onClick={() => console.log("heh")} color="grey" icon={["fas", 'user']} />
                        <Subtitle1 className="name" style={{ flex: 1, textAlign: 'left', marginLeft: '5px' }}>{appointment.consultant.name}</Subtitle1>
                    </div> */}
                <div className="flex-boy-row-space" style={{ padding: 0 }}>
                    <FontAwesomeIcon onClick={() => console.log("heh")} color="grey" icon={["fas", 'clock']} />
                    {appointment.allDay ? <Body2 color="black" style={{
                        textAlign: 'left', flex: 1
                    }}> All day</Body2>
                        :
                        <Body2 color="black" style={{
                            textAlign: 'left', flex: 1
                        }}> {dayjs(appointment.start_at).format("hh:mm a")} -{dayjs(appointment.end_at).format("hh:mm a")}</Body2>}
                </div>


            </div> : null}
        </div>
    </AppointmentCard>);
}
const mapStateToProps = (state) => ({

})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(TimeBlockCard)


