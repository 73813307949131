import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Subtitle1} from '../../../components'
// import { NewPatentCard } from './components/NewPatentCard';
import {Avatar, Button, Dropdown, Grid, Table, Tag} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    fetchEncounters,
    resetEncountersPagination,
    resetPatientModuleState,
    selectAssessment,
    selectPatient,
    setAssessmentNotificationOpen,
    setEncountersLoaded,
    updateEncounterListFilters
} from '../../../app/Reducers';
import {toast} from "react-toastify"
import dayjs from 'dayjs';
import {apiRequest} from '../../../app/Apis';

import {
    createFormInputsFromTableColumns,
    dialogCloser,
    emptyFun,
    getAssessmentStatus,
    getSkels,
    makeAutoCompleteOptions,
    noEncounterList,
    onTableChange,
    responsiveTbl, secondaryButton,
    stateFilter
} from "../../../common/helpers";
import VisitStatus from "./VisitStatus";
import EditAssessmentModal from "./EditAssessmentModal";
import {genAllograftReport, generateLabRequisition, genReport} from "../../../app/Apis/commonApi";
import VisitDetails from "./VisitDetails";
import PdfViewer from "../../../components/PdfViewer";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import TableFilters from "../../../components/InputComponents/TableFilters";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useWoundLocationSearch} from '../../../hooks/useWoundLocationSearch';
import {CloudUploadOutlined, EditOutlined} from '@ant-design/icons';
import {ExportTableToCsv} from './ExportTableToCsv';
import {useConsultantSearch} from '../../../hooks/useConsultantSearch';
import {useLocation, useNavigate} from 'react-router-dom';
import {useFacilitySearch} from '../../../hooks/useFacilitySearch';
import {useProcedureSearch} from '../../../hooks/useProcedureSearch';
const { useBreakpoint } = Grid;
const EncounterList = ({  encountersPagination, encounters, appConf, appointments, navs, selectedAssessmentEncounter, assessmentNotificationOpen, facility, activeUser }) => {
    const location = useLocation()
    const navigate = useNavigate();
    const windowState = useBreakpoint();
    const can_view_assessment = usePermissionCheck('view_assessment', false, [])
    const [activeTab, setactivTab] = useState('dme')
    const { woundLocationOptions, debouncedWLocationSearch, woundLocationLoading } = useWoundLocationSearch( [])
    const [providerOptions, setProviderOptions] = useState([])
    const debouncedConsultantSearch = useConsultantSearch(setProviderOptions,[])
    const [isExportVisible, showExportForm] = useState(false);
    let defaultList=!!activeUser?.facilityProfile?[{...activeUser?.facilityProfile, label: activeUser?.facilityProfile.name, value: activeUser?.facilityProfile.id }]:[]
    const [facilityOptions, debouncedFacilitiesSearch,facilitySearchLoading] = useFacilitySearch([], 'id', defaultList)
    const [procedureOptions, debouncedProcedureSearch, procedureSearchLoading] = useProcedureSearch([], 'name')
    const columns = [{
        title: 'Patient', dataIndex: ['appointment', 'patient', 'name'], key: 'patientName',
        onCell: (record, rowIndex) => {
            return {
                onClick: event => {
                    (async () => {
                        let patient = record.appointment.patient
                        if (patient.id !== navs.patientDetailsOwner) {
                            await dispatch(resetPatientModuleState())
                        }
                        await dispatch(selectPatient({ record: patient }))
                        navigate(`/patients/${record.appointment.patient.id}`)
                    })().catch(e => console.log(e))
                } // click cell
            };
        },

        /* sorter: (a, b) => a.name - b.name, fltr: {filterable: true, type: 'text'},*/
        render: (name) => (<Button size={"large"} type={"text"}
                                      style={{
                                          textTransform: 'none', margin: 0, width: '100%', display: "flex", justifyContent: "flex-start",
                                          backgroundColor: 'transparent', color: '#038A96', textAlign: 'left'
                                      }}>{name}</Button>
           )
    }, {
        title: 'Date created', dataIndex: 'created_at', key: 'createdAt', fltr: { filterable: true, type: 'date' },
        render: (created_at, record) => {
            return (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(created_at).format("MM/DD/YYYY")}</Subtitle1>);
        }
    },
        //baseKey is used as form field name on the Filters form while keys attr is used to name filter API parameters
        {
            title: 'Date of service', dataIndex: ['appointment', 'start_at'], key: 'dateOfService',
            fltr: { filterable: true, type: 'date_range', keys: ['dateOfServiceRange[start_at]', 'dateOfServiceRange[end_at]'], baseKey: 'date_of_service_filter' },
            render: (start_at, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(start_at).format("MM/DD/YYYY")}</Subtitle1>),
            csvExported:true
        }, {
            title: 'Wound Location',
            fltr: {
                filterable: true, type: 'autocomplete', props: {
                    showSearch: true,
                    filterOption: false,
                    onSearch: (value) => debouncedWLocationSearch(value),
                    defaultActiveFirstOption: false,
                    showArrow: true,
                    placeholder: 'Search...'
                },
                options: woundLocationOptions
            }, key: 'woundLocation',
            render: (record) => {
                return (
                    <Subtitle1
                        style={{ textAlign: 'left' }}>{`${record.wound_position && record.wound_side ? record.wound_side : ''} ${record.wound_position || ''} ${record.location || ''}`}</Subtitle1>);
            }
        },  {
            title: 'Procedure Type', dataIndex: 'procedure', key: 'procedure', fltr: {
                filterable: true, type: 'select', options: procedureOptions,
                props: {
                    loading: procedureSearchLoading,
                    onSearch: (value) => debouncedProcedureSearch(value), showSearch: true, filterOption: false
                }
            }, render: (recommended_procedure) => {
                if (recommended_procedure && Array.isArray(recommended_procedure) && recommended_procedure.length > 0) {
                    return recommended_procedure.join(', ')
                } else {
                    return recommended_procedure;
                }
            },
            csvExported: true
        }, {
            title: 'Office', dataIndex: ['appointment', 'patient', 'medical_facility'], key: 'facility',
            csvExported:true,
            fltr: { filterable: true, type: 'select', options: facilityOptions,
                props: {
                    disabled: !!activeUser?.facilityProfile,loading:facilitySearchLoading,
                    onSearch: (value) => debouncedFacilitiesSearch(value), showSearch: true, filterOption: false
                },
                fiprops:{
                initialValue:activeUser?.facilityProfile ? activeUser?.facilityProfile?.id : undefined
                } },
        }, {
            title: 'Provider', dataIndex: ['appointment', 'consultant', 'name'], key: 'provider',
            fltr: { filterable: true, type: 'select',options:providerOptions,
                props: {
                    showSearch: true,
                    filterOption: false,
                    onSearch: (value) => debouncedConsultantSearch(value),
                    defaultActiveFirstOption: false,
                    showArrow: true,
                    placeholder: 'Search...'
                } },
            csvExported:true
        }, {
            title: 'Visit Type', dataIndex: ['appointment', 'appointment_visit_type'], key: 'visitType',
            fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(['established visit', 'new visit']) },
            csvExported:true
        },
        {
            title: 'Assessment Status', dataIndex: 'review', key: 'reviewStatus', fltr: {
                filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(['rejected', 'approved', 'pending_review', 'needs_review'])
            }, render: (review) => getAssessmentStatus(review?.status?.toLowerCase())
        },
        ...(activeUser?.is_qa_user?[{ width:15,
            visible: true,
            title: <FontAwesomeIcon color="grey" icon={['fas', 'table']}/>,
            key: 'operation',
            render: (record) => <Tag className={`${(record.is_qa && activeUser?.is_qa_user)?'visible':'invisible'}`} icon={<EditOutlined/>} color="#55acee">QA</Tag>
        }]:[]),
        

    ]
        
        const biologicsEligibilityFilter = {
            key: 'woundBiologicEligibility', title: 'Biologics Eligibility', fltr: {
              filterable: true, type: 'select',
              options: [ { label: 'Eligible', value: 'eligible' }, { label: 'Not Eligible', value: 'ineligible' }]
            }
          }
    
    const dispatch = useDispatch()
    const [visitStatus, setStatus] = useState("Approve")
    const [sellectedVisit, setsellectedVisit] = useState(null)
    const [visitStatusModal, setStatusModal] = useState(false)
    const [editassessmentModal, seteditassessmentModal] = useState(false)
    const [isVisible, setIsVisible] = useState(false);
    const [isExecuting, setisExecuting] = useState(false)
    const [isPrinting, setPrinting] = useState(false)
    const [isLabPrinting, setLabPrinting] = useState(false)
    const [isAllograftPrinting, setAllograftPrinting] = useState(false)
    const { message } = useSubscription(`view_assessment`, { qos: 2, nl: true });
    const [pdfShowing, setPdfShowing] = useState(false);
    const [pdfLoading, setLoading] = useState(false);
    const [showAuditDialog, setShowAuditDialog] = useState(false);
    const [pdf, setPdf] = useState(null);
    let exportedColumns=columns.filter(value => value.csvExported)
    let defaultFilters = (!!activeUser?.facilityProfile&&!!facility) ? { filterData: { facility: facility?.name } } : {}
    useEffect(() => {
        if (!!message) {
            console.log('MQTT MESSAGE:  ', message)
            loadEncounters(true).catch(e => console.log(e))
        }
        return emptyFun
    }, [message])
    const loadEncounters = async (isMQ) => {
        /*if (!!encounters[0] && !isMQ) {
          return
        }*/
        await dispatch(updateEncounterListFilters([appConf.encounterListFilters[0], appConf.encounterListFilters[1], !isMQ]))
        await dispatch(fetchEncounters(appConf.encounterListFilters[0] ? appConf.encounterListFilters[1] : undefined))
        // await dispatch(fetchProcedures())
        // await dispatch(fetchLabRegions())
        // await dispatch(loadWoundLocations())
        await dispatch(setEncountersLoaded(true))
        await dispatch(updateEncounterListFilters([appConf.encounterListFilters[0], appConf.encounterListFilters[1], false]))
    }
    const syncPageStatus = () => {
    }

    // const [showExitPrompt, setShowExitPrompt] = useExitPrompt(true, loadEncounters, syncPageStatus);

    async function handleFilterAction(action, values) {
        let common = !activeUser.facilityProfile ? {} : { facility: facility?.name }
        await dispatch(resetEncountersPagination())
        if (action === 'filter') {
            let pl = { filterData: { ...values ,...common}, pagination: encountersPagination }
            await dispatch(updateEncounterListFilters([false, pl, true]))
            await dispatch(fetchEncounters(pl))
            await dispatch(updateEncounterListFilters([true, pl, false]))
        }
        if (action === 'reset') {
            await dispatch(updateEncounterListFilters([appConf.encounterListFilters[0], undefined, true]))
            await dispatch(fetchEncounters(defaultFilters))
            await dispatch(updateEncounterListFilters([false, undefined, false]))
        }
        setIsVisible(false)
    }

    const [VisitModal, setVisit] = useState(false)
    useEffect(() => {
        loadEncounters(false).catch(e => console.log(e))
        return emptyFun
    }, [])


/*    useEffect(() => {
        if (activeUser?.facilityProfile && facility) {
            filterbyFacility()
        }
        return () => { }
    }, [facility])*/



/*    const filterbyFacility = async () => {
        await handleFilterAction('filter', { facility: [facility.name] })
    }*/

    useEffect(() => {
        (async () => {
            if (assessmentNotificationOpen) {
                if (can_view_assessment) {
                    await dispatch(setAssessmentNotificationOpen(false))
                    await setsellectedVisit(selectedAssessmentEncounter)
                    await setVisit(true)
                }
            }
        })()
        return emptyFun
    }, [assessmentNotificationOpen])
    const approveAsessment = async () => {
        await setisExecuting(true)
        const toastId = toast("Updating Assesment", { type: "info", autoClose: false });
        const response = await apiRequest.post(`/assessments/${sellectedVisit.id}/status`, { status: "approved" }).catch(async function (err) {
            await setisExecuting(false)
            toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
            await dispatch(fetchEncounters(appConf.encounterListFilters[0] ? appConf.encounterListFilters[1] : undefined))
        })
        if (response && response.data) {
            toast.update(toastId, { render: "Assessment Approved", type: toast.TYPE.SUCCESS, autoClose: 5000 });
            await dispatch(fetchEncounters(appConf.encounterListFilters[0] ? appConf.encounterListFilters[1] : undefined))
        }
        await setisExecuting(false)
        setVisit(false)
        setStatusModal(false)
    }
    const declineAssessment = async (payload) => {
        await setisExecuting(true)
        const toastId = toast("Updating Assessment", { type: "info", autoClose: false });
        let data = { notes: payload.notes, status: "rejected" }
        const response = await apiRequest.post(`/assessments/${sellectedVisit.id}/status`, data).catch(async function (err) {
            await setisExecuting(false)
            console.log(err)
            toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
            await dispatch(fetchEncounters(appConf.encounterListFilters[0] ? appConf.encounterListFilters[1] : undefined))
        })
        if (response && response.data) {
            toast.update(toastId, { render: "Assessment Rejected", type: toast.TYPE.SUCCESS, autoClose: 5000 });
            await dispatch(fetchEncounters(appConf.encounterListFilters[0] ? appConf.encounterListFilters[1] : undefined))
        }
        await setisExecuting(false)
        setVisit(false)
        setStatusModal(false)
    }
    const handlevisitOk = () => setVisit(false)
    const handlevisitCancel = () => setVisit(false)
    const generateReport = () => genReport(sellectedVisit, setPrinting, setVisit, setStatusModal, setPdf, setLoading, setPdfShowing)
    const generateLabRequest = () => generateLabRequisition(sellectedVisit, setLabPrinting, setVisit, setStatusModal, setPdf, setLoading, setPdfShowing)
    const generateAllograftReport = () => genAllograftReport(sellectedVisit, setAllograftPrinting, setVisit, setStatusModal, setPdf, setLoading, setPdfShowing)
    const generateLabReport = (report) => { 
        setPdf(report)
        setPdfShowing(true)
    }
    return (<div className="main-page">
        {(!!sellectedVisit && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)}
                                                                  resourceType={'Assessment'}
                                                                  loading={false} resourceDescription={`${sellectedVisit.appointment.patient.name}'s ${sellectedVisit.procedure} assessment`}
                                                                  defaultValues={{ auditableId: sellectedVisit?.id, auditable: 'assessment', format: 'json' }}/>}
        <PdfViewer pdf={pdf} setLoading={setLoading} loading={pdfLoading} onCancel={() => setPdfShowing(false)} visible={pdfShowing}/>
        {!!sellectedVisit ? <EditAssessmentModal editassessmentModal={editassessmentModal} seteditassessmentModal={seteditassessmentModal}
                                                 isExecuting={isExecuting} setVisit={setVisit} setisExecuting={setisExecuting}
                                                 sellectedVisit={sellectedVisit}/> : null}
        {(VisitModal && !!sellectedVisit) && <VisitDetails setShowAuditDialog={setShowAuditDialog}
        // assessment={sellectedVisit}                  generateLabReport
                                                        setVisit={setVisit} approveAsessment={approveAsessment}
                                                        VisitModal={VisitModal} seteditassessmentModal={seteditassessmentModal}
                                                        isPrinting={isPrinting} isLabPrinting={isLabPrinting} isExecuting={isExecuting}
                                                        setStatusModal={setStatusModal} handlevisitOk={handlevisitOk}
                                                        handlevisitCancel={handlevisitCancel}
                                                        generateLabReport={generateLabReport}
                                                        generateLabRequest={generateLabRequest}
                                                        generateReport={generateReport} isAllograftPrinting={isAllograftPrinting}
                                                        generateAllograftReport={generateAllograftReport}/>}
        <VisitStatus visitStatusModal={visitStatusModal} setStatusModal={setStatusModal} isExecuting={isExecuting} declineAssessment={declineAssessment}/>
        <div className="w-full">
            <Table
              title={() => (<TableFilters datasource={[...columns, biologicsEligibilityFilter, stateFilter]}
                                          setIsVisible={setIsVisible}
                                          isVisible={isVisible} filters={appConf.encounterListFilters[1]}
                                          handleFilterAction={handleFilterAction}
                                          showClear={appConf.encounterListFilters[0]}
                                          actionButton={<Dropdown arrow={true}
                                                                  destroyPopupOnHide={true}
                                                                  dropdownRender={(menu) => <ExportTableToCsv
                                                                    exportedColumns={exportedColumns}
                                                                    showExportForm={showExportForm}
                                                                    defaultFilters={{ facilty: activeUser?.facilityProfile ? activeUser?.facilityProfile?.id : undefined }}
                                                                  />}
                                                                  trigger={['click']}
                                                                  overlayStyle={{ width: 300 }}
                                                                  open={isExportVisible}
                                          >
                                              {secondaryButton(() => showExportForm((prev) => !prev), 'Export to CSV', false, null, { marginRight: 10 }, { icon: <CloudUploadOutlined /> })}
                                          </Dropdown>}
                                            loading={appConf.encounterListFilters[2]}/>)}
                size={'small'} loading={appConf.encounterListFilters[2]} {...responsiveTbl(windowState)} locale={{ emptyText: noEncounterList }}
                onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchEncounters, appConf.encounterListFilters, updateEncounterListFilters)}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            (async () => {
                                await dispatch(selectAssessment(record))
                                if (can_view_assessment) {
                                    await setsellectedVisit(record)
                                    await setVisit(true)
                                }
                            })().catch(e => console.log(e))
                        } // click row
                    };
                }}
                {...getSkels((appConf.encounterListFilters[2]), columns, encounters)} pagination={encountersPagination}/>
        </div>
    </div>)
}
const mapStateToProps = (state) => ({
    encounters: state.appointments.encounters, appConf: state.appConf, navs: state.navs,
    encountersPagination: state.appointments.encountersPagination,
    selectedAssessmentEncounter: state.appointments.selectedAssessmentEncounter,
    assessmentNotificationOpen: state.appointments.assessmentNotificationOpen,
    appointments: state.appointments.appointments,
    facility: state.facilities.selectedFacility,
    activeUser: state.auth.activeUser
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(EncounterList)
