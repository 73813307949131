import { useEffect, useState } from "react";
import { Segmented, Divider, Empty, Spin, DatePicker, Form } from "antd";
import { Headline6 } from "../../../components";
import { emptyFun } from "../../../common/helpers";
import { BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Bar, Tooltip } from "recharts";
import { apiRequest } from "../../../app/Apis";
import dayjs from "dayjs";

export default function AssessmentChart({ region }) {
  const [option, setOption] = useState('week');
  const [assessments, setAssessments] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const dateFormat = 'YY-MM-DD';
  const [dateValues, setDateValues] = useState(null);
  const [endDate, setEndDate] = useState(dayjs().format(dateFormat));
  const [startDate, setStartDate] = useState(
    dayjs()
      .subtract(5, 'days')
      .format(dateFormat)
  );

  const { RangePicker } = DatePicker;

  const defaultOptions = [
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: 'Quarter', value: 'quarter' },
    { label: 'Year', value: 'year' },
  ];

  async function GetDateRange(option) {
    if (option === 'month') {
      let start = dayjs().startOf('month').format(dateFormat);
      let end = dayjs().endOf('month').format(dateFormat);
      setStartDate(start);
      setEndDate(end);
    } else if (option === 'quarter') {
      let start = dayjs().startOf('quarter').format(dateFormat);
      let end = dayjs().endOf('quarter').format(dateFormat);
      setStartDate(start);
      setEndDate(end);
    } else if (option === 'year') {
      let start = dayjs().startOf('year').format(dateFormat);
      let end = dayjs().endOf('year').format(dateFormat);
      setStartDate(start);
      setEndDate(end);
    } else {
      let start = dayjs().startOf('week').format(dateFormat);
      let end = dayjs().endOf('week').format(dateFormat);
      setStartDate(start);
      setEndDate(end);
    }
  }

  function onChangeLocation(option) {
    setOption(option);
  }
  const onChange = async (value) => {
    if (value !== null) {
      console.log(value);
      setDateValues(value);

      let start = dayjs(value[0])?.format(dateFormat);
      let end = dayjs(value[1])?.format(dateFormat);
      setStartDate(start);
      setEndDate(end);
    } else {
      setDateValues(null);
    }
  };

  const disabledFutureDates = (current) => current && current > dayjs(dayjs())

  async function constructData(data) {
    let chartData = [];
    for (let key of Object.keys(data)) {
      let name = key;
      let dataset = { name };
      let arr = data[key];
      for (let i = 0; i < arr.length; i++) {
        dataset = {
          ...dataset,
          [arr[i].procedure]: arr[i].count,
        }
      }
      chartData.push(dataset);
    }
    console.log("Assessment Chart Data:", chartData)
    return chartData;
  }

  const sumProperties = (arr) => {
    return arr.reduce((acc, obj) => {
      return acc + Object.values(obj).slice(0, -1).reduce((a, b) => a + b);
    }, 0);
  }

  async function fetchAssessments(option, region) {
    setLoading(true);
    let queryString = '';
    if (!!region && region !== '' && region !== undefined) {
      queryString = `&region=${region}`;
    }
    const response = await apiRequest.get(`/assessment_stats?format=${option}${queryString}&dateRange[start_at]=${startDate}&dateRange[end_at]=${endDate}`);
    const res = response.data?.data;
    let tots = sumProperties(res);
    setTotal(tots);
    setAssessments(res);
    setLoading(false);
  }

  useEffect(() => {
    GetDateRange(option);
    fetchAssessments(option, region)
    return emptyFun
  }, [option, region])

  useEffect(() => {
    fetchAssessments(option, region)
    return emptyFun
  }, [endDate, startDate])

  return (
    <div className="stats-card-box-large" style={{ width: '100%' }}>
      <div style={{ width: '100%', padding: '5px' }}>
        <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>All Assessments</Headline6>
        <Divider style={{ margin: '4px', color: '#E2E4EA', width: '100%' }} />
      </div>
      <div className="flex-boy-row-space" style={{ width: '100%' }}>
        <div className="flex-boy-column">
          <Headline6>Total Assessments</Headline6>
          <Headline6 style={{ fontWeight: 'bold', fontSize: '20px' }}>{total}</Headline6>
        </div>
        <Segmented options={defaultOptions} onChange={onChangeLocation} />
      </div>
      {assessments ?
        <div style={{ width: "100%" }}>
          <Spin spinning={loading}>
            <ResponsiveContainer width={"100%"} height={550} style={{ paddingTop: '20px' }}>
              <BarChart
                data={assessments}
                width={300}
                height={300}
              >
                <CartesianGrid strokeDasharray="4 4" />
                <XAxis dataKey={'label'} />
                <YAxis allowDecimals={false}
                  axisLine={false}
                  domain={[0, 100]}
                  tick={{
                    fontWeight: '700',
                    color: '#0E153A',
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontSize: '14px',
                  }}
                />
                <Tooltip isAnimationActive />
                <Legend align="left" iconType='circle' style={{ marginLeft: '20px' }} />
                <Bar dataKey='Biologics Ordering' fill='#AF78FF' />
                <Bar dataKey='Biologics  Application' fill='#FF5F5F' />
                <Bar dataKey='Biologics Pre-assessment' fill='#50D2A0' />
                <Bar dataKey='Lab' fill='#FF9F5F' />
                <Bar dataKey='Podiatry' fill='#83E8FF' label='Podiatry' />
                <Bar dataKey='Wound Debriment' fill='#7785FF' label='Wound Debriment' />
                <Bar dataKey='Wound Evaluation' fill='#2E5AAC' label='Wound Evaluation' />
              </BarChart>
            </ResponsiveContainer>
            <RangePicker
              style={{
                borderRadius: '7px',
                cursor: 'pointer',
                marginTop: '15px',
                float: 'right'
              }}
              onChange={(dateStrings) => onChange(dateStrings)}
              disabledDate={disabledFutureDates}
              onCalendarChange={(dateStrings) => setDateValues(dateStrings)}
              allowClear={true}
              format={dateFormat}
            />
          </Spin>
        </div>
        : <Empty style={{ marginTop: '100px' }} description="No data" />}
    </div>
  )
}
