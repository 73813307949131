import React, { useState } from 'react';


import { Radio } from 'antd';

import { connect, useDispatch } from 'react-redux'
import { setzonalSelectionMenuVisible } from '../../../app/Reducers/mapSlice';



const ZonalSelectionMenu = ({ zonalSelectionMenuVisible }) => {
    const dispatch = useDispatch()
    const [selectedOption, setSelectedOption] = useState(zonalSelectionMenuVisible ? zonalSelectionMenuVisible : 'Medicare MACS area');

    const options = [
        { name: 'Medicare MACS area', color: '#ff73c3' },
        { name: 'Sales and Marketing regions', color: '#ff5f5f' },
        { name: 'Active, pending and inactive WP states', color: '#af78ff' },
    ];

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        dispatch(setzonalSelectionMenuVisible(e.target.value))
    };

    return (
        <div className="p-4 bg-customGray shadow-lg rounded-lg">
            <p className="mb-4 font-semibold text-lg text-customBlue text-left">Show on map</p>
            <Radio.Group className='text-left font-semibold text-base' onChange={handleOptionChange} value={zonalSelectionMenuVisible}>
                {options.map((option, index) => (
                    <Radio className='my-2' key={index} value={option.name} >
                        {option.name}
                    </Radio>
                ))}
            </Radio.Group>
        </div>
    );

};

const mapStateToProps = (state) => ({
    zonalSelectionMenuVisible: state.map.zonalSelectionMenuVisible,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ZonalSelectionMenu)


