export const boundaries =[
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpBUkxwOnY0",
        "worldview": "all",
        "unit_code": "01",
        "wikidata_id": "Q173",
        "name": "Alabama",
        "name_en": "",
        "name_translations": "{\"en\": [\"AL\", \"Ala\", \"Ala.\", \"Alabama\", \"Bama\", \"Heart of Dixie\", \"State of Alabama\", \"The Yellowhammer State\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"East South Central\"], \"US Census Region\" : [\"South\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-AL\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "159864",
        "centroid": "[-86.7411,32.6483]",
        "bbox": "[-88.4732,30.1941,-84.8882,35.008]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpBaExwOnY0",
        "worldview": "all",
        "unit_code": "02",
        "wikidata_id": "Q797",
        "name": "Alaska",
        "name_en": "",
        "name_translations": "{\"en\": [\"AK\", \"Alaska\", \"Alaska, United States\", \"State of Alaska\", \"The Last Frontier\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"Pacific\"], \"US Census Region\" : [\"West\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-AK\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "3526429",
        "centroid": "[-151.0647,64.3151]",
        "bbox": "[-187.5552,51.2154,-129.994,71.3885]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpBeExwOnY0",
        "worldview": "all",
        "unit_code": "04",
        "wikidata_id": "Q816",
        "name": "Arizona",
        "name_en": "",
        "name_translations": "{\"en\": [\"AZ\", \"Ariz.\", \"Arizona\", \"Arizona, United States\", \"Grand Canyon State\", \"State of Arizona\", \"The Grand Canyon State\", \"US-AZ\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"Mountain\"], \"US Census Region\" : [\"West\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-AZ\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "358925",
        "centroid": "[-111.6252,34.2195]",
        "bbox": "[-114.8166,31.3322,-109.0452,37.0037]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpCQkxwOnY0",
        "worldview": "all",
        "unit_code": "05",
        "wikidata_id": "Q1612",
        "name": "Arkansas",
        "name_en": "",
        "name_translations": "{\"en\": [\"AR\", \"AR-kən-saw\", \"ARkənsaw\", \"Ark.\", \"Arkansas\", \"Arkansas, United States\", \"Arkansaw\", \"Arkensaw\", \"State of Arkansas\", \"The Natural State\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"West South Central\"], \"US Census Region\" : [\"South\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-AR\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "168663",
        "centroid": "[-92.4991,34.7706]",
        "bbox": "[-94.6179,33.0041,-89.6444,36.4997]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpCUkxwOnY0",
        "worldview": "all",
        "unit_code": "06",
        "wikidata_id": "Q99",
        "name": "California",
        "name_en": "",
        "name_translations": "{\"en\": [\"CA\", \"Calif.\", \"California\", \"California, United States\", \"State of California\", \"The Golden State\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"Pacific\"], \"US Census Region\" : [\"West\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-CA\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "515558",
        "centroid": "[-120.105,37.4213]",
        "bbox": "[-124.415,32.5343,-114.1312,42.0095]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpCaExwOnY0",
        "worldview": "all",
        "unit_code": "08",
        "wikidata_id": "Q1261",
        "name": "Colorado",
        "name_en": "",
        "name_translations": "{\"en\": [\"CO\", \"Colo.\", \"Colorado\", \"Colorado, United States\", \"State of Colorado\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"Mountain\"], \"US Census Region\" : [\"West\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-CO\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "347489",
        "centroid": "[-105.5494,39.0275]",
        "bbox": "[-109.0602,36.9924,-102.0409,41.0034]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpCeExwOnY0",
        "worldview": "all",
        "unit_code": "09",
        "wikidata_id": "Q779",
        "name": "Connecticut",
        "name_en": "",
        "name_translations": "{\"en\": [\"CT\", \"Connecticut\", \"Connecticut, United States\", \"Conneticut\", \"State of Connecticut\", \"The Constitution State\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"New England\"], \"US Census Region\" : [\"Northeast\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-CT\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "17232",
        "centroid": "[-72.8052,41.5196]",
        "bbox": "[-73.7278,40.9805,-71.7872,42.0505]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpDQkxwOnY0",
        "worldview": "all",
        "unit_code": "10",
        "wikidata_id": "Q1393",
        "name": "Delaware",
        "name_en": "",
        "name_translations": "{\"en\": [\"Blue Hen State\", \"DE\", \"Delaware\", \"Delaware, United States\", \"State of Delaware\", \"The Diamond State\", \"The First State\", \"The Small Wonder\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"South Atlantic\"], \"US Census Region\" : [\"South\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-DE\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "6560",
        "centroid": "[-75.5806,39.1489]",
        "bbox": "[-75.789,38.4504,-75.0487,39.8395]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpDUkxwOnY0",
        "worldview": "all",
        "unit_code": "11",
        "wikidata_id": "Q3551781",
        "name": "District of Columbia",
        "name_en": "",
        "name_translations": "{\"en\": [\"D.C.\", \"DC\", \"District of Columbia\"]}",
        "description": "federal district",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"South Atlantic\"], \"US Census Region\" : [\"South\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-DC\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "227",
        "centroid": "[-76.988,38.8938]",
        "bbox": "[-77.1198,38.7916,-76.9094,38.9958]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpDaExwOnY0",
        "worldview": "all",
        "unit_code": "12",
        "wikidata_id": "Q812",
        "name": "Florida",
        "name_en": "",
        "name_translations": "{\"en\": [\"FL\", \"Fla.\", \"Florida\", \"Florida, United States\", \"Peninsular State\", \"State of Florida\", \"The Sunshine State\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"South Atlantic\"], \"US Census Region\" : [\"South\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-FL\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "168288",
        "centroid": "[-81.5978,28.0182]",
        "bbox": "[-87.6348,24.5211,-80.0312,31.001]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpDeExwOnY0",
        "worldview": "all",
        "unit_code": "13",
        "wikidata_id": "Q1428",
        "name": "Georgia",
        "name_en": "",
        "name_translations": "{\"en\": [\"GA\", \"Georgia\", \"Georgia, United States\", \"State of Georgia\", \"The Empire State of the South\", \"The Goober State\", \"The Peach State\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"South Atlantic\"], \"US Census Region\" : [\"South\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-GA\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "181205",
        "centroid": "[-83.2723,32.7089]",
        "bbox": "[-85.6052,30.3558,-80.8403,35.0013]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpEQkxwOnY0",
        "worldview": "all",
        "unit_code": "15",
        "wikidata_id": "Q782",
        "name": "Hawaii",
        "name_en": "",
        "name_translations": "{\"en\": [\"HI\", \"Hawai'i\", \"Hawaii\", \"Hawaii, United States\", \"Hawaiʻi\", \"Kaimana Hila\", \"State of Hawaii\", \"The Aloha State\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"Pacific\"], \"US Census Region\" : [\"West\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-HI\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "17929",
        "centroid": "[-155.4496,19.591]",
        "bbox": "[-178.3332,18.9107,-154.8066,28.4021]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpEUkxwOnY0",
        "worldview": "all",
        "unit_code": "16",
        "wikidata_id": "Q1221",
        "name": "Idaho",
        "name_en": "",
        "name_translations": "{\"en\": [\"Gem State\", \"ID\", \"Idaho\", \"Idaho, United States\", \"Potato State\", \"State of Idaho\", \"US-ID\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"Mountain\"], \"US Census Region\" : [\"West\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-ID\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "297109",
        "centroid": "[-115.502,45.6039]",
        "bbox": "[-117.243,41.9882,-111.0435,49.0009]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpEaExwOnY0",
        "worldview": "all",
        "unit_code": "17",
        "wikidata_id": "Q1204",
        "name": "Illinois",
        "name_en": "",
        "name_translations": "{\"en\": [\"21st State\", \"IIIinois\", \"IL\", \"IL (state)\", \"Ilinois\", \"Ill.\", \"Illinios\", \"Illinoid\", \"Illinois\", \"Illinois (U.S. state)\", \"Illinois (state)\", \"Illinois, USA\", \"Illinois, United States\", \"Illinoy\", \"Illionis\", \"Ills.\", \"Land of Lincoln\", \"Lincoln State\", \"Prairie State\", \"State of Illinois\", \"The Land of Lincoln\", \"The Prairie State\", \"Twenty-First State\", \"US-IL\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"East North Central\"], \"US Census Region\" : [\"Midwest\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-IL\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "191824",
        "centroid": "[-89.4489,39.7947]",
        "bbox": "[-91.5131,36.9703,-87.4932,42.5085]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpEeExwOnY0",
        "worldview": "all",
        "unit_code": "18",
        "wikidata_id": "Q1415",
        "name": "Indiana",
        "name_en": "",
        "name_translations": "{\"en\": [\"Hoosier State\", \"IN\", \"Ind.\", \"Indiana\", \"Indiana, United States\", \"State of Indiana\", \"US-IN\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"East North Central\"], \"US Census Region\" : [\"Midwest\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-IN\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "122602",
        "centroid": "[-86.1737,39.795]",
        "bbox": "[-88.0979,37.7717,-84.7846,41.7607]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpFQkxwOnY0",
        "worldview": "all",
        "unit_code": "19",
        "wikidata_id": "Q1546",
        "name": "Iowa",
        "name_en": "",
        "name_translations": "{\"en\": [\"Hawk-eye State\", \"IA\", \"Iowa\", \"Iowa, United States\", \"State of Iowa\", \"The Land Between Two Rivers\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"West North Central\"], \"US Census Region\" : [\"Midwest\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-IA\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "196920",
        "centroid": "[-93.1479,41.9582]",
        "bbox": "[-96.6395,40.3754,-90.1401,43.5012]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpFUkxwOnY0",
        "worldview": "all",
        "unit_code": "20",
        "wikidata_id": "Q1558",
        "name": "Kansas",
        "name_en": "",
        "name_translations": "{\"en\": [\"KS\", \"Kan.\", \"Kans.\", \"Kansas\", \"Kansas, United States of America\", \"State of Kansas\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"West North Central\"], \"US Census Region\" : [\"Midwest\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-KS\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "272642",
        "centroid": "[-98.3287,38.5139]",
        "bbox": "[-102.0518,36.993,-94.5884,40.0032]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpFaExwOnY0",
        "worldview": "all",
        "unit_code": "21",
        "wikidata_id": "Q1603",
        "name": "Kentucky",
        "name_en": "",
        "name_translations": "{\"en\": [\"Bluegrass State\", \"Commonwealth of Kentucky\", \"KY\", \"Kentucky\", \"Kentucky, United States\", \"Ky.\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"East South Central\"], \"US Census Region\" : [\"South\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-KY\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "131668",
        "centroid": "[-84.7305,37.8343]",
        "bbox": "[-89.5712,36.4971,-81.9648,39.1477]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpFeExwOnY0",
        "worldview": "all",
        "unit_code": "22",
        "wikidata_id": "Q1588",
        "name": "Louisiana",
        "name_en": "",
        "name_translations": "{\"en\": [\"18th State\", \"LA\", \"Louisiana\", \"Louisiana, United States\", \"State of Louisiana\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"West South Central\"], \"US Census Region\" : [\"South\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-LA\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "138815",
        "centroid": "[-92.5489,31.0725]",
        "bbox": "[-94.0434,28.9175,-88.8201,33.0195]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpGQkxwOnY0",
        "worldview": "all",
        "unit_code": "23",
        "wikidata_id": "Q724",
        "name": "Maine",
        "name_en": "",
        "name_translations": "{\"en\": [\"ME\", \"Maine\", \"Maine, United States\", \"State of Maine\", \"The Pine Tree State\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"New England\"], \"US Census Region\" : [\"Northeast\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-ME\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "119961",
        "centroid": "[-69.1354,45.305]",
        "bbox": "[-71.0839,42.9776,-66.9496,47.4599]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpGUkxwOnY0",
        "worldview": "all",
        "unit_code": "24",
        "wikidata_id": "Q1391",
        "name": "Maryland",
        "name_en": "",
        "name_translations": "{\"en\": [\"MD\", \"Maryland\", \"Maryland, United States\", \"Md.\", \"Old Line State\", \"State of Maryland\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"South Atlantic\"], \"US Census Region\" : [\"South\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-MD\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "32996",
        "centroid": "[-76.7316,38.8855]",
        "bbox": "[-79.4877,37.8938,-75.0493,39.723]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpGaExwOnY0",
        "worldview": "all",
        "unit_code": "25",
        "wikidata_id": "Q771",
        "name": "Massachusetts",
        "name_en": "",
        "name_translations": "{\"en\": [\"Bay State\", \"Commonwealth of Massachusetts\", \"MA\", \"Mass.\", \"Massachusetts\", \"US-MA\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"New England\"], \"US Census Region\" : [\"Northeast\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-MA\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "28324",
        "centroid": "[-72.0951,42.1881]",
        "bbox": "[-73.5082,41.239,-69.9288,42.8868]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpGeExwOnY0",
        "worldview": "all",
        "unit_code": "26",
        "wikidata_id": "Q1166",
        "name": "Michigan",
        "name_en": "",
        "name_translations": "{\"en\": [\"MI\", \"Mich.\", \"Michigan\", \"Michigan, United States\", \"State of Michigan\", \"Wolverine State\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"East North Central\"], \"US Census Region\" : [\"Midwest\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-MI\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "212982",
        "centroid": "[-85.1863,43.7777]",
        "bbox": "[-90.4184,41.6961,-82.4183,48.2627]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpHQkxwOnY0",
        "worldview": "all",
        "unit_code": "27",
        "wikidata_id": "Q1527",
        "name": "Minnesota",
        "name_en": "",
        "name_translations": "{\"en\": [\"Land of 10,000 Lakes\", \"MN\", \"Minn.\", \"Minnesota\", \"Minnesota, United States\", \"State of Minnesota\", \"US-MN\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"West North Central\"], \"US Census Region\" : [\"Midwest\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-MN\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "315044",
        "centroid": "[-94.4467,46.319]",
        "bbox": "[-97.2391,43.4994,-89.4918,49.3831]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpHUkxwOnY0",
        "worldview": "all",
        "unit_code": "28",
        "wikidata_id": "Q1494",
        "name": "Mississippi",
        "name_en": "",
        "name_translations": "{\"en\": [\"MS\", \"Miss.\", \"Mississippi\", \"Mississippi, United States\", \"State of Mississippi\", \"US-MS\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"East South Central\"], \"US Census Region\" : [\"South\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-MS\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "147518",
        "centroid": "[-89.6933,32.6194]",
        "bbox": "[-91.655,30.1768,-88.0979,34.9961]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpHaExwOnY0",
        "worldview": "all",
        "unit_code": "29",
        "wikidata_id": "Q1581",
        "name": "Missouri",
        "name_en": "",
        "name_translations": "{\"en\": [\"MO\", \"Missouri\", \"Missouri, United States\", \"Mo.\", \"State of Missouri\", \"The Show Me State\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"West North Central\"], \"US Census Region\" : [\"Midwest\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-MO\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "230868",
        "centroid": "[-92.4894,38.3443]",
        "bbox": "[-95.7747,35.9957,-89.099,40.6136]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpHeExwOnY0",
        "worldview": "all",
        "unit_code": "30",
        "wikidata_id": "Q1212",
        "name": "Montana",
        "name_en": "",
        "name_translations": "{\"en\": [\"Big Sky Country\", \"MT\", \"Mont.\", \"Montana\", \"Montana, United States\", \"State of Montana\", \"Treasure State\", \"US-MT\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"Mountain\"], \"US Census Region\" : [\"West\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-MT\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "562194",
        "centroid": "[-109.3459,46.7293]",
        "bbox": "[-116.05,44.3579,-104.0397,49.0011]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpIQkxwOnY0",
        "worldview": "all",
        "unit_code": "31",
        "wikidata_id": "Q1553",
        "name": "Nebraska",
        "name_en": "",
        "name_translations": "{\"en\": [\"NE\", \"Neb.\", \"Nebr.\", \"Nebraska\", \"Nebraska, United States\", \"State of Nebraska\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"West North Central\"], \"US Census Region\" : [\"Midwest\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-NE\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "267949",
        "centroid": "[-100.0598,41.518]",
        "bbox": "[-104.0535,39.9999,-95.3083,43.0017]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpIUkxwOnY0",
        "worldview": "all",
        "unit_code": "32",
        "wikidata_id": "Q1227",
        "name": "Nevada",
        "name_en": "",
        "name_translations": "{\"en\": [\"NV\", \"Nev.\", \"Nevada\", \"Nevada, United States\", \"State of Nevada\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"Mountain\"], \"US Census Region\" : [\"West\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-NV\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "375406",
        "centroid": "[-116.7303,38.5916]",
        "bbox": "[-120.0065,35.0019,-114.0395,42.0018]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpIaExwOnY0",
        "worldview": "all",
        "unit_code": "33",
        "wikidata_id": "Q759",
        "name": "New Hampshire",
        "name_en": "",
        "name_translations": "{\"en\": [\"N.H.\", \"NH\", \"New Hampshire\", \"New Hampshire, United States\", \"State of New Hampshire\", \"The Granite State\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"New England\"], \"US Census Region\" : [\"Northeast\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-NH\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "33016",
        "centroid": "[-71.5463,44.016]",
        "bbox": "[-72.5571,42.697,-70.6109,45.3058]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpIeExwOnY0",
        "worldview": "all",
        "unit_code": "34",
        "wikidata_id": "Q1408",
        "name": "New Jersey",
        "name_en": "",
        "name_translations": "{\"en\": [\"Garden State\", \"Jersey\", \"N.J.\", \"NJ\", \"New Jersey\", \"New Jersey, United States\", \"State of New Jersey\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"Middle Atlantic\"], \"US Census Region\" : [\"Northeast\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-NJ\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "25552",
        "centroid": "[-74.3713,40.1534]",
        "bbox": "[-75.5713,38.9286,-73.9027,41.3574]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpJQkxwOnY0",
        "worldview": "all",
        "unit_code": "35",
        "wikidata_id": "Q1522",
        "name": "New Mexico",
        "name_en": "",
        "name_translations": "{\"en\": [\"N. Mex.\", \"N.M.\", \"NM\", \"New Mexico\", \"New Mexico, United States\", \"Nuevo México\", \"State of New Mexico\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"Mountain\"], \"US Census Region\" : [\"West\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-NM\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "384027",
        "centroid": "[-106.0453,34.215]",
        "bbox": "[-109.0504,31.3322,-103.002,37.0002]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpJUkxwOnY0",
        "worldview": "all",
        "unit_code": "36",
        "wikidata_id": "Q1384",
        "name": "New York",
        "name_en": "",
        "name_translations": "{\"en\": [\"NY\", \"NY state\", \"NYS\", \"New York\", \"New York (state)\", \"New York State\", \"New York state\", \"New York, United States\", \"State of New York\", \"The Empire State\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"Middle Atlantic\"], \"US Census Region\" : [\"Northeast\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-NY\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "171378",
        "centroid": "[-76.0889,42.9321]",
        "bbox": "[-79.7621,40.4962,-71.8563,45.0129]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpJaExwOnY0",
        "worldview": "all",
        "unit_code": "37",
        "wikidata_id": "Q1454",
        "name": "North Carolina",
        "name_en": "",
        "name_translations": "{\"en\": [\"N.C.\", \"NC\", \"NC, United States\", \"North Carolina\", \"North Carolina (US)\", \"North Carolina, United States\", \"State of North Carolina\", \"Tar Heel State\", \"The Tar Heel State\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"South Atlantic\"], \"US Census Region\" : [\"South\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-NC\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "157547",
        "centroid": "[-80.4379,35.2269]",
        "bbox": "[-84.3218,33.8344,-75.461,36.5881]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpJeExwOnY0",
        "worldview": "all",
        "unit_code": "38",
        "wikidata_id": "Q1207",
        "name": "North Dakota",
        "name_en": "",
        "name_translations": "{\"en\": [\"Flickertail State\", \"N. Dak.\", \"N. Dakota\", \"N.D.\", \"ND\", \"NoDak\", \"North Dakota\", \"North Dakota, United States\", \"Peace Garden State\", \"Roughrider State\", \"State of North Dakota\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"West North Central\"], \"US Census Region\" : [\"Midwest\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-ND\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "270522",
        "centroid": "[-100.4544,47.4901]",
        "bbox": "[-104.05,45.9351,-96.5544,49.0007]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpKQkxwOnY0",
        "worldview": "all",
        "unit_code": "39",
        "wikidata_id": "Q1397",
        "name": "Ohio",
        "name_en": "",
        "name_translations": "{\"en\": [\"Buckeye State\", \"OH\", \"Ohio\", \"Ohio, United States\", \"State of Ohio\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"East North Central\"], \"US Census Region\" : [\"Midwest\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-OH\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "140446",
        "centroid": "[-82.735,40.2135]",
        "bbox": "[-84.8203,38.4034,-80.5185,41.9773]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpKUkxwOnY0",
        "worldview": "all",
        "unit_code": "40",
        "wikidata_id": "Q1649",
        "name": "Oklahoma",
        "name_en": "",
        "name_translations": "{\"en\": [\"OK\", \"Oklahoma\", \"Oklahoma, United States\", \"State of Oklahoma\", \"The Sooner State\", \"US-OK\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"West South Central\"], \"US Census Region\" : [\"South\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-OK\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "223891",
        "centroid": "[-97.2171,35.3258]",
        "bbox": "[-103.0024,33.6158,-94.4307,37.0023]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpKaExwOnY0",
        "worldview": "all",
        "unit_code": "41",
        "wikidata_id": "Q824",
        "name": "Oregon",
        "name_en": "",
        "name_translations": "{\"en\": [\"OR\", \"Ore.\", \"Oreg.\", \"Oregon\", \"Oregon, United States\", \"State of Oregon\", \"The Beaver State\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"Pacific\"], \"US Census Region\" : [\"West\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-OR\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "347814",
        "centroid": "[-120.5095,44.1521]",
        "bbox": "[-124.6065,41.9921,-116.4633,46.2921]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpKeExwOnY0",
        "worldview": "all",
        "unit_code": "42",
        "wikidata_id": "Q1400",
        "name": "Pennsylvania",
        "name_en": "",
        "name_translations": "{\"en\": [\"Commonwealth of Pennsylvania\", \"Keystone State\", \"PA\", \"Pa.\", \"Penna\", \"Penna.\", \"Pennsylvania\", \"Pennsylvania, United States\", \"Pennsylvania, United States (State of Pennsylvania) Keystone State (PA) Penna\", \"State of Pennsylvania\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"Middle Atlantic\"], \"US Census Region\" : [\"Northeast\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-PA\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "154958",
        "centroid": "[-77.809,41.0073]",
        "bbox": "[-80.5195,39.7198,-74.6895,42.2693]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpLQkxwOnY0",
        "worldview": "all",
        "unit_code": "44",
        "wikidata_id": "Q1387",
        "name": "Rhode Island",
        "name_en": "",
        "name_translations": "{\"en\": [\"Little Rhody\", \"RI\", \"Rhode Island\", \"Rhode Island and Providence Plantations\", \"Rhode Island, United States\", \"Road Island\", \"State of Rhode Island\", \"State of Rhode Island and Providence Plantations\", \"The Ocean State\", \"little rhodie\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"New England\"], \"US Census Region\" : [\"Northeast\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-RI\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "3735",
        "centroid": "[-71.6164,41.6622]",
        "bbox": "[-71.8865,41.1464,-71.1206,42.0188]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpLUkxwOnY0",
        "worldview": "all",
        "unit_code": "45",
        "wikidata_id": "Q1456",
        "name": "South Carolina",
        "name_en": "",
        "name_translations": "{\"en\": [\"Palmetto State\", \"S. C.\", \"S.C.\", \"SC\", \"South Carolina\", \"South Carolina, United States\", \"State of South Carolina\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"South Atlantic\"], \"US Census Region\" : [\"South\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-SC\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "97104",
        "centroid": "[-80.568,33.6408]",
        "bbox": "[-83.3539,32.0374,-78.5472,35.2155]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpLaExwOnY0",
        "worldview": "all",
        "unit_code": "46",
        "wikidata_id": "Q1211",
        "name": "South Dakota",
        "name_en": "",
        "name_translations": "{\"en\": [\"S. Dak.\", \"S. Dakota\", \"S.D.\", \"SD\", \"SoDak\", \"South Dakota\", \"South Dakota, United States\", \"State of South Dakota\", \"The Coyote State\", \"The Mount Rushmore State\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"West North Central\"], \"US Census Region\" : [\"Midwest\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-SD\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "280845",
        "centroid": "[-100.2539,44.2358]",
        "bbox": "[-104.0579,42.4797,-96.4365,45.9457]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpLeExwOnY0",
        "worldview": "all",
        "unit_code": "47",
        "wikidata_id": "Q1509",
        "name": "Tennessee",
        "name_en": "",
        "name_translations": "{\"en\": [\"State of Tennessee\", \"TN\", \"Tenn.\", \"Tennessee\", \"Tennessee, United States\", \"US-TN\", \"Volunteer State\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"East South Central\"], \"US Census Region\" : [\"South\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-TN\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "134965",
        "centroid": "[-86.3183,35.8349]",
        "bbox": "[-90.3103,34.9829,-81.6469,36.6783]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpMQkxwOnY0",
        "worldview": "all",
        "unit_code": "48",
        "wikidata_id": "Q1439",
        "name": "Texas",
        "name_en": "",
        "name_translations": "{\"en\": [\"Lone Star State\", \"State of Texas\", \"TX\", \"Tex.\", \"Texas\", \"Texas, United States\", \"US-TX\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"West South Central\"], \"US Census Region\" : [\"South\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-TX\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "810507",
        "centroid": "[-99.8102,31.32]",
        "bbox": "[-106.6456,25.8372,-93.508,36.5007]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpMUkxwOnY0",
        "worldview": "all",
        "unit_code": "49",
        "wikidata_id": "Q829",
        "name": "Utah",
        "name_en": "",
        "name_translations": "{\"en\": [\"Beehive State\", \"State of Utah\", \"UT\", \"Utah\", \"Utah, United States\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"Mountain\"], \"US Census Region\" : [\"West\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-UT\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "283989",
        "centroid": "[-111.5495,39.546]",
        "bbox": "[-114.0529,36.9977,-109.0416,42.0017]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpMaExwOnY0",
        "worldview": "all",
        "unit_code": "50",
        "wikidata_id": "Q16551",
        "name": "Vermont",
        "name_en": "",
        "name_translations": "{\"en\": [\"State of Vermont\", \"The Green Mountain State\", \"VT\", \"Vermont\", \"Vermont, United States\", \"Vt.\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"New England\"], \"US Census Region\" : [\"Northeast\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-VT\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "33805",
        "centroid": "[-72.7757,43.8828]",
        "bbox": "[-73.4312,42.7269,-71.465,45.0167]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpMeExwOnY0",
        "worldview": "all",
        "unit_code": "51",
        "wikidata_id": "Q1370",
        "name": "Virginia",
        "name_en": "",
        "name_translations": "{\"en\": [\"Commonwealth of Virginia\", \"Old Dominion\", \"State of Virginia\", \"VA\", \"Va.\", \"Virginia\", \"Virginia, United States\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"South Atlantic\"], \"US Census Region\" : [\"South\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-VA\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "129931",
        "centroid": "[-78.4559,38.018]",
        "bbox": "[-83.6754,36.5409,-75.2424,39.466]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpNQkxwOnY0",
        "worldview": "all",
        "unit_code": "53",
        "wikidata_id": "Q1223",
        "name": "Washington",
        "name_en": "",
        "name_translations": "{\"en\": [\"Evergreen State\", \"State of Washington\", \"WA\", \"Washington\", \"Washington (state)\", \"Washington State\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"Pacific\"], \"US Census Region\" : [\"West\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-WA\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "258718",
        "centroid": "[-119.7421,47.3013]",
        "bbox": "[-124.7625,45.5435,-116.9161,49.0024]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpNUkxwOnY0",
        "worldview": "all",
        "unit_code": "54",
        "wikidata_id": "Q1371",
        "name": "West Virginia",
        "name_en": "",
        "name_translations": "{\"en\": [\"Almost Heaven\", \"State of West Virginia\", \"The Mountain State\", \"W. Va.\", \"W.Va.\", \"WV\", \"West Virginia\", \"West Virginia, United States\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"South Atlantic\"], \"US Census Region\" : [\"South\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-WV\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "80148",
        "centroid": "[-80.2144,38.941]",
        "bbox": "[-82.6446,37.2015,-77.7195,40.6388]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpNaExwOnY0",
        "worldview": "all",
        "unit_code": "55",
        "wikidata_id": "Q1537",
        "name": "Wisconsin",
        "name_en": "",
        "name_translations": "{\"en\": [\"Badger State\", \"State of Wisconsin\", \"WI\", \"Wis.\", \"Wisconsin\", \"Wisconsin, United States\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"East North Central\"], \"US Census Region\" : [\"Midwest\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-WI\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "203919",
        "centroid": "[-90.368,44.7706]",
        "bbox": "[-92.8894,42.4919,-86.764,47.0807]"
    },
    {
        "iso_3166_1": "US",
        "layer_type": "admin",
        "layer_level": "1",
        "layer_name": "adm1",
        "mapbox_id": "dXJuOm1ieGJuZDpNeExwOnY0",
        "worldview": "all",
        "unit_code": "56",
        "wikidata_id": "Q1214",
        "name": "Wyoming",
        "name_en": "",
        "name_translations": "{\"en\": [\"Big Wyoming\", \"State of Wyoming\", \"The Cowboy State\", \"The Equality State\", \"US-WY\", \"WY\", \"Wyo\", \"Wyo.\", \"Wyoming\", \"Wyoming, United States\"]}",
        "description": "state",
        "unit_code_description": "",
        "source_date": "2018",
        "iso_3166_1_alpha_3": "USA",
        "grouping_attributes": "{ \"US Census Division\" : [\"Mountain\"], \"US Census Region\" : [\"West\"] }",
        "join_attributes": "{ \"iso_3166_2\" : \"US-WY\" }",
        "parent_0": "dXJuOm1ieGJuZDo4aERwOnY0",
        "parent_1": "",
        "parent_2": "",
        "parent_3": "",
        "parent_4": "",
        "polygon_tileset_id": "mapbox.boundaries-adm1-v4",
        "polygon_source_layer": "boundaries_admin_1",
        "point_tileset_id": "mapbox.boundaries-admPoints-v4",
        "point_source_layer": "points_admin_1",
        "polygon_z_min": "0",
        "point_z_min": "0",
        "area_sqkm": "346607",
        "centroid": "[-107.5486,43.0349]",
        "bbox": "[-111.0569,40.9948,-104.0522,45.0058]"
    }
]