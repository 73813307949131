import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRequest } from "../Apis";
import { errMsg, getToastId, toastUp, updateToast } from "./reducerUtils";
import { statistics } from "./initialState";
import { appLog } from "../../common/helpers";

const initialState = { ...statistics };

export const fetchStats = createAsyncThunk(  "fetchStats",  async (payload, thunkAPI) => {
    const toastId = getToastId("Fetching Statistics");
    return new Promise(async (resolve, reject) => {
      try {
        let queryString = '';
        if (!!payload && payload.region !== '' && payload.region !== undefined) {
          queryString = `?region=${payload.region}`;
        }
        const response = await apiRequest.get(`/facility_stats${queryString}`);
        const payloadData = response.data.data;
        updateToast(toastId, "Statistics Fetched", true);
        resolve(payloadData);
      } catch (e) {
        console.log(e);
        toastUp(errMsg(e, true, "Statistics"), false);
        reject(e);
      }
    });
  }
);

export const fetchScheduleStats = createAsyncThunk(
  "/schedule_stats",
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        let queryString = '';
        if (payload !== '') {
          queryString = `?region=${payload}`;
        }
        const response = await apiRequest.get(`/scheduling-summaries${queryString}`);
        const payloadData = response.data.data;
        resolve(payloadData);
      } catch (e) {
        console.log(e);
        toastUp(errMsg(e, true, "Schedule Statistics"), false);
        reject(e);
      }
    })
  }
)

export const fetchConsultantsStatistics = createAsyncThunk(
  "/consultants_statistics",
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        let queryString = "";
        if (!!payload && payload.region !== '' && payload.region !== undefined) {
          queryString = `?region=${payload.region}`;
        }
        const response = await apiRequest.get(`/consultant_stats${queryString}`);
        const payloadData = response.data.data;
        resolve(payloadData);
      } catch (e) {
        console.log(e)
        toastUp(errMsg(e, true, 'Consultants Statistics'), false)
        reject(e)
      }
    })
  }
)

export const fetchPatientStatistics = createAsyncThunk(
  "/patient_statistics",
  async (payload, thunkAPI) => {
    console.log("Patients Payload:::", payload)
    return new Promise(async (resolve, reject) => {
      try {
        let queryString = "";
        if (!!payload && payload !== '' && payload !== undefined) {
          queryString = `?region=${payload}`;
        }
        const response = await apiRequest.get(`/patients_stats${queryString}`);
        const payloadData = response.data.data;
        console.log("Patients Payload Response:::", payloadData)
        resolve(payloadData);
      } catch (e) {
        console.log(e)
        toastUp(errMsg(e, true, 'Patient Statistics'), false)
        reject(e)
      }
    });
  }
);

export const fetchFacilitieStats = createAsyncThunk(
  '/facilities',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        let queryString = "";
        if (!!payload && payload.region !== '' && payload.region !== undefined) {
          queryString = `?region=${payload.region}`;
        }
        const response = await apiRequest.get(`/facility_stats${queryString}`)
        const payloadData = response.data.data
        resolve(payloadData)
      } catch (e) {
        console.log(e)
        toastUp(errMsg(e, true, 'Offices'), false)
        reject(e)
      }
    })
  }
)

export const fetchPatientByLocation = createAsyncThunk(
  "/patients_statistics_by_location",
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId(`Fetching Patients by location`)
      try {
        let queryString = "";
        if (!!payload && payload.region !== '' && payload.region !== undefined) {
          queryString = `?region=${payload.region}`;
        }
        const response = await apiRequest.get(`/patient_stats/location?states=${payload}`)
        const res = response.data.data
        updateToast(toastId, `Patients by location fetched successfully`, true)
        resolve(res)
      } catch (e) {
        console.log(e)
        reject(e)
      }
    })
  }
)
export const StatsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {
    setStats: (state, action) => {
      state.stats = action.payload;
    }, doResetStatsSlice: (state) => {
      Object.keys(initialState).map(key => state[key] = initialState[key])
    },
  },

  extraReducers:(builder)=> {
    builder.addCase(fetchStats.pending, (state, action) => {
      state.loadingStatistics = true;
    }).addCase(fetchStats.fulfilled, (state, action) => {
      state.statistics = action.payload?.all_facilities;
      state.loadingStatistics = false;
    }).addCase(fetchScheduleStats.pending, (state, action) => {
      state.loadingScheduleStatistics = true;
    }).addCase(fetchScheduleStats.fulfilled, (state, action) => {
      state.scheduleStatistics = action.payload;
      state.loadingScheduleStatistics = false;
    }).addCase(fetchConsultantsStatistics.fulfilled, (state, action) => {
      state.consultants = action.payload?.consultant;
    }).addCase(fetchPatientStatistics.pending, (state, action) => {
      state.loadingPatients = true;
    }).addCase(fetchPatientStatistics.fulfilled, (state, action) => {
      state.patients = action.payload;
      state.loadingPatients = false;
    }).addCase(fetchFacilitieStats.fulfilled, (state, action) => {
      state.facilities = action.payload?.all_facilities;
    }).addCase(fetchPatientByLocation.fulfilled, (state, action) => {
      state.patientLocation = action.payload;
    })
  }
})

export const { setStats,doResetStatsSlice } = StatsSlice.actions;
export default StatsSlice.reducer;
