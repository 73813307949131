import React from 'react'
import {connect} from 'react-redux'
import {Form, Input} from 'antd'

export const DeclineApproval = ({declineAssessment}) => {
  const {TextArea} = Input;
  const onFinish = (values) => {
    declineAssessment(values)
  };
  return (<Form
      name="declineApprovalForm"
      id="declineApprovalForm"
      labelCol={{
        span: 16
      }}
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item

        name="notes"
        label="Comment"
        rules={[{required: true, message: 'Missing comment'}]}
      >
        <TextArea rows={4}/>
      </Form.Item>
    </Form>)
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(DeclineApproval)
