import React, {useEffect, useState} from 'react'
import {Form, Select} from 'antd'
import {emptyFun, makeAutoCompleteOptions, makeSelectOptions} from '../../../common/helpers'
import dayjs from 'dayjs'
import {connect, useDispatch} from "react-redux";
import DynamicForm from "../../../components/DynamicForm";
import {copayRules} from '../../../common/inputUtils';
import {fetchInsuranceProviders} from '../../../app/Reducers';

const ProspectInsuranceForm = ({setInsurances, insurances, setInsuranceForm, insuranceProviders, initialData, editing,prospectData,prospectName}) => {
  const dispatch = useDispatch();
  const [effectiveDate, setEffectiveDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [holderRelationship, setHolderRelationship] = useState(initialData?.policy_holder_relationship);
  const [insuranceProviders_, setInsuranceProviders_] = useState([]);
  const [guarantorRel, setGuarantorRel] = useState(initialData?.guarantor_relationship);
  const uploadUrl = process.env.REACT_APP_ENDPOINT + '/files'
  //console.log({initialData})
  const [initialInsuranceData, setInitialInsuranceData] = useState(initialData ? {
    ...initialData,
    ...(!!initialData.copay_due && {copay_due: parseFloat(initialData?.copay_due)}),
    effective_date: initialData?.effective_date ? dayjs(initialData?.effective_date) : null,
    end_date: initialData?.end_date ? dayjs(initialData?.end_date) : null,
    billing_group: initialData.billing_group || null
  } : undefined)
  const [insuranceForm] = Form.useForm()
  const dateFormat = "MM/DD/YYYY"
  const {Option} = Select
  
  useEffect(() => {
    if (!!insuranceProviders[0]) {
      setInsuranceProviders_(makeAutoCompleteOptions(insuranceProviders.map(item => item.name)))
    }
    return emptyFun
  }, [insuranceProviders])
  
  useEffect(() => {
    dispatch(fetchInsuranceProviders())
    return emptyFun
  }, [])
  
  const effectiveDateChange = (value) => {
    let customDate = dayjs(value).format(dateFormat)
    setEffectiveDate(customDate);
  };
  
  const endDateChange = (value) => {
    let customDate = dayjs(value).format(dateFormat)
    setEndDate(customDate);
  };
  
  const disablePrevDates = (current) => {
    if (effectiveDate) {
      let customDate = dayjs(effectiveDate);
      return (current && current < dayjs(customDate, dateFormat))
    }
  }
  const disabledFutureDates = (current) => {
    let customDate = dayjs()
    let tooOld = dayjs("1869-12-31")
    return (current && current > dayjs(customDate, dateFormat)) || (current && current < dayjs(tooOld, dateFormat))
  }
  
  async function onFinish(values_) {
    let values = { ...values_.values }
    const {billing_group, ...payload } = { ...initialInsuranceData, ...values, effective_date: effectiveDate, end_date: endDate }
    if (!!initialInsuranceData) {
      const insurances_ = [...insurances]
      let indexOfInitialData = insurances.indexOf(initialData)
      insurances_.splice(indexOfInitialData, 1)
      await setInsurances([...insurances_, payload]);
    } else {
      await setInsurances([...insurances, payload]);
    }
    
    insuranceForm.resetFields();
    setInsuranceForm(false);
  }

  let insuranceInputFields = {
    fields: [
      {fullRow: true, label: 'Insurance Provider', name: 'insurance_provider', required: true, type: 'autocomplete', options: insuranceProviders_},
      {fullRow: true, label: "Insurance Plan", name: 'plan', required: false, type: 'text'},
      {fullRow: true, label: "Policy Number", name: 'policy_number', required: true, type: 'text'},
      {fullRow: true, label: "Policy Holder Relationship", name: 'policy_holder_relationship', required: true, type: 'select', props: {onChange: (e) => setHolderRelationship(e), allowClear: true}, options: makeSelectOptions(['Self', 'Spouse', 'Sibling']) },
      holderRelationship !== 'Self' && {label: "Policy Holder Name", name: 'policy_holder_name', required: true, type: 'text' , fullRow: true},
      {fullRow: true, label: 'Copay', name: 'copay', required: true, type: 'number', fiprops: {rules: copayRules}, props: {step: "0.01", min: 0/* stringMode: true*/}},
      {label: 'Priority', name: 'priority', required: true, type: 'select', props: {allowClear: true}, options: makeSelectOptions(['primary insurance', 'secondary insurance', 'tertiary insurance'])},
      {label: "Effective Date", name: 'effective_date', required: false, type: 'date', dateFormat, props: {onChange: effectiveDateChange, disabledDate: disabledFutureDates}},
      { label: "End Date", name: 'end_date', required: false, type: 'date', dateFormat, props: { onChange: endDateChange, disabledDate: disablePrevDates } },
      { label: "Guarantor Relationship", name: 'guarantor_relationship', required: true, type: 'select',
        props: { onSelect: (e)=> {
          setGuarantorRel(e)
            insuranceForm.setFieldsValue({guarantor_relationship:e, ...((e === 'Self' && !!prospectData) ? { guarantor_name: `${prospectData?.name || `${prospectData?.first_name || ''} ${prospectData?.middle_name || ''} ${prospectData?.last_name || ''}`}` }:{guarantor_name:prospectName}) })
          }, allowClear: true }, options: makeSelectOptions(['Self', 'Spouse', 'Sibling']) },
      {
        label: "Guarantor Name", name: 'guarantor_name', required: true, type: 'text',
        props: { disabled: (guarantorRel === 'Self' && (prospectData?.name || prospectData?.first_name || prospectData?.middle_name || prospectData?.last_name)) }
      }
    
    ]
  }
  return <div>
    {<DynamicForm
      defaultValues={initialInsuranceData}
      form={insuranceForm} formFooter={null}
      formName={"prospectInsuranceForm"}
      formId={"prospectInsuranceForm"}
      onDynamicFormEntriesResult={onFinish}
      inputFields={insuranceInputFields}/>}
  </div>
};

const mapStateToProps = (state) => ({insuranceProviders: state.masters.insuranceProviders})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ProspectInsuranceForm)
