/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Button, Col, Grid, Image, Modal, Row, Table, Upload} from 'antd'
import {Headline6, Subtitle1} from '../../../components'
import SignaturePad from 'react-signature-canvas';
// import './sigCanvas.css';
import {toast} from 'react-toastify'
import {apiRequest} from '../../../app/Apis'
import {createConsultantTaxonomy, fetchSpecificConsultant, setActiveUser} from '../../../app/Reducers'
import {
  addButton,
  containedButton,
  createFormInputsFromTableColumns,
  dialogCloser,
  editButton,
  emptyFun,
  emptyTable,
  executeChunkFn,
  formSubmitButton,
  getSkels,
  horStack,
  modalClose,
  outlinedButton,
  responsiveTbl
} from "../../../common/helpers";
import EditConsultant from './EditConsultant'
import ConsultantLocations from "./ConsultantLocations";
import TodayActivity from "./TodayActivity";
import ConsultantLocationPane from "./ConsultantLocationPane";
import ConsultantVehicles from "./ConsultantVehicles";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import ConsultantOffices from './ConsultantOffices';
import RemoveCookie from '../../../hooks/RemoveCookie';
import SetCookie from '../../../hooks/setCookie';
import SignatoryConsultant from './SignatoryConsultantsList';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {US_STATES} from '../../../common/States';
import * as Sentry from '@sentry/react';
import {errMsg, toastUp} from '../../../app/Reducers/reducerUtils';
import DynamicModalForm from '../../../components/DynamicModalForm';
import ConsultantMilage from './ConsultantMilage';
import ConsultantAssignmentsMini from './ConsultantAssignmentsMini';
import EditConsultantModules from './EditConsultantModules';
import {useLocation, useNavigate} from 'react-router-dom';

const columns = [
  {
    title: 'Primary taxonomy', dataIndex: 'primary', key: 'primary', render: (record) => {
      console.log({ record })
      return record ? "Yes" : 'No'
    }, fltr: { filterable: false, type: 'select', options: [{ label: "Yes", value: true }, { label: 'No', value: false }], editableCol: true, required: true, fullRow: false }
  },
  {
    title: 'Descriptions',
    key: 'desc',
    fltr: { filterable: false, type: 'text', editableCol: true, required: true, fullRow: false }, invisible: true
  },
  {
    title: 'Selected taxonomy', render: (record) => {
      // console.log({ record })
      return `${record.code} ${record.desc} `
    }, fltr: { filterable: false, type: 'text', editableCol: false, required: true, fullRow: true }
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    fltr: { filterable: false, type: 'select', options: US_STATES.map(value => ({ value: value.Code, label: value.State })), editableCol: true, required: true, fullRow: false }
  },
  {
    title: 'Code',
    key: 'code',
    fltr: { filterable: false, type: 'text', editableCol: true, required: true, fullRow: false }, invisible: true
  },
  {
    title: 'Taxonomy group',
    key: 'taxonomy_group',
    fltr: { filterable: false, type: 'text', editableCol: true, required: true, fullRow: false }, invisible: true
  },
  {
    title: 'Licence Number', dataIndex: 'license', key: 'license', fltr: { filterable: false, type: 'text', editableCol: true, required: true, fullRow: false }
  }
]
const { useBreakpoint } = Grid;
export const ConsultantSummary = ({ consultant, appConf, navs, activeUser, setActiveTab, isWcc }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate();
  const windowState = useBreakpoint();
  const [signatureModal, setSignatureModal] = useState(false);
  const [locationsModal, setlocationsModal] = useState(false)
  const [imageUrl, setImageUrl] = useState(null);
  const [isExecuting, setisExecuting] = useState(false)
  const sigCanvas = useRef({});
  const { message } = useSubscription(`view_consultant/${consultant.id}`, { qos: 2, nl: true });
  const can_view_consultant_vehicle = usePermissionCheck('view_consultant_vehicle', false, [])
  const can_update_consultant_global = usePermissionCheck('update_consultant_global', false, [])
  const can_view_assigned_patient_consultant = usePermissionCheck('view_assigned_patient_consultant', false, [])
  const can_view_consultant_biologics_applications = usePermissionCheck('view_consultant_biologics_applications', false, [])
  const can_view_consultant_signature = usePermissionCheck('view_consultant_signature', false, [])
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message)
      dispatch(fetchSpecificConsultant(consultant.id))
    }
    return emptyFun
  }, [message])
  
  const dateFormat = "YYYY-MM-DD";
  let taxonomyFormInputs = createFormInputsFromTableColumns(false, columns, null, null, null, null, dateFormat)
  //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
  let dynamicFormInputFields = {
    'Edit taxonomy': taxonomyFormInputs, 'Add taxonomy': taxonomyFormInputs
  }
  
  const openTaxonomyForm = async (record) => {
    await setFormName(!!record ? 'Edit taxonomy' : 'Add taxonomy');
    await setDefaultDynamicModalFormValues(!!record ? { ...record, consultantId: consultant.id } : { consultantId: consultant.id });
    await setDynamicFormOpen(true);
  };
  
  /**
   * This function is handling returned form entries from the dynamic form
   * */
  const onDynamicFormEntriesResult = async (entries) => {
    
    try {
      switch (formName) {
        case 'Add taxonomy':
          await executeChunkFn(dispatch, createConsultantTaxonomy, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen), null)
          break;
        case 'Edit taxonomy':
          break;
        default:
          return
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      toastUp(errMsg(e, false, formName), false);
    }
  };
  // useEffect(() => {
  //   dispatch(fetchSpecificConsultant(consultant.id))
  //   return emptyFun
  // }, [history])
  const clear = () => sigCanvas.current.clear();
  
  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]); else byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }
  
  const save = async () => {
    await setisExecuting(true)
    setImageUrl(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
    const toastId = toast("updating signature", { type: "info", autoClose: false });
    let formData = new FormData();
    const file_64 = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
    let file = dataURItoBlob(file_64)
    formData.append("file", file);
    const { data } = await apiRequest.post('/files', formData)
    const image_id = data.data.id
    const signature = await apiRequest.post(`consultants/${consultant.id}/signature`, { signature_id: image_id })
    if (signature && signature.data.data) {
      setFileList([])
      const user = await apiRequest.get('/me/profile').catch(async function (err) {
        await setisExecuting(false)
        console.log(err.response.data)
        toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
      })
      if (user && user.data) {
        // RemoveCookie('activeUser')
        SetCookie('activeUser', JSON.stringify(user.data.data))
        // localStorage.setItem('activeUser', JSON.stringify(user.data.data))
        await dispatch(setActiveUser(user.data.data))
        await dispatch(fetchSpecificConsultant(consultant.id))
      }
      toast.update(toastId, { render: "Signature updated", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      
    }
    await setisExecuting(false)
    handleSignatureCancel()
  };
  const handleSignatureOk = () => {
  }
  const handleSignatureCancel = () => setSignatureModal(false)
  const handleLocationsOk = () => {
  }
  const handleLocationsCancel = () => setlocationsModal(false)
  
  const onPieEnter = () => {
  }
  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  const uploadSignature = async () => {
    const toastId = toast("updating signature", { type: "info", autoClose: false });
    let formData = new FormData();
    formData.append("file", fileList[0].originFileObj);
    const { data } = await apiRequest.post('/files', formData)
    const image_id = data.data.id
    const signature = await apiRequest.post(`consultants/${consultant.id}/signature`, { signature_id: image_id })
    await setFileList([])
    if (signature && signature.data.data) {
      const user = await apiRequest.get('/me/profile').catch(function (err) {
        console.log(err.response.data)
        toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
      })
      if (user && user.data) {
        // RemoveCookie('activeUser')
        SetCookie('activeUser', JSON.stringify(user.data.data))
        // localStorage.setItem('activeUser', JSON.stringify(user.data.data))
        dispatch(setActiveUser(user.data.data))
        dispatch(fetchSpecificConsultant(consultant.id))
      }
      toast.update(toastId, { render: "Signature updated", type: toast.TYPE.SUCCESS, autoClose: 5000 });
    }
  }
  const [consultantForm, setconsultantForm] = useState(false)
  /*let taxonomies = [
      {
          "code": "208000000X",
          "desc": "Pediatrics",
          "state": "NY",
          "license": "179017-1",
          "primary": true,
          "taxonomy_group": ""
      }
  ]*/
  
  const [consultantModulesForm, setconsultantModulesForm] = useState(false)
  
  // selectedsection
  const [selectedsection, setselectedsection] = useState('primary')
  return (
    (<div className="">
      {(isDynamicFormOpen && !!formName) && (
        <DynamicModalForm
          setDynamicFormOpen={setDynamicFormOpen}
          isDynamicFormOpen={isDynamicFormOpen}
          inputFields={dynamicFormInputFields[formName]}
          onDynamicFormEntriesResult={onDynamicFormEntriesResult}
          closeModal={dialogCloser(setDynamicFormOpen)}
          isExecuting={isExecuting}
          defaultValues={defaultDynamicModalFormValues}
          formName={formName}
        />
      )}
      <Modal
        destroyOnClose={true}
        title={"Edit Consultant"}
        open={consultantForm}
        // onOk={handleShippingOk}
        onCancel={dialogCloser(setconsultantForm)}
        closeIcon={modalClose(dialogCloser(setconsultantForm))}
        maskClosable={false}
        width={800}
        footer={[
          <div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([
              outlinedButton(() => dialogCloser(setconsultantForm)(), 'Cancel', isExecuting),
              formSubmitButton('consultantForm', 'Update', isExecuting, 'Updating')
            ])}
          </div>
        ]}>
        <EditConsultant consultant={consultant} closeModal={dialogCloser(setconsultantForm)} setisExecuting={setisExecuting}/>
      </Modal>
      
      <Modal
        destroyOnClose={true}
        title={selectedsection==='offices'?'Edit consultant\'s state licenses':`Edit Consultant ${selectedsection} details`}
        open={consultantModulesForm}
        // onOk={handleShippingOk}
        onCancel={dialogCloser(setconsultantModulesForm)}
        closeIcon={modalClose(dialogCloser(setconsultantModulesForm))}
        maskClosable={false}
        width={800}
        footer={[
          <div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([
              outlinedButton(() => dialogCloser(setconsultantModulesForm)(), 'Cancel', isExecuting),
              formSubmitButton('consultantForm', 'Update', isExecuting, 'Updating')
            ])}
          </div>
        ]}>
        <EditConsultantModules consultant={consultant} closeModal={dialogCloser(setconsultantModulesForm)} setisExecuting={setisExecuting} selectedsection={selectedsection}/>
      </Modal>
      
      <Modal
        title="Signature Pad"
        open={signatureModal}
        onOk={handleSignatureOk}
        onCancel={handleSignatureCancel}
        closeIcon={modalClose(handleSignatureCancel)}
        maskClosable={false}
        width={600}
        footer={[
          <div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([
              outlinedButton(() => handleSignatureCancel(), 'Cancel', isExecuting),
              outlinedButton(clear, 'Clear', isExecuting),
              containedButton(save, 'Save', isExecuting, 'Saving')
            ])}
          </div>
        ]}>
        <SignaturePad ref={sigCanvas} canvasProps={{ width: 552, height: 500, className: 'signatureCanvas' }}/>
      </Modal>
      <Modal
        title="Consultant Locations"
        open={locationsModal}
        onOk={handleLocationsOk}
        onCancel={handleLocationsCancel}
        closeIcon={modalClose(handleLocationsCancel)}
        maskClosable={false}
        width={600}
        footer={[
          <div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([
              outlinedButton(() => handleLocationsCancel(), 'Cancel', isExecuting),
              formSubmitButton('consultantLocationForm', 'Save', isExecuting, 'Saving')
            ])}
          </div>
        ]}>
        <ConsultantLocations setisExecuting={setisExecuting} handleLocationsCancel={handleLocationsCancel} consultant={consultant}/>
      
      </Modal>
      <Row gutter={16}>
        <Col span={16}>
          <div className="flex-boy-column">
            
            <TodayActivity/>
            {consultant && (consultant.designation === 'M.D' || consultant.roles.includes('MedicalDirector')) &&
              <SignatoryConsultant consultantId={consultant.id} appConf={appConf} navs={navs} navigate={navigate}/>}
            {
              consultant && consultant.account_type === 'Provider' &&
              
              <div className="shadow-card-box" style={{ minHeight: "100px" }}>
                <div className="mb-4 flexy-row">
                  <Subtitle1>Taxonomy</Subtitle1>
                  {addButton(() => openTaxonomyForm())}
                </div>
                <div className={'w-full'}>
                  <Table
                    loading={appConf.insuranceProvidersFilters[2]} size={'small'} {...responsiveTbl(windowState)}
                    locale={{ emptyText: emptyTable('patient wounds.') }}
                    {...getSkels(false, columns.filter(value => !value.invisible), consultant?.npi_detail?.npi_details?.taxonomies || [])} pagination={null}
                  />
                </div>
              </div>}
          
          </div>
        </Col>
        <Col span={8}>
          {isWcc && <ConsultantMilage setActiveTab={setActiveTab} consultant={consultant}/>}
          {isWcc && <ConsultantAssignmentsMini setActiveTab={setActiveTab}/>}
          <ConsultantLocationPane setlocationsModal={setlocationsModal}/>
          {/*<div className="card-box">
            <div className="flex-boy-row-space" style={{width: "100%"}}>
              <Headline6>Locations</Headline6>
              <IconButton type={"button"} size={"small"} onClick={() => setlocationsModal(true)}> <EditOutlined
                fontSize={"small"}/>
              </IconButton>
            </div>
            <div style={{width: "100%"}}>
              {consultant.territories && consultant.territories.length ? consultant.territories.map((item, i) => {
                  return (
                    <div className="flex-boy-row-space" style={{width: "100%", alignItems: "normal"}} key={i}>
                      <Subtitle1 style={{textAlign: 'left', flex: 1}}>Default:</Subtitle1>
                      <div style={{flex: 1}}>
                        <Body2 color="black" style={{textAlign: 'left', flex: 1}}>{item.zone_name}</Body2>
                      </div>
                    </div>
                  )
                }) :
                <div className="flex-boy-row-space" style={{width: "100%", alignItems: "normal"}}>
                  <Subtitle1 style={{textAlign: 'left', flex: 1}}>Default:</Subtitle1>
                  <div style={{flex: 1}}>
                    <Body2 color="black" style={{
                      textAlign: 'left',
                      flex: 1
                    }}>{consultant.city}, {consultant.state}, {consultant.region}</Body2>
                  </div>
                </div>}
            </div>
          </div>*/}
          { < ConsultantOffices consultant={consultant} setselectedsection={setselectedsection} setconsultantForm={setconsultantModulesForm}/>}
          
          
          {can_view_consultant_vehicle && <ConsultantVehicles/>}
          {(can_view_consultant_signature ||consultant.user_id === activeUser.id)&&<div className="card-box">
            <div className="flex-boy-row-space" style={{ width: "100%" }}>
              <Headline6>Signature</Headline6>
              {(can_update_consultant_global || consultant.user_id === activeUser.id) && editButton(() => setSignatureModal(true))}
            </div>
            <div className="flex-boy-row-space">
              <div style={{ width: "100%", textAlign: 'left', marginRight: '10px' }}>
                {consultant.signature && !fileList.length[0] ? <Image
                  src={consultant.signature ? consultant.signature.url : require("../../../assets/Frame89.svg").default}
                  style={{ height: "180px" }}/> : (!can_update_consultant_global && consultant.user_id !== activeUser.id) ? <p>No Signature</p> : null}
              </div>
              {(can_update_consultant_global || consultant.user_id === activeUser.id) && <div>
                <Upload listType="picture-card" fileList={fileList} onChange={onChange} onPreview={onPreview}> Update signature </Upload>
                {fileList.length < 1 ? null :
                  <Button type="primary" onClick={uploadSignature} disabled={fileList.length === 0} className="login-form-button" style={{ marginTop: 16 }}>
                    Save
                  </Button>
                }
              </div>}
            </div>
          </div>}
        
        </Col>
      </Row>
    </div>)
  );
}
const mapStateToProps = (state) => ({

  consultant: state.consultants.selectedConsultant,
  activeUser: state.auth?.activeUser,
  appointments: state.consultants.consultantSchedule,
  appConf: state.appConf, navs: state.navs
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ConsultantSummary)
