import {useCallback, useState} from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./RotateAndCropUtil";
import {Button, Slider} from 'antd';

const RotateAndCrop = ({image, onImageEdited,loading}) => {
  const [crop, setCrop] = useState({x: 0, y: 0})
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  
  const showCroppedImage = useCallback(async () => {
    // let toastId = getToastId('Saving image')
    try {
      const {croppedImage, blobFile} = await getCroppedImg(image, croppedAreaPixels, rotation)
      console.log('donee', {croppedImage})
      setCroppedImage(croppedImage)
      let formData = new FormData();
      formData.append("file", blobFile);
      // const {data} = await apiRequest.post('/files', formData)
      // console.log({data})
      // updateToast(toastId, 'Image saved successfully', true)
      // onImageEdited(data)
      onImageEdited(blobFile)
    } catch (e) {
      console.error(e)
      // updateToast(toastId, errMsg(e, false, null, 'Unable to upload image'), false)
    }
  }, [croppedAreaPixels, rotation])
  
  const onClose = useCallback(() => {
    setCroppedImage(null)
  }, [])
  const rotateMarks = {360: 'Rotate'}
  const zoomMarks = {3: 'Zoom'}
  return (
    <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
      <div
        className="crop-area-container"
        // style={{display: image === null || croppedImage !== null ? "none" : "block"}}
      >
        <div className="crop-container">
          <Cropper
            image={image}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={4 / 3}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        {/*<div className="cropped-image-container">
          {croppedImage && (<img className="cropped-image" src={croppedImage} alt="cropped"/>)}
          {croppedImage && <button onClick={onClose}>close</button>}
        </div>*/}
      </div>
      
      <div style={{marginTop: 10, width: '100%'}}>
        <Slider
          value={rotation}
          marks={rotateMarks}
          min={0}
          max={360}
          step={1}
          onChange={(rotation) => setRotation(rotation)}
        />
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          marks={zoomMarks}
          onChange={(zoom) => setZoom(zoom)}
        />
      </div>
      
      <Button type={'primary'} loading={loading}
              style={{display: image === null || croppedImage !== null ? "none" : "block"}}
              onClick={showCroppedImage}>
        Save Image
      </Button>
    </div>
  );
};

export default RotateAndCrop;