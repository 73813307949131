import {Button} from 'antd'
import React from 'react'
import {connect} from 'react-redux'
import {Headline6, Subtitle2} from '../../general'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export const MapStatsCard = (props) => {
  return (<div className="map-stats-card flex-boy-column-space">
      <div className="flex-boy-row-space" style={{width: '100%'}}>
        <Headline6>California</Headline6>
        <FontAwesomeIcon size="1x" style={{marginBottom: "2px"}} icon={['fas', 'times']}/>
      </div>

      <div className="flex-boy-row-space" style={{width: '100%'}}>
        <Subtitle2 style={{
          flex: '1', textAlign: "left", margin: '0 10px 0 10px', fontWeight: '700', textTransform: 'capitalize'
        }}>Consultants</Subtitle2>
        <Subtitle2 style={{fontWeight: '700', color: 'gainsboro'}}>999</Subtitle2>
      </div>
      <div className="flex-boy-row-space" style={{width: '100%'}}>
        <Subtitle2 style={{
          flex: '1', textAlign: "left", margin: '0 10px 0 10px', fontWeight: '700', textTransform: 'capitalize'
        }}>Patients</Subtitle2>
        <Subtitle2 style={{fontWeight: '700', color: 'gainsboro'}}>9992</Subtitle2>
      </div>
      <div className="flex-boy-row-space" style={{width: '100%'}}>
        <Subtitle2 style={{
          flex: '1', textAlign: "left", margin: '0 10px 0 10px', fontWeight: '700', textTransform: 'capitalize'
        }}>Offices</Subtitle2>
        <Subtitle2 style={{fontWeight: '700', color: 'gainsboro'}}>99</Subtitle2>
      </div>
      <Button shape="round" type="primary">View Stats</Button>
    </div>)
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(MapStatsCard)
