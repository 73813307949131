/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Body2, Headline6, RegularButton, Subtitle1} from '../../components'
import HeaderTop from '../../components/HeaderTop'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Avatar, Breadcrumb, Dropdown, Image, Menu, Tabs} from 'antd';
import ConsultantSummary from './components/ConsultantSummary'
import ConsultantAvailabilty from './components/ConsultantAvailabilty'
import dayjs from 'dayjs'
import {useLocation, useNavigate} from "react-router-dom";
import {fetchConsultantDaetails, fetchConsultantshedule, fetchSpecificConsultant, getConsultantStateLicences, setConsultantNavs, updateConsultant} from '../../app/Reducers';
import {emptyFun, getHeaderLeftNav} from "../../common/helpers";
import ConsultantMileages from "./components/ConsultantMileages";
import {usePermissionCheck} from '../../hooks/usePermissionCheck';
import ConsultantAssignments from './components/ConsultantAssignments'
import ConsultantInfoPane from './components/ConsultantInfoPane'
import ConsultantOrders from './components/ConsultantOrders'


export const ConsultantPage = ({  consultant, navs, activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const can_view_consultant_trips = usePermissionCheck('view_consultant_trips', false, [])
  const can_view_availability_schedule = usePermissionCheck('view_availability_schedule', false, [])
  const can_deactivate_consultant = usePermissionCheck('deactivate_consultant', false, [])
  // is wcc if roles contain woundCareConsultant
  const [isWcc, setIsWcc] = useState(false);
  useEffect(() => {
    setIsWcc(['WoundCareConsultant','ClinicalSupervisor','ClinicalAdmin'].some(value => consultant?.roles?.includes(value)));
  }, [consultant]);

  const TabList = ["Summary", "User details", can_view_availability_schedule && "Availabilty", can_view_consultant_trips && isWcc && "Trips", isWcc && "Orders", isWcc && "Assigned Patients"].filter(Boolean)
  const dispatch = useDispatch()
  const { TabPane } = Tabs;
  const consultantId = Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1)) || activeUser?.consultantProfile.id
  //implement loading of patient data in case this is a navigation from the roue map
  useEffect(() => {
    //console.log({ consultantId, consultant })
    // if (consultantId !== consultant?.id && !isNaN(consultantId)) {
      dispatch(fetchSpecificConsultant(consultantId))
      dispatch(getConsultantStateLicences(consultantId))
    // }
    return emptyFun
  }, [])
  useEffect(() => {
    if (!consultant) {
      // let id = location.pathname.replace('/project-detail/', '')
      //     console.log(id)
      //     // getProjectDetail({ id, navigate })
      //     let record = consultants.find(item=> item.id === id)
      //     dispatch(selectConsultant({ navigate:null, record: record }))
      navigate(`/consultants`)
    } else {
      (async () => {
        dispatch(fetchConsultantshedule(consultant.id))
        if (can_view_availability_schedule) {
          dispatch(fetchConsultantDaetails(consultant))
        }
        
      })().catch(e => console.log(e))
      // await dispatch(fetchConsultantTimes(consultant))
    }
  }, [location])
  useEffect(() => {
    if (consultant) {
      dispatch(fetchSpecificConsultant(consultant.id))
    }
    return emptyFun
  }, [])
  const [isVisible, setIsVisible] = useState(false);
  let stati = ['active', 'in-active']
  
  async function handleMenuClick(e) {
    setIsVisible(prevState => !prevState)
    console.log(stati[e.key])
    let pl = { data: { status: stati[e.key] }, consultant_id: consultant.id }
    await dispatch(updateConsultant(pl))
  }
  
  const getIndicator = (text) => {
    return text === "active" ? <div className="dot-circle-active"/> : text === 'in-active' ?
      <div className="dot-circle-inactive"/> : <div className="dot-circle-inactive"/>
  }
  const menu = (<Menu onClick={handleMenuClick}>
    {stati.map((item, index) => <Menu.Item key={index}
                                           style={{ textTransform: 'capitalize' }}> {item.replace('-', '').replace(/_/g, ' ')}  </Menu.Item>)}
  </Menu>);
  const setActiveTab = async (tab) => {
    await dispatch(setConsultantNavs({ tab: tab }))
  }
  return (<>
    {consultant ? <>
      {/* <HeaderTop child={getHeaderLeftNav(navigate, 'Consultant Card')}/> */}
      <div id="patient-encounter-tabs" className=" bg-white flex items-start h-auto pt-4 w-full">
       
        
      
        <Tabs type="card" activeKey={navs.consultantNavs.tab} defaultActiveKey={navs.consultantNavs.tab} onChange={setActiveTab} tabBarStyle={{ marginBottom: 0 }} rootClassName={'w-full'}>
            {TabList.map(item => <TabPane key={item} tab={<p className='mb-0'>{item}</p>}>
            </TabPane>)}
          </Tabs>
        
        
        
      </div>
      <div className="full-page bg-gray-200" style={{ padding: "10px" }}>
        {navs.consultantNavs.tab === "Summary" ? <ConsultantSummary isWcc={isWcc} setActiveTab={setActiveTab} /> :
          navs.consultantNavs.tab === "Trips" ? <ConsultantMileages consultant={consultant}/> :
            navs.consultantNavs.tab === "Availabilty" ? <ConsultantAvailabilty/> :
                navs.consultantNavs.tab === "Orders" ? <ConsultantOrders/> :
                    navs.consultantNavs.tab === "Assigned Patients" ? <ConsultantAssignments/> :
                        navs.consultantNavs.tab === "User details" ? <ConsultantInfoPane  isWcc={isWcc}/> : null}
      </div>
    </> : null}
  </>)
}
const mapStateToProps = (state) => ({
  consultant: state.consultants.selectedConsultant,
  activeUser: state.auth.activeUser,
  consultants: state.consultants.consultants, navs: state.navs
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ConsultantPage)
