import {appLog, emptyFun, makeAutoCompleteOptions, roundToDp} from "../../../common/helpers";
import {errMsg, getToastId, updateToast} from "../../../app/Reducers/reducerUtils";
import {apiRequest} from "../../../app/Apis";
import * as Sentry from "@sentry/react";
import {toast} from 'react-toastify';


/** Updates list of selected biologic product to assessmentKits on every change
 * @param {string} value is the name of the selected product
 * @param {number} index is the index of the current product in the list of selected assessmentKits
 * @param setvalidationerrors
 * @param biologicsProducts
 * @param setAssessmentKits
 * @param assessmentKits
 * @param form
 * */
export const handleKitChangeUtil = async (value, index, setvalidationerrors, biologicsProducts, setAssessmentKits, assessmentKits, form) => {
  /** Suppress "no selected grafts" error since at least one has been selected*/
  setvalidationerrors(prevState => ({...prevState, biologic_products: false}))
  // console.log('handleKitChange:  ', value)
  /** @property kit - The current biologic product [kit] from the list of [biologicsProducts] from the backend*/
  let kit = biologicsProducts.find(item => `${item.company_name} ${item.product_name}` === value)
  if (!!kit && value.length > 0) {
    /** Update the UI and add the new product to assessmentKits list*/
    await setAssessmentKits(prevState => {
      prevState[index] = {product: value, biologic_kits: ['']}
      return [...prevState]
    })
    let formValues = {...form.getFieldsValue()}
    formValues.kits = assessmentKits.map(item => ({...(item.product && {product: item.product}), ...(item.biologic_kits && {biologic_kits: item.biologic_kits})}))
    form.setFieldsValue(formValues)
  } else if (!value) {
    /** Reset the UI if this was product deletion from assessmentKits list*/
    await setAssessmentKits(prevState => {
      prevState[index] = {biologic_kits: ['']}
      return [...prevState]
    })
    let formValues = {...form.getFieldsValue()}
    formValues.kits = assessmentKits.map(item => ({...(item.product && {product: item.product}), ...(item.biologic_kits && {biologic_kits: item.biologic_kits})}))
    form.setFieldsValue(formValues)
  }
}
/** Updates list of selected graft sizes to assessmentKits under their respective products on every change
 * @param {string} value is the name of the selected graft size e.g 4*4
 * @param {number} productIndex is the index of the current product in the list of selected assessmentKits
 * @param {number} kitIndex is the index of the current graft size in the list under their respective biologic product
 * @param setvalidationerrors
 * @param biologicsProducts
 * @param assessmentKits
 * @param setAssessmentKits
 * @param form
 * */
export const handleKitSizeChangeUtil = async (value, productIndex, kitIndex, setvalidationerrors, biologicsProducts, assessmentKits, setAssessmentKits, form) => {
  // console.log('handleKitSizeChage: ', value)
  let ocr_data = [{tissue_id: undefined, serial_number: undefined}]
  //console.log('ocr_data ', ocr_data)
  /** Suppress "no selected grafts" error message since at least one has been selected.*/
  setvalidationerrors(prevState => ({...prevState, biologic_products: false}))
  /** @property {Object} kit - The current biologic product from the list of [biologicsProducts] from the backend*/
  let kit = biologicsProducts.find(item => `${item.company_name} ${item.product_name}` === assessmentKits[productIndex].product)
  /** @property {Object} biokit - The current graft from the list of graft sizes of the current biologic product*/
  let biokit = kit.kits.find(item => value === item.size)
  let kitId = biokit.id
  // console.log('handleKitSizeChage: ', biokit)
  /** Add the new graft size to assessmentKits list under the current biologic product*/
  let biologic_kits = (assessmentKits[productIndex] && assessmentKits[productIndex].biologic_kits) ? assessmentKits[productIndex].biologic_kits : []
  biologic_kits[kitIndex] = biologic_kits[kitIndex] ? {...biologic_kits[kitIndex], id: kitId, size: value, area: biokit.area, quantity: 1, ocr_data} :
    {id: kitId, size: value, area: biokit.area, quantity: 1, ocr_data}
  let newAssessmentKits=[...assessmentKits]
  newAssessmentKits[productIndex] = {...assessmentKits[productIndex], biologic_kits}
  await setAssessmentKits(newAssessmentKits)
  
  let formValues = {...form.getFieldsValue()}
  formValues.kits[productIndex].biologic_kits[kitIndex].ocr_data = ocr_data
  form.setFieldsValue(formValues)
  form.setFieldValue('kits',newAssessmentKits)
  // console.log(assessmentKits)
  // console.log({ formValues })
}

export const getManualWoundimentions = async (annotation, fileList, setFileList, assessmentDetails, setwoundAnnotation, setMaxArea, form) => {
  const toastId = getToastId("Generating Wound Dimensions")
  let formData = new FormData();
  formData.append("annotation", JSON.stringify(annotation))
  formData.append("app_id", 12345)
  formData.append("image_id", fileList.length ? fileList[0].image_id : assessmentDetails?.image_id)
  try {
    const response = await apiRequest.post("/wound-measurements-annotated", formData)
    console.log({ getManualWoundimentions: response })
    let measurement = response.data.data
    if (measurement.is_successfull) {
      if (response && response.data.data) {
        toast.update(toastId, { render: "Wound Dimensions Generated", type: toast.TYPE.SUCCESS, autoClose: 5000 });
        let original_url = fileList[0]?.original_image_url || assessmentDetails?.original_image?.url
        setFileList([{
          uid: 1,
          status: 'done',
          url: response.data.data.uploaded_image || response.data.data.original_image_url,
          thumbUrl: response.data.data.uploaded_image || response.data.data.original_image_url,
          masked_image_id: response.data.data.masked_image_id,
          image_id: response.data.data.original_image_id,
          original_image_url: original_url
        }])
        setwoundAnnotation(false)
        let area = parseFloat(response.data.data.wound.area)
        if (!!setMaxArea) {
          setMaxArea(roundToDp(area, 2))
        }
        form.setFieldsValue({
          width: response.data.data.wound.width, length: response.data.data.wound.length, area: response.data.data.wound.area,
          necrotic_tissue: response.data.data.tissue_percentage.black ? response.data.data.tissue_percentage.black : 0,
          volume: (area * parseFloat(form.getFieldValue('depth'))).toFixed(2),
          granulation_tissue: response.data.data.tissue_percentage.red ? response.data.data.tissue_percentage.red : 0,
          fibrous_tissue: response.data.data.tissue_percentage.yellow ? response.data.data.tissue_percentage.yellow : 0,
          size: response.data.data.wound.size
        });
        updateToast(toastId, "Generate wound dimensions successfully", true)
        // setDimensions({...data.data.wound})
      } else {
        updateToast(toastId, "Unable to generate wound dimensions please manually annotate the wound or fill in the wound measurements manually", false)
        setwoundAnnotation(false)
      }
    } else {
      updateToast(toastId, "Unable to generate wound dimensions please try again or fill in the wound measurements manually", false)
      setwoundAnnotation(false)
    }
  } catch (err) {
    updateToast(toastId, err.response?.data.message || "Server Error", false)
  }
  
}

/** Updates list of selected graft sizes to assessmentKits under their respective products on every quantity change
 * @param e is the onChange event
 * @param {number} productIndex is the index of the current product in the list of selected assessmentKits
 * @param {number} kitIndex is the index of the current graft size in the list under their respective biologic product
 * @param assessmentKits
 * @param setAssessmentKits
 * @param syncKits
 * */
export const onQtyChangeUtil = async (e, productIndex, kitIndex, assessmentKits, setAssessmentKits) => {
  // console.log('onQtyChange: ', e.target.value)
  let biologic_kits = (assessmentKits[productIndex] && assessmentKits[productIndex].biologic_kits) ? assessmentKits[productIndex].biologic_kits : []
  if (e.target.value) {
    biologic_kits[kitIndex] = biologic_kits[kitIndex] ? {...biologic_kits[kitIndex], quantity: Number(e.target.value)} : {quantity: Number(e.target.value)}
  } else {
    biologic_kits[kitIndex] = biologic_kits[kitIndex] ? {...biologic_kits[kitIndex], quantity: undefined} : {quantity: undefined}
  }
  await setAssessmentKits(prevState => {
    prevState[productIndex] = {...assessmentKits[productIndex], biologic_kits}
    return [...prevState]
  })
}
/** Updates selected graft sizes to assessmentKits under their respective products on every batch ID change
 * @param ocr_data
 * @param {number} productIndex is the index of the current product in the list of selected assessmentKits
 * @param {number} kitIndex is the index of the current graft size in the list under their respective biologic product
 * @param assessmentKits
 * @param setAssessmentKits
 * @param ocrIndex
 * */
export const onTissueAndSerialNoChange = async (ocr_data, productIndex, kitIndex, assessmentKits, setAssessmentKits, ocrIndex, form) => {
  // console.log('onQtyChange: ', e.target.value)
  //console.log('ocr_data: ', ocr_data, ' kitIndex: ', kitIndex, ' ocrIndex: ', ocrIndex)
  // let ocr_data = form.getFieldsValue().kits[index].biologic_kits[kitIndex].ocr_data
  //console.log('tissue_id onChange ocr_data: ', ocr_data)
  let biologic_kits = [...assessmentKits[productIndex].biologic_kits]
  //console.log('biologic_kits2: ', biologic_kits)
  biologic_kits[kitIndex].ocr_data = ocr_data
  //console.log('biologic_kits3: ', biologic_kits)
  let assessmentKits_ = [...assessmentKits]
  assessmentKits_[productIndex].biologic_kits = biologic_kits
  await setAssessmentKits(assessmentKits_)
  let formValues = {...form.getFieldsValue()}
  formValues.kits[productIndex].biologic_kits[kitIndex].ocr_data = ocr_data
  form.setFieldsValue(formValues)
  // syncKits()
}

export const getGraftDetails = async (options, index, kitIndex, setUploadLoading, assessmentKits, setAssessmentKits, form) => {
  const {onSuccess, onError, file, onProgress} = options;
  let formData = new FormData();
  const toastId = getToastId('Getting graft details')
  formData.append("biologic_image", file);
  try {
    setUploadLoading([index, kitIndex])
    const {data} = await apiRequest.post("/biologics-ocr-recognition", formData)
    //console.log('Bio OCR:  ', data)
    if (!data?.data || !data?.data[0]) {
      updateToast(toastId, 'Serial No. and tissue ID could not be established', false)
    } else {
      let ocr_data = data.data.map(item => ({tissue_id: item.tissue_id, serial_number: item.serial_number}))
      // await updateTissueAndSerialID(ocr_data, index, kitIndex, assessmentKits, setAssessmentKits, syncKits, form)
      await onTissueAndSerialNoChange(ocr_data, index, kitIndex, assessmentKits, setAssessmentKits, -1, form).catch(appLog);
      updateToast(toastId, 'Graft OCR details loaded', true)
    }
  } catch (e) {
    Sentry.captureException(e);
    //console.log(e)
    updateToast(toastId, errMsg(e, false, null, 'Unable to perform Biologic OCR recognition'), false)
  }
  setUploadLoading([-1, -1])
}
/** Determine if all kits under a biologic product have been selected by the physician in order to hide the Add button.
 *  This is also used to determine the list of kits not yet selected to appear in the dropdown
 *  @param {Array} bioKits is the list of graft sizes for the current biologic product
 *   @param {number} index is the position of the current biologic product in the selected assessmentKits
 * @param assessmentKits
 *  */
export const canAddKitsUtil = (bioKits, index, assessmentKits) => {
  /** Graft sizes already added*/
  let selectedKits = assessmentKits[index] && assessmentKits[index].biologic_kits ? assessmentKits[index].biologic_kits.map(item => item.size) : []
  /** Graft sizes that are not yet selected and should appear in the graft sizes dropdown for the current Biologic product*/
  let selectables = bioKits.filter(item => !selectedKits.includes(item.size))
  return selectables.length > 0
}
export const calculateKitAreaUtil = (maxArea, assessmentKits, setKitAmountMessage, setMaxKitArea,biologicsProducts,setMaxUnitAreaWarnings,assessment) => {
  //console.log('calculateKitAreaUtil 1')
  if (assessmentKits.length === 0) {
    setKitAmountMessage(null)
  }
  //console.log('calculateKitAreaUtil 2')
  /** Calculating new area total every time content of selected kits change*/
  if (!isNaN(maxArea) && !!assessmentKits[0] && !!assessmentKits[0].biologic_kits && !!assessmentKits[0].biologic_kits[0] && !!assessmentKits[0].biologic_kits[0].quantity) {
    let MaxKitArea = 0
    let firstGraftWithoutAreaFound = false
    let maxUnitAreaWarnings=[]
    for (const item of assessmentKits) {
      let selectedBiologicProduct = biologicsProducts.find(product => `${product.company_name} ${product.product_name}` === item.product)
      // console.log({max_unit_area:Number(selectedBiologicProduct.max_unit_area),item})
      /** kits whose quantities are already set in the form*/
      let kitsWithQuantity = item.biologic_kits.filter(item => !!item.quantity)
      /** @property MaxKitArea is total area of that can be covered by already selected grafts*/
      MaxKitArea += kitsWithQuantity.reduce((previousValue, currentItem) => {
        /** @property sizeToAdd is determined based on whether the current kit has known area from the backend.
         *  If not, e.g in the case of liquid grafts, max area of the wound is
         *  automatically added and final total is assumed to be sufficient as selected by physician. Any other graft with known area is summed anyway*/
        let sizeToAdd = currentItem.area !== 0 ? (currentItem.area * currentItem.quantity) : !firstGraftWithoutAreaFound ? maxArea : 0
        return previousValue + sizeToAdd;
      }, 0)
      if (MaxKitArea>Number(selectedBiologicProduct.max_unit_area)) {
        maxUnitAreaWarnings.push(`${item.product} exceeds Max Unit Area limit of ${selectedBiologicProduct.max_unit_area}cm² by ${(MaxKitArea-Number(selectedBiologicProduct.max_unit_area))}cm²`)
      }
      //todo: Restrict combination of 'None' with other values and simplify the condition below
      if (((assessment?.exposed_structures?.includes('None') && assessment?.exposed_structures.length > 1) || (!assessment?.exposed_structures?.includes('None') && assessment?.exposed_structures?.[0])) && (selectedBiologicProduct?.used_on_exposed_structure === 'no')) {
        maxUnitAreaWarnings.push(`${selectedBiologicProduct.company_name} ${selectedBiologicProduct.product_name} should not be used on exposed structures`)
      }
    }
    if (isNaN(MaxKitArea)) {
      setKitAmountMessage('')//More kits can be added
      return emptyFun
    }
    setMaxKitArea(roundToDp(MaxKitArea, 2))
    setMaxUnitAreaWarnings(maxUnitAreaWarnings)
    // console.log('MaxKitArea:  ', MaxKitArea)
    // console.log('MaxArea:  ', maxArea)
    /** Graft size warning message is set only if @property MaxKitArea already exceeds wound area i.e @property maxArea*/
    if (maxArea > MaxKitArea) {
      setKitAmountMessage('Selected graft size may not be sufficient.')//More kits can be added
    } else if (maxArea < MaxKitArea) {
      setKitAmountMessage('')
    } else {
      setKitAmountMessage('')
    }
  } else {
    if (!assessmentKits[0]) {
      setMaxKitArea(0)
    }
    setKitAmountMessage('')
  }
}

/** Generate list of graft sizes to be selected from each biologic product. The inner list where quantity is also keyed by physician
 * @param biologicsProducts
 * @param assessmentKits
 * @param {number} index is the position of the current biologic product in the list of biologicsProducts*/
export const getBiologicKitsUtil = (index, biologicsProducts, assessmentKits) => {
  let product = biologicsProducts.find(item => `${item.company_name} ${item.product_name}` === assessmentKits[index].product)
  return !!product ? product.kits : null
}
export const suggestGrafts = async (maxArea, biologicsProducts, setAssessmentKits, form, selectedProduct,setRecommending) => {
  // let recommendedGrafts = recommendGraftUtil(maxArea, biologicsProducts)
  let selectedBiologicProduct = biologicsProducts.find(product => `${product.company_name} ${product.product_name}` === selectedProduct)
  // console.log({selectedBiologicProduct})
  setRecommending(true)
  const {data} = await apiRequest.post(`/biologic-product-recommendation/${selectedBiologicProduct.id}`, {"wound_area": maxArea})
  // console.log({data})
  let recommendation = [{product: selectedProduct, biologic_kits: data.data.recommended_kits.map(item => ({id: item.id, quantity: 1, size: item.size, area: item.area}))}]
  // console.log({recommendation})
  await setAssessmentKits(recommendation)
  let formValues = {...form.getFieldsValue()}
  formValues.kits = recommendation
  form.setFieldsValue(formValues)
  setRecommending(false)
  
}
export const fetchAsssessmentForm = (setassessmentForm, procedure, procedures) => {
  let procedure_ = procedures.find(item => item.name === procedure)
  console.log('procedure_:  ', procedure_)
  setassessmentForm(procedure_?.form_inputs)
}


export const onImageEditedUtil = async (file, setFileList, form, setMaxArea, setRotateImage, setdisplayAnnotation, setwoundAnnotation,setUploadingRotatedImage) => {
  let formData = new FormData();
  // add one or more of your files in FormData
  // again, the original file is located at the `originFileObj` key
  const toastId = toast("Generating Wound Dimensions", {type: "info", autoClose: false});
  formData.append("wound_image", file);
  appLog("wound_image: ", file);
  await setUploadingRotatedImage(true)
  const {data} = await apiRequest.post("/wound-measurements", formData).catch(function (err) {
    toast.update(toastId, {render: err.response?.data.message || "Server Error", type: toast.TYPE.ERROR, autoClose: 5000});
    setFileList([])
  })
  await setUploadingRotatedImage(false)
  if (data && data.data && data.data.is_successfull) {
    toast.update(toastId, {render: "Wound Dimensions Generated", type: toast.TYPE.SUCCESS, autoClose: 5000});
    setFileList([{
      uid: 1,
      status: 'done',
      url: data.data.uploaded_image || data.data.original_image_url,
      thumbUrl: data.data.uploaded_image || data.data.original_image_url,
      masked_image_id: data.data.masked_image_id,
      image_id: data.data.original_image_id,
      original_image_url: data.data.original_image_url
    }])
    
    setdisplayAnnotation(true)
    let area = parseFloat(data.data.wound.area)
    setMaxArea(roundToDp(area, 2))
    form.setFieldsValue({
      width: data.data.wound.width, length: data.data.wound.length, area: data.data.wound.area,
      necrotic_tissue: data.data.tissue_percentage.black ? data.data.tissue_percentage.black : 0,
      volume: area * parseFloat(form.getFieldValue('depth')),
      granulation_tissue: data.data.tissue_percentage.red ? data.data.tissue_percentage.red : 0,
      fibrous_tissue: data.data.tissue_percentage.yellow ? data.data.tissue_percentage.yellow : 0
    });
    setRotateImage(false)
    // setDimensions({...data.data.wound})
  } else {
    toast.update(toastId, {
      render: "Unable to generate wound dimensions please manualy annotate the wound or fill in the wound measurements manualy",
      type: toast.TYPE.ERROR,
      autoClose: 6000
    });
    setFileList([{
      uid: 1, status: 'done', url: data.data.uploaded_image || data.data.original_image_url, thumbUrl: data.data.uploaded_image || data.data.original_image_url,
      masked_image_id: data.data.masked_image_id, image_id: data.data.original_image_id, original_image_url: data.data.original_image_url
    }])
    
    setdisplayAnnotation(true)
    let area = parseFloat(data.data.wound.area)
    setMaxArea(roundToDp(area, 2))
    form.setFieldsValue({
      width: data.data.wound.width, length: data.data.wound.length, area: data.data.wound.area,
      necrotic_tissue: data.data.tissue_percentage.black ? data.data.tissue_percentage.black : 0,
      volume: area * parseFloat(form.getFieldValue('depth')),
      granulation_tissue: data.data.tissue_percentage.red ? data.data.tissue_percentage.red : 0,
      fibrous_tissue: data.data.tissue_percentage.yellow ? data.data.tissue_percentage.yellow : 0
    });
    setwoundAnnotation(true)
    setRotateImage(false)
  }
}