/* eslint-disable no-unused-vars */
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {apiRequest} from "../Apis"
import {mapState} from "./initialState";
import {errMsg, toastUp} from "./reducerUtils";
import querystring from "querystring";
import moment from 'moment';

const initialState = {...mapState};

// export const fetchMapSchedule = createAsyncThunk('/appointment-schedules', async (params, thunkAPI) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await apiRequest.get(`/appointments?scheduledFor=${params}`)
//       resolve(response.data.data)
//     } catch (e) {
//       // toastUp(errMsg(e, true, 'schedule'), false)
//       reject(e)
//     }
//   })
// })
// patients
export const fetchAllPatients = createAsyncThunk('map/fetchAllPatients', async (regionName, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/map-data?resource_type=patient`)
      let data = response.data.data.filter(item => item.lat && item.lng).map(item => ({ ...item, latlng: [item.lng, item.lat] }))
      resolve({ data, count: response.data.data.length })
    } catch (e) {
      // toastUp(errMsg(e, true, 'patients'), false)
      reject(e)
    }
  })
})

// ferch asset-locations
export const fetchAllAssetLocations = createAsyncThunk('map/fetchAllAssetLocations', async (regionName, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/asset-locations`)
      let data = response.data.data.filter(item => item.lat && item.lng).map(item => ({ ...item, latlng: [item.lng, item.lat] }))
      resolve({ data, count: response.data.data.length })
    } catch (e) {
      // toastUp(errMsg(e, true, 'patients'), false)
      reject(e)
    }
  })
})







// fetch macs
export const fetchAllMacs = createAsyncThunk('map/fetchAllMacs', async (regionName, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/macs`)
      let data = response.data.data
      resolve({ data, count: response.data.data.length })
    } catch (e) {
      // toastUp(errMsg(e, true, 'patients'), false)
      reject(e)
    }
  })
})


// fetch marketing-regions
export const fetchAllMarketingRegions = createAsyncThunk('map/fetchAllMarketingRegions', async (regionName, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/market-presence`)
      let data = response.data.data
      resolve({ data, count: response.data.data.length })
    } catch (e) {
      // toastUp(errMsg(e, true, 'patients'), false)
      reject(e)
    }
  })
})

// fetch state-boundaries
export const fetchAllStateBoundaries = createAsyncThunk('map/fetchAllStateBoundaries', async (regionName, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/state-boundaries`)
      let data = response.data.data
      resolve({ data, count: response.data.data.length })
    } catch (e) {
      // toastUp(errMsg(e, true, 'patients'), false)
      reject(e)
    }
  })
})






export const fetchRegionPatients = createAsyncThunk('map/fetchRegionPatients', async (regionName, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/patients?region=${regionName}`)
      let data = response.data.data.filter(item => item.lat && item.lng).map(item => ({...item, latlng: [item.lng, item.lat]}))
      resolve({data, count: response.data.meta.total})
    } catch (e) {
      // toastUp(errMsg(e, true, 'patients'), false)
      reject(e)
    }
  })
})
// consultants
export const fetchRegionConsultants = createAsyncThunk('map/fetchRegionConsultants', async (regionName, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/consultants?region=${regionName}`)
      let data = response.data.data.filter(item => item.lat && item.lng).map(item => ({...item, latlng: [item.lng, item.lat]}))
      resolve({data, count: response.data.meta.total})
    } catch (e) {
      // toastUp(errMsg(e, true, 'consultants'), false)
      reject(e)
    }
  })
})
export const fetchAllConsultants = createAsyncThunk('map/fetchAllConsultants', async (regionName, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/map-data?resource_type=consultant`)
      let data = response.data.data.filter(item => item.lat && item.lng).map(item => ({...item, latlng: [item.lng, item.lat]}))
      resolve({data, count: response.data.data.length})
    } catch (e) {
      // toastUp(errMsg(e, true, 'consultants'), false)
      reject(e)
    }
  })
})
// facilities
export const fetchRegionFacilities = createAsyncThunk('map/fetchRegionFacilities', async (regionName, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/medical-facilities?region=${regionName}`)
      let data = response.data.data.filter(item => item.lat && item.lng).map(item => ({...item, latlng: [item.lng, item.lat]}))
      resolve({data, count: response.data.meta.total})
    } catch (e) {
      // toastUp(errMsg(e, true, 'facilities'), false)
      reject(e)
    }
  })
})
export const fetchAllFacilities = createAsyncThunk('map/fetchAllFacilities', async (thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/map-data?resource_type=medical_facility`)
      let data = response.data.data.filter(item => item.lat && item.lng).map(item => ({...item, latlng: [item.lng, item.lat]}))
      resolve({data, count: response.data.data.length})
    } catch (e) {
      // toastUp(errMsg(e, true, 'facilities'), false)
      reject(e)
    }
  })
})
// consultant schedule
export const fetchConsultantshedule = createAsyncThunk('/consultantSchedule', async (id, thunkApi) => {
  return new Promise(async (resolve, reject) => {
    try {
      let queryString = `consultant=${id}&scheduledFor=${moment().format('YYYY-MM-DD')}`
      const response = await apiRequest.get(`/appointments?${queryString}`)
      let data = response.data.data.filter(item => item.patient.lat).map(item => {
        item.latlng = [item.patient.lng, item.patient.lat]
        item.consultant.latlng = [item.consultant.lng, item.consultant.lat]
        return item
      })
      resolve(data)
    } catch (e) {
      // toastUp(errMsg(e, true, 'consultant schedule'), false)
      reject(e)
    }
  })
})
// consultant schedule
export const fetchAllConsultantTrips = createAsyncThunk('fetchAllConsultantTrips', async (params, thunkApi) => {
  return new Promise(async (resolve, reject) => {
    try {
      let queryString = !!params ? '?' + querystring.stringify(params) : ""
      const response = await apiRequest.get(`/consultant-trips${queryString}`)
      let data = response.data.data
      //console.log('consultant-trips: ', data)
      resolve(data)
    } catch (e) {
      toastUp(errMsg(e, true, 'consultant trips'), false)
      reject(e)
    }
  })
})
export const mapSlice = createSlice({
  name: 'map', initialState, // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setVisibilty: (state, action) => {
      state[action.payload.key].visible = action.payload.value;
    },
    setTripConsultant: (state, action) => {
      state.tripConsultant = action.payload;
    }, setVisibleSchedule: (state, action) => {
      state.visibleSchedule = action.payload
    }, selectConslultant: (state, action) => {
      state.selectedConslultant = action.payload
    }, pinConsultant: (state, action) => {
      state.pinnedConsultants.push(action.payload)
    }, unpinConsultant: (state, action) => {
      state.pinnedConsultants = state.pinnedConsultants.filter(item => item.id !== action.payload.id)
    }, regionsAdded: (state, action) => {
      state.regions = [...state.regions, action.payload]
    }, setDistance: (state, action) => {
      state.consultantDistance = action.payload
    },
    doResetMapSlice: (state) => {
      Object.keys(initialState).map((key) => (state[key] = initialState[key]));
    },
    setzonalSelectionMenuVisible: (state, action) => {
      state.zonalSelectionMenuVisible = action.payload
    }
  }, extraReducers: (builder)=>{
     builder.addCase(fetchRegionPatients.fulfilled, (state, action) => {
      state.patients.data = action.payload.data
      state.patients.count = action.payload.count
    }).addCase(fetchRegionConsultants.fulfilled, (state, action) => {
      state.consultants.data = action.payload.data
      state.consultants.count = action.payload.count
    }).addCase(fetchAllConsultants.fulfilled, (state, action) => {
      state.consultants.data = action.payload.data
      state.consultants.count = action.payload.count
    }).addCase(fetchAllPatients.fulfilled, (state, action) => {
      state.patients.data = action.payload.data
      state.patients.count = action.payload.count
    }).addCase(fetchRegionFacilities.fulfilled, (state, action) => {
      state.facilities.data = action.payload.data
      state.facilities.count = action.payload.count
    }).addCase(fetchAllFacilities.fulfilled, (state, action) => {
      state.facilities.data = action.payload.data
      state.facilities.count = action.payload.count
    }).addCase(fetchConsultantshedule.fulfilled, (state, action) => {
      state.consultantSchedule = [...action.payload]
    }).addCase(fetchAllConsultantTrips.fulfilled, (state, action) => {
      state.consultantTrips = [...action.payload]
    }).addCase(fetchAllAssetLocations.fulfilled, (state, action) => {
      const groupedData = action.payload.data.reduce((acc, curr) => {
        if (!acc[curr.asset_type]) {
          acc[curr.asset_type] = [];
        }
        acc[curr.asset_type].push(curr);
        return acc;
      }, {});

      const assetTypes = Object.keys(groupedData);
      console.log('assetTypes: ', assetTypes, groupedData);
      assetTypes.forEach((type) => {
        const count = groupedData[type].length;
        if (!state[`${type}s`]) {
          state[`${type}s`] = { data: [], count: 0, visible: true };
        } 
        state[`${type}s`].data = groupedData[type];
        state[`${type}s`].count = count;
      });
    }).addCase(fetchAllMacs.fulfilled, (state, action) => {
      state.macs.data = action.payload.data
      state.macs.count = action.payload.count
    }).addCase(fetchAllMarketingRegions.fulfilled, (state, action) => {
      state.sales_territories.data = action.payload.data
      state.sales_territories.count = action.payload.count
    }).addCase(fetchAllStateBoundaries.fulfilled, (state, action) => {
      // group payload.data by reagional_boundary.name add color code to each group
      const colors = {
        "Western": "#FF0000",
        "Eastern": "#00ffff",
        "Midwest": "#0000FF"}
      // loop through action.payload.data and add color to each item based on region_boundary.name
      let data = action.payload.data.map(item => {
        item.color_code = colors[item.region_boundary.name]
        return item
      })
      state.sales_regions.data = data
      state.sales_regions.count = action.payload.data.length

    })
     
  }
});
export const selectAllRegions = state => state.regions
export const selectMap = (state) => state.map
export const { doResetMapSlice, setVisibilty, unpinConsultant, pinConsultant, setVisibleSchedule, selectConslultant, setDistance, setTripConsultant, setzonalSelectionMenuVisible } = mapSlice.actions;
export default mapSlice.reducer;
