import React, {useEffect, useState} from 'react';
import {Form, Input, Select} from "antd";
import countries from 'world_countries_lists/data/countries/en/countries.json';
import parsePhoneNumber, {AsYouType, isValidPhoneNumber} from 'libphonenumber-js'
import {emptyFun} from '../../common/helpers';
import {defaultAreas} from '../../common/lists';

const getNation = (phone_code) => {
  if (!phone_code) {
    return {emoji: "🇺🇸", phoneCode: "1", short: "US", label: "🇺🇸 US", country: "United States of America", value: "1"}
  }
  let nation = defaultAreas.find(item => item.phoneCode === Number(phone_code));
  return !!nation ? {...nation, phoneCode: `${nation.phoneCode}`, label: `${nation.emoji} ${nation.short}`, value: `${nation.phoneCode}`} :
    {emoji: "🇺🇸", phoneCode: "1", short: "US", label: "🇺🇸 US", country: "United States of America", value: "1"}
}
const AppPhoneInput = ({name, phoneValue, setPhoneValue, formItemProps, setSelectPopupOpen, label}) => {
  const [nations, setNations] = useState([]);
  let phone = !!phoneValue ? phoneValue[`defaultValue-${name}`] : undefined
  //console.log({phone})
  let phoneNumber;
  let country
  if (!phone) {
    country = {emoji: "🇺🇸", phoneCode: "1", short: "US", label: "🇺🇸 US", country: "United States of America", value: "1"}
  } else {
    phoneNumber = parsePhoneNumber(phone);
    if (!phoneNumber) {
      country = {emoji: "🇺🇸", phoneCode: "1", short: "US", label: "🇺🇸 US", country: "United States of America", value: "1"}
    } else {
      country = getNation(phoneNumber?.countryCallingCode)
      //console.log({country})
    }
  }
  
  const [nation, setNation] = useState(country);
  
  const handleNationChange = (e, option) => setNation(option)
  useEffect(() => {
    let newArr = defaultAreas.map(country => {
      let country_ = countries.find(item => item.alpha2 === country.short.toLowerCase())
      return {...country, phoneCode: `${country.phoneCode}`, label: `${country.emoji} ${country.short}`, country: !!country_ ? country_.name : undefined, value: `${country.phoneCode}`}
    }).filter(item => !!item.country && item.allowed === true).sort((a, b) => a.short > b.short ? 1 : a.short < b.short ? -1 : 0)
    setNations(newArr)
    return emptyFun
  }, [])
  const selectBefore = (
    <Select options={nations} placeholder={'Select country'}
            defaultValue={nation.value}
            value={nation.value}
            style={{width: '90px'}}
            onChange={handleNationChange}
            onDropdownVisibleChange={open => {
              //This is used in popups like in filters, to avoid closing the base popup form
              if (!!setSelectPopupOpen) setSelectPopupOpen(open);
            }}/>
  );
  
  return !!nations[0] &&
    <Form.Item className={'m-0 mb-2 w-full'}
      rules={[{
        required: formItemProps.required ? formItemProps.required : true, message: 'Invalid Phone number or format',
         validator: async (rule, value) => {
          try {
            if (!value && formItemProps.required === false) {
              await setPhoneValue({...phoneValue, [name]: undefined})
              return Promise.resolve()
            }
            // console.log({ value })
            const asYouType = new AsYouType(nation.short)
            asYouType.input(value)
            let phoneIn = asYouType.getNumber().number
            if (isValidPhoneNumber(phoneIn)) {
              console.log('Valid phone number ✅: ', phoneIn)
              // appLog({'TTTTT:':phoneValue})
              await setPhoneValue({...phoneValue, [name]: phoneIn})
              return Promise.resolve()
            } else {
              console.log('Not a valid phone number ❌')
              await setPhoneValue({...phoneValue, [name]: undefined})
              if (!formItemProps.required) {
                await setPhoneValue({...phoneValue, [name]: undefined})
                //If phone is optional, make sure it is empty instead of sending invalid phone number
                return Promise.reject('Invalid Phone number, make empty if optional')
              }
              return Promise.reject('Invalid Phone number or format')
            }
          } catch (e) {
            // console.log(e)
            await setPhoneValue({...phoneValue, [name]: undefined})
            if (!formItemProps.required) {
              await setPhoneValue({...phoneValue, [name]: undefined})
              return Promise.resolve()
            }
            return Promise.reject('Invalid Phone number or format')
          }
        }
      }]}
      style={{width: '100%'}} name={name} label={'Phone Number'} {...formItemProps}>
      <Input autoComplete={'off'} addonBefore={selectBefore} placeholder={'##########'} minLength={10} type={'text'} maxLength={10}/>
    </Form.Item>
};

export default AppPhoneInput;
