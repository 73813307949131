import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Document, Page } from 'react-pdf';
import { Form, Spin, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { toast } from 'react-toastify';
import { apiRequest } from '../../../../app/Apis';
import _ from 'lodash';
import { Headline6, Subtitle1 } from '../../../../components';
import { errMsg, getToastId, updateToast } from '../../../../app/Reducers/reducerUtils';
import dayjs from 'dayjs';


export const LabReview = ({ report, assessment, closeModal, setisExecuting, loadEncounters, masterList }) => {
    const dispatch = useDispatch()
    const REVIEW_RESULTS_MAPPING = {
        "Yes, confirmed by PCR and Clinically Significant": {
            infection: "Yes, confirmed by PCR and Clinically Significant",
            patient_symptoms: assessment.patient_symptoms,
            clinical_signs_of_infection: assessment.clinical_signs_of_infection,

            visit_summary: `
PCR Lab Result is clinically significant: Infection control implemented. Wound PCR Lab results were reviewed and found to be clinically significant, and infection control measures will be implemented. These include topical antibiotic therapy, regular monitoring, patient education, and possible follow-up culture or consultation with an infectious disease specialist. ${dayjs().format('MM/DD/YYYY')}

PCR Collected: ${dayjs(assessment?.lab_tests[0]?.intake_date).format('MM/DD/YYYY')}

PCR Results: ${dayjs().format('MM/DD/YYYY')}

PCR Reviewed: ${dayjs().format('MM/DD/YYYY')}


*PCR Results findings:


*RX:
` },
        "Yes, confirmed by PCR and not Clinically Significant": {
            infection: "Yes, confirmed by PCR and not Clinically Significant",
            patient_symptoms: assessment.patient_symptoms,
            clinical_signs_of_infection: assessment.clinical_signs_of_infection,
            visit_summary: "" 
        },
        "No, confirmed by PCR": {
            infection: "No, confirmed by PCR",
            patient_symptoms: assessment.patient_symptoms,
            clinical_signs_of_infection: assessment.clinical_signs_of_infection,
            visit_summary: "" 
        },
        "Resolved by PCR": {
            infection: "Resolved by PCR",
            patient_symptoms: ['none'],
            clinical_signs_of_infection: ["none"], 
            visit_summary: "Infection Resolved" 
        },
        "Resolved by Signs and Symptoms": {
            infection: "Resolved by Signs and Symptoms",
            patient_symptoms: ['none'],
            clinical_signs_of_infection: ["none"],
            visit_summary: "Infection Resolved"
        }
    };



    const { Option } = Select;
    const [numPages, setNumPages] = useState(null);
    const onDocumentLoadSuccess = ({ numPages }) => {
        console.log(numPages)
        setNumPages(numPages ?? 0);
    };
    //visit summary
    const [visitSummary, setVisitSummary] = useState('')

    const [pdfData, setPdfData] = useState(null);

    const [observations , setObservations] = useState({})

    useEffect(() => {
        axios.get(report, { responseType: 'arraybuffer' })
            .then(res => {
                setPdfData(res.data);
            });
        
        return

    }, [report]);

const onFinish = async (values) => {
    let payload = {
        review_results: values.review_results,
        observations: _.omit(observations, ['visit_summary']),
        visit_summary: visitSummary
    }
    console.log(payload)
    setisExecuting(true)

    const toastId = getToastId("Adding Review")
    try {
        const promises = assessment.lab_tests.map(item =>
            apiRequest.post(`assessments/${assessment.id}/lab-tests/${item.id}/review`, payload)
            .then(result => ({ status: 'ok', result }))
            .catch(error => ({ status: 'error', error }))
        );

        const results = await Promise.all(promises);

        // Track the success of all promises
        let allSuccessful = true;
        results.forEach((res, index) => {
            if (res.status === 'error') {
                allSuccessful = false;
                console.error(`Error for item ${index}:`, res.error);
                updateToast(toastId, errMsg(res.error, false, null, 'Unable to add review'), false);
            }
        });

        // Only if all promises were successful
        if (allSuccessful) {
            toast.update(toastId, { render: "Review Added", type: toast.TYPE.SUCCESS, autoClose: 5000 });
           
        }
    } catch (error) {
        // Catch any unexpected errors in the processing
        console.error('Unexpected error', error);
        updateToast(toastId, errMsg(error, false, null, 'Unable to add review'), false);
    } finally {
        
        setisExecuting(false);
        closeModal();
        loadEncounters();
    }
};

    

    const handleDropdownChange = async(value) => {
        console.log(value)
        const results = await apiRequest.get(`assessments/${assessment.id}/lab-tests/${assessment.lab_tests[0].id}/review-results-mapping?review_results=${value}`).catch(err => console.log(err))
        console.log(results)
        if (results?.data?.data?.observations) { 

            form.setFieldsValue({
                visit_summary: results.data.data.observations.visit_summary, 
            });
            setVisitSummary(results.data.data.observations.visit_summary)
            setObservations(results.data.data.observations)
        }



       

    }
    const [form] = Form.useForm();

  return (
      <div className="flex flex-row  w-full bg-slate-100">
          <div className="w-2/3 bg-white m-2 shadow-md rounded">
              <div className="p-1">
                  <Headline6>Test Results</Headline6>
              </div>
              
              {pdfData ? <Document file={report} onLoadSuccess={onDocumentLoadSuccess}>
                  <div style={{ width: '100%' }}>
                      {Array.from(new Array(numPages), (el, index) => (
                          <Page wrap={false} scale={1.25} key={`page_${index + 1}`} pageNumber={index + 1} />
                      ))}
                  </div>
              </Document> :
                  <div className="w-full  flex justify-center h-full items-center">
                      <Spin spinning={!pdfData}></Spin>
                  </div>
                  
              }
             
              {/* <iframe title='lab-report' src={report} frameBorder="0"  width="100%" height="500px" className="flex-1"></iframe> */}
          </div>
          <div className="w-1/3  bg-white m-2 shadow-md h-fit rounded">
              <div className="p-2 mb-0">
                  <Headline6>Addendum</Headline6>
              <Form
                      name="lab_review"
                      onFinish={onFinish}
                      layout="vertical"
                      style={{ width: '100%' }}
                        form={form}
                    initialValues={{
                      ...assessment,
                  }}
                  >
                      <Form.Item
                          label={"Review PCR results"}
                          name="review_results"
                          style={{ width: '100%' }}
                          rules={[{ required: true, message: 'This field is required!' }]}
                      >
                          <Select placeholder="Select a result" onChange={handleDropdownChange}>
                            {/* get from masterlist */}
                            {masterList.find(item => item.system_name === "review_results")?.options?.map(item => <Option
                                value={item}>{item.toString().replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}</Option>) || []}
                          </Select>
                      </Form.Item>
                 
                      <Form.Item
                          label={"Visit Addendum"}
                          name="visit_summary"
                          style={{ width: '100%' }}
                          rules={[{ required: false, message: 'this field is required!' }]}
                          wrapperCol={{ span: 24 }}
                          onChange={(e) => {
                              //console.log(e)
                              setVisitSummary(e.target.value)
                          }}>
                          <TextArea
                              rows={6}
                              autoSize={{ minRows: 6 }}
                          />
                      </Form.Item>
                 
                </Form>
              </div>
          </div>
        </div>
  )
}

const mapStateToProps = (state) => ({
     masterList: state.masters.masterListRaw,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LabReview)