import { useState, useEffect } from "react";
import TinyTable from "./TinyTable";
import { Divider, Segmented, Spin } from "antd";
import { Headline6 } from "../../../components";
import { apiRequest } from "../../../app/Apis";
import { emptyFun } from "../../../common/helpers";
import { LeftOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LocationDetails from "./LocationDetails";

export default function AssessmentLocation({ region }) {
  const [option, setOption] = useState('false');
  const [consultants, setConsultants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0); // this is used to trigger the useEffect when the region changes
  const [navParams, setNavParams] = useState(null);
  const [assessment, setAssessment] = useState(null);
  const [loadingAssessment, setLoadingAssessment] = useState(false);
  const [total, setTotal] = useState(null);

  const defaultOptions = [
    { label: 'By Region', value: 'region' },
    { label: 'By State', value: 'state' }
  ];
  function onChangeLocation(option) {
    setOption(option)
  }

  function sumAssessmentObjects(data) {
    let keysToSum = ['Biologics Application', 'Biologics Ordering', 'Biologics Pre-Assessment', 'DME', 'Lab', 'Podiatry', 'Wound Debridement', 'Wound Evaluation'];
    let total = data?.reduce((acc, obj) => {
      keysToSum.forEach((key) => { acc += obj[key]; });
      return acc;
    }, 0);
    return total;
  }

  async function fetchConsultants(option) {
    setLoading(true)
    let queryString = '';
    if (!!region && region !== '' && region !== undefined) {
      queryString = `&region=${region}`;
    }
    let optionString = option === "state" ? `?group_by=state` : "?group_by=region" // this will be used to filter by state or region, by default the endpoint brings by region
    const response = await apiRequest.get(`/assessment_stats/location${optionString}${queryString}`)
    const res = response.data.data
    let newRes = res.filter((obj) => obj.label !== null && obj.label !== " Region")
    setConsultants(newRes)
    setLoading(false)
  }

  async function fetchOneAssessment(option, value) {
    setLoadingAssessment(true)
    let str = value?.toLowerCase()
    let queryString = option === "state" ? `?state=${str}` : `?region=${str}` // this will be used to filter by state or region, by default the endpoint brings by region
    const response = await apiRequest.get(`/assessment_stats/${queryString}&format=year`)
    const res = response.data.data
    let newRes = res.filter((obj) => obj.label !== null && obj.label !== " Region")
    setTotal(sumAssessmentObjects(newRes))
    setAssessment(newRes)
    setLoadingAssessment(false)
  }

  useEffect(() => {
    fetchConsultants("region")
    return emptyFun
  }, [])

  useEffect(() => {
    fetchConsultants(option)
    return emptyFun
  }, [option])

  useEffect(() => {
    fetchConsultants(option, region)
    return emptyFun
  }, [region])

  useEffect(() => {
    if (step === 1) {
      fetchOneAssessment(option, navParams?.label)
    }
  }, [step, navParams])

  return (
    <div className="stats-card-box">
      {step === 0 ?
        <div style={{ width: '100%', padding: '5px' }}>
          <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>Assessments by location</Headline6>
          <Divider style={{ margin: '4px', color: '#E2E4EA', width: '100%' }} />
        </div>
        :
        <div className='flex-boy-row-space' style={{ width: '100%' }}>
          <div className="flex-boy-row" style={{ cursor: 'pointer' }}>
            <LeftOutlined style={{ marginRight: 30, marginTop: 10 }} onClick={() => setStep(0)} />
            <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>{navParams?.label || 'Unknown'}</Headline6>
          </div>
          <FontAwesomeIcon color="grey" icon={['fas', 'poll']} />
        </div>
      }

      {step === 0 ?
        <div className="">
          <div className="flex justify-start p-4">
            <Segmented options={defaultOptions} onChange={(value) => onChangeLocation(value)} />
          </div>
          <Spin spinning={loading}>
            <TinyTable patients={consultants} type="assessments" option={option} setStep={setStep} setNavParams={setNavParams} />
          </Spin>
        </div>
        :
        <div className="group">
          <div style={{ width: '100%', padding: '15px' }}>
            <Headline6 style={{ fontWeight: 'bold', fontSize: '16px' }}>Total Assessments</Headline6>
            <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>{total}</Headline6>
          </div>
          <Spin spinning={loadingAssessment}>
            {assessment && <LocationDetails option={option} setStep={setStep} type='assessments' data={assessment} />}
          </Spin>
        </div>
      }
    </div>
  )
}