import React, {useEffect, useState} from 'react';
import {Grid, Table} from "antd";
import {connect, useDispatch} from "react-redux";
import {Subtitle1} from "../../../components";
import {
    createInsuranceProvider,
    deleteInsuranceProvider,
    editInsuranceProvider,
    fetchInsuranceProviders,
    resetInsuranceProviderPagination,
    updateInsuranceProvidersFilters
} from "../../../app/Reducers";
import TableFilters from "../../../components/InputComponents/TableFilters";
import {containedButton, createFormInputsFromTableColumns, dialogCloser, emptyFun, emptyTable, executeChunkFn, getSkels, kebabMenus, onTableChange, responsiveTbl} from "../../../common/helpers";

import KebabMenu from "../../../components/KebabMenu";
import * as Sentry from "@sentry/react";
import {errMsg, toastUp} from "../../../app/Reducers/reducerUtils";
import DynamicModalForm from "../../../components/DynamicModalForm";
import {PlusOutlined} from '@ant-design/icons';

const { useBreakpoint } = Grid;
const InsuranceProviders = ({ appConf, insuranceProviderPagination, insuranceProviders }) => {
    const dispatch = useDispatch();
    const windowState=useBreakpoint()
    const dateFormat = "YYYY-MM-DD";
    const [showAuditDialog, setShowAuditDialog] = useState(false);
    const [auditItem, setAuditItem] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [selected, setSelected] = useState(null)
    const [isExecuting, setisExecuting] = useState(false)
    const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
    const [formName, setFormName] = useState(undefined);
    const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
    useEffect(() => {
        dispatch(fetchInsuranceProviders());
        return emptyFun
    }, []);

    const columns = [
        {
            title: 'Name', dataIndex: 'name', key: 'name', fltr: { filterable: true, type: 'text', editableCol: true, required: true, fullRow: true }
        },
        {
            title: 'Active', dataIndex: 'is_active', key: 'is_active',
            fltr: { filterable: false, type: 'checkbox', editableCol: true, required: false },
            render: (text, record) => (
                <div className="status-container">
                    <div className="dot-circle-active" style={{ background: record.is_active ? '#50D2A0' : "#fc223e" }}/>
                    <Subtitle1>{record.is_active ? 'Yes' : 'No'}</Subtitle1>
                </div>
            )
        },
        {
            title: 'Action',
            render: (record) =>
                (<KebabMenu
                        menus={actionMenus}
                        recordItem={record}
                        handleMenuClick={kebabMenuClick}
                        resource="insurance provider"
                        handleOk={() => handleOk(record)}
                        showConfirmDialog={selected === record.id}
                        handleCancel={handleCancel}
                        confirmLoading={confirmLoading}
                    />
                )
        }
    ]
    let providerFormInputs = createFormInputsFromTableColumns(false, columns, null, null, null, null, dateFormat)
    //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
    let dynamicFormInputFields = {
        'Edit insurance provider': providerFormInputs, 'Add insurance provider': providerFormInputs
    }

    const actionMenus = kebabMenus(true, false, true)
    const showPopconfirm = (id) => setSelected(id);

    async function kebabMenuClick(e, record) {
        switch (actionMenus[e.key].title) {
            case 'Edit':
                await openProviderForm(record)
                break
            case 'Delete':
                showPopconfirm(record.id)
                break
            case 'Audit':
                break
            default:
        }
    }

    const handleOk = async (record) => {
        await setConfirmLoading(true)
        await dispatch(deleteInsuranceProvider(record.id))
        await setConfirmLoading(false)
        await setSelected(0)
    }

    const handleCancel = () => setSelected(0);

    async function handleFilterAction(action, values) {
        await dispatch(resetInsuranceProviderPagination())
        if (action === 'filter') {
            let pl = { filterData: { ...values }, pagination: insuranceProviderPagination }
            await dispatch(updateInsuranceProvidersFilters([false, pl, true]))
            await dispatch(fetchInsuranceProviders(pl))
            await dispatch(updateInsuranceProvidersFilters([true, pl, false]))
        }
        if (action === 'reset') {
            await dispatch(updateInsuranceProvidersFilters([appConf.insuranceProvidersFilters[0], undefined, true]))
            await dispatch(fetchInsuranceProviders())
            await dispatch(updateInsuranceProvidersFilters([false, undefined, false]))
        }
        setIsVisible(false)
    }


    const openProviderForm = async (record) => {
        await setFormName(!!record ? 'Edit insurance provider' : 'Add insurance provider');
        await setDefaultDynamicModalFormValues(!!record ? { ...record } : undefined);
        await setDynamicFormOpen(true);
    };

    /**
     * This function is handling returned form entries from the dynamic form
     * */
    const onDynamicFormEntriesResult = async (entries) => {

        try {
            switch (formName) {
                case 'Add insurance provider':
                    await executeChunkFn(dispatch, createInsuranceProvider, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen), null)
                    break;
                case 'Edit insurance provider':
                    await executeChunkFn(dispatch, editInsuranceProvider, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen), null)
                    break;
                default:
                    return
            }
        } catch (e) {
            Sentry.captureException(e);
            console.log(e);
            toastUp(errMsg(e, false, formName), false);
        }
    };
    return (
        <div className="w-full">
            {(isDynamicFormOpen && !!formName) && (
                <DynamicModalForm
                    setDynamicFormOpen={setDynamicFormOpen}
                    isDynamicFormOpen={isDynamicFormOpen}
                    inputFields={dynamicFormInputFields[formName]}
                    onDynamicFormEntriesResult={onDynamicFormEntriesResult}
                    closeModal={dialogCloser(setDynamicFormOpen)}
                    isExecuting={isExecuting}
                    defaultValues={defaultDynamicModalFormValues}
                    formName={formName}
                />
            )}
            <Table
                title={() => (<TableFilters datasource={columns} setIsVisible={setIsVisible} isVisible={isVisible}
                                            handleFilterAction={handleFilterAction} filters={appConf.insuranceProvidersFilters[1]}
                                            showClear={appConf.insuranceProvidersFilters[0]} loading={appConf.insuranceProvidersFilters[2]}
                                            actionButton={containedButton(() => openProviderForm(), 'Add Insurance Provider', false, null, null, { icon: <PlusOutlined /> })}
                />)}
                loading={appConf.insuranceProvidersFilters[2]} size={'small'} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('patient wounds.') }}
                onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchInsuranceProviders, appConf.insuranceProvidersFilters, updateInsuranceProvidersFilters, null)}
                onRow={(record) => ({
                    onClick: async (event) => {
                        await setAuditItem(record)
                        await setShowAuditDialog(true)
                    }
                })}
                {...getSkels((appConf.insuranceProvidersFilters[2]), columns, insuranceProviders)} pagination={insuranceProviderPagination}
            />
        </div>

    );
};


const mapStateToProps = (state) => ({
    activeUser: state.auth?.activeUser,
    insuranceProviders: state.masters.insuranceProviders,
    insuranceProviderPagination: state.masters.insuranceProviderPagination,
    appConf: state.appConf
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(InsuranceProviders);
