// department slice
//

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {apiRequest} from "../Apis"
import {errMsg, getToastId, toastUp, updateToast} from "./reducerUtils";
import {departments} from "./initialState";

const initialState = {
    ...departments
}

// fetch departments
export const fetchDepartments = createAsyncThunk('departments/fetchDepartments', async (requestParams, thunkAPI) => {
        // const toastId = getToastId("Fetching Departments")

        return new Promise(async (resolve, reject) => {
            try {
                let state = thunkAPI.getState();
                let pagination = requestParams?.pagination ? requestParams.pagination : state.departments.pagination;
                let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
                params.page = pagination.current
                const response = await apiRequest.get(`/departments`,{params});
                let data = response.data.data.map((item) => ({ ...item }));
                const payload = { departments: data, pagination: { ...pagination, total: response.data.meta.total } };
                //console.log(payload);
                resolve(payload);
            } catch (e) {
                toastUp(errMsg(e, true, 'departments'), false);
                reject(e)

            }
        })
    }
)
// fetch department
export const fetchDepartment = createAsyncThunk('departments/fetchDepartment', async (params, thunkAPI) => {
        // const toastId = getToastId("Fetching Department")

        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiRequest.get(`/departments/${params.id}`)
                // updateToast(toastId, "Department Fetched", true)
                await thunkAPI.dispatch(selectDepartment(response.data.data))
                resolve(response)
            } catch (e) {
                toastUp(errMsg(e, true, 'departments'), false);
                reject(e)

            }
        })
    }
)

// create department
export const createDepartment = createAsyncThunk('departments/createDepartment', async (params, thunkAPI) => {

        // const toastId = getToastId("Creating Department")

        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiRequest.post(`/departments`, params)
                // updateToast(toastId, "Department Created", true)
                if (params.users.length > 0) {
                    await thunkAPI.dispatch(addDepartmentUsers({ id: response.data.data.id, users: params.users }))
                }

                await thunkAPI.dispatch(fetchDepartments())


                resolve(response)
            } catch (e) {
                toastUp(errMsg(e, true, 'departments'), false);
                reject(e)

            }
        })
    }
)


// update department
export const updateDepartment = createAsyncThunk('departments/updateDepartment', async (params, thunkAPI) => {

        const toastId = getToastId("Updating Department")

        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiRequest.patch(`/departments/${params.id}`, params)
                updateToast(toastId, "Department Updated", true)
                await thunkAPI.dispatch(selectDepartment(response.data.data))
                await thunkAPI.dispatch(fetchDepartments())
                resolve(response)
            } catch (e) {
                toastUp(errMsg(e, true, 'departments'), false);
                reject(e)

            }
        })
    }
)


// delete department
export const deleteDepartment = createAsyncThunk('departments/deleteDepartment', async (params, thunkAPI) => {

        const toastId = getToastId("Deleting Department")

        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiRequest.delete(`/departments/${params.id}`)
                updateToast(toastId, "Department Deleted", true)
                await thunkAPI.dispatch(fetchDepartments())
                resolve(response)
            } catch (e) {
                toastUp(errMsg(e, true, 'departments'), false);
                reject(e)

            }
        })
    }
)


// fetch department tasks
export const fetchDepartmentTasks = createAsyncThunk('departments/fetchDepartmentTasks', async (params, thunkAPI) => {

        // const toastId = getToastId("Fetching Department Tasks")

        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiRequest.get(`/departments/${params.id}/tasks`)
                // updateToast(toastId, "Department Tasks Fetched", true)
                await thunkAPI.dispatch(setSelectedDepartmentTasks(response.data.data))
                resolve(response)
            } catch (e) {
                toastUp(errMsg(e, true, 'departments'), false);
                reject(e)

            }
        })
    }
)


// fetch department users
export const fetchDepartmentUsers = createAsyncThunk('departments/fetchDepartmentUsers', async (requestParams, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            try {
                let state = thunkAPI.getState();
                let pagination = requestParams?.pagination ? requestParams.pagination : state.departments.departmentUsersPagination;
                let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
                params.page = pagination.current
                const response = await apiRequest.get(`departments/${requestParams.id || state.departments.selectedDepartment.id}/users`,{params});
                let data = response.data.data.map((item) => ({ ...item }));
                const payload = { users: data, departmentUsersPagination: { ...pagination, total: response.data.meta.total } };
                resolve(payload);
            } catch (e) {
                console.log(e)
                toastUp(errMsg(e, true, 'department users'), false);
                reject(e)

            }
        })
    }
)

// add department users
export const addDepartmentUsers = createAsyncThunk('departments/addDepartmentUsers', async (params, thunkAPI) => {

        const toastId = getToastId("Adding Department Users")

        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiRequest.post(`/departments/${params.id}/users`, params)
                updateToast(toastId, "Department Users Added", true)
                await thunkAPI.dispatch(fetchDepartmentUsers({ id: params.id }))
                resolve(response.data.data)
            } catch (e) {
                toastUp(errMsg(e, true, 'departments'), false);
                reject(e)

            }
        })
    }
)


// remove department users
export const removeDepartmentUser = createAsyncThunk('departments/removeDepartmentUsers', async (params, thunkAPI) => {

        const toastId = getToastId("Removing Department User")

        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiRequest.delete(`/departments/${params.departmentId}/users/${params.id}`, { data: params })
                updateToast(toastId, "Department Users Removed", true)
                await thunkAPI.dispatch(fetchDepartmentUsers({ id: params.departmentId }))
                resolve(response.data.data)
            } catch (e) {
                toastUp(errMsg(e, true, 'departments'), false);
                reject(e)

            }
        })
    }
)


// crete departmentSlice
const DepartmentSlice = createSlice({
        name: 'departments',
        initialState,
        reducers: {
            setDepartments: (state, action) => {
                state.departments = action.payload
            }
            ,
            selectDepartment: (state, action) => {
                state.selectedDepartment = action.payload
            }
            ,
            setSelectedDepartmentTasks: (state, action) => {
                state.selectedDepartmentTasks = action.payload
            }
            ,
            clearSelectedDepartmentTasks: (state, action) => {
                state.selectedDepartmentTasks = []
            },
            resetDepartmentPagination: (state, action) => {
                state.pagination = initialState.pagination
            },
            resetDepartmentUserPagination: (state, action) => {
                state.departmentUsersPagination = initialState.departmentUsersPagination
            },
            doResetDepartmentSlice: (state) => {
                Object.keys(initialState).map((key) => (state[key] = initialState[key]));
            }


        }
        ,
        extraReducers: (builder) => {
            builder.addCase(fetchDepartments.pending, (state, action) => {
                state.loading = true
                state.error = null
                state.successMsg = null
                state.errorMsg = null
            }).addCase(fetchDepartments.fulfilled, (state, action) => {
                state.loading = false
                Object.keys(action.payload).forEach(key => state[key] = action.payload[key])
            }).addCase(fetchDepartments.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            }).addCase(fetchDepartment.pending, (state, action) => {
                state.loading = true
                state.error = null
                state.successMsg = null
                state.errorMsg = null
            }).addCase(fetchDepartment.fulfilled, (state, action) => {
                state.loading = false
                Object.keys(action.payload).forEach(key => state[key] = action.payload[key])
            }).addCase(fetchDepartment.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            }).addCase(fetchDepartmentUsers.fulfilled, (state, action) => {
                state.loading = false
                Object.keys(action.payload).forEach(key => state[key] = action.payload[key])
            })
        }
    }
)

export const {
    setDepartments, selectDepartment, setSelectedDepartmentTasks, clearSelectedDepartmentTasks,
    resetDepartmentPagination, resetDepartmentUserPagination, doResetDepartmentSlice
} = DepartmentSlice.actions

export default DepartmentSlice.reducer