/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Body2, Headline6, Subtitle1 } from '../../../general'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import _ from "lodash";
import { apiRequest } from "../../../../app/Apis";

const RouteSearchComponent = ({ handleConsultant, filterRoutes, showActiveTrips, loadingTrips, searchTrip }) => {
  const [searchState, setSearchState] = React.useState({});
  const [searchValue, setsearchValue] = useState('')
  const dispatch = useDispatch()
  const [searchOption, setSearchOption] = useState(true)
  const onSearchOption = (isConsultant) => {
    console.log(isConsultant)
    setSearchOption(isConsultant)
  }
  const [searchResults, setsearchResults] = useState(null)
  const searchSuggestions = async (value) => {
    let response = await apiRequest.get(`suggest?query=${value}`).catch(e => console.log(e))
    if (response?.data) {
      setsearchResults(response.data)
    }
  }

  const CounsultantHit = ({ hit }) => {
    if (hit) {
      return (<div onClick={() => {
        goToConsultant(hit);
        //Hide the dropdown
        setsearchResults(null)
      }} className="flex-boy-row-space" style={{ width: "100%", cursor: 'pointer' }}>
        <FontAwesomeIcon size="1x" icon={['fas', 'user']} />
        <div style={{ flex: 1, marginLeft: "10px" }}><Subtitle1 style={{ textAlign: "left" }}>{hit.name}</Subtitle1> <Body2 color="black">{hit.address_1}</Body2></div>
      </div>)
    }
  }

  const goToConsultant = async (project) => {
    handleConsultant(project)
    console.log('goToConsultant: ', project)
    clearSearch()
  }
  const clearSearch = () => setSearchState({ ...searchState, query: '' })


  const debouncedChangeHandler = useCallback(
    _.debounce(function (value) {
      searchSuggestions(value)
    }, 500),
    []
  );

  /*return (<div className="trip-instant-search">
    <InstantSearch searchClient={searchClient} indexName={consultantsSearchIndex} searchState={searchState} onSearchStateChange={setSearchState}>
      <Configure hitsPerPage={5}/>
      <div style={{position: 'relative', display: 'flex', flexDirection: 'row', height: 'max-content',  flex: 1, alignItems: 'center',background: 'rgba(255,255,255,0.5)', borderRadius: '24px',
      paddingLeft:10,paddingRight:10}}>
        <div style={{marginRight: 6, position: 'relative', display: 'flex', flexDirection: 'row', height: 'max-content'}}>
          <Typography noWrap={true} style={{marginRight: 6, fontWeight: 600,color:'midnightblue'}} variant="body2">Search by: </Typography>
          <Switch style={{flexWrap: 'nowrap'}}
                  checkedChildren={<Typography variant="body2">Consultant</Typography>}
                  unCheckedChildren={<Typography variant="body2">Ref No</Typography>}
                  defaultChecked={showActiveTrips}
                  onChange={onSearchOption}
          />
        </div>
        {searchOption ? <SearchBox  translations={{submitTitle: 'Submit your search query.', resetTitle: 'Clear your search query.', placeholder: "Type consultant name"}}/> :
          <TripSearch searchTrip={searchTrip}/>}
        <Switch
          style={{flexWrap: 'nowrap', position: 'relative',marginLeft:6}}
          checkedChildren={<Typography variant="body2">Ongoing trips</Typography>}
          unCheckedChildren={<Typography variant="body2">Completed trips</Typography>}
          defaultChecked={showActiveTrips}
          onChange={filterRoutes} loading={loadingTrips} disabled={loadingTrips}
        />
      </div>
      <div style={{
        margin: '0px', backgroundColor: searchState && searchState.query && searchState.query !== '' ? 'whitesmoke' : 'transparent',
        border: searchState && searchState.query !== '' ? 'gainsboro' : 'transparent'
      }} className="right-panel">
        {searchState && searchState.query ?
          (<Index indexName={consultantsSearchIndex}> <Headline6 style={{margin: '5px'}}>Consultants</Headline6> <Configure hitsPerPage={3}/> <Hits hitComponent={CounsultantHit}/> </Index>) : null}
      </div>
    </InstantSearch>
  </div>)*/

  return (<div className="instant-search-elastic">
    <Input placeholder="Search for consultants here..." prefix={<SearchOutlined />} width={"100%"} onChange={(e) => {
      setsearchValue(e.target.value)
      debouncedChangeHandler(e.target.value)
    }} />
    {(!!searchValue && searchValue.length > 0 && !!searchResults?.consultants_suggest) && <div className="search-results">
      <Headline6>Consultants</Headline6>
      {searchResults?.consultants_suggest?.map(item => CounsultantHit({ hit: item._source }))}
    </div>}
  </div>)
}
const mapStateToProps = (state) => ({  })
const mapDispatchToProps = (dispatch) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(RouteSearchComponent)
