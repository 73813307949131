/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'


import {Tabs} from "antd";
import {Subtitle1} from '../../../components';
import PatientOrderDme from './PatientOrderDme';
import PatientOrdersBiologics from './PatientOrdersBiologics';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {emptyFun} from '../../../common/helpers';

 const Orders = ({ permissions }) => {
  const can_view_biologic_orders = usePermissionCheck('view_biologic_orders', false, [])
  const can_view_dme_orders = usePermissionCheck('view_dme_orders', false, [])
  const [activeTab, setactivTab] = useState(can_view_dme_orders ? 'dme' : can_view_biologic_orders ? 'biologics' : null)
  const setActiveTab = async (tab) => {
    setactivTab(tab)
  }
  
  useEffect(() => {
    setactivTab(can_view_dme_orders ? 'dme' : can_view_biologic_orders ? 'biologics' : null)
    return emptyFun
  }, [can_view_dme_orders, can_view_biologic_orders])
  
  const Tabs = () => {
    return (<div className="flex-boy-row patients-tabs bg-white">
      {can_view_dme_orders && <div onClick={() => setActiveTab("dme")} style={{ height: "60px" }}
                                   className={activeTab === "dme" ? "patient-tab active" : "patient-tab"}>
        <Subtitle1>DME List</Subtitle1>
      </div>}
      {can_view_biologic_orders && <div onClick={() => setActiveTab("biologics")} style={{ height: "60px" }}
                                        className={activeTab === "biologics" ? "patient-tab active" : "patient-tab"}>
        <Subtitle1>Biologics List</Subtitle1>
      </div>}
    </div>)
    // return
    // <Tabs defaultActiveKey={activeTab} onChange={setActiveTab} tabBarStyle={{ marginBottom: 0 }}>
    //   {TabList.map(item => <TabPane key={item} tab={<Subtitle1>{item}</Subtitle1>}>
    //   </TabPane>)}
    // </Tabs>
  }
  
  return (<div className="full-page">
    <Tabs/>
    {activeTab === 'dme' ? <PatientOrderDme/> : (can_view_biologic_orders && activeTab === 'biologics') ? <PatientOrdersBiologics/> : null}
  </div>)
}
const mapStateToProps = (state) => ({
  
  navs: state.navs
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Orders)

