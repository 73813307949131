import React, { useCallback, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AutoComplete, DatePicker, Form, Input, Select, } from 'antd'
import dayjs from "dayjs"
import { createPatient } from '../../../app/Reducers'
import MapGL from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import { mapboxToken } from '../../../configure/constants'
import NumberFormat from "react-number-format";
import { Body1, Subtitle1 } from '../../../components'
import { findTimeZone, handleActiveFacilitySearch } from "../../../app/Apis/commonApi";
import { executeChunkFn, GetOptions, inputRow } from "../../../common/helpers";
import { toast } from 'react-toastify'
import { apiRequest } from '../../../app/Apis'
import AppPhoneInput from '../../../components/InputComponents/AppPhoneInput';

 const NewPatientForm = ({ closeModal, setisExecuting, activeUser }) => {
  const dateFormat = 'MM-DD-YYYY';
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const [date, setDate] = useState(null);
  const [facilities, setFacilities] = useState([]);
  const [phoneValue, setPhoneValue] = useState(undefined);
  const [facility, setFacility] = useState()
  const onFinish = async (values) => {
    if (!locationData) {
      setLocationError(true)
    } else {
      const payload = {
        ...values, name: values.first + ' ' + values.last, ...locationData,
        birth_date: dayjs(values.birth_date).format("YYYY-MM-DD"),
        billable_from: dayjs(values.billable_from).format("YYYY-MM-DD"),
        ...(facility && { facility_id: facility.id }), ...phoneValue
      }
      await executeChunkFn(dispatch, createPatient, payload, setisExecuting, closeModal)
    }
  };
  const disabledFutureDates = (current) => {
    let customDate = dayjs();
    let tooOld = dayjs("1869-12-31");
    return (current && current > dayjs(customDate, dateFormat)) || (current && current < dayjs(tooOld, dateFormat));
  }
  const disabledPastDates = (current) => {
    let customDate = dayjs().subtract(1, 'd');
    return current && current < dayjs(customDate, dateFormat);
  }
  // location stuff
  const [viewport, setViewport] = useState({ latitude: 37.7577, longitude: -122.4376, zoom: 8 });
  const [locationData, setLocationData] = useState(null)
  const geocoderContainerRef = useRef()
  const mapRef = useRef()
  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);
   const onResult = useCallback(async (result) => {
     // console.log(result)
     let center = result.result.center
     let context = result.result.context
     let state = context.find(item => item.id.includes('region'))
     let city = context.find(item => item.id.includes('place'))
     let zip_code = context.find(item => item.id.includes('postcode'))
     let timezone = await findTimeZone(city ? city.text : result.result.place_name)

     let data = {
       // city: city ? city.text : result.result.place_name, state: state ? state.text : null,
       // zip_code: zip_code ? zip_code.text : result.result.place_name,
       co_ordinates: `${center[0]},${center[1]}`,
       // address_1: result.result.place_name
     }

     setLocationData(data)
     form.setFieldsValue({
       address_1: result.result.place_name,
       city: city ? city.text : null,
       state: state ? state.text : null,
       zip_code: zip_code ? zip_code.text : null,
       timezone: timezone
     });
   }, [])
  const change = (value) => {
    setDate(value)
  }
  const getSsnField = () => {
    return dayjs().diff(dayjs(date), 'years') > 18 ? <Form.Item
      style={{ width: '100%' }}
      name="ssn"
      label="SSN"
      rules={[{ required: false, message: 'SSN is required for adults' }, { max: 11, message: 'SSN length exceeds limit' }/*, {pattern: /^[1-9]./, message: 'Invalid SSN'}*/]}>
      <NumberFormat autoComplete={'off'} customInput={Input} format="###-##-####" placeholder="###-##-####" />
    </Form.Item> : <Form.Item
      style={{ width: '100%' }}
      name="ssn"
      label="SSN"
      rules={[{ max: 11, message: 'SSN length exceeds limit' }/*, {pattern: /^[1-9]./, message: 'Invalid SSN'}*/]}>
      <NumberFormat autoComplete={'off'} customInput={Input} format="###-##-####" placeholder="###-##-####" />
    </Form.Item>
  }
  const onFacilitySelect = (value) => {
    let facility = facilities.find(item => item.name === value)
    setFacility(facility)
  };
  const [LocationError, setLocationError] = useState(false);
  const getPatientDetails = async options => {
    const { onSuccess, onError, file, onProgress } = options;
    let formData = new FormData();
    // add one or more of your files in FormData
    // again, the original file is located at the `originFileObj` key
    const toastId = toast("Generating prospect details", { type: "info", autoClose: false });
    formData.append("patient_details_image", file);
    const { data } = await apiRequest.post("/ocr-patient-details", formData).catch(function (err) {
      toast.update(toastId, { render: err.response?.data.message || "Server Error", type: toast.TYPE.ERROR, autoClose: 5000 });

    })
    if (data && data.data && data.data.dump) {
      toast.update(toastId, { render: "Prospect Details Generated", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      onSuccess("Ok");
      console.log(form.getFieldsValue(), data.data.dump)
      form.setFieldsValue({
        first: data.data.dump['First Name '],
        last: data.data.dump['Last Name '],
        birth_date: dayjs(data.data.dump['Date of Birth ']),
        email: data.data.dump['Email '],
        phone_number: data.data.dump['Phone '],
        gender: data.data.dump['Gender '],
        ssn: data.data.dump['SSN ']
      });
      handleActiveFacilitySearch('ken', setFacilities)
    } else {
      toast.update(toastId, { render: "unable to generate prospect details fom the facesheet please manualy fill the details", type: toast.TYPE.ERROR, autoClose: 5000 });
    }
  }
  return (
    <Form
      name="new-patient"
      id="patientForm"
      labelCol={{ span: 16 }}
      form={form}
      layout="vertical"
      onFinish={onFinish}>

      {inputRow([
        <Form.Item
          style={{ width: '100%' }}
          name="first_name"
          label="First Name"
          rules={[{ required: true, message: 'Missing/Invalid first name', pattern: /[a-zA-Z ]$/ }]}>
          <Input autoComplete={'off'} placeholder="First Name" />
        </Form.Item>,
        <Form.Item
          style={{ width: '100%' }}
          name="middle_name"
          label="Middle Name"
          rules={[{ required: false, message: 'Missing/Invalid name', pattern: /[a-zA-Z ]$/ }]}>
          <Input autoComplete={'off'} placeholder="Middle Name" />
        </Form.Item>,
        <Form.Item
          style={{ width: '100%' }}
          name="last_name"
          label="Last Name"
          rules={[{ required: true, message: 'Missing/Invalid Last Name', pattern: /[a-zA-Z ]$/ }]}>
          <Input autoComplete={'off'} placeholder="Last Name" />
        </Form.Item>
      ])}
      {inputRow([
        <Form.Item
          style={{ width: '100%' }}
          name="birth_date"
          label="Date of Birth"
          rules={[{ required: true, message: 'Missing Date of Birth' }]}>
          <DatePicker onChange={change} disabledDate={disabledFutureDates} style={{ width: '100%' }} format={dateFormat} />
        </Form.Item>,
        <Form.Item
          name="gender"
          label="Gender"
          style={{ width: '100%' }}
          rules={[{ required: true, message: 'Missing Gender' }]}>
          <Select popupMatchSelectWidth={false}
            placeholder="gender"
            style={{ width: '100%' }}
            allowClear>
            {GetOptions('gender')}
          </Select>
        </Form.Item>,
        getSsnField()
      ])}
      {inputRow([
        <Form.Item
          style={{ width: '100%' }}
          name="email"
          label="Email"
        // rules={[{ required: true, message: 'Missing Email' }]}
        >
          <Input autoComplete={'off'} placeholder="Email" type="email" />
        </Form.Item>,
        <AppPhoneInput name={'phone_no'} setPhoneValue={setPhoneValue} phoneValue={phoneValue} formItemProps={{ style: { width: '100%' }, required: true }} />
        /*<Form.Item
          style={{ width: '100%' }}
          name="phone_no"
          label="Phone Number"
          rules={[{ required: true, message: 'Missing Phone Number' }]}>
          <NumberFormat autoComplete={'off'} customInput={Input} format="+(#)(###) ###-####" placeholder="Phone Number" />
        </Form.Item>*/
      ])}
      {inputRow([
        <Form.Item
          style={{ width: '100%' }}
          name="facility_id"
          initialValue={facility ? facility.name : undefined}
          label="Office"
          placeholder="Search for facility"
          rules={[{ required: false, message: 'Missing Office' }]}>
          <AutoComplete popupMatchSelectWidth={false} style={{ width: "100%", borderRadius: "4px" }}
            onSelect={onFacilitySelect}
            placeholder="Search for facility"

            onSearch={(value) => handleActiveFacilitySearch(value, setFacilities)}>
            {facilities.length > 0 && facilities.map((item, index) =>
              <AutoComplete.Option key={index} value={item.name}>{item.name}</AutoComplete.Option>)}
          </AutoComplete>
        </Form.Item>,
        <Form.Item
          style={{ width: '100%' }}
          name="billable_from"
          label="Date Can Bill"
          rules={[{ required: true, message: 'Missing Date Can Bill' }]}>
          <DatePicker disabledDate={disabledPastDates} style={{ width: '100%' }} format={dateFormat} />
        </Form.Item>
      ])}
      <Subtitle1 className="Validator" style={{ textAlign: 'left', marginBottom: '10px' }}>Address Search</Subtitle1>
      <Form.Item
        style={{ width: '100%' }}
        name="default_region"
        label=""
        rules={[{ required: false, message: 'Missing Region' }]}>

        <div
          ref={geocoderContainerRef}
          style={{ position: "absolute", top: 0, left: 0, zIndex: 1, width: "100%" }}
        />
        <MapGL
          ref={mapRef}
          {...viewport}
          width="100%"
          height="100%"
          onViewportChange={handleViewportChange}
          mapboxApiAccessToken={mapboxToken}>
          <Geocoder
            mapRef={mapRef}
            containerRef={geocoderContainerRef}
            onViewportChange={handleViewportChange}
            mapboxApiAccessToken={mapboxToken}
            countries="us,ke"
            onResult={onResult}
            marker={false}
            onError={onResult} />
        </MapGL>
      </Form.Item>
      {LocationError && <Body1 className="Validator" color='crimson' style={{ textAlign: 'left', marginBottom: '10px' }}>Please add an address!</Body1>}

      {locationData && <>
        <Form.Item
          style={{ width: '100%', marginTop: '10px' }}
          name="address_1"
          label="Address"
          rules={[{ required: true, message: 'Missing location' }]}>
          <Input autoComplete={'off'} placeholder="Address" />
        </Form.Item>
        {inputRow([
          <Form.Item
            style={{ width: '100%' }}
            name="state"
            label="State"
            rules={[{ required: true, message: 'Missing State' }]}>
            <Input autoComplete={'off'} placeholder="State" />
          </Form.Item>,
          <Form.Item
            style={{ width: '100%' }}
            name="city"
            label="City"
            rules={[{ required: true, message: 'Missing City' }]}>
            <Input autoComplete={'off'} placeholder="City" />
          </Form.Item>,
          <Form.Item
            style={{ width: '100%' }}
            name="zip_code"
            label="Zip Code"
            rules={[
              {
                required: true,
                message: 'Missing Zip code',
              },
              // {
              //   pattern: /^\d{5}(?:[-\s]\d{4})?$/,
              //   message: 'Please enter a valid zip Code',
              // }
            ]}>
            <Input autoComplete={'off'} placeholder="zip" />
          </Form.Item>,
        ])}
        {/* // time_zone */}
        <Form.Item
          style={{ width: '100%' }}
          name="timezone"
          label="Time Zone"
          rules={[{ required: false, message: 'Missing Time Zone' }]}>

          <Input autoComplete={'off'} placeholder="timezone" />
        </Form.Item>
        
      </>}

      <Form.Item
        label="Address 2"
        name="address_2"
        rules={[{ required: false, message: 'Missing Address' }]}>
        <Input autoComplete={'off'} placeholder="App, suite, unit, building, floor, etc" />
      </Form.Item>
      {/* {!activeUser?.facilityProfile && <Form.Item
        label="SmartSheet ID"
        name="smartsheet_id"
        rules={[{ required: false, message: 'Missing SmartSheet ID' }]}
      >
        <Input autoComplete={'off'} placeholder="SmartSheet ID" />
      </Form.Item>} */}

    </Form>
  );
}
const mapStateToProps = (state) => ({ regions: state.map.regions, activeUser: state.auth?.activeUser
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(NewPatientForm)
