import React, {useState} from 'react'
import {connect} from 'react-redux'
import {AutoComplete, Button, Col, Form, Input, Row, Select} from 'antd'
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';

const {Option} = Select
export const NewPatentCard = (props) => {
  const [form] = Form.useForm();
  const supplyItems = [{name: 'Product One', quantities: ['250mg', 'box', '1L']},
    {name: 'Product Two', quantities: ['300mg', 'bottle', '2L']},
    {name: 'Product Three', quantities: ['200mg', 'sachet']}, {name: 'Product Four', quantities: ['500mg', '3L']},
    {name: 'Product Five', quantities: ['20mg', 'box', '1/2L']}, {name: 'Product Six', quantities: ['150mg', 'sachet']}]
  const [list, setList] = useState([...supplyItems]);
  const updateForm = (index, name, value) => {
    let formValues = form.getFieldsValue()
    formValues.orderItems[index][name] = value
    form.setFieldsValue({...formValues})
  }
  const showItem = (listName, index, itemName, component) => {
    let formItems = form.getFieldsValue()
    if (formItems.orderItems && !!formItems.orderItems[index] && !!formItems.orderItems[index]['product']) {
      return component
    }
  }
  return (<Form
      name="new-patient"
      form={form}
      labelCol={{span: 16}}
      layout="vertical"
    >
      <Form.Item

        name="recipient"
        label="Deliver To"
        rules={[{required: true, message: 'Missing Recipient'}]}
      >
        <Input autoComplete={'off'} placeholder="Reason"/>
      </Form.Item>

      <Form.Item

        name="reason"
        label="Reason"
        rules={[{required: true, message: 'Missing Recipient'}]}
      >
        <Input autoComplete={'off'} placeholder="Reason"/>
      </Form.Item>
      <Form.List name="orderItems" style={{marginBottom: "0px"}}>
        {(fields, {add, remove}) => (<>
            {fields.map(({key, name, fieldKey, ...restField}, index) => (
              <Row gutter={[8, 0]} align={"stretch"} justify={"start"} style={{width: '100%'}}>
                <Col xs={24} sm={24} md={10} lg={10} xl={10} flex={"auto"}>
                  <Form.Item
                    {...restField}

                    style={{marginBottom: '5px', flexGrow: 1}}
                    name={[name, 'product']}
                    fieldKey={[fieldKey, 'product']}
                    rules={[{required: true, message: 'Missing product'}]}>
                    <AutoComplete
                      allowClear
                      onChange={(value) => updateForm(index, 'product', value)}
                      placeholder={"Product name"}>
                      {list.map((item, index) => <Option key={index} value={item.name}>{item.name}</Option>)}
                    </AutoComplete>
                  </Form.Item>
                </Col>
                {showItem('orderItems', index, 'product', <Col xs={24} sm={24} md={10} lg={10} xl={10} flex={"auto"}>
                  <Row gutter={[8, 0]}>
                    <Col xs={22} sm={22} md={22} lg={22} xl={22} flex={"auto"}>
                      <Form.Item
                        {...restField}
                        shouldUpdate

                        style={{marginBottom: '5px', flexGrow: 1}}
                        name={[name, 'qty']}
                        fieldKey={[fieldKey, 'qty']}
                        rules={[{required: true, message: 'Missing quantity'}]}>
                        <Select
                          mode="multiple"
                          style={{alignSelf: 'center'}}
                          placeholder="Quantity"
                          onChange={(value) => updateForm(index, 'qty', value)}
                          allowClear>
                          {list[index].quantities.map((item, index) => <Option key={index}
                                                                               value={item}>{item}</Option>)}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2} flex={"auto"}>
                      <Form.Item
                        style={{marginBottom: '5px', alignSelf: 'center'}}>
                        <Button shape={'circle'} size={"middle"} style={{marginLeft: 10, alignSelf: 'center'}}
                                    onClick={() => remove(name)} icon={<DeleteOutlined/>}/>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>)}


              </Row>))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                Add More
              </Button>
            </Form.Item>
          </>)}
      </Form.List>
    </Form>)
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(NewPatentCard)
