/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Avatar, Button, Spin} from 'antd'
import {createOrderNote, createProgressNote} from '../../../../app/Reducers'
import {appLog, emptyFun, emptyTable, executeChunkFn, skelBars} from "../../../../common/helpers";
import dayjs from 'dayjs'
import {apiRequest} from '../../../../app/Apis'
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {LoadingOutlined, UserOutlined} from '@ant-design/icons';

const OrderTimeLine = ({ order }) => {
  const [isExecuting, setisExecuting] = useState(false)
  const [orderNotes, setOrderNotes] = useState("")
  const can_update_treatment_order = usePermissionCheck('update_treatment_order', false, [])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    fetchUpdates().catch(appLog)
    return emptyFun
  }, [])
  
  const [activities, setactivities] = useState([])
  const fetchUpdates = async () => {
    await setLoading(true)
    const response = await apiRequest.get(`/order/${order?.id}/activity-log`).catch(err => console.log(err))
    if (response?.data.data) {
     await setactivities(response.data.data)
    }
    await setLoading(false)
  }
  
  
  const dispatch = useDispatch()
  const onOrderNote = async () => {
    let payload = { note: orderNotes, treatment_order_id: order?.id }
    await executeChunkFn(dispatch, createOrderNote, payload, setisExecuting, null,null)
    await fetchUpdates()
    clearInput()
  }
  
  
  
  const clearInput = () => {
    setOrderNotes("")
  };
  
  return (
    <div className="shadow-card-box flex flex-col">
      <div className="flex  w-full">
        <p className="font-semibold text-xl">Last 30 Days updates</p>
      </div>
      {(can_update_treatment_order && !loading) && <div className="my-0 w-full flex flex-row  h-20">
        <div className="flex flex-col items-end justify-start content-center w-1/5  py-8 px-4">
          <p className="text-[14px] font-semibold text-rigth my-auto">Today</p>
        </div>
        {activities[0] && <div className={' h-full flex flex-col items-center bg-white'}>
          <div className={'w-[0.2px] bg-transparent flex-1'}></div>
          <div className={'w-[0.2px] bg-gray-500 flex-1'}></div>
        </div>}
        <div className="add-note bg-white flex-1 flex flex-row items-center  py-8">
          {activities[0] && <div className={'w-[30px] bg-gray-500 h-[0.2px]'}></div>}
          <div className="flex-1 h-10 items-center flex rounded-lg bg-white border-[0.1px] border-gray-400 border-solid">
            <input disabled={isExecuting}
              type="text" value={orderNotes}
              onChange={event => setOrderNotes(event.target.value)}
              className="w-full h-full py-4 px-4 text-[14px] text-black rounded-lg outline-none border-0"
              placeholder="Add note..."
            />
            {/*<i className={'h-full w-12 '}>
              <BsFillSendFill className="px-1 my-auto py-2  pt-2.5 text-[20px] h-10 w-8 text-center text-gray-500 focus:outline-none"/>
            </i>*/}
            <Button onClick={onOrderNote} disabled={isExecuting} className={'m-2'} shape={'circle'} type={'text'}
                    icon={isExecuting ? <Spin size={"small"} spinning={isExecuting} indicator={<LoadingOutlined style={{ color: 'grey' }}/>}/> :
                    <FontAwesomeIcon icon={['fas', 'paper-plane']} style={{ color: 'grey' }} />}></Button>
          </div>
        </div>
      </div>}
      
      {activities[0] ?
        activities.map((item, index) => {
          let hasNextActivity = !!activities[index + 1]
          return (
            <div key={item?.id} className={`my-0 w-full flex flex-row h-fit`}>
              <div className={`flex flex-col items-end w-1/5 px-4 py-2`}>
                <p className="text-[14px] font-semibold text-right mb-0.5 px-2">{dayjs(item.created_at).format("MMM DD, ddd YYYY")}</p>
                <p className="text-right text-[12px] text-gray-600 px-2 py-0 ">{dayjs(item.created_at).format("hh:mm a")}</p>
              </div>
              <div className={'flex flex-col items-center'}>
                <div className={'w-[0.2px] bg-gray-500 flex-1'}></div>
                <div className={`w-[0.2px] ${hasNextActivity ? 'bg-gray-500' : 'bg-transparent'} flex-1`}></div>
              </div>
              <div className=" flex-1 py-2">
                <div className="flex flex-row items-center">
                  <div className={'w-[30px] bg-gray-500 h-[0.1px]'}></div>
                  <div className="flex flex-row items-center gap-2">
                    <Avatar size={'small'} src={item.actor_profile_image_url} icon={<UserOutlined/>}
                      // className={`${!item?.actor_profile_image_url ? `bg-[${item.action === 'updated' ? '#3386c0' : item.action === 'created' ? '#87d068' : '#EF8100'}]` : ''}`}
                            {...(!item?.actor_profile_image_url && { style: { backgroundColor: item.action === 'updated' ? '#108ee9' : item.action === 'created' ? 'green' : '#EF8100' } })}
                    />
                    <p className="text-md font-semibold text-left my-auto">{item.actor}</p>
                    <p className={'my-auto text-xs'}>{item.action}</p>
                    <div className="rounded w-fit bg-gray-200 px-2">
                      <p className="font-semibold text-center my-auto text-[12px]">{item.new_values.status ? 'Status' :
                        (item?.new_values?.notes || item?.new_values?.note) ? 'Note' : 'Order'}</p>
                    </div>
                    {/* {kebabMenus()} */}
                  </div>
                </div>
                <div className={`ml-[30px] rounded w-fit my-2 bg-gray-100 px-2`}>
                  {item.new_values?.status ? <p className="text-md  text-center capitalize">{item.new_values.status}</p> :
                    item?.new_values?.note ? <p className="text-md  text-center capitalize">{item.new_values.note}</p> : null}
                </div>
              </div>
            </div>)
        }) : loading ? skelBars() : <div className="w-full flex flex-row items-center">{emptyTable()} </div>}
    
    
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OrderTimeLine)