/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Subtitle1} from '../../../../components'
import {Drawer, Grid, Table} from "antd"

import dayjs from 'dayjs'
import {
  fetchDuplicatePatientWounds,
  fetchPatientById,
  resetDuplicateWoundsPagination,
  setPatientDetailsLoading,
  squashDuplicateWounds,
  updateDuplicateWoundsFilters,
  updatePatientDetailsOwner
} from "../../../../app/Reducers";
import {containedButton, emptyFun, emptyTable, executeChunkFn, getSkels, getWoundStatus, makeAutoCompleteOptions, onTableChange, responsiveTbl} from "../../../../common/helpers";
import TableFilters from "../../../../components/InputComponents/TableFilters";
import {useSubscription} from "../../../../hooks/MqttHooks/useSubscription";
import {useLocation, useNavigate} from 'react-router-dom';
import {MergeCellsOutlined} from '@ant-design/icons';
const { useBreakpoint } = Grid;
const PatientWounds = ({ wounds, patient, appConf, navs, duplicateWoundsPagination, duplicateWoundsShowing, showDuplicateWounds, selectedWound, setSelectedWound }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState=useBreakpoint()
  const dispatch = useDispatch()
  const [isVisible, setIsVisible] = useState(false);
  const [executing, setIsExecuting] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const { message } = useSubscription(`view_patient_wounds/${patientId}`, { qos: 2, nl: true });
  const columns = [{
    title: 'Date Created', dataIndex: 'created_at', key: 'creation_date', fltr: { filterable: true, type: 'date' },
    render: (created_at) => (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(created_at).format("MM/DD/YYYY")}</Subtitle1>)
  },
    {
      title: 'Wound', dataIndex: 'location', key: 'location', fltr: { filterable: true, type: 'text' }
    },
    {
      title: 'Wound Status', dataIndex: 'status', key: 'status', fltr: {
        filterable: true, type: 'autocomplete',
        options: makeAutoCompleteOptions(['unchanged', 'improving', 'deteriorating', 'new', 'healed', 'resolved', 'stalled'])
      }, render: (status) => getWoundStatus(status?.toLowerCase())
      
    }]
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await loadWounds(true)
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    (async () => {
      await loadWounds(false)
    })().catch(e => console.log(e))
    return emptyFun
  }, [])
  const loadWounds = async (isMQMessage) => {
    await dispatch(updateDuplicateWoundsFilters([appConf.duplicateWoundsFilters[0], appConf.duplicateWoundsFilters[1], !isMQMessage]))
    //In case of back navigations
    if (!patient || (patientId !== patient.id)) {
      await dispatch(setPatientDetailsLoading(!isMQMessage))
      await dispatch(fetchPatientById(Number(patientId)))
      await dispatch(setPatientDetailsLoading(false))
      await dispatch(updatePatientDetailsOwner(patientId))
    }
    await dispatch(fetchDuplicatePatientWounds(appConf.duplicateWoundsFilters[0] ? appConf.duplicateWoundsFilters[1] : { id: selectedWound.id, patient_id: patientId }))
    await dispatch(updateDuplicateWoundsFilters([appConf.duplicateWoundsFilters[0], appConf.duplicateWoundsFilters[1], false]))
  }
  
  async function handleFilterAction(action, values) {
    await dispatch(resetDuplicateWoundsPagination())
    if (action === 'filter') {
      let pl = { filterData: { id: selectedWound.id, patient_id: patientId, ...values }, pagination: duplicateWoundsPagination }
      await dispatch(updateDuplicateWoundsFilters([false, pl, true]))
      await dispatch(fetchDuplicatePatientWounds(pl))
      await dispatch(updateDuplicateWoundsFilters([true, pl, false]))
    }
    if (action === 'reset'/* && appConf.duplicateWoundsFilters[0]*/) {
      await dispatch(updateDuplicateWoundsFilters([appConf.duplicateWoundsFilters[0], undefined, true]))
      await dispatch(fetchDuplicatePatientWounds({ id: selectedWound.id, patient_id: patientId }))
      await dispatch(updateDuplicateWoundsFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  const onSelectChange = (newSelectedRowKeys) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const squashDuplicates = async () => {
    let payload = { patient_id: patient.id, target: selectedWound.id, revisions: selectedRowKeys }
    await executeChunkFn(dispatch, squashDuplicateWounds, payload, setIsExecuting, null, () => setSelectedRowKeys([]))
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (<>
    <Drawer
      title={`Selected wound: ${selectedWound?.location} (Created on ${dayjs(selectedWound?.created_at).format("MM/DD/YYYY")})`}
      width={'50%'}
      onClose={async () => {
        await setSelectedWound(null)
        await setSelectedRowKeys([])
        showDuplicateWounds(false)
      }}
      open={(duplicateWoundsShowing && !!selectedWound)}
      styles={{body:{paddingBottom: 20} }}
      extra={null}>
      <div className="w-full">
        <Table rowSelection={rowSelection}
               rowKey={'id'}
               title={() => (<TableFilters datasource={columns} setIsVisible={setIsVisible} isVisible={isVisible}
                                           extraLeftActions={[
                                             ...(hasSelected ? [containedButton(squashDuplicates, 'Merge', executing, 'Merging', null, { icon: <MergeCellsOutlined /> })] : [])
                                           ]}
                                           handleFilterAction={handleFilterAction} filters={appConf.duplicateWoundsFilters[1]}
                                           showClear={appConf.duplicateWoundsFilters[0]} loading={appConf.duplicateWoundsFilters[2]}/>)}
               loading={appConf.duplicateWoundsFilters[2]} size={'small'} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('patient wounds.') }}
               onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchDuplicatePatientWounds, appConf.duplicateWoundsFilters, updateDuplicateWoundsFilters, {
                 id: selectedWound.id,
                 patient_id: patientId
               })}
               {...getSkels((appConf.duplicateWoundsFilters[2]), columns, wounds)} pagination={duplicateWoundsPagination}/>
      </div>
    </Drawer>
  </>)
}
const mapStateToProps = (state) => ({ wounds: state.patients.duplicateWounds, patient: state.patients.selectedPatient,
  appConf: state.appConf,
  duplicateWoundsPagination: state.patients.duplicateWoundsPagination,
  navs: state.navs
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PatientWounds)
