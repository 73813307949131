import React, {useEffect, useState} from 'react';
import './formmodal.css'
import {cleanObject, closeButton, dialogCloser, dynamicFormOnFinish, emptyFun, formSubmitButton, getInputField, horStack, outlinedButton} from "../common/helpers";
import {Col, Form, Modal, Row} from "antd";

const DynamicModalForm = (
  {
    inputFields, isDynamicFormOpen, setDynamicFormOpen, onDynamicFormEntriesResult, closeModal, isExecuting, phoneValue, setPhoneValue, defaultValues, formName,
    computedValues,
    useUpdateTerminology
  }) => {
  const [form] = Form.useForm()
  useEffect(() => {
    // console.log('computedValues: ', computedValues)
    if (!!computedValues) {
      form.setFieldsValue({ ...computedValues })
    }
    return emptyFun
  }, [computedValues])
  
  
  const onFinish = async values_ => {
    
    let values = await dynamicFormOnFinish(inputFields, values_)
    //Override default values if removed i.e. not required  during edit
    let defValues=cleanObject({ ...defaultValues,...computedValues,...values_ })
    // console.log({values, values_,defValues,computedValues })
    onDynamicFormEntriesResult({ values: { ...defValues, ...values, ...phoneValue }, actionName: formName })
    if (inputFields.exitOnFinish) {
      closeModal()
    }
  };
  
  return (
    <Modal closable={false}
           title={<div className={' px-4 w-full flex flex-row justify-between h-12 items-center shadow-md'}>
             <p className={'my-auto font-bold text-[15px]'}>{formName}</p>
             {closeButton(dialogCloser(setDynamicFormOpen), null, { type: 'text' })}
           </div>}
           rootClassName={'ModalForm'}
           open={(isDynamicFormOpen && !!inputFields.fields[0])}
      // onOk={dialogCloser(setDynamicFormOpen)}
           width={600}
      // onCancel={dialogCloser(setDynamicFormOpen)}
      // closeIcon={modalClose(dialogCloser(setDynamicFormOpen))}
           closeIcon={null}
           maskClosable={false}
           footer={[<div className="flex flex-row px-4 mt-4 justify-end w-full py-2 bg-white shadow-sm rounded-b-lg border-1 border-t-black">
             {horStack([outlinedButton(dialogCloser(setDynamicFormOpen), 'Cancel'),
               formSubmitButton('dynamicForm', useUpdateTerminology ? useUpdateTerminology[0] : 'Submit', isExecuting, useUpdateTerminology ? useUpdateTerminology[1] : 'Submitting', null, null)])} </div>]}
           destroyOnClose={true}>
      <Form
        name="dynamicForm" className={'px-2 '}
        id="dynamicForm"
        form={form}
        preserve={false}
        initialValues={{ ...defaultValues }}
        layout="vertical"
        onFieldsChange={(changedFields, allFields) => {
          // console.log({allFields, changedFields})
        }}
        onValuesChange={(changedValues, allValues) => {
          // console.log({allValues, changedValues})
        }}
        onFinish={onFinish}>
        {/*<Divider className={'mb-4'}/>*/}
        {inputFields.inputCardGroups && Object.keys(inputFields.inputCardGroups).map((key, index) => {
          return <div className={"shadow-card-box m-0 mb-4 w-full"}><Row key={index} gutter={[4, 0]} align={"bottom"} justify={"start"} className={'w-full'}>
            {inputFields.inputCardGroups[key].map((field, index) => field.fullRow ?
              <Col key={index} xs={24} sm={24} md={24} lg={24} xl={24} flex={"auto"} style={{ width: '100%' }}>{getInputField(form, field, phoneValue, setPhoneValue)}</Col> :
              <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}>{getInputField(form, field, phoneValue, setPhoneValue)}</Col>)}
          </Row>
          </div>
        })}
        {(inputFields.fields && !inputFields.inputCardGroups) && <div className={"shadow-card-box m-0 mb-4 w-full"}><Row gutter={[4, 0]} align={"bottom"} justify={"start"} className={'w-full m-0'}>
          {inputFields.fields.map(field => field.fullRow ?
            <Col xs={24} sm={24} md={24} lg={24} xl={24} flex={"auto"} style={{ width: '100%' }}>{getInputField(form, field, phoneValue, setPhoneValue)}</Col> :
            <Col xs={24} sm={24} md={12} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}>{getInputField(form, field, phoneValue, setPhoneValue)}</Col>)}
        </Row></div>}
      
      </Form>
    </Modal>
  );
};

export default DynamicModalForm;
