import React, {useEffect, useState} from 'react';
import {Col, DatePicker, Form, Row, Select} from "antd";
import {CSVDownload} from 'react-csv';
import {connect, useDispatch} from 'react-redux';
import {formSubmitButton, horStack, makeSelectOptions, outlinedButton} from '../../../common/helpers';
import {apiRequest} from '../../../app/Apis';
import _ from 'lodash';
import dayjs from 'dayjs';
import {getToastId} from '../../../app/Reducers/reducerUtils';
import {toast} from 'react-toastify';
import {Headline6} from '../../../components';
import {useFacilitySearch} from '../../../hooks/useFacilitySearch';
// export const ExportToCsvButton = ({ data }) => {
//   return (
//     <CSVLink data={data} filename={"schedule.csv"}>
//       <Button size={'middle'} className='mr-2'
//         icon={<Avatar shape="square" size={'default'} src={<FontAwesomeIcon size="lg" color="black" icon={["fas", 'file-csv']} />} />}>
//       </Button>
//     </CSVLink>
//   )
// }

 const ExportToCsvForm = ({  setExportForm ,activeUser}) => {
  const { RangePicker } = DatePicker;
  const [patientOptions, setpatientOptions] = useState([])
  const [providerOptions, setProviderOptions] = useState([])
  const [selectedConsultant, setSelectedConsultant] = useState(null)
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [selectedFacility, setSelectedFacility] = useState(null)
  const [isExecuting, setisExecuting] = useState(false)
  const [CSVData, setCSVData] = useState(null)
  const [exportForm] = Form.useForm();
  
  const dispatch = useDispatch()
   
   const [facilityOptions, debouncedFacilitiesSearch,facilitySearchLoading] = useFacilitySearch([], 'id')
  
  const [docHeight, setDocHeight] = useState(`${window.innerHeight * 0.8}px`);
  useEffect(() => {
    function handleResize() {
      setDocHeight(`${window.innerHeight * 0.8}px`)
    }
    
    window.addEventListener('resize', handleResize)
    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })
  
  const handlePatientsearch = async (value) => {
    const { data } = await apiRequest.get(`/patients?search=${value}`)
    if (data) {
      setpatientOptions(data.data.filter(item => item.status === "active" || item.status === "hospitalized").map(item => ({ label: item.name, value: item.name, id: item.id })));
    }
  };
  
  const handleProvidersearch = async (value) => {
    console.log(value)
    if (value) {
      setProviderOptions([])
      const { data } = await apiRequest.get(`/consultants?search=${value}`)
      if (data) {
        setProviderOptions(_.uniqBy(data.data.map(item => ({ label: item.name, value: item.name, id: item.id })), 'id'));
      }
    } else {
      setProviderOptions([])
    }
  };
  
  const onSelectConsultant = async (value) => {
    setSelectedConsultant(value)
  }
  
  const onSelectPatient = async (value) => {
    setSelectedPatient(value)
  }
  
  const onSelectFacility = async (value) => {
    setSelectedFacility(value)
  }
  
  async function onFinish(values) {
    const dateRange = values.schedule_range ? values.schedule_range.map(item => dayjs(item).format('YYYY-MM-DD')) : [];
    const scheduleFor = values.schedule_for ? dayjs(values.schedule_for).format('YYYY-MM-DD') : undefined;
    const patient = values.patient ? patientOptions.find(item => item.label === values.patient).id : undefined;
    const consultant = values.consultant_id ? providerOptions.find(item => item.value === values.consultant_id).id : undefined;
    const facility =  activeUser?.facilityProfile ? activeUser?.facilityProfile?.id:selectedFacility?.id
    
    const params = {
      ...(dateRange[0] && { "scheduledRange[start_at]": dateRange[0], "scheduledRange[end_at]": dateRange[1] }),
      ...(scheduleFor && { scheduleFor }),
      ...(facility && { facility }),
      ...((consultant) && { consultant_id: consultant }),
      ...((values.status) && { status: values.status }),
      ...((values.schedule_type) && { scheduleType: values.schedule_type })
    }
    
    // Filter out undefined values
    Object.keys(params).forEach(key => params[key] === undefined && delete params[key]);
    
    
    setisExecuting(true)
    // Convert parameters object to a URL query string
    const toastId = getToastId('Generating CSV', 'info')
    const response = await apiRequest.get('/appointment-export',{params})
    if (response.status === 200) {
      toast.update(toastId, { render: 'CSV Generated', type: 'success', autoClose: 2000 })
      const data = new Blob([response.data], { type: 'text/csv' });
      const urlBlob = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', 'schedule.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
      setisExecuting(false)
      setExportForm(false)
      exportForm.resetFields()
    } else {
      toast.error('Failed to export to csv', { toastId })
      setisExecuting(false)
    }
  }
  
  return (
    <div className="" style={{
      flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', alignContent: 'center', width: '100%', justifyItems: 'center',
      background: 'white'
    }}>
      <Row style={{
        width: '100%', background: 'white', borderStyle: 'solid', borderRadius: 6, borderColor: 'gainsboro', borderWidth: 1,
        boxShadow: '6px 6px 8px gainsboro', maxHeight: docHeight, overflowY: 'auto',
        margin: 8, padding: 8
      }}>
        <Col flex={"auto"} style={{ width: '100%' }}>
          <Headline6 className="p-4">Export Schedule to CSV</Headline6>
          <Form
            form={exportForm}
            style={{ width: '100%' }}
            labelCol={{ sm: 24, xs: 24, md: 24, lg: 24, xl: 24, xxl: 24 }}
            name="exportToCsvForm"
            layout={'vertical'}
            initialValues={{ facility: activeUser?.facilityProfile ? activeUser?.facilityProfile.name : undefined }}
            onFinish={onFinish}
            id="exportToCsvForm">
            <Form.Item
              label="Schedule Range"
              name="schedule_range"
              style={{ width: '100%' }}>
              <RangePicker style={{
                width: '100%'
              }}/>
            </Form.Item>
            <Form.Item
              label="Schedule For"
              name={'schedule_for'}
              style={{ width: '100%' }}>
              <DatePicker style={{ width: '100%' }}/>
            </Form.Item>
            <Form.Item style={{ width: '100%' }} label="Office" name="facility">
              <Select
                disabled={!!activeUser?.facilityProfile}
                options={facilityOptions}
                onSearch={(value) => debouncedFacilitiesSearch(value)}
                placeholder="Search For Office"
                loading={facilitySearchLoading}
                onSelect={(value,option) => {
                  console.log({value,option})
                  return onSelectFacility(option);
                }}
                showSearch
              />
            </Form.Item>
            <Form.Item style={{ width: '100%' }} label="Consultant" name="consultant_id">
              <Select
                showSearch
                options={providerOptions}
                onSearch={(value) => handleProvidersearch(value)}
                placeholder="Search Consultants"
                onSelect={(value) => onSelectConsultant(value)}
                value={selectedConsultant}
              />
            </Form.Item>
            <Form.Item style={{ width: '100%' }} label="Patient" name="patient">
              <Select
                options={patientOptions}
                onSearch={(value) => handlePatientsearch(value)}
                placeholder="Search patients"
                onSelect={(value) => onSelectPatient(value)}
                value={selectedPatient}
                showSearch
              />
            </Form.Item>
            <Form.Item style={{ width: '100%' }} label="Appointment status" name="status">
              <Select
                options={makeSelectOptions(['scheduled', 'rescheduled', 'cancelled_by_patient', 'cancelled_by_wcc', 'missed', 'completed', 'in_progress', 'missed_by_wcc', 'missed_by_patient', 'visited'])}/>
            </Form.Item>
            {horStack([
              outlinedButton(() => setExportForm(false), 'Cancel', isExecuting),
              formSubmitButton('exportToCsvForm', 'Export to CSV', isExecuting, 'Exporting')
            ])}
          </Form>
          {!!CSVData && <CSVDownload data={CSVData} target="_blank"/>}
          {!!CSVData && <p>CSV Ready</p>}
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = (state) => ({
  activeUser: state.auth.activeUser
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ExportToCsvForm)
