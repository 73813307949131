import { Avatar } from 'antd'
import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RegularButton, Subtitle1 } from './general'
import SDK from '../SDK'
import { acceptCall, rejectCall } from '../app/Reducers'
import { emptyFun } from "../common/helpers";
import { useClient } from '../content/Chat/Components/Video/Settings'
export const IncommingCall = ({ incomingCall, onCancel, acceptCall, initiator }) => {
    const client = useClient()

    const dispatch = useDispatch()
    const [userProfileData, setuserProfileData] = useState(null)
    const [audio] = useState(new Audio('sounds/ring1.mp3'));
    const [playing, setPlaying] = useState(false);
    useEffect(() => {
       
        audio.play()
        audio.loop = true
        return emptyFun
    }, [])


    const takeCall = () => {
        audio.pause()


        audio.loop = false
        audio.pause()
        acceptCall()
        onCancel()
    }
    const declineCall = async () => {
        audio.pause()
      
       
        audio.loop = false
        audio.pause()
        onCancel()
    }
  
    return (
        <div className="incomming-call">
            <Avatar src={"https://joeschmoe.io/api/v1/random"} alt="Han Solo" />
            <Subtitle1 color='white'>Incomming call from {initiator} </Subtitle1>
            <div className="flex-boy-row-space" style={{ width: "100%" }}>
                <RegularButton onClick={takeCall} background="teal">Accept</RegularButton>
                <RegularButton onClick={declineCall}>Decline</RegularButton>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    incomingCall: state.chat.incomingCall
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(IncommingCall)
