import { createContext, useCallback, useContext, useEffect, useState, } from 'react';
import { useChatContext } from 'stream-chat-react';
const Context = createContext({
    handleInputChange: () => null,
    handleMemberSelect: () => null,
    handleSubmit: () => null,
    members: [],
    name: '',
    errors: { name: null, members: null },
});
const getChannelTypeFromWorkspaceName = (workspace) => workspace.match(/.*__(team|messaging)/)?.[1];
const getUpsertAction = (workspace) => {
    if (workspace.match('Channel-Create'))
        return 'create';
    if (workspace.match('Channel-Edit'))
        return 'update';
};
export const AdminPanelForm = ({ children, defaultValues, workspace, onSubmit }) => {
    const { client, channel, setActiveChannel } = useChatContext();
    const [name, setChannelName] = useState(defaultValues.name);
    const [members, setMembers] = useState(defaultValues.members);
    const [errors, setErrors] = useState({ name: null, members: null });
    const createChannelType = getChannelTypeFromWorkspaceName(workspace);
    const action = getUpsertAction(workspace);
    const createChannel = useCallback(async ({ name, members }) => {
        if (!createChannelType || members.length === 0)
            return;
        const newChannel = await client.channel(createChannelType, name, {
            name,
            members,
            demo: 'team',
        });
        await newChannel.watch();
        setActiveChannel(newChannel);
    }, [createChannelType, setActiveChannel, client]);
    const updateChannel = useCallback(async ({ name, members }) => {
        if (name !== (channel?.data?.name || channel?.data?.id)) {
            await channel?.update({ name }, { text: `Channel name changed to ${name}` });
        }
        if (members?.length) {
            await channel?.addMembers(members);
        }
    }, [channel]);
    const validateForm = useCallback(({ action, createChannelType, values }) => {
        let errors = { name: null, members: null };
        if (action === 'create') {
            errors = {
                name: !values.name && createChannelType === 'team' ? 'Channel name is required' : /\s/.test(values.name)? "Channel name cant have spaces" : null,
                members: values.members.length < 2 ? 'At least one additional member is required' : null,
            };
        }
        if (action === 'update' && values.name === defaultValues.name && values.members.length === 0) {
            errors = {
                name: 'Name not changed (change name or add members)',
                members: 'No new members added (change name or add members)',
            };
        }
        return Object.values(errors).some(v => !!v) ? errors : null;
    }, [defaultValues.name]);
    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        const errors = validateForm({ values: { name, members }, action, createChannelType });
        if (errors) {
            setErrors(errors);
            return;
        }
        try {
            if (action === 'create')
                await createChannel({ name, members });
            if (action === 'update')
                await updateChannel({ name, members });
            onSubmit();
        }
        catch (err) {
            console.error(err);
        }
    }, [action, createChannelType, name, members, createChannel, updateChannel, onSubmit, validateForm]);
    const handleInputChange = useCallback((event) => {
        event.preventDefault();
        setChannelName(event.target.value);
    }, []);
    const handleMemberSelect = useCallback((event) => {
        setMembers((prevMembers) => {
            const { value } = event.target;
            if (event.target.checked) {
                return prevMembers.length ? [...prevMembers, value] : [value];
            }
            return prevMembers?.filter((prevUser) => prevUser !== value);
        });
    }, []);
    useEffect(() => {
        setChannelName(defaultValues.name);
        setMembers(defaultValues.members);
    }, [defaultValues, createChannelType]);
    return (<Context.Provider value={{
            createChannelType,
            errors,
            name,
            members,
            handleInputChange,
            handleMemberSelect,
            handleSubmit,
        }}>
      {children}
    </Context.Provider>);
};
export const useAdminPanelFormState = () => useContext(Context);
