import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Subtitle1 } from '../../../../components'
import { Select } from 'antd';
import dayjs from 'dayjs';
import { FileImageOutlined } from '@ant-design/icons';

export const WoundComparison = ({ wound, progress }) => {
    const [firstWound, setfirstWound] = useState(null)
    const [secondWound, setSecondWound] = useState(null)
    const { Option } = Select;
    const handleChange = (value) => {
        setfirstWound(value)
    };
    const handleChange2 = (value) => {
        setSecondWound(value)
    };
    return (
        <div className='flex-boy-row-space' style={{ width: "100%" }}>
            <div style={{ flex: 1, textAlign: 'left', margin: '10px' }}>
                <Subtitle1 style={{ textAlign: 'left' }}>Select Visit #1</Subtitle1>
                <Select

                    style={{
                        width: "100%",
                    }}
                    onChange={handleChange}
                >
                    {progress.filter(date => date.name !== secondWound).map((item, index) => <Option key={index} value={item.name}>{dayjs(item.name).format("YYYY-MM-DD")}</Option>)}
                    {/* {progress.map((item, index) => <Option key={index} value={item.name}>{dayjs(item.name).format("YYYY-MM-DD")}</Option>)} */}
                </Select>
                {firstWound && progress.find(item => item.name === firstWound)?.image_url ?
                    <div style={{ position: 'relative', width: '100%', marginTop: '5px', height: '450px' }}>
                        <img src={progress.find(item => item.name === firstWound)?.image_url} style={{ width: '100%', height: '100%' }} alt="firstWound" />

                        <div style={{ position: 'absolute', left: '5%', bottom: '5%', backgroundColor: "#404040a4", padding: '5px', borderRadius: '5px', color: 'white' }}>{firstWound}</div>
                    </div>




                    : <div className='flex-boy-row' style={{ marginTop: '10px', width: '100%', height: '450px', background: 'whitesmoke', justifyContent: 'center', alignItems: 'center' }}>
                        <FileImageOutlined style={{ fontSize: '60px', color: 'grey' }} />
                    </div>}
            </div>
            <div style={{ flex: 1, textAlign: 'left', margin: '10px' }}>
                <Subtitle1 style={{ textAlign: 'left' }}>Select Visit #2</Subtitle1>
                <Select

                    style={{
                        width: "100%",
                    }}
                    onChange={handleChange2}
                >
                    {progress.filter(date => date.name !== firstWound).map((item, index) => <Option key={index} value={item.name}>{dayjs(item.name).format("YYYY-MM-DD")}</Option>)}
                    {/* {progress.map((item, index) => <Option key={index} value={item.name}>{dayjs(item.name).format("YYYY-MM-DD")}</Option>)} */}
                </Select>
                {secondWound && progress.find(item => item.name === secondWound)?.image_url ?
                    <div style={{ position: 'relative', width: '100%', marginTop: '5px', height: '450px' }}>
                        <img src={progress.find(item => item.name === secondWound)?.image_url} style={{ width: '100%', height: '100%' }} alt="secondWound" />

                        <div style={{ position: 'absolute', left: '5%', bottom: '5%', backgroundColor: "#404040a4", padding: '5px', borderRadius: '5px', color: 'white' }}>{secondWound}</div>
                    </div> :
                    <div className='flex-boy-row' style={{ marginTop: '10px', width: '100%', height: '450px', background: 'whitesmoke', justifyContent: 'center', alignItems: 'center' }}>

                        <FileImageOutlined style={{ fontSize: '60px', color: 'grey' }} />
                    </div>}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(WoundComparison)