/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Body1, Subtitle1 } from '../general'
import { Divider, Menu, Switch } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { setVisibilty } from '../../app/Reducers/mapSlice';
import { appLog } from "../../common/helpers";

const { SubMenu } = Menu;
const regions = {
  western: ["California"], eastern: ["Texas"]
}
const MapMenu = ({ patients, consultants, facilities, consultants_visibility, patients_visibility, facilities_visibility }) => {
  const dispatch = useDispatch();
  const [isCollapsed, setCollapsed] = useState(true)
  const treeData = [{
    title: 'Western', key: 'western', children: regions.western.map(item => {
      return ({ title: item, key: item, children: [] })
    })
  }, {
    title: 'Eastern', key: 'eastern', children: regions.eastern.map(item => {
      return ({ title: item, key: item, children: [] })
    })
  }]
  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };
  const onCheck = (checkedKeys, info) => {
    console.log('onCheck', checkedKeys, info);
  };
  const onSwitchChange = (e, name) => {
    //console.log({ key: name, value: e })
    dispatch(setVisibilty({ key: name, value: e }))
  }
  return (<Fragment>

    <div className="map-menu-collapsed" style={{ width: isCollapsed ? "64px" : "0px" }}
      onClick={() => setCollapsed(false)}>
      <FontAwesomeIcon onClick={() => console.log("heh")} color="grey" icon={["fas", 'map']} />
    </div>
    <div className="flex-boy-column-space collapsible map-menu"
      style={{ width: isCollapsed ? '0px' : "320px", height: "calc(100vh - 64px)" }}>

      <div className="top" style={{ opacity: isCollapsed ? 0 : 1 }}>
        <div className="header flex-boy-row-space">

          {isCollapsed ? null : <Subtitle1 color="black">Live Map</Subtitle1>}
          <div className="circle-border">
            {isCollapsed ?
              <FontAwesomeIcon onClick={() => setCollapsed(false)} color="grey" icon={["fas", 'chevron-right']} /> :
              <FontAwesomeIcon onClick={() => setCollapsed(true)} color="grey" icon={["fas", 'chevron-left']} />}
          </div>

        </div>

        <div className="switch-menu">
          <div className="flex-boy-row-space">
            <Switch
              style={{ marginRight: '10px' }}
              checkedChildren={<EyeOutlined />}
              unCheckedChildren={<EyeInvisibleOutlined />}
              defaultChecked={consultants_visibility}
              onChange={(e) => onSwitchChange(e, "consultants")}
            />
            <Subtitle1 style={{ flex: 1, textAlign: "left" }} color="black">Consultants</Subtitle1>
            <Body1 color="grey" style={{ margin: 0 }}>{JSON.stringify(consultants)}</Body1>
          </div>
          <Divider />
          <div className="flex-boy-row-space">
            <Switch
              style={{ marginRight: '10px' }}
              checkedChildren={<EyeOutlined />}
              unCheckedChildren={<EyeInvisibleOutlined />}
              defaultChecked={patients_visibility}
              onChange={(e) => onSwitchChange(e, "patients")}
            />
            <Subtitle1 style={{ flex: 1, textAlign: "left" }} color="black"> Patients</Subtitle1>
            <Body1 color="grey" style={{ margin: 0 }}>{patients ? patients : 0}</Body1>
          </div>
          <Divider />
          <div className="flex-boy-row-space">
            <Switch
              style={{ marginRight: '10px' }}
              checkedChildren={<EyeOutlined />}
              unCheckedChildren={<EyeInvisibleOutlined />}
              defaultChecked={facilities_visibility}
              onChange={(e) => onSwitchChange(e, "facilities")}
            />
            <Subtitle1 style={{ flex: 1, textAlign: "left" }} color="black">Offices</Subtitle1>
            <Body1 color="grey" style={{ margin: 0 }}>{facilities ? facilities : 0}</Body1>
          </div>
          <Divider />
          {/* <div className=" flex-boy-row-space">
                            <Switch
                                style={{ marginRight: '10px' }}
                                checkedChildren={<EyeOutlined />}
                                unCheckedChildren={<EyeInvisibleOutlined />}
                                defaultChecked
                            />
                            <Subtitle1 style={{ flex: 1, textAlign: "left" }} color="black"> Missed Appointments</Subtitle1>
                            <Body1 color="grey" style={{ margin: 0 }}>50</Body1>
                        </div>
                        <Divider /> */}
        </div>

        {/* <Subtitle1 style={{ flex: 1, textAlign: "left", marginLeft: "10px", marginBottom: "20px" }} color="black">View Regions</Subtitle1> */}
        {/* <Tree
                        checkable
                        defaultCheckedKeys={['western', 'California', 'eastern', 'Texas']}
                        onSelect={onSelect}
                        onCheck={onCheck}
                        treeData={treeData}
                    /> */}

      </div>


      <div style={{ width: "320px" }}></div>

    </div>
  </Fragment>)
}
const mapStateToProps = (state) => ({ regions: state.map.regions, consultants: state.map.consultants.count,
  patients: state.map.patients.count, facilities: state.map.facilities.count, patients_visibility: state.map.patients.visible,
  consultants_visibility: state.map.consultants.visible, facilities_visibility: state.map.facilities.visible
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(MapMenu)
