import { useEffect, useMemo, useState } from 'react';
import { Avatar, useChatContext } from 'stream-chat-react';
import { Input, Space } from 'antd';

import { Subtitle1 } from '../../../../components';
import { useAdminPanelFormState } from './context/AdminPanelFormContext';
import { ValidationError } from './ValidationError';
const ListContainer = (props) => {
  const { Search } = Input;
    const { children, onSearch, isnew } = props;
    const { errors, createChannelType } = useAdminPanelFormState();
    const showHeading = !createChannelType || createChannelType === 'team';
  return (
    <div className='user-list__container'>
      {!isnew && <div className="p-4 text-left" >
        <Subtitle1 className='text-left mb-2' style={{ textAlign: 'left' }}>Current Members</Subtitle1>
        
       
        <p className='font-semibold text-sm'>{props.channelMembers.map(member => member?.user?.name).toString().replace(/,/g, ', ')}</p>
      </div>}
      
      {showHeading && <h2><span>Add Members</span><ValidationError errorMessage={errors.members} /></h2>}
  <div className="p-4">
      <Search
        placeholder="Search for members"
        allowClear
        onSearch={onSearch}
        style={{
          width: "50%",
        }}
      />
      </div>
        <div className='user-list__header user-list__row'>
          <div className='user-list__column-block'>
            <p>User</p>
            <p className='user-list__column--last-active'>Last Active</p>
          </div>
          <div className='user-list__column--checkbox'>
            <p>Invite</p>
          </div>
        </div>
        {children}
      </div>
  );
};
const MOCKED_LAST_ACTIVE_STRINGS = [
    '12 min ago',
    '27 min ago',
    '6 hours ago',
    '14 hours ago', 
];
const UserItem = ({ index, user }) => {
    const { handleMemberSelect } = useAdminPanelFormState();
    const lastActive = MOCKED_LAST_ACTIVE_STRINGS[index] || 'Yesterday';
    const title = user.name || user.id;
    return (<label htmlFor={user.id} title={title} className='user-list__row'>
      <div className='user-list__column-block'>
        <div className='user-list__column--user-data'>
          <Avatar image={user.image} name={title} size={32}/>
          <p className='user-item__name'>{title}</p>
        </div>
        <p className='user-list__column--last-active'>{lastActive}</p>
      </div>
      <div className='user-list__column--checkbox'>
        <input type='checkbox' name='members' id={user.id} value={user.id} onChange={handleMemberSelect}/>
      </div>
    </label>);
};
const LOAD_STATE_NOTIFICATION = {
    empty: 'No users found.',
    error: 'Error loading, please refresh and try again.',
    loading: 'Loading users...',
};
export const UserList = ({isnew}) => {
    const { client, channel } = useChatContext();
    const { createChannelType } = useAdminPanelFormState();
    const [loadState, setLoadState] = useState(null);
    const [users, setUsers] = useState();
    const channelMembers =  useMemo(() => channel?.state.members
        ? Object.keys(channel.state.members)
      : [], [channel?.state?.members]);
  
  
  
  const channelMemberNames =  useMemo(() => channel?.state.members 
    ? Object.values(channel.state.members)
    : [], [channel?.state?.members]);
  
  const getUsers = async () => {
    if (loadState)
      return;
    setLoadState('loading');
    try {
      const response = channelMembers.length ? await client.queryUsers({ id: { $nin: channelMembers } }, { id: 1 }, { limit: 100 }) : await client.queryUsers({  }, { id: 1 }, { limit: 100 })
      if (response.users.length) {
        setUsers(response.users);
      }
      else {
        setLoadState('empty');
      }
    }
    catch (event) {
      setLoadState('error');
    }
    setLoadState(null);
  };



  const searchUsers = async (val) => {
    if (!val.length) {
     getUsers()
      
    }
     

    try {
      const response = channelMembers.length ? await client.queryUsers({ name: { $autocomplete: val }, id: { $nin: channelMembers } }, { limit: 100 }) : await client.queryUsers({ name: { $autocomplete: val } }, { limit: 100 })
      if (response.users.length) {
        setUsers(response.users);
      }
      else {
        setLoadState('empty');
      }
    }
    catch (event) {
      setLoadState('error');
    }
    setLoadState(null);
  };

    useEffect(() => {
       
        if (client)
          getUsers();
      // searchUsers()
    }, [client, channelMembers, createChannelType]); // eslint-disable-line react-hooks/exhaustive-deps
  
  
  
  
    return (<ListContainer isnew={isnew} onSearch={searchUsers} channelMembers={channelMemberNames}>
      {loadState
            ? <div className='user-list__message'>{LOAD_STATE_NOTIFICATION[loadState]}</div>
            : users?.length && users.map((user, i) => <UserItem index={i} key={user.id} user={user}/>)}
    </ListContainer>);
};
