/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Subtitle1 } from '../../components'
import HeaderTop from '../../components/HeaderTop'
import { Grid, Modal, Spin, Table, Tabs, Tag, Tooltip } from 'antd';
import EditProspectCard from './components/EditProspectCard';
import {
  approveProspect,
  archivePatient,
  declineProspect,
  fetchPatients,
  fetchProspects,
  resetPatientModuleState,
  resetPatientNavs,
  resetPatientPagination,
  resetProspectPagination,
  restorePatient,
  selectPatient,
  setPatientNavs,
  setProspectNotificationOpen,
  updatePatientFilters,
  updateProspectFilters
} from '../../app/Reducers';
import ProspectPreviewCard from './components/ProspectPreviewCard';
import dayjs from 'dayjs';
import {
  appConfirm,
  containedButton,
  dialogCloser,
  emptyFun,
  emptyTable,
  executeChunkFn,
  formSubmitButton,
  getPatientStatus,
  getProspectStatus,
  GetRawListOptions,
  getSkels,
  horStack,
  IsItemEnabled,
  makeAutoCompleteOptions,
  makeSelectOptions,
  modalClose,
  onTableChange,
  outlinedButton,
  patientAdmissionFilter,
  regionFilter,
  responsiveTbl,
  stateFilter
} from "../../common/helpers";
import TableFilters from "../../components/InputComponents/TableFilters";
import { useSubscription } from "../../hooks/MqttHooks/useSubscription";
import ImportProspectCard from './components/ImportProspectCard';
import * as Sentry from "@sentry/react";
import RcopiaReports from "./components/RcopiaMessages/RcopiaReports";
import RcopiaMessages from "./components/RcopiaMessages/RcopiaMessages";
import DynamicModalForm from "../../components/DynamicModalForm";
import NewPatientCard from './components/NewPatientForm';
import { usePermissionCheck } from '../../hooks/usePermissionCheck';
import NewReferralCard from './NewReferralCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import {useFacilitySearch} from '../../hooks/useFacilitySearch';
const { useBreakpoint } = Grid;
const PatientList = (
  {
    patients, prospects, pagination, appConf, activeUser, navs, prospectPagination,
    facility, FacilityLoading, settings, selectedProspect, prospectNotificationOpen
  }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate();
  const windowState = useBreakpoint()
  const can_view_patient = usePermissionCheck('view_patient', false, [])
  const can_delete_patient = usePermissionCheck('delete_patient', false, [])
  const can_create_patient = usePermissionCheck('create_patient', false, [])
  const can_create_prospect = usePermissionCheck('create_prospect', false, [])
  const can_view_prospect = usePermissionCheck('view_prospect', false, [])
  const can_view_patient_ssn = usePermissionCheck('view_patient_ssn', false, [])
  //add facility to filters if the user is a facility and maintain existing filters if any
  let defaultFilters = !!activeUser?.facilityProfile ? { filterData: { facility_id: facility?.id } } : undefined
  const [selected, setSelected] = React.useState(null);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const showPopconfirm = (selected) => setSelected(selected)
  const handleCancel = () => setSelected(null)
  const handleOk = async (record) => {
    await setConfirmLoading(true);
    if (!record.deleted_at) {
      await dispatch(archivePatient(record.id))
    } else {
      await dispatch(restorePatient(record.id))
    }
    await loadPatients(true)
    await setSelected(null)
    await setConfirmLoading(false);
  }
  const approval_statusesRaw = useSelector(state => state.masters.masterListRaw.find(item => item.system_name === 'approval_status')?.options)
  const subRegionsRaw = useSelector(state => state.masters.masterListRaw.find(item => item.system_name === 'sub_region')?.options)
  // console.log({ approval_statusesRaw })
  const approval_statuses = (!!approval_statusesRaw && !!approval_statusesRaw[0]) ? approval_statusesRaw/*.map(item => item !== 'Not Approved' ? item : 'pending')*/ : []
  //console.log({pagination})
  let onCell = (record) => ({
    onClick: () => {
      (async () => {
        if (record.id !== navs.patientDetailsOwner) {
          await dispatch(resetPatientModuleState())
        }
        await dispatch(resetPatientNavs())
        await dispatch(selectPatient({ record: record }))
        if (can_view_patient) {
          navigate(`/patients/${record.id}`)
        }
      })().catch(e => console.log(e))
    }
  })
  
  
  const [facilityOptions, debouncedFacilitiesSearch,facilitySearchLoading] = useFacilitySearch([], 'id')

  const prospectColumns = [{
    title: 'First & Last Name', /*dataIndex: 'name',*/ key: 'name',
    fltr: { filterable: true, type: 'text' }, visible: true, render: (record) => (<>
      <div className="flex-boy-row-space" style={{ justifyContent: 'normal' }}><Subtitle1
        style={{ textAlign: "left", marginLeft: '10px' }}>{record?.name || `${record.first_name || ''} ${record.last_name || ''}`}</Subtitle1></div>
    </>)
  }, {
    title: 'Region', dataIndex: 'region', key: 'regionName',
    fltr: { ...regionFilter, filterable: !activeUser?.facilityProfile }, visible: !activeUser?.facilityProfile
  }, {
    title: 'Sub-region', dataIndex: 'sub_region', key: 'sub_region',
    fltr: { filterable: !activeUser?.facilityProfile, type: 'autocomplete', options: makeAutoCompleteOptions(subRegionsRaw) }, visible: !activeUser?.facilityProfile
  },
  {
    title: 'Referred By', dataIndex: "referred_by", key: 'referred_by', visible: !activeUser?.facilityProfile,
    render: (referred_by) => referred_by || 'Unknown',
    fltr: { filterable: !activeUser?.facilityProfile, type: 'select', options: makeSelectOptions(['Office', 'Internal', 'PCC']) }
  },
  {
    title: 'Office', dataIndex: ['medical_facility', 'name'], key: 'facility',
    fltr: {
      filterable: !activeUser?.facilityProfile, type: 'select', options: facilityOptions,
      props: {
        onSearch: (value) => debouncedFacilitiesSearch(value),loading:facilitySearchLoading, showSearch: true, filterOption: false
      }
    }, visible: !activeUser?.facilityProfile
  },
  // {
  //   title: 'Referral Type', dataIndex: "referral_type", key: 'referral_type',
  //   fltr: {
  //     filterable: true, type: 'autocomplete',
  //     options: makeAutoCompleteOptions([{ label: 'Wound Care', value: 'wound care' }, { value: 'podiatry', label: 'Podiatry' }, {
  //       value: 'wound care and podiatry',
  //       label: 'Wound Care and Podiatry'
  //     }])
  //   }, visible: !activeUser?.facilityProfile
  // },
  {
    title: 'Contact Phone', dataIndex: 'phone_no', key: 'phone_no', fltr: { filterable: true, type: 'phone' },
    render: (phone_no) => <p className={'text-sm text-[#038A96]'}>{phone_no}</p>,
    visible: true
  },
  {
    title: 'Created At', dataIndex: 'created_at', key: 'created_at', fltr: { filterable: true, type: 'date' },
    render: (created_at) => (<>
      {dayjs(created_at).format('MM/DD/YYYY')}
    </>), visible: true
  },
  {
    title: 'DOB', dataIndex: 'birth_date', key: 'birth_date', fltr: { filterable: false, type: 'date' },
    render: (dob) => (<>
      {dayjs(dob).format('MM/DD/YYYY')}
    </>), visible: true
  }, {
    title: 'Referral Status',
    dataIndex: 'approval_status',
    key: 'approval_status',
    fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(['Not Approved', 'pending', 'Pending Insurance Review', 'Insurance Review Completed']) },
    visible: true,
    render: (status) => getProspectStatus(status)
  },
  ...(activeUser?.is_qa_user ? [{
    width: 15,
    visible: true,
    title: <FontAwesomeIcon color="grey" icon={['fas', 'table']} />,
    key: 'operation',
    render: (record) => <Tag className={`${(record.is_qa && activeUser?.is_qa_user) ? 'visible' : 'invisible'}`} icon={<EditOutlined />} color="#55acee">QA</Tag>
  }] : [])]
  const patientColumns = [{
    title: 'First & Last Name', /*dataIndex: 'name',*/
    key: 'name',
    fltr: { filterable: true, type: 'text' },
    visible: true,
    render: (record) => (<Subtitle1 style={{ textAlign: "left", marginLeft: '10px' }}>{record?.name || `${record.first_name || ''} ${record.last_name || ''}`}</Subtitle1>),
    onCell
  }, 
  // {
  //   title: 'Smartsheet ID', dataIndex: 'smartsheet_id', key: 'smartsheet_id',
  //   fltr: { filterable: true, type: 'text' }, visible: !activeUser?.facilityProfile, render: (smartsheet_id) => smartsheet_id || 'N/A', onCell
  // }, 
  {
    title: 'Region', dataIndex: 'region', key: 'regionName',
    fltr: { ...regionFilter, filterable: !activeUser?.facilityProfile }, visible: !activeUser?.facilityProfile, onCell
  }, {
    title: 'Sub-region', dataIndex: 'sub_region', key: 'sub_region',
    fltr: { filterable: !activeUser?.facilityProfile, type: 'autocomplete', options: makeAutoCompleteOptions(subRegionsRaw) }, visible: !activeUser?.facilityProfile, onCell
  },

  {
    title: 'Office', dataIndex: 'medical_facility', key: 'facility',
    fltr: {
      filterable: !activeUser?.facilityProfile, type: 'select', options: facilityOptions,
      props: {
        onSearch: (value) => debouncedFacilitiesSearch(value), loading:facilitySearchLoading, showSearch: true, filterOption: false
      }
    }, visible: !activeUser?.facilityProfile, onCell
  }, {
    title: 'Contact Phone', dataIndex: 'phone_no', key: 'phone_no', fltr: { filterable: true, type: 'phone' },
    render: (phone_no) => <p className={'text-sm text-[#038A96]'}>{phone_no}</p>,
    visible: true, onCell
  }, {
    title: 'DOB', dataIndex: 'birth_date', key: 'birth_date', fltr: { filterable: false, type: 'date' },
    render: (dob) => (<>
      {dayjs(dob).format('MM/DD/YYYY')}
    </>), visible: true, onCell
  },
  {
    title: 'Referred By', dataIndex: "referred_by", key: 'referred_by', visible: true,
    fltr: { filterable: true, type: 'select', options: makeSelectOptions(['Office', 'Internal']) },
    render: (referred_by => referred_by || 'Unknown')
  },
  {
    title: 'Approval Date', dataIndex: 'approved_date', key: 'approved_date',
    fltr: { filterable: true, type: 'date' },
    render: (approved_date) => approved_date ? dayjs(approved_date).format('MM/DD/YYYY') : 'N/A',
    visible: true, onCell
  },
  {
    title: 'Referral status',
    dataIndex: 'approval_status',
    key: 'approval_status',
    fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions([...approval_statuses, 'Unassigned']) },
    render: (approval_status) => approval_status || 'Unassigned',
    visible: true,
    onCell
  }, {
    title: 'Status', dataIndex: 'status', key: 'status',
    fltr: {
      filterable: true, type: 'autocomplete',
      options: makeAutoCompleteOptions(GetRawListOptions('patient_status'))?.filter(item => item.actualValue !== 'Not Approved')
    },
    visible: true, render: (status) => getPatientStatus(status), onCell
  },
  ...(can_delete_patient ? [{
    visible: true,
    fltr: { filterable: false }, render: (record) => <Tooltip title={'Archive patient'}>
      {appConfirm(!record.deleted_at ? <FontAwesomeIcon color="grey" icon={["fas", "trash"]} /> : <FontAwesomeIcon color="grey" icon={["fas", "trash-restore"]} />, selected === record.id, () => showPopconfirm(record.id),
        `Press OK to ${!record.deleted_at ? 'archive' : 'restore'} this patient.`, () => handleOk(record), handleCancel, confirmLoading)}</Tooltip>
  }] : []),
  ...(activeUser?.is_qa_user ? [{
    width: 15,
    visible: true,
    title: <FontAwesomeIcon color="grey" icon={['fas', 'table']} />,
    key: 'operation',
    render: (record) => <Tag className={`${(record.is_qa && activeUser?.is_qa_user) ? 'visible' : 'invisible'}`} icon={<EditOutlined />} color="#55acee">QA</Tag>
  }] : [])]
  const [prospectForm, setProspectForm] = useState(false)
  const [ImportprospectForm, setImportprospectForm] = useState(false)
  const [editProspect, setEditProspect] = useState(false)
  const [isPopping, setPopping] = useState(false)
  const [prospectView, setProspectView] = useState(false)
  const [prospectData, setProspectData] = useState((!!prospectNotificationOpen && !!selectedProspect) ? { ...selectedProspect } : {})
  const [prospectDecline, setProspectDecline] = useState(false)
  const [isExecuting, setisExecuting] = useState(false)
  const [creatingProspect, setCreatingProspect] = useState(false)
  const [patientForm, setPatientForm] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isPVisible, setIsPVisible] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isDeclineLoading, setDeclineLoading] = useState(false);
  const [isUpdatingProspect, setUpdatingProspect] = useState(false);
  const { message } = useSubscription(`view_patients`, { qos: 2, nl: true });
  const [otherReason, setOtherReason] = useState(null);
  const [discharge, setdischarge] = useState(false);
  // const [missingKit, setMissingKit] = useState(false);

  // const fetchNotifications = async() => {
  //   let data = await apiRequest.get("/integrations/dr_first/notifications_count").catch(err => console.log(err))
  //   if (data && data.data) {
  //     setmessageCount(data.data.length)
  //   }
  // }

  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message)
      loadPatients(true).catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  const { message: prospectMessage } = useSubscription(`view_prospects`, { qos: 2, nl: true });
  useEffect(() => {
    if (!!prospectMessage) {
      console.log('MQTT prospects MESSAGE:  ', prospectMessage)
      loadPatients(true).catch(e => console.log(e))
    }
    return emptyFun
  }, [prospectMessage])

  useEffect(() => {
    console.log('navs.patientNavs.patientsTab:  ', navs.patientNavs.patientsTab)
    loadPatients(false, true).catch(e => console.log(e))
    setIsVisible(false)
    setIsPVisible(false)
    return emptyFun
  }, [navs.patientNavs.patientsTab])

  useEffect(() => {
    console.log('navs.patientNavs.patientsTab2:  ', navs.patientNavs.patientsTab)
    loadPatients(false, true).catch(e => console.log(e))
    setIsVisible(false)
    setIsPVisible(false)
    return emptyFun
  }, [])

  /**
   *If it is MQTT update, load respective tabs in the background else reset the tab
   * Todo: Comment out the checks stopping redundant reload if you want reload on every tab change even if there is no data on the backend(Optional)
   * */
  const loadPatients = async (isMQ, isTabChange) => {
    console.log('loadPatients')
    if (navs.patientNavs.patientsTab === 'Patients') {
      let payloadData = appConf.patientFilters[0] ? appConf.patientFilters[1] : defaultFilters
      if (isMQ) {
        await dispatch(fetchPatients(payloadData))
      } else {
        //Stop redundant reload. Check that they dont have facility_id to differentiate from the FacilityPatient.js redux records
        if (isTabChange && !!patients[0] && !patients.some(item => !!item.facility_id) && !activeUser?.facilityProfile) {
          return
        }
        await fetchPatientListAfresh()
      }
    } else {
      let payloadData = appConf.prospectFilters[0] ? appConf.prospectFilters[1] : defaultFilters
      if (isMQ) {
        await dispatch(fetchProspects(payloadData))
      } else {
        //Stop redundant reload. Check that they dont have facility_id to differentiate from the FacilityPatient.js redux records
        if (isTabChange && !!prospects[0] && !prospects.some(item => !!item.facility_id) && !activeUser?.facilityProfile) {
          return
        }
        handleProspectFilterAction('reset').catch(e => console.log(e))
      }
    }
  }

  async function handleFilterAction(action, values) {
    await dispatch(resetPatientPagination())
    let common = !activeUser.facilityProfile ? {} : { facility_id: facility?.id }
    if (action === 'filter') {
      let payloadData = { filterData: { ...values, ...common }, pagination }
      await dispatch(updatePatientFilters([false, payloadData, true]))
      await dispatch(fetchPatients(payloadData))
      await dispatch(updatePatientFilters([true, payloadData, false]))
    }
    if (action === 'reset') {
      await fetchPatientListAfresh()
    }
    setIsPVisible(false)
    setIsVisible(false)
  }


  async function handleProspectFilterAction(action, values) {
    await dispatch(resetProspectPagination())
    let common = !activeUser?.facilityProfile ? {} : { facility_id: facility?.id }
    if (action === 'filter') {
      let payloadData = { filterData: { ...values, ...common }, pagination: prospectPagination }
      await dispatch(updateProspectFilters([false, payloadData, true]))
      await dispatch(fetchProspects(payloadData))
      await dispatch(updateProspectFilters([true, payloadData, false]))
    }
    if (action === 'reset') {
      await dispatch(updateProspectFilters([!!appConf.prospectFilters[0], undefined, true]))
      await dispatch(fetchProspects(defaultFilters))
      await dispatch(updateProspectFilters([false, undefined, false]))
    }
    setIsPVisible(false)
    setIsVisible(false)
  }

  let creationDateFilter = { key: 'creationDate', title: 'Date of service', fltr: { filterable: false, type: 'date' } }
  let ssnFilter = { key: 'ssn', title: 'SSN', fltr: { filterable: true, type: 'text' } }
  let consultantFilter = { key: 'consultant', title: 'Consultant', fltr: { filterable: true, type: 'text' } }

  const approve = async (approval_status) => {
    console.log("Prospect Data::", prospectData)
    console.log('appConf.prospectFilters:  ', appConf.prospectFilters)
    try {
      await executeChunkFn(dispatch, approveProspect, { id: prospectData.id, approval_status }, setIsApproveLoading, dialogCloser(setProspectView))
      await dispatch(fetchProspects(appConf.prospectFilters[0] ? appConf.prospectFilters[1] : defaultFilters))
      await dispatch(fetchPatients(appConf.patientFilters[0] ? appConf.patientFilters[1] : defaultFilters))
    } catch (e) {
      Sentry.captureException(e);
    }
  }
  const { TabPane } = Tabs;
  const TabList = ['Patients', activeUser?.facilityProfile ? "Referrals" : (IsItemEnabled(settings, 'modules', 'Prospects') && can_view_prospect) && "Referrals", ...(activeUser?.consultantProfile?.account_type === "Provider" && IsItemEnabled(settings, 'integrations', 'drfirst_integration') ? ["Notifications"] : []),
    ...(activeUser?.consultantProfile?.account_type === "Provider" && IsItemEnabled(settings, 'integrations', 'drfirst_integration') ? ["Reports"] : [])]
  const setActiveTab = async (tab) => {
    console.log('setActiveTab: ', tab)
    setIsPVisible(false)
    setIsVisible(false)
    await dispatch(setPatientNavs({ patientsTab: tab }));
    console.log("ala")
  }
  const Tabs_ = () => {
    return (<Tabs defaultActiveKey={navs.patientNavs.patientsTab} onChange={setActiveTab} tabBarStyle={{ marginBottom: 0 }}>
      {TabList.map(item => <TabPane key={item} tab={<Subtitle1>{item} {item === "Referrals" && activeUser?.facilityProfile?.pcc_enabled && "from PointClickCare"}</Subtitle1>}>
      </TabPane>)}
    </Tabs>)

  }


  useEffect(() => {
    if (activeUser?.facilityProfile) {
      fetchNewStuff()
    }
    return emptyFun
  }, [facility])

  useEffect(() => {
    if (!!prospectNotificationOpen && !!selectedProspect) {
      setProspectData(selectedProspect)
      dispatch(setProspectNotificationOpen(false))
      setProspectView(true)
    }
    return emptyFun
  }, [prospectNotificationOpen])

  const fetchNewStuff = async () => {
    // console.log("the happening on this ", navs.patientNavs.patientsTab)
    defaultFilters = !activeUser?.facilityProfile ? {} : { filterData: { facility_id: facility?.id } }
    if (navs.patientNavs.patientsTab === 'Patients') {
      await fetchPatientListAfresh()
    } else {
      handleProspectFilterAction("reset")
    }
  }
 /* useEffect(() => {
    console.log('appConf.patientFilters: ',appConf.patientFilters)
  }, [appConf.patientFilters]);*/
  const fetchPatientListAfresh = async () => {
    await dispatch(updatePatientFilters([false, undefined, true]))
    await dispatch(fetchPatients({ ...defaultFilters }))
    await dispatch(updatePatientFilters([false, undefined, false]))
  }
  const onFinish = async (values_) => {
    let values = { ...values_.values }
    //console.log({values})
    const payload = {
      id: prospectData.id,
      data: {
        ...values,
        ...(values.discharged_date && {
          discharged_date: values.discharged_date,
          rejection_reason: values.rejection_reason === "Other" ? values.rejection_reason_other : values.rejection_reason
        })
      }
    }
    await executeChunkFn(dispatch, declineProspect, payload, setDeclineLoading, setProspectDecline, () => {
      setOtherReason(null)
      setdischarge(false)
      setProspectView(false);
    })
  }
  const handleChange = (value) => {
    //console.log({value})
    setOtherReason(value);
    setdischarge(value === "SNF-100 Days")
  }
  let inputFields = {
    fields: [
      {
        name: 'rejection_reason', label: "Reason for Decline", required: true, type: 'select', fullRow: true,
        options: makeSelectOptions(["Active HMO/PPO under HH", "No hospice benefits", "No Medicare Part B", "SNF-100 Days", "Other"]), props: { onChange: handleChange }
      },
      ...(discharge ? [{ name: 'discharged_date', label: "Discharged Date", required: true, type: 'date', fullRow: true, dateFormat: "YYYY-MM-DD" }] : []),
      ...(otherReason === 'Other' ? [{ name: 'rejection_reason_other', label: 'Other reason', required: true, type: 'textarea', fullRow: true }] : [])
    ],
    actionName: "Decline Prospect"
  }


  return (
    (<div className="flexy-column h-full overscroll-y-contain overflow-hidden box-border">
      <HeaderTop child={<Tabs_ />} />
      <Modal
        title="New Patient"
        open={patientForm}
        onOk={dialogCloser(setPatientForm)}
        destroyOnClose
        width={800}
        onCancel={dialogCloser(setPatientForm)}
        closeIcon={modalClose(dialogCloser(setPatientForm))}
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setPatientForm), 'Cancel', isExecuting),
            ...(can_create_patient ? [formSubmitButton('patientForm', 'Create', isExecuting, 'Creating')] : [])])}
        </div>]}>
        <NewPatientCard setisExecuting={setisExecuting} closeModal={dialogCloser(setPatientForm)} />
      </Modal>
      <Modal
        title="New Referral"
        open={prospectForm}
        onOk={dialogCloser(setProspectForm)}
        onCancel={dialogCloser(setProspectForm)}
        destroyOnClose
        closeIcon={modalClose(dialogCloser(setProspectForm))}
        maskClosable={false}
        width={1200}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setProspectForm), 'Cancel', creatingProspect),
            formSubmitButton('new-prospect-form', 'Create', creatingProspect, 'Creating')])}
        </div>]}>
        <NewReferralCard setCreatingProspectExecuting={setCreatingProspect} closeModal={dialogCloser(setProspectForm)} />
        {/* <NewProspectCard setCreatingProspectExecuting={setCreatingProspect} closeModal={dialogCloser(setProspectForm)} /> */}
      </Modal>
      <Modal
        title={"New Referral"}
        open={ImportprospectForm}
        onOk={dialogCloser(setImportprospectForm)}
        onCancel={dialogCloser(setImportprospectForm)}
        destroyOnClose
        closeIcon={modalClose(dialogCloser(setImportprospectForm))}
        maskClosable={false}
        width={600}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setImportprospectForm), 'Cancel', isExecuting),
            formSubmitButton('new-import-prospect-form', 'Import', isExecuting, 'Creating')])}
        </div>]}>
        <ImportProspectCard setisExecuting={setisExecuting} closeModal={dialogCloser(setImportprospectForm)} />
      </Modal>
      <Modal
        title={"Edit Referral"}
        open={editProspect}
        onOk={dialogCloser(setEditProspect)}
        onCancel={dialogCloser(setEditProspect)}
        closeIcon={modalClose(dialogCloser(setEditProspect))}
        maskClosable={false}
        destroyOnClose={true}
        width={1200}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setEditProspect), 'Cancel', isUpdatingProspect),
            formSubmitButton('edit-prospect-form', 'Update', isUpdatingProspect, 'Updating')])}
        </div>]}>
        <EditProspectCard setUpdatingProspect={setUpdatingProspect} prospect={prospectData} closeModal={dialogCloser(setEditProspect)} />
      </Modal>
      {(!!prospectData && !!prospectView) &&
        <ProspectPreviewCard prospectData={prospectData} onClose={() => setProspectView(false)} prospectView={prospectView} setProspectView={setProspectView} approve={approve}
          setPopping={setPopping} isPopping={isPopping} setEditProspect={setEditProspect} isApproveLoading={isApproveLoading}
          setProspectDecline={setProspectDecline} defaultFilters={defaultFilters} />}
      <DynamicModalForm inputFields={inputFields} isDynamicFormOpen={prospectDecline} closeModal={() => {
        setProspectView(false);
        setProspectDecline(false);
      }} setDynamicFormOpen={setProspectDecline} isExecuting={isDeclineLoading} onDynamicFormEntriesResult={onFinish} />
      {['Patients', 'Prospects', 'Referrals'].includes(navs.patientNavs.patientsTab) && <div className="w-full overflow-y-auto overscroll-contain flex-1 overflow-x-hidden">
        {navs.patientNavs.patientsTab === 'Patients' && <Table
          title={() => (<TableFilters datasource={[...patientColumns, creationDateFilter, ssnFilter, stateFilter, patientAdmissionFilter, consultantFilter]}
                                      setIsVisible={setIsVisible} filters={appConf.patientFilters[1]}
                                      actionButton={!activeUser?.facilityProfile && can_create_patient && containedButton(() => setPatientForm(true), 'New Patient', false, null, null, {
                                        icon: <PlusOutlined/>
                                      })}
                                      windowState={windowState}
                                      isVisible={isVisible} showClear={appConf.patientFilters[0]} loading={appConf.patientFilters[2]}
                                      handleFilterAction={handleFilterAction}/>)}
          loading={appConf.patientFilters[2]} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('patients.') }}
          onChange={(pagination, filters,
                     sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchPatients, appConf.patientFilters, updatePatientFilters, !activeUser?.facilityProfile ? undefined : { facility_id: facility?.id })}
          rowKey={'id'}
          {...getSkels(appConf.patientFilters[2], patientColumns.filter(item => item.visible), patients)} pagination={pagination}/>}
        {(navs.patientNavs.patientsTab === 'Prospects' || navs.patientNavs.patientsTab === 'Referrals') &&
          <Table
            title={() => (<TableFilters datasource={[...prospectColumns, creationDateFilter, ssnFilter, stateFilter]}
                                        setIsVisible={setIsPVisible}
                                        filters={appConf.prospectFilters[1]}
                                        actionButton={[can_create_prospect ? containedButton(() => setProspectForm(true), `New Referral`, false, null, null,
                                          { icon: <PlusOutlined/> }) : null,
                                          activeUser?.facilityProfile && activeUser?.facilityProfile?.pcc_enabled && containedButton(() => setImportprospectForm(true), `Import Referral`, false, null, { marginLeft: '10px' }, {
                                            icon: <PlusOutlined/>
                                          })]}
                                        windowState={windowState}
                                        isVisible={isPVisible} showClear={appConf.prospectFilters[0]}
                                        loading={appConf.prospectFilters[2]}
                                        handleFilterAction={handleProspectFilterAction}/>)}
            loading={(appConf.prospectFilters[2])} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('referrals.') }}
            rowKey={'id'}
            onChange={(pagination, filters,
                       sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchProspects, appConf.prospectFilters, updateProspectFilters, !activeUser?.facilityProfile ? undefined : { facility_id: facility?.id })}
            onRow={(record) => {
              return {
                onClick: () => {
                  // console.log(navigate)
                  if (can_view_prospect) {
                    setProspectView(true)
                    // setProspect(record)
                    setProspectData(record)
                  }
                } // click row
              };
            }}
            {...getSkels(appConf.prospectFilters[2], prospectColumns.filter(item => item.visible), prospects)} pagination={prospectPagination}/>
        }
      </div>}
      
      
      {navs.patientNavs.patientsTab === 'Reports' && <RcopiaReports/>}
      {navs.patientNavs.patientsTab === 'Notifications' && <RcopiaMessages/>}
    </div>)
  );
}
const mapStateToProps = (state) => ({
  patients: state.patients.patients,
  prospects: state.patients.prospects,
  selectedProspect: state.patients.selectedProspect,
  prospectNotificationOpen: state.patients.prospectNotificationOpen,
  patientNotificationOpen: state.patients.patientNotificationOpen,
  activeUser: state.auth?.activeUser,
  pagination: state.patients.pagination,
  prospectPagination: state.patients.prospectPagination,
  appConf: state.appConf, navs: state.navs,
  facility: state.facilities.selectedFacility,
  FacilityLoading: state?.facilities.FacilityLoading,
  settings: state.settings?.settings
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PatientList)