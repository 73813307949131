import React, {useState} from 'react';
import {Grid, Table} from 'antd';
import {apiRequest} from '../../../../app/Apis';
import {appLog, emptyTable, getSkels, onTableChange} from '../../../../common/helpers';
import {connect, useDispatch} from 'react-redux';
import { useLocation, useNavigate} from 'react-router-dom';
import {fetchPatientUpdates, selectPatient, selectWound, setfacilityWoundNavs, updatePatientUpdateFilters} from '../../../../app/Reducers';
const { useBreakpoint } = Grid;
const PatientUpdates = ({ setPatients, facility,  loading, columns, filteredProspects, filterState, appConf, patientUpdatePagination, patientUpdates }) => {
  const dateFormat = 'YY-MM-DD';
  const usDateFormat = 'MM/DD/YYYY';
  const dispatch = useDispatch();
  const windowState = useBreakpoint();
  const location = useLocation()
  const navigate = useNavigate();
  const [dateValues, setDateValues] = useState(null);
  
  
  const disabledFutureDates = (current) => {
    if (!dateValues) {
      return false;
    }
    let filterByValue = filterState === 'Week' ? 7 : 30;
    const tooLate = dateValues[0] && current.diff(dateValues[0], 'days') > filterByValue;
    const tooEarly = dateValues[1] && dateValues[1].diff(current, 'days') > filterByValue;
    return !!tooEarly || !!tooLate;
  };
  
  
  async function fetchWounds(id) {
    const res = await apiRequest(`/wounds/${id}`);
    let record = res?.data?.data;
    appLog('Patient Wound Update', record);
    dispatch(selectWound({ record: record }));
  }
  
  async function fetchPatient(id) {
    const res = await apiRequest(`/patients/${id}`);
    let record = res?.data?.data;
    dispatch(selectPatient({ record: record }));
  }
  
  return (
    <div className="table facility-stats">
      <Table
        locale={{
          emptyText: !appConf?.patientUpdateFilters?.[0] ? emptyTable('patient tasks') : emptyTable('patient tasks.')
        }}
        onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchPatientUpdates, appConf.patientUpdateFilters, updatePatientUpdateFilters, {facility_id:facility.id})}
        onRow={(record) => ({
          onClick: () => {
            (async () => {
              console.log('record', record)
              await fetchWounds(record?.wound_id);
              await fetchPatient(record?.patient_id);
              // dispatch(fetchPatientById(record?.patient_id));
              dispatch(setfacilityWoundNavs({ firstTab: 'Wound Track' }));
              navigate.push({
                pathname: `/facility/wounds/${record?.patient_id}`
              });
            })().catch(appLog);
          }
        })}
        {...getSkels(appConf?.patientUpdateFilters?.[2], columns, filteredProspects.length > 0 ? filteredProspects : patientUpdates)}
        pagination={patientUpdatePagination}/>
    </div>
  );
};

const mapStateToProps = (state) => ({
  permisions: state.auth?.permisions,
  patientUpdatePagination: state.facilities?.patientUpdatePagination,
  appConf: state.appConf,
  navs: state.navs,

  patientUpdates: state.facilities?.patientUpdates,
  activeUser: state.auth?.activeUser
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PatientUpdates);
