import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Grid, Input, Modal, Table} from "antd"
import {fetchPrescriptions, fetchRcopiaNotifications, resetPrescriptionsPagination, setPrescriptionsLoaded, updatePrescriptionsFilters} from '../../../../app/Reducers';
import dayjs from 'dayjs';
import {EditFilled, EyeOutlined} from '@ant-design/icons';
import {addButton, dialogCloser, emptyFun, emptyTable, getSkels, makeAutoCompleteOptions, modalClose, onTableChange, responsiveTbl} from "../../../../common/helpers";

import Rcopia from '../PatientEncounterList/Rcopia';
import TableFilters from "../../../../components/InputComponents/TableFilters";
import {useSubscription} from "../../../../hooks/MqttHooks/useSubscription";
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
const { useBreakpoint } = Grid;
const PrescriptionSection = ({ patient, prescriptions, appConf,  navs, prescriptionsPagination }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState=useBreakpoint()
  const [allergiesForm, setAllergiesForm] = useState(false);
  // const prescriptions = useSelector(state => state.patients.healthData.prescriptions);
  // const patient = useSelector(state => state.patients.selectedPatient);
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [isExecuting, setisExecuting] = useState(false);
  const dispatch = useDispatch()
  const { Search } = Input;
  const [isVisible, setIsVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [editingForm, setEditingForm] = useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [prescriptions_, setPrescriptions] = useState(null);
  const showPopconfirm = (prescriptions) => setSelected(prescriptions.id)
  const handleCancel = () => setSelected(null)
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  const handleOk = async (id) => {
    console.log("clicked")
    await setConfirmLoading(true);
    let payload = { patient_id: patientId, prescriptions_id: id }
    await setAllergiesForm(true)
    await setSelected(null)
    await setConfirmLoading(false);
  }
  
  const { message } = useSubscription(`view_patient_prescription/${patientId}`, { qos: 2, nl: true });
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await loadPrescriptions(true)
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    (async () => {
      await loadPrescriptions(false)
    })()
    return emptyFun
  }, [])
  const loadPrescriptions = async (isMQ) => {
    await dispatch(updatePrescriptionsFilters([appConf.prescriptionsFilters[0], appConf.prescriptionsFilters[1], !isMQ]))
    await dispatch(fetchPrescriptions(appConf.prescriptionsFilters[0] ? appConf.prescriptionsFilters[1] : patientId))
    await dispatch(setPrescriptionsLoaded(patientId))
    await dispatch(updatePrescriptionsFilters([appConf.prescriptionsFilters[0], appConf.prescriptionsFilters[1], false]))
  }
  
  
  const columns = [{
    title: 'Name', dataIndex: 'drug_generic_name', key: 'genericName', fltr: { filterable: true, type: 'text' }
  }, {
    title: 'Dose(mg)', dataIndex: 'dose', key: 'doseInMg', fltr: { filterable: false, type: 'text' }
  }, {
    title: 'Description', dataIndex: 'drug_description', key: 'description', fltr: { filterable: false, type: 'text' }
  }
    , {
      title: 'Directions', dataIndex: 'directions', key: 'directions', fltr: {
        filterable: true, type: 'autocomplete', options:
          makeAutoCompleteOptions(["Every Other Day", "Two Times Weekly", "One Daily", "One Daily Morning", "One Daily Evening", "One Daily Bedtime", "Twice Daily", "Thrice Daily", "Four Times Daily", "Every 1 hour", "Every 2 hour", "Every 3 hour", "Every 4 hour",
            "Every 12 hour"])
      }
    }, {
      title: 'Route', dataIndex: 'route', key: 'route', fltr: { filterable: true, type: 'text' }
    }, {
      title: 'Pharmacy', dataIndex: 'pharmacy', key: 'pharmacy', fltr: { filterable: true, type: 'text' }
    }, {
      title: 'Created', dataIndex: 'created_date', key: 'created_date', fltr: { filterable: true, type: 'date' },
      render: (date) => (<>
        {date ? dayjs(date).format('MM/DD/YY') : "N/A"}
      </>)
    }, {
      title: 'Updated', dataIndex: 'updated_at', key: 'updated_at', fltr: { filterable: true, type: 'date' },
      render: (date) => (<>
        {date ? dayjs(date).format('MM/DD/YY') : "N/A"}
      </>)
    }, {
      title: <EditFilled style={{ color: 'gray' }}/>, fltr: { filterable: false },
      render: (record) => (<EyeOutlined onClick={() => setAllergiesForm(true)}/>)
    }]
  
  async function handleFilterAction(action, values) {
    await dispatch(resetPrescriptionsPagination())
    if (action === 'filter') {
      console.log('FILTERS:  ', JSON.stringify({ ...values, toLoad: 'prescriptions', endpoint: '/prescriptionss?patient=1' }));
      let pl = { filterData: { patient: patientId, ...values }, pagination: prescriptionsPagination }
      await dispatch(updatePrescriptionsFilters([false, pl, true]))
      await dispatch(fetchPrescriptions(pl))
      await dispatch(updatePrescriptionsFilters([true, pl, false]))
    }
    if (action === 'reset'/* && appConf.prescriptionsFilters[0]*/) {
      await dispatch(updatePrescriptionsFilters([appConf.prescriptionsFilters[0], undefined, true]))
      await dispatch(fetchPrescriptions(patientId))
      await dispatch(updatePrescriptionsFilters([false, undefined, false]))
    }
    // const response = await apiRequest.get(`/prescriptionss?patient=${patientId}`,{params:{...values, toLoad: 'prescriptions'}})
    setIsVisible(false)
  }
  
  return (
    (<div>
      <Modal
        title={editingForm ? "Edit Prescriptions" : "Add Prescriptions"}
        open={allergiesForm}
        destroyOnClose={true}
        onOk={dialogCloser(setAllergiesForm)}
        onCancel={() => {
          dialogCloser(setAllergiesForm)
          dispatch(fetchRcopiaNotifications({ is_interval: false }))
        }}
        closeIcon={modalClose(dialogCloser(setAllergiesForm))}
        maskClosable={false}
        width="80vw"
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {/* {horStack([
                      outlinedButton(dialogCloser(setAllergiesForm), 'Cancel'),

                      formSubmitButton('madicationForm', editingForm ? 'Edit' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')
                  ])
                  } */}
        </div>]}
      >
        <Rcopia/>
      </Modal>
      <div className="w-full">
        <Table
          title={() => (<TableFilters datasource={columns} setIsVisible={setIsVisible} isVisible={isVisible}
                                      handleFilterAction={handleFilterAction} filters={appConf.prescriptionsFilters[1]} windowState={windowState}
                                      actionButton={(can_update_patient_resource && !patient.deleted_at) ? addButton(() => {
                                        setEditingForm(false)
                                        setAllergiesForm(true);
                                      }) : null}
                                      showClear={appConf.prescriptionsFilters[0]} loading={appConf.prescriptionsFilters[2]}/>)}
          loading={appConf.prescriptionsFilters[2] || navs.patientDetailsLoading} size={'small'} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('prescriptionss.') }}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchPrescriptions, appConf.prescriptionsFilters, updatePrescriptionsFilters, { patient: patientId })}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
              
              } // click row
            };
          }}
          {...getSkels((appConf.prescriptionsFilters[2] || navs.patientDetailsLoading), columns, prescriptions)} pagination={prescriptionsPagination} scroll={{ x: 1000 }}/>
      </div>
    </div>)
  );
}
const mapStateToProps = (state) => ({ prescriptions: state.patients.healthData.prescriptions,
  patient: state.patients.selectedPatient, appConf: state.appConf, navs: state.navs,
  prescriptionsPagination: state.patients.prescriptionsPagination
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionSection)
