/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '../Apis';
import { arrayStateAfterDelete, newResourceArray, updatedArrayState } from '../../common/helpers';
import { consultants } from './initialState';
import { errMsg, toastUp } from './reducerUtils';
import dayjs from "dayjs"
import ReactGA from "react-ga4";
import GetCookie from '../../hooks/GetCookie';
import {mapboxToken} from '../../configure/constants';

const activeUser = GetCookie('activeUser')
const initialState = {
    ...consultants,
    selectedConsultant: activeUser ? JSON.parse(activeUser).consultantProfile : null
};
export const fetchConsultants = createAsyncThunk(
    '/consultants',
    async (requestParams, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            try {
                let state = thunkAPI.getState();
                let pagination = requestParams?.pagination ? requestParams.pagination : state.consultants.consultantsPagination;
                let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
                params.page = pagination.current
                const response = await apiRequest.get(`/consultants`,{params});
                let data = response.data.data.map((item) => ({ ...item, latlng: [item.lng, item.lat] }));
                const payload = { data, pagination: { ...pagination, total: response.data.meta.total } };
                //console.log(payload);
                resolve(payload);
            } catch (e) {
                toastUp(errMsg(e, true, 'consultants'), false);
                reject(e);
            }
        });
    }
);
export const fetchMileages = createAsyncThunk('fetchMileages', async (requestParams, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
        try {
            let state = thunkAPI.getState();
            let pagination = requestParams?.pagination ? requestParams.pagination : state.consultants.mileagesPagination;
            let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
            params.page = pagination.current
            const response = await apiRequest.get(`/consultant-trip-summaries`,{params});
            let data = response.data.data.map((item) => ({ ...item, latlng: [item.lng, item.lat] }));
            const payload = { data, pagination: { ...pagination, total: response.data.meta.total } };
            //console.log({ fetchMileages: payload });
            resolve(payload);
        } catch (e) {
            toastUp(errMsg(e, true, 'mileages'), false);
            reject(e);
        }
    });
}
);
export const fetchConsultantshedule = createAsyncThunk(
    'fetchConsultantshedule',
    async (id, thunkApi) => {
        return new Promise(async (resolve, reject) => {
            try {
                let queryString = `consultant=${id}&scheduledFor=${dayjs().format('YYYY-MM-DD')}`;
                const response = await apiRequest.get(`/appointments?${queryString}`);
                resolve(response.data.data);
            } catch (e) {
                toastUp(errMsg(e, true, 'consultant schedule'), false);
                reject(e);
            }
        });
    }
);
export const fetchVehicles = createAsyncThunk(
    '/fetchVehicles',
    async (id, thunkApi) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiRequest.get(`/vehicles`);
                resolve(response.data.data);
            } catch (e) {
                toastUp(errMsg(e, true, 'consultant schedule'), false);
                reject(e);
            }
        });
    }
);
export const fetchSpecificConsultant = createAsyncThunk(
    '/consultant',
    async (id, thunkApi) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiRequest.get(`consultants/${id}`);
                resolve(response.data.data);
            } catch (e) {
                toastUp(errMsg(e, true, 'consultant'), false);
                reject(e);
            }
        });
    }
);
export const fetchConsultantTimes = createAsyncThunk(
    '/consultantTimes',
    async (consultant, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            try {
                const availabilityDateTime = await apiRequest.get(
                    `/consultant-availability-datetimes?consultant=${consultant.id}&available=yes`
                );
                const unavailabilityDateTime = await apiRequest.get(
                    `/consultant-availability-datetimes?consultant=${consultant.id}&available=no`
                );
                resolve({
                    availabilityTimes: [
                        ...availabilityDateTime.data.data,
                        ...unavailabilityDateTime.data.data
                    ]
                });
            } catch (e) {
                toastUp(errMsg(e, true, 'consultant times'), false);
                reject(e);
            }
        });
    }
);

export const fetchConsultantDaetails = createAsyncThunk(
    '/consultantdetails',
    async (consultant, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            try {
                const availabilityDateTime = await apiRequest.get(
                    `/consultant-availability-datetimes?consultant=${consultant.id}&available=yes`
                );
                const unavailabilityDateTime = await apiRequest.get(
                    `/consultant-availability-datetimes?consultant=${consultant.id}&available=no`
                );
                const availabilityShchedule = await apiRequest.get(
                    `/consultant-availability-schedules?consultant=${consultant.id}`
                );
                const consultantvehicles = await apiRequest.get(
                    `/consultants/${consultant.id}/vehicles`
                );
                resolve({
                    schedule: availabilityShchedule.data.data,
                    availabilityTimes: [
                        ...availabilityDateTime.data.data,
                        ...unavailabilityDateTime.data.data
                    ],
                    vehicles: consultantvehicles.data.data
                });
            } catch (e) {
                toastUp(errMsg(e, true, 'consultant details'), false);
                reject(e);
            }
        });
    }
);
export const createVehicle = createAsyncThunk(
    'vehicle/create',
    async (payload, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiRequest.post(
                    `/consultants/${payload.data.consultant_id}/vehicles`,
                    payload.data
                );
                await thunkAPI.dispatch(
                    fetchConsultantVehicles(payload.data.consultant_id)
                );
                toastUp('Vehicle added successfully', true);
                //consultant_vehicle_added
                ReactGA.event({
                    category: "consultant_vehicle_added",
                    action: "consultant_vehicle_added",
                    label: "consultant_vehicle_added",
                    value: 1
                });
                resolve(response.data);
            } catch (e) {
                toastUp(errMsg(e, false, null), false);
                reject(e);
            }
        });
    }
);

export const createOffices = createAsyncThunk(
    'offices/create',
    async (payload, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiRequest.post(
                    `integrations/dr_first/${payload.consultant_id}/office_location`,
                    payload.data
                );
                await thunkAPI.dispatch(
                    fetchConsultantOffices(payload.consultant_id)
                );
                toastUp('Office added successfully', true);
                resolve(response.data);
            } catch (e) {
                toastUp(errMsg(e, false, null), false);
                reject(e);
            }
        });
    }
);
export const deleteOffices = createAsyncThunk(
    'offices/create',
    async (payload, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiRequest.post(
                    `integrations/dr_first/${payload.consultant_id}/office_location`,
                    payload.data
                );
                await thunkAPI.dispatch(
                    fetchConsultantOffices(payload.consultant_id)
                );
                toastUp('Office deleted successfully', true);
                resolve(response.data);
            } catch (e) {
                toastUp(errMsg(e, false, null), false);
                reject(e);
            }
        });
    }
);

export const fetchConsultantOffices = createAsyncThunk(
    '/consultants-offices',
    async (_id, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiRequest.get(`/integrations/dr_first/${_id}/office_location`);
                resolve(response.data.data);
            } catch (e) {
                toastUp(errMsg(e, true, 'consultant offices'), false);
                reject(e);
            }
        });
    }
);


export const createConsultantVehicleMakes = createAsyncThunk(
    'createConsultantVehicleMakes',
    async (vehicle, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiRequest.post(`/vehicles`, vehicle);
                await thunkAPI.dispatch(fetchVehicles());
                toastUp('Vehicle makes added successfully', true);
                resolve(response);
            } catch (e) {
                toastUp(errMsg(e, false, null), false);
                reject(e);
            }
        });
    }
);
export const createConsultantTaxonomy = createAsyncThunk('createConsultantTaxonomy', async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
        const { consultantId, ...rest } = payload
        try {
            const response = await apiRequest.post(`/consultants/${consultantId}/consultant-taxonomy`, rest);
            await thunkAPI.dispatch(fetchSpecificConsultant(consultantId));
            toastUp('Taxonomy added successfully', true);
            resolve(response);
        } catch (e) {
            toastUp(errMsg(e, false, null), false);
            reject(e);
        }
    });
}
);
export const updateConsultantVehicleModels = createAsyncThunk(
    'updateConsultantVehicleModels',
    async (payload, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { vehicleId, ...rest } = payload;
                const response = await apiRequest.patch(`/vehicles/${vehicleId}`, rest);
                await thunkAPI.dispatch(fetchVehicles());
                toastUp('Vehicle makes added successfully', true);
                resolve(response);
            } catch (e) {
                toastUp(errMsg(e, false, null), false);
                reject(e);
            }
        });
    }
);
export const updateVehicle = createAsyncThunk(
    'vehicle/update',
    async (payload, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiRequest.patch(
                    `/consultants/${payload.data.consultant_id}/vehicles/${payload.vehicle_id}`,
                    payload.data
                );
                await thunkAPI.dispatch(
                    fetchConsultantVehicles(payload.data.consultant_id)
                );
                toastUp('Vehicle Details Updated  ', true);
                //consultant_vehicle_updated
                ReactGA.event({
                    category: "consultant_vehicle_updated",
                    action: "consultant_vehicle_updated",
                    label: "consultant_vehicle_updated",
                    value: 1
                });
                resolve(response.data);
            } catch (e) {
                toastUp(errMsg(e, false), false);
                reject(e);
            }
        });
    }
);

export const deleteVehicle = createAsyncThunk(
    'vehicle/delete',
    async (payload, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiRequest.delete(
                    `/consultants/${payload.data.consultant_id}/vehicles/${payload.data.vehicle_id}`
                );
                await thunkAPI.dispatch(
                    fetchConsultantVehicles(payload.data.consultant_id)
                );
                toastUp('Deleted vehicle successfully', true);
                //consultant_vehicle_deleted
                ReactGA.event({
                    category: "consultant_vehicle_deleted",
                    action: "consultant_vehicle_deleted",
                    label: "consultant_vehicle_deleted",
                    value: 1
                });
                resolve(response.data);
            } catch (e) {
                toastUp(errMsg(e, false), false);
                reject(e);
            }
        });
    }
);

export const updateConsultant = createAsyncThunk(
    '/updateConsultant',
    async (payload, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            try {
                let dispatch = thunkAPI.dispatch;
                const response = await apiRequest.patch(
                    `/consultants/${payload.consultant_id}`,
                    payload.data
                );
                await dispatch(fetchSpecificConsultant(payload.consultant_id));
                await dispatch(fetchConsultants(payload.consultant_id));
                toastUp('Update successful', true);
                //consultant_updated
                ReactGA.event({
                    category: "consultant_updated",
                    action: "consultant_updated",
                    label: "consultant_updated",
                    value: 1
                });
                resolve(response);
            } catch (e) {
                //console.log(e);
                toastUp(errMsg(e, false), false);
                reject(e);
            }
        });
    }
);
export const updateConsultantStateLicences = createAsyncThunk('updateConsultantStateLicences',
  async (payload, thunkAPI) => {
      return new Promise(async (resolve, reject) => {
          try {
               await apiRequest.patch(`/state-licenses/${payload.consultant_id}`, payload.data);
              await thunkAPI.dispatch(getConsultantStateLicences(payload.consultant_id));
              toastUp('Update successful', true);
              //consultant_updated
              ReactGA.event({
                  category: "consultant_updated",
                  action: "consultant_updated",
                  label: "consultant_updated",
                  value: 1
              });
              resolve();
          } catch (e) {
              //console.log(e);
              toastUp(errMsg(e, false), false);
              reject(e);
          }
      });
  }
);

export const getConsultantStateLicences = createAsyncThunk('getConsultantStateLicences',
  async (consultant_id, thunkAPI) => {
      return new Promise(async (resolve, reject) => {
          try {
              const {data}=await apiRequest.get(`/state-licenses/${consultant_id}`)
              resolve(data?.state_licenses);
          } catch (e) {
              //console.log(e);
              toastUp(errMsg(e, false), false);
              reject(e);
          }
      });
  }
);

export const fetchConsultantVehicles = createAsyncThunk(
    '/consultants-vehicle',
    async (_id, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiRequest.get(`/consultants/${_id}/vehicles`);
                resolve(response.data.data);
            } catch (e) {
                toastUp(errMsg(e, true, 'consultant vehicles'), false);
                reject(e);
            }
        });
    }
);
export const fetchConsultanListSchedule = createAsyncThunk(
    '/consultantListSched',
    async (consultant, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            try {
                const availabilityShchedule = await apiRequest.get(
                    `/consultant-availability-schedules?consultant=${consultant.id}`
                );
                resolve({ schedule: availabilityShchedule.data.data });
            } catch (e) {
                toastUp(errMsg(e, true, 'consultant schedule'), false);
                reject(e);
            }
        });
    }
);

export const fetchConsultantOrders = createAsyncThunk('/fetchConsultantOrders', async (requestParams, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            try {
                let state = thunkAPI.getState();
                let pagination = requestParams?.pagination ? requestParams.pagination : state.consultants.consultantOrdersPagination;
                let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : { consultantId: requestParams }
                params.page = pagination.current
                const { data } = await apiRequest.get(`/treatment-orders`, { params });
                const payload = { data: data.data, pagination: { ...pagination, total: data.meta.total } }
                resolve(payload);
            } catch (e) {
                console.log(e)
                toastUp(errMsg(e, true, 'consultant orders '), false);
                reject(e);
            }
        });
    }
);
export const fetchProvider = createAsyncThunk(
    'fetchProvider',
    async (_id, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            //console.log('fetchProvider ', _id);
            try {
                let dispatch = thunkAPI.dispatch;
                const response = await apiRequest.get(`/me/profile`);
                //console.log('Profile: ', response);
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(new Error('Something went wrong'));
                }
            } catch (e) {
                reject(e);
            }
        });
    }
);
export const updateProvider = createAsyncThunk(
    '/updateProvider',
    async (payload, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            //console.log('updateProvider', payload);
            try {
                let dispatch = thunkAPI.dispatch;
                const response = await apiRequest.patch(`/me/profile`, payload.data);
                toastUp('Update successful', true);
                await dispatch(fetchProvider(payload.consultant_id));
                //profile_updated
                ReactGA.event({
                    category: "profile_updated",
                    action: "profile_updated",
                    label: "profile_updated",
                    value: 1
                });
                resolve(response);
            } catch (e) {
                //console.log(e);
                toastUp(errMsg(e, false), false);
                reject(e);
            }
        });
    }
);

export const fetchStateSignatories = createAsyncThunk('/state-signatories', async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiRequest.get(`/state-signatories`);
            console.log('state signatories:::', response.data.data)
            resolve(response.data.data);
        } catch (e) {
            reject(e);
        }
    });
});

// fettch consultant assisgnments from patient-consultant-assignments endpoint
export const fetchConsultantAssignments = createAsyncThunk(
    '/consultantAssignments',
    async (requestParams, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            console.log('fetchConsultantAssignments', requestParams);
            try {
                let state = thunkAPI.getState();
                let pagination = requestParams?.pagination ? requestParams.pagination : state.consultants.consultantsAssigmentPagination;
                let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : { consultant: requestParams.consultant }
                params.page = pagination.current
                const response = await apiRequest.get(`/patient-consultant-assignments`, { params });
                let data = response.data.data.map((item) => ({ ...item, latlng: [item.lng, item.lat] }));
                const payload = { data, pagination: { ...pagination, total: response.data.meta.total } };
                resolve(payload);
            } catch (e) {
                toastUp(errMsg(e, true, 'consultants'), false);
                reject(e);
            }
        });
    }
);



export const consultantSlice = createSlice({
    name: 'consultants',
    initialState, // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        newConsultant: (state, action) => {
            let newState = newResourceArray(state.consultants, action);
            state.consultants = [...newState];
        },
        consultantUpdated: (state, action) => {
            let newState = updatedArrayState(state.consultants, action, 'id');
            state.consultants = [...newState];
        },
        selectedConsultantUpdated: (state, action) => {
            state.selectedConsultant = { ...action.payload };
        },
        newAvailability: (state, action) => {
            let newState = newResourceArray(
                state.selectedConsultantDetails.availabilityTimes,
                action
            );
            state.selectedConsultantDetails.availabilityTimes = [...newState];
        },
        availabilityDeleted: (state, action) => {
            let newState = arrayStateAfterDelete(
                state.selectedConsultantDetails.availabilityTimes,
                action,
                'id'
            );
            state.selectedConsultantDetails.availabilityTimes = [...newState];
        },
        newSchedule: (state, action) => {
            let newState = newResourceArray(
                state.selectedConsultantDetails.schedule,
                action
            );
            state.selectedConsultantDetails.schedule = [...newState];
        },
        scheduleUpdated: (state, action) => {
            let newState = updatedArrayState(
                state.selectedConsultantDetails.schedule,
                action,
                'id'
            );
            state.selectedConsultantDetails.schedule = [...newState];
        }, // Use the PayloadAction type to declare the contents of `action.payload`
        selectConsultant: (state, action) => {
            //console.log(action.payload);
            state.selectedConsultant = action.payload.record;
        },
        resetConsultantModuleState: (state) => {
            let localState = { ...initialState };
            delete localState.consultants;
            Object.keys(localState).map((key) => (state[key] = localState[key]));
        },
        doResetConsultantSlice: (state) => {
            Object.keys(initialState).map(key => state[key] = initialState[key])
        },
        resetConsultantsPagination: (state) => {
            state.consultantsPagination = initialState.consultantsPagination;
        },
        resetMileagesPagination: (state) => {
            state.mileagesPagination = initialState.consultantsPagination;
        },
        clearConsultantModuleState: (state) => {
            Object.keys(initialState).map((key) => (state[key] = initialState[key]));
        },
        resetConsultantOrdersPagination: (state) => {
            state.consultantOrdersPagination = initialState.consultantOrdersPagination;
        },
        resetConsultantLicences: (state) => {
            state.consultantStateLicences =[]
            
        }
    },
    extraReducers:(builder)=> {
        builder.addCase(fetchConsultants.fulfilled, (state, action) => {
            state.consultants = [...action.payload.data];
            state.consultantsPagination = action.payload.pagination;
        }).addCase(fetchConsultantOrders.fulfilled, (state, action) => {
            state.consultantOrders = [...action.payload.data];
            state.consultantOrdersPagination = action.payload.pagination;
        }).addCase(fetchConsultantshedule.fulfilled, (state, action) => {
            state.consultantSchedule = [...action.payload];
        }).addCase(fetchMileages.fulfilled, (state, action) => {
            state.mileages = [...action.payload.data];
            state.mileagesPagination = action.payload.pagination;
        }).addCase(fetchVehicles.fulfilled, (state, action) => {
            state.consultantVehicles = [...action.payload];
        }).addCase(fetchSpecificConsultant.fulfilled, (state, action) => {
            state.selectedConsultant = action.payload;
            state.selectedConsultant.territories = action.payload.territories;
        }).addCase(fetchConsultantDaetails.fulfilled, (state, action) => {
            state.selectedConsultantDetails = { ...action.payload };
            state.vehicles = [...action.payload.vehicles];
        }).addCase(fetchConsultantTimes.fulfilled, (state, action) => {
            state.selectedConsultantDetails.availabilityTimes = [
                ...action.payload.availabilityTimes
            ];
        }).addCase(fetchConsultanListSchedule.fulfilled, (state, action) => {
            state.selectedConsultantDetails.schedule = [...action.payload.schedule];
        }).addCase(fetchConsultantVehicles.fulfilled, (state, action) => {
            state.vehicles = [...action.payload];
        }).addCase(getConsultantStateLicences.fulfilled, (state, action) => {
            state.consultantStateLicences = [...action.payload];
        }).addCase(fetchConsultantOffices.fulfilled, (state, action) => {
            state.offices = [...action.payload];
        }).addCase(fetchProvider.fulfilled, (state, action) => {
            //console.log('fetchProvider.fulfilled', action);
            state.selectedProvider = action.payload.data;
        }).addCase(fetchStateSignatories.fulfilled, (state, action) => {
            state.stateSignatories = [...action.payload];
        }).addCase(fetchConsultantAssignments.fulfilled, (state, action) => {
            state.consultantAssignments = [...action.payload.data];
            state.consultantsAssigmentPagination = action.payload.pagination;
        })
    }
});
export const { doResetConsultantSlice,
    selectConsultant,
    newSchedule,
    scheduleUpdated,
    newConsultant,
    consultantUpdated,
    selectedConsultantUpdated,
    selectProspect,
    newAvailability,
    availabilityDeleted,
    resetConsultantModuleState,
    clearConsultantModuleState,
    resetConsultantsPagination, resetMileagesPagination,
    resetConsultantOrdersPagination,
    selectedProvider,resetConsultantLicences
} = consultantSlice.actions;
export default consultantSlice.reducer;
