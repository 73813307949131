import React, {useEffect, useState} from 'react'
import {Button, Col, Grid, Row, Table, Typography} from 'antd'
import {connect, useDispatch} from "react-redux"
import {LeftOutlined, PlusOutlined} from '@ant-design/icons'
import {Headline6} from "../../../components"
import {
  createBiologicKit,
  createBiologicProduct,
  deleteBiologicKit,
  deleteBiologicProduct,
  editBiologicKit,
  editBiologicProduct, fetchBiologicProducts, fetchFacilities, resetBiologicProductsPagination,
  updateBiologicProductsFilters, updateFacilitiesFilters
} from '../../../app/Reducers'
import {
  appMessage, containedButton,
  createFormInputsFromTableColumns,
  dialogCloser,
  emptyFun,
  executeChunkFn,
  getSkels,
  kebabMenus,
  makeAutoCompleteOptions,
  makeSelectOptions,
  onTableChange, responsiveTbl
} from '../../../common/helpers'
import KebabMenu from '../../../components/KebabMenu'
import {errMsg, toastUp} from "../../../app/Reducers/reducerUtils";
import * as Sentry from "@sentry/react";
import DynamicModalForm from "../../../components/DynamicModalForm";
import {US_STATES} from '../../../common/States';
import TableFilters from '../../../components/InputComponents/TableFilters';
import {TrimmedStateArray} from '../MasterList';

const { useBreakpoint } = Grid;
let biologicProductInputs = {
  fields: [
    { name: 'company_name', label: 'Company name', required: true, type: 'text' },
    { name: 'product_name', label: 'Product name', required: true, type: 'text' },
    { name: 'max_unit_area', label: 'Max Unit Area', required: true, type: 'number' },
    { name: 'status', label: 'Status', required: true, type: 'select', options: makeSelectOptions(['Enabled', 'Disabled']) },
    { name: 'used_on_exposed_structure', label: 'Used on exposed structures?', required: true, type: 'select', options: makeSelectOptions(['yes', 'no']) },
    { name: 'belongs_to_radiant', label: 'Belongs to Radiant', required: true, type: 'select', options: makeSelectOptions(['yes','no']), },
    { name: 'state', label: 'State', required: true, type: 'select', options: makeSelectOptions(['All',...TrimmedStateArray]), fullRow: true,props:{mode:'multiple'} }
  ]
};
const BiologicProductsAndKits = ({ biologicProducts, appConf,biologicProductsPagination }) => {
  const windowState=useBreakpoint()
  const [dynamicFormComputedValues, setDynamicFormComputedValues] = useState(undefined);
  const dateFormat = "YYYY-MM-DD";
  const dispatch = useDispatch()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [selected, setSelected] = useState(null)
  const [isExecuting, setisExecuting] = useState(false)
  const [editingForm, setEditingForm] = useState(false)
  const [datePopupOpen, setDatePopupOpen] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const [phoneValue, setPhoneValue] = useState(undefined);
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [kits, setKits] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [deleteProduct, showDeleteProduct] = useState();
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  const [isVisible, setIsVisible] = useState(false);
  const onChange = (e) => {
    // console.log({e})
    if (!!e && e !== '') {
      setDynamicFormComputedValues({ ...dynamicFormComputedValues, size: `${e}d`, area: areaOfCircle(Math.ceil(parseFloat(e) / 2)).toFixed(2) })
    } else {
      setDynamicFormComputedValues({ ...dynamicFormComputedValues, size: undefined, area: undefined })
    }
    
  }
  
  const productsOptions = makeAutoCompleteOptions(biologicProducts.map((item) => item.name?.split(" ")?.[0]))
  const columns = [
    {
      title: 'Shape', dataIndex: 'shape', key: 'shape',
      fltr: {
        filterable: false, type: 'select', editableCol: true, required: true, options: makeSelectOptions(['circular', 'rectangular']),
        props: {
          onSelect: (value, option) => setDynamicFormComputedValues({
            ...dynamicFormComputedValues, shape: value, ...(value !== 'circular' && {
              diameter: undefined,
              size: undefined,
              area: undefined
            })
          })
        }
      }, invisible: true
    },
    {
      title: 'Diameter', dataIndex: 'diameter', key: 'diameter',
      fltr: {
        filterable: false, type: 'number', editableCol: true, required: dynamicFormComputedValues?.shape === 'circular',
        props: { onChange, disabled: dynamicFormComputedValues?.shape !== 'circular' },
        fiprops: {
          dependencies: ['shape']
        }
      }
      , invisible: true
    },
    {
      title: 'Size', dataIndex: 'size', key: 'size',
      fltr: {
        filterable: false, type: 'text', editableCol: true, required: true,
        props: { disabled: dynamicFormComputedValues?.shape === 'circular' }
      }
    },
    { title: 'Kit code', dataIndex: 'biologic_kit_code', key: 'biologic_kit_code', fltr: { filterable: true, type: 'text', editableCol: true, required: true } },
    { title: 'BC Item ID', dataIndex: 'bc_item_id', key: 'bc_item_id', fltr: { filterable: true, type: 'text', editableCol: true, required: true } },
    {
      title: 'Area(cm²)', dataIndex: 'area', key: 'area', fltr: {
        filterable: true, type: 'number', editableCol: true, required: true,
        props: { disabled: dynamicFormComputedValues?.shape === 'circular' }
      }
    },
    {
      title: 'Status', dataIndex: 'status', key: 'status',
      fltr: { filterable: false, type: 'select', editableCol: true, required: true, options: makeSelectOptions(['In stock', 'Out of stock']) }
      /* render: (text, record) => (
         <div className="status-container">
           <div className="dot-circle-active" style={{background: record.is_available ? '#50D2A0' : "#fc223e"}}/>
           <Subtitle1>{record.is_available ? 'Yes' : 'No'}</Subtitle1>
         </div>
       )*/
    },
    {
      title: 'Action',
      render: (record) =>
        (<KebabMenu menus={actionMenus} recordItem={record} handleMenuClick={kebabMenuClick}
                    resource="biologic kit" handleOk={() => handleOk(record)} showConfirmDialog={selected === record.id}
                    handleCancel={handleCancel} confirmLoading={confirmLoading}
        />)
    }
  ]
  let onCell = (record, rowIndex) => ({ onClick: async event => await updateKits(record.name) })
  const productColumns = [
    {
      onCell,
      title: 'Company name', dataIndex: 'company_name', key: 'company_name',
      fltr: { filterable: true, type: 'autocomplete', options:productsOptions, editableCol: true, required: true }
    },
    { onCell, title: 'Product name', dataIndex: 'product_name', key: 'product_name', fltr: { filterable: false, type: 'text', editableCol: true, required: true } },
    { onCell, title: 'Belongs to Radiant', dataIndex: 'belongs_to_radiant', key: 'belongs_to_radiant',render:(value)=>value?.replace('-', '')?.replace(/_/g, ' ')?.replace(/^\w/, c => c.toUpperCase()),
      fltr: { filterable: true, type: 'select', options: makeSelectOptions(['yes','no']), editableCol: true, required: true } },
    { onCell, title: 'Max Unit Area', dataIndex: 'max_unit_area', key: 'max_unit_area', fltr: { filterable: false, type: 'number', editableCol: true, required: true } },
    {
      onCell,
      title: 'Product status', dataIndex: 'status', key: 'status',
      fltr: { filterable: false, type: 'text', editableCol: true, required: false }
      /*render: (text, record) => (
        <div className="status-container">
          <div className="dot-circle-active" style={{background: record.is_available ? '#50D2A0' : "#fc223e"}}/>
          <Subtitle1>{record.is_available ? 'Yes' : 'No'}</Subtitle1>
        </div>
      )*/
    },
    {
      onCell,
      title: 'State', dataIndex: 'state', key: 'state',
      fltr: { filterable: true, type: 'autocomplete', options:makeAutoCompleteOptions(['All',...TrimmedStateArray]), editableCol: true, required: false },
      render: (state) => state?state?.join(', '):null
    },
    {
      title: 'Used on exposed structures?', dataIndex: 'used_on_exposed_structure', key: 'used_on_exposed_structure',
      fltr: { filterable: false, type: 'select', editableCol: true, required: true, options: makeSelectOptions(['yes', 'no']) }
      /* render: (text, record) => (
         <div className="status-container">
           <div className="dot-circle-active" style={{background: record.is_available ? '#50D2A0' : "#fc223e"}}/>
           <Subtitle1>{record.is_available ? 'Yes' : 'No'}</Subtitle1>
         </div>
       )*/
    },
    {
      title: 'Action',
      render: (record) =>
        (<KebabMenu menus={productAuditMenus} recordItem={record} handleMenuClick={productKebabMenuClick}
                    resource="biologic product" handleOk={() => handleOk(record)} showConfirmDialog={selected === record.id}
                    handleCancel={handleCancel} confirmLoading={confirmLoading}
        />)
    }
  ]
  let biologicKitFormInputs = createFormInputsFromTableColumns(false, columns, phoneValue, setPhoneValue, null, null, dateFormat)
  
  
  //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
  let dynamicFormInputFields = {
    'Edit biologic product': biologicProductInputs, 'Add biologic product': biologicProductInputs,
    'Edit biologic kit': biologicKitFormInputs, 'Add biologic kit': biologicKitFormInputs
  }
  const handleSelect = async e => await updateKits(e);
  
  async function updateKits(productName) {
    //console.log({productName})
    if (!productName) {
      await setSelectedProduct(undefined)
      await setKits([])
      return
    }
    let product = biologicProducts.find(item => item.name === productName)
    await setSelectedProduct(product)
    //console.log({product})
    let productKits = product.kits.map(item => ({ ...item, name: productName, product_id: product.id }))
    //console.log({productKits})
    if (!productKits[0]) {
      appMessage('Selected product has no biologic kits', 'error')
      return
    }
    await setKits(productKits)
  }
  
  
  const actionMenus = kebabMenus(true, false, true)
  const productAuditMenus = [{ title: 'Add graft size', icon: <PlusOutlined fontSize={"small"} style={{ color: 'grey' }}/> }, ...kebabMenus(true, false, true)]
  const showPopconfirm = (id) => setSelected(id);
  
  
  async function productKebabMenuClick(e, record) {
    switch (productAuditMenus[e.key].title) {
      case 'Add graft size':
        await openBiologicKitDynamicForm(null, record.id)
        break
      case 'Edit':
        await openBiologicProductDynamicForm(record)
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        break
      default:
    }
  }
  
  async function kebabMenuClick(e, record) {
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await openBiologicKitDynamicForm(record)
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        break
      default:
    }
  }
  
  const handleOk = async (record) => {
    await setConfirmLoading(true)
    await setSelected(0)
    //Use kits[0] tot tall whether to delete product or kit and pass ids conditionally
    await executeChunkFn(dispatch, !!kits[0] ? deleteBiologicKit : deleteBiologicProduct, { id: record.id, ...(!!kits[0] && { product_id: selectedProduct.id }) },
      setConfirmLoading, dialogCloser(showDeleteProduct), async () => {
        //Only delete product if it is a product deletion
        if (!kits[0]) {
          await setSelectedProduct(undefined)
        } else {
          await updateKits(record.name)
        }
      })
    await setConfirmLoading(false)
  }
  
  const deleteSelectedProduct = async (record) => {
    await executeChunkFn(dispatch, deleteBiologicProduct, record.id, setConfirmLoading, dialogCloser(showDeleteProduct), async () => await setSelectedProduct(undefined))
  }
  
  const handleCancel = () => setSelected(0);
  
  useEffect(() => {
    if (!!biologicProducts[0]) {
      if (!!selectedProduct) {
        updateKits(selectedProduct.name).catch(e => console.log(e))
      } else {
        updateKits().catch(e => console.log(e))
      }
    }
    return emptyFun
  }, [biologicProducts])
  /**
   * @param record
   * @param product_id is added only when adding the first graft size to a product, else it is taken from selectedProduct once kits list is opened.
   *
   * */
  const openBiologicKitDynamicForm = async (record, product_id) => {
    await setFormName(!!record ? 'Edit biologic kit' : 'Add biologic kit');
    await setDefaultDynamicModalFormValues(!!record ? { ...record, shape: record?.shape?.endsWith('d') ? 'circular' : 'rectangular' } : { product_id: selectedProduct?.id || product_id });
    await setDynamicFormOpen(true);
  };
  const openBiologicProductDynamicForm = async (record) => {
    await setFormName(!!record ? 'Edit biologic product' : 'Add biologic product');
    await setDefaultDynamicModalFormValues(!!record ? { ...record } : undefined);
    await setDynamicFormOpen(true);
  };
  const areaOfCircle = (radius) => {
    const pi = Math.PI;
    return pi * radius * radius;
  };
  /**
   * This function is handling returned form entries from the dynamic form
   * */
  const onDynamicFormEntriesResult = async (entries) => {
    // console.log(entries.values)
    const { shape, diameter, ...rest } = entries.values
    try {
      switch (formName) {
        case 'Add biologic kit':
          await executeChunkFn(dispatch, createBiologicKit, { ...rest }, setisExecuting, dialogCloser(setDynamicFormOpen), null)
          break;
        case 'Edit biologic kit':
          await executeChunkFn(dispatch, editBiologicKit, { ...rest }, setisExecuting, dialogCloser(setDynamicFormOpen), null)
          break;
        case 'Add biologic product':
          await executeChunkFn(dispatch, createBiologicProduct, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen))
          break;
        case 'Edit biologic product':
          await executeChunkFn(dispatch, editBiologicProduct, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen))
          break;
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      toastUp(errMsg(e, false, formName), false);
    }
  };
  
  async function handleProductFilterAction(action, values) {
    await dispatch(resetBiologicProductsPagination());
    if (action === 'filter') {
      let pl = { filterData: values, pagination: biologicProductsPagination };
      await dispatch(updateBiologicProductsFilters([false, pl, true]));
      await dispatch(fetchBiologicProducts(pl));
      await dispatch(updateBiologicProductsFilters([true, pl, false]));
    }
    if (action === 'reset') {
      await dispatch(updateBiologicProductsFilters([appConf.biologicProductsFilters[0], undefined, true]));
      await dispatch(fetchBiologicProducts());
      await dispatch(updateBiologicProductsFilters([false, undefined, false]));
    }
    setIsVisible(false);
  }
  return (
    <Row style={{ height: '100%', width: '100%' }}>
      {(isDynamicFormOpen && !!formName) && (
        <DynamicModalForm
          setDynamicFormOpen={setDynamicFormOpen}
          isDynamicFormOpen={isDynamicFormOpen}
          inputFields={dynamicFormInputFields[formName]}
          onDynamicFormEntriesResult={onDynamicFormEntriesResult}
          closeModal={dialogCloser(setDynamicFormOpen)}
          isExecuting={isExecuting} phoneValue={phoneValue} setPhoneValue={setPhoneValue}
          defaultValues={defaultDynamicModalFormValues}
          formName={formName}
          computedValues={dynamicFormComputedValues}
        />
      )}
      {(!!kits[0] && !!selectedProduct) && <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: 10, marginTop: 10 }}>
        <div className="flexy-row" style={{ width: '100%' }}>
          <Button onClick={() => updateKits().catch(e => console.log(e))} style={{ margin: 0 }} type={"text"} icon={<LeftOutlined/>}>Back to products</Button>
          <Typography variant="subtitle1" fontWeight={600}>{selectedProduct.name} </Typography>
          <span style={{ minWidth: 250 }}/>
        </div>
      </Col>}
      {/*<Col xs={24} sm={24} md={24} lg={24} xl={24} style={{marginBottom: 10, marginTop: 10}}>
        <div className="flexy-row">
          <Headline6 style={{flexGrow: 2}}>Biologic products:</Headline6>
          <AutoComplete popupMatchSelectWidth={false} style={{minWidth: 180, flexGrow: 1}} options={productsOptions} filterOption={true}
                        onSelect={handleSelect}
                        onChange={(e) => {
                          if (!e || e === '') updateKits().catch(e=>console.log(e))
                        }}
                        children={<Input.Search allowClear placeholder={'Select product'}/>}>
          </AutoComplete>
          <Button type="primary" icon={<PlusOutlined/>} onClick={() => openBiologicProductDynamicForm()}>CREATE</Button>
          {!!selectedProduct && <Button type="default" icon={<EditOutlined/>} onClick={() => openBiologicProductDynamicForm(selectedProduct)}>EDIT</Button>}
          {!!selectedProduct && appConfirm(<Button type="dashed" danger={true} icon={<DeleteOutlined/>} onClick={() => showDeleteProduct(true)}>DELETE</Button>,
            deleteProduct, () => showPopconfirm(selectedProduct.id), `Press OK to delete selected product.`,
            () => deleteSelectedProduct(selectedProduct), () => showDeleteProduct(false), confirmLoading)}
        
        </div>
      </Col>*/}
      {
        !kits[0] && <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div style={{ width: '100%', height: '100%', marginTop: 10 }}>
            <div className="flexy-row" style={{ width: '100%' }}>
              <Headline6>Biologic products</Headline6>
              {/*<Button type="primary" icon={<PlusOutlined/>} onClick={() => openBiologicProductDynamicForm()}>Add Biologic product</Button>*/}
            </div>
            <div className="w-full">
              <Table
                title={() => (<TableFilters datasource={[...productColumns]}
                                            setIsVisible={setIsVisible}
                                            isVisible={isVisible}
                                            filters={appConf.biologicProductsFilters[1]}
                                            actionButton={containedButton(() => openBiologicProductDynamicForm(), 'Add Biologic product', false, 'Creating', null, { icon: <PlusOutlined /> })}
                                            windowState={windowState} handleFilterAction={handleProductFilterAction}
                                            showClear={appConf.biologicProductsFilters[0]} loading={appConf.biologicProductsFilters?.[2]}
                />)}
              loading={appConf.biologicProductsFilters?.[2]}
              {...responsiveTbl(windowState)}
              onChange={(pagination, filters, sorter) =>
                onTableChange(pagination, filters, sorter, dispatch, fetchBiologicProducts, appConf.biologicProductsFilters, updateBiologicProductsFilters)
              }
              {...getSkels(appConf.biologicProductsFilters?.[2], productColumns, biologicProducts)}
              pagination={biologicProductsPagination}
            /></div>
          </div>
        </Col>
      }
      {
        !!kits[0] && <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div style={{ width: '100%', height: '100%' }}>
            <div className="flexy-row" style={{ width: '100%' }}>
              <Headline6>Biologic graft sizes</Headline6>
              <Button type="primary" icon={<PlusOutlined/>} onClick={() => openBiologicKitDynamicForm()}>Add Biologic graft size</Button>
            </div>
            <div className="w-full"><Table columns={columns.filter(value => !value.invisible)} dataSource={kits}/></div>
          </div>
        </Col>
      }
    </Row>
  )
}

const mapStateToProps = (state) => ({
  biologicProducts: state?.masters.biologicProducts,
  biologicProductsPagination: state?.masters.biologicProductsPagination,
  appConf: state.appConf, })

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BiologicProductsAndKits)
