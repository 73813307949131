import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLocation, useNavigate} from "react-router-dom";
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd'
import dayjs from "dayjs"
import { createFamilyMember, fetchFamilyHistory, updateFamilyMember } from '../../../../../app/Reducers';
import {appConfirm, cleanObject, deleteButton, emptyFun, executeChunkFn, getInputField, GetOptions, inputRow} from "../../../../../common/helpers";
import { toastUp } from "../../../../../app/Reducers/reducerUtils";
import { apiRequest } from "../../../../../app/Apis";
import * as Sentry from "@sentry/react";
import {DeleteOutlined} from "@ant-design/icons";

const NewHistoryForm = ({ closeModal, setisExecuting, member, editingForm, masterList, patient }) => {
  const df = 'MM/DD/YYYY';
  const uiFields = (member_) => {
    let member = { ...member_ }
    cleanObject(member)
    return {
      ...member, first: member.subject.split(' ')[0], last: member.subject.split(' ')[1],
      date_of_birth: dayjs(member.date_of_birth), ...(member.date_of_death && { date_of_death: dayjs(member.date_of_death) }), ...(!!member.medical_histories && member.medical_histories.length > 0 && {
        medical_histories: member.medical_histories.map(item => ({ ...item, diagnosed_at: dayjs(item.diagnosed_at) }))
      })
    }
  }
  const [form] = Form.useForm();
  const { Option } = Select;
  const location = useLocation()
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState(editingForm ? uiFields(member) : { medical_histories: [{ disease_name: undefined, status: undefined, diagnosed_at: undefined }] });
  const [confirmDelete, setConfirmDelete] = useState(0);
  const [isExecuting, setIsExecuting] = useState(false);
  const dispatch = useDispatch()
  let dateFormat = "YYYY-MM-DD";
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const onFinish = async (values) => {
    if (!values.medical_histories || !values.medical_histories[0]?.disease_name) {
      toastUp('At least one medical history has to be added!', false)
      return
    }
    let payload = {
      ...values, subject: values.first + ' ' + values.last, patient_id: patientId,
      ...(editingForm && { member_id: member.id }),
      // ...(values.medical_histories[0] === '' && {medical_histories: []})
    }
    await executeChunkFn(dispatch, editingForm ? updateFamilyMember : createFamilyMember, payload, setisExecuting, closeModal)
  };
  useEffect(() => {
    console.log('member:  ', member)
    setInitialValues(editingForm ? uiFields(member) : { medical_histories: [{ disease_name: undefined, status: undefined, diagnosed_at: undefined }] })
    return emptyFun
  }, [member, editingForm])
  const disabledFutureDates = (current) => {
    let customDate = dayjs()
    let tooOld = dayjs("1869-12-31")
    return (current && current > dayjs(customDate, dateFormat)) || (current && current < dayjs(tooOld, dateFormat));
  }
  const disabledDiagnosisDates = (current) => {
    if (initialValues.date_of_death) {
      return current && current <= dayjs(initialValues.date_of_birth).add(1, 'day') || current && current > dayjs(initialValues.date_of_death);
    }
    let now = dayjs();
    return current && current <= dayjs(initialValues.date_of_birth).add(1, 'day') || current && current > dayjs(now);
  }
  const disabledDeathDates = (current) => {
    let now = dayjs();
    if (initialValues.date_of_birth) {
      return current && current <= dayjs(initialValues.date_of_birth).add(1, 'day') || current && current > dayjs(now);
    }
    let customDate = dayjs();
    return current && current > dayjs(customDate, "YYYY-MM-DD").add(1, 'day') || current && current > dayjs(now);
  }
  const onDateChange = async (date, dateString) => {
    console.log('date', date)
    console.log('dateString', dateString)
    if (date === null || dateString === 'Invalid date' || !dateString) {
      delete initialValues.date_of_death
      setInitialValues({ ...initialValues });
    } else {
      setInitialValues({ ...initialValues, date_of_death: dayjs(dateString, df) });
      console.log('Field Values:   ', form.getFieldsValue())
      let formValues = await form.getFieldsValue()
      let medical_histories = formValues.medical_histories.map(item => ({ ...item, status: 'Inactive' }))
      formValues.medical_histories = [...medical_histories]
      // await form.resetFields()
      await form.setFieldsValue({ ...formValues })
      console.log('Field Values:   ', form.getFieldsValue())
    }
  }
  const deleteDisease = (diseaseId) => {
    return new Promise(async (resolve, reject) => {
      setIsExecuting(true)
      try {
        await apiRequest.delete(`/patients/${patient.id}/next-of-kin/${member.id}/medical-history/${diseaseId}`)
        // await apiRequest.delete(`next-of-kin/${member.id}/medical-history/${diseaseId}`)
        await dispatch(fetchFamilyHistory(patientId));
        toastUp('Deleted successfully', true)
        setIsExecuting(false)
        resolve()
      } catch (e) {
        setIsExecuting(false)
        Sentry.captureException(e);
        console.log(e)
        toastUp('Unable to delete disease', false)
        reject(e)
      }
    })
  }
  return (
    <Form
      name="new-patient"
      id="familyHistoryForm"
      form={form}
      labelCol={{ span: 16 }}
      onValuesChange={(changedValues, allValues) => {
        console.log('changedValues:', changedValues)
        console.log('allValues:', allValues)
        setInitialValues(allValues)
      }}
      layout="vertical"
      initialValues={{ ...initialValues }}
      onFinish={onFinish}>
      {inputRow([
        <Form.Item
          style={{ width: '100%' }}
          name="first"
          label="First Name"
          rules={[{ required: true, message: 'Missing/Invalid First Name', pattern: /[a-zA-Z ]$/ }]}>
          <Input autoComplete={'off'} placeholder="First Name" />
        </Form.Item>,

        <Form.Item
          style={{ width: '100%' }}
          name="last"
          label="Last Name"
          rules={[{ required: true, message: 'Missing/Invalid Last Name', pattern: /[a-zA-Z ]$/ }]}>
          <Input autoComplete={'off'} placeholder="Last Name" />
        </Form.Item>
      ])}
      {inputRow([
        <Form.Item
          name="gender"
          label="Gender"
          style={{ width: '100%' }}
          rules={[{ required: true, message: 'Missing Gender' }]}>
          <Select popupMatchSelectWidth={false}
            placeholder="Gender"
            allowClear>
            {GetOptions('gender')}
          </Select>
        </Form.Item>,
        <Form.Item
          name="relationship"
          label="Relationship"
          style={{ width: '100%' }}
          rules={[{ required: true, message: 'Missing Relationship' }]}>
          <Select popupMatchSelectWidth={false}
            placeholder="Relationship" allowClear>
            {GetOptions('relationship')}
          </Select>
        </Form.Item>
      ])}
      {inputRow([
        <Form.Item
          style={{ width: '100%' }}
          name="date_of_birth"
          label="Date of Birth"
          rules={[{ required: true, message: 'Missing date of birth' }]}
        >
          <DatePicker disabledDate={disabledFutureDates} style={{ width: '100%' }} placeholder={df}
            format={df} />
        </Form.Item>,
        ...(initialValues.date_of_birth ? [<Form.Item
          style={{ width: '100%' }}
          name="date_of_death"
          label="Date of Death"
        // rules={[{ required: true, message: 'Missing Date of Death' }]}
        >
          <DatePicker onChange={onDateChange} disabledDate={disabledDeathDates} style={{ width: '100%' }}
            placeholder={df}
            format={df} />
        </Form.Item>] : [])
      ])}
      <Form.Item
        name="cause_of_death"
        label="Cause Of Death"
      // rules={[{ required: true, message: 'Missing Cause Of Death' }]}
      >
        <Input autoComplete={'off'} placeholder="Type here" />
      </Form.Item>
      {getInputField(form,{
        label: `Observation`,
        name: 'observation', required: true, type: 'textarea', fullRow: true,
        props: {maxLength:191, style: { backgroundColor: '#F7F8F9',marginBottom:10 },showCount:true ,autoSize: { minRows: 2,maxRows: 6 } }
      })}
      {initialValues.date_of_birth && <Form.List lable="Diseases" name="medical_histories">
        {(fields, { add, remove }) => (<>
          {fields.map(({ key, name, fieldKey, ...restField }, index) => (
            <Row gutter={[4, 0]} align={"bottom"} justify={"start"} style={{ width: '100%', marginTop: 6, background: '#eeeeee' }} key={index}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} flex={"auto"}>
                <Form.Item
                  {...restField} style={{ width: '100%', marginBottom: 2 }}
                  name={[name, 'disease_name']}
                  fieldKey={[fieldKey, 'disease_name']}
                  rules={[{ required: true, message: 'Missing Disease' }]}
                  label="Disease">
                  <Input autoComplete={'off'} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} flex={"auto"}>
                <Form.Item
                  {...restField}
                  shouldUpdate style={{ width: '100%', marginBottom: 2 }}

                  name={[name, 'status']}
                  fieldKey={[fieldKey, 'status']}
                  rules={[{ required: true, message: 'Missing Status' }]}
                  label="Status">
                  <Select popupMatchSelectWidth={false} style={{ width: '100%' }}
                    onChange={(value) => {
                      let formValues = form.getFieldsValue()
                      formValues.medical_histories[index]['status'] = value
                      form.setFieldsValue({ ...formValues })
                    }}
                    {...((form.getFieldValue('medical_histories')[index]) && { value: form.getFieldValue('medical_histories')[index]['status'] })}              >

                    {masterList.find(option => option.system_name === 'disease_status')?.options?.map(option => <Option
                      disabled={option === "active" && !!initialValues.date_of_death}
                      value={option}>{option}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} flex={"auto"}>
                <Form.Item style={{ width: '100%', alignSelf: 'center', marginBottom: 1 }}
                  {...restField}
                  name={[name, 'diagnosed_at']}
                  fieldKey={[fieldKey, 'diagnosed_at']}
                  rules={[{ required: true, message: 'Missing diagnosis date' }]}
                  label="Diagnosis date">
                  <DatePicker disabledDate={disabledDiagnosisDates} style={{ width: '100%' }} placeholder={df} format={df} />
                </Form.Item>
              </Col>
              {form.getFieldValue('medical_histories').length > 1 && <Col xs={2} sm={2} md={2} lg={2} xl={2} flex={"auto"}>
                <Form.Item style={{ marginBottom: 2 }}>
                  {form.getFieldValue('medical_histories')?.[index]?.id ? appConfirm(
                    <DeleteOutlined />, confirmDelete === form.getFieldValue('medical_histories')?.[index]?.id, () => setConfirmDelete(form.getFieldValue('medical_histories')[index].id), 'Are you sure of permanently deleting this disease?', async () => {
                      if (editingForm) {
                        await deleteDisease(form.getFieldValue('medical_histories')[index]?.id)
                      }
                      console.log('Removing:    ✅✅✅✅✅')
                      remove(name)
                      setConfirmDelete(0)
                    }, () => setConfirmDelete(0), isExecuting)
                    : deleteButton(() => {
                      remove(name)
                    })}
                </Form.Item>
              </Col>}
            </Row>))}
          <Form.Item style={{ marginTop: 8 }}>
            <Button onClick={() => add()} height="30px">
              Add medical history
            </Button>
          </Form.Item>
        </>)}
      </Form.List>}
    </Form>
  );
}
const mapStateToProps = (state) => ({
  masterList: state.masters.masterListRaw,
  patient: state.patients.selectedPatient
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(NewHistoryForm)
