/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import {Subtitle1} from '../../../components'
import {Grid, Menu, Modal, Table, Tabs} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import EditProspectCard from '../../Patients/components/EditProspectCard';
import {
  approveProspect,
  archivePatient,
  declineProspect, fetchFacilityPatients, fetchFacilityProspectsList,
  resetFacilityPatientPagination,
  resetFacilityProspectPagination,
  resetPatientModuleState,
  resetPatientNavs,
  restorePatient,
  selectPatient,
  setPatientNavs,
  updatefPatientFilters,
  updatefProspectFilters
} from '../../../app/Reducers';

import dayjs from 'dayjs';
import {
  appConfirm,
  dialogCloser,
  emptyFun,
  emptyTable,
  executeChunkFn,
  formSubmitButton,
  getPatientStatus,
  getProspectStatus,
  getSkels,
  horStack,
  makeAutoCompleteOptions, makeSelectOptions,
  modalClose,
  onTableChange,
  outlinedButton,
  regionFilter,
  responsiveTbl,
  stateFilter
} from "../../../common/helpers";

import TableFilters from "../../../components/InputComponents/TableFilters";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import * as Sentry from "@sentry/react";
import DynamicModalForm from "../../../components/DynamicModalForm";
import ProspectPreviewCard from '../../Patients/components/ProspectPreviewCard';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
const { useBreakpoint } = Grid;
const FacilityPatients = ({
                            patients, prospects, facilityPatientPagination, facilityProspectPagination, appConf, activeUser, navs, facility, farchivedPatientPagination, archivedPatients
                          }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState = useBreakpoint();
  const dispatch = useDispatch()
  const can_view_patient = usePermissionCheck('view_patient', false, [])
  const can_delete_patient = usePermissionCheck('delete_patient', false, [])
  const can_view_prospect = usePermissionCheck('view_prospect', false, [])
  let patientsFilter = { filterData: { facility_id: facility.id /*"facility": facility.name*/ },pagination:facilityPatientPagination }
  let prospectsFilter = { filterData: { facility_id: facility.id /*"facility": facility.name*/ }, pagination: facilityProspectPagination }
  const [selected, setSelected] = React.useState(null);
  const approval_statuses = useSelector(state => state.masters.masterListRaw.find(item => item.system_name === 'approval_status')?.options)?.filter(item => item !== 'Not Approved')
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [isUpdatingProspect, setUpdatingProspect] = useState(false);
  const [editProspect, setEditProspect] = useState(false)
  const [prospectView, setProspectView] = useState(false)
  const [prospectData, setProspectData] = useState({})
  const [prospectDecline, setProspectDecline] = useState(false)
  const [patientForm, setPatientForm] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isPVisible, setIsPVisible] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const showPopconfirm = (selected) => setSelected(selected)
  const handleCancel = () => setSelected(null)
  const handleOk = async (record) => {
    await setConfirmLoading(true);
    if (!record.deleted_at) {
      await dispatch(archivePatient(record.id))
    } else {
      await dispatch(restorePatient(record.id))
    }
    await loadPatients(true)
    await setSelected(null)
    await setConfirmLoading(false);
  }
  const { message } = useSubscription(`view_medical_facility_patients/${facility.id}`, { qos: 2, nl: true });
  const [isDeclineLoading, setDeclineLoading] = useState(false);
  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message)
      loadPatients(true).catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  const { message: prospectMessage } = useSubscription(`view_medical_facility_prospects/${facility.id}`, { qos: 2, nl: true });
  useEffect(() => {
    if (!!prospectMessage) {
      console.log('MQTT MESSAGE:  ', prospectMessage)
      loadPatients(true).catch(e => console.log(e))
    }
    return emptyFun
  }, [prospectMessage])
  
  useEffect(() => {
    loadPatients(false, true).catch(e => console.log(e))
    return emptyFun
  }, [navs.patientNavs.patientsTab, facility])
  const syncPageStatus = () => {
  }
  // const [showExitPrompt, setShowExitPrompt] = useExitPrompt(true, reloadPage, syncPageStatus);
  const loadPatients = async (isMQ, isTabChange) => {
    let pfilter = appConf.fpatientFilters[0] ? appConf.fpatientFilters[1] : patientsFilter
    let prfilter = appConf.fprospectFilters[0] ? appConf.fprospectFilters[1] : prospectsFilter
    if (navs.patientNavs.patientsTab === 'Patients') {
      //Stop API reload
      if (isTabChange && !!patients?.[0] && patients.every(item => item.facility_id === facility.id)) {
        return
      }
      await dispatch(updatefPatientFilters([appConf.fpatientFilters[0], appConf.fpatientFilters[1], !isMQ]))
      await dispatch(fetchFacilityPatients(pfilter))
      await dispatch(updatefPatientFilters([appConf.fpatientFilters[0], appConf.fpatientFilters[1], false]))
    } else {
      //Stop API reload
      if (isTabChange && !!prospects?.[0] && prospects.every(item => item.facility_id === facility.id)) {
        return
      }
      await dispatch(updatefProspectFilters([appConf.fprospectFilters[0], appConf.fprospectFilters[1], !isMQ]))
      await dispatch(fetchFacilityProspectsList(prfilter))
      await dispatch(updatefProspectFilters([appConf.fprospectFilters[0], appConf.fprospectFilters[1], false]))
    }
  }

  let onCell = (record, rowIndex) => ({
    onClick: event => {
      (async () => {
        if (record.id !== navs.patientDetailsOwner) {
          await dispatch(resetPatientModuleState())
        }
        await dispatch(resetPatientNavs())
        await dispatch(selectPatient({ record: record }))
        if (can_view_patient) {
          navigate(`/patients/${record.id}`)
        }
      })().catch(e => console.log(e))
    }
  })
  const columns = [{
    title: 'First & Last Name', dataIndex: 'name', key: 'name', fltr: { filterable: true, type: 'text' }, visible: true, render: (name) => (<>
      <div className="flex-boy-row-space" style={{ justifyContent: 'normal' }}>
        <Subtitle1 style={{ textAlign: "left", marginLeft: '10px' }}>{name}</Subtitle1>
      </div>
    </>)
  }, {
    title: 'Region', dataIndex: 'region', key: 'regionName', fltr: { ...regionFilter, filterable: !activeUser?.facilityProfile }, visible: !activeUser?.facilityProfile
  }, {
    title: 'Contact Phone',
    dataIndex: 'phone_no',
    key: 'phone_no',
    fltr: { filterable: true, type: 'phone' },
    render: (phone_no) => <p className={'text-[#038A96]'}>{phone_no}</p>,
    visible: true
  }, {
    title: 'DOB', dataIndex: 'birth_date', key: 'birth_date', fltr: { filterable: true, type: 'date' }, visible: true
  }, {
    title: 'Referral Status',
    dataIndex: 'approval_status',
    key: 'approval_status',
    fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(['Not Approved', 'pending', 'Pending Insurance Review', 'Insurance Review Completed']) },
    visible: true,
    render: (status) => getProspectStatus(status)
  }, {
    title: <FontAwesomeIcon color="grey" icon={["fas", "table"]}/>, key: 'operation', visible: true, width: 70
    // render: () => <a>action</a>,
  }]
  const patientColumns = [{
    title: 'First & Last Name', dataIndex: 'name', key: 'name', fltr: { filterable: true, type: 'text' }, visible: true, render: (name) => (<>
      <div className="flex-boy-row-space" style={{ justifyContent: 'normal' }}>
        <Subtitle1 style={{ textAlign: "left", marginLeft: '10px' }}>{name}</Subtitle1>
      </div>
    </>), onCell
  }, {
    title: 'Region', dataIndex: 'region', key: 'regionName', fltr: { ...regionFilter, filterable: !activeUser?.facilityProfile }, visible: !activeUser?.facilityProfile, onCell
  }, {
    title: 'Contact Phone',
    dataIndex: 'phone_no',
    key: 'phone_no',
    fltr: { filterable: true, type: 'phone' },
    render: (phone_no) => <p className={'text-[#038A96]'}>{phone_no}</p>,
    visible: true,
    onCell
  }, {
    title: 'DOB', dataIndex: 'birth_date', key: 'birth_date', fltr: { filterable: true, type: 'date' }, render: (dob) => (<>
      {dayjs(dob).format('MM/DD/YYYY')}
    </>), visible: true, onCell
    },
    // {
    // title: 'Created At',
    // dataIndex: 'created_at',
    // key: 'created_at',
    // fltr: { filterable: true, type: 'date' },
    // visible: true,
    // render: (created_at) => <p className={'text-[#038A96]'}>{dayjs(created_at).format('DD/MM/YYYY')}</p>
    // },
    {
    title: 'Status', dataIndex: 'status', key: 'status', fltr: {
      filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(['active', 'deceased', 'in-active', 'refused_treatment', 'healed', 'cancelled', 'hospitalized', 'hold', 'discharged'])
    }, visible: true, render: (status) => getPatientStatus(status), onCell
  }, {
    visible: true,
    fltr: { filterable: false },
    render: record => can_delete_patient ? appConfirm(!record.deleted_at ? <FontAwesomeIcon color="grey" icon={["fas","trash" ]}/> :
      <FontAwesomeIcon color="grey" icon={["fas","trash-restore" ]}/>, selected === record.id, () => showPopconfirm(record.id), `Press OK to ${!record.deleted_at ? 'archive' : 'restore'} this patient.`, () => handleOk(record), handleCancel, confirmLoading) : null
  }]
  
  async function handleFilterAction(action, values) {
    await dispatch(resetFacilityPatientPagination())
    if (action === 'filter') {
      let pl = { filterData: { facility_id: facility.id, /*"facility": facility.name,*/ ...values }, pagination: facilityPatientPagination }
      await dispatch(updatefPatientFilters([false, pl, true]))
      await dispatch(fetchFacilityPatients(pl))
      await dispatch(updatefPatientFilters([true, pl, false]))
    }
    if (action === 'reset'/* && appConf.fpatientFilters[0]*/) {
      await dispatch(updatefPatientFilters([appConf.fpatientFilters[0], undefined, true]))
      await dispatch(fetchFacilityPatients(patientsFilter))
      await dispatch(updatefPatientFilters([false, undefined, false]))
    }
    setIsPVisible(false)
    setIsVisible(false)
  }
  
  
  async function handleProspectFilterAction(action, values) {
    await dispatch(resetFacilityProspectPagination())
    if (action === 'filter') {
      let pl = { filterData: { facility_id: facility.id, /*"facility": facility.name,*/ ...values }, pagination: facilityProspectPagination }
      await dispatch(updatefProspectFilters([false, pl, true]))
      await dispatch(fetchFacilityProspectsList(pl))
      await dispatch(updatefProspectFilters([true, pl, false]))
    }
    if (action === 'reset'/* && appConf.fprospectFilters[0]*/) {
      await dispatch(updatefProspectFilters([appConf.fprospectFilters[0], undefined, true]))
      await dispatch(fetchFacilityProspectsList(patientsFilter))
      await dispatch(updatefProspectFilters([false, undefined, false]))
    }
    // const response = await apiRequest.get(`/prospects`,{params:{...values,toLoad:'prospects'}})
    setIsPVisible(false)
    setIsVisible(false)
  }
  
  let creationDateFilter = { key: 'creationDate', title: 'Date of service', fltr: { filterable: true, type: 'date' } }
  useEffect(() => {
    setIsVisible(false)
    setIsPVisible(false)
    return emptyFun
  }, [navs.patientNavs.patientsTab])
  const approve = async (approval_status) => {
    try {
      await executeChunkFn(dispatch, approveProspect, { id: prospectData.id, approval_status }, setIsApproveLoading, dialogCloser(setProspectView))
      await dispatch(fetchFacilityProspectsList(appConf.fprospectFilters[0] ? appConf.fprospectFilters[1] : patientsFilter))
      await dispatch(fetchFacilityPatients(appConf.fpatientFilters[0] ? appConf.fpatientFilters[1] : patientsFilter))
    } catch (e) {
      Sentry.captureException(e);
    }
  }
  const { TabPane } = Tabs;
  const TabList = ['Patients', "Referrals"]
  const setActiveTab = async (tab) => await dispatch(setPatientNavs({ patientsTab: tab }))
  const Tabs_ = () => {
    return (<Tabs defaultActiveKey={navs.patientNavs.patientsTab} onChange={setActiveTab} tabBarStyle={{ marginBottom: 0, width: '300px' }}>
      {TabList.map(item => <TabPane key={item} tab={<Subtitle1>{item}</Subtitle1>}>
      </TabPane>)}
    </Tabs>)
   
  }
  
  async function handleMenuClick(e) {
    await approve(approval_statuses[e.key])
  }
  
  const menu = (<Menu onClick={handleMenuClick}>
    {approval_statuses?.map((item, index) => <Menu.Item key={index} style={{ textTransform: 'capitalize' }}> {item.replace('-', '').replace(/_/g, ' ')}  </Menu.Item>)} </Menu>);
  
  const onFinish = async (values_) => {
    let values = { ...values_.values }
    //console.log({ values })
    const payload = {
      id: prospectData.id, data: {
        ...values, ...(values.discharged_date && {
          discharged_date: values.discharged_date, rejection_reason: values.rejection_reason === "Others" ? values.rejection_reason_other : values.rejection_reason
        })
      }
    }
    await executeChunkFn(dispatch, declineProspect, payload, setDeclineLoading, () => {
      setProspectView(false);
      setProspectDecline(false);
    }, async () => await loadPatients())
  }
  const [otherReason, setOtherReason] = useState('');
  const [discharge, setdischarge] = useState(false);
  const handleChange = (value) => {
    if (value === 'Other') {
      setOtherReason('');
      setdischarge(false)
    } else if (value === "SNF-100 Days") {
      setdischarge(true)
    } else {
      setOtherReason(value);
      setdischarge(false)
    }
  }
  let inputFields = {
    fields: [
      {
        name: 'rejection_reason', label: "Reason for Decline", required: true, type: 'select', fullRow: true,
        options: makeSelectOptions(["Active HMO/PPO under HH", "No hospice benefits", "No Medicare Part B", "SNF-100 Days", "Others"]), props: { onChange: handleChange }
      },
      ...(discharge ? [{ name: 'discharged_date', label: "Discharged Date", required: true, type: 'date', fullRow: true, dateFormat: "YYYY-MM-DD" }] : []),
      ...(otherReason === 'Others' ? [{ name: 'rejection_reason_other', label: 'Other reason', required: true, type: 'textarea', fullRow: true }] : [])
    ],
    actionName: "Decline Prospect"
  }
  const [isPopping, setPopping] = useState(false)
  return ((<div className="w-full">
      <Tabs_/>
      <Modal
        title={"Edit Referral"}
        open={editProspect}
        onOk={dialogCloser(setEditProspect)}
        onCancel={() => setEditProspect(false)}
        closeIcon={modalClose(dialogCloser(setEditProspect))}
        maskClosable={false}
        destroyOnClose={true}
        width={1200}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(dialogCloser(setEditProspect), 'Cancel', isUpdatingProspect), formSubmitButton('edit-prospect-form', 'Update', isUpdatingProspect, 'Updating')])}
        </div>]}>
        <EditProspectCard prospect={prospectData} loadPatients={loadPatients} closeModal={dialogCloser(setEditProspect)} setUpdatingProspect={setUpdatingProspect}/>
      </Modal>
      {/*<Modal
        title="Decline Prospect"
        id='decline'
        open={prospectDecline}
        zIndex={2000}
        onOk={dialogCloser(setProspectDecline)}
        onCancel={dialogCloser(setProspectDecline)}
        closeIcon={modalClose(dialogCloser(setProspectDecline))}
        maskClosable={false}
        destroyOnClose={true}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setProspectDecline), 'Cancel', isDeclineLoading),
            formSubmitButton("declineProspectForm", 'Confirm', isDeclineLoading, 'Declining')])}
        </div>]}>
        <DeclineProspect prospect={prospectData} setDeclineLoading={setDeclineLoading} decline={() => {
          setProspectView(false);
          setProspectDecline(false);
          loadPatients()
        }} />
      </Modal>*/}
      <DynamicModalForm inputFields={inputFields} isDynamicFormOpen={prospectDecline} closeModal={() => {
        setProspectView(false);
        setProspectDecline(false);
      }} setDynamicFormOpen={setProspectDecline} isExecuting={isDeclineLoading} onDynamicFormEntriesResult={onFinish}/>
      {(!!prospectData && !!prospectView) &&
        <ProspectPreviewCard prospectData={prospectData} onClose={() => setProspectView(false)} prospectView={prospectView} setProspectView={setProspectView} approve={approve}
                             setPopping={setPopping} isPopping={isPopping} setEditProspect={setEditProspect} isApproveLoading={isApproveLoading}
                             setProspectDecline={setProspectDecline}/>}
      {navs.patientNavs.patientsTab === 'Patients' ? <>
        <div className="w-full">
          <Table
            title={() => (<TableFilters datasource={[...patientColumns, creationDateFilter, stateFilter]}
                                        setIsVisible={setIsVisible} filters={appConf.fpatientFilters[1]} windowState={windowState}
                                        isVisible={isVisible} showClear={appConf.fpatientFilters[0]} loading={appConf.fpatientFilters[2]} actionButton={null}
                                        handleFilterAction={handleFilterAction}/>)}
            loading={(navs.fPatientsLoading || appConf.fpatientFilters[2])} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('patients in facility') }}
            onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchFacilityPatients, appConf.fpatientFilters, updatefPatientFilters, {
              facility_id: facility.id /*facility: facility.name*/
            })}
            {...getSkels((navs.fPatientsLoading || appConf.fpatientFilters[2]), patientColumns.filter(item => item.visible), patients)}
            pagination={facilityPatientPagination}/>
        </div>
      </> : <div className="w-full">
        <Table
          title={() => (<TableFilters datasource={[...columns, creationDateFilter, stateFilter]}
                                      setIsVisible={setIsPVisible} filters={appConf.fprospectFilters[1]}
                                      isVisible={isPVisible} showClear={appConf.fprospectFilters[0]}
                                      loading={appConf.fprospectFilters[2]}
                                      handleFilterAction={handleProspectFilterAction}/>)}
          loading={(navs.fProspectsLoading || appConf.fprospectFilters[2])} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('prospects in facility') }}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchFacilityProspectsList, appConf.fprospectFilters, updatefProspectFilters, {
            facility_id: facility.id, facility: facility.name
          })}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                if (can_view_prospect) {
                  setProspectView(true)
                  setProspectData(record)
                }
              } // click row
            };
          }}
          {...getSkels((navs.fProspectsLoading || appConf.fprospectFilters[2]), columns.filter(item => item.visible), prospects)} pagination={facilityProspectPagination}/>
      </div>}
    </div>));
}
const mapStateToProps = (state) => ({
  patients: state.facilities.patients,
  prospects: state.facilities.prospects,
  activeUser: state.auth?.activeUser,
  appConf: state.appConf,
  navs: state.navs,
  facility: state.facilities.selectedFacility,
  facilityProspectPagination: state.facilities.facilityProspectPagination,
  facilityPatientPagination: state.facilities.facilityPatientPagination,

})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(FacilityPatients)
