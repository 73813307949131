/* eslint-disable no-unused-vars */
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Body2, Subtitle1 } from '../../general'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { selectPatient } from '../../../app/Reducers'
import {useLocation, useNavigate} from 'react-router-dom';

export const PatientCard = ({  patient }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const goToPatient = () => {
    dispatch(selectPatient({ record: patient }))
    navigate(`/patients/${patient.id}`)
  }
  return (<div onClick={() => goToPatient()} className="flex-boy-column" style={{ width: "400px" }}>

    <div className="flex-boy-column" style={{ width: '100%' }}>
      <div className="flex-boy-row">
        <div className="icon-circle">
          <FontAwesomeIcon size="1x" icon={['fas', 'user-injured']} />
        </div>
        <div className="flex-boy-row-space" style={{ flex: 1 }}>
          <div>
            <Subtitle1 style={{ textAlign: "left" }}>{patient.name}</Subtitle1>
            {/* <Body2
                color="grey">{dayjs(patient.birth_date).format("MM/DD/YYYY")}({dayjs().diff(dayjs(patient.birth_date), 'years')}y.o.)</Body2> */}
            <Body2>{patient.phone_no}</Body2>
          </div>
          <FontAwesomeIcon size="1x" icon={['fas', 'chevron-right']} />
        </div>
      </div>
      {/* <div className="flex-boy-row">
                    <div className="icon-circle">
                        <FontAwesomeIcon size="1x" icon={['fas', 'star-of-life']} />
                    </div>
                    <div className="flex-boy-row-space" style={{ flex: 1 }}>
                        <div>
                            <Subtitle1 style={{ textAlign: "left" }}>{patient.medical_facility}</Subtitle1>
                            <Body2 color="grey">4806 Shenandoah Ave, Los Angeles, CA 90044.</Body2>
                            <Body2 >212-222-2222</Body2>
                        </div>
                        <FontAwesomeIcon onClick={()=>goToPatient()} style={{cursor:'pointer'}} size="1x" icon={['fas', 'chevron-right']} />
                    </div>

                </div> */}
    </div>


  </div>)
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PatientCard)
