import React, { useEffect, useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLocation, useNavigate} from "react-router-dom";
import { Form, Input, DatePicker, Select, AutoComplete } from 'antd'
import { createAllergy, updateAllergy } from '../../../../../app/Reducers';
import { emptyFun, executeChunkFn, inputRow, GetOptions, appLog } from "../../../../../common/helpers";
import { apiRequest } from '../../../../../app/Apis';
import _ from "lodash"
import dayjs from 'dayjs';
import { Body1 } from '../../../../../components';
import TextArea from 'antd/es/input/TextArea';
const NewAllergyForm = ({ closeModal, editingForm, setisExecuting, allergy, patient }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dateFormat = 'YYYY-MM-DD'
  const [form] = Form.useForm();
  const { Option } = Select;

  const dispatch = useDispatch()
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [drugListOptions, setdrugListOptions] = useState([])
  const [selectedDrug, setselectedDrug] = useState(allergy?.drug)
  const [selectedDrugGroup, setselectedDrugGroup] = useState(allergy?.drug_group)
  const [drugGroupOptions, setdrugGroupOptions] = useState([])
  const debouncedChangeHandler = useCallback(
    _.debounce(function (value) {

      getListAvailableDrugs(value)
    }, 500),
    []
  );

  // const debouncedChange = (value) => {
  //   debouncedChangeHandler(value)
  // }
  const getListAvailableDrugs = async (value) => {
    console.log(value)
    setdrugListOptions([])
    if (value.length >= 2) {
      setLengthError(false)
      const { data } = await apiRequest.get(`/integrations/dr_first/search_allergies?search=${value}`)
      // setOptions(data);
      if (data) {
        console.log(data.data)

        if (data?.data?.length) {
          setnodataError(false)
          setdrugListOptions(data.data);
        } else {
          setnodataError(true)
          setdrugListOptions([])
        }
      }
    } else {
      setLengthError(true)
    }
  };
  const onDrugSelect = (value) => {
    setLengthError(false)
    setnodataError(false)
    setdrugError(false)
    console.log('onSelect', value);
    let drug = drugListOptions?.find(item => `${item.brand_name}-${item?.generic_name}-${item.strength}`.replace("-null", "") === value)
    setselectedDrug(drug)
    // setdrugGroupOptions([...drug.drug_groups])
  };
  const onDrugGroupSelect = (value) => {
    console.log('onSelect', value);
    let drug_group = drugGroupOptions.find(item => item.name === value)
    setselectedDrugGroup(drug_group)
  };

  const disabledFutureDates = (current) => {
    let customDate = dayjs()
    let tooOld = dayjs("1869-12-31")
    return (current && current > dayjs(customDate, dateFormat)) || (current && current < dayjs(tooOld, dateFormat))
  }
  const [initialValues, setInitialValues] = useState(editingForm ? { ...allergy,
    ...(!!allergy.onset_date&&{onset_date: dayjs(allergy.onset_date)}),
    drug: allergy.drug ? `${allergy?.drug?.brand_name}-${allergy?.drug?.generic_name}-${allergy?.drug?.strength}` : '', drug_group: allergy?.drug_group?.name } : {});
  useEffect(() => {
    setInitialValues(editingForm ? { ...allergy } : {})
    return emptyFun
  }, [allergy, editingForm])
  const onFinish = async (values) => {
    console.log('Success:', values);
    let payload
    if (allergen_type === 'Drug' && !selectedDrug) {
      setdrugError(true)
    } else {
      setdrugError(false)
      if (editingForm) {
        console.log(allergen_type)
        payload = {
          ...values, patient_id: patientId, drug: allergen_type === 'Drug' ? _.omit(selectedDrug, ['drug_groups']) : [], allergy_id: allergy.id,
          onset_date: dayjs(values.onset_date).format('YYYY-MM-DD')
        }
      } else {
        payload = { ...values, drug: allergen_type === 'Drug' ? _.omit(selectedDrug, ['drug_groups']) : [], patient_id: patientId }
      }
      console.log(payload)
      await executeChunkFn(dispatch, editingForm ? updateAllergy : createAllergy, payload, setisExecuting, closeModal,null)
    }
  };

  const [LengthError, setLengthError] = useState(false)
  const [nodataError, setnodataError] = useState(false)
  // valid drugerror
  const [drugError, setdrugError] = useState(false)
  const [allergen_type, setallergen_type] = useState(editingForm && allergy.allergen)
  const handleTypeChange = (e) => {
    console.log(e)
    setallergen_type(e)
  }
  const optionsList = drugListOptions?.map((item, index) => {
    return (<AutoComplete.Option key={index} value={`${item.brand_name}-${item?.generic_name}-${item.strength}`.replace("-null", "")}>{`${item.brand_name}-${item?.generic_name}-${item.strength}`.replace("-null", "")}</AutoComplete.Option>)
  })
  return (<Form
    name="new-patient"
    id="allergiesForm"
    form={form}
    labelCol={{ span: 16 }}
    initialValues={{ ...initialValues }}
    layout="vertical"
    onFinish={onFinish}>
    {/* <Form.Item
      name="allergy_type"
      label="Allergy Type"
      style={{ width: '100%' }}
      rules={[{ required: true, message: 'Missing Allergy Type' }]}>
      <Select popupMatchSelectWidth={false}
        placeholder="select"
        allowClear>
        {GetOptions('allergy_type')}
      </Select>
    </Form.Item> */}

    <Form.Item
      name="allergen"
      label="Allergen"
      style={{ width: '100%' }}
      rules={[{ required: true, message: 'Missing Allergen' }]}>
      <Select onChange={handleTypeChange} popupMatchSelectWidth={false}
        placeholder="select"
        allowClear>
        {GetOptions('allergen')}
      </Select>
    </Form.Item>

    <div style={{ padding: '10px' }}>

    </div>

    {form.getFieldValue('allergen') === 'Drug' && <Form.Item
      label="Drug Allergen"

      name="drug"
      rules={[{ required: form.getFieldValue('allergen') === 'Drug', message: 'this field is required!' }]}
      wrapperCol={{ span: 24 }}>
      <AutoComplete popupMatchSelectWidth={true}
        value={selectedDrug ? `${selectedDrug.brand_name}-${selectedDrug?.generic_name}-${selectedDrug.strength}` : ''}
        style={{ width: "100%", marginBottom: "20px" }}
        onSelect={onDrugSelect}
        options={null}
        onSearch={(value) => debouncedChangeHandler(value)}
        placeholder="search for drugs">
        {drugListOptions.length > 0 && optionsList}
      </AutoComplete>
    </Form.Item>}
    <div style={{ textAlign: 'left', marginBottom: '10px', marginTop: '-30px' }}>
      {LengthError && <Body1 color='crimson' style={{ textAlign: 'left', marginTop: '20px', marginBottom: '10px' }}>Please use atleast two characters!</Body1>}
      {nodataError && <Body1 color='crimson' style={{ textAlign: 'left', marginBottom: '10px' }}>No record found!</Body1>}
      {drugError && <Body1 color='crimson' style={{ textAlign: 'left', marginBottom: '10px' }}>Please select a valid drug!</Body1>}
    </div>
    {
      inputRow([
        <Form.Item
          label="Severity"
          name="severity"
          rules={[{ required: false, message: 'this field is required!' }]}
          wrapperCol={{ span: 24 }}>
          <Select popupMatchSelectWidth={false}
            placeholder="select"
            allowClear>
            {GetOptions('severity')}
          </Select>
        </Form.Item>,
        <Form.Item
          style={{ width: '100%' }}
          name="onset_date"
          label="Onset Date"
          rules={[{ required: true, message: 'Missing onset date' }]}>
          <DatePicker disabledDate={disabledFutureDates} style={{ width: '100%' }} format={dateFormat} />
        </Form.Item>
      ])}

    {
      inputRow([
        <Form.Item
          name="reaction"
          label="Reaction"
          style={{ width: '100%' }}
          rules={[{ required: true, message: 'Missing Reaction' }]}>
          <Select popupMatchSelectWidth={false}
            placeholder="select"
            allowClear>
            {GetOptions('reaction')}
          </Select>
        </Form.Item>,
        <Form.Item
          name="status"
          label="Status"
          style={{ width: '100%' }}
          rules={[{ required: true, message: 'Missing Status' }]}>
          <Select popupMatchSelectWidth={false}
            placeholder="select"
            allowClear>
            {GetOptions('status')}
          </Select>
        </Form.Item>
      ])
    }
    <Form.Item
      name="notes"
      label="Notes"
      rules={[{ required: false, message: 'Missing Note' },{ max: 255, message: 'Cannot exceed 255 characters' }]}>
      <TextArea autoComplete={'off'} placeholder="Type here" maxLength={255} showCount={true}/>
    </Form.Item>
  </Form >)
}
const mapStateToProps = (state) => ({  patient: state.patients.selectedPatient })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(NewAllergyForm)
