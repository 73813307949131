// new department form

import { AutoComplete, Avatar, Form, Input, Select, Tag } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { apiRequest } from '../../../app/Apis'
import { addDepartmentUsers, createDepartment } from '../../../app/Reducers'
import { dialogCloser, executeChunkFn } from '../../../common/helpers'

export const NewUsers = ({ setIsExecuting, department, users, setNewUserModal}) => {
    const dispatch = useDispatch()

    const [form] = Form.useForm()
    const {Option} = Select
    const onFinish = async(values) => {
       
        console.log('Success:', values)
        let payload = {
           id: department.id,
            users: selectedConsultants.map(consultant => consultant.user_id),
            // users:_.uniq([...users.map(item=> item.id), ...selectedConsultants.map(consultant => consultant.user_id)]),

        }
       
      await executeChunkFn( dispatch, addDepartmentUsers, payload, setIsExecuting, dialogCloser(setNewUserModal), null)
    
       
    }

    //  assigneews
    const [assignees, setassignees] = useState([]);
    const [selectedAssignee, setselectedAssignee] = useState(null);
     
    // handle assignee search

const handleAssigneesearch = async (value) => {
    const {data} = await apiRequest.get(`/consultants?search=${value}`)
    // setOptions(data);
    if (data) {
      console.log(data.data)
      setassignees(data.data);
    }
  };
  
  const [selectedConsultants, setSelectedConsultants] = useState([]);


    
 

  const handleSelectChange = (value, option) => {
    setSelectedConsultants([...selectedConsultants, {id: value, name: option.children}]);
    form.setFieldsValue({ consultants: undefined }); // clear input field
  };

  const handleClose = removedConsultant => {
    const remainingConsultants = selectedConsultants.filter(consultant => consultant.id !== removedConsultant.id);
    setSelectedConsultants(remainingConsultants);
  };

  return (
    <div className='bg-gray-100 p-2'>
        {/* new department form ant design */}
        <Form
            name="userForm"
            initialValues={{ remember: true }}
            onFinish={onFinish}
           
            autoComplete="off"
            layout="vertical"
            form={form}
            
           
            className="mt-4"
            >
                
       
            <div className="w-full bg-white rounded-md p-2 shadow mb-4">
         <Form.Item  noStyle
                label="Add users"
                name="users"
                rules={[{ required: false, message: 'Please type department supervisor!' }]}
            >
               
            {/* autocomplete */}
            <AutoComplete popupMatchSelectWidth={false}

                              style={{width: "100%", marginBottom: "20px"}}
                              onSearch={handleAssigneesearch}
                              onSelect={(value) =>{
                                // find user_id of selected assignee
                                const selectedAssignee = assignees.find(item => item.user_id === value)


                                // const selectedAssignee = assignees.find(item => item.name === value)
                                setSelectedConsultants([...selectedConsultants, selectedAssignee])

                                  form.setFieldsValue({users:''})
                              }}
                              placeholder="search for staff members">
                              
          {assignees.map(item => (<Option key={item.id} value={item.user_id}>{item.name}</Option>))}
        </AutoComplete>
        </Form.Item>
         <Form.Item  noStyle>
           <div className="flex flex-row w-full flex-wrap">
           {selectedConsultants.map(consultant => (
          <Tag
            key={consultant.id}
            closable
            onClose={() => handleClose(consultant)}
            className='flex flex-row items-center p-2 m-2'

          >
            <div className="flex flex-row items-center">
                <Avatar size="small" className="mr-2" src={consultant.profile_image_url}>
                    {consultant.name}
                    </Avatar>
                <span className='mr-2'>{consultant.name}</span>
            </div>
            
          </Tag>
        ))}
           </div>

        
      </Form.Item>
            
      </div>  
          
        </Form>
    </div>
  )
}

const mapStateToProps = (state) => ({
    users:state.departments.users,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(NewUsers)