import {Col, Row, Tabs} from 'antd'
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {apiRequest} from '../../../../app/Apis'

import {appLog, emptyFun, getHeaderLeftNav} from '../../../../common/helpers'
import HeaderTop from '../../../../components/HeaderTop'
import OrderPreview from './OrderPreview'
import {fetchOrderTracking, fetchSpecificOrder} from '../../../../app/Reducers'
import OrdersPatientInfoPane from './OrdersPatientInfoPane';
import {useLocation, useNavigate} from 'react-router-dom';
import PatientInfoPaneCommon from '../../../../components/PatientInfoPaneCommon'

const TabList = ["Summary", "Notes", "History"]
 const Order = ({ order, orderTab }) => {
  const navigate=useNavigate()
   const location=useLocation()
  const [activeTab, setActiveTab] = useState('Summary');
  const [patient, setpatient] = useState(null)
  const [loadingPatient, setLoadingPatient] = useState(false)
  const [consultant, setconsultant] = useState(null)
  const orderID = Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const { TabPane } = Tabs
  const dispatch = useDispatch()
  const changTab = async (tab) => {
    //console.log('changTab:  ', tab)
    setActiveTab(tab)
    
  }
  useEffect(() => {
    dispatch(fetchOrderTracking(orderID))
    if (order?.patient) {
      console.log('order patient:', order?.patient)
      fetchPatientById().catch(appLog)
    }
    
    fetchConsultantById().catch(appLog)
    return emptyFun
  }, [order])
  
  useEffect(() => {
    if (!order) {
      dispatch(fetchSpecificOrder(orderID))
    }
    return emptyFun
  }, [])
  
  const fetchPatientById = async () => {
    try {
      if (order?.patient) {
        await setLoadingPatient(true)
        const response = await apiRequest.get(`/patients/${order?.patient?.id}`)
        const patient = response?.data?.data
        if (patient) {
          const shippingResponse = await apiRequest.get(`/shipping-addresses?user=${patient?.user_id}`)
          const patient_addresses = shippingResponse?.data?.data
          patient.addresses = [...patient_addresses]
        }
        await setpatient(patient)
        await setLoadingPatient(false)
      }
    
    }catch (e) {
      await setLoadingPatient(false)
      
    }
  }
  
  const fetchConsultantById = async () => {
    if (order?.consultant) {
      const response = await apiRequest.get(`/consultants/${order?.consultant.id}`)
      const consultant = response?.data?.data
      if (consultant) {
        const shippingResponse = await apiRequest.get(`/shipping-addresses?user=${consultant.user_id}`)
        const consultant_addresses = shippingResponse?.data?.data
        consultant.addresses = [...consultant_addresses]
      }
      setconsultant(consultant)
      
    }
  }
  return (<div className="full-page  bg-gray-200 flex flex-col">
    <HeaderTop  child={getHeaderLeftNav(navigate, 'Order Details')}/>
    {order ? <>
      {/*<div className="page-header">
        {order?.patient && <div className="flex-boy-row-space">
          <div style={{ textAlign: 'left' }}>
            <Headline6>{order?.patient?.name}</Headline6>
            <Body2
              color="grey">{dayjs(order?.created_at).format("MM/DD/YYYY")}</Body2>
          </div>
        </div>}
        <Tabs defaultActiveKey={activeTab} onChange={changTab} tabBarStyle={{ marginBottom: 0 }}>
          {TabList.map(item => <TabPane key={item} tab={<Subtitle1>{item}</Subtitle1>}>
          </TabPane>)}
        </Tabs>
      </div>
      <div className="main-body" style={{ padding: "10px" }}>
        {activeTab === "Summary" ? <OrderPreview consultant={consultant} patient={patient} order={order} /> : activeTab === "History" ? <OrderHistory order={order} /> : <OrderNotes order={order} />}
      </div>*/}
      {(!!patient||loadingPatient) ?<Row gutter={[6, 3]} className={'mt-4 h-full'}>
        <Col xs={24} sm={24} md={24} lg={6} xl={6} className={'h-full'}>
          <div className="h-full">
            {/* <OrdersPatientInfoPane setDetailsForm={null} patient={patient} loadingPatient={loadingPatient}/> */}
            <PatientInfoPaneCommon order={order} patientProp={patient} loadingPatient={loadingPatient}/>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <OrderPreview consultant={consultant} patient={patient} order={order} loadingPatient={loadingPatient}/>
        </Col>
      </Row>:
        <div className={'w-full mt-4 h-full px-4'}><OrderPreview consultant={consultant} patient={patient} order={order}/></div>}
    </> : null}
  </div>)
}

const mapStateToProps = (state) => ({ order: state.orders.selectedOrder })

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Order)
