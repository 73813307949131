import React from 'react';
import {connect, useDispatch} from "react-redux";
import {Headline6} from "../../components";
import HeaderTop from "../../components/HeaderTop";


import Departments from "./Departments/DepartmentList";


const DepartmentsList = () => {
  const dispatch = useDispatch()
  
  
  return (<div className="main-page">
    <HeaderTop child={
      <Headline6>Departments</Headline6>
    }/>
    <Departments/>
  </div>)
};

const mapStateToProps = (state) => ({})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(DepartmentsList)
