import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import HeaderTop from '../../../components/HeaderTop'
import { appLog, containedButton, dialogCloser, emptyFun, emptyTable, formSubmitButton, getHeaderLeftNav, getSkels, horStack, kebabMenus, onTableChange, outlinedButton } from '../../../common/helpers'
import { Avatar, Divider, Modal, Table } from 'antd'
import KebabMenu from '../../../components/KebabMenu'
import { fetchDepartment, fetchDepartmentUsers, removeDepartmentUser, resetDepartmentUserPagination, updateDepartmentUserFilters } from '../../../app/Reducers'
import AddUser from './AddUser'
import TableFilters from '../../../components/InputComponents/TableFilters'
import NewDepartment from './NewDepartment'
import {MoreOutlined, PlusOutlined} from '@ant-design/icons'
import { apiRequest } from '../../../app/Apis';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';


export const DepartmentPage = ({ department, users, appConf, departmentUsersPagination, activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const can_create_department = usePermissionCheck('create_department', false, [])
  const can_attach_department_users = usePermissionCheck('attach_department_users', false, [])
  const departmentId = Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))

  // use effect check if department is null if so call fetchdeprtment uisng departmentId
  useEffect(() => {
    if (!department) {
      dispatch(fetchDepartment(departmentId))
    }
    // cleanup
    return () => {

    }
  }, [])

  const [consultantRoles, setConsultantRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    fetchRoles().catch(appLog)
    return emptyFun
  }, [])
  const fetchRoles = async () => {
    const response = await apiRequest.get('/roles').catch(function (err) {
      console.log(err)
    })
    console.log(response.data.data)
    if (response && response.data && response.data.data) {
      setRoles(response.data.data.map(item => ({ label: item.name, value: item.name })))
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fltr: { filterable: true, type: 'string' }
    },
    // role
    {
      title: 'Role', dataIndex: 'roles', key: 'role', render: (roles) => {
        console.log('roles: ', roles)
        let names = roles.map(item => {
          return <p style={{ maxWidth: 200 }}>{item.name}</p>
        })

        return <div>{names}</div>

      }, fltr: { filterable: true, type: 'select', options: roles }
    },
    // email

    // // view department tasks render checkbox
    // {
    //     title: 'View Department Tasks',
    //     dataIndex: 'view_department_tasks',
    //     key: 'view_department_tasks',
    //     fltr : { filterable: true, type: 'string' },
    //     render: (view_department_tasks, record) => (
    //         <div className="flex flex-row items-center">
    //            {/* checkbox if true */}

    //            <Checkbox checked={view_department_tasks} />
    //         </div>
    //         )
    // },
    // // edit department tasks render checkbox
    // {
    //     title: 'Edit Department Tasks',
    //     dataIndex: 'edit_department_tasks',
    //     key: 'edit_department_tasks',
    //     fltr : { filterable: true, type: 'string' },
    //     render: (edit_department_tasks, record) => (
    //         <div className="flex flex-row items-center">
    //              {/* checkbox if true */}
    //             <Checkbox checked={edit_department_tasks} />
    //         </div>
    //         )
    // },
    // // delete department tasks render checkbox
    // {
    //     title: 'Delete Department Tasks',
    //     dataIndex: 'delete_department_tasks',
    //     key: 'delete_department_tasks',
    //     fltr : { filterable: true, type: 'string' },
    //     render: (delete_department_tasks, record) => (
    //         <div className="flex flex-row items-center">

    //             {/* checkbox if true */}
    //             <Checkbox checked={delete_department_tasks} />
    //         </div>
    //         )
    // },
    // kebab menu
    {
      fltr: { filterable: false }, render: (record) => {

        return <KebabMenu menus={actionMenus}
          recordItem={record}
          handleMenuClick={kebabMenuClick}
                          resource={'department user'}
                          handleOk={() => handleOk(record.id)}
                          handleCancel={handleCancel}
                          confirmLoading={confirmLoading}
                          showConfirmDialog={selected === record.id}
          />;
      }
    }
  ]
  // selected record
  const [selected, setSelected] = React.useState(null)
  const actionMenus = kebabMenus(false, false, can_create_department || activeUser?.id === department.supervisor_id)

  // show popconfirm

  async function kebabMenuClick(e, record) {

    switch (actionMenus[e.key].title) {

      case 'Delete':
        await setSelected(record.id)
        break

      default:
    }
  }

  // handle ok
  const handleOk = async (id) => {

    await dispatch(removeDepartmentUser({ departmentId, id }))
    handleCancel()
  }
  // handle cancel
  const handleCancel = () => {
    setSelected(null)
  }
  const adduser = () => {
    setNewUserModal(true)
  }

  useEffect(() => {
    fetchUsers()
    return emptyFun
  }, [])


  // fetch users
  const fetchUsers = async () => {

    await dispatch(fetchDepartmentUsers({ id: departmentId }))
  }


  // confirm loading
  const [confirmLoading, setConfirmLoading] = React.useState(false)


  // new user modal
  const [newUserModal, setNewUserModal] = React.useState(false)

  // is executing
  const [isExecuting, setIsExecuting] = React.useState(false)


  const [newDepartmentModal, setNewDepartmentModal] = useState(false)


  // new department modal close
  const deparm = () => {
    setNewDepartmentModal(false)
  }
  // is editmodal usestate
  const [isEditModal, setIsEditModal] = useState(false)


  // handle filter action

  //     // console.log('handleFilterAction: ',values)
  //     await dispatch(resetDepartmentPagination())
  //     if (action === 'filter') {
  //       console.log('FILTERS:  ', JSON.stringify({ ...values, toLoad: 'departments', endpoint: '/departments' }));
  //       let pl = { filterData: values, pagination: departmentPagination }
  //       await dispatch( updatedepartmentUserFilters([false, pl, true]))
  //       await dispatch(fetchDepartments(pl))
  //       await dispatch( updatedepartmentUserFilters([true, pl, false]))
  //     }
  //     if (action === 'reset'/* && appConf.appointmentFilters[0]*/) {
  //       await dispatch( updatedepartmentUserFilters([appConf.departmentUserFilters[0], undefined, true]))
  //       await dispatch(fetchDepartments())
  //       await dispatch(updatedepartmentUserFilters([false, undefined, false]))
  //     }
  //     // const response = await apiRequest.get(`/appointments`,{params: {...values,toLoad:'appointments'}})
  //     setIsVisible(false)
  //   }

  // is visible
  const [isVisible, setIsVisible] = useState(false)

  async function handleFilterAction(action, values) {
    // console.log('handleFilterAction: ',values)
    await dispatch(resetDepartmentUserPagination())
    if (action === 'filter') {
      console.log('FILTERS:  ', JSON.stringify({ ...values, toLoad: 'departmentUsers', endpoint: '/departmentUsers' }));
      let pl = { id: department.id, filterData: values, pagination: departmentUsersPagination }
      await dispatch(updateDepartmentUserFilters([false, pl, true]))
      await dispatch(fetchDepartmentUsers(pl))
      await dispatch(updateDepartmentUserFilters([true, pl, false]))
    }
    if (action === 'reset'/* && appConf.appointmentFilters[0]*/) {
      await dispatch(updateDepartmentUserFilters([appConf.departmentUserFilters[0], undefined, true]))
      await dispatch(fetchDepartmentUsers({ id: department.id }))
      await dispatch(updateDepartmentUserFilters([false, undefined, false]))
    }
    // const response = await apiRequest.get(`/appointments`,{params: {...values,toLoad:'appointments'}})
    setIsVisible(false)
  }


  return (
    <div className="main-page">


      <Modal
        title="Add Department Users"
        open={newUserModal}
        onCancel={dialogCloser(setNewUserModal)}
        width={600}
        destroyOnClose={true}
        maskClosable={false}
        centered={true}

        className="new-department-modal"
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(dialogCloser(setNewUserModal), 'Cancel', isExecuting),
          formSubmitButton('userForm', 'Create', isExecuting, 'Creating')])}
        </div>]}
      >
        <AddUser setIsExecuting={setIsExecuting} setNewUserModal={setNewUserModal} department={department} />
      </Modal>

      <Modal
        title="Edit Department"
        open={newDepartmentModal}
        onCancel={deparm}
        width={600}
        destroyOnClose={true}
        maskClosable={false}
        centered={true}
        className="new-department-modal"
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(dialogCloser(setNewDepartmentModal), 'Cancel', isExecuting),
          formSubmitButton('departmentForm', 'Update', isExecuting, 'Updating')])}
        </div>]}
      >
        <NewDepartment department={department} editingForm={true} setNewDepartmentModal={setNewDepartmentModal} setIsExecuting={setIsExecuting} />
      </Modal>

      <HeaderTop child={getHeaderLeftNav(navigate, 'Department Details')} />
      {department && <div className="grid  grid-cols-5 h-full w-full">
        <div className="col-span-1 shadow bg-white p-2">
          <div className="flex flex-row justify-between items-center">
            <p className="text-left text-gray-500">INFORMATION</p>
            {(can_create_department || department.supervisor_id === activeUser.id || department.created_by === activeUser.id) && <MoreOutlined onClick={() => setNewDepartmentModal(true)} />}
          </div>
          <p className="font-semibold text-left my-2">{department?.name}</p>
          <Divider />
          <p className="text-left my-2 text-gray-500">Supervisor:</p>
          <div className="flex flex-row items-center">
            <Avatar className="mr-2" src={department?.supervisor?.profile_image_url} alt={department?.supervisor?.name}>{department?.supervisor?.name?.charAt(0)}</Avatar>
            <p className="text-left my-2">{department?.supervisor?.name}</p>
          </div>
          <Divider />
          <p className="text-left my-2 text-gray-500">Description:</p>
          <p className="text-left my-2">{department.description}</p>
          <Divider className="my-4" />
        </div>
        <div className="col-span-4 shadow-md bg-gray-50 p-4">
          <div className="w-full">
            <Table
              title={() => (<TableFilters
                setIsVisible={setIsVisible} isVisible={isVisible}
                datasource={[...columns]}
                handleFilterAction={handleFilterAction}
                filters={appConf?.departmentUserFilters[1]}
                loading={appConf?.departmentUserFilters[2]}
                showClear={appConf?.departmentUserFilters[0]}
                actionButton={(can_attach_department_users || department.supervisor_id === activeUser.id || department.created_by === activeUser.id) && containedButton(adduser, 'Add users', false, null, null, {
                  icon: <PlusOutlined />
                })}
              />)}

              locale={{
                emptyText: !appConf?.departmentUserFilters?.[0] ? emptyTable('department  users' ) : emptyTable('department users')
              }}
              onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchDepartmentUsers, appConf.departmentUserFilters, updateDepartmentUserFilters, { id: department.id })}
              {...getSkels(appConf?.departmentUserFilters?.[2], columns, users)} pagination={departmentUsersPagination} />
          </div>
        </div>
      </div>}


    </div>
  )
}

const mapStateToProps = (state) => ({
  department: state.departments.selectedDepartment,
  users: state.departments.users,
  appConf: state.appConf,
  departmentUsersPagination: state.departments.departmentUsersPagination,
  activeUser: state.auth.activeUser,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentPage)