import React, {useState} from 'react';
import './comparison-modal.css'
import {Button, Modal, Table} from 'antd';
import {Headline6} from '../../../components';
import {closeButton, containedButton, dialogCloser} from '../../../common/helpers';
import {MergeCellsOutlined, UserOutlined} from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';

const ReferralComparisonUI = ({ patientMatches, comparisonModalShowing, showComparisonModal,mergePatientAndReferral,mergingId }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const columns = [
    { title: 'Field name', dataIndex: 'field_name' },
    { title: 'Patient', dataIndex: 'patient_value', className:'bold-table-column' },
    { title: 'Prospect', dataIndex: 'prospect_value', className:'bold-table-column' }]
  return (
    <Modal rootClassName={'ComparisonModal'}
      title={<div className=' px-4 w-full flex flex-row justify-between h-12 items-center shadow-md'>
        <Headline6>{"Referral "}</Headline6>
        {closeButton(dialogCloser(showComparisonModal), null, { type: 'text' })}
      </div>}
      open={comparisonModalShowing}
      closeIcon={null}
      closable={false}
      destroyOnClose={true}
      onOk={dialogCloser(showComparisonModal)}
      onCancel={dialogCloser(showComparisonModal)}
      maskClosable={false}
      width={800}
      footer={null}>
      <div className={'w-full flexy-column px-4'}>
        {patientMatches.map((item,index) => (
          <div className={'shadow-card-box'}>
            <div className={'w-full'}>
              <Table title={() => <p className={'font-bold text-md'}>{patientMatches.length>1?`Comparison ${ index+1}`:'Compare data'}</p>} bordered={false}
                     rowClassName={(record, index) => record.patient_value===record.prospect_value ? 'table-matching-row' :  'table-row-not-matching'}
                     columns={columns}
                     dataSource={item}
                     size={'small'}
                     pagination={false}
                     footer={()=><div className={'flexy-row mt-4'}>
                       <Button onClick={()=> {
                         navigate(`/patients/${item?.[0]?.patient_id}`)
                         showComparisonModal(false)
                       }} icon={<UserOutlined />}>Open Patient Card</Button>
                       {containedButton(()=>mergePatientAndReferral(item?.[0]?.patient_id), 'Save and Merge', mergingId===item?.[0]?.patient_id, 'Merging', null, { icon: <MergeCellsOutlined /> })}
              </div>}
              />
            </div>
          </div>))}
      </div>
    </Modal>);
};

export default ReferralComparisonUI;