import { Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { executeChunkFn } from '../../../common/helpers';
import { createAppointmentNotes, updateAppointmentNote } from '../../../app/Reducers';

export default function NewNote({
  closeModal,
  setisExecuting,
  appointment,
  note,
}) {
  const { form } = Form.useForm();
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    const payload = {
      notes: values.note,
      appointment_id: appointment.id,
    };
    console.log('Payload:', payload);
    if (!note) {
      await executeChunkFn(
        dispatch,
        createAppointmentNotes,
        payload,
        setisExecuting,
        closeModal
      );
    } else {
      payload.id = note.id;
      await executeChunkFn(
        dispatch,
        updateAppointmentNote,
        payload,
        setisExecuting,
        closeModal
      );
    }
  };
  return (
    <Form
      form={form}
      name='addNoteForm'
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      layout='vertical'
      initialValues={note}
    >
      <Form.Item
        name='note'
        label='Note'
        style={{ width: '100%' }}
        rules={[{ required: false, message: 'Missing Notes' }]}
      >
        <TextArea rows={4} autoCapitalize='off' placeholder='Appointment Note' />
      </Form.Item>
    </Form>
  );
}
