import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Button, DatePicker, Form, Image, Input, Select, Upload } from 'antd'
import dayjs from "dayjs"
import { fetchProvider, updateProvider } from '../../../app/Reducers'
import { Subtitle1 } from '../../../components/general'
import { apiRequest } from '../../../app/Apis'
import MapGL from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import { mapboxToken } from '../../../configure/constants'
import { getPhoneParams, emptyFun, formatPhone, showCurrentAddress, appLog } from "../../../common/helpers";
import * as Sentry from "@sentry/react";
import GetCookie from '../../../hooks/GetCookie'
import NumberFormat from 'react-number-format'
import _ from 'lodash'
import {CloudUploadOutlined} from '@ant-design/icons';
import AppPhoneInput from '../../../components/InputComponents/AppPhoneInput';

const { TextArea } = Input;

const degree = ["DPM", "M.D.", "MBA", "MD", "NP", "PA", "Sales", "Consultant"]
const designations = ["D.P.M.", "L.V.N.", "M.D.", "N.P.", "Consultant", "R.N."]
const Roles = ['DPM', 'Facility Admin', 'MD', 'Front Desk Executive', 'Medical record associates', 'Nurse Practioner',
  'Physical Trainer', 'Regional clinical coordinate', 'Regional executive director', 'Regional medical director',
  'Human Resource', 'Billing', 'Scheduling']
const languages = ['English', 'Swahili', 'Spanish', 'Chinese', 'French', 'German', 'Italian']

const services = ['Specialized wound dressings',
  'Debridement',
  'Compression therapy',
  'Hyperbaric oxygen therapy (HBOT)',
  'Prescription growth factors',
  'Engineered skin grafting',
  'Edema management',
  'Non-invasive vascular assessment']

export const EditProvider = ({ handlepatientCancel, setisExecuting, provider }) => {
  const dateFormat = 'YYYY-MM-DD';
  const { Option } = Select;
  const uploadUrl = process.env.REACT_APP_ENDPOINT + '/files'
  const token = GetCookie('accessToken')
  const dispatch = useDispatch();
  const [states, setStates] = useState([]);
  const [roles, setRoles] = useState([]);
  const [phoneValue, setPhoneValue] = useState(!!provider ? { ...getPhoneParams(provider?.phone_no, 'phone_no') } : undefined);
  const [initialValues, setInitialValues] = useState(!!provider ? { ...provider, ...(!!provider?.phone_no && { ...getPhoneParams(provider?.phone_no, 'phone_no') }) } : {})
  useEffect(() => {
    // fetchStates();
    fetchRoles()
    return emptyFun
  }, [])
  const fetchRoles = async () => {
    const response = await apiRequest.get('/roles').catch(function (err) {
      console.log(err)
    })
    console.log(response.data.data)
    if (response && response.data && response.data.data) {
      setRoles(response.data.data)
    }
  }
  const onFinish = async (values) => {

    setisExecuting(true)
    const payload = {
       ...locationData,...values, timezone: "PDT", name: values.name,
      birth_date: dayjs(values.birth_date).format(dateFormat),
      date_of_hire: dayjs(values.date_of_hire).format(dateFormat), ...phoneValue
    }
    console.log(payload)
    // remove null values from payload with lodash
    let data = _.omitBy(payload, _.isNull);
    let params = { data: data, consultant_id: provider.id }
    try {
      await dispatch(updateProvider(params))
      // await apiRequest.patch(`/chief-practices/${provider.id}`, payload)
      await dispatch(fetchProvider(provider.id))
      setisExecuting(false)
      handlepatientCancel()
    } catch (e) {
      Sentry.captureException(e);
      console.log("eerrrr", e);
      setisExecuting(false)
    }
  }
  const [viewport, setViewport] = useState({
    latitude: 37.7577, longitude: -122.4376, zoom: 8
  });
  const [locationData, setLocationData] = useState(null)
  const geocoderContainerRef = useRef();
  const mapRef = useRef();
  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);
  const onResult = useCallback((result) => {
    // console.log(result)
    let center = result.result.center
    let context = result.result.context
    let state = context.find(item => item.id.includes('region'))
    let city = context.find(item => item.id.includes('place'))
    let zip_code = context.find(item => item.id.includes('postcode'))
    let data = {
      city: city ? city.text : result.result.place_name, state: state ? state.text : null,
      zip_code: zip_code ? zip_code.text : result.result.place_name, co_ordinates: `${center[0]},${center[1]}`,
      address_1: result.result.place_name
    }
    setLocationData(data)
  }, [])
  const disabledFutureDates = (current) => {
    let customDate = dayjs();
    let tooOld = dayjs("1869-12-31")
    return (current && current > dayjs(customDate, dateFormat)) || (current && current < dayjs(tooOld, dateFormat));
  }


// profileimagelist
  const [profileImageList, setProfileImageList] = useState([])
  const [providerForm] = Form.useForm();
  // profile image change
  const onProfileimageChange = ({ fileList: newFileList }) => {
    newFileList = newFileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
        providerForm.setFieldsValue({
          profile_image_id: file.response.data.id
        })
      }
      return file;
    });
  };
  const [fileList, setFileList] = useState([]);
  


  return (
    <Form
      name="providerForm"
      id="providerForm"
      form={providerForm}
      labelCol={{
        span: 24
      }}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        ...initialValues, birth_date: initialValues.birth_date ? dayjs(initialValues.birth_date): dayjs(), ...initialValues.consultantProfile, date_of_hire: dayjs(initialValues.date_of_hire), language: initialValues.consultantProfile?.language
      }}
    >

      <Form.Item
        style={{ width: '100%' }}
        name="profile_image_id"
        rules={[{ required: true, message: 'Missing Document' }]}
        label="Profile Image">
        <Upload accept="image/*"
          action={uploadUrl}
          listType="picture"
          headers={{ 'Authorization': `Bearer ${token}` }}
          defaultFileList={[...fileList]}
          onChange={onProfileimageChange}
          maxCount={1}>
          <Button icon={<CloudUploadOutlined />}>Upload</Button>
        </Upload>
        <Input autoComplete={'off'} style={{ width: '100%', display: 'none' }} />
      </Form.Item>

      {initialValues?.profile_image_url && <div className="flex flex-col mb-2">
        <Subtitle1 style={{ textAlign: 'left' }}>Current Profile image</Subtitle1>
        <Image width={200} src={initialValues?.profile_image_url} />
      </div>}
      <div className="flex-boy-row-space">
        <Form.Item
          style={{ width: '100%' }}
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Missing first name', pattern: /[a-zA-Z ]$/ }]}
        >
          <Input autoComplete={'off'} placeholder="First Name" />
        </Form.Item>

      </div>

      




      <div className="flex-boy-row-space">
        <Form.Item
          style={{ width: '100%' }}
          name="birth_date"
          label="Date of Birth"
          rules={[{ required: true, message: 'Missing Date of Birth' }]}
        >
          <DatePicker disabledDate={disabledFutureDates} style={{ width: '100%' }} format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item

          name="gender"
          label="Gender"
          style={{ width: '100%' }}
          rules={[{ required: true, message: 'Missing Gender' }]}
        >
          <Select
            placeholder="gender"
            style={{ width: '100%' }}
            allowClear
          >
            <Option value="male">Male</Option>
            <Option value="female">Female</Option>

          </Select>
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          name="date_of_hire"
          label="Date of Hire"
          rules={[{ required: true, message: 'Missing Date of hire' }]}
        >
          <DatePicker style={{ width: '100%' }} format={dateFormat} />
        </Form.Item>


      </div>
      <div className="flex-boy-row-space">
        <Form.Item
          style={{ width: '100%' }}
          name="npi"
          label="NPI"
          rules={[{ required: false, message: 'Missing NPI' }]}
        >
          <Input autoComplete={'off'} placeholder="NPI" />
        </Form.Item>

        <Form.Item
          style={{ width: '100%' }}
          name="caqh_id"
          label="CAQH ID"
          rules={[{ required: false, message: 'Missing CAQH ID' }]}
        >
          <Input autoComplete={'off'} placeholder="CAQH ID" />
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          name="bhpn_practitioner_id"
          label="BHPN Practitioner ID"
          rules={[{ required: false, message: 'Missing BHPN' }]}
        >
          <Input autoComplete={'off'} placeholder="BHPN Practitioner ID" />
        </Form.Item>
      </div>

      <div className="flex-boy-row-space">
        <Form.Item
          style={{ width: '100%' }}
          name="degree"
          label="Degree"
          rules={[{ required: true, message: 'Missing Degree' }]}
        >
          <Select
            style={{ width: '100%' }}
          >
            {degree.map(item => <Option value={item}>{item}</Option>)}

          </Select>
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          name="experience_years"
          label="Experience(years)"
          rules={[{ required: true, message: 'Missing Years of Experience' }]}
        >
          <Input autoComplete={'off'} placeholder="Years" />
        </Form.Item>
        
        <Form.Item
          style={{ width: '100%' }}
          name="ssn"
          label="SSN"
          rules={[{ required: false, message: 'SSN is required for adults' }, { max: 11, message: 'SSN length exceeds limit' }/*, {pattern: /^[1-9]./, message: 'Invalid SSN'}*/]}>
          <NumberFormat autoComplete={'off'} customInput={Input} format="###-##-####" placeholder="###-##-####" />
        </Form.Item>
      </div>
      <div className="flex-boy-row-space">
        <Form.Item
          style={{ width: '100%' }}
          name="email"
          label="Email"
          rules={[{ required: true, message: 'Missing Email' }]}
        >
          <Input autoComplete={'off'} placeholder="Email" type="email" disabled={true} style={{ color: 'gray' }} />
        </Form.Item>
        <AppPhoneInput name={'phone_no'} setPhoneValue={setPhoneValue} phoneValue={phoneValue} formItemProps={{ style: { width: '100%' }, required: true }} />
        {/*<Form.Item
          style={{ width: '100%' }}
          name="phone_no"
          label="Phone Number"

          rules={[{ required: true, message: 'Missing Phone Number' },
          {
            max: 19, message: "Value should be less than 19 characters"
          }]}
          validateTrigger="onBlur">
          <NumberFormat autoComplete={'off'} customInput={Input} format="+(#)(###) ###-####"
            placeholder="Phone Number" />
        </Form.Item>*/}
      </div>
      <div className="flex-boy-row-space">

        <Form.Item
          style={{ width: '100%' }}
          name="language"
          label="Languages"
          rules={[{ required: true, message: 'Missing language' }]}
        >
          <Select
            // onChange={handleChange}
            // mode="multiple"
            style={{ width: '100%' }}
          >
            {languages.map(item => <Option value={item}>{item}</Option>)}

          </Select>
        </Form.Item>
      </div>
      <div className="flex-boy-row-space">
        <Form.Item
          style={{ width: '100%' }}
          name="about_me"
          label="About Me"
          rules={[{ required: true, message: 'Missing about deatails' }]}
        >
          <TextArea autoComplete={'off'} placeholder="About" autoSize={{ minRows: 3, maxRows: 10 }} />
        </Form.Item>
      </div>


      <Subtitle1 className="Validator" style={{ textAlign: 'left', marginBottom: '10px' }}>Address</Subtitle1>
      <Form.Item
        style={{ width: '100%', ...(provider.address_1 && { marginBottom: 0 }) }}
        name="default_region"
        label=""
        rules={[{ required: false, message: 'Missing Region' }]}
      >

        <div
          ref={geocoderContainerRef}
          style={{ position: "absolute", top: 0, left: 0, zIndex: 1, width: "100%" }}
        />
        <MapGL
          ref={mapRef}
          {...viewport}
          width="100%"
          height="100%"
          onViewportChange={handleViewportChange}
          mapboxApiAccessToken={mapboxToken}
        >
          <Geocoder
            mapRef={mapRef}
            containerRef={geocoderContainerRef}
            onViewportChange={handleViewportChange}
            mapboxApiAccessToken={mapboxToken}
            countries="us"
            onResult={onResult}
            marker={false}
            onError={onResult}
          />
        </MapGL>
      </Form.Item>
      {showCurrentAddress(provider.address_1)}

    </Form>
  );
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(EditProvider)
