/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import './App.css';
import './index.css';
import CacheBuster from './CacheBuster';
import {HashRouter, Navigate, Route, Routes} from 'react-router-dom'

import Main from './Main';
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {updateWindowState} from "./app/Reducers/appWindow";
import {connect, useDispatch} from "react-redux";
import {appLog, containedButton, emptyFun, generateString, horStack} from './common/helpers';
import * as Sentry from "@sentry/react";
import {ConfigProvider, Grid, Modal} from 'antd';
import {useBeforeRender} from './hooks/useBeforeRender';
import {InfoCircleFilled} from '@ant-design/icons';
import {acknowledgeReleaseNotes, setActiveUser, setMQTTSetupState} from './app/Reducers';
import {apiRequest} from './app/Apis';
import SecureLS from 'secure-ls';
import GetCookie from './hooks/GetCookie';
import {pdfjs} from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


let packageJson = require('../package.json')
library.add(fas, far)

const { useBreakpoint } = Grid;
function App({ settings, activeUser, MQTTSetupState, ...props }) {
  const dispatch = useDispatch()
  const point = useBreakpoint()
  const [showReleaseNotesDialog, setShowReleaseNotesDialog] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  
  const handleOk = async () => {
    try {
      setConfirmLoading(true);
      await dispatch(acknowledgeReleaseNotes({ acknowledged_release_version: packageJson.version }))
      await dispatch(setActiveUser({ ...activeUser, acknowledged_release_version: packageJson.version }))
      setShowReleaseNotesDialog(false);
      setConfirmLoading(false);
    } finally {
      setConfirmLoading(false);
    }
  };
  //choose the screen size
  useBeforeRender(() => {
    window.addEventListener("error", (e) => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr)
          resizeObserverErr.className = "hide-resize-observer";
        if (resizeObserverErrDiv)
          resizeObserverErrDiv.className = "hide-resize-observer";
      }
    });
  }, []);
  useEffect(() => {
    console.log({ 'user in app': activeUser })
    if (!!activeUser && packageJson.version !== activeUser?.acknowledged_release_version && !activeUser?.password_was_generated) {
      setShowReleaseNotesDialog(true);
      
    }
    initVerneMQ().catch(appLog)
    return emptyFun
  }, [activeUser])
  
  const initVerneMQ = async () => {
    if (!!activeUser && !activeUser?.password_was_generated && !MQTTSetupState) {
      let vmqPass = generateString(24)
      let username = activeUser?.email
      let vernePayload = { username: username, password: vmqPass }
      console.log('vernePayload:::', vernePayload)
      //Update Mqtt creds
      try {
        if (!activeUser?.password_was_generated) {
          await apiRequest.post('/vernemq/init-sync', vernePayload).catch(err => console.log(err));
        }
        const ls = new SecureLS({ encodingType: 'aes', encryptionSecret: GetCookie('accessToken').substring(0, 32) });
        ls.set('latlongval', vmqPass);
        dispatch(setMQTTSetupState(true))
      } catch (e) {
        console.log(e)
      }
    }
  }
  // create an event listener
  useEffect(() => {
    // window.addEventListener("resize", handleResize)
    (async () => {
      //console.log('SCREENSIZE: ', window.innerWidth)
      //console.log('SCREENHEIGHT: ', window.innerHeight)
      // await dispatch(updateWindowState(point))
    })().catch(appLog)
  }, [point])
  
  // useEffect(() => {
  //     let styleData = document.getElementsByClassName('kore-chat-window')
  //     if (IsItemEnabled(settings, 'integrations', 'kore_integration') && !!activeUser?.email) {
  //         if (!!activeUser?.facilityProfile) {
  //             //console.log('window.KoreSDK.chatConfig: ', window.KoreSDK.chatConfig);
  //             if (window?.KoreSDK?.chatConfig?.botOptions && window?.KoreSDK?.chatConfig?.botOptions?.botInfo && window?.KoreSDK?.chatConfig?.botOptions?.botInfo?.customData) {
  //                 // let botOptions = window?.KoreSDK?.chatConfig?.botOptions
  //                 // window.KoreSDK.chatConfig.botOptions.botInfo = {...botOptions, newData: 'Philip'}
  //                 var event = { 'event': "updateCustomData", data: { facility_id: activeUser.facilityProfile.id, facility_name: activeUser.facilityProfile.name, userIdentity: activeUser.email } }
  //                 window.KoreSDK.chatConfig.botOptions.userIdentity = activeUser.email
  //                 window.postMessage(event, '*')
  //                 // window.KoreSDK.chatConfig.botOptions.botInfo.customData.facility_id = activeUser.facilityProfile.id
  //             }
  //             //console.log('window.KoreSDK.chatConfig1: ', window.KoreSDK.chatConfig.botOptions.botInfo);
  //             if (!!styleData && styleData[0]) {
  //                 document.getElementsByClassName('kore-chat-window')[0].style.display = 'block';
  //             }
  //         } else {
  //             if (!!styleData && styleData[0]) {
  //                 document.getElementsByClassName('kore-chat-window')[0].style.display = 'none';
  //                 document.getElementsByClassName('chat-container')[0].innerHTML = ''
  //             }
  //         }
  //     } else {
  //         if (!!styleData && styleData[0]) {
  //             document.getElementsByClassName('kore-chat-window')[0].style.display = 'none';
  //             document.getElementsByClassName('chat-container')[0].innerHTML = ''
  //         }
  //     }
  // }, [activeUser])
  
  return (<>
    <ConfigProvider
      theme={{
        components: {
          Form: {
            colorPrimary: '#3d91ff'
          },
          Input: {
            colorPrimary: '#EF8100'
          },
          /*Radio: {
            colorPrimary: '#EF8100',
            colorPrimaryBorderHover: '#EF8100',
            colorPrimaryHover: '#EF8100',
            colorPrimaryActive: '#EF8100',
            colorPrimaryTextHover: '#EF8100'
          },*/
          Button: {
            // colorPrimary: '#038A96'
            colorPrimary: '#EF8100',
            colorPrimaryBorderHover: '#EF8100',
            colorPrimaryHover: '#EF8100',
            colorPrimaryActive: '#EF8100',
            colorPrimaryTextHover: '#EF8100'
          },
          Switch: {
            // colorPrimary: '#038A96'
            colorPrimary: '#EF8100',
            colorPrimaryBorderHover: '#EF8100',
            colorPrimaryHover: '#EF8100',
            colorPrimaryActive: '#EF8100',
            colorPrimaryTextHover: '#EF8100',
            colorBgContainer: '#8d8d8d'
          }
        },
        token: {
          // colorPrimary: '#038A96'
          colorPrimary: '#3d91ff'
        }
      }}
    >
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
            //console.log("weh")
          }
          return (<HashRouter future={{ v7_startTransition: true }}>
            <ToastContainer position="top-right"/>
            <Modal
              title={<div className={'flex-row flex items-center gap-4'}><InfoCircleFilled className={'text-blue-500'}/><p className={'text-[16px] my-auto font-semibold'}>Release
                notes</p></div>}
              open={showReleaseNotesDialog}
              onCancel={() => setShowReleaseNotesDialog(false)}
              footer={[<div className="entity-filters flex-boy-row justify-end">
                {horStack([
                  containedButton(handleOk, 'Acknowledge', confirmLoading, 'Acknowledging')])}
              </div>]}
            >
              <p>We just added some amazing features/improvements for you.{' '}<br/><br/> <a className={'text-blue-400'}
                                                                                             href={'https://docs.google.com/document/d/1S7o6RzwcOvbVZv_SOBhmOGvzH0fqIV5Pb2C4fqWJYGg/edit?usp=sharing'}
                                                                                             target={'_blank'}>Click here to learn more <i
                className="fas fa-external-link-alt"></i></a></p>
            </Modal>
            <div className="App">
              
              <Routes>
                <Route path="/*" element={<Main/>}/>
                <Route index element={<Navigate to="/auth/login" replace />} />
                {/*<Route path="*" element={<Navigate to="/auth/login" replace/>}/>*/}
              </Routes>
            </div>
          
          </HashRouter>)
        }}
      </CacheBuster>
    </ConfigProvider>
  </>);
}

const mapStateToProps = (state) => ({ activeUser: state.auth?.activeUser, settings: state?.settings?.settings, MQTTSetupState: state?.appConf?.MQTTSetupState })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Sentry.withProfiler(App))
