import { Avatar, Modal } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { deleteOrderNote, fetchOrderNotes } from '../../../../app/Reducers'
import {addButton, dialogCloser, emptyFun, formSubmitButton, horStack, kebabMenus, modalClose, outlinedButton} from '../../../../common/helpers'
import { Body1, Body2, Headline6, RegularButtonCircle, Subtitle1 } from '../../../../components'
import KebabMenu from '../../../../components/KebabMenu'
import NewInternalNote from '../OrderForms/NewInternalNote'
import AuditLogViewer from "../../../../components/AuditLogs/AuditLogViewer";
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';

const OrderNotes = ({ order, notes }) => {
  const dispatch = useDispatch()
  const [orderForm, setOrderForm] = useState(false)
  const [moreNotes, setmoreNotes] = useState(false)
  const [selected, setSelected] = useState(null);
  const handleCancel = () => setSelected(0);
  const [editingForm, setEditingForm] = useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [note, setNote] = useState(null);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_treatment_order = usePermissionCheck('update_treatment_order', false, [])
  useEffect(() => {
    console.log({order})
    dispatch(fetchOrderNotes({ id: order.id }))
    return emptyFun
  }, [order])


  
  const showPopconfirm = (order) => {
    console.log(order)
    setSelected(order.id)
  };
  const actionMenus = kebabMenus(true, can_view_audit_logs, true)

  async function kebabMenuClick(e, item) {
    await setNote(item);
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true)
        await setOrderForm(true)

        break;
      case 'Delete':
        showPopconfirm(item)
        break;
      case 'Audit':
        await setShowAuditDialog(true);
        break
      default:
        await setNote(false);
    }
  }

  const handleOk = async (note) => {
    //console.log(note)
    let payload = { note_id: note.id, order };
    await setConfirmLoading(true);
    await dispatch(deleteOrderNote(payload))
    await setSelected(null)
    await setConfirmLoading(false);
  };
  return (
    (<div className="shadow-card-box">
      {(showAuditDialog && !!note) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false} resourceDescription={'Treatment order note'}
        resourceType={'Treatment order note'}
        defaultValues={{ auditableId: note.id, auditable: 'medical_facility_treatment_orders_note', format: 'json' }} />}
      <div className="flex-boy-row-space" style={{ width: "100%" }}>
        <Headline6>Notes</Headline6>
        {can_update_treatment_order && addButton(() => setOrderForm(true))}
      </div>
      <Modal
        title={editingForm ? "Edit Note" : "Add Note"}
        open={orderForm}
        onOk={dialogCloser(setOrderForm)}
        destroyOnClose
        onCancel={dialogCloser(setOrderForm)}
        closeIcon={modalClose(dialogCloser(setOrderForm))}
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setOrderForm), 'Dismiss', isSubmitting),
            formSubmitButton('new-progress-note', editingForm ? 'Update' : 'Add', isSubmitting, editingForm ? 'Updating' : 'Adding')])}
        </div>]}>
        <NewInternalNote note={note} editingForm={editingForm} order={order} setIsSubmitting={setIsSubmitting} closeModal={dialogCloser(setOrderForm)} />
      </Modal>
      {!moreNotes && notes?.filter(item => item.treatment_order_id === order.id).slice(0, 3).map((note) => {
        return (
          <div key={note.id} className="flex-boy-row-space" style={{ width: '100%' }}>
            <div className="flex-boy-row">
              <Avatar>
                {note.created_by.charAt(0)}
              </Avatar>
              <div style={{ marginLeft: "10px", width: '80%' }}>
                <div className="flex-boy-row">
                  <Subtitle1 style={{ margin: '5px', textAlign: 'left' }}>
                    {note.created_by}
                  </Subtitle1>
                  <Body1 color="black"> {dayjs(note.created_at).format("MM/DD/YYYY")} </Body1>
                </div>

                <Body1 color="black">{note.note}</Body1>
              </div>
            </div>
            {can_update_treatment_order &&
              <KebabMenu menus={actionMenus}
                recordItem={note}
                handleMenuClick={kebabMenuClick} resource={'note'} handleOk={() => handleOk(note)} handleCancel={handleCancel}
                confirmLoading={confirmLoading}
                showConfirmDialog={selected === note.id} />}
          </div>
        )
      })}
      {moreNotes && notes?.filter(item => item.treatment_order_id === order.id).map((note) => {
        return (
          <div key={note.id} className="flex-boy-row-space" style={{ width: '100%' }}>
            <div className="flex-boy-row">
              <Avatar>
                {note.created_by.charAt(0)}
              </Avatar>
              <div style={{ marginLeft: "10px", width: '80%' }}>
                <div className="flex-boy-row">
                  <Subtitle1 style={{ margin: '5px', textAlign: 'left' }}>
                    {note.created_by}
                  </Subtitle1>
                  <Body1 color="black"> / {dayjs(note.created_at).format("MM/DD/YYYY")} </Body1>
                </div>

                <Body1 color="black">{note.note}</Body1>
              </div>
            </div>
            <KebabMenu menus={actionMenus}
              recordItem={note}
              handleMenuClick={kebabMenuClick} resource={'note'} handleOk={() => handleOk(note)} handleCancel={handleCancel} confirmLoading={confirmLoading}
              showConfirmDialog={selected === note.id} />
          </div>
        )
      })}
      {notes?.filter(item => item.treatment_order_id === order.id).length > 3 && <Body2 onClick={() => setmoreNotes(!moreNotes)} style={{
        textAlign: 'center', width: '100%', cursor: 'pointer'
      }}>View {moreNotes ? "less" : "more"} ({notes?.filter(item => item.treatment_order_id === order.id).length - 3})</Body2>}
    </div>)
  );
}

const mapStateToProps = (state) => ({
  notes: state.orders.orderNotes, activeUser: state.auth?.activeUser,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OrderNotes)
