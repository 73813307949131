/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Body2, Indicator, Subtitle1, Subtitle2 } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect, useDispatch } from 'react-redux'
import dayjs from 'dayjs'


import { resetPatientModuleState, selectAppointment, selectConsultant, selectPatient } from '../../../app/Reducers';
import { getAppointmentIndicator, secondaryButton } from '../../../common/helpers';
import { Avatar, Divider, Image } from 'antd';
import { status_colors } from '../../../configure/constants';
import { dismissToast, errMsg, getToastId, updateToast } from '../../../app/Reducers/reducerUtils';
import { apiRequest } from '../../../app/Apis';
import { resetPatientNavs } from '../../../app/Reducers';
import { LoadingOutlined } from '@ant-design/icons';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';


const AppointmentViewCard = ({  appointment, navs, activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const can_view_appointment = usePermissionCheck('view_appointment', false, [])
  const goToAppointment = () => {
    if (appointment && can_view_appointment) {
      dispatch(selectAppointment({ navigate, record: appointment }))
      navigate(`/appointments/${specificAppointment?.id}`)

    }

  }
  const [specificAppointment, setspecificAppointment] = useState(null)
  useEffect(() => {
    fetchdata()

    return () => {

    }
  }, [appointment])

  const fetchdata = async () => {
    const response = await apiRequest.get(`/appointments/${appointment.id}`).catch(e => console.log(e))
    if (response?.data?.data) {
      setspecificAppointment(response?.data?.data)
    }
  }
  const [ploading, setpLoading] = useState(false);
  const [patient, setPatient] = useState()
  const fetchPatient = async (id) => {
    const toastId = getToastId('Getting patient details')
    setpLoading(true)
    try {
      const { data } = await apiRequest.get(`/patients/${id}`)
      if (data && data.data) {
        let patient = data.data
        setPatient(patient)
        if (patient.id !== navs.patientDetailsOwner) {
          await dispatch(resetPatientModuleState())
        }
        await dispatch(selectPatient({ record: patient }))
        await dispatch(resetPatientNavs())
        dismissToast(toastId)
        setpLoading(false)
        navigate(`/patients/${id}`)
      }
    } catch (e) {

      setpLoading(false)
      console.log(e)
      updateToast(toastId, errMsg(e, true, 'patient info'), false)
    }
  }
  const goToConsultant = async () => {

    let consultant = specificAppointment.consultant
    console.log(consultant)

    dispatch(selectConsultant({ record: consultant }))
    navigate(`/consultants/${consultant.id}`)

  }
  const [updatingStatus, setUpdatingStatus] = useState(false);
  return (<div className="flex-boy-column">
    {specificAppointment ? <>
      <div className="flex flex-row justify-between" style={{ marginBottom: "10px" }}>
        <div>
          <Subtitle1 color="black" style={{
            textAlign: 'left', flex: 1
          }}> {dayjs(specificAppointment?.start_at).format("DD MMM, YYYY")} / {dayjs(specificAppointment?.start_at).format("hh:mm a")} -{dayjs(specificAppointment?.end_at).format("hh:mm a")}</Subtitle1>
          <Subtitle1>{appointment.appointment_visit_type}</Subtitle1>
        </div>

        {secondaryButton(null, <Subtitle1
          style={{ textTransform: 'capitalize' }}>{specificAppointment?.status}</Subtitle1>, updatingStatus, 'Updating',
          null, { icon: getAppointmentIndicator(specificAppointment?.status) })
        }
      </div>
      <Divider />
      <div className="flex-boy-row" style={{ marginBottom: "10px" }}>
        <div className="icon-circle">
          <FontAwesomeIcon size="1x" icon={['fas', 'user-injured']} />
        </div>
        <div className="flex-boy-row-space" style={{ flex: 1 }}>
          <div>
            <Subtitle1 style={{ textAlign: "left" }}>{specificAppointment?.patient.name}</Subtitle1>

           <Body2 color="black">{specificAppointment?.patient.address_1}</Body2>
           <Body2>{specificAppointment?.patient.phone_no}</Body2>
           <Body2>{specificAppointment?.patient.email}</Body2>
          </div>
         <Body2 className="cursor-pointer" onClick={() => fetchPatient(specificAppointment?.patient.id)}>View patient</Body2>
        </div>

      </div>
      <Divider />
      <div className="flex flex-row justify-between" style={{ marginBottom: "10px" }}>

        <Avatar src={<Image src={require("../../../assets/user.png")} />} />
        <div className="ml-4 flex-1 flex-col">
          <Subtitle1 style={{ textAlign: "left" }}>{specificAppointment?.consultant.name}</Subtitle1>
          {!activeUser?.facilityProfile &&
            <>
              <Body2 color="black">{specificAppointment?.consultant.address_1}</Body2>
              <Body2>{specificAppointment?.consultant.email}</Body2>
            </>
          }
        </div>

        {/* {!activeUser?.facilityProfile && <Body2 onClick={goToConsultant} className="cursor-pointer">View Provider</Body2>} */}
      </div>
      <Divider />
      <div className="flex-boy-row">
        <div className="icon-circle">
          <FontAwesomeIcon size="1x" icon={['fas', 'file-alt']} />
        </div>
        <div className="flex-boy-row-space" style={{ flex: 1 }}>
          <div>
            <Subtitle1 style={{ textAlign: "left" }}>{specificAppointment?.visit_type}</Subtitle1>
            {specificAppointment?.wounds.map((wound, index) => <Subtitle2>{wound.location} - {wound.recommended_procedure?.toString()} </Subtitle2>)}

          </div>
          {!activeUser?.facilityProfile && <Body2 className="cursor-pointer" onClick={goToAppointment}>{specificAppointment.status === "completed" ? "View Appointment" : "Start Appointment"}</Body2>}
        </div>

      </div>

    </> : <LoadingOutlined />}
  </div>);
}
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser,
  patientAppointments: state.patients.patientAppointments,
  procedures: state.appointments.procedures,
  navs: state.navs,
  loading: state.appointments.assessment_form_loading, woundEncounters: state.patients.woundEncounters
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentViewCard)
