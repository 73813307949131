import { useEffect, useState } from "react";
import { Dropdown, Row, Col, Button, Spin } from "antd";
import StatsCard from "./components/StatsCard";
import { emptyFun } from "../../common/helpers";
import { connect, useDispatch } from "react-redux";
import HeaderTop from "../../components/HeaderTop";
import AllProspects from "./components/AllProspects";
import {CaretDownOutlined, DownSquareOutlined} from "@ant-design/icons";
import AllFacilities from "./components/AllFacilities";
import PatientsChart from "./components/PatientsChart";
import { Headline6, Primary, Subtitle1 } from "../../components";
import PatientLocation from "./components/PatientLocation";
import AllAppointments from "./components/AllAppointments";
import AssessmentChart from "./components/AssessmentChart";
import FacilityLocation from "./components/FacilityLocation";
import ConsultantLocation from "./components/ConsultantLocation";
import AssessmentLocation from "./components/AssessmentLocation";
import AppointmentLocation from "./components/AppointmentLocation";
import BiologicsCharts from "./components/BiologicsCharts";
import { fetchFacilitieStats } from "../../app/Reducers/StatsSlice";
import { LoadingOutlined } from '@ant-design/icons';
import { fetchScheduleStats, fetchPatientStatistics, fetchStats } from "../../app/Reducers";

const StatsPage = ({ activeUser, loadingPatients, scheduleStatistics, loadingSchedule, consultants, patients, facilities, patientLocation, loadingStats }) => {
  const dispatch = useDispatch();
  const [option, setOption] = useState('');
  const [clearing, setClearing] = useState(false);
  const [appointmentTotal, setAppointmentTotal] = useState(0);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  function loadInit(region) {
    dispatch(fetchScheduleStats(region))
    dispatch(fetchStats(region));
    dispatch(fetchPatientStatistics(region));
    dispatch(fetchFacilitieStats(region));
  }

  function clearRegionFilters() {
    setClearing(true);
    setOption('');
    loadInit(option);
    setClearing(false);
  }

  useEffect(() => {
    loadInit(option);
    return emptyFun
  }, [option])
  

  const items = [
    { key: 'Eastern Region', label: 'Eastern Region' },
    { key: 'Western Region', label: 'Western Region' },
    { key: 'MidWest Region', label: 'MidWest Region' }
  ]

  return (
    <div className="full-page bg-pageBackground" >
      <HeaderTop child={<Headline6>Stats</Headline6>} />
      <div className="full-page px-10 py-4">
        <div style={{ alignItems: 'center'}} className='flex-boy-row w-full mb-4'>
          <Dropdown menu={{ items, selectable: true, onSelect: (item) => setOption(item.key), defaultActiveFirst: true }} style={{ borderRadius: '10px', width: 200, height: 60, }} size='large' trigger={['click']} disabled={loadingStats}>
            <div className='flex-boy-row-space bg-white rounded-lg' style={{  width: 200, height: 45, padding: '10px' }}>
              {loadingStats
                ? <Spin indicator={loadingIcon} color={Primary} />
                : <Subtitle1 style={{ fontSize: '16px' }}>{option !== null & option !== '' ? `${option?.toUpperCase()}` : 'Select Region'}</Subtitle1>}
              <CaretDownOutlined className={'text-gray-700'} size={20} />
            </div>
          </Dropdown>
          {!clearing && option !== '' &&
            <Button onClick={() => clearRegionFilters()} disabled={clearing} type={'dashed'} size='middle'
              style={{
                color: !clearing ? '#EF8100 ' : 'grey', borderColor: !clearing ? '#EF8100 ' : 'grey',
                borderRadius: 6, marginLeft: 10,
                fontWeight: 400, alignSelf: 'center', height: "40px",
              }}> Clear Region Filter
            </Button>
          }
        </div>
        <div className="max-w-screen">
          <div className="grid grid-cols-1 gap-4 w-full md:grid-cols-3 lg:grid-cols-3">
            <StatsCard logo={'user'} schedule={scheduleStatistics?.patient_scheduling} loading={loadingSchedule} />
            <StatsCard logo={'schedule'} schedule={scheduleStatistics?.consultant_scheduling} loading={loadingSchedule} />
            <StatsCard logo={'consultant'} schedule={scheduleStatistics?.consultants} loading={loadingSchedule} />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
            <PatientsChart patients={patients} region={option} loading={loadingPatients} />
            <PatientLocation patients={patientLocation} region={option} />
            <AllProspects region={option} />
          </div>
          {/* <div className="grid grid-cols-2 md:grid-cols-2 lg-grid-cols-3 gap-4"> */}
          <div className="grid-cols-1 md:grid-cols-1">
            <AllAppointments region={option} setAppointmentTotal={setAppointmentTotal} />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
            <AllFacilities facilities={facilities} region={option} />
            <FacilityLocation region={option} />
            <ConsultantLocation region={option} />
          </div>
          <div className="grid-cols-1 md:grid-cols-1">
            <AssessmentChart region={option} />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-3">
            <div className="col-span-1 md:col-span-2 lg:col-span-1">
              <AssessmentLocation region={option} />
            </div>
            <div className="col-span-1 md:col-span-2 lg:col-span-1">
              <AppointmentLocation region={option} />
            </div>
            <div className="col-span-1 md:col-span-2 lg:col-span-1">
              <BiologicsCharts region={option} activeUser={activeUser} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({

  stats: state.stats.statistics,
  activeUser: state.auth?.activeUser,
  consultants: state.stats?.consultants,
  patients: state.stats?.patients,
  facilities: state.stats?.facilities,
  patientLocation: state.stats?.patientLocation,
  loadingStats: state.stats?.loadingStatistics,
  loadingSchedule: state.stats?.loadingScheduleStatistics,
  scheduleStatistics: state.stats?.scheduleStatistics,
  loadingPatients: state.stats?.loadingPatients,
  state: state,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StatsPage);