import React, { useEffect, useState } from 'react';
import { Headline6, Subtitle2 } from "../../../components";
import { addButton, addButtonClean, dialogCloser, editMenus, emptyFun, formSubmitButton, horStack, kebabMenus, modalClose, outlinedButton, skelBars } from "../../../common/helpers";
import { Divider, Menu, Modal, Tag } from "antd";
import { connect, useDispatch } from "react-redux";

import { setshippingAddressesLoaded, setshippingAddressesLoading } from "../../../app/Reducers";
import { deleteShippingAddress, fetchShippingAddresses } from "../../../app/Reducers";
import ShippingForm from "./patientSummaryForms/shippingForm";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import KebabMenu from "../../../components/KebabMenu";
import { useSubscription } from "../../../hooks/MqttHooks/useSubscription";
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';

const PatientShippingAddress = ({ shippingAddresses, navs,  patient, activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [isExecuting, setisExecuting] = useState(false)
  const [shippingForm, setShippingForm] = useState(false);
  const [editingForm, setEditingForm] = useState(false);
  const [sortedAddresses, setSortedAddresses] = useState([]);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [address, setAddress] = useState(null);
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const { message } = useSubscription(`view_patient_shipping_address/${patient.user_id}`, { qos: 2, nl: true });
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  const can_update_patient_shipping_address = usePermissionCheck('update_patient_shipping_address', false, [])
  const can_create_patient_shipping_address = usePermissionCheck('create_patient_shipping_address', false, [])
  const can_delete_patient_shipping_address = usePermissionCheck('delete_patient_shipping_address', false, [])
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await loadShippingAddresses(patient.user_id, true)
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])

  async function loadShippingAddresses(user_id, isMQMessage) {
    /*if (!!shippingAddresses[0] && !isMQMessage && shippingAddresses.every(item => item?.user_id === patient.user_id)) {
      return
    }*/
    await dispatch(setshippingAddressesLoading(!isMQMessage))
    await dispatch(fetchShippingAddresses(patient.user_id))
    await dispatch(setshippingAddressesLoading(false))
    await dispatch(setshippingAddressesLoaded(patient.user_id))
  }

  useEffect(() => {
    (async () => {
      // if (navs.shippingAddressesLoaded !== patientId && !navs.shippingAddressesLoading) {
      if (patient.user_id) {
        await loadShippingAddresses(patient.user_id)
      }
      // }
    })().catch(e => console.log(e))
    return emptyFun
  }, [patient])
  useEffect(() => {
    if (shippingAddresses && shippingAddresses.length && shippingAddresses.length > 0) {
      setSortedAddresses([...shippingAddresses].sort((a, b) => {
        if (a.default < b.default) {
          return 1;
        }
        if (a.default > b.default) {
          return -1;
        }
        return 0;
      }))
    } else {
      setSortedAddresses([])
    }
    return emptyFun
  }, [shippingAddresses])
  const handleShippingCancel = (values) => {
    setShippingForm(false);
    setAddress(null);
  }
  const [selected, setSelected] = React.useState(null);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const showPopconfirm = (address) => {
    setSelected(address.street_address)
  };
  const handleCancel = () => setSelected(null)
  const handleOk = async (address_id) => {
    await setConfirmLoading(true);
    let data = { address_id, user_id: patient.user_id }
    await dispatch(deleteShippingAddress(data))
    await setSelected(null)
    await setConfirmLoading(false);
  };
  const actionMenus = kebabMenus(( can_update_patient_shipping_address && !patient.deleted_at), can_view_audit_logs, can_delete_patient_shipping_address)

  async function kebabMenuClick(e, address) {
    console.log(actionMenus[e.key].title)
    await setAddress(address)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true)
        await setShippingForm(true)
        break
      case 'Delete':
        showPopconfirm(address)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
    }
  }

  return (
    (<div className="card-box">
      {(!!address && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} resourceType={'Patient'}
        onCancel={dialogCloser(setShowAuditDialog)} loading={false} resourceDescription={`${patient.name}'s Shipping address`}
        defaultValues={{ auditableId: address?.id, auditable: 'shipping_address', format: 'json' }} />}
      <Modal
        destroyOnClose={true}
        title={!!address ? "Edit Shipping Address" : "Add Shipping Address 1"}
        open={shippingForm}
        onOk={handleShippingCancel}
        onCancel={handleShippingCancel}
        closeIcon={modalClose(handleShippingCancel)}
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(() => handleShippingCancel(), 'Cancel', isExecuting),
          formSubmitButton('shippingForm', 'Save', isExecuting, 'Saving')])}
        </div>]}>
        <ShippingForm patient={patient} setisExecuting={setisExecuting} address={address} editingForm={editingForm}
          closeModal={handleShippingCancel} />
      </Modal>
      <div className="flex-boy-row-space" style={{ width: "100%" }}>
        <Headline6>Shipping Address</Headline6>
        {(can_create_patient_shipping_address && !patient.deleted_at) ? addButtonClean(() => {
          setEditingForm(false)
          setAddress(null)
          setShippingForm(true);
        }) : null}
      </div>
      {navs.shippingAddressesLoading ? skelBars() : <>
        {sortedAddresses.length ? sortedAddresses.map((address, index) => {
          return (<div key={index} style={{ width: "100%" }}>
            {address.default && <div style={{ textAlign: 'left' }}>
              <Tag style={{ textAlign: 'left' }} color="#108ee9">Default</Tag></div>}
            <div className="flex-boy-row-space" style={{ width: "100%" }}>
              <Subtitle2 style={{ fontSize: "1.1em", textAlign:'left' }} color="black">{address.physical_address ? `${address.physical_address}-` : null}{address.street_address}</Subtitle2>
                <KebabMenu menus={actionMenus}
                  recordItem={address}
                  handleMenuClick={kebabMenuClick} resource={'address'} handleOk={() => handleOk(address.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
                  showConfirmDialog={selected === address.street_address} />
              
            </div>
            <Divider />
          </div>)
        }) : <div style={{ width: "100%" }}>
          <div style={{ textAlign: 'left' }}>
            <Tag style={{ textAlign: 'left' }} color="#108ee9">Default</Tag></div>
          <div className="flex-boy-row-space" style={{ width: "100%" }}>
            <Subtitle2 style={{ fontSize: "1.1em" }} color="black">{patient.address_1}</Subtitle2>
            {/* {can_update_patient_shipping_address &&
              <Dropdown dropdownRender={()=>menu(address)} trigger={["click"]} disabled={selected === patient.address}>
                {appConfirm(
                  <MoreOutlined />, selected === patient.address, null, 'Are you sure of deleting this address?', () => handleOk(address.id), handleCancel, confirmLoading)}
                </Dropdown>
              } */}
          </div>
          <Divider />
        </div>}
      </>}
    </div>)
  );
};
const mapStateToProps = (state) => ({  activeUser: state.auth?.activeUser, patient: state.patients.selectedPatient, shippingAddresses: state.patients.shippingAddresses, navs: state.navs })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PatientShippingAddress)
