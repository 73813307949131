import React, {useEffect, useState} from 'react';
import {Body1, Headline4, Headline6, Subtitle1, Subtitle2span} from '../../../components';
import HeaderTop from '../../../components/HeaderTop';
import {connect, useDispatch} from 'react-redux';
import BarGraph from './components/BarGraph';
import PatientUpdates from '../Dashboard/components/PatientUpdates';
import {Avatar, Card, Col, Divider, Empty, Grid, Modal, Row, Segmented, Skeleton} from 'antd';
import NewAppointmentChart from './components/AppointmentChart';
import PatientStats from '../Dashboard/components/PatientsStats';
import ProspectsPieChart from './components/ProspectsPieChart';
import {dialogCloser, emptyFun, formSubmitButton, horStack, modalClose, outlinedButton} from '../../../common/helpers';
import {
  fetchFacilityById,
  fetchFacilityProgress,
  fetchFacilityProspects,
  fetchFacilityStatsById,
  fetchPatientUpdates,
  resetPatientUpdatePagination,
  updatePatientUpdateFilters
} from '../../../app/Reducers';
import FacilityInformation from './components/FacilityInformation';
import {CaretDownOutlined, CaretRightOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import {apiRequest} from '../../../app/Apis';
import NextAppointments from './components/NextAppointments';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TableFilters from '../../../components/InputComponents/TableFilters';
import FacilityCards from './components/FacilityCards';
import {FacilityLoadingdiv} from '../utils';
import NewReferralCard from '../../Patients/NewReferralCard';


const appointmentStyles = { height: 'fit-content', padding: '20px', marginLeft: 'auto', marginRight: 'auto', width: '97%', borderRadius: '12px' };
const defaultOptions = ['Week', 'Month'];
const { useBreakpoint } = Grid;
const FacilityDashboard = ({
                               facilityStats,
                               activeUser,
                               patients,
                               facility,
                               facilityId,
                               showHeader,
                               FacilityLoading,
                               loadingFacilityPatients,
                               appConf,
                               patientUpdatePagination,
                               patientUpdates
                           }) => {
    const dispatch = useDispatch();
    const windowState = useBreakpoint();
    const dateFormat = 'YY-MM-DD';
    const [totalWounds, setTotalWounds] = useState(null);
    const [totalPatients, setTotalPatients] = useState(null);
    // total prospects
    const [totalProspects, setTotalProspects] = useState(null);
    const [clearFilters, setClearFilters] = useState(false);
    const [filteredProspects, setFilteredProspects] = useState([]);
    const [clearing, setClearing] = useState(false);
    const [filterStateAppointments, setFilterStateAppointments] = useState('Week');
    const [filterStateProspects, setFilterStateProspects] = useState('Week');
    const [filterPatientUpdate, setFilterPatientUpdate] = useState('Week');
    const [showFacility, setShowFacility] = useState(false);
    const [searchForm, setSearchForm] = useState(null);
    const [healingRate, setHealingRate] = useState(null);
    const [endDate, setEndDate] = useState(undefined);
    const [startDate, setStartDate] = useState(undefined);
    const [isVisible, setIsVisible] = useState(false);

    async function clearFilterFunction() {
        setClearing(true);
        setClearFilters(false);
        filteredProspects.length = 0;
        setClearing(false);
        searchForm.resetFields();
    }

    async function getDates(state) {
        if (state !== 'Week') {
            const now = new Date();
            const first = new Date(now.getFullYear(), now.getMonth(), 1);
            const firstDay = dayjs(first).format(dateFormat);
            const last = new Date(now.getFullYear(), now.getMonth() + 1, 0);
            // add one day to last day of month
            const lastDay = dayjs(last).format(dateFormat);
            console.log(lastDay);
            return { firstDay, lastDay };
        } else {
            const startOfWeek = dayjs().startOf('isoweek').toDate()
            const firstDay = dayjs(startOfWeek).format(dateFormat);
            var endOfWeek = dayjs().endOf('isoweek')
            const lastDay = dayjs(endOfWeek).format(dateFormat);
            return { firstDay, lastDay };
        }
    }

    /*  async function fetchPatientUpdates() {
        let dates = await getDates(filterPatientUpdate);
        if (filterPatientUpdate === 'Week') {
          await fetchFacilityProgress({ id: facility.id, startDate: dates.startOfWeek, endDate: dates.endOfWeek });
        } else {
          await fetchFacilityProgress({ id: facility.id, startDate: dates.firstDay, endDate: dates.lastDay });
        }
      }*/
    const loadFacilityData = async (id) => {
        // await dispatch(fetchFacilityStats());
        if (!facility) {
            await dispatch(fetchFacilityById(id));
        }
        await dispatch(fetchFacilityStatsById(id));
        // await dispatch(fetchFacilityAppointments());
        await dispatch(fetchFacilityProgress({ id: id, startDate: undefined, endDate: undefined }));
        await dispatch(fetchFacilityProspects());
        await fetchHealingRateRank(id);
        await dispatch(fetchPatientUpdates({ filterData: { facility_id: id } }))
    };

    const fetchHealingRateRank = async (id) => {
        const res = await apiRequest.get(`/facilities/${id}/heal-rate-ranking`);
        console.log("Healing Rate::", res.data.data)
        setHealingRate(res.data.data);
        return res.data
    }

    useEffect(() => {
        let facilityID = activeUser?.facilityProfile ? facility?.id : facilityId;
        loadFacilityData(facilityID).catch(e => console.log(e));
        setTotalWounds(facilityStats?.open_wounds['total']);
        setTotalPatients(facilityStats?.patients_by_status['total']);

        return emptyFun;
    }, []);

    useEffect(() => {
        setTotalWounds(facilityStats?.open_wounds['total']);
        setTotalPatients(facilityStats?.patients_by_status['total']);
        setTotalProspects(facilityStats?.prospects_by_status && Object.values(facilityStats?.prospects_by_status).reduce((acc, value) => {
            return acc + value;
        }));
    }, [facilityStats]);

    useEffect(() => {
        dispatch(fetchFacilityProgress({ id: facility.id, startDate: startDate, endDate: endDate }));
    }, [startDate, endDate])

    // useEffect(() => {
    //   fetchPatientUpdates();
    // }, [filterPatientUpdate]);

    useEffect(() => {
        setFilteredProspects([])
        loadFacilityData(facility.id).catch(e => console.log(e));
        return emptyFun
    }, [facility])


    function getSum(data) {
        const values = data?.map((item) => item.value);
        const sumVal = values?.reduce((acc, value) => {
            return acc + value;
        });
        return sumVal;
    }

    let schedule = facilityStats?.scheduled_appointments;
    let woundRate = facilityStats?.open_wounds;

    const [prospectForm, setProspectForm] = useState(false)

    const [isExecuting, setisExecuting] = useState(false)

    function getDotColorByWound(wound) {
        let color;
        if (wound === 'Venous Stasis Ulceration') {
            color = '#4B9BFF';
        } else if (wound === 'Arterial Ulceration') {
            color = '#7785FF';
        } else if (wound === 'Heel Ulceration') {
            color = '#2E5AAC';
        } else if (wound === 'Sacral Ulceration') {
            color = '#f4e409';
        } else if (wound === 'Unstageable Ulceration') {
            color = '#a8dcd9';
        } else if (wound === 'Pressure Ulceration') {
            color = '#bdfffd';
        } else if (wound === 'Surgical Ulceration') {
            color = '#315659';
        } else if (wound === 'Diabetic Foot Ulceration') {
            color = '#7cffc4';
        } else if (wound === 'Unclassified Wounds') {
            color = '#6abea7';
        } else {
            color = '#5e6973';
        }
        return <div style={{ backgroundColor: `${color}`, width: '10px', height: '10px', borderRadius: '50%', margin: '5px' }}/>
    }

    function sliceHealingRate(value) {
        return `${parseFloat(value.replace(' cm/day') || 0).toFixed(4)}  cm/day`;
    }

    const columns = [
        {
            title: 'Patient', dataIndex: 'patient', key: 'patient', fltr: { filterable: true, type: 'text' },
            render: (text, render) => (<div><h4 style={{ textAlign: 'left' }}>{render?.patient_name ?? 'N/A'}</h4></div>)
        },
        {
            title: 'Wound', dataIndex: 'location', key: 'wound', fltr: { filterable: true, type: 'text' },
            render: (text, render) => (
                <div>
                    <div className="flex-boy-row">{getDotColorByWound(render?.classification)}<h4 style={{ textAlign: 'left' }}>{render?.classification ?? 'N/A'} </h4></div>
                    <Subtitle2span style={{ textAlign: 'left', marginLeft: '20px', color: '#827F86' }}> {render?.location ?? 'N/A'}</Subtitle2span>
                </div>
            )
        },
        {
            title: 'Visits', dataIndex: 'visits', key: 'visits',
            render: (text, render) => (
                <div><h4 style={{ textAlign: 'left' }}>{render?.visits_count ?? 'N/A'}</h4>
                    <Subtitle2span style={{ textAlign: 'left', color: '#827F86' }}>Last {dayjs(render?.latest_assessment_date).format('MM/DD/YYYY')} </Subtitle2span>
                </div>
            )
        },
        {
            title: 'Projected Days to Healing', dataIndex: 'healing_rate', key: 'healing_rate',
            render: (text, render) => (
                <h4 style={{ textAlign: 'left' }}>
                    {render?.healing_rate?.time_to_closure === 'INF'
                        ? 'N/A' :
                        render?.healing_rate?.time_to_closure > 1000
                            ? 'Infinite'
                            : render?.status === 'Healed' ? '0' : render?.healing_rate?.time_to_closure}
                </h4>
            )
        },
        {
            title: 'Healing Rate', dataIndex: 'healing_rate', key: 'healing_rate',
            render: (text, render) => (
                <div>
                    <h4 style={{ textAlign: 'left' }}>
                        {sliceHealingRate(render?.healing_rate?.continuous_healing_rate) ??
                            'N/A'}
                    </h4>
                    <Body1
                        color={parseFloat(render?.healing_rate?.percent_healed) > 0
                            ? "#07bf26" : "#f72a48"}>{parseFloat(render?.healing_rate?.percent_healed) > 0
                        ? String.fromCharCode(8599) : parseFloat(render?.healing_rate?.percent_healed) === 0
                            ? null : String.fromCharCode(8601)} {parseFloat(render?.healing_rate?.percent_healed || 0)?.toFixed(2)}%</Body1>
                </div>
            )
        },
        {
            title: 'Volume Reduction', dataIndex: 'volume_reduction', key: 'volume_reduction',
            render: (text, render) => (
                <div>
                    <h4 style={{ textAlign: 'left' }}>
                        {(render?.current_wound_volume).toFixed(2) ?? 'N/A'}
                    </h4>
                    <Body1
                        color={parseFloat(render?.volume_reduction) > 0
                            ? "#07bf26" : "#f72a48"}>{parseFloat(render?.volume_reduction) > 0
                        ? String.fromCharCode(8601) : parseFloat(render?.volume_reduction) === 0
                            ? null : String.fromCharCode(8599)} {parseFloat(render?.volume_reduction || 0)?.toFixed(2)}%</Body1>
                </div>
            )
        },
        {
            title: 'Subs Applied', dataIndex: 'grafts_applied', key: 'grafts_applied',
            render: (text, render) => (<h4 style={{ textAlign: 'left' }}>{render?.grafts_applied ?? 'N/A'}</h4>)
        }
    ];
    let timeFilter = {
        title: 'Updated date', dataIndex: null, key: 'dateRange',
        fltr: { filterable: true, type: 'date_range', keys: ['dateRange[start_at]', 'dateRange[end_at]'], baseKey: 'dateRange_filter' }
    }

    async function handleFilterAction(action, values) {
        console.log({ action, values })
        await dispatch(resetPatientUpdatePagination())
        if (action === 'filter') {
            let pl = { filterData: { ...values, facility_id: facility.id }, pagination: patientUpdatePagination }
            await dispatch(updatePatientUpdateFilters([false, pl, true]))
            await dispatch(fetchPatientUpdates(pl))
            await dispatch(updatePatientUpdateFilters([true, pl, false]))
        }
        if (action === 'reset') {
            await dispatch(updatePatientUpdateFilters([appConf.patientUpdateFilters[0], undefined, true]))
            await dispatch(fetchPatientUpdates({ filterData: { facility_id: facility?.id } }))
            await dispatch(updatePatientUpdateFilters([false, undefined, false]))
        }
        setIsVisible(false)
    }

    return (
        <div className="w-full h-full px-10 gap-4 flex flex-col">
            <Modal
                title={"New Referral"}
                open={prospectForm}
                onOk={dialogCloser(setProspectForm)}
                onCancel={dialogCloser(setProspectForm)}
                destroyOnClose
                closeIcon={modalClose(dialogCloser(setProspectForm))}
                maskClosable={false}
                width={1200}
                footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
                    {horStack([
                        outlinedButton(dialogCloser(setProspectForm), 'Cancel', isExecuting),
                        formSubmitButton('new-prospect-form', 'Create', isExecuting, 'Creating')])}
                </div>]}>
                <NewReferralCard _facility={facility} setCreatingProspectExecuting={setisExecuting} loadFacilityData={loadFacilityData} setisExecuting={setisExecuting}
                                 closeModal={dialogCloser(setProspectForm)}/>
            </Modal>
            {/* div with spinner for facility loading */}
            {FacilityLoading ?
                <FacilityLoadingdiv/> :
                <>
                    {activeUser?.facilityProfile && (
                        <HeaderTop child={
                            <div className="flex flex-row">
                                <Subtitle1 style={{ color: '#272833', cursor: 'pointer' }} onClick={() => setShowFacility(!showFacility)}>
                                    {showFacility ? <CaretDownOutlined/> : <CaretRightOutlined/>}{' '}
                                    {showFacility ? 'Hide Details' : 'Show Details'}
                                </Subtitle1>
                            </div>
                        }
                        />
                    )}
                    <div style={{ width: '100%', visibility: !showFacility && 'hidden', opacity: !showFacility ? 0 : 1, transition: 'opacity 0.2s linear', height: !showFacility && 0 }}>
                        <FacilityInformation activeUser={activeUser} facility={facility}/>
                    </div>
                    <div className={'w-full '}>
                        <Row className={'w-full m-0'} gutter={[4, 0]} align={"stretch"} justify={"space-between"}>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <FacilityCards logo={'schedule'} schedule={schedule}/>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <FacilityCards logo={'user'} schedule={woundRate}/>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <FacilityCards logo={'healing'} schedule={healingRate}/>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <div className="facility-cards">
                                    <Card
                                        style={{
                                            width: '100%',
                                            borderRadius: '12px',
                                            padding: '10px'
                                        }}
                                    >
                                        <Headline6>Total patients</Headline6>
                                        <Headline4 style={{ textAlign: 'left' }}>{totalPatients || "0"}</Headline4>
                                        <div className="flex flex-row mt-4 justify-between items-center">
                                            <Subtitle1
                                                className="mt-4"
                                                style={{
                                                    color: '#827F86',
                                                    textAlign: 'left',
                                                    fontWeight: 'bold',
                                                    marginRight: '10px',
                                                    marginTop: '20px !important'
                                                }}>
                                                {totalProspects} Referrals
                                            </Subtitle1>
                                            <Avatar
                                                onClick={() => setProspectForm(true)}
                                                icon={<FontAwesomeIcon color="white" icon={['fas', 'plus']}/>}
                                                size={40}
                                                style={{
                                                    backgroundColor: 'crimson',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            />
                                        </div>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className={'shadow-card-box w-full mb-0'}>
                        <Headline6 style={{ alignItems: 'flex-start', paddingLeft: '10px' }}> Wound Type </Headline6>
                        <Divider type="horizontal"/>
                        {facilityStats ? (
                            <div>
                                {facilityStats?.wound_types?.length > 0 ? (<BarGraph woundType={facilityStats?.wound_types}/>) : (
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Wound Types available"/>)}
                            </div>
                        ) :
                          (<div className={'w-full flex flex-col justify-center gap-2'}>
                                <Skeleton paragraph={{ rows: 4 }} active/>
                            </div>
                          )}
                    </div>
                    <div className="shadow-card-box w-full mb-0">
                        <div className="flex-boy-row-space" style={{ width: '100%' }}>
                            <Headline6 style={{ alignItems: 'flex-start', paddingLeft: '10px', width: "25%" }}>Patients updates</Headline6>
                            {<div className={'p-4 w-full'}>
                                <TableFilters datasource={[...columns, timeFilter]}
                                              setIsVisible={setIsVisible} isVisible={isVisible}
                                              extraLeftActions={[]}
                                              handleFilterAction={handleFilterAction}
                                              filters={appConf.patientUpdateFilters[1]}
                                              showClear={appConf.patientUpdateFilters[0]}
                                              actionButton={<Segmented options={defaultOptions} onChange={setFilterPatientUpdate} value={filterPatientUpdate}/>}
                                              loading={appConf.patientUpdateFilters[2]}/></div>
                            }
                        </div>
                        <Divider type="horizontal"/>
                        <PatientUpdates facility={facility} filterState={filterPatientUpdate} loading={loadingFacilityPatients} windowState={windowState}
                                        filteredProspects={filteredProspects} clearFilters={clearFilters} columns={columns}/>
                    </div>
                    <Row className=" w-full px-0" gutter={8} justify={'space-between'}>
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} className={'w-full'}>
                            <div style={{ minHeight: "100%" }} className=" rounded-md card-box mb-2">
                                <div style={{ textAlign: 'left' }}><Headline6>Appointments for next 30 days</Headline6></div>
                                <NextAppointments/>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8} className={'w-full'}>
                            <div style={{ minHeight: "100%" }} className="card-box">
                                <div className="flexy-row">
                                    <div style={{ textAlign: 'left' }}><Headline6>Appointments History</Headline6></div>
                                    <Segmented options={defaultOptions} onChange={setFilterStateAppointments} value={filterStateAppointments}/>
                                </div>
                                <Divider type="horizontal"/>
                                <div style={{ width: '100%' }}>
                                    <NewAppointmentChart appointments={facilityStats?.appointments_by_status} facility={facility} filterState={filterStateAppointments}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="flex-boy-row more-stats w-full mb-0" style={{ gap: 10 }}>
                        <div className="card-box more-stats-patient">
                            <Headline6 style={{ alignItems: 'flex-start', paddingLeft: '10px' }}>All Patients Stats</Headline6>
                            <Divider type="horizontal"/>
                            <Headline6 style={{ alignItems: 'flex-start', paddingLeft: '10px' }}> Total patients </Headline6>
                            <Headline6 style={{ fontWeight: 'bold', alignItems: 'flex-start', paddingLeft: '10px' }}>
                                {facilityStats?.patients_by_status['total'] || 'N/A'}
                            </Headline6>
                            <PatientStats facilityStats={facilityStats}/>
                        </div>
                        <div className="card-box more-stats-prospects" style={{ borderRadius: '10px' }}>
                            <div className="flex-boy-row-space" style={{ width: '100%' }}>
                                <div style={{ textAlign: 'left' }}><Headline6>All Referral Stats</Headline6></div>
                                <div><Segmented options={defaultOptions} onChange={setFilterStateProspects} value={filterStateProspects}/></div>
                            </div>
                            <Divider type="horizontal"/>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ProspectsPieChart prospects={facilityStats?.prospects_by_status}
                                                                                                                                filterState={filterStateProspects}
                                                                                                                                facility={facility}/></div>
                        </div>
                    </div>
                </>}

        </div>

    );
};

const mapStateToProps = (state) => ({
    permisions: state.auth?.permisions,
    facilities: state.facilities,
    facility: state.facilities.selectedFacility,
    appointments: state.facilities?.facilityAppointments,
    patients: state.facilities?.facilityPatients,
    prospects: state.facilities?.facilityProspects,
    schedule: state.facilities.totalAppointments,
    allProspects: state.facilities.totalProspects,
    appConf: state.appConf,
    navs: state.navs,
    activeUser: state.auth?.activeUser,
    state: state,
    facilityStats: state?.facilities?.facilityStats,
    FacilityLoading: state?.facilities.FacilityLoading,
    loadingFacilityPatients: state.facilities.loadingFacilityPatients,
    patientUpdatePagination: state.facilities?.patientUpdatePagination,
    patientUpdates: state.facilities?.patientUpdates
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FacilityDashboard);
