import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import HeaderTop from '../../components/HeaderTop'
import {Menu, Modal, Tabs} from 'antd';
import PatientSummary from './components/PatientSummary'
import PatientEncounterList from './components/PatientEncounterList'
import PatientWounds from './components/PatientWounds/PatientWounds'
import PatientHealthDataJs from './components/PatientHealthData/index.js'
import {fetchPatientById, resetPatientWoundsPagination, setPatientNavs, updatePatientDetails} from '../../app/Reducers'
import Documents from './components/PatientDocuments/Documents'
import InternalNotes from './components/InternalNotes'
import {dialogCloser, emptyFun, formSubmitButton, getHeaderLeftNav, horStack, modalClose, outlinedButton} from "../../common/helpers";

import * as PropTypes from "prop-types";
import PatientInfoPane from './components/PatientInfoPane';
import PatientDetailsForm from './components/patientSummaryForms/patientDetailsForm';
import PatientInsuranceInfo from './components/PatientInsuranceInfo';
import PatientDetails from './components/PatientDetails';
import TimeLine from './components/TimeLine';
import ConsentForms from './components/PatientDocuments/ConsentForms';
import {usePermissionCheck} from '../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';

function FontAwesomeIcon(props) {
  return null;
}

FontAwesomeIcon.propTypes = {
  icon: PropTypes.arrayOf(PropTypes.string),
  color: PropTypes.string
}
 const NewPatientPage = ({ patient, activeUser, navs }) => {
   const location = useLocation()
   const navigate = useNavigate();
  const myRef = useRef(null);
  const can_reactivate_deceased_patient = usePermissionCheck('reactivate_deceased_patient', false, [])
  const approval_statuses = useSelector(state => state.masters.masterListRaw.find(item => item.system_name === 'approval_status')?.options)
  const can_view_patient_progress_note = usePermissionCheck('view_patient_progress_note', false, [])
  const can_view_patient_insurance_policy = usePermissionCheck('view_patient_insurance_policy', false, [])
  const dispatch = useDispatch()
  const patientId = Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  let stati = useSelector(state => state.masters.masterListRaw.find(item => item.system_name === 'patient_status')?.options)
  const TabList = ["Dashboard", !activeUser.facilityProfile?.id ? "Timeline" : null, "Patient Details", "Insurance", "Encounter List", "Documents", "Consent Forms"].filter(Boolean)

  //implement loading of patient data in case this is a navigation from the roue map
  useEffect(() => {
    console.log({ patientId, patient })
    if (patientId !== patient?.id) {
      dispatch(fetchPatientById(patientId))
    }
    return emptyFun
  }, [])
  
  async function handleMenuClick(e) {
    setIsVisible(prevState => !prevState)
    console.log(stati[e.key])
    let pl = { status: stati[e.key], patient_id: patientId }
    await setUpdatingStatus(true)
    await dispatch(updatePatientDetails(pl))
    await setUpdatingStatus(false)
  }
  
  const scrolltop = () => {
    myRef.current.scrollTop = 0;
  }
  
  const menu = (<Menu disabled={!!patient?.deleted_at} onClick={handleMenuClick}>
    {can_reactivate_deceased_patient && patient?.status === 'deseased' ?
      stati?.filter(item => item === "active")?.map((item, index) => <Menu.Item key={index}
                                                                                style={{ textTransform: 'capitalize' }}> {item.replace('-', '').replace(/_/g, ' ')}  </Menu.Item>) :
      stati?.map((item, index) => <Menu.Item key={index}
                                             style={{ textTransform: 'capitalize' }}> {item.replace('-', '').replace(/_/g, ' ')}  </Menu.Item>)}
  </Menu>);
  const changTab = async (tab) => {
    console.log('changTab:  ', tab)
    await dispatch(resetPatientWoundsPagination())
    dispatch(setPatientNavs({ firstTab: tab }))
  }
  const { TabPane } = Tabs;
  
  async function approvalMenuClick(e) {
    console.log('click', approval_statuses, approval_statuses[parseInt(e.key)], e.key, approval_statuses[7]);
    let pl = { approval_status: approval_statuses[e.key], patient_id: patientId }
    await setIsApproveLoading(true)
    await dispatch(updatePatientDetails(pl))
    await setIsApproveLoading(false)
  }
  
  const approvalMenu = (<Menu disabled={!!patient?.deleted_at} onClick={approvalMenuClick}>
    {approval_statuses?.map((item, index) =>
      <Menu.Item key={index} style={{ textTransform: 'capitalize' }}> {item.replace('-', '').replace(/_/g, ' ')}  </Menu.Item>)} </Menu>);
  
  
  const [detailsForm, setDetailsForm] = useState(false);
  const [shippingForm, setShippingForm] = useState(false);
  const [isExecuting, setisExecuting] = useState(false)
  
  
  return (
    (<div className="main-page flex flex-col">
      <Modal
        destroyOnClose={true}
        title="Patient Information"
        open={detailsForm}
        onOk={dialogCloser(setDetailsForm)}
        onCancel={dialogCloser(setDetailsForm)}
        closeIcon={modalClose(dialogCloser(setDetailsForm))}
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(dialogCloser(setDetailsForm), 'Cancel', isExecuting),
            formSubmitButton('patientForm', 'Update', isExecuting, 'Updating')])}
        </div>]}>
        <PatientDetailsForm setisExecuting={setisExecuting} patient={patient} closeModal={dialogCloser(setDetailsForm)}/>
      </Modal>
      <HeaderTop child={getHeaderLeftNav(navigate, 'Patient Card')}/>
      <div className="grid grid-cols-8 flex-1 bg-gray-200">
        <div className="col-span-2 bg-gray-200  sticky">
          <PatientInfoPane setDetailsForm={setDetailsForm}/>
        </div>
        <div className="col-span-6 bg-slate-200 h-full   ">
          {patient ? <>
            <div id="patient-encounter-tabs" className="page-header  bg-white">
              
              
              <Tabs type="card" defaultActiveKey={navs.patientNavs.firstTab} activeKey={navs.patientNavs.firstTab} onChange={changTab} tabBarStyle={{ marginBottom: 0 }}>
                {TabList.map(item => <TabPane key={item} tab={<p>{item}</p>}>
                
                </TabPane>)}
                {!activeUser?.facilityProfile && <TabPane key={"Internal Notes"} tab={<p>Internal Notes</p>}>
                
                </TabPane>}
              </Tabs>
            
            </div>
            
            {navs.patientNavs && <div ref={myRef} className="main-body py-[10px]" style={{ height: 'calc(100vh - 150px)', overflowY: 'scroll' }}>
              {navs.patientNavs.firstTab === "Dashboard" ? <PatientSummary scrolltop={scrolltop} changTab={changTab}/> :
                navs.patientNavs.firstTab === "Encounter List" ? <PatientEncounterList/> :
                  navs.patientNavs.firstTab === "Wounds" ? <PatientWounds/> :
                    navs.patientNavs.firstTab === "Documents" ? <Documents/> :
                      navs.patientNavs.firstTab === "Consent Forms" ? <ConsentForms/> :
                        (navs.patientNavs.firstTab === "Internal Notes" && can_view_patient_progress_note) ? <InternalNotes/> :
                          (navs.patientNavs.firstTab === "Insurance" && can_view_patient_insurance_policy) ? <div className="px-10"><PatientInsuranceInfo/></div> :
                            navs.patientNavs.firstTab === "Patient Details" ? <div className="px-10"><PatientDetails/></div> :
                              navs.patientNavs.firstTab === "Timeline" ? <div className="px-10"><TimeLine/></div> :
                                <PatientHealthDataJs/>}
            </div>}
          </> : null}
        </div>
      </div>
    </div>)
  );
}

const mapStateToProps = (state) => ({
  patient: state.patients.selectedPatient, activeUser: state.auth?.activeUser,
  navs: state.navs,
  masterList: state.masters.masterListRaw
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(NewPatientPage)