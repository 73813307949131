/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Avatar, Grid, Modal, Table, Tabs} from "antd"
import {Subtitle1} from '../../../../components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  fetchEncounters,
  fetchPatientById,
  filterPatientEncounters,
  resetPatientEncountersPagination,
  selectAssessment,
  setPatientNavs,
  updatePatientDetailsOwner,
  updatePatientEncounterListFilters
} from '../../../../app/Reducers'
import dayjs from 'dayjs'
import {
  containedButton,
  dialogCloser,
  emptyFun,
  emptyTable,
  formSubmitButton,
  getAssessmentStatus,
  getLabStatus,
  GetRawListOptions,
  getSkels,
  horStack,
  IsItemEnabled,
  makeAutoCompleteOptions,
  makeSelectOptions,
  onTableChange,
  outlinedButton,
  responsiveTbl
} from "../../../../common/helpers";
import VisitStatus from "../../../Appointments/components/VisitStatus";

import {toast} from "react-toastify";
import {apiRequest} from "../../../../app/Apis";

import EditAssessmentModal from "../../../Appointments/components/EditAssessmentModal";
import {genAllograftReport, generateLabRequisition, genReport} from "../../../../app/Apis/commonApi";
import VisitDetails from "../../../Appointments/components/VisitDetails";
import PdfViewer from "../../../../components/PdfViewer";
import PrescriptionSection from '../PatientHealthData/PrescriptionSection'
import AuditLogViewer from "../../../../components/AuditLogs/AuditLogViewer";
import TableFilters from "../../../../components/InputComponents/TableFilters";
import {useSubscription} from "../../../../hooks/MqttHooks/useSubscription";
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
import LabReview from './LabReview'
import {useProcedureSearch} from '../../../../hooks/useProcedureSearch'


const { TabPane } = Tabs;
const { useBreakpoint } = Grid;
const Encounters = ({ history, encounters, patient, appConf, navs, patientEncountersPagination, activeUser, settings }) => {
  const windowState=useBreakpoint()
  const [procedureOptions, debouncedProcedureSearch, procedureSearchLoading] = useProcedureSearch([], 'name')
  console.log({procedureOptions})
  const procedureTypeFilter={
    title: 'Procedure Type', dataIndex: 'procedure', key: 'procedure', fltr: {
      filterable: true, type: 'select', options: procedureOptions,
      props: {
        loading: procedureSearchLoading,
        onSearch: (value) => debouncedProcedureSearch(value), showSearch: true, filterOption: false
      }
    }, render: (recommended_procedure) => {
      if (recommended_procedure && Array.isArray(recommended_procedure) && recommended_procedure.length > 0) {
        return recommended_procedure.join(', ')
      } else {
        return recommended_procedure
      }
    }
  }
  const labProcedureFilter={
    title: 'Procedure Type', dataIndex: 'procedure', key: 'procedure', fltr: {
      filterable: true, type: 'select', options: makeSelectOptions(['Lab-Bacterial','Lab-Fungal','Lab-Nail','Lab']),
      props: {
        loading: procedureSearchLoading,
        onSearch: (value) => debouncedProcedureSearch(value), showSearch: true, filterOption: false
      }
    }, render: (recommended_procedure) => {
      if (recommended_procedure && Array.isArray(recommended_procedure) && recommended_procedure.length > 0) {
        return recommended_procedure.join(', ')
      } else {
        return recommended_procedure
      }
    }
  }
  const otherColumns = [/*{
      title: 'Consultant',

      dataIndex: ['appointment','consultant','name'],
      key: 'wcc',

      render: (name) => (
          <>
              <div className="flex-boy-row-space" style={{justifyContent:'normal'}}>
                  <Avatar src={<Image src={require("../../../../assets/user.png")} />} />
                  <Subtitle1  style={{textAlign:"left",marginLeft:'10px'}}>{name}</Subtitle1>
              </div>
          </>
      ),

  },*/
    {
      title: 'Date of service', dataIndex: ['appointment', 'start_at'], key: 'dateOfService', fltr: { filterable: true, type: 'date' },
      render: (start_at) => (
        <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(start_at).format("MM/DD/YYYY")}</Subtitle1>)
    },
    {
      title: 'Date created', dataIndex: ['created_at'], key: 'created_at', fltr: { filterable: true, type: 'date' },
      render: (start_at) => <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(start_at).format("MM/DD/YYYY")}</Subtitle1>
    }, {
      title: 'Wound', key: 'woundLocation', width: "12%", fltr: { filterable: true, type: 'text' },
      render: (record) => (<Subtitle1 style={{ textAlign: 'left' }}>{record.wound_side} {record.wound_position} {record.location} </Subtitle1>)
    }, procedureTypeFilter, {
      title: 'Assessment Status', dataIndex: 'review', key: 'reviewStatus', fltr: {
        filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(['rejected', 'approved', 'pending_review'])
      }, render: (review) => getAssessmentStatus(review?.status?.toLowerCase())
    },
    {
      title: "Progress Status", key: 'progressStatus', width: "12%",
      render: (record) => <Subtitle1 style={{ textAlign: 'left' }}>{record.published_at ? "Completed" : "Draft"}</Subtitle1>,
      fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(["Completed", "Draft"]) }
    }, { title: <FontAwesomeIcon color="grey" icon={["fas", "table"]}/>, key: 'operation', width: 70 }
  ]
const labColumns = [
    {
      title: 'PCR Type',
      dataIndex: "pcr_type",
      key: 'pcrType',
      fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(GetRawListOptions('p_c_r_type')) },
     render: (pcr_type,record) => (<Subtitle1 style={{ textAlign: 'left' }}>{record?.lab_tests?.[0]?.pcr_type?.toString().replaceAll(",", ", ")}</Subtitle1>)
    },
    {
      title: 'Sample Id', dataIndex: 'sample_id', key: 'sample_id', width: "12%", fltr: { filterable: true, type: 'text' },
      render: (sample_id, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{record?.lab_tests?.[0]?.sample_id}</Subtitle1>)
    },
    {
      title: 'Lab Region', dataIndex: 'lab_region', key: 'lab_region', width: "10%", fltr: { filterable: true, type: 'text' },
      render: (lab_region, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{record?.lab_tests?.[0]?.lab_region}</Subtitle1>)
    },
   
    {
      title: 'Intake Date', dataIndex: "intake_date", key: 'intake_date', fltr: { filterable: true, type: 'date' },
      render: (intake_date, record) => (
         record.lab_tests?.[0]?.intake_date ? <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(record.lab_tests?.[0]?.intake_date).format("MM/DD/YYYY")}</Subtitle1> : "-")
    },
    {
      title: 'Status', key: 'status', fltr: {
        filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(['in progress', 'completed', 'ready for dispatch']),
       
      },
      render: (record) => getLabStatus(record.lab_tests?.[0]?.status)
    },
    {
      title: 'Reviewed',  key: 'reviewed', width: "12%", fltr: { filterable: true, type: 'date' },
      render: (record) => (
        record.lab_tests?.[0]?.results_reviewed_at ? <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(record.lab_tests?.[0]?.results_reviewed_at).format("MM/DD/YYYY")}</Subtitle1> : "-")
    },
    {

      title: 'Reviewed By', key: 'reviewed_by', width: "14%",
      fltr: { filterable: true, type: 'string' },
      render: ( record) => {
        console.log({ record})
        return  record.lab_tests?.[0]?.reviewed_by?.id ? <div className="flex flex-row items-center">
            <Avatar
              size={24}
              className="mr-2" src={record.appointment.consultant?.profile_image_url} alt={record.appointment.consultant?.name}>{record.appointment.consultant?.name?.charAt(0)}</Avatar>
            <Subtitle1 className="ml-2">{record.appointment.consultant?.name}</Subtitle1>
          </div> : "-"
        
      }
    }
    ,
    {
      title: "", dataIndex: ['report', 'url'], key: 'operation', width: 70,
      render: (report, record) => {
        let url = record.lab_tests?.[0]?.report?.url
        if (!record.lab_tests?.[0]?.visit_summary) {
          return (url ? containedButton((e) => {
            e.stopPropagation()
            setWoundAssessment(record)
            setLabReviewUrl(url)
            setLabReview(true)
          }, 'Review') : null)
        } else {
          return (url ? <FontAwesomeIcon color="grey" icon={["fas", "download"]} onClick={(e) => {
            e.stopPropagation()
            window.open(url, '_blank')
          }} /> : null)
        }
       
      }
    },
    
  ]
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate();
  const can_view_assessment = usePermissionCheck('view_assessment', false, [])
  const [isVisible, setIsVisible] = useState(false);
  const [tab, setTab] = useState(navs.patientNavs.encounterTab);
  const [woundAssessment, setWoundAssessment] = useState(null)
  const [VisitModal, setVisit] = useState(false)
  const [visitStatusModal, setStatusModal] = useState(false)
  const [editassessmentModal, seteditassessmentModal] = useState(false)
  const [isExecuting, setisExecuting] = useState(false)
  const [isPrinting, setPrinting] = useState(false)
  const [isLabPrinting, setLabPrinting] = useState(false)
  const [isAllograftPrinting, setAllograftPrinting] = useState(false)
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const { message } = useSubscription(`view_patient_assessments/${patientId}`, { qos: 2, nl: true });
  const [pdfShowing, setPdfShowing] = useState(false);
  const [pdfLoading, setLoading] = useState(false);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [labReviewUrl, setLabReviewUrl] = useState(null);
  
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await loadEncounters(true)
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    (async () => {
      await loadEncounters(false)
    })().catch(e => console.log(e))
    return emptyFun
  }, [tab])
  const loadEncounters = async (isMQMessage) => {
    //console.log({ isTabChange, isMQMessage, type: tab, type1: navs.patientNavs.encounterTab })
    await dispatch(resetPatientEncountersPagination())
    setIsVisible(false)
    await dispatch(setPatientNavs({ encounterTab: tab }))
    /*if (isTabChange && !!encounters[tab][0] && !isMQMessage && encounters[tab].every(item => item?.appointment?.patient?.id === patientId)) {
      console.log('Returning....')
      return
    }*/
    let payload = tab === "All" ? { filterData: { patient: patientId } } : { filterData: { patient: patientId, type: tab } }
    //console.log({ payload, tab, type: navs.patientNavs.encounterTab })
    await dispatch(updatePatientEncounterListFilters([appConf.patientEncounterListFilters[0], appConf.patientEncounterListFilters[1], !isMQMessage]))
    //In case of back navigations
    if (!patient || (patientId !== patient.id)) {
      await dispatch(fetchPatientById(Number(patientId)))
      await dispatch(updatePatientDetailsOwner(patientId))
    }
    await dispatch(filterPatientEncounters(payload))
    // await dispatch(fetchProcedures())
    // await dispatch(fetchLabRegions())
    // await dispatch(loadWoundLocations())
    await dispatch(updatePatientEncounterListFilters([appConf.patientEncounterListFilters[0], appConf.patientEncounterListFilters[1], false
    ]))
  }
  const tabSwitch = async (key) => await setTab(key)
  
  async function handleFilterAction(action, values) {
    console.log('FILTERS1:  ', JSON.stringify({ ...values, toLoad: 'patientEncounters', action: action }));
    await dispatch(resetPatientEncountersPagination())
    let payload = navs.patientNavs.encounterTab === "All" ? { patient: patientId } : { patient: patientId, type: navs.patientNavs.encounterTab }
    if (action === 'filter') {
      let pl = { filterData: { patient: patientId, ...payload, ...values }, pagination: patientEncountersPagination }
      await dispatch(updatePatientEncounterListFilters([false, pl, true]))
      await dispatch(filterPatientEncounters(pl))
      await dispatch(updatePatientEncounterListFilters([true, pl, false]))
    }
    if (action === 'reset') {
      let payload = navs.patientNavs.encounterTab === "All" ? { filterData: { patient: patientId } } : {
        filterData: { patient: patientId, type: navs.patientNavs.encounterTab }
      }
      await dispatch(updatePatientEncounterListFilters([appConf.patientEncounterListFilters[0], undefined, true]))
      await dispatch(filterPatientEncounters(payload))
      await dispatch(updatePatientEncounterListFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  const handlevisitOk = () => setVisit(false)
  const handlevisitCancel = () => setVisit(false)
  const approveAsessment = async () => {
    await setisExecuting(true)
    const toastId = toast("Updating Assesment", { type: "info", autoClose: false });
    const response = await apiRequest.post(`/assessments/${woundAssessment.id}/status`, { status: "approved" }).catch(async function (err) {
      await setisExecuting(false)
      console.log(err.response.data.message)
      toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
      await dispatch(fetchEncounters())
    })
    if (response && response.data) {
      toast.update(toastId, { render: "Assessment Approved", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      await dispatch(fetchEncounters())
    }
    await setisExecuting(false)
    setVisit(false)
    setStatusModal(false)
  }
  const generateReport = () => genReport(woundAssessment, setPrinting, setVisit, setStatusModal, setPdf, setLoading, setPdfShowing)
  const generateLabRequest = () => generateLabRequisition(woundAssessment, setLabPrinting, setVisit, setStatusModal, setPdf, setLoading, setPdfShowing)
  const generateAllograftReport = () => genAllograftReport(woundAssessment, setAllograftPrinting, setVisit, setStatusModal, setPdf, setLoading, setPdfShowing)
  
  
  const declineAssessment = async (payload) => {
    await setisExecuting(true)
    const toastId = toast("Updating Assessment", { type: "info", autoClose: false });
    let data = { notes: payload.notes, status: "rejected" }
    const response = await apiRequest.post(`/assessments/${woundAssessment.id}/status`, data).catch(async function (err) {
      await setisExecuting(false)
      console.log(err)
      toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
      await dispatch(fetchEncounters())
    })
    if (response && response.data) {
      toast.update(toastId, { render: "Assessment Rejected", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      await dispatch(fetchEncounters())
    }
    await setisExecuting(false)
    setVisit(false)
    setStatusModal(false)
  }

  const biologicsEligibilityFilter = {
    key: 'woundBiologicEligibility', title: 'Biologics Eligibility', fltr: {
      filterable: true, type: 'select',
      options: [ { label: 'Eligible', value: 'eligible' }, { label: 'Not Eligible', value: 'ineligible' }]
    }
  }
  
  const tabList = ["All", "Wound Evaluation", "DME", "Wound Debridement", "Biologics", !activeUser?.facilityProfile && "Podiatry", !activeUser?.facilityProfile && "Vascular", !activeUser?.facilityProfile && "Lab", activeUser?.consultantProfile?.account_type === "Provider" && IsItemEnabled(settings, 'integrations', 'drfirst_integration') && "Prescriptions"]
  
  //labReview
  const [labReview, setLabReview] = useState(false)

  const generateLabReport = (report) => {
    setPdf(report)
    setPdfShowing(true)
  }
  return (<div id="patient-encounter-tabs">
    {(!!woundAssessment && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} resourceType={'Assessment'}
                                                               loading={false}
                                                               resourceDescription={`${woundAssessment.appointment.patient.name}'s ${woundAssessment.procedure} assessment`}
                                                               defaultValues={{ auditableId: woundAssessment?.id, auditable: 'assessment', format: 'json' }}/>}
    <PdfViewer pdf={pdf} setLoading={setLoading} loading={pdfLoading} onCancel={() => setPdfShowing(false)} visible={pdfShowing}/>
    {!!woundAssessment &&
      <EditAssessmentModal editassessmentModal={editassessmentModal} seteditassessmentModal={seteditassessmentModal} loadEncounters={loadEncounters}
                           isExecuting={isExecuting} setVisit={setVisit} setisExecuting={setisExecuting} sellectedVisit={woundAssessment}/>}
    <VisitStatus visitStatusModal={visitStatusModal} setStatusModal={setStatusModal} isExecuting={isExecuting} declineAssessment={declineAssessment}/>
    {(VisitModal && !!woundAssessment) &&
      <VisitDetails setShowAuditDialog={setShowAuditDialog}
        // assessment={woundAssessment}
      setVisit={setVisit} approveAsessment={approveAsessment}
      generateLabReport={generateLabReport}
                    VisitModal={VisitModal} seteditassessmentModal={seteditassessmentModal} isAllograftPrinting={isAllograftPrinting}
                    isExecuting={isExecuting} isPrinting={isPrinting} isLabPrinting={isLabPrinting} setStatusModal={setStatusModal}
                    handlevisitOk={handlevisitOk} handlevisitCancel={handlevisitCancel} generateReport={generateReport} 
                    generateLabRequest={generateLabRequest} generateAllograftReport={generateAllograftReport}/>}
    
    <Tabs onChange={tabSwitch} type="card" defaultActiveKey={navs.patientNavs.encounterTab}>
      
      {tabList.filter(item => item).map(item => <TabPane key={item} tab={item}>
      
      </TabPane>)}
    
    </Tabs>
    <Modal
      title="Lab Review"
     open={labReview}
      onCancel={() => setLabReview(false)}
      destroyOnClose={true}
      width={1200}
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        
        {horStack([outlinedButton(() => {
          setLabReview(false)
        }, 'Cancel', isExecuting),
          formSubmitButton('lab_review', 'Save', isExecuting, 'saving')])}
      </div>]}
    >
      <LabReview
        loadEncounters={loadEncounters}
        setisExecuting={setisExecuting}
        closeModal={dialogCloser(setLabReview)}
        report={labReviewUrl} assessment={woundAssessment} />
    </Modal>
    <div className="main-body">
      <div className="w-full">
        {IsItemEnabled(settings, 'integrations', 'drfirst_integration') && navs.patientNavs.encounterTab === "Prescriptions" ? <PrescriptionSection/> :
          <Table
            title={() => (<TableFilters datasource={navs.patientNavs.encounterTab === "Lab" ? [...labColumns, labProcedureFilter] : [...otherColumns, biologicsEligibilityFilter]} setIsVisible={setIsVisible} isVisible={isVisible}
                                        showClear={appConf.patientEncounterListFilters[0]}
                                        loading={appConf.patientEncounterListFilters[2]} filters={appConf.patientEncounterListFilters[1]}
                                        handleFilterAction={handleFilterAction}/>)}
            loading={appConf.patientEncounterListFilters[2]} size={'small'} {...responsiveTbl(windowState)}
            locale={{ emptyText: emptyTable('patient encounters.') }}
            onChange={(pagination, filters,
                       sorter) => onTableChange(pagination, filters, sorter, dispatch, filterPatientEncounters, appConf.patientEncounterListFilters, updatePatientEncounterListFilters,
              navs.patientNavs.encounterTab === "All" ? { patient: patientId } : { patient: patientId, type: navs.patientNavs.encounterTab })}
            onRow={(record) => {
              return {
                onClick: () => {
                  (async () => {
                    await dispatch(selectAssessment(record))
                    if (can_view_assessment) {
                      await setWoundAssessment(record)
                      setVisit(true)
                    }
                  })().catch(e => console.log(e))
                } // click row
              };
            }}
            {...getSkels((appConf.patientEncounterListFilters[2]), navs.patientNavs.encounterTab === "Lab" ? labColumns : navs.patientNavs.encounterTab !== "All" ? otherColumns.filter(item => item.title !== 'Procedure') : otherColumns, encounters[tab])}
            pagination={patientEncountersPagination}/>}
      </div>
    </div>
  </div>)
}
const mapStateToProps = (state) => ({
  encounters: state.patients.patientEncounters,
  patient: state.patients.selectedPatient, appConf: state.appConf, navs: state.navs,
  patientEncountersPagination: state.patients.patientEncountersPagination,
  activeUser: state.auth?.activeUser,
  settings: state.settings?.settings
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Encounters)
