import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { useSubscription } from "../../../hooks/MqttHooks/useSubscription";
import {emptyFun, emptyTable, getAppointmentStatus, getSkels, getTripStatus, makeSelectOptions, onTableChange, responsiveTbl} from "../../../common/helpers";
import { updateConsultantMileagesFilters } from "../../../app/Reducers";
import { fetchMileages, resetMileagesPagination } from "../../../app/Reducers";
import {Grid, Table, Tag, Typography} from "antd";
import TableFilters from "../../../components/InputComponents/TableFilters";
import dayjs from "dayjs";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {EditOutlined} from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';

const { Title, Paragraph, Text } = Typography;
const { useBreakpoint } = Grid;
const ConsultantMileages = ({ mileages, mileagesPagination, appConf,  navs, consultant,activeUser }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate();
  const windowState = useBreakpoint();
  const { message } = useSubscription(`view_consultant_mileages`, { qos: 2, nl: true });
  const columns = [
    { title: 'Patient', dataIndex: ['patient'], key: 'patient', fltr: { filterable: true, type: 'text' },
      render: (record) => <Text style={{ fontWeight: '600' }}> {record?.name||`${record.first_name||''} ${record.last_name||''}`}</Text> },
    { title: 'Consultant', dataIndex: ['consultant'], key: 'consultant', fltr: { filterable: true, type: 'text' },
      render: (record) => <Text style={{ fontWeight: '600' }}> {record?.name||`${record.first_name||''} ${record.last_name||''}`}</Text>  },
    {
      title: 'Trip Ref No', dataIndex: 'reference_number', key: 'reference_number', fltr: { filterable: true, type: 'text' }, render: (refNo) =>
        <Text style={{ fontWeight: '600' }}> {refNo.substring(0, 8).toUpperCase()}</Text>
    },
    {
      title: 'Date', dataIndex: 'created_at', key: 'date_range',
      fltr: { filterable: true, type: 'date_range', keys: ['date_range[start_at]', 'date_range[end_at]'], baseKey: 'date_range_filter' },
      render: date => dayjs(date).format("YYYY-MM-DD")
    },
    { title: 'Visit Type', dataIndex: 'type', key: 'visit_type', fltr: { filterable: true, type: 'text' }, render: (type) => type?.replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()) },
    { title: 'Distance', dataIndex: 'mileage', key: 'mileage', fltr: { filterable: false, type: 'phone' } },
    { title: 'Fuel expense', dataIndex: 'fuel_expense', key: 'fuel_expense', fltr: { filterable: false, type: 'text' } },
    {
      title: 'Appointment Status', dataIndex: 'appointment_status', key: 'appointment_status', render: (status) => getAppointmentStatus(status?.toLowerCase()),
      fltr: { filterable: true, type: 'select', options: makeSelectOptions(['scheduled', 'rescheduled', 'cancelled_by_patient', 'cancelled_by_wcc', 'missed', 'completed', 'in_progress', 'missed_by_wcc', 'missed_by_patient', 'visited']) }
    },
    {
      title: 'Trip Status', dataIndex: 'trip_status', key: 'status',
      fltr: { filterable: false, type: 'select', options: makeSelectOptions(['on', 'finished', 'cancelled']) },
      render: (status => getTripStatus(status.toLowerCase()))
    },
    ...(activeUser?.is_qa_user?[{ width:15,
      visible: true,
      title: <FontAwesomeIcon color="grey" icon={['fas', 'table']}/>,
      key: 'operation',
      render: (record) => <Tag className={`${(record.is_qa && activeUser?.is_qa_user)?'visible':'invisible'}`} icon={<EditOutlined/>} color="#55acee">QA</Tag>
    }]:[])]
  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message)
      loadMileages(true).catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  const loadMileages = async (isMQ) => {
    /*if (!!mileages[0] && !isMQ) {
      return
    }*/
    //Prevent read only property issues restriction by creating new object literals
    let filters = !!appConf.consultantMileagesFilters[1] ? { ...appConf.consultantMileagesFilters[1] } : undefined
    let filterData = filters ? { ...filters.filterData } : undefined
    //Delete consultant_id from filters if this is not a view from the consultant summary
    if (!!filterData) {
      delete filterData?.consultant_id
      filters.filterData = filterData
    }
    filters = !!filters ? { ...filters, filterData: { ...filters.filterData, ...(!!consultant && { consultant_id: consultant.id }) } } : !!consultant ? { filterData: { consultant_id: consultant.id } } : undefined
    await dispatch(updateConsultantMileagesFilters([appConf.consultantMileagesFilters[0], filters, !isMQ]))
    await dispatch(fetchMileages(filters))
    await dispatch(updateConsultantMileagesFilters([appConf.consultantMileagesFilters[0], filters, false]))
  }
  useEffect(() => {
    loadMileages(false).catch(e => console.log(e))
    return emptyFun
  }, [])
  const [isVisible, setIsVisible] = useState(false);

  async function handleFilterAction(action, values) {
    await dispatch(resetMileagesPagination())
    if (action === 'filter') {
      let pl = { filterData: { ...values, ...(!!consultant && { consultant_id: consultant.id }) }, pagination: mileagesPagination }
      //console.log({ pl })
      await dispatch(updateConsultantMileagesFilters([false, pl, true]))
      await dispatch(fetchMileages(pl))
      await dispatch(updateConsultantMileagesFilters([true, pl, false]))
    }
    if (action === 'reset') {
      let pl = !!consultant ? { filterData: { consultant_id: consultant.id } } : undefined
      await dispatch(updateConsultantMileagesFilters([appConf.consultantMileagesFilters[0], pl, true]))
      await dispatch(fetchMileages(pl))
      await dispatch(updateConsultantMileagesFilters([false, pl, false]))
    }
    setIsVisible(false)
  }

  return (<div className="card-box" style={{ width: '100%', padding: 0 }}>
    <div className="w-full">
      <Table
        title={() => (<TableFilters datasource={[...columns]}
          setIsVisible={setIsVisible}
          actionButton={undefined}
          windowState={windowState}
          isVisible={isVisible} filters={appConf.consultantMileagesFilters[1]}
          handleFilterAction={handleFilterAction}
          showClear={appConf.consultantMileagesFilters[0]} loading={appConf.consultantMileagesFilters[2]} />)}
        loading={appConf.consultantMileagesFilters[2]} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('mileages') }}
        onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchMileages, appConf.consultantMileagesFilters, updateConsultantMileagesFilters)}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
            } // click row
          };
        }} {...getSkels((appConf.consultantMileagesFilters[2]), columns, mileages)} pagination={mileagesPagination} />
    </div>
  </div>)
};

const mapStateToProps = (state) => ({ mileages: state.consultants.mileages,
  pagination: state.consultants.pagination, appConf: state.appConf,
  navs: state.navs,
  mileagesPagination: state.consultants.mileagesPagination,
  activeUser: state.auth?.activeUser
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ConsultantMileages);
