import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'

import {Divider, Form, Radio} from 'antd';
import dayjs from 'dayjs'
import {
  deleteFilterProfiles,
  fetchAppointments,
  loadFilterProfiles,
  resetSchedulePagination,
  saveFilterProfile,
  setAppointmentsLoaded,
  updateAppointmentFilters
} from '../../../app/Reducers';
import {dialogCloser, emptyFun, emptyTable, executeChunkFn, formSubmitButton, horStack, kebabMenus, makeAutoCompleteOptions, makeSelectOptions, textButton} from "../../../common/helpers";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";

import DynamicForm from "../../../components/DynamicForm";
import {Calendar, DateRange} from 'react-date-range';
import {apiRequest} from "../../../app/Apis";
import {US_STATES} from "../../../common/States";
import _ from "lodash"
import DynamicModalForm from '../../../components/DynamicModalForm';
import * as Sentry from '@sentry/react';
import {errMsg, toastUp} from '../../../app/Reducers/reducerUtils';
import {Subtitle1} from '../../../components';
import KebabMenu from '../../../components/KebabMenu';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {CloseOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {useFacilitySearch} from '../../../hooks/useFacilitySearch';

let filterNameInputFields = {
  fields: [
    { name: 'profile_name', label: 'Filter profile name:', required: true, type: 'text', fullRow: true }
  ],
  actionName: 'Save filter profile'
}
const SchedulesideBar = (
  {
    mode, appConf, activeUser, selectedPeriod, setSelectedPeriod,
    handleCalendarFilters, listRange, setScheduleRange, showTimeBlocker, facility, filterProfiles
  }) => {


  const [patientOptions, setpatientOptions] = useState([])
  const [providerOptions, setProviderOptions] = useState([])

  const [calenderFilterForm] = Form.useForm();
  const dispatch = useDispatch()


  const [savingFilters, setIsSavingFilters] = useState(false)
  const [clearingFilters, setIsClearingFilters] = useState(false)


  const { message } = useSubscription(`view_appointment`, { qos: 2, nl: true });
  const [, setShowAuditDialog] = useState(false);
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  const [defaultDynamicFormValues, setDefaultDynamicFormValues] = useState(!!appConf.scheduleFilters[1]?.filterData ? { ...appConf.scheduleFilters[1]?.filterData } : undefined);
  const [selected, setSelected] = React.useState(null);
  const [confirmLoading, setConfirmLoading] = React.useState(false);



  const [filterRadioOption, setFilterRadioOption] = useState('Filters')
  const [facilityOptions, debouncedFacilitiesSearch,facilitySearchLoading] = useFacilitySearch([], 'id')
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  usePermissionCheck('view_appointment', false, []);
  const can_delete_appointment = usePermissionCheck('delete_appointment', false, [])
  const can_search_scheduled_providers = usePermissionCheck('search_scheduled_providers', false, [])
  const handlePatientsearch = async (value) => {
    const { data } = await apiRequest.get(`/patients?search=${value}`)
    if (data) {
      setpatientOptions(data.data.filter(item => item.status === "active" || item.status === "hospitalized").map(item => ({ label: item.name, value: item.name })));
    }
  };
  const handleProvidersearch = async (value) => {
    console.log(value)
    if (value) {
      setProviderOptions([])
      const { data } = await apiRequest.get(`/consultants?search=${value}`)
      if (data) {
        setProviderOptions(_.uniqBy(data.data.map(item => ({ label: item.name, value: item.name, id: item.id })), 'id'));
      }
    } else {
      setProviderOptions([])
    }
  };
  let calendarFilterFormInputs = {
    fields: [
      showTimeBlocker !== true && {
        name: 'patientName', label: 'Patient', required: false, type: 'select', options: patientOptions, fullRow: true,
        props: { mode: 'multiple', showSearch: true, filterOption: false, onSearch: handlePatientsearch, defaultActiveFirstOption: false, showArrow: true, placeholder: 'Search patients' }
      },
      mode !== "scheduler" && !activeUser?.facilityProfile && can_search_scheduled_providers/*&& !activeUser?.consultantProfile*/ && {
        name: 'wcc', label: 'Provider', required: false, type: 'select', options: providerOptions, fullRow: true,
        props: {
          mode: 'multiple',
          showSearch: true,
          filterOption: false,
          autoClearSearchValue: true,
          onSearch: handleProvidersearch,
          defaultActiveFirstOption: false,
          showArrow: true,
          placeholder: 'Search WCCs'
        }
      },
      !activeUser?.facilityProfile && !showTimeBlocker && {
        name: 'facility', label: 'Office', required: false, type: 'select', options: facilityOptions, fullRow: true,
        props: {
          mode: 'multiple',
          showSearch: true,
          filterOption: false,loading:facilitySearchLoading,
          onSearch: (value) => debouncedFacilitiesSearch(value),
          defaultActiveFirstOption: false,
          showArrow: true,
          placeholder: 'Search medical facilities'
        }
      },
      !showTimeBlocker && {
        name: 'state', label: 'State', required: false, type: 'autocomplete', options: makeAutoCompleteOptions(US_STATES.map(item => item.State)),
        fullRow: true
      },
      !showTimeBlocker && {
        name: 'visitType', label: 'Visit Type', required: false, type: 'autocomplete', options: makeAutoCompleteOptions(['established visit', 'new visit']),
        fullRow: true
      },
      !showTimeBlocker && {
        name: 'status',
        label: 'Appointment Status',
        required: false,
        type: 'select',
        options: makeSelectOptions(['scheduled', 'rescheduled', 'cancelled_by_patient', 'cancelled_by_wcc', 'completed', 'in_progress', 'missed_by_wcc', 'missed_by_patient', 'visited','confirmed']),
        fullRow: true
      }
    ]
    // defaultValues: {...appConf.scheduleFilters[1]?.filterData}
  }
  //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
  let dynamicFormInputFields = {
    'Save filter profile': filterNameInputFields
  }
  useEffect(() => {
    if (!!message) {
      console.log("FilterData:::", appConf.scheduleFilters[1]?.filterData)
      console.log('MQTT MESSAGE:  ', message)
      loadAppointments(true).catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    dispatch(loadFilterProfiles())
    return emptyFun
  }, [])


  const handleCancel = () => setSelected(0);
  const showPopconfirm = (id) => setSelected(id);
  kebabMenus(( can_update_patient_resource), can_view_audit_logs, can_delete_appointment);
  const filterProfileMenus = [{ title: 'Apply', icon: <EditOutlined /> },
    { title: 'Delete', icon: <DeleteOutlined/> },
    { title: 'Dismiss', icon: <CloseOutlined /> }]
  const [range, setRange] = useState(null)

  useEffect(() => {
    let date = selectedPeriod
    let weekStart = dayjs(date).clone().startOf('isoWeek');
    let weekEnd = dayjs(date).clone().endOf('isoWeek');
    let monthStart = dayjs(date).startOf('month')
    let monthEnd = dayjs(date).endOf('month')

    if (mode === 'week' || mode === "month" || (mode === 'list' && listRange !== 'day')) {
      let range_ = mode === 'week' || listRange === "week" ? [{ startDate: dayjs(weekStart).toDate(), endDate: dayjs(weekEnd).toDate() }] : [{
        startDate: dayjs(monthStart).toDate(),
        endDate: dayjs(monthEnd).toDate()
      }]
      setRange(range_)
      setScheduleRange([dayjs(range_[0].startDate).format("YYYY-MM-DD"), dayjs(range_[0].endDate).format("YYYY-MM-DD")])

    }
    return emptyFun
  }, [mode, selectedPeriod, listRange, showTimeBlocker])

  async function filterProfileKebabMenuClick(e, record) {
    switch (filterProfileMenus[e.key].title) {
      case 'Apply':
        await setFilterRadioOption('Filters')
        await setDefaultDynamicFormValues(record.filter_profile)
        await handleCalendarFilters('filter', { ...record.filter_profile })
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
    }
  }

  useEffect(() => {

    if (!appConf.scheduleFilters[1]) {
      calenderFilterForm.resetFields()
    }

    return () => {

    }
  }, [appConf])


  const loadAppointments = async (isMQ) => {
    /*if (!!appointments[0] && !isMQ) {
      return
    }*/
    await dispatch(updateAppointmentFilters([appConf.appointmentFilters[0], appConf.appointmentFilters[1], !isMQ]))
    await dispatch(fetchAppointments(appConf.appointmentFilters[0] ? appConf.appointmentFilters[1] : undefined))
    await dispatch(setAppointmentsLoaded(true))
    await dispatch(updateAppointmentFilters([appConf.appointmentFilters[0], appConf.appointmentFilters[1], false]))
  }


  // useEffect(() => {
  //   loadAppointments(false).catch(e=>console.log(e))
  //   return emptyFun
  // }, [])
  useEffect(() => {
    calenderFilterForm.resetFields()
    return emptyFun
  }, [listRange])


  const handleProfileFiltersOk = async (id) => {
    await setConfirmLoading(true);
    await dispatch(deleteFilterProfiles(id))
    await setSelected(null)
    await setConfirmLoading(false);
  };







  const onChange = (date) => {

    setSelectedPeriod(dayjs(date).format("YYYY-MM-DD"))
    //console.log({ date });
    // handleFilterAction('filter',{ scheduledFor: dayjs(date).format("YYYY-MM-DD")  })

  }


  const onCalenderFilterResult = async (entries) => {
    console.log("🚁", { onCalenderFilterResult: entries.values })
    await dispatch(resetSchedulePagination())
    handleCalendarFilters(!!Object.keys(entries.values)[0] ? 'filter' : 'reset', entries.values)
  };

  useEffect(() => {
    let weekStart = dayjs(selectedPeriod).clone().startOf('isoWeek');
    let weekEnd = dayjs(selectedPeriod).clone().endOf('isoWeek');

    if (!range) {
      let range_ = [{ startDate: dayjs(weekStart).toDate(), endDate: dayjs(weekEnd).toDate() }]
      setRange(range_)
      setScheduleRange([dayjs(weekStart).toDate(), dayjs(weekEnd).toDate()])
    }
    return emptyFun
  }, [])

  useEffect(() => {
    if (facility) {
      calenderFilterForm.setFieldValue("facility", facility?.name)
    }
  }, [facility])

  const handleSchedulerangechange = (range) => {
    let range_ = [dayjs(range.startDate).format("YYYY-MM-DD"), dayjs(range.endDate).format("YYYY-MM-DD")]
    setScheduleRange(range_)
  }

  const openFilterProfileForm = async () => {
    await setFormName('Save filter profile');
    let values = { ...calenderFilterForm.getFieldsValue() }
    //Remove empty fields
    Object.keys(values).forEach(key => (!values[key] || (typeof values[key] === 'string' && values[key]?.trim() === '') || (Array.isArray(values[key]) && !values[key][0])) && delete values[key])
    await setDefaultDynamicModalFormValues(!!Object.keys(values)[0] ? { filter_profile: values } : undefined);
    await setDynamicFormOpen(true);
  };
  /**
   * This function is handling returned form entries from the dynamic form
   * */
  const onDynamicFormEntriesResult = async (entries) => {
    try {
      switch (formName) {
        case 'Save filter profile':
          await executeChunkFn(dispatch, saveFilterProfile, entries.values, setIsSavingFilters, dialogCloser(setDynamicFormOpen), null)
          break;
        default:
          return
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      toastUp(errMsg(e, false, formName), false);
    }
  };

  return (
    <div id="calender-pick" className="calender-side border border-slate-200 overflow-y-scroll">
      {(isDynamicFormOpen && !!formName) && (
        <DynamicModalForm
          setDynamicFormOpen={setDynamicFormOpen}
          isDynamicFormOpen={isDynamicFormOpen}
          inputFields={dynamicFormInputFields[formName]}
          onDynamicFormEntriesResult={onDynamicFormEntriesResult}
          closeModal={dialogCloser(setDynamicFormOpen)}
          isExecuting={savingFilters}
          defaultValues={defaultDynamicModalFormValues}
          formName={formName}
        />
      )}
      {/* <Calendar value={dayjs(selectedPeriod)} onSelect={onChange} fullscreen={false} onPanelChange={onPanelChange}/> */}

      {(mode === 'day' || (mode === 'list' && listRange === 'day') || mode === "scheduler") && mode !== "agenda" && <Calendar
        // color={'#3d91ff'}
        date={dayjs(selectedPeriod).toDate()}
        onChange={onChange}
      />}


      {mode !== 'day' && mode !== "scheduler" && !(mode === 'list' && listRange === 'day') && (range) &&
        <DateRange
          // rangeColors={['#3d91ff', '#3ecf8e', '#fed14c']}
          editableDateInputs={false}
          onChange={item => {
            setRange([item.range1])
            handleSchedulerangechange(item.range1)
          }}
          moveRangeOnFirstSelection={true}
          retainEndDateOnFirstSelection={true}
          ranges={range}
        />}
      {/* <Calendar value={appConf.scheduleFilters[1]?.filterData?.['scheduledForRange[start_at]'] ? [dayjs(appConf.scheduleFilters[1]?.filterData?.['scheduledForRange[start_at]']), dayjs(appConf.scheduleFilters[1]?.filterData['scheduledForRange[end_at]'])] :dayjs(selectedPeriod)} onSelect={onChange} fullscreen={false} onPanelChange={onPanelChange} /> */}
      {/* <Calendar value={[dayjs("2023-10-06"), dayjs("2023-10-10") ]} onSelect={onChange} fullscreen={false} onPanelChange={onPanelChange} /> */}


      {/* <div id="calender-pick">
                <RangePicker popupClassName="calender-pick" open={true} getCalendarContainer={() => document.getElementById('calender-pick')} />
           </div> */}
      {/*<div className="flex flex-row p-2 justify-center w-full">
        <Button style={{width: "25%",borderTopLeftRadius:6,borderBottomLeftRadius:6}}>Filters</Button>
        <Button style={{width: "25%",borderTopRightRadius:6,borderBottomRightRadius:6}}>Saved</Button>
      </div>*/}
      <Radio.Group size={'small'} style={{ minWidth: "60%" }} onChange={(e) => setFilterRadioOption(e.target.value)} value={filterRadioOption} buttonStyle="solid">
        <Radio.Button style={{ minWidth: "50%", borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }} value="Filters">Filters</Radio.Button>
        <Radio.Button style={{ minWidth: "50%", borderTopRightRadius: 6, borderBottomRightRadius: 6 }} value="Saved">Saved</Radio.Button>
      </Radio.Group>
      {filterRadioOption === 'Saved' && <div className="w-full">
        {filterProfiles[0] ? filterProfiles.map(item => <div className="w-full">
          <div className="flex-boy-row-space" style={{ width: "100%" }}>
            <Subtitle1>{item.profile_name}</Subtitle1>
            <KebabMenu menus={filterProfileMenus}
                       recordItem={item}
                       handleMenuClick={filterProfileKebabMenuClick}
                       resource={'filter profile'}
                       handleOk={() => handleProfileFiltersOk(item.id)}
                       handleCancel={handleCancel}
                       confirmLoading={confirmLoading}
                       showConfirmDialog={selected === item.id}/>
          </div>
          <Divider/>
        </div>) : <div style={{ paddingTop: 20 }}> {emptyTable()}</div>}
      </div>}
      {filterRadioOption === 'Filters' && <DynamicForm formId={"calenderFilterForm"} formName={"calenderFilterForm"} form={calenderFilterForm} onDynamicFormEntriesResult={onCalenderFilterResult}
                                                       inputFields={calendarFilterFormInputs} formProps={{ style: { minWidth: '300px', margin: 0 } }} defaultValues={defaultDynamicFormValues}
                                                       formFooter={<div className="flex flex-row p-4 mt-4 justify-evenly w-full">
                                                         {horStack([
                                                           ...(!!appConf.scheduleFilters[1]?.filterData ? [textButton(async () => {
                                                             calenderFilterForm.resetFields()
                                                             dispatch(resetSchedulePagination())
                                                             await setIsClearingFilters(true)
                                                             await setDefaultDynamicFormValues(undefined)
                                                             await handleCalendarFilters('reset')
                                                             await setIsClearingFilters(false)
                                                           }, clearingFilters ? 'Resetting' : 'RESET', clearingFilters, '#6B6C7E')] : []),
                                                           formSubmitButton('calenderFilterForm', 'APPLY', appConf.scheduleFilters[2], 'Applying'),
                                                           textButton(openFilterProfileForm, savingFilters ? 'Saving' : 'SAVE', savingFilters, '#EF8100', null, null)
                                                         ])}
                                                       </div>}/>}
      {/* {mode !== 'list' && mode !== "agenda" && mode !== "scheduler" && !activeUser?.facilityProfile &&
        <div className="flex flex-row p-2 justify-between items-center">
          <p className="text-sm font-semibold">Show Time Block</p>
          <Switch checked={showTimeBlocker} onChange={(val) => setShowTimeBlocker(val)} />
        </div>} */}
    </div>
  )
}


const mapStateToProps = (state) => ({ activeUser: state.auth?.activeUser, appointments: state.appointments.appointments,
  loading: state.appointments.loading, appConf: state.appConf, navs: state.navs,
  facility: state.facilities.selectedFacility,
  filterProfiles: state.appointments.filterProfiles
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulesideBar)