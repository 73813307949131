/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Grid, Modal} from 'antd'

import {fetchPatientById} from '../../../app/Reducers'
import PatientAdditionalContacts from "./PatientAdditionalInformation";
import PatientShippingAddress from "./PatientShippingAddress";
import PatientInitiation from "./PatientInitiation";
import {dialogCloser, emptyFun, formSubmitButton, horStack, modalClose, outlinedButton} from "../../../common/helpers";
import NewPatientInfo from './NewPatientInfo'
import PatientAddress from './PatientAddress'
import PatientaddressForm from './patientSummaryForms/PatientaddressForm'
import PatientForm from './patientSummaryForms/PatientForm'
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
const { useBreakpoint } = Grid;
export const PatientDetails = ({ patient, navs, changTab }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState=useBreakpoint()
  const [detailsForm, setDetailsForm] = useState(false);
  const [detailsType, setDetailsType] = useState('address');
  const [shippingForm, setShippingForm] = useState(false);
  const [isExecuting, setisExecuting] = useState(false)
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const can_view_patient_shipping_address = usePermissionCheck('view_patient_shipping_address', false, [])
  const dispatch = useDispatch()
  useEffect(() => {
    console.log('PATIENT ID:', patient)
    // if ((patient && patient.id !== navs.patientDetailsOwner && !navs.patientDetailsLoading) || (patientId !== patient.id)) {
    dispatch(fetchPatientById(Number(patientId)))
    // }
    return emptyFun
  }, [])
  
  const setdetsForm = (val) => {
    setDetailsType("details")
    setDetailsForm(val)
    
  }
  const setaddressForm = (val) => {
    setDetailsType("address")
    setDetailsForm(val)
    
  }
  return (
    (<div className="px-20 pt-2">
      <Modal
        destroyOnClose={true}
        title="Patient Information"
        open={detailsForm}
        onOk={dialogCloser(setDetailsForm)}
        onCancel={dialogCloser(setDetailsForm)}
        closeIcon={modalClose(dialogCloser(setDetailsForm))}
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(dialogCloser(setDetailsForm), 'Cancel', isExecuting),
            formSubmitButton('patientForm', 'Update', isExecuting, 'Updating')])}
        </div>]}>
        {detailsType === "address" ? <PatientaddressForm detailsType={detailsType} setisExecuting={setisExecuting} patient={patient} closeModal={dialogCloser(setDetailsForm)}/> :
          <PatientForm detailsType={detailsType} setisExecuting={setisExecuting} patient={patient} closeModal={dialogCloser(setDetailsForm)}/>}
      
      </Modal>
      <NewPatientInfo setDetailsForm={setdetsForm}/>
      <PatientAdditionalContacts/>
      <PatientAddress setDetailsForm={setaddressForm}/>
      {can_view_patient_shipping_address && <PatientShippingAddress/>}
      {patient.initiation && <PatientInitiation/>}
    </div>)
  );
}

const mapStateToProps = (state) => ({
  woundsLoading: state.patients.woundsLoading,
  wounds: state.patients.patientWounds, appointments: state.patients.patientAppointments,
  patient: state.patients.selectedPatient, vitals: state.patients.vitals,
  shippingAddresses: state.patients.shippingAddresses, appConf: state.appConf, navs: state.navs
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails)