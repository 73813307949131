/* eslint-disable no-unused-vars */
import React, {useState} from 'react'
import {connect} from 'react-redux'
import {MapCounterCircle, Subtitle2} from '../../general'
import {MapStatsCard} from './MapStatsCard';

export const CaliforniaMarker = (props) => {
  const [count, setCount] = useState(1220);
  const [statsVisible, viewStats] = useState(false)
  const setStatsVisibility = () => {
    viewStats(!statsVisible)
  }
  return (<div onClick={() => setStatsVisibility()} className="cluster-marker flex-boy-row">
      {statsVisible ? <MapStatsCard/> : null}
      <MapCounterCircle>
        <Subtitle2 color="white">{count}</Subtitle2>
      </MapCounterCircle>
    </div>)
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(CaliforniaMarker)
