import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Radio, Space } from 'antd';
import { dialogCloser, emptyFun, horStack, modalClose, outlinedButton } from "../common/helpers";

const SingleItemSelector = ({ title, isAppSelectorOpen, setAppSelectorOpen, selectItem, closeModal, options }) => {
  /*  useEffect(() => {
      console.log('options: ',options)
      return emptyFun
    })*/
  return (
    (<Modal
      title={title}
      open={(isAppSelectorOpen && !!options[0])}
      onOk={dialogCloser(setAppSelectorOpen)}
      width={600}
      onCancel={dialogCloser(setAppSelectorOpen)}
      closeIcon={modalClose(dialogCloser(setAppSelectorOpen))}
      maskClosable={false}
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        {horStack([outlinedButton(dialogCloser(setAppSelectorOpen), 'Cancel')])} </div>]}
      destroyOnClose={true}>
      <Radio.Group onChange={(e) => {
        selectItem(e.target.value)
        closeModal()
      }}>
        <Space direction="vertical">{(options && !!options[0]) && options.map(item => <Radio value={item}>{item}</Radio>)}</Space>
      </Radio.Group>
    </Modal>)
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SingleItemSelector);
