import React, {useEffect, useState} from 'react';

import {
  appLog,
  closeButton,
  containedButton,
  deleteButton,
  dialogCloser,
  editButton,
  emptyFun,
  formSubmitButton,
  getTaskStatus,
  horStack,
  makeSelectOptions,
  outlinedButton, sentenceCaseNoDash
} from '../../common/helpers';
import {Avatar, Button, Modal, Radio, Tag} from 'antd';
import {connect, useDispatch} from 'react-redux';
import PatientTasksKanbanView from './Components/Kanban';
import PatientTasksListView from './Components/ListView';
import NewTask from './Components/Taskforms/NewTask';
import ViewTask from './Components/Taskforms/ViewTask';
import {Headline6, Subtitle1} from '../../components';
import HeaderTop from '../../components/HeaderTop';
import {fetchTasks, resetTaskPagination, selectTask, setTaskNavs, setViewTask, setViewTaskNotification, updateTaskFilters} from '../../app/Reducers';
import dayjs from 'dayjs';
import TableFilters from '../../components/InputComponents/TableFilters';
import {FieldTimeOutlined, PlusOutlined} from '@ant-design/icons';
import AuditLogViewer from '../../components/AuditLogs/AuditLogViewer';
import {usePermissionCheck} from '../../hooks/usePermissionCheck';
import {useDepartmentSearch} from '../../hooks/useDepartmentSearch';


// list of dummy tasks for testing in this format

const tasks = [
  {
    "id": 1,
    "department_id": 91900000000079,
    "assignee_id": 398,
    "reporter_id": 399,
    "patient_id": 38,
    "due_date": "2019-12-15",
    "priority": "high",
    "description": "Dicta tempore ex et.",
    "notes": "Dolorem qui consequuntur sit debitis.",
    "status": "completed"
  },
  {
    "id": 2,
    "department_id": 91900000000079,
    "assignee_id": 398,
    "reporter_id": 399,
    "patient_id": 38,
    "due_date": "2019-12-15",
    "priority": "normal",
    "description": "Dicta tempore ex et.",
    "notes": "Dolorem qui consequuntur sit debitis.",
    "status": "done"
  },
  {
    "id": 3,
    "department_id": 91900000000079,
    "assignee_id": 398,
    "reporter_id": 399,
    "patient_id": 38,
    "due_date": "2019-12-15",
    "priority": "high",
    "description": "Dicta tempore ex et.",
    "notes": "Dolorem qui consequuntur sit debitis.",
    "status": "assigned"
  },
  {
    "id": 4,
    "department_id": 91900000000079,
    "assignee_id": 398,
    "reporter_id": 399,
    "patient_id": 38,
    "due_date": "2019-12-15",
    "priority": "low",
    "description": "Dicta tempore ex et.",
    "notes": "Dolorem qui consequuntur sit debitis.",
    "status": "in progress"
  }
]
// priority tag colors
const priorityColors = {
  low: '#AF78FF',
  normal: '#7785FF',
  high: 'crimson'
}


 const Tasks = ({ navs, activeUser, appConf, taskPagination, selectedTask, viewTaskNotification }) => {
  const dispatch = useDispatch();
  const [task, setTask] = useState(null);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState([])
  const debouncedDepartmentSearch = useDepartmentSearch(setDepartmentOptions,[])
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  
  let onCell = (record, rowIndex) => ({
    onClick: event => {
      (async () => {
        await dispatch(selectTask(record))
        await dispatch(setViewTask(true))
      })().catch(appLog)
    }
  })
  const columns = [
    {
      onCell, title: 'Task', dataIndex: 'description', key: 'description', fltr: { filterable: true, type: 'string' },
      // render description and capitalize first letter
      render: (description, record) => <Subtitle1 style={{ textAlign: 'left' }}>{description.charAt(0).toUpperCase() + description.slice(1)}</Subtitle1>
    },
    {
      onCell, title: 'Type', dataIndex: 'task_type', key: 'type', fltr: {
        filterable: true, type: 'select',
        options: makeSelectOptions(['facility', 'assessment', 'appointment', 'patient', 'wounds', 'login', 'consultant', 'map', 'order', 'Stats', 'Other'])
      },
      render: (task_type, record) => <Subtitle1 style={{ textAlign: 'left' }}>{sentenceCaseNoDash(task_type)}</Subtitle1>
    },
    { onCell, title: 'ID', dataIndex: 'id', key: 'id', fltr: { filterable: false, type: 'string' } },
    {
      onCell,
      title: 'Assigned to',
      dataIndex: 'assignee',
      key: 'assignee',
      fltr: { filterable: true, type: 'string' },
      render: (assignee, record) => (
        <div className="flex flex-row items-center">
          <Avatar className="mr-2" src={assignee?.profile_image_url} alt={assignee?.name}>{assignee?.name?.charAt(0)}</Avatar>
          <Subtitle1 className="ml-2">{assignee.name}</Subtitle1>
        </div>
      )
    }
    ,
    // patient
    { onCell, title: 'Patient', dataIndex: ['patient', 'name'], key: 'patient', fltr: { filterable: true, type: 'string' } },
    
    
    { onCell, title: 'Department', dataIndex: ['department', 'name'], key: 'department', fltr: { filterable: true, type: 'select',options:departmentOptions,
        props: {
          showSearch: true,
          filterOption: false,
          onSearch: (value) => debouncedDepartmentSearch(value),
          defaultActiveFirstOption: false,
          showArrow: true,
          placeholder: 'Search...'
        } } },
    
    {
      onCell,
      title: 'Due Date', dataIndex: ['due_date'], key: 'dueDate', fltr: { filterable: true, type: 'date' },
      render: (due_date, record) => <Subtitle1 style={{ textAlign: 'left' }}>{due_date ? dayjs(due_date).format("MM/DD/YYYY") : ""}</Subtitle1>
    },
    // creation date
    {
      onCell,
      title: 'Created', dataIndex: ['created_at'], key: 'created_at', fltr: {
        filterable: true, type: 'date_range',
        keys: ['createdAtRange[start_at]', 'createdAtRange[end_at]'], baseKey: 'createdAtRangeFilter'
      },
      render: (created_at, record) => <Subtitle1 style={{ textAlign: 'left' }}>{created_at ? dayjs(created_at).format("MM/DD/YYYY") : ""}</Subtitle1>
    },
    // reporter
    { onCell, title: 'Reporter', dataIndex: ['reporter', 'name'], key: 'reporter', fltr: { filterable: true, type: 'string' } },
    // priority
    {
      onCell,
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      fltr: { filterable: true, type: 'select', options: makeSelectOptions(['High', 'Low', 'Normal']) },
      render: (priority) => <Tag className="capitalize" color={priorityColors[priority]}>{priority}</Tag>
    },
    // status
    {
      onCell, title: 'Status', dataIndex: 'status', key: 'status', fltr: {
        filterable: true, type: 'select',
        options: makeSelectOptions(['in_progress', 'done', 'assigned', 'in_review'])
      },
      render: (status) => getTaskStatus(status)
    },
    ...(can_view_audit_logs ?
      [{
        title: '', dataIndex: '', key: 'x', render: (text, record, index) => <Button onClick={async () => {
          await setTask(record)
          await setShowAuditDialog(true)
          
        }} type="dashed" icon={<FieldTimeOutlined/>}>Audit</Button>
      }] : [])
  ]
  
  const [isVisible, setIsVisible] = useState(false);
  const onViewChange = (e) => {
    dispatch(setTaskNavs({ tab: e.target.value }))
  }
// new task modal
  const [newTaskModal, setNewTaskModal] = useState(false)
  
  const newTaskModalOpen = () => {
    setNewTaskModal(true)
  }
  const newTaskModalClose = () => {
    setNewTaskModal(false)
  }
// is executing
  const [isExecuting, setIsExecuting] = useState(false)

// viw task modal
  const [viewTaskModal, setViewTaskModal] = useState(false)
  
  
  const viewTaskModalClose = async () => {
    await dispatch(selectTask(null))
    await setViewTaskModal(false)
  }
  
  // fetch tasks
  
  useEffect(() => {
    dispatch(fetchTasks())
    return emptyFun
  }, [dispatch])
  
  useEffect(() => {
    if (viewTaskNotification) {
      dispatch(setViewTaskNotification(false))
      dispatch(setViewTask(true))
    }
    return emptyFun
  }, [viewTaskNotification])
  
  async function handleFilterAction(action, values) {
    await dispatch(resetTaskPagination())
    if (action === 'filter') {
      let pl = { filterData: { ...values }, pagination: taskPagination }
      await dispatch(updateTaskFilters([false, pl, true]))
      await dispatch(fetchTasks(pl))
      await dispatch(updateTaskFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updateTaskFilters([appConf.taskFilters[0], undefined, true]))
      await dispatch(fetchTasks())
      await dispatch(updateTaskFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  const headerButtons = (<div className={'flexy-row'} style={{ alignContent: 'flex-end', justifyContent: 'flex-end' }}>
    <div className="flex flex-1"></div>
    {/* ant design tabs with list view or kanban */}
    <Radio.Group className="mr-4" onChange={onViewChange} defaultValue={navs.taskNavs.tab}
                 buttonStyle="solid">
      <Radio.Button value="list"> List View </Radio.Button>
      <Radio.Button value="kanban">Kanban</Radio.Button>
    </Radio.Group>
    {
      containedButton(() => newTaskModalOpen(), 'Add New', false, null, null, { icon: <PlusOutlined /> })}
  </div>)
  return (<div className="main-page">
      {(showAuditDialog && !!task) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false} resourceDescription={'Task'}
                                                      resourceType={'Task'}
                                                      defaultValues={{ auditableId: task.id, auditable: 'task', format: 'json' }}/>}
      <HeaderTop child={<Headline6>Task Management</Headline6>}/>
      <div className="mt-2">
        {/*  new taksk modal */}
        <Modal
          title="New Task"
          open={newTaskModal}
          onCancel={newTaskModalClose}
          
          width={600}
          destroyOnClose={true}
          maskClosable={false}
          centered={true}
          className="new-task-modal"
          footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([outlinedButton(dialogCloser(setNewTaskModal), 'Cancel', isExecuting),
              formSubmitButton('taskForm', 'Create', isExecuting, 'Creating')])}
          </div>]}
        >
          <NewTask closeModal={newTaskModalClose} isExecuting={isExecuting} setIsExecuting={setIsExecuting}/>
        </Modal>
        {/* view task modal */}
        <Modal
          closable={false}
          title={<div className="flex flex-1" style={{ justifyContent: 'space-between' }}>
            <Headline6>View Task</Headline6>
            <div className="flex flex-1 flex-row items-center " style={{ justifyContent: 'flex-end' }}>
              {deleteButton('Delete', isExecuting, () => {
              }, 'Delete Task')}
              {editButton(() => {
                newTaskModalOpen()
              })}
              {closeButton(() => {
                viewTaskModalClose()
              })}
            </div>
          
          
          </div>}
          
          open={viewTaskModal}
          onCancel={viewTaskModalClose}
          
          width={1000}
          destroyOnClose={true}
          maskClosable={false}
          centered={true}
          className="view-task-modal"
        
        >
          <ViewTask/>
        </Modal>
        {<div className={'p-4 w-full'}>
          <TableFilters datasource={columns}
                        setIsVisible={setIsVisible} isVisible={isVisible}
                        extraLeftActions={[]}
                        handleFilterAction={handleFilterAction}
                        filters={appConf.taskFilters[1]}
                        showClear={appConf.taskFilters[0]}
                        actionButton={headerButtons}
                        loading={appConf.taskFilters[2]}/></div>
        }
        {
          navs?.taskNavs?.tab === 'list' ? <PatientTasksListView tasks={tasks} columns={columns}/> :
            <PatientTasksKanbanView tasks={tasks}/>
      }
      
      
      
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser,
  patient: state.patients.selectedPatient,
  appConf: state.appConf,
  navs: state.navs,
  taskPagination: state.tasks.taskPagination,
  ViewTaskOpen: state.tasks.ViewTaskOpen,
  selectedTask: state.tasks.selectedTask,
  viewTaskNotification: state.tasks.viewTaskNotification
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Tasks)