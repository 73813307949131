/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Avatar, Grid, Modal} from "antd"
import {dialogCloser, emptyFun, emptyOrders, formSubmitButton, getOrderStatus, horStack, makeAutoCompleteOptions, modalClose, outlinedButton} from "../../../common/helpers";
import {selectOrder} from '../../../app/Reducers'
import {Headline6, Subtitle1} from '../../../components'
import {RightOutlined} from '@ant-design/icons'
import {apiRequest} from '../../../app/Apis'
import PatientOrderPreview from './PatientOrderPreview'
import {useLocation, useNavigate} from 'react-router-dom';
import {useProcedureSearch} from '../../../hooks/useProcedureSearch';
const { useBreakpoint } = Grid;
 const PatientOrders = ({ patient, patientOrdersPagination, appConf, appWindow, setContent,activeUser, ...props }) => {
   const location = useLocation()
   const navigate = useNavigate();
     const windowState=useBreakpoint()
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
   const [procedureOptions, debouncedProcedureSearch, procedureSearchLoading] = useProcedureSearch([], 'name')
  const columns = [
    {
      title: 'WCC', dataIndex: ['consultant', 'name'], key: 'patient', fltr: { filterable: true, type: 'text' }
    },
    {
      title: 'DOS', dataIndex: 'date_of_service', key: 'date_of_service', fltr: { filterable: true, type: 'text' }
    },
    
    {
      title: 'Office', dataIndex: ['patient', 'medical_facility'], key: 'facility', fltr: { filterable: false, type: 'text' }
    }, {
      title: 'Procedure Type', dataIndex: 'procedure', key: 'procedure', fltr: {
        filterable: true, type: 'select', options: procedureOptions,
        props: {
          loading: procedureSearchLoading,
          onSearch: (value) => debouncedProcedureSearch(value), showSearch: true, filterOption: false
        }
      }, render: (recommended_procedure) => {
        if (recommended_procedure && Array.isArray(recommended_procedure) && recommended_procedure.length > 0) {
          return recommended_procedure.join(', ')
        } else {
          return recommended_procedure
        }
      }
    },
    {
      title: 'Status', dataIndex: 'status', key: 'status', fltr: {
        filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(['WareHouse alert', 'approved', 'pending'])
      }, render: (status) => getOrderStatus(status?.toLowerCase())
    }
  
  ]
  
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch()
  const [previewisOpen, setpreviewOpen] = useState(false)
  const [selectedOrder, setselectedOrder] = useState(null)
  
  const [orders, setorders] = useState([])
  useEffect(() => {
    (async () => {
      await fetchOrders()
    })().catch(e => console.log(e))
    return emptyFun
  }, [])
  
  const fetchOrders = async () => {
    let data = await apiRequest.get(`/treatment-orders?patientId=${patient.id}`).catch(e => console.log(e))
    if (data?.data?.data) {
      console.log("Patient Orders:::", data?.data?.data)
      setorders(data?.data?.data)
    }
  }
  const [orderForm, setOrderForm] = useState(false)
  const handleOrderOk = () => {
  
  }
  const handleOrderCancel = () => {
    setOrderForm(false)
  }
  const selectOrdere = async (order) => {
    await setselectedOrder(order)
    await dispatch(selectOrder(order))
    await fetchPatientById()
    await fetchConsultantById()
    await setpreviewOpen(true)
  }
  const handlePreviewOrderCancel = () => {
    setpreviewOpen(false)
  }
  
  
  const [consultant, setconsultant] = useState(null)
  const [orderPatient, setorderPatient] = useState(null)
  
  
  const fetchConsultantById = async () => {
    if (selectedOrder?.consultant) {
      const response = await apiRequest.get(`/consultants/${selectedOrder.consultant.id}`)
      const consultant = response?.data?.data
      if (consultant) {
        const shippingResponse = await apiRequest.get(`/shipping-addresses?user=${consultant.user_id}`)
        const consultant_addresses = shippingResponse?.data?.data
        consultant.addresses = [...consultant_addresses]
      }
      setconsultant(consultant)
      
    }
  }
  
  const fetchPatientById = async () => {
    
    
    const _patient = { ...patient }
    if (_patient) {
      const shippingResponse = await apiRequest.get(`/shipping-addresses?user=${patient.user_id}`)
      const patient_addresses = shippingResponse?.data?.data
      _patient.addresses = [...patient_addresses]
    }
    setorderPatient(_patient)
    
  }
  
  const [isExecuting, setisExecuting] = useState(false)
  return (
    <div className="card-box">
      <Modal
        title={selectedOrder?.id ? `Order ${selectedOrder?.id} Details` : "Order details"}
        open={previewisOpen}
        onOk={dialogCloser(setpreviewOpen)}
        onCancel={dialogCloser(setpreviewOpen)}
        closeIcon={modalClose(dialogCloser(setpreviewOpen))}
        maskClosable={false}
        destroyOnClose={true}
        width={600}
        footer={activeUser?.facilityProfile ? null : [<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setpreviewOpen), 'Cancel', isExecuting),
            formSubmitButton('patientOrderpreviewForm', 'Save', isExecuting, 'Updating')])}
        </div>]}>
        
        
        <PatientOrderPreview setpreviewOpen={setpreviewOpen} isExecuting={isExecuting} setisExecuting={setisExecuting} consultant={consultant} patient={orderPatient} order={selectedOrder} loadOrders={fetchOrders} />
      </Modal>
      
      
      <div className="flex flex-row justify-between items-center w-full">
        <Headline6>Orders</Headline6>
        
        <RightOutlined onClick={() => setContent("Orders")}/>
      </div>
      
      
      {orders.length ?
        <>
          {orders?.filter(item => item.visit_type === 'DME').length > 0 && <Subtitle1>DME</Subtitle1>}
          {orders?.filter(item => item.visit_type === 'DME').slice(0, 2).map(order => {
            return (
              <div onClick={() => selectOrdere(order)} className="rounded w-full p-2 my-2 cursor-pointer" key={order.id} style={{ background: '#ebeff4' }}>
                <div className="flex flex-row w-full justify-between items-center my-2">
                  <p className="text-md text-left font-bold">ID{order.id}</p>
                  <p>{getOrderStatus(order.status)}</p>
                </div>
                
                <div className="flex flex-row w-full justify-between items-center">
                  <p className="text-md text-left font-medium">{order.wound_location}</p>
                  <div className="flex flex-row items-center">
                    <p className="font-semibold text-left text-md mr-2 capitalize">{order.consultant?.name}</p>
                    <Avatar/>
                  
                  </div>
                
                </div>
              
              </div>
            )
          })}
          
          {orders?.filter(item => item.visit_type === 'Biologics').length > 0 && <Subtitle1> Biologics</Subtitle1>}
          {orders?.filter(item => item.visit_type === 'Biologics').slice(0, 2).map(order => {
            return (
              <div onClick={() => selectOrder(order)} className="rounded w-full p-2 my-2 cursor-pointer" key={order.id} style={{ background: '#ebeff4' }}>
                <div className="flex flex-row w-full justify-between items-center my-2">
                  <p className="text-md text-left font-bold">ID{order.id}</p>
                  <p>{getOrderStatus(order.status)}</p>
                </div>
                
                <div className="flex flex-row w-full justify-between items-center">
                  <p className="text-md text-left font-medium">{order.wound_location}</p>
                  <div className="flex flex-row items-center">
                    <p className="font-semibold text-left text-md mr-2 capitalize">{order.consultant?.name}</p>
                    <Avatar/>
                  
                  </div>
                
                </div>
              
              </div>
            )
          })}
        </> : emptyOrders()}
    </div>
  )
}

const mapStateToProps = (state) => ({
  
  patient: state.patients.selectedPatient,
  consultant: state.consultants.selectedConsultant,
  patientOrdersPagination: state.patients.patientOrdersPagination,
  appConf: state.appConf, navs: state.navs, appWindow: state.appWindow,activeUser: state.auth?.activeUser,
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PatientOrders)