import {connect} from 'react-redux';
import {Chat, enTranslations, Streami18n} from 'stream-chat-react';
import './styles/index.scss';
import HeaderTop from '../../components/HeaderTop';
import {Subtitle1} from '../../components';
import {Sidebar} from './components/Sidebar/Sidebar';
import {WorkspaceController} from './context/WorkspaceController';
import {ChannelContainer} from './components/ChannelContainer/ChannelContainer';

const urlParams = new URLSearchParams(window.location.search);

const theme = urlParams.get('theme') || 'light';

const i18nInstance = new Streami18n({
  language: 'en',
  translationsForLanguage: {
    ...enTranslations
  }
});

export const Index = ({ activeUser, client }) => {
  
  return (<>
    <div className="main-page relative" id="chat-container">
      <HeaderTop child={<Subtitle1>Chat</Subtitle1>}/>
      {client && <div className="app__wrapper str-chat">
        <Chat {...{ client, i18nInstance }} theme={`team ${theme}`}>
          <WorkspaceController>
            <Sidebar user={`${client?.user?.id}`}/>
            <ChannelContainer/>
          </WorkspaceController>
        </Chat>
      </div>}
    </div>
  </>);
}

const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Index)