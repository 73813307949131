import React, {useEffect, useRef, useState} from 'react';
import {Form} from "antd";
import {createFormInputsFromTableColumns, dialogCloser, emptyFun, formSubmitButton, horStack, outlinedButton, useOnClickOutside} from '../../../common/helpers';
import {apiRequest} from '../../../app/Apis';
import dayjs from 'dayjs';
import {errMsg, getToastId, updateToast} from '../../../app/Reducers/reducerUtils';
import DynamicForm from '../../../components/DynamicForm';

export const ExportTableToCsv = ({ showExportForm, exportedColumns, defaultFilters }) => {
  const [form] = Form.useForm();
  const ref = useRef();
  const [datePopupOpen, setDatePopupOpen] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const dateFormat = "YYYY-MM-DD";
  const [phoneValue, setPhoneValue] = useState(undefined);
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicFormValues, setDefaultDynamicFormValues] = useState(undefined);
  let exportedFormInputsFields = createFormInputsFromTableColumns(true, exportedColumns, phoneValue, setPhoneValue, setPopupOpen, setDatePopupOpen, dateFormat)
  //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
  let dynamicFormInputFields = { 'Export encounter list to CSV': exportedFormInputsFields }
  const [isExecuting, setisExecuting] = useState(false)
  const [CSVData, setCSVData] = useState(null)
  useEffect(() => {
    if (!!defaultFilters){
      setDefaultDynamicFormValues({ ...defaultFilters })
      form.setFieldsValue({ ...defaultFilters })
    }
    return emptyFun
  }, [defaultFilters]);
  useOnClickOutside(ref, () => {
    if (!datePopupOpen && !popupOpen) {
      form.resetFields()
      showExportForm(false)
    }
  });
  const [docHeight, setDocHeight] = useState(`${window.innerHeight * 0.8}px`);
  useEffect(() => {
    function handleResize() {
      setDocHeight(`${window.innerHeight * 0.8}px`)
    }
    
    window.addEventListener('resize', handleResize)
    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })
  
  
  async function onFinish(entries) {
    const toastId = getToastId('Generating CSV', 'info')
    try {
      setisExecuting(true)
      const url = '/encounter-export?' + new URLSearchParams(entries.values).toString();
      const { data } = await apiRequest.get(url)
      if (data) {
        updateToast(toastId, 'CSV Generated', true)
        const blob_data = new Blob([data], { type: 'text/csv' });
        const urlBlob = window.URL.createObjectURL(blob_data);
        const link = document.createElement('a');
        link.href = urlBlob;
        link.setAttribute('download', `Encounter list ${dayjs().format('YYYY-MM-DD hh:mm:ss a')}.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setisExecuting(false)
        form.resetFields()
        showExportForm(false)
      } else {
        updateToast(toastId, 'Failed to export to csv', false)
        setisExecuting(false)
      }
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      setisExecuting(false)
    }
  }
  
  return (
    <div ref={ref} className={`max-h-[${docHeight}] w-full overflow-y-auto bg-white rounded-lg border border-[gainsboro] border-solid shadow-xl drop-shadow-xl`}>
      <DynamicForm form={form}
                   formFooter={null}
                   formName={"insuranceForm"}
                   formId={"insuranceForm"}
                   onDynamicFormEntriesResult={onFinish}
                   inputFields={exportedFormInputsFields}
                   phoneValue={phoneValue}
                   setPhoneValue={setPhoneValue}
                   defaultValues={defaultDynamicFormValues}
                   formProps={{
                     style: { width: '100%' },
                     labelCol: { sm: 24, xs: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
                     background: 'blue'
                   }} isFormUsedAsFilter={true}/>
      <div className="flex flex-row p-4 mt-4 justify-center">
        {horStack([
          outlinedButton(()=>{
            form.resetFields()
            showExportForm(false)
          }, 'Cancel', isExecuting),
          formSubmitButton('insuranceForm', 'Export', isExecuting, 'Exporting')
        ])}
      </div>
    </div>
  )
}