/* eslint-disable no-unused-vars */
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {apiRequest} from "../Apis"
import {errMsg, toastUp} from "./reducerUtils";
import {notifications} from "./initialState";

const initialState = {...notifications}
export const fetchNotifications = createAsyncThunk('fetchNotifications', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/notifications`)
      const payload = {notifications: response.data.data}
      //console.log({fetchNotifications: payload})
      resolve(payload)
    } catch (e) {
      //console.log(e)
      // toastUp(errMsg(e, true, 'notifications'), false)
      reject(e)
    }
  })
})

export const fetchRcopiaNotifications = createAsyncThunk('/rcopianotifications', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    //console.log(requestParams)
    try {

      const response = await apiRequest.get(`/integrations/dr_first/notifications_count?interval=${requestParams.is_interval}`)
      const payload = [...response.data.data]
      resolve(payload)
    } catch (e) {
      //console.log(e)
      // toastUp(errMsg(e, true, 'Master List'), false)
      reject(e)
    }
  })
})

export const markAllRead = createAsyncThunk('/update_field', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/notifications/mark-all-as-read`)
      await thunkAPI.dispatch(fetchNotifications());
      toastUp("Marked as read successfully", true)
      resolve(response.data)
    } catch (e) {
      //console.log(e)
       toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const markSpecificRead = createAsyncThunk('/alert.data.message', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/notifications/${payload.id}/mark-as-read`, payload)
      await thunkAPI.dispatch(fetchNotifications());
      toastUp("Marked as read successfully", true)
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      // toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const NotificationSlice = createSlice({
  name: 'notifications', initialState, // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    selectField: (state, action) => {
      state.selectedField = action.payload.record;
    },
    selectLocation: (state, action) => {
      state.location = action.payload
    },
    doResetNotificationSlice: (state, action) => {
      Object.keys(initialState).map((key) => (state[key] = initialState[key]));
    },
  }, extraReducers: (builder)=>{
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      //console.log(action.payload)
      state.notifications = [...action.payload.notifications]

    }).addCase(fetchRcopiaNotifications.fulfilled, (state, action) => {
      //console.log(action.payload)
      state.rcopianotifications = [...action.payload]
    })
  }
});
export const {doResetNotificationSlice,
  selectField
} = NotificationSlice.actions
export default NotificationSlice.reducer
