import React, {useCallback, useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {useLocation, useNavigate} from "react-router-dom";
import {Form, Input, Select} from 'antd'
import dayjs from "dayjs"
import {updatePatientDetails} from '../../../../app/Reducers';
import MapGL from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import {Subtitle1} from '../../../../components';
import {mapboxToken} from '../../../../configure/constants';
import {executeChunkFn, getPhoneParams, inputRow, showCurrentAddress} from "../../../../common/helpers";

export const PatienDetailsForm = ({ patient, closeModal, setisExecuting, activeUser, detailsType }) => {
  const dateFormat = 'MM/DD/YYYY';
  const { Option } = Select;
  const location = useLocation()
  const navigate = useNavigate();
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [facilities, setFacilities] = useState([]);
  const [facility, setFacility] = useState()
  const [phoneValue, setPhoneValue] = useState(!!patient ? { ...getPhoneParams(patient?.phone_no, 'phone_no') } : undefined);
  const [initialValues, setInitialValues] = useState(!!patient ? {
    ...patient,
    birth_date: dayjs(patient.birth_date), ...(!!patient?.phone_no && { ...getPhoneParams(patient?.phone_no, 'phone_no') })
  } : {})
  const onFinish = async (values) => {
    let val = { ...values }
    delete val.facility_id
    let payload = {
      ...val, patient_id: patient.id, ...locationData,
      birth_date: dayjs(val.birth_date || patient.birth_date).format(dateFormat), ...(facility && { facility_id: facility.id }), ...phoneValue
    }
    await executeChunkFn(dispatch, updatePatientDetails, payload, setisExecuting, closeModal)
  };
  // location stuff
  const [viewport, setViewport] = useState({ latitude: 37.7577, longitude: -122.4376, zoom: 8 })
  const [locationData, setLocationData] = useState(null)
  const geocoderContainerRef = useRef();
  const mapRef = useRef();
  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);
  const onResult = useCallback((result) => {
    // console.log(result)
    let center = result.result.center
    let context = result.result.context
    let state = context.find(item => item.id.includes('region'))
    let city = context.find(item => item.id.includes('place'))
    let zip_code = context.find(item => item.id.includes('postcode'))
    let data = {
      // city: city ? city.text : result.result.place_name, state: state ? state.text : null,
      // zip_code: zip_code ? zip_code.text : result.result.place_name,
      co_ordinates: `${center[0]},${center[1]}`
      // address_1: result.result.place_name
    }
    
    setLocationData(data)
    form.setFieldsValue({
      address_1: result.result.place_name,
      city: city ? city.text : null,
      state: state ? state.text : null,
      zip_code: zip_code ? zip_code.text : null
    });
  }, [])
  const onFacilitySelect = (value) => {
    let facility = facilities.find(item => item.name === value)
    setFacility(facility)
  };
  return (<Form
    name="new-patient"
    form={form}
    id="patientForm"
    labelCol={{ span: 16 }}
    onValuesChange={(changedValues, allValues) => {
      // console.log('changedValues:', changedValues)
      // console.log('allValues:', allValues)
    }}
    initialValues={{ ...initialValues }}
    layout="vertical"
    onFinish={onFinish}>
    
    <Subtitle1 className="Validator" style={{ textAlign: 'left', marginBottom: '10px' }}>Address Search</Subtitle1>
    <Form.Item
      style={{ width: '100%', ...(initialValues.address_1 && { marginBottom: 0 }) }}
      name="default_region"
      label=""
      rules={[{ required: false, message: 'Missing Region' }]}
    >
      <div
        ref={geocoderContainerRef}
        style={{ position: "absolute", top: 0, left: 0, zIndex: 1, width: "100%" }}
      />
      <MapGL
        ref={mapRef}
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={handleViewportChange}
        mapboxApiAccessToken={mapboxToken}
      >
        <Geocoder
          mapRef={mapRef}
          containerRef={geocoderContainerRef}
          onViewportChange={handleViewportChange}
          mapboxApiAccessToken={mapboxToken}
          countries="us,ke"
          onResult={onResult}
          marker={false}
          onError={onResult}
        />
      </MapGL>
    </Form.Item>
    {showCurrentAddress(initialValues.address_1)}
    {(locationData || initialValues.address_1) && <>
      <Form.Item
        style={{ width: '100%', marginTop: '10px' }}
        name="address_1"
        label="Address"
        rules={[{ required: true, message: 'Missing location' }]}>
        <Input autoComplete={'off'} placeholder="Address"/>
      </Form.Item>
      {inputRow([
        <Form.Item
          style={{ width: '100%' }}
          name="state"
          label="State"
          rules={[{ required: true, message: 'Missing State' }]}>
          <Input autoComplete={'off'} placeholder="State"/>
        </Form.Item>,
        <Form.Item
          style={{ width: '100%' }}
          name="city"
          label="City"
          rules={[{ required: true, message: 'Missing City' }]}>
          <Input autoComplete={'off'} placeholder="City"/>
        </Form.Item>,
        <Form.Item
          style={{ width: '100%' }}
          name="zip_code"
          label="Zip Code"
          rules={[
            {
              required: true,
              message: 'Missing Zip code'
            }
            // {
            //   pattern: /^\d{5}(?:[-\s]\d{4})?$/,
            //   message: 'Please enter a valid zip Code',
            // }
          ]}>
          <Input autoComplete={'off'} placeholder="zip"/>
        </Form.Item>
      ])}
    </>}
    <Form.Item
      label="Address 2"
      name="address_2">
      <Input autoComplete={'off'} placeholder="App, suite, unit, building, floor, etc"/>
    
    </Form.Item>
  
  </Form>)
}
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PatienDetailsForm)
