import clsx from 'clsx';
import { Avatar, useChatContext } from 'stream-chat-react';
import { channelByUser, isChannel } from './utils';
const SearchResult = (props) => {
    const { focusedId, result, setChannel } = props;
    const { client, setActiveChannel } = useChatContext();
    if (isChannel(result)) {
        const channel = result;
        return (<div onClick={() => setChannel(channel)} className={clsx('channel-search__result-container', { highlighted: focusedId === channel.id })}>
        <div className='result-hashtag'>#</div>
        <p className='channel-search__result-text'>{channel?.data?.name}</p>
      </div>);
    }
    else {
        const user = result;
        return (<div onClick={() => {
                channelByUser({ client, setActiveChannel, user });
            }} className={clsx('channel-search__result-container', { highlighted: focusedId === user.id })}>
          <Avatar image={user.image} name={user.name || user.id} size={24}/>
          <p className='channel-search__result-text'>{user.name || user.id || 'Johnny Blaze'}</p>
      </div>);
    }
};
export const ResultsDropdown = (props) => {
    const { teamChannels, directChannels, focusedId, loading, setChannel, setQuery } = props;
    document.addEventListener('click', () => setQuery(''));
    return (<div className='channel-search__results'>
      <p className='channel-search__results-header'>Groups</p>
      {loading && !teamChannels?.length && (<p className='channel-search__results-header'>
          <i>Loading...</i>
        </p>)}
      {!loading && !teamChannels?.length ? (<p className='channel-search__results-header'>
          <i>No channels found</i>
        </p>) : (teamChannels?.map((channel, i) => (<SearchResult result={channel} focusedId={focusedId} key={i} setChannel={setChannel}/>)))}
      <p className='channel-search__results-header'>Users</p>
      {loading && !directChannels?.length && (<p className='channel-search__results-header'>
          <i>Loading...</i>
        </p>)}
      {!loading && !directChannels?.length ? (<p className='channel-search__results-header'>
          <i>No direct messages found</i>
        </p>) : (directChannels?.map((user, i) => (<SearchResult result={user} focusedId={focusedId} key={i} setChannel={setChannel}/>)))}
    </div>);
};
