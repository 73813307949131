/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Divider, Form, Input, Tabs } from 'antd';
import { Subtitle1 } from '../../components';
import {
  doResetAppointmentSlice,
  clearConsultantModuleState,
  clearFacilityModuleState,
  doResetOrganisationSlice,
  doResetPatientSlice,
  loginFacility,
  loginUser,
  LoginWithSSO,
  doResetAuthSlice,
  resetAuditLogs,
  doResetConfigSlice,
  doResetNavSlice,
  doResetAuditLogSlice,
  doResetBillingSlice,
  doResetChatSlice,
  doResetConsultantSlice,
  doResetDepartmentSlice,
  doResetFacilitySlice,
  doResetMasterListState,
  doResetNotificationSlice,
  doResetOrderSlice, doResetSettingsSlice, doResetStatsSlice, doResetTaskSlice, fetchSettings
} from '../../app/Reducers';
import { appLog, emptyFun, inputRow, IsItemEnabled } from '../../common/helpers';
import {doResetMapSlice} from '../../app/Reducers/mapSlice';
import {useNavigate} from 'react-router-dom';

const { TabPane } = Tabs;

export const Login = ({ loadingUser,activeUser, dynamicLink,settings }) => {
  const navigate=useNavigate()
  const dispatch = useDispatch();
  const [isFacility, setIsFacility] = useState(false);

  const [form] = Form.useForm();
  const email = Form.useWatch('username', form);
  //Hide chatbot when logged out in the background
  // useEffect(() => {
  //   let styleData = document.getElementsByClassName('kore-chat-window')
  //   if (!!styleData && styleData[0]) {
  //     document.getElementsByClassName('kore-chat-window')[0].style.display = 'none';
  //     document.getElementsByClassName('chat-container')[0].innerHTML = ''
  //   }
  //   return emptyFun
  // })
  

  useEffect(() => {
    console.log({activeUser})
    if (activeUser && !activeUser.password_was_generated){
      const permissions = activeUser?.roles?.map((item) => item.permissions).flat(1).map(item => item.name)
      let initialPath
      if (permissions.includes("view_map")) {
        initialPath=dynamicLink ? dynamicLink : '/map'
      } else {
        initialPath=activeUser?.facilityProfile ? `/facility/${activeUser?.facilityProfile?.id}/stats` : '/patients';
      }
      console.log({navigate,initialPath})
      navigate(initialPath)
    }else {
      // clear any websocket connection associated with previous session in case of idle logout
      // window.location.reload()
    }
    return emptyFun
  }, [activeUser]);
  const onFinish = (values) => {
    if (!loadingUser) {
      (async () => {
        await dispatch(doResetAuthSlice())
        await dispatch(doResetAuthSlice())
        await dispatch(doResetAppointmentSlice())
        await dispatch(doResetAuditLogSlice())
        await dispatch(doResetBillingSlice())
        await dispatch(doResetChatSlice())
        await dispatch(doResetConfigSlice())
        await dispatch(doResetConsultantSlice())
        await dispatch(doResetDepartmentSlice())
        await dispatch(doResetFacilitySlice())
        await dispatch(doResetMapSlice())
        await dispatch(doResetMasterListState())
        await dispatch(doResetNavSlice())
        await dispatch(doResetNotificationSlice())
        await dispatch(doResetOrderSlice())
        await dispatch(doResetOrganisationSlice())
        await dispatch(doResetPatientSlice())
        await dispatch(doResetSettingsSlice())
        await dispatch(doResetStatsSlice())
        await dispatch(doResetTaskSlice())
        let user
        if (isFacility) {
          user= await dispatch(loginFacility({ navigate, ...values })).unwrap();
        } else {
           user=  await dispatch(loginUser({ navigate, ...values })).unwrap();
          // document.getElementsByClassName('minimized')[0].style.visibility = 'visible';
        }
        console.log({user})
        const permissions = user?.roles?.map((item) => item.permissions).flat(1).map(item => item.name)
        if (!user.password_was_generated) {
          dispatch(fetchSettings())
        }
        console.log('dynamicLink: ', dynamicLink)
        let initialPath
        if (permissions.includes("view_map")) {
          console.log('Trying to navigate 1')
          initialPath=user.password_was_generated ? '/auth/new-password' : dynamicLink ? dynamicLink : '/map' ;
        } else {
          console.log('Trying to navigate2')
          initialPath=user.password_was_generated ? '/auth/new-password' : user?.facilityProfile ? `/facility/${user?.facilityProfile?.id}/stats` : '/patients';
        }
        console.log({navigate,initialPath})
        navigate(initialPath,{state:{old_password:values.password,email:values.username}})
      })().catch(appLog);
    }
  };

  const LoginSSO = async (service, driver) => {
    let payload = {
      servicename: service,
      servicedriver: driver,
      navigate: navigate
    };
    
    let user = await dispatch(LoginWithSSO(payload)).unwrap();
    console.log({user})
    const permissions = user?.roles?.map((item) => item.permissions).flat(1).map(item => item.name)
    console.log('dynamicLink: ', dynamicLink)
    let initialPath
    console.log('Trying to navigate3')
    initialPath = permissions.includes("view_map") ? dynamicLink ? dynamicLink : '/map' : user?.facilityProfile ? `/facility/${user?.facilityProfile?.id}/stats` : '/patients';
    console.log({navigate,initialPath})
    navigate(initialPath)
  };
  

  const onFinishFailed = (errorInfo) => console.log('Failed:', errorInfo);
  const tabSwitch = async (key) => {
    console.log('USER: ', key);
    setIsFacility(key !== 'Agency');
  };
  return (
    <div className="login-div">
      <p className="font-bold text-left text-base mb-2">Login</p>
      <Divider />
      <div>
        <Form
          form={form}
          name="Agency"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Email / Username"
            name="username"
            rules={[
              { required: true, message: 'Please type username!' }
            ]}
          >
            <Input autoComplete={'off'} placeholder="username" />
          </Form.Item>

          <Form.Item
            className="password"
            label="Password"
            name="password"
            rules={[
              { required: true, message: 'Please type password!' }
            ]}
          >
            <Input.Password placeholder="password" />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" className="login-form-button">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
      {/* {inputRow([
        <a href={`${process.env.REACT_APP_SERVER_ENDPOINT}/googlead/login`}>
          <Button style={{ width: '100%', padding: '6px' }}>
            <div className="flex-boy-row">
              <img src={require('../../assets/icons/Google.svg').default} alt="google-logo" style={{ width: '15px', height: '17px', marginRight: '5px' }} />
              <span style={{ fontWeight: 'bold' }}> Google</span>
            </div>
          </Button>
        </a>,
        <a href={`${process.env.REACT_APP_SERVER_ENDPOINT}/azuread/login`}>
          <Button style={{ width: '100%', padding: '5px' }} onClick={() => LoginSSO('azuread', 'azure')}>
            <div className="flex-boy-row">
              <img src={require('../../assets/icons/Microsoft.svg').default} alt="microsoft-logo" style={{ width: '15px', height: '17px', marginRight: '5px' }} />
              <span style={{ fontWeight: 'bold' }}> Microsoft</span>
            </div>
          </Button>
        </a>,
        // IsItemEnabled(settings, 'integrations', 'pcc_integrations') &&
        <a href={`${process.env.REACT_APP_SERVER_ENDPOINT}/pcclead/login`}>
          <Button style={{ width: '100%', padding: '5px' }}>
            <div className="flex-boy-row">
              <img src={require('../../assets/pcc-logo-clear.png')} alt="pcc-logo" style={{ width: '30px', height: '30px', marginRight: '0px' }} />
              <span style={{ fontWeight: 'bold' }}> PCC </span>
            </div>
          </Button>
        </a>
      ])} */}
      <Subtitle1
        onClick={() => navigate('/auth/password-reset')}
        style={{ cursor: 'pointer', marginTop: '10px' }}
      >
        Forgot Password?
      </Subtitle1>
    </div>
  );
};
const mapStateToProps = (state) => ({
  loadingUser: state.auth?.loadingUser,
  settings: state.settings?.settings,
  activeUser: state.auth?.activeUser,
  dynamicLink: state.auth?.dynamicLink,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
