import Papa from 'papaparse';
import { boundaries } from './boundaries';

export const zipcodes = await (async () => {
    return new Promise((resolve, reject) => {
        Papa.parse('mapbox-boundaries-adm1-v4_2-US.csv', {
            download: true,
            header: true,
            complete: (results) => {
                resolve(results.data);
            },
            error: (data) => reject(data)
        });
    });
})();
export const getMapboxIdByStateName = async (stateName) => {
    

    
    
    // Parse the CSV da
    // Find the state object in the parsed data
    const stateObj = boundaries.find((item) => item.name === stateName);
   
    // Return the mapbox_id or null if the state was not found
    return stateObj ? stateObj.mapbox_id : null;
}

export const mapboxToken = "pk.eyJ1Ijoid3AtbWFwYm94IiwiYSI6ImNrbndvYmxxODB5bXAybm9hcXI5ajlkODQifQ.mGyLCVqMTcdwf5SMMAaxbw"