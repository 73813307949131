import React, {useEffect, useState} from 'react';
import {Grid, Table, Upload} from "antd";
import {connect, useDispatch} from "react-redux";
import {createPPEKit, deletePPEKit, editPPEKit, fetchPpeKits, resetPpeKitsPagination, updatePpeKitsFilters} from "../../../app/Reducers";
import TableFilters from "../../../components/InputComponents/TableFilters";
import {
  containedButton,
  createFormInputsFromTableColumns,
  dialogCloser,
  emptyFun,
  emptyTable,
  executeChunkFn,
  getSkels,
  kebabMenus,
  onTableChange,
  responsiveTbl,
  secondaryButton
} from "../../../common/helpers";
import KebabMenu from "../../../components/KebabMenu";
import * as Sentry from "@sentry/react";
import {errMsg, getToastId, toastUp, updateToast} from "../../../app/Reducers/reducerUtils";
import DynamicModalForm from "../../../components/DynamicModalForm";
import {apiRequest} from '../../../app/Apis';
import {CloudUploadOutlined, PlusOutlined} from '@ant-design/icons';


const { useBreakpoint } = Grid;
const PpeKits = ({ appConf, ppeKitsPagination, ppeKits }) => {
  const dispatch = useDispatch();
  const windowState=useBreakpoint()
  const dateFormat = "YYYY-MM-DD";
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [auditItem, setAuditItem] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [selected, setSelected] = useState(null)
  const [isExecuting, setisExecuting] = useState(false)
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  useEffect(() => {
    dispatch(fetchPpeKits());
    return emptyFun
  }, []);
  
  const columns = [
    {
      title: 'Name', dataIndex: 'name', key: 'name', fltr: { filterable: true, type: 'text', editableCol: true, required: true, fullRow: false }
    },
    {
      title: 'BC Item ID', dataIndex: 'bc_item_id', key: 'bc_item_id', fltr: { filterable: true, type: 'text', editableCol: true, required: true, fullRow: false }
    },
    {
      title: 'Vendor number', dataIndex: 'vendor_number', key: 'vendor_number', fltr: { filterable: true, type: 'text', editableCol: true, required: true, fullRow: false }
    },
    {
      title: 'Unit of measure', dataIndex: 'unit_of_measure', key: 'unit_of_measure', fltr: { filterable: true, type: 'text', editableCol: true, required: true, fullRow: false }
    },
    {
      title: 'Default unit count', dataIndex: 'default_unit_count', key: 'default_unit_count', fltr: { filterable: true, type: 'text', editableCol: true, required: true, fullRow: false }
    },
    {
      title: 'Unit count', dataIndex: 'unit_count', key: 'unit_count', fltr: { filterable: true, type: 'text', editableCol: true, required: true, fullRow: false }
    },
    {
      title: 'Action',
      render: (record) =>
        (<KebabMenu
            menus={actionMenus}
            recordItem={record}
            handleMenuClick={kebabMenuClick}
            resource="PPE kit"
            handleOk={() => handleOk(record)}
            showConfirmDialog={selected === record.id}
            handleCancel={handleCancel}
            confirmLoading={confirmLoading}
          />
        )
    }
  ]
  let ppeKitFormInputs = createFormInputsFromTableColumns(false, columns, null, null, null, null, dateFormat)
  //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
  let dynamicFormInputFields = {
    'Edit PPE kit': ppeKitFormInputs, 'Add PPE kit': ppeKitFormInputs
  }
  
  const actionMenus = kebabMenus(true, false, true)
  const showPopconfirm = (id) => setSelected(id);
  
  async function kebabMenuClick(e, record) {
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await openPPEKitForm(record)
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        break
      default:
    }
  }
  
  const handleOk = async (record) => {
    await setConfirmLoading(true)
    await dispatch(deletePPEKit(record.id))
    await setConfirmLoading(false)
    await setSelected(0)
  }
  
  const handleCancel = () => setSelected(0);
  
  async function handleFilterAction(action, values) {
    await dispatch(resetPpeKitsPagination())
    if (action === 'filter') {
      let pl = { filterData: { ...values }, pagination: ppeKitsPagination }
      await dispatch(updatePpeKitsFilters([false, pl, true]))
      await dispatch(fetchPpeKits(pl))
      await dispatch(updatePpeKitsFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updatePpeKitsFilters([appConf.ppeKitsFilters[0], undefined, true]))
      await dispatch(fetchPpeKits())
      await dispatch(updatePpeKitsFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  
  const openPPEKitForm = async (record) => {
    await setFormName(!!record ? 'Edit PPE kit' : 'Add PPE kit');
    await setDefaultDynamicModalFormValues(!!record ? { ...record } : undefined);
    await setDynamicFormOpen(true);
  };
  
  /**
   * This function is handling returned form entries from the dynamic form
   * */
  const onDynamicFormEntriesResult = async (entries) => {
    
    try {
      switch (formName) {
        case 'Add PPE kit':
          await executeChunkFn(dispatch, createPPEKit, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen), null)
          break;
        case 'Edit PPE kit':
          await executeChunkFn(dispatch, editPPEKit, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen), null)
          break;
        default:
          return
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      toastUp(errMsg(e, false, formName), false);
    }
  };
  const [uploading, setUploading] = useState(false)
  const uploadCSV = async options => {
    const { onSuccess, onError, file, onProgress } = options;
    let formData = new FormData();
    const toastId = getToastId("Uploading PPE kits file")
    formData.append("csv_file", file);
    await setUploading(true)
    try {
      await apiRequest.post("/masterlist_ppe_kits/csv_upload", formData)
      await dispatch(fetchPpeKits())
      onSuccess("Ok");
      updateToast(toastId, 'Upload successful', true)
    } catch (e) {
      onError('Failed to upload')
      updateToast(toastId, 'Upload failed', false)
    } finally {
      await setUploading(false)
    }
  }
  return (
    <div className="w-full">
      {(isDynamicFormOpen && !!formName) && (
        <DynamicModalForm
          setDynamicFormOpen={setDynamicFormOpen}
          isDynamicFormOpen={isDynamicFormOpen}
          inputFields={dynamicFormInputFields[formName]}
          onDynamicFormEntriesResult={onDynamicFormEntriesResult}
          closeModal={dialogCloser(setDynamicFormOpen)}
          isExecuting={isExecuting}
          defaultValues={defaultDynamicModalFormValues}
          formName={formName}
        />
      )}
      <Table
        title={() => (<TableFilters datasource={columns} setIsVisible={setIsVisible} isVisible={isVisible}
                                    handleFilterAction={handleFilterAction} filters={appConf.ppeKitsFilters[1]}
                                    showClear={appConf.ppeKitsFilters[0]} loading={appConf.ppeKitsFilters[2]}
                                    actionButton={
                                      <div className="flex-boy-row" style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <Upload accept=".csv" customRequest={uploadCSV} maxCount={1}
                                                showUploadList={false}>
                                          {secondaryButton(null, 'Upload CSV', uploading, 'Uploading', { marginRight: 10 }, { icon: <CloudUploadOutlined /> })}</Upload>
                                        {containedButton(() => openPPEKitForm(), 'Add PPE Kit', false, null, null, { icon: <PlusOutlined /> })}
                                      
                                      </div>}
        />)}
        loading={appConf.ppeKitsFilters[2]} size={'small'} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('patient wounds.') }}
        onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchPpeKits, appConf.ppeKitsFilters, updatePpeKitsFilters, null)}
        onRow={(record) => ({
          onClick: async (event) => {
            await setAuditItem(record)
            await setShowAuditDialog(true)
          }
        })}
        {...getSkels((appConf.ppeKitsFilters[2]), columns, ppeKits)} pagination={ppeKitsPagination}
      />
    </div>
  
  );
};


const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser,
  ppeKits: state.masters.ppeKits,
  ppeKitsPagination: state.masters.ppeKitsPagination,
  appConf: state.appConf
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PpeKits);
