import axios from 'axios';
import RemoveCookie from '../../hooks/RemoveCookie';
import SetCookie from '../../hooks/setCookie';
import GetCookie from '../../hooks/GetCookie';
// history

let isRefreshing = false;
let failedQueue = [];
let activeQueue = {};

export const apiRequest = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  }
});
const CancelToken = axios.CancelToken;
apiRequest.interceptors.request.use((config) => {
  // console.log('Request URL', config.baseURL + config.url);
  const token = GetCookie('accessToken')
  // console.log({ requestedToken: token })
  const source = CancelToken.source();
  const queue_ = `${config.url}-${config.method}`
  config.cancelToken = source.token
  if (!token) {
    //Cancel request if the user just logged out
    source.cancel()
  } else if (!!activeQueue[queue_]) {
    //Cancel request if there is an ongoing request to the same url
    source.cancel()
    // let queueIndex = activeQueue.indexOf(queue_)
    // activeQueue.splice(queueIndex, 1)
    // console.log(`${queue_} HAS BEEN CANCELLED`)
  } else {
    if (!['get','options'].includes(config.method.toLowerCase())) {
      activeQueue[queue_] = queue_;
    }
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  //console.log({ error })
  return Promise.reject(error);
});

apiRequest.interceptors.response.use(
  (response) => {
    let queue = `${response?.config?.url}-${response?.config?.method}`
    delete activeQueue[queue]
    return response;
  },
  async (error) => {
    let queue = `${error?.config?.url}-${error?.config?.method}`
    delete activeQueue[queue]
    //console.log({ error2: error })
    const originalRequest = error.config;
    
    
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true;
        const refreshToken = GetCookie('refresh_token')
        const formData = new FormData();
        formData.append('refresh_token', refreshToken);
        formData.append('client_secret', clientSecret);
        formData.append('client_id', 2);
        formData.append('grant_type', 'refresh_token');
        const config = {
          headers: {
            accept: 'application/json'
          }
        };
        try {
          const { data } = await axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/oauth/token`, formData, config);
          RemoveCookie('accessToken')
          SetCookie('accessToken', data.access_token)
          // localStorage.setItem('accessToken', data.access_token);
          apiRequest.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
          failedQueue.forEach((failedRequest) => {
            failedRequest.headers.Authorization = `Bearer ${data.access_token}`;
          });
          failedQueue = [];
          isRefreshing = false;
        } catch (error) {
          //console.log({ error1: error })
          RemoveCookie('accessToken')
          RemoveCookie('refresh_token')
          RemoveCookie('activeUser')
          localStorage.clear();
          sessionStorage.clear();
          // window.location.reload();
        }
      }
      
      originalRequest._retry = true;
      const token = GetCookie('accessToken')
      originalRequest.headers.Authorization = `Bearer ${token}`;
      failedQueue.push(originalRequest);
      
      return axios(originalRequest);
    }
    
    
    //  if error response is 422 redirect to password reset page
    // if (error.response && error.response.status === 422) {
    //   window.location.hash = '/auth/password-reset'
    // }
    
    
    return Promise.reject(error);
  }
);

apiRequest.interceptors.response.use((response) => {
  // Remove the succeeded request from the failedQueue array
  failedQueue = failedQueue.filter((failedRequest) => failedRequest.url !== response.config.url);
  // Reset the _retry flag for the succeeded request
  response.config._retry = false;
  let queue = `${response?.config?.url}-${response?.config?.method}`
  delete activeQueue[queue]
  return response;
}, (error) => {
  let queue = `${error?.config?.url}-${error?.config?.method}`
  delete activeQueue[queue]
  if (error.response && error.response.status === 401) {
    // Remove the failed request from the failedQueue array
    failedQueue = failedQueue.filter((failedRequest) => failedRequest.url !== error.config.url);
    // Reset the _retry flag for the failed request
    error.config._retry = false;
  }
  return Promise.reject(error);
});


export const apiRequestBlob = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT
  // baseURL:'https://app-uat.ibuild.global'
})

apiRequestBlob.interceptors.request.use(config => {
  console.log('Request URL', config.baseURL + config.url)
  config.responseType = 'blob'
  if (!config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json;charset=utf-8'
  }
  const token = GetCookie('accessToken')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  } else {
    return config
  }
  return config
}, error => Promise.reject(error))

apiRequestBlob.interceptors.response.use((response) => {
  return response
}, function (error) {
  //console.log({ error4: error })
  const originalRequest = error.config;
  if (error.response && error.response.status === 401 && originalRequest.url === `${process.env.REACT_APP_SERVER_ENDPOINT}/oauth/token`) {
    //console.log({ error3: error })
    return Promise.reject(error);
  }
  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const refreshToken = GetCookie('refresh_token');
    const formData = new FormData();
    formData.append("refresh_token", refreshToken)
    formData.append("client_secret", clientSecret)
    formData.append("scope", "")
    formData.append("client_id", 2)
    formData.append("grant_type", "refresh_token")
    let config = {
      headers: {
        accept: "application/json"
      },
      responseType: 'arraybuffer'
    }
    return axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/oauth/token`, formData, config).then(res => {
      if (res.status === 200) {
        RemoveCookie('accessToken')
        SetCookie('accessToken', res.data)
        // localStorage.setItem('accessToken', res.data);
        apiRequest.defaults.headers.common['Authorization'] = 'Bearer ' + GetCookie('refresh_token');
        return apiRequest(originalRequest);
      } else {
        
        // store.dispatch(logOut(navigate))
      }
    }).catch(e => {
      // localStorage.clear()
      // window.location.reload()
    })
  }
  return Promise.reject(error);
});
export const clientSecret = "8B38zThF4aqNzzBZLNIVA1Q0ZvcO159lcJUTnj26"
