import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Form, Input, Select } from 'antd'
import { createSocialHistory, updateSocialHistory, fetchSocialHistory } from '../../../../../app/Reducers';
import { emptyFun, executeChunkFn, GetOptions } from "../../../../../common/helpers";

const NewSocialHistoryForm = ({ socialHistory, patient, closeModal, setisExecuting, form }) => {
  const { Option } = Select;
  // const [socialHistoryForm] = Form.useForm();
  const [initialValues, setInitialValues] = useState(socialHistory ? socialHistory : null);
  const dispatch = useDispatch()
  useEffect(() => {
    // dispatch(fetchSocialHistory(patient.id))
    setInitialValues(socialHistory ? socialHistory : null)
    return emptyFun
  }, [socialHistory])
  const onFinish = async (values) => {
    let payload = { data: { ...values, patient_id: patient.id }, id: socialHistory.id !== null ? socialHistory.id : null }
    await executeChunkFn(dispatch, socialHistory && socialHistory.id !== null ? updateSocialHistory : createSocialHistory, payload, setisExecuting, closeModal)
  };
  return (<Form
    form={form}
    id="socialHistoryForm"
    initialValues={initialValues}
    labelCol={{ span: 16 }}
    layout="vertical"
    name="new-patient"
    onFinish={onFinish}
  >
    <Form.Item
      label="Alcohol"
      name="alcohol"
      // style={{ width: 160 }}
      rules={[{ required: true, message: 'Missing Alcohol' }]}
    >
      <Select popupMatchSelectWidth={false} allowClear>
        {GetOptions('alcohol')}
      </Select>
    </Form.Item>
    <Form.Item
      label="Tobacco"
      name="tobacco"
      // style={{ width: 160 }}
      rules={[{ required: true, message: 'Missing Tobacco' }]}
    >
      <Select popupMatchSelectWidth={false} allowClear>
        {GetOptions('tobacco')}
      </Select>
    </Form.Item>
    <Form.Item
      label="Drugs"
      name="drugs"
      // style={{ width: 160 }}
      rules={[{ required: true, message: 'Missing Drugs' }]}
    >
      <Select popupMatchSelectWidth={false} allowClear>
        {GetOptions('drugs')}
      </Select>
    </Form.Item>
    <Form.Item
      label="Occupation"
      name="occupation"
      // style={{ width: 160 }}
      rules={[{ required: true, message: 'Missing Occupation' }]}
    >
      <Input autoComplete={'off'} />
    </Form.Item>
    <Form.Item
      label="Travel"
      name="travel"
      // style={{ width: 160 }}
      rules={[{ required: true, message: 'Missing Travel' }]}
    >
      <Select popupMatchSelectWidth={false} allowClear>
        {GetOptions('travel')}
      </Select>
    </Form.Item>
  </Form>)
}
const mapStateToProps = (state) => ({
  socialHistory: state.patients.healthData.socialHistory
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(NewSocialHistoryForm)
