import React, {useEffect, useState} from 'react';
import './audit-modal.css'
import {
  boldKeyCapitalizedValue,
  cleanObject, closeButton,
  containedButton, dialogCloser,
  dynamicFormOnFinish,
  emptyFun,
  executeChunkFn,
  formSubmitButton,
  getInputField,
  modalClose,
  noLogs,
  outlinedButton
} from "../../common/helpers";
import {Col, Divider, Form, Modal, Row, Skeleton, Timeline} from "antd";
import {connect, useDispatch} from "react-redux";
import {ClockCircleOutlined, StopOutlined} from '@ant-design/icons';
import dayjs from "dayjs";
import {fetchAuditLogColumns, fetchAuditLogs, resetAuditLogs} from "../../app/Reducers";
import {apiRequest} from '../../app/Apis';
import fileDownload from 'js-file-download'

const AuditLogViewer = ({ resourceDescription, onCancel, visible, loading, defaultValues, auditLogs, auditableColumns, activeUser, resourceType }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [auditDialogLoading, setAuditDialogLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [inputFields, setInputFields] = useState({
    fields: [
      {
        name: 'date_range',
        label: 'Date Filter',
        required: true,
        type: 'date_range',
        fullRow: true,
        dateFormat: "YYYY-MM-DD",
        keys: ['dateRange[start_at]', 'dateRange[end_at]'],
        baseKey: 'dateRangeFilter'
      }
    ],
    defaultValues: {
      ...defaultValues, ...(!!defaultValues["dateRange[start_at]"] && { date_range: [dayjs(defaultValues["dateRange[start_at]"]), dayjs(defaultValues["dateRange[end_at]"])] })
    }
  });
  const filterAuditLogs = async (values_) => {
    let values = await dynamicFormOnFinish(inputFields, values_)
    await setAuditDialogLoading(true)
    //console.log({values})
    await dispatch(resetAuditLogs())
    let params = { ...inputFields.defaultValues, resourceDescription, ...values }
    //console.log({params})
    const { fields, ...rest } = params
    cleanObject(rest)
    //console.log({rest})
    await executeChunkFn(dispatch, fetchAuditLogs, { ...rest, ...((!!fields && !!fields[0]) && { fields }) }, setAuditDialogLoading, null, null)
    // returnDynamicFormEntries({values: {...inputFields.defaultValues, ...values}, actionName: inputFields.actionName})
  }
  useEffect(() => {
    (async () => {
      console.log({ defaultValues })
      //Preload audit logs is date range is provided, e.g. from masterlist
      // if (!!inputFields.defaultValues["dateRange[start_at]"]) {
      await setAuditDialogLoading(true)
      await executeChunkFn(dispatch, fetchAuditLogs, inputFields.defaultValues, setAuditDialogLoading, null, null)
      // }
    })().catch(e => console.log(e))
    return emptyFun
  }, [])
  
  const [docHeight, setDocHeight] = useState(`${window.innerHeight * 0.8}px`);
  useEffect(() => {
    function handleResize() {
      setDocHeight(`${window.innerHeight * 0.8}px`)
    }
    
    window.addEventListener('resize', handleResize)
    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })
  useEffect(() => {
    (async () => {
      await dispatch(resetAuditLogs())
      await dispatch(fetchAuditLogColumns(inputFields.defaultValues.auditable))
    })().catch(e => console.log(e))
    return emptyFun
  }, [])
  useEffect(() => {
    (async () => {
      if (!!auditableColumns?.patient) {
        await setInputFields({
          ...inputFields,
          fields: [inputFields.fields[0],
            {
              name: "fields", label: 'Fields (Optional)', required: false, type: 'multiselect', fullRow: true,
              options: auditableColumns?.patient.reduce((previousValue, currentValue) => ({ ...previousValue, [currentValue.column_name]: currentValue.label }), {})
            }
          ]
        })
      }
    })().catch(e => console.log(e))
    return emptyFun
  }, [auditableColumns])
  const getLog = (value, flag) => {
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      return Object.keys(value).map(key => {
        return `${key.replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}: ${value[key]}`;
      }).join(', \n').concat('\n');
    } else if (Array.isArray(value) && value !== null) {
      // console.log('Is Array: ', value)
      return value.length > 0 ? value.join(', ') : 'None'
    } else {
      // if (flag) {
      //   console.log('Before Logs not found')
      //   console.log('Type: ', typeof value, ': ', value)
      // }
      return `${value || ''}`
    }
  }
  
  const exportAuditReport = async () => {
    let values = await dynamicFormOnFinish(inputFields, { ...form.getFieldsValue })
    // await setAuditDialogLoading(true)
    await dispatch(resetAuditLogs())
    let params = { ...inputFields.defaultValues, resourceDescription, ...values }
    //console.log({params})
    const { fields, ...rest } = params
    cleanObject(rest)
    //console.log({rest})
    setReportLoading(true)
    let queryString = '?' + new URLSearchParams(!!fields ? params : rest).toString();
    const response = await apiRequest.get(`/audit_log/report${queryString}`)
    //console.log({response, type: typeof response.data})
    setReportLoading(false)
    fileDownload(response.data, `Audit Log Report-${dayjs().format("YYYY-MM-HH:mm:ss")}.csv`)
  }
  
  return (
    <Modal
      title={<div className={' px-4 w-full flex flex-row justify-between h-12 items-center shadow-md'}>
        <p className={'my-auto font-bold text-[15px]'}>Audit View</p>
        {closeButton(onCancel, null, { type: 'text' })}
      </div>}
      closable={false}
      destroyOnClose={true}
      rootClassName={'AuditModal'}
      afterClose={async () => await dispatch(resetAuditLogs())}
      open={visible}
      className={` h-[${docHeight}]`}
      footer={[
        <div className="flex flex-row px-4 mt-4 justify-end w-full py-2 bg-white shadow-sm rounded-b-lg border-1 border-t-black gap-4">
          
            {outlinedButton(onCancel, 'Cancel', null,  null, null)}
          {!!auditLogs && containedButton(exportAuditReport, (!!auditLogs && !auditLogs[0]) ? 'No Logs' : `Export Audit Report`, reportLoading, 'Loading',
              { cursor: (!!auditLogs && !auditLogs[0]) ? 'not-allowed' : 'pointer' },
              { ...((!!auditLogs && !auditLogs[0]) && { disabled: true, icon: <StopOutlined /> }) })}
        </div>]}
      style={{ top: 0, padding: 0, marginBottom: 0 }}
      onCancel={onCancel}
      closeIcon={modalClose(onCancel)}
      maskClosable={loading}
      width={"60%"}>
      <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
        <Row gutter={[1, 16]} align={"top"} justify="space-around" style={{ background: 'white',  width: "100%" }}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} flex={"auto"} style={{ background: '#F7F8F9', padding: 10 }}>
            <Divider orientation="left"> <p className={'text-sm font-semibold'}>Audit Summary: </p></Divider>
            {boldKeyCapitalizedValue('Auditor:', `${activeUser.name}`)}
            {boldKeyCapitalizedValue('Auditor role(s):', `${activeUser?.roles.map(item => item.name).join(', ')}`)}
            {boldKeyCapitalizedValue('Resource Type:', resourceType)}
            {boldKeyCapitalizedValue('Resource ID/Name:', resourceDescription || 'N/A')}
            <Divider orientation="left"> <p className={'text-sm font-semibold'}>Audit Filters: </p></Divider>
            <Form
              name="auditForm" form={form}
              id="auditForm"
              preserve={false}
              initialValues={{ ...inputFields?.defaultValues }}
              layout="vertical"
              autoComplete="off"
              onFinish={(values) => filterAuditLogs(values, setAuditDialogLoading, dispatch, inputFields.defaultValues).catch(e => console.log(e))}>
              <Row gutter={[4, 0]} align={"stretch"} justify={"start"} style={{ width: '100%', margin: 0 }}>
                {inputFields?.fields.map(field => field.fullRow ? <Col xs={24} sm={24} md={24} lg={24} xl={24} flex={"auto"} style={{ width: '100%' }}>{getInputField(form, field)}</Col> :
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}>{getInputField(form, field)}</Col>)}
              </Row>
              <Form.Item style={{ textAlign: 'center', width: '100%', marginTop: 10 }}>
                {formSubmitButton('auditForm', 'Apply filters', auditDialogLoading, 'Submitting', null, { width: '50%' })}
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24} sm={24} md={15} lg={15} xl={15} style={{ background: '#F7F8F9', padding: 10, height: docHeight, overflowY: 'auto', overflowX: 'hidden' }}>
            <Divider orientation="left"> <p  className={'text-sm font-semibold'}>Changes: </p></Divider>
            
            {auditDialogLoading ? <Timeline mode={'left'} pending="Loading logs...">
              <Timeline.Item label={<p  className={'text-sm font-semibold'}> Actor </p>} dot={<ClockCircleOutlined/>} color="red">
                <Skeleton active/>
              </Timeline.Item>
            </Timeline> : (!auditLogs || !auditLogs[0]) ? noLogs :
              <Timeline mode={'left'}{...(auditDialogLoading && { pending: "Loading logs..." })} >
                {[...auditLogs.map(item => {
                  return [
                    <Timeline.Item label={<p  className={'text-sm font-semibold'}> Actor </p>} dot={<ClockCircleOutlined/>} color="red">
                      <div className={'w-full bg-white rounded p-4 py-0'}><p>
                        <strong>{`${item?.actor?.name} (${item?.actor?.role})`}</strong><br/>
                        Email: <span className={'text-blue-400'}>{item?.actor?.email || 'Unknown'}</span><br/>
                        Phone: <span className={'text-blue-400'}>{item?.actor?.phone_no || 'Unknown'}</span><br/>
                      </p></div>
                    </Timeline.Item>,
                    <Timeline.Item label={dayjs(item.time).format("YYYY-MM-DD h:mm:ss a")}{...{ color: item.event === 'created' ? 'green' : '#108ee9' }}>
                      {item.event === 'created' ? <p style={{ color: 'green' }}><b>Resource created</b></p> :
                        item.event === 'updated' ? <p style={{ color: '#108ee9' }}><b>Resource updated</b></p> :
                          item.event === 'deleted' ? <p style={{ color: '#EF8100' }}><b>Resource deleted</b></p> : null
                      }
                      {item.changes.map(change => <p style={{ margin: 0 }}><b>{`${change.name.replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())} `}</b>:{!change.before ?
                        <span style={{ color: '#EF8100' }}>{' '}None</span> : ''} {`${getLog(change.before, true)}  → ${getLog(change.after)}`}</p>)}
                    </Timeline.Item>
                  ];
                })]}
                <Timeline.Item color={'red'} label={null} dot={<ClockCircleOutlined/>}> <b>{`End`}</b> </Timeline.Item>
              </Timeline>}
          </Col>
        </Row>
      </div>
    </Modal>
  );
};


const mapStateToProps = (state) => ({
  auditLogs: state.auditLogs.logs, auditableColumns: state.auditLogs.auditableColumns,
  activeUser: state.auth?.activeUser
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(AuditLogViewer)
