import { apiRequest } from "./app/Apis";
import {messaging, getToken, askForPermissionToReceiveNotifications} from "./firebase";
console.log(messaging)



export const getNotificationToken = async () => {
    try {
        //todo: Take the API keys below to env
        getToken(messaging, {vapidKey:"BPS5bolWcaQlwI8AWoWHzcrTEzFwFTXcVEV1nNabNta6vWs2QkhijWo7iTnB_yZq6ugEOiqHmRs_rrt-hAG_rj8" }).then( async(currentToken) => {
             // Save the token to your server.
            if (currentToken) {
                // console.log("Registration token:", currentToken);
                 await apiRequest.post(`/register-fcm`, { fcm_token:currentToken }).catch(err => console.log(err))
            }
        }).catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
        });



       

    } catch (error) {
        console.log("Error getting registration token:", error);
    }

}

export const requestPermission = async () => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            console.log("Notification permission granted.");
        } else {
            console.log("Unable to get permission to notify.");
        }
    } catch (error) {
        console.log("error", error);
        
    }
};



export function notification(title, body) {
    if (Notification.permission === "granted") {
        new Notification(title, { body });
    } else {
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                new Notification(title, { body });
            }
        });
    }
}



export default notification;