import React, { useState, useRef, useCallback, useEffect } from 'react'
import { Col, Form, Row, Upload, Select, AutoComplete, Input, DatePicker, Radio, Button, Image as ImageTag, Modal, Divider } from 'antd'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { useDispatch, connect } from 'react-redux'
import { apiRequest } from '../../app/Apis'
import { toast } from 'react-toastify'
import MapGL from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import NumberFormat from 'react-number-format'
import { addButton, dialogCloser, emptyFun, executeChunkFn, formSubmitButton, getInputField, GetNumericalListOptions, GetOptions, horStack, inputRow, makeAutoCompleteOptions, makeSelectOptions, modalClose, outlinedButton, showImagePreview, kebabMenus, keyValItemForReferral, keyValItemGreyForReferral } from '../../common/helpers'
import { fetchActiveFacilities, findTimeZone, handleActiveFacilitySearch } from '../../app/Apis/commonApi'
import GetCookie from '../../hooks/GetCookie';
import dayjs from 'dayjs'
import { Subtitle1, Body1, Headline6 } from '../../components'
import { mapboxToken } from '../../configure/constants'
import { fetchBillingGroupPerState, createProspect, fetchProspects } from '../../app/Reducers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { errMsg, getToastId, updateToast } from '../../app/Reducers/reducerUtils'
import * as Sentry from '@sentry/react';
import WoundUpload from './components/WoundUpload'
import ProspectInsuranceForm from './components/ProspectInsuranceForm'
import InsuranceCard from './components/InsuranceCard'
import ProspectDocumentsView from './components/prospectDocuments'
import KebabMenu from '../../components/KebabMenu'
import AppPhoneInput from '../../components/InputComponents/AppPhoneInput';

const facesheet_types = ["SNF", "Hospice care", "Palliative care", "Other"]
const NewReferralCard = ({ setCreatingProspectExecuting, closeModal, activeUser, selectedFacility, masterListRaw, appConf, billingGroups, loadFacilityData, _facility }) => {
  const { Option } = Select
  const [faceForm] = Form.useForm()
  const [documentsForm] = Form.useForm()
  const [prospectForm] = Form.useForm()
  const dateFormat = 'MM/DD/YYYY'
  const [facesheetForm, setFacesheetForm] = useState(false)
  const [facilities, setFacilities] = useState([])
  const [facility, setFacility] = useState()
  const [image, setImage] = useState(null)
  const [images, setImages] = useState([])
  const [faceImages, setFaceImages] = useState([])
  const [faceSheets, setFaceSheets] = useState([])
  const [creatingProspect, setCreatingProspect] = useState(false)
  const [date, setDate] = useState(null)
  const dispatch = useDispatch()
  const [phoneValue, setPhoneValue] = useState(undefined);
  const [isExecuting, setisExecuting] = useState(false)
  const [addingInsurance, setAddingInsurance] = useState(false)
  const [isSubmitting, setisSubmitting] = useState(false)
  const uploadUrl = process.env.REACT_APP_ENDPOINT + '/files'
  const token = GetCookie('accessToken')
  const [preview, setPreview] = useState(false)
  const [fileList, setFileList] = useState([])
  const [uploadList, setUploadList] = useState([])
  const [diabetic, setDiabetic] = useState(null)
  const [documentForm, setDocumentForm] = useState(false)
  const [insuranceList, setInsuranceList] = useState([])
  const [editInsurance, setEditInsurance] = useState(false)
  const [insuranceForm, setInsuranceForm] = useState(false)
  const [noOfWounds, setNoOfWounds] = useState(0)
  const [woundForm, setWoundForm] = useState(false)
  const [documentList, setDocumentList] = useState([])
  const [LocationError, setLocationError] = useState(false);
  const [masterLocations, setMasterLocations] = useState(masterListRaw[0] ? masterListRaw.find(item => item.system_name === 'wound_location')?.options : [])
  const [woundLocations, setwoundLocations] = useState((!!masterLocations && masterLocations[0]) ? makeAutoCompleteOptions(...[masterListRaw.find(item => item.system_name === 'wound_location')?.options]) : [])
  const [initialInsuranceData, setInitialInsuranceData] = useState(undefined)
  const [consent, setConsent] = useState(false)
  const [selected, setSelected] = React.useState(null)
  const [editWound, setEditWound] = useState(false)
  const [selectedWound, setSelectedWound] = useState(null)
  const [validateWoundField, setValidateWoundField] = useState(false)
  let defaultFilters = !!activeUser?.facilityProfile ? { filterData: { facility: activeUser?.facilityProfile?.name } } : undefined
  console.log({uploadList})

  const getSsnField = () => {
    return dayjs().diff(dayjs(date), 'years') > 18 ? <Form.Item
      style={{ width: '100%' }}
      name="ssn"
      label="SSN"
      rules={[{ required: false, message: 'SSN is required for adults' }, { max: 11, message: 'SSN length exceeds limit' }/*, {pattern: /^[1-9]./, message: 'Invalid SSN'}*/]}>
      <NumberFormat autoComplete={'off'} customInput={Input} format="###-##-####" placeholder="###-##-####" />
    </Form.Item> : <Form.Item
      style={{ width: '100%' }}
      name="ssn"
      label="SSN"
      rules={[{ max: 11, message: 'SSN length exceeds limit' }/*, {pattern: /^[1-9]./, message: 'Invalid SSN'}*/]}>
      <NumberFormat autoComplete={'off'} customInput={Input} format="###-##-####" placeholder="###-##-####" />
    </Form.Item>
  }

  const removeImage = (image, index) => {
    const arr = faceSheets.filter(i => i !== image.sheetId)
    setImages(faceImages.splice(index, 1))
    setFaceSheets(arr)
  }
  const removeInsuranceDetails = (id) => {
    console.log("Removing:", id)
    const arr = insuranceList.filter(i => i.plan !== id)
    setInsuranceList(arr)
  }
  const removeWoundImage = (id) => {
    console.log("Removing:", id)
    const arr = uploadList.filter(i => i.image_id !== id)
    setUploadList(arr)
  }

  const handleFacesheetOk = (values) => setFacesheetForm(false)
  const handleFacesheetCancel = () => setFacesheetForm(false)

  const handleDocumentCancel = () => setDocumentForm(false)


  const onFacesheetChange = ({ fileList: newFileList }) => {
    newFileList.map(file => {
      if (file.response) {
        setImage(file.response.data)
        setImages([...images, file.response.data])
        faceForm.setFieldsValue({ image_id: file.response.data.id })
      }
      return file
    })
  }

  const DetailsPreview = (file) => {
    return (
      <ImageTag.PreviewGroup>
        <div className='flex-boy-row-space'>
          <ImageTag fallback='../../assets/doc.png' height={100} src={file}>{file?.name}</ImageTag>
        </div>
      </ImageTag.PreviewGroup>
    )
  }

  const onFaceFinish = async (values) => {
    await setCreatingProspect(true)
    await createFaceSheet(values)
    await setCreatingProspect(false)
    await setFacesheetForm(false)
  }

  const onDocumentChange = ({ fileList: newFileList }) => {
    newFileList?.map(file => {
      if (file.response) {
        documentsForm.setFieldsValue({ image_id: file.response.data.id })
      }
      return file
    })
  }

  const onDocumentFinish = async (values) => {
    if (!values.image_id) return
    console.log('Document Values: ', values)
    setDocumentForm(false)
    setDocumentList([...documentList, values])
    documentsForm.resetFields()
    console.log('Document List: ', documentList)
  }

  let hospitalizedField = {
    label: "Has patient been hospitalized in the past 100 Days?",
    name: 'hospitalize',
    required: true,
    type: 'select',
    options: makeSelectOptions(['yes', 'no'])
  }
  let medicalRecordField = {
    label: "Where do you want to receive the Medical Records?",
    name: 'medical_record_access_option', required: true, type: 'select', options: makeSelectOptions(['email', 'fax', 'email and fax'])
  }

  const dateChange = (value) => setDate(value)
  const disabledFutureDates = (current) => {
    let customDate = dayjs()
    let tooOld = dayjs("1869-12-31")
    return (current && current > dayjs(customDate, dateFormat)) || (current && current < dayjs(tooOld, dateFormat))
  }
  const disabledPastDates = (current) => {
    let customDate = dayjs()
    return current && current < dayjs(customDate, 'YYYY-MM-DD')
  }
  const onFacilitySelect = (value) => {
    let facility = facilities.find(item => item.name === value)
    setFacility(facility)
  }
  // location stuff
  const [viewport, setViewport] = useState({ latitude: 37.7577, longitude: -122.4376, zoom: 8 })
  const [locationData, setLocationData] = useState(null)
  const geocoderContainerRef = useRef()
  const mapRef = useRef()
  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), [])

  const onResult = useCallback(async (result) => {
    // console.log(result)
    let center = result.result.center
    let context = result.result.context
    let state = context.find(item => item.id.includes('region'))
    let city = context.find(item => item.id.includes('place'))
    let zip_code = context.find(item => item.id.includes('postcode'))
    let data = {
      // city: city ? city.text : result.result.place_name, state: state ? state.text : null,
      // zip_code: zip_code ? zip_code.text : result.result.place_name,
      co_ordinates: `${center[0]},${center[1]}`,
      state: state ? state.text : null
      // address_1: result.result.place_name
    }
    setLocationData(data)
    let timezone = await findTimeZone(city ? city.text : result.result.place_name)
    console.log("Timezone:", timezone)
    console.log("Location Data:", data)
    setLocationError(false)

    prospectForm.setFieldsValue({
      address_1: result.result.place_name,
      city: city ? city.text : null,
      state: state ? state.text : null,
      zip_code: zip_code ? zip_code.text : null,
      timezone: timezone
    });
    dispatch(fetchBillingGroupPerState(data.state))
  }, [])

  const createFaceSheet = async (payload) => {
    console.log('facesheet payload', payload)
    const toastId = getToastId("Adding FaceSheet")
    try {
      const response = await apiRequest.post('/face-sheets', payload)
      console.log('createFaceSheet: ', response.data.data)
      if (response && response.data && response.data.data) {
        setFaceSheets([...faceSheets, response.data.data.id])
        setFaceImages([...faceImages, { url: image.url, name: image.name, sheetId: response.data.data.id }])
        prospectForm.setFieldsValue({ face_sheets: faceSheets })
        updateToast(toastId, "Facesheet Added", true)
        setFacesheetForm(false)
        faceForm.resetFields()
      }
    } catch (e) {
      Sentry.captureException(e);
      updateToast(toastId, errMsg(e, false), false)
    }
  }

  const getPatientDetails = async options => {
    const { onSuccess, onError, file, onProgress } = options;
    let formData = new FormData();
    // add one or more of your files in FormData
    // again, the original file is located at the `originFileObj` key
    const toastId = toast("Generating referral details", { type: "info", autoClose: false });
    formData.append("patient_details_image", file);
    const { data } = await apiRequest.post("/ocr-patient-details", formData).catch(function (err) {
      toast.update(toastId, { render: err.response?.data.message || "Server Error", type: toast.TYPE.ERROR, autoClose: 5000 });
      setFileList([])
    })
    if (data && data.data) {
      console.log('data.data: ', data.data)
      toast.update(toastId, { render: "Prospect Details Generated", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      onSuccess("Ok");
      console.log(prospectForm.getFieldsValue(), data.data, data.data['First Name'].toString().slice(0, -1))
      prospectForm.setFieldsValue({
        first_name: data.data['First Name'],
        last_name: data.data['Last Name'],
        birth_date: dayjs(data.data['DOB']).isValid() === true ? dayjs(data.data['DOB']?.slice(0, -1)) : null,
        email: data.data['Email'],
        phone_no: `${data.data['Phone']}`,
        gender: data.data['Gender']?.toLowerCase(),
        ssn: data.data['SSN'] !== "N/A" ? data.data['SSN'] : null,
        fax: data.data['Fax'] !== "N/A" ? data.data['Fax'] : null,
        address_1: data.data['Address']
      });
      handleActiveFacilitySearch(data.data["Office"], setFacilities)
      faceForm.setFieldsValue({ default_region: data.data['Address'] })
    } else {
      toast.update(toastId, { render: "unable to generate referral details fom the facesheet please manualy fill the details", type: toast.TYPE.ERROR, autoClose: 5000 });
    }
  }

  const onProspectFinish = async (values) => {
    console.log('Values: ', values)
    prospectForm.validateFields(['face_sheet'])
    if (uploadList.length !== noOfWounds) {
      prospectForm.validateFields(['number_of_Wounds'])
    }
    if (!locationData) {
      setLocationError(true)
    } else {
      const wounds = uploadList
      const documents = documentList
      const payload = {
        ...values, name: values.first + ' ' + values.last, face_sheets: faceSheets, wounds: wounds, ...locationData,
        ...((documentList.length > 0) && { document: documents }),
        insurances: insuranceList, birth_date: dayjs(values.birth_date).format("YYYY-MM-DD"),
        ...((!!values.billable_from && values.billable_from !== "Invalid date") && { billable_from: dayjs(values.billable_from).format("YYYY-MM-DD") }),
        expires_at: dayjs(values.expires_at).format("YYYY-MM-DD"),
        request_date: dayjs(values.request_date).format("YYYY-MM-DD"),
        ...((activeUser?.facilityProfile || facility) && { facility_id: activeUser?.facilityProfile ? selectedFacility.id : facility.id }),
        ...phoneValue
      }

      console.log(payload)

      try {
        //Goal:Set the table to loading, and filter states until new least of prospects is loaded
        await executeChunkFn(dispatch, createProspect, payload, setCreatingProspectExecuting, closeModal, async () => {
          let payloadData = appConf.prospectFilters[0] ? appConf.prospectFilters[1] : defaultFilters
          setImages([])
          setFaceSheets([])
          setFaceImages([])
          setUploadList([])
          setDocumentList([])
          await dispatch(fetchProspects(payloadData))
          if (loadFacilityData) {
            loadFacilityData(_facility.id)
          }
        })
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  }

  const onPreview = async (file) => {
    console.log(file)
    let src = file.url
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const actionMenus = kebabMenus(true, false, true)
  async function kebabMenuClick(e, record) {
    console.log("Kebab Menu Clicked:", record)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        setEditWound(true)
        const wound_details = uploadList.find(item => item.image_id === record.image_id)
        console.log("Wound Details:", wound_details)
        setSelectedWound(wound_details)
        setWoundForm(true)
        break;
      case 'Delete':
        setSelectedWound(record.image_id)
        // removeWoundImage(record.image_id)
        break
      default:
        return;
    }
  }
  const handleOk = async (id) => {
    console.log("Selected:", selected, "ID:", id)
    removeWoundImage(id)
    handleCancel()
  }
  // handle cancel
  const handleCancel = () => {
    setSelected(null)
    setSelectedWound(null)
  }
  const handleCancelWound = () => {
    setEditWound(false)
    setWoundForm(false)
    setSelectedWound(null)
    dialogCloser(setWoundForm)
  }

  useEffect(() => {
    console.log('uploadList: ', uploadList)
    setUploadList(uploadList)
    // prospectForm.setFieldsValue({ number_of_Wounds: uploadList.length })
  }, [uploadList])
  const [prospectName, setProspectName] = useState('')
  return (
    <>
      <Modal
        title="Add facesheet"
        open={facesheetForm}
        onOk={handleFacesheetOk}
        onCancel={handleFacesheetCancel}
        closeIcon={modalClose(handleFacesheetCancel)}
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(handleFacesheetCancel, 'Cancel', creatingProspect),
          formSubmitButton('faceSheetForm', 'Add', creatingProspect, 'Adding')])}
        </div>]}>
        <Form
          form={faceForm}
          name="faceSheetForm"
          id="faceSheetForm"
          labelCol={{ span: 16 }}
          layout="vertical"
          onFinish={onFaceFinish}>
          <Form.Item
            onChange={() => faceForm.setFieldsValue({ name: faceForm.getFieldValue('title') })}
            name="title"
            label="Facesheet Title"
            rules={[{ required: true, message: 'Missing facesheet title' }]}>
            <Input autoComplete={'off'} placeholder="" />
          </Form.Item>
          <Form.Item
            style={{ width: '100%', display: 'none' }}
            name="name"
            label="Facesheet Name">
            <Input autoComplete={'off'} placeholder="" />
          </Form.Item>
          {inputRow([
            // <Form.Item
            //   style={{ width: '100%' }}
            //   name="type"
            //   label="Face Sheet Type"
            //   rules={[{ required: true, message: 'Missing face sheet type' }]}>
            //   <Select popupMatchSelectWidth={false}
            //     // onChange={handleChange}
            //     // defaultValue="elbow"
            //     style={{ width: '100%' }}>
            //     {facesheet_types.map(type => (<Option value={type}>{type} </Option>))}
            //   </Select>
            // </Form.Item>,
            <Form.Item
              style={{ width: '100%' }}
              name="expires_at"
              label="Expiration Date"
              rules={[{ required: false, message: 'Missing Expiration Date' }]}>
              <DatePicker disabledDate={disabledPastDates} style={{ width: '100%' }} format={dateFormat} />
            </Form.Item>
          ])}
          <Form.Item
            style={{ width: '100%' }}
            name="image_id"
            label="Facesheet Documents"
            rules={[{ required: true, message: 'Missing Facesheet Documents' }]}
          >
            <Upload accept="image/*,.pdf"
              action={uploadUrl}
              headers={{ 'Authorization': `Bearer ${token}` }}
              listType="picture"
              // defaultFileList={[...fileList]}
              onChange={onFacesheetChange}
              maxCount={1}>
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            <Input autoComplete={'off'} style={{ width: '100%', display: 'none' }} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={editWound ? "Edit Wound" : "Add Wound"}
        open={woundForm}
        onOk={dialogCloser(setWoundForm)}
        onCancel={dialogCloser(setWoundForm)}
        closeIcon={modalClose(dialogCloser(setWoundForm))}
        closable={true}
        maskClosable={false}
        destroyOnClose={true}
        width={600}
        height={600}
        footer={
          [<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([
              outlinedButton(handleCancelWound, 'Cancel', isExecuting),
              formSubmitButton('prospectImageForm', !editWound ? 'Add' : 'Update', isExecuting, editWound ? 'Adding' : 'Updating')])}
          </div>]}>
        <WoundUpload
          setUploadList={setUploadList}
          masterListRaw={masterListRaw}
          uploadList={uploadList}
          woundLocations={woundLocations}
          setwoundLocation={setwoundLocations}
          masterLocations={masterLocations}
          uploadUrl={uploadUrl}
          token={token}
          closeModal={()=>{
            setWoundForm(false)
            setSelectedWound(null)
          }}
          editing={editWound}
          setEditing={setEditWound}
          selectedWound={selectedWound}
        />
      </Modal>
      <Modal
        title={editInsurance ? "Edit Insurance Information" : "Add Insurance Information"}
        open={insuranceForm}
        destroyOnClose={true}
        onOk={dialogCloser(setInsuranceForm)}
        onCancel={dialogCloser(setInsuranceForm)}
        closable={true}
        maskClosable={false}
        closeIcon={modalClose(dialogCloser(setInsuranceForm))}
        width={600}
        footer={
          [<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([
              outlinedButton(dialogCloser(setInsuranceForm), 'Cancel', addingInsurance),
              formSubmitButton('prospectInsuranceForm', 'Add', addingInsurance, 'Adding')])}
          </div>]}
      >
        <ProspectInsuranceForm
          setInsurances={setInsuranceList}
          insurances={insuranceList}
          setInsuranceForm={dialogCloser(setInsuranceForm)}
          initialData={initialInsuranceData}
          editing={editInsurance}
          prospectName={prospectName}
        />
      </Modal>
      <Modal
        title="Add Documents"
        open={documentForm}
        destroyOnClose={true}
        onOk={dialogCloser(setDocumentForm)}
        onCancel={dialogCloser(setDocumentForm)}
        closable={true}
        maskClosable={false}
        closeIcon={modalClose(dialogCloser(setDocumentForm))}
        width={600}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(handleDocumentCancel, 'Cancel', isSubmitting),
          formSubmitButton('prospectDocumentForm', 'Add', isSubmitting, 'Adding')])}
        </div>]}>
        <Form
          form={documentsForm}
          name="prospectDocumentForm"
          id="prospectDocumentForm"
          labelCol={{ span: 16 }}
          layout="vertical"
          onFinish={onDocumentFinish}
        >
          <Form.Item
            onChange={() => documentsForm.setFieldsValue({ name: documentsForm.getFieldValue('title') })}
            name="title"
            label="Document Title"
            rules={[{ required: true, message: 'Missing Document Title' }]}>
            <Input autoComplete={'off'} placeholder="" />
          </Form.Item>
          <Form.Item
            style={{ width: '100%', display: 'none' }}
            name="name"
            label="Document Name">
            <Input autoComplete={'off'} placeholder="" />
          </Form.Item>
          {inputRow([
            <Form.Item
              style={{ width: '100%' }}
              name="type"
              label="Document Type"
              rules={[{ required: true, message: 'Missing Document Type' }]}>
              <Select style={{ width: '100%' }}
                options={[
                  { label: "Insurance", value: "Insurance" },
                  { label: 'Wound progress notes', value: 'Wound progress notes' },
                  { label: "Other", value: "Other" }
                ]}
              />
            </Form.Item>,
            <Form.Item
              style={{ width: '100%' }}
              name="expires_at"
              label="Expiration Date"
              rules={[{ required: false, message: 'Missing Expiration Date' }]}>
              <DatePicker disabledDate={disabledPastDates} style={{ width: '100%' }} format={dateFormat} />
            </Form.Item>
          ])}
          <Form.Item style={{ width: '100%' }} name="image_id" label="Documents"
            rules={[{ required: true, message: 'Missing Document image' }]}>
            <Upload action={uploadUrl}
              headers={{ 'Authorization': `Bearer ${token}` }}
              listType="picture"
              onChange={onDocumentChange}
              maxCount={1}>
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            <Input autoComplete={'off'} style={{ width: '100%', display: 'none' }} />
          </Form.Item>
        </Form>
      </Modal>
      <div className="w-full">
        <Form
          form={prospectForm}
          name="new-prospect"
          // labelCol={{ span: 16 }}
          onFinish={onProspectFinish}
          onValuesChange={(changedValues, values) => {
            setProspectName(`${values?.first_name||''} ${values?.middle_name||''} ${values?.last_name||''}`.trim())
          }}
          layout="vertical"
          id="new-prospect-form">
          <Row>
            <Col span={16}>
              <div className='p-2' style={{ width: '100%' }}>
                <Form.Item
                  style={{ width: '100%' }}
                  rules={[{ required: false }]}
                  name="prospect_details_image"
                  className='border rounded-xl shadow'>
                  <div className='h-auto'>
                    <div className='p-4 flex flex-row justify-between w-full h-auto'>
                      <Subtitle1>
                        Referral Details Image</Subtitle1>
                      <div className=''>
                        <Upload
                          accept="image/*,.pdf"
                          customRequest={getPatientDetails}
                          headers={{ 'Authorization': `Bearer ${token}` }}
                          listType="picture"
                          onPreview={onPreview}
                          className="w-full"
                          // itemRender={(file) => DetailsPreview(file)}
                          maxCount={1}>
                          <div className='left-0'>
                            <PlusOutlined />
                          </div>
                        </Upload>
                      </div>
                    </div>
                    { }
                  </div>
                </Form.Item>
                <div className='w-full border rounded-xl shadow p-4'>
                  <div className='p-2 flex flex-row w-full'>
                    <Headline6 className=''>Details</Headline6>
                  </div>

                  {inputRow([
                    ...(!activeUser?.facilityProfile ? [<Form.Item
                      style={{ width: '100%' }}
                      name="facility_id"
                      initialValue={facility ? facility.name : undefined}
                      label="Referring Agency Name"
                      placeholder="Search for agency"
                      rules={[{ required: true, message: 'Missing Referring Agent' }]}>
                      <AutoComplete popupMatchSelectWidth={false} style={{ width: "100%", borderRadius: "4px" }}
                        onSelect={onFacilitySelect}
                        placeholder="Search for agency"
                        defaultValue={facility ? facility.name : null}
                        onSearch={(value) => handleActiveFacilitySearch(value, setFacilities)}>
                        {facilities?.length > 0 && facilities.map((item, index) =>
                          <AutoComplete.Option key={index} value={item.name}>{item.name}</AutoComplete.Option>)}
                      </AutoComplete>
                    </Form.Item>] : [])
                  ])}
                  {inputRow([
                    <Form.Item
                      style={{ width: '100%' }}
                      name="first_name"
                      label="First Name"
                      rules={[{ required: true, message: 'Missing/Invalid first name', pattern: /[a-zA-Z ]$/ }]}>
                      <Input autoComplete={'off'} placeholder="First Name" />
                    </Form.Item>,
                    <Form.Item
                      style={{ width: '100%' }}
                      name="middle_name"
                      label="Middle Name"
                      rules={[{ required: false, message: 'Missing/Invalid name', pattern: /[a-zA-Z ]$/ }]}>
                      <Input autoComplete={'off'} placeholder="Middle Name" />
                    </Form.Item>,
                    <Form.Item
                      style={{ width: '100%' }}
                      name="last_name"
                      label="Last Name"
                      rules={[{ required: true, message: 'Missing/Invalid Last name', pattern: /[a-zA-Z ]$/ }]}>
                      <Input autoComplete={'off'} placeholder="Last Name" />
                    </Form.Item>
                  ])}
                  {inputRow([
                    <Form.Item
                      style={{ width: '100%' }}
                      name="birth_date"
                      label="Date of Birth"
                      rules={[{ required: true, message: 'Missing date of birth' }]}>
                      <DatePicker onChange={dateChange} disabledDate={disabledFutureDates} style={{ width: '100%' }}
                        format={dateFormat} />
                    </Form.Item>,
                    <Form.Item
                      style={{ width: '100%' }}
                      name="gender"
                      label="Gender"
                      rules={[{ required: true, message: 'Missing Gender' }]}>
                      <Select popupMatchSelectWidth={false}
                        placeholder="Gender"
                        style={{ width: '100%' }}
                        allowClear
                      // onChange={() => { console.log('wounds', faceSheets, prospectForm.getFieldValue('first'),) }}
                      >
                        {GetOptions('gender')}
                      </Select>
                    </Form.Item>
                  ])}
                  {inputRow([
                    <Form.Item
                      name="visit_type"
                      label="New Patient?"
                      rules={[{ required: true, message: 'Missing/Invalid Last name', pattern: /[a-zA-Z ]$/ }]}>
                      <Select popupMatchSelectWidth={false}
                        placeholder="Is the patient New or Established"
                        style={{ width: '100%' }}
                        allowClear>
                        <Option value="New Patient">New</Option>
                        <Option value="Established Patient">Established</Option>
                      </Select>
                    </Form.Item>,
                    <Form.Item
                      style={{ width: '100%' }}
                      name="referral_priority"
                      label="Referral Priority"
                      rules={[{ required: true, message: 'Missing Priority' }]}
                    >
                      <Select
                        popupMatchSelectWidth={false}
                        placeholder="Priority"
                        style={{ width: '100%' }}
                        allowClear
                      >
                        <Option value="Low">Low</Option>
                        <Option value="Medium">Medium</Option>
                        <Option value="High">High</Option>
                        <Option value="Urgent">Urgent</Option>
                      </Select>
                    </Form.Item>
                  ])}
                  {inputRow([
                    getSsnField(),
                    <Form.Item
                      style={{ width: '100%' }}
                      name="email"
                      label="Email"
                      rules={[{ required: false/*!activeUser?.facilityProfile*/, message: 'Missing Email' }]}>
                      <Input autoComplete={'off'} placeholder="Email" />
                    </Form.Item>
                  ])}
                  {inputRow([
                    <AppPhoneInput name={'phone_no'} setPhoneValue={setPhoneValue} phoneValue={phoneValue} formItemProps={{ style: { width: '100%' }, required: true }} />,
                    <Form.Item
                      style={{ width: '100%' }}
                      name="fax"
                      label="Fax"
                      rules={[{ required: false, message: 'Missing Fax' }]}
                    >
                      <NumberFormat
                        autoComplete={'off'}
                        customInput={Input}
                        format="+# (###) ### ####"
                        placeholder="Fax"
                      />
                    </Form.Item>,
                  ])}
                  {/* <Form.Item name={"type_of_kit"} label="Type of Kit" rules={[{ required: false, message: 'Missing Kit Type' }]}>
                    <Select placeholder="Type of Kit">
                      <Option value="Home Health (Standard) Kit">Standard Kit</Option>
                      <Option value="Hospice Kit">Hospice Kit</Option>
                      <Option value="Hospice Kit (Cash)">Hospice Kit (Cash)</Option>
                      <Option value="No Supplies">No Supplies</Option>
                    </Select>
                  </Form.Item> */}
                  <Form.Item
                    style={{ width: '100%' }}
                    name="patient_location_type"
                    label="Patient Location Type"
                    rules={[{ required: true, message: 'Missing Patient Location Type' }]}
                  >
                    <Select style={{ width: '100%' }}>
                      {GetOptions('patient_location_type')}
                    </Select>
                  </Form.Item>
                  <Subtitle1 className="Validator" style={{ textAlign: 'left', marginBottom: '10px' }}>Address Search</Subtitle1>
                  <Form.Item
                    style={{ width: '100%' }}
                    name="default_region"
                    label=""
                    rules={[{ required: false, message: 'Missing Region' }]}>
                    <div
                      ref={geocoderContainerRef}
                      style={{ position: "absolute", top: 0, left: 0, zIndex: 1, width: "100%" }}
                    />
                    <MapGL
                      ref={mapRef}
                      {...viewport}
                      width="100%"
                      height="100%"
                      onViewportChange={handleViewportChange}
                      mapboxApiAccessToken={mapboxToken}>
                      <Geocoder
                        mapRef={mapRef}
                        containerRef={geocoderContainerRef}
                        onViewportChange={handleViewportChange}
                        mapboxApiAccessToken={mapboxToken}
                        countries="us,ke"
                        onResult={onResult}
                        marker={false}
                        onError={onResult} />
                    </MapGL>
                  </Form.Item>
                  {LocationError && <Body1 className="Validator" color="crimson" style={{ textAlign: 'left', marginBottom: '10px' }}>Please add an address!</Body1>}
                  {(locationData || prospectForm.getFieldValue("address_1")) && <>
                    <Form.Item
                      style={{ width: '100%', marginTop: '10px' }}
                      name="address_1"
                      label="Address"
                      rules={[{ required: true, message: 'Missing location' }]}>
                      <Input autoComplete={'off'} placeholder="Address" />
                    </Form.Item>
                    {inputRow([
                      <Form.Item
                        style={{ width: '100%' }}
                        name="state"
                        label="State"
                        rules={[{ required: true, message: 'Missing State' }]}>
                        <Input autoComplete={'off'} placeholder="State" />
                      </Form.Item>,
                      <Form.Item
                        style={{ width: '100%' }}
                        name="city"
                        label="City"
                        rules={[{ required: true, message: 'Missing City' }]}>
                        <Input autoComplete={'off'} placeholder="City" />
                      </Form.Item>,
                      <Form.Item
                        style={{ width: '100%' }}
                        name="zip_code"
                        label="Zip Code"
                        rules={[
                          {
                            required: true,
                            message: 'Missing Zip code'
                          }
                        ]}>
                        <Input autoComplete={'off'} placeholder="zip" />
                      </Form.Item>
                    ])}
                    {/* timezone */}
                    <Form.Item
                      style={{ width: '100%' }}
                      name="timezone"
                      label="Timezone"
                      rules={[{ required: false, message: 'Missing Timezone' }]}>
                      <input autoComplete={'off'} placeholder="Timezone" disabled />
                    </Form.Item>
                  </>}
                  {getInputField(prospectForm, hospitalizedField)}
                  {/* <Form.Item name="date_of_hospitalization" label="Date of Hospitalization" rules={[{ required: false, message: 'Missing Date of Hospitalization' }]}>
                    <DatePicker onChange={dateChange} disabledDate={disabledFutureDates} style={{ width: '100%' }} />
                  </Form.Item> */}
                  <Form.Item
                    style={{ width: '100%' }}
                    name="diabetic"
                    label="Diabetic?"
                    rules={[{ required: false, message: 'Missing Diabetic' }]}>
                    <Select placeholder="Is Patient Diabetic?" onSelect={(value) => setDiabetic(value)}>
                      <Option value="Yes">Yes</Option>
                      <Option value="No">No</Option>
                    </Select>
                  </Form.Item>
                  {diabetic && diabetic === 'Yes' && (
                    <Form.Item name="diabetic_type" label="Diabetic Type" rules={[{ required: true, message: 'Missing Diabetic Type' }]}>
                      <Select placeholder="Diabetic Type">
                        <Option value="Type 1">Type 1</Option>
                        <Option value="Type 2">Type 2</Option>
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item name='consent' label="Consent" rules={[{ required: true, message: 'Missing Consent' }]}>
                    <Select placeholder="Consent" onChange={(value) => setConsent(value)}>
                      <Option value="self">Self</Option>
                      <Option value="power of attorney">Power of Attorney</Option>
                    </Select>
                  </Form.Item>
                  {consent === 'power of attorney' && (
                    <>
                      {inputRow([
                        <Form.Item
                          style={{ width: '100%' }}
                          name="power_of_attorney_name"
                          label="Power of Attorney Name"
                          rules={[{ required: true, message: 'Missing Power of Attorney Name' }]}>
                          <Input autoComplete={'off'} placeholder="Power of Attorney Name" />
                        </Form.Item>,
                        <AppPhoneInput name={'power_of_attorney_contact_number'} label="Power of Attorney phone number" setPhoneValue={setPhoneValue} phoneValue={phoneValue}
                          formItemProps={{ style: { width: '100%' }, required: true, label: 'Attorney phone number' }} />
                      ])}
                    </>
                  )}

                  {inputRow([
                    <Form.Item name="case_manager" label="Case Manager Name" rules={[{ required: false, message: 'Missing Case Manager Name' }]}>
                      <Input autoComplete={'off'} placeholder="Case Manager Name" />
                    </Form.Item>,
                    <AppPhoneInput name={'case_manager_phone'} setPhoneValue={setPhoneValue} phoneValue={phoneValue}
                      formItemProps={{ style: { width: '100%' }, required: false, label: 'Case manager number' }} />
                  ])}

                  {!activeUser?.facilityProfile && inputRow([
                    <Form.Item
                      style={{ width: '100%' }}
                      name="request_date"
                      label="Date of Request"
                      rules={[{ required: true, message: 'Missing date of request' }]}>
                      <DatePicker disabledDate={disabledFutureDates} style={{ width: '100%' }} format={dateFormat} />
                    </Form.Item>,
                    <Form.Item
                      style={{ width: '100%' }}
                      name="billable_from"
                      label="Date can Bill"
                      rules={[{ required: false, message: 'Missing date can bill' }]}>
                      <DatePicker disabledDate={disabledPastDates} style={{ width: '100%' }} format={dateFormat} />
                    </Form.Item>
                  ])}
                  <Form.Item
                    style={{ width: '100%' }}
                    name="visit_reason"
                    label="Visit Reason"
                    rules={[{
                      required: false, message: 'Missing Visit Reason', max: 140},{
                      message: "Value should be less than 140 characters",
                    }]}>
                    <Input autoComplete={'off'} placeholder="" />
                  </Form.Item>
                  {getInputField(prospectForm, medicalRecordField)}
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className='p-2' style={{ width: '100%' }}>
                <div className=''>
                  <Form.Item
                    name="face_sheets"
                    required={true}
                    className='border shadow rounded-xl'
                    rules={[
                      {
                        validator: (_, values) =>
                          faceSheets && faceSheets?.length > 0 ? Promise.resolve()
                            : Promise.reject(new Error('Please upload a facesheet.'))
                      },
                    ]}>
                    <div className='p-4 w-full h-auto'>
                      <div className='flex-boy-row-space' style={{ width: '100%' }}>
                        <Subtitle1>
                          {!activeUser?.facilityProfile?.pcc_enabled && <span className='text-red-500'>*{" "}</span>}
                          Facesheet</Subtitle1>
                        <PlusOutlined onClick={() => setFacesheetForm(true)} />
                      </div>
                      <div className='mt-2'>
                        {faceImages.length > 0 &&
                          <>
                            <ImageTag.PreviewGroup>
                              {faceImages.map((item, index) =>
                                <div className="flex-boy-row-space"
                                  style={{ width: '100%', maxHeight: 100, marginBottom: 10 }} key={index}>
                                  <ImageTag
                                    fallback={require('../../assets/doc.png')} height={100}
                                    src={item.url}>{item.name}
                                  </ImageTag>
                                  <div className=''>
                                    <span>{item.name}</span>
                                  </div>
                                  <FontAwesomeIcon onClick={() => removeImage(item, index)} color="grey" icon={["fas", "trash"]} />
                                </div>)}
                            </ImageTag.PreviewGroup>
                          </>}
                      </div>
                    </div>
                  </Form.Item>
                </div>
                {prospectForm.getFieldValue('referral_type') !== 'podiatry' &&
                  <div className='p-2 border shadow rounded-xl w-full h-auto mb-8'>
                    <div className='flex-boy-row-space' style={{ width: '100%' }}>
                      <Subtitle1>Wounds</Subtitle1>
                      <PlusOutlined onClick={() => setWoundForm(true)} />
                    </div>
                    <Form.Item style={{ width: '100%' }} name="number_of_Wounds" label="Number of Wounds" rules={[{ required: false, message: 'Missing Number of Wounds' }]}>
                      <Select onChange={(value) => setNoOfWounds(value)} style={{ width: '100%', margin: "0px" }} >
                        {GetNumericalListOptions(10)}
                      </Select>
                    </Form.Item>
                    
                    {uploadList?.map((wound, index) => {
                      // console.log({wound})
                      return (
                        <>
                          <div className="flex-boy-row-space" style={{ width: '100%' }}>
                            <Headline6>Wound Details</Headline6>
                            <KebabMenu
                              menus={actionMenus}
                              recordItem={wound}
                              handleMenuClick={kebabMenuClick}
                              handleOk={() => handleOk(wound.image_id)}
                              handleCancel={handleCancel}
                              showConfirmDialog={selectedWound === wound.image_id}
                              resource={"wound"}
                            />
                          </div>
                          <Row gutter={12} style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Col style={{ width: "100%" }}>
                              {keyValItemForReferral('Wound Location', wound.wound_location)}
                              {keyValItemGreyForReferral('Wound Side', wound.wound_side)}
                              {keyValItemForReferral('Wound Position', wound.wound_position)}
                              {keyValItemGreyForReferral('Wound Duration', wound.wound_duration)}
                              {/*{keyValItemForReferral('Wound Bed', wound?.wound_bed||'-')}*/}
                              {keyValItemGreyForReferral('Granulation Tissue(%)', wound?.granulation_tissue?.toString()||'-')}
                              {keyValItemForReferral('Necrotic Tissue(%)', wound?.necrotic_tissue?.toString()||'-')}
                              {keyValItemGreyForReferral('Fibrous Tissue(%)', wound?.fibrous_tissue?.toString()|| '-')}
                              {keyValItemForReferral('Onset of the wound', wound.original_date_of_wound ? dayjs(wound.original_date_of_wound).format('MM-DD-YYYY') : '-')}
                              {/* {keyValItem('Wound Type', wound.wound_type)} */}
                            </Col>
                          </Row>
                          <Row style={{ width: '100%' }}>
                            <Col span={12}>
                              {wound.image && wound.image.url &&
                                <div className="flex-boy-row-space" style={{ width: "100%" }}>
                                  <ImageTag src={wound?.image?.url} height={100} width={100} fallback={require('../../assets/pic.png')}/>
                                </div>
                              }
                            </Col>
                            <Col span={12}>
                              {keyValItemForReferral('Width', wound?.width ? wound?.width : '-')}
                              {keyValItemGreyForReferral('Length', wound?.length ? wound?.length : '-')}
                              {keyValItemForReferral('Area', wound?.area ? wound?.area : '-')}
                              {keyValItemGreyForReferral('Depth', wound?.depth ? wound?.depth : '-')}
                              {keyValItemForReferral('Volume', wound?.volume ? wound?.volume : '-')}
                            </Col>
                          </Row>
                          <Divider/>
                        </>
                      );
                    })}
                    {
                      uploadList.length < noOfWounds ?
                        <Body1 className="Validator" color="crimson">
                          The Referral has {Math.abs(uploadList.length - noOfWounds)} wound{Math.abs(uploadList.length - noOfWounds) > 1 ? "s" : ""} missing compared to the number selected in the "number of wounds" dropdown.
                        </Body1>
                        :
                        uploadList.length > noOfWounds ?
                          <Body1 className="Validator" color="crimson">
                            The Referral has {Math.abs(uploadList.length - noOfWounds)} extra wound{Math.abs(uploadList.length - noOfWounds) > 1 ? "s" : ""} compared to the number selected in the "number of wounds" dropdown.
                          </Body1>
                          : null
                    }
                  </div>
                }
                {prospectName.trim().length>0 &&<div className="p-2 border shadow rounded-xl w-full h-auto">
                  <div className="flex-boy-row-space" style={{ width: '100%' }}>
                    <Subtitle1>Insurance Information (Optional)</Subtitle1>
                    <PlusOutlined onClick={() => setInsuranceForm(true)}/>
                  </div>
                  <div className="mt-2">
                    {insuranceList.length > 0 && insuranceList.map(
                      (item, index) => {
                        return (
                          <div style={{ marginBottom: "7px" }}>
                            <InsuranceCard key={item?.id} insurance={item} removeInsuranceDetails={removeInsuranceDetails}
                                           setEditInsurance={setEditInsurance} setInitialInsuranceData={setInitialInsuranceData} setInsuranceForm={setInsuranceForm}/>
                          </div>
                        );
                      })}
                  </div>
                </div>}
                <div className='p-2 border shadow rounded-xl w-full h-auto mt-8'>
                  <div className='flex-boy-row-space' style={{ width: '100%' }}>
                    <Subtitle1>Documents{" "}(Insurance, Wound Progress Notes)</Subtitle1>
                    <PlusOutlined onClick={() => setDocumentForm(true)} />
                  </div>
                  {documentList.length > 0 && documentList.map((item, index) => (
                    <ProspectDocumentsView key={index} file={item} documentList={documentList} setDocumentList={setDocumentList} />
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  activeUser:
    state.auth?.activeUser, selectedFacility: state.facilities.selectedFacility,
  masterListRaw: state.masters.masterListRaw,
  masterList: state.masters,
  appConf: state.appConf,
  billingGroups: state.masters.billingGroups
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(NewReferralCard)
