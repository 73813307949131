import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { apiRequest } from '../../../app/Apis'
import { Subtitle1 } from '../../../components';
import dayjs from 'dayjs';
import { Table, Button, Form, Input, Space, AutoComplete, InputNumber, Divider, Popconfirm, Select } from 'antd';
import { cleanObject, executeChunkFn, getAssessmentStatus, getprogressStatus, makeAutoCompleteOptions, makeSelectOptions } from '../../../common/helpers';
import { MinusCircleOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import _, { orderBy } from 'lodash';
import { submitBillingClaims, submitSplitBillingClaims } from '../../../app/Reducers';
import { red } from '@mui/material/colors';
import {useProcedureSearch} from '../../../hooks/useProcedureSearch';





export const Claims = ({ tab, fetchAssessment, encounter, billingNavs }) => {
  const { Option } = Select;
  // progress status
  const [progressStatus, setProgressStatus] = useState(billingNavs.tab.toLowerCase())
  const dispatch = useDispatch()
  const [procedureOptions, debouncedProcedureSearch, procedureSearchLoading] = useProcedureSearch([], 'name')
  const onCell = (record, rowIndex) => {
    return {
      onClick: (event) => {
        // console.log('record', record)
        // console.log('rowIndex', rowIndex)
        // console.log('event', event)
        fetchAssessment(record.assessment_id)
      },
    };
  }
  const columns = [

    // date of service
    {
      title: 'Date of Service', dataIndex: 'date_of_service', key: 'date_of_service', fltr: { filterable: true, type: 'date' }, onCell,
      render: (date_of_service, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(date_of_service).format("MM/DD/YYYY")}</Subtitle1>)
    },
    // date created
    {
      title: 'Date Created', dataIndex: 'created_at', key: 'created_at', fltr: { filterable: true, type: 'date' }, onCell,
      render: (created_at, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(created_at).format("MM/DD/YYYY")}</Subtitle1>)
    },
    {
      title: 'Wound',
      dataIndex: 'wound',
    },
    {
      title: 'Procedure Type', dataIndex: 'procedure_type', key: 'procedure', fltr: {
        filterable: true, type: 'select',options:procedureOptions,
      props: {
        loading:procedureSearchLoading,
        onSearch: (value) => debouncedProcedureSearch(value), showSearch: true, filterOption: false
      }}, onCell
    },

    // assessment status
    {
      title: 'Assessment Status', dataIndex: 'assessment_status', key: 'reviewStatus', fltr: {
        filterable: false, type: 'autocomplete', options: makeAutoCompleteOptions(['rejected', 'approved', 'pending_review'])
      }, render: (assessment_status) => getAssessmentStatus(assessment_status?.toLowerCase())
    },
    {
      title: 'Progress Status',
      dataIndex: 'progress_status',
      render: (progress_status) => getprogressStatus(progress_status?.toLowerCase())

    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        return (
          record.group ?
            <div className="flex justify-center align-center w-full">
              <Popconfirm
                title="Delete the task"
                description="Are you sure to remove this claim from group?"
                onConfirm={() => removeClaimFromGroup(record)}

                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>

            </div> : null
        )
      }
    },
    Table.EXPAND_COLUMN,

  ];

  const [claims, setClaims] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)

  // call get claims function on page load
  useEffect(() => {
    getClaims()
  }, [tab])

  const [cptCharges, setCptCharges] = useState({});
  // claims loadind

  const getClaims = async () => {
    setLoading(true)

    const data = await apiRequest.get(`/claims?appointment=${encounter.id}&type=${tab}&progressStatus=${progressStatus}`).catch(err => console.log(err))
    if (data) {
      const fetchedClaims = data.data.data.map(claim => ({
        ...claim,
        cpt_charges: claim.cpt_charges.length ? claim.cpt_charges : [{ cpt_code: '', units: '', unit_charge: '', billed_amount: '', modifier: [], diagnosis_code: [] }]
      }));
      setClaims(fetchedClaims);

      // Initialize CPT charges state for each claim
      const initialCptCharges = {};
      fetchedClaims.forEach(claim => {
        initialCptCharges[claim.id] = claim.cpt_charges;
      });
      setCptCharges(initialCptCharges);
      setLoading(false)
    }
  }
  //   selected row keys
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys, onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };



  // list of group names
  const [groupNames, setGroupNames] = useState([])

  // grrouping claims
  const groupClaims = () => {
    console.log('grouping claims')
    // generate uniqie group name
    const name = `Group-${Math.floor(Math.random() * 1000000)}`
    // get selected claims
    const selectedClaims = claims.filter(claim => selectedRowKeys.includes(claim.id))
    // add name as group key value to each of the selected claims
    selectedClaims.forEach(claim => claim.group = name)
    // add name to group names
    setGroupNames([...groupNames, name])
    // update claims
    setClaims([...claims])
    setSelectedRowKeys([])


  }

  // ungrouping claims
  const ungroupClaims = (name) => {
    console.log('ungrouping claims')
    // get selected claims
    const selectedClaims = claims.filter(claim => claim.group === name)
    // remove group key value from each of the selected claims
    selectedClaims.forEach(claim => claim.group = null)
    // remove name from group names
    setGroupNames(groupNames.filter(groupName => groupName !== name))
    // update claims
    setClaims([...claims])
    setSelectedRowKeys([])
  }
  // Function to ensure all fields in a CPT unit_charge are present
  const normalizeCptCharge = (unit_charge) => {
    const defaultCharge = {
      cpt_code: '',
      units: '',
      unit_charge: '',
      billed_amount: '',
      modifier: [],
      diagnosis_code: []
    };

    return { ...defaultCharge, ...unit_charge };
  };

  // Function to handle form changes
  const handleFormChange = (changedValues, allValues) => {
    console.log('changedValues',
      form.getFieldsValue())
    const changedClaimId = Object.keys(changedValues)[0];
    const currentCptCharges = cptCharges[changedClaimId] || [];
    const updatedCptCharges = allValues[changedClaimId].cpt_charges.map((unit_charge, index) => {
      // Normalize the unit_charge
      const normalizedCharge = normalizeCptCharge(unit_charge);

      if (normalizedCharge === null) {
        return currentCptCharges[index];
      }
      return { ...currentCptCharges[index], ...normalizedCharge };
    }).filter(unit_charge => unit_charge !== null);

    setCptCharges({
      ...cptCharges,
      [changedClaimId]: updatedCptCharges
    });

  };

  const onFinish = async (values) => {
    console.log('values', values)
    console.log('cptCharges', cptCharges)
    // add cpt_charges to values to claims based on claim id

    const ungroupClaimsValues = claims.map(claim => {
      const cpt_charges = values[claim.id]?.cpt_charges
      // omit nil values
      let newClaim = _.omitBy(claim, _.isNil)
      return {
        ...newClaim,
        progress_status: 'ready for submission',
        cpt_charges
      }
    })
    console.log('ungroupClaimsValues', ungroupClaimsValues)
    let payload = {
      claims: ungroupClaimsValues
    }
    await executeChunkFn(dispatch, submitSplitBillingClaims, cleanObject(payload), setIsExecuting, null, async () => {
      getClaims()
    })
  }

  // isExecuting
  const [isExecuting, setIsExecuting] = useState(false)
  const [cptCodes, setCptCodes] = useState([])

  const searchcptcodes = async (value) => {
    if (value.length > 1) {
      const response = await apiRequest.get(`/cpt-code?search=${value}`).catch(function (err) {
        console.log(err.response.data.message);
      })
      if (response && response.data) {
        setCptCodes(response.data.data)
      }
    }
  }



  // search diagnosis codes
  const [diagnosisCodes, setDiagnosisCodes] = useState([])

  const searchdiagnosiscodes = async (value) => {
    if (value.length > 1) {
      const response = await apiRequest.get(`/dx-code?search=${value}`).catch(function (err) {
        console.log(err.response.data.message);
      })
      if (response && response.data) {
        setDiagnosisCodes(response.data.data)
      }
    }
  }

  // option list
  const optionsList = cptCodes?.map((item, index) => {
    return (<AutoComplete.Option key={index} value={`${item.cpt}`}>{`${item?.cpt}-${item.description}`.replace("-null", "")}</AutoComplete.Option>)
  })

  // dx-options list
  const dxOptionsList = diagnosisCodes?.map((item, index) => {
    return (<Option key={index} value={`${item.diagnosis}`}>{`${item?.diagnosis}-${item.description}`.replace("-null", "")}</Option>)
  })

  // form useForm
  const [form] = Form.useForm();


  // remove claim from group
  const removeClaimFromGroup = (claim) => {
    claim.group = null
    claim.split = null
    setClaims([...claims])
  }


  // add claims to existing group
  const addClaimsToGroup = (group) => {
    // get selected claims
    const selectedClaims = claims.filter(claim => selectedRowKeys.includes(claim.id))
    // add name as group key value to each of the selected claims
    selectedClaims.forEach(claim => claim.group = group)

    // update claims
    setClaims([...claims])
    setSelectedRowKeys([])
  }


  const [totalBilledAmounts, setTotalBilledAmounts] = useState({});

  // Function to calculate total billed amount for a claim
  const calculateTotalBilledAmount = (charges) => {
    return charges.reduce((total, charge) => total + charge.units * charge.unit_charge, 0);
  };

  // useEffect to re-calculate total billed amounts whenever cptCharges changes
  useEffect(() => {
    redoTotals()
  }, [cptCharges]);

  const redoTotals = () => {
    const newTotals = {};
    for (const claimId in cptCharges) {
      newTotals[claimId] = calculateTotalBilledAmount(cptCharges[claimId]);
    }
    setTotalBilledAmounts(newTotals);
  }

  // Rendering total billed amount in UI
  const renderTotalBilledAmount = (claimId) => {
    // format total billed amount to currency
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    let totalBilled = formatter.format(totalBilledAmounts[claimId]);
    // if billed amount is greater than 1000000, show warning in red
    if (totalBilledAmounts[claimId] > 100000) {
      return <div className="text-red-500">Total Billed Amount: {totalBilled || 0}  </div>;
    }
    return <div>Total Billed Amount: {totalBilled || 0}   </div>;
  };


  // Function to render the total billed amount for all claims in a group
  const renderGroupTotalBilledAmount = (groupName) => {
    const groupClaims = claims.filter(claim => claim.group === groupName);
    let totalBilledAmount = groupClaims.reduce((total, claim) => {
      return total + (totalBilledAmounts[claim.id] || 0);
    }, 0);
    // format total billed amount to currency
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    totalBilledAmount = formatter.format(totalBilledAmount);

    // if billed amount is greater than 1000000, show warning in red
    if (totalBilledAmount > 100000) {
      return <div className="text-red-500">Total Billed Amount: {totalBilledAmount} exeeds recommended 100,000 $</div>;
    }
    return <div>Total Billed Amount: {totalBilledAmount}</div>;
  };


  return (
    <div className="p-2">
      <Form layout="vertical" className="mb-3"
        initialValues={{ ...claims }}
        onValuesChange={handleFormChange}
        // onFieldsChange={(_, allFields) => {
        //   console.log('allFields', allFields)
        //   // reset charges

        //        }}
        onFinish={onFinish}
        form={form}
        disabled={billingNavs.tab === 'Ready for submission'}
      >

        {claims && <Table
          // rowSelection={{
          //   type: 'checkbox',
          //   ...rowSelection,
          // }}
          pagination={false}
          loading={loading}
          expandable={{

            expandedRowRender: (record) => (
              <>
                <Form.List
                  key={record.id}
                  name={[record.id, 'cpt_charges']}
                  initialValue={cptCharges[record.id]}
                  // Dynamically handle changes for each claim's CPT charges
                  onValuesChange={changedValues => {
                    console.log('changedValues', changedValues)
                  }}
                  onFieldsChange={(_, allFields) => {
                    console.log('allFields', allFields)
                    setCptCharges({
                      ...cptCharges,
                      [record.id]: allFields.map(field => field.name[1])
                    });
                  }}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                        <div
                         className='shadow-md p-2 rounded-md m-2'
                        >
                          <div
                           
                          key={index}
                          style={{
                            display: 'flex',
                            marginBottom: 8,
                            width: '100%',
                           
                          }}
                          align="center"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'cpt_code']}
                            fieldKey={[fieldKey, 'cpt_code']}
                            label="CPT Code"
                            style={{ flex: 2 }}
                            rules={[{ required: true, message: 'Missing cpt code' }]}
                          >
                            <AutoComplete
                              placeholder="CPT code"
                              style={{ width: '100%' }}
                              onSearch={searchcptcodes}
                              onSelect={(value) => {
                                const selectedOption = cptCodes.find(item => item.cpt === value);
                                if (selectedOption) {
                                  console.log(fieldKey, 'selectedOption', selectedOption)
                                  // Create a path to the 'unit_charge' field of this item
                                  const chargePath = [record.id, 'cpt_charges', index, 'unit_charge'];

                                  // Update the unit_charge in the form

                                  form.setFieldValue(chargePath, selectedOption.charges)
                                  //  update ammount if units is present
                                  const units = form.getFieldValue([record.id, 'cpt_charges', index, 'units']);
                                  if (units) {
                                    form.setFieldValue([record.id, 'cpt_charges', index, 'billed_amount'], selectedOption.charges * units)
                                  }

                                }
                              }}
                            >
                              {cptCodes.length > 0 && optionsList}
                            </AutoComplete>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            style={{ flex: 1 }}
                            label="Unit"
                            name={[name, 'units']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing units',
                              },
                            ]}
                          >
                            <InputNumber style={{ width: '100%' }} placeholder="Units"
                              onChange={(value) => {

                                const amountPath = [record.id, 'cpt_charges', index, 'billed_amount'];
                                // units * unit_charge

                                const unit_charge = form.getFieldValue([record.id, 'cpt_charges', index, 'unit_charge']);
                                if (unit_charge) {
                                  form.setFieldValue(amountPath, unit_charge * value)

                                }
                              }}
                            />

                          </Form.Item>
                          <Form.Item
                            style={{ flex: 1 }}
                            {...restField}
                            label="Unit Charge"
                            name={[name, 'unit_charge']}
                            fieldKey={[fieldKey, 'unit_charge']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing unit_charge',
                              },
                            ]}
                          >
                            <InputNumber
                              disabled
                              style={{ width: '100%' }}
                              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/\$\s?|(,*)/g, '')}
                              onChange={(value) => {
                                console.log(fieldKey, 'value', value)
                                const amountPath = [record.id, 'cpt_charges', index, 'billed_amount'];
                                // units * unit_charge

                                const units = form.getFieldValue([record.id, 'cpt_charges', index, 'units']);
                                console.log(fieldKey, 'units', units)
                                if (units) {
                                  form.setFieldValue(amountPath, units * value)
                                }
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            style={{ flex: 1 }}
                            {...restField}

                            label="Billed Amount"
                            name={[name, 'billed_amount']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing billed_amount',
                              },
                            ]}
                          >
                            <InputNumber disabled style={{ width: '100%' }} placeholder="Amount" />
                          </Form.Item>
                          </div>
                          <div className='w-full flex '>
                          <Form.Item
                            style={{ flex: 2 }}
                            {...restField}
                            label="Modifier"

                            name={[name, 'modifier']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing modifier',
                              },
                            ]}
                          >
                            <Select
    mode="tags"
    style={{
      width: '100%',
    }}
    placeholder="Type in modifier, press enter to add"
    
    options={[]}
  />
                          </Form.Item>
                          <Form.Item
                            style={{ flex: 2 }}
                            {...restField}
                            label="Diagnosis Code"
                            name={[name, 'diagnosis_code']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing diagnosis code',
                              },
                            ]}
                          >
                            <Select
                              mode='multiple'
                              placeholder="Dx code"
                              style={{ width:  '100%' }}
                              onSearch={(value) => {
                                searchdiagnosiscodes(value)
                              }}
                            >
                              {diagnosisCodes.length > 0 && dxOptionsList}
                            </Select>
                          </Form.Item>
                              </div>
                          <MinusCircleOutlined onClick={() => remove(name)} />

                       
                 </div>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>

                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                {renderTotalBilledAmount(record.id)}
              </>
            ),
          }}
          title={() => <div className="d-flex justify-content-between">
            {selectedRowKeys.length > 0 && <div className="flex-boy-row-space" style={{ backgroundColor: 'teal', marginTop: '10px' }}>
              <Subtitle1 color="white">Selected: {selectedRowKeys.length}</Subtitle1>
              <div className={'flex flex-row gap-2'}>
                <Button className="btn btn-primary" onClick={groupClaims}>Group Selected</Button>
                {/* Select from list of existing group names */}
                {groupNames.length > 0 && <div className="flex flex-row gap-2 items-center">
                  <Subtitle1 style={{ width: '160px' }} color="white">Add to group</Subtitle1>
                  <Select
                    placeholder="Group name"
                    style={{ width: '100%' }}

                    onSelect={(value) => {
                      addClaimsToGroup(value)
                    }}
                  >
                    {groupNames.map((name, index) => {
                      return <Select.Option key={index} value={name}>{name}</Select.Option>
                    })}
                  </Select>
                </div>}

              </div>
            </div>}
          </div>}
          rowKey={'id'}
          columns={columns}
          dataSource={claims.filter(claim => !claim.group)}
        />}

        {groupNames.map(name => {
          const groupClaims = claims.filter(claim => claim.group === name)
          return (
            <div className="mt-3" key={name}>
              <div className="flex flex-row justify-between">
                <Subtitle1 color="teal"> {name}</Subtitle1>
                <div className="flex flex-row gap-2">
                  <Button danger type="text" className="btn btn-primary" onClick={() => ungroupClaims(name)}>Ungroup</Button>

                </div>
              </div>
              <Table
                loading={loading}
                pagination={false}
                expandable={{

                  expandedRowRender: (record) => (
                    <Form.List
                      key={record.id}
                      name={[record.id, 'cpt_charges']}
                      initialValue={cptCharges[record.id]}
                      // Dynamically handle changes for each claim's CPT charges
                      onFieldsChange={(_, allFields) => {
                        console.log('allFields', allFields)
                        setCptCharges({
                          ...cptCharges,
                          [record.id]: allFields.map(field => field.name[1])
                        });
                      }}
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                            <>
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                marginBottom: 8,
                                width: '100%'
                              }}
                              align="center"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, 'cpt_code']}
                                fieldKey={[fieldKey, 'cpt_code']}
                                label="CPT Code"
                                style={{ flex: 2 }}
                                rules={[{ required: true, message: 'Missing cpt code' }]}
                              >
                                <AutoComplete
                                  placeholder="CPT code"
                                  style={{ width: '100%' }}
                                  onSearch={searchcptcodes}
                                  onSelect={(value) => {
                                    const selectedOption = cptCodes.find(item => item.cpt === value);
                                    if (selectedOption) {
                                      console.log(fieldKey, 'selectedOption', selectedOption)
                                      // Create a path to the 'unit_charge' field of this item
                                      const chargePath = [record.id, 'cpt_charges', index, 'unit_charge'];

                                      // Update the unit_charge in the form

                                      form.setFieldValue(chargePath, selectedOption.charges)
                                      //  update ammount if units is present
                                      const units = form.getFieldValue([record.id, 'cpt_charges', index, 'units']);
                                      if (units) {
                                        form.setFieldValue([record.id, 'cpt_charges', index, 'billed_amount'], selectedOption.charges * units)
                                      }

                                    }
                                  }}
                                >
                                  {cptCodes.length > 0 && optionsList}
                                </AutoComplete>
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                style={{ flex: 1 }}
                                label="Unit"
                                name={[name, 'units']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing units',
                                  },
                                ]}
                              >
                                <InputNumber style={{ width: '100%' }} placeholder="Units"
                                  onChange={(value) => {

                                    const amountPath = [record.id, 'cpt_charges', index, 'billed_amount'];
                                    // units * unit_charge

                                    const unit_charge = form.getFieldValue([record.id, 'cpt_charges', index, 'unit_charge']);
                                    if (unit_charge) {
                                      form.setFieldValue(amountPath, unit_charge * value)
                                    }
                                  }}
                                />

                              </Form.Item>
                              <Form.Item
                                style={{ flex: 1 }}
                                {...restField}
                                label="Unit Charge"
                                name={[name, 'unit_charge']}
                                fieldKey={[fieldKey, 'unit_charge']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing unit_charge',
                                  },
                                ]}
                              >
                                <InputNumber
                                  disabled
                                  style={{ width: '100%' }}
                                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                  onChange={(value) => {
                                    console.log(fieldKey, 'value', value)
                                    const amountPath = [record.id, 'cpt_charges', index, 'billed_amount'];
                                    // units * unit_charge

                                    const units = form.getFieldValue([record.id, 'cpt_charges', index, 'units']);
                                    console.log(fieldKey, 'units', units)
                                    if (units) {
                                      form.setFieldValue(amountPath, units * value)
                                    }
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                style={{ flex: 1 }}
                                {...restField}

                                label="Billed Amount"
                                name={[name, 'billed_amount']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing billed_amount',
                                  },
                                ]}
                              >
                                <InputNumber disabled style={{ width: '100%' }} placeholder="Amount" />
                              </Form.Item>
                              </div>
                            <div>
                              <Form.Item
                                style={{ flex: 2 }}
                                {...restField}
                                label="Modifier"

                                name={[name, 'modifier']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing modifier',
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                               <Form.Item
                            style={{ flex: 2 }}
                            {...restField}
                            label="Diagnosis Code"
                            name={[name, 'diagnosis_code']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing diagnosis code',
                              },
                            ]}
                          >
                            <Select
                              mode='multiple'
                              placeholder="Dx code"
                              style={{ width:  '100%' }}
                              onSearch={(value) => {
                                searchdiagnosiscodes(value)
                              }}
                            >
                              {diagnosisCodes.length > 0 && dxOptionsList}
                            </Select>
                          </Form.Item>

                              <MinusCircleOutlined onClick={() => remove(name)} />

                              </div>
                              </>
                          ))}
                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>

                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  ),
                }}
                rowKey={'id'}
                columns={columns}
                dataSource={groupClaims}
              />
              {renderGroupTotalBilledAmount(name)}
            </div>
          )
        }
        )}

        <div className="w-full flex justify-end">
          {/* submit button */}
          {billingNavs.tab === 'Ready for submission' ?
            null :
            <Button type="primary" htmlType="submit" >
              Submit
            </Button>}
        </div>
      </Form>
    </div>
  )
}

const mapStateToProps = (state) => ({

  encounter: state.billing.selectedEncounter,
  masterListRaw: state.masters.masterListRaw, activeUser: state.auth.activeUser,
  billingNavs: state.navs.billingNavs
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Claims)