import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import HeaderTop from '../../../components/HeaderTop'
import {Grid, Tabs} from 'antd';
import {fetchOrganisationById, fetchOrganizationPermissions, setOrgNavs} from '../../../app/Reducers'
import {emptyFun, getHeaderLeftNav} from "../../../common/helpers";
import { useLocation, useNavigate} from "react-router-dom";
import OrganisationInfoPage from './OrganisationInfoPage';
import OrganisationFacilitiesList from './OrganisationFacilitiesList';
import OrganisationUsers from './OrganisationUsers';
import OrganisationPermGroups from './OrganisationPermGroups';

const TabList = ['Facilities', "Users", 'Permission Groups']
const { TabPane } = Tabs;
const { useBreakpoint } = Grid;
export const OrganisationDetailsPage = ({ organisation, activeUser, navs, masterList }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState = useBreakpoint();
  const myRef = useRef(null);
  const dispatch = useDispatch()
  const organisationId = Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [activeTab, setActiveTab] = useState(navs.orgNavs);
  const [isVisible, setIsVisible] = useState(false);
  //implement loading of organisation data in case this is a navigation from the roue map
  useEffect(() => {
    //console.log({organisationId, organisation})
    if (organisationId !== organisation?.id) {
      dispatch(fetchOrganisationById(organisationId))
    }
    return emptyFun
  }, [])
  
  const changTab = async (tab) => {
    console.log('changTab:  ', tab)
    setActiveTab(tab)
    dispatch(setOrgNavs(tab))
  }
  
  
  return (
    (<div className="main-page flex flex-col">
      <HeaderTop child={getHeaderLeftNav(navigate, 'Organisation Details')}/>
      <div className="grid grid-cols-5  flex-1 bg-gray-200">
        <div className="col-span-1 bg-gray-200  sticky">
          <OrganisationInfoPage/>
        </div>
        <div className="col-span-4 bg-slate-200 h-full   ">
          {!!organisation && <>
            <div id="patient-encounter-tabs" className="page-header  bg-white">
              <Tabs type="card" defaultActiveKey={navs.orgNavs} activeKey={activeTab} onChange={changTab} tabBarStyle={{ marginBottom: 0 }}>
                {TabList.map(item => <TabPane key={item} tab={<p>{item}</p>}> </TabPane>)}
              </Tabs>
            
            </div>
            
            {navs.orgNavs && <div ref={myRef} className="main-body py-[4px]" style={{ height: 'calc(100vh - 150px)', overflowY: 'scroll' }}>
              {navs.orgNavs === "Facilities" ? <OrganisationFacilitiesList/> :
                navs.orgNavs === 'Users' ? <OrganisationUsers/> :
                  navs.orgNavs === 'Permission Groups' ? <OrganisationPermGroups/> : null}
            </div>}
          </>}
        </div>
      </div>
    </div>)
  );
}

const mapStateToProps = (state) => ({
  organisation: state.organisations.selectedOrganisation,
  organizationPermissions: state.organisations.organizationPermissions,
  activeUser: state.auth?.activeUser,
  navs: state.navs,
  masterList: state.masters.masterListRaw
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(OrganisationDetailsPage)