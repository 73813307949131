/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiRequest, apiRequestBlob } from "../Apis"
import { newResourceArray, updatedArrayState } from "../../common/helpers";
import { fetchPatientOrders } from "./PatientSlice";
import { errMsg, getToastId, toastUp, updateToast } from "./reducerUtils";
import { orders } from "./initialState";
import download from "downloadjs";
import dayjs from 'dayjs';
import ReactGA from "react-ga4";

const initialState = { ...orders }
export const fetchOrders = createAsyncThunk('/orders', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let visitType = requestParams?.['filterData']?.visitType
      let orderState = requestParams?.['filterData']?.orderState
      let pagination = requestParams?.pagination ? requestParams.pagination : orderState === 'pre-order' ? state.orders.ordersPagination :
          visitType === 'Biologics' ? state.orders.bioOrdersPagination :
              visitType === 'DME' ? state.orders.dmeOrdersPagination :
                  visitType === 'Internal' ? state.orders.internalOrdersPagination :
                      visitType === 'final' ? state.orders.finalOrdersPagination :
                          orderState === 'order-history' ? state.orders.historyOrdersPagination :
                              state.orders.ordersPagination
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const response = await apiRequest.get(`/treatment-orders`, { params })
      let data = response.data.data.map(item => ({ ...item, latlng: [item.lng, item.lat] }))
      let paginationName = orderState === 'pre-order' ? 'ordersPagination' :
          orderState === 'final' ? 'finalOrdersPagination' :
              visitType === 'Biologics' ? 'bioOrdersPagination' :
                  visitType === 'DME' ? 'dmeOrdersPagination' :
                      visitType === 'Internal' ? 'internalOrdersPagination' :
                          visitType === 'final' ? 'finalOrdersPagination' :
                              orderState === 'order-history' ? 'historyOrdersPagination' :
                                  'ordersPagination'
      const payload = { orders: data, [paginationName]: { ...pagination, total: response.data.meta.total } }
      resolve(payload)
    } catch (e) {
      console.log(e)
      reject(e)
      toastUp(errMsg(e, true, 'orders'), false)
    }
  })
})


export const downloadOrders = createAsyncThunk('/download-orders', async (requestParams, thunkAPI) => {

  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.orders.ordersPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const response = await apiRequestBlob.get(`/treatment-orders-export`,{params})
      download(response.data, `orders-${dayjs().format("YYYY-MMM-DD hh:mm")}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      reject(e)
      toastUp(errMsg(e, true, 'orders'), false)
    }
  })
})
export const fetchOrderDaetails = createAsyncThunk('/orderdetails', async (order, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const availabilityDateTime = await apiRequest.get(`/order-availability-datetimes?order=${order.id}&available=yes`)
      // const availabilityShchedule = await apiRequest.get(`/order-availability-schedules?order=${order.id}`)
      // const ordervehicles = await apiRequest.get(`/orders/${order.id}/vehicles`)
      // console.log(availabilityDateTime, availabilityShchedule)
      resolve(availabilityDateTime)
    } catch (e) {
      toastUp(errMsg(e, true, 'order details'), false)
      reject(e)
    }
  })
})
export const fetchSpecificOrder = createAsyncThunk('/specific-orders', async (order, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/treatment-orders/${order}`)
      // const assessments = await apiRequest.get(`/assessments?order=${order}`)
      let data = response.data.data
      // data.previous_assessments = assessments.data.data
      // data.wounds = data.wounds || []
      resolve(data)
    } catch (e) {
      toastUp(errMsg(e, true, 'order'), false)
      reject(e)
    }
  })
})
export const createOrder = createAsyncThunk('/fetchorders', async (order, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Creating Order")
    try {
      const response = await apiRequest.post('/treatment-orders', order)
      updateToast(toastId, "Order created", true)
      // await thunkAPI.dispatch(fetchOrders())
      //GA4 event order_created
      ReactGA.event({
        category: "order_created",
        action: "order_created",
        label: "order_created",
        value: 1,
      });
      resolve(response)
    } catch (e) {
      //console.log(e)
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})

export const updateOrder = createAsyncThunk('/updateorders', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Updating Order")
    try {
      console.log({payload})
      const response = await apiRequest.patch(`/treatment-orders/${payload.id}`, payload)
      if (payload.patient_id) {
       await fetchPatientOrders(payload.patient_id)
      } else {
        await thunkAPI.dispatch(fetchOrders())
        await thunkAPI.dispatch(fetchSpecificOrder(payload.id))
      }
      resolve(response?.data?.data)
      updateToast(toastId, "Order updated", true)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})
export const updateTreatmentOrderStatus = createAsyncThunk('updateTreatmentOrderStatus', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Updating Order")
    try {
      console.log({payload})
      const {id,...rest}=payload
      const response = await apiRequest.post(`/treatment-orders-status-update/${id}`, rest)
      await thunkAPI.dispatch(fetchOrders())
      await thunkAPI.dispatch(fetchSpecificOrder(id))
      resolve(response?.data?.data)
      updateToast(toastId, "Order updated", true)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})

export const bulkUpdateOrderStatus = createAsyncThunk('/bulkupdateorderstatuses', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Updating Orders")
    try {
      const response = await apiRequest.post(`/treatment-orders-status-update`, payload.payload)

      if (payload.patient_id) {
        fetchPatientOrders(payload.patient_id)
      } else {
        await thunkAPI.dispatch(fetchOrders(payload.filters))
      }
      updateToast(toastId, "Orders updated", true)
      resolve(response)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})

export const fetchOrderNotes = createAsyncThunk('/orderdetails/notes', async (requestParams, thunkAPI) => {
  //console.log(requestParams)
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.orders.notesPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : { treatementOrder: requestParams.id }
      params.page = pagination.current
      const response = await apiRequest.get(`/medical_facility-treatment_orders/note`, { params })
      const payload = {
        orderNotes: response.data.data.map(item => ({ ...item })),
        pagination: params.pagination ? { ...params.pagination, total: response.data.meta.total } : { ...state.orders.notesPagination, total: response.data.meta.total }
      }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'order  notes '), false)
      reject(e)
    }
  })
})
export const createOrderNote = createAsyncThunk('createOrderNote', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Adding Internal Note")
    try {
      const response = await apiRequest.post(`/medical_facility-treatment_orders/note`, payload)
      // const response = await apiRequest.post(`/progress-notes`, payload)
      let parm = { id: payload.treatment_order_id }
      await thunkAPI.dispatch(fetchOrderNotes(parm))
      updateToast(toastId, "Added successfully", true)
      resolve(response)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})
export const deleteOrderNote = createAsyncThunk('deleteOrderNote', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      //console.log(payload)
      const response = await apiRequest.delete(`/medical_facility-treatment_orders/note/${payload.note_id}`)
      // const response = await apiRequest.delete(`/progress-notes/${payload.note_id}`)
      let parm = { id: payload.order.id }
      await thunkAPI.dispatch(fetchOrderNotes(parm));
      toastUp("Deleted successfully", true)
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const updateOrderNote = createAsyncThunk('updateOrderNote', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/medical_facility-treatment_orders/note/${payload.note_id}`, payload.data)
      let parm = { id: payload.data.patient_id }
      await thunkAPI.dispatch(fetchOrderNotes(parm));
      toastUp("Updated successfully", true)
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})

export const fetchOrderTracking = createAsyncThunk('fetchOrderTracking', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/treatment-orders/${requestParams}/tracking`)
      // console.log("Resolving Tracking:::", response.data?.data)
      resolve(response.data?.data)
    } catch (e) {
      console.log("Tracking Information Missing:::", e)
      reject(e)
    }
  })
})

export const OrderSlice = createSlice({
  name: 'orders', initialState, // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    newOrder: (state, action) => {
      let newState = newResourceArray(state.orders, action)
      state.orders = [...newState]
    }, updateOrders: (state, action) => {
      state.orders = action.payload
    }, newAssessment: (state, action) => {
      let newState = newResourceArray(state.encounters, action)
      state.encounters = [...newState]
    }, assessmentUpdated: (state, action) => {
      let newState = updatedArrayState(state.encounters, action, 'id')
      state.encounters = [...newState]
    },
    selectOrder: (state, action) => {
      state.selectedOrder = action.payload;
    },
    canUpdateOrderStatus: (state, action) => {
      state.can_update_order_status = action.payload;
    }, setpagination: (state, action) => {
      state.pagination = action.payload
    }, setFormLoadind: (state, action) => {
      state.assessment_form_loading = action.payload
    }, resetOrderModuleState: (state, action) => {
      let localState = { ...initialState }
      delete localState.orders
      delete localState.encounters
      delete localState.drafts
      delete localState.procedures
      delete localState.scheduledOrders
      Object.keys(localState).map(key => state[key] = localState[key])
    }, clearOrderModuleState: (state, action) => {
      Object.keys(initialState).map(key => state[key] = initialState[key])
    }, resetOrdersPagination: (state, action) => {
      state.ordersPagination = initialState.ordersPagination
    }, resetBioOrdersPagination: (state, action) => {
      state.bioOrdersPagination = initialState.bioOrdersPagination
    }, resetDmeOrdersPagination: (state, action) => {
      state.dmeOrdersPagination = initialState.dmeOrdersPagination
    }, resetInternalOrdersPagination: (state, action) => {
      state.internalOrdersPagination = initialState.internalOrdersPagination
    }, resetFinalOrdersPagination: (state, action) => {
      state.finalOrdersPagination = initialState.finalOrdersPagination
    }, resetHistoryOrdersPagination: (state, action) => {
      state.historyOrdersPagination = initialState.historyOrdersPagination
    },
    doResetOrderSlice: (state, action) => {
      Object.keys(initialState).map((key) => (state[key] = initialState[key]));
    },
  }, extraReducers: (builder)=>{
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      //console.log('action.payload: ', action.payload)
      Object.keys(action.payload).forEach(key => state[key] = action.payload[key])
      // state.orders = [...action.payload.data]
      // state.ordersPagination = action.payload.pagination
    }).addCase(fetchOrderNotes.fulfilled, (state, action) => {
      state.orderNotes = [...action.payload.orderNotes]
      state.notesPagination = action.payload.pagination
    }).addCase(fetchSpecificOrder.fulfilled, (state, action) => {
      state.selectedOrder = action.payload
    }).addCase(updateOrder.fulfilled, (state, action) => {
      state.selectedOrder = action.payload
    }).addCase(fetchOrderTracking.pending, (state, action) => {
      state.orderTrackingLoading = true
    }).addCase(fetchOrderTracking.fulfilled, (state, action) => {
      state.orderTracking = { ...action.payload }
      state.orderTrackingLoading = false
    }).addCase(fetchOrderTracking.rejected, (state, action) => {
      state.orderTracking = null
      state.orderTrackingLoading = false
    })
  }
});
export const {doResetOrderSlice,
  selectOrder,canUpdateOrderStatus,
  updateOrders, resetBioOrdersPagination, resetOrdersPagination, resetDmeOrdersPagination, resetInternalOrdersPagination, resetHistoryOrdersPagination, resetFinalOrdersPagination
} = OrderSlice.actions
export default OrderSlice.reducer
