import React, {useState} from 'react';
import {Body2, Headline6, Subtitle1} from "../../../components";
import {connect} from "react-redux";
import KebabMenu from "../../../components/KebabMenu";
import {dialogCloser, kebabMenus} from "../../../common/helpers";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';

const ConsultantLocationPane = ({ setlocationsModal,  consultant, activeUser }) => {
  const [showAuditDialog, setShowAuditDialog] = useState(false);
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_consultant_global = usePermissionCheck('update_consultant_global', false, [])
  const actionMenus = kebabMenus((can_update_consultant_global || consultant.user_id === activeUser.id), can_view_audit_logs, false)
  
  async function kebabMenuClick(e, address) {
    console.log(actionMenus[e.key].title)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setlocationsModal(true)
        break
      case 'Delete':
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
    }
  }
  
  return (
    <div className="card-box">
      {(showAuditDialog) && <AuditLogViewer visible={showAuditDialog} resourceType={'Consultant'}
                                            onCancel={dialogCloser(setShowAuditDialog)} loading={false} resourceDescription={`${consultant.name}'s location`}
                                            defaultValues={{ auditableId: consultant?.id, auditable: 'consultant_location', format: 'json' }}/>}
      <div className="flex-boy-row-space" style={{ width: "100%" }}>
        <Headline6>Locations</Headline6>
        {/*<IconButton type={"button"} size={"small"} onClick={() => setlocationsModal(true)}> <EditOutlined
          fontSize={"small"}/>
        </IconButton>*/}
        <KebabMenu menus={actionMenus}
                   recordItem={null}
                   handleMenuClick={kebabMenuClick} resource={'consultant location'} handleOk={null} handleCancel={null} confirmLoading={null}
                   showConfirmDialog={false}/>
      </div>
      <div style={{ width: "100%" }}>
        {consultant.territories && consultant.territories.length ? consultant.territories.map((item, i) => {
            return (
              <div className="flex-boy-row-space" style={{ width: "100%", alignItems: "normal" }} key={i}>
                <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>{item.default_region ? "Default": "Additional"}:</Subtitle1>
                <div style={{ flex: 1 }}>
                  <Body2 color="black" style={{ textAlign: 'left', flex: 1 }}>{item.zone_name}</Body2>
                </div>
              </div>
            )
          }) :
          <div className="flex-boy-row-space" style={{ width: "100%", alignItems: "normal" }}>
            <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Default:</Subtitle1>
            <div style={{ flex: 1 }}>
              <Body2 color="black" style={{
                textAlign: 'left',
                flex: 1
              }}>{consultant.city}, {consultant.state}, {consultant.region}</Body2>
            </div>
          </div>}
      </div>
    </div>
  );
};


const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser,
  consultant: state.consultants.selectedConsultant,
  appointments: state.consultants.consultantSchedule
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ConsultantLocationPane)
