import React, {useState} from 'react';
import {Form, Image, Modal, Row} from "antd";
import {Body1, Subtitle1} from "../../components";
import KebabMenu from "../../components/KebabMenu";
import dayjs from "dayjs";
import {addButton, containedButton, dialogCloser, emptyDocuments, formSubmitButton, horStack, kebabMenus, modalClose, outlinedButton} from "../../common/helpers";

import DocForm from "./components/DocForm";
import {errMsg, getToastId, updateToast} from "../../app/Reducers/reducerUtils";
import {apiRequest} from "../../app/Apis";
import {fetchFacilityById, updateFacilityDetails} from "../../app/Reducers";
import {toast} from "react-toastify";
import {connect, useDispatch} from "react-redux";
import PdfViewer from "../../components/PdfViewer";
import AuditLogViewer from "../../components/AuditLogs/AuditLogViewer";
import * as Sentry from "@sentry/react";
import GetCookie from '../../hooks/GetCookie';
import {usePermissionCheck} from '../../hooks/usePermissionCheck';
import {PlusOutlined} from '@ant-design/icons';

const FacilityDocument = ({facility,  activeUser}) => {
  const dispatch = useDispatch();
  const can_update_medical_facility_admin = usePermissionCheck('update_medical_facility_admin', false, [])
  const [faceForm] = Form.useForm();
  const [editingForm, setEditingForm] = useState(false);
  const [faceSheetForm, setFaceSheetForm] = useState(false);
  const [doc, setDoc] = useState(null);
  const [faceImages, setFaceImages] = useState([]);
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const [faceSheets, setFaceSheets] = useState([]);
  const uploadUrl = process.env.REACT_APP_ENDPOINT + '/files';
  const token = GetCookie('accessToken')
  const [isSubmitting, setSubmitting] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [pdfShowing, setPdfShowing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [selected, setSelected] = React.useState(0);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const showPopconfirm = (id) => setSelected(id);
  const handleCancel = () => setSelected(0);
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const updateDocument = async (docId, payload) => {
    const toastId = getToastId('Updating documents');
    try {
      const response = await apiRequest.patch(`/documents/${docId}`, payload);
      if (response && response.data && response.data.data) {
        await dispatch(fetchFacilityById(facility.id));
        faceForm.resetFields();
        updateToast(toastId, 'Document added successfully', true);
        setFaceSheetForm(false);
      }
    } catch (e) {
      Sentry.captureException(e);
      updateToast(
        toastId,
        errMsg(e, false, null, 'Unable to update document'),
        false
      );
    }
  };
  
  const createFaceSheet = async (payload) => {
    // console.log('facesheet payload', payload);
    const toastId = toast('Adding Documents', {
      type: 'info',
      autoClose: true
    });
    const response = await apiRequest
      .post(`/documents`, payload)
      .catch(function (err) {
        toast.update(toastId, {
          render: err.response.data.message,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
      });
    console.log('createFaceSheet: ', response.data.data);
    if (response && response.data && response.data.data) {
      toast.update(toastId, {
        render: 'Document added successfully',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000
      });
      setFaceSheets([...faceSheets, response.data.data.id]);
      setFaceImages([...faceImages, response.data.data]);
      let payload = {
        id: facility.id,
        documents: [
          response.data.data.id,
          ...facility.documents.map((item) => item.id)
        ]
      };
      setFaceSheetForm(false)
      // console.log(payload)
      setFaceSheetForm(false)
      await dispatch(updateFacilityDetails(payload));
      
    }
  };
  
  const deleteDocument = async (docId) => {
    const toastId = getToastId('Updating documents');
    try {
      await apiRequest.delete(`/documents/${docId}`);
      updateToast(toastId, 'Document deleted successfully', true);
      let remainingDocs = facility.documents.filter((item) => item.id !== docId);
      let payload = {id: facility.id, documents: remainingDocs.map((item) => item.id)};
      await dispatch(updateFacilityDetails(payload));
      faceForm.resetFields();
      setFaceSheetForm(false);
    } catch (e) {
      Sentry.captureException(e);
      updateToast(toastId, errMsg(e, false, null, 'Unable to update document'), false);
    }
  };
  const onFaceFinish = async (values) => {
    console.log('onFaceFinish: ', values);
    await setSubmitting(true);
    if (editingForm) {
      await updateDocument(doc.id, values);
    } else {
      await createFaceSheet(values);
    }
    await setSubmitting(false);
    setFaceSheetForm(false);
  };
  
  const handleOk = async (docID) => {
    await setConfirmLoading(true);
    await deleteDocument(docID);
    dispatch(fetchFacilityById(facility.id));
    await setSelected(0);
    await setConfirmLoading(false);
  };
  
  const actionMenus = kebabMenus((can_update_medical_facility_admin), can_view_audit_logs, true)
  
  async function kebabMenuClick(e, doc) {
    await setDoc({...doc, ...(!!doc.expires_at ? {expires_at: dayjs(doc.expires_at)} : {expires_at: undefined})});
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true);
        await setFaceSheetForm(true);
        break;
      case 'Delete':
        await setEditingForm(false);
        showPopconfirm(doc.id);
        break;
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
        await setEditingForm(false);
    }
  }
  
  const addDoc = async () => {
    await setEditingForm(false);
    await setDoc(null);
    await setFaceSheetForm(true);
  };
  return <>
    <div className="shadow-card-box">
      {(!!doc && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)}
                                                     loading={false} resourceDescription={`Document: ${doc.title.replace(/_/g, ' ')}`} resourceType={'Office'}
                                                     defaultValues={{auditableId: doc.id, auditable: 'facility_document', format: 'json'}}/>}
      <PdfViewer
        pdf={pdf}
        setLoading={setLoading}
        loading={loading}
        onCancel={() => setPdfShowing(false)}
        visible={pdfShowing}
      />
      <Modal
        title={editingForm ? 'Edit Document' : 'Add Document'}
        open={faceSheetForm}
        destroyOnClose={true}
        onOk={dialogCloser(setFaceSheetForm)}
        onCancel={dialogCloser(setFaceSheetForm)}
        closeIcon={modalClose(dialogCloser(setFaceSheetForm))}
        maskClosable={false}
        footer={[
          <div className="entity-filters flex-boy-row" style={{justifyContent: 'flex-end'}}>
            {horStack([
              outlinedButton(dialogCloser(setFaceSheetForm), 'Cancel', isSubmitting),
              formSubmitButton('faceSheetForm', editingForm ? 'Save' : 'Add', isSubmitting, editingForm ? 'Updating' : 'Adding')
            ])}
          </div>
        ]}
      >
        <DocForm
          doc={doc}
          editingForm={editingForm}
          setFaceSheetForm={setFaceSheetForm}
          setSubmitting={setSubmitting}
          uploadName={'file_id'}
          updateDocument={updateDocument}
          createDocument={createFaceSheet}
        />
      </Modal>
      <div className="flex-boy-row-space" style={{alignItems: 'center', width: '100%'}}>
        <Subtitle1>Documents:</Subtitle1>
        {can_update_medical_facility_admin && addButton(addDoc)}
      </div>
      
      {!!facility.documents && !!facility.documents[0]
        ? facility.documents.map((doc, index) => {
          // console.log('Office DOC:  ', doc);
          return (
            <Row key={index} span={12}>
                <div className="document-div" style={{flexGrow: 1, margin: 10}}>
                  <div className="flex-boy-row-space" style={{width: '100%'}}>
                    <Subtitle1 style={{alignSelf: 'center', textTransform: 'capitalize'}}>{'Type: ' + doc.type}</Subtitle1>
                    {
                      <KebabMenu menus={actionMenus}
                                 recordItem={doc}
                                 handleMenuClick={kebabMenuClick} resource={'document'} handleOk={() => handleOk(doc.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
                                 showConfirmDialog={selected === doc.id}/>
                    }
                  </div>
                  <div className="flex-doc-row-space">
                    {doc.file && (doc.file.mime_type.includes('png') || doc.file.mime_type.includes('jp')) ?
                      <Image
                        style={{width: '100px'}}
                        fallback={require('../../assets/doc.png')}
                        alt="doc"
                        src={doc.file.url}
                      /> : <img style={{width: '100px', cursor: 'pointer'}}
                                aria-disabled={loading}
                                onClick={() => {
                                  setPdf(doc.file.url);
                                  setLoading(true);
                                  setPdfShowing(true);
                                }}
                                src={require('../../assets/doc.png')}
                                alt="doc"
                      />}
                    <div style={{padding: '10px', flex: 1, textAlign: 'start'}}>
                      <Body1 color="black">{`${doc.title.replace(/_/g, ' ')}`}</Body1>
                      {doc.expires_at && (<Body1 color="black">{`Expiration Date: ${dayjs(doc.expires_at).format('MM/DD/YYYY')}`}</Body1>)}
                    </div>
                  </div>
                </div>
              </Row>
          );
        })
        : emptyDocuments('Document', can_update_medical_facility_admin ? containedButton(addDoc, 'Add Document', false, null, {}, {icon: <PlusOutlined />}) : null
        )}
    </div>
  </>;
};

const mapStateToProps = (state) => ({
  facility: state.facilities.selectedFacility,
  appointments: state.consultants.consultantSchedule,
  activeUser: state.auth?.activeUser
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(FacilityDocument);
