import React, {useEffect, useState} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import {Headline6, Subtitle1} from '../../../components'
import {Button, Col, Divider, Dropdown, Image, Menu, Modal, Row, Spin} from 'antd'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import {saveAs} from "file-saver";
import {
  appLog,
  boldKeyCapitalizedValue,
  closeButton,
  containedButton,
  dialogCloser,
  dialogOpener,
  emptyFun,
  getProspectStatus,
  getReferralIndicatorColor,
  horStack,
  keyValItemForReferral,
  keyValItemGreyForReferral,
  outlinedButton
} from "../../../common/helpers";
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {AuditOutlined, CaretDownOutlined, EditOutlined, LoadingOutlined} from '@ant-design/icons'
import AuditLogViewer from '../../../components/AuditLogs/AuditLogViewer';
import ReferralComparisonUI from './ReferralComparisonUI';
import {apiRequest} from '../../../app/Apis';
import {errMsg, getToastId, updateToast} from '../../../app/Reducers/reducerUtils';
import {fetchPatients, fetchProspects} from '../../../app/Reducers';

const ProspectPreviewCard = ({
                               prospectData, activeUser, prospectView, setProspectView, setEditProspect, isApproveLoading, setProspectDecline, isPopping, setPopping, approve, appConf, defaultFilters
                             }) => {
  const dispatch = useDispatch()
  const approval_statusesRaw = useSelector(state => state.masters.masterListRaw.find(item => item.system_name === 'approval_status')?.options)
  const can_update_prospect = usePermissionCheck('update_prospect', false, [])
  const can_approve_prospect = usePermissionCheck('approve_prospect', false, [])
  const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_view_patient_ssn = usePermissionCheck('view_patient_ssn', false, [])
  const [comparisonModalShowing, showComparisonModal] = useState(false)
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [actionMenuRecord, setActionMenuRecord] = useState(null);
  console.log("Approval Status:", { approval_statusesRaw })
  let approval_statuses = !!approval_statusesRaw[0] ? approval_statusesRaw.filter(item => ![prospectData.approval_status, 'Not Approved', 'Pending' /*'Pending Insurance Review', 'Insurance Review Completed'*/].includes(item))/*.concat(['Pending Insurance Review', 'Insurance Review Completed'])*/ : []
  // approval_statuses.push('Special Case')
  const [insuranceDocuments, setInsuranceDocuments] = useState(null)
  console.log({ prospectData, approval_statuses })
  const [otherSheets, setOtherSheets] = useState(null)
  const [patientMatches, setPatientMatches] = useState([])
  const loadPatientMatches = async () => {
    const { data } = apiRequest.get(`/prospect-patient-matches?${new URLSearchParams({
      name: prospectData?.name || `${prospectData?.first_name || ''} ${prospectData?.middle_name || ''} ${prospectData?.last_name || ''}`
    }).toString()}`)
    let attributesToCompare = ['first_name', 'last_name', 'gender', 'birth_date', 'ssn', 'email', 'power_of_attorney_name', 'power_of_attorney_contact_number', 'address']
    let potentialDuplicates = data?.data
    if (!potentialDuplicates) {
      return
    }
    /*let potentialDuplicates = [
      {
        id: 123, first_name: prospectData?.first_name, last_name: prospectData?.last_name, gender: prospectData?.gender, birth_date: prospectData?.birth_date,
        ssn: prospectData?.ssn, email: prospectData?.email, power_of_attorney_name: 'Ragwel', power_of_attorney_contact_number: '+1054763257632', address: 'Sample address'
      },
      {
        id: 546364346, first_name: prospectData?.first_name, last_name: prospectData?.last_name, gender: prospectData?.gender, birth_date: prospectData?.birth_date,
        ssn: prospectData?.ssn, email: prospectData?.email, power_of_attorney_name: 'Jade', power_of_attorney_contact_number: '+1244234323423', address: 'SSfdfu7323djsb'
      },
      {
        id: 546384346, first_name: prospectData?.first_name, last_name: prospectData?.last_name, gender: prospectData?.gender, birth_date: prospectData?.birth_date,
        ssn: prospectData?.ssn, email: prospectData?.email, power_of_attorney_name: 'Jade', power_of_attorney_contact_number: '+1244234323423', address: 'SSfdfu7323djsb'
      }
    ]*/
    let datasource = []
    for (const patient of potentialDuplicates) {
      let dataItems = []
      for (const key of attributesToCompare) {
        let dataItem = {
          field_name: key.replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()), patient_value: patient[key] || '-', prospect_value: prospectData[key] || '-', patient_id: patient.id
        }
        dataItems.push(dataItem)
      }
      datasource.push(dataItems)
    }
    await setPatientMatches(datasource)
  }
  useEffect(() => {
    loadPatientMatches().catch(appLog)
    return emptyFun
  }, [prospectData])
  useEffect(() => {
    console.log({ prospectData })
    if (prospectData?.face_sheets?.length > 0) {
      setOtherSheets(prospectData?.face_sheets)
    }
    console.log("Insurance Documents:", prospectData?.documents)
    setInsuranceDocuments(prospectData?.documents)
    return emptyFun
  }, [])
  const downloadImage = (image) => {
    const newBlob = new Blob([image.image.url], { type: image.image.mime_type });
    saveAs(image.image.url, image.image.name)
    console.log('blob', newBlob);
  }
  const handleMenuClick = async e => await approve(approval_statuses[e.key]);
  let pendingStatuses = can_update_prospect ? ['Pending Insurance Review', 'Insurance Review Completed', ...(prospectData.approval_status === 'Not Approved' ? ['Pending'] : [])].filter(value => value !== prospectData.approval_status) : []
  const handlePendingMenuClick = async e => await approve(pendingStatuses[e.key]);
  const menu = (<Menu onClick={handleMenuClick}>
    {approval_statuses.map((item, index) => <Menu.Item key={index} style={{ textTransform: 'capitalize' }}> {item.replace('-', '').replace(/_/g, ' ')}  </Menu.Item>)} </Menu>);
  const pendingStatusMenu = (<Menu onClick={handlePendingMenuClick}>
    {pendingStatuses.map((item, index) => <Menu.Item key={index}> {item} </Menu.Item>)} </Menu>);
  const [mergingId, setMergingId] = useState(-1)
  const mergePatientAndReferral = async (patient_id) => {
    const toastId = getToastId('Merging referral and patient data')
    try {
      await setMergingId(patient_id)
      await apiRequest.post(`/prospect-patient-merge?${new URLSearchParams({ referral_d: prospectData.id, patient_id }).toString()}`)
      updateToast(toastId, 'Merged successfully', true)
      await dispatch(fetchProspects(appConf.prospectFilters[0] ? appConf.prospectFilters[1] : defaultFilters))
      await dispatch(fetchPatients(appConf.patientFilters[0] ? appConf.patientFilters[1] : defaultFilters))
      await showComparisonModal(false)
      await setProspectView(false)
    } catch (e) {
      updateToast(toastId, errMsg(e, false, null, 'Merging failed'), false)
    } finally {
      await setMergingId(-1);
    }
  }
  return (<>
    {(!!prospectData && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false}
                                                            resourceDescription={`${prospectData?.name} `}
                                                            resourceType={'Patient referral'}
                                                            defaultValues={{ auditableId: prospectData?.id, auditable: 'referral', format: 'json' }}/>}
    {(comparisonModalShowing && !!patientMatches[0]) && <ReferralComparisonUI comparisonModalShowing={comparisonModalShowing} patientMatches={patientMatches} showComparisonModal={showComparisonModal}
                                                                              mergePatientAndReferral={mergePatientAndReferral} mergingId={mergingId}/>}
    <Modal
      title={<div className="flex-boy-row-space" style={{ flex: 1, marginRight: "10px", padding: 0 }}>
        <Headline6>{"Referral Preview"}</Headline6>
        <div className="flex-boy-row">
          
          {can_view_audit_logs ? outlinedButton(() => setShowAuditDialog(true), 'Audit', false, { marginRight: 10 }, { icon: <AuditOutlined/> }) : null}
          {can_update_prospect && <Button shape={'circle'} icon={<EditOutlined/>} className={'mr-[20px]'} size={"middle"} type={"text"} onClick={() => {
            setProspectView(false);
            setEditProspect(true);
          }}/>}
          {closeButton(dialogCloser(setProspectView), null, { type: 'text' })}
        </div>
      </div>}
      open={prospectView}
      closeIcon={null}
      closable={false}
      destroyOnClose={true}
      onOk={dialogCloser(setProspectView)}
      onCancel={dialogCloser(setProspectView)}
      maskClosable={false}
      width={1200}
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        
        {horStack([outlinedButton(() => setProspectView(false), 'Cancel', isApproveLoading), ...((can_approve_prospect && prospectData.approval_status !== "rejected") ? [outlinedButton(() => {
          console.log("close this")
          // setProspectView(false)
          setProspectDecline(true)
        }, 'Not Approved', isApproveLoading)] : []), /*...((prospectData.status !== "Approved" && can_approve_prospect) ? [containedButton(() => approve(), 'Approve', isApproveLoading, 'Approving')] : []),*/
          ...((prospectData.approval_status !== "Approved" && can_approve_prospect) ? [prospectData.approval_status !== 'rejected' ?
            <Dropdown destroyPopupOnHide={true} dropdownRender={()=>menu} trigger={['click']}
            >
              {containedButton(() => setPopping(!isPopping), 'Approve', isApproveLoading, 'Approving', null,
                { endIcon: <CaretDownOutlined/> })}
            </Dropdown> :
            containedButton(() => approve('pending'), 'Pending', isApproveLoading, 'Updating', null, null)] : [])])}
      </div>]}>
      <div style={{ width: "100%" }}>
        <Row style={{ width: "100%" }} gutter={16}>
          <Col span={8} className="">
            <div className="p-4 w-full bg-white rounded-xl shadow flex-col m-4 justify-start inline-flex gap-2">
              <Headline6 className="float-left m-2 text-lg font-bold">Referral</Headline6>
              <div className="my-2 w-full">
                {['Not Approved', 'Pending', 'Pending Insurance Review', 'Insurance Review Completed'].includes(prospectData.approval_status) ?
                  <Dropdown destroyPopupOnHide={true} dropdownRender={()=>can_approve_prospect ? pendingStatusMenu : <></>} trigger={['click']}
                            disabled={isApproveLoading || !can_approve_prospect}>
                    <div className="appointment-status-box px-2 cursor-pointer"
                         style={{
                           borderColor: getReferralIndicatorColor(prospectData.approval_status), backgroundColor: getReferralIndicatorColor(prospectData.approval_status) + '15'
                         }} onClick={() => setPopping(!isPopping)}>
                      <div className="dot-circle-active" style={{ background: getReferralIndicatorColor(prospectData.approval_status) }}/>
                      <Subtitle1 style={{ textAlign: 'left', flex: 1 }} className="mr-2">{prospectData.approval_status.replace('-', '').replace(/_/g, ' ')}</Subtitle1>
                      <div>
                        {isApproveLoading ?
                          <Spin size="small" spinning={isApproveLoading} indicator={<LoadingOutlined style={{ color: 'grey' }}/>}/> : (can_approve_prospect && can_update_prospect) ?
                            <CaretDownOutlined style={{ color: 'grey' }}/> : null}
                      </div>
                    </div>
                  </Dropdown> : getProspectStatus(prospectData.approval_status)}
              </div>
              {prospectData.rejection_reason && boldKeyCapitalizedValue('Reason for Decline', prospectData.rejection_reason)}
              <div className="w-full inline-flex flex-col gap-2">
                {keyValItemForReferral('Created At', dayjs(prospectData.created_at).format('MM/DD/YYYY'))}
                {/* {keyValItemGreyForReferral('Referral Type', sentenceCaseNoDash(prospectData.referral_type))} */}
                {keyValItemForReferral('Office', prospectData?.medical_facility?.name || '-')}
                {keyValItemGreyForReferral('Patient Type', prospectData.visit_type || '-')}
                {keyValItemForReferral('Patient Location Type', prospectData.patient_location_type || '-')}
                {keyValItemGreyForReferral('Referral Priority', prospectData.referral_priority || '-')}
                {/* {keyValItemForReferral('Kit Type', prospectData.type_of_kit === 'Home Health (Standard) Kit' ? 'Standard Kit' : prospectData.kit_type || 'N/A')} */}
                {keyValItemGreyForReferral('Date of Request', prospectData.request_date?dayjs(prospectData.request_date).format('MM/DD/YYYY'):'-')}
                {keyValItemForReferral('Date can Bill', prospectData.billable_from ?dayjs(prospectData.billable_from).format('MM/DD/YYYY'):'-')}
                {keyValItemGreyForReferral('Reason to visit', prospectData.visit_reason || '-')}
                {prospectData?.reason_approval_status_changed && keyValItemGreyForReferral('Approval status reason: ', prospectData.reason_approval_status_changed || '-')}
              </div>
            </div>
            <div className="p-4 w-full bg-white rounded-xl shadow flex-col m-4 justify-start inline-flex gap-2">
              <Headline6 className="float-left m-2 text-lg font-bold leading-normal">Referral Information</Headline6>
              <div className="w-full inline-flex flex-col gap-2">
                {keyValItemForReferral('Referred by', prospectData.referred_by || 'Unknown')}
                {keyValItemGreyForReferral('Case manager', prospectData.case_manager || '-')}
                {keyValItemForReferral('Case manager\'s phone', prospectData.case_manager_phone || '-')}
                {keyValItemGreyForReferral('Diabetic', prospectData.diabetic || '-')}
                {keyValItemForReferral('Diabetic type', prospectData.diabetic_type || 'N/A')}
                {keyValItemGreyForReferral('Hospitalized in the past 100 Days:', prospectData?.hospitalize?.replace('-', '')?.replace(/_/g, ' ')?.replace(/^\w/, c => c.toUpperCase()) || '-')}
                {keyValItemForReferral('Medical Records sent via:', prospectData?.medical_record_access_option?.replace('-', '')?.replace(/_/g, ' ')?.replace(/^\w/, c => c.toUpperCase()) || '-')}
              </div>
            </div>
            <div className="p-4 w-full bg-white rounded-xl shadow flex-col m-4 justify-start inline-flex gap-2">
              <Headline6 className="float-left m-2 text-lg font-bold leading-normal">Wounds</Headline6>
              {!!prospectData?.wounds?.[0] && <>
                <Row gutter={8} style={{ width: "100%" }}>
                  <Headline6>Wound Details</Headline6>
                  {keyValItemGreyForReferral('Number of Wounds', prospectData?.wounds?.length)}
                  {prospectData?.wounds.map((wound, index) => {
                    return <Row gutter={8} style={{ width: "100%" }}>
                      <Row gutter={12} style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Col style={{ width: "100%" }}>
                          {keyValItemForReferral('Wound Location', wound.wound_location)}
                          {keyValItemGreyForReferral('Wound Side', wound.wound_side)}
                          {keyValItemForReferral('Wound Position', wound.wound_position)}
                          {keyValItemGreyForReferral('Wound Duration', wound.wound_duration)}
                          {/*{keyValItemForReferral('Wound Bed', wound?.wound_bed||'-')}*/}
                          {keyValItemGreyForReferral('Granulation Tissue(%)', wound?.granulation_tissue?.toString()||'-')}
                          {keyValItemForReferral('Necrotic Tissue(%)', wound?.necrotic_tissue?.toString()||'-')}
                          {keyValItemGreyForReferral('Fibrous Tissue(%)', wound?.fibrous_tissue?.toString()|| '-')}
                          {keyValItemForReferral('Onset of the wound', wound.original_date_of_wound ? dayjs(wound.original_date_of_wound).format('MM-DD-YYYY') : '-')}
                          {/* {boldKeyCapitalizedValue('Wound Type', wound.wound_type)} */}
                        </Col>
                      </Row>
                      <Row style={{ width: '100%' }}>
                        <Col span={12}>
                          {wound.image && wound.image.url && <div className="flex-boy-row-space" style={{ width: "100%" }}>
                            <Image src={wound?.image?.url} height={100} width={100} fallback={require('../../../assets/pic.png')}/>
                          </div>}
                        </Col>
                        <Col span={12}>
                          {keyValItemForReferral('Width', wound.width || '-')}
                          {keyValItemGreyForReferral('Length', wound.length || '-')}
                          {keyValItemForReferral('Area', wound.area || '-')}
                          {keyValItemGreyForReferral('Depth', wound?.depth || '-')}
                          {keyValItemForReferral('Volume', wound?.volume || '-')}
                        </Col>
                      </Row>
                      <Divider/>
                    </Row>
                  })}
                </Row>
              </>}
            </div>
          </Col>
          <Col span={8}>
            <div className="p-4 w-full bg-white rounded-xl shadow flex-col m-4 justify-start inline-flex gap-2">
              <Headline6 className="float-left m-2 text-lg font-bold leading-normal">Primary Information</Headline6>
              {!!patientMatches[0] && <div className={'rounded-lg w-full bg-[#FFF4EC] min-h-[40px] px-4 py-2'}>
                <p className={'text-[#863A00] font-semibold'}>Data matches {patientMatches.length} patient(s)</p>
                <div className={'flexy-row'}>
                  <Button className={'w-full'} type={'dashed'} onClick={dialogOpener(showComparisonModal)}>Compare with patient data</Button>
                </div>
              </div>}
              <div className="w-full inline-flex flex-col gap-2">
                {keyValItemForReferral('Name', `${prospectData?.name || `${prospectData?.first_name || ''} ${prospectData?.middle_name || ''} ${prospectData?.last_name || ''}`}`)}
                {keyValItemGreyForReferral('Gender', prospectData.gender.replace(prospectData.gender[0], prospectData.gender[0].toUpperCase()))}
                {keyValItemForReferral('Date of birth', `${dayjs(prospectData.birth_date).format("MM/DD/YYYY")}\n(${dayjs().diff(dayjs(prospectData.birth_date), 'years')}y.o.)`)}
                {can_view_patient_ssn && keyValItemGreyForReferral('SSN', prospectData.ssn || '-')}
                {keyValItemForReferral('Phone', prospectData.phone_no)}
                {keyValItemGreyForReferral('Email', prospectData.email || '-')}
                {keyValItemForReferral('Consent', prospectData.consent || '-')}
                {prospectData.consent !== 'self' && keyValItemForReferral('Attorney name', prospectData.power_of_attorney_name || '-')}
                {prospectData.consent !== 'self' && keyValItemGreyForReferral('Attorney phone', prospectData.power_of_attorney_contact_number || '-')}
              </div>
            </div>
            <div className="p-4 w-full bg-white rounded-xl shadow flex-col m-4 justify-start inline-flex gap-2">
              <Headline6 className="float-left m-2 text-lg font-bold leading-normal">Address</Headline6>
              <div className="w-full inline-flex flex-col gap-2">
                {keyValItemForReferral('Region', prospectData.region)}
                {keyValItemGreyForReferral('Time Zone', prospectData.timezone || '-')}
                {keyValItemForReferral('City', prospectData.city)}
                {prospectData.zip_code !== prospectData.address_1 && keyValItemForReferral('ZIP', prospectData.zip_code)}
                {keyValItemGreyForReferral('State', prospectData.state)}
                {keyValItemForReferral('Address', prospectData.address_1)}
              </div>
            </div>
            <div className="p-4 w-full bg-white rounded-xl shadow flex-col m-4 justify-start inline-flex gap-2">
              <Headline6 className="float-left m-2 text-lg font-bold leading-normal">Insurance</Headline6>
              {prospectData.insurance && prospectData.insurance.length > 0 && <>
                {prospectData.insurance.map((insurance, index) => (<Col>
                  {keyValItemForReferral('ID', insurance?.id)}
                  {keyValItemGreyForReferral('Policy', insurance?.policy_number)}
                  {keyValItemForReferral('Plan', insurance?.plan)}
                  {keyValItemGreyForReferral('Status', insurance?.status)}
                  {keyValItemForReferral('Insurance Provider', insurance?.insurance_provider)}
                  {keyValItemGreyForReferral('Guarantor Name', insurance?.guarantor_name)}
                  {keyValItemForReferral('Policy Holder', insurance?.policy_holder_name)}
                  {keyValItemGreyForReferral('Priority', insurance?.priority)}
                  <Divider/>
                </Col>))}
              </>}
            </div>
          </Col>
          <Col span={8}>
            <div className="p-4 w-full bg-white rounded-xl shadow flex-col m-4 justify-start inline-flex gap-2">
              <Headline6 className="float-left m-2 text-lg font-bold leading-normal">Requests</Headline6>
              <div className="w-full inline-flex flex-col gap-2">
                {keyValItemForReferral('Date of Request', prospectData.request_date?dayjs(prospectData.request_date).format('MM/DD/YYYY'):'-')}
                {keyValItemForReferral('Face Sheet', prospectData?.face_sheets?.length ? "Yes" : "No")}
              </div>
            </div>
            {prospectData.face_sheets && otherSheets?.length > 0 && <div className="p-4 w-full bg-white rounded-xl shadow flex-col m-4 justify-start inline-flex gap-2">
              <Headline6 className="float-left m-2 text-lg font-bold leading-normal">Face Sheets</Headline6>
              {otherSheets?.map((facesheet, index) => (<Col className="w-full">
                {keyValItemForReferral('Title:', facesheet?.title)}
                {!!facesheet?.image && <div className="flex-boy-row-space" style={{ width: "100%" }}>
                  <Image src={facesheet.image.url} fallback={require('../../../assets/doc.png')} height={100} width={100}/>
                  <Button onClick={() => downloadImage(facesheet)} style={{ borderColor: '#d9d9d9', color: '#d9d9d9' }}
                          icon={<FontAwesomeIcon color="grey" icon={["fas", "download"]}/>} size="medium">
                    <Subtitle1 className="text-gray-700">Download</Subtitle1>
                  </Button>
                </div>}
              </Col>))}
            </div>}
            {prospectData.documents && <div className="p-4 w-full bg-white rounded-xl shadow flex-col m-4 justify-start inline-flex gap-2">
              <Headline6 className="float-left m-2 text-lg font-bold leading-normal">Documents</Headline6>
              {insuranceDocuments?.length > 0 && <>
                {insuranceDocuments.map((document, index) => (<Col className="w-full">
                  {keyValItemForReferral('Title:', document?.title)}
                  {keyValItemGreyForReferral('Type:', document?.type)}
                  {!!document?.image && <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Image src={document.image.url} fallback={require("../../../assets/doc.png")} height={100} width={100}/>
                    <Button onClick={() => downloadImage(document)} style={{ borderColor: '#d9d9d9', color: '#d9d9d9' }}
                            icon={<FontAwesomeIcon color="grey" icon={["fas", "download"]}/>} size="medium">
                      <Subtitle1 className="text-gray-700">Download</Subtitle1>
                    </Button>
                  </div>}
                </Col>))}
              </>}
            </div>}
          </Col>
        </Row>
      </div>
    </Modal>
  </>);
}
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser, appConf: state.appConf
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ProspectPreviewCard)