import React, {useEffect, useState} from 'react';
import {Grid, Table, Upload} from "antd";
import {connect, useDispatch} from "react-redux";
import {createCPTCode, deleteCPTCode, editCPTCode, fetchCPTCodes, resetCPTCodePagination, updateCPTCodesFilters} from "../../../app/Reducers";
import TableFilters from "../../../components/InputComponents/TableFilters";
import {
  containedButton,
  createFormInputsFromTableColumns,
  dialogCloser,
  emptyFun,
  emptyTable,
  executeChunkFn,
  getSkels,
  kebabMenus,
  makeSelectOptions,
  onTableChange,
  responsiveTbl,
  secondaryButton
} from "../../../common/helpers";
import KebabMenu from "../../../components/KebabMenu";
import * as Sentry from "@sentry/react";
import {errMsg, getToastId, toastUp, updateToast} from "../../../app/Reducers/reducerUtils";
import DynamicModalForm from "../../../components/DynamicModalForm";
import {apiRequest} from '../../../app/Apis';
import {CaretDownOutlined, CloudUploadOutlined, PlusOutlined} from '@ant-design/icons';
const { useBreakpoint } = Grid;
const CPTCodesCharges = ({ appConf, CPTCodePagination, CPTCodes, procedures }) => {
  const dispatch = useDispatch();
  const windowState = useBreakpoint();
  const dateFormat = "YYYY-MM-DD";
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [auditItem, setAuditItem] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [selected, setSelected] = useState(null)
  const [isExecuting, setisExecuting] = useState(false)
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  const [phoneValue, setPhoneValue] = useState(undefined);
  const [procedureOptions, setProcedureOptions] = useState([]);
  useEffect(() => {
    dispatch(fetchCPTCodes());
    return emptyFun
  }, []);
  
  const columns = [
    {
      title: 'CPT Code', dataIndex: 'cpt', key: 'cpt', fltr: { filterable: true, type: 'text', editableCol: true, required: true, fullRow: true }
    },
    {
      title: 'Description', dataIndex: 'description', key: 'description', fltr: {
        filterable: true, type: 'text', editableCol: true, required: false, fullRow: true
      },
      ellipsis: true, invisible: true
    },
    {
      title: 'Default charge per unit', dataIndex: 'charges', key: 'charges', fltr: { filterable: false, type: 'text', editableCol: true, required: true, fullRow: false }
    },
    {
      title: 'Service Type', dataIndex: 'service_type', key: 'serviceType', fltr: {
        filterable: true, type: 'select', editableCol: true, required: true, fullRow: true,
        options: makeSelectOptions(['Biologic', 'Supplies', 'Lab', 'Medical Care', 'Vascular ABI'])
      }
    },
    {
      title: 'Procedure', dataIndex: 'procedure', key: 'procedure', fltr: {
        filterable: true,
        type: 'select',
        options: procedureOptions,
        editableCol: true,
        required: true,
        fullRow: true,
        props: { suffixIcon: <CaretDownOutlined/>, mode: 'multiple' }
      },
      render: (procedures) => Array.isArray(procedures) ? procedures.join(', ') : ''
    },
    {
      title: 'Action',
      render: (record) =>
        (<KebabMenu
            menus={actionMenus}
            recordItem={record}
            handleMenuClick={kebabMenuClick}
            resource="CPT Code"
            handleOk={() => handleOk(record)}
            showConfirmDialog={selected === record.id}
            handleCancel={handleCancel}
            confirmLoading={confirmLoading}
          />
        )
    }
  ]
  let CPTCodeFormInputs = createFormInputsFromTableColumns(false, columns, phoneValue, setPhoneValue, null, null, dateFormat)
  //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
  let dynamicFormInputFields = {
    'Edit CPT Code': CPTCodeFormInputs, 'Add CPT Code': CPTCodeFormInputs
  }
  
  const actionMenus = kebabMenus(true, false, true)
  const showPopconfirm = (id) => setSelected(id);
  
  useEffect(() => {
    setProcedureOptions([...procedures.map(item => ({ ...item, label: item.name, value: item.name }))])
    return emptyFun
  }, [procedures]);
  
  async function kebabMenuClick(e, record) {
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await openCPTCodeForm(record)
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        break
      default:
    }
  }
  
  const handleOk = async (record) => {
    await setConfirmLoading(true)
    await dispatch(deleteCPTCode(record.id))
    await setConfirmLoading(false)
    await setSelected(0)
  }
  
  const handleCancel = () => setSelected(0);
  
  async function handleFilterAction(action, values) {
    await dispatch(resetCPTCodePagination())
    if (action === 'filter') {
      let pl = { filterData: { ...values }, pagination: CPTCodePagination }
      await dispatch(updateCPTCodesFilters([false, pl, true]))
      await dispatch(fetchCPTCodes(pl))
      await dispatch(updateCPTCodesFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updateCPTCodesFilters([appConf.CPTCodesFilters[0], undefined, true]))
      await dispatch(fetchCPTCodes())
      await dispatch(updateCPTCodesFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  
  const openCPTCodeForm = async (record) => {
    await setFormName(!!record ? 'Edit CPT Code' : 'Add CPT Code');
    await setDefaultDynamicModalFormValues(!!record ? { ...record, serviceType: record.service_type } : undefined);
    await setDynamicFormOpen(true);
  };
  
  /**
   * This function is handling returned form entries from the dynamic form
   * */
  const onDynamicFormEntriesResult = async (entries) => {
    
    try {
      switch (formName) {
        case 'Add CPT Code':
          await executeChunkFn(dispatch, createCPTCode, { ...entries.values, service_type: entries.values.serviceType }, setisExecuting, dialogCloser(setDynamicFormOpen), null)
          break;
        case 'Edit CPT Code':
          await executeChunkFn(dispatch, editCPTCode, { ...entries.values, service_type: entries.values.serviceType }, setisExecuting, dialogCloser(setDynamicFormOpen), null)
          break;
        default:
          return
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      toastUp(errMsg(e, false, formName), false);
    }
  };
  
  const [uploading, setUploading] = useState(false)
  const uploadCPTCodeCSV = async options => {
    const { onSuccess, onError, file, onProgress } = options;
    let formData = new FormData();
    const toastId = getToastId("Uploading CPT Code file")
    formData.append("csv_file", file);
    await setUploading(true)
    try {
      await apiRequest.post("/cpt-codes/csv_upload", formData)
      await dispatch(fetchCPTCodes())
      onSuccess("Ok");
      updateToast(toastId, 'Upload successful', true)
    } catch (e) {
      onError('Failed to upload')
      updateToast(toastId, 'Upload failed', false)
    } finally {
      await setUploading(false)
    }
  }
  return (
    <div className="w-full">
      {(isDynamicFormOpen && !!formName) && (
        <DynamicModalForm
          setDynamicFormOpen={setDynamicFormOpen}
          isDynamicFormOpen={isDynamicFormOpen}
          inputFields={dynamicFormInputFields[formName]}
          onDynamicFormEntriesResult={onDynamicFormEntriesResult}
          closeModal={dialogCloser(setDynamicFormOpen)}
          isExecuting={isExecuting}
          defaultValues={defaultDynamicModalFormValues}
          formName={formName}
          phoneValue={phoneValue} setPhoneValue={setPhoneValue}
          useUpdateTerminology={['Save', 'Saving']}
        />
      )}
      <Table
        title={() => (<TableFilters datasource={columns}
                                    setIsVisible={setIsVisible}
                                    isVisible={isVisible}
                                    handleFilterAction={handleFilterAction}
                                    filters={appConf.CPTCodesFilters[1]}
                                    showClear={appConf.CPTCodesFilters[0]}
                                    loading={appConf.CPTCodesFilters[2]}
                                    actionButton={
                                      <div className="flex-boy-row" style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <Upload accept=".csv" customRequest={uploadCPTCodeCSV} maxCount={1}
                                                showUploadList={false}>
                                          {secondaryButton(null, 'Import', uploading, 'Uploading', { marginRight: 10 }, { icon: <CloudUploadOutlined /> })}</Upload>
                                        {containedButton(() => openCPTCodeForm(), 'Add CPT Code', false, null, null, { icon: <PlusOutlined /> })}
                                      </div>
                                    }
        />)}
        loading={appConf.CPTCodesFilters[2]} size={'small'} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('patient wounds.') }}
        onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchCPTCodes, appConf.CPTCodesFilters, updateCPTCodesFilters, null)}
        onRow={(record) => ({
          onClick: async (event) => {
            await setAuditItem(record)
            await setShowAuditDialog(true)
          }
        })}
        {...getSkels((appConf.CPTCodesFilters[2]), columns, CPTCodes)} pagination={CPTCodePagination}
      />
    </div>
  
  );
};


const mapStateToProps = (state) => ({

  activeUser: state.auth?.activeUser,
  CPTCodes: state.masters.CPTCodes,
  CPTCodePagination: state.masters.CPTCodePagination,
  procedures: state.appointments.procedures,
  appConf: state.appConf
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(CPTCodesCharges);
