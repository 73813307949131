import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import AgoraUIKit, {layout} from "agora-react-uikit";
import { RegularButton, Subtitle1 } from '../../../../components';
import { apiRequest } from '../../../../app/Apis';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';


export const VideoCallui = ({ client, channelName, token, handleCancel, uid, isHost, activeUser, setInCall, inCall, hostdata, Leave }) => {


// screenshere token
    const [screenShareToken, setScreenShareToken] = useState(null);

    // useEffect(() => {
    //   if(!isHost){
    // getToken()
    //   }
    //   return () => {
        
    //   }
    // }, [channelName])



    // const getToken = async () => {
    //     const response = await apiRequest.post(`/agora/token`, { "channel_name": channelName, "uid": 0 }).catch(err => console.log(err))
    //     if (response) {
    //         setScreenShareToken(response.data.token)
    //     }
    // }
    const callbacks = {
        EndCall: async() => {
            if(isHost){
                if(isRecording){
                StopCallRecord()
                }
            //  send fcm message to recipient
            const call_response = await apiRequest.post(`/agora/chat-notify`, { 
                "users": [...hostdata?.users],
                "message": "Call Ended",
                "payload": {
                    "status": "call_ended",
                    "initiator": hostdata?.initiator,
                    "channel_name": channelName,
                }
               
             }).catch(err => console.log(err))
            console.log(call_response)
            }
            Leave()
            setInCall(false)
            handleCancel()
        },
    };
// call record
    
    
    useEffect(() => {
        aqquireCallRecordconnection()
    
      return () => {
    
      }
    }, [uid, channelName])
    
    
    // resourceid useState
    const [resourceId, setResourceId] = useState(null);
    const aqquireCallRecordconnection = async () => { 
        const response = await apiRequest.post(`agora/cloud-recoding/connect`, {
            cname: channelName, "uid": uid, "clientRequest": {
            } }).catch(err => console.log(err))
        if (response) {
            setResourceId(response.data.data.resourceId)
        }
    }
   
    
    const StartCallRecord = async () => { 
        const response = await apiRequest.post(`/agora/cloud-recoding/start`, {
           
            "cname": channelName, "uid": uid, "clientRequest": {
                    token: token,
            } ,
           "mode_type": "mix",
            "resource_id": resourceId,
        }).catch(err => console.log(err))
        if (response) {
           
            setSid(response.data.data.sid)
            setIsRecording(true)
            const call_response = await apiRequest.post(`/agora/chat-notify`, { 
                "users": [...hostdata?.users],
                "message": "Call Recording Started",
                "payload": {
                    "status": "call_recording_started",
                    "initiator": hostdata?.initiator,
                    "channel_name": channelName,
                }
               
             }).catch(err => console.log(err))
            console.log(call_response)
        }
    }

    // sid useState

    const [sid, setSid] = useState(null);

    // isrecording useState
    const [isRecording, setIsRecording] = useState(false);


    const StopCallRecord = async () => { 
        const response = await apiRequest.post(`/agora/cloud-recoding/stop`, {
           
            "cname": channelName, "uid": uid, "clientRequest": {
                    token: token,
            } ,
           "mode_type": "mix",
            "resource_id": resourceId,
            sid:sid
        }).catch(err => console.log(err))
        if (response) {
            console.log(response)
            setIsRecording(false)
            const call_response = await apiRequest.post(`/agora/chat-notify`, { 
                "users": [...hostdata?.users],
                "message": "Call Recording Ended",
                "payload": {
                    "status": "call_recording_ended",
                    "initiator": hostdata?.initiator,
                    "channel_name": channelName,
                }
               
             }).catch(err => console.log(err))
            console.log(call_response)
        }
    }

    const rtcProps =  {
        appId: "17ca3fc090b549cca97fed8308c46642",
        channel: channelName,
        // role: isHost ? 'host' : 'audience',
        token: token,
        uid: isHost ? uid : 0,
        // enableScreensharing: true,
        // enableDualStream: true,
        enableVideo: false,
        enableAudio: false,
        // cucustomRtcClient:client,
        // screenshareUid: 0,
        // screenshareToken: screenShareToken,
        layout: layout.grid,
        
        
    } 
    const videoCallRef = useRef(null);
    // const unpublishLocalStream = () => {
    // console.log(videoCallRef)
    //     if (videoCallRef.current) {
    //       const { client, localStream } = videoCallRef.current;
    // console.log(client, localStream)
    //       // Unpublish local stream
    //       if (localStream) {

    //         client.unpublish([localStream]);
    //         localStream.close();
    //       }
    //     }
    //   };

    return (
        <>


            { inCall ? (
                <div  className='flex flex-col' style={{ display: "flex", width: "100%", height: "100%" }}>
                
                    {isHost && (isRecording ? <div onClick={StopCallRecord} className='flex flex-row justify-between items-center cursor-pointer my-2' style={{ position: "relative", left: "10px", background: "red", color: "white", padding: "5px", borderRadius: "5px", width: '120px' }}>
                        <FontAwesomeIcon
                            className={`text-red-500 transition-all duration-500 ${isRecording ? 'text-2xl animate-pulse' : 'text-base'}`}
                            color='white'
                            size='lg'
                            icon={['fas', 'circle']}
                        />
                       Recording
                    </div> : <div onClick={StartCallRecord} className='flex flex-row justify-between my-2 items-center' style={{ position: "relative", left: "10px", background: "red", color: "white", padding: "5px", borderRadius: "5px", width:'120px' }}>
                            <FontAwesomeIcon
                                className={`text-white transition-all duration-500 ${isRecording ? 'text-2xl animate-pulse' : 'text-base'}`}
                                color='white'
                                size='lg'
                                icon={['fas', 'circle']}
                            />
                        Record call</div>)}
                    <div style={{ display: "flex", width: "100%", height: "calc(100% - 40px)" }}>
                        <AgoraUIKit  styleProps={{ localBtnContainer: { background: '#002F3B' } }} rtcProps={rtcProps} callbacks={callbacks} rtmProps={{ username: activeUser.name, displayUsername: true }}/>
                        {/* <Button onClick={unpublishLocalStream}> Unpublish</Button> */}
                    </div>
 
                </div>
            ) : (
                    <RegularButton


                        onClick={() => setInCall(true)}
                    >
                        Join Call
                    </RegularButton>
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    activeUser: state.auth?.activeUser,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(VideoCallui)
