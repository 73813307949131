/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Divider, Form, TimePicker } from 'antd'
import { Subtitle1 } from '../../../components/general'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { apiRequest } from '../../../app/Apis'
import { fetchConsultanListSchedule } from '../../../app/Reducers'
import { addButton, deleteButton, emptyFun } from "../../../common/helpers";

export const AvailabilityCard = ({ consultant, consultantDetails, setIsModalVisible, setisExecuting }) => {
  const daysoftheweek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]
  const [poppedTimes, setpoppedTimes] = useState([])
  const dispatch = useDispatch()
  const createTimes = () => {
    console.log(dayjs().startOf('day'))
    if (consultantDetails.schedule.length) {
      let times = []
      consultantDetails.schedule.map(day => {
        let dayTimes = {
          day_of_week: day.day_of_week, isPopped: !day.start_time && !day.end_time, times: {
            [`${day.day_of_week}_start_time`]: !day.start_time ? dayjs().startOf('day').format("HH:mm:ss") : day.start_time,
            [`${day.day_of_week}_end_time`]: !day.start_time ? dayjs().endOf('day').format("HH:mm:ss") : day.end_time
          }
        }
        form.setFieldsValue({
          [`${day.day_of_week}_start_time`]: !day.start_time ? dayjs().startOf('day') : dayjs(`2020-01-15 ${day.start_time}`),
          [`${day.day_of_week}_end_time`]: !day.end_time ? dayjs().startOf('day') : dayjs(`2020-01-15 ${day.end_time}`)
        });

        times.push(dayTimes)
        return day
      })
      console.log(times)
      let shchedule_days = consultantDetails.schedule.map(item => item.day_of_week)
      console.log(shchedule_days, daysoftheweek, daysoftheweek.filter(item => !shchedule_days.includes(item)))
      daysoftheweek.filter(item => !shchedule_days.includes(item)).map(day => {
        let dayTimes = {
          day_of_week: day, isAvailable: true, isPopped: false, times: {
            [`${day}_start_time`]: dayjs().startOf('day').format("HH:mm:ss"),
            [`${day}_end_time`]: dayjs().endOf('day').format("HH:mm:ss")
          }
        }
        form.setFieldsValue({
          [`${day}_start_time`]: dayjs().startOf('day'), [`${day}_end_time`]: dayjs().endOf('day')
        });
        // console.log(dayTimes)
        times.push(dayTimes)
        return day
      })
      console.log(times)
      setAvailability(times)
    } else {
      let times = []
      daysoftheweek.map(day => {
        let dayTimes = {
          day_of_week: day, isAvailable: true, isPopped: false, times: {
            [`${day}_start_time`]: dayjs().startOf('day').format("HH:mm:ss"),
            [`${day}_end_time`]: dayjs().endOf('day').format("HH:mm:ss")
          }
        }
        form.setFieldsValue({
          [`${day}_start_time`]: dayjs().startOf('day'), [`${day}_end_time`]: dayjs().endOf('day')
        });
        // console.log(dayTimes)
        times.push(dayTimes)
        return day
      })
      setAvailability(times)
    }
    return true
  }
  useEffect(() => {
    createTimes()
    return emptyFun
  }, [])
  const [availability, setAvailability] = useState([])
  const timeItem = (day) => {
    <TimePicker minuteStep={15} showTime={{ format: 'hh:mm:ss A', use12Hours: true }} onChange={value => console.log(value)} />
  }
  const addTime = (value, day, name) => {
    console.log(value, day, name)
    let times = [...availability]
    let objIndex = times.findIndex(obj => obj.day_of_week === day);
    console.log(objIndex)
    let day_in_focus = times[objIndex]
    day_in_focus.times[`${day}_${name}`] = dayjs(value, "HH:mm:ss A").format("HH:mm:ss")
    times[objIndex] = day_in_focus
    setAvailability(times)
  }
  const updateItem = (day) => {
    let times = [...availability]
    let objIndex = times.findIndex(obj => obj.day_of_week === day);
    console.log(objIndex)
    let day_in_focus = times[objIndex]
    day_in_focus.isPopped = !day_in_focus.isPopped
    times[objIndex] = day_in_focus
    setAvailability(times)
  }
  const onFinish = async (values) => {
    await setisExecuting(true)
    let new_availability = [...availability]
    new_availability = new_availability.map(item => {
      let new_item = {
        day_of_week: item.day_of_week, start_time: item.isPopped ? null : item.times[`${item.day_of_week}_start_time`],
        end_time: item.isPopped ? null : item.times[`${item.day_of_week}_end_time`]
      }
      return new_item
    })
    let data = {
      schedules: new_availability
    }
    const toastId = toast("Updating Availability Schedules", { type: "info", autoClose: false });
    const response = await apiRequest.post(`/consultant-availability-schedules`, data).catch(async function (err) {
      await setisExecuting(false)
      console.log(err)
      toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
    })
    if (response && response.data) {
      toast.update(toastId, { render: "Availability schedules updated", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      await dispatch(fetchConsultanListSchedule(consultant))
      await setisExecuting(false)
      setIsModalVisible(false)
      // setIsAvailabilityModalVisible(false)
    }
  }
  const [form] = Form.useForm();
  return (<div>
    <Subtitle1 style={{ textAlign: 'left' }}>Days of week</Subtitle1>
    <Form
      form={form}
      name="schedule-form" onFinish={onFinish} labelCol={{ span: 2 }} wrapperCol={{ span: 24 }}

    >

      {availability.map(day => {
        return (<>
          <div key={day} className="flex-boy-row-space" style={{ width: "100%", alignItems: 'normal' }}>


            <Form.Item label={day.day_of_week} style={{ marginBottom: 0, width: "100%" }}>


              <div className="flex-boy-row-space">
                <Form.Item
                  name={`${day.day_of_week}_start_time`}
                  noStyle

                // rules={[{ required: true, message: 'Username is required' }]}
                >
                  <TimePicker minuteStep={15} showTime={{ format: 'hh:mm:ss A', use12Hours: true }} disabled={day.isPopped}
                    onChange={(e, value) => addTime(value, day.day_of_week, "start_time")} />
                </Form.Item>

                -
                <Form.Item
                  name={`${day.day_of_week}_end_time`}
                  noStyle
                // rules={[{ required: true, message: 'Username is required' }]}
                >
                  <TimePicker minuteStep={15} showTime={{ format: 'hh:mm:ss A', use12Hours: true }} disabled={day.isPopped}
                    onChange={(e, value) => addTime(value, day.day_of_week, "end_time")} />
                </Form.Item>
                {day.isPopped ? addButton(() => updateItem(day.day_of_week)) : deleteButton(() => updateItem(day.day_of_week))}
              </div>
            </Form.Item>
          </div>
          <Divider />
        </>)
      })}
    </Form>
  </div>)
}
const mapStateToProps = (state) => ({ consultant: state.consultants.selectedConsultant,
  consultantDetails: state.consultants.selectedConsultantDetails
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityCard)
