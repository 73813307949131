import { Tooltip, Divider, Skeleton, Spin } from "antd";
import { Headline6, Subtitle1 } from "../../components";
import { appLog } from "../../common/helpers";

const calculatePercentage = (number, total) => {
  let perc = ((number / total) * 100).toFixed(2);
  console.log("Percentages:", perc)
  return perc
};

function Bar({ color, width }) {
  return (
    <Tooltip
      placement='top'
      title={`${width}%`}
      color={color}
      arrowPointAtCenter
    >
      <div style={{ width: '100%' }}>
        <div
          style={{
            backgroundColor: `${color}`,
            width: `${width}%`,
            height: '16px',
            borderRadius: '2px',
          }}
        />
      </div>
    </Tooltip>
  );
}

export function PatientStatsChart({ title, percentage, color, total, loadingPatients }) {
  return (
    <div className='flex-boy-row' style={{ padding: '0px', flexWrap: 'wrap' }}>
      <>
        <div className='flex-boy-column' style={{ alignItems: 'start', justifyContent: 'center', width: '20%', paddingLeft: '10px' }}>
          <Subtitle1 style={{ fontFamily: 'Lato', fontSize: '13px', fontWeight: 'bold', textAlign: 'left' }} >{title}</Subtitle1>
        </div>
        <div className='flex-boy-column' style={{ justifyContent: 'start', alignItems: 'start', flex: 1, marginLeft: '40px', marginTop: '20px', padding: '0px' }} >
          {loadingPatients ? <Skeleton style={{
            width: percentage
          }} /> : <Bar width={percentage} color={color} />}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Subtitle1 style={{ textAlign: 'center', marginLeft: '5px', fontSize: '13px', fontWeight: 'bold', fontFamily: 'Lato' }} >{total} </Subtitle1>
            <Subtitle1 style={{ textAlign: 'center', marginLeft: '5px', fontSize: '13px', fontWeight: 'bold', color: '#827F86', fontFamily: 'Lato' }}>  | {percentage}% </Subtitle1>
          </div>
          <Divider style={{ margin: '4px', color: '#E2E4EA', width: '100%' }} />
        </div>
      </>
    </div>
  );
}

// export facility loading
export const FacilityLoadingdiv = () => {
  return <div className="flex flex-col justify-center items-center w-full h-full">
    <Headline6 style={{textAlign:'center'}}>Loading Office...</Headline6>
    <Spin className="mt-4" size="large" /> </div>
}
