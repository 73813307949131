/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Subtitle1 } from '../../components';
import {Button, Grid, Input, Modal, Table, Tag} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Highlighter from 'react-highlight-words';
import {EditOutlined, PlusOutlined, SearchOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  fetchConsultants,
  fetchFacilities,
  resetFacilitiesPagination,
  resetFacilityPatientPagination,
  resetFacilityProspectPagination,
  selectFacility,
  setFacilitiesLoaded,
  updateFacilitiesFilters
} from '../../app/Reducers';
import NewFacilityCard from './components/NewFacility';
import {
  containedButton,
  dialogCloser,
  emptyFun,
  emptyTable,
  formSubmitButton,
  getDualStatus, GetRawListOptions,
  getSkels,
  horStack,
  makeAutoCompleteOptions,
  makeSelectOptions,
  modalClose,
  onTableChange,
  outlinedButton,
  regionFilter,
  responsiveTbl,
  stateFilter
} from '../../common/helpers';
import _ from 'lodash';
import { apiRequest } from '../../app/Apis';
import TableFilters from "../../components/InputComponents/TableFilters";
import { useSubscription } from "../../hooks/MqttHooks/useSubscription";

import { usePermissionCheck } from '../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
const { useBreakpoint } = Grid;
const FacilitiesList = ({  facilities, facilitiesPagination, appConf,  consultants, masterList, activeUser }) => {
    const location = useLocation()
    const navigate = useNavigate();
      const windowState=useBreakpoint()
  const dispatch = useDispatch();
  const can_create_medical_facility_admin = usePermissionCheck('create_medical_facility_admin', false, [])
  const [consultantOptions, setSalesManagerOptions] = useState([]);
  const can_view_medical_facility = usePermissionCheck('view_medical_facility', false, [])
  const { message } = useSubscription(`view_medical_facility_listing`, { qos: 2, nl: true });
  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message);
      loadFacilities(true).catch(e => console.log(e));
    }
    return emptyFun;
  }, [message]);
  useEffect(() => {
    loadFacilities(false).catch(e => console.log(e));
    return emptyFun;
  }, []);

  const handleConsultantssearch = async (value) => {
    let new_depth = [...consultantOptions];
    const { data } = await apiRequest.get(
      `/consultants?search=${value}&form=facility`
    );
    if (data) {
      console.log(data.data);
      new_depth = [...new_depth, ...data.data];
      setSalesManagerOptions(_.uniqBy(new_depth, 'id'));
    }
  };
  const loadFacilities = async (isMQ) => {
    /*if (!!facilities[0] && !isMQ) {
      return
    }*/
    await dispatch(updateFacilitiesFilters([appConf.facilitiesFilters[0], appConf.facilitiesFilters[1], !isMQ]));
    await dispatch(fetchFacilities(appConf.facilitiesFilters[0] ? appConf.facilitiesFilters[1] : undefined));
    await dispatch(setFacilitiesLoaded(true));
    await dispatch(
      updateFacilitiesFilters([
        appConf.facilitiesFilters[0],
        appConf.facilitiesFilters[1],
        false
      ])
    );
    await dispatch(fetchConsultants())
  };
  const [dataSource, setDataSource] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [pageStep, setPageStep] = useState(false);
  const returnDate = (date) => {
    return dayjs(date).format('YYYY-MM-DD');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => (inputRef = node)}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (<SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => inputRef.select());
      }
    },
    render: (text) => (<Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }} searchWords={[searchText]} autoEscape />)
  });
  let inputRef = React.useRef(null);
  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  let ReferralOrganizationOptions=makeSelectOptions(GetRawListOptions('referral_organization'))
  const columns = [
    { title: 'Office Name', dataIndex: 'name', key: 'name', fltr: { filterable: true, type: 'text' } },
    { title: 'Region', dataIndex: 'region', key: 'regionName', fltr: regionFilter },
    {
      title: 'Sub-region', dataIndex: 'sub_region', key: 'sub_region', fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(GetRawListOptions('sub_region')) }
    },
    { ...stateFilter, title: 'State', dataIndex: 'state', key: 'state' },
    { title: 'City', dataIndex: 'city', key: 'city', fltr: { filterable: true, type: 'text' } },
    { title: 'Contact Phone', dataIndex: 'phone_no', key: 'phoneNo', fltr: { filterable: true, type: 'phone' } },
    {
      title: 'Referral Organization',
      dataIndex: 'referral_source',
      key: 'referralSource',
      fltr: { filterable: true, type: 'select', options: ReferralOrganizationOptions }
    },
    {
      title: 'Regional VP Sales', dataIndex: 'sales_managers', key: 'salesManager', fltr: { filterable: true, type: 'text' },
      render: (managers) => (<Subtitle1 style={{ textAlign: 'left' }}> {managers?.map((item) => item.name).toString()} </Subtitle1>)
    },
    {
      title: 'Created At', dataIndex: 'created_at', key: 'created_at', fltr: { filterable: true, type: 'date' },
      render: (created_at) => (<>  {dayjs(created_at).format('MM/DD/YYYY')}  </>), visible: true
    },
    {
      title: 'Status', dataIndex: 'status', key: 'status',
      fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(['active', 'in-active']) },
      render: (status) => getDualStatus(status)
    },
    ...(activeUser?.is_qa_user?[{ width:15,
      visible: true,
      title: <FontAwesomeIcon color="grey" icon={['fas', 'table']} />,
      key: 'operation',
      render: (record) => <Tag className={`${(record.is_qa && activeUser?.is_qa_user)?'visible':'invisible'}`} icon={<EditOutlined/>} color="#55acee">QA</Tag>
    }]:[])
  ];

  const csmFilter = {
    title: 'Client Success Manager',
    dataIndex: 'client_success_managers',
    key: 'clientSuccessManager',
    fltr: {
      filterable: true,
      type: 'text',

    }
  }

  const [patientForm, setPatientForm] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
    
    async function handleFilterAction(action, values) {
      await dispatch(resetFacilitiesPagination());
      if (action === 'filter') {
        let pl = { filterData: values, pagination: facilitiesPagination };
        await dispatch(updateFacilitiesFilters([false, pl, true]));
        await dispatch(fetchFacilities(pl));
        await dispatch(updateFacilitiesFilters([true, pl, false]));
      }
      if (action === 'reset') {
        await dispatch(updateFacilitiesFilters([appConf.facilitiesFilters[0], undefined, true]));
        await dispatch(fetchFacilities());
        await dispatch(updateFacilitiesFilters([false, undefined, false]));
      }
      setIsVisible(false);
    }

  const [isExecuting, setisExecuting] = useState(false);
  // const formButtonText = isPreview ? 'Save Office' : 'Preview Office';

  return (
    (<div className="main-page">
      <Modal
        title="New Office"
        open={patientForm}
        onOk={dialogCloser(setPatientForm)}
        destroyOnClose
        width={650}
        onCancel={dialogCloser(setPatientForm)}
        closeIcon={modalClose(dialogCloser(setPatientForm))}
        maskClosable={false}
        footer={[
          <div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}   >
            {horStack([
              outlinedButton(dialogCloser(setPatientForm), 'Cancel', isExecuting),
              formSubmitButton('facilityForm', 'Create Office', isExecuting, 'Creating')
            ])}
          </div>
        ]}>
        <NewFacilityCard setisExecuting={setisExecuting} closeModal={dialogCloser(setPatientForm)} />
      </Modal>
      <div className="w-full">
        <Table
          title={() => (<TableFilters datasource={[...columns, csmFilter]} setIsVisible={setIsVisible} isVisible={isVisible} filters={appConf.facilitiesFilters[1]}
            actionButton={can_create_medical_facility_admin ? containedButton(() => setPatientForm(true), 'Add New', false, null, null, { icon: <PlusOutlined /> }) : null}
            windowState={windowState} handleFilterAction={handleFilterAction}
            showClear={appConf.facilitiesFilters[0]} loading={appConf.facilitiesFilters[2]}
          />)}
          loading={appConf.facilitiesFilters[2]}
          {...responsiveTbl(windowState)}
          locale={{ emptyText: emptyTable('facilities') }}
          onChange={(pagination, filters, sorter) =>
            onTableChange(pagination, filters, sorter, dispatch, fetchFacilities, appConf.facilitiesFilters, updateFacilitiesFilters)
          }
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                (async () => {
                  await dispatch(resetFacilityPatientPagination());
                  await dispatch(resetFacilityProspectPagination());
                  await dispatch(selectFacility(record));
                  if (can_view_medical_facility) {
                    navigate(`/facilities/${record.id}`);
                  }
                })().catch(e => console.log(e));
              } // click row
            };
          }}
          {...getSkels(appConf.facilitiesFilters[2], columns, facilities)}
          pagination={facilitiesPagination}
        />
      </div>
    </div>)
  );
}
  ;
const mapStateToProps = (state) => ({
  facilities: state.facilities.facilities,
  pagination: state.facilities.pagination,
  appConf: state.appConf,
  navs: state.navs,
  facilitiesPagination: state.facilities.facilitiesPagination,

  consultants: state.consultants.consultants,
  activeUser: state.auth.activeUser,
  masterList: state.masters.masterListRaw
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(FacilitiesList);
