import React, {useRef, useState} from 'react';
import {Headline6} from "../../../components";
import {Button, Image, Modal, Upload} from "antd";
import {
  appMessage,
  containedButton,
  dialogCloser,
  editButton,
  horStack,
  modalClose,
  outlinedButton
} from "../../../common/helpers";
import SignaturePad from "react-signature-canvas";
import {apiRequest} from "../../../app/Apis";
import {connect, useDispatch} from "react-redux";
import {errMsg, getToastId, toastUp, updateToast} from "../../../app/Reducers/reducerUtils";

const SignatureUpload = ({wcc, onDynamicFormEntriesResult, values, closeModal}) => {
  const dispatch = useDispatch()
  const [signatureModal, setSignatureModal] = useState(false);
  const sigCanvas = useRef({});
  const [isExecuting, setIsExecuting] = useState(false)
  
  const [fileList, setFileList] = useState([]);
  const onChange = ({fileList: newFileList}) => setFileList(newFileList);
  const clear = () => sigCanvas.current.clear();

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]); else byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type: mimeString});
  }
  
  const save = async () => {
    await setIsExecuting(true)
    let formData = new FormData();
    const file_64 = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
    let file = dataURItoBlob(file_64)
    // console.log({file, type: typeof file})
    formData.append("file", file);
    const {data} = await apiRequest.post('/files', formData)
    const signature_id = data.data.id
    await setIsExecuting(false)
    onDynamicFormEntriesResult({values: {...values, signature_id}})
    await setSignatureModal(false)
    // const signature = await apiRequest.post(`consultants/${wcc.id}/signature`, {signature_id})
  };
  
  const uploadSignature = async () => {
    if (!fileList[0]) {
      let payload={...values}
      if (!values?.signature?.id && !values?.signature_path) {
        toastUp('Signature is required', false)
        return
      }
      /*if (values?.signature?.id ) {
        //Push changes if it is an MD update
        payload={...payload, signature_id: values?.signature?.id, signature_path: values?.signature?.url}
        onDynamicFormEntriesResult({ values: payload })
        await closeModal(false)
      }else {
        // Drop changes since WCC only updates signature and none is added
        appMessage('No changes made','info')
        await closeModal(false)
      }*/
      
      //Push changes if it is an MD update
      payload={...payload, signature_id: values?.signature?.id, signature_path: values?.signature?.url}
      onDynamicFormEntriesResult({ values: payload })
      await closeModal(false)
      return
    }
    const toastId = getToastId("updating signature")
    try {
      let formData = new FormData();
      // console.log({fileList})
      formData.append("file", fileList[0].originFileObj);
      const {data} = await apiRequest.post('/files', formData)
      const signature_id = data.data.id
      // const signature = await apiRequest.post(`consultants/${wcc.id}/signature`, {signature_id})
      await setFileList([])
      onDynamicFormEntriesResult({values: {...values, signature_id}})
      updateToast(toastId, `uploaded successfully`, true)
      await closeModal(false)
    } catch (e) {
      console.log(e)
      updateToast(toastId, errMsg(e, false), false)
      
    }
  }
  
  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  return (
    <div className="card-box">
      <Modal
        title="Signature Pad"
        open={signatureModal}
        onOk={dialogCloser(setSignatureModal)}
        onCancel={dialogCloser(setSignatureModal)}
        closeIcon={modalClose(dialogCloser(setSignatureModal))}
        maskClosable={false}
        width={600}
        footer={[
          <div className="entity-filters flex-boy-row" style={{justifyContent: 'flex-end'}}>
            {horStack([
              outlinedButton(dialogCloser(setSignatureModal), 'Cancel', isExecuting),
              outlinedButton(clear, 'Clear', isExecuting),
              containedButton(save, 'Save', isExecuting, 'Saving')
            ])}
          </div>
        ]}>
        <SignaturePad ref={sigCanvas} canvasProps={{width: 552, height: 500, className: 'signatureCanvas'}}/>
      </Modal>
      <div className="flex-boy-row-space" style={{width: "100%"}}>
        <Headline6>Signature</Headline6>
        {editButton(() => setSignatureModal(true))}
      </div>
      <div className="flex-boy-row-space">
        <div style={{width: "100%", textAlign: 'left', marginRight: '10px'}}>
          {(!!wcc?.signature_path && !fileList[0]) ? <Image
            src={wcc.signature_path ? wcc.signature_path : require("../../../assets/Frame89.svg").default}
            style={{height: "180px"}}/> : null}
        </div>
        <div>
          <Upload accept={"image/*"} listType="picture-card" fileList={fileList} onChange={onChange} onPreview={onPreview}> Update signature </Upload>
          {(fileList.length < 1 && !wcc?.signature_path&&!values?.signature?.id && !values.signature_path) ? null :
            <Button type="primary" onClick={uploadSignature} disabled={(fileList.length === 0&& !wcc?.signature_path)} className="login-form-button" style={{ marginTop: 16 }}> Save </Button>
          }
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => ({
  us_states: state?.masters.billingRegions,
  medicalDirectors: state?.masters.medicalDirectors,
  loadingMedicalDirectors: state?.masters.loadingMedicalDirectors
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SignatureUpload)