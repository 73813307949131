import React, {useEffect, useState} from 'react';
import {Headline6, Subtitle1} from "../../../components";
import {Grid} from "antd";
import {fetchOpenPatientWounds, resetPatientOpenWoundsPagination, selectWound, setWoundsLoaded, updatePatientOpenWoundsFilters} from "../../../app/Reducers";
import {emptyFun, emptyTable, getWoundStatus, makeAutoCompleteOptions} from "../../../common/helpers";
import {connect, useDispatch} from "react-redux";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import {woundStatuses} from "../../../common/lists";
import {RightOutlined} from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';

const { useBreakpoint } = Grid;

function PatientOpenWounds({ wounds, navs, appConf, patient, patientOpenWoundsPagination, changTab, ...props }) {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState = useBreakpoint()
  const dispatch = useDispatch()
  const columns2 = [
    {
      title: 'Wound',
      key: 'location',
      fltr: { filterable: true, type: 'text' },
      render: (record) => (<Subtitle1 style={{ textAlign: 'left' }}>{record?.side} {record?.position} {record?.location} </Subtitle1>)
    }, {
      title: 'Wound Status', dataIndex: 'status', key: 'status', fltr: {
        filterable: true, type: 'autocomplete',
        options: makeAutoCompleteOptions(woundStatuses)
      }, render: (status) => getWoundStatus(status?.toLowerCase())
    }]
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [isAppVisible, setIsAppVisible] = useState(false);
  const { message } = useSubscription(`view_patient_wounds/${patientId}`, { qos: 2, nl: true });
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await loadWounds(true)
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  
  useEffect(() => {
    (async () => {
      (async () => {
        await loadWounds(false)
      })().catch(e => console.log(e))
    })().catch(e => console.log(e))
    return emptyFun
  }, [])
  const loadWounds = async (isMQMessage) => {
    /*if (!!wounds[0] && !isMQMessage && wounds.every(item => item.patient_id === patientId)) {
      return
    }*/
    await dispatch(updatePatientOpenWoundsFilters([appConf.patientOpenWoundsFilters[0], appConf.patientOpenWoundsFilters[1], !isMQMessage]))
    await dispatch(fetchOpenPatientWounds(appConf.patientOpenWoundsFilters[0] ? appConf.patientOpenWoundsFilters[1] : { id: patientId }))
    await dispatch(updatePatientOpenWoundsFilters([appConf.patientOpenWoundsFilters[0], appConf.patientOpenWoundsFilters[1], false]))
    await dispatch(setWoundsLoaded(patientId))
  }
  
  async function handleWoundFilterAction(action, values) {
    await dispatch(resetPatientOpenWoundsPagination())
    console.log('handleWoundFilterAction')
    if (action === 'filter') {
      console.log('FILTERS:  ', JSON.stringify({ ...values, toLoad: 'patientAppointments', endpoint: '/appointments?patient=1' }));
      let pl = { filterData: { patient: patientId, ...values }, pagination: patientOpenWoundsPagination }
      await dispatch(updatePatientOpenWoundsFilters([false, pl, true]))
      await dispatch(fetchOpenPatientWounds(pl))
      await dispatch(updatePatientOpenWoundsFilters([true, pl, false]))
    }
    if (action === 'reset'/* && appConf.patientOpenWoundsFilters[0]*/) {
      await dispatch(updatePatientOpenWoundsFilters([appConf.patientOpenWoundsFilters[0], undefined, true]))
      await dispatch(fetchOpenPatientWounds(patient))
      await dispatch(updatePatientOpenWoundsFilters([false, undefined, false]))
    }
    // const response = await apiRequest.get(`/appointments?patient=${patient.id}`,{params:{...values, toLoad: 'patientAppointments'}})
    setIsAppVisible(false)
  }
  
  return (<div className="card-box">
    <div className="flex flex-row justify-between items-center w-full">
      <Headline6> Wounds</Headline6>
      <RightOutlined onClick={() => changTab("Wounds")}/>
    </div>
    
    
    {wounds.length ? wounds.slice(0, 3).map(item => {
      return (
        <div onClick={async () => {
          dispatch(selectWound({ navigate, record: item }))
          navigate(`/wounds/${item.id}`)
        }} className="rounded-md w-full p-2 my-2" key={item.id} style={{ background: '#ebeff4' }}>
          <div className="flex flex-row w-full mt-2 justify-between items-center">
            <div className="flex flex-row items-center justify-between w-full">
              
              <Subtitle1 style={{ textAlign: 'left' }}>{item?.side} {item?.position} {item?.location} </Subtitle1>
            
            </div>
            {getWoundStatus(item.status?.toLowerCase())}
          </div>
        
        </div>
      )
    }) : <div className="flex  mx-auto justify-center" style={{ height: "180px", width: '180px', marginBottom: '25px' }}> {emptyTable()} </div>}
  </div>);
}

const mapStateToProps = (state) => ({
  wounds: state.patients.patientOpenWounds, patient: state.patients.selectedPatient,
  appConf: state.appConf, navs: state.navs,
  patientOpenWoundsPagination: state.patients.patientOpenWoundsPagination
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PatientOpenWounds)
