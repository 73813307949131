import React, {useEffect, useState} from 'react';
import {Subtitle1} from "../../../components";
import {Form, Input, Modal, Table} from "antd";
import {
  deleteAppointment,
  fetchPatientAppointments,
  resetPatientAppointmentPagination,
  selectAppointment,
  setPatientAppointmentsLoaded,
  updatePatientAppointment,
  updatePatientAppointmentsFilters
} from "../../../app/Reducers";
import {
  addButton,
  dialogCloser,
  emptyFun,
  emptyTable,
  formSubmitButton,
  getAppointmentStatus,
  GetRawListOptions,
  getSkels,
  horStack,
  kebabMenus,
  makeAutoCompleteOptions,
  makeSelectOptions,
  modalClose,
  onTableChange,
  outlinedButton,
  responsiveTbl,
  stateFilter
} from "../../../common/helpers";
import dayjs from "dayjs";
import {connect, useDispatch} from "react-redux";

import SheduleAppointment from "../../Appointments/components/ScheduleAppointment";
import TableFilters from "../../../components/InputComponents/TableFilters";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import KebabMenu from "../../../components/KebabMenu";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import {apiRequest} from "../../../app/Apis";
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';

function PatientAppointments({ navs, appConf, patient,  appointments, patientAppointmentPagination, appWindow, activeUser, ...props }) {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const can_update_appointment = usePermissionCheck('update_appointment', false, [])
  const can_view_appointment = usePermissionCheck('view_appointment', false, [])
  const can_create_appointment = usePermissionCheck('create_appointment', false, [])
  const can_delete_appointment = usePermissionCheck('delete_appointment', false, [])
  const dateFormat = 'YYYY-MM-DD';
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [isOpen, setOpen] = useState(false);
  const [isExecuting, setisExecuting] = useState(false)
  const [editingForm, setEditingForm] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [patientAppointments, setpatientAppointments] = useState([])
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  let onCell = (record, rowIndex) => ({
    onClick: async event => {
      await dispatch(selectAppointment({ navigate, record: record }))
      // let initialAppointment = appointments.find(item => (item.appointment_visit_type === 'new visit' && item.wounds.some(item => item?.id === record?.wounds[0]?.id)))
      // console.log({ initialAppointment })
      // await dispatch(selectPatientInitialAppointment(initialAppointment))
      if (can_view_appointment) {
        navigate(`/appointments/${record.id}`)
      }
    }
  })
  const appointment_columns = [{
    title: 'Provider', dataIndex: ['consultant', 'name'], key: 'wcc', fltr: { filterable: !activeUser?.consultantProfile, type: 'text' }, onCell,
    render: (name) => (<>
      <div className="flex-boy-row-space">
        <Subtitle1 style={{ textAlign: 'left', flex: 1, marginLeft: '10px' }}>{name}</Subtitle1>
      </div>
    </>)
  }, {
    title: 'Date of appointment',
    sorter: { multiple: 3, compare: (a, b) => dayjs(a.start_at, dateFormat).isBefore(dayjs(b.start_at, dateFormat)) },
    dataIndex: 'start_at',
    key: 'date',
    fltr: { filterable: true, type: 'date' },
    onCell,
    render: (start_at, record) => (
      <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(start_at).format("MM/DD/YYYY")}</Subtitle1>)
  }, {
    title: 'Time Slot', dataIndex: 'start_at', key: 'time_slot', render: (start_at, record) => (<Subtitle1
      style={{ textAlign: 'left' }}>{dayjs(start_at).format("h:mm a")} - {dayjs(record.end_at).format("h:mm a")}</Subtitle1>),
    onCell
  },/* {
    title: 'Wound side', key: 'wound', fltr: { filterable: false },
    render: (record) => [record.wounds[0]].map(item => <Subtitle1 style={{ textAlign: 'left' }}>{item?.side}</Subtitle1>),
    onCell
  },{
    title: 'Wound Position', key: 'wound', fltr: { filterable: false },
    render: (record) => [record.wounds[0]].map(item => <Subtitle1 style={{ textAlign: 'left' }}>{item?.position}</Subtitle1>),
    onCell
  },*/{
    title: 'Wound location(s)', key: 'wound', fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(GetRawListOptions('wound_location')) },
    render: (record) => record.wounds.map(item => item?.location).join(', '),
    onCell
  }, {
    title: 'Visit Type', dataIndex: 'appointment_visit_type', key: 'visitType', fltr: {
      filterable: true, type: 'select', options: makeSelectOptions(['established visit', 'new visit'])
    }, onCell
  }, {
    title: 'Appointment Status', dataIndex: 'status', key: 'status', fltr: {
      filterable: true, type: 'select',
      options: makeSelectOptions(['scheduled', 'rescheduled', 'cancelled_by_patient', 'cancelled_by_wcc', 'missed', 'completed', 'in_progress', 'missed_by_wcc', 'missed_by_patient', 'visited'])
    }, render: (status) => getAppointmentStatus(status?.toLowerCase()), onCell
  },
    {
      fltr: { filterable: false }, render: (record) => {
        const actionMenus = kebabMenus(( can_update_patient_resource && record.status !== 'completed' && can_update_appointment), can_view_audit_logs, can_delete_appointment)
        return !patient.deleted_at && !activeUser.facilityProfile &&
          <KebabMenu menus={actionMenus}
                     recordItem={record}
                     handleMenuClick={kebabMenuClick} resource={'appointment'} handleOk={() => handleOk(record.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
                     showConfirmDialog={selected === record.id}/>;
      }
    }
  ]
  
  
  const [selected, setSelected] = React.useState(null);
  const handleCancel = () => setSelected(0);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const showPopconfirm = (id) => setSelected(id);
  
  const actionMenus = kebabMenus(( can_update_patient_resource), can_view_audit_logs, can_delete_appointment)
  
  async function kebabMenuClick(e, record) {
    console.log(actionMenus[e.key])
    await setAppointment(record)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true)
        await setOpen(true)
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
    }
  }
  
  
  const handleOk = async (id) => {
    setappointmentTodeleteID(id)
    setreasonModal(true)
    setSelected(0)
  };
  
  
  const [appointmentTodeleteID, setappointmentTodeleteID] = useState(null)
  const [reasonStatus, setreasonStatus] = useState(null)
  const [reasonModal, setreasonModal] = useState(false)
  const reasonFinish = async (values) => {
    await setConfirmLoading(true);
    await dispatch(deleteAppointment({ appointment_id: appointmentTodeleteID, ...values, patient_id: patient.id }));
    await setSelected(0);
    await setConfirmLoading(false);
    setreasonModal(false)
  }
  
  useEffect(() => {
    fetchPatientAppointmentsWithWound().catch(e => console.log(e))
    return emptyFun
  }, [patientId])
  const [isVisible, setIsVisible] = useState(false);
  const { message } = useSubscription(`view_patient_appointments/${patientId}`, { qos: 2, nl: true });
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await loadAppointments(true)
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  const loadAppointments = async (isMQMessage) => {
    /*if (!!appointments[0] && !isMQMessage && appointments.every(item => item?.patient?.id === patientId)) {
      return
    }*/
    await dispatch(updatePatientAppointmentsFilters([appConf.patientAppointmentsFilters[0], appConf.patientAppointmentsFilters[1], !isMQMessage]))
    await dispatch(fetchPatientAppointments(appConf.patientAppointmentsFilters[0] ? appConf.patientAppointmentsFilters[1] : patientId))
    await dispatch(updatePatientAppointmentsFilters([appConf.patientAppointmentsFilters[0], appConf.patientAppointmentsFilters[1], false]))
    await dispatch(setPatientAppointmentsLoaded(patientId))
  }
  useEffect(() => {
    (async () => await loadAppointments(false))().catch(e => console.log(e))
    return emptyFun
  }, [])
  
  async function handleAppointmentFilterAction(action, values) {
    await dispatch(resetPatientAppointmentPagination())
    console.log('handleAppointmentFilterAction')
    if (action === 'filter') {
      console.log('FILTERS:  ', JSON.stringify({ ...values, toLoad: 'wounds', endpoint: '/patients/1/wounds' }));
      let pl = { filterData: { patient: patientId, ...values }, pagination: patientAppointmentPagination }
      await dispatch(updatePatientAppointmentsFilters([false, pl, true]))
      await dispatch(fetchPatientAppointments(pl))
      await dispatch(updatePatientAppointmentsFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updatePatientAppointmentsFilters([appConf.patientAppointmentsFilters[0], undefined, true]))
      await dispatch(fetchPatientAppointments(patientId))
      await dispatch(updatePatientAppointmentsFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  const fetchPatientAppointmentsWithWound = async () => {
    //todo request new endpoint
    const wounds = await apiRequest.post(`/patient-established-visit/${patientId}/wounds`)
    if (wounds && wounds.data.data) {
      console.log(wounds.data.data)
      await setpatientAppointments(wounds.data.data)
    }
  }
  
  const sortAppointmentsByDate = async (sortOrder) => {
    let sortedAppointments = [...appointments];
    if (sortOrder === 'descend') {
      appointments = sortedAppointments.sort((a, b) => dayjs(a.start_at, dateFormat).isBefore(dayjs(b.start_at, dateFormat)) ? 1 : dayjs(a.start_at, dateFormat).isAfter(dayjs(b.start_at, dateFormat)) ? -1 : 0);
    } else {
      appointments = sortedAppointments.sort((a, b) => dayjs(a.start_at, dateFormat).isAfter(dayjs(b.start_at, dateFormat)) ? 1 : dayjs(a.start_at, dateFormat).isBefore(dayjs(b.start_at, dateFormat)) ? -1 : 0)
    }
    await dispatch(updatePatientAppointment(sortedAppointments))
  }
  return (
    (<div className="card-box">
      {(!!appointment && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false}
                                                             resourceDescription={`${appointment.patient.name}'s appointment`} resourceType={'Appointment'}
                                                             defaultValues={{ auditableId: appointment?.id, auditable: 'appointment', format: 'json' }}/>}
      <Modal
        
        title={editingForm ? 'Edit appointment' : "Add appointment"}
        open={isOpen}
        onCancel={dialogCloser(setOpen)}
        closeIcon={modalClose(dialogCloser(setOpen))}
        maskClosable={false}
        destroyOnClose={true}
        width={650}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setOpen), 'Cancel', isExecuting),
            formSubmitButton('appointmentForm', editingForm ? 'Update' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
        </div>]}>
        <SheduleAppointment appointment={appointment} editingForm={editingForm} patient={patient}
                            setisExecuting={setisExecuting} closeModal={dialogCloser(setOpen)}/>
      </Modal>
      <Modal
        title={`Please add a Reason`}
        open={reasonModal}
        destroyOnClose={true}
        
        onOk={() => setreasonModal(false)}
        onCancel={() => setreasonModal(false)}
        closeIcon={modalClose(() => setreasonModal(false))}
        maskClosable={false}
        width={1200}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(() => {
            setreasonModal(false)
            setSelected(0)
          }, 'Cancel', isExecuting),
            formSubmitButton('reasonForm', 'Add Reason', isExecuting, 'Updating')])}
        </div>]}
      >
        <Form
          name="reasonForm"
          onFinish={reasonFinish}
          layout="vertical"
        >
          
          <Form.Item
            style={{ width: '100%' }}
            label="Delete Reason"
            name="delete_reason"
            rules={[{ required: true, message: 'this field is required!' }]}
            wrapperCol={{ span: 24 }}
          >
            <Input autoComplete={'off'}/>
          </Form.Item>
        </Form>
      
      </Modal>
      <div className="w-full">
        <Table
          // sortDirections={['ascend', 'descend', 'ascend']}
          title={() => <TableFilters datasource={[...appointment_columns, stateFilter]}
                                     setIsVisible={setIsVisible}
                                     isVisible={isVisible} handleFilterAction={handleAppointmentFilterAction}
                                     actionButton={((patient.status === 'active' || patient.status === "hospitalized") && can_update_patient_resource && can_create_appointment && !patient.deleted_at) && addButton(() => {
                                       setEditingForm(false)
                                       setOpen(true);
                                     })}
                                     showClear={appConf.patientAppointmentsFilters[0]} filters={appConf.patientAppointmentsFilters[1]}
                                     loading={appConf.patientAppointmentsFilters[2]}/>}
          loading={(appConf.patientAppointmentsFilters[2])} size={'small'}{...responsiveTbl(appWindow)}
          locale={{ emptyText: emptyTable('patient appointments') }}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchPatientAppointments, appConf.patientAppointmentsFilters,
            updatePatientAppointmentsFilters, { patient: patientId }, patientAppointmentPagination)}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                /*dispatch(selectAppointment({navigate, record: record}))
                navigate(`/appointments/${record.id}`)*/
              }
            }
          }}
          pagination={patientAppointmentPagination}
          {...getSkels((appConf.patientAppointmentsFilters[2]), appointment_columns, appointments.map(item => ({ ...item, location: item.wounds[0]?.location })))} />
      </div>
    </div>)
  );
}

const mapStateToProps = (state) => ({ patient: state.patients.selectedPatient,
  patientAppointmentPagination: state.patients.patientAppointmentPagination, activeUser: state.auth?.activeUser,
  appointments: state.patients.patientAppointments, appConf: state.appConf, navs: state.navs, appWindow: state.appWindow
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PatientAppointments)
