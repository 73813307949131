/* eslint-disable no-unused-vars */
import React, {useCallback, useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'

import {Col, Form, Row, Select} from 'antd'
import {createDiagnosis, deleteDiagnoses, fetchDiagnoses, resetPatientDiagnosisPagination, updateDiagnosis} from '../../../../app/Reducers'
import dayjs from 'dayjs';
import {
  addButton,
  appLog,
  appMessage,
  boldKeyCapitalizedValue,
  cleanObject,
  dialogCloser,
  emptyDiagnosis,
  emptyFun,
  executeChunkFn,
  getDiagnosisStatus,
  GetRawListOptions,
  kebabMenus,
  makeSelectOptions
} from "../../../../common/helpers";
import KebabMenu from "../../../../components/KebabMenu";
import AuditLogViewer from "../../../../components/AuditLogs/AuditLogViewer";
import {useSubscription} from "../../../../hooks/MqttHooks/useSubscription";
import {updatePatientDiagnosisFilters} from '../../../../app/Reducers/ConfigSlice';
import _ from 'lodash';
import {apiRequest} from '../../../../app/Apis';
import {errMsg, toastUp} from '../../../../app/Reducers/reducerUtils';
import * as Sentry from "@sentry/react";
import DynamicModalForm from '../../../../components/DynamicModalForm';
import TableFilters from '../../../../components/InputComponents/TableFilters';
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';

 const DiagnosisSection = ({ diagnoses, patient,  activeUser, appConf, patientDiagnosisPagination, masterList }) => {
  const dateFormat = 'YYYY-MM-DD';
   const location = useLocation()
   const navigate = useNavigate();
  const [form] = Form.useForm();
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [isExecuting, setisExecuting] = useState(false);
  const [diagnosis, setDiagnosis] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  const dispatch = useDispatch()
  const { message } = useSubscription(`view_patient_diagnosis/${patientId}`, { qos: 2, nl: true });
  const [problemListOptions, setproblemListOptions] = useState([]);
  const [diagnosisOptions, setDiagnosisOptions] = useState([]);
  const [drugType, setDrugType] = useState(null);
  const [selectedProblem, setselectedProblem] = useState(diagnosis?.problem_statement);
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  const can_create_patient_diagnosis = usePermissionCheck('create_patient_diagnosis', false, [])
  const can_update_patient_diagnosis = usePermissionCheck('update_patient_diagnosis', false, [])
  const can_delete_patient_diagnosis = usePermissionCheck('delete_patient_diagnosis', false, [])
  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message)
      loadDiagnoses(true)
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    loadDiagnoses(false)
    return emptyFun
  }, [])
  const loadDiagnoses = (isMQMessage) => {
    /*if (!!diagnoses && !!diagnoses[0] && !isMQMessage && diagnoses.every(item => item.patient_id === patientId)) {
      return
    }*/
    dispatch(fetchDiagnoses(patientId))
  }
  const [selected, setSelected] = React.useState(0);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const showPopconfirm = (id) => setSelected(id);
  const handleCancel = () => setSelected(0)
  const handleOk = async (diagnosisID) => {
    await setConfirmLoading(true);
    let params = { diagnosisID, patientId }
    await dispatch(deleteDiagnoses(params))
    await dispatch(fetchDiagnoses(patientId))
    await setSelected(0)
    await setConfirmLoading(false);
  };
  
  const GetOptions = (system_name) => {
    return masterList.find(item => item.system_name === system_name)?.options?.map(item => ({ ...item, label: item, value: item, actualValue: item })) || []
  }
  
  const actionMenus = kebabMenus((can_update_patient_diagnosis && !patient.deleted_at), can_view_audit_logs, (!activeUser.facilityProfile && can_delete_patient_diagnosis))
  
  async function kebabMenuClick(e, diagnosis_) {
    await setDiagnosis(diagnosis_)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        openDiagnosisForm(diagnosis_).catch(appLog);
        break
      case 'Delete':
        showPopconfirm(diagnosis_.id)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
        emptyFun()
    }
  }
  
  const debouncedChangeHandlerDiagnosis = useCallback(_.debounce(function (value) {
    getDiagnosesList(value);
  }, 1000), []);
  const getProblemStatementList = async (value) => {
    console.log(value);
    setproblemListOptions([]);
    setDiagnosisOptions([]);
    if (value.length > 1) {
      const { data } = await apiRequest.get(`/integrations/dr_first/search_diagnoses?search=${value}`);
      // setOptions(data);
      if (data) {
        // console.log('getProblemStatementList: ', data.data);
        if (data?.data?.length) {
          await setproblemListOptions(_.uniqBy(data.data, 'code').map(item => ({
            ...item, label: `${item.code_type}-${item.code}-${item.description}`, value: `${item.code_type}-${item.code}-${item.description}`,
            actualValue: `${item.code_type}-${item.code}-${item.description}`
          })));
        } else {
          setproblemListOptions([]);
        }
      }
    }
  };
  
  const getDiagnosesList = async (value) => {
    // await setproblemListOptions([]);
    await setDiagnosisOptions([]);
    if (value.length > 0) {
      let lowerCaseSearchTerm = value.toLowerCase();
      let arr = masterList.find(item => item.system_name === "icd_code")?.options || [];
      let data = arr.filter(string => string.toLowerCase().includes(lowerCaseSearchTerm));
      if (data) {
        
        // await setproblemListOptions(data.data.map(item => ({...item, label: item.description, value: item.description, actualValue: item.description})));
        await setDiagnosisOptions(data.map(item => ({ ...item, label: item, value: item, actualValue: item })));
      } else {
        // setproblemListOptions([]);
        setDiagnosisOptions([]);
      }
    }
    
  };
  
  const disabledFutureDates = (current) => {
    let customDate = dayjs();
    return current && current > dayjs(customDate, dateFormat);
  };
  const disabledPastDates = (current) => {
    let customDate = dayjs();
    return current && current < dayjs(customDate, dateFormat);
  };
  const onDrugTypeChange = (e) => {
    console.log({ e })
    setproblemListOptions([]);
    setDiagnosisOptions([]);
    form.setFieldsValue({ name: undefined, problem_statement: undefined })
    setDrugType(e);
  };
  const debouncedChangeHandler = useCallback(_.debounce(value => {
    getProblemStatementList(value).catch(appLog)
  }, 1000), []);
  
  async function handleFilterAction(action, values) {
    // console.log({action, values})
    await dispatch(resetPatientDiagnosisPagination())
    if (action === 'filter') {
      let pl = { filterData: { patient: patientId, ...values }, pagination: patientDiagnosisPagination }
      await dispatch(updatePatientDiagnosisFilters([false, pl, true]))
      await dispatch(fetchDiagnoses(pl))
      await dispatch(updatePatientDiagnosisFilters([true, pl, false]))
    }
    if (action === 'reset'/* && appConf.patientDiagnosisFilters[0]*/) {
      await dispatch(updatePatientDiagnosisFilters([appConf.patientDiagnosisFilters[0], undefined, true]))
      await dispatch(fetchDiagnoses(patientId))
      await dispatch(updatePatientDiagnosisFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  useEffect(() => {
    // appLog({drugType, problemListOptions, diagnosisOptions})
    appLog("Drug Type:::", drugType)
    return emptyFun
  }, [drugType, problemListOptions, diagnosisOptions])
  const onProblemSelect = (value, option) => {
    appLog({ value, option })
    let problem = problemListOptions.find((item) => `${item.code_type}-${item.code}-${item.description}` === value);
    setselectedProblem(option);
  };
  let diagnosisFormInputs = {
    fields: [
      {
        name: 'is_drug', label: 'Diagnosis Type', required: true, type: 'select', options: [{ label: 'Drug Based', value: 'Drug Based' }, { label: 'Wound Based', value: 'Wound Based' }],
        props: { onSelect: onDrugTypeChange }, fullRow: true, filterable: true, initialValue: drugType === 'Drug Based' ? true : false, value: drugType === 'Drug Based' ? true : false
      },
      ...(drugType === 'Drug Based' ? [{
        name: 'problem_statement', label: 'Problem Statement', required: true, type: 'autocomplete', options: problemListOptions,
        props: { showSearch: true, filterOption: false, onSelect: onProblemSelect, onSearch: (value) => debouncedChangeHandler(value) }, fullRow: true, filterable: true
      }] : drugType === 'Wound Based' ? [{
        name: 'name', label: 'Diagnosis', required: true, type: 'autocomplete', options: diagnosisOptions,
        props: {
          onSearch: (value) => debouncedChangeHandlerDiagnosis(value), showSearch: true, filterOption: false,
          onChange: (e) => setDefaultDynamicModalFormValues({ ...defaultDynamicModalFormValues, name: e })
        }, fullRow: true, filterable: true
      }] : []),
      { name: 'diagnosis_date', label: "Date of Diagnosis", required: true, type: 'date', dateFormat, props: { disabledDate: disabledFutureDates }, filterable: true },
      {
        name: 'status', label: "Problem Status", required: true, type: 'select', options: makeSelectOptions(GetRawListOptions('problem_status')), filterable: true
      },
      { name: 'notes', label: 'Notes', required: false, type: 'textarea', fullRow: true, shouldNotShow: isVisible }
    ]
  };
  let dynamicFormInputFields = {
    'Edit Diagnosis': diagnosisFormInputs, 'Add Diagnosis': diagnosisFormInputs
  }
  const openDiagnosisForm = async (record) => {
    // appLog({record})
    if (!!record) {
      //Show problem statement or 'Diagnosis' name
      await setDrugType(!!record?.problem_statement ? 'Drug Based' : 'Wound Based');
      if (!!record?.problem_statement) {
        //initialise default selected problem
        let item = record?.problem_statement
        //create initial respective listOptions for default values to appear in form
        await setselectedProblem(record?.problem_statement);
        await setproblemListOptions([{ ...item, label: item.description, value: item.description, actualValue: item.description }]);
        record = { ...record, problem_statement: !!record?.problem_statement ? record?.problem_statement?.description : undefined }
      } else if (!!record.name) {
        await setDiagnosisOptions([{ label: record.name, value: record.name, actualValue: record.name }]);
      }
    }
    // appLog({record})
    await setFormName(!!record ? 'Edit Diagnosis' : 'Add Diagnosis');
    await setDefaultDynamicModalFormValues(!!record ? cleanObject({
      ...record, diagnosis_date: dayjs(record.diagnosis_date), is_drug: !!record?.problem_statement ? 'Drug Based' : 'Wound Based'
    }) : undefined);
    await setDynamicFormOpen(true);
  };
  const addClick = () => openDiagnosisForm().catch(appLog)
  
  /**
   * This function is handling returned form entries from the dynamic form
   * */
  const onDynamicFormEntriesResult = async (entries) => {
    console.log({ entries })
    let is_drug = entries?.values?.is_drug === 'Drug Based'
    const drugBool = entries?.values?.is_drug === 'Drug Based' ? 'true' : 'false'
    
    if (!drugBool) {
      entries.values.problem_statement = null
    } else {
      entries.values.problem_statement = selectedProblem
    }
    //Todo: this is temporal solution. Remove this check, make name required in the inputs and remove onChange from name input
    if (!is_drug && (!entries?.values?.name || entries?.values?.name?.trim() === '')) {
      appMessage('No diagnosis entered', 'error')
      return
    }
    
    let payload = { data: { ...entries.values, patient_id: patientId, is_drug: drugBool, ...(is_drug && { problem_statement: selectedProblem }) }, patient_id: patientId }
    console.log({ payload })
    try {
      switch (formName) {
        case 'Add Diagnosis':
          await executeChunkFn(dispatch, createDiagnosis, payload, setisExecuting, dialogCloser(setDynamicFormOpen), null)
          break;
        case 'Edit Diagnosis':
          await executeChunkFn(dispatch, updateDiagnosis, payload, setisExecuting, dialogCloser(setDynamicFormOpen), null)
          break;
        default:
          return
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      toastUp(errMsg(e, false, formName), false);
    }
  };
  return (<>
    {(!!diagnosis && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} resourceType={'Patient'}
                                                         loading={false} resourceDescription={`${patient.name}'s diagnosis`}
                                                         defaultValues={{ auditableId: diagnosis?.id, auditable: 'diagnosis', format: 'json' }}/>}
    
    <TableFilters datasource={!!drugType?diagnosisFormInputs.fields:diagnosisFormInputs.fields}
                  setIsVisible={setIsVisible}
                  isVisible={isVisible}
                  actionButton={(can_create_patient_diagnosis && !patient.deleted_at) && addButton(addClick)}
                  handleFilterAction={handleFilterAction}
                  filters={appConf.patientDiagnosisFilters[1]}
                  inputFields={!!diagnosisFormInputs ? diagnosisFormInputs : undefined}
                  showClear={appConf.patientDiagnosisFilters[0]}
                  loading={appConf.patientDiagnosisFilters[2]}/>
    {(isDynamicFormOpen && !!formName) && (
      <DynamicModalForm
        setDynamicFormOpen={setDynamicFormOpen}
        isDynamicFormOpen={isDynamicFormOpen}
        inputFields={dynamicFormInputFields[formName]}
        onDynamicFormEntriesResult={onDynamicFormEntriesResult}
        closeModal={dialogCloser(setDynamicFormOpen)}
        isExecuting={isExecuting}
        defaultValues={defaultDynamicModalFormValues}
        formName={formName}
      />
    )}
    
    {!!diagnoses && !!diagnoses[0] ?
      <div className="">
        <Row gutter={16}>
          <Col span={24}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {diagnoses?.map((item, index) => {
                return (
                  <div className="card-box" style={{ margin: 10, width: '20%' }}>
                    <div className="flex-boy-row-space"
                         style={{ width: "100%", paddingBottom: '2em' }}>
                      {/*<Subtitle1>{item.name}</Subtitle1>*/}
                      {getDiagnosisStatus(item.status)}
                      <KebabMenu menus={actionMenus}
                                 recordItem={item}
                                 handleMenuClick={kebabMenuClick} resource={'diagnosis'} handleOk={() => handleOk(item.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
                                 showConfirmDialog={selected === item.id}/>
                    </div>
                    <div style={{ width: "100%" }}>
                      {boldKeyCapitalizedValue('Diagnosis Type', item?.problem_statement ? "Drug based" : 'Wound based')}
                      {item?.problem_statement && boldKeyCapitalizedValue('Problem Statement:', item.problem_statement?.description)}
                      {item?.name && boldKeyCapitalizedValue('Diagnosis:', item.name)}
                      {boldKeyCapitalizedValue('Diagnosis Date:', dayjs(item.diagnosis_date).format('MM/DD/YY'))}
                      {boldKeyCapitalizedValue('Notes:', item.notes || 'N/A')}
                    </div>
                  </div>
                )
              })}
            </div>
          </Col>
        </Row>
      </div> :
      emptyDiagnosis('Diagnosis', /*(can_update_patient_resource && !patient.deleted_at) ? containedButton(addClick, 'Add Diagnosis', false, null, {}, {
        icon: <PlusOutlined />
      }) :*/ null)
      
    }
  </>);
}
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser, diagnoses: state.patients.healthData.diagnosis,
  patient: state.patients.selectedPatient, appConf: state.appConf,
  patientDiagnosisPagination: state.patients.patientDiagnosisPagination,
  masterList: state.masters.masterListRaw
  
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(DiagnosisSection)
