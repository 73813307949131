/* eslint-disable default-case */
import {Button, DatePicker, Popconfirm, Radio} from 'antd'
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import dayjs from 'dayjs'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {containedButton} from '../../../common/helpers'
import {MenuFoldOutlined, MenuUnfoldOutlined, NotificationOutlined, PlusOutlined, ReloadOutlined, ThunderboltOutlined} from '@ant-design/icons'
import {toastUp} from '../../../app/Reducers/reducerUtils'
import {apiRequest} from '../../../app/Apis'
import {setNotifiedStakeholder} from '../../../app/Reducers';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';


const ScheduleHeader = ({ activeUser, checkIfNotified, addnew, selectedPeriod, handleFilterAction, setMode, setSelectedPeriod, isOpen, setisOpen, mode, showtimeBlocker, isNotified }) => {
  const dispatch = useDispatch()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [sentNotification] = useState(false)
  const [open, setOpen] = useState(false)
  const nextDay = dayjs().add(1, 'day').format('YYYY-MM-DD')
  const can_appointment_notify_stakeholders = usePermissionCheck('appointment_notify_stakeholders', false, [])
  
  useEffect(() => {
    console.log("🚀 Notified in Header::", isNotified)
  }, [isNotified])
  
  const handleNavigation = (nav) => {
    let date = dayjs(selectedPeriod);
    console.log("🥲", date, selectedPeriod)
    if (mode === 'week') {
      date = date.startOf('week')
    } else if (mode === 'month') {
      date = date.startOf('month')
    }
    switch (nav) {
      case 'today':
        date = dayjs()
        break;
      case 'next':
        date = date.add(1, mode);
        break;
      case 'back':
        date = date.subtract(1, mode);
        break;
    }
    setSelectedPeriod(date.format("YYYY-MM-DD"))
  }
  
  const notifyStakeHolders = async () => {
    setConfirmLoading(true);
    try {
      const response = await apiRequest.get('/appointments/calendar/notifications/notify');
      if (!!response.status && response.status === 200) {
        console.log("Alert Notification::", response);
        toastUp('Notifified Stakeholders successfully', true)
        setConfirmLoading(false);
        setOpen(false);
        dispatch(setNotifiedStakeholder(true));
        checkIfNotified()
        // window.location.reload();
        // data.all_stakeholders_notified = false;
        // await checkIfNotified();
      }
    } catch (e) {
      console.log(e);
      toastUp({ type: 'error', message: 'Error sending notifications' });
      setConfirmLoading(false);
      setOpen(false);
    }
  };
  
  const handleCancel = () => {
    setOpen(false)
  }
  
  return (
    <div className="shadow-md h-14 p-2 sticky top-0 z-50 flex justify-between flex-row border border-slate-200 bg-white mb-6">
      <div className="flex flex-row justify-between items-center">
        <div className="rounded border flex flex-row justify-center items-center p-2 aspect-square mr-2 shadow">
          {isOpen ? <MenuFoldOutlined onClick={() => setisOpen(!isOpen)}/> : <MenuUnfoldOutlined onClick={() => setisOpen(!isOpen)}/>}
        </div>
        
        <div className="rounded border flex flex-row justify-center items-center p-2 aspect-square mr-2 shadow">
          <ReloadOutlined onClick={() => handleFilterAction('reset')}/>
        </div>
        {mode !== "list" &&
          <>
            <Button onClick={() => handleNavigation("back")} value="back">
              <FontAwesomeIcon size="1x" icon={['fas', 'chevron-left']}/>
            </Button>
            <Button onClick={() => handleNavigation("today")} value="today">Today</Button>
            <Button onClick={() => handleNavigation("next")} value="next">
              <FontAwesomeIcon size="1x" icon={['fas', 'chevron-right']}/>
            </Button>
            <DatePicker className="mx-4 shadow" onChange={(date, dateString) => dateString && setSelectedPeriod(dayjs(dateString).format("YYYY-MM-DD"))} value={dayjs(selectedPeriod)}
                        allowClear="false"
                        format={"MMM DD, YYYY"}/>
          </>}
      </div>
      <div className="flex flex-row justify-between items-center">
        <Radio.Group value={mode} className="mr-4" onChange={(e) => setMode(e.target.value)}>
          <Radio.Button value="day">Day</Radio.Button>
          <Radio.Button value="week">Week</Radio.Button>
          <Radio.Button value="month">4 Weeks</Radio.Button>
          <Radio.Button disabled={showtimeBlocker} value="list">List</Radio.Button>
          <Radio.Button disabled={showtimeBlocker} value="agenda">Agenda</Radio.Button>
          {!activeUser?.facilityProfile && <Radio.Button disabled={showtimeBlocker} value="scheduler">scheduler</Radio.Button>}
        </Radio.Group>
        {!activeUser?.facilityProfile && containedButton(() => addnew(), "Add new", null, null, null, { icon: <PlusOutlined /> })}
        {can_appointment_notify_stakeholders && isNotified ?
          <Button disabled icon={<ThunderboltOutlined/>} style={{ marginLeft: 5, backgroundColor: '#12B02C', color: "#fff" }}>Notified Stakeholders</Button>
          : can_appointment_notify_stakeholders ? <Popconfirm
            open={open}
            placement="bottom"
            title={"Notify StakeHolders"}
            description={sentNotification ? 'We have notified stakeholders about tomorrow’s appointment' :
              `Would you like to send notifications to the relevant stakeholders for the appointments that will take place on ${nextDay}`}
            onConfirm={() => notifyStakeHolders()}
            okText="Confirm"
            okButtonProps={{ loading: confirmLoading }}
            cancelText="Cancel"
            style={{ marginRight: 15 }}
            onCancel={() => handleCancel()}
          >
            <Button onClick={() => setOpen(true)} type="primary" icon={<NotificationOutlined/>} style={{ marginLeft: 5 }}>Notify StakeHolders</Button>
          </Popconfirm> : null}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser, appointments: state.appointments.appointments,
  loading: state.appointments.loading, appConf: state.appConf, navs: state.navs,

})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleHeader)