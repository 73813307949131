import React from 'react';
import { formSubmitButton, horStack, modalClose, outlinedButton } from "../../../common/helpers";
import { Modal } from "antd";
import { DeclineApproval } from "./DeclineApproval";

const VisitStatus = ({ visitStatusModal, setStatusModal, declineAssessment, isExecuting }) => {
  return (
    (<Modal
      title="Decline Assessment"
      open={visitStatusModal}
      style={{ zIndex: 2000 }}
      className="decline"
      destroyOnClose
      onOk={() => setStatusModal(false)}
      onCancel={() => setStatusModal(false)}
      closeIcon={modalClose(() => setStatusModal(false))}
      maskClosable={false}
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        {horStack([outlinedButton(() => setStatusModal(false), 'Cancel', isExecuting),
        formSubmitButton('declineApprovalForm', 'Confirm', isExecuting, 'Declining')])}
      </div>]}>
      <DeclineApproval declineAssessment={declineAssessment} />
    </Modal>)
  );
};
export default VisitStatus;