/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Col, Grid, Modal, Row, Table} from 'antd'
import {Subtitle1} from '../../../../components'
import NewImmunizationForm from './forms/NewImmunizationForm'
import {deleteImmunization, fetchImmunisations, resetImmunizationPagination, updateImmunizationFilters} from '../../../../app/Reducers';
import {
  addButton,
  containedButton,
  dialogCloser,
  emptyFun,
  emptyImmunization,
  emptyTable,
  formSubmitButton,
  GetRawListOptions,
  getSkels,
  horStack,
  kebabMenus,
  boldKeyCapitalizedValue,
  makeSelectOptions,
  modalClose,
  onTableChange,
  outlinedButton,
  responsiveTbl
} from "../../../../common/helpers";
import KebabMenu from "../../../../components/KebabMenu";
import AuditLogViewer from "../../../../components/AuditLogs/AuditLogViewer";
import {useSubscription} from "../../../../hooks/MqttHooks/useSubscription";
import dayjs from 'dayjs';
import TableFilters from '../../../../components/InputComponents/TableFilters';
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import {useLocation} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';
const { useBreakpoint } = Grid;
 const ImmunizationSection = ({ immunizations, patient, appConf, immunizationPagination }) => {
   const location=useLocation()
     const windowState=useBreakpoint()
  const [immunizationsForm, setImmunizationsForm] = useState(false);
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [isExecuting, setisExecuting] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch()
  const { message } = useSubscription(`view_patient_immunisation/${patientId}`, { qos: 2, nl: true });
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  const can_delete_patient_immunisation = usePermissionCheck('delete_patient_immunisation', false, [])
  const can_create_patient_immunisation = usePermissionCheck('create_patient_immunisation', false, [])
  const can_view_patient_immunisation = usePermissionCheck('view_patient_immunisation', false, [])
  const can_update_patient_immunisation = usePermissionCheck('update_patient_immunisation', false, [])
  const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  //console.log({immunizations})
  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message)
      loadImmunisations(true).catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    loadImmunisations(false).catch(e => console.log(e))
    return emptyFun
  }, [])
  const loadImmunisations = async (isMQMessage) => {
    /*if (!!immunizations && !!immunizations[0] && !isMQMessage && immunizations.every(item => item.patient_id === patientId)) {
      return
    }*/
    await dispatch(updateImmunizationFilters([false, undefined, true]))
    dispatch(fetchImmunisations(patientId))
    await dispatch(updateImmunizationFilters([false, undefined, false]))
  }
  const handleImmunizationsOk = (values) => {
  }
  const [selected, setSelected] = React.useState(null);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [editingForm, setEditingForm] = useState(false);
  const [immunization_, setImmunization] = useState(null);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const showPopconfirm = (immunization_) => setSelected(immunization_.id)
  const handleCancel = () => setSelected(null)
  const handleOk = async (id) => {
    await setConfirmLoading(true);
    let data = { immunization_id: id, patient_id: patientId }
    await dispatch(deleteImmunization(data))
    await setSelected(null)
    await setConfirmLoading(false);
    await setIsModalOpen(false);
  }
  
  const actionMenus = kebabMenus((can_update_patient_immunisation && !patient.deleted_at), can_view_audit_logs, can_delete_patient_immunisation)
  
  async function kebabMenuClick(e, immunization_) {
    console.log('immunization_:  ', immunization_)
    await setImmunization(immunization_)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true)
        await setImmunizationsForm(true)
        break
      case 'Delete':
        await setEditingForm(false)
        showPopconfirm(immunization_)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
        await setEditingForm(false)
    }
  }
  
  /*const menu = (item) => {
    return <Menu onClick={(e) => handleMenuClick(e, item)}>
      {editMenus.map((item, index) => <Menu.Item key={index} icon={item.icon}> {item.title}  </Menu.Item>)}
    </Menu>
  }*/
  const addClick = () => {
    setEditingForm(false)
    setImmunizationsForm(true);
  }
  let dateFormat = "YYYY-MM-DD"
  const columns2 = [
    {
      title: 'Administered', dataIndex: ['administered_date'], key: 'administeredDate', fltr: { filterable: true, type: 'date' },
      render: (administered_date, record) => <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(administered_date).format(dateFormat)}</Subtitle1>
    },
    {
      title: 'Expiration', dataIndex: ['expiration_date'], key: 'expirationDate', fltr: { filterable: true, type: 'date' },
      render: (expiration_date, record) => <Subtitle1 style={{ textAlign: 'left' }}> {expiration_date && dayjs(expiration_date).format(dateFormat)}</Subtitle1>
    },
    { title: 'Ordered By', dataIndex: 'ordered_by', key: 'orderedBy', fltr: { filterable: true, type: 'string' } },
    { title: 'Immunization', dataIndex: 'name', key: 'name', fltr: { filterable: true, type: 'select', options: makeSelectOptions(GetRawListOptions('immunization')) } },
    {
      title: 'Route of Administration', dataIndex: 'route_of_administration', key: 'routeOfAdministration',
      fltr: { filterable: true, type: 'select', options: makeSelectOptions(GetRawListOptions('route_of_administration')) }
    },
    {
      title: 'Immunization Status', dataIndex: 'is_rejected', key: 'status', fltr: {
        filterable: true, type: 'select',
        options: makeSelectOptions(GetRawListOptions('status'))
      }, render: (is_rejected, record) => (
        (record.status === 'inactive') ? <div className="status-container">
            <div className="dot-circle-inactive"></div>
            <Subtitle1>Inactive</Subtitle1></div> :
          <div className="status-container">
            <div className="dot-circle-active"></div>
            <Subtitle1>Active</Subtitle1></div>)
    }
    // {
    //   fltr: {filterable: false}, render: (record) => {
    //     return <KebabMenu menus={actionMenus}
    //                       recordItem={record}
    //                       handleMenuClick={kebabMenuClick} resource={'immunizations'} handleOk={() => handleOk(record.id)} handleCancel={handleCancel}
    //                       confirmLoading={confirmLoading}
    //                       showConfirmDialog={selected === record.id}/>
    //   }
    // }
  ]
  
  async function handleFilterAction(action, values) {
    await dispatch(resetImmunizationPagination())
    if (action === 'filter') {
      let pl = { filterData: { patient: patientId, ...values }, pagination: immunizationPagination }
      await dispatch(updateImmunizationFilters([false, pl, true]))
      await dispatch(fetchImmunisations(pl))
      await dispatch(updateImmunizationFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updateImmunizationFilters([appConf.immunizationFilters[0], undefined, true]))
      await dispatch(fetchImmunisations(patientId))
      await dispatch(updateImmunizationFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handlimueOk = () => {
    setIsModalOpen(false);
  };
  const handleimuCancel = () => {
    setIsModalOpen(false);
  };
  
  // selected record
  const [selectedRecord, setSelectedRecord] = useState(null);
  
  return (
    <div className="">
      {!!immunization_ && showAuditDialog && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false}
                                                             resourceDescription={`${patient.name}'s immunisation info`} resourceType={'Patient'}
                                                             defaultValues={{ auditableId: immunization_?.id, auditable: 'immunisation', format: 'json' }}/>}
      <Modal
        title={editingForm ? "Edit Immunization" : "Add Immunization"}
        open={immunizationsForm}
        destroyOnClose
        onOk={dialogCloser(setImmunizationsForm)}
        onCancel={dialogCloser(setImmunizationsForm)}
        closeIcon={modalClose(dialogCloser(setImmunizationsForm))}
        maskClosable={false}
        width={800}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(dialogCloser(setImmunizationsForm), 'Cancel', isExecuting),
            formSubmitButton('immunizationForm', editingForm ? 'Update' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
        </div>]}>
        <NewImmunizationForm editingForm={editingForm} immunization={immunization_} setisExecuting={setisExecuting} closeModal={dialogCloser(setImmunizationsForm)}/>
      </Modal>
      {/*<div className="flex-boy-row-space" style={{width: "100%"}}>
        <Headline6>Immunization</Headline6>
        {can_update_patient_resource && !patient.deleted_at ? addButton(addClick) : null}
      </div>*/}
      
      
      {/* ant design modal */}
      
      <Modal title="Immunization" open={isModalOpen} onOk={handlimueOk} onCancel={handleimuCancel}>
        <Row gutter={16}>
          <Col span={24}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ margin: 10 }}>
                <div className="card-box" style={{ flexGrow: 1, margin: 10 }}>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1>{selectedRecord?.name}</Subtitle1>
                    <KebabMenu menus={actionMenus}
                               recordItem={selectedRecord}
                               handleMenuClick={kebabMenuClick} resource={'immunizations'} handleOk={() => handleOk(selectedRecord?.id)} handleCancel={handleCancel}
                               confirmLoading={confirmLoading}
                               showConfirmDialog={selected === selectedRecord?.id}/>
                  
                  </div>
                  <div style={{ width: "100%" }}>
                    {(selectedRecord?.status === 'inactive') ? <div className="status-container">
                        <div className="dot-circle-inactive"></div>
                        <Subtitle1>Inactive</Subtitle1></div> :
                      <div className="status-container">
                        <div className="dot-circle-active"></div>
                        <Subtitle1>Active</Subtitle1></div>}
                    {(selectedRecord?.is_rejected === 1 && !!selectedRecord?.rejection_reason) && boldKeyCapitalizedValue('Reason for rejection:', selectedRecord?.rejection_reason || 'N/A')}
                    {(selectedRecord?.is_rejected === 1 && !!selectedRecord?.rejection_notes) && boldKeyCapitalizedValue('Rejection notes:', selectedRecord?.rejection_notes || 'N/A')}
                    {boldKeyCapitalizedValue('Amount:', selectedRecord?.amount_in_ml || 'N/A')}
                    {boldKeyCapitalizedValue('Administered Date:', selectedRecord?.administered_date || 'N/A')}
                    {boldKeyCapitalizedValue('Route of Administration:', selectedRecord?.route_of_administration || 'N/A')}
                    {boldKeyCapitalizedValue('Administration Site:', selectedRecord?.administration_site || 'N/A')}
                    {boldKeyCapitalizedValue('Immunity Status:', selectedRecord?.immunity_status || 'N/A')}
                    {boldKeyCapitalizedValue('Ordered by:', selectedRecord?.ordered_by || 'N/A')}
                    {boldKeyCapitalizedValue('VFC eligibility:', selectedRecord?.vfc_eligibility || 'N/A')}
                    {boldKeyCapitalizedValue('Expiration date:', selectedRecord?.expiration_date || 'N/A')}
                    {boldKeyCapitalizedValue('Vaccine Lot Number:', selectedRecord?.vaccine_lot_number || 'N/A')}
                    {boldKeyCapitalizedValue('Manufacturer:', selectedRecord?.manufacturer || 'N/A')}
                    {boldKeyCapitalizedValue('Administration site:', selectedRecord?.administration_site || 'N/A')}
                    {boldKeyCapitalizedValue('Administered by:', selectedRecord?.administered_by || 'N/A')}
                    {boldKeyCapitalizedValue('Notes:', selectedRecord?.notes || 'N/A')}
                  </div>
                </div>
              </div>
            
            </div>
          </Col>
        
        </Row>
      </Modal>
      {
        
        <div className="w-full">
          <Table
            title={() => <TableFilters datasource={columns2} setIsVisible={setIsVisible}
                                       isVisible={isVisible} filters={appConf.immunizationFilters[1]}
                                       actionButton={(can_create_patient_immunisation && !patient.deleted_at) ? addButton(addClick) : null}
                                       windowState={windowState}
                                       handleFilterAction={handleFilterAction}
                                       showClear={appConf.immunizationFilters[0]} loading={appConf.immunizationFilters[2]}/>}
            loading={appConf.immunizationFilters[2]} size={'small'} {...responsiveTbl(windowState)}
            locale={{
              emptyText: !appConf.immunizationFilters[0] ? emptyImmunization('immunizations', can_update_patient_resource && !patient.deleted_at ?
                containedButton(addClick, 'Add Immunization', false, null, {}, { icon: <PlusOutlined /> }) : null) : emptyTable('immunizations.')
            }}
            onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchImmunisations, appConf.immunizationFilters, updateImmunizationFilters, { patient: patientId })}
            onRow={(record, rowIndex) => ({
              onClick: event => {
                if (!can_view_patient_immunisation){
                  return
                }
                setSelectedRecord(record)
                setIsModalOpen(true)
              } // click row
            })}
            {...getSkels(appConf.immunizationFilters[2], columns2, immunizations)} pagination={immunizationPagination}/>
        </div>
      }
    
    </div>
  );
}
const mapStateToProps = (state) => ({ immunizations: state.patients.healthData.immunizations,
  patient: state.patients.selectedPatient,
   appConf: state.appConf, navs: state.navs,
  immunizationPagination: state.patients.immunizationPagination
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ImmunizationSection)
