import React from 'react';
import {connect, useDispatch} from "react-redux";
import {setRoleModuleNavs} from "../../app/Reducers";
import {Tabs} from "antd";
import {Subtitle1} from "../../components";
import HeaderTop from "../../components/HeaderTop";
import ConsultantList from '../Consultants/ConsultantList';
import RolesList from './RolesList';
import TeamList from './TeamList';

const { TabPane } = Tabs;
const RolesModule = ({ navs }) => {
  const dispatch = useDispatch()
  const setActiveTab = async (tab) => {
    console.log({ tab, roleNavs: navs.roleNavs })
    return await dispatch(setRoleModuleNavs(tab));
  }
  const items = [
    {
      key: 'Roles',
      label: <Subtitle1>Roles</Subtitle1>
      // children: 'Content of Tab Pane 1'
    },
    {
      key: 'Teams',
      label: <Subtitle1>Teams</Subtitle1>
      // children: 'Content of Tab Pane 2'
    },
    /*{
      key: 'Access Control History',
      label: <Subtitle1>Access Control History</Subtitle1>
    }*/
  ];
  
  return (<div className="main-page overflow-hidden">
    <HeaderTop child={<Tabs rootClassName={''} defaultActiveKey={navs.roleNavs} activeKey={navs?.roleNavs} onChange={setActiveTab} tabBarStyle={{ marginBottom: 0,border:0 }} items={items}/>}
               customStyle={{ style: { paddingBottom: 0 } }}/>
    {navs?.roleNavs === 'Roles' ? <RolesList/> : <TeamList/>}
  </div>)
};

const mapStateToProps = (state) => ({
  navs: state.navs
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(RolesModule)
