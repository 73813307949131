import { Row, Col } from 'antd';
import { Headline6 } from '../../../../components';
import { boldKeyCapitalizedValue } from '../../../../common/helpers';

export default function FaciltyInformation({activeUser, facility}) {
  return (
    <div className='shadow-card-box'>
      <div className='flex-boy-row-space' style={{width: '100%'}}>
        <Headline6>Office Information</Headline6>
      </div>
      <Row
        gutter={[16, 16]}
        align={'start'}
        justify={'space-between'}
        style={{ width: '100%', margin: 0 }}
      >
        <Col span={12} xs={24} sm={24} md={24} lg={16} flex={'auto'}>
          {boldKeyCapitalizedValue(
            'Name:',
            <h4 style={{ color: '#231F20', alignItems: 'left' }}>
              {facility.name}
            </h4>
          )}
          {!activeUser?.facilityProfile &&
            boldKeyCapitalizedValue(
              'Sales Manager:',
              <h4 style={{ color: '#231F20', alignItems: 'left' }}>
                {facility.sales_managers?.map((item) => item.name).toString()}
              </h4>
            )}
          {boldKeyCapitalizedValue(
            'Type:',
            <h4 style={{ color: '#231F20', alignItems: 'left' }}>
              {facility.facility_type}
            </h4>
          )}
          {boldKeyCapitalizedValue(
            'ID:',
            <h4 style={{ color: '#231F20', alignItems: 'left' }}>
              {facility.id}
            </h4>
          )}
          {boldKeyCapitalizedValue(
            'Primary Email:',
            <h4 style={{ color: '#231F20' }}>{facility.email ?? 'N/A'}</h4>
          )}
          {boldKeyCapitalizedValue(
            'Additional Emails:',
            <h4 style={{ color: '#231F20' }}>
              {facility.additional_emails?.toString() ?? 'N/A'}
            </h4>
          )}
        </Col>
        <Col span={12} xs={24} sm={24} md={24} lg={16} flex={'auto'}>
          {boldKeyCapitalizedValue(
            'Contact Person:',
            <h4 style={{ color: '#231F20', alignItems: 'left' }}>
              {facility.primary_contact_name ?? 'N/A'}
            </h4>
          )}
          {boldKeyCapitalizedValue(
            'Phone Number:',
            <h4 style={{ color: '#038A96' }}>{facility.phone_no ?? 'N/A'}</h4>
          )}
          {boldKeyCapitalizedValue(
            'Additional Phone Numbers:',
            <h4 style={{ color: '#038A96' }}>
              {facility.additional_phone_numbers ?? 'N/A'}
            </h4>
          )}

          {boldKeyCapitalizedValue(
            'Website:',
            <h4 style={{ color: '#038A96' }}>{facility.website ?? 'N/A'}</h4>
          )}
          {boldKeyCapitalizedValue(
            'Address:',
            <h4 style={{ color: '#231F20' }}>{facility.address ?? 'N/A'}</h4>
          )}
        </Col>
      </Row>
    </div>
  );
}
