import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Headline6, Subtitle1} from '../../components';
import HeaderTop from '../../components/HeaderTop';
import {Divider, Grid, Input, Menu, Modal, Table, Typography} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {dialogCloser, emptyFun, executeChunkFn, formSubmitButton, horStack, IsItemEnabled, makeSelectOptions, modalClose, outlinedButton, regionFilter} from '../../common/helpers';
import {
  createTreatmentScenario,
  createTreatmentSupplies,
  deleteTreatmentSupplies,
  fetchAvailableSignatoryStates,
  fetchBillingRegions,
  fetchBiologicProducts,
  fetchMasterListFields,
  fetchPlanOfCareItems,
  fetchProcedures,
  fetchStateBoundaries,
  fetchTreatmentScenarios,
  fetchTreatmentSupplies,
  fetchVehicles,
  updateOpenSettings,
  updateTreatmentScenario,
  updateTreatmentSupplies
} from '../../app/Reducers';
import ArchivedPatients from './ArchivedPatients';
import SingleItemSelector from '../../components/SingleItemSelector';
import DynamicModalForm from '../../components/DynamicModalForm';
import {errMsg, toastUp} from '../../app/Reducers/reducerUtils';
import FieldOptions from './Components/FieldOptions';
import {useSubscription} from '../../hooks/MqttHooks/useSubscription';
import BillingRegions from '../Master/Components/BillingRegions'
import AuditLogList from "../../components/AuditLogs/AuditLogList";
import * as Sentry from "@sentry/react";
import BiologicProductsAndKits from "./Components/BiologicProductsAndKits";
import InsuranceProviders from "./Components/InsuranceProviders";
import AssessmentSignatories from "./Components/AssessmentSignatories";
import WPRegions from './Components/WPRegions';
import WPSubRegions from './Components/WPSubRegions';
import WPStates from './Components/WPStates';
import WPCities from './Components/WPCities';
import FeatureToggle from './Features/FeatureToggle';
import PpeKits from './Components/PpeKits';
import {usePermissionCheck} from '../../hooks/usePermissionCheck';
import BillingEntities from './Components/BillingEntities';
import CPTCodesCharges from './Components/CPTCodesCharges';
import AssessmentReport from './AssessmentReport'

const { Search } = Input;

let treatmentSupplyItemInputs = {
  fields: [
    { name: 'name', label: 'Treatment item name', required: true, type: 'text' },
    { name: 'bc_item_id', label: 'BC Item Id', required: true, type: 'text' },
    { name: 'unit_of_measure', label: 'Unit of measure', required: true, type: 'text' },
    { name: 'default_pack_amount', label: 'Default pack units', required: true, type: 'number' },
    { name: 'default_units', label: 'Default units', required: true, type: 'number' },
    {
      name: 'default_frequency', label: 'Frequency', required: true, type: 'select',
      options: makeSelectOptions(['Daily', 'Once a week', '2 times a week', '3 times a week', 'In between'])
    },
    {
      name: 'kit_type', label: 'Kit type', required: true, type: 'select',
      options: makeSelectOptions(['Normal'])
    },
    {
      name: 'status', label: 'Status', required: true, type: 'select',
      options: makeSelectOptions(['In stock', 'Out of stock'])
    },
    {
      name: 'notes', label: 'Notes', required: true, type: 'textarea', fullRow: true
    }
  ]
};
let planOfCareItemInputs = {
  fields: [
    { name: 'step', label: 'Plan of care step', required: true, type: 'text' },
    { name: 'scenario_number', label: 'Treatment scenario  number', required: true, type: 'text' },
    { name: 'treatment_code', label: 'Treatment code', required: true, type: 'text' },
    { name: 'step_number', label: 'Step number', required: true, type: 'text' },
    { name: 'kit_type', label: 'Kit type', required: true, type: 'text' },
    { name: 'type', label: 'Type', required: true, type: 'select', options: makeSelectOptions(['Primary Dressing', 'Secondary Dressing']) }
  ]
};
let treatmentScenarioInputs = {
  fields: [
    { name: 'scenario_number', label: 'Scenario number', required: true, type: 'text' },
    { name: 'treatment_code', label: 'Treatment code', required: true, type: 'text' },
    { name: 'protocol_name', label: 'Protocol name', required: true, type: 'text' },
    { name: 'size', label: 'Wound size', required: true, type: 'text' },
    { name: 'exudate', label: 'Exudate amount', required: true, type: 'text' },
    { name: 'stage', label: 'Wound stage', required: true, type: 'text' },
    { name: 'extra_notes', label: 'Extra notes', required: true, type: 'text' },
    { name: 'wound_type', label: 'Wound type', required: true, type: 'text' }
  ]
};
const { useBreakpoint } = Grid;
export const Master = ({ openSettingsPath, masterList, consultantVehicles, treatmentSupplies, treatmentScenarios, planOfCareItems, settings, reports }) => {
  const dispatch = useDispatch();
  const windowState=useBreakpoint()
  const [visible, setvisible] = useState(true);
  //console.log({openSettingsPath})
  const [openSettings, setOpenSettings] = useState(openSettingsPath[0]);
  const [selectedField, setselectedField] = useState();
  const [isOpen, setOpen] = useState(false);
  const [isAppSelectorOpen, setAppSelectorOpen] = useState(false);
  const [isExecuting, setisExecuting] = useState(false);
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  const { message } = useSubscription(`masterListSettingFormFields`, { qos: 2, nl: true });
  const billingInfoFullAccess = usePermissionCheck(['view_billing_group', 'create_billing_group', 'update_billing_group', 'delete_billing_group'], true, [])
  const archivedPatientsAccess = usePermissionCheck(['delete_patient', 'view_deleted_patient', 'restore_deleted_patient'], true, [])
  const masterGlobalAccess = usePermissionCheck('master_global_access', false, [])
  const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const ordersAccess = usePermissionCheck('access_masterlist_orders', false, [])
  const accessStateMDs = usePermissionCheck('access_masterlist_consultants_state_mds', false, [])
  const can_edit_masterlist = usePermissionCheck('edit_masterlist', false, [])
  const can_view_deleted_patient = usePermissionCheck('view_deleted_patient', false, [])
  useEffect(() => {
    dispatch(fetchStateBoundaries());
    dispatch(fetchAvailableSignatoryStates());
    dispatch(fetchVehicles());
    dispatch(fetchProcedures());
    dispatch(fetchTreatmentSupplies());
    dispatch(fetchTreatmentScenarios());
    dispatch(fetchPlanOfCareItems());
    dispatch(fetchBillingRegions());
    dispatch(fetchBiologicProducts());
  }, []);
  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message);
      dispatch(fetchMasterListFields());
    }
    return emptyFun;
  }, [message]);
  const [location, setLocation] = useState({ parent: 'Master', module: 'Assessments', sub_module: 'Start Assessment' });
  const [dataSource, setdataSource] = useState(masterList[0]?.sub_modules[0].items_list);
  const showDrawer = () => setvisible(true);
  
  const onClose = () => setvisible(false);
  const handleClick = async (e) => {
    //console.log(e);
    await dispatch(updateOpenSettings(e.keyPath));
    // await setOpenSettings(e.key)
    let arr = ['Consultant vehicles', 'Treatment supply items', 'Treatment scenarios', 'Plan of care items', 'Audit Logs', 'Biologic products', 'PPE kits', 'Billing groups', 'Billing Entities', 'CPT Codes charges', 'State Medical Director', 'Insurance providers', 'Regions', 'Sub-regions', 'States', 'Cities']
    if (!e.keyPath.includes('Masters') || arr.some(item => e.keyPath.includes(item))) {
      return;
    }
    let new_location = { parent: e.keyPath[2], module: e.keyPath[1], sub_module: e.keyPath[0] };
    let parent = e.keyPath[2];
    let module = e.keyPath[1];
    let sub_module = e.keyPath[0];
    //console.log(module, sub_module);
    let dataStuff = masterList.find((item) => item.master_block === module).sub_modules.find((mod) => mod.sub_module === sub_module).items_list;
    setdataSource(dataStuff);
    setLocation(new_location);
  };
  useEffect(() => {
    let { sub_module, module } = location;
    //console.log({masterList})
    if (!!masterList[0]) {
      let dataStuff = masterList.find((item) => item.master_block === module).sub_modules.find((mod) => mod.sub_module === sub_module).items_list;
      setdataSource(dataStuff);
    }
    return emptyFun;
  }, [masterList]);
  
  const columns = [
    { title: 'System Name', dataIndex: 'system_name', key: 'system_name', fltr: { filterable: true, type: 'text' } },
    { title: 'Label', dataIndex: 'label', key: 'label', fltr: regionFilter, render: (label) => label === 'Other Interventions' ? 'Orders/recommendations' : label },
    { title: 'Field Type', dataIndex: 'field_type', key: 'field_type', fltr: { filterable: true, type: 'phone' } }
  ];
  
  //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
  let dynamicFormInputFields = {
    'Edit plan of care item': planOfCareItemInputs, 'Add plan of care item': planOfCareItemInputs,
    'Edit treatment scenario': treatmentScenarioInputs, 'Add treatment scenario': treatmentScenarioInputs,
    'Edit supply item': treatmentSupplyItemInputs, 'Add supply item': treatmentSupplyItemInputs
  }
  const openSupplyItemDynamicForm = async (value) => {
    await setFormName('Add supply item');
    await setDefaultDynamicModalFormValues({ name: value });
    await setDynamicFormOpen(true);
  };
  const openTreatmentScenarioDynamicForm = async (value) => {
    await setFormName('Add treatment scenario');
    await setDefaultDynamicModalFormValues({ protocol_name: value });
    await setDynamicFormOpen(true);
  };
  const openPlanOfCareItemsDynamicForm = async (value) => {
    await setFormName('Add plan of care item');
    await setDefaultDynamicModalFormValues({ protocol_name: value, kit_type: 'Normal' });
    await setDynamicFormOpen(true);
  };
  const onDynamicFormEntriesResult = async (entries) => {
    //console.log('entries: ', entries);
    try {
      switch (formName) {
        case 'Add supply item':
          await setisExecuting(true);
          await executeChunkFn(dispatch, createTreatmentSupplies, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen));
          break;
        case 'Edit supply item':
          await executeChunkFn(dispatch, updateTreatmentSupplies, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen));
          break;
        case 'Add treatment scenario':
          await setisExecuting(true);
          await executeChunkFn(dispatch, createTreatmentScenario, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen));
          break;
        case 'Edit treatment scenario':
          await executeChunkFn(dispatch, updateTreatmentScenario, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen));
          break;
        case 'Add plan of care item':
          await setisExecuting(true);
          await executeChunkFn(dispatch, createTreatmentScenario, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen));
          break;
        case 'Edit plan of care item':
          await executeChunkFn(dispatch, updateTreatmentScenario, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen));
          break;
      }
    } catch (e) {
      Sentry.captureException(e);
      //console.log(e);
      toastUp(errMsg(e, false, formName), false);
    }
  };
  const onChipEdit = async (chip) => {
    let fieldToEdit, inputs;
    switch (chip.actionName) {
      case 'treatment_supplies':
        fieldToEdit = treatmentSupplies.find((item) => item.name === chip.chip);
        await setFormName(!!chip?.chip ? 'Edit supply item' : 'Add supply item');
        await setDefaultDynamicModalFormValues({ ...fieldToEdit });
        await setDynamicFormOpen(true);
        break;
      case 'treatment_scenarios':
        fieldToEdit = treatmentScenarios.find((item) => item.protocol_name === chip.chip);
        await setFormName(!!chip?.chip ? 'Edit treatment scenario' : 'Add treatment scenario');
        await setDefaultDynamicModalFormValues({ ...fieldToEdit, exudate: fieldToEdit?.exudate_amount, stage: fieldToEdit?.wound_stage, size: fieldToEdit?.wound_size });
        await setDynamicFormOpen(true);
        break;
      case 'care_items':
        fieldToEdit = planOfCareItems.find((item) => item.step === chip.chip);
        await setFormName(!!chip?.chip ? 'Edit plan of care item' : 'Add plan of care item');
        await setDefaultDynamicModalFormValues({ ...fieldToEdit, kit_type: 'Normal' });
        await setDynamicFormOpen(true);
        break;
    }
  };
  const onDeleteChip = async (chip) => {
    //console.log('onDeleteChip: ', chip);
    switch (formName) {
      case 'treatment_supplies':
        let supplyItem = treatmentSupplies.find((item) => item.name === chip.chip);
        //console.log('chip: ', chip);
        //console.log('supplyItem: ', supplyItem);
        await setisExecuting(true);
        await executeChunkFn(dispatch, deleteTreatmentSupplies, supplyItem.id, setisExecuting, dialogCloser(setOpen));
        break;
      case 'vehicle_make':
        toastUp('Car makes are not removable!', false);
        setOpen(false);
        break;
      case 'treatment_scenarios':
        toastUp('Treatment scenarios are not removable!', false);
        setOpen(false);
        break;
    }
  };
  let consultantVehicleDataSource = [
    { system_name: 'make_name', label: 'Car Make', field_type: 'Select Input' },
    { system_name: 'models', label: 'Car Model', field_type: 'Select Input' }
  ];
  let treatmentSuppliesSource = [{ system_name: 'name', label: 'Treatment supply item name', field_type: 'Select Input' }];
  let treatmentScenariosSource = [{ system_name: 'protocol_name', label: 'Treatment scenario name', field_type: 'Select Input' }];
  let careItemsSource = [{ system_name: 'step', label: 'Plan of care step', field_type: 'Select Input' }];
  
  const [searchKey, setsearchKey] = useState('');
  const onSearch = async (value) => {
    setsearchKey(value.trim());
    let { sub_module, module } = location;
    if (!!value && value.trim().length > 0) {
      let dataStuff = masterList.find((item) => item.master_block === module).sub_modules.find((mod) => mod.sub_module === sub_module).items_list.filter((item) =>
        item.label.toString().toLowerCase().includes(value.toLowerCase()));
      setdataSource(dataStuff);
    }
    if (!value || value === '') {
      let dataStuff = masterList.find((item) => item.master_block === module).sub_modules.find((mod) => mod.sub_module === sub_module).items_list;
      setdataSource(dataStuff);
    }
  };
  const getItem = (label, key, icon, children, type) => ({ key, icon, children, label, type });
  const filteredMasterListItems = masterList.filter(item => {
    if (item.master_block === 'Billing Information' && !billingInfoFullAccess) {
      return false
    } else if (item.master_block === 'Geography' && !masterGlobalAccess) {
      return false
    } else if (item.master_block === 'Orders' && ordersAccess) {
      return true
    } else {
      return true
    }
  })
  // custom reports
  const reportsData = reports.reports.map(report => {
    if (report.report_name === "DME") {
      return {...report, report_name: 'DME Report'};
    }
    return report;
  });

  const items = [
    IsItemEnabled(settings, 'modules', 'MasterList') && getItem('Masters', 'Masters', null, [...filteredMasterListItems.map((item) =>
      getItem(item.master_block, item.master_block, null, [
        ...item.sub_modules.filter((mod) => mod.sub_module !== 'Vehicle details').map((module) => getItem(module.sub_module, module.sub_module)),
        ...(item.master_block === 'Consultants' ? [getItem('Consultant vehicles', 'Consultant vehicles'),
          ...(accessStateMDs ? [getItem('State Medical Director', 'State Medical Director')] : [])] : []),
        ...(item.master_block === 'Appointments' ? [
          getItem('Treatment supply items', 'Treatment supply items'),
          getItem('Treatment scenarios', 'Treatment scenarios'),
          getItem('Plan of care items', 'Plan of care items')
        ] : []),
        ...((item.master_block === 'Orders') ?
          [getItem('Biologic products', 'Biologic products', null, null, null),
            getItem('PPE kits', 'PPE kits', null, null, null)] : []),
        /*        ...((item.master_block === 'Billing Information') ?
                  [
                      // getItem('Billing groups', 'Billing groups'),
                    getItem('Billing Entities', 'Billing Entities'),
                    getItem('CPT Codes charges', 'CPT Codes charges'),
                    getItem('Insurance providers', 'Insurance providers'),
        
                  ] : []),*/
        ...((item.master_block === 'Geography') ?
          [getItem('Regions', 'Regions', null, null, null),
            getItem('Sub-regions', 'Sub-regions', null, null, null),
            getItem('States', 'States', null, null, null),
            getItem('Cities', 'Cities', null, null, null)
          ] : [])
      ])),
      getItem('Billing Information', 'Billing Information', null, [
        // getItem('Billing groups', 'Billing groups'),
        getItem('Billing Entities', 'Billing Entities'),
        getItem('CPT Codes charges', 'CPT Codes charges'),
        getItem('Insurance providers', 'Insurance providers')
      
      ], null)
    ]),
    can_view_deleted_patient && getItem('Archived Patients', 'Archived Patients'),
    masterGlobalAccess && getItem('Audit Logs', 'Audit Logs'),
    masterGlobalAccess && getItem('Features', 'Features', null),
    masterGlobalAccess && getItem('Reports', 'Reports', null, [...reportsData.map(({ report_name }) =>getItem(report_name, report_name)),])
  ];
  return (
    (<div className="main-page bg-gray-100">
      <Modal
        title={`Edit ${selectedField?.label === 'Other Interventions' ? 'Orders/recommendations' : selectedField?.label}`}
        open={isOpen && selectedField}
        onOk={dialogCloser(setOpen)}
        width={
          selectedField?.updateAction?.actionName === 'treatment_scenarios' || selectedField?.system_name ==="icd_code" ? windowState.lg || windowState.xl ? window.innerWidth * 0.8 : window.innerWidth * 0.8  : 600}
        onCancel={dialogCloser(setOpen)}
        closeIcon={modalClose(dialogCloser(setOpen))}
        maskClosable={false}
        destroyOnClose={true}
        footer={[
          <div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([
              outlinedButton(dialogCloser(setOpen), 'Cancel', isExecuting),
              ...(selectedField?.updateAction?.actionName === 'vehicle_make' ? [] :
                can_edit_masterlist ? [formSubmitButton('fieldForm', 'Update', isExecuting, 'Updating')] : [])
            ])}
          </div>
        ]}
      >
        <FieldOptions selectedField={selectedField} setisExecuting={setisExecuting} closeModal={dialogCloser(setOpen)} onChipEdit={onChipEdit} onDeleteChip={onDeleteChip}
                      isExecuting={isExecuting}/>
      </Modal>
      <SingleItemSelector
        isAppSelectorOpen={isAppSelectorOpen}
        setAppSelectorOpen={setAppSelectorOpen}
        title={`Select vehicle make`}
        closeModal={dialogCloser(setAppSelectorOpen)}
        options={selectedField?.options}
        selectItem={async (make) => {
          // console.log('item:', make)
          let vehicle = consultantVehicles.find((vehicle) => vehicle.make_name === make);
          await setselectedField({ options: vehicle.models, label: `${make} models`, updateAction: { actionName: 'vehicle_models', vehicleId: vehicle.id, make } });
          await setOpen(true);
        }}
      />
      {(isDynamicFormOpen && !!formName) &&
        (<DynamicModalForm
            setDynamicFormOpen={setDynamicFormOpen}
            isDynamicFormOpen={isDynamicFormOpen}
            inputFields={dynamicFormInputFields[formName]}
            onDynamicFormEntriesResult={onDynamicFormEntriesResult}
            closeModal={dialogCloser(setDynamicFormOpen)}
            isExecuting={isExecuting}
            defaultValues={defaultDynamicModalFormValues}
            formName={formName}
          />
        )}
      <HeaderTop child={<Subtitle1>Settings</Subtitle1>}/>
      <div className="flex-boy-row">
        <div className="settings-drawer" style={{ width: visible ? '20%' : '5%' }}>
          <div className="circle-border">
            {!visible ? (<FontAwesomeIcon onClick={showDrawer} color="grey" icon={['fas', 'chevron-right']}/>) :
              (<FontAwesomeIcon onClick={onClose} color="grey" icon={['fas', 'chevron-left']}/>)}
          </div>
          <Menu onClick={handleClick} style={{ width: '100%' }} defaultSelectedKeys={openSettingsPath} items={items} mode="inline"/>
        </div>
        <div className={'p-8 flex-1 pt-0 text-left overflow-y-hidden'}>
          {openSettingsPath[0] !== 'Biologic products' && <Divider orientation="left">
            <Typography variant="subtitle1" fontWeight={600}>{openSettingsPath[0] === null ? `${location.parent || ''} ${location.module || ''} block:` : openSettingsPath[0]}{': '} </Typography>
          </Divider>}
          {openSettingsPath[0] === null && (<Headline6 style={{ marginBottom: '20px' }}>              {location.sub_module}            </Headline6>)}
          {openSettingsPath[0] === null && (
            <Search
              placeholder="Search"
              onSearch={onSearch}
              value={searchKey || undefined}
              onChange={(event) => onSearch(event.target.value)}
              onPressEnter={(e) => onSearch(e.target.value)}
              size={'middle'}
              style={{ width: 190, backgroundColor: 'transparent', marginRight: 6, marginBottom: '20px' }}
            />
          )}
          <div className="w-full">
            {openSettingsPath[0] === 'Archived Patients' ? (<ArchivedPatients/>) :
              // openSettingsPath[0] === 'Logs' ? (<div><Subtitle1>Logs template</Subtitle1></div>) :
              openSettingsPath[0] === 'Permissions' ? (<div><Subtitle1>Permissions template</Subtitle1></div>) :
                (openSettingsPath[0] === 'Consultant vehicles' && !!consultantVehicles[0]) ? (
                    <Table
                      columns={columns}
                      dataSource={consultantVehicleDataSource}
                      onRow={(record) => ({
                        onClick: async (event) => {
                          // console.log('record: ', record)
                          // console.log('consultantVehicles: ', consultantVehicles)
                          let makes = consultantVehicles.map((item) => item.make_name).sort((a, b) => {
                            if (a > b) {
                              return 1;
                            }
                            if (a < b) {
                              return -1;
                            }
                            return 0;
                          });
                          await setselectedField({ options: makes, label: 'car make', updateAction: { actionName: 'vehicle_make' }, setselectedField, setOpen });
                          if (record.system_name === 'models') {
                            await setAppSelectorOpen(true);
                          } else {
                            await setOpen(true);
                          }
                        }
                      })}
                    />) :
                  (openSettingsPath[0] === 'Treatment supply items' && !!treatmentSupplies[0]) ? (
                      <Table
                        columns={columns}
                        dataSource={treatmentSuppliesSource}
                        onRow={(record) => ({
                          onClick: async (event) => {
                            // console.log('record: ', record)
                            // console.log('consultantVehicles: ', consultantVehicles)
                            let treatment_supplies = treatmentSupplies.map((item) => item.name).sort((a, b) => {
                              if (a > b) {
                                return 1;
                              }
                              if (a < b) {
                                return -1;
                              }
                              return 0;
                            });
                            await setselectedField(
                              {
                                options: treatment_supplies, label: 'Treatment supply items', updateAction: { actionName: 'treatment_supplies' },
                                openDynamicForm: openSupplyItemDynamicForm
                              });
                            await setOpen(true);
                          }
                        })}
                      />) :
                    (openSettingsPath[0] === 'Treatment scenarios' && !!treatmentScenarios[0]) ? (
                        <Table
                          columns={columns}
                          dataSource={treatmentScenariosSource}
                          onRow={(record) => ({
                            onClick: async (event) => {
                              // console.log('record: ', record)
                              // console.log('consultantVehicles: ', consultantVehicles)
                              let treatment_scenarios = treatmentScenarios.map((item) => item.protocol_name).sort((a, b) => {
                                if (a > b) {
                                  return 1;
                                }
                                if (a < b) {
                                  return -1;
                                }
                                return 0;
                              });
                              await setselectedField({
                                options: treatment_scenarios,
                                label: 'Treatment scenarios',
                                updateAction: { actionName: 'treatment_scenarios' },
                                openDynamicForm: openTreatmentScenarioDynamicForm
                              });
                              await setOpen(true);
                            }
                          })}
                        />) :
                      (openSettingsPath[0] === 'Plan of care items' && !!planOfCareItems[0]) ? (
                          <Table
                            columns={columns}
                            dataSource={careItemsSource}
                            onRow={(record) => ({
                              onClick: async (event) => {
                                let care_items = planOfCareItems.map((item) => item.step).sort((a, b) => {
                                  if (a > b) {
                                    return 1;
                                  }
                                  if (a < b) {
                                    return -1;
                                  }
                                  return 0;
                                });
                                await setselectedField({
                                  options: care_items, label: 'Plan of care items', updateAction: { actionName: 'care_items' }, openDynamicForm: openPlanOfCareItemsDynamicForm
                                });
                                await setOpen(true);
                              }
                            })}
                          />) :
                        (openSettingsPath[0] === 'Plan of care items' && !!planOfCareItems[0]) ? (
                            <Table
                              columns={columns}
                              dataSource={careItemsSource}
                              onRow={(record) => ({
                                onClick: async (event) => {
                                  let care_items = planOfCareItems.map((item) => item.step).sort((a, b) => {
                                    if (a > b) {
                                      return 1;
                                    }
                                    if (a < b) {
                                      return -1;
                                    }
                                    return 0;
                                  });
                                  await setselectedField({
                                    options: care_items, label: 'Plan of care items', updateAction: { actionName: 'care_items' }, openDynamicForm: openPlanOfCareItemsDynamicForm
                                  });
                                  await setOpen(true);
                                }
                              })}
                            />) :
                    openSettingsPath[0] === 'Wound Debridement' ?  <AssessmentReport title='Wound Debridement'/>:
                      openSettingsPath[0] === 'DME Report' ?  <AssessmentReport title='DME'/> :
                        openSettingsPath[0] === 'Biologics' ? <AssessmentReport title='Biologics'/> :
                          openSettingsPath[0] === 'Audit Logs' ? <AuditLogList/> :
                            openSettingsPath[0] === 'Billing groups' ? (<BillingRegions/>) :
                              openSettingsPath[0] === 'Billing Entities' ? (<BillingEntities/>) :
                                openSettingsPath[0] === 'CPT Codes charges' ? (<CPTCodesCharges/>) :
                                  openSettingsPath[0] === 'State Medical Director' ? (<AssessmentSignatories/>) :
                                    openSettingsPath[0] === 'Insurance providers' ? (<InsuranceProviders/>) :
                                      openSettingsPath[0] === 'Biologic products' ? (<BiologicProductsAndKits/>) :
                                        openSettingsPath[0] === 'Regions' ? (<WPRegions/>) :
                                          openSettingsPath[0] === 'Sub-regions' ? (<WPSubRegions/>) :
                                            openSettingsPath[0] === 'States' ? (<WPStates/>) :
                                              openSettingsPath[0] === 'Cities' ? (<WPCities/>) :
                                                openSettingsPath[0] === 'Features' ? (<FeatureToggle/>) :
                                                  openSettingsPath[0] === 'PPE kits' ? (<PpeKits/>) :
                                                    <Table columns={columns} dataSource={dataSource}
                                                           onRow={(record) => ({
                                                             onClick: (event) => {
                                                               setselectedField(record);
                                                               setOpen(true);
                                                             }
                                                           })}/>
            }
          </div>
        </div>
      </div>
    </div>)
  );
};

const mapStateToProps = (state) => ({
  masterList: state.masters.masterList,
  localStorage: state.masters.location,
  openSettingsPath: state.masters.openSettingsPath,
  consultantVehicles: state.consultants.consultantVehicles,
  treatmentSupplies: state.appointments.treatmentSupplies,
  treatmentScenarios: state.appointments.treatmentScenarios,
  planOfCareItems: state.appointments.planOfCareItems,
  settings: state?.settings?.settings,
  reports: state.reports
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Master);
