import { Avatar, Card } from 'antd';
import { Headline4, Headline6, Subtitle1 } from '../../../../components';
import { DownArrow, UpArrow } from './Icons/facility-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {skelBars} from "../../../../common/helpers";

const FacilityCards = ({ logo, schedule }) => {
  function getColorByCount(count) {
    if (count > 0) {
      return (
        <div className="flex-boy-row">
          <UpArrow />
          <Subtitle1 style={{ color: '#5ACA75' }}>{count}%</Subtitle1>
        </div>
      );
    } else if (count < 0) {
      return (
        <div className="flex-boy-row">
          <DownArrow />
          <Subtitle1 style={{ color: '#f5222d' }}>{count}% </Subtitle1>
        </div>
      );
    }
  }

  return (
    <div className="facility-cards">
      <Card
        style={{
          width: '100%',
          borderRadius: '12px',
          padding: '10px'
        }}
      >
        <div className="flex-boy-column" style={{ alignItems: 'flex-start' }}>
          <Headline6>
            {logo === 'user'
              ? 'Total open wounds'
              : logo === 'schedule'
                ? 'Scheduled appointments today' :
                logo === 'add' ? 'Total patients'
                  : 'Healing rate rank'}
          </Headline6>
          {schedule ? (
            <Headline4 style={{ fontWeight: 'bold' }}>
              {
                (logo === 'schedule')
                  ? schedule?.count
                  : (logo === 'healing')
                    ? schedule?.rank
                    : schedule?.total
              }
            </Headline4>
          ) : null}
        </div>
        {schedule ? (
          <div className="flex-boy-row-space">
            <div className="flex-boy-row-space">
              {
                schedule?.rate !== 0 ? (
                  logo === 'healing' ? (
                    <Subtitle1
                      style={{
                        color: '#f5222d',
                        fontWeight: 'bold',
                        marginRight: '10px'
                      }}>
                      {schedule?.avg_heal_rate.toFixed(4)}
                    </Subtitle1>
                  ) : (
                    getColorByCount(schedule?.rate.toFixed(2))
                  )
                ) : (
                  <Subtitle1
                    style={{
                      color: '#f5222d',
                      fontWeight: 'bold',
                      marginRight: '10px'
                    }}>
                    {logo !== 'healing' ? `${schedule?.rate}%` : `${schedule?.avg_heal_rate}%`}
                  </Subtitle1>
                )
              }
              <Subtitle1 style={{ color: '#827F86', fontWeight: 'bold' }}>
                {logo === 'healing' ? 'Average healing rate' : 'Total open wounds'}
              </Subtitle1>
            </div>
            {logo === 'user' ? (
              <Avatar
                icon={
                  <FontAwesomeIcon color="#A7A9BC" icon={['fas', 'heartbeat']} />
                }
                size={45}
                style={{
                  backgroundColor: '#F1F2F5',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '5px'
                }}
              />
            ) : logo === 'schedule' ? (
              <Avatar
                icon={<FontAwesomeIcon color="#A7A9BC" icon={['fas', 'calendar-alt']} />}
                size={45}
                style={{
                  backgroundColor: '#F1F2F5',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              />
            ) : (
              <Avatar
                icon={<FontAwesomeIcon color="#A7A9BC" icon={['fas', 'trophy']} />}
                size={45}
                style={{
                  backgroundColor: '#F1F2F5',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '5px'
                }}
              />
            )}
          </div>
        ) : skelBars()}
      </Card>
    </div>
  );
};
export default FacilityCards
