import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
// import { NewPatentCard } from './components/NewPatentCard';
import {AutoComplete, Checkbox, DatePicker, Divider, Form, Input, Radio, Select, Space, TimePicker} from 'antd';
import {Body1, RegularButtonCircle} from '../../../components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  createAppointment,
  fetchConsultants,
  fetchPatientAppointments,
  fetchProcedures,
  fetchSpecificAppointment,
  getConsultantStateLicences,
  resetConsultantLicences,
  updateAppointment
} from '../../../app/Reducers'
import {apiRequest} from '../../../app/Apis';
import {addButton, appLog, cleanObject, emptyFun, executeChunkFn, getInputField, inputRow, showCurrentAddress, skelBars} from "../../../common/helpers";
import {errMsg, toastUp} from "../../../app/Reducers/reducerUtils";
import {toast} from 'react-toastify';
import * as Sentry from "@sentry/react";
import dayjs from 'dayjs'
import {useWoundLocationSearch} from '../../../hooks/useWoundLocationSearch';
import {usePatientSearch} from '../../../hooks/usePatientSearch';


const ScheduleAppointment = ({ patient, consultantStateLicences, closeModal, setisExecuting, appointment, editingForm, masterListRaw, selectedAppointment, handleFilterAction }) => {
  const { Option } = Select;
  const dateFormat = "MM-DD-YYYY"
  const dispatch = useDispatch()
  const uiFields = (appointment_) => {
    // console.log('APPOINTMENT: ', appointment_)
    let appointment = { ...appointment_ }
    let consultant = appointment.consultant
    let wounds = appointment.wounds.map(item => ({ location: item.location, recommended_procedure: item.recommended_procedure, id: item.id }))
    delete appointment.consultant
    delete appointment.wounds
    delete appointment.latlng
    delete appointment.patient
    cleanObject(appointment)
    return {
      ...appointment,
      ...(appointment.start_at && { date: dayjs(appointment.start_at) }),
      ...(appointment.end_at && { start_at: dayjs(appointment.start_at), end_at: dayjs(appointment.end_at) }),
      telehealth_appointment: appointment.telehealth_appointment === 1,
      ...(consultant && { consultant: consultant.name }),
      ...(wounds && { wounds })
    }
  }
  const [patient_, setPatient_] = useState(undefined)
  const [referralWounds, setReferralWounds] = useState([])
  const [initialValues, setInitialValues] = useState(editingForm ? uiFields(appointment) : { wounds: [{ recommended_procedure: undefined, location: undefined }] });
  const [consultant, setConsultant] = useState(editingForm ? appointment.consultant : null)
  const [consultantOptions, setconsultantOptions] = useState([])
  
  const [woundProcedureOptions, setWoundProcedureOptions] = useState([])
  const [form] = Form.useForm();
  const [IsEstablishedVisit, setIsEstablishedVisit] = useState(appointment?.appointment_visit_type === "established visit")
  const [patientWounds, setpatientWounds] = useState(editingForm ? appointment.wounds : [])
  const [missingWoundsList, setMissingWoundsList] = useState([])
  const [missingWounds, setMissingWounds] = useState({})
  const [insurancesError, setinsuranceError] = useState(false)
  const [consultantListOptions, setconsultantListOptions] = useState([])
  const [existingWounds, setExistingWounds] = useState([])
  const [patientDataLoading, setPatientDataLoading] = useState(false)
  // is telehealth appointment
  const [isTelehealth, setisTelehealth] = useState(editingForm ? appointment.telehealth_appointment : false)
  const [masterLocations, setMasterLocations] = useState(masterListRaw[0] ? masterListRaw.find(item => item.system_name === 'wound_location')?.options : [])
  const [timeRange, setTimeRange] = useState([]);
  const [isHourBeingSelected, setIsHourBeingSelected] = useState([false, false]); // Flags for start and end times
  const { patientOptions, setPatientOptions, debouncedPatientSearch, patientLoading } = usePatientSearch([])
  const { woundLocationOptions, debouncedWLocationSearch, woundLocationLoading } = useWoundLocationSearch([])
  useEffect(() => {
    let patient_ = patient || appointment?.patient
    setPatient_(patient_)
    if (patient_) {
      setPatientOptions([
        {
          ...patient_,
          label: patient_.name || `${patient_?.first_name || ''} ${patient_?.last_name || ''}`,
          value: patient_.id
        }
      ])
    }
    let refWounds = appointment?.wounds?.[0].location ? appointment.wounds.map(item => ({
      location: item.location,
      recommended_procedure: item.recommended_procedure,
      id: item.id
    })) : referralWounds[0] ? referralWounds.map(wound => ({
      recommended_procedure: wound.recommended_procedure[0] ? wound.recommended_procedure : "Wound Evaluation", location: wound.location, id: wound.id
    })) : [{ recommended_procedure: undefined, location: undefined }]
    setInitialValues(editingForm ? {
      ...uiFields(appointment), occurrences_mode: initialValues.occurrences_mode || "daily", wounds: refWounds,
      ...(patient_ && { patient_id: patient_?.id })
    } : { wounds: refWounds, occurrences_mode: 'daily', ...(patient_ && { patient_id: patient_?.id }) })
    
    form.setFieldsValue(editingForm ? {
      ...uiFields(appointment), occurrences_mode: initialValues.occurrences_mode || "daily", wounds: refWounds,
      ...(patient_ && { patient_id: patient_?.id })
    } : { wounds: refWounds, occurrences_mode: 'daily', ...(patient_ && { patient_id: patient_?.id }) })
    return emptyFun
  }, [appointment, editingForm, patient])
  
  useEffect(() => {
    if (patient_) {
      fetchInsurance(patient_).catch(appLog)
      if (patient_?.active_assigned_consultant && !editingForm) {
        fetchConsultant(patient_, patient_?.active_assigned_consultant?.name).catch(appLog)
      }
    }
    
    return emptyFun
  }, [patient_])
  
  useEffect(() => {
    console.log({ referralWounds, initialValues })
    return emptyFun
  }, [referralWounds, initialValues])
  useEffect(() => {
    
    // if (patient) {
    //   getListAvailableConsultants(patient.id);
    //   /*if (!editingForm) {
    //     fetchPatientWounds()
    //   }*/
    // }
    // fetchWoundLocations(setwoundLocations)
    fetchwoundProcedures()
    // dispatch(fetchPatients())
    dispatch(fetchConsultants())
    dispatch(fetchProcedures())
    if (editingForm) {
      dispatch(fetchSpecificAppointment(appointment.id))
    }
    return emptyFun
  }, [])
  useEffect(() => {
    if (patientWounds[0] && !!patient_) {
      let ids = patientWounds.map(item => item.id)
      fetchMissingWounds(ids).catch(e => console.log(e))
    }
    return emptyFun
  }, [patientWounds, patient_])
  
  
  const fetchPatientWounds = async () => {
    if (patient_) {
      let wounds_ = []
      if (patientWounds.length > 0) {
        wounds_ = patientWounds.map(item => ({
          location: item.location,
          recommended_procedure: editingForm ? item.recommended_procedure : [...item.recommended_procedure.filter(proc => !proc.includes('Biologics Pre-Assessment'))],
          id: item.id
        }))
      } else {
        const wounds = await apiRequest.post(`/patient-established-visit/${patient_.id}/wounds`)
        if (wounds && wounds.data.data) {
          //console.log(wounds.data.data)
          wounds_ = wounds.data.data.filter(item => item.last_visit).map(item => ({
            location: `${item?.side || ''} ${item?.position || ""} ${item?.location || ''}`,
            recommended_procedure: editingForm ? item.recommended_procedure : [...item.recommended_procedure.filter(proc => !proc.includes('Biologics Pre-Assessment'))],
            id: item.id,
            label: `${item?.side || ''} ${item?.position || ""} ${item?.location || ''}`
          }))
          await setpatientWounds(wounds.data.data.filter(item => item.last_visit))
        }
      }
      let formVal = form.getFieldsValue()
      let refWounds = formVal?.wounds?.[0]?.location ? formVal.wounds : referralWounds[0] ? referralWounds.map(wound => ({
        recommended_procedure: wound.recommended_procedure[0] ? wound.recommended_procedure : "Wound Evaluation", location: wound.location, id: wound.id
      })) : [{ recommended_procedure: undefined, location: undefined }]
      if (formVal?.appointment_visit_type === "established visit") {
        if (!wounds_?.[0]?.location) {
          toast.error("Patient has no previously visited wounds!", { autoClose: 5000 })
          form.setFieldsValue({ ...formVal, appointment_visit_type: 'new visit' })
        }
        await setInitialValues(prevState => ({ ...prevState, wounds: wounds_[0]?.location ? wounds_ : refWounds, appointment_visit_type: !wounds_.length ? 'new visit' : "established visit" }))
        form.setFieldsValue({ ...formVal, wounds: wounds_[0]?.location ? wounds_ : refWounds, appointment_visit_type: !wounds_.length ? 'new visit' : "established visit" })
      }
    }
  }
  const fetchMissingWounds = async (ids) => {
    if (patient_) {
      let wounds_ = []
      const { data } = await apiRequest.post(`/patient-established-visit/${patient_.id}/wounds`, { exclude: ids })
      if (data?.data) {
        wounds_ = data.data?.map(item => ({
          search: `Location: ${item.location} Consultant: ${item?.consultant?.name ||
          'Unknown'} Status: ${item.status} ${!!item.last_visit ? 'Last visit: ' + dayjs(item.last_visit).format('YYYY-MM-DD HH:mm:ss') : ''}`,
          ...item
        }))
        await setMissingWoundsList(wounds_)
      }
    }
  }
  const fetchwoundProcedures = async () => {
    const response = await apiRequest.get('/procedures').catch(function (err) {
      //console.log(err)
    })
    if (response && response.data && response.data.data) {
      let procedures = response.data.data.filter(item => item.name === "Wound Evaluation").map(item => ({ label: item.name, value: item.name }))
      setWoundProcedureOptions(procedures)
    }
  }
  const onPatientSelect = async (value, option) => {
    setPatientDataLoading(true)
    console.log({ value, option })
    await loadReferralWounds(option)
    await fetchInsurance(option)
    if (option?.active_assigned_consultant?.name) {
      await fetchConsultant(option, option?.active_assigned_consultant?.name)
    }
    setPatientDataLoading(false)
  };
  const loadReferralWounds = async (option) => {
    let wounds_ = []
    await setReferralWounds([])
    let params = { is_referral: true }
    const { data } = await apiRequest.post(`/patient-established-visit/${option.id}/wounds`, params)
    wounds_ = data?.data
    console.log({ wounds_ })
    if (wounds_) {
      await setReferralWounds(wounds_)
      setInitialValues(prevState => ({
        ...prevState,
        wounds: appointment?.wounds?.[0].location ? appointment.wounds.map(item => ({
          location: item.location,
          recommended_procedure: item.recommended_procedure,
          id: item.id
        })) : referralWounds[0] ? wounds_.map(wound => ({
          recommended_procedure: wound.recommended_procedure[0] ? wound.recommended_procedure : "Wound Evaluation",
          location: wound.location, id: wound.id
        })) : [{ recommended_procedure: undefined, location: undefined }]
      }))
    }
    
  }
  const [insuranceChecked, setInsuranceChecked] = useState(false)
  const fetchInsurance = async (patient) => {
    try {
      await setInsuranceChecked(false)
      if (!referralWounds[0]) {
        await loadReferralWounds(patient)
      }
      const { data: d1 } = await apiRequest.get(`/patients/${patient.id}`)
      let fullPatientInfo = d1.data
      if (!fullPatientInfo) {
        throw new Error('Unable to pull patient data')
      }
      const { data } = await apiRequest.get(`/patients/${patient.id}/insurance`)
      let insurances = data?.data
      if (insurances?.[0]) {
        setPatient_(fullPatientInfo)
        setinsuranceError(false)
      } else if (!insurances?.[0]&&fullPatientInfo.approval_status !== "Cash/Approved"){
        setinsuranceError(true)
        setPatient_(null)
      }else if (fullPatientInfo.approval_status === "Cash/Approved"){
        setinsuranceError(false)
        setPatient_(fullPatientInfo)
      }
      await setInsuranceChecked(true)
    } catch (e) {
      console.error(e)
    }
    
  }
  useEffect(() => {
    if (consultant) {
      dispatch(getConsultantStateLicences(consultant.id))
    } else {
      dispatch(resetConsultantLicences())
    }
    return emptyFun
  }, [consultant]);
  
  
  const onconsultantSelect = (value) => {
    let wcc = consultantListOptions.find(item => item.name === value)
    setConsultant(wcc)
  };
  const onFinish = async (values) => {
    
    setisExecuting(true)
    let data = { ...values }
    try {
      //console.log(data.date)
      data.patient_id = patient_.id
      data.consultant_id = consultant.id
      data.date = dayjs(values.date).format("YYYY-MM-DD")
      data.start_at = `${dayjs(values.date).format("YYYY-MM-DD")} ${dayjs(values.start_at).format("HH:mm:ss")}`
      data.end_at = `${dayjs(values.date).format("YYYY-MM-DD")} ${dayjs(values.end_at).format("HH:mm:ss")}`
      data.telehealth_appointment = isTelehealth
      data.appointment_visit_type = values.appointment_visit_type
      // data.repeat = values.repeats || false
      
      // data.occurrences = [
      //   {
      //     appointment_end_date: dayjs(values.appointment_end_date).format("YYYY-MM-DD"),
      //     every: `${values.every}`,
      //     repeats: `${values.after}`,
      //     occurrences_mode: values.occurrences_mode,
      //     occur: values.occur,
      //     overlapping: values.overlapping || false
      //   }
      // ]
      // data.overlapping = values.overlapping || false
      //console.log(data)
      let wounds_ = form.getFieldsValue().wounds
      let existingWounds = wounds_.filter(item => item && !!item.id)
      let woundIds = existingWounds.map(item => item.id)
      let currentWounds = patientWounds.filter(item => !!item.id && woundIds.includes(item.id))//if any
      currentWounds = currentWounds.map(item => ({ id: item.id, location: item.location, recommended_procedure: item.recommended_procedure }))
      let newWounds = wounds_.filter(item => item && !item.id)
      newWounds = newWounds.map(item => ({ ...item, recommended_procedure: [item.recommended_procedure] }))
      let allWounds = wounds_.filter(item => item && item.recommended_procedure).map(item => ({
        recommended_procedure: Array.isArray(item.recommended_procedure) ? item.recommended_procedure : [item.recommended_procedure],
        location: item.location, ...(item.id && { id: item.id })
      }))
      // let allWounds = [...currentWounds, ...newWounds]
      //console.log('allWounds: ', wounds_, allWounds)
      data.wounds = allWounds
      const { date, ...rest } = data
      // console.log('allWounds: ', wounds_, allWounds, data)
      if (allWounds.length) {
        let payload = editingForm ? { appointment_id: appointment.id, ...(patient_ && { patient_id: patient_.id }), data } : rest;
        await executeChunkFn(dispatch, editingForm ? updateAppointment : createAppointment, payload, setisExecuting, closeModal, async () => {
          if (patient_?.id) await dispatch(fetchPatientAppointments(patient_.id));
          if (handleFilterAction) {
            handleFilterAction('filter', {})
          }
        })
      } else {
        let payload = editingForm ? { appointment_id: appointment.id, ...(patient_ && { patient_id: patient_.id }), data } : rest;
        payload.wounds = []
        await executeChunkFn(dispatch, editingForm ? updateAppointment : createAppointment, payload, setisExecuting, closeModal, async () => {
          if (patient_?.id) await dispatch(fetchPatientAppointments(patient_.id));
          if (handleFilterAction) {
            handleFilterAction('filter', {})
          }
        })
      }
      
      
    } catch (e) {
      Sentry.captureException(e);
      setisExecuting(false)
      //console.log(e)
      toastUp(errMsg(e, false), false)
    }
  };
  const onChange = async (e) => {
    if (e.target.value === "established visit") {
      await setIsEstablishedVisit(true)
      if (patient_) {
        await fetchPatientWounds()
      }
    } else {
      let refWounds = initialValues?.wounds?.[0]?.location ? initialValues.wounds : referralWounds[0] ? referralWounds.map(wound => ({
        recommended_procedure: wound.recommended_procedure[0] ? wound.recommended_procedure : "Wound Evaluation", location: wound.location, id: wound.id
      })) : [{ recommended_procedure: undefined, location: undefined }]
      setInitialValues(prevState => ({ ...prevState, wounds: refWounds }))
      form.setFieldsValue({ ...form.getFieldsValue(), wounds: refWounds })
      setIsEstablishedVisit(false)
    }
  }
  
  
  const handleprocedureChange = async (e, index) => {
    if (!IsEstablishedVisit) {
      return
    }
    let wounds = form.getFieldsValue().wounds
    //Only make update request if this is not a new wound without Id
    if (wounds[index].id) {
      let myArray = JSON.parse(JSON.stringify(patientWounds));
      let wound = patientWounds.find(item => item.id === wounds[index].id)
      if (!wound) {
        return
      }
      let objIndex = patientWounds.findIndex(obj => obj.id === wound.id)
      if (objIndex > -1) {
        myArray[objIndex].recommended_procedure = [e]
      }
      // let data = { ...wound, recommended_procedure: [e], patient_id: patient_.id }
      // const toastId = getToastId("Updating wound procedures")
      // try {
      //     await apiRequest.patch(`/wounds/${wound.id}`, data)
      //     updateToast(toastId, 'Wound updated', true)
      //     setpatientWounds(myArray)
      // } catch (e) {
      //     Sentry.captureException(e);
      //     console.log(e)
      //     updateToast(toastId, errMsg(e, false, null, 'Unable to update wound'), false)
      // }
    }
  }
  const handleMissingWoundsClear = async (e, index) => {
    //console.log({handleMissingWoundsClear: e})
    let wounds = form.getFieldsValue().wounds
    let wounds_ = [...wounds]
    //Remove wound from patient wounds if a missing wound was removed
    let newPatientWounds = [...patientWounds]
    for (let i = 0; i < newPatientWounds.length; i++) {
      if (Object.values(missingWounds).includes(newPatientWounds[i].id)) {
        let woundsIndex = wounds_.findIndex(item => {
          //console.log({item, newPatientWounds, i})
          return item?.id === newPatientWounds[i]?.id;
        })
        newPatientWounds.splice(i, 1)
        if (woundsIndex >= 0) {
          wounds_.splice(woundsIndex, 1)
        }
      }
    }
    await setMissingWounds({})
    let formVal = form.getFieldsValue()
    //console.log({wounds_})
    if (!wounds_[0]) {
      toast.error("Patient has no  previous wounds!", { autoClose: 5000 })
      form.setFieldsValue({ ...formVal, appointment_visit_type: 'new visit', wounds_: [''] })
    }
    await setInitialValues(prevState => ({ ...prevState, wounds: wounds_, appointment_visit_type: !wounds_[0] ? 'new visit' : "established visit" }))
    form.setFieldsValue({ ...formVal, wounds: !!wounds_[0] ? wounds_ : [''], appointment_visit_type: !wounds_[0] ? 'new visit' : "established visit" })
  }
  const handleMissingWoundsDeselect = async (location) => {
    //console.log({handleMissingWoundsDeselect: location})
    let wounds = form.getFieldsValue().wounds
    let wounds_ = [...wounds]
    //Remove wound from patient wounds if a missing wound was removed
    let newPatientWounds = [...patientWounds]
    //Check for removal if an item is removed but some are still selected
    if (Object.keys(missingWounds).includes(location)) {
      let patientWoundsIndex = newPatientWounds.findIndex(item => item.id === missingWounds[location])
      if (patientWoundsIndex >= 0) {
        newPatientWounds.splice(patientWoundsIndex, 1)
      }
      let woundsIndex = wounds_.findIndex(item => item.id === missingWounds[location])
      if (woundsIndex >= 0) {
        wounds_.splice(woundsIndex, 1)
      }
    }
    
    let newMissingWounds = { ...missingWounds }
    delete newMissingWounds[location]
    await setMissingWounds(newMissingWounds)
    let formVal = form.getFieldsValue()
    //console.log({wounds_})
    if (!wounds_[0]) {
      toast.error("Patient has no  previous wounds!", { autoClose: 5000 })
      form.setFieldsValue({ ...formVal, appointment_visit_type: 'new visit', wounds_: [''] })
    }
    await setInitialValues(prevState => ({ ...prevState, wounds: wounds_, appointment_visit_type: !wounds_[0] ? 'new visit' : "established visit" }))
    form.setFieldsValue({ ...formVal, wounds: !!wounds_[0] ? wounds_ : [''], appointment_visit_type: !wounds_[0] ? 'new visit' : "established visit" })
  }
  const handleMissingSelect = async (e) => {
    //console.log({handleMissingSelect: e})
    let wounds = form.getFieldsValue().wounds
    let wounds_ = [...wounds]
    //Remove wound from patient wounds if a missing wound was removed
    let newPatientWounds = [...patientWounds]
    for (const wound of missingWoundsList) {
      if (wound.search === e) {
        let newWound = { ...wound }
        delete newWound.search
        // console.log({newWound, wound, missingWoundsList})
        await setMissingWounds({ ...missingWounds, [wound.search]: wound.id })
        newPatientWounds.push(newWound)
        //provide "Wound Evaluation" sa the recommended_procedure if the recommended_procedure list returned from backend is empty
        wounds_ = [...wounds_, {
          location: newWound.location,
          recommended_procedure: newWound.recommended_procedure[0] ? newWound.recommended_procedure : ["Wound Evaluation"],
          id: newWound.id
        }]
      }
    }
    
    let formVal = form.getFieldsValue()
    //console.log({wounds_})
    if (!wounds_[0]) {
      toast.error("Patient has no  previous wounds!", { autoClose: 5000 })
      form.setFieldsValue({ ...formVal, appointment_visit_type: 'new visit', wounds_: [''] })
    }
    await setInitialValues(prevState => ({ ...prevState, wounds: wounds_, appointment_visit_type: !wounds_[0] ? 'new visit' : "established visit" }))
    form.setFieldsValue({ ...formVal, wounds: !!wounds_[0] ? wounds_ : [''], appointment_visit_type: !wounds_[0] ? 'new visit' : "established visit" })
  }
  const getListAvailableConsultants = async (value, date, start_at, end_at) => {
    let start = `${dayjs(date).format("YYYY-MM-DD")} ${dayjs(start_at).format("HH:mm:ss")}`
    let end = `${dayjs(date).format("YYYY-MM-DD")} ${dayjs(end_at).format("HH:mm:ss")}`
    let queryParams = `&notScheduledBetween[start]=${start}&notScheduledBetween[end]=${end}`
    const data = await apiRequest.get(`consultant-scheduling/${patient_?.id || patient?.id}?search=${value.trim()}${queryParams}`).catch(function (err) {
      console.log(err)
      toastUp(errMsg(err), true)
    })
    if (data && data.data) {
      //console.log(data.data, data)
      // setconsultantOptions(data.data.data);
      setconsultantListOptions(data.data.data)
    }
  }
  
  
  const searchpreExistingWounds = (e, key) => {
    console.log({ e, key })
    let wounds_ = [...existingWounds]
    //console.log({ wounds_, key })
    let index = wounds_.findIndex(obj => obj.key === key);
    if (patientWounds.find(item => item.location === e)) {
      //console.log("patient already has this wound!")
      let wound = patientWounds.find(item => item.location === e)
      if (index >= 0) {
        
        wounds_[index] = { name: ` ${wound?.side || ''} ${wound?.position || ""} ${wound?.location || ''}`, key: key }
      } else {
        wounds_.push({ name: ` ${wound?.side || ''} ${wound?.position || ""} ${wound?.location || ''}`, key: key })
      }
    } else {
      if (index >= 0) {
        wounds_.splice(index, 1)
      }
    }
    setExistingWounds(wounds_)
  }
  const radioStyle = { display: 'block', height: '30px', lineHeight: '30px' };
  
  
  // fetch assigned consultant consultant
  const fetchConsultant = async (patient, value) => {
    const response = await apiRequest.get(`consultant-scheduling/${patient.id}?search=${value}`).catch(e => {
      toastUp(errMsg(e), true)
    })
    if (response?.data?.data?.length) {
      setConsultant(response.data.data[0])
      form.setFieldsValue({ consultant: response.data.data[0].name })
    }
  }
  
  
  return (
    <Form
      form={form}
      preserve={false}
      name="appointmentForm"
      id="appointmentForm"
      labelCol={{ span: 16 }}
      layout="vertical"
      onValuesChange={(changedValues, allValues) => {
        // console.log({ changedValues, allValues })
        setInitialValues(allValues)
      }}
      className="p-2"
      style={{ background: 'whitesmoke' }}
      initialValues={initialValues}
      onFinish={onFinish}>
      
      <div className="p-2 rounded shadow-md bg-white">
        {getInputField(form, {
          name: 'patient_id', label: 'Patient', required: true, type: 'select', options: patientOptions,
          props: {
            loading: patientLoading,
            showSearch: true, filterOption: false, onSelect: onPatientSelect, onSearch: (value) => debouncedPatientSearch(value),
            defaultActiveFirstOption: false, showArrow: true, placeholder: 'Search...', disabled: editingForm
          },
          fiprops: { className: 'mb-0' }
        })}
        {showCurrentAddress(patient_?.address_1)}
        {(insurancesError) ? <Body1 color="crimson">Patient has no insurance information!</Body1> : null}
        
        {!patientDataLoading ? <Form.Item
          label="Visit Type"
          name="appointment_visit_type"
          rules={[{ required: true, message: 'this field is required!' }]}
          wrapperCol={{ span: 24 }}>
          <Radio.Group onChange={onChange} disabled={(editingForm && appointment?.appointment_visit_type === "established visit")}>
            <Radio style={radioStyle} value="new visit">New Visit</Radio>
            <Radio style={radioStyle} value="established visit">Established Visit</Radio>
          
          </Radio.Group>
        </Form.Item> : skelBars()}
        {/* <Checkbox className="my-4 w-full" onChange={(e) => setisTelehealth(e.target.checked)} defaultChecked={editingForm ? appointment.telehealth_appointment : false}>Telehealth
          Appointment</Checkbox> */}
        
        
        {patient_ && <div className="flex">
          <Form.Item
            label="Date"
            style={{ width: '40%' }}
            name="date"
            rules={[{ required: true, message: 'this field is required!' }]}>
            <DatePicker format={dateFormat} style={{ width: '100%' }}/>
          </Form.Item>
          <div className="flex justify-between items-start w-[60%]">
            <Form.Item
              label="Start Time"
              style={{ width: '50%' }}
              name="start_at"
              onChange={(e) => {
                console.log('Start Time:', e)
              }}
              rules={[{ required: true, message: 'Start time is required!' }]}
            >
              <TimePicker
                defaultOpenValue={dayjs('12:00 am', 'HH:mm')}
                format={'HH:mm'}
                style={{ width: '100%' }}
                minuteStep={15}
                onBlur={(e) => console.log('onBlur Start Time:', e)}
              />
            </Form.Item>
            
            <Form.Item
              label="End Time"
              style={{ width: '50%' }}
              name="end_at"
              rules={[
                { required: true, message: 'End time is required!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || !getFieldValue('start_at')) {
                      return Promise.resolve();
                    }
                    if (value.isSameOrAfter(getFieldValue('start_at'))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('End time cannot be earlier than start time!'));
                  }
                })
              ]}
            >
              <TimePicker
                defaultOpenValue={dayjs('12:00 am', 'HH:mm a')}
                format={'HH:mm'}
                style={{ width: '100%' }}
                minuteStep={15}
                onBlur={(e) => console.log('onBlur End Time:', e)}
                disabledHours={() => {
                  const start = form.getFieldValue('start_at');
                  if (start) {
                    return Array.from({ length: start.hour() }, (_, i) => i);
                  }
                  return [];
                }}
                disabledMinutes={(selectedHour) => {
                  const start = form.getFieldValue('start_at');
                  if (start && selectedHour === start.hour()) {
                    return Array.from({ length: Math.floor(start.minute() / 15) }, (_, i) => i * 15);
                  }
                  return [];
                }}
                
                disabled={!form.getFieldValue('start_at')}
              />
            </Form.Item>
          </div>
        
        </div>
        
        }
        
        
        {(form.getFieldValue('end_at') || consultant) && <Form.Item
          label="Consultant"
          name="consultant"
          
          rules={[{ required: true, message: 'this field is required!' }]}
          wrapperCol={{ span: 24 }}>
          <AutoComplete popupMatchSelectWidth={false}
                        value={consultant ? consultant.name : ''}
                        style={{ width: "100%", marginBottom: "20px" }}
                        onSelect={onconsultantSelect}
                        disabled={editingForm && selectedAppointment?.previous_assessments?.length}
                        onSearch={(value) => getListAvailableConsultants(value, initialValues.date, initialValues.start_at, initialValues.end_at)}
                        placeholder="search for consultants">
            {consultantListOptions.map(item => (
              <Option key={item.id} value={item.name}>{item.name} -{item.state} {item.distance_from_patient?.toFixed(2) || 0} miles away </Option>))}
          </AutoComplete>
        </Form.Item>}
        {!!consultantStateLicences[0] && <div className="flexy-column mt-2 justify-between">
          {consultantStateLicences?.map((item) => {
            return item?.licenses?.map(value => {
              return dayjs(value.expiry_date).isBefore(dayjs().add(2, 'months')) ?
                <p className="mb-1 text-xs bg-[#F7F8F9] py-2 px-2"><span className={'font-bold text-lg text-red-500'}>⚠️</span> Consultant's {value.license_type} licence <span
                  className={'font-semibold'}>{value.license_name}</span> for {item.state_abbreviation}<span
                  className={'font-semibold'}>{dayjs(value.expiry_date).isBefore(dayjs()) ? ' has expired' : ` expires on ${dayjs(value.expiry_date).format('YYYY-MM-DD')}`}.</span> Please act on
                  it!</p> : null
            })
          }).flat(1)}
        </div>}
      </div>
      <div className="p-2 rounded shadow-md bg-white mt-2">
        <Form.Item
          label="Reason" name="visit_reason" rules={[{ required: true, message: 'this field is required!' }]}
          wrapperCol={{ span: 24 }}>
          <Input autoComplete={'off'}/>
        </Form.Item>
        {!!form.getFieldValue('appointment_visit_type') && <Form.List lable="Wounds" name="wounds"
          // initialValue={initialValues.wounds}
        >
          {(fields, { add, remove }) => (<>
            {fields.map((field, index) => (
              <Space key={field.key} style={{ display: 'flex', marginBottom: 8, alignItems: 'center', flexWrap: 'wrap' }} align="center">
                {inputRow([
                  <Form.Item
                    {...field}
                    name={[field.name, 'location']}
                    rules={[{ required: false, message: 'Missing Wound location' }, !IsEstablishedVisit && {
                      message: "Selected Location does not exist",
                      validator: (_, value) => {
                        if (value && !masterLocations.find(item => item.replace(/\s{2,}/g, ' ').trim() === value.replace(/\s{2,}/g, ' ').trim())) {
                          //console.log(value, masterLocations)
                          return Promise.reject('Selected Location does not exist!');
                        } else {
                          return Promise.resolve();
                        }
                      }
                    }]}
                    label="Wound Location">
                    <Select popupMatchSelectWidth={false}
                            style={{ width: 200, borderRadius: "4px" }}
                            options={woundLocationOptions}
                            showSearch={true}
                            onSelect={e => searchpreExistingWounds(e, field.key)}
                            onSearch={(value) => debouncedWLocationSearch(value)}
                            disabled={initialValues?.appointment_visit_type === "established visit"}
                            placeholder="Search"/>
                  </Form.Item>,
                  <Form.Item
                    {...field}
                    name={[field.name, 'recommended_procedure']}
                    rules={[{ required: false, ...(!editingForm && { message: 'Missing Procedure type' }) }]}
                    label="Procedure">
                    <Select
                      options={woundProcedureOptions}
                      disabled={(editingForm && !!form.getFieldValue('wounds')?.[index]?.['recommended_procedure']) || (initialValues?.appointment_visit_type === "established visit" && form.getFieldValue('wounds')?.[index]?.['recommended_procedure']?.length)}
                      mode={(editingForm || initialValues?.appointment_visit_type === "established visit") && "multiple"}
                      popupMatchSelectWidth={false}
                      onChange={(e) => handleprocedureChange(e, index)}
                      style={{ width: 200, borderRadius: "4px" }}>
                    </Select>
                  </Form.Item>
                ])}
                <Form.Item style={{ marginBottom: '0px', alignSelf: 'center', marginLeft: '2px' }}>
                  <RegularButtonCircle borderColor="grey" color="transparent" onClick={() => remove(field.name)} height="30px">
                    <FontAwesomeIcon color="grey" icon={["fas", "times"]}/>
                  </RegularButtonCircle>
                </Form.Item>
                <div style={{ width: "100%" }}>
                  
                  {existingWounds.find(item => item.key === field.key) &&
                    <Form.Item
                      {...field}
                      className="two-rows-label"
                      label={<span style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                           {`There exists a similar wound ${existingWounds.find(item => item.key === field.key).name}  for this patient do you wish to use that wound or continue creating a new one`}
                                        </span>}
                      name={[field.name, 'wound_create']}
                      
                      labelCol={{ style: { width: "100%" } }}
                      rules={[{ required: true, message: 'this field is required!' }]}
                      wrapperCol={{ span: 24 }}>
                      <Radio.Group>
                        <Radio value="create_new">Create New</Radio>
                        <Radio value="use_existiog">Use Existing</Radio>
                      </Radio.Group>
                    </Form.Item>}
                  
                  {/* if existing show user list of existing wounds to choose from as radio buttons */}
                  {form.getFieldValue('wounds')[index]?.['wound_create'] === "use_existiog" && <Form.Item
                    {...field}
                    label="Select Wound"
                    name={[field.name, 'wound_id']}
                    rules={[{ required: true, message: 'this field is required!' }]}
                    wrapperCol={{ span: 24 }}>
                    <Radio.Group>
                      {existingWounds.map((item, index) => <Radio key={index} value={item.id}>
                        {item.name}
                      </Radio>)}
                    </Radio.Group>
                  </Form.Item>}
                </div>
              
              </Space>))}
            {form.getFieldValue('appointment_visit_type') === "new visit" && <Form.Item>
              {addButton(() => add(), 'grey')}
            </Form.Item>}
          </>)}
        </Form.List>}
        {(editingForm && initialValues?.appointment_visit_type === "established visit") &&
          <Form.Item
            label="Add previous wounds" name="missingWoundsList" rules={[{ required: false, message: 'this field is required!' }]}
            wrapperCol={{ span: 24 }}>
            <Select mode={"multiple"}
              // allowClear={true}
                    onClear={handleMissingWoundsClear}
                    popupMatchSelectWidth={false}
                    onSelect={handleMissingSelect}
              // onChange={handleMissingWounds}
                    onDeselect={handleMissingWoundsDeselect}
                    style={{ width: '100%', borderRadius: "4px" }}>
              {missingWoundsList.map((item) => <Select.Option key={item.search} value={item.search}>{item.search}</Select.Option>)}
            </Select>
          </Form.Item>
        }
        <Divider/>
      
      
      </div>
    </Form>
  );
  
}
const mapStateToProps = (state) => ({
  selectedAppointment: state.appointments.selectedAppointment,
  consultantStateLicences: state.consultants.consultantStateLicences,
  procedures: state.appointments.procedures,
  masterListRaw: state.masters.masterListRaw
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleAppointment)
