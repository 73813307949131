/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Col, Modal, Row } from 'antd'
import { Body2, Headline6, Subtitle1 } from '../../../../components'
import { deleteFamilyMember, fetchFamilyHistory } from '../../../../app/Reducers';
import dayjs from 'dayjs';

import { addButton, addButtonClean, containedButton, dialogCloser, emptyFamilyHistory, emptyFun, formSubmitButton, horStack, kebabMenus, boldKeyCapitalizedValue, modalClose, outlinedButton } from "../../../../common/helpers";
import NewHistoryForm from "./forms/FamilyHistoryForm";
import KebabMenu from "../../../../components/KebabMenu";
import AuditLogViewer from "../../../../components/AuditLogs/AuditLogViewer";
import { useSubscription } from "../../../../hooks/MqttHooks/useSubscription";
import {PlusOutlined, RightOutlined} from '@ant-design/icons';
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';

export const FamilyHistorySection = ({ patient, familyHistory, activeUser, setContent }) => {
    const location = useLocation()
    const navigate = useNavigate();
    const [allergiesForm, setAllergiesForm] = useState(false);

    const [isExecuting, setisExecuting] = useState(false);
    const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))

    const dispatch = useDispatch()
    const { message } = useSubscription(`view_patient_next_of_kin/${patientId}`, { qos: 2, nl: true });
    
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
    const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
    const can_delete_patient_next_of_kin = usePermissionCheck('delete_patient_next_of_kin', false, [])
    useEffect(() => {
        if (!!message) {
            console.log('MQTT MESSAGE:  ', message)
            loadFamilyHistory(true)
        }
        return emptyFun
    }, [message])
    useEffect(() => {
        loadFamilyHistory(false)
        return emptyFun
    }, [])
    const loadFamilyHistory = (isMQMessage) => {
        /*if (!!familyHistory && !!familyHistory[0] && !isMQMessage && familyHistory.every(item => item.patient_id === patientId)) {
            return
        }*/
        dispatch(fetchFamilyHistory(patientId))
    }
    const [selected, setSelected] = React.useState(null);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [editingForm, setEditingForm] = useState(false);
    const [member, setMember] = useState(null);
    const [showAuditDialog, setShowAuditDialog] = useState(false);
    const showPopconfirm = (member) => {
        setSelected(member.id);
    }
    const handleCancel = () => setSelected(null)
    const handleOk = async (id) => {
        await setConfirmLoading(true);
        let data = { id, patient_id: patientId }
        await dispatch(deleteFamilyMember(data))
        await setSelected(null)
        await setConfirmLoading(false);
    }
    const actionMenus = kebabMenus((can_update_patient_resource && !patient.deleted_at), can_view_audit_logs, can_delete_patient_next_of_kin)

    async function kebabMenuClick(e, member) {
        await setMember(member)
        switch (actionMenus[e.key].title) {
            case 'Edit':
                await setEditingForm(true)
                await setAllergiesForm(true)
                break
            case 'Delete':
                await setEditingForm(false)
                showPopconfirm(member)
                break
            case 'Audit':
                setShowAuditDialog(true);
                break
            default:
                await setEditingForm(false)
        }
    }

    /*const menu = (item) => {
      return <Menu onClick={(e) => handleMenuClick(e, item)}>
        {editMenus.map((item, index) => <Menu.Item key={index} icon={item.icon}> {item.title}  </Menu.Item>)}
      </Menu>
    }*/
    const addClick = () => {
        setEditingForm(false)
        setAllergiesForm(true);
    }
    return (
        (<div className="w-full">
            {(!!member && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)}
                resourceType={'Patient'}
                loading={false} resourceDescription={`${patient.name}'s family history`}
                defaultValues={{ auditableId: member?.id, auditable: 'family_history', format: 'json' }} />}
            <Modal
                title={editingForm ? 'Edit Family member' : "Add Family Member"}
                open={allergiesForm}
                onOk={dialogCloser(setAllergiesForm)}
                onCancel={dialogCloser(setAllergiesForm)}
                closeIcon={modalClose(dialogCloser(setAllergiesForm))}
                maskClosable={false}
                destroyOnClose
                width={700}
                footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
                    {horStack([outlinedButton(dialogCloser(setAllergiesForm), 'Cancel'),
                    formSubmitButton('familyHistoryForm', editingForm ? 'Update' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
                </div>]}>
                <NewHistoryForm editingForm={editingForm} member={member} setisExecuting={setisExecuting} closeModal={dialogCloser(setAllergiesForm)} patient={patient} />
            </Modal>
            <div className="flex flex-row items-center justify-between w-full" style={{ width: "100%" }}>
                <Headline6> Family History </Headline6>
                <div className="flex flex-row items-center">
                    {(can_update_patient_resource && !patient.deleted_at) ? addButtonClean(addClick) : null}
                    <RightOutlined className='ml-4' onClick={() => setContent("Family History")} />
                </div>
            </div>
            {!!familyHistory && !!familyHistory[0] ?

                familyHistory?.slice(0, 3).map((item, index) => {
                    return (
                        <div style={{ margin: 10, background: '#ebeff4' }}>
                            <div className="w-full" >
                                <div className="flex-boy-row-space" style={{ width: "100%" }}>
                                    <Subtitle1>{item.subject}</Subtitle1>
                                    {/*<Dropdown dropdownRender={()=>menu(item)} trigger={['click']} disabled={selected === item.id}>
                        {appConfirm(
                          <MoreOutlined />, selected === item.id, null, 'Are you sure of deleting this family member?', () => handleOk(item.id), handleCancel, confirmLoading)}
                      </Dropdown>*/}
                                    <KebabMenu menus={actionMenus}
                                        recordItem={item}
                                        handleMenuClick={kebabMenuClick} resource={'family history'} handleOk={() => handleOk(item.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
                                        showConfirmDialog={selected === item.id} />
                                </div>
                                <div key={item.id} className="text-left rounded w-full p-2 " style={{ background: '#ebeff4' }}>

                                    {/* <Subtitle1 style={{ textAlign: 'left' }}>{item.subject}</Subtitle1> */}
                                    <p className='capitalize'>{item.relationship}</p>
                                    <Body2 color="grey">{dayjs(item?.date_of_birth).format("MM/DD/YYYY")}({dayjs().diff(dayjs(item?.date_of_birth), 'years')}y.o.)</Body2>
                                    {item.medical_histories.map((disease) => {
                                        return (<div className='flex flex-row justify-between items-center' style={{ width: "100%", alignItems: "normal" }}>
                                            <p>&#8226; {disease.disease_name}</p>
                                            <p>{dayjs().diff(dayjs(disease.diagnosed_at), 'years')}y.o.- Now</p>
                                        </div>)
                                    })}
                                </div>
                            </div>

                        </div>
                    )
                })



                :
                emptyFamilyHistory('Family members', (can_update_patient_resource && !patient.deleted_at) ? containedButton(addClick, 'Add Family History', false, null, {}, {
                    icon: <PlusOutlined />
                }) : null)
            }
        </div>)
    );
}
const mapStateToProps = (state) => ({
    
    activeUser: state.auth?.activeUser, familyHistory: state.patients.healthData.familyHistory,
    patient: state.patients.selectedPatient
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(FamilyHistorySection)
