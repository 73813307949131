import React, { useEffect, useState } from 'react'

import HeaderTop from '../../../components/HeaderTop'

import AppointmentPatientInfoPane from '../../Appointments/components/AppointmentPatientInfoPane';
import { Tabs } from 'antd'
import { useLocation, useNavigate } from "react-router-dom";
import EncounterPatientInfoPane from './EncounterPatientInfoPane';
import { connect, useDispatch } from 'react-redux'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'

import {
  addButtonClean,
  cleanObject,
  executeChunkFn,
  formSubmitButton,
  getAssessmentStatus,
  getprogressStatus,
  getHeaderLeftNav,
  getSubmitStatus,
  horStack,
  inputRow,
  boldKeyCapitalizedValue,
  boldKeyCapitalizedValueGrayBackground,
  makeAutoCompleteOptions,
  modalClose,
  outlinedButton,
  secondaryButton,
  getPatientStatus,
  keyValItemSimple,
  keyValItemCaps
} from '../../../common/helpers'
import { Body2, Headline5, Headline6, Subtitle1, Subtitle2 } from '../../../components'
import { AutoComplete, Avatar, Button, Checkbox, Col, Divider, Dropdown, Form, Image, Input, InputNumber, Menu, Modal, Row, Spin, Typography } from 'antd'
import dayjs from 'dayjs'
import { CloseCircleOutlined, EditOutlined, LoadingOutlined, CloseOutlined } from '@ant-design/icons'
import { fetchBillingEncounter, fetchFacilityById, selectFacility, submitBillingEncounter, updateBillingEncounter } from '../../../app/Reducers'
import { toast } from 'react-toastify'
import { apiRequest } from '../../../app/Apis'
import { usePermissionCheck } from '../../../hooks/usePermissionCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toastUp } from '../../../app/Reducers/reducerUtils'
import _ from 'lodash'
import Claims from './Claims';
import BiologicClaims from './BiologicClaims';
import PatientInfoPaneCommon from '../../../components/PatientInfoPaneCommon';




const TabList = ['Medical Care', 'Supplies', 'Biologics', 'Lab']

export const NewEncounterPage = ({ encounter, masterListRaw }) => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [masterLocations, setMasterLocations] = useState(masterListRaw[0] ? masterListRaw.find(item => item.system_name === 'wound_location')?.options : [])
  const [woundLocations, setwoundLocations] = useState((!!masterLocations && masterLocations[0]) ? makeAutoCompleteOptions(...[masterListRaw.find(item => item.system_name === 'wound_location')?.options]) : [])
  const encounter_id = Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [isEditing, setIsEditing] = useState(false);


  const can_update_billing = usePermissionCheck('update_assessment_billing', false, [])

 


  





  const handleMenuClick = async (e) => {
    console.log('click', e);
    let key = e.key
    if (key === "needs_review") {
      setreasonModal(true)
    }
  }

  const menu = (<Menu onClick={handleMenuClick}>
    <Menu.Item key={"needs_review"} style={{ textTransform: 'capitalize' }}> {"needs_review".replace('-', '').replace(/_/g, ' ')}  </Menu.Item>
  </Menu>);
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  };
  const tailLayout = {
    wrapperCol: { offset: 0, span: 24 }
  };

  // isExecuting
  const [isExecuting, setIsExecuting] = useState(false)
  
  function viewPatient(id) {
    navigate(`/patients/${id}`)
  }

  async function viewFacility(id) {
    const response = await apiRequest.get(`/medical-facilities/${id}`);
    dispatch(selectFacility(response.data.data))
    if (response.data.data) {
      navigate(`/facilities/${id}`)
    }
  }

  useEffect(() => {
    console.log('Encounter:::', encounter)
    dispatch(fetchBillingEncounter({ id: encounter_id }))
   
    return () => { }
  }, [])

  

  async function onEntityUpdateFinish(values) {
    console.log(values)
    setIsEditing(false)
  }



  const [form] = Form.useForm();


  // reason modal
  const [reasonModal, setreasonModal] = useState(false)

  const reasonfinish = async (values) => {
    console.log('Success:', values);

    const toastId = toast("Updating Assesment status", { type: "info", autoClose: false });
    const response = await apiRequest.post(`/assessments/${assessment?.id}/status`, { status: "needs_review", notes: values.reason }).catch(async function (err) {
      toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
    })
    if (response && response.data) {
      toast.update(toastId, { render: "Assessment status updated", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      navigate(-1)
    }
  };
  // fetch assessment tied to encounter
  const [assessment, setAssessment] = useState(null)
  const [assessmentLoading, setassessmentLoading] = useState(false)
  const fetchAssessment = async (id) => {
    setassessmentLoading(true)
    const response = await apiRequest.get(`/assessments/${id}`);
    if (response.data.data) {
      console.log('assessment', response.data.data)
      setAssessment(response.data.data)
      setAssessmentWidth("30%")
      setassessmentLoading(false)
    }
  }






  const [assessmentWidth, setAssessmentWidth] = useState("0%");

  // activeTab
  const [activeTab, setActiveTab] = useState('Medical Care')
  const changTab = async (tab) => {
    console.log('changTab:  ', tab)
    setActiveTab(tab)
  }



  return (
    <div className="full-page flex flex-col bg-gray-200">
      <Modal
        title={`Please add a Reason`}
        open={reasonModal}
        destroyOnClose={true}
        onOk={() => setreasonModal(false)}
        onCancel={() => setreasonModal(false)}
        closeIcon={modalClose(() => setreasonModal(false))}
        maskClosable={false}
        width={1200}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(() => setreasonModal(false), 'Cancel', isExecuting),
          formSubmitButton('reasonForm', 'Update Reason', isExecuting, 'Updating')])}
        </div>]}>
        <Form
          name="reasonForm"
          onFinish={reasonfinish}
          layout="vertical"
        >
          <Form.Item
            style={{ width: '100%' }}
            label="Review Request Note"
            name="reason"
            rules={[{ required: true, message: 'this field is required!' }]}
            wrapperCol={{ span: 24 }}>
            <Input autoComplete={'off'} />
          </Form.Item>
        </Form>
      </Modal>
      <HeaderTop child={getHeaderLeftNav(navigate, 'Billing')} />
      <Row className={'h-full'} gutter={[6, 0]}>
        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
          <div className=" h-full">
            {/* <EncounterPatientInfoPane setDetailsForm={null} patient={encounter?.patient} /> */}
            <PatientInfoPaneCommon patientProp={encounter?.patient} />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={20} xl={20} className={'h-full'}>
          <div className="w-full flex flex-row justify-between">


            {assessmentWidth !== "100%" && <div className=" h-full flex-1 bg-white h-fit min-h-screen p-2">
              {/* Ant Design Tabs */}
               <div id="patient-encounter-tabs" className="bg-white">
              <Tabs type="card" defaultActiveKey={activeTab} activeKey={activeTab} onChange={changTab} tabBarStyle={{ marginBottom: 0 }}>
                {TabList.map(item => <TabPane key={item} tab={<p>{item}</p>}> </TabPane>)}
                </Tabs>
                </div>

              {activeTab === 'Biologics' ? <BiologicClaims appointmentId={1090000008174} fetchAssessment={fetchAssessment} /> : <Claims fetchAssessment={fetchAssessment} tab={activeTab} />}

            </div>}
            <div className={`bg-gray-100 h-screen overflow-y-scroll  transition-all duration-300  
                ease-out `} style={{ width: assessmentWidth }}>
              <div className="w-full flex justify-between shadow bg-white  p-4">
                {assessmentWidth === "100%" ? <MenuUnfoldOutlined onClick={() => setAssessmentWidth("30%")} /> :
                  <MenuFoldOutlined onClick={() => setAssessmentWidth("100%")} />}
                <Headline6 className="ml-4 mt-4">Assessment Details</Headline6>
                <CloseOutlined onClick={() => setAssessmentWidth("0%")} />
                {/* <div className="flex">
                                <Button style={{ padding: 0, marginLeft: 10, marginRight: 10, borderWidth: 0 }} size={'middle'} onClick={() => console.log("clicked")}
                                    icon={<FontAwesomeIcon size="lg" color="black" icon={["far", 'file-pdf']} />}>
                                </Button>
                            </div> */}

              </div>
              {assessmentLoading ?
                <div className="h-screen justify-center  flex items-center">
                  <Spin />
                </div>
                :
                <div className={"grid grid-cols-12 gap-1 p-4"}>
                  <div className={`col-span-12 lg:${assessmentWidth === "100%" ? 'col-span-6' : 'col-span-12'}`}>
                    <div className="card-box">
                      <div className="flex-boy-row-space" style={{ width: '100%' }}>
                        <Headline6>Assessment Details</Headline6>
                        {(assessment?.billing_status === 'pending_review' && can_update_billing) ?
                          <Dropdown dropdownRender={() => menu} trigger={['click']}>
                            {secondaryButton(null, getAssessmentStatus(assessment?.assessment_status), updatingStatus, 'Updating',
                              null, null)}
                          </Dropdown>

                          : getAssessmentStatus(assessment?.assessment_status)
                        }
                      </div>
                      <Row gutter={8} style={{ width: '100%' }}>
                        <Col xl={24} xxl={12}>
                          {boldKeyCapitalizedValue('Patient', encounter?.patient?.name)}
                          {boldKeyCapitalizedValueGrayBackground('Visit', dayjs(assessment?.service_date).format('MM/DD/YYYY'))}
                          {boldKeyCapitalizedValue('Procedure', assessment?.procedure)}
                          {boldKeyCapitalizedValueGrayBackground('WCC', assessment?.consultant_name)}
                        </Col>
                        <Col xl={24} xxl={12}>
                          {boldKeyCapitalizedValue('Location', `${assessment?.wound_position && assessment?.wound_side ? assessment?.wound_side : ''} ${assessment?.wound_position ||
                            ''} ${assessment?.location || ''}`)}
                          {boldKeyCapitalizedValueGrayBackground('Status', assessment?.status)}
                          {boldKeyCapitalizedValue('Classification', assessment?.wound_type)}
                          {boldKeyCapitalizedValueGrayBackground('Stage', assessment?.stage)}
                          {boldKeyCapitalizedValue('Diagnosis', assessment?.diagnosis)}
                          {assessment?.procedure.includes('Biologics') &&
                            boldKeyCapitalizedValue('Treatment Number', assessment?.treatment_number)}
                          {/* {/* {boldKeyCapitalizedValue('Exudate', assessment?.exudate)} */}
                          {/* {boldKeyCapitalizedValue('Office', assessment?.exudate_type)} */}
                        </Col>
                      </Row>
                    </div>
                    <div className="card-box">
                      <Subtitle1>Dimensions</Subtitle1>
                      <Row gutter={8} style={{ width: '100%' }}>
                        <Col xl={24} xxl={12}>
                          {boldKeyCapitalizedValue('Length', `${parseFloat(assessment?.length).toFixed(2)}cm`)}
                          {boldKeyCapitalizedValueGrayBackground('Width', `${parseFloat(assessment?.width).toFixed(2)}cm`)}
                          {boldKeyCapitalizedValue('Depth', `${parseFloat(assessment?.depth).toFixed(2)}cm`)}
                        </Col>
                        <Col xl={24} xxl={12}>
                          {boldKeyCapitalizedValue('Area', `${parseFloat(assessment?.area).toFixed(2)}cm`, '2')}
                          {boldKeyCapitalizedValueGrayBackground('Volume', `${parseFloat(assessment?.volume).toFixed(2)}cm`, '3')}
                          {boldKeyCapitalizedValue('Wound size', assessment?.size)}
                          {(assessment?.procedure === 'Wound Debridement' ||
                            assessment?.procedure.includes('Biologics')) && (
                              <div style={{ marginTop: '20px' }}>
                                <Subtitle1>Post Debridement Dimensions</Subtitle1>
                                {boldKeyCapitalizedValue('Length', `${parseFloat(assessment?.new_length).toFixed(2)}cm`)}
                                {boldKeyCapitalizedValueGrayBackground('Width', `${parseFloat(assessment?.new_width).toFixed(2)}cm`)}
                                {boldKeyCapitalizedValue('Depth', `${parseFloat(assessment?.new_depth).toFixed(2)}cm`)}
                              </div>
                            )}
                        </Col>
                      </Row>
                    </div>
                    <div className="card-box">
                      <Headline6>Summary</Headline6>
                      <div className='w-full'>
                        <Subtitle1 style={{ textAlign: 'left' }}>Patient Information</Subtitle1>
                        <div className="rounded-md w-full p-2 my-2 mb-4" style={{ background: '#ebeff4' }}>
                          <div className="flex flex-row w-full mt-2 justify-between items-center">
                            <div className="flex flex-row items-center justify-between w-full">
                              <div className='flex flex-row items-center'>
                                <Avatar className='mr-2' size="md">{assessment?.patient?.name?.charAt(0)} </Avatar>
                                <Subtitle1 className='ml-2 text-bold mt-8' color='black'>{assessment?.patient?.name}</Subtitle1>
                              </div>
                              <div className='flex flex-row cursor-pointer items-center' onClick={() => viewPatient(assessment?.patient?.id)}>
                                <Subtitle1 className='mr-2 text-bold text-sm' color='black'>View Patient</Subtitle1>
                                <FontAwesomeIcon className='ml-2' size='sm' color="black" icon={['fas', 'chevron-right']} />
                              </div>
                            </div>
                          </div>
                          <div className='w-full'>
                            {/* <Subtitle1 className='ml-2 text-bold text-sm' color='black'>Address: {assessment?.patient?.address_1}</Subtitle1> */}
                            {keyValItemSimple('Address:', assessment?.patient?.address_1 || 'N/A')}
                            {keyValItemSimple('Address 2:', assessment?.patient?.address_2 || 'N/A')}
                            {keyValItemSimple('Status:', getPatientStatus(assessment?.patient?.status))}
                           
                          </div>
                        </div>
                      </div>
                      <div className='w-full'>
                        <Subtitle1 style={{ textAlign: 'left' }}>Office Information</Subtitle1>
                        <div className="rounded-md w-full p-2 my-2 mb-4" style={{ background: '#ebeff4' }}>
                          <div className="flex flex-row w-full mt-2 justify-between items-center">
                            <div className="flex flex-row items-center justify-between w-full">
                              <div className='flex flex-row items-center'>
                                <Avatar className='mr-2' size="md">
                                  <FontAwesomeIcon size="lg" color="grey" icon={['fas', 'hospital']} />
                                </Avatar>
                                <Subtitle1 className='ml-2 text-bold mt-8' color='black'>{assessment?.facility?.name}</Subtitle1>
                              </div>
                              <div className='flex flex-row cursor-pointer items-center' onClick={() => viewFacility(assessment?.facility?.id)}>
                                <Subtitle1 className='mr-2 text-bold text-sm' color='black'>View Office</Subtitle1>
                                <FontAwesomeIcon className='ml-2' size='sm' color="black" icon={['fas', 'chevron-right']} />
                              </div>
                            </div>
                          </div>
                          <div className='w-full'>
                            {/* <Subtitle1 className='ml-2 text-bold text-sm' color='black'>Address: {assessment?.patient?.address_1}</Subtitle1> */}
                            {boldKeyCapitalizedValue('Address:', assessment?.facility?.address || 'N/A')}

                          </div>
                        </div>
                      </div>
                      <div className='w-full'>
                        <Subtitle1 style={{ textAlign: 'left' }}>Visit Addendum</Subtitle1>
                        <Body2 style={{ textAlign: 'left' }} color="black">{assessment?.visit_summary || 'N/A'} </Body2>
                      </div>
                    </div>
                  </div>
                  <div className={`col-span-12 lg:${assessmentWidth === "100%" ? 'col-span-6' : 'col-span-12'}`}>
                    <div className="card-box">
                      <Headline6>Location and Image</Headline6>
                      <div className="flex">
                        {assessment?.woundLocationPath ? (
                          <Image
                            src={assessment?.woundLocationPath ? assessment?.woundLocationPath.url : woundLocations.find((item) => item === assessment?.location) ? woundLocations.find((item) => item === assessment?.location).image : require('../../../assets/woundLoc.png')}
                            height={150}
                          />
                        ) : null}
                        <Image
                          style={{ width: '150px' }}
                          src={assessment?.woundImage.url}
                          height={150}
                        />
                      </div>
                    </div>
                    <div className="card-box">
                      <Headline6>Wound Details</Headline6>
                      <Row gutter={8} style={{ width: '100%' }}>
                        <Col xl={24} xxl={12}>
                          {/* {boldKeyCapitalizedValue('Wound side', assessment?.wound_side)} */}
                          {boldKeyCapitalizedValue('Wound Stage', assessment?.stage)}
                          {boldKeyCapitalizedValueGrayBackground('Wound Bed', assessment?.wound_bed)}
                          {boldKeyCapitalizedValue('Granulation Tissue', `${parseFloat(assessment?.granulation_tissue).toFixed(2)}`)}
                          {boldKeyCapitalizedValueGrayBackground('Necrotic Tissue', assessment?.necrotic_tissue)}
                          {boldKeyCapitalizedValue('FibrousTissue', assessment?.fibrous_tissue)}
                          {/* {boldKeyCapitalizedValueGrayBackground('Slough Tissue', assessment?.slough_tissue)}
                      {boldKeyCapitalizedValue('Eschar Tissue', assessment?.eschar_tissue)} */}
                          {boldKeyCapitalizedValueGrayBackground('Exudate Amount', assessment?.exudate_amount)}
                          {boldKeyCapitalizedValue('Exudate Type', assessment?.exudate_type)}
                          {boldKeyCapitalizedValueGrayBackground('Periwound Color', assessment?.periwound_color)}
                          {!assessment?.procedure.includes('Biologics') && boldKeyCapitalizedValue('Wound Condition', assessment?.wound_condition)}
                          {boldKeyCapitalizedValue('Wound Duration', assessment?.wound_duration)}
                          {boldKeyCapitalizedValueGrayBackground('Conservative Treatment', assessment?.conservative_treatment?.toString())}
                          {(assessment?.procedure === 'DME' || assessment?.procedure.includes("Lab")) && boldKeyCapitalizedValue('Response to Therapy', assessment?.response_to_therapy)}

                        </Col>
                        <Col xl={24} xxl={12}>
                          {boldKeyCapitalizedValue('Wound edges', assessment?.wound_edges)}
                          {assessment?.procedure === 'Wound Debridement' && boldKeyCapitalizedValue('Wound Dressing', assessment?.wound_dressing)}
                          {boldKeyCapitalizedValueGrayBackground('Undermining', assessment?.undermining)}
                          {boldKeyCapitalizedValue('Epithelialization', assessment?.epithelialization)}
                          {boldKeyCapitalizedValueGrayBackground('Tunneling', assessment?.tunneling_size && assessment?.tunneling_direction !== 'N/A' ? `${assessment?.tunneling_size}cm at ${assessment?.tunneling_direction}` : 'N/A')}
                          {boldKeyCapitalizedValue('Sinus Tract', assessment?.sinus_tract ? `${assessment?.sinus_tract} cm ` : 'N/A')}
                          {boldKeyCapitalizedValueGrayBackground('Odor', assessment?.odor)}
                          {boldKeyCapitalizedValue('Infection', assessment?.infection)}
                          {boldKeyCapitalizedValueGrayBackground('Pain Level', assessment?.pain_level)}
                          {boldKeyCapitalizedValue('Exposed Structures', Array.isArray(assessment?.exposed_structures) ? assessment?.exposed_structures.join(', ') : assessment?.exposed_structures)}
                          {assessment?.procedure === 'Wound Debridement' && boldKeyCapitalizedValueGrayBackground('Debridement Type', assessment?.debridement_type)}
                          {boldKeyCapitalizedValue('Orders/Recommendations', assessment?.other_interventions?.toString())}
                          {boldKeyCapitalizedValueGrayBackground('Other Related Factors', assessment?.other_related_factors?.toString() || 'N/A')}

                          {(assessment?.procedure === 'Wound Debridement' || assessment?.procedure === 'Biologics Application') && boldKeyCapitalizedValue('Anesthesia Used', assessment?.anesthesia_used)}
                        </Col>

                        {assessment?.procedure === 'Wound Debridement' && (
                          <>
                            <Col xl={24} xxl={12}>
                              <Headline6 style={{ marginBottom: '20px', marginTop: '20px' }}> Vascular Measurements </Headline6>
                              <div>
                                {boldKeyCapitalizedValue('Right DP', assessment?.right_d_p)}
                                {boldKeyCapitalizedValueGrayBackground('Left DP', assessment?.left_d_p)}
                                {boldKeyCapitalizedValue('Right PT', assessment?.right_p_t)}
                                {boldKeyCapitalizedValueGrayBackground('Left PT', assessment?.left_p_t)}
                                {boldKeyCapitalizedValue('Skin Temperature Right', assessment?.skin_temperature_right)}
                                {boldKeyCapitalizedValueGrayBackground('Skin Temperature Left', assessment?.skin_temperature_right)}
                                {boldKeyCapitalizedValue('Digital Hair Right', assessment?.digital_hair_right)}
                                {boldKeyCapitalizedValueGrayBackground('Digital Hair Left', assessment?.digital_hair_left)}
                              </div>
                            </Col>

                            <Col xl={24} xxl={12}>
                              <Headline6 style={{ marginBottom: '20px', marginTop: '20px' }}> Neurology Measurements </Headline6>
                              <div>
                                {boldKeyCapitalizedValue('Monofilament Testing Right', assessment?.monofilament_testing_right)}
                                {boldKeyCapitalizedValueGrayBackground('Monofilament Testing Left', assessment?.monofilament_testing_left)}
                                {boldKeyCapitalizedValue('Pin Prick Right', assessment?.pin_prick_right)}
                                {boldKeyCapitalizedValueGrayBackground('Pin Prick Left', assessment?.pin_prick_left)}
                                {boldKeyCapitalizedValue('Soft Touch Right', assessment?.soft_touch_right)}
                                {boldKeyCapitalizedValueGrayBackground('Soft Touch Left', assessment?.soft_touch_left)}
                              </div>
                            </Col>
                          </>
                        )}
                        {assessment?.procedure.includes('Biologics') && (
                          <>
                            <Col span={24}>
                              <Subtitle1 style={{ marginBottom: '20px', marginTop: '20px', textAlign: 'left' }}> Assessment Observations </Subtitle1>
                              <div>
                                {assessment?.treatment_number !==
                                  'Pre-treatment Assessment - Upper Extremity' && boldKeyCapitalizedValue('Circulation', assessment?.circulation)}
                                {boldKeyCapitalizedValueGrayBackground('Comorbidites', assessment?.comorbidities)}
                                {boldKeyCapitalizedValue('Primary Care Physician', assessment?.primary_care_physician)}
                                {boldKeyCapitalizedValueGrayBackground('Conservative Treatment', assessment?.conservative_treatment?.toString())}
                                {assessment?.treatment_number !== 'Pre-treatment Assessment - Upper Extremity' && boldKeyCapitalizedValue('Neurologic Status', assessment?.neurologic_status)}
                                {boldKeyCapitalizedValueGrayBackground('Wound Duration', assessment?.wound_duration)}
                              </div>
                            </Col>
                          </>
                        )}

                        <Col xl={24} xxl={12}>
                          {(assessment?.procedure === 'Wound Debridement' || assessment?.procedure.includes('Biologics')) && <Subtitle1
                            style={{ marginBottom: '20px', marginTop: '20px', textAlign: 'left' }}
                          >
                            Wound Debridement</Subtitle1>}
                          {(assessment?.procedure === 'Wound Debridement' || assessment?.procedure.includes('Biologics')) &&
                            boldKeyCapitalizedValue('Debridement Methods', assessment?.debridement_methods?.toString())}
                          {(assessment?.procedure === 'Wound Debridement' || assessment?.procedure.includes('Biologics')) &&
                            boldKeyCapitalizedValueGrayBackground('Tissue Debrided', assessment?.tissue_debrided?.toString())}
                          {(assessment?.procedure === 'Wound Debridement' || assessment?.procedure.includes('Biologics')) &&
                            boldKeyCapitalizedValue('Instrument Used', assessment?.instruments_used?.toString())}
                          {(assessment?.procedure === 'Wound Debridement' || assessment?.procedure.includes('Biologics')) &&
                            boldKeyCapitalizedValueGrayBackground('Level of Debridement', assessment?.level_of_debridement?.toString())}
                          {(assessment?.procedure === 'Wound Debridement' || assessment?.procedure.includes('Biologics')) &&
                            boldKeyCapitalizedValue('Hemostasis', assessment?.hemostasis?.toString())}
                          {assessment?.procedure === 'Wound Debridement' &&
                            boldKeyCapitalizedValueGrayBackground(' Wound Cleanser', assessment?.wound_cleanser?.toString())}
                        </Col>
                      </Row>

                    </div>
                    {(assessment?.procedure.includes('Biologics') ||
                      assessment?.procedure === 'DME') && <div className="card-box">
                        <Headline6>Assessment Kit</Headline6>
                        {assessment?.procedure.includes('Biologics') ||
                          assessment?.procedure === 'DME'
                          ? (
                            <>
                              <div className='w-full'>
                                <div className="visit-card">

                                  {assessment?.procedure.includes('Biologics') ? (
                                    <>
                                      <Subtitle1>Biologics</Subtitle1>
                                      {(() => {
                                        if (assessment?.biologic_kits && !!assessment?.biologic_kits[0]) {
                                          let productNames = [];
                                          let assessmentKits_ = [];
                                          for (const bioKit of assessment?.biologic_kits) {
                                            let name = `${bioKit.biologic_product.company_name} ${bioKit.biologic_product.product_name}`;
                                            if (!productNames.includes(name)) {
                                              let productKits = assessment?.biologic_kits.filter((item) => `${item.biologic_product.company_name} ${item.biologic_product.product_name}` === name);
                                              let biologic_kits = productKits.map(
                                                (item) => ({
                                                  id: item.id, size: item.size, quantity: item.quantity, biologic_kit_code: item.biologic_kit_code,
                                                  ...(!!item.tissue_id && { tissue_id: item.tissue_id }),
                                                  ...(!!item.serial_number && { serial_number: item.serial_number }),
                                                  ...(!!item.ocr_data && !!item.ocr_data[0] && { ocr_data: [...item.ocr_data] })
                                                })
                                              );
                                              assessmentKits_ = [...assessmentKits_, { product: name, biologic_kits }];
                                              productNames.push(name);
                                            }
                                          }
                                          return assessmentKits_.map((bioKit) => {
                                            return (
                                              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, width: '100%' }}>
                                                <Typography variant={'body2'} style={{ fontWeight: 600, textAlign: "left" }}>{bioKit.product}:{' '}</Typography>
                                                {bioKit?.biologic_kits.map((item, i) => {
                                                  // console.log('ocr:  ', item)
                                                  // console.log('!item.ocr_data:  ', !item.ocr_data)
                                                  // <Typography key={`${i}`} variant={"body2"}>{`${item.biologic_kit_code}`}:&nbsp;     &nbsp;{`${item.size}`}&nbsp;     &nbsp;{`Quantity: ${item.quantity}`}</Typography>
                                                  return (
                                                    <Row
                                                      gutter={[4, 1]}
                                                      align={'stretch'}
                                                      justify={'start'}
                                                      style={{
                                                        width: '100%',
                                                        overflowWrap: 'break-word'
                                                      }}
                                                    >
                                                      <Col
                                                        xs={24}
                                                        sm={24}
                                                        md={8}
                                                        lg={8}
                                                        xl={8}
                                                        flex={'auto'}
                                                        style={{ width: '100%' }}
                                                      >
                                                        <Typography
                                                          style={{ marginTop: 6 }}
                                                          key={`${i}`}
                                                          variant={'body2'}
                                                        >
                                                          {`${item.biologic_kit_code}`}:
                                                        </Typography>
                                                      </Col>
                                                      <Col
                                                        xs={24}
                                                        sm={24}
                                                        md={16}
                                                        lg={16}
                                                        xl={16}
                                                        flex={'auto'}
                                                        style={{ width: '100%' }}
                                                      >
                                                        <div style={{ width: '100%' }}>
                                                          {boldKeyCapitalizedValue(
                                                            'Size:',
                                                            `${item.size}`
                                                          )}
                                                          {boldKeyCapitalizedValue(
                                                            'Quantity:',
                                                            `${item.quantity}`
                                                          )}
                                                          {assessment?.procedure.includes(
                                                            'Application'
                                                          ) &&
                                                            !item.ocr_data &&
                                                            !!item.tissue_id &&
                                                            boldKeyCapitalizedValue(
                                                              'Tissue ID:',
                                                              `${item.tissue_id || 'N/A'}`
                                                            )}
                                                          {assessment?.procedure.includes(
                                                            'Application'
                                                          ) &&
                                                            !item.ocr_data &&
                                                            !!item.serial_number &&
                                                            boldKeyCapitalizedValue(
                                                              'Serial No:',
                                                              `${item.serial_number ||
                                                              'N/A'}`
                                                            )}
                                                          {assessment?.procedure.includes(
                                                            'Application'
                                                          ) &&
                                                            !!item.ocr_data &&
                                                            item.ocr_data.map((ocr) => {
                                                              return (
                                                                <>
                                                                  {boldKeyCapitalizedValue(
                                                                    'Serial No:',
                                                                    `${ocr.serial_number ||
                                                                    'N/A'}`
                                                                  )}
                                                                  {boldKeyCapitalizedValue(
                                                                    'Tissue ID:',
                                                                    `${ocr.tissue_id ||
                                                                    'N/A'}`
                                                                  )}
                                                                  <Divider />
                                                                </>
                                                              );
                                                            })}
                                                        </div>
                                                      </Col>
                                                      <Divider style={{ margin: 4 }} />
                                                    </Row>
                                                  );
                                                })}
                                              </div>
                                            );
                                          });
                                        } else return 'N/A';
                                      })()}
                                    </>
                                  ) : (
                                    <>
                                      <Subtitle1>
                                        Scenario:{' '}
                                        {assessment?.recommended_treatment_protocol
                                          ? assessment?.recommended_treatment_protocol
                                          : null}
                                      </Subtitle1>
                                      <Subtitle1 color="grey">Primary Dressing</Subtitle1>
                                      {assessment?.treatment_order_items
                                        .filter((item) => item.type === 'Primary Dressing')
                                        .map((item, i) => (
                                          <div
                                            className="flex-boy-row-space"
                                            style={{ width: '100%' }}
                                            key={`${i}`}
                                          >
                                            <Body2
                                              color="black"
                                              style={{ textAlign: 'left', flex: 1, lineHeight: '21px' }}
                                            >
                                              {item.name} ({item.pack_amount} {item.unit_of_measure})
                                            </Body2>
                                            <Body2 color="black" style={{ textAlign: 'left', lineHeight: '21px', flex: 'initial' }}>
                                              {item.unit_count}{' '}
                                              {item.unit_of_measure === 'box' ? 'boxe' : item.unit_of_measure === 'oz' ? 'oz' : item.unit_of_measure} s
                                            </Body2>
                                          </div>
                                        ))}
                                      <Subtitle1 color="grey">Secondary Dressing</Subtitle1>
                                      {assessment?.treatment_order_items.filter((item) => item.type === 'Secondary Dressing').map((item, i) => (
                                        <div
                                          className="flex-boy-row-space"
                                          style={{ width: '100%' }}
                                          key={`${i}`}
                                        >
                                          <Body2
                                            color="black"
                                            style={{
                                              textAlign: 'left',
                                              flex: 1,
                                              lineHeight: '21px'
                                            }}
                                          >
                                            {item.name} ({item.pack_amount} {item.unit_of_measure})
                                          </Body2>
                                          <Body2
                                            color="black"
                                            style={{
                                              textAlign: 'left',
                                              lineHeight: '21px',
                                              flex: 'initial'
                                            }}
                                          >
                                            {item.unit_count}{' '}
                                            {item.unit_of_measure === 'Box'
                                              ? 'Boxe' :
                                              item.unit_of_measure === 'Bottle(s)'
                                                ? 'Bottle'
                                                : item.unit_of_measure === 'Oz'
                                                  ? 'oz'
                                                  : item.unit_of_measure}
                                            s
                                          </Body2>
                                        </div>
                                      ))}
                                      <Subtitle1 color="grey">Additional Supplies</Subtitle1>
                                      {assessment?.treatment_order_items.filter((item) => item.type === 'Additional Dressing').map((item, i) => (
                                        <div
                                          className="flex-boy-row-space"
                                          style={{ width: '100%' }}
                                          key={`${i}`}
                                        >
                                          <Body2
                                            color="black"
                                            style={{
                                              textAlign: 'left',
                                              flex: 1,
                                              lineHeight: '21px'
                                            }}
                                          >
                                            {item.name} ({item.pack_amount} {item.unit_of_measure})
                                          </Body2>
                                          <Body2
                                            color="black"
                                            style={{
                                              textAlign: 'left',
                                              lineHeight: '21px',
                                              flex: 'initial'
                                            }}
                                          >
                                            {item.unit_count}{' '}
                                            {item.unit_of_measure === 'Box'
                                              ? 'Boxe' :
                                              item.unit_of_measure === 'Bottle(s)'
                                                ? 'Bottle'
                                                : item.unit_of_measure === 'Oz'
                                                  ? 'oz'
                                                  : item.unit_of_measure}
                                            s
                                          </Body2>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                </div>
                              </div>

                              <Divider />
                            </>
                          ) : null}
                      </div>}
                  </div>
                </div>
              }

            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = (state) => ({
  encounter: state.billing.selectedEncounter,
  masterListRaw: state.masters.masterListRaw, activeUser: state.auth.activeUser
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(NewEncounterPage)