/* eslint-disable no-unused-vars */
import React, {useEffect} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Tabs} from "antd"
import AllergiesSection from './AllergiesSection';
import ImmunizationSection from './ImmunizationSection';
import DiagnosisSection from './DiagnosisSection';
import FamilyHistorySection from './FamilyHistorySection';
import MedicationSection from './MedicationSection';
import SocialHistorySection from './SocialHistorySection';
import {fetchPatientById, setPatientDetailsLoading, setPatientNavs, updatePatientDetailsOwner} from "../../../../app/Reducers";
import {emptyFun} from "../../../../common/helpers";

import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';

const { TabPane } = Tabs;
export const Index = ({  navs, patient }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1)) || patient.id
  const can_view_patient_medication = usePermissionCheck('view_patient_medication', false, [])
  const can_view_patient_diagnosis = usePermissionCheck('view_patient_diagnosis', false, [])
  const can_view_patient_allergy = usePermissionCheck('view_patient_allergy', false, [])
  const can_view_patient_next_of_kin = usePermissionCheck('view_patient_next_of_kin', false, [])
  useEffect(() => {
    (async () => {
      //In case of back navigations
      if (!patient || (patientId !== patient.id)) {
        await dispatch(setPatientDetailsLoading(true))
        await dispatch(fetchPatientById(Number(patientId)))
        await dispatch(setPatientDetailsLoading(false))
        await dispatch(updatePatientDetailsOwner(patientId))
      }
    })().catch(e => console.log(e))
    return emptyFun
  }, [])
  
  const callback = async key => await dispatch(setPatientNavs({ healthTab: key }));
  const tabList = ["Allergies", "Immunization", "Medication", "Diagnosis", "Social History", "Family History"]
  return (<div id="patient-encounter-tabs">
    <Tabs defaultActiveKey={navs.patientNavs.healthTab} onChange={callback} type="card">
      {tabList.map(item => <TabPane key={item} tab={item}> </TabPane>)}
    </Tabs>
    <div className="main-body">
      {(navs.patientNavs.healthTab === "Allergies" && can_view_patient_allergy) ? <AllergiesSection/> : navs.patientNavs.healthTab === "Immunization" ?
        <ImmunizationSection/> : (navs.patientNavs.healthTab === "Medication" && can_view_patient_medication) ? <MedicationSection/> :
          (navs.patientNavs.healthTab === "Diagnosis" && can_view_patient_diagnosis) ? <DiagnosisSection/> : // navs.patientNavs.healthTab === "Prescriptions" ? <PrescriptionSection /> :
            (navs.patientNavs.healthTab === "Family History" && can_view_patient_next_of_kin) ? <FamilyHistorySection/> : <SocialHistorySection/>}
    </div>
  </div>)
}
const mapStateToProps = (state) => ({
  patient: state.patients.selectedPatient, navs: state.navs
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Index)
