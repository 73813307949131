import { useEffect, useState } from 'react';
import { appLog } from "../common/helpers";

const initBeforeUnLoad = (showExitPrompt, reloadPage, syncPageStatus) => {
  /*  window.onbeforeunload = (event) => {
      console.log('THIS WAS onbeforeunload')
      if (showExitPrompt) {
        alert('Trying to reload window')
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = '';
        }
        return '';
      }
    };*/
  window.onload = ev => {
    console.log('THIS WAS ONLOAD')
  }
  if (window.performance) {
    if (performance.navigation.type === 1) {
      console.log("This page is reloaded");
      reloadPage()
    } else {
      console.log(performance.getEntriesByType("navigation")[0].type)
      console.log(performance.navigation.type)
      console.log("This page is not reloaded");
      syncPageStatus()
    }
  }
};
// Hook
export default function useExitPrompt(bool, reloadPage, syncPageStatus) {
  const [showExitPrompt, setShowExitPrompt] = useState(bool);
  window.onload = function () {
    initBeforeUnLoad(showExitPrompt);
  };
  useEffect(() => {
    initBeforeUnLoad(showExitPrompt, reloadPage, syncPageStatus);
  }, [showExitPrompt]);
  return [showExitPrompt, setShowExitPrompt];
}
