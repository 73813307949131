import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { AutoComplete, Button, Divider, Form, Input, Radio, Select, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
// import dayjs from 'dayjs';
import dayjs from 'dayjs';
import {Body1, Headline6, Subtitle1} from '../../../components';
import {apiRequest} from '../../../app/Apis';
import {importProspect} from '../../../app/Reducers'
import NumberFormat from "react-number-format";
import {dialogCloser, emptyFun, executeChunkFn, boldKeyCapitalizedValue, makeAutoCompleteOptions, showImagePreview} from "../../../common/helpers";
import {fetchActiveFacilities, handleActiveFacilitySearch} from "../../../app/Apis/commonApi"
import {errMsg, getToastId, updateToast} from "../../../app/Reducers/reducerUtils"
import {toast} from 'react-toastify';
import _ from 'lodash';
import GetCookie from '../../../hooks/GetCookie';
import {useWoundLocationSearch} from '../../../hooks/useWoundLocationSearch';

const facesheet_types = ["SNF", "Hospice care", "Palliative care", "Other"]
const NewProspectCard = ({ closeModal, activeUser, selectedFacility, setisExecuting, masterListRaw }) => {
    const { Option } = Select
    const [faceForm] = Form.useForm()
    const [prospectForm] = Form.useForm()
    const dateFormat = 'YYYY-MM-DD'
    const [facesheetForm, setFacesheetForm] = useState(false)
    const [facilities, setFacilities] = useState([])
    const [facility, setFacility] = useState()
    const [image, setImage] = useState(null)
    const [images, setImages] = useState([])
    const [faceImages, setFaceImages] = useState([])
    const [faceSheets, setFaceSheets] = useState([])
    const [creatingProspect, setCreatingProspect] = useState(false)
    const [date, setDate] = useState(null)
    const dispatch = useDispatch()
    const [phoneValue, setPhoneValue] = useState(undefined);
    const uploadUrl = process.env.REACT_APP_ENDPOINT + '/files'
    const token = GetCookie('accessToken')
    const [preview, setPreview] = useState(false)
    const [fileList, setFileList] = useState([])
    // const [patientDetailsList, patientDetailsFileList] = useState([])
    const [LocationError, setLocationError] = useState(false);
    const { woundLocationOptions, debouncedWLocationSearch, woundLocationLoading } = useWoundLocationSearch( [])
    useEffect(() => {
        fetchActiveFacilities(setFacilities)
        return emptyFun
    }, [])
    const createFaceSheet = async (payload) => {
        console.log('facesheet payload', payload)
        const toastId = getToastId("Adding FaceSheet")
        try {
            const response = await apiRequest.post('/face-sheets', payload)
            console.log('createFaceSheet: ', response.data.data)
            if (response && response.data && response.data.data) {
                setFaceSheets([...faceSheets, response.data.data.id])
                setFaceImages([...faceImages, { url: image.url, name: image.name, sheetId: response.data.data.id }])
                prospectForm.setFieldsValue({ face_sheets: faceSheets })
                updateToast(toastId, "Facesheet Added", true)
                setFacesheetForm(false)
                faceForm.resetFields()
            }
        } catch (e) {
            updateToast(toastId, errMsg(e, false), false)
        }
    }

    const getPatientDetails = async options => {
        const { onSuccess, onError, file, onProgress } = options;
        let formData = new FormData();
        // add one or more of your files in FormData
        // again, the original file is located at the `originFileObj` key
        const toastId = toast("Generating prospect details", { type: "info", autoClose: false });
        formData.append("patient_details_image", file);
        const { data } = await apiRequest.post("/ocr-patient-details", formData).catch(function (err) {
            toast.update(toastId, { render: err.response?.data.message || "Server Error", type: toast.TYPE.ERROR, autoClose: 5000 });
            setFileList([])
        })
        if (data && data.data) {
            console.log('data.data: ', data.data)
            toast.update(toastId, { render: "Prospect Details Generated", type: toast.TYPE.SUCCESS, autoClose: 5000 });
            onSuccess("Ok");

            console.log(prospectForm.getFieldsValue(), data.data, data.data['First Name'].toString().slice(0, -1))
            prospectForm.setFieldsValue({
                first: data.data['First Name'].toString().slice(0, -1),
                last: data.data['Last Name']?.slice(0, -1),
                birth_date: dayjs(data.data['DOB']?.slice(0, -1)),
                email: data.data['Email']?.slice(0, -1),
                phone_no: `${data.data['Phone']}`,
                gender: data.data['Gender']?.slice(0, -1).toLowerCase(),
                ssn: data.data['SSN']?.slice(0, -1) !== "N/A" ? data.data['SSN']?.slice(0, -1) : null
            });
            handleActiveFacilitySearch(data.data.facility, setFacilities)
            faceForm.setFieldsValue({ default_region: data.data['Address'] })
        } else {
            toast.update(toastId, { render: "unable to generate prospect details fom the facesheet please manualy fill the details", type: toast.TYPE.ERROR, autoClose: 5000 });
        }
    }
    const onWoundChange = (file) => {
        let { fileList: newFileList } = file
        if (newFileList.length === 0) {
            prospectForm.setFieldsValue({ wound_image: undefined })
            return setFileList([])
        }
        console.log('FILE: ', newFileList)
        setFileList(newFileList.map(file => file.response ? { uid: file.uid, status: file.status, url: file.response.data.url, thumbUrl: file.thumbUrl } : file))
        let response = newFileList[newFileList.length - 1]?.response
        prospectForm.setFieldsValue({ wound_image: response ? response.data.id : undefined })
    }
    const onProspectFinish = async (values) => {

        let payload = {
            "patient_id": `${patient_.patient_id}`,
            "referral_type": values.referral_type,
            "wounds": values.wound_location ? [
                {
                    "image_id": values.wound_image,
                    "wound_location": values.wound_location
                }
            ] : []
        }
        console.log(payload)
      await executeChunkFn(dispatch, importProspect, payload, setisExecuting, closeModal, async () => { })

    }

    const handleFacesheetOk = (values) => console.log('here', values)
    const handleFacesheetCancel = () => setFacesheetForm(false)
    const disabledFutureDates = (current) => {
        let customDate = dayjs()
        let tooOld = dayjs("1869-12-31")
        return (current && current > dayjs(customDate, dateFormat)) || (current && current < dayjs(tooOld, dateFormat))
    }
    const disabledPastDates = (current) => {
        let customDate = dayjs()
        return current && current < dayjs(customDate, 'YYYY-MM-DD')
    }
    const removeImage = (image, index) => {
        const arr = faceSheets.filter(i => i !== image.sheetId)
        setImages(faceImages.splice(index, 1))
        setFaceSheets(arr)
    }
    // location stuff
    const [viewport, setViewport] = useState({ latitude: 37.7577, longitude: -122.4376, zoom: 8 })
    const [locationData, setLocationData] = useState(null)
    const geocoderContainerRef = useRef()
    const mapRef = useRef()
    const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), [])

    const onResult = useCallback((result) => {
        // console.log(result)
        let center = result.result.center
        let context = result.result.context
        let state = context.find(item => item.id.includes('region'))
        let city = context.find(item => item.id.includes('place'))
        let zip_code = context.find(item => item.id.includes('postcode'))
        let data = {
            // city: city ? city.text : result.result.place_name, state: state ? state.text : null,
            // zip_code: zip_code ? zip_code.text : result.result.place_name,
            co_ordinates: `${center[0]},${center[1]}`
            // address_1: result.result.place_name
        }
        setLocationError(false)
        setLocationData(data)
        prospectForm.setFieldsValue({
            address_1: result.result.place_name,
            city: city ? city.text : null,
            state: state ? state.text : null,
            zip_code: zip_code ? zip_code.text : null
        });
    }, [])
    const dateChange = (value) => setDate(value)
    const getSsnField = () => {
        return dayjs().diff(dayjs(date), 'years') > 18 ? <Form.Item
            style={{ width: '100%' }}
            name="ssn"
            label="SSN"
            rules={[{ required: false, message: 'SSN is required for adults' }, { max: 11, message: 'SSN length exceeds limit' }/*, {pattern: /^[1-9]./, message: 'Invalid SSN'}*/]}>
            <NumberFormat autoComplete={'off'} customInput={Input} format="###-##-####" placeholder="###-##-####"/>
        </Form.Item> : <Form.Item
            style={{ width: '100%' }}
            name="ssn"
            label="SSN"
            rules={[{ max: 11, message: 'SSN length exceeds limit' }/*, {pattern: /^[1-9]./, message: 'Invalid SSN'}*/]}>
            <NumberFormat autoComplete={'off'} customInput={Input} format="###-##-####" placeholder="###-##-####"/>
        </Form.Item>
    }
    const onFacilitySelect = (value) => {
        let facility = facilities.find(item => item.name === value)
        setFacility(facility)
    }
    const onPreview = async (file) => {
        console.log(file)
        let src = file.url
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };
    const [type, set_type] = useState("wound care")
    const handleTypeChange = (e) => {
        set_type(e)
    }

    const debouncedChangeHandler = useCallback(
        _.debounce(function (value) {
            handlePatientsearch(value)
        }, 1000),
        []
    );
    const handlePatientsearch = async (value) => {
        if (value.length > 1) {
            const { data } = await apiRequest.post(`/pointclickcare/patient`, { name: value }).catch(e => console.log(e))
            // setOptions(data);
            if (data) {
                console.log(data)
                setpatientOptions(data);
            }
        }
    };
    const [patient_, setpatient_] = useState(null)
    const [patientOptions, setpatientOptions] = useState([])
    const onpatientSelect = async (value, option) => {
        console.log(value, option)
        let patient = patientOptions.find(item => item.patient_id === parseFloat(option.key))
        getCoverage(patient)
        setpatient_(patient)
    }
    const getCoverage = async (patient) => {
        console.log(patient)
        const { data } = await apiRequest.post(`/pointclickcare/patient-coverage`, {...patient }).catch(e => console.log(e))
        // setOptions(data);
        if (data) {
            console.log(data)
            setinsuranceInfo(data);
        }

    }
    const [insuranceInfo, setinsuranceInfo] = useState(null)
    return (<>
        {fileList.length > 0 && showImagePreview(preview, 'Wound image', dialogCloser(setPreview), fileList[0].url)}


        <Form
            form={prospectForm}
            name="new-import-prospect"
            id="new-import-prospect-form"
            labelCol={{
                span: 16
            }}
            layout="vertical"
            onFinish={onProspectFinish}
        >

            {/* <Form.Item
                label="Referral Type"
                name="referral_type"
                rules={[{ required: true, message: 'this field is required!' }]}
                wrapperCol={{ span: 24 }}>
                <Radio.Group onChange={handleTypeChange}>
                    <Radio value="wound care">Wound Care</Radio>
                    <Radio value="podiatry">Podiatry</Radio>
                    <Radio value="wound care and podiatry">Wound Care and Podiatry</Radio>
                </Radio.Group>
            </Form.Item> */}
            <Form.Item
          label="Add Referral"
                name="patient_id"

                rules={[{ required: true, message: 'this field is required!' }]}

                wrapperCol={{ span: 24 }}>
                <AutoComplete popupMatchSelectWidth={false}
                              showSearch
                              filterOption={false}
                              notFoundContent="Couldn't find any matches!"
                              style={{ width: "100%", marginBottom: "20px" }}
                              onSelect={onpatientSelect}
                              onSearch={(value) => debouncedChangeHandler(value)}
                              placeholder="search for patients">
                    {patientOptions.map(item => (<Option key={item.patient_id} value={`${item.first_name} ${item.last_name}`}>
                        <div style={{ textAlign: 'left' }}>
                            <Subtitle1 style={{ textAlign: 'left' }}>{`${item.first_name} ${item.last_name}`}</Subtitle1>
                            <Body1 color="grey"> {`${dayjs().diff(dayjs(item.dob), 'years')}y.o. ${dayjs(item.dob).format("MM/DD/YYYY")},${item.gender}, `}</Body1>
                        </div>

                    </Option>))}
                </AutoComplete>
            </Form.Item>
            {patient_ && <div style={{ width: "100%" }}>
                <Headline6 style={{ textAlign: 'left' }}>Primary Information</Headline6>
                {boldKeyCapitalizedValue('First Name', patient_.first_name)}
                {boldKeyCapitalizedValue('Last Name', patient_.last_name)}
                {boldKeyCapitalizedValue('Date of birth', `${dayjs(patient_.dob).format("MM/DD/YYYY")}`)}
                {boldKeyCapitalizedValue('Gender', patient_.gender)}
                <Divider/>
            </div>}
            {
                insuranceInfo &&
                <div style={{ width: "100%" }}>
                    <Headline6 style={{ textAlign: 'left' }}>Primary Information</Headline6>
                    {insuranceInfo?.payers?.map(item => {
                        <div>
                            {boldKeyCapitalizedValue('Player Name and Player Type', `${item.payerName} ${item.payerType}`)}
                            {boldKeyCapitalizedValue('Player Rank', item.payerRank)}
                        </div>
                    })}
                </div>
            }


            {prospectForm.getFieldValue('referral_type') !== 'podiatry' &&
                <>
                    <Form.Item
                        style={{ width: '100%' }}
                        name="wound_location"
                        rules={[{ required: false, message: 'Missing Wound location' }]}
                        label="Wound location">
                        <AutoComplete popupMatchSelectWidth={false}
                                      options={woundLocationOptions}
                                      style={{ width: '100%', borderRadius: "4px" }}
                                      onSearch={(value) => debouncedWLocationSearch(value)}
                                      autocomplete="chrome-off"
                                      placeholder="Search for wound location"/>
                    </Form.Item>
                    <Form.Item
                        style={{ width: '100%' }}
                        rules={[{ required: false, message: 'Missing Wound image' }]}
                        name="wound_image"
                        label="Wound image">
                        <Upload accept="image/*"
                                action={uploadUrl}
                                headers={{ 'Authorization': `Bearer ${token}` }}
                                listType="picture"
                                onPreview={file => setPreview(true)}
                                defaultFileList={[...fileList]}
                                onChange={onWoundChange}
                                className="upload-list-inline"
                                maxCount={1}>
                            <Button icon={<UploadOutlined/>}>Upload</Button>
                        </Upload>
                    </Form.Item>
                </>}
        </Form>
    </>)
}
const mapStateToProps = (state) => ({
    activeUser: state.auth?.activeUser, selectedFacility: state.facilities.selectedFacility, masterListRaw: state.masters.masterListRaw
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(NewProspectCard)
