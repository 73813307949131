import {useCallback, useState} from "react";
import _ from 'lodash';
import {apiRequest} from '../app/Apis';

export const usePatientSearch = ( deps) => {
  const [isRun, setIsRun] = useState(false);
  const [patientLoading, setPatientSearchLoading] = useState(false);
  const [patientOptions, setPatientOptions] = useState([])
  
  /* if (!isRun) {
     setIsRun(true);
   }*/
  
  const debouncedPatientSearch = useCallback(_.debounce(async function (value) {
    console.log({ value })
    if (!value || value === '') {
      setPatientOptions([])
      return
    }
    await setPatientSearchLoading(true)
    const { data } = await apiRequest.get(`/patients?search=${value}`)
    if (data) {
      let list = data.data.filter(item => item.status === "active" || item.status === "hospitalized").map(item => ({
        ...item,
        label: item.name || `${item?.first_name || ''} ${item?.last_name || ''}`,
        value: item.id
      }))
      setPatientOptions(list);
    }
    await setPatientSearchLoading(false)
  }, 1000), deps)
  
  return { patientOptions, setPatientOptions, debouncedPatientSearch, patientLoading }
};