import React, { useEffect, useState } from 'react';
import { Headline3, Headline4, Headline6, Indicator, Subtitle1 } from "../../../components";
import { Avatar, Button, Col, DatePicker, Divider, Image, Row } from "antd";
import { status_colors } from "../../../configure/constants";
import { Cell, Pie, PieChart } from "recharts";
import { data } from "./dummyData";
import dayjs from "dayjs";
import { connect, useDispatch } from "react-redux";
import { apiRequest } from "../../../app/Apis";
import { emptyFun, getAppointmentStatus, getAppointmentIndicatorColor } from "../../../common/helpers";
import { fetchConsultantshedule } from "../../../app/Reducers";
import { useSubscription } from "../../../hooks/MqttHooks/useSubscription";
import querystring from "querystring";
import { errMsg, toastUp } from "../../../app/Reducers/reducerUtils";
import * as Sentry from "@sentry/react";
import ConsultantAppointmentCard from './ConsultantAppointmentCard';
import {useLocation, useNavigate} from 'react-router-dom';

const TodayActivity = ({ consultant, appointments }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate();
  const COLORS = ['#0088FE', '#00fea9', '#EF8100;', '#FFA500'];
  const [mileage, setmileage] = useState(0.0)
  const [pieData, setpieData] = useState([])
  const { message: mileageMessage } = useSubscription(`view_mileage_portal/${consultant.id}`, { qos: 2, nl: true });
  useEffect(() => {
    if (!!mileageMessage) {
      console.log('MQTT MESSAGE:  ', mileageMessage)
      fetchMilage().catch(e => console.log(e))
    }
    return emptyFun
  }, [mileageMessage])
  const { message: appointmentsMessage } = useSubscription(`view_consultant_appointments/${consultant.id}`, { qos: 2, nl: true });
  useEffect(() => {
    if (!!appointmentsMessage) {
      console.log('MQTT MESSAGE:  ', appointmentsMessage)
      dispatch(fetchConsultantshedule(consultant.id))
    }
    return emptyFun
  }, [appointmentsMessage])
  useEffect(() => {
    fetchMilage().catch(e => console.log(e))
    return emptyFun
  }, [consultant])
  const fetchMilage = async () => {
    try {
      // const response = await apiRequest.get(`/consultants/${consultant.id}/mileages`)
      let params = { 'date_range[start_at]': dayjs().subtract(2, 'd').format("YYYY-MM-DD"), 'date_range[end_at]': dayjs().add(1, 'd').format("YYYY-MM-DD"), consultant_id: consultant.id }
      const response = await apiRequest.get(`/consultant-trip-summaries?${querystring.stringify(params)}`)
      if (response && response.data) {
        let total = response.data.data.reduce((previousValue, currentValue) => previousValue + Number(currentValue.mileage), 0)
        // console.log({total})
        // console.log({total1: response.data.data})
        setmileage(total)
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e)
      toastUp(errMsg(e, true, 'mileage'), false)
    }
  }

  const fetchMilageRange = async (e) => {
    try {
      console.log(e)
      let start_date = dayjs(e[0]).format("YYYY-MM-DD")
      let end_date = dayjs(e[1]).format("YYYY-MM-DD")
      // const response = await apiRequest.get(`/consultants/${consultant.id}/mileages?startDate=${start_date}&endDate=${end_date}`)
      let params = { 'date_range[start_at]': start_date, 'date_range[end_at]': end_date, consultant_id: consultant.id }
      const response = await apiRequest.get(`/consultant-trip-summaries?${querystring.stringify(params)}`)
      //console.log({ response })
      if (response && response.data) {
        let total = response.data.data.reduce((previousValue, currentValue) => previousValue + Number(currentValue.mileage), 0)
        // console.log({total})
        // console.log({total2: response.data.data})
        setmileage(total)
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e)
      toastUp(errMsg(e, true, 'mileage'), false)
    }
  }
  const availableStatuses = ['scheduled', 'rescheduled', 'cancelled_by_patient', 'cancelled_by_wcc', 'completed', 'in_progress', 'missed_by_wcc', 'missed_by_patient', 'visited']

  const widths = availableStatuses.reduce((acc, status) => {
    acc[status] = (appointments.filter(item => item.status === status).length / appointments.length) * 100;
    return acc;
  }, {});

  const onPieEnter = () => {
    console.log("onPieEnter", (appointments.filter(item => item.status === "scheduled").length / appointments.length) * 100)
  }
 
  return (
    <div className="card-box">
   
      <Row gutter={24} style={{ width: "100%" }}>
        <Col span={24}>
          <div style={{ height: "100%", padding: 0 }}>
            <div className="flex flex-row justify-between">
              <p className={'font-semibold text-sm'} > Todays Appointments: </p>
              <Subtitle1 style={{ textAlign: 'right', marginBottom: '10px' }}>{appointments.filter(item => item.status === "completed").length} of {appointments.length}</Subtitle1>
            </div>
          

            {appointments.length ? (
              <div className="flex flex-row w-full h-[40px]">
                {availableStatuses.map(status => (
                  <div
                    key={status}
                    className="h-full"
                    style={{
                      width: `${widths[status]}%`,
                      backgroundColor: getAppointmentIndicatorColor(status),
                    }}
                  />
                ))}
              </div>
            ) : null}

            <div className="flex-boy-row-space" style={{ width: "100%", marginTop: 0, paddingTop: 0, justifyContent: 'start' }}>
              {/* a div with children who's widths are ratios of total appointments anch background color relative to indicator color */}
              
             

              <div style={{ width: "100%" }}>
                <div className="flex flex-row" style={{ paddingLeft: 0, width: "100%", flexWrap: "wrap" }}>
                  
                  {availableStatuses.map(item => {
                    return (
                      <div className="p-2">
                        {getAppointmentStatus(item)}
                      </div>
                     )
                  })}
                </div>
              </div>
              {/* <PieChart width={220} height={200} onMouseEnter={onPieEnter}>
                <Pie data={pieData} cx={120} cy={100} innerRadius={60} outerRadius={80} fill="#EF8100;" paddingAngle={5} dataKey="value">
                  {data.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />))}
                </Pie>
              </PieChart> */}
            </div>
          </div>
        </Col>
      
        <Col span={24} style={{ marginTop: "20px" }}>
          {appointments.map(item => <ConsultantAppointmentCard appointment={item} />)}
        </Col>
        {/*{!!consultant && <Col span={24}>
          <Divider orientation="left"> <Typography variant="subtitle1" fontWeight={600}>Trip Summary : </Typography></Divider>
          <ConsultantMileages consultant={consultant}/>
        </Col>}*/}
      </Row>
    </div >
  );
};

const mapStateToProps = (state) => ({  consultant: state.consultants.selectedConsultant, appointments: state.consultants.consultantSchedule })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(TodayActivity)
