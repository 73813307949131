import React, {useEffect, useState} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import dayjs from 'dayjs'
import {Modal, Radio, Table, Typography, Button, Grid} from 'antd'
import {
  containedButton,
  dialogCloser,
  emptyFun,
  emptyTable,
  formSubmitButton,
  getPatientStatus,
  GetRawListOptions,
  getSkels,
  horStack,
  makeAutoCompleteOptions,
  modalClose,
  onTableChange,
  outlinedButton,
  responsiveTbl,
  stateFilter
} from '../../../common/helpers'
import {fetchConsultantAssignments, resetConsultantOrdersPagination, resetPatientModuleState, selectPatient, updateConsultantsAssigmentFilters} from '../../../app/Reducers'
import NewAdmission from './NewAdmission'
import {usePermissionCheck} from '../../../hooks/usePermissionCheck'
import TableFilters from '../../../components/InputComponents/TableFilters'
import {useLocation, useNavigate} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';
const { useBreakpoint } = Grid;
const ConsultantAssignments = ({ appConf, consultantsAssigmentPagination, consultant, assignments, navs }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate();
  const windowState = useBreakpoint();
  // isOpen
  const [isOpen, setOpen] = useState(false)
  // isExecuting
  const [isExecuting, setisExecuting] = useState(false)
  
  const can_view_admission = usePermissionCheck('view_admission', false, [])
  const can_create_admission = usePermissionCheck('assign_consultant', false, [])
  const can_delete_admission = usePermissionCheck('delete_admission', false, [])
  const [isVisible, setIsVisible] = useState(false);
  const [patientStatus, setPatientStatus] = useState('active')
  const onCell = (record, rowIndex) => {
    return {
      onClick: (event) => {
      },
      onDoubleClick: (event) => {
      },
      onContextMenu: (event) => {
      },
      onMouseEnter: (event) => {
      },
      onMouseLeave: (event) => {
      }
    };
  }
  const columns = [{
    title: 'Patient', dataIndex: ['patient', 'name'], key: 'patientName',
    /* sorter: (a, b) => a.name - b.name, fltr: {filterable: true, type: 'text'},*/
    render: (name) => (<Button size={"middle"} type={"text"}
                                  style={{
                                    textTransform: 'none',
                                    margin: 0,
                                    width: '100%',
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    backgroundColor: 'transparent',
                                    color: '#038A96',
                                    textAlign: 'left'
                                  }}>{name}</Button>
    ), onCell: (record, rowIndex) => {
      return {
        onClick: event => {
          (async () => {
            let patient = record.patient
            if (patient.id !== navs.patientDetailsOwner) {
              await dispatch(resetPatientModuleState())
            }
            await dispatch(selectPatient({ record: patient }))
            navigate(`/patients/${record.patient.id}`)
          })().catch(e => console.log(e))
        } // click cell
      };
    }
    
  }, {
    title: 'Office', dataIndex: ['patient', 'facility'], key: 'facility',
    fltr: { filterable: true, type: 'text' }, onCell
  },
    {
      title: 'Contact Phone', dataIndex: ['patient', 'phone_no'], key: 'phone_no', fltr: { filterable: true, type: 'phone' },
      render: (phone_no) => <Typography variant={"body2"} style={{ color: '#038A96' }}>{phone_no}</Typography>,
      visible: true, onCell
    },
    {
      title: 'DOB', dataIndex: ['patient', 'birth_date'], key: 'birth_date', fltr: { filterable: false, type: 'date' },
      render: (dob) => (<>
        {dayjs(dob).format('MM/DD/YYYY')}
      </>), visible: true, onCell
    },
    
    {
      title: 'Status', dataIndex: ['patient', 'status'], key: 'status',
      fltr: {
        filterable: true, type: 'autocomplete',
        options: makeAutoCompleteOptions(GetRawListOptions('patient_status'))?.filter(item => item.actualValue !== 'Not Approved')
      },
      visible: true, render: (status) => getPatientStatus(status), onCell
    }
  
  ]
  
  useEffect(() => {
    loadConsultantsAssigment().catch(console.log)
    return emptyFun
  }, [])
  
  
  const handleFilterAction = async (action, values) => {
    await dispatch(resetConsultantOrdersPagination())
    if (action === 'filter') {
      let pl = { filterData: { consultant: consultant.id, ...values, patientStatus }, pagination: consultantsAssigmentPagination }
      await dispatch(updateConsultantsAssigmentFilters([false, pl, true]))
      await dispatch(fetchConsultantAssignments(pl))
      await dispatch(updateConsultantsAssigmentFilters([true, pl, false]))
    }
    if (action === 'reset') {
      let pl = { filterData: { consultant: consultant.id, patientStatus }, pagination: consultantsAssigmentPagination }
      await dispatch(updateConsultantsAssigmentFilters([appConf.consultantsAssigmentFilters[0], pl, true]))
      await dispatch(fetchConsultantAssignments(pl))
      await dispatch(updateConsultantsAssigmentFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  const loadConsultantsAssigment = async (isMQ) => {
    //   add consultant_id as a filter
    let pl = appConf.consultantsAssigmentFilters[0] ? appConf.consultantsAssigmentFilters[1] : {
      filterData: { ...appConf.consultantsAssigmentFilters[1]?.filterData,
        consultant: consultant.id,
        patientStatus: patientStatus
      }, pagination: consultantsAssigmentPagination
    }
    await dispatch(updateConsultantsAssigmentFilters([false, pl, true]))
    await dispatch(fetchConsultantAssignments(pl))
    await dispatch(updateConsultantsAssigmentFilters([false, pl, false]))
  }
  
  
  useEffect(() => {
      loadConsultantsAssigment().catch(console.log)
      return emptyFun
    }
    , [patientStatus])
  return (
    
    <div className="w-full">
      <Modal
        title={"New Admission"}
        open={isOpen}
        onOk={dialogCloser(setOpen)}
        onCancel={dialogCloser(setOpen)}
        closeIcon={modalClose(dialogCloser(setOpen))}
        maskClosable={false}
        destroyOnClose={true}
        width={1200}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setOpen), 'Cancel', isExecuting),
            formSubmitButton('admissionForm', 'Save', isExecuting, 'Saving')])}
        </div>]}>
        
        <NewAdmission loadConsultantsAssigment={loadConsultantsAssigment} setisExecuting={setisExecuting} closeModal={dialogCloser(setOpen)}/>
      
      </Modal>
      <Table
        title={() => (
          // antd radio button group with 2 options (active, in-active)
          <div className="text-left flex flex-row justify-between">
            <div className="flex ">
              <Radio.Group
                defaultValue={patientStatus}
                buttonStyle="solid"
                onChange={async (e) => {
                  await setPatientStatus(e.target.value)
                  
                }}
              >
                {/* number of assignees who's status is active */}
                
                <Radio.Button value="active">Active</Radio.Button>
                <Radio.Button value="in-active">Inactive</Radio.Button>
              
              </Radio.Group>
            </div>
            <div className="flex flex-1">
              <TableFilters datasource={[...columns, stateFilter /*procedureFilter*/]}
                            searchOnly={true}
                            setIsVisible={setIsVisible}
                            isVisible={isVisible}
                            windowState={windowState}
                            handleFilterAction={handleFilterAction}
                            filters={appConf.consultantsAssigmentFilters[1]}
                            showClear={appConf.consultantsAssigmentFilters[0]}
                            loading={appConf.consultantsAssigmentFilters[2]}
              
              />
            </div>
            
            {/* add new admission button */}
            <div className="flex flex-1 justify-end">
              
              {
                can_create_admission ? containedButton(() => setOpen(true), 'Assign Patient', false, null, null, { icon: <PlusOutlined /> }) : null
              }
            </div>
          </div>
        
        )}
        loading={appConf.consultantsAssigmentFilters[2]}
        {...responsiveTbl(windowState)}
        locale={{ emptyText: emptyTable('admissions') }}
        onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchConsultantAssignments, appConf.consultantsAssigmentFilters, updateConsultantsAssigmentFilters, {consultant: consultant.id,patientStatus}, consultantsAssigmentPagination)}
        {...getSkels((appConf.consultantsAssigmentFilters[2]), columns, assignments)} pagination={consultantsAssigmentPagination}/>
    
    </div>
  
  
  )
}

const mapStateToProps = (state) => ({
  appConf: state.appConf,
  assignments: state.consultants.consultantAssignments,
  consultantsAssigmentPagination: state.consultants.consultantsAssigmentPagination,

  consultant: state.consultants.selectedConsultant,
  navs: state.navs
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantAssignments)