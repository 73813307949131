/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Subtitle1} from '../../../components'

import {Form, Grid, Input, Modal, Table, Tabs, Tag} from 'antd';
import SheduleAppointment from './ScheduleAppointment';
import dayjs from 'dayjs'
import {
  deleteAppointment,
  fetchAppointments,
  resetAppointmentModuleState,
  resetAppointmentsPagination,
  selectAppointment,
  setAppointmentsLoaded,
  setSelectAppointmentID,
  updateAppointmentFilters
} from '../../../app/Reducers';
import {
  containedButton,
  dialogCloser,
  emptyFun,
  emptyTable,
  formSubmitButton,
  getAppointmentStatus,
  getSkels,
  horStack,
  kebabMenus,
  makeSelectOptions,
  modalClose,
  onTableChange,
  outlinedButton,
  responsiveTbl,
  stateFilter
} from "../../../common/helpers";
import KebabMenu from "../../../components/KebabMenu";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import TableFilters from "../../../components/InputComponents/TableFilters";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {EditOutlined, PlusOutlined} from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';
import _ from 'lodash';
import {useFacilitySearch} from '../../../hooks/useFacilitySearch';
import {useProcedureSearch} from '../../../hooks/useProcedureSearch';
const { useBreakpoint } = Grid;
const AppointmentsList = ({  appointments, loading, appConf, navs, appointmentsPagination, activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState = useBreakpoint();
  const [activeTab, setActiveTab] = useState('patients');
  const [isOpen, setOpen] = useState(false)
  const dispatch = useDispatch()
  const [isVisible, setIsVisible] = useState(false);
  const [isExecuting, setisExecuting] = useState(false)
  const [editingForm, setEditingForm] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const { message } = useSubscription(`view_appointment_listing`, { qos: 2, nl: true });
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const can_update_appointment = usePermissionCheck('update_appointment', false, [])
  const can_view_appointment = usePermissionCheck('view_appointment', false, [])
  const can_create_appointment = usePermissionCheck('create_appointment', false, [])
  const can_delete_appointment = usePermissionCheck('delete_appointment', false, [])
  const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  let defaultList = !!activeUser?.facilityProfile ? [{ ...activeUser?.facilityProfile, label: activeUser?.facilityProfile.name, value: activeUser?.facilityProfile.id }] : []
  const [facilityOptions, debouncedFacilitiesSearch,facilitySearchLoading] = useFacilitySearch([], 'id', defaultList)
  const [procedureOptions, debouncedProcedureSearch, procedureSearchLoading] = useProcedureSearch([], 'name')
  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message)
      loadAppointments(true).catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  let onCell = (record, rowIndex) => ({
    onClick: event => {
      (async () => {
        if (record.id !== navs.selectedAppointmentID) {
          await dispatch(resetAppointmentModuleState())
        }
        await dispatch(selectAppointment({ navigate, record: _.omit(record, 'wounds') }))
        await dispatch(setSelectAppointmentID(record.id))
        if (can_view_appointment) {
          navigate(`/appointments/${record.id}`)
        }
      })().catch(e => console.log(e))
    }
  })
  const columns = [{
    title: 'Patient', dataIndex: ['patient', 'name'], key: 'patientName',
    /* sorter: (a, b) => a.name - b.name, fltr: {filterable: true, type: 'text'},*/ render: (name) => (
      <div className="flex-boy-row-space" style={{ justifyContent: 'normal' }}>
        <Subtitle1 style={{ textAlign: "left", marginLeft: '10px' }}>{name}</Subtitle1>
      </div>), onCell
  }, {
    title: 'Date of appointment', dataIndex: 'start_at', key: 'date', fltr: { filterable: true, type: 'date' },
    sorter: { multiple: 3, compare: (a, b) => dayjs(a.start_at).unix() - dayjs(b.start_at).unix() },
    render: (start_at, record) => (
      <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(start_at).format("MM/DD/YYYY")}</Subtitle1>), onCell
  }, {
    title: 'Time Slot', dataIndex: 'start_at', key: 'time_slot', render: (start_at, record) => (<Subtitle1
      style={{ textAlign: 'left' }}>{dayjs(start_at).format("h:mm a")} -{dayjs(record.end_at).format("h:mm a")}</Subtitle1>),
    onCell
  }, {
    title: 'Office', dataIndex: ['patient', 'medical_facility'], key: 'facility',
    fltr: { filterable: true, type: 'select', options: facilityOptions,
      props: {
        disabled: !!activeUser?.facilityProfile,loading:facilitySearchLoading,
        onSearch: (value) => debouncedFacilitiesSearch(value), showSearch: true, filterOption: false
      },
      fiprops:{
        initialValue:activeUser?.facilityProfile ? activeUser?.facilityProfile?.id : undefined
      } }, onCell
  }, {
    title: 'Visit Type', dataIndex: 'appointment_visit_type', key: 'visitType', fltr: {
      filterable: true, type: 'select', options: makeSelectOptions(['established visit', 'new visit'])
    }, onCell
    },
    {
      title: 'Visit Approval status', dataIndex: 'visit_approval_status', key: 'visitStatus', fltr: {
        filterable: true, type: 'select', options: makeSelectOptions([
          "Pending",
          "Visit Approved",
          "Visit Approved/Cash",
          "Visit Not Approved",
          "Probono",
          "Jurisdiction Discrepancy",
          "Deceased"
        ])
      }, onCell
    },
    {
      title: 'Provider', dataIndex: ['consultant', 'name'], key: 'wcc', fltr: { filterable: true, type: 'text' }, onCell
    }, {
      title: 'Appointment Status', dataIndex: 'status', key: 'status', fltr: {
        filterable: true, type: 'select',
        options: makeSelectOptions(['scheduled', 'rescheduled', 'cancelled_by_patient', 'cancelled_by_wcc', 'confirmed', 'completed', 'in_progress', 'missed_by_wcc', 'missed_by_patient', 'visited'])
      }, render: (status) => getAppointmentStatus(status?.toLowerCase()), onCell
    },
    // {
    //   title: <FontAwesomeIcon color="grey" icon={['fas', 'table']} />,
    //   key: 'telehealth_appointment',
    //   width: 70,
    //   render: (record) => <>
    //     {record.telehealth_appointment && <Tag color="gainsboro" style={{color:'black'}}>Telehealth</Tag>}
    //   </>
    // },
    {
      fltr: { filterable: false }, render: (record) => {
        const actionMenus = kebabMenus(( record.status !== 'completed' && can_update_appointment), can_view_audit_logs, can_delete_appointment)
        return <KebabMenu menus={actionMenus}
                          recordItem={record}
                          handleMenuClick={kebabMenuClick} resource={'appointment'} handleOk={() => handleOk(record.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
                          showConfirmDialog={selected === record.id}/>;
      }
    },
    ...(activeUser?.is_qa_user?[{ width:15,
      visible: true,
      title: <FontAwesomeIcon color="grey" icon={['fas', 'table']}/>,
      key: 'operation',
      render: (record) => <Tag className={`${(record.is_qa && activeUser?.is_qa_user)?'visible':'invisible'}`} icon={<EditOutlined/>} color="#55acee">QA</Tag>
    }]:[])
  ]
  let procedureFilter= {
    title: 'Procedure Type', dataIndex: 'procedure', key: 'procedure', fltr: {
      filterable: true, type: 'select', options: procedureOptions,
      props: {
        loading: procedureSearchLoading,
        onSearch: (value) => debouncedProcedureSearch(value), showSearch: true, filterOption: false
      }
    }
  }
  const [selected, setSelected] = React.useState(null);
  const handleCancel = () => setSelected(0);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const showPopconfirm = (id) => setSelected(id);
  const actionMenus = kebabMenus(can_update_appointment, can_view_audit_logs, can_delete_appointment)
  
  async function kebabMenuClick(e, record) {
    await setAppointment(record)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true)
        await setOpen(true)
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
    }
  }
  
  
  async function handleFilterAction(action, values) {
    // console.log('handleFilterAction: ',values)
    await dispatch(resetAppointmentsPagination())
    if (action === 'filter') {
      console.log('FILTERS:  ', JSON.stringify({ ...values, toLoad: 'appointments', endpoint: '/appointments' }));
      let pl = { filterData: values, pagination: appointmentsPagination }
      await dispatch(updateAppointmentFilters([false, pl, true]))
      await dispatch(fetchAppointments(pl))
      await dispatch(updateAppointmentFilters([true, pl, false]))
    }
    if (action === 'reset'/* && appConf.appointmentFilters[0]*/) {
      await dispatch(updateAppointmentFilters([appConf.appointmentFilters[0], undefined, true]))
      await dispatch(fetchAppointments())
      await dispatch(updateAppointmentFilters([false, undefined, false]))
    }
    // const response = await apiRequest.get(`/appointments`,{params: {...values,toLoad:'appointments'}})
    setIsVisible(false)
  }
  
  const loadAppointments = async (isMQ) => {
    /*if (!!appointments[0] && !isMQ) {
      return
    }*/
    await dispatch(updateAppointmentFilters([appConf.appointmentFilters[0], appConf.appointmentFilters[1], !isMQ]))
    await dispatch(fetchAppointments(appConf.appointmentFilters[0] ? appConf.appointmentFilters[1] : undefined))
    // await dispatch(fetchProcedures())
    // await dispatch(fetchLabRegions())
    // await dispatch(loadWoundLocations())
    await dispatch(setAppointmentsLoaded(true))
    await dispatch(updateAppointmentFilters([appConf.appointmentFilters[0], appConf.appointmentFilters[1], false]))
  }
  
  
  useEffect(() => {
    loadAppointments(false).catch(e => console.log(e))
    return emptyFun
  }, [])
  
  const handleOk = async (id) => {
    setappointmentTodeleteID(id)
    setreasonModal(true)
    setSelected(0)
  };
  
  
  const [appointmentTodeleteID, setappointmentTodeleteID] = useState(null)
  const [reasonStatus, setreasonStatus] = useState(null)
  const [reasonModal, setreasonModal] = useState(false)
  const reasonFinish = async (values) => {
    await setConfirmLoading(true);
    await dispatch(deleteAppointment({ appointment_id: appointmentTodeleteID, ...values }));
    await setSelected(0);
    await setConfirmLoading(false);
    setreasonModal(false)
  }
  
  const [selectedTab, setselectedTab] = useState("1")
  const callback = (key) => {
    setselectedTab(key)
  }
  const Tabs_ = () => {
    return (
      <Tabs defaultActiveKey={selectedTab} onChange={callback}>
        <Tabs.TabPane tab={<Subtitle1>{editingForm ? 'Edit appointment' : "New Appointment"}</Subtitle1>} key="1">
        
        </Tabs.TabPane>
        filter fix
      
      </Tabs>
    )
  }
  return (
    (<div className="main-page">
      {(!!appointment && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false}
                                                             resourceDescription={`${appointment.patient.name}'s appointment`} resourceType={'Appointment'}
                                                             defaultValues={{ auditableId: appointment?.id, auditable: 'appointment', format: 'json' }}/>}
      <Modal
        title={Tabs_()}
        open={isOpen}
        onOk={dialogCloser(setOpen)}
        onCancel={dialogCloser(setOpen)}
        closeIcon={modalClose(dialogCloser(setOpen))}
        maskClosable={false}
        destroyOnClose={true}
        width={650}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setOpen), 'Cancel', isExecuting),
            formSubmitButton('appointmentForm', editingForm ? 'Update' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
        </div>]}>
        
        <SheduleAppointment appointment={editingForm ? appointment : null} editingForm={editingForm} setisExecuting={setisExecuting} closeModal={dialogCloser(setOpen)}/>
      
      </Modal>
      <Modal
        title={`Please add a Reason`}
        open={reasonModal}
        destroyOnClose={true}
        
        onOk={() => setreasonModal(false)}
        onCancel={() => setreasonModal(false)}
        closeIcon={modalClose(() => setreasonModal(false))}
        maskClosable={false}
        width={1200}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(() => {
            setreasonModal(false)
            setSelected(0)
          }, 'Cancel', isExecuting),
            formSubmitButton('reasonForm', 'Add Reason', isExecuting, 'Updating')])}
        </div>]}
      >
        <Form
          name="reasonForm"
          onFinish={reasonFinish}
          layout="vertical"
        >
          
          <Form.Item
            style={{ width: '100%' }}
            label="Delete Reason"
            name="delete_reason"
            rules={[{ required: true, message: 'this field is required!' }]}
            wrapperCol={{ span: 24 }}
          >
            <Input autoComplete={'off'}/>
          </Form.Item>
        </Form>
      
      </Modal>
      <div className="w-full">
        
        
        <Table
          title={() => (
            <TableFilters datasource={[...columns, stateFilter, procedureFilter]}
                          setIsVisible={setIsVisible} isVisible={isVisible} windowState={windowState}
                          handleFilterAction={handleFilterAction} filters={appConf.appointmentFilters[1]}
                          showClear={appConf.appointmentFilters[0]} loading={appConf.appointmentFilters[2]}
                          actionButton={can_create_appointment ? containedButton(() => {
                            setEditingForm(false)
                            setOpen(true);
                          }, 'Add New', false, null, null, { icon: <PlusOutlined /> }) : null}
            />
          )}
          loading={appConf.appointmentFilters[2]} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('appointments') }}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchAppointments, appConf.appointmentFilters, updateAppointmentFilters, null, appointmentsPagination)}
          {...getSkels((appConf.appointmentFilters[2]), columns, appointments)} pagination={appointmentsPagination}/>
      </div>
    </div>)
  );
}
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser, appointments: state.appointments.appointments,
  loading: state.appointments.loading, appConf: state.appConf, navs: state.navs,
  appointmentsPagination: state.appointments.appointmentsPagination
  
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsList)
