import clsx from 'clsx';
import { useCallback } from 'react';
import { useChatContext } from 'stream-chat-react';
import { DirectMessagingChannelPreview } from './DirectMessagingChannelPreview';
import { TeamChannelPreview } from './TeamChannelPreview';
import { useWorkspaceController } from '../../context/WorkspaceController';
export const ChannelPreview = ({ channel, type,unread }) => {
    const { channel: activeChannel, setActiveChannel } = useChatContext();
    const { displayWorkspace } = useWorkspaceController();
    const handleClick = useCallback(() => {
        displayWorkspace('Chat');
        if (setActiveChannel) {
            setActiveChannel(channel);
        }
    }, [channel, displayWorkspace, setActiveChannel]);
    return (<button className={clsx('channel-preview', { selected: channel?.id === activeChannel?.id })} onClick={handleClick}>
      {type === 'team'
            ? <TeamChannelPreview unread={unread} name={channel?.data?.name || channel?.data?.id || 'random'}/>
            : <DirectMessagingChannelPreview unread={unread} channel={channel}/>}
    </button>);
};
