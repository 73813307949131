import React, {useCallback, useEffect, useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {AutoComplete, Button, Col, DatePicker, Divider, Form, Image as ImageTag, Input, Modal, Row, Select, Upload} from 'antd'
import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import {Body1, Headline6, Subtitle1} from '../../../components/general';
import {apiRequest} from '../../../app/Apis';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {updateProspect} from '../../../app/Reducers'
import MapGL from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import {mapboxToken} from '../../../configure/constants'
import ProspectInsuranceForm from './ProspectInsuranceForm';
import NumberFormat from "react-number-format";
import {
  appLog,
  cleanObject,
  dialogCloser,
  emptyFun,
  executeChunkFn,
  formSubmitButton,
  getInputField,
  GetNumericalListOptions,
  GetOptions,
  getPhoneParams,
  GetRawListOptions,
  horStack,
  inputRow,
  kebabMenus,
  keyValItemForReferral,
  keyValItemGreyForReferral,
  makeAutoCompleteOptions,
  makeSelectOptions,
  modalClose,
  outlinedButton,
  showCurrentAddress,
  showImagePreview
} from "../../../common/helpers";
import {fetchActiveFacilities, findTimeZone, handleActiveFacilitySearch} from "../../../app/Apis/commonApi";
import {errMsg, getToastId, updateToast} from "../../../app/Reducers/reducerUtils";
import * as Sentry from "@sentry/react";
import WoundUpload from './WoundUpload';
import ProspectDocumentsView from './prospectDocuments';
import InsuranceCard from "./InsuranceCard";
import GetCookie from '../../../hooks/GetCookie';
import KebabMenu from '../../../components/KebabMenu';
import AppPhoneInput from '../../../components/InputComponents/AppPhoneInput';

const EditProspectCard = ({ closeModal, prospect, setUpdatingProspect, masterListRaw, loadPatients, activeUser }) => {
  const facesheet_types = ["SNF", "Hospice care", "Palliative care", "Other"]
  const { Option } = Select;
  const [faceForm] = Form.useForm();
  const [documentsForm] = Form.useForm()
  const [prospectForm] = Form.useForm();
  const dateFormat = 'MM/DD/YYYY';
  const [facesheetForm, setFacesheetForm] = useState(false);
  const [facilities, setFacilities] = useState(prospect.medical_facility ? [prospect.medical_facility] : []);
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const [isExecuting, setisExecuting] = useState(false)
  const [preview, setPreview] = useState(false)
  const [phoneValue, setPhoneValue] = useState(!!prospect ? { ...getPhoneParams(prospect.phone_no, 'phone_no'), ...getPhoneParams(prospect?.power_of_attorney_contact_number, "power_of_attorney_contact_number") } : undefined);
  const [facility, setFacility] = useState(prospect.medical_facility ? prospect.medical_facility : undefined)
  const [fileList, setFileList] = useState(prospect.wounds && prospect.wounds[0]?.image ? [{ ...prospect.wounds[0].image, status: 'done' }] : [])
  const [diabetic, setDiabetic] = useState(prospect.diabetic ? prospect.diabetic?.toLowerCase() : null)
  const [masterLocations, setMasterLocations] = useState(masterListRaw[0] ? masterListRaw.find(item => item.system_name === 'wound_location')?.options : [])
  const [woundLocations, setwoundLocations] = useState((!!masterLocations && masterLocations[0]) ? makeAutoCompleteOptions(...[masterListRaw.find(item => item.system_name === 'wound_location')?.options]) : [])
  const [date, setDate] = useState(null);
  const dispatch = useDispatch();
  const uploadUrl = process.env.REACT_APP_ENDPOINT + '/files'
  const token = GetCookie('accessToken')
  const [woundForm, setWoundForm] = useState(false)
  const [allWounds, setAllWounds] = useState([])
  const [insuranceList, setInsuranceList] = useState(prospect.insurance ? prospect.insurance : [])
  const [noOfWounds, setNoOfWounds] = useState(prospect.number_of_Wounds ? prospect.number_of_Wounds : 1)
  const [insuranceForm, setInsuranceForm] = useState(false)
  const [addingInsurance, setAddingInsurance] = useState(false)
  const [addingWound, setAddingWound] = useState(false)
  const [insuranceDocuments, setInsuranceDocuments] = useState([])
  const [uploadedInsuranceDocuments, setUploadedInsuranceDocuments] = useState([])
  const [insuranceDocumentForm, setInsuranceDocumentForm] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [editInsurance, setEditInsurance] = useState(false)
  const [initialInsuranceData, setInitialInsuranceData] = useState(undefined)
  const [faceImages, setFaceImages] = useState(!!prospect?.face_sheets ? prospect?.face_sheets.map(item => ({ url: item.image.url, name: item.image.name, id: item.id })) : []);
  const [selected, setSelected] = React.useState(null)
  const [editWound, setEditWound] = useState(false)
  const [selectedWound, setSelectedWound] = useState(null)
  const [selectedWoundId, setSelectedWoundId] = useState(null)
  const [consent, setConsent] = useState(prospect.consent ? prospect.consent : null)
  const initialValues = {
    ...prospect,
    ...(prospect.birth_date && { birth_date: dayjs(prospect.birth_date) }),
    ...({ billable_from: !!prospect.billable_from ? dayjs(prospect.billable_from) : undefined }),
    ...(prospect.expires_at && { expires_at: dayjs(prospect.expires_at) }),
    ...(prospect.request_date && { request_date: dayjs(prospect.request_date) }),
    ...(prospect.first && { first: prospect.first }),
    ...(prospect.last && { last: prospect.last }),
    ...(prospect.number_of_Wounds && { number_of_Wounds: prospect.number_of_Wounds }),
    ...(!!prospect?.phone_no && getPhoneParams(prospect.phone_no, 'phone_no')),
    ...(!!prospect?.power_of_attorney_contact_number && getPhoneParams(prospect.power_of_attorney_contact_number, "power_of_attorney_contact_number")),
    ...(prospect.hospitalize && { hospitalize: prospect.hospitalize }),
    ...(!!prospect?.case_manager_phone && getPhoneParams(prospect.case_manager_phone, "case_manager_phone")),
    ...(!!prospect?.fax && { fax: prospect.fax }),
    ...(!!prospect?.type_of_kit && { type_of_kit: prospect.type_of_kit })
  }
  
  useEffect(() => {
    appLog({ faceImages })
    return emptyFun
  }, [faceImages])
  
  useEffect(() => {
    fetchActiveFacilities(setFacilities)
    return emptyFun
  }, [])
  
  useEffect(() => {
    console.log("Prospect Wounds:", prospect.wounds)
    setUploadedInsuranceDocuments(prospect?.documents ? [...prospect?.documents] : [])
    let available_wounds = prospect?.wounds.map((item, index) => ({
      original_date_of_wound: item.original_date_of_wound,
      wound_duration: item.wound_duration,
      wound_location: item.wound_location,
      wound_side: item.wound_side,
      wound_position: item.wound_position,
      fibrous_tissue: item.fibrous_tissue,
      granulation_tissue: item.granulation_tissue,
      necrotic_tissue: item.necrotic_tissue,
      volume: item.volume,
      depth: item.depth,
      image_id: item?.image?.id,
      image: item.image,
      wound_image: item?.image?.url,
      width: item.width,
      length: item.length,
      area: item.area,
      //Used to read wound to update; where wound did not have image
      ...(!item?.image?.id && { index })
    }))
    console.log("New Available Wounds:::", available_wounds)
    setAllWounds(available_wounds)
    if (available_wounds.length > 0) {
      prospectForm.setFieldsValue({ number_of_Wounds: available_wounds.length })
    }
    if (!!prospect?.city) {
      setLocationData({ city: prospect.city, state: prospect.state, zip_code: prospect.zip_code })
    }
    // console.log("Face-Images", faceImages)
    return emptyFun
  }, [prospect])
  const createFaceSheet = async (payload) => {
    console.log('facesheet payload', payload);
    const toastId = getToastId("Adding FaceSheet")
    try {
      const response = await apiRequest.post('/face-sheets', payload)
      console.log('createFaceSheet: ', response.data.data)
      if (response && response.data && response.data.data) {
        setFaceImages([...faceImages, { url: image.url, name: image.name, id: response.data.data.id }])
        prospectForm.setFieldsValue({ face_sheets: faceImages.map(item => item.id) });
        updateToast(toastId, "Facesheet Added", true)
        faceForm.resetFields();
      }
    } catch (e) {
      Sentry.captureException(e);
      updateToast(toastId, errMsg(e, false), false)
    }
  }
  
  const onFacesheetChange = ({ fileList: newFileList }) => {
    newFileList.map(file => {
      if (file.response) {
        setImage(file.response.data)
        setImages([...images, file.response.data])
        faceForm.setFieldsValue({ image_id: file.response.data.id })
      }
      return file
    })
  }
  const onFaceFinish = async (values) => {
    await setisExecuting(true)
    await createFaceSheet(values)
    await setisExecuting(false)
    await setFacesheetForm(false)
  }
  const onProspectFinish = async (values_) => {
    let values = { ...values_ }
    //console.log({values, phoneValue})
    console.log("Documents", insuranceDocuments)
    if (allWounds.length !== noOfWounds) {
      prospectForm.validateFields(['number_of_Wounds'])
    }
    const location = locationData ? locationData : { city: prospect.city, state: prospect.state, zip_code: prospect.zip_code }
    cleanObject(values)
    const payload = {
      data: {
        ...values, face_sheets: faceImages.map(item => item.id), wounds: allWounds, ...location, insurances: insuranceList, diabetic: values?.diabetic,
        phone_no: phoneValue,
        birth_date: dayjs(values.birth_date).format("YYYY-MM-DD"), document: [...insuranceDocuments, ...uploadedInsuranceDocuments],
        reference_type: "wound care",
        ...((!!values.billable_from && values.billable_from !== "Invalid date") && { billable_from: dayjs(values.billable_from).format("YYYY-MM-DD") }),
        expires_at: dayjs(values.expires_at).format("YYYY-MM-DD"),
        request_date: dayjs(values.request_date).format("YYYY-MM-DD"),
        ...(!!facility && { facility_id: facility.id }),
        ...phoneValue
      }, prospect_id: prospect.id
    };
    try {
      await executeChunkFn(dispatch, updateProspect, payload, setUpdatingProspect, closeModal, () => {
        prospectForm.resetFields()
        setImages([]);
        setFaceImages([])
        if (loadPatients) {
          loadPatients()
        }
      })
    } catch (e) {
      Sentry.captureException(e);
    }
  }
  
  const onDocumentChange = ({ fileList: newFileList }) => {
    newFileList?.map(file => {
      if (file.response) {
        documentsForm.setFieldsValue({ image_id: file.response.data.id })
      }
      return file
    })
  }
  
  const onDocumentFinish = async (values) => {
    console.log("Document Value:", values)
    setInsuranceDocumentForm(false)
    setInsuranceDocuments([...insuranceDocuments, values])
    documentsForm.resetFields()
  }
  const handleDocumentCancel = () => setInsuranceDocumentForm(false)
  const handleFacesheetOk = (values) => setFacesheetForm(false)
  const handleFacesheetCancel = () => setFacesheetForm(false)
  const disabledFutureDates = (current) => {
    let customDate = dayjs();
    let tooOld = dayjs("1869-12-31")
    return (current && current > dayjs(customDate, dateFormat)) || (current && current < dayjs(tooOld, dateFormat));
  }
  const disabledPastDates = (current) => {
    let customDate = dayjs();
    return current && current < dayjs(customDate, 'YYYY-MM-DD');
  }
  const removeImage = (image, index) => {
    console.log({ faceImages, image })
    const arr = faceImages.filter((item, i) => item.id !== image.id)
    console.log({ arr })
    setImages(arr);
    setFaceImages(arr);
  }
  
  const removeInsuranceDetails = (id) => {
    console.log("Removing:", id)
    const arr = insuranceList.filter(i => i.plan !== id)
    setInsuranceList(arr)
  }
  
  const removeWoundImage = (id) => {
    console.log("Removing:", id)
    const arr = allWounds.filter(i => (i.image_id !== id || i.index !== id))
    setAllWounds(arr)
  }
  // location stuff
  const [viewport, setViewport] = useState({ latitude: 37.7577, longitude: -122.4376, zoom: 8 });
  const [locationData, setLocationData] = useState(null)
  const geocoderContainerRef = useRef();
  const mapRef = useRef();
  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);
  
  const onResult = useCallback(async (result) => {
    console.log(result)
    let center = result.result.center
    let context = result.result.context
    let state = context.find(item => item.id.includes('region'))
    let city = context.find(item => item.id.includes('place'))
    let zip_code = context.find(item => item.id.includes('postcode'))
    let data = {
      // city: city ? city.text : result.result.place_name, state: state ? state.text : null,
      // zip_code: zip_code ? zip_code.text : result.result.place_name,
      co_ordinates: `${center[0]},${center[1]}`,
      state: state ? state.text : null
      // address_1: result.result.place_name
    }
    let timezone = await findTimeZone(city ? city.text : result.result.place_name)
    setLocationData(data)
    prospectForm.setFieldsValue({
      address_1: result.result.place_name,
      city: city ? city.text : null,
      state: state ? state.text : null,
      zip_code: zip_code ? zip_code.text : null,
      timezone: timezone
    });
  }, [])
  const dateChange = (value) => setDate(value)
  const getSsnField = () => {
    return dayjs().diff(dayjs(date), 'years') > 18 ? <Form.Item
      style={{ width: '100%' }}
      name="ssn"
      label="SSN"
      rules={[{ required: false, message: 'SSN is required for adults' }, { max: 11, message: 'SSN length exceeds limit' }/*, {pattern: /^[1-9]./, message: 'Invalid SSN'}*/]}>
      <NumberFormat autoComplete={'off'} customInput={Input} format="###-##-####" placeholder="###-##-####"/>
    </Form.Item> : <Form.Item
      style={{ width: '100%' }}
      name="ssn"
      label="SSN"
      rules={[{ max: 11, message: 'SSN length exceeds limit' }/*, {pattern: /^[1-9]./, message: 'Invalid SSN'}*/]}>
      <NumberFormat autoComplete={'off'} customInput={Input} format="###-##-####" placeholder="###-##-####"/>
    </Form.Item>
  }
  const onFacilitySelect = (value) => {
    let facility = facilities.find(item => item.name === value)
    setFacility(facility)
  };
  
  const [type, set_type] = useState(prospect.referral_type)
  const handleTypeChange = (e) => set_type(e)
  let medicalRecordField = {
    label: "Where do you want to receive the Medical Records?",
    name: 'medical_record_access_option', required: true, type: 'select', options: makeSelectOptions(['email', 'fax', 'email and fax'])
  }
  let hospitalizedField = {
    label: "Has patient been hospitalized in the past 100 Days?",
    name: 'hospitalize',
    required: true,
    type: 'select',
    options: makeSelectOptions(['yes', 'no'])
  }
  
  const actionMenus = kebabMenus(true, false, true)
  
  async function kebabMenuClick(e, record) {
    console.log("Kebab Menu Clicked:", record)
    await setSelectedWound(null)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        // await setSelectedWoundId(record.image_id || record.index)
        await setEditWound(true)
        // delete wound_details.index
        console.log({ record })
        await setSelectedWound(record)
        await setWoundForm(true)
        break;
      case 'Delete':
        setSelectedWoundId(record.image_id || record.index)
        break
      default:
        return;
    }
  }
  
  const handleOk = async (id) => {
    console.log("Selected:", selected, "ID:", id)
    removeWoundImage(id)
    handleCancel()
  }
  // handle cancel
  const handleCancel = () => {
    setSelected(null)
    setSelectedWoundId(null)
  }
  const handleCancelWound = () => {
    setEditWound(false)
    setWoundForm(false)
    setSelectedWound(null)
    setSelectedWoundId(null)
    dialogCloser(setWoundForm)
  }
  
  return <>
    {fileList.length > 0 && showImagePreview(preview, 'Wound image', dialogCloser(setPreview), fileList[0].url)}
    <Modal
      title="Add face sheet"
      open={facesheetForm}
      onOk={handleFacesheetOk}
      onCancel={handleFacesheetCancel}
      closeIcon={modalClose(handleFacesheetCancel)}
      maskClosable={false}
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        {horStack([
          outlinedButton(handleFacesheetCancel, 'Cancel', isExecuting),
          formSubmitButton('faceSheetForm', 'Add', isExecuting, 'Adding')])}
      </div>]}>
      <Form
        form={faceForm}
        name="faceSheetForm"
        id="faceSheetForm"
        labelCol={{ span: 16 }}
        layout="vertical"
        onFinish={onFaceFinish}>
        <Form.Item
          onChange={() => faceForm.setFieldsValue({ name: faceForm.getFieldValue('title') })}
          name="title"
          label="Face Sheet Title"
          rules={[{ required: true, message: 'Missing face sheet title' }]}>
          <Input autoComplete={'off'} placeholder=""/>
        </Form.Item>
        <Form.Item
          style={{ width: '100%', display: 'none' }}
          name="name"
          label="Face Sheet Name"
          // rules={[{ required: true, message: 'Missing face sheet title' }]}
        >
          <Input autoComplete={'off'} placeholder=""/>
        </Form.Item>
        {inputRow([
          <Form.Item
            style={{ width: '100%' }}
            name="type"
            label="Face Sheet Type"
            rules={[{ required: true, message: 'Missing face sheet type' }]}>
            <Select popupMatchSelectWidth={false}
                    style={{ width: '100%' }}>
              {facesheet_types.map(type => (<Option value={type}>{type} </Option>))}
            </Select>
          </Form.Item>,
          <Form.Item
            style={{ width: '100%' }}
            name="expires_at"
            label="Expiration Date"
            rules={[{ required: false, message: 'Missing Expiration Date' }]}>
            <DatePicker disabledDate={disabledPastDates} style={{ width: '100%' }} format={dateFormat}/>
          </Form.Item>
        ])}
        <Form.Item
          style={{ width: '100%' }}
          name="image_id"
          label="Face Sheet Images"
          required={true}
          rules={[{
            validator: (rule, value, callback) => {
              if (value) {
                if (!images[0]) {
                  callback('File not uploaded');
                } else {
                  callback();
                }
              }
            }
          }]}
        >
          <Upload accept="image/*,.pdf,.csv,.xlsx,.doc,.docx"
                  action={uploadUrl}
                  headers={{ 'Authorization': `Bearer ${token}` }}
                  listType="picture"
                  onChange={onFacesheetChange}
                  maxCount={1}>
            <Button icon={<UploadOutlined/>}>Upload</Button>
          </Upload>
          <Input autoComplete={'off'} style={{ width: '100%', display: 'none' }}/>
        </Form.Item>
      </Form>
    </Modal>
    <Modal
      title={editWound ? "Edit Wound" : "Add Wound"}
      open={woundForm}
      onOk={dialogCloser(setWoundForm)}
      onCancel={handleCancelWound}
      closeIcon={modalClose(dialogCloser(setWoundForm))}
      maskClosable={false}
      closable={true}
      destroyOnClose={true}
      width={600}
      height={600}
      footer={
        [<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(handleCancelWound, 'Cancel', addingWound),
            formSubmitButton('prospectImageForm', !editWound ? 'Add' : 'Update', isExecuting, editWound ? 'Adding' : 'Updating')])}
        </div>]}>
      <WoundUpload
        setUploadList={setAllWounds}
        masterListRaw={masterListRaw}
        uploadList={allWounds}
        woundLocations={woundLocations}
        setwoundLocation={setwoundLocations}
        masterLocations={masterLocations}
        uploadUrl={uploadUrl}
        token={token}
        closeModal={() => {
          setWoundForm(false)
          setEditWound(false)
          setSelectedWound(null)
        }}
        editing={editWound}
        setEditing={setEditWound}
        selectedWound={selectedWound}
      />
    </Modal>
    <Modal
      title={editInsurance ? "Edit Insurance Information" : "Add Insurnace Information"}
      open={insuranceForm}
      destroyOnClose={true}
      onOk={dialogCloser(setInsuranceForm)}
      onCancel={dialogCloser(setInsuranceForm)}
      closable={true}
      maskClosable={false}
      closeIcon={modalClose(dialogCloser(setInsuranceForm))}
      width={600}
      footer={
        [<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setInsuranceForm), 'Cancel', addingInsurance),
            formSubmitButton('prospectInsuranceForm', editInsurance ? "Update" : 'Add', addingInsurance, editInsurance ? "Updating" : 'Adding')])}
        </div>]}
    >
      <ProspectInsuranceForm
        setInsurances={setInsuranceList}
        insurances={insuranceList}
        setInsuranceForm={dialogCloser(setInsuranceForm)}
        initialData={initialInsuranceData} prospectData={prospect}
      />
    </Modal>
    <Modal
      title="Add Insurance Document"
      open={insuranceDocumentForm}
      destroyOnClose={true}
      onOk={dialogCloser(setInsuranceDocumentForm)}
      onCancel={dialogCloser(setInsuranceDocumentForm)}
      closable={true}
      maskClosable={false}
      closeIcon={modalClose(dialogCloser(setInsuranceDocumentForm))}
      width={600}
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        {horStack([outlinedButton(dialogCloser(setInsuranceDocumentForm), 'Cancel', isSubmitting),
          formSubmitButton('prospectDocumentForm', 'Add', isSubmitting, 'Adding')])}
      </div>]}
    >
      <Form
        form={documentsForm}
        name="prospectDocumentForm"
        id="prospectDocumentForm"
        labelCol={{ span: 16 }}
        layout="vertical"
        onFinish={onDocumentFinish}
      >
        <Form.Item
          onChange={() => documentsForm.setFieldsValue({ name: documentsForm.getFieldValue('title') })}
          name="title"
          label="Document Title"
          rules={[{ required: true, message: 'Missing Document Title' }]}>
          <Input autoComplete={'off'} placeholder=""/>
        </Form.Item>
        <Form.Item
          style={{ width: '100%', display: 'none' }}
          name="name"
          label="Document Name">
          <Input autoComplete={'off'} placeholder=""/>
        </Form.Item>
        {inputRow([
          <Form.Item
            style={{ width: '100%' }}
            name="type"
            label="Document Type"
            rules={[{ required: true, message: 'Missing Document Type' }]}
          >
            <Select style={{ width: '100%' }}
                    options={[
                      { label: "Insurance", value: "insurance" },
                      { label: 'Wound progress notes', value: 'wound progress notes' },
                      { label: "Other", value: "other" }
                    ]}
            />
          </Form.Item>,
          <Form.Item
            style={{ width: '100%' }}
            name="expires_at"
            label="Expiration Date"
            rules={[{ required: false, message: 'Missing Expiration Date' }]}>
            <DatePicker disabledDate={disabledPastDates} style={{ width: '100%' }} format={dateFormat}/>
          </Form.Item>
        ])}
        <Form.Item style={{ width: '100%' }} name="image_id" label="Documents"
                   rules={[{ required: true, message: 'Missing Document image' }]}>
          <Upload action={uploadUrl}
                  headers={{ 'Authorization': `Bearer ${token}` }}
                  listType="picture"
                  onChange={onDocumentChange}
                  maxCount={1}>
            <Button icon={<UploadOutlined/>}>Upload</Button>
          </Upload>
          <Input autoComplete={'off'} style={{ width: '100%', display: 'none' }}/>
        </Form.Item>
      </Form>
    </Modal>
    <div className="w-full">
      <Form
        form={prospectForm}
        name="edit-prospect-form"
        id="edit-prospect-form"
        initialValues={{ ...initialValues, wound_location: prospect.wounds[0]?.wound_location }}
        layout="vertical"
        onFinish={onProspectFinish}>
        <Row>
          <Col span={16}>
            <div className="p-2" style={{ width: '100%' }}>
              <div className="w-full border rounded-xl shadow p-4">
                <Headline6 className="float-left p-2">Details</Headline6>
                {inputRow([
                  <Form.Item
                    style={{ width: '100%' }}
                    name="facility_id"
                    label="Referring Agency Name"
                    placeholder="Search for agency"
                    initialValue={facility ? facility.name : null}
                    rules={[{ required: true, message: 'Missing Referring Agent' }]}>
                    <AutoComplete disabled={activeUser?.facilityProfile} popupMatchSelectWidth={false} style={{ width: "100%", borderRadius: "4px" }}
                                  onSelect={onFacilitySelect}
                                  placeholder="Search for agency"
                                  defaultValue={facility ? facility.name : null}
                                  onSearch={(value) => handleActiveFacilitySearch(value, setFacilities)}>
                      {facilities?.length > 0 && facilities?.map((item, index) =>
                        <AutoComplete.Option key={index} value={item.name}>{item.name}</AutoComplete.Option>)}
                    </AutoComplete>
                  </Form.Item>
                ])}
                {/*<Form.Item
                  style={{ width: '100%' }}
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: 'Missing/Invalid name', pattern: /[a-zA-Z ]$/ }]}>
                  <Input autoComplete={'off'} placeholder="Name" />
                </Form.Item>*/}
                {inputRow([
                  <Form.Item
                    style={{ width: '100%' }}
                    name="first_name"
                    label="First Name"
                    rules={[{ required: true, message: 'Missing/Invalid first name', pattern: /[a-zA-Z ]$/ }]}>
                    <Input autoComplete={'off'} placeholder="First Name"/>
                  </Form.Item>,
                  <Form.Item
                    style={{ width: '100%' }}
                    name="middle_name"
                    label="Middle Name"
                    rules={[{ required: false, message: 'Missing/Invalid name', pattern: /[a-zA-Z ]$/ }]}>
                    <Input autoComplete={'off'} placeholder="Middle Name"/>
                  </Form.Item>,
                  <Form.Item
                    style={{ width: '100%' }}
                    name="last_name"
                    label="Last Name"
                    rules={[{ required: true, message: 'Missing/Invalid Last Name', pattern: /[a-zA-Z ]$/ }]}>
                    <Input autoComplete={'off'} placeholder="Last Name"/>
                  </Form.Item>
                ])}
                {inputRow([
                  <Form.Item
                    style={{ width: '100%' }}
                    name="birth_date"
                    label="Date of Birth"
                    rules={[{ required: true, message: 'Missing date of birth' }]}>
                    <DatePicker onChange={dateChange} disabledDate={disabledFutureDates} style={{ width: '100%' }}
                                format={dateFormat}/>
                  </Form.Item>,
                  <Form.Item
                    style={{ width: '100%' }}
                    name="gender"
                    label="Gender"
                    rules={[{ required: true, message: 'Missing Gender' }]}>
                    <Select popupMatchSelectWidth={false}
                            placeholder="Gender"
                            options={makeSelectOptions(GetRawListOptions('gender'))}
                            style={{ width: '100%' }}
                            allowClear
                    />
                  </Form.Item>
                ])}
                {inputRow([
                  <Form.Item
                    name="visit_type"
                    label="New Patient?"
                    rules={[{ required: true, message: 'Missing/Invalid Last name', pattern: /[a-zA-Z ]$/ }]}>
                    <Select popupMatchSelectWidth={false}
                            placeholder="Is the patient New or Established"
                            style={{ width: '100%' }}
                            allowClear>
                      <Option value="New Patient">New</Option>
                      <Option value="Established Patient">Established</Option>
                    </Select>
                  </Form.Item>,
                  <Form.Item
                    style={{ width: '100%' }}
                    name="referral_priority"
                    label="Referral Priority"
                    rules={[{ required: true, message: 'Missing Priority' }]}
                  >
                    <Select
                      popupMatchSelectWidth={false}
                      placeholder="Priority"
                      style={{ width: '100%' }}
                      allowClear
                    >
                      <Option value="Low">Low</Option>
                      <Option value="Medium">Medium</Option>
                      <Option value="High">High</Option>
                      <Option value="Urgent">Urgent</Option>
                    </Select>
                  </Form.Item>
                ])}
                
                {inputRow([
                  getSsnField(),
                  <Form.Item
                    style={{ width: '100%' }}
                    name="email"
                    label="Email"
                    rules={[{ required: false, message: 'Missing Email' }]}>
                    <Input autoComplete={'off'} placeholder="Email"/>
                  </Form.Item>
                ])}
                
                {inputRow([
                  <Form.Item
                    style={{ width: '100%' }}
                    name="fax"
                    label="Fax"
                    rules={[{ required: false, message: 'Missing Fax' }]}
                  >
                    <NumberFormat
                      autoComplete={'off'}
                      customInput={Input}
                      format="+# (###) ### ####"
                      placeholder="Fax"
                    />
                  </Form.Item>,
                  <AppPhoneInput name={'phone_no'} setPhoneValue={setPhoneValue} phoneValue={phoneValue} formItemProps={{ style: { width: '100%' }, required: true }}/>
                ])}
                {/* 
                <Form.Item name={"type_of_kit"} label="Type of Kit" rules={[{ required: false, message: 'Missing Kit Type' }]}>
                  <Select placeholder="Type of Kit">
                    <Option value="Home Health (Standard) Kit">Standard Kit</Option>
                    <Option value="Hospice Kit">Hospice Kit</Option>
                    <Option value="Hospice Kit (Cash)">Hospice Kit (Cash)</Option>
                    <Option value="No Supplies">No Supplies</Option>
                  </Select>
                </Form.Item> */}
                
                <Form.Item style={{ width: '100%' }} name="patient_location_type" label="Patient Location Type" rules={[{ required: true, message: 'Missing Patient Location Type' }]}>
                  <Select style={{ width: '100%' }}>
                    {GetOptions('patient_location_type')}
                  </Select>
                </Form.Item>
                <Subtitle1 className="Validator" style={{ textAlign: 'left', marginBottom: '10px' }}>Address Search</Subtitle1>
                <Form.Item
                  style={{ width: '100%', ...(initialValues.address_1 && { marginBottom: 0 }) }}
                  name="default_region"
                  label=""
                  rules={[{ required: false, message: 'Missing Region' }]}>
                  <div ref={geocoderContainerRef} style={{ position: "absolute", top: 0, left: 0, zIndex: 1, width: "100%" }}/>
                  <MapGL ref={mapRef}  {...viewport} width="100%" height="100%" onViewportChange={handleViewportChange} mapboxApiAccessToken={mapboxToken}>
                    <Geocoder mapRef={mapRef} containerRef={geocoderContainerRef} onViewportChange={handleViewportChange} mapboxApiAccessToken={mapboxToken} countries="us,ke" onResult={onResult}
                              marker={false} onError={onResult}/>
                  </MapGL>
                </Form.Item>
                {showCurrentAddress(initialValues.address_1)}
                {(locationData || initialValues.address_1) && <>
                  <Form.Item style={{ width: '100%', marginTop: '10px' }} name="address_1" label="Address" rules={[{ required: true, message: 'Missing location' }]}>
                    <Input autoComplete={'off'} placeholder="Address"/>
                  </Form.Item>
                  {inputRow([
                    <Form.Item style={{ width: '100%' }} name="state" label="State" rules={[{ required: true, message: 'Missing State' }]}>
                      <Input autoComplete={'off'} placeholder="State"/>
                    </Form.Item>,
                    <Form.Item style={{ width: '100%' }} name="city" label="City" rules={[{ required: true, message: 'Missing City' }]}>
                      <Input autoComplete={'off'} placeholder="City"/>
                    </Form.Item>,
                    <Form.Item style={{ width: '100%' }} name="zip_code" label="Zip Code"
                               rules={[{ required: true, message: 'Missing Zip code' }]}>
                      <Input autoComplete={'off'} placeholder="zip"/>
                    </Form.Item>
                  ])}
                  {/* timezone */}
                  <Form.Item
                    style={{ width: '100%' }}
                    name="timezone"
                    label="Timezone"
                    rules={[{ required: false, message: 'Missing Timezone' }]}>
                    <input autoComplete={'off'} placeholder="Timezone" disabled/>
                  </Form.Item>
                </>}
                {getInputField(prospectForm, hospitalizedField)}
                <Form.Item
                  style={{ width: '100%' }}
                  name="diabetic"
                  label="Diabetic?"
                  rules={[{ required: false, message: 'Missing Diabetic' }]}>
                  <Select placeholder="Is Patient Diabetic?" onSelect={(value) => setDiabetic(value)}>
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                </Form.Item>
                {diabetic && diabetic === "yes" && (
                  <Form.Item name="diabetic_type" label="Diabetic Type" rules={[{ required: true, message: 'Missing Diabetic Type' }]}>
                    <Select placeholder="Diabetic Type">
                      <Option value="Type 1">Type 1</Option>
                      <Option value="Type 2">Type 2</Option>
                    </Select>
                  </Form.Item>
                )}
                <Form.Item name="consent" label="Consent" rules={[{ required: true, message: 'Missing Consent' }]}>
                  <Select placeholder="Consent" onChange={(value) => setConsent(value)}>
                    <Option value="self">Self</Option>
                    <Option value="power of attorney">Power of Attorney</Option>
                  </Select>
                </Form.Item>
                {consent === 'power of attorney' && (
                  <>
                    {inputRow([
                      <Form.Item
                        style={{ width: '100%' }}
                        name="power_of_attorney_name"
                        label="Power of Attorney Name"
                        rules={[{ required: true, message: 'Missing Power of Attorney Name' }]}>
                        <Input autoComplete={'off'} placeholder="Power of Attorney Name"/>
                      </Form.Item>,
                      <AppPhoneInput name={'power_of_attorney_contact_number'} label="Power of Attorney phone number" setPhoneValue={setPhoneValue} phoneValue={phoneValue}
                                     formItemProps={{ style: { width: '100%' }, required: true, label: 'Attorney phone number' }}/>
                    ])}
                  </>
                )}
                
                {inputRow([
                  <Form.Item name="case_manager" label="Case Manager Name" rules={[{ required: false, message: 'Missing Case Manager Name' }]}>
                    <Input autoComplete={'off'} placeholder="Case Manager Name"/>
                  </Form.Item>,
                  <AppPhoneInput name={'case_manager_phone'} setPhoneValue={setPhoneValue} phoneValue={phoneValue}
                                 formItemProps={{ style: { width: '100%' }, required: false, label: 'Case manager number' }}/>
                ])}
                {inputRow([
                  <Form.Item
                    style={{ width: '100%' }}
                    name="request_date"
                    label="Date of Request"
                    rules={[{ required: true, message: 'Missing date of request' }]}>
                    <DatePicker disabledDate={disabledFutureDates} style={{ width: '100%' }} format={dateFormat}/>
                  </Form.Item>,
                  <Form.Item
                    style={{ width: '100%' }}
                    name="billable_from"
                    label="Date can Bill"
                    rules={[{ required: false, message: 'Missing date can bill' }]}>
                    <DatePicker disabledDate={disabledPastDates} style={{ width: '100%' }} format={dateFormat}/>
                  </Form.Item>
                ])}
                {inputRow([
                  <Form.Item
                    style={{ width: '100%' }}
                    name="visit_reason"
                    label="Visit Reason"
                    rules={[{ required: false, message: 'Missing Visit Reason' }, {
                      max: 140,
                      message: "Value should be less than 140 characters"
                    }]}>
                    <Input autoComplete={'off'} placeholder=""/>
                  </Form.Item>
                
                ])}
                {getInputField(prospectForm, medicalRecordField)}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="p-2" style={{ width: '100%' }}>
              <div className="">
                <Form.Item
                  name="face_sheets"
                  required={true}
                  className="border shadow rounded-xl"
                  rules={[
                    {
                      validator: (_, values) =>
                        !!faceImages[0] ? Promise.resolve() : Promise.reject(new Error('Please upload a facesheet.'))
                    }
                  ]}
                >
                  <div className="p-4 w-full h-auto">
                    <div className="flex-boy-row-space" style={{ width: '100%' }}>
                      <Subtitle1>
                        <span className="text-red-500">*</span>
                        Face Sheets</Subtitle1>
                      <PlusOutlined onClick={() => setFacesheetForm(true)}/>
                    </div>
                    <div className="mt-2">
                      {faceImages?.length > 0 &&
                        <>
                          <ImageTag.PreviewGroup>
                            {faceImages.map((item, index) => <div className="flex-boy-row-space gap-2"
                                                                  style={{ width: '100%', maxHeight: 100, marginBottom: 10 }}
                                                                  key={index}><ImageTag
                              fallback={require('../../../assets/pic.png')} height={60} width={60}
                              src={item.url}>{item.name}</ImageTag>
                              <p>{item.name?.trim()}</p>
                              <FontAwesomeIcon onClick={() => removeImage(item, index)} color="grey" icon={["fas", "trash"]}/>
                            </div>)}
                          </ImageTag.PreviewGroup>
                        </>}
                    </div>
                  </div>
                </Form.Item>
              </div>
              {prospectForm.getFieldValue('referral_type') !== 'podiatry' &&
                <>
                  <div className="p-2 border shadow rounded-xl w-full h-auto mb-8">
                    <div className="flex-boy-row-space" style={{ width: '100%' }}>
                      <Subtitle1>Wounds</Subtitle1>
                      <PlusOutlined onClick={() => setWoundForm(true)}/>
                    </div>
                    <Form.Item style={{ width: '100%' }} name="number_of_Wounds" label="Number of Wounds" rules={[{ required: false, message: 'Missing Number of Wounds' }]}>
                      {/* <InputNumber min={1} max={10}   value={noOfWounds} /> */}
                      <Select onChange={(value) => setNoOfWounds(value)} style={{ width: '100%', margin: "0px" }}>
                        {GetNumericalListOptions(10)}
                      </Select>
                    </Form.Item>
                    {allWounds && allWounds.length > 0 && allWounds.map((wound, index) => {
                        console.log({ wound1: wound })
                        return <>
                          <div className="flex-boy-row-space" style={{ width: '100%' }}>
                            <Headline6>Wound Details</Headline6>
                            <KebabMenu
                              menus={actionMenus}
                              recordItem={wound}
                              handleMenuClick={kebabMenuClick}
                              handleOk={() => handleOk(wound.image_id || wound.index)}
                              handleCancel={handleCancel}
                              showConfirmDialog={selectedWoundId === wound.image_id || selectedWoundId === wound.index}
                              resource={"wound"}
                            />
                          </div>
                          <Row gutter={12} style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Col style={{ width: "100%" }}>
                              {keyValItemForReferral('Wound Location', wound.wound_location)}
                              {keyValItemGreyForReferral('Wound Side', wound.wound_side)}
                              {keyValItemForReferral('Wound Position', wound.wound_position)}
                              {keyValItemGreyForReferral('Wound Duration', wound.wound_duration)}
                              {/*{keyValItemForReferral('Wound Bed', wound?.wound_bed||'-')}*/}
                              {keyValItemGreyForReferral('Granulation Tissue(%)', wound?.granulation_tissue?.toString() || '-')}
                              {keyValItemForReferral('Necrotic Tissue(%)', wound?.necrotic_tissue?.toString() || '-')}
                              {keyValItemGreyForReferral('Fibrous Tissue(%)', wound?.fibrous_tissue?.toString() || '-')}
                              {keyValItemForReferral('Onset of the wound', wound.original_date_of_wound ? dayjs(wound.original_date_of_wound).format('MM-DD-YYYY') : '-')}
                              {/* {keyValItem('Wound Type', wound.wound_type)} */}
                            </Col>
                          </Row>
                          <Row style={{ width: '100%' }}>
                            <Col span={12}>
                              {wound.image && wound.image.url &&
                                <div className="flex-boy-row-space" style={{ width: "100%" }}>
                                  <ImageTag src={wound?.image?.url} height={100} width={100} fallback={require('../../../assets/pic.png')}/>
                                </div>
                              }
                            </Col>
                            <Col span={12}>
                              {keyValItemForReferral('Width', wound?.width ? wound?.width : '-')}
                              {keyValItemGreyForReferral('Length', wound?.length ? wound?.length : '-')}
                              {keyValItemForReferral('Area', wound?.area ? wound?.area : '-')}
                              {keyValItemGreyForReferral('Depth', wound?.depth ? wound?.depth : '-')}
                              {keyValItemForReferral('Volume', wound?.volume ? wound?.volume : '-')}
                            </Col>
                          </Row>
                          <Divider/>
                        
                        </>;
                      }
                    )}
                    {allWounds.length > 0 &&
                    allWounds.length < noOfWounds ?
                      <Body1 className="Validator" color="crimson">
                        The Referral has {Math.abs(allWounds.length - noOfWounds)} wound{Math.abs(allWounds.length - noOfWounds) > 1 ? "s" : ""} missing compared to the number selected in the "number
                        of wounds" dropdown.
                      </Body1>
                      :
                      allWounds.length > noOfWounds ?
                        <Body1 className="Validator" color="crimson">
                          The Referral has {Math.abs(allWounds.length - noOfWounds)} extra wound{Math.abs(allWounds.length - noOfWounds) > 1 ? "s" : ""} compared to the number selected in the "number
                          of wounds" dropdown.
                        </Body1>
                        : null
                    }
                  </div>
                </>}
              
              <div className="p-2 border shadow rounded-xl w-full h-auto">
                <div className="flex-boy-row-space" style={{ width: '100%' }}>
                  <Subtitle1>Insurance Information (Optional)</Subtitle1>
                  <PlusOutlined onClick={async () => {
                    await setInitialInsuranceData(null);
                    await setInsuranceForm(true);
                  }}/>
                </div>
                <div className="mt-2">
                  {insuranceList[0] && insuranceList.map((item, index) => {
                    console.log({ item })
                    return (
                      <div style={{ marginBottom: "7px" }}>
                        <InsuranceCard key={item?.id} insurance={item} removeInsuranceDetails={removeInsuranceDetails} setEditInsurance={setEditInsurance}
                                       setInitialInsuranceData={setInitialInsuranceData}
                                       setInsuranceForm={setInsuranceForm} prospectData={prospect}/>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="p-2 border shadow rounded-xl w-full h-auto mt-8">
                <div className="flex-boy-row-space" style={{ width: '100%' }}>
                  <Subtitle1>Documents{" "}(Insurance, Wound Progress Notes)</Subtitle1>
                  <PlusOutlined onClick={() => setInsuranceDocumentForm(true)}/>
                </div>
                {uploadedInsuranceDocuments && uploadedInsuranceDocuments.length > 0 && uploadedInsuranceDocuments.map((item, index) => (
                  <ProspectDocumentsView key={index} file={item} documentList={uploadedInsuranceDocuments} setDocumentList={setUploadedInsuranceDocuments}/>
                ))}
                {insuranceDocuments && insuranceDocuments.length > 0 && insuranceDocuments.map((item, index) => (
                  <ProspectDocumentsView key={index} file={item} documentList={insuranceDocuments} setDocumentList={setInsuranceDocuments}/>
                ))}
              </div>
            
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  </>;
}
const mapStateToProps = (state) => ({
  masterListRaw: state.masters.masterListRaw,
  activeUser: state.auth?.activeUser
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(EditProspectCard)
