import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import HeaderTop from '../../../components/HeaderTop'
import {
  addButtonClean,
  cleanObject,
  executeChunkFn,
  formSubmitButton,
  getAssessmentStatus,
  getprogressStatus,
  getHeaderLeftNav,
  getSubmitStatus,
  horStack,
  inputRow,
  boldKeyCapitalizedValue,
  boldKeyCapitalizedValueGrayBackground,
  makeAutoCompleteOptions,
  modalClose,
  outlinedButton,
  secondaryButton,
  getPatientStatus,
  keyValItemSimple,
  keyValItemCaps
} from '../../../common/helpers'
import { Body2, Headline5, Headline6, Subtitle1, Subtitle2 } from '../../../components'
import { AutoComplete, Avatar, Button, Checkbox, Col, Divider, Dropdown, Form, Image, Input, InputNumber, Menu, Modal, Row, Spin, Typography } from 'antd'
import dayjs from 'dayjs'
import { CloseCircleOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { fetchBillingEncounter, fetchFacilityById, selectFacility, submitBillingEncounter, updateBillingEncounter } from '../../../app/Reducers'
import { toast } from 'react-toastify'
import { apiRequest } from '../../../app/Apis'
import { usePermissionCheck } from '../../../hooks/usePermissionCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toastUp } from '../../../app/Reducers/reducerUtils'
import _ from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom';

export const EncounterPage = ({ encounter, masterListRaw }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [masterLocations, setMasterLocations] = useState(masterListRaw[0] ? masterListRaw.find(item => item.system_name === 'wound_location')?.options : [])
  const [woundLocations, setwoundLocations] = useState((!!masterLocations && masterLocations[0]) ? makeAutoCompleteOptions(...[masterListRaw.find(item => item.system_name === 'wound_location')?.options]) : [])
  const encounter_id = Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [billingEntity, setBillingEntity] = useState(null);
  const [allBillingEntities, setAllBillingEntities] = useState([]);

  const can_update_billing = usePermissionCheck('update_assessment_billing', false, [])

  const createBillingEntity = () => {
    const payload = apiRequest.get(`/billing-entity`).then((e) => {
      console.log("Entities:::", e.data.data)
      setAllBillingEntities(e.data.data)
    })
  }
  // search cpt codes
  const [cptCodes, setCptCodes] = useState([])

  const searchcptcodes = async (value) => {
    if (value.length > 1) {
      const response = await apiRequest.get(`/cpt-code?search=${value}`).catch(function (err) {
        console.log(err.response.data.message);
      })
      if (response && response.data) {
        setCptCodes(response.data.data)
      }
    }
  }

  const fetchBillingEntityByProcedure = async (procedure) => {
    const response = await apiRequest.get(`/billing-entity/procedure/${procedure}`);
    if (response.data.data) {
      console.log('billing entity', response.data.data[0])
      setBillingEntity(response.data.data[0]);
    }
  }


  // search diagnosis codes
  const [diagnosisCodes, setDiagnosisCodes] = useState([])

  const searchdiagnosiscodes = async (value) => {
    if (value.length > 1) {
      const response = await apiRequest.get(`/dx-code?search=${value}`).catch(function (err) {
        console.log(err.response.data.message);
      })
      if (response && response.data) {
        setDiagnosisCodes(response.data.data)
      }
    }
  }


  // initial values useState
  const [initialValues, setInitialValues] = useState(encounter?.billing_status === "pending_review" ? { cpt_code: [''], modifier: ['', '', ''], diagnosis_code: ['', ''] } : { ...encounter?.billing })

  const handleMenuClick = async (e) => {
    console.log('click', e);
    let key = e.key
    if (key === "needs_review") {
      setreasonModal(true)
    }
  }

  const menu = (<Menu onClick={handleMenuClick}>
    <Menu.Item key={"needs_review"} style={{ textTransform: 'capitalize' }}> {"needs_review".replace('-', '').replace(/_/g, ' ')}  </Menu.Item>
  </Menu>);
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  };
  const tailLayout = {
    wrapperCol: { offset: 0, span: 24 }
  };

  // isExecuting
  const [isExecuting, setIsExecuting] = useState(false)
  const onFinish = async (values) => {
    console.log('Success:', values);
    // setIsExecuting(true)
    // await executeChunkFn(dispatch, editingForm ? updateTask : createTask, cleanObject(payload), setIsExecuting, closeModal, async () => {

    // })
    let payload = encounter?.billing_status === "pending_review" ? { data: { ...values }, id: encounter?.id } : { data: { ...values }, id: encounter?.id, billing_id: encounter?.billing.id }
    await executeChunkFn(dispatch, encounter?.billing_status === "pending_review" ? submitBillingEncounter : updateBillingEncounter, cleanObject(payload), setIsExecuting, null, async () => {
    })
  };

  function viewPatient(id) {
    navigate(`/patients/${id}`)
  }

  async function viewFacility(id) {
    const response = await apiRequest.get(`/medical-facilities/${id}`);
    dispatch(selectFacility(response.data.data))
    if (response.data.data) {
      navigate(`/facilities/${id}`)
    }
  }

  useEffect(() => {
    console.log('Encounter:::', encounter)
    dispatch(fetchBillingEncounter({ id: encounter_id }))
    createBillingEntity()
    return () => { }
  }, [])

  useEffect(() => {
    setInitialValues(encounter?.billing_status === "pending_review" ? { cpt_code: [''], modifier: ['', '', ''], diagnosis_code: ['', ''], units: "1" } : { ...encounter?.billing })
    if (encounter?.billing) {
      form.setFieldsValue({ ...encounter?.billing, modifier: encounter?.billing?.modifier ? encounter?.billing?.modifier : ['', '', ''] })
    }
    fetchBillingEntityByProcedure(encounter?.procedure)
    return () => { }
  }, [encounter])

  async function onEntityUpdateFinish(values) {
    console.log(values)
    setIsEditing(false)
  }

  const computedInitialValues = encounter?.billing_status === "pending_review"
    ? { cpt_code: [''], modifier: ['', '', ''], diagnosis_code: ['', ''], units: "1" }
    : { ...encounter?.billing, modifier: encounter?.billing?.modifier ? encounter?.billing?.modifier : ['', '', ''] };

  const [form] = Form.useForm();


  // option list
  const optionsList = cptCodes?.map((item, index) => {
    return (<AutoComplete.Option key={index} value={`${item.cpt}`}>{`${item?.cpt}-${item.description}`.replace("-null", "")}</AutoComplete.Option>)
  })

  // dx-options list
  const dxOptionsList = diagnosisCodes?.map((item, index) => {
    return (<AutoComplete.Option key={index} value={`${item.diagnosis}`}>{`${item?.diagnosis}-${item.description}`.replace("-null", "")}</AutoComplete.Option>)
  })
  // reason modal
  const [reasonModal, setreasonModal] = useState(false)

  const reasonfinish = async (values) => {
    console.log('Success:', values);

    const toastId = toast("Updating Assesment status", { type: "info", autoClose: false });
    const response = await apiRequest.post(`/assessments/${encounter?.id}/status`, { status: "needs_review", notes: values.reason }).catch(async function (err) {
      toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
    })
    if (response && response.data) {
      toast.update(toastId, { render: "Assessment status updated", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      navigate(-1)
    }
  };
  // fetch assessment tied to encounter
  const [assessment, setAssessment] = useState(null)
  const fetchAssessment = async () => {
    const response = await apiRequest.get(`/assessments/${encounter?.id}`);
    if (response.data.data) {
      console.log('assessment', response.data.data)
      setAssessment(response.data.data)
    }
  }

  const updateAssessments = async () => {
    setUpdatingStatus(true)
    console.log('updating assessment', billingEntity?.id)
    const payload = { billing_entity_id: `${billingEntity?.id}` }
    const toastId = toast("Updating Billing Entity", { type: "info", autoClose: false });
    const response = await apiRequest.patch(`/assessments/${encounter?.id}`, payload).catch(async function (err) {
      toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
    })
    if (response && response.data) {
      toast.update(toastId, { render: "Billing entity updated", type: toast.TYPE.SUCCESS, autoClose: 5000 });
    }
    fetchAssessment()
    setUpdatingStatus(false)
    setIsEditing(false)
  }

  useEffect(() => {
    fetchAssessment()
    return () => {
    }
  }, [encounter])

  // selected options
  const [selectedOptions, setSelectedOptions] = useState([])

  useEffect(() => {
    let cpt_codes = form.getFieldValue('cpt_code')

    // if cpt_codes is an array loop through it and get all the charges from selectedOptions where item.cpt === cpt_codes[index] and add them up
    if (cpt_codes.length) {
      let charges = 0
      cpt_codes.forEach((item, index) => {
        let selected = selectedOptions.find(option => option.cpt === item)
        charges += parseFloat(selected?.charges || 0)
      })
      form.setFieldsValue({ charges });
    }
    return () => {
    }

  }, [selectedOptions])

  return (
    <div className="main-page">
      <Modal
        title={`Please add a Reason`}
        open={reasonModal}
        destroyOnClose={true}
        onOk={() => setreasonModal(false)}
        onCancel={() => setreasonModal(false)}
        closeIcon={modalClose(() => setreasonModal(false))}
        maskClosable={false}
        width={1200}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(() => setreasonModal(false), 'Cancel', isExecuting),
          formSubmitButton('reasonForm', 'Update Reason', isExecuting, 'Updating')])}
        </div>]}>
        <Form
          name="reasonForm"
          onFinish={reasonfinish}
          layout="vertical"
        >
          <Form.Item
            style={{ width: '100%' }}
            label="Review Request Note"
            name="reason"
            rules={[{ required: true, message: 'this field is required!' }]}
            wrapperCol={{ span: 24 }}>
            <Input autoComplete={'off'} />
          </Form.Item>
        </Form>
      </Modal>
      {encounter && <>
        <HeaderTop child={getHeaderLeftNav(navigate, 'Encounter Details')} />
        <div className="grid grid-cols-8 w-full" style={{ height: 'calc(100vh - 60px)' }}>
          <div className="col-span-2 shadow bg-white p-2 h-full">
            {/* billing form */}
            <div className="flex justify-between items-center mb-2">
              <p className="font-bold text-left">{encounter?.billing_status === "submitted" ? "Submit status " : "Status"}</p>
              {encounter?.billing_status === "submitted" ? getSubmitStatus(encounter?.billing_response_status) : getprogressStatus(encounter?.billing_status)}

            </div>
            {encounter?.billing_response_status === "failed" && <div className="flex items-center p-2 rounded mb-3" style={{ background: "#EBEFF4" }}>
              <span className='font-semibold mr-2 p-0'>Reason:</span>
              <span className='p-0'>{encounter?.billing_response_message?.message}</span>
            </div>}
            <Form
              form={form}
              disabled={encounter?.billing_status === 'ready_for_submission' || !can_update_billing}
              name="billing"
              layout="vertical"
              initialValues={{ ...computedInitialValues }}
              onFinish={onFinish}
            >
              {/* dynamic form item with only one field called code returns array */}
              <Form.List name="cpt_code">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <>
                        {index === 0 &&
                          <Form.Item>
                            <div className="flex justify-between items-center">
                              <div className="flex items-center">
                                <p classname="text-red-500" style={{ color: 'red' }}>*</p>
                                <p className="font-bold ml-1">
                                  CPT Code
                                </p>
                              </div>
                              {(encounter?.billing_status !== 'ready_for_submission' && can_update_billing) && addButtonClean(() => {
                                console.log('new code')
                                add()
                              })}
                            </div>
                          </Form.Item>
                        }
                        <Form.Item
                          {...(index === 0 ? layout : tailLayout)}
                          required={false}
                          key={field.key}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Please input CPT code or delete this field."
                              }
                            ]}
                            noStyle
                          >
                            {/* <Input placeholder="CPT code" style={{ width: fields.length > 1 ? '90%' : '100%' }} /> */}
                            {/* autocomplete */}
                            {/* <AutoComplete
                              onSelect={(value) => {
                                const selectedOption = masterListRaw.find(item => item.system_name === "c_p_t_code")?.options?.find(item => item.toString().split('-')[0].replace(/\s/g, '') === value);
                                const label = selectedOption?.toString().replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase());
                                const charges = selectedOption?.toString().split('-').pop();
                                console.log('label', label);
                                console.log('charges', charges);
                                form.setFieldsValue({ charges });
                              }}
                              placeholder="CPT code"
                              style={{ width: fields.length > 1 ? '90%' : '100%' }}
                              options={masterListRaw.find(item => item.system_name === "c_p_t_code")?.options?.map(item => {
                                // get all characters before the first dash and remove spaces
                                let code = item.toString().split('-')[0].replace(/\s/g, '')
                                return { value: code, label: item.toString().replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()) }
                              })}
                            /> */}
                            <AutoComplete

                              placeholder="CPT code"
                              style={{ width: fields.length > 1 ? '90%' : '100%' }}
                              onSearch={(value) => { searchcptcodes(value) }}
                              onSelect={async (value) => {
                                const selectedOption = cptCodes.find(item => item.cpt === value);

                                let options = [...selectedOptions, selectedOption]
                                await setSelectedOptions(options)

                                // get current value of charges and increment it by selectedOption.charges as int if only one cpt code replace
                                // const charges = parseFloat(form.getFieldValue('charges') ? form.getFieldValue('charges') : 0) + parseFloat(selectedOption.charges)

                              }}



                            >
                              {cptCodes.length > 0 && optionsList}
                            </AutoComplete>


                          </Form.Item>
                          {encounter?.billing_status !== 'ready_for_submission' && fields.length > 1 ? (
                            <CloseCircleOutlined
                              className="dynamic-delete-button"
                              style={{ margin: '0 8px' }}
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          ) : null}
                        </Form.Item>
                      </>
                    ))}

                  </>
                )}
              </Form.List>
              {/* form input called modifier with 3 input fields in one row returns array */}
              <Form.List name="modifier">
                {(fields, { add, remove }) => (
                  <>
                    <div className="flex items-center mb-2">
                      {/* <p classname="text-red-500" style={{ color: 'red' }}>*</p> */}
                      <p className="font-bold ml-1">

                        Modifier
                      </p>
                    </div>
                    {inputRow([...(fields.map((field, index) => (
                      <>
                        {/* {index === 0 &&

                                                <Form.Item>
                                                    <div className="flex justify-between items-center">
                                                        <p className="font-bold">modifier</p>
                                                        {encounter?.billing_status !== 'ready_for_submission' && addButtonClean(() => {
                                                            console.log('new modifier')
                                                            add()
                                                        })}
                                                    </div>
                                                </Form.Item>

                                            } */}
                        <Form.Item
                          {...(index === 0 ? layout : tailLayout)}

                          required={false}
                          key={field.key}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: false,
                                whitespace: true,
                                message: "Please input modifier"
                              }
                            ]}
                            noStyle
                          >
                            <Input style={{ width: '100%' }} />
                          </Form.Item>

                        </Form.Item>
                      </>
                    )))])}
                  </>
                )}
              </Form.List>
              {/* units fields */}
              <Form.Item
                label="Units"
                name="units"
                rules={[{ required: true, message: 'Please input units!' }]}
              >
                <InputNumber placeholder="Units" />
              </Form.Item>

              {/* Charge input number field with dollar prefix */}
              <Form.Item
                label="Unit Charge"
                name="charges"
                rules={[{ required: true, message: 'Please input charge!' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>


              {/* form input called diagnosis with 2 input fields in one row  returns array */}
              <Form.List name="diagnosis_code">
                {(fields, { add, remove }) => (
                  <>
                    <div className="flex justify-between items-center mb-3">
                      <div className="flex items-center">
                        <p classname="text-red-500" style={{ color: 'red' }}>*</p>
                        <p className="font-bold ml-1">
                          Diagnosis Code (Dx)
                        </p>
                      </div>

                      {(encounter?.billing_status !== 'ready_for_submission' && can_update_billing) && addButtonClean(() => {
                        console.log('new diagnosis')
                        add()
                      })}
                    </div>
                    <div className="flex flex-wrap">
                      {fields.map((field, index) => (
                        <div className="w-1/2">
                          <Form.Item
                            {...(index === 0 ? layout : tailLayout)}
                            required={true}
                            key={field.key}
                          >
                            <Form.Item
                              {...field}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please input diagnosis or delete this field."
                                }
                              ]}
                              noStyle
                            >
                              <AutoComplete

                                placeholder="Dx code"
                                style={{ width: fields.length > 1 ? '80%' : '100%' }}
                                onSearch={(value) => {
                                  searchdiagnosiscodes(value)
                                }}
                              >
                                {diagnosisCodes.length > 0 && dxOptionsList}
                              </AutoComplete>
                            </Form.Item>
                            {encounter?.billing_status !== 'ready_for_submission' && fields.length > 1 ? (
                              <CloseCircleOutlined
                                className="dynamic-delete-button"
                                style={{ margin: '0 8px' }}
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            ) : null}

                          </Form.Item>
                        </div>
                      ))}
                    </div>
                  </>

                )}
              </Form.List>


              {/* submit button with title Submit if encounter?.billing_status === 'pending_review' resubmit otherwise full width */}
              {/* <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                {encounter?.billing_status === 'pending_review' ? 'Submit' : 'Resubmit'}
                            </Button>
                        </Form.Item> */}
              {(encounter?.billing_status !== 'ready_for_submission' && can_update_billing) && formSubmitButton('billing', encounter?.billing_status === 'pending_review' ? 'Submit' : 'Resubmit', isExecuting, encounter?.billing_status === 'pending_review' ? 'Submitting' : 'Resubmiting', null, { width: '100%' }
              )}


            </Form>

          </div>
          <div className="col-span-6 bg-gray-100 h-full">
            <div className="w-full flex justify-between shadow bg-white  p-4">
              <Headline6 className="ml-4 mt-4">Assessment</Headline6>
              {/* <div className="flex">
                                <Button style={{ padding: 0, marginLeft: 10, marginRight: 10, borderWidth: 0 }} size={'middle'} onClick={() => console.log("clicked")}
                                    icon={<FontAwesomeIcon size="lg" color="black" icon={["far", 'file-pdf']} />}>
                                </Button>
                            </div> */}

            </div>
            <div className="grid grid-cols-12 gap-1 p-4">
              <div className="col-span-12 lg:col-span-6">
                <div className="card-box">
                  <div className="flex-boy-row-space" style={{ width: '100%' }}>
                    <Headline6>Assessment Details</Headline6>
                    {(encounter?.billing_status === 'pending_review' && can_update_billing) ?
                      <Dropdown dropdownRender={() => menu} trigger={['click']}>
                        {secondaryButton(null, getAssessmentStatus(encounter?.assessment_status), updatingStatus, 'Updating',
                          null, null)}
                      </Dropdown>

                      : getAssessmentStatus(encounter?.assessment_status)
                    }
                  </div>
                  <Row gutter={8} style={{ width: '100%' }}>
                    <Col span={12}>
                      {boldKeyCapitalizedValue('Patient', encounter?.patient.name)}
                      {boldKeyCapitalizedValueGrayBackground('Visit', dayjs(encounter?.service_date).format('MM/DD/YYYY'))}
                      {boldKeyCapitalizedValue('Procedure', encounter?.procedure)}
                      {boldKeyCapitalizedValueGrayBackground('WCC', encounter?.consultant_name)}
                    </Col>
                    <Col span={12}>
                      {boldKeyCapitalizedValue('Location', `${encounter?.wound_position && encounter?.wound_side ? encounter?.wound_side : ''} ${encounter?.wound_position ||
                        ''} ${encounter?.location || ''}`)}
                      {boldKeyCapitalizedValueGrayBackground('Status', encounter?.status)}
                      {boldKeyCapitalizedValue('Classification', encounter?.wound_type)}
                      {boldKeyCapitalizedValueGrayBackground('Stage', encounter?.stage)}
                      {boldKeyCapitalizedValue('Diagnosis', encounter?.diagnosis)}
                      {encounter?.procedure.includes('Biologics') &&
                        boldKeyCapitalizedValue('Treatment Number', encounter?.treatment_number)}
                      {/* {/* {boldKeyCapitalizedValue('Exudate', encounter?.exudate)} */}
                      {/* {boldKeyCapitalizedValue('Office', encounter?.exudate_type)} */}
                    </Col>
                  </Row>
                </div>
                <div className="card-box">
                  <Subtitle1>Dimensions</Subtitle1>
                  <Row gutter={8} style={{ width: '100%' }}>
                    <Col span={12}>
                      {boldKeyCapitalizedValue('Length', `${parseFloat(encounter?.length).toFixed(2)}cm`)}
                      {boldKeyCapitalizedValueGrayBackground('Width', `${parseFloat(encounter?.width).toFixed(2)}cm`)}
                      {boldKeyCapitalizedValue('Depth', `${parseFloat(encounter?.depth).toFixed(2)}cm`)}
                    </Col>
                    <Col span={12}>
                      {boldKeyCapitalizedValue('Area', `${parseFloat(encounter?.area).toFixed(2)}cm`, '2')}
                      {boldKeyCapitalizedValueGrayBackground('Volume', `${parseFloat(encounter?.volume).toFixed(2)}cm`, '3')}
                      {boldKeyCapitalizedValue('Wound size', encounter?.size)}
                      {(encounter?.procedure === 'Wound Debridement' ||
                        encounter?.procedure.includes('Biologics')) && (
                          <div style={{ marginTop: '20px' }}>
                            <Subtitle1>Post Debridement Dimensions</Subtitle1>
                            {boldKeyCapitalizedValue('Length', `${parseFloat(encounter?.new_length).toFixed(2)}cm`)}
                            {boldKeyCapitalizedValueGrayBackground('Width', `${parseFloat(encounter?.new_width).toFixed(2)}cm`)}
                            {boldKeyCapitalizedValue('Depth', `${parseFloat(encounter?.new_depth).toFixed(2)}cm`)}
                          </div>
                        )}
                    </Col>
                  </Row>
                </div>
                <div className="card-box">
                  <Headline6>Summary</Headline6>
                  <div className='w-full'>
                    <Subtitle1 style={{ textAlign: 'left' }}>Patient Information</Subtitle1>
                    <div className="rounded-md w-full p-2 my-2 mb-4" style={{ background: '#ebeff4' }}>
                      <div className="flex flex-row w-full mt-2 justify-between items-center">
                        <div className="flex flex-row items-center justify-between w-full">
                          <div className='flex flex-row items-center'>
                            <Avatar className='mr-2' size="md">{encounter?.patient?.name?.charAt(0)} </Avatar>
                            <Subtitle1 className='ml-2 text-bold mt-8' color='black'>{encounter?.patient?.name}</Subtitle1>
                          </div>
                          <div className='flex flex-row cursor-pointer items-center' onClick={() => viewPatient(encounter?.patient?.id)}>
                            <Subtitle1 className='mr-2 text-bold text-sm' color='black'>View Patient</Subtitle1>
                            <FontAwesomeIcon className='ml-2' size='sm' color="black" icon={['fas', 'chevron-right']} />
                          </div>
                        </div>
                      </div>
                      <div className='w-full'>
                        {/* <Subtitle1 className='ml-2 text-bold text-sm' color='black'>Address: {encounter?.patient?.address_1}</Subtitle1> */}
                        {keyValItemSimple('Address:', encounter?.patient?.address_1 || 'N/A')}
                        {keyValItemSimple('Address 2:', encounter?.patient?.address_2 || 'N/A')}
                        {keyValItemSimple('Status:', getPatientStatus(encounter?.patient?.status))}
                        {keyValItemSimple('Billing Entity:',
                          updatingStatus ? <Spin size={"small"} spinning={true} indicator={<LoadingOutlined style={{ color: 'grey' }} />} /> :
                            isEditing ?
                              <Form className='flex-boy-row-space' onFinish={onEntityUpdateFinish} >
                                <AutoComplete
                                  options={allBillingEntities.map(item => ({ value: item.name, label: item.name }))}
                                  placeholder="Billing Entity"
                                  style={{ width: '100%' }}
                                  onChange={(value) => {
                                    const selectedOption = allBillingEntities.find(item => item.name === value);
                                    setBillingEntity(selectedOption);
                                  }}
                                />
                                <Button type="primary" htmlType="submit" size='small' onClick={updateAssessments}>Update</Button>
                              </Form>
                              : <div className='flex-boy-row'>
                                <p className='text-sm'>{assessment?.billingEntity !== undefined ? assessment?.billingEntity?.name : billingEntity?.name}</p>
                                <Button icon={<EditOutlined />} size='small' className='ml-4' onClick={() => setIsEditing(true)} />
                              </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='w-full'>
                    <Subtitle1 style={{ textAlign: 'left' }}>Office Information</Subtitle1>
                    <div className="rounded-md w-full p-2 my-2 mb-4" style={{ background: '#ebeff4' }}>
                      <div className="flex flex-row w-full mt-2 justify-between items-center">
                        <div className="flex flex-row items-center justify-between w-full">
                          <div className='flex flex-row items-center'>
                            <Avatar className='mr-2' size="md">
                              <FontAwesomeIcon size="lg" color="grey" icon={['fas', 'hospital']} />
                            </Avatar>
                            <Subtitle1 className='ml-2 text-bold mt-8' color='black'>{encounter?.facility?.name}</Subtitle1>
                          </div>
                          <div className='flex flex-row cursor-pointer items-center' onClick={() => viewFacility(encounter?.facility?.id)}>
                            <Subtitle1 className='mr-2 text-bold text-sm' color='black'>View Office</Subtitle1>
                            <FontAwesomeIcon className='ml-2' size='sm' color="black" icon={['fas', 'chevron-right']} />
                          </div>
                        </div>
                      </div>
                      <div className='w-full'>
                        {/* <Subtitle1 className='ml-2 text-bold text-sm' color='black'>Address: {encounter?.patient?.address_1}</Subtitle1> */}
                        {boldKeyCapitalizedValue('Address:', encounter?.facility?.address || 'N/A')}

                      </div>
                    </div>
                  </div>
                  <div className='w-full'>
                    <Subtitle1 style={{ textAlign: 'left' }}>Visit Addendum</Subtitle1>
                    <Body2 style={{ textAlign: 'left' }} color="black">{encounter?.visit_summary || 'N/A'} </Body2>
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-6">
                <div className="card-box">
                  <Headline6>Location and Image</Headline6>
                  <div className="flex">
                    {encounter?.woundLocationPath ? (
                      <Image
                        src={encounter?.woundLocationPath ? encounter?.woundLocationPath.url : woundLocations.find((item) => item === encounter?.location) ? woundLocations.find((item) => item === encounter?.location).image : require('../../../assets/woundLoc.png')}
                        height={150}
                      />
                    ) : null}
                    <Image
                      style={{ width: '150px' }}
                      src={encounter?.woundImage.url}
                      height={150}
                    />
                  </div>
                </div>
                <div className="card-box">
                  <Headline6>Wound Details</Headline6>
                  <Row gutter={8} style={{ width: '100%' }}>
                    <Col span={12}>
                      {/* {boldKeyCapitalizedValue('Wound side', encounter?.wound_side)} */}
                      {boldKeyCapitalizedValue('Wound Stage', encounter?.stage)}
                      {boldKeyCapitalizedValueGrayBackground('Wound Bed', encounter?.wound_bed)}
                      {boldKeyCapitalizedValue('Granulation Tissue', `${parseFloat(encounter?.granulation_tissue).toFixed(2)}`)}
                      {boldKeyCapitalizedValueGrayBackground('Necrotic Tissue', encounter?.necrotic_tissue)}
                      {boldKeyCapitalizedValue('FibrousTissue', encounter?.fibrous_tissue)}
                      {/* {boldKeyCapitalizedValueGrayBackground('Slough Tissue', encounter?.slough_tissue)}
                      {boldKeyCapitalizedValue('Eschar Tissue', encounter?.eschar_tissue)} */}
                      {boldKeyCapitalizedValueGrayBackground('Exudate Amount', encounter?.exudate_amount)}
                      {boldKeyCapitalizedValue('Exudate Type', encounter?.exudate_type)}
                      {boldKeyCapitalizedValueGrayBackground('Periwound Color', encounter?.periwound_color)}
                      {!encounter?.procedure.includes('Biologics') && boldKeyCapitalizedValue('Wound Condition', encounter?.wound_condition)}
                      {boldKeyCapitalizedValue('Wound Duration', encounter?.wound_duration)}
                      {boldKeyCapitalizedValueGrayBackground('Conservative Treatment', encounter?.conservative_treatment?.toString())}
                      {(encounter?.procedure === 'DME' || encounter?.procedure.includes("Lab")) && boldKeyCapitalizedValue('Response to Therapy', encounter?.response_to_therapy)}

                    </Col>
                    <Col span={12}>
                      {boldKeyCapitalizedValue('Wound edges', encounter?.wound_edges)}
                      {encounter?.procedure === 'Wound Debridement' && boldKeyCapitalizedValue('Wound Dressing', encounter?.wound_dressing)}
                      {boldKeyCapitalizedValueGrayBackground('Undermining', encounter?.undermining)}
                      {boldKeyCapitalizedValue('Epithelialization', encounter?.epithelialization)}
                      {boldKeyCapitalizedValueGrayBackground('Tunneling', encounter?.tunneling_size && encounter?.tunneling_direction !== 'N/A' ? `${encounter?.tunneling_size}cm at ${encounter?.tunneling_direction}` : 'N/A')}
                      {boldKeyCapitalizedValue('Sinus Tract', encounter?.sinus_tract ? `${encounter?.sinus_tract} cm ` : 'N/A')}
                      {boldKeyCapitalizedValueGrayBackground('Odor', encounter?.odor)}
                      {boldKeyCapitalizedValue('Infection', encounter?.infection)}
                      {boldKeyCapitalizedValueGrayBackground('Pain Level', encounter?.pain_level)}
                      {boldKeyCapitalizedValue('Exposed Structures', Array.isArray(encounter?.exposed_structures) ? encounter?.exposed_structures.join(', ') : encounter?.exposed_structures)}
                      {encounter?.procedure === 'Wound Debridement' && boldKeyCapitalizedValueGrayBackground('Debridement Type', encounter?.debridement_type)}
                      {boldKeyCapitalizedValue('Orders/Recommendations', encounter?.other_interventions?.toString())}
                      {boldKeyCapitalizedValueGrayBackground('Other Related Factors', encounter?.other_related_factors?.toString() || 'N/A')}

                      {(encounter?.procedure === 'Wound Debridement' || encounter?.procedure === 'Biologics Application') && boldKeyCapitalizedValue('Anesthesia Used', encounter?.anesthesia_used)}
                    </Col>

                    {encounter?.procedure === 'Wound Debridement' && (
                      <>
                        <Col span={12}>
                          <Headline6 style={{ marginBottom: '20px', marginTop: '20px' }}> Vascular Measurements </Headline6>
                          <div>
                            {boldKeyCapitalizedValue('Right DP', encounter?.right_d_p)}
                            {boldKeyCapitalizedValueGrayBackground('Left DP', encounter?.left_d_p)}
                            {boldKeyCapitalizedValue('Right PT', encounter?.right_p_t)}
                            {boldKeyCapitalizedValueGrayBackground('Left PT', encounter?.left_p_t)}
                            {boldKeyCapitalizedValue('Skin Temperature Right', encounter?.skin_temperature_right)}
                            {boldKeyCapitalizedValueGrayBackground('Skin Temperature Left', encounter?.skin_temperature_right)}
                            {boldKeyCapitalizedValue('Digital Hair Right', encounter?.digital_hair_right)}
                            {boldKeyCapitalizedValueGrayBackground('Digital Hair Left', encounter?.digital_hair_left)}
                          </div>
                        </Col>

                        <Col span={12}>
                          <Headline6 style={{ marginBottom: '20px', marginTop: '20px' }}> Neurology Measurements </Headline6>
                          <div>
                            {boldKeyCapitalizedValue('Monofilament Testing Right', encounter?.monofilament_testing_right)}
                            {boldKeyCapitalizedValueGrayBackground('Monofilament Testing Left', encounter?.monofilament_testing_left)}
                            {boldKeyCapitalizedValue('Pin Prick Right', encounter?.pin_prick_right)}
                            {boldKeyCapitalizedValueGrayBackground('Pin Prick Left', encounter?.pin_prick_left)}
                            {boldKeyCapitalizedValue('Soft Touch Right', encounter?.soft_touch_right)}
                            {boldKeyCapitalizedValueGrayBackground('Soft Touch Left', encounter?.soft_touch_left)}
                          </div>
                        </Col>
                      </>
                    )}
                    {encounter?.procedure.includes('Biologics') && (
                      <>
                        <Col span={24}>
                          <Subtitle1 style={{ marginBottom: '20px', marginTop: '20px', textAlign: 'left' }}> Assessment Observations </Subtitle1>
                          <div>
                            {encounter?.treatment_number !==
                              'Pre-treatment Assessment - Upper Extremity' && boldKeyCapitalizedValue('Circulation', encounter?.circulation)}
                            {boldKeyCapitalizedValueGrayBackground('Comorbidites', encounter?.comorbidities)}
                            {boldKeyCapitalizedValue('Primary Care Physician', encounter?.primary_care_physician)}
                            {boldKeyCapitalizedValueGrayBackground('Conservative Treatment', encounter?.conservative_treatment?.toString())}
                            {encounter?.treatment_number !== 'Pre-treatment Assessment - Upper Extremity' && boldKeyCapitalizedValue('Neurologic Status', encounter?.neurologic_status)}
                            {boldKeyCapitalizedValueGrayBackground('Wound Duration', encounter?.wound_duration)}
                          </div>
                        </Col>
                      </>
                    )}

                    <Col span={12}>
                      {(encounter?.procedure === 'Wound Debridement' || encounter?.procedure.includes('Biologics')) && <Subtitle1
                        style={{ marginBottom: '20px', marginTop: '20px', textAlign: 'left' }}
                      >
                        Wound Debridement</Subtitle1>}
                      {(encounter?.procedure === 'Wound Debridement' || encounter?.procedure.includes('Biologics')) &&
                        boldKeyCapitalizedValue('Debridement Methods', encounter?.debridement_methods?.toString())}
                      {(encounter?.procedure === 'Wound Debridement' || encounter?.procedure.includes('Biologics')) &&
                        boldKeyCapitalizedValueGrayBackground('Tissue Debrided', encounter?.tissue_debrided?.toString())}
                      {(encounter?.procedure === 'Wound Debridement' || encounter?.procedure.includes('Biologics')) &&
                        boldKeyCapitalizedValue('Instrument Used', encounter?.instruments_used?.toString())}
                      {(encounter?.procedure === 'Wound Debridement' || encounter?.procedure.includes('Biologics')) &&
                        boldKeyCapitalizedValueGrayBackground('Level of Debridement', encounter?.level_of_debridement?.toString())}
                      {(encounter?.procedure === 'Wound Debridement' || encounter?.procedure.includes('Biologics')) &&
                        boldKeyCapitalizedValue('Hemostasis', encounter?.hemostasis?.toString())}
                      {encounter?.procedure === 'Wound Debridement' &&
                        boldKeyCapitalizedValueGrayBackground(' Wound Cleanser', encounter?.wound_cleanser?.toString())}
                    </Col>
                  </Row>

                </div>
                {(assessment?.procedure.includes('Biologics') ||
                  assessment?.procedure === 'DME') && <div className="card-box">
                    <Headline6>Assessment Kit</Headline6>
                    {assessment?.procedure.includes('Biologics') ||
                      assessment?.procedure === 'DME'
                      ? (
                        <>
                          <div className='w-full'>
                            <div className="visit-card">

                              {assessment?.procedure.includes('Biologics') ? (
                                <>
                                  <Subtitle1>Biologics</Subtitle1>
                                  {(() => {
                                    if (assessment?.biologic_kits && !!assessment?.biologic_kits[0]) {
                                      let productNames = [];
                                      let assessmentKits_ = [];
                                      for (const bioKit of assessment?.biologic_kits) {
                                        let name = `${bioKit.biologic_product.company_name} ${bioKit.biologic_product.product_name}`;
                                        if (!productNames.includes(name)) {
                                          let productKits = assessment?.biologic_kits.filter((item) => `${item.biologic_product.company_name} ${item.biologic_product.product_name}` === name);
                                          let biologic_kits = productKits.map(
                                            (item) => ({
                                              id: item.id, size: item.size, quantity: item.quantity, biologic_kit_code: item.biologic_kit_code,
                                              ...(!!item.tissue_id && { tissue_id: item.tissue_id }),
                                              ...(!!item.serial_number && { serial_number: item.serial_number }),
                                              ...(!!item.ocr_data && !!item.ocr_data[0] && { ocr_data: [...item.ocr_data] })
                                            })
                                          );
                                          assessmentKits_ = [...assessmentKits_, { product: name, biologic_kits }];
                                          productNames.push(name);
                                        }
                                      }
                                      return assessmentKits_.map((bioKit) => {
                                        return (
                                          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, width: '100%' }}>
                                            <Typography variant={'body2'} style={{ fontWeight: 600, textAlign: "left" }}>{bioKit.product}:{' '}</Typography>
                                            {bioKit?.biologic_kits.map((item, i) => {
                                              // console.log('ocr:  ', item)
                                              // console.log('!item.ocr_data:  ', !item.ocr_data)
                                              // <Typography key={`${i}`} variant={"body2"}>{`${item.biologic_kit_code}`}:&nbsp;     &nbsp;{`${item.size}`}&nbsp;     &nbsp;{`Quantity: ${item.quantity}`}</Typography>
                                              return (
                                                <Row
                                                  gutter={[4, 1]}
                                                  align={'stretch'}
                                                  justify={'start'}
                                                  style={{
                                                    width: '100%',
                                                    overflowWrap: 'break-word'
                                                  }}
                                                >
                                                  <Col
                                                    xs={24}
                                                    sm={24}
                                                    md={8}
                                                    lg={8}
                                                    xl={8}
                                                    flex={'auto'}
                                                    style={{ width: '100%' }}
                                                  >
                                                    <Typography
                                                      style={{ marginTop: 6 }}
                                                      key={`${i}`}
                                                      variant={'body2'}
                                                    >
                                                      {`${item.biologic_kit_code}`}:
                                                    </Typography>
                                                  </Col>
                                                  <Col
                                                    xs={24}
                                                    sm={24}
                                                    md={16}
                                                    lg={16}
                                                    xl={16}
                                                    flex={'auto'}
                                                    style={{ width: '100%' }}
                                                  >
                                                    <div style={{ width: '100%' }}>
                                                      {boldKeyCapitalizedValue(
                                                        'Size:',
                                                        `${item.size}`
                                                      )}
                                                      {boldKeyCapitalizedValue(
                                                        'Quantity:',
                                                        `${item.quantity}`
                                                      )}
                                                      {assessment?.procedure.includes(
                                                        'Application'
                                                      ) &&
                                                        !item.ocr_data &&
                                                        !!item.tissue_id &&
                                                        boldKeyCapitalizedValue(
                                                          'Tissue ID:',
                                                          `${item.tissue_id || 'N/A'}`
                                                        )}
                                                      {assessment?.procedure.includes(
                                                        'Application'
                                                      ) &&
                                                        !item.ocr_data &&
                                                        !!item.serial_number &&
                                                        boldKeyCapitalizedValue(
                                                          'Serial No:',
                                                          `${item.serial_number ||
                                                          'N/A'}`
                                                        )}
                                                      {assessment?.procedure.includes(
                                                        'Application'
                                                      ) &&
                                                        !!item.ocr_data &&
                                                        item.ocr_data.map((ocr) => {
                                                          return (
                                                            <>
                                                              {boldKeyCapitalizedValue(
                                                                'Serial No:',
                                                                `${ocr.serial_number ||
                                                                'N/A'}`
                                                              )}
                                                              {boldKeyCapitalizedValue(
                                                                'Tissue ID:',
                                                                `${ocr.tissue_id ||
                                                                'N/A'}`
                                                              )}
                                                              <Divider />
                                                            </>
                                                          );
                                                        })}
                                                    </div>
                                                  </Col>
                                                  <Divider style={{ margin: 4 }} />
                                                </Row>
                                              );
                                            })}
                                          </div>
                                        );
                                      });
                                    } else return 'N/A';
                                  })()}
                                </>
                              ) : (
                                <>
                                  <Subtitle1>
                                    Scenario:{' '}
                                    {assessment?.recommended_treatment_protocol
                                      ? assessment?.recommended_treatment_protocol
                                      : null}
                                  </Subtitle1>
                                  <Subtitle1 color="grey">Primary Dressing</Subtitle1>
                                  {assessment?.treatment_order_items
                                    .filter((item) => item.type === 'Primary Dressing')
                                    .map((item, i) => (
                                      <div
                                        className="flex-boy-row-space"
                                        style={{ width: '100%' }}
                                        key={`${i}`}
                                      >
                                        <Body2
                                          color="black"
                                          style={{ textAlign: 'left', flex: 1, lineHeight: '21px' }}
                                        >
                                          {item.name} ({item.pack_amount} {item.unit_of_measure})
                                        </Body2>
                                        <Body2 color="black" style={{ textAlign: 'left', lineHeight: '21px', flex: 'initial' }}>
                                          {item.unit_count}{' '}
                                          {item.unit_of_measure === 'box' ? 'boxe' : item.unit_of_measure === 'oz' ? 'oz' : item.unit_of_measure} s
                                        </Body2>
                                      </div>
                                    ))}
                                  <Subtitle1 color="grey">Secondary Dressing</Subtitle1>
                                  {assessment?.treatment_order_items.filter((item) => item.type === 'Secondary Dressing').map((item, i) => (
                                    <div
                                      className="flex-boy-row-space"
                                      style={{ width: '100%' }}
                                      key={`${i}`}
                                    >
                                      <Body2
                                        color="black"
                                        style={{
                                          textAlign: 'left',
                                          flex: 1,
                                          lineHeight: '21px'
                                        }}
                                      >
                                        {item.name} ({item.pack_amount} {item.unit_of_measure})
                                      </Body2>
                                      <Body2
                                        color="black"
                                        style={{
                                          textAlign: 'left',
                                          lineHeight: '21px',
                                          flex: 'initial'
                                        }}
                                      >
                                        {item.unit_count}{' '}
                                        {item.unit_of_measure === 'Box'
                                          ? 'Boxe' :
                                          item.unit_of_measure === 'Bottle(s)'
                                            ? 'Bottle'
                                            : item.unit_of_measure === 'Oz'
                                              ? 'oz'
                                              : item.unit_of_measure}
                                        s
                                      </Body2>
                                    </div>
                                  ))}
                                  <Subtitle1 color="grey">Additional Supplies</Subtitle1>
                                  {assessment?.treatment_order_items.filter((item) => item.type === 'Additional Dressing').map((item, i) => (
                                    <div
                                      className="flex-boy-row-space"
                                      style={{ width: '100%' }}
                                      key={`${i}`}
                                    >
                                      <Body2
                                        color="black"
                                        style={{
                                          textAlign: 'left',
                                          flex: 1,
                                          lineHeight: '21px'
                                        }}
                                      >
                                        {item.name} ({item.pack_amount} {item.unit_of_measure})
                                      </Body2>
                                      <Body2
                                        color="black"
                                        style={{
                                          textAlign: 'left',
                                          lineHeight: '21px',
                                          flex: 'initial'
                                        }}
                                      >
                                        {item.unit_count}{' '}
                                        {item.unit_of_measure === 'Box'
                                          ? 'Boxe' :
                                          item.unit_of_measure === 'Bottle(s)'
                                            ? 'Bottle'
                                            : item.unit_of_measure === 'Oz'
                                              ? 'oz'
                                              : item.unit_of_measure}
                                        s
                                      </Body2>
                                    </div>
                                  ))}
                                </>
                              )}
                            </div>
                          </div>

                          <Divider />
                        </>
                      ) : null}
                  </div>}
              </div>
            </div>

          </div>

        </div>
      </>
      }
    </div>
  );
}

const mapStateToProps = (state) => ({

  encounter: state.billing.selectedEncounter,
  masterListRaw: state.masters.masterListRaw, activeUser: state.auth.activeUser
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EncounterPage)