 import { useState, useEffect } from 'react';
import { Empty, Modal, Skeleton } from 'antd';
import {
  addButton,
  dialogCloser,
  formSubmitButton,
  horStack,
  modalClose,
  outlinedButton,
} from '../../../common/helpers';
import { Headline6 } from '../../../components';
import NewNote from './NewNotes';
import NoteCard from './NoteCard';

export default function FacilityNotes({ facility, notes, loadingNotes }) {
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [editNoteModal, setEditNoteModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [isExecuting, setisExecuting] = useState(false);
  function handleAdd() {
    setAddNoteModal(true);
  }

  return (
    (
      <div className='shadow-card-box'>
        <Modal
          title='Add Note'
          open={addNoteModal}
          onOk={dialogCloser(setAddNoteModal)}
          onCancel={dialogCloser(setAddNoteModal)}
          destroyOnClose
          closeIcon={modalClose(dialogCloser(setAddNoteModal))}
          maskClosable={false}
          footer={[
            <div
              className='entity-filters flex-boy-row'
              style={{ justifyContent: 'flex-end' }}
            >
              {horStack([
                outlinedButton(
                  dialogCloser(setAddNoteModal),
                  'Cancel',
                  isExecuting
                ),
                formSubmitButton('addNoteForm', 'Add', isExecuting, 'Saving'),
              ])}
            </div>,
          ]}
        >
          <NewNote
            setisExecuting={setisExecuting}
            closeModal={dialogCloser(setAddNoteModal)}
            facility={facility}
          />
        </Modal>
        <Modal
          title='Edit Note'
          open={editNoteModal}
          onOk={dialogCloser(setEditNoteModal)}
          onCancel={dialogCloser(setEditNoteModal)}
          destroyOnClose
          closeIcon={modalClose(dialogCloser(setEditNoteModal))}
          maskClosable={false}
          footer={[
            <div
              className='entity-filters flex-boy-row'
              style={{ justifyContent: 'flex-end' }}
            >
              {horStack([
                outlinedButton(
                  dialogCloser(setEditNoteModal),
                  'Cancel',
                  isExecuting
                ),
                formSubmitButton('addNoteForm', 'Update', isExecuting, 'Saving'),
              ])}
            </div>,
          ]}
        >
          <NewNote
            note={selectedNote}
            setisExecuting={setisExecuting}
            closeModal={dialogCloser(setEditNoteModal)}
            facility={facility}
          />
        </Modal>
        <div className='flex-boy-row-space' style={{ width: '100%' }}>
          <Headline6>Notes</Headline6>
          {addButton(handleAdd)}
        </div>
        {notes?.length > 0 ? (
          <div style={{ width: '100%' }}>
            {notes?.map((note) => (
              <div
                className='flex-boy-column'
                style={{ width: '100%', padding: '10px' }}
              >
                {loadingNotes ? (
                  <Skeleton rows={2} paragraph />
                ) : (
                  <NoteCard
                    note={note}
                    setSelectedNote={setSelectedNote}
                    setEditNoteModal={setEditNoteModal}
                  />
                )}
              </div>
            ))}
          </div>
        ) : (
         <div style={{marginLeft: '40%'}}>
           <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No Notes' />
          </div>
        )}
      </div>
   )
  );
}
