import { useState, useEffect } from "react";
import { Segmented, Divider, Spin } from "antd";
import { Headline6, Subtitle1 } from "../../../components";
import TinyTable from "./TinyTable";
import { emptyFun } from "../../../common/helpers";
import LocationDetails from "./LocationDetails";
import { apiRequest } from "../../../app/Apis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LeftOutlined } from "@ant-design/icons";

const PatientLocation = ({ region }) => {
  const [option, setOption] = useState("false")
  const [patients, setPatients] = useState([])
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0)
  const [navParams, setNavParams] = useState(null)
  const [patient, setPatient] = useState(null)
  const [loadingPatient, setLoadingPatient] = useState(false)
  const [total, setTotal] = useState(null)
  const defaultOptions = [
    { label: 'By Region', value: "region" },
    { label: 'By State', value: "state" }
  ];

  function onChangeLocation(option) {
    setOption(option)
  }

  async function fetchPatients(option) {
    setLoading(true)
    let reg = ""

    if(!!region && region !== '' && region !== undefined) {
      reg =`&region=${region}`
    }
    let queryString = option === "state" ? `?group_by=state` : "?group_by=region" // this will be used to filter by state or region, by default the endpoint brings by region
    const response = await apiRequest.get(`/patient_stats/location${queryString}${reg}`)
    const res = response.data.data
    let newRes = res.filter((obj) => obj.label !== null && obj.label !== " Region")
    setPatients(newRes)
    setLoading(false)
  }

  async function fetchOnePatient(value) {
    setLoadingPatient(true)
    let str = value?.toLowerCase()
    let queryString = option === "state" ? `?state=${str}` : `?region=${str}` // this will be used to filter by state or region, by default the endpoint brings by region
    const response = await apiRequest.get(`/patients_stats/${queryString}`)
    const res = response.data.data
    let newRes = res.filter((obj) => obj.label !== null && obj.label !== " Region")
    console.log("Fetch One Patient Data:::", res)
    setPatient(newRes)
    setLoadingPatient(false)
  }

  useEffect(() => {
    fetchPatients("region")
    return emptyFun
  }, [])

  useEffect(() => {
    fetchPatients('region')
  }, [region])

  useEffect(() => {
    if (patient) {
      let tot = patient?.reduce((acc, curr) => acc + curr.count, 0)
      setTotal(tot)
    }
  }, [patient])

  useEffect(() => {
    fetchPatients(option)
    return emptyFun
  }, [option])

  useEffect(() => {
    if (navParams !== null && step === 1) {
      fetchOnePatient(navParams?.label)
    }
    return emptyFun
  }, [step, navParams])

  return (
    <div className="stats-card-box">
      {step === 0 ?
        <div style={{ width: '100%', padding: '5px' }}>
          <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>Patients by Location</Headline6>
        </div> :
        <div className='flex-boy-row-space' style={{ width: '100%' }}>
          <div className="flex-boy-row" style={{ cursor: 'pointer' }}>
            <LeftOutlined style={{ marginRight: 30, marginTop: 10 }} onClick={() => setStep(0)} />
            <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>{navParams?.label || 'Unknown'}</Headline6>
          </div>
          <FontAwesomeIcon color="grey" icon={['fas', 'poll']} />
        </div>
      }
      <Divider style={{ margin: '4px', color: '#E2E4EA', width: '100%' }} />
      {step === 0 ? <div className="w-full">
        <div className="flex w-full flex-row items-center justify-center">
          <Segmented className={'justify-items-center'} options={defaultOptions} onChange={(value) => onChangeLocation(value)} />
        </div>
        <div>
          <Spin spinning={loading}>
            <TinyTable patients={patients} type="patients" setStep={setStep} setNavParams={setNavParams} />
          </Spin>
        </div>
      </div> : <div className="group">
        <Spin spinning={loadingPatient}>
          <div style={{ width: '100%', padding: '15px' }}>
            <Headline6 style={{ fontWeight: 'bold', fontSize: '16px' }}>Total Patients</Headline6>
            <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>{total}</Headline6>
          </div>
          {patient && <LocationDetails option={option} setStep={setStep} type='patients' data={patient} />}
        </Spin>
      </div>}
    </div>
  )
};
export default PatientLocation

