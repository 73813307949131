import React, {useEffect, useState} from 'react';
import {Headline6} from '../../../components';
import {
  containedButton,
  dialogCloser,
  emptyFun,
  emptyInsurance,
  formSubmitButton,
  horStack,
  kebabMenus,
  boldKeyCapitalizedValue,
  boldKeyCapitalizedValueGrayBackground,
  modalClose,
  openDialog,
  outlinedButton,
  skelBars
} from '../../../common/helpers';
import {Divider, Modal} from 'antd';
import {connect, useDispatch} from 'react-redux';
import {setInsuranceLoaded, setInsuranceLoading} from '../../../app/Reducers';
import {deleteInsurance, fetchPatientInsurance} from '../../../app/Reducers';
import InsuranceForm from './patientSummaryForms/InsuranceForm';
import KebabMenu from "../../../components/KebabMenu";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import dayjs from "dayjs";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';

const PatientInsuranceInfo = ({ navs, insurances,  patient, activeUser}) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [isExecuting, setisExecuting] = useState(false);
  const [insuranceForm, setInsuranceForm] = useState(false);
  const [selected, setSelected] = useState(null);
  const [editingForm, setEditingForm] = useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [insurance, setInsurance] = useState(null);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const {message} = useSubscription(`view_patient_insurance_policy/${patientId}`, {qos: 2, nl: true});
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  const can_create_patient_insurance_policy = usePermissionCheck('create_patient_insurance_policy', false, [])
  const can_update_patient_insurance_policy = usePermissionCheck('update_patient_insurance_policy', false, [])
  const can_delete_patient_insurance_policy = usePermissionCheck('delete_patient_insurance_policy', false, [])
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message);
        await loadInsuranceInfo(patientId, false);
      })().catch(e => console.log(e));
    }
    return emptyFun;
  }, [message]);
  const showPopconfirm = (insurance) => setSelected(insurance.id);
  const handleCancel = () => setSelected(null);
  const handleOk = async (id) => {
    await setConfirmLoading(true);
    let payload = {patient_id: patientId, insurance_id: id};
    await dispatch(deleteInsurance(payload));
    // appMessage('Feature coming soon', 'info')
    await setSelected(null);
    await setConfirmLoading(false);
  };
  
  const actionMenus = kebabMenus(( can_update_patient_insurance_policy && !patient.deleted_at), can_view_audit_logs, can_delete_patient_insurance_policy)
  
  async function kebabMenuClick(e, insurance) {
    await setInsurance(insurance);
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true);
        await openDialog(setInsuranceForm);
        break;
      case 'Delete':
        await setEditingForm(false);
        showPopconfirm(insurance);
        break;
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
        await setEditingForm(false);
    }
  }
  
  useEffect(() => {
    (async () => {
      // if (navs.insuranceLoaded !== patientId && !navs.insuranceLoading) {
      await loadInsuranceInfo(patientId);
      console.log('INSURANCE LOADED', insurances);
      // }
    })().catch(e => console.log(e));
    return emptyFun;
  }, []);
  const loadInsuranceInfo = async (patientId, isMQMessage) => {
    /*if (!!insurances[0] && !isMQMessage && insurances.every(item => item?.patient_id === patientId)) {
      return
    }*/
    await dispatch(setInsuranceLoading(!isMQMessage));
    await dispatch(fetchPatientInsurance(patientId));
    await dispatch(setInsuranceLoading(false));
    await dispatch(setInsuranceLoaded(patientId));
  };
  return (
    (<div className="mt-2">
      {(!!insurance && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false} resourceType={'Patient Insurance'}
                                                           resourceDescription={`${patient.name}'s insurance info`}
                                                           defaultValues={{auditableId: insurance?.id, auditable: 'patient_insurance', format: 'json'}}/>}
      <Modal
        destroyOnClose={true}
        title={
          editingForm
            ? 'Edit Insurance Information'
            : 'Add Insurance Information'
        }
        open={insuranceForm}
        onOk={dialogCloser(setInsuranceForm)}
        onCancel={dialogCloser(setInsuranceForm)}
        closeIcon={modalClose(dialogCloser(setInsuranceForm))}
        maskClosable={false}
        width={750}
        footer={[
          <div className="flex flex-row p-4 mt-4 justify-center">
            {horStack([
              outlinedButton(dialogCloser(setInsuranceForm), 'Cancel', isExecuting),
              formSubmitButton('insuranceForm', editingForm ? 'Update' : 'Save', isExecuting, editingForm ? 'Updating' : 'Saving')
            ])}
          </div>
        ]}
      >
        <InsuranceForm
          editingForm={editingForm}
          patient={patient}
          setisExecuting={setisExecuting}
          insurance={insurance}
          closeForm={dialogCloser(setInsuranceForm)}
          state={patient?.state}
        />
      </Modal>
      <div className="flex-boy-row-space" style={{width: '100%'}}>
        <Headline6>Insurance List</Headline6>
        {can_create_patient_insurance_policy && !patient?.deleted_at ?
          containedButton(() => {
            setEditingForm(false);
            setInsuranceForm(true);
          }, 'Add New', false, null, null, {icon: <PlusOutlined />})
          : null}
      </div>
      {navs.insuranceLoading ? (
        skelBars()
      ) : (
        <div className="card-box">
          {insurances.length ? insurances.map((item, index) => (
            <div key={index} style={{width: '100%'}}>
              <div className=" flex-boy-row-space" style={{width: '100%'}}>
                <Headline6 className="capitalize">{item.priority}</Headline6>
                {/*{can_update_patient_resource && (
                  <Dropdown
                    dropdownRender={()=>menu(item)}
                    trigger={['click']}
                    disabled={selected === item.id}
                  >
                    {appConfirm(
                      <MoreOutlined />,
                      selected === item.id,
                      null,
                      'Are you sure of deleting this insurance?',
                      () => handleOk(item.id),
                      handleCancel,
                      confirmLoading
                    )}
                  </Dropdown>

                )}*/}
                <KebabMenu menus={actionMenus}
                           recordItem={item}
                           handleMenuClick={kebabMenuClick} resource={'insurance info'} handleOk={() => handleOk(item.id)} handleCancel={handleCancel}
                           confirmLoading={confirmLoading}
                           showConfirmDialog={selected === item.id}/>
                {/* <FontAwesomeIcon  color="grey" icon={["fas", "pencil-alt"]} /> */}
              </div>
              {boldKeyCapitalizedValue('Effective date:', item.effective_date ? dayjs(item.effective_date).format("MM/DD/YYYY") : 'Unknown')}
              {boldKeyCapitalizedValueGrayBackground('Insurance Provider:', item.insurance_provider || 'N/A')}
              {boldKeyCapitalizedValue('Plan:', item.plan || 'N/A')}
              {boldKeyCapitalizedValueGrayBackground('Phone/Fax:', `${item.phone || "N/A"} / ${item.fax ? item.fax : 'N/A'}`)}
              {boldKeyCapitalizedValue('Policy Number:', item.policy_number ?? 'N/A')}
              {boldKeyCapitalizedValueGrayBackground('Policy Holder:', item.policy_holder_name ?? 'N/A')}
              {boldKeyCapitalizedValue('Policy Relationship:', item.policy_holder_relationship ?? 'N/A')}
              {boldKeyCapitalizedValue('Guarantor Relationship:', item.guarantor_relationship ?? 'N/A')}
              {boldKeyCapitalizedValueGrayBackground('Guarantor Name:', item.guarantor_name ?? 'N/A')}
              {boldKeyCapitalizedValueGrayBackground('Priority:', item.priority)}
              {boldKeyCapitalizedValue('Copay:', parseFloat(item.copay_due).toFixed(2))}
              {boldKeyCapitalizedValueGrayBackground('Contact:', item.contact ?? 'N/A')}
              {boldKeyCapitalizedValue('Status:', item.status)}
         
              <Divider/>
            </div>
          )) : emptyInsurance()}
        </div>
      )}
    </div>)
  );
};
const mapStateToProps = (state) => ({
  insurances: state.patients.insurances,
  activeUser: state.auth?.activeUser,
  patient: state.patients.selectedPatient,
  navs: state.navs
});
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientInsuranceInfo);
