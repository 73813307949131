import React, { useCallback, useState , useRef} from 'react';
import { logChatPromiseExecution } from 'stream-chat';
import { ActionsIcon, defaultPinPermissions, MessageInput, MessageList, Thread, useChannelActionContext, useChannelStateContext, useChatContext, Window, } from 'stream-chat-react';
import { PinnedMessageList } from '../PinnedMessageList/PinnedMessageList';
import { TeamChannelHeader } from '../TeamChannelHeader/TeamChannelHeader';
import { ThreadMessageInput } from '../TeamMessageInput/ThreadMessageInput';
import { useGiphyInMessageContext } from '../../context/GiphyInMessageFlagContext';
import Video from '../../../Chat/Components/Video';
import { Modal } from 'antd';
import { setChannelName } from '../../../../app/Reducers';
import { apiRequest } from '../../../../app/Apis';
import VideoCallui from '../../../Chat/Components/Video/videoCallui';
import { useClient } from '../../../Chat/Components/Video/Settings';
import { ResizableBox } from 'react-resizable';


import _ from 'lodash';
import Draggable from 'react-draggable';
export const ChannelInner = () => {
    const { inputHasGiphyMessage, clearGiphyFlag } = useGiphyInMessageContext();
    const { client } = useChatContext();
    const { channel, watcher_count } = useChannelStateContext();
    const { sendMessage } = useChannelActionContext();
    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
      });
    




    const teamPermissions = { ...defaultPinPermissions.team, user: true };
    const messagingPermissions = {
        ...defaultPinPermissions.messaging,
        user: true,
    };
    const pinnedPermissions = {
        ...defaultPinPermissions,
        team: teamPermissions,
        messaging: messagingPermissions,
    };
    const overrideSubmitHandler = useCallback((message) => {
        let updatedMessage = {
            attachments: message.attachments,
            mentioned_users: message.mentioned_users,
            parent_id: message.parent?.id,
            parent: message.parent,
            text: message.text,
        };
        const isReply = !!updatedMessage.parent_id;
        if (inputHasGiphyMessage(isReply)) {
            const updatedText = `/giphy ${message.text}`;
            updatedMessage = { ...updatedMessage, text: updatedText };
        }
        if (sendMessage) {
            const sendMessagePromise = sendMessage(updatedMessage);
            logChatPromiseExecution(sendMessagePromise, 'send message');
            clearGiphyFlag(isReply);
        }
    }, [inputHasGiphyMessage, sendMessage, clearGiphyFlag]);

    const [visible, setVisible] = useState(false);

    const handleOk = () => {
        // Handle OK button click
        setVisible(false);
    };

    const handleCancel = () => {
        // Handle Cancel button click
        setVisible(false);
    };

    const callUser = async () => {
        const response = await apiRequest.post('/agora/call-link', {
            title: "Huddle Call",
            
        })
        if (response) {
            // open new window
           
            console.log(response)
            await setagoraToken(response.token)
            await setchannelName(response?.call?.agora?.channel)
            await setUid(response.agora_uid)
            // setVisible(true)

            //  send fcm message to recipient
            const call_response = await apiRequest.post(`/agora/chat-notify`, { 
                "users": _.compact([...Object.values(channel.state.members).map((item)=> {
                    if(item.user_id !== client.user.id){
                        console.log(item.user_id, client.user.id)
                        return item.user_id
                    }
                })]),
                "message": "You have been invited to a call",
                "payload": {
                    "status": "call_started",
                    "initiator": client.user.name,
                    "channel_name": channel.cid,
                    "call_link": response.data.data.attendee
                }
               
             }).catch(err => console.log(err))
            console.log(call_response)
            window.open(response.data.data.host, '_blank');

            // const messageResponse = await channel.sendMessage({
            //     text: `Incoming call from ${client.user.name} join now...`, call_type: "video", call: response.call, silent: true,
            // });
            // console.log(messageResponse)
        }

    }
    const [agoraToken, setagoraToken] = useState(null)
    const [channelName, setchannelName] = useState(null)
    const [uid, setUid] = useState(null)

    const Leave = async () => {
        agora_client.unpublish()
        await agora_client.leave();


    }

    const agora_client = useClient();
    // host data useState
    const [hostData, setHostData] = useState({
        "users": _.compact([...Object.values(channel.state.members).map((item)=> {
            if(item.user_id !== client.user.id){
                console.log(item.user_id, client.user.id)
                return item.user_id
            }
        })]),
        "initiator": client.user.name,
    })
    const draggleRef = useRef(null);

    const onStart = (_event, uiData) => {
      const { clientWidth, clientHeight } = window.document.documentElement;
      const targetRect = draggleRef.current?.getBoundingClientRect();
      if (!targetRect) {
        return;
      }
      setBounds({
        left: -targetRect.left + uiData.x,
        right: clientWidth - (targetRect.right - uiData.x),
        top: -targetRect.top + uiData.y,
        bottom: clientHeight - (targetRect.bottom - uiData.y),
      });
    };

    const [height, setHeight] = useState(700);
    const [width, setWidth] = useState(800);
    const onResize = (event, { size }) => {
        console.log(size)
        setHeight(size.height);
        setWidth(size.width);
      };
      const [disabled, setDisabled] = useState(false);
    return (<>
        <Window>
            <Modal
                visible={visible}
title={<div style={{
    width: "100%",
    cursor: "move"
  }}
  onMouseOver={() => {
    if (disabled) {
      setDisabled(false);
    }
  }}
  onMouseOut={() => {
    setDisabled(true);
  }} >
    
    <h2>Video Call</h2>
    </div>}
                okText="OK"
                cancelText="Cancel"
                onOk={handleOk}
                onCancel={handleCancel}
                
               destroyOnClose={true}
                mask={false}
                closable={false}
                maskClosable={false}
                bodyStyle={{
                    backgroundColor: '#1C1C24',
                    color: 'white'
                }}
                width={width + 30}
                afterClose={Leave}
                getContainer={() => document.getElementById('chat-container')}
                modalRender={(modal) => (
                    <Draggable
                        disabled={disabled}
                      bounds={bounds}
                      onStart={(event, uiData) => onStart(event, uiData)}
                    >
                      <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                  )}
            >
                 <ResizableBox
        width={width}
        height={height}
        resizeHandles={["sw", "nw", "se", "ne"]}
        minConstraints={[200, 200]}
        onResize={onResize}
        >
        <div  onMouseOver={() => {
            setDisabled(true);
          }} style={{ width: width, height: height }} className="p-2 bg-white">
                <Video isHost={true} hostData={hostData} uid={uid} token={agoraToken} tic={agoraToken} channelName={channelName} handleCancel={handleCancel} />
                </div>
</ResizableBox>

            </Modal>
            <TeamChannelHeader callUser={callUser} />


            <MessageList disableQuotedMessages={true} pinPermissions={pinnedPermissions} />
            <MessageInput grow overrideSubmitHandler={overrideSubmitHandler} />


        </Window>
        <Thread additionalMessageInputProps={{ grow: true, Input: ThreadMessageInput, overrideSubmitHandler }} />
        <PinnedMessageList />
    </>);
};
