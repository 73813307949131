import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Headline6} from '../../../components'
import {RightOutlined} from '@ant-design/icons'
import {apiRequest} from '../../../app/Apis'
import {Grid} from "antd";
const { useBreakpoint } = Grid;
export const ConsultantAssignments = ({
                                          appConf,
                                          consultantsAssigmentPagination,
                                          consultant,
                                          assignments,
                                          navs,
                                          setActiveTab
                                      }) => {
    const windowState = useBreakpoint();

    useEffect(() => {
        loadConsultantsAssigment()
    }, [])

    const loadConsultantsAssigment = async (isMQ) => {
        // fetchConsultantsAssigment
        const response = await apiRequest.get(`/patient-consultant-assignments?consultant=${consultant.id}`)
        if (response.status === 200) {

            setAssignments(response.data.data)


        }

    }

    // assignments
    const [assignments_, setAssignments] = useState([])
    return (

        <div className="card-box mt-3">

            <div className="flex flex-row  justify-between items-center w-full mb-4">
                <Headline6>Assigned Patients</Headline6>
                <RightOutlined onClick={() => setActiveTab("Assigned Patients")}/>
            </div>

            <div className="flex flex-row justify-between items-center w-full font-semibold px-2">
                <p className="text-left font-semibold">Active patients</p>
                {/* active patients count */}
                <p className="text-left font-semibold">{consultant.active_assigned_patients_count}</p>

            </div>
            <div className="flex flex-row justify-between items-center w-full font-semibold px-2">
                <p className="text-left font-semibold">Inactive patients</p>
                {/* active patients count */}
                <p className="text-left font-semibold">{consultant.inactive_assigned_patients_count}</p>
            </div>

        </div>


    )
}

const mapStateToProps = (state) => ({
    appConf: state.appConf,
    assignments: state.consultants.consultantAssignments,
    consultantsAssigmentPagination: state.consultants.consultantsAssigmentPagination,

    consultant: state.consultants.selectedConsultant,
    navs: state.navs,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantAssignments)