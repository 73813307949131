import React, {useEffect, useState} from 'react';
import {addButton, dialogCloser, emptyFun, executeChunkFn, formSubmitButton, getInputField, horStack, makeSelectOptions, outlinedButton} from '../../../../common/helpers';
import {Col, Divider, Form, Modal, Row} from 'antd';
import {Subtitle1} from '../../../../components';
import DynamicModalForm from '../../../../components/DynamicModalForm';
import * as Sentry from '@sentry/react';
import {errMsg, toastUp} from '../../../../app/Reducers/reducerUtils';
import {useDispatch} from 'react-redux';
import PermissionCard from './PermissionCard';
import {createPermissionGroup} from '../../../../app/Reducers';
import {useLocation, useNavigate} from 'react-router-dom';

const createPermissions = (permGroup) => {
  console.log({ permGroup })
  if (!permGroup.permissions) {
    return []
  }
  let permissions = []
  let resources = Object.keys(permGroup.permissions)
  for (const resource of resources) {
    permissions.push({ resource, ...permGroup[resource] })
  }
  console.log({ permissions })
  return permissions
}
const PermissionsForm = ({ isModalOpen, openModal, organisation, permGroup, organizationPermissions, activeUser }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate();
  const [form] = Form.useForm()
  const organisationId = organisation?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  const [dynamicFormComputedValues, setDynamicFormComputedValues] = useState(undefined);
  const [permissions, setPermissions] = useState(!!permGroup ? createPermissions(permGroup) : []);
  const [isExecuting, setisExecuting] = useState(false);
  let indexOfInitialData
  let resources = [...new Set(permissions.map(item => item.resource_name).reduce((previousValue, currentValue) => [...previousValue, currentValue], []))]
  const [permissibleResources, setPermissibleResources] = useState(makeSelectOptions([{ value: '*', label: 'All' }, ...organizationPermissions.map(resource => resource.module)]))
  const [selectedResource, setSelectedResource] = useState("assessment");
  console.log({ organizationPermissions })
  const onResourceSelect = (value, option) => {
    //console.log({value, option})
    setSelectedResource(value)
  }
  let groupFormInputs = {
    fields: [
      { name: 'name', label: 'Permission group name', required: true, type: 'text', requires: true, fullRow: true },
      { name: 'description', label: 'Description', required: true, type: 'textarea', requires: true, fullRow: true }
    ]
  }
  let permRules = [{ required: true, message: 'Required field' },
    ({ getFieldValue }) => ({
      validator(_, value, callback) {
        console.log({ value })
        if (value.length > 1 && value.includes('*')) {
          callback('You cannot select "All" together with other items')
        }
        if ((getFieldValue('resource_name') === '*' && value.some(item => item !== '*'))) {
          callback('No setting specific item permissions when resource name is "All"')
        }
        /*if ((!!getFieldValue('resource_name')[1] && value.some(item => item !== '*'))) {
          callback('Multiple resource names can only share "All" permission')
        }*/
        callback()
      }
    })]
  let resource_nameRules = [{ required: true, message: 'Required field' },
    ({ getFieldValue }) => ({
      validator(_, value, callback) {
        console.log({ value })
        if (value.length > 1 && value.includes('*')) {
          callback('You cannot select "All" together with other items')
        }
        callback()
      }
    })]
  let resourceOptions = {}
  organizationPermissions.map(resource => {
    resourceOptions[resource.module] = makeSelectOptions([{ value: '*', label: 'All' }, { label:'Turner',value:100002222 }, { label:'Tellan',value:10000232 },
      { label:'WP Internal',value:100002223 }, { label:'Sample Office',value:100002224 }])
  })
  let resourceInputs = {}
  for (const organizationPermission of organizationPermissions) {
    resourceInputs[organizationPermission.module] = {
      fields: [{
        name: 'resource_name',
        label: 'Resource names',
        required: true,
        type: 'select',
        options: permissibleResources,
        props: {onSelect:onResourceSelect, disabled: !!defaultDynamicModalFormValues },
        fullRow: true, fiprops: { rules: resource_nameRules }
      },
        ...organizationPermission.permissions.map(permission => {
        return {
          name: permission,
          label: permission.replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()),
          required: true,
          type: 'select',
          options: permission.includes('create') ? [{ value: '*', label: 'All' }] : resourceOptions[organizationPermission.module],
          props: { disabled: !!defaultDynamicModalFormValues, mode:'multiple' },
          fullRow: true, fiprops: { rules: permRules }
        };
      })],
      exitOnFinish: true
    }
  }
/*
  let permFormInputs = {
    fields: [
      {
        name: 'resource_name',
        label: 'Resource names',
        required: true,
        type: 'select',
        options: permissibleResources,
        requires: true,
        props: { disabled: !!defaultDynamicModalFormValues },
        fullRow: true, fiprops: { rules: resource_nameRules }
      },
      // { name: 'permissions', label: 'Permissions', required: true, type: 'select', options: makeSelectOptions(['create', 'read', 'update', 'delete', 'approve-insurance']) },
      {
        name: 'view',
        label: 'View',
        required: true,
        type: 'select',
        options: makeSelectOptions([{ value: '*', label: 'All' }, '10000020001', '10000020002', '10000020003', '10000020004']),
        requires: true,
        props: { mode: 'multiple' },
        fullRow: true, fiprops: { rules: permRules }
      },
      {
        name: 'create', label: 'Create', required: true, type: 'select', options: [{ value: '*', label: 'All' }], props: { mode: 'multiple' },
        requires: true, fullRow: true, fiprops: { rules: permRules }
      },
      {
        name: 'update',
        label: 'Update',
        required: true,
        type: 'select',
        options: makeSelectOptions([{ value: '*', label: 'All' }, '10000020001', '10000020002', '10000020003', '10000020004']),
        requires: true,
        props: { mode: 'multiple' },
        fullRow: true, fiprops: { rules: permRules }
      },
      {
        name: 'delete',
        label: 'Delete',
        required: true,
        type: 'select',
        options: makeSelectOptions([{ value: '*', label: 'All' }, '10000020001', '10000020002', '10000020003', '10000020004']),
        requires: true,
        props: { mode: 'multiple' },
        fullRow: true, fiprops: { rules: permRules }
      }
    ],
    exitOnFinish: true
  }
  */
  const [permFormInputs, setPermFormInputs] = useState(resourceInputs[selectedResource]);
  console.log({resourceInputs,permFormInputs})
  useEffect(()=>{
    setPermFormInputs(resourceInputs[selectedResource])
    return emptyFun
  },[selectedResource])
  //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
  let dynamicFormInputFields = {
    'Edit permission': resourceInputs[selectedResource],
    'Add permission': resourceInputs[selectedResource]
  }
  
  useEffect(() => {
    console.log({ organizationPermissions })
    return emptyFun
  }, [organizationPermissions])
  useEffect(() => {
    let usedPermissions = permissions.map(item => item.resource_name)
    resources = Array.from(new Set(usedPermissions))
    console.log({ resources, usedPermissions })
    setPermissibleResources(makeSelectOptions(permissibleResources.filter(resource => !resources.includes(resource.value))))
    console.log('Permission change: ', permissions)
    return emptyFun
  }, [permissions])
  const openPermDynamicForm = async (record) => {
    console.log('Add permission: ', record)
    if (!!record) {
      indexOfInitialData = permissions.findIndex(item => item.resource_name === record.resource_name)
    }
    await setFormName(!!record ? 'Edit permission' : 'Add permission');
    await setDefaultDynamicModalFormValues(record);
    await setDynamicFormOpen(true);
  };
  
  /**
   * This function is handling returned form entries from the dynamic form
   * */
  const onDynamicFormEntriesResult = async (entries) => {
    console.log({ entries })
    const { resource_name, ...rest } = entries.values
    try {
      switch (formName) {
        case 'Add permission':
          await setPermissions([...permissions, entries.values]);
          break;
        case 'Edit permission':
          const permissions_ = [...permissions]
          indexOfInitialData = permissions.findIndex(item => item.resource_name === entries.values.resource_name)
          console.log({ permissions_, indexOfInitialData })
          permissions_.splice(indexOfInitialData, 1)
          await setPermissions([...permissions_, entries.values]);
          break;
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      toastUp(errMsg(e, false, formName), false);
    }
  };
  
  const onFinish = async values => {
    // console.log(values)
    let permissions_ = {}
    for (const permission of permissions) {
      const { resource_name, ...rest } = permission;
      permissions_ = ({ ...permissions_, [resource_name]: { ...rest } })
    }
    let payload = { organisationId, ...values, permissions: permissions_ }
    console.log({ payload })
    await executeChunkFn(dispatch, createPermissionGroup, payload, setisExecuting, dialogCloser(openModal), null)
  };
  
  const removePermission = async (permission) => {
    console.log("Removing:", permission)
    let permissionIndex = permissions.findIndex(item => item.resource_name === permission.resource_name)
    let permissions_ = [...permissions]
    // console.log({ permissionIndex, permissions_ })
    permissions_.splice(permissionIndex, 1)
    await setPermissions(permissions_)
    console.log({ permissions_ })
  }
  return (
    <Modal
      title={'Add New Permission Group'}
      open={isModalOpen}
      onOk={dialogCloser(openModal)}
      width={600}
      onCancel={dialogCloser(openModal)}
      // closeIcon={modalClose(dialogCloser(setDynamicFormOpen))}
      maskClosable={false}
      footer={[<div className="flex flex-row p-4 mt-4 justify-center">
        {horStack([outlinedButton(dialogCloser(openModal), 'Cancel'),
          formSubmitButton('permForm', 'Submit', isExecuting, 'Submitting')])} </div>]}
      destroyOnClose={true}>
      {(isDynamicFormOpen && formName) && (
        <DynamicModalForm
          setDynamicFormOpen={setDynamicFormOpen}
          isDynamicFormOpen={isDynamicFormOpen}
          // inputFields={dynamicFormInputFields[formName]}
          inputFields={permFormInputs}
          onDynamicFormEntriesResult={onDynamicFormEntriesResult}
          closeModal={dialogCloser(setDynamicFormOpen)}
          isExecuting={isExecuting} phoneValue={null} setPhoneValue={null}
          defaultValues={defaultDynamicModalFormValues}
          formName={formName}
          computedValues={dynamicFormComputedValues}
        />
      )}
      <Form
        name="permForm"
        id="permForm"
        form={form}
        preserve={false}
        initialValues={{ ...permGroup }}
        layout="vertical"
        onValuesChange={(changedValues, allValues) => {
          // console.log({allValues, changedValues})
        }}
        onFinish={onFinish}>
        <Divider className={'mb-4'}/>
        <Row gutter={[4, 0]} align={"stretch"} justify={"start"} style={{ width: '100%', margin: 0 }}>
          {groupFormInputs.fields.map((field, index) => {
            return field.fullRow ?
              <Col xs={24} sm={24} md={24} lg={24} xl={24} flex={"auto"} style={{ width: '100%' }}>{getInputField(form, field, null, null)}</Col> :
              <Col xs={24} sm={24} md={12} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}>{getInputField(form, field, null, null)}</Col>;
          })}
        </Row>
        <div style={{ width: '100%' }}>
          <div className="flex-boy-row-space" style={{ width: "100%" }}>
            <Subtitle1>Permissions</Subtitle1>
            {addButton(() => openPermDynamicForm())}
          </div>
        </div>
        {permissions.length > 0 && permissions.map((item, index) => (
          <div style={{ marginBottom: "7px" }}>
            <PermissionCard key={item?.id} permissions={item} removePermission={removePermission} openPermDynamicForm={openPermDynamicForm}/>
          </div>
        ))}
      </Form>
    </Modal>
  );
};

export default PermissionsForm