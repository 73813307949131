/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiRequest } from "../Apis"
import { errMsg, toastUp } from "./reducerUtils";
import { auditLogs } from "./initialState";
import { appLog } from "../../common/helpers";
import RemoveCookie from '../../hooks/RemoveCookie';

const querystring = require('query-string');

const initialState = { ...auditLogs }
export const fetchAuditLogs = createAsyncThunk('fetchAuditLogs', async (params, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      //console.log({ fetchAuditLogs: params })
      const { fields, ...rest } = params
      let queryString = '?' + new URLSearchParams(!!fields ? params : rest).toString();
      const response = await apiRequest.get(`/audit_log${queryString}`)
      const auditLogs = response.data.data
      //console.log('auditLogs: ', auditLogs)
      let formattedLogs = []
      if (!!auditLogs && !!auditLogs[0]) {
        for (const item of auditLogs) {
          let isNew = Array.isArray(item.old_values) && !!item.old_values[0]
          formattedLogs.push({
            time: item.updated_at, actor: item.user, event: item.event,
            changes: Object.keys(item.new_values).map(key => ({ name: key, before: isNew ? undefined : item.old_values[key], after: item.new_values[key] }))
          })
        }

        //console.log('formattedLogs: ', formattedLogs)
        resolve(formattedLogs)
      } else {
        //console.log('auditLogs2: ', auditLogs)
        resolve([])
      }
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'audit logs'), false)
      reject(e)
    }
  })
})
export const fetchAuditLogColumns = createAsyncThunk('fetchAuditLogColumns', async (resource_type, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let queryString = '?' + querystring.stringify({ resource_type })
      const response = await apiRequest.post(`/audit_log/columns${queryString}`)
      const AuditLogColumns = response?.data?.data
      //console.log({ AuditLogColumns })
      if (!!AuditLogColumns) {
        resolve({ [resource_type]: AuditLogColumns })
      } else {
        resolve({ [resource_type]: undefined })
      }
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(null, true, `audit log attributes for ${resource_type.replace(/_/g, ' ')}`), false)
      reject(e)
    }
  });
})

export const fetchAuditResources = createAsyncThunk('fetchAuditResources', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.auditLogs.auditResourcesPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const response = await apiRequest.get(`/audit_log/resources`,{params});
      resolve({ logs: response.data.data, pagination: { ...pagination, total: response.data.meta.total } })
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'general audit log index'), false)
      reject(e)
    }
  })
})
export const fetchAuditables = createAsyncThunk('fetchAuditables', async (payload, thunkAPI) => {
  //console.log('fetchAuditables called')
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/audit_log/auditable`);
      //console.log({ response })
      //console.log({ response1: response.data.data })
      resolve(response.data.data[0])
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'general audit log index'), false)
      reject(e)
    }
  })
})
export const AuditLogSlice = createSlice({
  name: 'auditLogs', initialState, // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetAuditLogs: (state, action) => {
      state['auditableColumns'] = {}
      state['logs'] = []
      // state['auditables'] = []
      // Object.keys(initialState).map(key => state[key] = initialState[key])
    }, resetAuditResourcesPagination: (state) => {
      state.auditResourcesPagination = initialState.auditResourcesPagination
    },
    doResetAuditLogSlice: (state, action) => {
      Object.keys(initialState).map((key) => (state[key] = initialState[key]));
    }
  }, extraReducers:(builder)=> {
    builder.addCase(fetchAuditLogs.fulfilled, (state, action) => {
      state.logs = [...action.payload]
    })
    builder.addCase(fetchAuditLogColumns.fulfilled, (state, action) => {
      state.auditableColumns = { ...state.auditableColumns, ...action.payload }
    })
    builder.addCase(fetchAuditResources.fulfilled, (state, action) => {
      state.auditResources = [...action.payload.logs]
      state.auditResourcesPagination = action.payload.pagination
    })
    builder.addCase(fetchAuditables.fulfilled, (state, action) => {
      //console.log({ pl: action.payload })
      state.auditables = [...action.payload]
    })
  }
});
export const { resetAuditLogs, resetAuditResourcesPagination,doResetAuditLogSlice } = AuditLogSlice.actions
export default AuditLogSlice.reducer
