import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { DatePicker, Form, Input, Select } from 'antd'

import dayjs from 'dayjs'
import _ from "lodash"

import download from "downloadjs";
import querystring from "querystring";
import { apiRequestBlob } from '../../../app/Apis'
import {useProcedureSearch} from '../../../hooks/useProcedureSearch';

const { RangePicker } = DatePicker;
const stati = [
    "pending_review",
    "ready_for_submission",
    "submitted",
]
const submission_statuses = [
    "pass",
    "fail",
]

const { Option } = Select;
export const ReportForm = ({ closeModal, setIsSubmitting, state, type }) => {
    const { TextArea } = Input
    const dispatch = useDispatch()
    const [initialValues, setInitialValues] = useState({ visitType: type });
    const [procedureOptions, debouncedProcedureSearch, procedureSearchLoading] = useProcedureSearch([], 'name')

    const onFinish = async (values) => {
        console.log(values)
        let payload = { ..._.omitBy(values, _.isNil) }
        if (payload.createdAt) {
            payload.createdAt = dayjs(payload.createdAt).format('YYYY-MM-DD')
        }
        if (payload.dateOfService) {
            payload.dateOfService = dayjs(payload.dateOfSubmission).format('YYYY-MM-DD')
        }
        payload.progressStatus = type

        console.log(payload)

        let queryString = querystring.stringify(payload)

        const response = await apiRequestBlob.get(`/billing-export?${queryString}`)
        download(response.data, `orders-${dayjs().format("YYYY-MMM-DD hh:mm")}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        closeModal()
    }


    return (<Form
        name="new-order-report"
        onFinish={onFinish}
        labelCol={{ span: 16 }}
        initialValues={{ ...initialValues }}
        layout="vertical">

        <Form.Item name="search" label="Patient" rules={[{ required: false, message: 'Missing dates' }]}>
            <Input autoComplete={'off'} />
        </Form.Item>

        {/* submission status */}
        {type === "submitted" && <Form.Item name="submissionStatus" label="Submission Status" rules={[{ required: false, message: 'Missing notes' }]}>
            <Select

                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"

            >
                {submission_statuses.map(item => <Option value={item}>{item}</Option>)}
            </Select>
        </Form.Item>}
        {/* created at datepicker */}
        <Form.Item name="createdAt" label="Created At" rules={[{ required: false, message: 'Missing notes' }]}>
            <DatePicker style={{ width: '100%' }} format={"YYYY-MM-DD"} />
        </Form.Item>

        {/* date of service datepicker */}
        <Form.Item name="dateOfService" label="Date Of Service" rules={[{ required: false, message: 'Missing notes' }]}>
            <DatePicker style={{ width: '100%' }} format={"YYYY-MM-DD"} />
        </Form.Item>

        {/* procedure select */}
        <Form.Item name="procedure" label="Procedure" rules={[{ required: false, message: 'Missing notes' }]}>
            <Select
              allowClear
              placeholder={'Select'}
              popupMatchSelectWidth={false}
              style={{ width: '100%' }}
              options={procedureOptions}
              loading={procedureSearchLoading}
              showSearch={true}
              filterOption={false}
              onSearch={(value) => debouncedProcedureSearch(value)}
            />
        </Form.Item>


    </Form>)
}
const mapStateToProps = (state) => ({ order: state.orders.selectedOrder })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ReportForm)
