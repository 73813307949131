import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { settings } from './initialState'
import { apiRequest } from "../Apis";
import { errMsg, toastUp } from "./reducerUtils";
import { fetchProcedures } from './AppointmentsSlice';

const initialState = { ...settings }

export const fetchSettings = createAsyncThunk('/settings/options', (params, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    console.log('Fetching Settings...')
    try {
      const response = await apiRequest.get('/settings')
      const data = response.data.data
      // toastUp('Settings Fetched', true)
      // console.log('Server Settings::', data)
      thunkAPI.dispatch(fetchProcedures())
      resolve(data)
    } catch (e) {
      toastUp(errMsg(e, true, "Settings"), false)
      reject(e)
    }
  })
})

export const SettingSlice = createSlice({
  name: 'settingslist',
  initialState,
  reducers: { doResetSettingsSlice: (state) => {
      Object.keys(initialState).map(key => state[key] = initialState[key])
    },
  },
  extraReducers: (builder)=>{
    builder.addCase(fetchSettings.pending, (state, action) => {
      state.loadingSettings = true
    }).addCase(fetchSettings.fulfilled, (state, action) => {
      state.settings = action.payload
      state.loadingSettings = false
    })
  }
})
export const {doResetSettingsSlice} = SettingSlice.actions
export default SettingSlice.reducer