import { apiRequest } from "./index";
import { errMsg, getToastId, toastUp } from "../Reducers/reducerUtils";
import { toast } from "react-toastify";
import { mapboxToken } from "../../configure/constants";
import dayjs from "dayjs";
import querystring from "querystring";
import * as Sentry from "@sentry/react";
import { appLog } from "../../common/helpers";


export const fetchActiveFacilities = (callback) => {
  apiRequest.get('/medical-facilities-assignment').then(response => callback((response && response.data && response.data.data) ? response.data.data : [])).catch(reason => callback(null))
}
export const handleActiveFacilitySearch = (value, cb) => {
  apiRequest.get(`/medical-facilities-assignment?search=${value}`).then(response => cb((response && response.data && response.data.data) ? response.data.data : [])).catch(reason => cb([]))
}
export const handleFacilitySearch = (value, cb) => {
  apiRequest.get(`/medical-facilities?search=${value}`).then(response => cb((response && response.data && response.data.data) ? response.data.data : [])).catch(reason => cb([]))
}
export const handleConsultantSearch = (value, date, time_slot, cb, patientId) => {
  let query = { search: value.trim() }
  let start = `${dayjs(date).format("YYYY-MM-DD")} ${dayjs(time_slot[0]).format("HH:mm:ss")}`
  let end = `${dayjs(date).format("YYYY-MM-DD")} ${dayjs(time_slot[1]).format("HH:mm:ss")}`

  //notScheduledBetween[start]=date&notScheduledBetween[end]=date
  if (query.search) {
    let queryParams = `&notScheduledBetween[start]=${start}&notScheduledBetween[end]=${end}`
    //console.log('query.search:  ', query.search)
    //console.log('queryParams:  ', queryParams)
    apiRequest.get(`/consultants?${querystring.stringify(query)}${queryParams}`).then(response => cb((response && response.data && response.data.data) ? response.data.data : [])).catch(reason => cb([]))
  } else {
    cb([])
  }
}
export const findTimeZone = async (string, cb) => {
  try {
    // Use Mapbox Geocoding API to get the coordinates of the location
    let geocodingData = await apiRequest.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${string}.json?access_token=${mapboxToken}`);
    let longitude = geocodingData.data.features[0].center[0];
    let latitude = geocodingData.data.features[0].center[1];

    // Use Mapbox Tilequery API to get the timezone of the location
    let tilequeryData = await apiRequest.get(`https://api.mapbox.com/v4/wp-mapbox.al9wz9e7/tilequery/${longitude},${latitude}.json?access_token=${mapboxToken}`);
    let zone = tilequeryData.data.features[0].properties.TZID;

    // Use Intl.DateTimeFormat to get the timezone name
    const currentDateTime = new Date();
    let timezoneName = new Intl.DateTimeFormat('en', {
      timeZone: zone,
      timeZoneName: 'short'
    }).formatToParts(currentDateTime)
      .find(part => part.type === 'timeZoneName').value;

    return timezoneName;
  } catch (error) {
    console.error('Error finding timezone:', error);
    throw new Error('Unable to find timezone');
  }
}


export const fetchWoundLocations = (callback) => {
  apiRequest.get('/wound-locations').then(response => callback((response && response.data && response.data.data) ? response.data.data.map(item => `${item.name}`) : [])).catch(reason => callback(null))
}

export const genReport = async (woundAssessment, setPrinting, setVisit, setStatusModal, setPdf, setLoading, setPdfShowing) => {
  await setPrinting(true)
  const toastId = getToastId("Printing Report")
  try {
    const response = await apiRequest.get(`/assessments/${woundAssessment.id}/report-pdf`)
    if (response && response.data) {
      toast.update(toastId, { render: "Assessment Printed", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      setPdf(response.data.reportUrl)
      setLoading(true)
      setPdfShowing(true);
      // window.open(response.data.reportUrl, '_blank');
    }
    await setPrinting(false)
    setVisit(false)
    setStatusModal(false)
  } catch (e) {
    Sentry.captureException(e);
    await setPrinting(false)
    toastUp(errMsg(e, false), false)
  }
}

export const generateLabRequisition = async (woundAssessment, setLabPrinting, setVisit, setStatusModal, setPdf, setLoading, setPdfShowing) => {
  await setLabPrinting(true)
  const toastId = getToastId("Printing Report")
  try {
    const response = await apiRequest.get(`/assessments/${woundAssessment.id}/report-pdf?requisition_form=true`)
    if (response && response.data) {
      toast.update(toastId, { render: "Lab Request Printed", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      setPdf(response.data.reportUrl)
      setLoading(true)
      setPdfShowing(true);
    }
    await setLabPrinting(false)
    setVisit(false)
    setStatusModal(false)
  } catch (e) {
    Sentry.captureException(e);
    await setLabPrinting(false)
    toastUp(errMsg(e, false), false)
  }
}

export const genAllograftReport = async (woundAssessment, setAllograftPrinting, setVisit, setStatusModal, setPdf, setLoading, setPdfShowing) => {
  await setAllograftPrinting(true)
  const toastId = getToastId("Printing Allograft Report")
  try {
    const response = await apiRequest.get(`/assessments/${woundAssessment.id}/allograft-pdf`)
    if (response && response.data) {
      toast.update(toastId, { render: "Allograft Report Printed", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      setPdf(response.data.reportUrl)
      setLoading(true)
      setPdfShowing(true);
      // window.open(response.data.reportUrl, '_blank');
    }
    await setAllograftPrinting(false)
    setVisit(false)
    setStatusModal(false)
  } catch (e) {
    Sentry.captureException(e);
    await setAllograftPrinting(false)
    toastUp(errMsg(e, false), false)
  }
}

export const genWoundsReport = async (wound, setWoundsProgressPrinting, setStatusModal, setPdf, setLoading, setPdfShowing) => {
  await setWoundsProgressPrinting(true)
  const toastId = getToastId("Printing Wounds Progress Report")
  try {
    const response = await apiRequest.get(`/wounds/${wound.id}/progress-pdf`)
    if (response && response.data) {
      toast.update(toastId, { render: "Wounds Progress Report Printed", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      setPdf(response.data.reportUrl)
      setLoading(true)
      setPdfShowing(true);
    }
    await setWoundsProgressPrinting(false)
    setStatusModal(false)
  } catch (e) {
    Sentry.captureException(e);
    await setWoundsProgressPrinting(false)
    toastUp(errMsg(e, false), false)
  }
}
export const genSpecificWoundsReport = async (wound, id, setWoundsProgressPrinting, setStatusModal, setPdf, setLoading, setPdfShowing) => {
  await setWoundsProgressPrinting(true)
  const toastId = getToastId("Printing Wounds Progress Report")
  try {
    const response = await apiRequest.get(`/wounds/${wound.id}/progress-pdf?planOfCareSummary=${id}`)
    if (response && response.data) {
      toast.update(toastId, { render: "Wounds Progress Report Printed", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      setPdf(response.data.reportUrl)
      setLoading(true)
      setPdfShowing(true);
    }
    await setWoundsProgressPrinting(false)
    setStatusModal(false)
  } catch (e) {
    Sentry.captureException(e);
    await setWoundsProgressPrinting(false)
    toastUp(errMsg(e, false), false)
  }
}


export const genOrdersReport = async (patientId, start, end, setWoundsProgressPrinting, setStatusModal, setPdf, setLoading, setPdfShowing) => {
  await setWoundsProgressPrinting(true)
  const toastId = getToastId("Printing Prescription supply form Report")
  try {
    const response = await apiRequest.get(`/patients/${patientId}/treatment-order-supply-order-form?deliveryDateRange[start_at]=${start}&deliveryDateRange[end_at]=${end}`)
    if (response && response.data) {
      toast.update(toastId, { render: "Prescription supply form Report Printed", type: toast.TYPE.SUCCESS, autoClose: 5000 });

      setPdf(response.data.reportUrl)
      setLoading(true)
      setPdfShowing(true);
    }
    await setWoundsProgressPrinting(false)
    setStatusModal(false)
  } catch (e) {
    Sentry.captureException(e);
    await setWoundsProgressPrinting(false)
    toastUp(errMsg(e, false), false)
  }
}


export const genSingleOrderReport = async (patientId, record, setWoundsProgressPrinting, setStatusModal, setPdf, setLoading, setPdfShowing) => {
  await setWoundsProgressPrinting(true)
  const toastId = getToastId("Printing Prescription supply form Report")
  try {
    const response = await apiRequest.get(`/patients/${patientId}/treatment-order-supply-order-form?order=${record.id}`)
    if (response && response.data) {
      toast.update(toastId, { render: "Prescription supply form Report Printed", type: toast.TYPE.SUCCESS, autoClose: 5000 });

      setPdf(response.data.reportUrl)
      setLoading(true)
      setPdfShowing(true);
    }
    await setWoundsProgressPrinting(false)
    setStatusModal(false)
  } catch (e) {
    Sentry.captureException(e);
    await setWoundsProgressPrinting(false)
    toastUp(errMsg(e, false), false)
  }
}



export const getOrderFilterStatuses = async (setavailableStatuses, order_state) => {
  let data = await apiRequest.get(`/treatment-order-filter-statuses?order_state=${order_state}`).catch(appLog)
  //console.log({ getOrderFilterStatuses: data })
  if (data && data.data) {
    setavailableStatuses(data.data.data)
  }
}


export const genOrderDeliveryReport = async (order, setOrderDeliverPrinting, setPdf, setLoading, setPdfShowing) => {
  await setOrderDeliverPrinting(true)
  const toastId = getToastId('Printing Order Delivery Report')
  try {
    const response = await apiRequest.get(`/treatment-orders/${order?.id}/proofofdelivery`)
    if (response.status == 200) {
      toast.update(toastId, { render: 'Order Delivery Report Printed', type: toast.TYPE.SUCCESS, autoClose: 5000 })
      console.log("Delivery URL:::", response.data.reportUrl)
      setPdf(response.data.reportUrl)
      setLoading(true)
      setPdfShowing(true)
    } else {
      setPdfShowing(false)
    }
    await setOrderDeliverPrinting(false)
  } catch (e) {
    // Sentry.captureEvent(e);
    console.log("Report issue:::", e)
    await setOrderDeliverPrinting(false)
    toastUp(errMsg(e, false), false)
  }
}