/* eslint-disable no-unused-vars */
import {createSlice} from '@reduxjs/toolkit';
import {navs} from "./initialState";

const initialState = {...navs};
export const navSlice = createSlice({
  name: 'navs', initialState, reducers: {
    updatePatientCardNav: (state, action) => {
      state.patientCardNavs = {...state.patientCardNavs, ...action.payload}
    },setOrgNavs: (state, action) => {
      state.orgNavs = action.payload
    }, updatePatientDetailsOwner: (state, action) => {
      state.patientDetailsOwner = action.payload
    }, setPatientDetailsLoading: (state, action) => {
      state.patientDetailsLoading = action.payload
    }, setArchivedPatientsLoading: (state, action) => {
      state.archivedPatientsLoading = action.payload
    }, setfArchivedPatientsLoading: (state, action) => {
      state.farchivedPatientsLoading = action.payload
    }, setfPatientsLoading: (state, action) => {
      state.fPatientsLoading = action.payload
    }, setfProspectsLoading: (state, action) => {
      state.fProspectsLoading = action.payload
    }, setfPatientsAndProspectsLoaded: (state, action) => {
      state.fPatientsAndProspectsLoaded = action.payload
    }, setAllergiesLoaded: (state, action) => {//todo: call this inside when fulfilled/rejected
      state.allergiesLoaded = action.payload
    }, setMedicationsLoaded: (state, action) => {//todo: call this inside when fulfilled/rejected
      state.medicationsLoaded = action.payload
    }, setPrescriptionsLoaded: (state, action) => {//todo: call this inside when fulfilled/rejected
      state.prescriptionsLoaded = action.payload
    }
    , setFacilitiesLoaded: (state, action) => {//todo: call this inside when fulfilled/rejected
      state.facilitiesLoaded = action.payload
    },
    setFacilityNavs: (state, action) => {
      state.facilityNavs = {...state.facilityNavs, ...action.payload}
    },
    setConsultantsLoaded: (state, action) => {//todo: call this inside when fulfilled/rejected
      state.consultantsLoaded = action.payload
    }, setAppointmentsLoaded: (state, action) => {//todo: call this inside when fulfilled/rejected
      state.appointmentsLoaded = action.payload
    }, setAdmissionsLoaded: (state, action) => {//todo: call this inside when fulfilled/rejected
      state.admissionsLoaded = action.payload
    },

    setPendingScheduleLoaded: (state, action) => {//todo: call this inside when fulfilled/rejected]
      state.pendingScheduleLoaded = action.payload
    }, setPendingScheduleLoading: (state, action) => {//todo: call this inside when fulfilled/rejected]
      state.pendingScheduleLoading = action.payload
    },
    setEncountersLoaded: (state, action) => {//todo: call this inside when fulfilled/rejected
      state.encountersLoaded = action.payload
    }, setDraftsLoaded: (state, action) => {//todo: call this inside when fulfilled/rejected
      state.draftsLoaded = action.payload
    }, setVitalsLoaded: (state, action) => {//todo: call this inside when fulfilled/rejected
      state.vitalsLoaded = action.payload
    }, setVitalsLoading: (state, action) => {//todo: call this inside when fulfilled/rejected
      state.vitalsLoading = action.payload
    }, setInsuranceLoaded: (state, action) => {//todo: call this inside when fulfilled/rejected
      state.insuranceLoaded = action.payload
    }, setInsuranceLoading: (state, action) => {//todo: call this inside when fulfilled/rejected
      state.insuranceLoading = action.payload
    }, setshippingAddressesLoaded: (state, action) => {//todo: call this inside when fulfilled/rejected
      state.shippingAddressesLoaded = action.payload
    }, setshippingAddressesLoading: (state, action) => {//todo: call this inside when fulfilled/rejected
      state.shippingAddressesLoading = action.payload
    }, setWoundsLoaded: (state, action) => {//todo: call this inside when fulfilled/rejected
      state.woundsLoaded = action.payload
    }, setPatientAppointmentsLoaded: (state, action) => {//todo: call this inside when fulfilled/rejected
      state.patientAppointmentsLoaded = action.payload
    }, setSelectAppointmentID: (state, action) => {
      state.selectedAppointmentID = action.payload;
    }, setSelectedConsultantID: (state, action) => {
      state.selectedConsultantID = action.payload;
    }, setPatientNavs: (state, action) => {
      state.patientNavs = {...state.patientNavs, ...action.payload};
    }, setAppointmentNavs: (state, action) => {
      state.appointmentNavs = {...state.appointmentNavs, ...action.payload};
    },setFacilityHomeNavs: (state, action) => {
      state.facilityHomeNavs = {...state.facilityHomeNavs, ...action.payload};
    }, setConsultantHomeNavs: (state, action) => {
      state.consultantHomeNavs = {...state.consultantHomeNavs, ...action.payload};
    }, setRoleModuleNavs: (state, action) => {
      state.roleNavs = action.payload;
    },
    setOrderNavs: (state, action) => {
      state.orderNavs = {...state.orderNavs, ...action.payload};
    },
    setfacilityWoundNavs: (state, action) => {
      state.facilityWoundNavs = {...state.facilityWoundNavs, ...action.payload};
    },
    resetfacilityWoundNavs: (state, action) => {
      state.facilityWoundNavs = {...state.facilityWoundNavs, tab: 'Wound Track'};
    }
    , setConsultantNavs: (state, action) => {
      state.consultantNavs = {...state.consultantNavs, ...action.payload};
    }, resetPatientNavs: (state) => {
      state.patientNavs = {
        ...state.patientNavs, firstTab: 'Dashboard', encounterTab: "All", healthTab: "Allergies", openChart: "Measures"
      }
    }, resetAppointmentNavs: (state) => {
      state.appointmentNavs = {...state.appointmentNavs, tab: 'Admission'}
    }, resetFacilityHomeNavs: (state) => {
      state.facilityHomeNavs = {...state.facilityHomeNavs, tab: 'Organisations'}
    }, resetConsultantHomeNavs: (state) => {
      state.consultantHomeNavs = {...state.consultantHomeNavs, tab: 'Consultants'}
    }, resetOrderNavs: (state) => {
      state.orderNavs = {...state.orderNavs, tab: 'preorders'}
      
    }
    , resetConsultantNavs: (state) => {
      state.consultantNavs = {...state.consultantNavs, tab: "Summary"}
    }, setSelectedFacilityID: (state, action) => {
      state.selectedFacilityID = action.payload;
    }, doResetNavSlice: (state) => {
      Object.keys(initialState).map(key => state[key] = initialState[key])
    },
    setTaskNavs: (state, action) => {
      state.taskNavs = {...state.taskNavs, ...action.payload};
    },
    resetTaskNavs: (state) => {
      state.taskNavs = {...state.taskNavs, tab: "list"}
    },
    setBillingNavs: (state, action) => {
      state.billingNavs = { ...state.billingNavs, ...action.payload };
    },
    resetBillingNavs: (state) => {
      state.billingNavs = { ...state.billingNavs, tab: "Pending review" }
    },
    setMapNavs: (state, action) => {
      state.mapNavs = { ...state.mapNavs, ...action.payload };
    }


  }
});
export const {setFacilityHomeNavs,resetFacilityHomeNavs,
  updatePatientCardNav,
  updatePatientDetailsOwner,
  setPatientDetailsLoading,
  setArchivedPatientsLoading,
  setAllergiesLoaded,
  setMedicationsLoaded,
  setFacilitiesLoaded,
  setConsultantsLoaded,
  setfArchivedPatientsLoading,
  setAppointmentsLoaded,
  setEncountersLoaded,
  setDraftsLoaded,
  doResetNavSlice,
  setSelectAppointmentID,
  setSelectedConsultantID,
  setSelectedFacilityID,
  setPatientNavs,
  resetPatientNavs,
  setConsultantHomeNavs,
  resetConsultantHomeNavs,
  setAppointmentNavs,
  resetAppointmentNavs,
  setConsultantNavs,
  setOrderNavs,
  resetOrderNavs,
  resetConsultantNavs,
  setVitalsLoaded,
  setWoundsLoaded,
  setVitalsLoading,
  setPatientAppointmentsLoaded,
  setPrescriptionsLoaded,
  setInsuranceLoaded,
  setInsuranceLoading,
  setshippingAddressesLoaded,
  setshippingAddressesLoading,
  setfPatientsLoading,
  setfProspectsLoading,
  setfPatientsAndProspectsLoaded,
  setfacilityWoundNavs,
  setFacilityNavs,setOrgNavs,
  setTaskNavs,
  resetTaskNavs,
  setBillingNavs,
  resetBillingNavs,
  setPendingScheduleLoaded,
  setAdmissionsLoaded,
  setMapNavs,
  setRoleModuleNavs
} = navSlice.actions
export default navSlice.reducer;
