/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '../Apis';
import querystring from 'querystring';
import {
  arrayStateAfterDelete,
  newResourceArray,
  updatedArrayState,
} from '../../common/helpers';
import { errMsg, getToastId, toastUp, updateToast } from './reducerUtils';
import { reports } from './initialState';

const initialState = { ...reports };

export const fetchReports = createAsyncThunk(
  '/custom_reports',
  async (parms, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiRequest.get(`/custom_reports`);

        const payload = {
          data: response.data.data,
        };
        resolve(payload);
      } catch (e) {
        console.log(e);
        toastUp(errMsg(e, true, 'Assessment Reports'), false);
        reject(e);
      }
    });
  }
);

export const updateReports = createAsyncThunk(
  '/update_reports',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Updating report');
      try {
        const response = await apiRequest.patch(`/custom_reports/${payload.id}`,payload.data);
        await thunkAPI.dispatch(fetchReports());
        updateToast(toastId, `Report updated successfully`, true)
        resolve(response.data);
      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);

export const createReport = createAsyncThunk(
  '/create_reports',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Creating report');
      try {
        const response = await apiRequest.post(`/custom_reports`, payload);
        await thunkAPI.dispatch(fetchReports());
        resolve(response.data);
      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);

export const ReportSlice = createSlice({
  name: 'reports',
  initialState,
  extraReducers: (builder)=> {
    builder.addCase(fetchReports.fulfilled, (state, action) => {
      state.reports = [...action.payload.data];
    })
  },
});

export default ReportSlice.reducer;
