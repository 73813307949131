import React, {useEffect, useState} from 'react'
import {Body2, Headline6, Subtitle1} from '../../../components';
import moment from 'moment';
import {Avatar, Modal, Popconfirm, Tag} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import NewTask from './NewTask';
import {addButtonClean, closeButton, deleteButton, dialogCloser, editButton, emptyTable, formSubmitButton, horStack, outlinedButton, priorityColors} from '../../../common/helpers';
import ViewTask from './ViewTask';
import {deletePatientTask, fetchPatientTasks, selectPatientTask} from '../../../app/Reducers';
import {connect, useDispatch} from 'react-redux'
import {RightOutlined} from '@ant-design/icons'


export const PatientTasksMini = ({ setContent, tasks, selectedTask }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchPatientTasks())
    
    return () => {
    
    }
  }, [])
  
  
  // new task modal
  const [newTaskModal, setNewTaskModal] = useState(false)
  
  const newTaskModalOpen = () => {
    setNewTaskModal(true)
  }
  const newTaskModalClose = () => {
    setNewTaskModal(false)
  }
  // is executing
  const [isExecuting, setIsExecuting] = useState(false)
  
  // viw task modal
  const [viewTaskModal, setViewTaskModal] = useState(false)
  
  const viewTaskModalOpen = async (task) => {
    await dispatch(selectPatientTask(task))
    setViewTaskModal(true)
  }
  const viewTaskModalClose = () => {
    setViewTaskModal(false)
  }
  
  // editing form
  const [editingForm, setEditingForm] = useState(false)
  const removeTask = async (id, cb) => {
    await dispatch(deletePatientTask({ id }))
    cb()
  }
  
  const addTask = () => {
    setEditingForm(false)
    setNewTaskModal(true)
    // setContent(<PatientTaskForm patient={patient} />)
  }
  
  const Task = ({ id, content, priority, index, task }) => {
    return (
      
      
      <div
        onClick={() => {
          viewTaskModalOpen(task)
        }
        }
        
        className={`p-4 bg-white shadow rounded mb-2 border-l-4 w-full cursor-pointer`}
      >
        
        <div className="flex justify-between my-2">
          <Body2 color={priorityColors[priority]}>Due {moment(task?.due_date).format("DD MMM, YYYY")} </Body2>
          <Tag color={priorityColors[priority]}>{priority}</Tag>
        </div>
        <Subtitle1 style={{ textAlign: 'left' }}>{content}</Subtitle1>
        
        <div className="flex justify-between  my-2">
          <div className="flex flex-row items-center">
            <div className="rounded-full flex justify-center items-center p-2 bg-gray-200 mr-1">
              <FontAwesomeIcon size="xs" color="grey" icon={['fas', 'user-friends']}/>
            </div>
            <Body2 color="black">{task?.department?.name}</Body2>
          </div>
          <div className="flex flex-row items-center">
            
            <Body2 className="mr-2" color="black">{task?.assignee.name}</Body2>
            <Avatar className="ml-2" size="small" src={task?.assignee.profile_image_url}>{task?.assignee.name?.charAt(0)} </Avatar>
          </div>
        </div>
      </div>
    
    );
  };
  
  return (
    <div className="card-box">
      
      {/*  new taksk modal */}
      <Modal
        title="New Task"
        open={newTaskModal}
        onCancel={newTaskModalClose}
        
        width={600}
        destroyOnClose={true}
        maskClosable={false}
        centered={true}
        className="new-task-modal"
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(dialogCloser(setNewTaskModal), 'Cancel', isExecuting),
            formSubmitButton('taskForm', editingForm ? 'Update' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
        </div>]}
      >
        <NewTask closeModal={newTaskModalClose} editingForm={editingForm} isExecuting={isExecuting} setIsExecuting={setIsExecuting}/>
      </Modal>
      {/* view task modal */}
      <Modal
        closable={false}
        title={<div className="flex flex-1" style={{ justifyContent: 'space-between' }}>
          <Headline6>View Task</Headline6>
          <div className="flex flex-1 flex-row items-center " style={{ justifyContent: 'flex-end' }}>
            
            <Popconfirm
              placement="top"
              title="Are you sure to delete this task?"
              description=""
              onConfirm={() => {
                removeTask(selectedTask?.id, viewTaskModalClose)
              }}
              okText="Yes"
              cancelText="No"
            >
              {deleteButton('Delete', isExecuting, () => {
              }, 'Delete Task')}
            </Popconfirm>
            {editButton(() => {
              setEditingForm(true)
              
              newTaskModalOpen()
            })}
            {closeButton(() => {
              viewTaskModalClose()
            })}
          </div>
        
        
        </div>}
        
        open={viewTaskModal}
        onCancel={viewTaskModalClose}
        onOk={viewTaskModalClose}
        
        width={1000}
        destroyOnClose={true}
        maskClosable={false}
        centered={true}
        className="view-task-modal"
      
      >
        {selectedTask && <ViewTask/>}
      </Modal>
      <div className="flex-boy-row-space" style={{ width: "100%" }}>
        
        <Headline6>Open Tasks</Headline6>
        <div className="flex flex-row items-center">
          {addButtonClean(() => {
            console.log('new task')
            addTask()
          })}
          <RightOutlined onClick={() => setContent("Tasks")}/>
        </div>
      </div>
      
      {tasks.length ? tasks.slice(0, 3).map((task, index) => {
        return (
          <Task
            key={task?.id}
            id={task?.id}
            content={task?.description}
            priority={task?.priority}
            index={index}
            task={task}
          />
        )
      }) : <div className="flex  mx-auto justify-center" style={{ height: "180px", width: '180px', marginBottom: '25px' }}> {emptyTable()} </div>}
    </div>)
  
}

const mapStateToProps = (state) => ({
  patient: state.patients.selectedPatient,
  selectedTask: state.patients.selectedPatientTask,
  tasks: state.patients.tasks
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PatientTasksMini)