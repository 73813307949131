import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import dayjs from 'dayjs'
import "react-big-calendar/lib/css/react-big-calendar.css";
import AppointmentCard from './ScheduleCard';
import { status_colors } from '../../../configure/constants';

import AppointmentViewCard from './AppointmentViewCard';
import { Subtitle1 } from '../../../components';

import { AutoComplete, Form, Input, Modal, Popover, Select } from 'antd';
import SheduleAppointment from './ScheduleAppointment';

import { deleteAppointment } from '../../../app/Reducers/AppointmentsSlice';
import {
    closeButton,
    deleteButton,
    dialogCloser,
    editButton,
    emptyFun,
    formSubmitButton,
    horStack,
    modalClose,
    outlinedButton
} from "../../../common/helpers";

import _ from "lodash"
import { apiRequest } from '../../../app/Apis';
import {
    CloseCircleFilled,
    CloseOutlined,
    DeleteOutlined,
    PlusCircleFilled,
    PlusCircleOutlined
} from '@ant-design/icons';
import querystring from 'querystring';
import { getToastId, updateToast } from '../../../app/Reducers/reducerUtils';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
const localizer = dayjsLocalizer(dayjs)


const initialConsultants = [{ id: 1, name: null }, { id: 2, name: null }, { id: 3, name: null }, { id: 4, name: null }, { id: 5, name: null }];
export const Scheduler = ({ handleFilterAction, appointments, appConf, mode, date, setMode, setSelectedPeriod, activeUser, setScheduleRange }) => {
    var weekStart = dayjs(date).clone().startOf('isoWeek');
    var weekEnd = dayjs(date).clone().endOf('isoWeek');
    var monthStart = dayjs(date).clone().startOf('isoMonth');
    var monthEnd = dayjs(date).clone().endOf('isoMonth');

    const [_range, setRange] = useState(mode === "month" ? [monthStart, monthEnd] : [weekStart, weekEnd])
    
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
    const can_delete_appointment = usePermissionCheck('delete_appointment', false, [])

    const { Option } = Select;
    useEffect(() => {
        let weekStart = dayjs(date).clone().startOf('isoWeek');
        let weekEnd = dayjs(date).clone().endOf('isoWeek');
        let monthStart = dayjs(date).startOf('month')
        let monthEnd = dayjs(date).endOf('month')

        if (mode === 'week' || mode === "month") {
            onRangeChange(mode === 'week' ? [weekStart, weekEnd] : [monthStart, monthEnd])
        }

        return emptyFun
    }, [mode, date])



    useEffect(() => {
        const apps = [...appointments]
        setevents(apps.map(item => ({ ...item, start: dayjs(item.start_at).toDate(), end: dayjs(item.end_at).toDate() })))
        return emptyFun
    }, [appointments])


    const CustomEvent = ({ event }) => (<AppointmentCard setevent={addevent} appointment={event} mode={mode} color={status_colors[event.status]} />);

    const addevent = (event) => {
        console.log(event)
        setevent(event)
        showModal()
    }

    const onRangeChange = async (range) => {
        console.log('onRangeChange: ', range, monthStart, monthStart)
        setRange(range)
        // setScheduleRange([dayjs(range[0]).format("YYYY-MM-DD"), dayjs(range[1]).format("YYYY-MM-DD")])
        // handleFilterAction('filter', { 'scheduledForRange[start_at]': dayjs(range[0]).format("YYYY-MM-DD"), 'scheduledForRange[end_at]': dayjs(range[1]).format("YYYY-MM-DD") })

    }


    const { views, ...otherProps } = useMemo(() => ({
        views: { month: true, day: true, week: true, agenda: true },
        // ... other props
    }), [])

    const onShowMore = useCallback((events, date) => {
        setSelectedPeriod(dayjs(date).format("YYYY-MM-DD"))
        setMode("day")
    }, [])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const [event, setevent] = useState(null)
    const [editingForm, setEditingForm] = useState(false);

    const [isExecuting, setisExecuting] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const dispatch = useDispatch()
    const [appointmentTodeleteID, setappointmentTodeleteID] = useState(null)
    const [reasonStatus, setreasonStatus] = useState(null)
    const [reasonModal, setreasonModal] = useState(false)
    const [selected, setSelected] = React.useState(null);
    const handleCanceldelete = () => setSelected(0);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const showPopconfirm = (id) => setSelected(id);
    const reasonFinish = async (values) => {
        await setConfirmLoading(true);
        await dispatch(deleteAppointment({ appointment_id: appointmentTodeleteID, ...values }));
        await setSelected(0);
        await setConfirmLoading(false);
        setreasonModal(false)
    }

    const Tabo = () => {
        return (
          <div className="p-2 flex flex-row justify-between w-full items-center">
              <p className={'my-auto font-semibold'}>Appointment</p>
              <div className="flex flex-row gap-4 items-center">
                  {editButton(handleedit)}
                  {can_delete_appointment && deleteButton(handledelete)}
                  {closeButton(dialogCloser(setIsModalOpen),null,{type:'text'})}
              </div>
              
              {/*<CloseOutlined/>*/}
          </div>
        )
    }
    const handledelete = () => {
        setappointmentTodeleteID(event.id)
        setreasonModal(true)
        setSelected(0)
    }
    const handleedit = async () => {
        const toastId = getToastId("Fetching appointment details")
        const response = await apiRequest.get(`/appointments/${event.id}`).catch(e => console.log(e))
        if (response?.data?.data) {
            updateToast(toastId, 'Fetched', true);
            await setevent(response?.data?.data)
            await setEditingForm(true)
            await setOpen(true)
        }

    }
    const localizer = dayjsLocalizer(dayjs);
    const [consultants, setconsultants] = useState(initialConsultants)
    const [events, setevents] = useState([

    ])

    useEffect(() => {
        if (consultants.length) {


            fetchConsultantEvents()
        }
        return () => {

        }
    }, [consultants, appointments, date, appConf])

    const fetchConsultantEvents = async () => {
        let prev_filters = appConf.scheduleFilters[1]?.filterData

        let consultant_ids = consultants.filter(item => item.name).map(item => item.id)
        let queryString = '';
        queryString += querystring.stringify(prev_filters)
        queryString = queryString += `&scheduledFor=${dayjs(date).format('YYYY-MM-DD')}`
        queryString += `&consultant_ids=[${consultant_ids}]`
        const response = await apiRequest.get(`/appointments-calendar?${queryString}`).catch(e => console.log(e))

        if (response && response.data.data) {
            let events = [...response.data.data].map(item => {
                return { ...item, start: dayjs(item.start_at).toDate(), end: dayjs(item.end_at).toDate(), "resourceId": item.consultant_name }
            })
            let consultants = _.uniqBy([...response.data.data].map(item => item.consultant), "id")
            // setconsultants(consultants)
            setevents(events)

            console.log(events, consultants)
        }
    }
    const [popopen, setPopOpen] = useState(false);
    const hide = () => {
        setPopOpen(false);
    };
    const handleOpenChange = (newOpen) => {
        setPopOpen(newOpen);
    };


    const addConsultant = (resourceId, consultant) => {
        console.log(resourceId, consultant)
        let newConsultants = [...consultants];
        newConsultants = newConsultants.map(item => {
            if (item.id === resourceId) {
                return consultant
            }
            return item;
        });

        console.log(newConsultants)
        setconsultants(newConsultants);
    }
    const onconsultantSelect = (id, values) => {
        console.log(id, values)
        let wcc = consultantListOptions.find(item => item.name === values)
        addConsultant(id, wcc)
    }

    const removeConsultant = (id) => {
        let idToFind = id;
        let newConsultants = [...consultants];
        let index = newConsultants.findIndex(obj => obj.id === idToFind);
        console.log(index)
        if (index !== -1) {
            newConsultants[index] = { id: (index + 1), name: null };
        }
        console.log(newConsultants)
        setconsultants(newConsultants);
    }


    const [consultantListOptions, setconsultantListOptions] = useState([])
    const getListAvailableConsultants = async (value, date, time_slot) => {

        const data = await apiRequest.get(`consultants?search=${value.trim()}`).catch(function (err) {
            console.log(err)

        })
        if (data && data.data) {
            console.log(data.data, data)
            // setconsultantOptions(data.data.data);
            setconsultantListOptions(data.data.data)

        }
    }

    return (
        <div className='bg-white h-full' >
            <Modal
              closable={false}
              closeIcon={null}
              title={Tabo()}
              destroyOnClose
              open={isModalOpen}
              onOk={handleOk} onCancel={handleCancel}
              width={600}>
                <AppointmentViewCard appointment={event} />
            </Modal>


            <Modal
                title={editingForm ? 'Edit appointment' : "New Appointment"}
                open={isOpen}
                destroyOnClose={true}
                onOk={dialogCloser(setOpen)}
                onCancel={dialogCloser(setOpen)}
                closeIcon={modalClose(dialogCloser(setOpen))}
                maskClosable={false}
                width={650}
                footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
                    {horStack([outlinedButton(dialogCloser(setOpen), 'Cancel', isExecuting),
                    formSubmitButton('appointmentForm', editingForm ? 'Update' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
                </div>]}>
                <SheduleAppointment handleFilterAction={fetchConsultantEvents} appointment={editingForm ? event : null} editingForm={editingForm} setisExecuting={setisExecuting} closeModal={dialogCloser(setOpen)} />
            </Modal>


            <Modal
                title={`Please add a Reason`}
                open={reasonModal}
                destroyOnClose={true}

                onOk={() => setreasonModal(false)}
                onCancel={() => setreasonModal(false)}
                closeIcon={modalClose(() => setreasonModal(false))}
                maskClosable={false}
                width={1200}
                footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
                    {horStack([outlinedButton(() => {
                        setreasonModal(false)
                        setSelected(0)
                    }, 'Cancel', isExecuting),
                    formSubmitButton('reasonForm', 'Add Reason', isExecuting, 'Updating')])}
                </div>]}
            >
                <Form
                    name="reasonForm"
                    onFinish={reasonFinish}
                    layout="vertical"
                >

                    <Form.Item
                        style={{ width: '100%' }}
                        label="Delete Reason"
                        name="delete_reason"
                        rules={[{ required: true, message: 'this field is required!' }]}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input autoComplete={'off'} />
                    </Form.Item>
                </Form>

            </Modal>

            <Calendar
                localizer={localizer}
                events={events}
                defaultView="day"
                toolbar={false}
                date={date}
                resources={consultants}
                resourceIdAccessor="name"
                resourceTitleAccessor={(resource, index) => {
                    return (
                        <div id={resource.id} key={resource.id} className='p-2'>

                            {resource.name ? <div className='flex  w-full flex-row justify-between items-center'>
                                <p>{resource.name}</p>
                                <CloseCircleFilled onClick={() => removeConsultant(resource.id)} style={{ color: 'gainsboro' }} />
                            </div> : <Popover
                                content={<div className='p-2'>
                                    <AutoComplete popupMatchSelectWidth={false}

                                        style={{ width: "200px", marginBottom: "20px" }}
                                        onSelect={(value) => {
                                            console.log("🤢", resource)
                                            onconsultantSelect(resource.id, value)
                                        }}

                                        onSearch={(value) => getListAvailableConsultants(value)}
                                        placeholder="search for consultants">
                                        {consultantListOptions.filter(item=>item.name).map(item => (
                                            <Option key={item.id} value={item.name}>{item.name} -{item.state} </Option>))}
                                    </AutoComplete>
                                </div>}
                                title="Search for consultant"
                                trigger="click"

                            >
                                <div id={resource.id} key={resource.id} className="flex flex-row justify-center items-center">
                                    <PlusCircleFilled style={{ color: 'teal' }} className='mr-1' />
                                    <p className='text-sm font-light'>Add</p>
                                </div>
                            </Popover>}
                        </div>
                    )

                }}
                components={{ event: CustomEvent }}
                startAccessor="start"
                endAccessor="end"

            />
        </div>
    )
}

const mapStateToProps = (state) => ({
     appointments: state.appointments.scheduledAppointments, appConf: state.appConf,
    activeUser: state.auth?.activeUser,


})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler)



// use this to add consultants
// import React, { useState } from 'react';
// import { Calendar, dayjsLocalizer } from 'react-big-calendar';
// import dayjs from 'dayjs';

// const localizer = dayjsLocalizer(dayjs);

// const initialConsultants = [null, null, null, null, null];

// const events = [
//     {
//         id: 1,
//         title: 'Event 1',
//         start: new Date(2022, 1, 10, 9, 0),
//         end: new Date(2022, 1, 10, 10, 0),
//         resourceId: 1
//     },
//     {
//         id: 2,
//         title: 'Event 2',
//         start: new Date(2022, 1, 10, 10, 0),
//         end: new Date(2022, 1, 10, 11, 0),
//         resourceId: 2
//     }
// ];

// function App() {
//     const [consultants, setConsultants] = useState(initialConsultants);

//     function addConsultant(resourceId, consultant) {
//         const newConsultants = [...consultants];
//         newConsultants[resourceId - 1] = consultant;
//         setConsultants(newConsultants);
//     }

//     return (
//         <>
//             {events.map(event => {
//                 if (!consultants[event.resourceId - 1]) {
//                     addConsultant(event.resourceId, { id: event.resourceId, name: `Consultant ${event.resourceId}` });
//                 }
//                 return null;
//             })}

//             <Calendar
//                 localizer={localizer}
//                 events={events}
//                 defaultView="day"
//                 resources={consultants}
//                 resourceIdAccessor="id"
//                 dayLayoutAlgorithm={(events, minimumStartDifference, slotMetrics, accessors) => {
//                     const layout = slotMetrics.map((slot, i) => {
//                         return {
//                             ...slot,
//                             header: (
//                                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                                     {consultants[i] && consultants[i].name}
//                                 </div>
//                             )
//                         };
//                     });
//                     return layout;
//                 }}
//                 eventPropGetter={event => {
//                     const resource = consultants.find(c => c.id === event.resourceId);
//                     return {
//                         style: {
//                             backgroundColor: resource.color
//                         }
//                     };
//                 }}
//             />
//         </>
//     );
// }

// export default App;
