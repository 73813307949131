import React, {useEffect, useState} from 'react';
import {Body2, Headline6, Subtitle1} from "../../../components";
import {addButtonClean, dialogCloser, emptyFun, executeChunkFn, formSubmitButton, horStack, kebabMenus, modalClose, outlinedButton, skelBars} from "../../../common/helpers";
import {Divider, Modal} from "antd";
import {connect, useDispatch} from "react-redux";

import {createPatient, deletePatientVitals, fetchPatientVitals, setVitalsLoaded, setVitalsLoading} from "../../../app/Reducers";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import Vitalsform from './patientSummaryForms/Vitalsform';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import KebabMenu from '../../../components/KebabMenu';
import {deleteReasonInputFields} from '../../../common/inputUtils';
import AuditLogViewer from '../../../components/AuditLogs/AuditLogViewer';
import DynamicModalForm from '../../../components/DynamicModalForm';
import * as Sentry from '@sentry/react';
import {errMsg, toastUp} from '../../../app/Reducers/reducerUtils';
import {useLocation, useNavigate} from 'react-router-dom';


const PatientVitals = ({ vitals, navs, patient, ...props }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [VitalsForm, setVitalsForm] = useState(false);
  const [isEdit, setIsEdit] = useState(!!vitals?.[0]);
  const [isExecuting, setisExecuting] = useState(false);
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const { message: vitalsMessage } = useSubscription(`view_patient_vitals/${patientId}`, { qos: 2, nl: true });
  
  
  const [selected, setSelected] = React.useState(null);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [actionMenuRecord, setActionMenuRecord] = useState(null);
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_create_patient_vitals = usePermissionCheck('create_patient_vitals', false, [])
  const can_delete_patient_vitals = usePermissionCheck('delete_patient_vitals', false, [])
  
  console.log({vitals})
  useEffect(() => {
    if (!!vitalsMessage) {
      console.log('MQTT MESSAGE:  ', vitalsMessage)
      dispatch(fetchPatientVitals(patientId))
    }
    return emptyFun
  }, [vitalsMessage])

  useEffect(() => {
    if (!!vitals && !!vitals?.[vitals?.length - 1]?.id && vitals?.[vitals?.length - 1]?.patient_id === patientId) {
      setIsEdit(true)
    }
    return emptyFun
  }
    , [vitals])
  

  useEffect(() => {
    (async () => {
      if (!!vitals && !!vitals?.[vitals?.length - 1]?.id && vitals?.[vitals?.length - 1]?.patient_id === patientId) {
        return
      }
      await dispatch(setVitalsLoading(true))
      await dispatch(fetchPatientVitals(patientId))
      await dispatch(setVitalsLoading(false))
      await dispatch(setVitalsLoaded(patientId))
    })().catch(e => console.log(e))
    return emptyFun
  }, [])
  
  //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
  let dynamicFormInputFields = { 'Delete Vital': deleteReasonInputFields }
  const actionMenus = kebabMenus(can_create_patient_vitals, can_view_audit_logs, can_delete_patient_vitals)
  const handleOk = async (record) => {
    await setActionMenuRecord(record)
    await setFormName('Delete Vital');
    await setDefaultDynamicModalFormValues(!!record ? { id: record.id,patientId } : undefined);
    await setDynamicFormOpen(true);
    setSelected(0)
  };
  
  const handleCancel = () => setSelected(0);
  const showPopconfirm = (id) => setSelected(id);
  
  async function kebabMenuClick(e, record) {
    // console.log({actionMenus, e, record, title: actionMenus[e.key].title })
    await setActionMenuRecord(record)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setVitalsForm(true)
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
    }
  }
  
  /**
   * This function is handling returned form entries from the dynamic form
   * */
  const onDynamicFormEntriesResult = async (entries) => {
    //console.log({entries})
    try {
      switch (formName) {
        case 'Delete Vital':
          await executeChunkFn(dispatch, deletePatientVitals, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen))
          break;
        default:
          return
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      toastUp(errMsg(e, false, formName), false);
    }
  };
  return navs.vitalsLoading ? skelBars() : <div className="card-box">
    
    {(!!actionMenuRecord && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false}
                                                                resourceDescription={`${patient.name}'s vitals`} resourceType={'Patient Vitals'}
                                                                defaultValues={{ auditableId: actionMenuRecord?.id, auditable: 'vitals', format: 'json' }}/>}
    {(isDynamicFormOpen && !!formName) && (
      <DynamicModalForm
        setDynamicFormOpen={setDynamicFormOpen}
        isDynamicFormOpen={isDynamicFormOpen}
        inputFields={dynamicFormInputFields[formName]}
        onDynamicFormEntriesResult={onDynamicFormEntriesResult}
        closeModal={dialogCloser(setDynamicFormOpen)}
        isExecuting={isExecuting}
        defaultValues={defaultDynamicModalFormValues}
        formName={formName}
      />
    )}
    <Modal
      title={ isEdit ? "Edit vitals" : "Add vitals"}
      open={VitalsForm}
      onOk={dialogCloser(setVitalsForm)}
      onCancel={dialogCloser(setVitalsForm)}
      closeIcon={modalClose(dialogCloser(setVitalsForm))}
      maskClosable={false}
      destroyOnClose
      width={700}
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        {horStack([outlinedButton(dialogCloser(setVitalsForm), 'Cancel'),
          formSubmitButton('vitals', isEdit ? 'Edit' : 'Add', isExecuting,  isEdit ? 'Editing' : 'Adding')])}
      </div>]}>
      <Vitalsform isEditForm={isEdit} fetchPatientVitals={fetchPatientVitals}  closeModal={dialogCloser(setVitalsForm)}/>
    </Modal>

   


    <div className="flex-boy-row-space" style={{ width: "100%" }}>
      <Headline6>Vitals</Headline6>
      {!!vitals?.[0] ?<div className="flex flex-row items-center">
        <KebabMenu menus={actionMenus}
                   recordItem={vitals?.[0]}
                   handleMenuClick={kebabMenuClick} resource={'patient vital'} handleOk={() => handleOk(vitals?.[0])} handleCancel={handleCancel} confirmLoading={confirmLoading}
                   showConfirmDialog={selected === vitals?.[0].id}/>
      </div> :
        <div className="flex flex-row items-center">
          {addButtonClean(() => {
           
           setVitalsForm(true)
          })}
          
        </div>
      
      }
    </div>
    <div className="flex-boy-row-space" style={{ width: "100%", alignItems: "normal" }}>
      <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Height:</Subtitle1>
      <div style={{ flex: 1 }}>
        <Body2 color="black" style={{ textAlign: 'left', flex: 1 }}>{vitals?.[0]?.height}</Body2>
      </div>
    </div>
    <Divider style={{ margin: 0 }}/>
    <div className="flex-boy-row-space" style={{ width: "100%", alignItems: "normal" }}>
      <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Weight:</Subtitle1>
      <div style={{ flex: 1 }}>
        <Body2 color="black" style={{ textAlign: 'left', flex: 1 }}>{vitals?.[0]?.weight}</Body2>
      </div>
    </div>
    <Divider style={{ margin: 0 }}/>
    <div className="flex-boy-row-space" style={{ width: "100%", alignItems: "normal" }}>
      <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Respiration:</Subtitle1>
      <div style={{ flex: 1 }}>
        <Body2 color="black" style={{ textAlign: 'left', flex: 1 }}>{vitals?.[0]?.respiration}</Body2>
      </div>
    </div>
    <Divider style={{ margin: 0 }}/>
    <div className="flex-boy-row-space" style={{ width: "100%", alignItems: "normal" }}>
      <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Heart Rate(BPM):</Subtitle1>
      <div style={{ flex: 1 }}>
        <Body2 color="black" style={{ textAlign: 'left', flex: 1 }}>{vitals?.[0]?.heart_rate}</Body2>
      </div>
    </div>
    <Divider style={{ margin: 0 }}/>
    {/*<div className="flex-boy-row-space" style={{width: "100%", alignItems: "normal"}}>*/}
    {/*  <Subtitle1 style={{textAlign: 'left', flex: 1}}>HRV:</Subtitle1>*/}
    {/*  <div style={{flex: 1}}>*/}
    {/*    <Body2 color="black" style={{textAlign: 'left', flex: 1}}>{vitals?.[vitals?.length -1]?.heart_rate_variability}</Body2>*/}
    {/*  </div>*/}
    {/*</div>*/}
    <Divider style={{ margin: 0 }}/>
    <div className="flex-boy-row-space" style={{ width: "100%", alignItems: "normal" }}>
      <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Blood Pressure (Systolic):</Subtitle1>
      <div style={{ flex: 1 }}>
        <Body2 color="black"
               style={{ textAlign: 'left', flex: 1 }}>{vitals?.[0]?.diastolic_blood_pressure}</Body2>
      </div>
    </div>
    <Divider style={{ margin: 0 }}/>
  </div>
};
const mapStateToProps = (state) => ({
  vitals: state.patients.vitals, patient: state.patients.selectedPatient, navs: state.navs
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PatientVitals)
