import { useEffect, useState } from "react";
import TinyTable from "./TinyTable";
import { Divider, Segmented, Spin } from "antd";
import { Headline6 } from "../../../components";
import { emptyFun } from "../../../common/helpers";
import { apiRequest } from "../../../app/Apis";
import { LeftOutlined } from "@ant-design/icons";
import LocationDetails from "./LocationDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FacilityLocation({ region }) {
  const [option, setOption] = useState("False")
  const [facilities, setFacilities] = useState([])
  const [loading, setLoading] = useState(false)
  const [navParams, setNavParams] = useState(null)
  const [step, setStep] = useState(0)
  const [facility, setFacility] = useState(null)
  const [loadingFacility, setLoadingFacility] = useState(false)
  const [total, setTotal] = useState(null)
  const defaultOptions = [
    { label: 'By Region', value: 'region' },
    { label: 'By State', value: 'state' }
  ];

  function onChangeLocation(option) {
    setOption(option)
  }

  async function fetchFacilities(option) {
    setLoading(true)
    let queryString = '';
    if (!!region && region !== '' && region !== undefined) {
      queryString = `&region=${region}`;
    }
    let optionString = option === "state" ? `?group_by=state` : "?group_by=region" // this will be used to filter by state or region, by default the endpoint brings by region
    const response = await apiRequest.get(`/facility_stats/location${optionString}${queryString}`)
    const res = response.data.data
    let newRes = res.filter((obj) => obj.label !== null && obj.label !== " Region")
    setFacilities(newRes)
    setLoading(false)
  }

  async function fetchOneFacility(value) {
    setLoadingFacility(true)
    let str = value?.toLowerCase()
    let queryString = option === "state" ? `?state=${str}` : `?region=${str}` // this will be used to filter by state or region, by default the endpoint brings by region
    const response = await apiRequest.get(`/facility_stats/${queryString}`)
    const res = response.data.data
    let newRes = res.filter((obj) => obj.label !== null && obj.label !== " Region")
    let tot = newRes?.reduce((acc, curr) => acc + curr.count, 0)
    setTotal(tot)
    console.log("One Office:", newRes)
    setFacility(newRes)
    setLoadingFacility(false)
  }

  useEffect(() => {
    fetchFacilities("region")
    return emptyFun
  }, [])
  useEffect(() => {
    fetchFacilities(option)
    return emptyFun
  }, [option])

  useEffect(() => {
    fetchFacilities(option, region)
    return emptyFun
  }, [region])

  useEffect(() => {
    if (navParams !== null && step === 1) {
      fetchOneFacility(navParams?.label)
    }
    return emptyFun
  }, [step, navParams])

  return (
    <div className="stats-card-box">

      {step === 0 ? <div style={{ width: '100%', padding: '5px' }}>
        <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>Offices by location</Headline6>
      </div> :
        <div className='flex-boy-row-space' style={{ width: '100%' }}>
          <div className="flex-boy-row" style={{ cursor: 'pointer' }}>
            <LeftOutlined style={{ marginRight: 30, marginTop: 10 }} onClick={() => setStep(0)} />
            <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>{navParams?.label || 'Unknown'}</Headline6>
          </div>
          <FontAwesomeIcon color="grey" icon={['fas', 'poll']} />
        </div>
      }
      <Divider style={{ margin: '4px', color: '#E2E4EA', width: '100%' }} />
      {step === 0 ?
        <div className="">
          <div className="flex justify-start p-4">
            <Segmented options={defaultOptions} onChange={(value) => onChangeLocation(value)} />
          </div>
          <div>
            <Spin spinning={loading}>
              <TinyTable patients={facilities} type="facility" setStep={setStep} setNavParams={setNavParams} />
            </Spin>
          </div>
        </div> :
        <div className="group">
          <Spin spinning={loadingFacility}>
            <div style={{ width: '100%' }}>
              <Headline6 style={{ fontWeight: 'bold', fontSize: '16px' }}>Total Offices</Headline6>
              <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>{total}</Headline6>
            </div>
            {facility && <LocationDetails option={option} setStep={setStep} type='facility' data={facility} />}
          </Spin>
        </div>
      }
    </div>
  )
}