import React, {useEffect, useState} from "react";
import {dialogCloser, emptyFun, sentenceCaseNoDash} from "../../../common/helpers";
import {connect, useDispatch} from "react-redux";
import {fetchSettings} from "../../../app/Reducers";
import {Headline6, Subtitle1} from "../../../components";
import {Button, Divider, Menu, Spin, Switch} from "antd";
import {apiRequest} from "../../../app/Apis";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import {FieldTimeOutlined} from '@ant-design/icons';
import AuditLogViewer from '../../../components/AuditLogs/AuditLogViewer';

const FeatureToggle = ({ settings, loading, activeUser }) => {
  const [pick, setPick] = useState("");
  const [toggling, setToggling] = useState(false)
  const [menuItems, setMenuItems] = useState([]);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  
  const { message } = useSubscription('view_feature_toggle', {
    qos: 2, nl: true
  })
  
  useEffect(() => {
    if (!!message) {
      (async () => {
        await dispatch(fetchSettings())
      })().catch(e => console.log("Failed Loading Message", e))
    }
    return emptyFun
  }, [message])
  
  const dispatch = useDispatch();
  useEffect(() => {
    //console.log("Fetching Settings in client::", settings)
    dispatch(fetchSettings());
    return emptyFun
  }, []);
  
  useEffect(() => {
    (async () => {
      if (settings) {
        //console.log("Client Settings:::", settings)
        const items = await constructItems();
        setMenuItems(items);
      }
    })();
    return emptyFun
  }, [settings]);
  
  async function constructItems() {
    const getItem = (label, key, icon, children, type) => ({ key, icon, children, label, type });
    const rt = [
      ...Object?.keys(settings).map((item) => {
        return getItem(
          <Subtitle1 style={{ float: "left" }}>{sentenceCaseNoDash(item)}</Subtitle1>,
          item,
          null,
          null,
          null
        );
      })
    ];
    // console.log("Constructed Settings::", rt)
    return rt
  }
  
  const handleClick = async (e) => {
    // console.log({e})
    const [category, item] = e.key.split("_");
    if (item) {
      setPick(`${category}.${item}`);
    } else {
      setPick(category);
    }
  };
  
  const handleSwitchChange = async (checked, group, key) => {
    setToggling(true);
    const toggleKey = `${group}.${key}`;
    const payload = {
      [toggleKey]: {
        enabled: checked ? "yes" : "no"
      }
    };
    
    const response = await apiRequest.post("/settings", payload);
    if (response.status === "200") {
      dispatch(fetchSettings());
      setToggling(false);
      // window.location.reload();
    }
    dispatch(fetchSettings());
    setToggling(false);
  };
  
  const renderSwitches = (pick) => {
    if (!pick || !settings[pick]) return null;
    
    const options = settings[pick];
    //console.log("options:::", options)
    return (
      <div className="w-full">
        {Object.keys(options).map((key) => {
          const option = options[key];
          const label = sentenceCaseNoDash(key);
          const checked = option['enabled'] === "yes";
          return (
            <div className="flex-boy-column my-3 w-full">
              <div key={key} className="flexy-row w-full">
                <p className={'text-sm font-semibold my-auto '}>{label}</p>
                <Switch className={'my-auto'}
                        checked={checked}
                        onChange={(e) => handleSwitchChange(e, pick, key)}
                        loading={toggling}
                />
              </div>
              <Divider orientation="horizontal"/>
            </div>
          );
        })}
      </div>
    );
  };
  
  
  return (
    <div className="w-full h-full flex flex-row">
      {( showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false}
                                                                  resourceDescription={`Feature Toggle`} resourceType={'Feature Toggle'}
                                                                  defaultValues={{  auditable: 'feature_toggle', format: 'json' }}/>}
      <div style={{ width: "150px" }}>
        <Spin spinning={loading} className="mb-10">
          <Menu
            items={menuItems}
            mode="inline"
            onClick={handleClick}
            style={{ width: "105%" }}
          />
        </Spin>
      </div>
      <Divider type="vertical" style={{ height: "100vh", color: "#696969" }}/>
      <div className={'flex-1  px-8 w-full'}>
        <div className={'flexy-row w-3/4'}>
          <Headline6 className="p-2">{sentenceCaseNoDash(pick) || "Feature Toggle"}</Headline6>
          <Button onClick={async () => {
            await setShowAuditDialog(true)
          }} type="dashed" icon={<FieldTimeOutlined/>}>Audit</Button></div>
        <div className="flex-boy-row-space w-3/4">
          {renderSwitches(pick)}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  settings: state?.settings?.settings,
  loading: state?.settings?.loadingSettings,
  activeUser: state.auth?.activeUser
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FeatureToggle);