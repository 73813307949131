import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, Form, Image, Image as ImageTag, Input, Modal, Radio, Select, Space, Steps, Upload } from 'antd';
import { ConsoleSqlOutlined, UploadOutlined } from '@ant-design/icons';
import { createFacility, updateFacilityDetails } from '../../../app/Reducers';
import { Body2, RegularButton, Subtitle1 } from '../../../components/general';
import { apiRequest } from '../../../app/Apis';
import MapGL from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Geocoder from 'react-map-gl-geocoder';
import { mapboxToken } from '../../../configure/constants';
import CreatableSelect from 'react-select/creatable';
import {
  appLog,
  dialogCloser,
  emptyFun,
  executeChunkFn,
  formSubmitButton,
  GetOptions,
  getPhoneParams,
  GetRawListOptions,
  horStack,
  inputRow,
  makeSelectOptions,
  modalClose,
  outlinedButton,
  showCurrentAddress
} from '../../../common/helpers';
import dayjs from 'dayjs';
import { errMsg, getToastId, updateToast } from '../../../app/Reducers/reducerUtils';
import _ from 'lodash';
import PreviewFacilityPane from './PreviewFacilityPane';
import * as Sentry from "@sentry/react";
import GetCookie from '../../../hooks/GetCookie';
import NumberFormat from "react-number-format";
import AppPhoneInput from '../../../components/InputComponents/AppPhoneInput';

const NewFacilityCard = ({ setisExecuting, closeModal, activeUser, facility, setIsPreview, isPreview }) => {
  const { Option } = Select;
  const { Step } = Steps;
  const dispatch = useDispatch();
  const [consultant, setSalesManager] = useState(facility ? facility?.sales_managers : []);
  const [clientSuccessManager, setClientSuccessManager] = useState(facility ? facility.client_success_managers : []);
  const [consultantOptions, setSalesManagerOptions] = useState([]);
  const uploadUrl = process.env.REACT_APP_ENDPOINT + '/files';
  const token = GetCookie('accessToken')
  const [facesheetForm, setFacesheetForm] = useState(false);
  const [faceForm] = Form.useForm();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);
  const [faceImages, setFaceImages] = useState([]);
  const [images, setImages] = useState([]);
  const [faceSheets, setFaceSheets] = useState([]);
  const [rawFaceSheet, setRawFaceSheet] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [phoneValue, setPhoneValue] = useState(!!facility ? { ...getPhoneParams(facility?.phone_no, 'phone_no') } : undefined);
  const [initialValues, setInitialValues] = useState(!!facility ?
    { ...facility, ...(!!facility?.phone_no && { ...getPhoneParams(facility?.phone_no, 'phone_no') }) } : {}
  );
  const salesmanagerRef = useRef();
  const csmRef = useRef();
  const [validationerrors, setvalidationerrors] = useState({
    sales_managers: false,
    address: false
  });
  const [pageStep, setPageStep] = useState(false);
  const [allValues, setAllValues] = useState(facility);
  const [additionalPhoneValue, setAdditionalPhoneValue] = useState(null);
  const [showOtherField, setShowOtherField] = useState(false);
  const masterList = useSelector((state) => state.masters.masterListRaw);
  const facilityType = form.getFieldValue('facility_type');

  useEffect(() => {
    if (facility) {
      console.log('initial facility:::', facility);
      facility.sales_managers?.forEach((item) =>
        handleConsultantssearch(item.name)
      );
      setSalesManager(facility.sales_managers);
      onManagerSelect(facility.sales_managers?.name);
      facility.client_success_managers?.forEach((item) => handleCSMsearch(item.name));
      setClientSuccessManager(facility.client_success_managers);
      onCSMSelect(facility.client_success_managers?.name);
    }
    console.log('res', clientSuccessManager);
    return emptyFun;
  }, []);

  useEffect(() => {
    console.log('Office Type:::', facilityType);
    if (facilityType === 'Other') {
      setShowOtherField(true)
    } else {
      setShowOtherField(false)
    }
  }, [facilityType])

  const onFinish = async (values) => {
    console.log('values', values);
    // console.log('phoneValue', phoneValue);
    setisExecuting(true);
    let errors = { ...validationerrors };
    let payload;
    payload = {
      ...values,
      documents: faceSheets,
      sales_managers: facility ? facility?.sales_managers?.map((item) => item.id) : consultant?.map((item) => item.user_id),
      client_success_managers: facility
        ? facility.client_success_managers?.map((item) => item.id)
        : clientSuccessManager?.map((item) => item.user_id),
      additional_emails: values?.additional_emails?.map((item) => item.value),
      additional_phone_numbers: values?.additional_phone_numbers?.map(
        (item) => item.value
      ),
      ...phoneValue
    };
    if (facility) {

      consultant?.forEach((item) => {
        handleConsultantssearch(item.name)
      })
      console.log("Preflight::", consultant)
      payload = {
        ...payload,
        ...values,
        id: facility.id,
        additional_emails: values?.additional_emails?.map((item) => item.value),
        additional_phone_numbers: values?.additional_phone_numbers?.map((item) => item.value),
        client_success_managers: clientSuccessManager?.map((item) => item.user_id),
        sales_managers: consultant?.map(item => item.user_id || item.id),
        ...phoneValue
      };

      console.log('payload facility:::', payload);
      if (facility.address || locationData?.address) {
        errors.address = false;
        setvalidationerrors(errors);
        payload.documents = [
          ...faceSheets,
          ...facility.documents.map((item) => item.id)
        ];
        const newpayload = { ...locationData, ...payload };
        payload = newpayload
      } else {
        errors.address = true;
        setvalidationerrors(errors);
        payload = null;
        await setisExecuting(false);
      }
    }
    if (locationData?.address  || values?.address) {
      errors.address = false;
      setvalidationerrors(errors);
      payload.documents = [
        ...faceSheets
      ];
      const newpayload = { ...locationData, ...payload };
      payload = newpayload
    } else {
      errors.address = true;
      setvalidationerrors(errors);
      payload = null;
      await setisExecuting(false);
    }

    if (payload && Object.keys(payload).length) {
      await executeChunkFn(
        dispatch,
        facility ? updateFacilityDetails : createFacility,
        payload,
        setisExecuting,
        closeModal
      );
    }
  };
  const [documents, setdocuments] = useState([]);
  const onPreview = async (file) => {
    console.log(file);

    if (file.type === 'image/jpeg') {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow.document.write(image.outerHTML);
    } else {
      appLog('wueh!');
    }
  };
  const handleConsultantssearch = async (value) => {
    let new_depth = [...consultantOptions];
    const { data } = await apiRequest.get(
      `/consultants?search=${value}&form=facility`
    );
    if (data) {
      console.log(data.data);
      new_depth = [...new_depth, ...data.data];
      console.log('new_depth:', new_depth)
      setSalesManagerOptions(_.uniqBy(new_depth, 'id'));
    }
  };
  const handleCSMsearch = async (value) => {
    let new_depth = [...consultantOptions];
    const { data } = await apiRequest.get(
      `/consultants?search=${value}&form=facility`
    );
    if (data) {
      console.log(data.data);
      new_depth = [...new_depth, ...data.data];
      setClientSuccessManager(_.uniqBy(new_depth, 'id'));
    }
  }
  const onManagerSelect = (value) => {
    if (value) {
      let wcc = value.map((item) =>
        consultantOptions.find((element) => element.name === item.toString())
      );
      console.log("Office WCC::", wcc);
      setSalesManager(wcc.length > 0 ? wcc.filter((item) => item) : []);
    }
  };

  const onCSMSelect = (value) => {
    if (value) {
      console.log("CSM Value::", value);
      // only add new CSMs to the list if value is not empty 
      let newCSM = value?.length ? [...clientSuccessManager] : [];
      value.forEach(item => {
        if (!newCSM.some(e => e.name === item.toString())) {
          let found = consultantOptions.find(element => element.name === item.toString());
          if (found) {
            newCSM.push(found);
          }
        }
      });
      setClientSuccessManager(newCSM);
    }
  }

  //  map stuff
  const [viewport, setViewport] = useState({
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 8
  });
  const [locationData, setLocationData] = useState(null);
  const geocoderContainerRef = useRef();
  const mapRef = useRef();
  const handleViewportChange = useCallback(
    (newViewport) => setViewport(newViewport),
    []
  );
  const onResult = useCallback((result) => {
    // console.log(result);
    let center = result.result.center;
    let context = result.result.context;
    let state = context.find((item) => item.id.includes('region'));
    let city = context.find((item) => item.id.includes('place'));
    let zip_code = context.find((item) => item.id.includes('postcode'));
    let data = {
      city: city ? city.text : result.result.place_name,
      state: state ? state.text : null,
      zip_code: zip_code ? zip_code.text : result.result.place_name,
      co_ordinates: `${center[0]},${center[1]}`,
      address: result.result.place_name
    };
    setLocationData(data);
    form.setFieldsValue({
      address: result.result.place_name,
      city: city ? city.text : null,
      state: state ? state.text : null,
      zip_code: zip_code ? zip_code.text : null
    });
  }, []);
  const createFaceSheet = async (payload) => {
    console.log('facesheet payload', payload);
    const toastId = getToastId('Adding Documents');
    try {
      const response = await apiRequest.post(`/documents`, payload);
      console.log('createFaceSheet: ', response.data.data);
      if (response && response.data && response.data.data) {
        updateToast(toastId, 'Document added successfully', true);
        setRawFaceSheet([...rawFaceSheet, response.data.data]);
        setFaceSheets([...faceSheets, response.data.data.id]);
        setFaceImages([...faceImages, response.data.data]);
      }
    } catch (e) {
      Sentry.captureException(e);
      updateToast(toastId, errMsg(e, false), false);
    }
  };
  const onFacesheetChange = (file) => {
    let { fileList: newFileList } = file;
    if (newFileList.length === 0) {
      faceForm.setFieldsValue({ file_id: undefined });
      return setFileList([]);
    }
    setFileList(
      newFileList.map((file) =>
        file.response
          ? {
            uid: file.uid,
            status: file.status,
            url: file.response.data.url,
            thumbUrl: file.thumbUrl
          }
          : file
      )
    );
    let file_ = newFileList.find((item) => !!item.response);
    faceForm.setFieldsValue({
      file_id: file_ ? file_.response.data.id : undefined
    });
  };

  const onFaceFinish = async (values) => {
    console.log('onFaceFinish: ', values);
    await setSubmitting(true);
    await createFaceSheet(values);
    fileList.length = 0;
    await setSubmitting(false);
    setFacesheetForm(false);

    faceForm.resetFields();
    console.log('onFaceFinish new Values::', values);
  };
  const disabledPastDates = (current) => {
    let customDate = dayjs();
    return current && current < dayjs(customDate, 'YYYY-MM-DD');
  };
  const removeImage = (image, index) => {
    const arr = faceSheets.filter((i) => i !== image.id);
    setImages(faceImages.splice(index, 1));
    setFaceSheets(arr);
  };
  const NoOptionsMessage = () => null;

  const NumberSelectOption = ({ innerProps, isDisabled }) => {
    !isDisabled && (
      <div {...innerProps}>
        <AppPhoneInput
          name={'additional_phone_number'}
          setPhoneValue={setAdditionalPhoneValue}
          phoneValue={additionalPhoneValue}
          formItemProps={{ required: true }}
        />
      </div>
    );
  };
  const [current, setCurrent] = useState(0);
  const steps = [
    {
      title: 'Fill In Office Information'
    },
    {
      title: 'Preview Office Information'
    }
  ];

  const next = async () => {
    await form.validateFields();
    let payload;
    payload = {
      ...allValues,
      documents: rawFaceSheet,
      sales_managers: consultant,
      additional_emails: allValues?.additional_emails?.some((item) => item?.value) ? allValues.additional_emails?.map((item) => item?.value)
        : facility?.additional_emails,
      additional_phone_numbers: allValues?.additional_phone_numbers?.some((item) => item?.value)
        ? allValues?.additional_phone_numbers?.map((item) => item.value)
        : facility?.additional_phone_numbers,
      address: locationData?.address || allValues?.address,
      ...phoneValue
    };
    setAllValues(payload);
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };


  // logo upload
  const [logoFileList, setLogoFileList] = useState([]);
  const onLogoChange = ({ fileList: newFileList }) => {
    newFileList = newFileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
        form.setFieldsValue({ logo_id: file.response.data.id })
      }
      return file;
    });
    setLogoFileList(newFileList.map(file => file.response ? {
      uid: file.uid,
      status: file.status,
      url: file.response.data.url,
      thumbUrl: file.thumbUrl
    } : file))
  };

  function onCancelDocumentUpload() {
    faceForm.resetFields();
    setFileList([]);
    setFacesheetForm(false);
  }

  return <>
    <Modal
      title="Add Document"
      open={facesheetForm}
      onOk={dialogCloser(setFacesheetForm)}
      onCancel={() => console.log('onCancel...')}
      closeIcon={modalClose(dialogCloser(setFacesheetForm))}
      maskClosable={false}
      destroyOnClose
      footer={[
        <div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }} >
          {horStack([outlinedButton(onCancelDocumentUpload, 'Cancel', isSubmitting), formSubmitButton('faceSheetForm', 'Add', isSubmitting, 'Adding')])}
        </div>
      ]}
    >
      <Form
        form={faceForm}
        name="faceSheetForm"
        id="faceSheetForm"
        labelCol={{ span: 16 }}
        layout="vertical"
        scrollToFirstError={true}
        onFinish={onFaceFinish}
      >
        <Form.Item
          onChange={() =>
            faceForm.setFieldsValue({ name: faceForm.getFieldValue('title') })
          }
          name="title"
          label="Document Title"
          rules={[{ required: true, message: 'Missing Document title' }]}
        >
          <Input autoComplete={'off'} placeholder="" />
        </Form.Item>
        <Form.Item
          style={{ width: '100%', display: 'none' }}
          name="name"
          label="Document Name"
        >
          <Input autoComplete={'off'} placeholder="" />
        </Form.Item>
        <div className="flex-boy-row-space">
          <Form.Item
            style={{ width: '100%' }}
            name="type"
            label="Document Type"
            rules={[{ required: true, message: 'Missing Document type' }]}
          >
            <Select
              popupMatchSelectWidth={false}
              style={{ width: '100%' }}>
              {GetOptions('document_type')}
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          style={{ width: '100%' }}
          name="file_id"
          label="Documents"
          rules={[{ required: true, message: 'Missing Document' }]}
        >
          <Upload
            accept="image/*,.pdf"
            action={uploadUrl}
            onPreview={onPreview}
            headers={{ Authorization: `Bearer ${token}` }}
            fileList={[...fileList]}
            onChange={onFacesheetChange}
            listType="picture-card"
            // listType="text"
            maxCount={5}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
          <Input
            autoComplete={'off'}
            style={{ width: '100%', display: 'none' }}
          />
        </Form.Item>
      </Form>
    </Modal>
    <Steps current={current} responsive="true">
      {steps.map((item) => (
        <Step key={item.title} title={item.title} />
      ))}
    </Steps>
    <div style={{ marginTop: '10px', padding: '5px' }}>
      <Form
        name="facilityForm"
        form={form}
        id="facilityForm"
        labelCol={{ span: 24 }}
        onValuesChange={(changedValues, allValues) => {
          console.log('changedValues:', changedValues);
          setAllValues(allValues);
        }}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          ...initialValues,
          additional_emails: facility?.additional_emails?.map((tag) => ({
            value: tag,
            label: tag
          })),
          additional_phone_numbers: facility?.additional_phone_numbers?.map(
            (tag) => ({ value: tag, label: tag })
          ),
          ...(facility &&
            facility.sales_managers && {
            sales_managers: facility.sales_managers.map(
              (item) => item.name
            )
          }),
          ...(facility &&
            facility.client_success_managers && {
            client_success_managers: facility.client_success_managers.map(
              (item) => item.name
            )
          }),
        }}
      >
        <div style={{ display: current === 1 && 'none' }}>
          <div ref={salesmanagerRef}></div>
          {activeUser?.facilityProfile ? null : (
            <Form.Item
              name="sales_managers"
              label="Sales Manager"
              style={{ width: '100%' }}
              rules={[{ required: false, message: 'Missing Sales Manager' }]}
            >
              <Select
                popupMatchSelectWidth={false}
                value={consultantOptions?.map((item) => item.name)}
                style={{ width: '100%' }}
                // onSelect={onManagerSelect}
                onChange={(e) => onManagerSelect(e)}
                mode="multiple"
                onSearch={handleConsultantssearch}
                placeholder="Search for Sales Manager"
              >
                {consultantOptions.map((item) => (
                  <Option key={item.id} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {validationerrors.sales_managers ? (
            <Body2 color="crimson">Please add a valid sales manager!</Body2>
          ) : null}
          {activeUser?.facilityProfile ? null : (
            <Form.Item
              name="client_success_managers"
              label="Client Success Manager"
              style={{ width: '100%' }}
              initialValue={clientSuccessManager}
              rules={[{ required: false, message: 'Missing Client Success Manager' }]}>
              <Select
                popupMatchSelectWidth={false}
                value={consultantOptions?.map((item) => item.name)}
                style={{ width: '100%' }}
                // onSelect={onManagerSelect}
                onChange={(e) => onCSMSelect(e)}
                mode="multiple"
                onSearch={handleConsultantssearch}
                placeholder="Search for Client Success Manager"
              >
                {consultantOptions.map((item) => (
                  <Option key={item.id} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {validationerrors.sales_managers ? (
            <Body2 color="crimson">Please add a valid client success manager!</Body2>
          ) : null}
          <Form.Item
            style={{ width: '100%' }}
            name="name"
            label="Office Name"
            rules={[{ required: true, message: 'Missing Office name' }]}
          >
            <Input autoComplete={'off'} placeholder="Office Name" />
          </Form.Item>
          {inputRow([
            <Form.Item
              style={{ width: '100%' }}
              name="title"
              label="Title"
              rules={[{ required: false, message: 'Missing Contact Person Title' }]}
            >
              <Input autoComplete={'off'} placeholder="Title" />
            </Form.Item>
            ,
            <Form.Item
              style={{ width: '100%' }}
              name="primary_contact_name"
              label="Contact Person"
              rules={[{ required: true, message: 'Missing Contact Person' }]}
            >
              <Input autoComplete={'off'} placeholder="Contact Person" />
            </Form.Item>
          ])}
          <Form.Item
            name="facility_type"
            label="Office Type"
            rules={[{ required: true, message: 'Please pick a type' }]}
          >
            <Radio.Group>
              <Space direction="vertical">
                {/* <Radio value='Hospice Care'>Hospice Care</Radio>
                <Radio value='Palliative Care'>Palliative Care</Radio>
                <Radio value='SNF'>SNF</Radio>
                <Radio value='Home Health'>Home Health</Radio>
                <Radio value={'Home Health & Hospice Care'}>
                  Home Health & Hospice Care
                </Radio> */}
                {masterList
                  .find((item) => item.system_name === 'facility_type')
                  ?.options?.map((item) => (
                    <Radio value={item}>
                      {item
                        .toString()
                        .replace('-', '')
                        .replace(/_/g, ' ')
                        .replace(/^\w/, (c) => c.toUpperCase())}
                    </Radio>
                  )) || []}
                {/* <Radio value={'other'}>Other</Radio> */}
                {showOtherField &&
                  <Form.Item
                    style={{ width: '100%' }}
                    name="specify"
                    label="Other Office Type"
                    rules={[{ required: true, message: 'Missing Office Type' }]}>
                    <Input autoComplete={'off'} placeholder="Office Type" />
                  </Form.Item>
                }
              </Space>

            </Radio.Group>
          </Form.Item>
          <Form.Item
            style={{ width: '100%' }}
            name="file_id"
            label="Office Documents"
          >
            {/* <Upload
          action={uploadUrl}
          headers={{ 'Authorization': `Bearer ${token}` }}

          defaultFileList={[...documents]}
          onChange={onFacesheetChange}

        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload> */}
            {!faceImages.length ? (
              <Button
                style={{ marginTop: 10 }}
                onClick={() => setFacesheetForm(true)}
              >
                Add Office document
              </Button>
            ) : (
              <>
                <ImageTag.PreviewGroup>
                  {faceImages.map((item, index) => (
                    <div
                      className="flex-boy-row-space"
                      style={{
                        width: '100%',
                        maxHeight: 100,
                        marginBottom: 10
                      }}
                      key={index}
                    >
                      <span>{item.title}</span>
                      <FontAwesomeIcon
                        onClick={() => removeImage(item, index)}
                        color="grey"
                        icon={['fas', 'trash']}
                      />
                    </div>
                  ))}
                </ImageTag.PreviewGroup>
                <Button
                  style={{ marginTop: 10, position: 'relative' }}
                  onClick={() => setFacesheetForm(true)}
                >
                  Add Office document
                </Button>{' '}
              </>
            )}
          </Form.Item>
          <Form.Item
            label="Referral Organization"
            name="referral_source"
            rules={[{ required: true, message: 'Missing Referral Organization' }]}>
            <Select placeholder="Select Referral Organization"
              options={makeSelectOptions(GetRawListOptions('referral_organization'))} >
            </Select>
          </Form.Item>
          <Subtitle1
            className="Validator"
            style={{ textAlign: 'left', marginBottom: '10px' }}>
            Address search
          </Subtitle1>
          <Form.Item
            style={{ width: '100%' }}
            name="default_region"
            label=""
            rules={[{ required: false, message: 'Missing Region' }]}>
            <div ref={geocoderContainerRef}
              style={{ position: 'absolute', top: 0, left: 0, zIndex: 1, width: '100%' }} />
            <MapGL
              ref={mapRef}
              {...viewport}
              width="100%"
              height="100%"
              onViewportChange={handleViewportChange}
              mapboxApiAccessToken={mapboxToken}
            >
              <Geocoder
                mapRef={mapRef}
                containerRef={geocoderContainerRef}
                onViewportChange={handleViewportChange}
                mapboxApiAccessToken={mapboxToken}
                countries="us,ke"
                onResult={onResult}
                marker={false}
                onError={onResult}
              />
            </MapGL>
          </Form.Item>
          {validationerrors.address ? (<Body2 color="crimson">Please add an address!</Body2>) : null}
          {/* {showCurrentAddress(initialValues?.address)} */}
          {(locationData || initialValues.address) && <>
            <Form.Item
              style={{ width: '100%', marginTop: '10px' }}
              name="address"
              label="Address"
              rules={[{ required: true, message: 'Missing location' }]}>
              <Input autoComplete={'off'} placeholder="Address" />
            </Form.Item>
            {inputRow([
              <Form.Item
                style={{ width: '100%' }}
                name="state"
                label="State"
                rules={[{ required: true, message: 'Missing State' }]}>
                <Input autoComplete={'off'} placeholder="State" />
              </Form.Item>,
              <Form.Item
                style={{ width: '100%' }}
                name="city"
                label="City"
                rules={[{ required: true, message: 'Missing City' }]}>
                <Input autoComplete={'off'} placeholder="City" />
              </Form.Item>,
              <Form.Item
                style={{ width: '100%' }}
                name="zip_code"
                label="Zip Code"
                rules={[{ required: true, message: 'Missing Zip code' }
                ]}>
                <Input autoComplete={'off'} placeholder="zip" />
              </Form.Item>
            ])
            }</>}
          <Form.Item label="Address 2" name="address_2">
            <Input
              autoComplete={'off'}
              placeholder="App, suite, unit, building, floor, etc"
            />
          </Form.Item>
          {inputRow([
            <Form.Item
              style={{ width: '100%' }}
              name="email"
              label="Primary Email"
              rules={[{ required: true, message: 'Missing Email' }]}
            >
              <Input autoComplete={'off'} placeholder="Email" type="email" />
            </Form.Item>,
            <AppPhoneInput
              name={'phone_no'}
              setPhoneValue={setPhoneValue}
              phoneValue={phoneValue}
              formItemProps={{ required: true }}
            />
          ])}
          {inputRow([
            <Form.Item
              style={{ width: '100%', marginRight: '5px' }}
              name="additional_emails"
              label="Additional Emails"
              rules={[{ required: false, message: 'Missing Email' }]}
              components={{ NoOptionsMessage }}
            >
              <CreatableSelect
                isMulti
                placeholder={'Add more emails'}
                components={{ NumberSelectOption }}
                defaultValue={facility?.additional_emails?.map((tag) => ({
                  value: tag,
                  label: tag
                }))}
              />
            </Form.Item>,
            <Form.Item
              style={{ width: '100%' }}
              name="additional_phone_numbers"
              label="Additional Phone Numbers"
              rules={[{ required: false, message: 'Missing Phone Number' }]}
            >
              {/*<NumberFormat autoComplete={'off'} customInput={Input} format="+(#)(###) ###-####"
                      placeholder='Add more Phone numbers'/>*/}
              <CreatableSelect
                isMulti
                placeholder={'Add more Phone numbers'}
                components={{ NumberSelectOption }}
                defaultValue={facility?.additional_phone_numbers?.map((tag) => ({ value: tag, label: tag }))}
              />
            </Form.Item>
          ])}
          <div className="flex-boy-row-space">
            <Form.Item
              style={{ width: '100%' }}
              name="fax"
              label="Fax"
              rules={[{ required: false, message: 'Missing Fax' }]}
            >
              <NumberFormat
                autoComplete={'off'}
                customInput={Input}
                format="+# (###) ### ####"
                placeholder="Fax"
              />
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              name="website"
              label="Website"
            // rules={[{ required: true, message: 'Missing Website' }]}
            >
              <Input
                autoComplete={'off'}
                defaultValue={'https://'}
                placeholder="http:// or https://"
              />
            </Form.Item>
          </div>

          <Form.Item
            style={{ width: '100%' }}
            name="logo_id"
            rules={[{ required: false, message: 'Missing Document' }]}
            label="Logo">
            <Upload accept="image/*"
              action={uploadUrl}
              listType="picture"
              headers={{ 'Authorization': `Bearer ${token}` }}
              defaultFileList={[...logoFileList]}
              onChange={onLogoChange}
              maxCount={1}>
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            <Input autoComplete={'off'} style={{ width: '100%', display: 'none' }} />
          </Form.Item>

          {initialValues?.logo && <div className="flex flex-col">
            <Subtitle1 style={{ textAlign: 'left' }}>Current Logo</Subtitle1>
            <Image width={200} src={initialValues?.logo?.logo_url} />
          </div>}
        </div>
        <div style={{ display: current === 0 && 'none' }}>
          <PreviewFacilityPane
            facility={{ ...allValues, documents: [...(!!allValues?.documents ? allValues?.documents : []), ...(!!facility?.documents ? facility?.documents : [])] }} />
        </div>
      </Form>
    </div>
    <div className="" style={{ padding: '10px' }}>
      {current < steps.length - 1 && (
        <RegularButton type="primary" onClick={() => next()}>
          Preview Office Information
        </RegularButton>
      )}
      {current > 0 && (
        <RegularButton type="primary" onClick={() => prev()}>
          Edit Office Information
        </RegularButton>
      )}
    </div>
  </>;
};
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser
  // facilities: state.facility.facility,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(NewFacilityCard);
