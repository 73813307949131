/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Col, Modal, Row, Select } from 'antd';
import { Headline6, Indicator, Subtitle1 } from '../../components';
// import { data } from './dummyData'
import NewFacility from './components/NewFacility';
// import './sigCanvas.css';
import { Bar, BarChart, CartesianGrid, YAxis } from 'recharts';
import { dialogCloser, emptyFun, formSubmitButton, horStack, kebabMenus, boldKeyCapitalizedValue, modalClose, outlinedButton } from '../../common/helpers';
import FaclityPatients from './components/FaclityPatients';
import { fetchFacilityById, fetchFacilityNotes } from '../../app/Reducers';
import KebabMenu from '../../components/KebabMenu';
import FacilityDocument from './FacilityDocument';
import AuditLogViewer from '../../components/AuditLogs/AuditLogViewer';
import { useSubscription } from '../../hooks/MqttHooks/useSubscription';
import FacilityNotes from './components/FacilityNotes';
import { usePermissionCheck } from '../../hooks/usePermissionCheck';
import dayjs from 'dayjs';

export const FacilitySummary = ({
  
  facility,
  appointments,

  activeUser,
  facilityNotes,
  loadingFacilityNotes
}) => {
  const dispatch = useDispatch();
  const [facilityModal, setfacilityModal] = useState(false);
  const data = [{ ...facility.stats }];
  const [isExecuting, setisExecuting] = useState(false);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_medical_facility_admin = usePermissionCheck('update_medical_facility_admin', false, [])

  const { Option } = Select;

  const { message } = useSubscription(`view_medical_facility/${facility.id}`, {
    qos: 2,
    nl: true
  });
  console.log({facility})
  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message);
      dispatch(fetchFacilityById(facility.id));
    }
    return emptyFun;
  }, [message]);

  useEffect(() => {
    console.log('Office:::', facility);
    dispatch(fetchFacilityNotes({ id: facility.id }));
  }, [facility]);

  const actionMenus = kebabMenus(
    can_update_medical_facility_admin,
    can_view_audit_logs,
    false
  );

  async function kebabMenuClick(e, doc) {
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setfacilityModal(true);
        break;
      case 'Audit':
        setShowAuditDialog(true);
        break;
      default:
        return;
    }
  }

  /*  const menu = (item) => {
      return (
        <Menu onClick={(e) => handleMenuClick(e, item)}>
          {editMenus.map((item, index) => (
            <Menu.Item key={index} icon={item.icon}>
              {' '}
              {item.title}{' '}
            </Menu.Item>
          ))}
        </Menu>
      );
    };*/

  return (
    (<div className="h-full flex flex-col px-4">
      {showAuditDialog && (
        <AuditLogViewer
          visible={showAuditDialog}
          onCancel={dialogCloser(setShowAuditDialog)}
          loading={false}
          resourceDescription={facility.name}
          resourceType={'Office'}
          defaultValues={{
            auditableId: facility.id,
            auditable: 'facility',
            format: 'json'
          }}
        />
      )}
      <Modal
        title="Edit Office Details"
        open={facilityModal}
        onCancel={dialogCloser(setfacilityModal)}
        closeIcon={modalClose(dialogCloser(setfacilityModal))}
        destroyOnClose
        maskClosable={false}
        width={600}
        footer={[
          <div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([
              outlinedButton(dialogCloser(setfacilityModal), 'Cancel', isExecuting),
              formSubmitButton('facilityForm', facility ? 'Update' : 'Create', isExecuting, 'Saving')
            ])}
          </div>
        ]}
      >
        <NewFacility setisExecuting={setisExecuting} closeModal={dialogCloser(setfacilityModal)} facility={facility} />
      </Modal>
      <Row gutter={[16,4]} className={'h-full w-full'}>
        <Col span={16} className={'h-full'}>
            <div className="shadow-card-box">
              <div className="flex-boy-row-space" style={{ width: '100%' }}>
                <Headline6>Primary Information</Headline6>
                {can_update_medical_facility_admin && (
                  /*<IconButton
                    type={'button'}
                    size={'small'}
                    onClick={dialogOpener(setfacilityModal)}
                  >
                    <EditOutlined fontSize={'small'}/>
                  </IconButton>*/

                  (<KebabMenu
                    menus={actionMenus}
                    recordItem={facility}
                    handleMenuClick={kebabMenuClick}
                    resource={'facility'}
                    handleOk={null}
                    handleCancel={null}
                    confirmLoading={null}
                    showConfirmDialog={false}
                  />)
                )}
              </div>
              <Row gutter={48} className={'w-full'}>
                <Col span={12}>
                  {boldKeyCapitalizedValue('Name', facility.name)}
                  {boldKeyCapitalizedValue('Created At', dayjs(facility?.created_at).format('MM/DD/YYYY'))}
                  {!activeUser?.facilityProfile && boldKeyCapitalizedValue('Sales Manager', facility.sales_managers?.map((item) => item.name).toString() || 'N/A')}
                  {!activeUser?.facilityProfile && boldKeyCapitalizedValue('Client Success Manager', facility.client_success_managers?.map((item) => item.name).toString() || 'N/A') }
                  {boldKeyCapitalizedValue('Type', facility?.facility_type === 'Other' ? facility?.specify : facility?.facility_type)}
                  {boldKeyCapitalizedValue('ID', facility.id)}
                  {boldKeyCapitalizedValue('Contact Person Title', facility.title || 'N/A')}
                  {boldKeyCapitalizedValue('Contact Person', facility.primary_contact_name)}
                  {boldKeyCapitalizedValue('Phone Number', facility.phone_no)}
                  {boldKeyCapitalizedValue('Additional Phone Numbers', facility.additional_phone_numbers?.toString() || 'N/A')}
                  {boldKeyCapitalizedValue('Referral Organization', facility.referral_source || 'N/A')}
                </Col>
                <Col span={12}>
                  {boldKeyCapitalizedValue('Primary Email', facility.email)}
                  {boldKeyCapitalizedValue('Additional Emails', facility.additional_emails?.toString() || 'N/A')}
                  {boldKeyCapitalizedValue('Website', facility.website || 'N/A')}
                  {boldKeyCapitalizedValue('Fax', facility.fax || 'N/A')}
                  {boldKeyCapitalizedValue('Address', `${facility.address},${facility.zip_code}, ${facility.city}, ${facility.state}`)}
                  {facility.address_2 && boldKeyCapitalizedValue('Address 2', facility.address_2)}
                  {boldKeyCapitalizedValue('City', facility.city || 'N/A')}
                  {boldKeyCapitalizedValue('Zip', facility.zip_code || 'N/A')}
                  {boldKeyCapitalizedValue('State', facility.state)}
                </Col>
              </Row>
            </div>
          <div className="shadow-card-box">
            <FaclityPatients />
          </div>
        </Col>
        <Col span={8} className={'h-full'}>
          <div className="shadow-card-box">
            <div className="flex-boy-row-space w-full" >
              <Headline6>Stats</Headline6>
            </div>
            <BarChart width={200} height={200} style={{ width: '100%' }} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis tickCount={Math.max(data[0].prospects_count , data[0].patients_count)} />
              <Bar dataKey="prospects_count" fill="#8884d8" label={"Referrals Count"} />
              <Bar dataKey="patients_count" fill="#82ca9d" label={"Patients Count"} />
            </BarChart>
            <div className="flex-boy-row-space">
              <div className="status-container">
                <Indicator color={'#8884d8'} />
                <Subtitle1> Referrals </Subtitle1>
              </div>
              <div className="status-container">
                <Indicator color={'#82ca9d'} />
                <Subtitle1>Patients</Subtitle1>
              </div>
            </div>
          </div>
          <FacilityNotes setisExecuting={setisExecuting} closeModal={dialogCloser(setfacilityModal)} facility={facility} notes={facilityNotes} loadingNotes={loadingFacilityNotes} />
          <FacilityDocument />
        </Col>
      </Row>
    </div>)
  );
};
const mapStateToProps = (state) => ({
  facility: state.facilities.selectedFacility,
  appointments: state.consultants.consultantSchedule,
  activeUser: state.auth?.activeUser,
  facilityNotes: state.facilities.facilityNotes,
  loadingFacilityNotes: state.facilities.loadingFacilityNotes
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(FacilitySummary);
