/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Body2, Headline6, RegularButton, Subtitle1} from '../../components'
import {Avatar, Button, Divider, Form, Image, Input, Menu, Modal, Spin, Upload} from 'antd';
import HeaderTop from '../../components/HeaderTop'
import ProviderSummary from './components/ProviderSummary'
import Supplies from './components/Supplies'
import ProviderAvailabilty from './components/ProviderAvailabilty'
import {useLocation, useNavigate} from "react-router-dom";
import {fetchProvider, setConsultantNavs, updateConsultant, updateProvider} from '../../app/Reducers';
import {closeDialog, emptyFun, formSubmitButton, getHeaderLeftNav, horStack, outlinedButton} from "../../common/helpers";
import {Subtitle2} from '../../components';
import {apiRequest} from '../../app/Apis'
import {LoadingOutlined, UploadOutlined} from '@ant-design/icons';
import {toast} from 'react-toastify';
import GetCookie from '../../hooks/GetCookie';
import _ from 'lodash'
import {usePermissionCheck} from '../../hooks/usePermissionCheck';

export const ProviderPage = ({ provider, navs, activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const uploadUrl = process.env.REACT_APP_ENDPOINT + '/files'
  const token = GetCookie('accessToken')
  const can_view_availability_schedule = usePermissionCheck('view_availability_schedule', false, [])
  const TabList = can_view_availability_schedule ? ["Summary", "Availabilty"/*, "Orders"*/] : ["Summary"]
  const dispatch = useDispatch()
  const showPass = () => {
    if (showpassword && signaturePassword && !showpassform) {
      setshowpassform(true)
      setsignaturePassword(null)
    }
    
    setshowpass(!showpassword)
  }
  
  const [showpassform, setshowpassform] = useState(true)
  const [showpassword, setshowpass] = useState(false)
  const [signaturePassword, setsignaturePassword] = useState(null)
  const [loading, setloading] = useState(false)
  const getPassword = async (values) => {
    setloading(true)
    const data = await apiRequest.post(`/integrations/dr_first/${provider.consultantProfile.id}/view?password=${values.password}`).catch(err => toast("Invalid Credentials!", {
      type: "error",
      autoClose: true
    }))
    if (data && data.data) {
      setsignaturePassword(data.data)
      setshowpassform(false)
    }
    setloading(false)
  }
  useEffect(() => {
    if (!provider) {
      dispatch(fetchProvider())
      
    }
    return emptyFun
  }, [location, provider])
  
  
  useEffect(() => {
    const providerId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
    dispatch(fetchProvider(providerId))
    return emptyFun
  }, [])
  const [isVisible, setIsVisible] = useState(false);
  let stati = ['active', 'in-active']
  const getProvider = () => {
    const providerId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
    
  }
  
  async function handleMenuClick(e) {
    setIsVisible(prevState => !prevState)
    console.log(stati[e.key])
    let pl = { data: { status: stati[e.key] }, consultant_id: provider.id }
    await dispatch(updateConsultant(pl))
    // await dispatch(fetchConsultantById(provider.id))
    console.log('click', e.key);
  }
  
  const getIndicator = (text) => {
    return text === "active" ? <div className="dot-circle-active"/> : text === 'in-active' ?
      <div className="dot-circle-inactive"/> : <div className="dot-circle-inactive"/>
  }
  const menu = (<Menu onClick={handleMenuClick}>
    {stati.map((item, index) => <Menu.Item key={index}
                                           style={{ textTransform: 'capitalize' }}> {item.replace('-', '').replace(/_/g, ' ')}  </Menu.Item>)}
  </Menu>);
  const setActiveTab = async (tab) => {
    await dispatch(setConsultantNavs({ tab: tab }))
  }
  
  // profile image modal
  const [faceForm] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [image, setImage] = useState(null);
  
  const showModal = () => {
    setIsModalVisible(true);
  }
  
  const handleOk = () => {
    setIsModalVisible(false);
    
    // update profile image   
    const formData = new FormData();
  }
  const onFacesheetChange = ({ fileList: newFileList }) => {
    newFileList = newFileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
        faceForm.setFieldsValue({
          profile_image_id: file.response.data.id
        })
      }
      return file;
    });
  };
  const [fileList, setFileList] = useState([]);
  
  const onFinished = async (values) => {
    setIsExecuting(true)
    const payload = {
      ...provider,
      ...values
    }
    console.log(payload)
    let params = { data: _.omitBy(payload, _.isNull), consultant_id: provider.id }
    try {
      await dispatch(updateProvider(params))
      // await apiRequest.patch(`/chief-practices/${provider.id}`, payload)
      await dispatch(fetchProvider(provider.id))
      setIsExecuting(false)
      handleOk()
    } catch (e) {
      
      console.log("eerrrr", e);
      setIsExecuting(false)
    }
  }
  // isExecuting
  const [isExecuting, setIsExecuting] = useState(false);
  
  return (<div className="main-page">
    {provider ? <>
      <HeaderTop child={getHeaderLeftNav(navigate, 'Edit Profile')}/>
      {/* modal to update profile image */}
      <Modal title=" Profile Image" open={isModalVisible} onOk={handleOk} onCancel={() => setIsModalVisible(false)}
             
             footer={[
               <div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
                 {horStack([
                   outlinedButton(() => closeDialog(setIsModalVisible), 'Cancel', isExecuting),
                   formSubmitButton('faceForm', 'Update', isExecuting, 'Saving')
                 ])}
               </div>
             ]}>
        {/* tailwind colum flex with items center */}
        <div className="flex flex-col items-center w-full">
          
          <Avatar className="mb-4" size={100} src={<Image src={provider.profile_image_url || require("../../assets/user.png")}/>}/>
          <div className="mb-4">
            <Subtitle1 className="mb-4">Upload a new profile image</Subtitle1>
          </div>
          
          
          {/* form with antd upload */}
          <Form
            form={faceForm}
            name="faceForm"
            layout="vertical"
            onFinish={onFinished}
          >
            <Form.Item
              style={{ width: '100%' }}
              name="profile_image_id"
              rules={[{ required: true, message: 'Missing Document' }]}
            >
              <Upload accept="image/*"
                      action={uploadUrl}
                      listType="picture"
                      
                      headers={{ 'Authorization': `Bearer ${token}` }}
                // defaultFileList={[...fileList]}
                      onChange={onFacesheetChange}
                      maxCount={1}>
                <Button icon={<UploadOutlined/>}>Upload</Button>
              </Upload>
              <Input autoComplete={'off'} style={{ width: '100%', display: 'none' }}/>
            </Form.Item>
          
          </Form>
        </div>
      </Modal>
      
      <div className="page-header">
        <div className="flex-boy-row">
        </div>
        <div className="flex-boy-row-space">
          <div className="flex-boy-row-space">
            <Avatar onClick={() => setIsModalVisible(true)} src={<img alt="user profile" src={provider.profile_image_url || require("../../assets/user.png")}/>}/>
            <div style={{ marginLeft: "10px" }}>
              <Headline6>{provider.name}</Headline6>
              <Body2
                color="grey">{provider.practiceProfile?.practice_profile.address || provider.address_1}<Divider type="vertical"/>{provider.practiceProfile?.practice_profile.state || ''}</Body2>
              {/* <subtitle1>{provider.practiceProfile.practice_profile_phone}<Divider type="vertical" />{provider.practiceProfile.contact_person_phone}</subtitle1> */}
              <Subtitle2 style={{ textAlign: "left", paddingTop: 5 }}>{provider.practiceProfile?.practice_profile_phone}<Divider
                type="vertical"/>{provider.practiceProfile?.contact_person_phone || provider.phone}</Subtitle2>
            </div>
          </div>
        </div>
        <div className="flex-boy-row patients-tabs">
          {TabList.map(tab => (<div key={tab} onClick={() => setActiveTab(tab)}
                                    className={navs.consultantNavs.tab === tab ? "patient-tab active" : "patient-tab"}>
            <Subtitle1>{tab}</Subtitle1>
          </div>))}
        </div>
      </div>
      <div className="main-body" style={{ padding: "10px" }}>
        {navs.consultantNavs.tab === "Summary" ? <ProviderSummary/> : navs.consultantNavs.tab === "Availabilty" ?
          <ProviderAvailabilty /> : <ProviderSummary />}
        <div style={{ width: '200px' }}>
          {provider.consultantProfile && <RegularButton onClick={showPass}>{showpassword ? "Hide" : "View"} Password</RegularButton>}
        </div>
        {provider.consultantProfile && showpassword && showpassform && <div style={{ width: '500px', marginTop: '20px' }}>
          <Form
            name="basic"
            onFinish={getPassword}
            layout="inline"
            autoComplete="off"
          >
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please type password!' }
              ]}
            >
              <Input.Password/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                <Spin size={"small"} spinning={loading} indicator={<LoadingOutlined style={{ color: 'white' }}/>}/> {loading ? "Fetching" : 'Submit'}
              </Button>
            </Form.Item>
          </Form>
        </div>}
        {showpassword && signaturePassword && <div style={{ width: '400px', marginTop: '20px' }}>
          <div className="flex-boy-row">
            <Subtitle1 style={{ fontSize: '1.1em' }}> Rcopia Password : </Subtitle1> <Subtitle1 color="#00b6ff">{signaturePassword.rcopia_password}</Subtitle1>
          </div>
          <div className="flex-boy-row">
            <Subtitle1 style={{ fontSize: '1.1em' }}> Rcopia Signature Password : </Subtitle1><Subtitle1 color="#00b6ff">{signaturePassword.rcopia_signature_password}</Subtitle1>
          </div>
        </div>
        }
      </div>
    </> : <div className="flex-boy-row" style={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }} size="middle">
      
      <Spin size="large"/>
    </div>}
  </div>)
}
const mapStateToProps = (state) => ({
  provider: state.consultants.selectedProvider,
  activeUser: state.auth?.activeUser,
  consultants: state.consultants.consultants, navs: state.navs
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ProviderPage)
