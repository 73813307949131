import React, {useEffect, useState} from 'react'
import {Button, DatePicker, Form, InputNumber, Modal, Select, Skeleton, Upload} from 'antd'
import {appLog, divInputRow, emptyFun, GetRawListOptions, horStack, inputRow, makeSelectOptions, modalClose, outlinedButton} from '../../../common/helpers'
import {UploadOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import {apiRequest} from '../../../app/Apis';
import {Subtitle1} from '../../../components';
import {toastUp} from '../../../app/Reducers/reducerUtils';
import ManualAnnotation from '../../Appointments/components/ManualAnnotation';
import {getManualWoundimentions} from '../../Appointments/components/AssessmentUtil';

export default function WoundUpload({ uploadList, selectedWound, setUploadList, editing, woundLocations, uploadUrl, token, closeModal }) {
  const [uploadForm] = Form.useForm()
  const dateFormat = 'MM-DD-YYYY'
  const [fileList, setFileList] = useState(selectedWound?.image ? [selectedWound?.image] : [])
  const [fetchingWoundMeasurement, setFetchingWoundMeasurement] = useState(false)
  const [woundAnnotation, setwoundAnnotation] = useState(false)
  console.log({ selectedWound, fileList })
  const woundDurations = makeSelectOptions([
    'Less than 30 days',
    '30 days',
    '2 months',
    '3 months',
    '4 months',
    '5 months',
    '6 months',
    '6 months and longer'
  ])
  let woundBedOptions = makeSelectOptions(GetRawListOptions('wound_bed'))
  let woundPositionOptions = makeSelectOptions(GetRawListOptions('wound_position'))
  let woundSideOptions = makeSelectOptions(GetRawListOptions('wound_side'))
  useEffect(() => {
    if (selectedWound !== null) {
      //console.log("selectedWound::", selectedWound)
      uploadForm.setFieldsValue({
        wound_location: selectedWound?.wound_location,
        wound_position: selectedWound?.wound_position,
        wound_side: selectedWound?.wound_side,
        ...(selectedWound.wound_duration ? { wound_duration: selectedWound.wound_duration } : {}),
        ...(selectedWound.original_date_of_wound ? { original_date_of_wound: dayjs(selectedWound.original_date_of_wound).isValid() ? dayjs(selectedWound.original_date_of_wound) : undefined } : {}),
        wound_image: selectedWound?.wound_image,
        width: selectedWound?.width,
        length: selectedWound?.length,
        area: selectedWound?.area,
        depth: selectedWound?.depth || 0.1,
        volume: selectedWound?.volume,
        wound_bed: selectedWound?.wound_bed,
        granulation_tissue: selectedWound?.granulation_tissue,
        necrotic_tissue: selectedWound?.necrotic_tissue,
        fibrous_tissue: selectedWound?.fibrous_tissue
      })
    }
    return emptyFun
  }, [selectedWound])
  const disabledFutureDates = (current) => {
    let customDate = dayjs()
    let tooOld = dayjs("1869-12-31")
    return (current && current > dayjs(customDate, dateFormat)) || (current && current < dayjs(tooOld, dateFormat))
  }
  
  const onFinish = async (values) => {
    //console.log("Uploaded Wound:", values)
    console.log({ fileList })
    /*if (!fileList[0]) {
      toastUp('No wound image selected')
      return
    }*/
    let payload = {
      ...values,
      //Handle cases when there is an existing wound, i.e existing wound do not have .response attr
      image_id: fileList[0]?.response?.data?.id || fileList[0]?.id || fileList[0]?.image_id || undefined,
      wound_image: fileList[0]?.response?.data?.url || fileList[0]?.url || undefined,
      image: fileList[0]?.response?.data || fileList[0] || undefined,
      wound_location: values.wound_location,
      wound_side: values.wound_side,
      wound_position: values.wound_position,
      wound_duration: values.wound_duration,
      original_date_of_wound: values.original_date_of_wound ? dayjs(values.original_date_of_wound).format('YYYY-MM-DD') : undefined,
      width: parseFloat(values.width),
      length: parseFloat(values.length)
    }
    //console.log("Payload::", payload)
    console.log({ editing })
    //console.log("Initial Upload List::", uploadList)
    let uploadIndex;
    if (selectedWound?.image_id) {
      uploadIndex = uploadList.findIndex(item => item.image_id === selectedWound.image_id);
    } else {
      //Read wound to update; where wound did not have image
      uploadIndex = selectedWound?.index
    }
    let newUpdatedList = [...uploadList];
    console.log({ uploadIndex, newUpdatedList, uploadList })
    if (uploadIndex === -1 || uploadIndex === undefined) {
      newUpdatedList = [...newUpdatedList, payload]
    } else {
      newUpdatedList[uploadIndex] = payload
    }
    console.log({ uploadIndex, newUpdatedList, uploadList })
    await setUploadList(newUpdatedList);
    await closeModal()
  }
  
  const onChange = async ({ fileList: newFileList }) => {
    // console.log({newFileList})
    if (!newFileList[0]) {
      uploadForm.setFieldsValue({
        width: undefined, length: undefined, area: undefined, volume: undefined,
        necrotic_tissue: 0,
        granulation_tissue: 0,
        fibrous_tissue: 0
      })
    }
    await setFileList(newFileList)
    newFileList.map(async file => {
      if (file.response) {
        appLog('file.response.data: ', file)
        // uploadForm.setFieldsValue({ wound_image: file.response.data })
        await fetchWoundMeasurement(file.originFileObj).catch(e => console.log("Can't Upload Images::", e))
      }
    })
  }
  
  const fetchWoundMeasurement = async (file) => {
    setFetchingWoundMeasurement(true)
    let formData = new FormData()
    // let token = await localStorage.getItem('token')
    formData.append('wound_image', file)
    let response = await apiRequest.post('/wound-measurements', formData)
      .catch(e => console.log("Can't Upload Images::", e))
    let measurements = response.data.data
    // console.log("Wound Measurement Data::: ", measurements)
    if (measurements.is_successfull === true) {
      let data = response.data
      //console.log("Wound Measurement Data::: ", response)
      let wound = data?.data?.wound
      // console.log({wound})
      let woundDepth = uploadForm.getFieldValue('depth')
      let volume = (parseInt(wound?.area) * parseFloat(woundDepth)).toFixed(2)
      console.log({ woundDepth, volume })
      uploadForm.setFieldsValue({
        width: wound?.width, length: wound?.length, area: wound?.area, volume,
        necrotic_tissue: measurements.tissue_percentage.necrotic || 0,
        granulation_tissue: measurements.tissue_percentage.granulation || 0,
        fibrous_tissue: measurements.tissue_percentage.fibrous || 0
      })
      setFetchingWoundMeasurement(false)
    } else {
      uploadForm.setFieldsValue({
        width: undefined, length: undefined, area: undefined, volume: undefined,
        necrotic_tissue: 0,
        granulation_tissue: 0,
        fibrous_tissue: 0
      })
      // setFileList([])
      toastUp('Unable to generate wound dimensions, please fill in the wound measurements manually', false, null)
      setwoundAnnotation(true)
    }
    setFetchingWoundMeasurement(false)
  }
  
  
  return (
    <div>
      
      <Modal
        title="Wound Annotation"
        open={woundAnnotation && !!fileList[0]}
        destroyOnClose={true}
        onOk={() => setwoundAnnotation(false)}
        onCancel={() => setwoundAnnotation(false)}
        closeIcon={modalClose(() => setwoundAnnotation(false))}
        maskClosable={false}
        width={1200}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {outlinedButton(() => setwoundAnnotation(false), 'Cancel')}
        </div>]}>
        <ManualAnnotation file={{
          ...fileList?.[0],
          //for existing image loaded from backend
          image_id: fileList?.[0]?.id
            //set by getManualWoundimentions's response
            || fileList?.[0]?.image_id
            //For newly set image
            || fileList?.[0]?.response?.data?.id,
          
          //for existing image loaded from backend
          original_image_url: fileList?.[0]?.url
            //For newly set image
            || fileList?.[0]?.response?.data?.url
        }}
                          getManualWoundimentions={(annotation) => getManualWoundimentions(annotation,
                            [{
                              ...fileList[0],
                              image_id: fileList[0]?.id || fileList[0]?.image_id || fileList?.[0]?.response?.data?.id,
                              original_image_url: fileList[0]?.url || fileList?.[0]?.response?.data?.url
                            }],
                            setFileList, null, setwoundAnnotation, null, uploadForm)}/>
      </Modal>
      <Form
        form={uploadForm}
        name="prospectImageForm"
        id="prospectImageForm"
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={(changedValues, values) => {
          console.log({ changedValues, values })
          if ((changedValues.width || changedValues.length || changedValues.depth) && (values?.width && values?.length)) {
            let area = (values?.width * values?.length)?.toFixed(2)
            let volume = (area * values?.depth)?.toFixed(2)
            uploadForm.setFieldsValue({ area, volume })
          }
        }}
        
        defaultValues={editing ? selectedWound : null}
        scrollToFirstError>
        {divInputRow([
          <Form.Item
            style={{ width: '100%' }}
            name={`wound_location`}
            rules={[{ required: true, message: 'Missing Wound location' }]}
            label={`Wound Location`}>
            <Select popupMatchSelectWidth={false}
                    options={woundLocations}
                    style={{ width: '100%', borderRadius: "4px" }}
                    filterOption={false}
                    showSearch={true}
                    autocComplete="chrome-off"
                    placeholder="Search for wound location"/>
          </Form.Item>,
          <Form.Item className={'flex-1 min-w-[160px]'} name="wound_side" label="Wound side"
                     rules={[{ required: false, message: 'Missing field' }]}>
            <Select
              options={woundSideOptions}
              className={'w-full'}
              autoComplete={'off'}
              placeholder="Select"/>
          </Form.Item>,
          <Form.Item className={'flex-1 min-w-[160px]'} name="wound_position" label="Wound position"
                     rules={[{ required: false, message: 'Missing field' }]}>
            <Select
              options={woundPositionOptions}
              className={'w-full'}
              autoComplete={'off'}
              placeholder="Select"/>
          </Form.Item>
        ])}
        {inputRow([
          <Form.Item
            style={{ width: '100%' }}
            name="wound_duration"
            rules={[{ required: true, message: 'Missing Wound duration' }]}
            label="Wound Duration">
            <Select options={woundDurations}/>
          </Form.Item>,
          <Form.Item
            style={{ width: '100%' }}
            label="Onset of the wound"
            name="original_date_of_wound"
            rules={[{ required: false, message: ' Missing Onset of the wound' }]}>
            <DatePicker disabledDate={disabledFutureDates} style={{ width: '100%' }} format={dateFormat}/>
          </Form.Item>
        ])}
        <Form.Item
          style={{ width: '100%' }}
          rules={[{ required: false, message: 'Missing Wound image' }]}
          name={`wound_image`}
          label="Wound image">
          <Upload accept="image/*"
                  action={uploadUrl}
                  headers={{ 'Authorization': `Bearer ${token}` }}
                  listType="picture"
            // onPreview={file => setPreview(true)}
                  defaultFileList={fileList}
                  onChange={onChange}
                  className="upload-list-inline"
                  fileList={fileList}
                  maxCount={1}>
            <Button icon={<UploadOutlined/>}>Upload</Button>
          </Upload>
        </Form.Item>
        {(fileList[0] && (fileList[0]?.url || fileList?.[0]?.response?.data?.url)) && <div className={'w-full'}>
          <Button style={{ margin: "10px", marginLeft: "5px" }} onClick={() => setwoundAnnotation(true)}>Manual Annotation</Button>
        </div>}
        {/*{editing && (
        uploadForm.getFieldsValue('wound_image') !== null && initial?.wound_image !== null && (
          <div className='flex-boy-row-space'>
            <Image.PreviewGroup>
              <Image width={100} src={initial?.wound_image} fallback={require('../../../assets/doc.png')} height={100} />
            </Image.PreviewGroup>
            <FontAwesomeIcon color="grey" icon={["fas", "trash"]} onClick={removeImageFromWoundPayload} className='cursor-pointer' />
          </div>
        )
      )}*/}
        {!!fetchingWoundMeasurement ?
          <div>
            <Subtitle1>Fetching Wound Measurement...</Subtitle1>
            {horStack([
              <Skeleton.Input style={{ width: '50%' }} active={true}/>,
              <Skeleton.Input style={{ width: '50%' }} active={true}/>
            ])}
          </div> :
          <div className={'flexy-column'}>
            {divInputRow([
              <Form.Item className={'flex-1 min-w-[160px]'} name="width" label="Wound Width"
                         rules={[{ required: false, message: 'Missing field' }]}>
                <InputNumber className={'w-full'} autoComplete={'off'} placeholder="" type="number" precision={2}/>
              </Form.Item>,
              <Form.Item className={'flex-1 min-w-[160px]'} name="length" label="Wound Length"
                         rules={[{ required: false, message: 'Missing field' }]}>
                <InputNumber className={'w-full'} autoComplete={'off'} placeholder="" type="number" precision={2}/>
              </Form.Item>,
              <Form.Item className={'flex-1 min-w-[160px]'} name="area" label="Wound Area"
                         rules={[{ required: false, message: 'Missing field' }]}>
                <InputNumber className={'w-full'} autoComplete={'off'} placeholder="" precision={2}/>
              </Form.Item>
            ])}
            {divInputRow([
              <Form.Item className={'flex-1 min-w-[160px]'} name="depth" label="Wound depth"
                         initialValue={0.1}
                         rules={[{ required: false, message: 'Missing field' }]}>
                <InputNumber defaultValue={0.1} className={'w-full'} autoComplete={'off'} placeholder="" type="number" precision={2}/>
              </Form.Item>,
              <Form.Item className={'flex-1 min-w-[160px]'} name="volume" label="Wound volume"
                         rules={[{ required: false, message: 'Missing field' }]}>
                <InputNumber className={'w-full'} autoComplete={'off'} placeholder="" type="number" precision={2}/>
              </Form.Item>
              /*<Form.Item className={'flex-1 min-w-[160px]'} name="wound_bed" label="Wound bed"
                         rules={[{ required: false, message: 'Missing field' }]}>
                <Select
                  options={woundBedOptions}
                  className={'w-full'}
                  autoComplete={'off'}
                  placeholder="Select"/>
              </Form.Item>*/
            ])}
            {divInputRow([
              <Form.Item className={'flex-1 min-w-[160px]'} name="granulation_tissue" label="Granulation Tissue(%)"
                         rules={[{ required: false, message: 'Missing field' }]}>
                <InputNumber className={'w-full'} autoComplete={'off'} placeholder="" type="number" precision={2}/>
              </Form.Item>,
              <Form.Item className={'flex-1 min-w-[160px]'} name="necrotic_tissue" label="Necrotic Tissue(%)"
                         rules={[{ required: false, message: 'Missing field' }]}>
                <InputNumber className={'w-full'} autoComplete={'off'} placeholder="" type="number" precision={2}/>
              </Form.Item>,
              <Form.Item className={'flex-1 min-w-[160px]'} name="fibrous_tissue" label="Fibrous Tissue(%)"
                         rules={[{ required: false, message: 'Missing field' }]}>
                <InputNumber className={'w-full'} autoComplete={'off'} placeholder="" type="number" precision={2}/>
              </Form.Item>
            ])}
          </div>
        }
      </Form>
    </div>
  )
}
