import { emptyFun } from '../../../../common/helpers';
import React, { useCallback, useState, useEffect } from 'react';
import { PieChart, Pie, Sector, Legend, Cell, Tooltip } from 'recharts';
import { connect } from 'react-redux';
import { DatePicker, Spin, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { apiRequest } from '../../../../app/Apis';
import { Primary } from '../../../../components';

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}> {payload.name}</text>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
      <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + -2} outerRadius={outerRadius + 20} fill='#4B9BFF' opacity={0.4} />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill='#333' >{`${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill='#999' >{`${Math.floor(percent * 100)}%`}</text>
    </g>
  );
};

export default function ProspectsPieChart({ prospects, filterState, facility, }) {
  const { RangePicker } = DatePicker;
  const dateFormat = 'YY-MM-DD';
  const [date, setDate] = useState('');
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [endDate, setEndDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  const [dateValues, setDateValues] = useState(null);
  const [allProspects, setAllProspects] = useState(null);
  const [sum, setSum] = useState(null);
  console.log({prospects})
  const disabledFutureDates = (current) => {
    if (!dateValues) return false;
    let filterByValue = filterState === 'Week' ? 7 : 30;
    const tooLate = dateValues[0] && current.diff(dateValues[0], 'days') > filterByValue;
    const tooEarly = dateValues[1] && dateValues[1].diff(current, 'days') > filterByValue;
    return !!tooEarly || !!tooLate;
  };

  function titleCase(str) {
    return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
  }
  

  async function fetchProspects(start, end) {
    try {
      setIsLoading(true);
      const response = await apiRequest.get(`/facilities/${facility?.id}/stats`, { params: { prospect_sd: start, prospect_ed: end } });
      let filtered = constructDataFormat(response.data?.data?.prospects_by_status);
      setAllProspects(filtered);
      setSum(getSum(filtered));
      // setData(filtered);
      setIsLoading(false);
    }catch (e) {
      console.error(e)
    }
  }

  async function getDates(state) {
    if (state !== 'Week') {
      const now = new Date();
      const first = new Date(now.getFullYear(), now.getMonth(), 1);
      const firstDay = dayjs(first).format(dateFormat);
      const last = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      const lastDay = dayjs(last).format(dateFormat);
      console.log(lastDay);
      return { firstDay, lastDay };
    } else {
      const startOfWeek = dayjs().startOf('isoweek').toDate()
      const firstDay = dayjs(startOfWeek).format(dateFormat);
      var endOfWeek = dayjs().endOf('isoweek')
      const lastDay = dayjs(endOfWeek).format(dateFormat);
      return { firstDay, lastDay };
    }
  }

  async function getInitialData() {
    console.log('Running Initial Data', filterState)
    let dates = await getDates(filterState);
    if (filterState === 'Week') {
      await fetchProspects(dates.startOfWeek, dates.endOfWeek);
    } else {
      await fetchProspects(dates.firstDay, dates.lastDay);
    }
  }

  const onChange = async (value) => {
    if (value !== null) {
      let start = dayjs(value[0])?.format(dateFormat);
      let end = dayjs(value[1])?.format(dateFormat);
      setStartDate(start);
      setEndDate(end);
      setDateValues(value);
      // let range = getDatesInRange(
      //   dayjs(start).format(dateFormat),
      //   dayjs(end).format(dateFormat)
      // );
      // setDateValues(range);
      // console.log(range);
      if (start !== end) {
        await fetchProspects(start, end);
      } else {
        console.log('ADD BOTH DATES');
      }
    } else {
      setDateValues(null);
      setSum(0)
      console.log('Cleared Value:', value);
      setAllProspects(null);
    }
  };

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const constructDataFormat = (data) => {
    let dataFormat = [];
    for (let key in data) {
      dataFormat.push({ name: titleCase(key), value: data[key] });
    }
    dataFormat[2].name = 'Not Approved';
    return dataFormat;
  };

  useEffect(() => {
    getInitialData().catch(console.log);
    return emptyFun
  }, [filterState])

  useEffect(() => {
    if (prospects) {
      getInitialData().then(value => {
        let smm = getSum(allProspects);
        setSum(smm);
        
      }).catch(console.log);
    }
    return emptyFun;
  }, [prospects]);

  function getSum(data) {
    const values = data?.map((item) => item.value);
    const sumVal = values?.reduce((acc, value) => {
      return acc + value;
    });
    return sumVal;
  }

  return (
    <>
      {prospects ? (
        <Spin spinning={isLoading} style={{ color: Primary }} >
          <div>
            <PieChart width={400} height={400}>
              {sum === 0 ? (
                <Pie data={[{ name: 'No Referrals', value: 1 }]} fill='#A09F99' cx={200} cy={200} innerRadius={60} activeShape={renderActiveShape} outerRadius={90} dataKey='value' onMouseEnter={onPieEnter} />
              ) : (
                <Pie activeIndex={activeIndex} activeShape={renderActiveShape} data={allProspects} cx={200} cy={200} innerRadius={60} outerRadius={90} dataKey='value' onMouseEnter={onPieEnter} >
                  {allProspects?.map((entry, index) => (
                    <Cell key={index} fill={entry?.name === 'Pending' ? '#FF9F5F' : entry?.name === 'Approved' ? '#50D2A0' : entry?.name === 'Not Approved' ? '#FF5F5F' : null} />
                  ))}
                </Pie>
              )}
              <Legend type='circle' iconType='circle' />
            </PieChart>
            <RangePicker format={"MM/DD/YY"} onChange={(dateStrings) => onChange(dateStrings)} disabledDate={disabledFutureDates} onCalendarChange={(dateStrings) => setDateValues(dateStrings)} allowClear={true} className='date-range-picker' style={{ borderRadius: '7px', cursor: 'pointer', }} />
          </div></Spin>
      ) : (
        <div>
          <Spin spinning={isLoading} style={{ color: 'blue' }} />
          <Skeleton active paragraph rows={'2'} />
        </div>
      )}
    </>
  );
}
