/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Col, Row} from 'antd';
import {Route, Routes,  useLocation, useNavigate} from "react-router-dom";
import Login from './Login'
import PasswordReset from './PasswordReset'
import NewPassword from './NewPassword';
import {emptyFun} from "../../common/helpers";
import back from "../../assets/wd-bg.png"
import UpdateGeneratedPassword from './UpdateGeneratedPassword';

export const Auth = ({  activeUser, dynamicLink }) => {
    const location = useLocation()
    const navigate = useNavigate();
    const dispatch = useDispatch()
    useEffect(() => {
        if (activeUser) {
            if (activeUser.password_was_generated) {
                console.log('Trying to navigate 4')
                navigate( dynamicLink ? dynamicLink : "/auth/update-generated-password");
                return emptyFun
            }
            console.log('Trying to navigate')
            // navigate( dynamicLink ? dynamicLink : "/map");
        }
        return emptyFun
    }, [activeUser]);
    return (<div className="authsection" style={{ backgroundImage: `url(${back})` }}

    >
        <Row>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>

                <div className="auth-content">
                    <div className="auth-form card" style={{ zIndex: 99 }}>
                    <img style={{ width: "90%", position: "relative", margin:"auto" }} src={require('../../assets/WD-Logo_Color.png')} alt="logo2" />
                        <Routes>
                            <Route path="login" element={<Login/>}/>
                            <Route path="update-generated-password" element={<UpdateGeneratedPassword/>}/>
                            <Route path="new-password" element={<NewPassword/>}/>
                            <Route path="password-reset" element={<PasswordReset/>}/>
                        </Routes>
                    </div>
                </div>
            </Col>

        </Row>
    </div>)
}
const mapStateToProps = (state) => ({
    activeUser: state.auth?.activeUser,
    dynamicLink: state.auth?.dynamicLink
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Auth)
