import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
// import { NewPatentCard } from './components/NewPatentCard';
import { AutoComplete, Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select, TimePicker } from 'antd';
import { createTimeBlocker, fetchConsultants, updateTimeBlocker } from '../../../app/Reducers'
import dayjs from 'dayjs'
// import { woundLocations } from '../../../configure/constants';
import { apiRequest } from '../../../app/Apis';
import { cleanObject, emptyFun, executeChunkFn, inputRow } from "../../../common/helpers";
import { errMsg, toastUp } from "../../../app/Reducers/reducerUtils";
import * as Sentry from "@sentry/react";
import TextArea from 'antd/lib/input/TextArea';
import _ from "lodash"

const CheckboxGroup = Checkbox.Group;

const Timeblocker = ({ patient, activeUser, closeModal, setisExecuting, appointment, editingForm, masterListRaw, selectedAppointment, handleBlockCancel }) => {
  const { Option } = Select;
  const dateFormat = "YYYY-MM-DD"
  const uiFields = (appointment_) => {
    // console.log('APPOINTMENT: ', appointment_)
    let appointment = { ...appointment_ }
    let consultant = appointment.consultant
    delete appointment.consultant
    delete appointment.wounds
    delete appointment.latlng
    delete appointment.patient
    cleanObject(appointment)
    return {
      ...appointment,
      ...(appointment.start_at && { date: dayjs(appointment.start_at) }),
      ...(appointment.end_at && { time_slot: [dayjs(appointment.start_at), dayjs(appointment.end_at)] }),
      telehealth_appointment: appointment.telehealth_appointment === 1,
      ...(consultant && { consultant: consultant.name })

    }
  }
  const [patient_, setPatient_] = useState((editingForm || patient) ? appointment?.patient || patient : undefined)
  const [initialValues, setInitialValues] = useState(editingForm ? uiFields(appointment) : {});
  useEffect(() => {
    setInitialValues(editingForm ? uiFields(appointment) : {})

    return emptyFun
  }, [appointment, editingForm])
  const [treatmentOptions, setOptions] = useState([])
  const [consultant, setConsultant] = useState(editingForm ? appointment.consultant : null)
  const [consultantOptions, setconsultantOptions] = useState([])
  const [consultantListOptions, setconsultantListOptions] = useState([])
  // const [woundLocations, setwoundLocations] = useState(masterList.find(option => option.system_name === 'wound_location')?.options?.map(item=>({value:item})))
  const [masterLocations, setMasterLocations] = useState(masterListRaw[0] ? masterListRaw.find(item => item.system_name === 'wound_location')?.options : [])

  const [showForm, setShowForm] = useState(!!initialValues.date && !!initialValues.time_slot && !!initialValues.time_slot[0] && !!initialValues.time_slot[1])
  const [form] = Form.useForm();
  const [isCurrentVisit, setisCurrentVisit] = useState(appointment?.appointment_visit_type === "established visit")

  const dispatch = useDispatch()
  useEffect(() => {
    setShowForm(!!initialValues.date && !!initialValues.time_slot && !!initialValues.time_slot[0] && !!initialValues.time_slot[1])
  }, [initialValues])
  useEffect(() => {
    // if (patient) {
    //   getListAvailableConsultants(patient.id);
    //   /*if (!editingForm) {
    //     fetchPatientWounds()
    //   }*/
    // }
    // fetchWoundLocations(setwoundLocations)

    // dispatch(fetchPatients())
    dispatch(fetchConsultants())

    return emptyFun
  }, [])


  const onconsultantSelect = (value) => {
    console.log('onSelect', value);
    let wcc = consultantListOptions.find(item => item.name === value)
    setConsultant(wcc)
  };
  const [consultantsError, setconsultantsError] = useState(false)
  const onFinish = async (values) => {
    setisExecuting(true)
    console.log(values)
    let data = { ...values }
    try {


      data.consultant_id = `${consultant.id}`
      data.start_at = values.time_slot?.[0] && !values.all_day ? `${dayjs(values.date).format("YYYY-MM-DD")} ${dayjs(values.time_slot[0]).format("HH:mm:ss")}` : `${dayjs(values.date).format("YYYY-MM-DD")} ${dayjs().startOf('day').format("HH:mm:ss")}`
      data.end_at = values.time_slot?.[1] && !values.all_day ? `${dayjs(values.date).format("YYYY-MM-DD")} ${dayjs(values.time_slot[1]).format("HH:mm:ss")}` : `${dayjs(values.date).format("YYYY-MM-DD")} ${dayjs().endOf('day').format("HH:mm:ss")}`
      data.all_day = values.all_day || false
      data.repeat = values.repeats || false
      data.telemedicine_visit = values.telemedicine_visit || false
      data.overlapping = values.overlapping || false
      data.occurrences = [
        {
          end_date: dayjs(values.end_date).format("YYYY-MM-DD"),

          every: `${values.every}`,
          repeats: `${values.after}`,
          occurrences_mode: values.occurrences_mode,
          occur: values.occur
        }
      ]
      console.log(data)


      let payload = editingForm ? { timeblock_id: appointment.id, data: _.omit(data, ["consultant", "occurrences"]) } : data;
      console.log(_.omit(payload, 'consultant_id'))
      await executeChunkFn(dispatch, editingForm ? updateTimeBlocker : createTimeBlocker, payload, setisExecuting, closeModal, async () => {
        console.log("😁 done")

      })


    } catch (e) {
      Sentry.captureException(e);
      setisExecuting(false)
      console.log(e)
      toastUp(errMsg(e, false), false)
    }
  };


  const getListAvailableConsultants = async (value, date, time_slot) => {

    const data = await apiRequest.get(`consultants?search=${value.trim()}`).catch(function (err) {
      console.log(err)
      setconsultantsError(true)
    })
    if (data && data.data) {
      console.log(data.data, data)
      // setconsultantOptions(data.data.data);
      setconsultantListOptions(data.data.data)
      setconsultantsError(false)
    }
  }
  const disabledPastDates = (current) => {
    let customDate = dayjs().subtract(1, 'd');
    return current && current < dayjs(customDate, "YYYY-MM-DD");
  }
  const getID = () => editingForm ? appointment.patient.id : patient?.id


  const [existingWounds, setExistingWounds] = useState([])

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
  };
  return (<Form
    name="new-timeblocker"
    id="timeblockerForm"
    labelCol={{ span: 16 }}
    layout="vertical"
    onValuesChange={(changedValues, allValues) => {
      // console.log('changedValues:', changedValues)
      console.log('allValues:', allValues)
      setInitialValues(allValues)
    }}
    className="p-2"
    style={{ background: 'whitesmoke' }}
    initialValues={{ ...initialValues, occurrences_mode: initialValues.occurrences_mode || "daily" }}
    onFinish={onFinish}
    form={form}>

    <div className="p-2 rounded shadow-md bg-white">
      <Form.Item
        label="Consultant"
        name="consultant"

        rules={[{ required: true, message: 'this field is required!' }]}
        wrapperCol={{ span: 24 }}>
        <AutoComplete popupMatchSelectWidth={false}
          value={consultant ? consultant.name : ''}
          style={{ width: "100%", marginBottom: "20px" }}
          onSelect={onconsultantSelect}
          disabled={editingForm && selectedAppointment?.previous_assessments.length}
          onSearch={(value) => getListAvailableConsultants(value, initialValues.date, initialValues.time_slot)}
          placeholder="search for consultants">
          {consultantListOptions.filter(item => item.name).map(item => (<Option key={item.id} value={item.name}>{item.name} </Option>))}
        </AutoComplete>
      </Form.Item>


      <Form.Item
        label=""
        style={{ width: '100%' }}
        name="all_day"
        valuePropName="checked"
        rules={[{ required: false, message: 'this field is required!' }]}>
        <Checkbox value="All Day">All Day</Checkbox>
      </Form.Item>


      {inputRow([
        <Form.Item
          label="Date"
          style={{ width: '100%' }}
          name="date"
          rules={[{ required: true, message: 'this field is required!' }]}>
          <DatePicker format={dateFormat} style={{ width: '100%' }} />
        </Form.Item>,
        <Form.Item
          label="Time Slot"
          style={{ width: '100%' }}
          name="time_slot"
          rules={[{ required: false, message: 'this field is required!' }]}>
          <TimePicker.RangePicker disabled={initialValues.all_day} minuteStep={15} use12Hours format="h:mm a" style={{ width: '100%' }} />
        </Form.Item>])}


    </div>
    <div className="p-2 rounded shadow-md bg-white mt-2 flex flex-col items-start">


      <Form.Item
        label=""
        style={{ width: '100%' }}
        name="overlapping"
        valuePropName="checked"
        rules={[{ required: false, message: 'this field is required!' }]}>
        <Checkbox> Allow Overlapping</Checkbox>
      </Form.Item>
      <Form.Item
        label=""
        style={{ width: '100%' }}
        name="telemedicine_visit"
        valuePropName="checked"
        rules={[{ required: false, message: 'this field is required!' }]}>
        <Checkbox> Allow Telemedicine Visits</Checkbox>
      </Form.Item>

      <Form.Item
        label=""
        style={{ width: '100%' }}
        valuePropName="checked"
        name="repeats"
        rules={[{ required: false, message: 'this field is required!' }]}>
        <Checkbox> Repeats</Checkbox>
      </Form.Item>
      {initialValues.repeats && <Form.Item
        label=""
        style={{ width: '100%' }}

        name="occurrences_mode"
        rules={[{ required: false, message: 'this field is required!' }]}>
        <Radio.Group>
          <Radio.Button value="daily">Daily</Radio.Button>
          <Radio.Button value="weekly">Weekly</Radio.Button>

        </Radio.Group>

      </Form.Item>}
      {initialValues.repeats && initialValues.occurrences_mode === "weekly" && <Form.Item
        label="Occurs on"
        style={{ width: '100%' }}
        name="occur"
        rules={[{ required: true, message: 'this field is required!' }]}>
        <CheckboxGroup options={['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']} />
      </Form.Item>}


      {initialValues.repeats && inputRow([
        <Form.Item
          label="Every"
          style={{ width: '100%' }}
          name="every"
          rules={[{ required: true, message: 'this field is required!' }]}>
          <InputNumber min="0" addonAfter={initialValues.occurrences_mode === "weekly" ? "Weeks" : "Days"} />
        </Form.Item>,
        <Form.Item
          label="Ends On"
          style={{ width: '100%' }}
          name="end_date"
          rules={[{ required: true, message: 'this field is required!' }]}>
          <DatePicker format={dateFormat} style={{ width: '100%' }} />
        </Form.Item>,
        <Form.Item
          label="After"
          style={{ width: '100%' }}
          name="after"
          rules={[{ required: true, message: 'this field is required!' }]}>
          <InputNumber min="0" addonAfter="Repeats" />


        </Form.Item>

      ])}
    </div>
    <div className="p-2 rounded shadow-md bg-white mt-2">
      <Form.Item
        label="Reason" name="reason" rules={[{ required: true, message: 'this field is required!' }]}
        wrapperCol={{ span: 24 }}>
        <Input autoComplete={'off'} />
      </Form.Item>
      <Form.Item
        label="Notes" name="notes" rules={[{ required: false, message: 'this field is required!' }]}
        wrapperCol={{ span: 24 }}>
        <TextArea autoComplete={'off'} />
      </Form.Item>


    </div>
  </Form>)

}
const mapStateToProps = (state) => ({
  patients: state.patients.patients, consultants: state.consultants.consultants, selectedAppointment: state.appointments.selectedAppointment,
  procedures: state.appointments.procedures, activeUser: state.auth?.activeUser, masterListRaw: state.masters.masterListRaw
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Timeblocker)
