/* eslint-disable no-unused-vars */
import { ConsultantImage, MapStatusCircle, Subtitle1 } from '../../general'
import React, { useState } from 'react'
import ConsultantCard from "./ConsultantCard"
import { Popover } from 'antd';
import { useDispatch } from 'react-redux';
import {
  fetchConsultantshedule, pinConsultant, selectConslultant, setVisibleSchedule
} from '../../../app/Reducers/mapSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { pin } from '../../../assets'
import FacilityCard from './FacilityCard';
import { appLog } from "../../../common/helpers";
// export const Waiting = () => {
//     return (
//         <MapStatusCircle>
//             <div className="map-circle-inner" style={{ borderColor: "#5999F1" }}>
//             </div>
//         </MapStatusCircle>
//     )
// }
// export const Examined = () => {
//     return (
//         <PatientMarker color="#52BE80">
//             <div className="map-circle-inner" style={{ borderColor: "#52BE80" }}>
//                 <img src={require('../../../assets/Icon-patient.png')} alt="patient" />
//             </div>
//         </PatientMarker>
//     )
// }
// export const Changing = () => {
//     return (
//         <MapStatusCircle color="#F5A070">
//             <div className="map-circle-inner" style={{ borderColor: "#F5A070" }}>
//                 <QuestionCircleFilled />
//             </div>
//         </MapStatusCircle>
//     )
// }
// export const Cancelled = () => {
//     return (
//         <MapStatusCircle color="#EF7979">
//             <div className="map-circle-inner" style={{ borderColor: "#EF7979" }}>
//                 <CloseCircleFilled />
//             </div>
//         </MapStatusCircle>
//     )
// }
export const ConsultantMarker = ({ item, hideSchedule }) => {
  const [statsVisible, setStats] = useState(false)
  const dispatch = useDispatch()
  const handleVisibleChange = (e) => {
    console.log(e)
    e === false ? dispatch(selectConslultant(null)) : dispatch(selectConslultant(item))
    hideSchedule()
  }
  const Title = () => (<div className="flex-boy-row-space">
    <Subtitle1>{item.name}</Subtitle1>
    <div className="flex-boy-row-space" style={{ width: "100px" }}>
      <FontAwesomeIcon onClick={() => console.log("heh")} color="grey" size="1x" icon={["fas", 'map']} />
      <FontAwesomeIcon onClick={() => console.log("heh")} color="grey" size="1x" icon={["far", 'comment-alt']} />
      <img src={pin} alt="pin" onClick={() => dispatch(pinConsultant(item))} />
    </div>
  </div>)
  return (<Popover placement="top" content={<ConsultantCard consultant={item} />} title={<Title />} trigger="click"
    onVisibleChange={handleVisibleChange}>
    <div className="icon-circle" style={{ background: "#2A64EB" }}>
      <FontAwesomeIcon size="1x" color="white" icon={['fas', 'user']} />
    </div>
  </Popover>)
}
export const FacilityMarker = ({ item }) => {
  const [statsVisible, setStats] = useState(false)
  const dispatch = useDispatch()
  const handleVisibleChange = (e) => {
    e === false ? dispatch(selectConslultant(null)) : dispatch(selectConslultant(item))
    dispatch(setVisibleSchedule(e))
  }
  const Title = () => (<div className="flex-boy-row-space">
    <Subtitle1>Office</Subtitle1>
  </div>)
  return (<Popover placement="top" content={<FacilityCard />} title={<Title />} trigger="click">
    <MapStatusCircle onClick={() => setStats(!statsVisible)} color="#EF7979">

      <div className="icon-circle">
        <FontAwesomeIcon size="1x" color="black" icon={['fas', 'star-of-life']} />
      </div>
    </MapStatusCircle>
  </Popover>)
}
