/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-webpack-loader-syntax */
import React, { useRef, useState } from 'react'
// mapboxgl.workerClass = MapboxWorker
// mapboxgl.accessToken = mapb
import { connect, useDispatch } from 'react-redux'
// import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

import MapMenu from './Components/MapMenu';
import MapGL, { FlyToInterpolator, LinearInterpolator, NavigationControl, WebMercatorViewport } from 'react-map-gl';



import bbox from '@turf/bbox';

import { easeCubic } from 'd3-ease';
import background from "../../assets/map.png";

import { usePermissionCheck } from '../../hooks/usePermissionCheck';
import MapSearch from '../../components/map/Components/MapSearch';
import { mapboxToken } from '../../configure/constants';


export const Map = ({ consultants, patients, facilities, consultantSchedule, visibleSchedule, selectedConslultant,
    activeUser
}) => {
    const mapContainer = useRef();
    const [hotspots, setHotspots] = useState([])
    const [routing, setRouting] = useState(null)
    const can_view_map = usePermissionCheck('view_map', false, [])

    const [viewport, setViewport] = useState({
        width: '100%',
        height: '100%',
        latitude: 38.5,
        longitude: -98.0,
        zoom: 4
    });




    const goToNYC = (item) => {
        setViewport({
            ...viewport,
            longitude: item._geoloc.lng,
            latitude: item._geoloc.lat,
            zoom: 20,
            transitionDuration: 5000,
            transitionInterpolator: new FlyToInterpolator(),
            transitionEasing: easeCubic
        });
        // handlevisiblePop(item)
    };

    const onRegionClick = event => {
        const feature = event.features[0];
        console.log(feature);
        if (feature && feature.layer.source.includes('jsx')) {
            // calculate the bounding box of the feature
            // dispatch(fetchRegionPatients(feature.properties.name))

            const [minLng, minLat, maxLng, maxLat] = bbox(feature);
            // construct a viewport instance from the current state
            const vp = new WebMercatorViewport(viewport);
            const { longitude, latitude, zoom } = vp.fitBounds([[minLng, minLat], [maxLng, maxLat]], {
                padding: 40
            });
            setViewport({
                ...viewport,
                longitude,
                latitude,
                zoom,
                transitionInterpolator: new LinearInterpolator({
                    around: [event.offsetCenter.x, event.offsetCenter.y]
                }),
                transitionDuration: 1000
            });
        }
    };

    const dispatch = useDispatch()



    const navControlStyle = {
        left: 20,
        bottom: 50
    };







    // console.log(personFeature)


    // cluster stuff
    const mapRef = useRef();
    // const people = patients ? patients.data : [];
    // const persons = people?.map(patient => ({
    //     type: "Feature",
    //     properties: { cluster: false, ...patient },
    //     geometry: {
    //         type: 'Point',
    //         coordinates: [parseFloat(patient.lng), parseFloat(patient.lat)]
    //     }
    // }));

    // load and prepare data
    // get map bounds
    const bounds = mapRef.current
        ? mapRef.current
            .getMap()
            .getBounds()
            .toArray()
            .flat()
        : null;






    // load and prepare data
    // console.log(hospice_points)

    // get clusters


    // get clusters


    // const ReuseSupercluster = (points) => {

    //     const { clusters, supercluster } = useSupercluster({
    //         points: points,
    //         bounds,
    //         zoom: viewport.zoom,
    //         options: { radius: 100, maxZoom: 20 }
    //     });

    //     return { clusters, supercluster }
    // }





    return (<div className="mapCont">

        <MapMenu />
        <div className="map">
            {/* <div className="map-container" ref={mapContainer}>
                {loadwifiMarkers()}
                </div> */}
            <MapSearch goToNYC={goToNYC} />
            {/* <MapStatsCard /> */}
            <div id="static" style={{ backgroundImage: `url(${background})` }}></div>
            <div className="map-container" ref={mapContainer}>
                <MapGL height={'100%'}
                    {...viewport}
                    mapStyle="mapbox://styles/mapbox/dark-v11"
                    onViewportChange={nextViewport => setViewport(nextViewport)}
                    mapboxApiAccessToken={mapboxToken}
                    onClick={onRegionClick}
                    ref={mapRef}
                >

                    <NavigationControl style={navControlStyle} />
                </MapGL>
            </div>
        </div>



    </div>)
}
const mapStateToProps = (state) => ({
    consultants: state.map.consultants,
    patients: state.map.patients,
    facilities: state.map.facilities,
    consultantSchedule: state.map.consultantSchedule,
    visibleSchedule: state.map.visibleSchedule,
    selectedConslultant: state.map.selectedConslultant,
    activeUser: state.auth?.activeUser
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Map);
