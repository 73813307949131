import React, { useCallback } from 'react';
import { AddChannelButton } from './AddChannelButton';
import { useWorkspaceController } from '../../context/WorkspaceController';
import { useChatContext } from 'stream-chat-react';

const ChannelList = (props) => {
  const { children, error = false, loading, type } = props;
  const { displayWorkspace } = useWorkspaceController();
  const handleAddChannelClick = useCallback(() => {
    displayWorkspace(`Admin-Admin-Channel-Create__${type}`);
  }, [type, displayWorkspace]);
  if (error) {
    return type === 'team' ? (<div className='team-channel-list'>
      <p className='team-channel-list__message'>
        Connection error, please wait a moment and try again.
      </p>
    </div>) : null;
  }
  if (loading) {
    return (<div className='team-channel-list'>
      <p className='team-channel-list__message loading'>
        {type === 'team' ? 'Groups' : 'Messages'} loading....
      </p>
    </div>);
  }
  return (<div className='team-channel-list'>
    <div className='team-channel-list__header'>
      <p className='text-left font-semibold  text-slate-300 text-lg' style={{ color: 'gainsboro' }}>
        {type === 'team' ? 'Groups' : 'Direct Messages'}
      </p>
      <AddChannelButton onClick={handleAddChannelClick} />
    </div>
    {children}
  </div>);
};
export const TeamChannelList = React.memo(ChannelList);
