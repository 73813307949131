import {useCallback, useEffect, useState} from "react";
import _ from 'lodash';
import {apiRequest} from '../app/Apis';
import {appLog, emptyFun} from '../common/helpers';

export const useProcedureSearch = (deps, valueAttribute, defaultList) => {
  const [isRun, setIsRun] = useState(false);
  const [procedureSearchLoading, setFacilitySearchLoading] = useState(false);
  
  const [procedureOptions, setProcedureOptions] = useState(defaultList)
  /* if (!isRun) {
     setIsRun(true);
   }*/
  
  const loadData = async (value_) => {
    //Only allow full data load for small lists like procedures. Set options to empty list and return(end execution) if the list is known to be large
    let value = value_ === '' ? undefined : value_
    try {
      await setFacilitySearchLoading(true)
      //todo: This endpoint do not support search on the backend but search is already implemented here
      const { data } = await apiRequest.get(`/procedures`, { params: { search: value } })
      if (data) {
        let list = data.data.map(item => ({ ...item, label: item.name, value: valueAttribute ? item?.[valueAttribute] : item.name }))
        console.log({list})
        list.sort((a, b) => {
          if (!value) {
            if (a.label.toLowerCase() < b.label.toLowerCase()) {
              return -1
            } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
              return 1
            } else {
              return 0
            }
          } else {
            if (a.label.toLowerCase().indexOf(value?.toLowerCase()) < b.label.toLowerCase().indexOf(value?.toLowerCase())) {
              return -1
            } else if (a.label.toLowerCase().indexOf(value?.toLowerCase()) > b.label.toLowerCase().indexOf(value?.toLowerCase())) {
              return 1
            } else {
              return 0
            }
          }
        })
        await setProcedureOptions(list);
      }
      
    } catch (e) {
      console.error(e)
      await setProcedureOptions([]);
    } finally {
      await setFacilitySearchLoading(false)
    }
  }
  const debouncedProcedureSearch = useCallback(_.debounce(async function (value) {
    console.log({value})
    if (!value) {
      setProcedureOptions([])
      return
    }
    await loadData(value).catch(appLog)
  }, 1000), deps)
  useEffect(() => {
    console.log('LoG')
    loadData().catch(appLog)
    return emptyFun
  }, []);
  return [procedureOptions, debouncedProcedureSearch, procedureSearchLoading]
};