import React, {useEffect, useState} from 'react';
import {Headline6} from "../../../components";
import {
  appMessage,
  boldKeyCapitalizedValue,
  boldKeyCapitalizedValueGrayBackground,
  dialogCloser, editButton,
  emptyFun,
  formSubmitButton,
  horStack,
  kebabMenus,
  modalClose,
  outlinedButton
} from "../../../common/helpers";
import {Col, Modal, Row} from "antd";
import dayjs from "dayjs";
import {connect, useDispatch} from "react-redux";
import {fetchSpecificConsultant} from "../../../app/Reducers";

import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import ConsultantShippingAddress from './ConsultantShippingAddress';
import EditConsultantModules from './EditConsultantModules';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ConsultantOffices from './ConsultantOffices';

const ConsultantInfoPane = ({ consultant, activeUser, isWcc }) => {
  const dispatch = useDispatch()
  const { message } = useSubscription(`view_consultant/${consultant.id}`, { qos: 2, nl: true });
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_consultant = usePermissionCheck('update_consultant', false, [])
  const can_update_consultant_global = usePermissionCheck('update_consultant_global', false, [])
  
  console.log("Consultant Info Pane", consultant)
  
  const signatories = consultant.state_signatories?.length ? consultant.state_signatories?.map(item => item)?.join(', ') : 'N/A'
  
  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message)
      dispatch(fetchSpecificConsultant(consultant.id))
    }
    return emptyFun
  }, [message])
  
  const actionMenus = kebabMenus((can_update_consultant_global || consultant.user_id === activeUser.id), can_view_audit_logs, false)
  
  async function kebabMenuClick(e) {
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setconsultantForm(true)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      case 'Delete':
        appMessage('Operation not allowed', 'error')
        break
      default:
        await setShowAuditDialog(false);
    }
  }
  
  const [consultantForm, setconsultantForm] = useState(false)
  const [isExecuting, setisExecuting] = useState(false)
  // selectedsection
  const [selectedsection, setselectedsection] = useState('primary')
  
  
  return (
    <>
      <div className="card-box">
        <Modal
          destroyOnClose={true}
          title={selectedsection==='offices'?'Edit consultant\'s state licenses':`Edit Consultant ${selectedsection} details`}
          open={consultantForm}
          // onOk={handleShippingOk}
          onCancel={dialogCloser(setconsultantForm)}
          closeIcon={modalClose(dialogCloser(setconsultantForm))}
          maskClosable={false}
          width={1000}
          footer={[
            <div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
              {horStack([
                outlinedButton(() => dialogCloser(setconsultantForm)(), 'Cancel', isExecuting),
                formSubmitButton('consultantForm', 'Update', isExecuting, 'Updating')
              ])}
            </div>
          ]}>
          <EditConsultantModules consultant={consultant} closeModal={dialogCloser(setconsultantForm)} setisExecuting={setisExecuting} selectedsection={selectedsection}/>
        </Modal>
        
        
        {showAuditDialog && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false} resourceDescription={consultant.name}
                                            resourceType={'Consultant'}
                                            defaultValues={{ auditableId: consultant.id, auditable: 'consultant', format: 'json' }}/>}
        <div className="flex-boy-row-space" style={{ width: "100%" }}>
          <Headline6>Primary Details</Headline6>
          {can_update_consultant &&
            
            editButton(() => {
              setselectedsection('primary')
              setconsultantForm(true)
            })
            // <KebabMenu menus={actionMenus}
            //            recordItem={consultant}
            //            handleMenuClick={kebabMenuClick} resource={'consultant'} handleOk={null} handleCancel={null} confirmLoading={null}
            //            showConfirmDialog={false}/>
            
          }
        </div>
        
        <Row gutter={48} style={{ width: "100%" }}>
          <Col span={24}>
            {boldKeyCapitalizedValue('ID', consultant.id)}
            {boldKeyCapitalizedValueGrayBackground('Account Type', consultant.account_type)}
            {boldKeyCapitalizedValue('Name', consultant.name)}
            {boldKeyCapitalizedValueGrayBackground('Gender', consultant.gender)}
            {boldKeyCapitalizedValue('Date of birth', `${dayjs(consultant.birth_date).format("MM/DD/YYYY")}(${dayjs().diff(dayjs(consultant.birth_date), 'years')}y.o.)`)}
            {boldKeyCapitalizedValueGrayBackground('Date of hire', `${dayjs(consultant.date_of_hire).format("MM/DD/YYYY")}`)}
            {boldKeyCapitalizedValue('NPI', consultant.npi || "N/A")}
            {boldKeyCapitalizedValueGrayBackground('CAQH ID', consultant.caqh_id || "N/A")}
            {boldKeyCapitalizedValue('Language', (Array.isArray(consultant.language) && consultant?.language[0]) ? consultant?.language.join(', ') : consultant.language)}
            {boldKeyCapitalizedValueGrayBackground('Degree', consultant.degree)}
            {/* {boldKeyCapitalizedValue('Departments', consultant.departments?.join(', '))} */}
            {boldKeyCapitalizedValueGrayBackground('Assigned MD', signatories)}
          </Col>
          <Col span={24}>
          
          
          </Col>
        </Row>
      </div>
      <div className="card-box">
        <div className="flex-boy-row-space" style={{ width: "100%" }}>
          <Headline6>Contacts</Headline6>
          {can_update_consultant && editButton(() => {
            setselectedsection('contact')
            setconsultantForm(true)
          })
            
          }
        </div>
        <div className="w-full">
          {boldKeyCapitalizedValue('Phone', consultant.phone_no)}
          {boldKeyCapitalizedValueGrayBackground('Email', consultant.email)}
        </div>
      
      </div>
      <div className="card-box">
        <div className="flex-boy-row-space" style={{ width: "100%" }}>
          <Headline6>Primary Address</Headline6>
          {can_update_consultant && editButton(() => {
            setselectedsection('address')
            setconsultantForm(true)
          })
            
          }
        </div>
        <div className="w-full">
          {boldKeyCapitalizedValue('Address', `${consultant.address_1}, ${consultant.zip_code}, ${consultant.city}, ${consultant.state}`)}
          {boldKeyCapitalizedValueGrayBackground('City', consultant.city || 'N/A')}
          
          {boldKeyCapitalizedValue('Zip', consultant.zip_code || 'N/A')}
          {boldKeyCapitalizedValueGrayBackground('State', consultant.state)}
          
          {boldKeyCapitalizedValue('Time zone', consultant.timezone)}
          {boldKeyCapitalizedValueGrayBackground('Office Locations', consultant.office_location?.map(item => item.office_name).toString())}
        </div>
      
      </div>
      <div className="card-box">
        <div className="flex-boy-row-space" style={{ width: "100%" }}>
          <Headline6>User Details</Headline6>
          {can_update_consultant &&editButton(() => {
            setselectedsection('user')
            setconsultantForm(true)
          })
            
          }
        </div>
        <div className="w-full">
          {boldKeyCapitalizedValue('User Name', consultant.name)}
          {boldKeyCapitalizedValueGrayBackground('Role', `${(consultant.roles && Array.isArray(consultant.roles) && consultant.roles.length > 0) && consultant.roles.join(', ')}`)}
          {boldKeyCapitalizedValue('Designation', consultant.designation || 'N/A')}
          {boldKeyCapitalizedValueGrayBackground('Title', consultant.title || 'N/A')}
        </div>
      
      </div>
      {isWcc && <ConsultantOffices consultant={consultant} setselectedsection={setselectedsection} setconsultantForm={setconsultantForm } />}
      <ConsultantShippingAddress consultant={consultant}/>
    </>
  );
};


const mapStateToProps = (state) => ({ activeUser: state.auth?.activeUser, consultant: state.consultants.selectedConsultant })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ConsultantInfoPane)
