import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Form, Grid, Modal, Select, Table} from 'antd'
import {Subtitle1} from '../../../../components'
import {apiRequest} from '../../../../app/Apis';
import dayjs from 'dayjs'
import {fetchPatientById, fetchPatientDocuments, resetPatientDocPagination, setPatientDetailsLoading, updatePatientDetailsOwner, updatePatientDocFilters} from '../../../../app/Reducers'
import {
  addButton,
  containedButton,
  dialogCloser,
  emptyDocuments,
  emptyFun,
  emptyTable,
  formSubmitButton,
  getSkels,
  horStack,
  kebabMenus,
  modalClose,
  onTableChange,
  outlinedButton,
  responsiveTbl
} from "../../../../common/helpers";

import PdfViewer from "../../../../components/PdfViewer";

import {errMsg, getToastId, updateToast} from "../../../../app/Reducers/reducerUtils";
import DocForm from "../DocForm";
import {toast} from "react-toastify";
import KebabMenu from "../../../../components/KebabMenu";
import AuditLogViewer from "../../../../components/AuditLogs/AuditLogViewer";
import {useSubscription} from "../../../../hooks/MqttHooks/useSubscription";
import * as Sentry from "@sentry/react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {saveAs} from "file-saver";
import TableFilters from '../../../../components/InputComponents/TableFilters';
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';
const { useBreakpoint } = Grid;
export const Documents = ({ documents, patient, activeUser, patientDocPagination, appConf }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState=useBreakpoint()
  const [facesheetForm, setFacesheetForm] = useState(false);
  const [faceForm] = Form.useForm();
  const dispatch = useDispatch()
  const [pdfShowing, setPdfShowing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [pdf, setPdf] = useState(null);
  const patientId = patient.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const { message } = useSubscription(`view_patient_appointments/${patientId}`, { qos: 2, nl: true });
  const [doc, setDoc] = useState(null);
  const [editingForm, setEditingForm] = useState(false);
  const [selected, setSelected] = React.useState(0);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  const can_create_patient_document = usePermissionCheck('create_patient_document', false, [])
  const can_view_patient_document = usePermissionCheck('view_patient_document', false, [])
  const can_update_patient_document = usePermissionCheck('update_patient_document', false, [])
  const can_delete_patient_document = usePermissionCheck('delete_patient_document', false, [])
  // appLog({appConf: appConf?.patientDocFilters})
  const showPopconfirm = (id) => setSelected(id);
  const handleCancel = () => setSelected(0)
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await loadDocs(true)
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    (async () => {
      await loadDocs(false)
    })().catch(e => console.log(e))
    return emptyFun
  }, [])
  const loadDocs = async (isMQMessage) => {
    /*if (!!documents[0] && !isMQMessage && documents.every(item => item.patient_id === patientId)) {
      return
    }*/
    if (!patient || (patientId !== patient.id)) {
      await dispatch(setPatientDetailsLoading(!isMQMessage))
      await dispatch(fetchPatientById(Number(patientId)))
      await dispatch(setPatientDetailsLoading(false))
      await dispatch(updatePatientDetailsOwner(patientId))
    }
    await dispatch(fetchPatientDocuments(patientId))
  }
  const handleFacesheetOk = (values) => console.log('here', values)
  const handleFacesheetCancel = () => setFacesheetForm(false)
  
  const handleOk = async (docID) => {
    await setConfirmLoading(true);
    await deleteDocument(docID)
    await dispatch(fetchPatientDocuments(patientId))
    await setSelected(0)
    await setConfirmLoading(false);
  };
  
  const updateDocument = async (docId, payload) => {
    const toastId = getToastId("Adding Documents")
    try {
      const response = await apiRequest.patch(`/patients/${patientId}/documents/${docId}`, payload)
      if (response && response.data && response.data.data) {
        updateToast(toastId, "Document updated successfully", true)
        await dispatch(fetchPatientDocuments(patientId))
        faceForm.resetFields();
        setFacesheetForm(false);
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e)
      updateToast(toastId, errMsg(e, false, null, 'Unable to update document'), false)
    }
  }
  const deleteDocument = async (docId) => {
    const toastId = getToastId("Deleting document")
    try {
      await apiRequest.delete(`/patients/${patientId}/documents/${docId}`)
      updateToast(toastId, "Document deleted successfully", true)
      await dispatch(fetchPatientDocuments(patientId))
      faceForm.resetFields();
      setFacesheetForm(false);
    } catch (e) {
      Sentry.captureException(e);
      console.log(e)
      updateToast(toastId, errMsg(e, false, null, 'Unable to update document'), false)
    }
  }
  
  const createFaceSheet = async (payload) => {
    const toastId = toast("Adding Documents", { type: "info", autoClose: true });
    const response = await apiRequest.post(`/patients/${patientId}/documents`, payload).catch(err => toast.update(toastId, {
      render: err.response.data.message,
      type: toast.TYPE.ERROR,
      autoClose: 5000
    }))
    if (response && response.data && response.data.data) {
      toast.update(toastId, { render: "Document added successfully", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      await dispatch(fetchPatientDocuments(patientId))
      setFacesheetForm(false);
      faceForm.resetFields();
    }
  }
  
  const actionMenus = kebabMenus((can_update_patient_document && !patient.deleted_at), can_view_audit_logs, can_delete_patient_document)
  
  async function kebabMenuClick(e, doc) {
    await setDoc({ ...doc, ...(!!doc.expires_at ? { expires_at: dayjs(doc.expires_at) } : { expires_at: undefined }) })
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true)
        await setFacesheetForm(true)
        break
      case 'Delete':
        await setEditingForm(false)
        showPopconfirm(doc.id)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
        await setEditingForm(false)
    }
  }
  
  const addDoc = async () => {
    await setEditingForm(false)
    await setDoc(null)
    await setFacesheetForm(true)
  }
  /* const menu = (item) => {
     return <Menu onClick={(e) => handleMenuClick(e, item)}>
       {editMenus.map((item, index) => <Menu.Item key={index} icon={item.icon}> {item.title}  </Menu.Item>)}
     </Menu>
   }*/
  
  const downloadImage = (image) => {
    const newBlob = new Blob([image.image.url], { type: image.image.mime_type });
    saveAs(image.image.url, image.image.name)
    console.log('blob', newBlob);
  }
  
  const columns2 = [
    { title: 'Name', dataIndex: 'name', key: 'name', fltr: { filterable: true, type: 'string' } },
    { title: 'Type', dataIndex: 'type', key: 'type', fltr: { filterable: true, type: 'string' } },
    { title: 'File', dataIndex: ['image', "mime_type"], key: 'mime_type', fltr: { filterable: true, type: 'string' } },
    {
      title: 'Expiration Date', dataIndex: ['expires_at'], key: 'expires_at', fltr: { filterable: true, type: 'date' },
      render: (expires_at, record) => <Subtitle1 style={{ textAlign: 'left' }}>{expires_at ? dayjs(expires_at).format("MM/DD/YYYY") : ""}</Subtitle1>
    },
    
    ...(can_view_patient_document ? [{ fltr: { filterable: false }, render: (record) => <FontAwesomeIcon onClick={() => downloadImage(record)} color="grey" icon={["fas", "download"]}/> }]:[]),
    {
      fltr: { filterable: false }, render: (record) => {
        return (record.resource_type !== 'consent_form') &&
          <KebabMenu menus={actionMenus}
                     recordItem={record}
                     handleMenuClick={kebabMenuClick} resource={'document'} handleOk={() => handleOk(record.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
                     showConfirmDialog={selected === record.id}/>
      }
    }
  ]
  
  async function handleFilterAction(action, values) {
    await dispatch(resetPatientDocPagination())
    if (action === 'filter') {
      let pl = { filterData: { patient: patientId, ...values }, pagination: patientDocPagination }
      await dispatch(updatePatientDocFilters([false, pl, true]))
      await dispatch(fetchPatientDocuments(pl))
      await dispatch(updatePatientDocFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updatePatientDocFilters([appConf.patientDocFilters[0], undefined, true]))
      await dispatch(fetchPatientDocuments(patientId))
      await dispatch(updatePatientDocFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  return <>
    {(!!doc && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false} resourceType={'Patient'}
                                                   resourceDescription={`${patient.name}'s document(${doc.title.replace(/_/g, ' ')})`}
                                                   defaultValues={{ auditableId: doc?.id, auditable: 'patient_document', format: 'json' }}/>}
    <PdfViewer pdf={pdf} setLoading={setLoading} loading={loading} onCancel={() => setPdfShowing(false)} visible={pdfShowing}/>
    <Modal
      title={editingForm ? "Edit Document" : "Add Document"}
      open={facesheetForm}
      destroyOnClose={true}
      onOk={handleFacesheetOk}
      onCancel={handleFacesheetCancel}
      closeIcon={modalClose(handleFacesheetCancel)}
      maskClosable={false}
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        {horStack([outlinedButton(handleFacesheetCancel, 'Cancel', isSubmitting),
          formSubmitButton('faceSheetForm', editingForm ? 'Save' : 'Add', isSubmitting, editingForm ? 'Updating' : 'Adding')])}
      </div>]}>
      <DocForm doc={doc} editingForm={editingForm} setFacesheetForm={setFacesheetForm} setSubmitting={setSubmitting} uploadName={'image_id'}
               uploadProps={{ listType: "picture" }} updateDocument={updateDocument} createDocument={createFaceSheet}/>
    </Modal>
    {!pdfShowing && <div className="card-box m-2">
      <div className="w-full">
        <Table
          title={() => <TableFilters datasource={columns2} setIsVisible={setIsVisible}
                                     isVisible={isVisible} filters={appConf.patientDocFilters[1]}
                                     actionButton={(can_create_patient_document && !patient.deleted_at) ? addButton(addDoc) : null}
                                     windowState={windowState}
                                     handleFilterAction={handleFilterAction}
                                     showClear={appConf.patientDocFilters[0]} loading={appConf.patientDocFilters[2]}/>}
          loading={appConf.patientDocFilters[2]} size={'small'} {...responsiveTbl(windowState)}
          locale={{
            emptyText: !appConf.patientDocFilters[0] ? emptyDocuments('Patient documents', can_update_patient_resource && !patient.deleted_at ?
              containedButton(addDoc, 'Add Document', false, null, {}, { icon: <PlusOutlined /> }) : null) : emptyTable('patient documents.')
          }}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchPatientDocuments, appConf.patientDocFilters, updatePatientDocFilters, { patient: patientId })}
          onRow={(record, rowIndex) => ({
            onClick: event => {
            } // click row
          })}
          {...getSkels(appConf.patientDocFilters[2], columns2, documents)} pagination={patientDocPagination}/>
      </div>
    
    
    </div>}
  </>;
}
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser, documents: state.patients.patientDocuments,
  patient: state.patients.selectedPatient,

  patientDocPagination: state.patients.patientDocPagination,
  appConf: state.appConf
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Documents)
