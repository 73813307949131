/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Subtitle1 } from '../../components'
import {Grid, Modal, Table, Tag} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  fetchConsultants,
  fetchStateSignatories,
  fetchVehicles,
  resetConsultantModuleState,
  resetConsultantsPagination,
  selectConsultant,
  setConsultantNavs,
  setConsultantsLoaded,
  updateConsultantsFilters
} from '../../app/Reducers';
import dayjs from 'dayjs';
import NewConsultantCard from './components/NewConsultant';
import {
  containedButton,
  emptyFun,
  emptyTable,
  formSubmitButton,
  getDualStatus,
  getSkels,
  horStack,
  makeAutoCompleteOptions,
  makeSelectOptions,
  modalClose,
  onTableChange,
  outlinedButton,
  regionFilter,
  responsiveTbl,
  stateFilter
} from "../../common/helpers";
import TableFilters from "../../components/InputComponents/TableFilters";
import { useSubscription } from "../../hooks/MqttHooks/useSubscription";
import { usePermissionCheck } from '../../hooks/usePermissionCheck';
import {EditOutlined, PlusOutlined} from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';
const { useBreakpoint } = Grid;
const ConsultantList = ({ consultants, consultantsPagination, appConf, navs, stateSignatories, activeUser,consultant }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate();
  const windowState = useBreakpoint();
  const can_create_consultant = usePermissionCheck('create_consultant', false, [])
  const can_view_consultant = usePermissionCheck('view_consultant', false, [])
  const { message } = useSubscription(`view_consultant_listing`, { qos: 2, nl: true });
  const [selectedMD, setSelectedMD] = useState(null)
  const subRegionsRaw = useSelector(state => state.masters.masterListRaw.find(item => item.system_name === 'sub_region')?.options)

  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message)
      loadConsultants(true).catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  const loadConsultants = async (isMQ) => {
    /*if (!!consultants[0] && !isMQ) {
      return
    }*/
    await dispatch(updateConsultantsFilters([appConf.consultantsFilters[0], appConf.consultantsFilters[1], !isMQ]))
    await dispatch(fetchConsultants(appConf.consultantsFilters[0] ? appConf.consultantsFilters[1] : undefined))
    await dispatch(setConsultantsLoaded(true))
    await dispatch(updateConsultantsFilters([appConf.consultantsFilters[0], appConf.consultantsFilters[1], false]))
    await dispatch(fetchStateSignatories())
  }
  useEffect(() => {
    loadConsultants(false).catch(e => console.log(e))
    return emptyFun
  }, [])



  React.useRef(null);

  const [patientForm, setPatientForm] = useState(false);
  const [consultantRoles, setConsultantRoles] = useState([]);
  const handlepatientOk = () => {
  }
  const handlepatientCancel = () => setPatientForm(false)
  const columns = [{
    title: 'First & Last Name', /*dataIndex: 'first_name',*/ key: 'name',
    fltr: { filterable: true, type: 'text' }, /*...getColumnSearchProps('name'),*/ render: (record) => (<>
      <div className="flex-boy-row-space" style={{ justifyContent: 'normal' }}>

        <Subtitle1 style={{ textAlign: "left", marginLeft: '10px' }}>{record.name||`${record?.first_name||''} ${record?.last_name||''}`}</Subtitle1>
      </div>
    </>)
  }, {
    title: 'Role', dataIndex: 'roles', key: 'role', render: (roles) => {
      // console.log('roles: ', roles)
      if (roles && Array.isArray(roles) && roles.length > 0) {
        roles.map(item => {
          if (!consultantRoles.includes(item)) {
            consultantRoles.push(item)
            setConsultantRoles([...consultantRoles])
          }
        })
        return <p style={{ maxWidth: 200 }}>{roles.join(', ')}</p>
      } else {
        return roles
      }
    }, fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(consultantRoles) }
  }, {
    title: 'Region', dataIndex: 'region', key: 'regionName', fltr: regionFilter
  }, {
    title: 'Sub-region', dataIndex: 'sub_region', key: 'sub_region',
    fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(subRegionsRaw) }
  }, {
    title: 'Phone Number', dataIndex: 'phone_no', key: 'phone_no', fltr: { filterable: true, type: 'phone' }
  }, {
    title: 'Email', dataIndex: 'email', key: 'email', fltr: { filterable: true, type: 'text' }
  }, {
    title: 'Status', dataIndex: 'status', key: 'status', fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(['active', 'in-active']) },
    render: (status) => getDualStatus(status)
  },
    ...(activeUser?.is_qa_user?[{ width:15,
      visible: true,
      title: <FontAwesomeIcon color="grey" icon={['fas', 'table']}/>,
      key: 'operation',
      render: (record) => <Tag className={`${(record.is_qa && activeUser?.is_qa_user)?'visible':'invisible'}`} icon={<EditOutlined/>} color="#55acee">QA</Tag>
    }]:[])
  ]

  const stateSignatoryFilter = {

    key: 'stateMD', title: 'Assigned MD', fltr: {
      filterable: true,
      props: { placeholder: 'Select MD', }, type: 'select', options: stateSignatories ? stateSignatories?.map(item => { return { label: item.name, value: item.id, actualValue: item.id } }) : []
    }
  }
  const accountTypeFilter = {

    key: 'account_type', title: 'Account type', fltr: {
      filterable: true,
      props: { placeholder: 'Select ', }, type: 'select', options: makeSelectOptions(["Provider","Clinical Staff"])
    }
  }
  const [isVisible, setIsVisible] = useState(false);

  async function handleFilterAction(action, values) {
    await dispatch(resetConsultantsPagination())
    if (action === 'filter') {
      console.log('FILTERS:  ', JSON.stringify({ ...values, toLoad: 'consultants', endpoint: '/consultants' }));
      let pl = { filterData: values, pagination: consultantsPagination }
      await dispatch(updateConsultantsFilters([false, pl, true]))
      await dispatch(fetchConsultants(pl))
      await dispatch(updateConsultantsFilters([true, pl, false]))
    }
    if (action === 'reset' /*&& appConf.consultantsFilters[0]*/) {
      await dispatch(updateConsultantsFilters([appConf.consultantsFilters[0], undefined, true]))
      await dispatch(fetchConsultants())
      await dispatch(updateConsultantsFilters([false, undefined, false]))
    }
    // const response = await apiRequest.get(`/consultants`,{params:{...values, toLoad: 'consultants'}})
    setIsVisible(false)
  }

  const [isExecuting, setisExecuting] = useState(false)
  return (
    (<div className="main-page">
      <Modal
        title="New Consultant"
        open={patientForm}
        onOk={handlepatientOk}
        destroyOnClose={true}
        width={800}
        onCancel={handlepatientCancel}
        closeIcon={modalClose(handlepatientCancel)}
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(() => handlepatientCancel(), 'Cancel', isExecuting),
          formSubmitButton('consultantForm', 'Create', isExecuting, 'Creating')])}
        </div>]}
      >
        <NewConsultantCard setisExecuting={setisExecuting} handlepatientCancel={handlepatientCancel} />
      </Modal>
      {/*<HeaderTop child={<Subtitle1>Consultants</Subtitle1>} />*/}
      <div className="w-full">
        <Table
          title={() => (<TableFilters datasource={[...columns, stateFilter, stateSignatoryFilter,accountTypeFilter]}
            setIsVisible={setIsVisible}
            actionButton={can_create_consultant ? containedButton(() => setPatientForm(true), 'New Consultant', false, null, null, {
              icon: <PlusOutlined />
            }) : null}
            windowState={windowState}
            isVisible={isVisible} filters={appConf.consultantsFilters[1]}
            handleFilterAction={handleFilterAction}
            showClear={appConf.consultantsFilters[0]} loading={appConf.consultantsFilters[2]} />)}
          loading={appConf.consultantsFilters[2]} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('consultants') }}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchConsultants, appConf.consultantsFilters, updateConsultantsFilters)}
          onRow={(record) => {
            return {
              onClick: () => {
                (async () => {
                  if (record.id !== consultant?.id) {
                    await dispatch(resetConsultantModuleState())
                    await dispatch(fetchVehicles())
                  }
                  await dispatch(setConsultantNavs({ tab: 'Summary' }))
                  await dispatch(selectConsultant({ navigate, record: record }))
                  if (can_view_consultant) {
                    navigate(`/consultants/${record.id}`)
                  }
                })().catch(e => console.log(e))
              } // click row
            };
          }} {...getSkels((appConf.consultantsFilters[2]), columns, consultants)} pagination={consultantsPagination} />
      </div>
    </div>)
  );
}
const mapStateToProps = (state) => ({
  consultants: state.consultants.consultants,
  pagination: state.consultants.pagination, appConf: state.appConf,
  navs: state.navs,
  consultantsPagination: state.consultants.consultantsPagination,
  stateSignatories: state.consultants?.stateSignatories,
  activeUser: state.auth.activeUser,
  consultant: state.consultants.selectedConsultant,
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ConsultantList)
