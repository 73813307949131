import React, {useEffect, useState} from 'react';
import {Grid, Table} from "antd";
import {connect, useDispatch} from "react-redux";
import {createCity, deleteCity, editCity, fetchWPCities, fetchWPRegions, fetchWPSubRegions, resetWpCitiesPagination} from "../../../app/Reducers";
import {updateWpCitiesFilters} from "../../../app/Reducers/ConfigSlice";
import TableFilters from "../../../components/InputComponents/TableFilters";
import {
  appLog,
  containedButton,
  dialogCloser,
  emptyFun,
  emptyTable,
  executeChunkFn,
  getSkels,
  kebabMenus,
  makeAutoCompleteOptions,
  makeSelectOptions,
  onTableChange,
  responsiveTbl
} from "../../../common/helpers";
import * as Sentry from "@sentry/react";
import {errMsg, toastUp} from "../../../app/Reducers/reducerUtils";
import DynamicModalForm from "../../../components/DynamicModalForm";
import KebabMenu from '../../../components/KebabMenu';
import {apiRequest} from '../../../app/Apis';
import {PlusOutlined} from '@ant-design/icons';
const { useBreakpoint } = Grid;
const WPCities = ({appConf, wpCitiesPagination, wpCities, wpRegions, wpSubRegions}) => {
  const dispatch = useDispatch();
  const windowState=useBreakpoint()
  const dateFormat = "YYYY-MM-DD";
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [auditItem, setAuditItem] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [selected, setSelected] = useState(null)
  const [isExecuting, setisExecuting] = useState(false)
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  const [upperBoundaries, setUpperBoundaries] = useState(undefined);
  const [stateOptions, setStateOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState(wpRegions.map(item => item.name));
  const [subRegionOptions, setSubRegionOptions] = useState(wpSubRegions.map(item => item.name));
  useEffect(() => {
    dispatch(fetchWPRegions());
    dispatch(fetchWPSubRegions());
    loadStateBoundaries().catch(appLog)
    dispatch(fetchWPCities());
    return emptyFun
  }, []);
  
  const onStateChange = async (value, option) => {
    //console.log({value, option})
    await setUpperBoundaries({
      region_boundary_id: option.region_boundary.id,
      sub_region_boundary_id: option.sub_region_boundary.id,
      state_boundary_id: option.id
    })
  }
  let cityFormInputs = {
    fields: [
      {name: 'name', label: 'Name', required: true, type: 'text', fullRow: true},
      {name: 'state_boundary_id', label: 'State', required: true, type: 'autocomplete', options: makeAutoCompleteOptions(stateOptions), fullRow: true, props: {onSelect: onStateChange}}
    ]
  };
  const columns = [
    {
      title: 'Name', dataIndex: 'name', key: 'name', fltr: {filterable: true, type: 'text'}
    },
    {
      title: 'Region', dataIndex: ['region_boundary', 'name'], key: 'region',
      fltr: {filterable: true, type: 'select', options: makeSelectOptions(regionOptions)}
    },
    {
      title: 'Sub-region', dataIndex: ['sub_region_boundary', 'name'], key: 'subRegion',
      fltr: {filterable: true, type: 'select', options: makeSelectOptions(subRegionOptions)}
    },
    {
      title: 'State', dataIndex: ['state_boundary', 'name'], key: 'state',      fltr: {filterable: true, type: 'select', options: stateOptions}
    },
    {
      title: 'Action',
      render: (record) =>
        (<KebabMenu
            menus={actionMenus}
            recordItem={record}
            handleMenuClick={kebabMenuClick}
            resource="city"
            handleOk={() => handleOk(record)}
            showConfirmDialog={selected === record.id}
            handleCancel={handleCancel}
            confirmLoading={confirmLoading}
          />
        )
    }
  ]
  // let cityFormInputs = createFormInputsFromTableColumns(false, columns, null, null, null, null, dateFormat)
  //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
  let dynamicFormInputFields = {
    'Edit City': cityFormInputs, 'Add City': cityFormInputs
  }
  const loadStateBoundaries = async () => {
    const {data} = await apiRequest.get(`/state-boundaries`);
    let states_ = data.data.map(item => ({...item, label: item.name, value: item.name}))
    await setStateOptions(states_)
  }
  const actionMenus = kebabMenus(true, false, true)
  const showPopconfirm = (id) => setSelected(id);
  
  async function kebabMenuClick(e, record) {
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await openCityForm(record)
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        break
      default:
    }
  }
  
  const handleOk = async (record) => {
    await setConfirmLoading(true)
    await dispatch(deleteCity(record.id))
    await setConfirmLoading(false)
    await setSelected(0)
  }
  
  const handleCancel = () => setSelected(0);
  
  async function handleFilterAction(action, values) {
    await dispatch(resetWpCitiesPagination())
    if (action === 'filter') {
      let pl = {filterData: {...values}, pagination: wpCitiesPagination}
      await dispatch(updateWpCitiesFilters([false, pl, true]))
      await dispatch(fetchWPCities(pl))
      await dispatch(updateWpCitiesFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updateWpCitiesFilters([appConf.wpCitiesFilters[0], undefined, true]))
      await dispatch(fetchWPCities())
      await dispatch(updateWpCitiesFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  
  const openCityForm = async (record) => {
    //console.log({record, stateOptions})
    await setFormName(!!record ? 'Edit City' : 'Add City');
    await setDefaultDynamicModalFormValues(!!record ? {
      name: record.name, region_boundary_id: record.region_boundary.id, sub_region_boundary_id: record.sub_region_boundary.id,
      state_boundary_id: record.state_boundary.id
    } : undefined);
    await setDynamicFormOpen(true);
  };
  
  /**
   * This function is handling returned form entries from the dynamic form
   * */
  const onDynamicFormEntriesResult = async (entries) => {
    //console.log({entries})
    try {
      switch (formName) {
        case 'Add City':
          await executeChunkFn(dispatch, createCity, {...entries.values, ...upperBoundaries}, setisExecuting, dialogCloser(setDynamicFormOpen), null)
          break;
        case 'Edit City':
          await executeChunkFn(dispatch, editCity, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen), null)
          break;
        default:
          return
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      toastUp(errMsg(e, false, formName), false);
    }
  };
  return (
    <div className="w-full">
      {(isDynamicFormOpen && !!formName) && (
        <DynamicModalForm
          setDynamicFormOpen={setDynamicFormOpen}
          isDynamicFormOpen={isDynamicFormOpen}
          inputFields={dynamicFormInputFields[formName]}
          onDynamicFormEntriesResult={onDynamicFormEntriesResult}
          closeModal={dialogCloser(setDynamicFormOpen)}
          isExecuting={isExecuting}
          defaultValues={defaultDynamicModalFormValues}
          formName={formName}
        />
      )}
      <Table
        title={() => (<TableFilters datasource={columns} setIsVisible={setIsVisible} isVisible={isVisible}
                                    handleFilterAction={handleFilterAction} filters={appConf.wpCitiesFilters[1]}
                                    showClear={appConf.wpCitiesFilters[0]} loading={appConf.wpCitiesFilters[2]}
                                    actionButton={containedButton(() => openCityForm(), 'Add City', false, null, null, {icon: <PlusOutlined />})}
        />)}
        loading={appConf.wpCitiesFilters[2]} size={'small'} {...responsiveTbl(windowState)} locale={{emptyText: emptyTable('regions')}}
        onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchWPCities, appConf.wpCitiesFilters, updateWpCitiesFilters, null)}
        onRow={(record) => ({
          onClick: async (event) => {
            await setAuditItem(record)
            await setShowAuditDialog(true)
          }
        })}
        {...getSkels((appConf.wpCitiesFilters[2]), columns, wpCities)} pagination={wpCitiesPagination}
      />
    </div>);
};


const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser,
  wpCities: state.masters.wpCities,
  wpCitiesPagination: state.masters.wpCitiesPagination,
  wpSubRegions: state.masters.wpSubRegions,
  appConf: state.appConf,
  wpRegions: state.masters.wpRegions
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(WPCities);
