import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@mui/icons-material';
import { Headline6, Subtitle1 } from '../../../components';
import { IconButton } from '@mui/material';
import { Col, Row } from 'antd';
import { keyValItemRorReports } from '../../../common/helpers';
import { connect } from 'react-redux';

function CustomReportPane({ title, setUpdateReport, reports }) {

  const [report, setReport] = useState(null)
  useEffect(() => {
    if(!reports){
      return ()=>{};
    }
    const res = reports?.find(
      (e) => e.report_type === title?.replace(/ /g, '').toLowerCase()
      );
      setReport(res)
      return ()=>{};
    }, [reports, title]);
    
  return (
    <div className='card-box'>
      <div className='flex-boy-row-space' style={{ width: '100%' }}>
        <Headline6>{reports?.report_name}</Headline6>
        <IconButton type={'button'} size={'small'} onClick={setUpdateReport}>
          <EditOutlined fontSize={'small'} />
        </IconButton>
      </div>
      <Row
        gutter={[4, 4]}
        align={'stretch'}
        justify={'start'}
        style={{ width: '100%', margin: 0, padding: 3 }}
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          flex={'auto'}
          style={{ width: '100%' }}
        >
          {keyValItemRorReports(
            'Assessment Plan',
            report?.assessment_plan_summary || 'N/A'
          )}
          {keyValItemRorReports(
            'Assessment Procedure Summary',
            report?.assessment_procedure_summary || 'N/A'
          )}
          {keyValItemRorReports(
            'Assessment Summary',
            report?.assessment_summary || 'N/A'
          )}
          {keyValItemRorReports(
            'Pre-Assessment Summary',
            report?.pre_assessment_summary || 'N/A'
          )}
          {keyValItemRorReports('Procedure Summary', report?.procedure_summary || 'N/A')}
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
  navs: state.navs,
  appConf: state.appConf,
  reports: state.reports.reports,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomReportPane);
