import { useState, useEffect } from 'react'
import { apiRequest } from "../../../app/Apis";
import { DatePicker, Empty, Row, Col, Statistic, Spin } from 'antd';
import { Headline6, Subtitle1 } from "../../../components";
import dayjs from 'dayjs';

export default function ConsultantBiologics({ consultant }) {
  const dateFormat = 'YYYY-MM-DD';
  const [loading, setLoading] = useState(false)
  const [biologics, setBiologics] = useState(null)
  const [endDate, setEndDate] = useState(dayjs().format(dateFormat));
  const [startDate, setStartDate] = useState(dayjs().subtract(5, 'days').format(dateFormat));
  const [dateValues, setDateValues] = useState(null)
  const disabledFutureDates = (current) => current && current > dayjs(dayjs())

  const { RangePicker } = DatePicker;
  async function fetchBiologics(consultantId) {
    try {
      setLoading(true);
      const dates = `?dateRange[start_at]=${startDate}&dateRange[end_at]=${endDate}`;
      const response = await apiRequest.get(
        `/statistics/consultants/${consultantId}/biologic-applications${dates}`
      );

      if (response.status === 200) {
        setBiologics(response.data.data);
      } else {
        setBiologics(null);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  const onChange = async (value) => {
    if (value !== null) {
      console.log(value);
      setDateValues(value);

      let start = dayjs(value[0])?.format(dateFormat);
      let end = dayjs(value[1])?.format(dateFormat);
      setStartDate(start);
      setEndDate(end);
    } else {
      setDateValues(null);
    }
  };

  useEffect(() => {
    if (consultant) {
      (async () => {
        await fetchBiologics(consultant.id)
      })().catch(e => console.log(e))
    }
  }, [startDate, endDate])

  useEffect(() => {
    console.log("Loading:::", loading)
  }, [loading])

  return (
    <div className="card-box">
      <div  style={{ width: '100%' }}>
        <Headline6>Biologics Application</Headline6>
        <RangePicker
          style={{
            borderRadius: '7px',
            cursor: 'pointer',
            marginTop: '10px',
          }}
          disabledDate={disabledFutureDates}
          allowClear
          format={dateFormat}
          onChange={(dateStrings) => onChange(dateStrings)}
          onCalendarChange={(dateStrings) => setDateValues(dateStrings)}
        />
      </div>
      {biologics === null ?
        <Spin spinning={loading}>
          <div className='flex justify-center' style={{ width: '100%' }}>
            <Empty description={"No Biologics Found"} />
          </div>
        </Spin>
        :
        <div className='w-full'>
          <Spin spinning={loading}>
            <Row gutter={16}>
              <Col span={12}>
                <Statistic title={
                  <Subtitle1>Total</Subtitle1>
                } value={biologics?.total} />
              </Col>
              <Col span={12}>
                <Statistic title={
                  <Subtitle1>Average Application</Subtitle1>
                } value={biologics?.avg} precision={2} />
              </Col>
            </Row>
          </Spin>
        </div>
      }
    </div>
  )
}