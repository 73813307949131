import React from 'react';
import {connect, useDispatch} from 'react-redux';
import {setFacilityHomeNavs} from '../../app/Reducers';
import {Tabs} from 'antd';
import {Subtitle1} from '../../components';
import {HeaderTop} from '../../components/HeaderTop';
import FacilitiesList from './FacilitiesList';
import Organisations from './Organisations';

const {TabPane} = Tabs;
const FacilityIndex = ({navs, activeUser}) => {
  const dispatch = useDispatch()
  const setActiveTab = async (tab) => {
    await dispatch(setFacilityHomeNavs({tab: tab}))
  }
  
  const TabList = [/*'Organisations',*/ 'Offices',/* 'Users'*/];
  const Tabs_ = () => {
    return (<Tabs defaultActiveKey={navs.facilityHomeNavs.tab} onChange={setActiveTab} tabBarStyle={{marginBottom: 0}}>
      {TabList.map(item => <TabPane key={item} tab={<Subtitle1>{item}</Subtitle1>}>
      </TabPane>)}
    </Tabs>)
  }
  return (<div className="main-page ">
    <HeaderTop child={<Tabs_/>} customStyle={{style: {paddingBottom: 0}}}/>
    {navs.facilityHomeNavs.tab === 'Organisations' ? <Organisations/> : navs.facilityHomeNavs.tab === 'Facilities' ?
      <FacilitiesList/> : null}
  </div>)
};

const mapStateToProps = (state) => ({ navs: state.navs, activeUser: state.auth?.activeUser
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(FacilityIndex)
