/* eslint-disable no-unused-vars */
import React, {useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Subtitle1} from '../../components'
import {Button, Form, Input} from 'antd';
import {updateGeneratedPassword} from '../../app/Reducers';
import {useLocation, useNavigate} from 'react-router-dom';

const UpdateGeneratedPassword = ({ activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
  const onFinish = async (values) => {
    const params = new URLSearchParams(location.search);
    let data = { ...values, email: activeUser.email }
    //console.log(data)
    try {
      await dispatch(updateGeneratedPassword(data)).unwrap()
      navigate("/auth/login")
    } catch (e) {
      console.log(e)
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  let passMessage1 = '❶ Must be at least 10 chars long. \n' +
    '❷ Must contain at least one lowercase letter. \n' +
    '❸ Must contain at least one uppercase letter. \n' +
    '❹ Must contain at least one digit. \n' +
    '❺ Must contain at least one special character among (@$!%*#?&).'
  let passMessage = 'Must be at least 10 chars long. Must contain at least one of lowercase letter, uppercase letter, number and one special character among (@$!%*#?&).'
  const [sent, setSent] = useState(false)
  return (
    <div className="login-div">
      <Form
        name="Agency"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        
        <Form.Item
          style={{ width: '100%' }}
          name="old_password"
          label="Old Password"
          rules={[{ required: true, message: 'Missing Password' },]}
          hasFeedback
        >
          <Input.Password placeholder="Password"/>
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          name="password"
          label="Password"
          rules={[{ required: true, message: 'Missing Password' }, { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/, message: passMessage }]}
          hasFeedback
        >
          <Input.Password placeholder="Password"/>
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          dependencies={['password']}
          name="password_confirmation"
          label="Confirm Password"
          rules={[{ required: true, message: 'Missing Confirm Password' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Passwords do not match!'));
              }
            })]}
          hasFeedback
        >
          <Input.Password placeholder="Confirm Password"/>
        </Form.Item>
        
        
        <Button htmlType="submit" className="login-form-button">
          Save
        </Button>
      
      
      </Form>
      
      
      <Subtitle1 style={{ cursor: "pointer" }} onClick={() => navigate("/auth/login")}>&lt; &#32;  Back to
        Login </Subtitle1>
    </div>
  );
}
const mapStateToProps = (state) => ({ activeUser: state.auth?.activeUser })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateGeneratedPassword)
