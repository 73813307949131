import React, {useEffect, useState} from 'react';
import {Card, Checkbox, Col, Divider, Row, Select, Spin, Tooltip} from 'antd';
import {appLog, appMessage, containedButton, emptyFun, horStack, makeSelectOptions, outlinedButton, secondaryButton} from '../../common/helpers';
import {apiRequest} from '../../app/Apis';
import {errMsg, toastUp} from '../../app/Reducers/reducerUtils';
import {AuditOutlined, UndoOutlined} from '@ant-design/icons';

const TeamCard = ({ team, permissions, loading, roles }) => {
  const [teamSpecificPermissions, setRolePermissions] = useState([])
  const [teamRolePermissions, setTeamRolePermissions] = useState()
  const [roleOptions, setRoleOptions] = useState([])
  const [teamRoleIds, setTeamRoleIds] = useState([])
  const [freeze, setFreeze] = useState(false)
  const [permissionsOptions, setPermissionOptions] = useState([])
  const [updateDetected, setUpdateDetected] = useState(false)
  const [permissionGroups, setPermissionGroups] = useState({})
  const [filter, setFilter] = useState(null)
  const [moduleOptions, setModuleOptions] = useState([])
  const [selectedModule, setSelectedModule] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false)
  useEffect(() => {
    if (!!team) {
      if (roles[0]) {
        setInitialRoles().catch(appLog)
      }
      setInitialPerms().catch(appLog)
    }
    return emptyFun
  }, [team, permissions, roles]);
  const setInitialRoles = async () => {
    let teamRoles = team?.roles?.map(item => item.id) || []
    let teamRoleIds = new Set(teamRoles)
    let roleOptionList = roles.map(item => ({ ...item, label: item.name, value: item.id, checked: teamRoleIds.has(item.id) }))
    await setRoleOptions(roleOptionList)
    await setTeamRoleIds(Array.from(teamRoleIds))
  }
  const setInitialPerms = async (moduleName) => {
    await setFreeze(true)
    let rolePerms = team?.roles.map(item => item.permissions).flat(1).map(item => item.id)
    let teamSpecificPermisions = team?.permissions.map(item => item.id)
    let combinedPermIds = new Set([...rolePerms, ...teamSpecificPermisions])
    await setTeamRolePermissions(rolePerms)
    // let teamPerms = Array.from(combinedPermIds)
    let teamPerms = [...teamSpecificPermisions]
    console.log({ rolePerms, teamSpecificPermisions, teamPerms })
    await setRolePermissions(teamPerms)
    let groupedPermissions = {}
    for (const permission of permissions) {
      if (!groupedPermissions[permission.module_name]) {
        groupedPermissions[permission.module_name] = []
      }
      groupedPermissions[permission.module_name]?.push(permission)
    }
    //Sort and add checked
    for (const permGroup of Object.values(groupedPermissions)) {
      groupedPermissions[permGroup[0].module_name] = permGroup?.map(item => {
        let permissionAlreadyExistInOneOfTheRoles=rolePerms.includes(item.id)
        return {
          ...item,
          value: item.id,
          label: item.name.replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()),
          checked: teamPerms.includes(item.id),
          disabled: permissionAlreadyExistInOneOfTheRoles
        };
      }).sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0)
    }
    await setSelectedModule(moduleName)
    await setFilter(moduleName)
    await setPermissionGroups(moduleName ? { [moduleName]: groupedPermissions[moduleName] } : groupedPermissions)
    await setModuleOptions(makeSelectOptions(Object.keys(groupedPermissions).sort()))
    await setFreeze(false)
  }
  useEffect(() => {
    let checkedIds = Object.values(permissionGroups).flat(1).filter(item => item.checked).map(item => item.value)
    // console.log({ checked: checkedIds.length, ids: teamSpecificPermissions.length })
    let teamPerms = !selectedModule ? teamSpecificPermissions : team?.permissions.filter(value => value.module_name === selectedModule).map(item => item.id)
    let team_ids = roleOptions.filter(item => item.checked).map(item => item.value);
    setUpdateDetected(((checkedIds.every(value => !teamPerms.includes(value)) || checkedIds.length !== teamPerms.length) && !!checkedIds[0]) || team_ids.length !== teamRoleIds.length)
    return emptyFun
  }, [permissionGroups, roleOptions]);
  const updateRole = async () => {
    try {
      await setFreeze(true)
      await setIsUpdating(true)
      let permission_ids = Object.values(permissionGroups).flat(1).filter(item => item.checked).map(item => item.value);
      let team_ids = roleOptions.filter(item => item.checked).map(item => item.value);
      appMessage('Operation not allowed at the moment', 'info')
      return
      await apiRequest.patch(`/teams/${team?.id}`, { team_ids, permission_ids })
    } catch (err) {
      toastUp(errMsg(err), false)
    } finally {
      await setIsUpdating(true)
      await setFreeze(false)
    }
  }
  return (
    <div className={'flex flex-col gap-2  h-[100%]'}>
      <div className={'flexy-row py-1.5 px-4'}>
        {horStack([
          <p className={'font-semibold text-start my-auto me-10'}>{'Team 1' || team?.name}</p>,
          ...(!team?.is_pending_approval ? [secondaryButton(() => appMessage('Feature pending', 'info'), 'Approve Changes', false, 'Approving', null, { danger: true })] : [])
        
        ])}
        {horStack([
          ...(!!roles[0] ? [outlinedButton(() => appMessage('Feature pending', 'info'), 'Audit', false, null, { type: 'dashed', icon: <AuditOutlined/> })] : []),
          ...(updateDetected ? [outlinedButton(async () => {
            await setInitialRoles()
            await setInitialPerms()
          }, 'Reset', false, null, { type: 'dashed', icon: <UndoOutlined/> })] : []),
          ...(updateDetected ? [containedButton(updateRole, 'Save', isUpdating, 'Saving', null, null)] : [])
        ])}
      </div>
      <div className={'w-full h-[100%] overflow-y-auto overscroll-contain overflow-x-hidden pb-4'}>
        <Spin spinning={loading}>
          <Card className={'w-full'} hoverable>
            <Divider type={'horizontal'} orientation={'left'}>Team Roles (Select to remove/add)</Divider>
            <Row gutter={[8, 8]} align={'stretch'} justify={"start"} className={'w-full '}>
              {roleOptions.map((item, index1) => (
                <Col xs={24} sm={24} md={24} lg={6} xl={6} className={'w-full'} key={index1}>
                  <Checkbox
                    disabled={freeze}
                    onChange={async e => {
                      await setFreeze(true)
                      let existingRoles = [...roleOptions.map(item => ({ ...item, checked: item.value === e.target.value ? !item.checked : item.checked }))]
                      await setRoleOptions(existingRoles)
                      await setFreeze(false)
                    }}
                    checked={item.checked}
                    className={'w-full'}
                    value={item.value}>{item.label}</Checkbox>
                </Col>))}
            </Row>
          </Card>
        
        </Spin>
        
        <Divider type={'horizontal'} orientation={'left'}>
          <div className={'flex flex-row gap-4'}>
            <p className={`font-semibold text-start my-auto ${!!team ? 'me-10' : ''}`}>Team Permissions</p>
            <Select popupMatchSelectWidth={false}
                    loading={loading}
                    onClear={() => setInitialPerms()}
                    value={filter}
                    options={moduleOptions}
                    placeholder={'Filter module'}
                    onSelect={(value, option) => {
                      setInitialPerms(value)
                    }}
                    className={'min-w-[200px]'}
                    allowClear
            />
            {!!selectedModule && outlinedButton(() => setInitialPerms(), 'Clear Filter', false, null, { type: 'dashed', danger: true })}
          
          </div>
        </Divider>
        <Row gutter={[8, 8]} justify={"space-between"} className={'overflow-y-auto overscroll-contain'}>
          {Object.keys(permissionGroups).map((permGroup, index) =>
            (<Col xs={24} sm={24} md={24} lg={selectedModule ? 24 : 12} xl={selectedModule ? 24 : 12} className={'w-full'}>
              <Card title={permGroup} className={'w-full h-[100%]'} key={index} hoverable>
                <Row gutter={[8, 8]} align={'stretch'} justify={"start"} className={'w-full '}>
                  {permissionGroups[permGroup].map((item, index1) =>
                    (<Col xs={24} sm={24} md={24} lg={selectedModule ? 6 : 12} xl={selectedModule ? 6 : 12} className={`w-full`}>
                      <Checkbox key={index1}
                                disabled={freeze||item.disabled}
                                onChange={async e => {
                                  let rolePerms = team?.roles.map(item => item.permissions).flat(1).map(item => item.id)
                                  await setFreeze(true)
                                  let permGroupBeforeToggle = { ...permissionGroups }
                                  permGroupBeforeToggle[permGroup] = [...permGroupBeforeToggle[permGroup]
                                    .map(item => ({
                                      ...item,
                                      checked: item.value === e.target.value ? !item.checked : item.checked
                                    }))]
                                  //toggle checked state
                                  //Toggle check to determine whether to show the update/reset buttons
                                  await setPermissionGroups(permGroupBeforeToggle)
                                  await setFreeze(false)
                                }}
                                
                                checked={item.checked||item.disabled}
                                className={'w-full'}
                                value={item.value}>{item.disabled?<Tooltip title={'Exists in selected roles'}>{item.label}</Tooltip>:item.label}
                      </Checkbox>
                    </Col>))}
                </Row>
              </Card>
            </Col>)
          )}
        </Row>
      </div>
    </div>
  );
};

export default TeamCard;