export const MasterList = [
  {
    name: 'Assessments',
    sub_modules: [
      {
        name: 'Start Assessment',
        items_list: [
          'Procedure',
          'Wound location',
          'Wound status',
          'Biologics Treatment number',
          'Biologic kits',
          'Wound type',
          'Wound Stage',
          'Exudate',
          'Wound side',
          'Conservative treatment',
          'wound condition',
          'Wound duration',
          'Response to therapy',
          'wound edges',
          'Wound bed',
          'Tunneling direction',
          'Exposed structures',
          'Periwound color',
          'Exudate type',
          'Pain level',
          'Epithelialization',
          'odor',
          'Infections',
          'Order/Recommendations',
          'Other related factors',
        ],
      },
      {
        name: 'Assessment Observations',
        items_list: [
          'Circulation',
          'Neurologic status',
          'Conservative treatment',
          'Comorbidities',
        ],
      },
      {
        name: 'DME',
        items_list: [
          'Treatment Scenarios and Supplies',
          'Plan of care',
          'Treatment filter',
        ],
      },
      {
        name: 'Vascular measurements',
        items_list: [
          'Right DP',
          'Left DP',
          'Right PT',
          'Left PT',
          'Skin Temperature Right',
          'Skin temperature Left',
          'Digital Hair Right',
          'Digital Hair Left',
        ],
      },
      {
        name: 'Neurology Measurements',
        items_list: [
          'Monafilament Testing Right',
          'Monafilament Testing Left',
          'Pin prick right',
          'pin prick left',
          'Soft Touch Right',
          'Soft Touch Left',
        ],
      },
      {
        name: 'Lab',
        items_list: ['PCR Type', 'Lab Region'],
      },
    ],
  },
  {
    name: 'Appointments',
    sub_modules: [
      {
        name: 'Create New',
        items_list: ['Wound location', 'Procedure', 'visit type'],
      },
    ],
  },
  {
    name: 'Patients',
    sub_modules: [
      {
        name: 'Allergies',
        items_list: ['Allergy type', 'Allergen', 'Reaction', 'Status'],
      },
      {
        name: 'Immunization',
        items_list: [
          'VFC Eligibility',
          'Immunization',
          'status',
          'Immunity status',
          'Manufacturer',
          'Administration site',
          'Route of administration',
          'Rejection reason',
        ],
      },
      {
        name: 'Medication',
        items_list: ['Frequency'],
      },
      {
        name: 'Diagnosis',
        items_list: ['Problem status'],
      },
      {
        name: 'Social History',
        items_list: ['Alcohol', 'Tobacco', 'Drugs', 'Occupation', 'Travel'],
      },
      {
        name: 'Family History',
        items_list: ['Gender', 'Relationship', 'disease status'],
      },
      {
        name: 'Summary',
        items_list: ['Approval status', 'Patient status'],
      },
      {
        name: 'Insurance information',
        items_list: ['priority'],
      },
      {
        name: 'Create New Patient/Referral',
        items_list: ['Gender', 'Facesheet Type'],
      },
    ],
  },
  {
    name: 'Consultants',
    sub_modules: [
      {
        name: 'Consultant  card',
        items_list: ['Status', 'Gender', 'Degree', 'Designation', 'Role'],
      },
      {
        name: 'Vehicle details',
        items_list: ['Vehicle make', 'Model', 'Year', 'Fuel type'],
      },
    ],
  },
  {
    name: 'Office',
    sub_modules: [
      {
        name: 'Create new',
        items_list: ['Document type'],
      },
    ],
  },
  {
    name: 'Medical Groups',
    sub_modules: ['Document type'],
  },
  {
    name: 'Orders',
    sub_modules: [
      {
        name: 'Create New',
        items_list: ['Supplies'],
      },
      {
        name: 'Location',
        items_list: ['Region', 'Subregion'],
      },
    ],
  },
];

export const StateArray = [ "AK - Alaska",
                "AL - Alabama",
                "AR - Arkansas",
                "AS - American Samoa",
                "AZ - Arizona",
                "CA - California",
                "CO - Colorado",
                "CT - Connecticut",
                "DC - District of Columbia",
                "DE - Delaware",
                "FL - Florida",
                "GA - Georgia",
                "GU - Guam",
                "HI - Hawaii",
                "IA - Iowa",
                "ID - Idaho",
                "IL - Illinois",
                "IN - Indiana",
                "KS - Kansas",
                "KY - Kentucky",
                "LA - Louisiana",
                "MA - Massachusetts",
                "MD - Maryland",
                "ME - Maine",
                "MI - Michigan",
                "MN - Minnesota",
                "MO - Missouri",
                "MS - Mississippi",
                "MT - Montana",
                "NC - North Carolina",
                "ND - North Dakota",
                "NE - Nebraska",
                "NH - New Hampshire",
                "NJ - New Jersey",
                "NM - New Mexico",
                "NV - Nevada",
                "NY - New York",
                "OH - Ohio",
                "OK - Oklahoma",
                "OR - Oregon",
                "PA - Pennsylvania",
                "PR - Puerto Rico",
                "RI - Rhode Island",
                "SC - South Carolina",
                "SD - South Dakota",
                "TN - Tennessee",
                "TX - Texas",
                "UT - Utah",
                "VA - Virginia",
                "VI - Virgin Islands",
                "VT - Vermont",
                "WA - Washington",
                "WI - Wisconsin",
                "WV - West Virginia",
                "WY - Wyoming"]


export const TrimmedStateArray = ["Alaska",
  "Alabama",
  "Arkansas",
  "American Samoa",
  "Arizona",
  "California",
  "Colorado",
  "Connecticut",
  "District of Columbia",
  "Delaware",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Iowa",
  "Idaho",
  "Illinois",
  "Indiana",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Massachusetts",
  "Maryland",
  "Maine",
  "Michigan",
  "Minnesota",
  "Missouri",
  "Mississippi",
  "Montana",
  "North Carolina",
  "North Dakota",
  "Nebraska",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "Nevada",
  "New York",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Virginia",
  "Virgin Islands",
  "Vermont",
  "Washington",
  "Wisconsin",
  "West Virginia",
  "Wyoming"]