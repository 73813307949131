import React, {useEffect, useState} from 'react';
import {Button, Modal} from "antd";
import {modalClose} from "../common/helpers";
import {toast} from "react-toastify";
import axios from 'axios';
import * as Sentry from "@sentry/react";
import {CloseOutlined} from '@ant-design/icons';

const PdfViewer = ({pdf, onCancel, visible, loading, setLoading, docName}) => {
  const [doc, setDoc] = useState(null);
  const [docHeight, setDocHeight] = useState(`${window.innerHeight}px`);
  useEffect(() => {
    function handleResize() {
      setDocHeight(`${window.innerHeight}px`)
    }
    
    window.addEventListener('resize', handleResize)
    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })
  const loadPdf = async (url) => {
    try {
      console.log('url: ', url)
      const response = await axios.get(url, {responseType: 'blob'})
      console.log('PDF: ', response)
      if (!response.data) {
        return toast('Document download failed', {type: toast.TYPE.ERROR, autoClose: 5000});
      }
      let pdf = new Blob([response.data], {type: 'application/pdf'})
      const fileURl = window.URL.createObjectURL(response.data)
      setDoc(fileURl)
      setLoading(false)
    } catch (e) {
      Sentry.captureException(e);
      console.log(e)
      toast('Document download failed', {type: toast.TYPE.ERROR, autoClose: 5000});
    }
  }
  return (
    (<Modal
      title={null}
      destroyOnClose={true}
      open={visible}
      styles={{body:{padding: 0, margin: 0, minHeight: "660px", width: '100%'}}}
      footer={null}
      style={{top: 0, padding: 0, marginBottom: 0, height: '100%', minHeight: "660px",}}
      onCancel={onCancel}
      closable={false}
      className={'pdf-viewer-body'}
      closeIcon={modalClose(onCancel)}
      maskClosable={loading}
      width={"80%"}>
      <div className="iframe-parent">
        <object data={`${pdf}`} type="application/pdf" style={{width: "100%", height: docHeight}} onLoad={event => setLoading(false)}/>
        {!loading && <Button size={'large'} shape={'circle'} onClick={onCancel} disableFocusRipple={true} icon={<CloseOutlined style={{color: '#EF8100'}}/>}
                             className="iframe-button"></Button>}
      </div>
    </Modal>)
  );
};
export default PdfViewer;
