import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLocation, useNavigate} from "react-router-dom";
import {AutoComplete, Checkbox, DatePicker, Form, Input, Select, Switch} from 'antd'
import dayjs from "dayjs"
import { createImmunization, updateImmunization } from '../../../../../app/Reducers';
import { apiRequest } from '../../../../../app/Apis';
import NumberFormat from "react-number-format";
import { cleanObject, emptyFun, executeChunkFn, GetOptions, inputRow } from "../../../../../common/helpers";
import TextArea from 'antd/es/input/TextArea';

export const NewImmunizationForm = ({ closeModal, setisExecuting, editingForm, immunization, masterList, patient }) => {
  const dateFormat = 'MM/DD/YYYY';
  const uiFields = (immunization_) => {
    let immunization = { ...immunization_ }
    // immunization.ordered_by = null
    // immunization.administered_by = null
    cleanObject(immunization)
    return {
      ...immunization, ...(immunization.administered_date && { administered_date: dayjs(immunization.administered_date) }),
      ...(immunization.expiration_date && { expiration_date: dayjs(immunization.expiration_date) }), is_rejected: !!immunization.is_rejected
    }
  }
  const [form] = Form.useForm();
  const { Option } = Select;
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [consultant, setConsultant] = useState(null)
  const [administeredBy, setAdministeredBy] = useState(null)
  const [consultantOptions, setconsultantOptions] = useState([])
  const [admDate, setAdmDate] = useState({})
  const [initialValues, setInitialValues] = useState(editingForm ? uiFields(immunization) : {});
  useEffect(() => {
    setInitialValues(editingForm ? uiFields(immunization) : {})
    return emptyFun
  }, [immunization, editingForm])
  const handleConsultantssearch = async (value) => {
    const { data } = await apiRequest.get(`/consultants?search=${value}`)
    if (data) {
      setconsultantOptions(data.data);
    }
  };
  const onOrderedBySelect = (value) => {
    // console.log('onSelect', value, form.getFieldValue('ordered_by'));
    let wcc = consultantOptions.find(item => item.name === value)
    setConsultant(wcc)
  };
  const onAdministeredBySelect = (value) => {
    // console.log('onSelect', value, form.getFieldValue('administered_by'));
    let wcc = consultantOptions.find(item => item.name === value)
    setAdministeredBy(wcc)
  };
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const onFinish = async (values) => {
    let payload
    if (editingForm) {
      payload = { data: { ...values, patient_id: patientId/*, ordered_by: consultant.id, administered_by: administeredBy.id*/ }, immunization_id: immunization.id }
    } else {
      payload = { ...values, patient_id: patientId/*, ordered_by: consultant.id, administered_by: administeredBy.id*/ }
    }
    if (!values.rejection_reason === 'Other') {
      delete payload.rejection_notes
    }
    await executeChunkFn(dispatch, editingForm ? updateImmunization : createImmunization, payload, setisExecuting, closeModal)
  };
  const disabledFutureDates = (current) => {
    let customDate = dayjs();
    return current && current > dayjs(customDate, "YYYY-MM-DD");
  }
  const disabledExpDates = (current) => {
    let customDate = dayjs();
    return current && current < dayjs(customDate, "YYYY-MM-DD");
  }
  const onDateChange = (date, dateString) => setAdmDate(dateString === 'Invalid date' || !dateString ? null : dateString)

  const onSwitchChange = (e) => {
    if (e.target.checked) {
      form.setFieldsValue({ ...form.getFieldsValue(), rejection_reason: undefined })
      setInitialValues({...initialValues,is_rejected:true})
    }
  };

  return (
    <Form
      name="new-patient"
      id="immunizationForm"
      form={form}
      labelCol={{ span: 16 }}
      onValuesChange={(changedValues, allValues) => {
        // console.log('changedValues:', changedValues)
        console.log('allValues:', allValues)
        setInitialValues(allValues)
      }}
      layout="vertical"
      initialValues={{ ...initialValues }}
      onFinish={onFinish}>
      {inputRow([
        <Form.Item
          name="ordered_by"
          label="Ordered By"
          style={{ width: '100%' }}
          rules={[{ required: true, message: 'Missing Ordered By' }]}>
          <AutoComplete popupMatchSelectWidth={false}
            style={{ width: "100%" }}
            onSelect={onOrderedBySelect}
            onSearch={handleConsultantssearch}
            placeholder="Search for Consultant">
            {consultantOptions.map(item => (<Option key={item.id} value={item.name}>
              {item.name}
            </Option>))}
          </AutoComplete>
        </Form.Item>,
        <Form.Item
          name="vfc_eligibility"
          label="Vfc Eligibility"
          style={{ width: '100%' }}
          rules={[{ required: true, message: 'Missing Vfc Eligibility' }]}>
          <Select
            placeholder="select"
            popupMatchSelectWidth={false}
            allowClear>
            {GetOptions('v_f_c_eligibility')}
          </Select>
        </Form.Item>
      ])}
      {inputRow([
        <Form.Item
          name="name"
          label="Immunization"
          style={{ width: '100%' }}
          rules={[{ required: true, message: 'Missing Immunization' }]}>
          <Select
            placeholder="select"
            popupMatchSelectWidth={false}
            allowClear>
            {GetOptions('immunization')}
          </Select>
        </Form.Item>,
        <Form.Item
          name="status"
          label="Status"
          style={{ width: '100%' }}
          rules={[{ required: true, message: 'Missing Status' }]}>
          <Select
            placeholder="select"
            allowClear>
            {GetOptions('status')}
          </Select>
        </Form.Item>
      ])}
      {inputRow([
        <Form.Item
          name="administered_date"
          label="Administered"
          style={{ width: '100%' }}
          rules={[{ required: true, message: 'Missing Administered date' }]}>
          <DatePicker disabledDate={disabledFutureDates} onChange={onDateChange} style={{ width: '100%' }} format={dateFormat} />
        </Form.Item>,
        <Form.Item
          name="expiration_date"
          label="Expiration"
          style={{ width: '100%' }}
          rules={[{ required: false, message: 'Missing Expiration date' }]}>
          <DatePicker style={{ width: '100%' }} format={dateFormat} disabled={!!!admDate} disabledDate={disabledExpDates} />
        </Form.Item>
      ])}
      {inputRow([
        <Form.Item
          name="immunity_status"
          label="Immunity Status"
          style={{ width: '100%' }}
          rules={[{ required: true, message: 'Missing Immunity Status' }]}>
          <Select
            popupMatchSelectWidth={false}
            placeholder="select"
            allowClear>
            {GetOptions('immunity_status')}
          </Select>
        </Form.Item>,
        <Form.Item
          name="amount_in_ml"
          label="Amount Administered(ml)"
          style={{ width: '100%' }}
          rules={[{ required: true, message: 'Missing Amount Administered' }]}>
          <NumberFormat autoComplete={'off'} customInput={Input} format="####" placeholder="amount" />
        </Form.Item>
      ])}
      {inputRow([
        <Form.Item
          name="vaccine_lot_number"
          label="Vaccine Lot Number"
          style={{ width: '100%' }}
          rules={[{ required: false, message: 'Missing vaccine Lot Number' }]}>
          <Input autoComplete={'off'} type="text" />
        </Form.Item>,
        <Form.Item
          name="manufacturer"
          label="Manufacturer"
          style={{ width: '100%' }}
          rules={[{ required: false, message: 'Missing Manufacturer' }]}>
          <Select
            popupMatchSelectWidth={false}>
            {GetOptions('manufacturer')}
          </Select>
        </Form.Item>
      ])}
      {inputRow([
        <Form.Item
          name="administration_site"
          label="Administration Site"
          style={{ width: '100%' }}
          rules={[{ required: false, message: 'Missing Administration Site' }]}>
          <Select
            popupMatchSelectWidth={false}
            placeholder="Administration Site"
            allowClear>
            {GetOptions('administration_site')}
          </Select>
        </Form.Item>,
        <Form.Item
          name="administered_by"
          label="Administered By"
          style={{ width: '100%' }}
          rules={[{ required: false, message: 'Missing Administered By' }]}>
          <AutoComplete popupMatchSelectWidth={false}
            style={{ width: "100%" }}
            onSelect={onAdministeredBySelect}
            onSearch={handleConsultantssearch}
            placeholder="Search for Consultant">
            {consultantOptions.map(item => (<Option key={item.id} value={item.name}>{item.name}</Option>))}
          </AutoComplete>
        </Form.Item>
      ])}
      <Form.Item
        name="route_of_administration"
        label="Route Of Administration"
        rules={[{ required: true, message: 'Missing Route Of Administration' }]}>
        <Select>
          {GetOptions('route_of_administration')}
        </Select>
      </Form.Item>
      {initialValues.status !== 'active' && <Form.Item
        name="is_rejected"
        valuePropName="checked"
        label="">
        <Checkbox onChange={onSwitchChange} >Rejected Immunization</Checkbox>
      </Form.Item>}
      {initialValues.is_rejected ? <Form.Item
        name="rejection_reason"
        label="Rejection Reason"
        rules={[{ required: true, message: 'Reason is required' }]}
        style={{ width: '100%' }}>
        <Select
          popupMatchSelectWidth={false} placeholder="select" allowClear>
          {masterList.find(item => item.system_name === 'rejection_reason')?.options?.map(item => <Option
            value={item}>{item.toString().replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}</Option>) || []}
        </Select>
      </Form.Item> : null}
      {(initialValues.is_rejected && initialValues.rejection_reason === 'Other') && <Form.Item
        name="rejection_notes"
        label="Rejection notes"
        style={{ width: '100%' }}
        rules={[{ required: true, message: 'Missing Notes' }]}>
        <Input autoComplete={'off'} placeholder="type here" />
      </Form.Item>}
      <Form.Item
        name="notes"
        label="Immunization Notes"
        style={{ width: '100%' }}
        rules={[{ required: false, message: 'Missing Notes' },{ max: 1000, message: 'Cannot exceed 1000 characters' }]}>
        <TextArea autoComplete={'off'} placeholder="Type here" />
      </Form.Item>
    </Form>
  );
}
const mapStateToProps = (state) => ({  consultants: state.consultants.consultants, masterList: state.masters.masterListRaw, patient: state.patients.selectedPatient })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(NewImmunizationForm)
