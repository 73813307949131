import React from 'react'
import { connect, useDispatch } from 'react-redux'
import HeaderTop from '../../components/HeaderTop'
import { Tabs } from 'antd'
import { Subtitle1 } from '../../components';
import { setBillingNavs } from '../../app/Reducers';
import PendingReviewEncounters from './components/PendingReviewEncounters';
import ReadyForSubmission from './components/ReadyForSubmission';
import Submitted from './components/Submitted';

const { TabPane } = Tabs;


export const Index = ({ billingNavs }) => {
    const dispatch = useDispatch()
    const tabList = ['Pending review', 'Ready for submission', 'Submitted']
    const setActiveTab = async (tab) => {
        await dispatch(setBillingNavs({ tab: tab }))
    }

    const Tabs_ = () => {
        return (<Tabs defaultActiveKey={billingNavs?.tab} onChange={setActiveTab} tabBarStyle={{ marginBottom: 0 }}>
            {tabList.map(item => <TabPane key={item} tab={<Subtitle1>{item}</Subtitle1>}>
            </TabPane>)}
        </Tabs>)
    }


  return (
      <div className="main-page">
          <HeaderTop child={<Tabs_ />} customStyle={{ style: { paddingBottom: 0 } }} />
          {billingNavs.tab === "Pending review" ? <PendingReviewEncounters /> : billingNavs?.tab === "Ready for submission" ? <ReadyForSubmission /> : billingNavs.tab === "Submitted" ? <Submitted /> : null}
   </div>
  )
}

const mapStateToProps = (state) => ({
    billingNavs: state.navs.billingNavs
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Index)