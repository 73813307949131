import React from 'react';
import {connect, useDispatch} from "react-redux";
import {setConsultantHomeNavs} from "../../app/Reducers";
import {Tabs} from "antd";
import {Subtitle1} from "../../components";
import HeaderTop from "../../components/HeaderTop";
import ConsultantList from "./ConsultantList";
import ConsultantMileages from "./components/ConsultantMileages";

import Departments from "./Departments/DepartmentList";
import {usePermissionCheck} from '../../hooks/usePermissionCheck';
import { IsItemEnabled } from '../../common/helpers';

const { TabPane } = Tabs;
const ConsultantModule = ({ navs, activeUser, settings}) => {
  const dispatch = useDispatch()
  const setActiveTab = async (tab) => await dispatch(setConsultantHomeNavs({ tab: tab }))
  const can_view_mileage_portal = usePermissionCheck('view_mileage_portal', false, [])
  const can_view_consultant_listing = usePermissionCheck('view_consultant_listing', false, [])
  
  // const TabList = [can_view_consultant_listing && 'Consultants', ...((can_view_mileage_portal && can_view_consultant_listing) ? ['Mileage'] : []), !activeUser?.facilityProfile && IsItemEnabled(settings, 'modules', 'Task') && 'Departments']
  const TabList = [can_view_consultant_listing && 'Consultants', ...((can_view_mileage_portal && can_view_consultant_listing) ? ['Mileage'] : [])]
  const Tabs_ = () => {
    return (<Tabs defaultActiveKey={can_view_consultant_listing ? navs.consultantHomeNavs.tab : "Departments"} onChange={setActiveTab}
                  tabBarStyle={{ marginBottom: 0 }}>
      {TabList.map(item => <TabPane key={item} tab={<Subtitle1>{item}</Subtitle1>}>
      </TabPane>)}
    </Tabs>)
  }
  return (<div className="main-page">
    <HeaderTop child={<Tabs_/>} customStyle={{ style: { paddingBottom: 0 } }}/>
    {can_view_consultant_listing ? (navs.consultantHomeNavs.tab === 'Consultants' ? <ConsultantList/> :
      navs.consultantHomeNavs.tab === 'Mileage' ? <ConsultantMileages/> : <Departments/>) : <Departments/>}
  </div>)
};

const mapStateToProps = (state) => ({
  navs: state.navs,
  activeUser: state.auth.activeUser,
  settings: state?.settings?.settings
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ConsultantModule)
