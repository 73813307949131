/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Col, Form, Modal, Row } from 'antd';
import { Headline6 } from '../../../../components/general';
import { deleteSocialHistory, fetchSocialHistory } from '../../../../app/Reducers';

import { addButton, addButtonClean, dialogCloser, emptyFun, formSubmitButton, horStack, kebabMenus, boldKeyCapitalizedValue, modalClose, outlinedButton } from '../../../../common/helpers';
import NewSocialHistoryForm from './forms/SocialHistoryForm';
import KebabMenu from '../../../../components/KebabMenu';
import AuditLogViewer from '../../../../components/AuditLogs/AuditLogViewer';
import { useSubscription } from '../../../../hooks/MqttHooks/useSubscription';
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';

export const SocialHistorySection = ({ socialHistory, patient, activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const [allergiesForm, setAllergiesForm] = useState(false);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  // const socialHistory = useSelector(state => state.patients.healthData.socialHistory);
  // const patient = useSelector(state => state.patients.selectedPatient);
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1));
  const [isExecuting, setisExecuting] = useState(false);
  const [selected, setSelected] = React.useState(0);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [socialHistoryForm] = Form.useForm();
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  const can_create_patient_social_history = usePermissionCheck('create_patient_social_history', false, [])
  const can_update_patient_social_history = usePermissionCheck('update_patient_social_history', false, [])
  const can_delete_patient_social_history = usePermissionCheck('delete_patient_social_history', false, [])
  const showPopconfirm = (id) => setSelected(id);
  const handleCancel = () => setSelected(0)
  /**
   * Executes when OK of the delete prompt is clicked
   * */
  const handleOk = async (sHistoryID) => {
    await setConfirmLoading(true);
    let params = { sHistoryID, patientId }
    await dispatch(deleteSocialHistory(params))
    socialHistoryForm.resetFields();
    await setSelected(0)
    await setConfirmLoading(false);
  };
  const dispatch = useDispatch();
  const { message } = useSubscription(`view_patient_social_history/${patientId}`, { qos: 2, nl: true });
  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message);
      loadFamilyHistory(true);
    }
    return emptyFun;
  }, [message]);
  useEffect(() => {
    loadFamilyHistory(false);
    return emptyFun;
  }, []);
  const loadFamilyHistory = (isMQMessage) => {
    /*if (!!socialHistory && !isMQMessage && socialHistory.id && socialHistory.patient_id === patientId) {
      return;
    }*/
    dispatch(fetchSocialHistory(patientId));
  };
  const actionMenus = kebabMenus(can_update_patient_social_history && !patient.deleted_at, can_view_audit_logs, can_delete_patient_social_history)

  async function kebabMenuClick(e) {
    console.log(actionMenus[e.key].title);
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setAllergiesForm(true);
        break;
      case 'Audit':
        setShowAuditDialog(true);
        break;
      case 'Delete':
        showPopconfirm(socialHistory.id)
        break
      default:
    }
  }

  return (
    (<div className="w-full">
      {showAuditDialog && (<AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} resourceType={'Patient'} loading={false}
        resourceDescription={`${patient.name}'s social history`}
        defaultValues={{ auditableId: socialHistory?.id, auditable: 'social_history', format: 'json' }} />)}
      <Modal
        closeIcon={modalClose(dialogCloser(setAllergiesForm))}
        footer={[
          <div
            className="entity-filters flex-boy-row"
            key={''}
            style={{ justifyContent: 'flex-end' }}>
            {horStack([
              outlinedButton(dialogCloser(setAllergiesForm), 'Cancel', isExecuting),
              formSubmitButton('socialHistoryForm', !!socialHistory?.tobacco ? 'Update' : 'Create', isExecuting, !!socialHistory?.tobacco ? 'Updating' : 'Creating')
            ])}
          </div>
        ]}
        maskClosable={false}
        onCancel={dialogCloser(setAllergiesForm)}
        onOk={dialogCloser(setAllergiesForm)}
        title={!!socialHistory?.tobacco ? 'Edit Social History' : 'Add Social History'}
        open={allergiesForm}>
        <NewSocialHistoryForm closeModal={dialogCloser(setAllergiesForm)} patient={patient} setisExecuting={setisExecuting} form={socialHistoryForm} />
      </Modal>
      <Row >
        <Col span={24}>

          <div className="flex-boy-row-space" style={{ width: '100%' }}>
            <Headline6>Social History</Headline6>
            {socialHistory?.occupation ? (can_update_patient_resource && !patient.deleted_at && socialHistory !== "loading" ?
              (<KebabMenu menus={actionMenus} recordItem={socialHistory} handleMenuClick={kebabMenuClick} resource={'social history'}
                handleOk={() => handleOk(socialHistory.id)}
                handleCancel={handleCancel} confirmLoading={confirmLoading} showConfirmDialog={selected === socialHistory.id} />) : null) :
              (can_create_patient_social_history && !patient.deleted_at && socialHistory !== "loading" && addButtonClean(() => setAllergiesForm(true)))}
          </div>
          <div style={{ width: '100%' }}>
            {boldKeyCapitalizedValue('Alcohol:', socialHistory?.alcohol || 'Not selected')}
            {boldKeyCapitalizedValue('Tobacco:', socialHistory?.tobacco || 'Not selected')}
            {boldKeyCapitalizedValue('Drugs:', socialHistory?.drugs || 'Not selected')}
            {boldKeyCapitalizedValue('Occupation:', socialHistory?.occupation || 'Not selected')}
            {boldKeyCapitalizedValue('Travel:', socialHistory?.travel || 'Not selected')}
          </div>

        </Col>
      </Row>
    </div>)
  );
};
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser,
  socialHistory: state.patients.healthData.socialHistory,
  patient: state.patients.selectedPatient
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SocialHistorySection);
