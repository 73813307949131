import React, {useEffect} from 'react';
import {Col, Form, Row} from "antd";
import {cleanObject, dynamicFormOnFinish, emptyFun, getInputField} from "../common/helpers";

const DynamicForm = ({form, inputFields, onDynamicFormEntriesResult, formProps, formName, formId, formFooter, phoneValue, setPhoneValue, onValuesChange, defaultValues,isFormUsedAsFilter}) => {
  
  const onFinish = async values_ => {
    //Override default values if removed i.e. not required  during edit
    let defValues=cleanObject({ ...defaultValues,...values_ })
    let values = await dynamicFormOnFinish(inputFields, values_,isFormUsedAsFilter)
    if (!!onDynamicFormEntriesResult) {
      onDynamicFormEntriesResult({values: {...defValues,...values, ...phoneValue}, actionName: inputFields.actionName})
    }
  };
  useEffect(() => {
    console.log({defaultValues})
    if (!!defaultValues) {
      form.setFieldsValue({...defaultValues})
    }
    return emptyFun
  }, [defaultValues])
  return <Form
    name={formName}
    id={formId}
    preserve={false}
    form={form}
    style={{width:'100%'}}
    layout="vertical"
    autoComplete="off"
    onFieldsChange={(changedFields, allFields) => {
      console.log(changedFields, allFields)
    }}
    onValuesChange={onValuesChange}
    initialValues={{...defaultValues}}
    onFinish={onFinish}
    {...formProps}>
    {inputFields.inputCardGroups && Object.keys(inputFields.inputCardGroups).map((key, index) => {
      return <div className={"shadow-card-box m-0 mb-4 w-full"}><Row key={index} gutter={[4, 0]} align={"bottom"} justify={"start"} className={'w-full'}>
        {inputFields.inputCardGroups[key].map((field, index) => field.fullRow ?
          <Col key={index} xs={24} sm={24} md={24} lg={24} xl={24} flex={"auto"} style={{ width: '100%' }}>{getInputField(form, field, phoneValue, setPhoneValue)}</Col> :
          <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}>{getInputField(form, field, phoneValue, setPhoneValue)}</Col>)}
      </Row>
      </div>
    })}
    {(inputFields.fields && !inputFields.inputCardGroups) && <div className={"shadow-card-box m-0 mb-4 w-full"}><Row gutter={[4, 0]} align={"stretch"} justify={"start"} className={'w-full m-0'}>
      {inputFields.fields.map(field => field.fullRow ?
        <Col xs={24} sm={24} md={24} lg={24} xl={24} flex={"auto"} style={{ width: '100%' }}>{getInputField(form, field, phoneValue, setPhoneValue)}</Col> :
        <Col xs={24} sm={24} md={12} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}>{getInputField(form, field, phoneValue, setPhoneValue)}</Col>)}
    </Row></div>}
    {formFooter}
  </Form>
};

export default DynamicForm;
