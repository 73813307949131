import React from 'react';
// import {Buffer} from 'buffer';
import './index.scss';
import './index.css'
import {store} from './app/store';
import App from './App';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import {persistStore} from 'redux-persist'
import {PersistGate} from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import {createRoot} from 'react-dom/client';
import ReactDOM from 'react-dom';

let Buffer = require('buffer/').Buffer
window.Buffer = window.Buffer || Buffer;
let persistor = persistStore(store)
if (module.hot) {
  module.hot.accept();
}

Sentry.init({
  environment: "wounddocs",
  dsn: "https://11d1126c22a7490a83e0c2bb6959bcba@o518414.ingest.sentry.io/4504590290518016",
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay()
  ],
  
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
  
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0
});

// Initialize Tracking

// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const domNode = document.getElementById('wpapproot');
const root = createRoot(domNode);
root.render(<React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App/>
    </PersistGate>
  </Provider>
</React.StrictMode>);
/*ReactDOM.render(<React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App/>
    </PersistGate>
  </Provider>
</React.StrictMode>, document.getElementById('wpapproot'));*/
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();




