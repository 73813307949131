import {useCallback, useState} from "react";
import _ from 'lodash';
import axios from 'axios';
import {mapboxToken} from '../configure/constants';

export const useAddressSearch = (setState, deps) => {
    const [isRun, setIsRun] = useState(false);

    /* if (!isRun) {
       setIsRun(true);
     }*/

    return useCallback(_.debounce(async function (value) {
        if (!value || value === '') {
            setState([])
            return
        }
        const { data } = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json`,
            { params: { access_token: mapboxToken, country: "us,ke" } });
        if (data) {
            setState(data?.features?.map(item => {
                let state = item.context.find(item => item.id.includes('region'))
                let city = item.context.find(item => item.id.includes('place'))
                let zip_code = item.context.find(item => item.id.includes('postcode'))
                return ({
                    label: item.place_name, value: item.place_name, city: city?.text || null, state: state?.text || null,
                    zip_code: zip_code?.text | null, coordinates: item.center
                });
            }));
        }
    }, 1000), deps)
};