import { Divider, Spin, Empty } from 'antd'
import { apiRequest } from '../../../app/Apis'
import { Headline6 } from '../../../components'
import React, { useEffect, useState } from 'react'
import { PatientStatsChart } from '../../Facilities/utils'
import { emptyFun, getFacilityBarColorStatus, getPatientsBarColorStatus } from '../../../common/helpers'

export default function AllFacilities({ region }) {
  const [total, setTotal] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [facilities, setFacilities] = React.useState([])

  async function fetchFacility(region) {
    setLoading(true)
    let queryString = '';
    if (!!region && region !== '' && region !== undefined) {
      queryString = `?region=${region}`;
    }
    const response = await apiRequest.get(`/facility_stats${queryString}`)
    const payload = response.data.data
    // find total number of facilities
    let tot = payload?.reduce((acc, curr) => acc + curr.count, 0)
    setTotal(tot)
    setFacilities(payload)
    setLoading(false)
  }

  useEffect(() => {
    fetchFacility()
    return emptyFun
  }, [])


  useEffect(() => {
    fetchFacility(region)
    return emptyFun
  }, [region])

  return (
    <div className="stats-card-box">
      <div style={{ width: '100%', padding: '5px' }}>
        <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>All Offices</Headline6>
        <Divider style={{ margin: '4px', color: '#E2E4EA', width: '100%' }} />
      </div>
      <div style={{ width: '100%', padding: '15px' }}>
        <Headline6 style={{ fontWeight: 'bold', fontSize: '16px' }}>Total Offices</Headline6>
        <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>{total}</Headline6>
      </div>
      {facilities?.length > 0 ? <div style={{ width: '100%' }}>
        <Spin spinning={loading}>
          {facilities?.map((facility) => (
            <PatientStatsChart title={facility?.type} percentage={facility?.percentage} color={getFacilityBarColorStatus(facility?.type)} total={facility?.count} />
          ))}
        </Spin>
      </div> :
        <div style={{ width: '100%', padding: '15px' }}>
          <Empty description="No Office Statistics" />
        </div>
      }
    </div>
  )
}