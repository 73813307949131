import { useState } from "react"
import { Segmented, List, Empty, Spin, Divider } from "antd"
import { Subtitle1 } from "../../../components";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { sentenceCaseNoDash } from "../../../common/helpers";
import { apiRequest } from "../../../app/Apis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PatientStatsChart } from "../../Facilities/utils";
import { getPatientsBarColorStatus } from "../../../common/helpers";
import TinyAppointmentChart from "./TinyAppointmentChart";

export default function TinyTable({ patients, type, setStep, setNavParams, disabled }) {
  // construct an object with the type and value of the state of region
  // it will have a type and a region/state value
  const [navData, setNavData] = useState([])
  const [loading, setLoading] = useState(false)
  async function handleChange(value) {
    if (type === "patients" || type === "appointments" || type === "facility" || type === "assessments" || type === "consultants") {
      setLoading(true)
      setNavParams(value)
      setStep(1)
      setLoading(false)
    }
  }

  return (
    <div className="w-full">
      <div className="p-5">
        {!patients ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Empty description={"No data Found"} />
          </div>
        ) :
          <Spin spinning={loading}>
            <List size="large" dataSource={patients} pagination={{ onChange: (page) => { console.log(page); }, pageSize: 11 }}
              renderItem={(item, index) => (
                <div className="flex flex-row gap-2 justify-between items-center p-3 rounded-lg w-96 md:w-auto lg:w-96 sm:w-auto" style={{
                  backgroundColor: index % 2 === 0 ? '#F1F2F5' : '#fff',
                  cursor: 'pointer',
                }} onClick={() => handleChange(item)}>
                  <Subtitle1 style={{}}>{item.label || "Unknown"}</Subtitle1>
                  <div className="flex-boy-row">
                    <Subtitle1>{item.count || item.value}</Subtitle1>
                    {disabled ? null :
                      <div style={{ marginLeft: '100px' }}>
                        <RightOutlined />
                      </div>
                    }
                  </div>
                </div>
              )}
            />
          </Spin>
        }
      </div>
    </div>
  )
}
