import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { updateReports } from '../../../app/Reducers';
import { executeChunkFn } from '../../../common/helpers';
import { connect } from 'react-redux';

function UpdateCustomReport({ closeModal, setisExecuting, title, reports }) {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const [report, setReport] = useState(null)
  const [data, setData] = useState(null);

  const onFinish = async (values) => {
    let val = { ...values };
    val['report_type'] = report.report_type;
    let payload = {
      id: report.id,
      data: val,
    };

    await executeChunkFn(
      dispatch,
      updateReports,
      payload,
      setisExecuting,
      closeModal
    );
  };

  useEffect(() => {
    if(!reports){
      return ()=>{};
    }
    const res = reports.find(
      (e) => e.report_type === title?.replace(/ /g, '').toLowerCase()
      );
      setReport(res)
      form.setFieldsValue(res)
      return ()=>{};
    }, [reports, title]);

  return (
    <Form
      name='Update Custom Report'
      id='updateReport'
      labelCol={{ span: 16 }}
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={{ ...report }}
    >
      <Form.Item
        style={{ width: '100%' }}
        name='report_name'
        label='Report Name'
      >
        <Input autoComplete={'off'} placeholder='Enter Report Name' 
            disabled = {true} />
      </Form.Item>
      <Form.Item
        style={{ width: '100%' }}
        name='pre_assessment_summary'
        label='Pre Assessment Summary'
      >
        <TextArea
          rows={4}
          showCount
          placeholder='Maximum is 5000 characters'
          maxLength={5000}
          autoSize={true}
        />
      </Form.Item>
      <Form.Item
        style={{ width: '100%' }}
        name='procedure_summary'
        label='Procedure Summary'
      >
        <TextArea
          rows={4}
          showCount
          placeholder='Maximum is 5000 characters'
          maxLength={5000}
          autoSize={true}
        />
      </Form.Item>
      <Form.Item
        style={{ width: '100%' }}
        name='assessment_plan_summary'
        label='Assessment Plan Summary'
      >
        <TextArea
          rows={4}
          showCount
          placeholder='Maximum is 5000 characters'
          maxLength={5000}
          autoSize={true}
        />
      </Form.Item>
      <Form.Item
        style={{ width: '100%' }}
        name='assessment_procedure_summary'
        label='Assessment Procedure Summary'
      >
        <TextArea
          rows={4}
          showCount
          placeholder='Maximum is 5000 characters'
          maxLength={5000}
          autoSize={true}
        />
      </Form.Item>
      <Form.Item
        style={{ width: '100%' }}
        name='assessment_summary'
        label='Assessment Summary'
      >
        <TextArea
          rows={4}
          showCount
          placeholder='Maximum is 5000 characters'
          maxLength={5000}
          autoSize={true}
        />
      </Form.Item>
    </Form>
  );
}

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
  navs: state.navs,
  appConf: state.appConf,
  reports: state.reports.reports,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCustomReport);

