import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Form, Upload } from 'antd';
import { Headline6 } from '../../../components';
import { createConsultantVehicleMakes, fetchMasterListFields, updateConsultantVehicleModels, updateFieldOptions } from '../../../app/Reducers';
import { emptyFun, executeChunkFn } from "../../../common/helpers";
import { errMsg, toastUp } from "../../../app/Reducers/reducerUtils";
import ChipInput from 'material-ui-chip-input'
import { UploadOutlined } from '@ant-design/icons';
import { apiRequest } from '../../../app/Apis';
import { toast } from 'react-toastify';
import * as Sentry from "@sentry/react";

const FieldOptions = ({ selectedField, closeModal, setisExecuting, onChipEdit, onDeleteChip, isExecuting }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([])
  const [initialValues, setinitialValues] = useState(selectedField.options ? [...selectedField.options] : []);
  useEffect(() => {
    setinitialValues(selectedField.options ? [...selectedField.options] : [])
    return emptyFun
  }, [selectedField]);

  const onFinish = async (values) => {
    setisExecuting(true)
    if (selectedField.system_name === 'wound_location' && fileList.length) {
      const toastId = toast("Uploading field options", { type: "info", autoClose: false });
      let formData = new FormData();
      formData.append("csv_file", fileList[0]);
      let response = await apiRequest.post(`/masterlist_setting_form_fields_upload/${selectedField.id}/csv_upload`, formData).catch(err => {
        toast.update(toastId, { render: err.response?.data.message || "Server Error", type: toast.TYPE.ERROR, autoClose: 5000 });
        setisExecuting(false)
      })
      if (response && response.data) {
        toast.update(toastId, { render: `${selectedField.label} options successfully updated`, type: toast.TYPE.SUCCESS, autoClose: 5000 });
        setisExecuting(false)
        closeModal()
        dispatch(fetchMasterListFields())
      }

    } else {
      try {
        if (selectedField?.updateAction?.actionName === 'vehicle_models') {
          await executeChunkFn(dispatch, !selectedField?.updateAction?.vehicleId ? createConsultantVehicleMakes : updateConsultantVehicleModels,
            { "make_name": selectedField?.updateAction?.make, "models": initialValues, vehicleId: selectedField?.updateAction?.vehicleId }, setisExecuting, closeModal)
        } else {
          let payload = { ...selectedField, options: initialValues }
          await executeChunkFn(dispatch, updateFieldOptions, payload, setisExecuting, closeModal)
        }
      } catch (e) {
        Sentry.captureException(e);
        setisExecuting(false)
        //console.log(e)
        toastUp(errMsg(e, false), false)
      }
    }
  }

  const handleAddChip = async (chip) => {
    let new_chips = [...initialValues]
    new_chips.push(chip)
    setinitialValues([...new_chips])
    if (selectedField?.updateAction?.actionName === 'vehicle_make') {
      const { setselectedField, setOpen } = selectedField
      await setselectedField({ options: [], updateAction: { actionName: 'vehicle_models', make: chip }, label: `${chip} models` })
      closeModal()
      setOpen(true)
    }
    switch (selectedField?.updateAction?.actionName) {
      case 'treatment_supplies':
      case 'care_items':
      case 'treatment_scenarios':
        //console.log('selectedField: ', selectedField)
        const { openDynamicForm } = selectedField
        await openDynamicForm(chip)
        closeModal()
        break
      /*case 'care_items':
        appMessage('Addition of new plan of care items is not supported yet', 'error')
        closeModal()
        break*/
    }
  }
  const handleDeleteChip = (chip, index) => {
    let new_chips = [...initialValues]
    new_chips.splice(index, 1)
    setinitialValues([...new_chips])
    switch (selectedField?.updateAction?.actionName) {
      case 'vehicle_make':
      case 'treatment_supplies':
      case 'care_items':
      case 'treatment_scenarios':
        onDeleteChip({ chip, actionName: selectedField?.updateAction?.actionName })
        break
    }
  }
  const onChipClick = (e) => {
    switch (selectedField?.updateAction?.actionName) {
      case 'treatment_supplies':
      case 'care_items':
      case 'treatment_scenarios':
        //console.log('selectedField: ', selectedField)
        let chip = e.target.textContent
        //console.log('onChipClick: ', chip)
        onChipEdit({ chip, actionName: selectedField?.updateAction?.actionName })
        closeModal()
        break
      /*case 'care_items':
        appMessage('Addition/Update of new plan of care items is not supported yet', 'error')
        closeModal()
        break*/
    }
  }

  return <div>
    <Headline6>Content</Headline6>
    <Form
      name="fieldForm"
      id="fieldForm"
      labelCol={{ span: 16 }}
      layout="vertical"
      initialValues={{ ...initialValues }}
      onFinish={onFinish}
      form={form}>
      <ChipInput
        // style={{overflowWrap: 'break-word', flexWrap: 'wrap', textOverflow: 'wrap', flex: 1}}
        onClick={(e) => onChipClick(e)} disabled={isExecuting} size={"small"}
        value={[...initialValues]}
        onAdd={(chip) => handleAddChip(chip)}
        onDelete={(chip, index) => handleDeleteChip(chip, index)} />
      {selectedField.system_name === "wound_location" && <Form.Item
        style={{ width: '100%', marginTop: '20px' }}
        name="image_id"
        label="Upload CSV"
        rules={[{ required: false, message: 'Missing Facesheet image' }]}
      >
        <Upload accept=".csv,.xlsx"
          // defaultFileList={[...fileList]}
          beforeUpload={file => {
            setFileList([file])

            // Prevent upload
            return false;
          }}
          maxCount={1}>
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>

      </Form.Item>}
    </Form>

  </div>;
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(FieldOptions);
