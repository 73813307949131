/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Col, DatePicker, Divider, Form, Input, InputNumber, Modal, Row, Select, Spin, Steps } from 'antd';
import { apiRequest } from '../../../../app/Apis';
import { Body2, Subtitle1 } from '../../../../components/general'
import { toast } from 'react-toastify'
import _ from "lodash"
import { WoundAssessmentmodule } from '../../../../components/WoundAssessmentmodule';
import { emptyFun, modalClose, outlinedButton } from "../../../../common/helpers";

import dayjs from 'dayjs';

export const PlanOfCareAssessment = ({ procedure, procedures, wound, setassessmentModal, setisExecuting, masterList, woundLocations, progress, plan_of_care, fetchPlans,setPlanOfCareModal }) => {
  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]); else byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  let defaultOCR = [{ tissue_id: undefined, serial_number: undefined }]
  const [activeTab, setTab] = useState("wound_details")
  const { Step } = Steps;
  const { TextArea } = Input;
  const dispatch = useDispatch()
  /** when set, form fields are displayed*/
  const [assessmentForm, setassessmentForm] = useState(null)
  const { Option } = Select;
  const [treatmentScenario, setScenario] = useState(null)
  // const [woundLocations, setwoundLocations] = useState([])
  const [selectedwoundlocation, setselectedwoundlocation] = useState(null)
  const [visitSummary, setVisitSummary] = useState(null)
  const [extraNotes, setExtraNotes] = useState('')
  const [validationerrors, setvalidationerrors] = useState({
    wound_image: false, treatmentorderItems: false, treatmentprotocol: false, planofcareItems: false,
    extra_notes: false, visit_summary: false, wound_location: false
  })
  // treatment protocols stuff
  const [treatmentprotocol, settreatmentprotocol] = useState(null)
  const [treatmentOptions, setOptions] = useState([])
  const [treatmentorderItems, setTreatmentorderItems] = useState([])
  const [newTreatmentSupply, setnewTreatmentSupply] = useState(false)
  const [newPlanofCare, setnewPlanofCare] = useState(false)
  const [treatmentSupplies, setTreatmentSupplies] = useState([])
  const [planofcareSearch, setplanofCareSearch] = useState([])
  const [planofcareItems, setplanofCareItems] = useState([])
  const [assessmentKits, setAssessmentKits] = useState([]);
  const [uploadLoading, setUploadLoading] = useState([-1, -1]);
  const [assessmentDates, setassessmentDates] = useState([])


  const [woundImage, setwoundimage] = useState(null)
  const [woundDetails, setwoundDetails] = useState(null)


  const fetch_list_of_dates = async () => {
    const data = await apiRequest.get(`/assessments?wound=${wound?.id}`)
    if (data) {
      let dates = _.uniq(data.data.data.filter(item => item.procedure.includes("Biologics")).map(item => item.published_at))
      setassessmentDates(dates)
    }
  }


  const [form] = Form.useForm();
  const getForm = async () => {
    let data = await apiRequest.get("/procedures/Plan Of Care/assessment-form").catch(err => console.log(err))
    if (data && data.data.form) {
      setassessmentForm(data.data.form)
    }

  }


  useEffect(() => {
    // fetchAsssessmentForm(setassessmentForm, procedure, procedures)
    getForm()
    // fetchWoundLocations(setwoundLocations)
    fetch_list_of_dates()
    return emptyFun
  }, [wound])
  useEffect(() => {
    getForm()
    return emptyFun
  }, [])

  const onFinish = async (values) => {
    await setisExecuting(true)
    console.log(values)
    const data = { ...values }
    data.summary_from_date = dayjs(values.assessment_dates?.[0]).format("YYYY-MM-DD")
    data.summary_until_date = dayjs(values.assessment_dates?.[1]).format("YYYY-MM-DD")

    delete data.assessment_dates
    //    let payload = _.mapValues(data, _.method('toLowerCase'));
    data.substantiated_by = values.arterial_perfusion === "non-contributory" ? null : values.substantiated_by
    data.require_vascular_testing = values.arterial_perfusion === "non-contributory" ? null : values.require_vascular_testing
    data.vascular_testing = values.arterial_perfusion === "non-contributory" || values.require_vascular_testing === "no" ? null : values.vascular_testing
    const toastId = toast("Creating plan of care", { type: "info", autoClose: false });
    const response = await apiRequest.post(`wounds/${wound.id}/plan-of-care-summaries`, _.omitBy(data, _.isNull)).catch(async function (err) {
      await setisExecuting(false)
      console.log(err)
      toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 })
    })
    if (response && response.data && response.data.data) {
      toast.update(toastId, { render: "Plan of care created", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      await setisExecuting(false)
      await fetchPlans()
      // await setassessmentModal(false)
      await setPlanOfCareModal(false)
    }

  }


  const validateDebridement = async (values) => {
    let post_data = { ...values }
    if (!fileList.length && !woundImage) {
      let errors = { ...validationerrors }
      errors.wound_image = true
      setvalidationerrors(errors)
      setisExecuting(false)
      if (dummyRef.current) {
        dummyRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      let errors = { ...validationerrors }
      errors = {
        wound_image: false, treatmentorderItems: false, treatmentprotocol: false, planofcareItems: false,
        extra_notes: false, visit_summary: false
      }
      setvalidationerrors(errors)
      let formData = new FormData();
      // add one or more of your files in FormData
      // again, the original file is located at the `originFileObj` key
      const toastId = toast("Creating Assessment", { type: "info", autoClose: false });
      // formData.append("file", fileList[0].originFileObj);
      // const { data } = await apiRequest.post('/files', formData)
      // const image_id = data.data.id
      // formData.append("file", fileList[0].originFileObj);
      if (dummyFile) {
        let dataform = new FormData()
        dataform.append("file", dummyFile);
        const locationimage = await apiRequest.post('/files', dataform)
        post_data.wound_location_file_id = locationimage.data.data.id
      } else {
        post_data.wound_location_file_id = woundDetails?.woundLocationPath.id
      }
      post_data.image_id = fileList[0]?.image_id || woundImage?.id
      post_data.masked_image_id = post_data.masked_image_id = fileList[0] ? fileList[0].masked_image_id : woundDetails?.masked_image_id
      post_data.published_at = dayjs().format("YYYY-MM-D")
      post_data.visit_summary = visitSummary || ""
      // post_data.extra_notes = extraNotes || "No collagen"

      post_data.wound_id = wound.id
      const response = await apiRequest.post(`/assessments`, post_data).catch(function (err) {
        setisExecuting(false)
        console.log(err)
        toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 })
      })
      if (response && response.data) {
        toast.update(toastId, { render: "Assessment Created", type: toast.TYPE.SUCCESS, autoClose: 5000 });

        setisExecuting(false)
        setassessmentModal(false)
      }
    }
  }

  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    console.log(newFileList)
    setFileList(newFileList);
  };

  const calculateVolume = () => {
    const length = form.getFieldValue('length')
    const width = form.getFieldValue('width')
    const depth = form.getFieldValue('depth');
    let area = parseFloat(length) * parseFloat(width)

    form.setFieldsValue({ area, volume: parseFloat(length) * parseFloat(width) * parseFloat(depth) })
  }
  const [selectedProcedure, setselectedProcedure] = useState(procedure)

  const [changeProtocol, setchangeProtocol] = useState(false)
  const [currentPossition, setcurrentPossition] = useState(1)
  const onPositionChange = (e) => {
    if (e === 'some value') {
      window.location.href = '#1' // change there
    }
  }
  const woundDetailsRef = useRef()
  const dateFormat = "YYYY-MM-DD"
  const { RangePicker } = DatePicker;
  const treatmentRef = useRef()
  const biologicsRef = useRef()
  const summaryRef = useRef()
  const dummyRef = useRef()
  const [activeTabDiv, setactiveTabDiv] = useState(1)
  const [woundDummy, setwoundDummy] = useState(false)
  const [dummyImage, setDummyImage] = useState(null)
  const [dummyFile, setDummyFile] = useState(null)
  const [consultant, setConsultant] = useState(null)
  const [consultantOptions, setconsultantOptions] = useState([])
  const [formvalues, setformvalues] = useState(null)
  const handleConsultantssearch = async (value) => {
    const { data } = await apiRequest.get(`/consultants?search=${value}`)
    // setOptions(data);
    if (data) {
      console.log(data.data)
      setconsultantOptions(data.data);
    }
  };
  const onconsultantSelect = (value) => {
    console.log('onSelect', value);
    let wcc = consultantOptions.find(item => item.name === value)
    setConsultant(wcc)
  };

  const onPreview = async file => {
    console.log(file)
    if (!file.url && !file.preview) {
      file.preview = await toBase64(file.originFileObj);
    }
    let src = file.url;
    setPreviewImage(src);    //The path of this picture depends on your own situation
    setPreviewVisible(true)
  }
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const [dateValues, setDateValues] = useState(null);

  const disabledFutureDates = (current) => {

    if (!dateValues) {
      return false;
    }

    let filterByValue = 90;
    const tooLate =
      dateValues[0] && current.diff(dateValues[0], 'days') > filterByValue;
    const tooEarly =
      dateValues[1] && dateValues[1].diff(current, 'days') > filterByValue;
    return !!tooEarly || !!tooLate;
  };

  const onDatesChange = async (value) => {
    console.log(dayjs('2023-01-10').isBetween('2023-01-01', '2023-01-30'));
    setDateValues(value);
    let progress_length = value?.length ? progress.map(item => {
      if (dayjs(dayjs(item.name, "YYYY-MM-DD").format("YYYY-MM-DD")).isBetween(dayjs(value[0]).format("YYYY-MM-DD"), dayjs(value[1]).format("YYYY-MM-DD"), undefined, [])) {
        console.log(item)
        return item
      } else {
        console.log(dayjs(item.name, "YYYY-MM-DD").format("YYYY-MM-DD"), dayjs(dayjs(item.name).format("YYYY-MM-DD")).isBetween(dayjs('2023-01-01'), dayjs('2023-01-31'), undefined, []))
        return null;
      }
    }) : []

    if (_.compact(progress_length).length > 1) {
      sethasProgress(true)
      sethasProgressError(false)
    } else {
      sethasProgress(false)
      sethasProgressError(true)
    }
  };
  const [hasProgress, sethasProgress] = useState(false)
  const [hasProgressError, sethasProgressError] = useState(false)
  return (
    (<Row gutter={16}>
      <Modal
        title="Wound Location"
        open={woundDummy}
        destroyOnClose={true}
        onOk={() => setwoundDummy(false)}
        onCancel={() => setwoundDummy(false)}
        closeIcon={modalClose(() => setwoundDummy(false))}
        maskClosable={false}
        width={700}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {outlinedButton(() => setwoundDummy(false), 'Cancel')}
        </div>]}>
        <WoundAssessmentmodule setDummyFile={setDummyFile} setDummyImage={setDummyImage} setwoundDummy={setwoundDummy} />
      </Modal>
      <Modal open={previewVisible} title="Preview Image" footer={null} onCancel={() => setPreviewVisible(false)}> <img alt="example" style={{ width: '100%' }} src={previewImage} /> </Modal>
      <Col span={4}>
        {procedure !== "Wound Evaluation" && <div className={activeTabDiv === 2 ? "active-tab-div tab-div" : "tab-div"}>
          <Subtitle1 style={{ textAlign: 'left' }} onClick={() => {
            setactiveTabDiv(2)
            if (treatmentRef.current) {
              treatmentRef.current.scrollIntoView({ behavior: 'smooth' })
            }
          }}>Treatment</Subtitle1>
        </div>}


      </Col>
      <Col span={16} id="assessmentForm">
        {assessmentForm ? <Form
          name="planOfCareForm"
          id="planOfCareForm"
          labelCol={{ span: 16 }}
          form={form}
          onValuesChange={(changedValues, allValues) => {
            // console.log('changedValues:', changedValues)
            setformvalues(allValues)
            // setShowBiologic(allValues.treatment_number && !allValues.treatment_number.includes("Pre-treatment Assessment"))
          }}
          layout="vertical"
          initialValues={{ procedure: selectedProcedure, wounds: [''], location: wound.location, upload_type: 'generate' }}
          scrollToFirstError={true}
          onFinish={onFinish}>
          <div className="card-box" style={{ height: "fit-content" }} ref={summaryRef}>

            <Row gutter={16} style={{ width: '100%' }}>
              <Col
                span={24}>
                <Form.Item
                  label="Assessment Dates"
                  name="assessment_dates"
                  // style={{ width: 160 }}
                  rules={[{ required: true, message: 'Assessment Dates' }]}
                >
                  <RangePicker
                    format={dateFormat}
                    onChange={(dateStrings) => onDatesChange(dateStrings)}
                    disabledDate={disabledFutureDates}
                    onCalendarChange={(dateStrings) => setDateValues(dateStrings)}
                    allowClear={true}
                    style={{
                      borderRadius: '7px',
                      cursor: 'pointer',
                      marginTop: '-25px',
                      width: '100%'
                    }}
                  />
                </Form.Item>
                {hasProgressError && <Body2 color="crimson">Selected progress dates don't have enough visits. Please select a different date range.</Body2>}
              </Col>
            </Row>


            {hasProgress && assessmentForm && <>

              {assessmentForm.find(item => item.category === "Treatment Goals") ? <div><Subtitle1>Treatment Goals</Subtitle1> <Divider /></div> : null}
              <Row gutter={16}>
                {assessmentForm.filter(item => item.category === "Treatment Goals").map((item) => {
                  return (<Col span={item.classes?.includes('span-full') ? 24 : item.classes?.includes('span-1/2') ? 12 : item.classes?.includes('span-1/3') ? 8 : 12}>
                    <Form.Item
                      label={`${item.label.replace('New', '').replace("Other interventions", "Orders/Recommendations")}${item.label_unit ? item.label_unit : ''}`}



                      name={item.field_name} rules={[{ required: true, message: 'this field is required!' }]} wrapperCol={{ span: 24 }}>
                      {item.type === 'dropdown' ?
                        <Select popupMatchSelectWidth={false} style={{
                          width: "100%", borderRadius: "4px"
                        }}>                       {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                          <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                        </Select> : item.type === "textfield" ? <Input placeholder={item.label} /> : item.type === 'select' ?
                          <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                            {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                              <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}
                          </Select> : item.type === "textfield" ?
                            <Input placeholder={item.label} /> : item.type === 'select' ?
                              <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                                {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                  <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                              </Select> : item.type === "textArea" ? <TextArea rows={4} /> : null}
                    </Form.Item>
                  </Col>)
                })}
              </Row>


              {assessmentForm.find(item => item.category === "Vascular Status") ? <div><Subtitle1>Vascular Status</Subtitle1> <Divider /></div> : null}
              <Row gutter={16}>

                {assessmentForm.filter(item => item.category === "Vascular Status").filter(item => item.type !== "date").map((item) => {
                  return (<Col
                    span={item.classes?.includes('span-full') ? 24 : item.classes?.includes('span-1/2') ? 12 : item.classes?.includes('span-1/3') ? 8 : 12}>
                    {item.field_name === "arterial_perfusion" ?
                      <Form.Item
                        onChange={(e) => console.log(e)}
                        label={`${item.label.replace('New', '').replace("Other interventions", "Order/Recommendations")}${item.label_unit ? item.label_unit : ''}`}
                        name={item.field_name} rules={[{ required: true, message: 'this field is required!' }]} wrapperCol={{ span: 24 }}>
                        {item.type === 'dropdown' ?
                          <Select popupMatchSelectWidth={false} style={{
                            width: "100%", borderRadius: "4px"
                          }}>                       {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                            <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                          </Select> : item.type === "textfield" ? <Input autoComplete={'off'} placeholder={item.label} /> : item.type === 'select' ?
                            <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>  {item.options.map((option) => <Option key={option.value}
                              value={option.value}>{option.value}</Option>)}
                            </Select> : item.type === "textfield" ? <Input type="number" autoComplete={'off'} placeholder={item.label} /> : item.label_unit ?
                              <InputNumber autoComplete={'off'} onChange={() => calculateVolume()} type="number" precision={2} placeholder={item.label} style={{
                                width: "100%", borderRadius: "4px"
                              }} /> : item.type === 'select' ?
                                <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                                  {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                    <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}
                                </Select> : item.type === "textArea" ? <TextArea rows={4} /> : null}

                      </Form.Item>
                      :

                      item.field_name !== "vascular_testing" && item.field_name !== "arterial_perfusion" && (form.getFieldValue("arterial_perfusion") !== "non-contributory") ?
                        <Form.Item
                          onChange={(e) => console.log(e)}
                          label={`${item.label.replace('New', '').replace("Other interventions", "Orders/Recommendations")}${item.label_unit ? item.label_unit : ''}`}



                          name={item.field_name} rules={[{ required: true, message: 'this field is required!' }]} wrapperCol={{ span: 24 }}>
                          {item.type === 'dropdown' ?
                            <Select popupMatchSelectWidth={false} style={{
                              width: "100%", borderRadius: "4px"
                            }}>                       {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                              <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                            </Select> : item.type === "textfield" ? <Input autoComplete={'off'} placeholder={item.label} /> : item.type === 'select' ?
                              <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>  {item.options.map((option) => <Option key={option.value}
                                value={option.value}>{option.value}</Option>)}
                              </Select> : item.type === "textfield" ? <Input type="number" autoComplete={'off'} placeholder={item.label} /> : item.label_unit ?
                                <InputNumber autoComplete={'off'} onChange={() => calculateVolume()} type="number" precision={2} placeholder={item.label} style={{
                                  width: "100%", borderRadius: "4px"
                                }} /> : item.type === 'select' ?
                                  <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                                    {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                      <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}
                                  </Select> : item.type === "textArea" ? <TextArea rows={4} /> : null}

                        </Form.Item>
                        :
                        item.field_name === "vascular_testing" && (form.getFieldValue("arterial_perfusion") !== "non-contributory") && (form.getFieldValue("require_vascular_testing") === "yes") ?
                          <Form.Item
                            label={`${item.label.replace('New', '').replace("Other interventions", "Orders/Recommendations")}${item.label_unit ? item.label_unit : ''}`}



                            name={item.field_name} rules={[{ required: true, message: 'this field is required!' }]} wrapperCol={{ span: 24 }}>
                            {item.type === 'dropdown' ?
                              <Select popupMatchSelectWidth={false} style={{
                                width: "100%", borderRadius: "4px"
                              }}>                       {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                              </Select> : item.type === "textfield" ? <Input autoComplete={'off'} placeholder={item.label} /> : item.type === 'select' ?
                                <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>  {item.options.map((option) => <Option key={option.value}
                                  value={option.value}>{option.value}</Option>)}
                                </Select> : item.type === "textfield" ? <Input type="number" autoComplete={'off'} placeholder={item.label} /> : item.label_unit ?
                                  <InputNumber autoComplete={'off'} onChange={() => calculateVolume()} type="number" precision={2} placeholder={item.label} style={{
                                    width: "100%", borderRadius: "4px"
                                  }} /> : item.type === 'select' ?
                                    <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                                      {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                        <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}
                                    </Select> : item.type === "textArea" ? <TextArea rows={4} /> : null}

                          </Form.Item> : null}
                  </Col>)
                })}
              </Row>

              <Divider />
              {assessmentForm.find(item => item.category === "Nutritional Status") ?
                <div><Subtitle1>Nutritional Status</Subtitle1> <Divider /></div> : null}
              <Row gutter={16} id="#1">
                {assessmentForm.filter(item => item.category === "Nutritional Status" || item.category === "Nutrition Status").map((item) => {
                  return (<Col
                    span={item.classes?.includes('span-full') ? 24 : item.classes?.includes('span-1/2') ? 12 : item.classes?.includes('span-1/3') ? 8 : 12}>
                    <Form.Item
                      label={`${item.label.replace('New', '').replace("Other interventions", "Orders/Recommendations")}${item.label_unit ? item.label_unit : ''}`}



                      name={item.field_name}
                      rules={[{
                        required: item.field_name === 'other_interventions' || item.field_name === "other_related_factors" || item.field_name === "referral_recommendations" ? false : true,
                        message: 'this field is required!'
                      }]}
                      wrapperCol={24}>
                      {item.type === 'dropdown' ? <Select popupMatchSelectWidth={false} style={{ width: "100%", borderRadius: "4px" }}>
                        {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                          <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                      </Select> : item.type === "textfield" && !item.label_unit ?
                        <Input autoComplete={'off'} placeholder={item.label} /> : item.label_unit ? <InputNumber autoComplete={'off'} type="number" placeholder={item.label} style={{
                          width: "100%", borderRadius: "4px"
                        }} /> : item.type === 'select' ?
                          <Select mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                            {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                              <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                          </Select> : item.type === "textArea" ? <TextArea rows={4} /> : null}
                    </Form.Item>
                  </Col>)
                })}
              </Row>

              {assessmentForm.find(item => item.category === "Vascular Measurements") ?
                <div><Subtitle1>Vascular Measurements</Subtitle1> <Divider /></div> : null}
              <Row gutter={16}>
                {assessmentForm.filter(item => item.category === "Vascular Measurements").map((item) => {
                  return (<Col
                    span={item.classes?.includes('span-full') ? 24 : item.classes?.includes('span-1/2') ? 12 : item.classes?.includes('span-1/3') ? 8 : 12}>
                    <Form.Item
                      label={`${item.label.replace('New', '').replace("Other interventions", "Orders/Recommendations")}${item.label_unit ? item.label_unit : ''}`}



                      name={item.field_name}
                      rules={[{ required: true, message: 'this field is required!' }]}
                      wrapperCol={{ span: 24 }}>
                      {item.type === 'dropdown' ?
                        <Select popupMatchSelectWidth={false} style={{
                          width: "100%", borderRadius: "4px"
                        }}>                       {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                          <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                        </Select> : item.type === "textfield" ? <Input
                          placeholder={item.label} /> : item.type === 'select' ? <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                            {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                              <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                          </Select> : item.type === "textfield" ? <Input placeholder={item.label} /> : item.type === 'select' ?
                            <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                              {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                                <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}
                            </Select> : item.type === "textArea" ? <TextArea rows={4} /> : null}
                    </Form.Item>
                  </Col>)
                })}
              </Row>

              {assessmentForm.find(item => item.category === "Ongoing Treatment Plan") ? <div><Subtitle1>Ongoing Treatment Plan</Subtitle1> <Divider /></div> : null}
              <Row gutter={16}>
                {assessmentForm.filter(item => item.category === "Ongoing Treatment Plan").map((item) => {
                  return (<Col
                    span={item.classes?.includes('span-full') ? 24 : item.classes?.includes('span-1/2') ? 12 : item.classes?.includes('span-1/3') ? 8 : 12}>
                    <Form.Item
                      label={`${item.label.replace('New', '').replace("Other interventions", "Orders/Recommendations")}${item.label_unit ? item.label_unit : ''}`}



                      name={item.field_name}
                      rules={[{ required: true, message: 'this field is required!' }]}
                      wrapperCol={{ span: 24 }}>
                      {item.type === 'dropdown' ? <Select style={{ width: "100%", borderRadius: "4px" }}>
                        {/*                       {masterList.find(option => option.system_name === ( item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option => <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}
   */}
                        {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                          <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                      </Select> : item.type === "textfield" ? <Input placeholder={item.label} /> : item.type === 'select' ?
                        <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>

                          {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                            <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                        </Select> : item.type === "textfield" ? <Input placeholder={item.label} /> : item.type === 'select' ?
                          <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                            {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                              <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                          </Select> : item.type === "textArea" ? <TextArea rows={4} /> : null}
                    </Form.Item>
                  </Col>)
                })}
              </Row>


              {/* {assessmentForm.find(item => item.category === "Orders") ? <div><Subtitle1>Orders</Subtitle1> <Divider /></div> : null} */}
              <Row gutter={16}>
                {assessmentForm.filter(item => item.category === "Orders").map((item) => {
                  return (<Col
                    span={24}>
                    <Form.Item
                      label={`${item.label.replace('Recommended Interventions', 'Recommended Interventions/Orders')}${item.label_unit ? item.label_unit : ''}`}
                      name={item.field_name}
                      rules={[{ required: true, message: 'this field is required!' }]}
                      wrapperCol={{ span: 24 }}>
                      {item.type === 'dropdown' ? <Select style={{ width: "100%", borderRadius: "4px" }}>
                        {/*                       {masterList.find(option => option.system_name === ( item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option => <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}
   */}
                        {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                          <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                      </Select> : item.type === "textfield" ? <Input placeholder={item.label} /> : item.type === 'select' ?
                        <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>

                          {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                            <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                        </Select> : item.type === "textfield" ? <Input placeholder={item.label} /> : item.type === 'select' ?
                          <Select popupMatchSelectWidth={false} mode="multiple" style={{ width: "100%", borderRadius: "4px" }}>
                            {masterList.find(option => option.system_name === (item.field_name === "infection" ? 'infections' : item.field_name === 'location' ? 'wound_location' : item.field_name === 'treatment_number' ? 'biologics_treatment_number' : item.field_name === "size" ? 'wound_size' : item.field_name === "stage" ? 'wound_stage' : item.field_name === "status" ? "wound_status" : item.field_name === "exudate_amount" ? "exudate" : item.field_name))?.options?.map(option =>
                              <Option value={option}>{option}</Option>) || item.options.map((option) => <Option key={option.value} value={option.value}>{option.value}</Option>)}

                          </Select> : item.type === "textArea" ? <TextArea rows={4} /> : null}
                    </Form.Item>
                  </Col>)
                })}
              </Row>
            </>}
          </div>


        </Form> : <div className="card-box flex-boy-row-space" style={{ alignItems: "center", justifyContent: "center" }}>
          <Spin tip="Loading form..."></Spin>
        </div>}
      </Col>
      <Col span={4}>

      </Col>
    </Row>)
  );
}
const mapStateToProps = (state) => ({
  masterList: state.masters.masterListRaw,
  procedures: state.appointments.procedures,
  woundLocations: state.appointments.woundLocations
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PlanOfCareAssessment)
