import React, {useEffect, useRef, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import Menu from './components/menu';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {Button, Modal} from 'antd';
import Patients from './content/Patients';
import NewPatientPage from './content/Patients/NewPatientPage';
import Appointments from './content/Appointments';
import AppointmentHome from './content/Appointments/components/AppointmentHome';
import ConsultantPage from './content/Consultants/NewConssultantCard';
import WoundPage from './content/Patients/components/PatientWounds/WoundPage';
import Facilities from './content/Facilities';
import FacilityPage from './content/Facilities/FacilityPage';
import FacilityWoundPage from './content/Facilities/components/Wound/Index';
import Orders from './content/Orders';
import Profile from './content/Profile/ProviderPage';
import Master from './content/Master';
import Order from './content/Orders/components/OrderDetails';
import {fetchMasterListFields, fetchNotifications, fetchRcopiaNotifications, fetchReports, logOut, setChannelName} from './app/Reducers';
import {useIdleTimer} from 'react-idle-timer';
import TrackerMap from './components/map/Components/TrackerMap/TrackerMap';

import IncommingCall from './components/IncommingCall';
import FacilityDashboard from './content/Facilities/Dashboard';
import {Help} from './content/Help/Index';
import ConsultantModule from "./content/Consultants/ConsultantModule";
import GetStreamChat from "./content/GetStreamChat"
import Stats from './content/Stats';
import {StreamChat} from 'stream-chat';

import Chat from './content/Chat';
import {toast} from 'react-toastify';
import {useClient} from './content/Chat/Components/Video/Settings';
import {appLog, emptyFun, IsItemEnabled} from './common/helpers';

import {getNotificationToken, requestPermission} from "./Messaging";
import {messaging, onMessageListener} from "./firebase";

import {onMessage} from "firebase/messaging";
import Tasks from './content/Tasks';
import OrganisationDetailsPage from './content/Facilities/Dashboard/OrganisationDetailsPage';
import DepartmentPage from './content/Consultants/Departments/DepartmentPage';
import Departments from './content/Consultants/Departments.js';
import TaskPage from './content/Tasks/Components/Taskforms/TaskPage';
import Billing from './content/Billing';
import EncounterPage from './content/Billing/components/EncounterPage';
import {apiRequest} from './app/Apis';
import NewMap from './content/NewMap'
import PrivateRoute from './components/PrivateRoute';
import {Auth} from './content/NewAuth';
import SsoRedirect from './content/NewAuth/Sso';
import RolesModule from './content/Roles/RolesModule';
import NewEncounterPage from './content/Billing/components/NewEncounterPage';


const urlParams = new URLSearchParams(window.location.search);

const apiKey = "rz98uvtvv27t"


// const userToken = urlParams.get('user_token') || process.env.REACT_APP_USER_TOKEN;
const targetOrigin = urlParams.get('target_origin') || process.env.REACT_APP_TARGET_ORIGIN;


const Main = ({ activeUser, settings }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [incommingCall, setIncommingCall] = useState(false)
  const [videoIsOn, setVideoIsOn] = useState(false)
  const [incoming_message] = useState(new Audio('sounds/joined.wav'));
  const [callLink, setCallLink] = useState(null)
  const [initiator, setInitiator] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [chatClient, setChatClient] = useState(null);
  const [channelName, setchannelName] = useState("messaging:!members-6Zv1KrDsBOlSYJSvAXZnNNDki7J1dcxdZr37O7VPv5o Main.js:234")
  const [logoutTimer, setlogoutTimer] = useState(null);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0
  });
  
  
  // function connectionListener(response) {
  //   if (response.status === "CONNECTED") {
  //     console.log("Connection Established");
  //     dispatch(setConnectionState(true))
  //     dispatch(getChats())
  //   } else if (response.status === "DISCONNECTED") {
  //     console.log("Disconnected");
  //     initializeFly()
  //   }
  //   // Remaining Cases goes here
  // }
  
  // const incomingCallListener = (res) => {
  //   console.log("incoming call", res)
  //  dispatch(setIncomingCall(res))
  // };
  
  // const callStatusListener = (res) => { console.log("listenr",res)};
  // const userTrackListener = (res) => { console.log("listenr",res)};
  // const muteStatusListener = (res) => { console.log("listenr",res)};
  // const missedCallListener = (res) => { console.log("listenr",res)};
  // const callSwitchListener = (res) => {console.log("listenr",res) };
  // const inviteUsersListener = (res) => {console.log("listenr",res) };
  // const mediaErrorListener = (res) => {console.log("listenr",res) };
  // const callSpeakingListener = (res) => { console.log("listenr",res)};
  // const callUsersUpdateListener = (res) => {console.log("listenr",res) };
  // const callUserJoinedListener = (res) => { console.log("listenr",res)};
  // const callUserLeftListener = (res) => { console.log("listenr",res)};
  
  
  // Zendesk Settings
  // const settings = {
  //   webWidget: {
  //     launcher: {
  //       label: {
  //         'en-US': 'Chat With Us',
  //       }
  //     },
  //     color: {
  //       theme: '#c0c0c0',
  //     }
  //   }
  // };
  async function fetchData() {
    if (activeUser &&!activeUser.password_was_generated) {
      await dispatch(fetchMasterListFields());
      await dispatch(fetchNotifications());
      await dispatch(fetchReports());
    }
  }
  
  /*useEffect(() => {
    console.log({ 'user in app':activeUser })
    if (!activeUser) {
      navigate("/auth/login")
    }
    return emptyFun
  }, [activeUser])*/
  // handle Zendesk chat
  useEffect(() => {
    //console.log("Active User", activeUser)
    let styleData = document.getElementsByClassName('kore-chat-window');
    fetchData();
    // if (IsItemEnabled(settings, 'integrations', 'kore_integration') && !!activeUser?.email) {
    //   (async () => {
    //     if (!!activeUser?.email) {
    //       if (!!activeUser?.facilityProfile) {
    //         //console.log('window.KoreSDK.chatConfig: ', window.KoreSDK.chatConfig);
    //         if (
    //           window?.KoreSDK?.chatConfig?.botOptions &&
    //           window?.KoreSDK?.chatConfig?.botOptions?.botInfo &&
    //           window?.KoreSDK?.chatConfig?.botOptions?.botInfo?.customData
    //         ) {
    //           // let botOptions = window?.KoreSDK?.chatConfig?.botOptions
    //           // window.KoreSDK.chatConfig.botOptions.botInfo = {...botOptions, newData: 'Philip'}
    //           var event = {
    //             event: 'updateCustomData',
    //             data: {
    //               facility_id: activeUser.facilityProfile.id,
    //               facility_name: activeUser.facilityProfile.name,
    //               userIdentity: activeUser.email
    //             }
    //           };
    //           window.KoreSDK.chatConfig.botOptions.userIdentity =
    //             activeUser.email;
    //           window.postMessage(event, '*');
    //           // window.KoreSDK.chatConfig.botOptions.botInfo.customData.facility_id = activeUser.facilityProfile.id
    //         }
    //         // console.log('window.KoreSDK.chatConfig1: ', window.KoreSDK.chatConfig.botOptions.botInfo);
    //         if (!!styleData && styleData[0]) {
    //           document.getElementsByClassName(
    //             'kore-chat-window'
    //           )[0].style.display = 'block';
    //         }
    //       } else {
    //         if (!!styleData && styleData[0]) {
    //           document.getElementsByClassName(
    //             'kore-chat-window'
    //           )[0].style.display = 'none';
    //           document.getElementsByClassName('chat-container')[0].innerHTML =
    //             '';
    //         }
    //       }
    //     } else {
    //       if (!!styleData && styleData[0]) {
    //         document.getElementsByClassName(
    //           'kore-chat-window'
    //         )[0].style.display = 'none';
    //         document.getElementsByClassName('chat-container')[0].innerHTML = '';
    //       }
    //     }
    //     fetchData();
    //     activeUser.rcopia_username &&
    //     (await dispatch(fetchRcopiaNotifications({ is_interval: true })));
    
    //     await dispatch(fetchSettings())
    //   })().catch(appLog);
    // } else {
    //   if (!!styleData && styleData[0]) {
    //     document.getElementsByClassName('kore-chat-window')[0].style.display =
    //       'none';
    //     document.getElementsByClassName('chat-container')[0].innerHTML = '';
    //   }
    // }
    if (!activeUser){
      handleCancel()
    }
    return emptyFun
  }, [activeUser]);
  
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (activeUser && !activeUser.password_was_generated) {
        
        get_notifications();
        
      }
    }, 1500000);
    
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [activeUser]);
  
  
  //  use messaging
  
  useEffect(() => {
    if (IsItemEnabled(settings, 'integrations', 'agora_integration') && activeUser) {
      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        // ...
      });
      requestPermission();
      getNotificationToken();
    }
    
    return () => {
      // Clean up any subscriptions or other resources here, if necessary.
    };
  }, [activeUser]);
  
  
  
  onMessageListener().then(async (payload) => {
    
    console.log(payload);
    const regex = /You have been invited to a call/
    
    if (payload.notification.title === "Chat Notification") {
      console.log("payload", payload.data)
      if (payload.data.status === "call_started") {
        const channel_name = payload.data.channel_name
        const call_link = payload.data.call_link
        setCallLink(call_link)
        setInitiator(payload.data.initiator)
        await setchannelName(channel_name)
        await
          console.log("channel_name", channelName, channel_name)
        setIncommingCall(true)
      } else if (payload.data.status === "call_ended") {
        Leave()
        setVideoIsOn(false)
        toast(`🚫 call ended`, {
          
          draggable: false,
          
          position: 'top-right',
          
          toastId: 'incoming-message'
        });
      }// call record
      else if (payload.data.status === "call_recording_started") {
        toast(`📹 call recording started`, {
          
          draggable: false,
          
          position: 'top-right',
          
          toastId: 'incoming-message'
        });
        
      } else if (payload.data.status === "call_recording_ended") {
        toast(`📹 call recording ended`, {
          
          draggable: false,
          
          position: 'top-right',
          
          toastId: 'incoming-message'
        });
      }
      
    }
    
  }).catch(err => console.log('failed: ', err));
  
  
  const get_notifications = () => {
    dispatch(fetchNotifications());
    
    activeUser.rcopia_username &&
    dispatch(fetchRcopiaNotifications({ is_interval: true }));
  };
  
  const handleOk = () => {
    setIsModalVisible(false);
    dispatch(logOut(navigate));
    // document.getElementsByClassName('minimized')[0].style.visibility = 'hidden';
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
    if (logoutTimer) {
      clearTimeout(logoutTimer);
      setlogoutTimer(null);
    }
  };
  
  const ontimeOut = () => {
    setIsModalVisible(false);
    dispatch(logOut(navigate));
    // document.getElementsByClassName('minimized')[0].style.visibility = 'hidden';
  };
  
  const handleOnIdle = (event) => {
    if (!activeUser){
      handleCancel()
      return
    }
    setIsModalVisible(true);
    // console.log('user is idle', event)
    // console.log('last active', getLastActiveTime())
    setlogoutTimer(
      setTimeout(() => {
        ontimeOut();
      }, 1000 * 10 * 1)
    );
  };
  
  const handleOnActive = (event) => {
    // console.log('user is active', event)
    // console.log('time remaining', getRemainingTime())
  };
  
  const handleOnAction = (event) => {
    // console.log('user did something', event)
  };
  
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    crossTab: 'true',
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  });
  
  
  useEffect(() => {
    // console.log({ 'user in main': activeUser })
    return emptyFun
  }, [activeUser]);
  useEffect(() => {
    //todo: Looks like expected activeUser attributes for this section are null
    if (activeUser) {
      
      fetchToken()
    } else {
      chatClient?.disconnectUser();
      setChatClient(null); // ADD THIS LINE!
      
    }
    return () => {
      chatClient?.disconnectUser();
      setChatClient(null); // ADD THIS LINE!
      
    }
  }, [activeUser]);
  
  // fetch token
  const fetchToken = async () => {
    const StreamToken = await apiRequest.post('/getstream/token').catch(appLog)
    const streamToken = StreamToken?.data?.data
    if (streamToken) {
      const initChat = async () => {
        //console.log({ activeUser })
        
        
        const client = StreamChat.getInstance(apiKey, { enableInsights: false, enableWSFallback: false })
        
        await client.connectUser({ id: `${activeUser?.id}`, name: `${activeUser?.name}`, image: activeUser?.profile_image_url, role: 'admin' }, streamToken);
        //console.log(client)
        setChatClient(client);
      };
      
      initChat();
    }
    
  }
  const handleIncomingCall = async (channelName, callerName, token, uid) => {
    try {
      console.log(channelName)
      
      setChannelName(channelName)
      setIncommingCall(true)
      
    } catch (error) {
      console.error(error);
      toast.error('Failed to receive call. Please try again later.');
    }
  };
  
  // new message notification
  const notify = (user) => {
    incoming_message.play()
    toast(`📩 New message from ${user}.`, {
      
      draggable: false,
      
      position: 'top-right',
      
      toastId: 'incoming-message'
    });
  }
  
  // Listen for incoming calls
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (chatClient) {
      
      chatClient.on('message.new', async (event) => {
        //console.log(event)
        
        const { type, user, call_type, call } = event.message;
        if (user.id !== chatClient.user.id) {
          notify(user.name)
        }
        if (type === 'regular' && call_type === 'video' && user.id !== chatClient.user.id) {
          
          // Extract the caller's name from the message
          const match = event.message.text.match(/^Incoming call from (.+)\.$/);
          const callerName = match ? match[1] : 'unknown user';
          let response = await chatClient.getCallToken(call.id);
          //console.log(response)
          handleIncomingCall(call.agora.channel, callerName, response.token, response.agora_uid);
        }
      });
    }
  }, [chatClient]);
  
  
  
  
  const Leave = async () => {
    console.log(client)
    client.unpublish()
    await client.leave();
  }
  
  const client = useClient();
  
  const draggleRef = useRef(null);
  
  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y)
    });
  };
  return (<div className="main">
    {/* {activeUser?.mirrorfly_username && <Chat />} */}
    {(activeUser && !activeUser?.password_was_generated)&&<Menu chatClient={chatClient}/>}
    {/* <Login/> */}
    <div id="main-body">
      {/*{!!activeUser && <KoreHtml/>}*/}
      <Modal
        title="Session time out"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={handleOnActive}
        footer={[
          <Button type="primary" onClick={handleCancel}>
            Keep me Logged in
          </Button>
        ]}
      >
        <p>
          Your session is about to expire in 10 seconds due to inactivity. You
          will be redirected to the login page.{' '}
        </p>
      </Modal>
      
      <Modal
        title={null}
        width={'300px'}
        style={{ bottom: 20, color: 'white' }}
        closable={false}
        bodyStyle={{ backgroundColor: '#1C1C24', color: 'white' }}
        open={incommingCall}
        onOk={() => setIncommingCall(false)}
        onCancel={handleCancel}
        afterClose={handleOnActive}
        footer={null}>
        <IncommingCall initiator={initiator} acceptCall={() => window.open(callLink, '_blank')} onCancel={() => setIncommingCall(false)}/>
      </Modal>
      
      <Routes>
        {/*<Route element={<PrivateRoutes/>} >*/}
        <Route path="auth/*" element={<Auth/>}/>
          <Route path="sso" element={<SsoRedirect/>}/>
          <Route path="map" element={<PrivateRoute component={NewMap}/>}/>
          <Route path="routemap" element={<PrivateRoute component={TrackerMap}/>}/>
          <Route path="wounds/:id" element={<PrivateRoute component={WoundPage}/>}/>
          <Route path="patients" element={<PrivateRoute component={Patients}/>}/>
          <Route path="patients/:id" element={<PrivateRoute component={NewPatientPage}/>}/>
          <Route path="appointments" element={<PrivateRoute component={Appointments}/>}/>
          <Route path="appointments/:id" element={<PrivateRoute component={AppointmentHome}/>}/>
          <Route path="consultants" element={<PrivateRoute component={ConsultantModule}/>}/>
          <Route path="roles" element={<PrivateRoute component={RolesModule}/>}/>
          <Route path="consultants/:id" element={<PrivateRoute component={ConsultantPage}/>}/>
          <Route path="facilities" element={<PrivateRoute component={Facilities}/>}/>
          <Route path="facilities/:id" element={<PrivateRoute component={FacilityPage}/>}/>
          <Route path="facilities/organisation/:id" element={<PrivateRoute component={OrganisationDetailsPage}/>}/>
          <Route path="orders" element={<PrivateRoute component={Orders}/>}/>
          <Route path="tasks" element={<PrivateRoute component={Tasks}/>}/>
          <Route path="tasks/:id" element={<PrivateRoute component={TaskPage}/>}/>
          
          <Route path="departments" element={<PrivateRoute component={Departments}/>}/>
          <Route path="departments/:id" element={<PrivateRoute component={DepartmentPage}/>}/>
          <Route path="user-profile/:id" element={<PrivateRoute component={Profile}/>}/>
          <Route path="settings" element={<PrivateRoute component={Master}/>}/>
          <Route path="chat" element={<PrivateRoute component={() => <GetStreamChat client={chatClient}/>}/>}/>
          
          <Route path="chat-video" element={<PrivateRoute component={() => Chat}/>}/>
          <Route path="orders/:id" element={<PrivateRoute component={Order}/>}/>
          <Route path="help" element={<PrivateRoute component={Help}/>}/>
          <Route path="facility/:id/stats" element={<PrivateRoute component={FacilityDashboard}/>}/>
          <Route path="facility/wounds/:id" element={<PrivateRoute component={FacilityWoundPage}/>}/>
          <Route path="stats" element={<PrivateRoute component={Stats}/>}/>
          <Route path="billing" element={<PrivateRoute component={Billing}/>}/>
          <Route path="billing/:id" element={<PrivateRoute component={NewEncounterPage}/>}/>
        {/*</Route>*/}
      </Routes>
    </div>
  </div>);
};
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser,
  videoIsOn: state.chat.videoIsOn,
  // incomingCall: state.chat.incomingCall,
  conversation: state.chat.selectedChat,
  settings: state.settings?.settings
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
