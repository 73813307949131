/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Subtitle1 } from '../../components'
import {Grid, Table} from 'antd';
import { archivePatient, fetchArchivedPatients, resetArchivedPatientPagination, resetPatientModuleState, restorePatient, selectPatient } from '../../app/Reducers';
import dayjs from 'dayjs';
import { updateArchivedPatientFilters } from "../../app/Reducers";
import { resetPatientNavs, setArchivedPatientsLoading } from "../../app/Reducers";
import {
  appConfirm,
  appLog,
  emptyFun,
  emptyTable,
  getPatientStatus,
  GetRawListOptions,
  getSkels,
  makeAutoCompleteOptions,
  onTableChange,
  regionFilter,
  responsiveTbl,
  stateFilter
} from "../../common/helpers";

import TableFilters from "../../components/InputComponents/TableFilters";
import _ from "lodash"
import { useSubscription } from "../../hooks/MqttHooks/useSubscription";
import {usePermissionCheck} from '../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
import {UndoOutlined} from '@ant-design/icons';
import {useFacilitySearch} from '../../hooks/useFacilitySearch';
const { useBreakpoint } = Grid;
const ArchivedPatients = ({ pagination, appConf,  activeUser, navs, archivedPatientPagination, archivedPatients }) => {
  const dispatch = useDispatch()
    const location = useLocation()
    const windowState = useBreakpoint()
      const navigate = useNavigate();
  const can_view_patient = usePermissionCheck('view_patient', false, [])
  const can_restore_deleted_patient = usePermissionCheck('restore_deleted_patient', false, [])
  let globalFilters = !activeUser.facilityProfile ? {} : { filterData: { facility_id: activeUser.facilityProfile.id } }
  const [selected, setSelected] = React.useState(null);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [facilityOptions, debouncedFacilitiesSearch,facilitySearchLoading] = useFacilitySearch([], 'id')
  const showPopconfirm = (selected) => setSelected(selected)
  const handleCancel = () => setSelected(null)
  const handleOk = async (record) => {
    await setConfirmLoading(true);
    if (!record.deleted_at) {
      await dispatch(archivePatient(record.id))
    } else {
      await dispatch(restorePatient(record.id))
    }
    await loadPatients(true)
    await setSelected(null)
    await setConfirmLoading(false);
  }
  const approval_statuses = useSelector(state => state.masters.masterListRaw.find(item => item.system_name === 'approval_status')?.options) || []

  let onCell = (record, rowIndex) => ({
    onClick: event => {
      (async () => {
        if (record.id !== navs.patientDetailsOwner) {
          await dispatch(resetPatientModuleState())
        }
        await dispatch(resetPatientNavs())
        await dispatch(selectPatient({ record: record }))
        if (can_view_patient) {
      //    navigate(`/patients/${record.id}`)
        }
      })().catch(appLog)
    }
  })

  const patientColumns = [{
    title: 'First & Last Name', dataIndex: 'name', key: 'name',
    fltr: { filterable: true, type: 'text' }, visible: true, render: (name) => (<>
      <div className="flex-boy-row-space" style={{ justifyContent: 'normal' }}>
        <Subtitle1 style={{ textAlign: "left", marginLeft: '10px' }}>{name}</Subtitle1>
      </div>
    </>), onCell,
  }, {
    title: 'Region', dataIndex: 'region', key: 'regionName',
    fltr: { ...regionFilter, filterable: !activeUser?.facilityProfile }, visible: !activeUser?.facilityProfile, onCell,
  }, {
    title: 'Office', dataIndex: 'medical_facility', key: 'facility_id',
    fltr: { filterable: !activeUser?.facilityProfile, type: 'select', options: facilityOptions,
    props: {
      onSearch: (value) => debouncedFacilitiesSearch(value),loading:facilitySearchLoading, showSearch: true, filterOption: false
    } }, visible: !activeUser?.facilityProfile, onCell,
  }, {
    title: 'Contact Phone', dataIndex: 'phone_no', key: 'phone_no', fltr: { filterable: true, type: 'phone' },
    render: (phone_no) => <p className={'text-sm text-[#038A96]'}>{phone_no}</p>,
    visible: true, onCell,
  }, {
    title: 'DOB', dataIndex: 'birth_date', key: 'birth_date', fltr: { filterable: false, type: 'date' },
    render: (dob) => (<>
      {dayjs(dob).format('MM/DD/YYYY')}
    </>), visible: true, onCell,
  }, {
    title: 'Approval status', dataIndex: 'approval_status', key: 'approval_status', fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions([...approval_statuses, 'Unassigned']) },
    render: (approval_status) => approval_status || 'Unassigned', visible: true, onCell,
  }, {
    title: 'Status', dataIndex: 'status', key: 'status',
    fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(GetRawListOptions('patient_status')) },
    visible: true, render: (status) => getPatientStatus(status), onCell,
  },
  {
    visible: true,
    fltr: { filterable: false }, render: (record) =>
      can_restore_deleted_patient ? appConfirm(<UndoOutlined />, selected === record.id, () => showPopconfirm(record.id),
        `Press OK to ${!record.deleted_at ? 'archive' : 'restore'} this patient.`, () => handleOk(record), handleCancel, confirmLoading) : null
  }]
  const [isVisible, setIsVisible] = useState(false);
  // const { message } = useSubscription(`patients`, { qos: 2, nl: true });
  /*useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message)
      loadPatients(true).catch(appLog)
    }
    return emptyFun
  }, [message])*/
  useEffect(() => {
    loadPatients(false).catch(appLog)
    return emptyFun
  }, [navs.patientNavs.patientsTab])
  const loadPatients = async (isMQ) => {
    await dispatch(setArchivedPatientsLoading(!isMQ))
    await dispatch(fetchArchivedPatients(appConf.archivedPatientFilters[0] ? appConf.archivedPatientFilters[1] : { ...globalFilters, filterData: { ...globalFilters.filterData, deleted: true } }))
    await dispatch(setArchivedPatientsLoading(false))
  }

  async function handleArchivedPatientFilterAction(action, values) {
    //console.log('handleArchivedPatientFilterAction: ', values, action)
    await dispatch(resetArchivedPatientPagination())
    let common = !activeUser.facilityProfile ? { deleted: true } : { facility_id: activeUser.facilityProfile.id, deleted: true }
    if (action === 'filter') {
      //console.log('FILTERS:  ', JSON.stringify({ ...values, toLoad: 'patients' }));
      let pl = { filterData: { ...values, ...common }, pagination }
      await dispatch(updateArchivedPatientFilters([false, pl, true]))
      await dispatch(fetchArchivedPatients(pl))
      await dispatch(updateArchivedPatientFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updateArchivedPatientFilters([appConf.patientFilters[0], undefined, true]))
      await dispatch(fetchArchivedPatients({ ...globalFilters, filterData: { ...globalFilters.filterData, deleted: true } }))
      await dispatch(updateArchivedPatientFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }

  let creationDateFilter = { key: 'creationDate', title: 'Date of service', fltr: { filterable: false, type: 'date' } }
  let ssnFilter = { key: 'ssn', title: 'SSN', fltr: { filterable: true, type: 'text' } }
  let consultantFilter = { key: 'consultant', title: 'Consultant', fltr: { filterable: true, type: 'text' } }

  useEffect(() => {
    setIsVisible(false)
    return emptyFun
  }, [navs.patientNavs.patientsTab])
  return (
    <div className="main-page">
      <div className="w-full">
        <Table
          title={() => (<TableFilters datasource={[...patientColumns, creationDateFilter, ssnFilter, stateFilter, consultantFilter]}
            setIsVisible={setIsVisible}
            filters={appConf.archivedPatientFilters[1]}
            windowState={windowState}
            isVisible={isVisible}
            showClear={appConf.archivedPatientFilters[0]}
            loading={appConf.archivedPatientFilters[2]}
            handleFilterAction={handleArchivedPatientFilterAction} />)}
          loading={(navs.archivedPatientsLoading || appConf.archivedPatientFilters[2])} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('patients.') }}
          onChange={(pagination, filters,
            sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchArchivedPatients, appConf.archivedPatientFilters, updateArchivedPatientFilters, { deleted: true })
          }
          rowKey={(record, index) => String(index)}
          {...getSkels((navs.archivedPatientsLoading || appConf.archivedPatientFilters[2]), patientColumns.filter(item => item.visible), _.orderBy(archivedPatients, "deleted_at", 'desc'))} pagination={archivedPatientPagination} />
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  patients: state.patients.patients,
  archivedPatients: state.patients.archivedPatients,
  activeUser: state.auth?.activeUser,
  pagination: state.patients.pagination,
  archivedPatientPagination: state.patients.archivedPatientPagination,
  appConf: state.appConf, navs: state.navs,
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ArchivedPatients)
