import React, {useCallback, useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Checkbox, Form, Input, Switch} from 'antd'
import {createShippingAddress, updateShippingAddress} from '../../../../app/Reducers';
import MapGL from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import {mapboxToken} from '../../../../configure/constants'
import {executeChunkFn, showCurrentAddress} from "../../../../common/helpers";
import {Subtitle1} from "../../../../components";

const ShippingForm = ({closeModal, patient, setisExecuting, address, editingForm}) => {
  const [form] = Form.useForm()
  let initialValues = editingForm ? address : {default: false};
  const geocoderContainerRef = useRef();
  const mapRef = useRef();
  // location stuff
  const [viewport, setViewport] = useState({latitude: 37.7577, longitude: -122.4376, zoom: 8});
  const [locationData, setLocationData] = useState(null)
  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);
  const onResult = useCallback((result) => {
    // console.log(result)
    let center = result.result.center
    let context = result.result.context
    let state = context.find(item => item.id.includes('region'))
    let city = context.find(item => item.id.includes('place'))
    let zip_code = context.find(item => item.id.includes('postcode'))
    let data = {
      city: city ? city.text : result.result.place_name, state: state ? state.text : null,
      zip_code: zip_code ? zip_code.text : result.result.place_name, co_ordinates: `${center[0]},${center[1]}`,
      street_address: result.result.place_name
    }
    form.setFieldValue('street_address', result.result.place_name)
    setLocationData(data)
  }, [])
  const dispatch = useDispatch()
  const onFinish = async (values) => {
    let payload
    if (editingForm) {
      payload = {data: {...address, ...locationData, ...values, user_id: patient.user_id}, address_id: address.id}
    } else {
      payload = {data: {...locationData,...values, user_id: patient.user_id}, address_id: editingForm ? address.id : null}
    }
    await executeChunkFn(dispatch, editingForm ? updateShippingAddress : createShippingAddress, payload, setisExecuting, closeModal)
  }
  return (<Form
      name="new-patient"
      form={form}
      id="shippingForm"
      preserve={false}
      labelCol={{span: 16}}
      initialValues={initialValues}
      layout="vertical"
      onFinish={onFinish}>
      <Subtitle1 className="Validator" style={{textAlign: 'left', marginBottom: '10px'}}>Address Search</Subtitle1>
      <Form.Item
        style={{width: '100%', ...(initialValues.street_address && {marginBottom: 0})}}
        name="address_1"
        
        rules={[{required: false, message: 'Missing Region'}]}>
        <div
          ref={geocoderContainerRef}
          style={{position: "absolute", top: 0, left: 0, zIndex: 1, width: "100%"}}
        />
        <MapGL
          ref={mapRef}
          {...viewport}
          width="100%"
          height="100%"
          onViewportChange={handleViewportChange}
          mapboxApiAccessToken={mapboxToken}>
          <Geocoder
            mapRef={mapRef}
            containerRef={geocoderContainerRef}
            onViewportChange={handleViewportChange}
            mapboxApiAccessToken={mapboxToken}
            countries="us,ke"
            onResult={onResult}
            marker={false}
            onError={onResult}/>
        </MapGL>
      </Form.Item>
    
    
     <Form.Item
        label="Address"
        name="street_address"
        rules={[{required: true, message: 'this field is required!'}]}
      >
        <Input autoComplete={'off'} placeholder="street address"/>
      </Form.Item>
    
      <Form.Item
        label="Physical Address"
        name="physical_address"
        rules={[{required: true, message: 'this field is required!'}]}
      >
        <Input autoComplete={'off'} placeholder="App, suite, unit, building, floor, etc"/>
    </Form.Item>
   
      <Form.Item
        name="default"
        valuePropName="checked"
        label="">
        <Checkbox defaultChecked={editingForm ? address.default : false}>Set As Default Shipping Address</Checkbox>
      </Form.Item>
    </Form>
  )
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ShippingForm)
