import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Avatar, Button, Divider, Dropdown, Grid, Menu, Modal, Popover, Spin, Table} from "antd"
import {Subtitle1} from '../../../components'
import {
  appLog,
  dialogCloser,
  emptyFun,
  emptyOrders,
  formSubmitButton,
  getOrderStatus,
  getSkels,
  horStack,
  keyValItemOrders,
  makeAutoCompleteOptions,
  modalClose,
  onTableChange,
  outlinedButton,
  responsiveTbl,
  secondaryButton,
  stateFilter
} from "../../../common/helpers";


import {bulkUpdateOrderStatus, fetchPatientOrders, resetOrdersPagination, selectOrder, updatePatientBiologicsOrdersFilters} from '../../../app/Reducers';
import TableFilters from "../../../components/InputComponents/TableFilters";
import dayjs from 'dayjs';


import {CaretDownOutlined, FieldTimeOutlined, LoadingOutlined} from '@ant-design/icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {apiRequest} from '../../../app/Apis';
import {getOrderFilterStatuses} from "../../../app/Apis/commonApi";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";

import _ from "lodash"
import PatientOrderPreview from './PatientOrderPreview';
import ReportForm from './ReportForm';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';

var isoWeek = require('dayjs/plugin/isoWeek')
dayjs.extend(isoWeek)


const groupedItems = (data) => {
  const groupedByCompany = _.groupBy(data, 'biologic_product.company_name');
  
  // Get unique sizes for each company
  const uniqueSizes = _.mapValues(groupedByCompany, (items) => _.uniqBy(items, 'size'));
  
  // Add count of sizes to each object
  const result = _.mapValues(uniqueSizes, (items) => {
    return _.map(items, (item) => {
      return { ...item, count: _.filter(groupedByCompany[item.biologic_product.company_name], { 'size': item.size }).length }
    });
  });
  return result
}
const { useBreakpoint } = Grid;
const PatientBiologics = ({ ordersPagination, appConf, navs, orders, activeUser, patient }) => {
  const [isOpen, setOpen] = useState(false)
  const windowState=useBreakpoint()
  const [previewisOpen, setpreviewOpen] = useState(false)
  const [selectedOrder, setselectedOrder] = useState(null)
  const dispatch = useDispatch()
  const [isVisible, setIsVisible] = useState(false);
  const [isExecuting, setisExecuting] = useState(false)
  const [availableStatuses, setavailableStatuses] = useState([])
  const [orderStatuses, setOrderStatuses] = useState([])
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [order, setOrder] = useState(null);
  const can_export_treatment_order = usePermissionCheck('export_treatment_order', false, [])
  const can_view_dme_orders = usePermissionCheck('view_dme_orders', false, [])
  const can_view_biologic_orders = usePermissionCheck('view_biologic_orders', false, [])
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  let onCell = (record, rowIndex) => ({
    onClick: event => {
      (async () => {
        selectOrdere(record)
      })().catch(appLog)
    }
  })
  const columns = [
    {
      title: 'Provider', dataIndex: ['consultant', 'name'], key: 'consultant', fltr: { filterable: true, type: 'text' },
      sorter: { multiple: 3, compare: (a, b) => a?.consultant?.name < b?.consultant?.name }, onCell
    },
    // {
    //     title: 'Patient', dataIndex: ['patient', 'name'], key: 'patient', fltr: { filterable: true, type: 'text' }, onCell,
    //     sorter: { multiple: 3, compare: (a, b) => a?.patient?.name?.toLowerCase().localeCompare(b?.patient?.name?.toLowerCase()) },
    // },
    {
      title: 'Order date', dataIndex: 'created_at', key: 'createdAt', fltr: { filterable: true, type: 'date' }, onCell,
      render: (created_at, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(created_at).format("MM/DD/YYYY")}</Subtitle1>)
    },
    //baseKey is used as form field name on the Filters form while keys attr is used to name filter API parameters
    {
      title: 'DOS', dataIndex: 'date_of_service', key: 'date_of_service',
      fltr: { filterable: true, type: 'date_range', keys: ['dateOfServiceRange[start_at]', 'dateOfServiceRange[end_at]'], baseKey: 'date_of_service_filter' },
      render: (date_of_service, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(date_of_service).format("MM/DD/YYYY")}</Subtitle1>), onCell
    },
    
    {
      title: 'Week no', dataIndex: 'date_of_service', key: 'weekNumber', fltr: { filterable: true, type: 'number' },
      render: (date_of_service, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(date_of_service).isoWeek()}</Subtitle1>), onCell
    },
    { ...stateFilter, title: 'State', dataIndex: 'state', key: 'state', onCell },
    {
      title: 'Office', dataIndex: ['patient', 'facility'], key: 'facility', fltr: { filterable: true, type: 'text' }, onCell
    },
    {
      title: 'Wound Location', key: 'wound_location', fltr: { filterable: true, type: 'text' }, onCell,
      
      render: (record) => record.visit_type === "Biologics" ?
        <Popover style={{ cursor: 'pointer' }}
                 content={<div>
                   {Object.keys(groupedItems(record.biologic_kits)).map(item => {
                       return (<div>
                         {keyValItemOrders('Treatment Number:', `${record.assessment_observations?.treatment_number}`)}
                         
                         {keyValItemOrders('Product Name:', `${item} ${record.biologic_kits[0]?.biologic_product?.product_name}`)}
                         {keyValItemOrders('Wound Size (Area):', `${record.assessment_observations?.area}cm`, "2")}
                         {keyValItemOrders('Recommended Grafts:', ([].concat(Object.values(groupedItems(record.biologic_kits)))[0].map(item => (`${item?.size}(${item.count})`))).toString())}
                         <Divider/>
                       </div>)
                     }
                   )}
                 </div>
                 }
        
        >
          
          <p style={{ cursor: 'pointer' }}>{record.wound_location}</p>
        </Popover> : <p style={{ cursor: 'pointer' }}>{record.wound_location}</p>
    },
    
    {
      onCell,
      key: 'visitType', title: 'Order type', dataIndex: 'visit_type',
      render: (visit_type, record) => visit_type === "Biologics" ?
        <Popover style={{ cursor: 'pointer' }}
                 content={<div>
                   {Object.keys(groupedItems(record.biologic_kits)).map(item => {
                       return (<div>
                         {keyValItemOrders('Treatment Number:', `${record.assessment_observations?.treatment_number}`)}
                         {keyValItemOrders('Product Name:', `${item} ${record.biologic_kits[0]?.biologic_product?.product_name}`)}
                         {keyValItemOrders('Wound Size (Area):', `${record.assessment_observations?.area}cm`, "2")}
                         {keyValItemOrders('Recommended Grafts:', ([].concat(Object.values(groupedItems(record.biologic_kits)))[0].map(item => (`${item?.size}(${item.count})`))).toString())}
                         
                         <Divider/>
                       </div>)
                     }
                   )}
                 </div>
                 }
        >
          
          <p style={{ cursor: 'pointer' }}>{visit_type}</p>
        </Popover> : visit_type,
      fltr: {
        filterable: false, type: 'autocomplete',
        options: makeAutoCompleteOptions([
          ...(can_view_biologic_orders ? ['biologics'] : []),
          ...(can_view_dme_orders ? ['DME'] : []), 'internal'])
      }
    },
    {
      onCell,
      title: 'Status', dataIndex: 'status', key: 'status', fltr: {
        filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(orderStatuses)
      }, render: (status) => getOrderStatus(status?.toLowerCase())
    },
    ...(can_view_audit_logs ?
      [{
        title: '', dataIndex: '', key: 'x', render: (text, record, index) => <Button onClick={async () => {
          await setOrder(record)
          await setShowAuditDialog(true)
          
        }} type="dashed" icon={<FieldTimeOutlined/>}>Audit</Button>
      }] : [])
  
  ]
  useEffect(() => {
    loadOrders().catch(appLog)
    getOrderFilterStatuses(setOrderStatuses, 'biologics').catch(appLog)
    getStatuses().catch(appLog)
    return emptyFun
  }, [])
  const getStatuses = async () => {
    let data = await apiRequest.get(`/treatment-order-statuses`).catch(e => appLog(e))
    if (data && data.data) {
      setavailableStatuses(data.data.data)
    }
  }
  
  useEffect(() => {
    setSelectedRowKeys([])
    return emptyFun
  }, [orders])
  const loadOrders = async () => {
    await dispatch(updatePatientBiologicsOrdersFilters([appConf.patientBiologicsOrdersFilters[0], appConf.patientBiologicsOrdersFilters[1], true]))
    await dispatch(fetchPatientOrders(appConf.patientBiologicsOrdersFilters[0] ? { ...appConf.patientBiologicsOrdersFilters[1] } : {
      filterData: {
        "patientId": patient.id,
        "visitType": 'biologics'
      }
    }))
    await dispatch(updatePatientBiologicsOrdersFilters([appConf.patientBiologicsOrdersFilters[0], appConf.patientBiologicsOrdersFilters[1], false]))
  }
  
  const handleFilterAction = async (action, values) => {
    // appLog({action, values})
    await dispatch(resetOrdersPagination())
    if (action === 'filter') {
      let pl = { filterData: { ...values, "patientId": patient.id, "visitType": 'biologics' }, pagination: ordersPagination }
      await dispatch(updatePatientBiologicsOrdersFilters([false, pl, true]))
      await dispatch(fetchPatientOrders(pl))
      await dispatch(updatePatientBiologicsOrdersFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updatePatientBiologicsOrdersFilters([appConf.patientBiologicsOrdersFilters[0], undefined, true]))
      await dispatch(fetchPatientOrders({ "filterData": { "patientId": patient.id, "visitType": 'biologics' } }))
      await dispatch(updatePatientBiologicsOrdersFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    // appLog('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  
  const rowSelection = { selectedRowKeys, onChange: onSelectChange };
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const handleMenuClick = (e) => {
    let payload = { orders: [...selectedRowKeys], status: availableStatuses?.filter(item => !reasonableStatuses.includes(item))[e.key] }
    // appLog(e, payload)
    let data = { payload, filters: { "filterData": { "patientId": patient.id, "visitType": 'biologics' } } }
    dispatch(bulkUpdateOrderStatus(data))
  }
  
  const reasonableStatuses = ["cancelled", "needs review", "returned"]
  const menu = (<Menu onClick={handleMenuClick}>
    {availableStatuses?.filter(item => !reasonableStatuses.includes(item)).map((item, index) => <Menu.Item key={index}
                                                                                                           style={{ textTransform: 'capitalize' }}> {item.replace('-', '').replace(/_/g, ' ')}  </Menu.Item>)}
  </Menu>);
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderExportForm, setOrderExportForm] = useState(false)
  const handleExportOrderCancel = () => {
    setOrderExportForm(false)
  }
  
  
  const selectOrdere = async (order) => {
    await dispatch(selectOrder(order))
    await fetchPatientById()
    await fetchConsultantById()
    setpreviewOpen(true)
  }
  
  
  const [consultant, setconsultant] = useState(null)
  const [orderPatient, setorderPatient] = useState(null)
  
  
  const fetchConsultantById = async () => {
    if (selectedOrder?.consultant) {
      const response = await apiRequest.get(`/consultants/${selectedOrder.consultant.id}`)
      const consultant = response?.data?.data
      if (consultant) {
        const shippingResponse = await apiRequest.get(`/shipping-addresses?user=${consultant.user_id}`)
        const consultant_addresses = shippingResponse?.data?.data
        consultant.addresses = [...consultant_addresses]
      }
      setconsultant(consultant)
      
    }
  }
  
  const fetchPatientById = async () => {
    
    
    const _patient = { ...patient }
    if (_patient) {
      const shippingResponse = await apiRequest.get(`/shipping-addresses?user=${patient.user_id}`)
      const patient_addresses = shippingResponse?.data?.data
      _patient.addresses = [...patient_addresses]
    }
    setorderPatient(_patient)
    
  }
  
  
  return (
    (<div className="main-page">
      {(showAuditDialog && !!order) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false} resourceDescription={'Treatment order'}
                                                       resourceType={'Treatment order'}
                                                       defaultValues={{ auditableId: order.id, auditable: 'treatment_order', format: 'json' }}/>}
      <Modal
        title="Order Report"
        open={orderExportForm}
        destroyOnClose
        onCancel={handleExportOrderCancel}
        closeIcon={modalClose(handleExportOrderCancel)}
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(() => setOrderExportForm(false), 'Cancel', isExecuting),
            formSubmitButton('new-order-report', 'Download', isExecuting, 'Downloading')])}
        </div>]}>
        <ReportForm closeModal={handleExportOrderCancel} setIsSubmitting={setIsSubmitting} type={"biologics"}/>
      </Modal>
      <Modal
        title={`Order ${selectedOrder?.id || ''} Details`}
        open={previewisOpen}
        onOk={dialogCloser(setpreviewOpen)}
        onCancel={dialogCloser(setpreviewOpen)}
        closeIcon={modalClose(dialogCloser(setpreviewOpen))}
        maskClosable={false}
        destroyOnClose={true}
        width={600}
        footer={activeUser?.facilityProfile ? null : [<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setpreviewOpen), 'Cancel', isExecuting),
            formSubmitButton('patientOrderpreviewForm', 'Save', isExecuting, 'Updating')])}
        </div>]}>
        
        
        <PatientOrderPreview setpreviewOpen={setpreviewOpen} loadOrders={loadOrders} isExecuting={isExecuting} setisExecuting={setisExecuting} consultant={consultant} patient={orderPatient}
                             order={selectedOrder}/>
      </Modal>
      <div className="w-full">
        
        <Table
          // sortDirections={['ascend', 'descend', 'ascend']}
          rowKey={(record) => record.id}
          // rowSelection={!activeUser?.facilityProfile && availableStatuses?.filter(item => !reasonableStatuses.includes(item)).length > 0 && rowSelection}
          title={() => (
            <>
              <TableFilters datasource={[...columns]} setIsVisible={setIsVisible}
                            isVisible={isVisible}
                            showClear={appConf.patientBiologicsOrdersFilters[0]}
                            handleFilterAction={handleFilterAction}
                            loading={appConf.patientBiologicsOrdersFilters[2]}
                            filters={appConf.patientBiologicsOrdersFilters[1]}
                            actionButton={
                              <div className="flex-boy-row" style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                                {can_export_treatment_order &&
                                  <Button type={'default'} size={'middle'} onClick={() => setOrderExportForm(true)}
                                          icon={<FontAwesomeIcon icon={["far", 'file-excel']}/>}>Export to CSV
                                  </Button>}
                              
                              
                              </div>
                            }
              />
              {selectedRowKeys.length > 0 && <div className="flex-boy-row-space" style={{ backgroundColor: 'teal', marginTop: '10px' }}>
                <Subtitle1 color="white">Selected: {selectedRowKeys.length}</Subtitle1>
                <Dropdown dropdownRender={()=>menu} trigger={['click']}>
                  {secondaryButton(null, "Change Status", updatingStatus, 'Updating',
                    { backgroundColor: 'white' }, {
                      endIcon: <CaretDownOutlined/>
                    })}
                </Dropdown>
              </div>}
            </>
          )}
          loading={appConf.patientBiologicsOrdersFilters[2]} {...responsiveTbl(windowState)} locale={{ emptyText: emptyOrders('orders.') }}
          onChange={(pagination, filters,
                     sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchPatientOrders, appConf.patientBiologicsOrdersFilters, updatePatientBiologicsOrdersFilters, {
            "patientId": patient.id,
            "visitType": 'biologics'
          }, ordersPagination)}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
              } // click row
            };
          }}
          {...getSkels(appConf.patientBiologicsOrdersFilters[2], columns, orders)}
          pagination={ordersPagination}
        />
      </div>
    </div>)
  );
}

const mapStateToProps = (state) => ({
  
  orders: state.patients.orders,
  patientOrdersPagination: state.patients.patientOrdersPagination,
  ordersPagination: state.patients.patientBiologicsOrdersPagination,
  appConf: state.appConf,
  navs: state.navs, activeUser: state.auth.activeUser,
  patient: state.patients.selectedPatient,
  order: state.orders.selectedOrder
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PatientBiologics)
