/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Body1, Headline5, Headline6, RegularButton, Subtitle1} from '../../../../components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Avatar, Col, Grid, Image, Modal, Row, Table, Tabs} from 'antd';
import {apiRequest} from '../../../../app/Apis'
import dayjs from 'dayjs'
import {CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {
  fetchLabRegions,
  fetchProcedures,
  getWoundEncounters,
  getWoundPlanofcares,
  loadWoundLocations,
  selectAssessment,
  setPatientNavs,
  updatePatientAssessmentFilters,
  updatePlanOfCareFilters
} from "../../../../app/Reducers";
import {toast} from "react-toastify";
import {
  containedButton,
  dialogCloser,
  emptyFun,
  emptyTable,
  formSubmitButton,
  getAssessmentStatus,
  getSkels,
  getWoundStatus,
  horStack,
  boldKeyCapitalizedValue,
  makeAutoCompleteOptions,
  modalClose,
  outlinedButton,
  responsiveTbl, keyValItemForReferral, keyValItemGreyForReferral
} from "../../../../common/helpers";
import EditAssessmentModal from "../../../Appointments/components/EditAssessmentModal";
import {genAllograftReport, generateLabRequisition, genReport, genSpecificWoundsReport} from "../../../../app/Apis/commonApi";
import VisitDetails from "../../../Appointments/components/VisitDetails";
import VisitStatus from "../../../Appointments/components/VisitStatus";
import PdfViewer from "../../../../components/PdfViewer";
import WoundComparison from './WoundComparison'
import {EditOutlined, FileImageOutlined, HistoryOutlined} from '@ant-design/icons';
import ImageCarousel from './ImageCarousel'
import _ from "lodash"
import AuditLogViewer from "../../../../components/AuditLogs/AuditLogViewer";
import PlanOfCareAssessment from './PlanOfCareAssessment'
import EditPlanofCareAssessment from './EditPlanofCareAssessment'
import PlanOfCareDetails from './PlanOfCareDetails'
import TableFilters from "../../../../components/InputComponents/TableFilters";
import {woundStatuses} from "../../../../common/lists";
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import {useProcedureSearch} from '../../../../hooks/useProcedureSearch';

const TabList = ["Wound Track", "Patient Details", "Documents", "Health Data"]
const { useBreakpoint } = Grid;
const WoundPage = ({  wound, patient, appConf, woundEncounters, navs,  plansOfCare, planOfCareModal, setPlanOfCareModal }) => {
  const [procedureOptions, debouncedProcedureSearch, procedureSearchLoading] = useProcedureSearch([], 'name')
  const dispatch = useDispatch()
  const windowState = useBreakpoint();
  const can_view_assessment = usePermissionCheck('view_assessment', false, [])
  const can_view_plan_of_care_summary = usePermissionCheck('view_plan_of_care_summary', false, [])
  const can_update_plan_of_care_summary = usePermissionCheck('update_plan_of_care_summary', false, [])
  const can_create_plan_of_care_summary = usePermissionCheck('create_plan_of_care_summary', false, [])
  const [woundAssessment, setWoundAssessment] = useState(null)
  const [progress, setProgress] = useState([])
  const [percentChange, setpercentChange] = useState({ lenght: null, width: null, depth: null, area: null, volume: null })
  const [healingRate, sethealingRate] = useState(null)
  const [subs, setSubs] = useState(null)
  const [chartData, setChartData] = useState([])
  const [VisitModal, setVisit] = useState(false)
  const [visitStatusModal, setStatusModal] = useState(false)
  const [editassessmentModal, seteditassessmentModal] = useState(false)
  const [isExecuting, setisExecuting] = useState(false)
  const [isPrinting, setPrinting] = useState(false)
  const [isLabPrinting, setLabPrinting] = useState(false)
  const [isAllograftPrinting, setAllograftPrinting] = useState(false)
  const [pdfShowing, setPdfShowing] = useState(false);
  const [pdfLoading, setLoading] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [isWoundsProgressPrinting, setWoundsProgressPrinting] = useState(false)
  const [woundcomparison, setwoundcomparison] = useState(false)
  const handleComparisonCancel = () => setwoundcomparison(false)
  const [moreImages, setmoreImages] = useState(false)
  const [carousel, setcarousel] = useState(false)
  const handleCarouselCancel = () => setcarousel(false)
  const handlesCarouselStart = () => setcarousel(true)
  const [editplanModal, seteditplanModal] = useState(false)
  const [viewplanModal, setviewplanModal] = useState(false)
  const [assessment, setassessment] = useState(null)
  const { TabPane } = Tabs;
  
  const genWoundProgressReport = () => genSpecificWoundsReport(wound, assessment.id, setWoundsProgressPrinting, setStatusModal, setPdf, setLoading, setPdfShowing)
  let defParam = { filterData: { wound: wound?.id, patient: patient?.id } }
  useEffect(() => {
    (async () => {
      // Stop API reload
      //console.log({ woundEncounters })
      if (!!woundEncounters && !!woundEncounters[0] && woundEncounters.every(item => item?.appointment?.patient?.id === patient.id)
        && appConf.patientAssessmentFilters[1]?.filterData?.wound === wound?.id) {
        console.log('Returning...')
        return emptyFun
      }
      console.log('Post Return...')
      let filters = !!appConf.patientAssessmentFilters[1] ? { ...appConf.patientAssessmentFilters[1] } : undefined
      let filterData = !!filters ? { ...appConf.patientAssessmentFilters[1]?.filterData } : undefined
      if (filterData) {
        filters = { ...filters, filterData: { ...filterData, wound: wound?.id, patient: patient?.id } }
      }
      await dispatch(updatePatientAssessmentFilters([appConf.patientAssessmentFilters[0], filters, true]))
      await dispatch(getWoundEncounters(appConf.patientAssessmentFilters[0] ? filters : defParam));
      can_view_plan_of_care_summary && (await dispatch(getWoundPlanofcares(appConf.patientAssessmentFilters[0] ? filters : defParam)));
      await dispatch(updatePatientAssessmentFilters([appConf.patientAssessmentFilters[0], filters, false]))
    })().catch(e => console.log(e))
    return emptyFun
  }, [])
  
  function shuffle(array) {
    array.sort(() => Math.random() - 0.5);
  }
  
  const fetchPlans = async () => {
    can_view_plan_of_care_summary && (await dispatch(getWoundPlanofcares(appConf.planOfCareFilters[0] ? appConf.planOfCareFilters[1] : defParam)));
  }
  const handlevisitOk = () => setVisit(false)
  const handlevisitCancel = () => setVisit(false)
  
  const approveAsessment = async () => {
    await setisExecuting(true)
    const toastId = toast("Updating Assesment", { type: "info", autoClose: false });
    const response = await apiRequest.post(`/assessments/${woundAssessment.id}/status`, { status: "approved" }).catch(async function (err) {
      await setisExecuting(false)
      console.log(err.response.data.message)
      toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
      await dispatch(getWoundEncounters(appConf.patientAssessmentFilters[0] ? appConf.patientAssessmentFilters[1] : defParam))
    })
    if (response && response.data) {
      toast.update(toastId, { render: "Assessment Approved", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      await dispatch(getWoundEncounters(appConf.patientAssessmentFilters[0] ? appConf.patientAssessmentFilters[1] : defParam))
    }
    await setisExecuting(false)
    setVisit(false)
    setStatusModal(false)
  }
  const generateReport = () => genReport(woundAssessment, setPrinting, setVisit, setStatusModal, setPdf, setLoading, setPdfShowing)
  const generateLabRequest = () => generateLabRequisition(woundAssessment, setLabPrinting, setVisit, setStatusModal, setPdf, setLoading, setPdfShowing)
  const generateAllograftReport = () => genAllograftReport(woundAssessment, setAllograftPrinting, setVisit, setStatusModal, setPdf, setLoading, setPdfShowing)
  useEffect(() => {
    fetchProgress()
    dispatch(fetchProcedures())
    dispatch(fetchLabRegions())
    dispatch(loadWoundLocations())
    return emptyFun
  }, [])
  useEffect(() => {
    if (can_view_plan_of_care_summary) {
      dispatch(getWoundPlanofcares(defParam))
    }
    return emptyFun
  }, [can_view_plan_of_care_summary])
  const fetchProgress = async () => {
    const response = await apiRequest.get(`/wounds/${wound?.id}/progress`)
    if (response && response.data) {
      let progress = response.data.data.map(item => ({
        name: dayjs(item.appointment_start_date).format("YYYY-MM-DD"), Depth: !!item.depth ? item.depth : 0,
        Width: !!item.width ? item.width : 0, Length: !!item.length ? item.length : 0,
        Area: !!item.area ? item.area : 0, Volume: !!item.volume ? item.volume : 0,
        ...item
      }))
      setProgress(_.orderBy([...progress], 'name'))
      
      let paecent = {
        length: progress.length ? (_.orderBy(progress, 'name')[progress.length - 1]?.length) / (_.orderBy(progress, 'name')[progress.length - 2]?.length) * 100 - 100 : 0,
        width: progress.length ? (_.orderBy(progress, 'name')[progress.length - 1]?.width) / (_.orderBy(progress, 'name')[progress.length - 2]?.width) * 100 - 100 : 0,
        depth: progress.length ? (_.orderBy(progress, 'name')[progress.length - 1]?.depth) / (_.orderBy(progress, 'name')[progress.length - 2]?.depth) * 100 - 100 : 0,
        area: progress.length ? (_.orderBy(progress, 'name')[progress.length - 1]?.area) / (_.orderBy(progress, 'name')[progress.length - 2]?.area) * 100 - 100 : 0,
        volume: progress.length ? (_.orderBy(progress, 'name')[progress.length - 1]?.volume) / (_.orderBy(progress, 'name')[progress.length - 2]?.volume) * 100 - 100 : 0
      }
      sethealingRate(response.data.wound_progress)
      setSubs(response.data.grafts_applied)
      setpercentChange(paecent)
    }
  }
  const columns = [{
    title: 'Provider', dataIndex: ['appointment', 'consultant', 'name'], key: 'provider',
    fltr: { filterable: true, type: 'text' }, render: (name) => (<>
      <div className="flex-boy-row-space" style={{ justifyContent: 'normal' }}>
        <Avatar src={<Image src={require("../../../../assets/user.png")}/>}/>
        <Subtitle1 style={{ textAlign: "left", marginLeft: '10px' }}>{name}</Subtitle1>
      </div>
    </>)
  }, /*{
    title: 'Initial date', dataIndex: 'created_at', key: 'created_at', fltr: { filterable: true, type: 'date' },
    render: (start_at, record) => (
      <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(start_at).format("MM/DD/YYYY")}</Subtitle1>)
  },*/ {
    title: 'Appointment date', dataIndex: ['appointment', 'start_at'], key: 'start_at', fltr: { filterable: true, type: 'date' },
    render: (start_at, record) => (
      <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(start_at).format("MM/DD/YYYY")}</Subtitle1>)
  },/* {
    title: 'Last visited', dataIndex: ['appointment', 'updated_at'], key: 'updated_at', fltr: { filterable: true, type: 'date' },
    render: (start_at, record) => (
      <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(start_at).format("MM/DD/YYYY")}</Subtitle1>)
  },*/ {
    title: 'Wound', key: 'wound', fltr: { filterable: false, type: 'date' },
    render: (record) => (<Subtitle1 style={{ textAlign: 'left' }}>{record.wound_side} {record.wound_position} {record.location} </Subtitle1>)
  }, {
    title: 'Assessment Status', dataIndex: 'review', fltr: {
      filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(['rejected', 'approved', 'pending_review'])
    }, key: 'reviewStatus', render: (review) => getAssessmentStatus(review?.status?.toLowerCase())
  },  {
    title: 'Procedure Type', dataIndex: 'procedure', key: 'procedure', fltr: {
      filterable: true, type: 'select', options: procedureOptions,
      props: {
        loading: procedureSearchLoading,
        onSearch: (value) => debouncedProcedureSearch(value), showSearch: true, filterOption: false
      }
    }, render: (recommended_procedure) => {
      if (recommended_procedure && Array.isArray(recommended_procedure) && recommended_procedure.length > 0) {
        return recommended_procedure.join(', ')
      } else {
        return recommended_procedure
      }
    }
  }, {
    title: 'Wound Status', dataIndex: 'status', fltr: { filterable: false, type: 'autocomplete', options: makeAutoCompleteOptions(woundStatuses) }, key: 'wound_status',
    render: (wound_status) => getWoundStatus(wound_status?.toLowerCase())
  }, {
    title: <FontAwesomeIcon color="grey" icon={["fas", "table"]}/>, key: 'operation', width: 70
    // render: () => <a>action</a>,
  }]
  const columns_plan = [
    {
      title: 'Provider', dataIndex: 'provider', key: 'provider', fltr: { filterable: true, type: 'text' },
      render: (provider, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{provider}</Subtitle1>)
    },
    {
      title: 'Date of Creation', dataIndex: 'created_at', key: 'createdAt', fltr: { filterable: true, type: 'date' },
      render: (created_at, record) => (
        <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(created_at).format("MM/DD/YYYY")}</Subtitle1>)
    },
    //baseKey is used as form field name on the Filters form while keys attr is used to name filter API parameters
    {
      title: 'Summary Start', dataIndex: 'summary_from_date', key: 'summaryFromDate',
      fltr: { filterable: true, type: 'date_range', filterLabel: 'Summary Date Filter', keys: ['summaryFromDate', 'summaryUntilDate'], baseKey: 'summary_filter' },
      render: (summary_from_date, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(summary_from_date).format("MM/DD/YYYY")}</Subtitle1>)
    }, {
      title: 'Summary End', dataIndex: 'summary_until_date', key: 'summaryUntilDate', fltr: { filterable: false, type: 'date' },
      render: (summary_until_date, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(summary_until_date).format("MM/DD/YYYY")}</Subtitle1>)
    }, {
      title: 'Wound Status', dataIndex: 'wound_status', fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(woundStatuses) }, key: 'woundStatus',
      render: (wound_status) => getWoundStatus(wound_status?.toLowerCase())
    }
  ]
  const handleChartChange = async (e, newValue) => await dispatch(setPatientNavs({ openChart: newValue }))
  const renderLegend = (props) => {
    const { payload } = props;
    return (<>
      <div className="dot-circle-active"/>
      <Subtitle1>Something</Subtitle1>
    </>);
  }
  const renderChartLabel = (props) => {
    return <Headline6>Wound Details </Headline6>;
  }
  const [isVisible, setIsVisible] = useState(false);
  const [isPVisible, setIsPVisible] = useState(false);
  
  async function handleFilterAction(action, values) {
    if (action === 'filter') {
      console.log('FILTERS:  ', JSON.stringify({ ...values, toLoad: 'assessments', endpoint: '/assessments?wound=1' }));
      let pl = { filterData: { ...values, ...defParam.filterData } }
      await dispatch(updatePatientAssessmentFilters([false, pl, true]))
      await dispatch(getWoundEncounters(pl));
      await dispatch(updatePatientAssessmentFilters([true, pl, false]))
    }
    if (action === 'reset'/* && appConf.patientAssessmentFilters[0]*/) {
      await dispatch(updatePatientAssessmentFilters([appConf.patientAssessmentFilters[0], undefined, true]))
      await dispatch(getWoundEncounters(defParam));
      await dispatch(updatePatientAssessmentFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  async function handlePlanOfCareFilterAction(action, values) {
    if (action === 'filter') {
      console.log('FILTERS:  ', JSON.stringify({ ...values, toLoad: 'assessments', endpoint: '/assessments?wound=1' }));
      let pl = { filterData: { ...values, ...defParam.filterData } }
      await dispatch(updatePlanOfCareFilters([false, pl, true]))
      can_view_plan_of_care_summary && (await dispatch(getWoundPlanofcares(pl)));
      await dispatch(updatePlanOfCareFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updatePlanOfCareFilters([appConf.planOfCareFilters[0], undefined, true]))
      await dispatch(getWoundPlanofcares(defParam))
      await dispatch(updatePlanOfCareFilters([false, undefined, false]))
    }
    setIsPVisible(false)
  }
  
  
  const declineAssessment = async (payload) => {
    await setisExecuting(true)
    const toastId = toast("Updating Assessment", { type: "info", autoClose: false });
    let data = { notes: payload.notes, status: "rejected" }
    const response = await apiRequest.post(`/assessments/${woundAssessment.id}/status`, data).catch(async function (err) {
      await setisExecuting(false)
      console.log(err)
      toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
      await dispatch(getWoundEncounters(appConf.patientAssessmentFilters[0] ? appConf.patientAssessmentFilters[1] : defParam));
    })
    if (response && response.data) {
      toast.update(toastId, { render: "Assessment Rejected", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      await dispatch(getWoundEncounters(appConf.patientAssessmentFilters[0] ? appConf.patientAssessmentFilters[1] : defParam));
    }
    await setisExecuting(false)
    setVisit(false)
    setStatusModal(false)
  }
  return (<>
    {(!!woundAssessment && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} resourceType={'Assessment'}
                                                               loading={false} resourceDescription={`${woundAssessment.appointment.patient.name}'s ${woundAssessment.procedure} assessment`}
                                                               defaultValues={{ auditableId: woundAssessment?.id, auditable: 'assessment', format: 'json' }}/>}
    <PdfViewer pdf={pdf} setLoading={setLoading} loading={pdfLoading} onCancel={() => setPdfShowing(false)} visible={pdfShowing}/>
    {woundAssessment &&
      <EditAssessmentModal editassessmentModal={editassessmentModal} seteditassessmentModal={seteditassessmentModal}
                           isExecuting={isExecuting} setVisit={setVisit} setisExecuting={setisExecuting}
                           sellectedVisit={woundAssessment}/>}
    <VisitStatus visitStatusModal={visitStatusModal} setStatusModal={setStatusModal} isExecuting={isExecuting} declineAssessment={declineAssessment}/>
    {(VisitModal && !!woundAssessment) &&
      <VisitDetails setShowAuditDialog={setShowAuditDialog} assessment={woundAssessment} setVisit={setVisit} approveAsessment={approveAsessment}
                    VisitModal={VisitModal} seteditassessmentModal={seteditassessmentModal}
                    isExecuting={isExecuting} isPrinting={isPrinting} isLabPrinting={isLabPrinting} 
                    setStatusModal={setStatusModal} handlevisitOk={handlevisitOk}
                    handlevisitCancel={handlevisitCancel} generateLabRequest={generateLabRequest}
                    generateReport={generateReport} isAllograftPrinting={isAllograftPrinting}
                    generateAllograftReport={generateAllograftReport}/>}
    <Modal
      destroyOnClose={true}
      title="Compare wound State"
      open={woundcomparison}
      onOk={handleComparisonCancel}
      onCancel={handleComparisonCancel}
      closeIcon={modalClose(handleComparisonCancel)}
      width={1000}
      maskClosable={false}
    >
      <WoundComparison wound={wound} progress={progress}/>
    </Modal>
    <Modal
      destroyOnClose={true}
      title="Compare wound state"
      open={carousel}
      onOk={handleCarouselCancel}
      onCancel={handleCarouselCancel}
      closeIcon={modalClose(handleCarouselCancel)}
      
      maskClosable={false}
    >
      <ImageCarousel wound={wound} progress={progress} currentImage="1"/>
    </Modal>
    <Modal
      title={`${patient?.name}'s Plan of Care assessment`}
      open={editplanModal}
      destroyOnClose={true}
      onOk={() => seteditplanModal(false)}
      onCancel={() => seteditplanModal(false)}
      closeIcon={modalClose(() => seteditplanModal(false))}
      
      maskClosable={false}
      width={1200}
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        {horStack([outlinedButton(() => seteditplanModal(false), 'Cancel'),
          formSubmitButton('planOfCareAssessmentForm', 'Update Plan of care', isExecuting, 'Updating')])}
      
      </div>]}>
      <EditPlanofCareAssessment
                                setisExecuting={setisExecuting}
                                wound={wound}
                                assessment={assessment}
                                plan_of_care={assessment}
                                seteditplanModal={seteditplanModal}
                                setviewplanModal={setviewplanModal}
                                fetchPlans={fetchPlans}
                                progress={progress}
                                procedure={"Plan Of Care"}/>
    </Modal>
    <Modal
      title={<div className="flex-boy-row-space" style={{ marginRight: '20px' }}>
        <Subtitle1>{patient?.name}</Subtitle1>
        {can_update_plan_of_care_summary && outlinedButton(() => seteditplanModal(true), 'Edit', false, null, null, { icon: <EditOutlined/> })}
      </div>}
      open={viewplanModal}
      destroyOnClose={true}
      onOk={() => setviewplanModal(false)}
      onCancel={() => setviewplanModal(false)}
      closeIcon={modalClose(() => setviewplanModal(false))}
      
      maskClosable={false}
      width={1200}
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        {horStack([outlinedButton(() => setviewplanModal(false), 'Cancel'), containedButton(genWoundProgressReport, 'Generate Report', isWoundsProgressPrinting, 'Printing', null, {
          icon: <HistoryOutlined />
        })])}
      
      </div>]}>
      <PlanOfCareDetails setisExecuting={setisExecuting}
                         wound={wound}
                         assessment={assessment}
                         plan_of_care={assessment}
                         setviewplanModal={setviewplanModal}
                         seteditplanModal={seteditplanModal}
                         fetchPlans={fetchPlans}
                         progress={progress}
                         procedure={"Plan Of Care"}/>
    </Modal>
    <Modal
      title={`${patient?.name}`}
      open={planOfCareModal}
      destroyOnClose={true}
      onOk={() => setPlanOfCareModal(false)}
      onCancel={() => setPlanOfCareModal(false)}
      closeIcon={modalClose(() => setPlanOfCareModal(false))}
      maskClosable={false}
      width={1200}
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        {horStack([outlinedButton(() => setPlanOfCareModal(false), 'Cancel'), formSubmitButton('planOfCareForm', 'Create Plan of care', isExecuting, 'Creating')])}
      
      </div>]}>
      <PlanOfCareAssessment setisExecuting={setisExecuting}
                            wound={wound}
                            progress={progress}
                            fetchPlans={fetchPlans}
                            setPlanOfCareModal={setPlanOfCareModal}
                            procedure={"Plan Of Care"}/>
    </Modal>
    <div className="" style={{ background: 'whitesmoke' }}>
      <Row gutter={16}>
        <Col span={6}>
          <div className="flex-boy-column">
            <div className="card-box">
              <div className="flex-boy-row-space" style={{ width: "100%" }}>
                <Headline6>Wound Details </Headline6>
              
              </div>
              <Row gutter={48} style={{ width: "100%" }}>
                <Col span={24}>
                  {boldKeyCapitalizedValue('Updated', wound?.last_visit ? dayjs(wound?.last_visit).format('MM/DD/YYYY') : 'N/A')}
                  {boldKeyCapitalizedValue('Wound Location', ` ${wound?.side || ''} ${wound?.position || ""} ${wound?.location || ''}`)}
                  {boldKeyCapitalizedValue('Wound Side', wound.side)}
                  {boldKeyCapitalizedValue('Wound Position', wound.position)}
                  {/*{boldKeyCapitalizedValue('Classification', wound?.classification)}*/}
                  {boldKeyCapitalizedValue('Width', wound?.width)}
                  {boldKeyCapitalizedValue('Length', wound?.length)}
                  {boldKeyCapitalizedValue('Depth', wound?.depth)}
                  {boldKeyCapitalizedValue('Volume', parseFloat(wound?.volume).toFixed(2))}
                  {boldKeyCapitalizedValue('Area', parseFloat(wound?.area).toFixed(2))}
                  {/*{boldKeyCapitalizedValue('Wound Bed', wound?.wound_bed||'-')}*/}
                  {boldKeyCapitalizedValue('Granulation Tissue(%)', wound?.granulation_tissue?.toString()||'-')}
                  {boldKeyCapitalizedValue('Necrotic Tissue(%)', wound?.necrotic_tissue?.toString()||'-')}
                  {boldKeyCapitalizedValue('Fibrous Tissue(%)', wound?.fibrous_tissue?.toString()|| '-')}
                  {boldKeyCapitalizedValue('Onset of the wound', wound.original_date_of_wound ? dayjs(wound.original_date_of_wound).format('MM-DD-YYYY') : '-')}
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Image style={{ width: "150px" }} src={wound?.wound_image_url} height={150}/>
                    {woundEncounters?.find(item => item.woundLocationPath) ?
                      <Image src={woundEncounters.find(item => item.woundLocationPath).woundLocationPath.url} height={150}/> : null}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="card-box">
            <div className="flex-boy-row-space" style={{ width: "100%" }}>
              <div>
                <Headline6>Healing Rate</Headline6>
                <Body1 color="black">{parseFloat(healingRate?.continuous_healing_rate.replace(" cm/day") || 0).toFixed(4)} cm/day</Body1>
              </div>
              <div>
                <Body1></Body1>
                <Body1
                  color={parseFloat(healingRate?.percent_healed) > 0 ? "#07bf26" : "#f72a48"}>{parseFloat(healingRate?.percent_healed) > 0 ? String.fromCharCode(8599) : parseFloat(healingRate?.percent_healed) === 0 ? null : String.fromCharCode(8601)} {parseFloat(healingRate?.percent_healed || 0)?.toFixed(2)}%</Body1>
              </div>
            </div>
          
          </div>
          <div className="card-box">
            
            <div className="flex-boy-row-space" style={{ width: "100%" }}>
              <div>
                <Headline6>Projected days to healing</Headline6>
                <Body1 color="black">{healingRate?.time_to_closure} {healingRate?.time_to_closure !== "N/A" && "Days"}</Body1>
              </div>
            
            </div>
          </div>
          <div className="card-box">
            <div className="flex-boy-row-space" style={{ width: "100%" }}>
              <div>
                <Headline6>Total Visits</Headline6>
                <Headline6>{progress?.length}</Headline6>
              </div>
              <div>
              
              </div>
            </div>
          </div>
          <div className="card-box">
            
            <Headline6>Subs Applied</Headline6>
            <Headline6>{subs}</Headline6>
          </div>
        </Col>
        <Col span={18}>
          <div className="card-box" style={{ height: "inherit" }}>
            <Headline6>Progress</Headline6>
            <div className="flex-boy-row" style={{ width: "100%", flexWrap: 'wrap' }}>
              
              
              {moreImages ? _.orderBy(progress, 'name', 'asc').map(item => {
                return (
                  item.image_url ? <div style={{ position: 'relative', height: 'fit-content', width: '18%', margin: '5px' }}><img onClick={() => handlesCarouselStart()} src={item.image_url}
                                                                                                                                  style={{ margin: '5px', width: '100%', aspectRatio: "1 / 1" }}/>
                    <div style={{ position: 'absolute', left: '10%', bottom: '5%', backgroundColor: "#404040a4", padding: '5px', borderRadius: '5px', color: 'white' }}>{item.name}</div>
                  </div> : <div onClick={() => handlesCarouselStart()} className="flex-boy-row"
                                style={{ margin: '10px', width: '240px', height: '300px', background: 'whitesmoke', justifyContent: 'center', alignItems: 'center' }}>
                    <FileImageOutlined style={{ fontSize: '60px', color: 'grey' }}/>
                  
                  </div>
                )
              }) : _.orderBy(_.orderBy(progress, 'name', 'desc').slice(0, 4), 'name', 'asc').map(item => {
                return (
                  item.image_url ?
                    <div style={{ position: 'relative', height: 'fit-content', width: '18%', margin: '5px' }}><img onClick={() => handlesCarouselStart()} src={item.image_url}
                                                                                                                   style={{ margin: '5px', width: '100%', aspectRatio: "1 / 1" }}/>
                      <div style={{ position: 'absolute', left: '10%', bottom: '5%', color: 'white', backgroundColor: "#404040a4", padding: '5px', borderRadius: '5px' }}>{item.name}</div>
                    </div>
                    : <div onClick={() => handlesCarouselStart()} className="flex-boy-row"
                           style={{ margin: '10px', width: '240px', height: '300px', background: 'whitesmoke', justifyContent: 'center', alignItems: 'center' }}>
                      <FileImageOutlined style={{ fontSize: '60px', color: 'grey' }}/>
                    </div>
                )
              })}
            
            
            </div>
            
            <div className="flex-boy-row-space">
              {progress.length > 5 && <RegularButton onClick={() => setmoreImages(!moreImages)}>View {moreImages ? "Less" : "More"}</RegularButton>}
              <RegularButton color="crimson" background="white" onClick={() => setwoundcomparison(true)}>Compare</RegularButton>
            </div>
          
          
          </div>
          <div className="flex-boy-row-space">
            <div className="card-box">
              <div className="flex-boy-row-space" style={{ width: "100%" }}>
                <div>
                  <Subtitle1>Width</Subtitle1>
                  <Headline5>{progress && progress.length && _.orderBy(progress, 'name')[progress.length - 1]?.width || 0}</Headline5>
                </div>
                <div>
                  <Body1></Body1>
                  <Body1
                    color={parseFloat(percentChange.width) < 0 ? "#07bf26" : "#f72a48"}> {parseFloat(percentChange.width) > 0 ? String.fromCharCode(8599) : !percentChange.width ? null : String.fromCharCode(8601)} {Math.abs(percentChange.width && parseFloat(percentChange.width).toFixed(2)) || 0}%</Body1>
                
                </div>
              </div>
              <ResponsiveContainer width="100%" aspect={4.0 / 1.96}>
                <LineChart data={progress} ratio
                           margin={{ top: 10, right: 10, left: 10, bottom: 20 }}>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="name">
                    <Label alignmentBaseline={"after-edge"}
                           position="bottom" style={{ position: 'relative', margin: "10px" }}
                           value="Date of appointment"/>
                  </XAxis>
                  <YAxis>
                    <Label value={"Width"} alignmentBaseline={"after-edge"}
                           position="center" dx={-30}
                           angle={270}
                           style={{ margin: "10px" }}/>
                  </YAxis>
                  <Tooltip/>
                  <Legend align={"left"} wrapperStyle={{ position: 'relative' }}/>
                  
                  <Line type="monotone" dataKey="Width" stroke="#FFB46E" strokeWidth={2}/>
                
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div className="card-box">
              <div className="flex-boy-row-space" style={{ width: "100%" }}>
                <div>
                  <Subtitle1>Length</Subtitle1>
                  <Headline5>{_.orderBy(progress, 'name')[progress.length - 1]?.length}</Headline5>
                </div>
                <div>
                  <Body1></Body1>
                  <Body1
                    color={parseFloat(percentChange.length) < 0 ? "#07bf26" : "#f72a48"}>{parseFloat(percentChange.length) > 0 ? String.fromCharCode(8599) : !percentChange.length ? null : String.fromCharCode(8601)} {Math.abs(percentChange.length && parseFloat(percentChange.length)?.toFixed(2)) || 0}%</Body1>
                
                </div>
              </div>
              <ResponsiveContainer width="100%" aspect={4.0 / 1.96}>
                <LineChart data={progress} ratio
                           margin={{ top: 10, right: 10, left: 10, bottom: 20 }}>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="name">
                    <Label alignmentBaseline={"after-edge"}
                           position="bottom" style={{ position: 'relative', margin: "10px" }}
                           value="Date of appointment"/>
                  </XAxis>
                  <YAxis>
                    <Label value={"Length"} alignmentBaseline={"after-edge"}
                           position="center" dx={-30}
                           angle={270}
                           style={{ margin: "10px" }}/>
                  </YAxis>
                  <Tooltip/>
                  <Legend align={"left"} wrapperStyle={{ position: 'relative' }}/>
                  
                  
                  <Line type="monotone" dataKey="Length" stroke="#50D2A0" strokeWidth={2}/>
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div className="card-box">
              <div className="flex-boy-row-space" style={{ width: "100%" }}>
                <div>
                  <Subtitle1>Depth</Subtitle1>
                  <Headline5>{_.orderBy(progress, 'name')[progress.length - 1]?.depth}</Headline5>
                </div>
                <div>
                  <Body1></Body1>
                  <Body1
                    color={parseFloat(percentChange.depth) < 0 ? "#07bf26" : "#f72a48"}>{parseFloat(percentChange.depth) > 0 ? String.fromCharCode(8599) : !percentChange.depth ? null : String.fromCharCode(8601)} {Math.abs(percentChange.depth && parseFloat(percentChange.depth)?.toFixed(2)) || 0}%</Body1>
                </div>
              </div>
              <ResponsiveContainer width="100%" aspect={4.0 / 1.96}>
                <LineChart data={progress} ratio
                           margin={{ top: 10, right: 10, left: 10, bottom: 20 }}>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="name">
                    <Label alignmentBaseline={"after-edge"}
                           position="bottom" style={{ position: 'relative', margin: "10px" }}
                           value="Date of appointment"/>
                  </XAxis>
                  <YAxis>
                    <Label value={"Depth"} alignmentBaseline={"after-edge"}
                           position="center" dx={-30}
                           angle={270}
                           style={{ margin: "10px" }}/>
                  </YAxis>
                  <Tooltip/>
                  <Legend align={"left"} wrapperStyle={{ position: 'relative' }}/>
                  
                  
                  <Line type="monotone" dataKey="Depth" stroke="#7785FF" strokeWidth={2}/>
                
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="flex-boy-row-space">
            <div className="card-box">
              <div className="flex-boy-row-space" style={{ width: "100%" }}>
                <div>
                  <Subtitle1>Area</Subtitle1>
                  <Headline5>{parseFloat(_.orderBy(progress, 'name')[progress.length - 1]?.area || 0).toFixed(2)}</Headline5>
                </div>
                <div>
                  <Body1></Body1>
                  <Body1
                    color={parseFloat(percentChange.area) < 0 ? "#07bf26" : "#f72a48"}>{parseFloat(percentChange.area) > 0 ? String.fromCharCode(8599) : !percentChange.area ? null : String.fromCharCode(8601)} {Math.abs(percentChange.area && parseFloat(percentChange.area).toFixed(2)) || 0}%</Body1>
                
                </div>
              </div>
              <ResponsiveContainer width="100%" aspect={4.0 / 1.96}>
                <LineChart data={progress} ratio
                           margin={{ top: 10, right: 10, left: 10, bottom: 20 }}>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="name">
                    <Label alignmentBaseline={"after-edge"}
                           position="bottom" style={{ position: 'relative', margin: "10px" }}
                           value="Date of appointment"/>
                  </XAxis>
                  <YAxis>
                    <Label value={"Depth"} alignmentBaseline={"after-edge"}
                           position="center" dx={-30}
                           angle={270}
                           style={{ margin: "10px" }}/>
                  </YAxis>
                  <Tooltip/>
                  <Legend align={"left"} wrapperStyle={{ position: 'relative' }}/>
                  
                  
                  <Line type="monotone" dataKey="Area" stroke="#5FC8FF" strokeWidth={2}/>
                
                </LineChart>
              
              </ResponsiveContainer>
            </div>
            <div className="card-box">
              <div className="flex-boy-row-space" style={{ width: "100%" }}>
                <div>
                  <Subtitle1>volume</Subtitle1>
                  <Headline5>{parseFloat(_.orderBy(progress, 'name')[progress.length - 1]?.volume || 0).toFixed(2)}</Headline5>
                </div>
                <div>
                  <Body1></Body1>
                  <Body1
                    color={parseFloat(percentChange.volume) < 0 ? "#07bf26" : "#f72a48"}>{parseFloat(percentChange.volume) > 0 ? String.fromCharCode(8599) : !percentChange.volume ? null : String.fromCharCode(8601)} {Math.abs(percentChange.volume && parseFloat(percentChange.volume)?.toFixed(2)) || 0}%</Body1>
                </div>
              </div>
              <ResponsiveContainer width="100%" aspect={4.0 / 1.96}>
                <LineChart data={progress} ratio
                           margin={{ top: 10, right: 10, left: 10, bottom: 20 }}>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="name">
                    <Label alignmentBaseline={"after-edge"}
                           position="bottom" style={{ position: 'relative', margin: "10px" }}
                           value="Date of appointment"/>
                  </XAxis>
                  <YAxis>
                    <Label value={"Depth"} alignmentBaseline={"after-edge"}
                           position="center" dx={-30}
                           angle={270}
                           style={{ margin: "10px" }}/>
                  </YAxis>
                  <Tooltip/>
                  <Legend align={"left"} wrapperStyle={{ position: 'relative' }}/>
                  
                  
                  <Line type="monotone" dataKey="Volume" stroke="#FF5F5F" strokeWidth={2}/>
                
                </LineChart>
              </ResponsiveContainer>
            </div>
          
          </div>
          <div className="card-box">
            <Tabs id="wound-tabs" defaultActiveKey="Visit History" style={{ width: '100%' }}>
              <TabPane size="large" tab="Visit History" key="Agency">
                <Headline6>Visit history</Headline6>
                <div className="w-full">
                  <Table
                    title={() => (<TableFilters datasource={columns}
                                                setIsVisible={setIsVisible}
                                                isVisible={isVisible}
                                                actionButton={null}
                                                handleFilterAction={handleFilterAction}
                                                filters={appConf.patientAssessmentFilters[1]}
                                                showClear={appConf.patientAssessmentFilters[0]}
                                                loading={appConf.patientAssessmentFilters[2]}/>)}
                    size={'small'} loading={appConf.patientAssessmentFilters[2]} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('wound assessments') }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: event => {
                          (async () => {
                            await setWoundAssessment(record)
                            await dispatch(selectAssessment(record))
                            if (can_view_assessment) {
                              await setVisit(true)
                            }
                          })().catch(e => console.log(e))
                        } // click row
                      };
                    }}
                    {...getSkels((appConf.patientAssessmentFilters[2]), columns, woundEncounters)} />
                </div>
              </TabPane>
              {can_view_plan_of_care_summary && <TabPane size="large" tab="Plan of Care" key="Plan of Care">
                <div className="flex-boy-row-space">
                  
                  <div>
                    <Headline6>Plan of Care</Headline6>
                    {/* {getPlanFilters()} */}
                  </div>
                  {progress.length > 1 && can_create_plan_of_care_summary && <RegularButton onClick={() => setPlanOfCareModal(true)}>New Plan of Care</RegularButton>}
                </div>
                <div className="w-full">
                  <Table
                    title={() => (
                      <TableFilters
                        datasource={[...columns_plan]}
                        setIsVisible={setIsPVisible}
                        filters={appConf.planOfCareFilters[1]}
                        actionButton={null}
                        isVisible={isPVisible}
                        showClear={appConf.planOfCareFilters[0]}
                        loading={appConf.planOfCareFilters[2]}
                        handleFilterAction={handlePlanOfCareFilterAction}/>
                    )}
                    size={'small'}
                    loading={appConf.planOfCareFilters[2]}
                    {...responsiveTbl(windowState)}
                    locale={{ emptyText: emptyTable('wound assessments') }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: event => {
                          (async () => {
                            await setassessment(record)
                            setviewplanModal(true)
                          })().catch(e => console.log(e))
                        } // click row
                      };
                    }}
                    {...getSkels(null, columns_plan, _.orderBy(plansOfCare, 'created_at', 'desc'))} />
                </div>
              </TabPane>}
            
            </Tabs>
          
          
          </div>
        </Col>
      
      </Row>
    
    </div>
  </>);
}
const mapStateToProps = (state) => ({
  wound: state.patients.selectedWound, patient: state.patients.selectedPatient,
  plansOfCare: state.patients.woundPlansOfCare,
  appConf: state.appConf, woundEncounters: state.patients.woundEncounters,
  navs: state.navs
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(WoundPage)
