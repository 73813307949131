import React, {useEffect, useState} from "react";
import {RegularButton} from "../../../../components";
import {connect} from 'react-redux'
import {useClient} from "./Settings";
import VideoCallui from "./videoCallui";
import {apiRequest} from "../../../../app/Apis";

// import { channelName } from "./Settings";

export const VideoChat = ({ channelName, token, handleCancel, uid, isHost, hostData }) => {
  const [inCall, setInCall] = useState(isHost);
  
  const [screenShareToken, setScreenShareToken] = useState(null);
  const [muteAudio, setMuteAudio] = useState(false);
  const [hideVideo, setHideVideo] = useState(false);
  const Leave = async () => {
    console.log(client)
    
    client.unpublish()
    
    await client.leave();
    setclient(null)
    handleCancel()
    
  }
  
  useEffect(() => {
    if (!isHost) {
      getToken()
    }
    
    return () => {
    
    }
  }, [])
  const [client, setclient] = useState(useClient)
  
  
  const getToken = async () => {
    const response = await apiRequest.post(`/agora/token`, { "channel_name": channelName, "uid": 0 }).catch(err => console.log(err))
    if (response) {
      setScreenShareToken(response.data.data)
    }
  }
  
  
  return (
    <div className="VideoApp" style={{ height: "100%", width: "100%", background: '#1C1C24' }}>
      {inCall && client ? (
        <VideoCallui token={isHost ? token : screenShareToken}
                     hostdata={hostData}
                     isHost={isHost}
                     client={client}
                     inCall={inCall}
                     handleCancel={() => {
                       Leave()
                     }}
                     setInCall={() => {
                       setInCall()
                       Leave()
                       
                     }} Leave={Leave} uid={0} channelName={channelName}/>
      ) : (
        <div className="p-2">
          <RegularButton
            
            
            onClick={() => setInCall(true)}
          >
            Join Call
          </RegularButton>
          
          <div className="flex flex-row justify-center items-center m-4">
            {/* <RegularButtonCircle
                                className="m-2"

                                onClick={() => setMuteAudio(!muteAudio)}
                            >
                                {muteAudio ? <MicOffIcon color="white" /> : <MicIcon color="white" />  }
                            </RegularButtonCircle>
                <div className="m-2"></div>
                            <RegularButtonCircle
                                className="m-2"
                                onClick={() => setHideVideo(!hideVideo)}
                            >
                                {!hideVideo ? <VideocamIcon color="white" /> : <VideocamOffIcon />}
                            </RegularButtonCircle> */}
          
          
          </div>
        
        
        </div>
      
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  // channelName: state.chat.channelName,
  // token:state.auth.agoraToken
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(VideoChat)