import React, {useEffect, useState} from 'react';
import {Headline6, Subtitle2} from "../../../components";
import {addButton, dialogCloser, editMenus, emptyFun, formSubmitButton, horStack, kebabMenus, modalClose, outlinedButton, skelBars} from "../../../common/helpers";
import {Divider, Menu, Modal, Tag} from "antd";
import {connect, useDispatch} from "react-redux";

import {deleteShippingAddress, fetchShippingAddresses, setshippingAddressesLoaded, setshippingAddressesLoading} from "../../../app/Reducers";
import ShippingForm from "./shippingForm";
import KebabMenu from "../../../components/KebabMenu";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';

const ConsultantShippingAddress = ({  shippingAddresses, navs, consultant, activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [isExecuting, setisExecuting] = useState(false)
  const [shippingForm, setShippingForm] = useState(false);
  const [editingForm, setEditingForm] = useState(false);
  const [sortedAddresses, setSortedAddresses] = useState([]);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [address, setAddress] = useState(null);
  const consultantId = consultant?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const { message } = useSubscription(`view_consultant_shipping_address/${consultant.user_id}`, { qos: 2, nl: true });
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_consultant = usePermissionCheck('update_consultant', false, [])
  const can_update_consultant_global = usePermissionCheck('update_consultant_global', false, [])
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await loadShippingAddresses(consultant.user_id, true)
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  
  async function loadShippingAddresses(user_id, isMQMessage) {
    await dispatch(setshippingAddressesLoading(!isMQMessage))
    await dispatch(fetchShippingAddresses(consultant.user_id))
    await dispatch(setshippingAddressesLoading(false))
    await dispatch(setshippingAddressesLoaded(consultant.user_id))
  }
  
  useEffect(() => {
    (async () => {
      // if (navs.shippingAddressesLoaded !== consultantId && !navs.shippingAddressesLoading) {
      if (consultant.user_id) {
        await loadShippingAddresses(consultant.user_id)
      }
      // }
    })().catch(e => console.log(e))
    return emptyFun
  }, [consultant])
  useEffect(() => {
    if (shippingAddresses && shippingAddresses.length && shippingAddresses.length > 0) {
      setSortedAddresses([...shippingAddresses].sort((a, b) => {
        if (a.default < b.default) {
          return 1;
        }
        if (a.default > b.default) {
          return -1;
        }
        return 0;
      }))
    } else {
      setSortedAddresses([])
    }
    return emptyFun
  }, [shippingAddresses])
  const handleShippingCancel = (values) => {
    setShippingForm(false);
    setAddress(null);
  }
  const [selected, setSelected] = React.useState(null);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const showPopconfirm = (address) => {
    setSelected(address.street_address)
  };
  const handleCancel = () => setSelected(null)
  const handleOk = async (address_id) => {
    await setConfirmLoading(true);
    let data = { address_id, user_id: consultant.user_id }
    await dispatch(deleteShippingAddress(data))
    await setSelected(null)
    await setConfirmLoading(false);
  };
  const actionMenus = kebabMenus(((can_update_consultant_global || consultant.user_id === activeUser.id) && !consultant.deleted_at), can_view_audit_logs, can_update_consultant)
  
  async function kebabMenuClick(e, address) {
    console.log(actionMenus[e.key].title)
    await setAddress(address)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true)
        await setShippingForm(true)
        break
      case 'Delete':
        showPopconfirm(address)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
    }
  }
  
  const menu = (address) => {
    return <Menu onClick={(e) => kebabMenuClick(e, address)}>
      {editMenus.map((item, index) => <Menu.Item key={index} icon={item.icon}> {item.title}  </Menu.Item>)}
    </Menu>
  }
  return (
    (<div className="shadow-card-box">
      {(!!address && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} resourceType={'Consultant'}
                                                         onCancel={dialogCloser(setShowAuditDialog)} loading={false} resourceDescription={`${consultant.name}'s Shipping address`}
                                                         defaultValues={{ auditableId: address?.id, auditable: 'shipping_address', format: 'json' }}/>}
      <Modal
        destroyOnClose={true}
        title={!!address ? "Edit Shipping Address" : "Add Shipping Address"}
        open={shippingForm}
        onOk={handleShippingCancel}
        onCancel={handleShippingCancel}
        closeIcon={modalClose(handleShippingCancel)}
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(() => handleShippingCancel(), 'Cancel', isExecuting),
            formSubmitButton('shippingForm', 'Save', isExecuting, 'Saving')])}
        </div>]}>
        <ShippingForm consultant={consultant} setisExecuting={setisExecuting} address={address} editingForm={editingForm}
                      closeModal={handleShippingCancel}/>
      </Modal>
      <div className="flex-boy-row-space" style={{ width: "100%" }}>
        <Headline6>Shipping Address</Headline6>
        {((can_update_consultant_global || consultant.user_id === activeUser.id) && !consultant.deleted_at) ? addButton(() => {
          setEditingForm(false)
          setAddress(null)
          setShippingForm(true);
        }) : null}
      </div>
      {navs.shippingAddressesLoading ? skelBars() : <>
        {sortedAddresses.length ? sortedAddresses.map((address, index) => {
          return (<div key={index} style={{ width: "100%" }}>
            {address.default && <div style={{ textAlign: 'left' }}>
              <Tag style={{ textAlign: 'left' }} color="#108ee9">Default</Tag></div>}
            <div className="flex-boy-row-space" style={{ width: "100%" }}>
              <Subtitle2 style={{ fontSize: "1.1em", textAlign:'left' }} color="black">{address.physical_address ? `${address.physical_address}-` : null}{address.street_address}</Subtitle2>
              {(can_update_consultant && !consultant?.deleted_at) &&
                /*<Dropdown dropdownRender={()=>menu(address)} trigger={["click"]} disabled={selected === address.street_address}>
                  {appConfirm(
                    <MoreOutlined />, selected === address.street_address, null, 'Are you sure of deleting this address?', () => handleOk(address.id), handleCancel, confirmLoading)}
                </Dropdown>*/
                
                <KebabMenu
                  menus={actionMenus}
                  recordItem={address}
                  handleMenuClick={kebabMenuClick}
                  resource={'address'} handleOk={() => handleOk(address.id)}
                  handleCancel={handleCancel}
                  confirmLoading={confirmLoading}
                  showConfirmDialog={selected === address.street_address}/>
              }
            </div>
            <Divider/>
          </div>)
        }) : <div style={{ width: "100%" }}>
          <div style={{ textAlign: 'left' }}>
            <Tag style={{ textAlign: 'left' }} color="#108ee9">Default</Tag></div>
          <div className="flex-boy-row-space" style={{ width: "100%" }}>
            <Subtitle2 style={{ fontSize: "1.1em" }} color="black">{consultant.address_1}</Subtitle2>
            {/* {can_update_consultant &&
              <Dropdown dropdownRender={()=>menu(address)} trigger={["click"]} disabled={selected === consultant.address}>
                {appConfirm(
                  <MoreOutlined />, selected === consultant.address, null, 'Are you sure of deleting this address?', () => handleOk(address.id), handleCancel, confirmLoading)}
                </Dropdown>
              } */}
          </div>
          <Divider/>
        </div>}
      </>}
    </div>)
  );
};
const mapStateToProps = (state) => ({ activeUser: state.auth?.activeUser, consultant: state.consultants.selectedConsultant, shippingAddresses: state.patients.shippingAddresses, navs: state.navs })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ConsultantShippingAddress)
