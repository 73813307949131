/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Body2, Headline6, Subtitle1} from '../../../../components'
import HeaderTop from '../../../../components/HeaderTop'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Avatar, Breadcrumb, Image, Popover} from 'antd';
import {apiRequest} from '../../../../app/Apis'
import dayjs from 'dayjs'
import {fetchLabRegions, fetchProcedures, getWoundEncounters, getWoundPlanofcares, loadWoundLocations, updatePatientAssessmentFilters} from "../../../../app/Reducers";
import {toast} from "react-toastify";
import {
  appLog,
  containedButton,
  dialogCloser,
  emptyFun,
  executeChunkFn,
  getAssessmentStatus,
  getHeaderLeftNav,
  getWoundStatus,
  horStack,
  makeAutoCompleteOptions,
} from "../../../../common/helpers";
import EditAssessmentModal from "../../../Appointments/components/EditAssessmentModal";
import {genAllograftReport, genReport, genWoundsReport} from "../../../../app/Apis/commonApi";
import VisitDetails from "../../../Appointments/components/VisitDetails";
import VisitStatus from "../../../Appointments/components/VisitStatus";
import PdfViewer from "../../../../components/PdfViewer";
import WoundDetails from '../../../Facilities/components/Wound/WoundDetails'
import AuditLogViewer from "../../../../components/AuditLogs/AuditLogViewer";
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
import {HistoryOutlined} from '@ant-design/icons';
import {useProcedureSearch} from '../../../../hooks/useProcedureSearch';
import {toastUp} from '../../../../app/Reducers/reducerUtils';

const WoundPage = ({ wound, patient, appConf, plansOfCare }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [woundAssessment] = useState(null)
  const [progress, setProgress] = useState([])
  const can_view_plan_of_care_summary = usePermissionCheck('view_plan_of_care_summary', false, [])
  const can_print_plan_of_care_summary_report = usePermissionCheck('print_plan_of_care_summary_report', false, [])
  
  const [VisitModal, setVisit] = useState(false)
  const [visitStatusModal, setStatusModal] = useState(false)
  const [editassessmentModal, seteditassessmentModal] = useState(false)
  const [isExecuting, setisExecuting] = useState(false)
  const [isPrinting, setPrinting] = useState(false)
  const [isAllograftPrinting, setAllograftPrinting] = useState(false)
  const [isWoundsProgressPrinting, setWoundsProgressPrinting] = useState(false)
  const [pdfShowing, setPdfShowing] = useState(false);
  const [pdfLoading, setLoading] = useState(false);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [pdf, setPdf] = useState(null);
  let defParam = { filterData: { wound: wound?.id, patient: patient?.id } }
  const [procedureOptions, debouncedProcedureSearch, procedureSearchLoading] = useProcedureSearch([], 'name')
  const [planOfCareModal, setPlanOfCareModal] = useState(false)
  
  /*useEffect(() => {
    (async () => {
      await dispatch(updatePatientAssessmentFilters([appConf.patientAssessmentFilters[0], appConf.patientAssessmentFilters[1], true]))
      await dispatch(getWoundEncounters(appConf.patientAssessmentFilters[0] ? appConf.patientAssessmentFilters[1] : defParam));
      await dispatch(updatePatientAssessmentFilters([appConf.patientAssessmentFilters[0], appConf.patientAssessmentFilters[1], false]))
    })().catch(appLog)
    return emptyFun
  }, [encountersList])*/
  
  const handlevisitOk = () => setVisit(false)
  const handlevisitCancel = () => setVisit(false)
  
  const approveAsessment = async () => {
    await setisExecuting(true)
    const toastId = toast("Updating Assesment", { type: "info", autoClose: false });
    const response = await apiRequest.post(`/assessments/${woundAssessment.id}/status`, { status: "approved" }).catch(async function (err) {
      await setisExecuting(false)
      appLog(err.response.data.message)
      toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
      await dispatch(getWoundEncounters(appConf.patientAssessmentFilters[0] ? appConf.patientAssessmentFilters[1] : defParam))
    })
    if (response && response.data) {
      toast.update(toastId, { render: "Assessment Approved", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      await dispatch(getWoundEncounters(appConf.patientAssessmentFilters[0] ? appConf.patientAssessmentFilters[1] : defParam))
    }
    await setisExecuting(false)
    setVisit(false)
    setStatusModal(false)
  }
  const generateReport = () => genReport(woundAssessment, setPrinting, setVisit, setStatusModal, setPdf, setLoading, setPdfShowing)
  const generateAllograftReport = () => genAllograftReport(woundAssessment, setAllograftPrinting, setVisit, setStatusModal, setPdf, setLoading, setPdfShowing)
  const genWoundProgressReport = () => genWoundsReport(wound, setWoundsProgressPrinting, setStatusModal, setPdf, setLoading, setPdfShowing)
  
  useEffect(() => {
    
    dispatch(fetchProcedures())
    dispatch(fetchLabRegions())
    dispatch(loadWoundLocations())
    return emptyFun
  }, [])
  
  useEffect(() => {
    fetchProgress().catch(appLog)
    if (can_view_plan_of_care_summary) {
      executeChunkFn(dispatch, getWoundPlanofcares, appConf.patientAssessmentFilters[0] ? appConf.patientAssessmentFilters[1] : defParam, setisExecuting, undefined, undefined).catch(appLog)
    }
    return emptyFun
  }, [wound])
  
  const fetchProgress = async () => {
    const response = await apiRequest.get(`/wounds/${wound.id}/progress`)
    if (response && response.data) {
      setProgress(response.data.data.map(item => ({
        name: dayjs(item.assessment_date).format("YYYY-MM-DD"), Depth: !!item.depth ? item.depth : 0,
        Width: !!item.width ? item.width : 0, Length: !!item.length ? item.length : 0,
        Area: !!item.area ? item.area : 0, Volume: !!item.volume ? item.volume : 0,
        ...item
      })))
    }
  }
  const columns = [{
    title: 'Provider', dataIndex: ['appointment', 'consultant', 'name'], key: 'provider',
    fltr: { filterable: true, type: 'text' }, render: (name) => (<>
      <div className="flex-boy-row-space" style={{ justifyContent: 'normal' }}>
        <Avatar src={<Image src={require("../../../../assets/user.png")}/>}/>
        <Subtitle1 style={{ textAlign: "left", marginLeft: '10px' }}>{name}</Subtitle1>
      </div>
    </>)
  }, {
    title: 'Initial date', dataIndex: 'created_at', key: 'created_at', fltr: { filterable: true, type: 'date' },
    render: (start_at) => (
      <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(start_at).format("MM/DD/YYYY")}</Subtitle1>)
  }, {
    title: 'Appointment date', dataIndex: ['appointment', 'start_at'], key: 'start_at', fltr: { filterable: true, type: 'date' },
    render: (start_at) => (
      <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(start_at).format("MM/DD/YYYY")}</Subtitle1>)
  }, {
    title: 'Last visited', dataIndex: ['appointment', 'start_at'], key: 'start_at', fltr: { filterable: true, type: 'date' },
    render: (start_at) => (
      <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(start_at).format("MM/DD/YYYY")}</Subtitle1>)
  }, {
    title: 'Wound', key: 'wound', fltr: { filterable: false, type: 'date' },
    render: () => (
      <Subtitle1 style={{ textAlign: 'left' }}> {wound?.side} {wound?.position} {wound?.location}</Subtitle1>)
  }, {
    title: 'Assessment Status', dataIndex: 'review', fltr: {
      filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(['rejected', 'approved', 'pending_review'])
    }, key: 'reviewStatus', render: (review) => getAssessmentStatus(review?.status?.toLowerCase())
  },  {
    title: 'Procedure Type', dataIndex: 'procedure', key: 'procedure', fltr: {
      filterable: true, type: 'select', options: procedureOptions,
      props: {
        loading: procedureSearchLoading,
        onSearch: (value) => debouncedProcedureSearch(value), showSearch: true, filterOption: false
      }
    }, render: (recommended_procedure) => {
      if (recommended_procedure && Array.isArray(recommended_procedure) && recommended_procedure.length > 0) {
        return recommended_procedure.join(', ')
      } else {
        return recommended_procedure
      }
    }
  }, {
    title: 'Wound Status', fltr: { filterable: false }, key: 'woundStatus',
    render: () => wound?.status
  }, {
    title: <FontAwesomeIcon color="grey" icon={["fas", "table"]}/>, key: 'operation', width: 70
    // render: () => <a>action</a>,
  }]
  
  
  const [isVisible, setIsVisible] = useState(false);
  
  async function handleFilterAction(action, values) {
    if (action === 'filter') {
      appLog('FILTERS:  ', JSON.stringify({ ...values, toLoad: 'assessments', endpoint: '/assessments?wound=1' }));
      let pl = { filterData: { ...values, ...defParam.filterData } }
      await dispatch(updatePatientAssessmentFilters([false, pl, true]))
      await dispatch(getWoundEncounters(pl));
      await dispatch(updatePatientAssessmentFilters([true, pl, false]))
    }
    if (action === 'reset'/* && appConf.patientAssessmentFilters[0]*/) {
      await dispatch(updatePatientAssessmentFilters([appConf.patientAssessmentFilters[0], undefined, true]))
      await dispatch(getWoundEncounters(defParam));
      await dispatch(updatePatientAssessmentFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  
  const declineAssessment = async (payload) => {
    await setisExecuting(true)
    const toastId = toast("Updating Assessment", { type: "info", autoClose: false });
    let data = { notes: payload.notes, status: "rejected" }
    const response = await apiRequest.post(`/assessments/${woundAssessment.id}/status`, data).catch(async function (err) {
      await setisExecuting(false)
      appLog(err)
      toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
      await dispatch(getWoundEncounters(appConf.patientAssessmentFilters[0] ? appConf.patientAssessmentFilters[1] : defParam));
    })
    if (response && response.data) {
      toast.update(toastId, { render: "Assessment Rejected", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      await dispatch(getWoundEncounters(appConf.patientAssessmentFilters[0] ? appConf.patientAssessmentFilters[1] : defParam));
    }
    await setisExecuting(false)
    setVisit(false)
    setStatusModal(false)
  }
  const generateLabReport = (report) => {
    setPdf(report)
    setPdfShowing(true)
  }
  
  return (<div className="main-page">
    {(!!woundAssessment && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} resourceType={'Assessment'}
                                                               loading={false} resourceDescription={`${woundAssessment.appointment.patient.name}'s ${woundAssessment.procedure} assessment`}
                                                               defaultValues={{ auditableId: woundAssessment?.id, auditable: 'assessment', format: 'json' }}/>}
    <PdfViewer pdf={pdf} setLoading={setLoading} loading={pdfLoading} onCancel={() => setPdfShowing(false)} visible={pdfShowing}/>
    <HeaderTop child={getHeaderLeftNav(navigate, 'Wound Details')}/>
    {woundAssessment &&
      <EditAssessmentModal editassessmentModal={editassessmentModal} seteditassessmentModal={seteditassessmentModal}
                           isExecuting={isExecuting} setVisit={setVisit} setisExecuting={setisExecuting}
                           sellectedVisit={woundAssessment}/>}
    <VisitStatus visitStatusModal={visitStatusModal} setStatusModal={setStatusModal} isExecuting={isExecuting} declineAssessment={declineAssessment}/>
    {(VisitModal && !!woundAssessment) &&
      <VisitDetails setShowAuditDialog={setShowAuditDialog} assessment={woundAssessment} setVisit={setVisit} approveAsessment={approveAsessment}
                    VisitModal={VisitModal} seteditassessmentModal={seteditassessmentModal}
                    isExecuting={isExecuting} isPrinting={isPrinting}
                    setStatusModal={setStatusModal} handlevisitOk={handlevisitOk}
                    handlevisitCancel={handlevisitCancel}
                    generateReport={generateReport}
                    isAllograftPrinting={isAllograftPrinting}
      generateAllograftReport={generateAllograftReport}
      generateLabReport={generateLabReport}
                    genWoundsReport={genWoundsReport} isWoundsProgressPrinting={setWoundsProgressPrinting}
      />}
    <div className="page-header">
      <div className="flex-boy-row">
        <Breadcrumb>
          <Breadcrumb.Item>Patient List</Breadcrumb.Item>
          <Breadcrumb.Item>{patient?.name}</Breadcrumb.Item>
          <Breadcrumb.Item>Wound Details</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="flex-boy-row-space">
        <div>
          <Headline6>{patient?.name}</Headline6>
          <Body2 color="grey">{wound?.side} {wound?.position} {wound?.location} </Body2>
        </div>
        <div>
          {horStack([
            getWoundStatus(wound?.status?.toLowerCase()),
          
            ((!progress[0] && can_print_plan_of_care_summary_report) ?
              <Popover content={<Body2 color="crimson">Wound doesn't have enough visits to generate a report.</Body2>} title="" trigger="click">
                {containedButton(null, 'Generate Report', isWoundsProgressPrinting, 'Printing', null, { icon: <HistoryOutlined /> })}
              </Popover> :
              (!plansOfCare?.[0] && can_print_plan_of_care_summary_report) ?
                  containedButton(async () => {
                    await toastUp('Wound has no plan of care. Please create a plan of care to generate a report.')
                    setPlanOfCareModal(true)
                  }, 'Generate Report', isWoundsProgressPrinting, 'Printing', null, { icon: <HistoryOutlined /> })
                :
                can_print_plan_of_care_summary_report ? containedButton(genWoundProgressReport, 'Generate Report', isWoundsProgressPrinting, 'Printing', null, {
                  icon: <HistoryOutlined />
                }) : null)
            // containedButton(genWoundProgressReport, 'Generate Report', isWoundsProgressPrinting, 'Printing', null, {icon: <HistoryOutlined />})
          ])}
        </div>
      </div>
    
    </div>
    <div className="main-body" style={{ padding: "10px" }}>
      <WoundDetails planOfCareModal={planOfCareModal} setPlanOfCareModal={setPlanOfCareModal}/>
    </div>
  </div>)
}
const mapStateToProps = (state) => ({
  wound: state.patients.selectedWound,
  patient: state.patients.selectedPatient,
  // encountersList: state.appointments.encounters,
  appConf: state.appConf, woundEncounters: state.patients.woundEncounters,
  navs: state.navs,

  plansOfCare: state.patients.woundPlansOfCare
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(WoundPage)
