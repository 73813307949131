/* eslint-disable no-unused-vars */
import styled from 'styled-components'
import { Typography } from 'antd';

const { Title, Paragraph, Text } = Typography;
export const Primary = "#4B9BFF"
export const Secondary = "#0E153A"
export const PrimaryGrey = "#E0E0E0"
export const PrimaryRed = "#EF8100;"
export const Plus = styled.div`
  width: ${props => props.height || "300px"};
  height: ${props => props.height || "300px"};
  top: ${props => props.position.top + "%" || 0};
  left: ${props => props.position.left + "%" || 0};
`
export const ThickHeader = styled(Title)`
  font-family: 'Lato', cursive;
  color: ${props => props.color || "black"} !Important;

  font-style: normal;
  font-weight: normal;
  font-size: 6vh;
  line-height: 56px;
`
export const BodyText = styled(Paragraph)`

  color: ${props => props.color || "black"} !Important;


  font-family: 'Lato', sans-serif;

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
`
export const Headline1 = styled.h1`
  font: normal normal 96px/112px Lato;
  letter-spacing: 0px;
  color: ${props => props.color || Secondary};
  opacity: 1;;
  margin: 0px
`;
export const Headline2 = styled.h2`
  font: normal normal 60px/72px Lato;
  letter-spacing: -0.48px;
  color: ${props => props.color || Secondary};
  opacity: 1;;
  margin: 0px
`;
export const Headline3 = styled.h3`
  font: normal normal 48px/56px Lato;
  letter-spacing: 0px;
  color: ${props => props.color || Secondary};
  opacity: 1;;
  margin: 0px
`;
export const Headline4 = styled.h4`
  font: normal normal 34px/36px Lato;
  letter-spacing: 0px;
  color: ${props => props.color || Secondary};
  opacity: 1;;
  margin: 0px;
`;
export const Headline5 = styled.h5`
  font: normal normal 24px/24px Lato;
  letter-spacing: 0.18px;
  color: ${props => props.color || Secondary};
  opacity: 1;
  margin: 0px;
`;
export const Headline6 = styled.h6`

  color: ${props => props.color || Secondary};
  opacity: 1;
  margin: 0px;
  //styleName: 18/Bold;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;;
`;
export const Subtitle1 = styled.p`


  //styleName: 14/Bold;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;


  text-align: center;
  color: ${props => props.color || Secondary};
  opacity: 1;
  margin: 0px;

`;
export const Subtitle2 = styled.p`
  font-size: 12px;

  letter-spacing: 0.1px;
  font-family: 'Lato';
  color: ${props => props.color || Secondary};
  opacity: 1;
  margin: 0px;;
`;
export const Subtitle2span = styled.span`
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.1px;
  font-family: 'Lato';

  color: ${props => props.color || '#92929D'};
  opacity: 1;
  margin: 0px;;
`;
export const Body1 = styled.p`
  color: ${props => props.color || Primary};
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;
export const Body2 = styled.p`

  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: ${props => props.color || Primary};
  opacity: 1;
  margin: 0px;
`;
export const MapStatusCircle = styled.div`
  height: 30px;
  width: 30px;
  font-size: 1rem;
  position: relative;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  color: ${props => props.color || "#5999F1"};
`
export const MapCounterCircle = styled.div`
  width: 64px;
  height: 64px;
  background: #3D5AF1;
  border-radius: 50%;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center
`
export const ConsultantImage = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 50%;

  padding: 3px;
  background: url(${props => props.image}) no-repeat top center;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const RegularButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  flex: none;
  order: 0;
  border: 1px solid ${props => props.borderColor || "transparent"};;
  color: ${props => props.color || "white"};
  height: ${props => props.height === "regular" ? "40px" : "32px"};
  flex-grow: 0;
  margin: 0px 0px;
  background: ${props => props.background || PrimaryRed};
  border-radius: 4px;
  cursor: pointer;
`
export const RegularButtonCircle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: ${props => props.height || "40px"};
  width: ${props => props.height || "40px"};
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  background: ${props => props.color || PrimaryRed};
  border: 1px solid ${props => props.borderColor || "transparent"};;
  border-radius: 50%;
  cursor: pointer;

`
export const Avatar = styled.div`
  height: ${props => props.height || "32px"};
  width: ${props => props.height || "32px"};
  border-radius: 50%;
  background: url(${props => props.img})
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`
export const AppointmentCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;
  margin-bottom: 10px;
  background: #FFFFFF;
  border-radius: 4px;
  width: 100%;
  min-height: 65px;
  height: fit-content;
  left: 4px;
  top: 0px;
  margin-right: 10px;
  border-left: 5px solid ${props => props.color || Primary};
  box-shadow: 0px 1px 4px rgba(39, 40, 51, 0.2);
`
export const Indicator = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: ${props => props.color || Primary};
  border: ${props => `${props.color}33` || Primary} 5px solid
`
export const PatientMarker = styled.div`
  height: 40px;
  width: 40px;
  font-size: 1rem;
  position: relative;
  border-radius: 50%;
  background: ${props => props.color || Primary};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%)
`
export const DotCircle = styled.div`
  background: ${props => props.color || Primary};
  box-sizing: content-box;
  height: ${props => props.size || '10px'};
  width:  ${props => props.size || '10px'};
  border-radius: 50%;
  border: ${props => props.color || Primary} 5px solid;
`