/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { RegularButton, Subtitle1 } from '../../../components/general'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Table } from "antd"
import NewOrderCard from './NewOrderCard'
import { addButton, emptyTable, getSkels, modalClose, outlinedButton, stateFilter } from "../../../common/helpers";
import TableFilters from "../../../components/InputComponents/TableFilters";
import {useLocation, useNavigate} from 'react-router-dom';

export const Supplies = () => {
  const location = useLocation()
  const navigate = useNavigate();
  const columns = [{
    title: 'DOS', dataIndex: 'dos', key: 'dos', fltr: { filterable: true, type: 'date' }
  }, {
    title: 'Location', dataIndex: 'location', key: 'location', fltr: { filterable: true, type: 'text' }
  }, {
    title: 'Office', dataIndex: 'facility', key: 'facilty', fltr: { filterable: true, type: 'text' }
  }, {
    title: 'Order Status', dataIndex: 'status', key: 'status', fltr: { filterable: true, type: 'text' },
    render: (status) => (<>
      {status === "improving" ? <div className="status-container">
        <div className="dot-circle-active"></div>
        <Subtitle1>Waiting</Subtitle1>
      </div> : <div className="status-container">
        <div className="dot-circle-inactive"></div>
        <Subtitle1>WareHouse alert</Subtitle1>
      </div>}
    </>)
  }, {
    title: <FontAwesomeIcon color="grey" icon={["fas", "table"]} />, key: 'operation', fixed: 'right', width: 70
    // render: () => <a>action</a>,
  }]
  const data = [{
    "key": 19, "name": "Edrward 19", "location": "Arm", "facility": "London Park no. 19", "stage": "III",
    "dos": "12/7/2021", "status": "improving"
  }, {
    "key": 20, "name": "Edrward 20", "location": "Arm", "facility": "London Park no. 20", "stage": "III",
    "dos": "12/7/2021", "status": "improving"
  }, {
    "key": 21, "name": "Edrward 21", "location": "Arm", "facility": "London Park no. 21", "stage": "III",
    "dos": "12/7/2021", "status": "closed"
  }, {
    "key": 22, "name": "Edrward 22", "location": "Arm", "facility": "London Park no. 22", "stage": "III",
    "dos": "12/7/2021", "status": "closed"
  }]
  const [isVisible, setIsVisible] = useState(false);

  function handleFilterAction(action, values) {
    //todo: static data
    setIsVisible(prevState => !prevState)
  }

  const [orderForm, setOrderForm] = useState(false)
  const handleOrderOk = () => {
  }
  const handleOrderCancel = () => {
    setOrderForm(false)
  }
  return (
    (<div>
      <Modal
        title="New Order-Black,Marvin"
        open={orderForm}
        onOk={handleOrderOk}
        onCancel={handleOrderCancel}
        closeIcon={modalClose(handleOrderCancel)}
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {outlinedButton(() => setOrderForm(prevState => !prevState), 'Cancel')}
          <RegularButton onClick={() => setOrderForm(prevState => !prevState)}>Create</RegularButton>
        </div>]}>
        <NewOrderCard />
      </Modal>
      <div className="w-full">
        <Table
          title={() => (<TableFilters datasource={[...columns, stateFilter]} setIsVisible={setIsVisible}
            isVisible={isVisible} actionButton={addButton(() => setOrderForm(true))}
            handleFilterAction={handleFilterAction} />)}
          size={'small'} locale={{ emptyText: emptyTable('orders yet.') }} onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                navigate("/patients/")
              }, // click row
              onDoubleClick: event => {
              }, // double click row
              onContextMenu: event => {
              }, // right button click row
              onMouseEnter: event => {
              }, // mouse enter row
              onMouseLeave: event => {
              } // mouse leave row
            };
          }}
          {...getSkels((false), columns, data)} scroll={{ x: 1000 }} />
      </div>
    </div>)
  );
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Supplies)
