import {useEffect, useState} from "react";
import {useSelector} from 'react-redux';
import {emptyFun} from '../common/helpers';

export const usePermissionCheck = (permission, checkForAll, deps) => {
  const [isGranted, setGranted] = useState(false);
  const permissions = useSelector(state => state.auth?.permissions).map(item => item.name)
  // console.log({permissions})
  useEffect(() => {
    // console.log('usePermissionCheck')
    let allowed
    if (typeof permission === 'string') {
      allowed = permissions.includes(permission)
    } else {
      allowed = (checkForAll && Array.isArray(permission)) ? permission.every(item => permissions.includes(item)) : permission.some(item => permissions.includes(item))
    }
    // console.log({ allowed })
    setGranted(allowed)
    return emptyFun
  }, [deps,permissions]);
  return isGranted
};