import { Divider, Empty, Spin } from "antd";
import { useDispatch } from "react-redux";
import { Headline6 } from "../../../components";
import { PatientStatsChart } from "../../Facilities/utils";
import { emptyFun, getPatientsBarColorStatus, sentenceCaseNoDash } from "../../../common/helpers";
import { useEffect, useState } from "react";
import { apiRequest } from "../../../app/Apis";
import { fetchPatientStatistics } from "../../../app/Reducers";

export default function PatientsChart({ patients, region, loadingPatients }) {
  const [patientData, setPatientData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(null)
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => { })(
      dispatch(fetchPatientStatistics(region))).catch(e => console.log(e))
  }, [region])

  useEffect(() => {
    if (patients) {
      let tot = patients.reduce((acc, curr) => acc + curr.count, 0)
      setTotal(tot)
    }
    return emptyFun
  }, [patients])

  return (
    <div className="stats-card-box w-full">
      <div style={{ width: '100%', padding: '5px' }}>
        <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>All Patients</Headline6>
        <Divider style={{ margin: '4px', color: '#E2E4EA', width: '100%' }} />
      </div>
      <div style={{ width: '100%', padding: '15px' }}>
        <Headline6 style={{ fontWeight: 'bold', fontSize: '16px' }}>Total Patients</Headline6>
        <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>{total}</Headline6>
      </div>
      {patients?.length > 0 ?

        <div style={{ width: '100%' }}>
          {patients?.map((patient) => (
            <PatientStatsChart title={sentenceCaseNoDash(patient?.status)} percentage={patient?.percentage} color={getPatientsBarColorStatus(sentenceCaseNoDash(patient?.status))} total={patient?.count} loading={loadingPatients} />
          ))}
        </div> :
        <div style={{ width: '100%', padding: '15px' }}>
          <Empty description="No Patient Statistics" />
        </div>
      }
    </div>
  )
}
