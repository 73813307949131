/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Body2, Headline6, Subtitle1, RegularButton, Body1} from '../../../components'
import {Col, Divider, Form, Modal, Row, AutoComplete, Select, Input, Button, Dropdown, Spin, Menu, Switch, Checkbox} from 'antd';
import dayjs from 'dayjs';
import {DeleteOutlined, LoadingOutlined, PlusOutlined} from '@ant-design/icons';

import {
    emptyFun,
    horStack,
    boldKeyCapitalizedValue,
    modalClose,
    outlinedButton,
    addButton,
    getOrderStatus,
    formSubmitButton,
    dialogCloser,
    secondaryButton,
    getOrderAppliedStatus
} from "../../../common/helpers";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {errMsg, getToastId, toastUp, updateToast} from "../../../app/Reducers/reducerUtils";
import {fetchSpecificOrder, resetPatientModuleState, selectAssessment, selectPatient, updateOrder} from '../../../app/Reducers';
import OrderNotes from '../../Orders/components/OrderDetails/OrderNotes'
import ShippingAddress from '../../Orders/components/OrderForms/ShippingAddress';
import {resetPatientNavs} from '../../../app/Reducers';

import EditAssessmentCard from '../../Appointments/components/EditAssessmentCard';
import _ from 'lodash'
import * as Sentry from "@sentry/react";
import {apiRequest} from '../../../app/Apis';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import GetCookie from '../../../hooks/GetCookie';
import SetCookie from '../../../hooks/setCookie';
import {useLocation, useNavigate} from 'react-router-dom';


export const CreateOrder = ({ order, patient, consultant, navs, masterList, isExecuting, setisExecuting, setpreviewOpen, loadOrders }) => {
    const { Option } = Select;
    const location = useLocation()
    const navigate = useNavigate();
    const treatmentRef = useRef()
    const [initialValues, setinitialValues] = useState({ ...order })
    const [form] = Form.useForm();
    const [treatmentOptions, setOptions] = useState([])
    const [moreNotes, setmoreNotes] = useState(false)
    const dispatch = useDispatch()
    const [consultantOptions, setWccOptions] = useState([])
    const can_update_treatment_order = usePermissionCheck('update_treatment_order', false, [])
    const [treatmentorderItems, setTreatmentorderItems] = useState(order?.dme_supplies.map(item => {
        return { units: item.unit_count }
    }))
    const [newTreatmentSupply, setnewTreatmentSupply] = useState(false)

    const [treatmentSupplies, setTreatmentSupplies] = useState([])

    const [validationerrors, setvalidationerrors] = useState({
        supplyItems: false,
        delivery_location: false
    })
    const onFinish = async (values) => {


        let bioKits = []
        for (const item of assessmentKits) {
            bioKits = [...bioKits, ...item.biologic_kits.map(kit => ({
                id: kit.id,
                biologic_product_id: biologicsProducts.find(product => `${product.company_name} ${product.product_name}` === item.product).id,
                quantity: kit.quantity,

            }))]
        }

        console.log(values, bioKits)

        let payload = {
            ...order,
            ...values,

            consultant_id: order?.consultant.id,


            // date_of_service: dayjs().format("YYYY-MM-DD HH:mm:ss"),




        }
        if (order?.visit_type === "Internal") {
            if (order?.internal_order_resource_type === "DME") {
                payload.dme_supplies = treatmentorderItems.map(item => {
                    return (
                        {
                            id: item.id,
                            unit_count: item.unit_count,
                            pack_amount: item.pack_amount || item.default_pack_amount,
                            type: "Primary Dressing"
                        }
                    )
                })

            } else {
                payload.ppe_kits = ppetreatmentorderItems.map(item => {
                    return (
                        {
                            id: item.id,
                            unit_count: item.unit_count,
                        }
                    )
                })
            }

        }

        console.log(payload)

        setisExecuting(true)
        await dispatch(updateOrder(_.omitBy(payload, v => _.isNil(v) || (_.isArray(v) && !v.length))))
        loadOrders()
        setisExecuting(false)
        setpreviewOpen(false)
    }

    // const [order, setorder] = useState(dummy_order)
    const [treatmentprotocol, settreatmentprotocol] = useState({ protocol_name: order?.treatement_protocol })


    // treatment supplies
    const [changeProtocol, setchangeProtocol] = useState(false)
    const fetchtreatmentProtocol = async (id) => {
        try {
            const { data } = await apiRequest.get(`/treatment-scenarios/${id}`)
            if (data) {
                let item = data.data
                settreatmentprotocol({ ...item, protocol_name: item.treatment_code ? `${item.scenario_number} ${item.scenario_number ? "-" : null} ${item.treatment_code}-${item.protocol_name}` : `${item.scenario_number} ${item.scenario_number ? "-" : null}${item.protocol_name}` })

                setTreatmentorderItems(data.data.treatment_order_items.map(item => ({ ...item })))
            }
        } catch (e) {
            Sentry.captureException(e);
            console.log(e)
            toastUp(errMsg(e, true, 'treatment scenarios'), false)
        }
    }
    const handlesupplysearch = async (value) => {
        const { data } = await apiRequest.get(`/treatment-supplies?search=${value}`)
        if (data) {
            console.log(data.data)
            setTreatmentSupplies(data.data);
        }
    };
    const onsupplySelect = (value) => {
        console.log('onSelect', value);
        let supply = treatmentSupplies.find(item => item.name === value)
        supply.units = supply.unit_count
        let supplies = [...treatmentorderItems]
        supplies.push(supply)
        setTreatmentorderItems(supplies)
        setnewTreatmentSupply(false)
    };
    const removeTreatmentItem = (index) => {
        let supplies = [...treatmentorderItems]
        supplies.splice(index, 1);
        setTreatmentorderItems(supplies)
    }
    const unitChange = (item, index, value) => {

        let supplies = [...treatmentorderItems]
        let idx = supplies.findIndex(obj => obj.id === item.id)
        console.log(supplies[idx])
        supplies[idx].unit_count = value
        setTreatmentorderItems(supplies)
    }

    // PPE treatment supplies
    const [ppetreatmentorderItems, setppeTreatmentorderItems] = useState(Array.isArray(order?.ppe_kits) ? [...order?.ppe_kits] : [])
    const [newppeTreatmentSupply, setnewppeTreatmentSupply] = useState(false)
    const [newppePlanofCare, setnewppePlanofCare] = useState(false)
    const [ppetreatmentSupplies, setppeTreatmentSupplies] = useState([])


    const handleppesupplysearch = async (value) => {
        const { data } = await apiRequest.get(`/ppe-kits?search=${value}`)
        if (data) {
            console.log(data.data)
            setppeTreatmentSupplies(data.data);
        }
    };
    const onppesupplySelect = (value) => {
        console.log('onSelect', value);
        let supply = ppetreatmentSupplies.find(item => item.name === value)
        let supplies = [...ppetreatmentorderItems]
        supplies.push(supply)
        setppeTreatmentorderItems(supplies)
        setnewppeTreatmentSupply(false)
    };
    const removeppeTreatmentItem = (index) => {
        let supplies = [...ppetreatmentorderItems]
        supplies.splice(index, 1);
        setppeTreatmentorderItems(supplies)
    }
    const ppeunitChange = (item, index, value) => {
        let supplies = [...ppetreatmentorderItems]
        supplies[index].unit_count = value
        setppeTreatmentorderItems(supplies)
    }



    //  map stuff
    const [viewport, setViewport] = useState({ latitude: 37.7577, longitude: -122.4376, zoom: 8 });
    const [locationData, setLocationData] = useState(null)



    // biologic products
    const [biologicsProducts, setbiologicsProducts] = useState([])
    const [assessmentKits, setAssessmentKits] = useState([]);
    const [selectableProducts, setSelectableProducts] = useState([]);
    const [canAdd, setCanAdd] = useState(true);
    const [formValues, setsFormValues] = useState({ ...order })
    useEffect(() => {
        changeSelectableProducts()
        return emptyFun
    }, [assessmentKits])

    useEffect(() => {
        getStatuses()
        setTreatmentorderItems(order?.dme_supplies.map(item => { return { ...item, units: item.unit_count } }))
        setsFormValues({ ...order })
        setinitialValues({ ...order })
        if (order?.visit_type === "Biologics") {
            fetchBiologicsKit()
        } else {
            form.setFieldsValue({ ...order })
        }

        return emptyFun

    }, [order])

    useEffect(() => {
        fetchBiologicsKit()
        return emptyFun
    }, [])

    const fetchBiologicsKit = async () => {
        console.log(" 😎getting")
        const { data } = await apiRequest.get(`/biologic-products`)
        if (data) {
            await setbiologicsProducts(data.data)
            await setSelectableProducts(data.data.map(item => `${item.company_name} ${item.product_name}`))
            if (order?.biologic_kits?.length) {
                let productNames = []
                let assessmentKits_ = []
                for (const bioKit of order?.biologic_kits) {
                    let name = `${bioKit.biologic_product.company_name} ${bioKit.biologic_product.product_name}`
                    if (!productNames.includes(name)) {
                        let productKits = order?.biologic_kits.filter(item => `${item.biologic_product.company_name} ${item.biologic_product.product_name}` === name)
                        let biologic_kits = productKits.map(item => ({ id: item.id, size: item.size, quantity: item.quantity }))
                        assessmentKits_ = [...assessmentKits_, { product: name, biologic_kits }]
                        productNames.push(name)
                    }
                }
                console.log("🏥", assessmentKits_,)
                await setAssessmentKits([...assessmentKits_])
                setsFormValues(prevState => {
                    prevState.kits = assessmentKits_
                    return { ...order }
                })
                form.setFieldsValue({ ...order, kits: assessmentKits_ })
            }
        }
    }


    const [availableStatuses, setavailableStatuses] = useState([])
    const getStatuses = async () => {
        let data = await apiRequest.get(`/treatment-order-statuses?order_state=${order?.order_state?.replace(/\s+/g, '-')}&current_order_status=${order?.status}`).catch(e => console.log(e))
        if (data && data.data) {
            setavailableStatuses(data.data.data)
        }
    }

    const changeSelectableProducts = async () => {
        let selectedProducts = !!assessmentKits[0] ? assessmentKits.map(item => item.product) : []
        let selectables = biologicsProducts.filter(item => !selectedProducts.includes(`${item.company_name} ${item.product_name}`))
        await setSelectableProducts(selectables.map(item => `${item.company_name} ${item.product_name}`))
    }

    const getBiologicKits = (index) => {
        let kit = biologicsProducts.find(item => `${item.company_name} ${item.product_name}` === assessmentKits[index].product)
        return !!kit ? kit.kits : null
    }
    const canAddKits = (bioKits, index) => {
        let selectedKits = assessmentKits[index] && assessmentKits[index].biologic_kits ? assessmentKits[index].biologic_kits.map(item => item.size) : []
        let selectables = bioKits.filter(item => !selectedKits.includes(item.size))
        return selectables.length > 0
    }
    const syncKits = () => {
        let formValues = { ...form.getFieldsValue() }
        formValues.kits = assessmentKits
        form.setFieldsValue(formValues)
    }
    const handleKitChange = async (value, index) => {
        setvalidationerrors(prevState => ({ ...prevState, biologic_products: false }))
        console.log('handleKitChange:  ', value)
        let kit = biologicsProducts.find(item => `${item.company_name} ${item.product_name}` === value)
        if (!!kit && value.length > 0) {
            await setAssessmentKits(prevState => {
                prevState[index] = { product: value, biologic_kits: [''] }
                return [...prevState]
            })
            let formValues = { ...form.getFieldsValue() }
            formValues.kits = assessmentKits.map(item => ({ ...(item.product && { product: item.product }), ...(item.biologic_kits && { biologic_kits: item.biologic_kits }) }))
            form.setFieldsValue(formValues)
        } else if (!value) {
            await setAssessmentKits(prevState => {
                prevState[index] = { biologic_kits: [''] }
                return [...prevState]
            })
            let formValues = { ...form.getFieldsValue() }
            formValues.kits = assessmentKits.map(item => ({ ...(item.product && { product: item.product }), ...(item.biologic_kits && { biologic_kits: item.biologic_kits }) }))
            form.setFieldsValue(formValues)
        }
    }
    const handleKitSizeChage = async (value, productIndex, kitIndex) => {
        console.log('handleKitSizeChage: ', value)
        setvalidationerrors(prevState => ({ ...prevState, biologic_products: false }))
        let kit = biologicsProducts.find(item => `${item.company_name} ${item.product_name}` === assessmentKits[productIndex].product)
        let biokit = kit.kits.find(item => value === item.size)
        let kitId = biokit.id
        let biologic_kits = (assessmentKits[productIndex] && assessmentKits[productIndex].biologic_kits) ? assessmentKits[productIndex].biologic_kits : []
        biologic_kits[kitIndex] = biologic_kits[kitIndex] ? { ...biologic_kits[kitIndex], id: kitId, size: value } : { id: kitId, size: value }
        await setAssessmentKits(prevState => {
            prevState[productIndex] = { ...assessmentKits[productIndex], biologic_kits }
            return [...prevState]
        })
    }
    const onQtyChange = async (e, productIndex, kitIndex) => {
        console.log('onQtyChange: ', e.target.value)
        let biologic_kits = (assessmentKits[productIndex] && assessmentKits[productIndex].biologic_kits) ? assessmentKits[productIndex].biologic_kits : []
        if (e.target.value) {
            biologic_kits[kitIndex] = biologic_kits[kitIndex] ? { ...biologic_kits[kitIndex], quantity: Number(e.target.value) } : { quantity: Number(e.target.value) }
        } else {
            biologic_kits[kitIndex] = biologic_kits[kitIndex] ? { ...biologic_kits[kitIndex], quantity: undefined } : { quantity: undefined }
        }
        await setAssessmentKits(prevState => {
            prevState[productIndex] = { ...assessmentKits[productIndex], biologic_kits }
            return [...prevState]
        })
        syncKits()
    }
    // const [isExecuting, setisExecuting] = useState(false)
    const [shippingForm, setShippingForm] = useState(true)
    const [updatingStatus, setUpdatingStatus] = useState(false);
    const handleMenuClick = async (e) => {
        let status = availableStatuses[e.key]
        console.log(status, e)
        if (reasonableStatuses.includes(status)) {
            setreasonStatus(status)
            setreasonModal(true)
        } else {
            let payload = {
                ...order, status: availableStatuses[e.key], biologic_kits: order?.biologic_kits.map(kit => {
                    return ({
                        "id": kit.id,
                        "biologic_product_id": kit.biologic_product.id,
                        "quantity": kit.quantity
                    })
                })
            }
            if (order?.visit_type === "Internal") {
                if (order?.internal_order_resource_type === "DME") {
                    payload.dme_supplies = treatmentorderItems.map(item => {
                        return (
                            {
                                id: item.id,
                                unit_count: item.unit_count,
                                pack_amount: item.pack_amount || item.default_pack_amount,
                                type: "Primary Dressing"
                            }
                        )
                    })

                } else {
                    payload.ppe_kits = ppetreatmentorderItems.map(item => {
                        return (
                            {
                                id: item.id,
                                unit_count: item.unit_count,
                            }
                        )
                    })
                }

            }
            dispatch(updateOrder(_.omitBy(payload, v => _.isNil(v) || (_.isArray(v) && !v.length))))
        }


    }
    const reasonableStatuses = ["cancelled", "needs review", "returned"]
    const menu = (<Menu onClick={handleMenuClick}>
        {availableStatuses?.map((item, index) => <Menu.Item key={index} style={{ textTransform: 'capitalize' }}> {item.replace('-', '').replace(/_/g, ' ')}  </Menu.Item>)}
    </Menu>);

    const fetchPatient = async (patient) => {
        if (patient.id !== navs.patientDetailsOwner) {
            await dispatch(resetPatientModuleState())

        }
        await dispatch(selectPatient({ record: patient }))
        await dispatch(resetPatientNavs())

        navigate(`/patients/${patient.id}`)
    }
    const [changeOrderForm, setChangeOrderForm] = useState(false)
    const openAssessment = () => {
        let caution = GetCookie("order_caution")
        if (caution) {
            showassessmnt()
        } else {
            setChangeOrderForm(true)
        }

    }
    const ondontshowChange = (e) => {
        console.log(`switch to ${e.target.checked}`);
        SetCookie("order_caution", e.target.checked)
    };
    const [woundAssessment, setWoundAssessment] = useState(null)
    const [editassessmentModal, seteditassessmentModal] = useState(false)
    const showassessmnt = async () => {
        setChangeOrderForm(false)
        // await setAllergiesForm(true)
        let toastId = getToastId('Loading assessment')
        try {

            const encounters = await apiRequest.get(`/assessments/${order?.assessment_id}`)
            let wAssessment = encounters.data.data
            await setWoundAssessment(wAssessment)
            updateToast(toastId, 'Success', true)
            if (!wAssessment) {
                updateToast(toastId, errMsg("Order Assessment", true, null, 'No assessment was found for this wound'), false)
            } else {
                await dispatch(selectAssessment(wAssessment))
                await seteditassessmentModal(true)
            }
        } catch (e) {
            Sentry.captureException(e);
            console.log(e)
            updateToast(toastId, errMsg(e, true, 'wound assessment'), false)
        }
    }
    const [reasonStatus, setreasonStatus] = useState(null)
    const [reasonModal, setreasonModal] = useState(false)
    const reasonFinish = (values) => {
        let payload = {
            ...order, ...values,
            status: reasonStatus, biologic_kits: order?.biologic_kits.map(kit => {
                return ({
                    "id": kit.id,
                    "biologic_product_id": kit.biologic_product.id,
                    "quantity": kit.quantity
                })
            })
        }
        dispatch(updateOrder(_.omitBy(payload, v => _.isNil(v) || (_.isArray(v) && !v.length))))
        setreasonModal(false)
    }
    return <>
        <Modal
            title={"Change Order"}
            open={changeOrderForm}
            onOk={dialogCloser(setChangeOrderForm)}
            destroyOnClose
            onCancel={dialogCloser(setChangeOrderForm)}
            closeIcon={modalClose(dialogCloser(setChangeOrderForm))}
            maskClosable={false}
            footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
                {horStack([
                    outlinedButton(dialogCloser(setChangeOrderForm), 'Cancel', false),
                    <RegularButton onClick={showassessmnt}>Continue</RegularButton>])}
            </div>]}>
            <div>
                <Body1 color="black">
                    You will need to change the order in the patient record.
                    Make sure you have the correct permission to do this.
                </Body1>
                <div className="flex-boy-row">
                    <Checkbox onChange={ondontshowChange} >Don't Show again.</Checkbox>
                </div>


            </div>
        </Modal>
        <Modal
            title={`Assessment on ${order?.patient?.name}`}
            open={editassessmentModal}
            destroyOnClose={true}
            onOk={() => seteditassessmentModal(false)}
            onCancel={() => seteditassessmentModal(false)}
            closeIcon={modalClose(() => seteditassessmentModal(false))}
            maskClosable={false}
            width={1200}
            footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
                {horStack([outlinedButton(() => seteditassessmentModal(false), 'Cancel', isExecuting),
                formSubmitButton('updateappointmentForm', woundAssessment?.procedure === "Biologics Ordering" ? "Update order" : 'Update Assessment', isExecuting, 'Updating')])}
            </div>]}>
            {woundAssessment && <EditAssessmentCard setassessmentModal={() => {
                seteditassessmentModal()
                dispatch(fetchSpecificOrder(order?.id))
            }} setisExecuting={setisExecuting} setVisit={() => dispatch(fetchSpecificOrder(order?.id))}
                // assessment={woundAssessment}
                appointment={woundAssessment?.appointment} wound={woundAssessment?.wound} procedure={woundAssessment?.procedure} />}
        </Modal>
        <Modal
            title={`Please add a Reason`}
            open={reasonModal}
            destroyOnClose={true}

            onOk={() => setreasonModal(false)}
            onCancel={() => setreasonModal(false)}
            closeIcon={modalClose(() => setreasonModal(false))}
            maskClosable={false}
            width={1200}
            footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
                {horStack([outlinedButton(() => setreasonModal(false), 'Cancel', isExecuting),
                formSubmitButton('reasonForm', 'Update Reason', isExecuting, 'Updating')])}
            </div>]}
        >
            <Form
                name="reasonForm"
                onFinish={reasonFinish}
                layout="vertical"
            >
                {reasonStatus === 'cancelled' ?
                    <Form.Item
                        style={{ width: '100%' }}
                        label=" Cancellation Reason"
                        name="cancellation_reason"
                        rules={[{ required: true, message: 'this field is required!' }]}
                        wrapperCol={{ span: 24 }}
                    >
                        <Select
                            // mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please select"

                        >
                            {/* {cancellation_reasons.map(item => <Option value={item}>{item}</Option>)} */}
                            {masterList.find(item => item.system_name === "cancellation_reason")?.options?.map(item => <Option
                                value={item}>{item.toString().replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}</Option>) || []}
                        </Select>
                    </Form.Item> : reasonStatus === 'needs review' ?
                        <Form.Item
                            style={{ width: '100%' }}
                            label="Review Request Note"
                            name="review_request_note"
                            rules={[{ required: true, message: 'this field is required!' }]}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input autoComplete={'off'} />
                        </Form.Item> : <Form.Item
                            style={{ width: '100%' }}
                            label="Return Reason"
                            name="return_reason"
                            rules={[{ required: true, message: 'this field is required!' }]}
                            wrapperCol={{ span: 24 }}
                        >
                            <Select
                                // mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Please select"

                            >
                                {masterList.find(item => item.system_name === "return_reason")?.options?.map(item => <Option
                                    value={item}>{item.toString().replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}</Option>) || []}
                                {/* {GetOptions('return_reason')} */}
                            </Select>
                        </Form.Item>
                }
            </Form>

        </Modal>
        <Form
            name="patientOrderpreviewForm"
            id="patientOrderpreviewForm"
            form={form}
            initialValues={{ ...initialValues }}
            labelCol={{ span: 24 }}
            layout="vertical"
            onFinish={onFinish}
            style={{ background: '#E5E5E5', padding: '10px' }}
        >
            <Row gutter={16}>

                <Col span={24}>

                    <div className='flex-boy-row-space' style={{ float: "left", marginBottom: "20px" }}>


                        {availableStatuses?.length && can_update_treatment_order ? <Dropdown dropdownRender={()=>menu} trigger={['click']}>
                            {secondaryButton(null, getOrderStatus(order?.status?.toLowerCase()), updatingStatus, 'Updating',
                                null, null)}
                        </Dropdown> : getOrderStatus(order?.status?.toLowerCase())}
                        {order?.status === "delivered" && order?.visit_type === "Biologics" && getOrderAppliedStatus(order?.applied_status?.toLowerCase(), dayjs().diff(dayjs(order?.delivered_date), 'days'))}
                    </div>

                    <OrderNotes order={order} />

                    <div className="card-box shadow">
                        {boldKeyCapitalizedValue('Ordered by:', `${order?.consultant.name} ${dayjs(order?.date_of_service).format("MM/DD/YYYY")} `)}
                        {boldKeyCapitalizedValue('Approved by:', `${order?.approved_by || "-"} ${order?.approved_date ? dayjs(order?.approved_date).format("MM/DD/YYYY") : ''}`)}
                        <Divider />
                        {order?.sales_order_number && boldKeyCapitalizedValue('Sales order number:', `${order?.sales_order_number || "-"}`)}
                        {order?.tracking_number && boldKeyCapitalizedValue('Tracking Number:', `${order?.tracking_number || "-"}`)}
                    </div>

                    {order?.patient && <div className="card-box shadow" style={{ marginTop: '20px' }}>
                        <Headline6>Wound Details</Headline6>

                        {boldKeyCapitalizedValue('wound:', `${order?.wound_location}`)}


                    </div>}
                    <ShippingAddress isPatient={true} order={order} patient={patient} consultant={{ ...consultant }} />



                    <div className="card-box shadow" ref={treatmentRef}>
                        {order?.visit_type !== "Internal" && <>
                            <div className="flex-boy-row-space" style={{ width: "100%" }}>
                                <Headline6>Supplies List</Headline6>
                                {can_update_treatment_order && can_update_treatment_order && order?.order_state === "pre order" && order?.visit_type !== "Internal" && <RegularButton background="white" color="grey" borderColor="grey" onClick={() => openAssessment()}>Edit</RegularButton>}
                            </div>

                            <div style={{ width: "100%" }}>
                                {order?.dme_supplies.length > 0 || order?.visit_type === "DME" ? <>
                                    <Subtitle1 style={{ textAlign: 'left', padding: "5px" }} >Treatment Scenario :  {order?.treatment_protocol || "Other"}</Subtitle1>

                                    {treatmentorderItems?.map((item, index) => <div key={index} className="order-item flex-boy-row-space">
                                        <div className="flex-boy-row-space" style={{
                                            flex: 1, background: "#F1F2F5", border: "1px solid #F1F2F5", boxSizing: "border-box",
                                            minHeight: "30px", height: 'fit-content'
                                        }}><Subtitle1 style={{ textAlign: "left" }}>{item.name}</Subtitle1></div>
                                        <div className="flex-boy-row-space" style={{
                                            flex: 1, background: "#F1F2F5", border: "1px solid #F1F2F5", boxSizing: "border-box",
                                            minHeight: "30px", height: 'fit-content'
                                        }}><Subtitle1>{item.pack_amount || item.default_pack_amount} {item.unit_of_measure}</Subtitle1></div>
                                        <div style={{ width: "150px", marginLeft: "20px" }}>
                                            <Input
                                                disabled={(order?.order_state === "post order" || order?.visit_type !== "Internal")}
                                                onChange={(e) => unitChange(item, index, e.target.value)} value={item.unit_count || item.units_count}
                                            />
                                        </div>
                                        {can_update_treatment_order && order?.order_state === "pre order" && order?.visit_type === "Internal" && <FontAwesomeIcon onClick={() => removeTreatmentItem(item)} style={{ marginLeft: "10px" }} color="grey" icon={["fas", "trash-alt"]} />}
                                    </div>)}
                                    {validationerrors.treatmentorderItems ?
                                        <Body2 style={{ fontSize: '1em' }} color="crimson">Please ensure all Treatment order items have
                                            values!</Body2> : null}


                                    {newTreatmentSupply ? <AutoComplete
                                        popupMatchSelectWidth={false}
                                        style={{ width: "100%", marginBottom: "20px" }}
                                        onSelect={onsupplySelect}
                                        onSearch={handlesupplysearch}
                                        placeholder="Search for supply"
                                    // filterOption={(inputValue, option) =>
                                    //     option.protocol_name.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    //   }
                                    >
                                        {treatmentSupplies.map(item => (<Option key={item.name} value={item.name}>
                                            {item.name}
                                        </Option>))}
                                    </AutoComplete> : null}
                                    {can_update_treatment_order && order?.order_state === "pre order" && order?.visit_type === "Internal" ? <RegularButton style={{ width: "fit-content", marginTop: "40px" }} onClick={() => setnewTreatmentSupply(true)} background="white" color="grey" borderColor="grey">
                                        <FontAwesomeIcon style={{ marginLeft: "10px" }} color="grey" icon={["fas", "plus"]} />
                                        Add item
                                    </RegularButton> : null}
                                    <Divider />

                                </> : null}



                            </div>
                        </>}

                        {(order?.biologic_kits.length > 0 || order?.visit_type === "Biologics") && biologicsProducts.length ? <Row gutter={16} ref={treatmentRef}>

                            <Form.List name="kits" style={{ width: '100%' }}>
                                {(fields, { add, remove }) => (<>
                                    {fields.map(({ key, name, fieldKey, ...restField }, index) => ((order?.biologic_kits.length > 0 || order?.visit_type === "Biologics" || (order?.visit_type === "Internal" && order?.internal_order_resource_type === "Biologics")) && biologicsProducts.length &&
                                        <Row gutter={[8, 0]} align={"stretch"} justify={"start"} style={{ width: '100%' }}>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12} flex={"auto"}>
                                                <Form.Item
                                                    {...restField}
                                                    disabled={(order?.order_state === "post order" || order?.visit_type !== "Internal")}
                                                    labelAlign={"right"}

                                                    style={{ marginBottom: '5px', flexGrow: 1, }}
                                                    name={[name, 'product']}
                                                    fieldKey={[fieldKey, 'product']}
                                                    rules={[{ required: true, message: 'Missing product' }]}>
                                                    <AutoComplete disabled={(order?.order_state === "post order" || order?.visit_type !== "Internal")} popupMatchSelectWidth={false}
                                                        allowClear
                                                        onChange={(value) => handleKitChange(value, index)}
                                                        {...((!!assessmentKits && assessmentKits[index] && assessmentKits[index].product) && { value: assessmentKits[index].product })}
                                                        placeholder={"Search Biologic "}>
                                                        {selectableProducts.length > 0 && selectableProducts.map(item => (<Option key={item} value={item}>{item}</Option>))}
                                                    </AutoComplete>
                                                </Form.Item>
                                            </Col>


                                            {assessmentKits[index] && (() => {
                                                let bioKits = getBiologicKits(index);
                                                if (bioKits && bioKits.length) return <Col xs={24} sm={24} md={12} lg={12} xl={12} flex={"auto"}>
                                                    <Row gutter={[4, 0]}>
                                                        <Col xs={22} sm={22} md={22} lg={22} xl={22} flex={"auto"}>


                                                            <div style={{ width: '100%', background: '#F7F8F9', marginBottom: '10px', paddingTop: '2px', paddingBottom: '2px' }}>
                                                                <Form.List name={[name, 'biologic_kits']} style={{ marginBottom: "2px", flexGrow: 1 }}>
                                                                    {(fields, { add, remove }) => (
                                                                        <>
                                                                            {fields.map(({ key, name, fieldKey, ...restField }, kitIndex) =>
                                                                                <Row gutter={[4, 1]} align={"stretch"} justify={"start"} style={{ width: '100%', margin: 0 }}>
                                                                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} flex={"auto"}>
                                                                                        <Form.Item
                                                                                            {...restField}

                                                                                            style={{ flexGrow: 1, width: '100%', margin: 0, marginBottom: '2px', marginLeft: '2px' }}
                                                                                            name={[name, 'size']}
                                                                                            fieldKey={[fieldKey, 'size']}
                                                                                            rules={[{ required: true, message: 'Missing size' }]}>
                                                                                            <Select popupMatchSelectWidth={false}
                                                                                                disabled={(order?.order_state === "post order" || order?.visit_type !== "Internal")}

                                                                                                style={{ alignSelf: 'center' }} placeholder="Graft size"
                                                                                                onChange={(value) => handleKitSizeChage(value, index, kitIndex)}
                                                                                                {...((assessmentKits[index] && assessmentKits[index].biologic_kits && assessmentKits[index].biologic_kits[kitIndex]?.size) &&
                                                                                                    { value: assessmentKits[index].biologic_kits[kitIndex].size })}>
                                                                                                {(() => {
                                                                                                    let selectedKits = assessmentKits[index] && assessmentKits[index].biologic_kits ? assessmentKits[index].biologic_kits.map(item => item.size) : []
                                                                                                    let selectables = bioKits.filter(item => !selectedKits.includes(item.size))
                                                                                                    return selectables.map(item => <Option key={item.size} value={item.size}> {item.size} </Option>)
                                                                                                })()}
                                                                                            </Select>
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} flex={"auto"} style={{ margin: 0 }}>
                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            shouldUpdate


                                                                                            onChange={e => onQtyChange(e, index, kitIndex)}
                                                                                            style={{ flexGrow: 1, width: '100%', marginBottom: '2px', marginLeft: '2px' }}
                                                                                            name={[name, 'quantity']}
                                                                                            fieldKey={[fieldKey, 'quantity']}
                                                                                            rules={[{ required: true, message: 'Missing quantity' }]}>
                                                                                            <Input
                                                                                                disabled={(order?.order_state === "post order" || order?.visit_type !== "Internal")}
                                                                                                {...((assessmentKits[index] && assessmentKits[index].biologic_kits && assessmentKits[index].biologic_kits[kitIndex]?.quantity) &&
                                                                                                    { value: assessmentKits[index].biologic_kits[kitIndex].quantity })}
                                                                                                autoComplete={'off'} placeholder="Quantity" type={"number"} />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                    {(order?.order_state === "post order" || order?.visit_type !== "Internal") && (assessmentKits[index] && assessmentKits[index].biologic_kits && assessmentKits[index].biologic_kits.length > 1) &&
                                                                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} flex={"auto"} style={{ margin: 0 }}>
                                                                                            {can_update_treatment_order && order?.order_state === "pre order" && order?.visit_type === "Internal" && <Form.Item

                                                                                                style={{ marginBottom: '2px', alignSelf: 'center', marginLeft: '2px' }}>
                                                                                                <Button size={"middle"} shape={'circle'} type={'text'} style={{ alignSelf: 'center' }}
                                                                                                    onClick={async () => {
                                                                                                        await setAssessmentKits(prevState => {
                                                                                                            let current = [...prevState]
                                                                                                            current[index].biologic_kits.splice(kitIndex, 1)
                                                                                                            return current
                                                                                                        })
                                                                                                        remove(name)
                                                                                                        syncKits()
                                                                                                    }} icon={<DeleteOutlined/>}/>
                                                                                            </Form.Item>}
                                                                                        </Col>}
                                                                                </Row>)}
                                                                            {(can_update_treatment_order && order?.order_state === "pre order" && order?.visit_type === "Internal") && canAddKits(bioKits, index) &&
                                                                                <Form.Item style={{ marginBottom: '5px', alignSelf: 'center', marginLeft: '2px' }}> {addButton(() => add(), 'gray')} </Form.Item>}
                                                                        </>)}
                                                                </Form.List>
                                                            </div>

                                                        </Col>
                                                        <Col xs={2} sm={2} md={2} lg={2} xl={2} flex={"auto"}>
                                                            {can_update_treatment_order && order?.order_state === "pre order" && order?.visit_type === "Internal" && <Form.Item
                                                                style={{ marginBottom: '2px', alignSelf: 'center' }}>
                                                                <Button size={"middle"} shape={'circle'} type={'text'} style={{ marginLeft: 10, alignSelf: 'center' }}
                                                                    onClick={async () => {
                                                                        await setAssessmentKits(prevState => {
                                                                            prevState.splice(index, 1)
                                                                            return [...prevState]
                                                                        })
                                                                        changeSelectableProducts()
                                                                        remove(name)
                                                                        syncKits()
                                                                    }} icon={<DeleteOutlined/>}/>
                                                            </Form.Item>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            })()}



                                        </Row>))}



                                    {selectableProducts.length > 0 && order?.visit_type === "Internal" && can_update_treatment_order && order?.order_state === "pre order" && order?.biologic_kits.length > 0 && <Form.Item><Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>Add Biologic</Button></Form.Item>}
                                </>)}
                            </Form.List>
                        </Row> : null}

                        {(order?.visit_type === "Internal") ? <Row gutter={16} ref={treatmentRef}>
                            {order?.internal_order_resource_type === "DME" && <>
                                {treatmentorderItems?.map((item, index) => <div key={index} className="order-item flex-boy-row-space" style={{ width: "100%" }}>
                                    <div className="flex-boy-row-space" style={{
                                        flex: 1, background: "#F1F2F5", border: "1px solid #F1F2F5", boxSizing: "border-box",
                                        borderRadius: "4px", minHeight: "30px", height: 'fit-content'
                                    }}><Subtitle1 style={{ textAlign: "left" }}>{item.name}</Subtitle1></div>
                                    <div className="flex-boy-row-space" style={{
                                        flex: 1, background: "#F1F2F5", border: "1px solid #F1F2F5", boxSizing: "border-box",
                                        minHeight: "30px", height: 'fit-content'
                                    }}><Subtitle1>{item.pack_amount || item.default_pack_amount} {item.unit_of_measure}</Subtitle1></div>
                                    <div style={{ width: "150px", marginLeft: "20px" }}>
                                        <Input disabled={order?.order_state !== "pre order"} onChange={(e) => unitChange(item, index, e.target.value)} value={item.unit_count}
                                        />
                                    </div>
                                    {can_update_treatment_order && order?.order_state === "pre order" && <FontAwesomeIcon onClick={() => removeTreatmentItem(item)} style={{ marginLeft: "10px" }} color="grey" icon={["fas", "trash-alt"]} />}
                                </div>)}
                                {validationerrors.supplyItems ?
                                    <Body2 style={{ fontSize: '1em' }} color="crimson">Please ensure all supplies items have
                                        values!</Body2> : null}
                                {newTreatmentSupply ? <AutoComplete popupMatchSelectWidth={false}
                                    style={{ width: "100%", marginBottom: "20px" }}
                                    onSelect={onsupplySelect}
                                    onSearch={handlesupplysearch}
                                    placeholder="Search for supply">
                                    {treatmentSupplies.map(item => (<Option key={item.name} value={item.name}> {item.name} </Option>))}
                                </AutoComplete> : null}
                                {can_update_treatment_order && order?.order_state === "pre order" && <RegularButton style={{ width: "fit-content", marginTop: "40px" }} onClick={() => setnewTreatmentSupply(true)} background="white" color="grey" borderColor="grey">
                                    <FontAwesomeIcon style={{ marginLeft: "10px" }} color="grey" icon={["fas", "plus"]} />
                                    Add item
                                </RegularButton>}
                            </>}


                            {order?.internal_order_resource_type === "PPE" && <>
                                {ppetreatmentorderItems?.map((item, index) => <div key={index} className="order-item flex-boy-row-space" style={{ width: "100%" }}>
                                    <div className="flex-boy-row-space" style={{
                                        flex: 1, background: "#F1F2F5", border: "1px solid #F1F2F5", boxSizing: "border-box",
                                        borderRadius: "4px", minHeight: "30px", height: 'fit-content'
                                    }}><Subtitle1 style={{ textAlign: "left" }}>{item.name}</Subtitle1></div>

                                    <div style={{ width: "150px", marginLeft: "20px" }}>
                                        <Input disabled={order?.order_state !== "pre order"} onChange={(e) => ppeunitChange(item, index, e.target.value)} value={item.unit_count}
                                        />
                                    </div>
                                    {can_update_treatment_order && order?.order_state === "pre order" && <FontAwesomeIcon onClick={() => removeppeTreatmentItem(item)} style={{ marginLeft: "10px" }} color="grey" icon={["fas", "trash-alt"]} />}
                                </div>)}
                                {validationerrors.ppesupplyItems ?
                                    <Body2 style={{ fontSize: '1em' }} color="crimson">Please ensure all supplies items have
                                        values!</Body2> : null}
                                {newppeTreatmentSupply ? <AutoComplete popupMatchSelectWidth={false}
                                    style={{ width: "100%", marginBottom: "20px" }}
                                    onSelect={onppesupplySelect}
                                    onSearch={handleppesupplysearch}
                                    placeholder="Search for supply">
                                    {ppetreatmentSupplies.map(item => (<Option key={item.name} value={item.name}> {item.name} </Option>))}
                                </AutoComplete> : null}
                                {can_update_treatment_order && order?.order_state === "pre order" && <RegularButton style={{ width: "fit-content", marginTop: "40px" }} onClick={() => setnewppeTreatmentSupply(true)} background="white" color="grey" borderColor="grey">
                                    <FontAwesomeIcon style={{ marginLeft: "10px" }} color="grey" icon={["fas", "plus"]} />
                                    Add item
                                </RegularButton>}
                            </>}




                        </Row> : null}
                    </div>






                </Col>
                <Col span={24}>

                    {order?.status === "cancelled" && <div className="card-box shadow">
                        <Form.Item
                            style={{ width: '100%' }}
                            label=" Cancellation Reason"
                            name="cancellation_reason"
                            rules={[{ required: true, message: 'this field is required!' }]}
                            wrapperCol={{ span: 24 }}
                        >
                            <Select
                                // mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Please select"

                            >
                                {/* {cancellation_reasons.map(item => <Option value={item}>{item}</Option>)} */}
                                {masterList.find(item => item.system_name === "cancellation_reason")?.options?.map(item => <Option
                                    value={item}>{item.toString().replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}</Option>) || []}
                            </Select>
                        </Form.Item>

                    </div>}


                    {order?.status === "returned" && <div className="card-box shadow">
                        <Form.Item
                            style={{ width: '100%' }}
                            label="Return Reason"
                            name="return_reason"
                            rules={[{ required: true, message: 'this field is required!' }]}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input autoComplete={'off'} />
                        </Form.Item>

                    </div>}
                    {order?.status === "needs review" && <div className="card-box shadow">
                        <Form.Item
                            style={{ width: '100%' }}
                            label="Review Request Note"
                            name="review_request_note"
                            rules={[{ required: true, message: 'this field is required!' }]}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input autoComplete={'off'} />
                        </Form.Item>

                    </div>}
                    {order?.status === "warehouse alert" && <div className="card-box shadow">
                        <Form.Item
                            style={{ width: '100%' }}
                            label="Sales Order Number"
                            name="sales_order_number"
                            rules={[{ required: false, message: 'this field is required!' }]}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input autoComplete={'off'} />
                        </Form.Item>
                        <Form.Item wrapperCol={{ span: 24 }} style={{ width: '100%' }}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                {can_update_treatment_order && <Button type="primary" htmlType="submit" >
                                    Save
                                </Button>}
                            </div>
                        </Form.Item>

                    </div>}
                    {order?.status === "shipped" && <div className="card-box shadow">
                        <Form.Item
                            style={{ width: '100%' }}
                            label="Tracking Number"
                            name="tracking_number"
                            rules={[{ required: false, message: 'this field is required!' }]}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input autoComplete={'off'} />
                        </Form.Item>
                        <Form.Item wrapperCol={{ span: 24 }} style={{ width: '100%' }}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                {can_update_treatment_order && <Button type="primary" htmlType="submit" >
                                    Save
                                </Button>}
                            </div>
                        </Form.Item>
                    </div>}
                    {/* {order?.notes.length > 0 && <div className="card-box shadow">
                        <Subtitle1>Note</Subtitle1>
                        <div style={{ background: '#F1F2F5', width: '100%', padding: '10px', textAlign: 'left', borderRadius: '2px' }}>
                            {_.orderBy(order?.notes, ["created_at", "desc"])[0].note}
                        </div>


                    </div>} */}


                </Col>
            </Row>
            {/* <Form.Item wrapperCol={{ span: 24 }} style={{ width: '100%' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    {can_update_treatment_order && <Button type="primary" htmlType="submit" >
                        Save
                    </Button>}
                </div>
            </Form.Item> */}
        </Form>
    </>;
}

const mapStateToProps = (state) => ({
    navs: state.navs,
    
    masterList: state.masters.masterListRaw,
    order: state.orders.selectedOrder
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrder)
