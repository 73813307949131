/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-webpack-loader-syntax */
import React, { useEffect, useRef, useState } from 'react'
// mapboxgl.workerClass = MapboxWorker
// mapboxgl.accessToken = mapb
import { connect, useDispatch, useSelector } from 'react-redux'
// import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

import MapMenu from './Components/MapMenu';
import MapGL, { FlyToInterpolator, LinearInterpolator, Marker, NavigationControl, WebMercatorViewport } from 'react-map-gl';



import bbox from '@turf/bbox';

import { easeCubic } from 'd3-ease';
import background from "../../assets/map.png";

import { usePermissionCheck } from '../../hooks/usePermissionCheck';
import MapSearch from '../../components/map/Components/MapSearch';
import { mapboxToken } from '../../configure/constants';
import LocationSelectionMenu from './Components/LocationSelectionMenu';
import { fetchAllAssetLocations } from '../../app/Reducers/mapSlice';
import useSupercluster from 'use-supercluster';
import { Popover } from 'antd';



const options = [
    { name: 'State Office', color: '#ff73c3', type: "state_offices", icon: 'building' },
    {
        name: 'Lab Offices', color: '#ff5f5f', type: "labs", icon: 'flask'
    },
    { name: 'DME warehouses', color: '#4b9bff', type: "warehouses", icon: 'warehouse' },
    { name: 'WP owned properties', color: '#af78ff', type: "others", icon: 'home' }
];

export const Map = ({ warehouses, labs, state_offices, others }) => {
    const mapContainer = useRef();
    const [hotspots, setHotspots] = useState([])
    const [routing, setRouting] = useState(null)

    const [viewport, setViewport] = useState({
        width: '100%',
        height: '100%',
        latitude: 38.5,
        longitude: -98.0,
        zoom: 4
    });




    const goToNYC = (item) => {
        setViewport({
            ...viewport,
            longitude: item._geoloc.lng,
            latitude: item._geoloc.lat,
            zoom: 20,
            transitionDuration: 5000,
            transitionInterpolator: new FlyToInterpolator(),
            transitionEasing: easeCubic
        });
        // handlevisiblePop(item)
    };

    const onRegionClick = event => {
        const feature = event.features[0];
        console.log(feature);
        if (feature && feature.layer.source.includes('jsx')) {
            // calculate the bounding box of the feature
            // dispatch(fetchRegionPatients(feature.properties.name))

            const [minLng, minLat, maxLng, maxLat] = bbox(feature);
            // construct a viewport instance from the current state
            const vp = new WebMercatorViewport(viewport);
            const { longitude, latitude, zoom } = vp.fitBounds([[minLng, minLat], [maxLng, maxLat]], {
                padding: 40
            });
            setViewport({
                ...viewport,
                longitude,
                latitude,
                zoom,
                transitionInterpolator: new LinearInterpolator({
                    around: [event.offsetCenter.x, event.offsetCenter.y]
                }),
                transitionDuration: 1000
            });
        }
    };

    const dispatch = useDispatch()



    const navControlStyle = {
        left: 20,
        bottom: 50
    };







    // console.log(personFeature)


    // cluster stuff
    const mapRef = useRef();
    // const people = patients ? patients.data : [];
    // const persons = people?.map(patient => ({
    //     type: "Feature",
    //     properties: { cluster: false, ...patient },
    //     geometry: {
    //         type: 'Point',
    //         coordinates: [parseFloat(patient.lng), parseFloat(patient.lat)]
    //     }
    // }));




    // load and prepare data
    // get map bounds
    const bounds = mapRef.current
        ? mapRef.current
            .getMap()
            .getBounds()
            .toArray()
            .flat()
        : null;






    // load and prepare data
    // console.log(hospice_points)

    // get clusters
    const ReuseSupercluster = (points) => {

        const { clusters, supercluster } = useSupercluster({
            points: points,
            bounds,
            zoom: viewport.zoom,
            options: { radius: 100, maxZoom: 20 }
        });

        return { clusters, supercluster }
    }
    const warehouses_ = warehouses ? warehouses.data : [];
    const warehouses_cluster_points = warehouses_?.map(warehouse => ({
        type: "Feature",
        properties: { cluster: false, ...warehouse },
        geometry: {
            type: 'Point',
            coordinates: [parseFloat(warehouse.lng), parseFloat(warehouse.lat)]
        }
    }));

    const labs_ = labs ? labs.data : [];

    const labs_cluster_points = labs_?.map(lab => ({
        type: "Feature",
        properties: { cluster: false, ...lab },
        geometry: {
            type: 'Point',
            coordinates: [parseFloat(lab.lng), parseFloat(lab.lat)]
        }
    }));







    const state_offices_ = state_offices ? state_offices.data : [];
    const state_offices_cluster_points = state_offices_?.map(state_office => ({
        type: "Feature",
        properties: { cluster: false, ...state_office },
        geometry: {
            type: 'Point',
            coordinates: [parseFloat(state_office.lng), parseFloat(state_office.lat)]
        }
    }));


    const others_ = others ? others.data : [];


    const others_cluster_points = others_?.map(other => ({
        type: "Feature",
        properties: { cluster: false, ...other },
        geometry: {
            type: 'Point',
            coordinates: [parseFloat(other.lng), parseFloat(other.lat)]
        }
    }));

    const warehouseCluster = ReuseSupercluster(warehouses_cluster_points)
    const labsCluster = ReuseSupercluster(labs_cluster_points)
    const state_officesCluster = ReuseSupercluster(state_offices_cluster_points)
    const othersCluster = ReuseSupercluster(others_cluster_points)


    // get clusters



    useEffect(() => {
        dispatch(fetchAllAssetLocations())

    }, [])



    // renderClusters
    const generateClusters = (dataPoints) => {
        return dataPoints.map(point => ({
            type: "Feature",
            properties: { cluster: false, ...point },
            geometry: {
                type: 'Point',
                coordinates: [parseFloat(point.lng), parseFloat(point.lat)]
            }
        }));
    }

    const renderClusters = (clusterPoints, type) => {
        const option = options.find(opt => opt.type === type);
        if (!option) return null;

        const clusters = ReuseSupercluster(clusterPoints);
        if (!CheckVisibility(type, mapState)) return null;

        return clusters.clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
                cluster: isCluster,
                point_count: pointCount
            } = cluster.properties;

            if (isCluster) {
                return (
                    <Marker
                        key={`cluster-${cluster.id}`}
                        latitude={latitude}
                        longitude={longitude}
                    >
                        <div
                            className={`cluster-marker-${type}`}
                            style={{
                                width: `${40 + (pointCount / clusterPoints.length) * 20}px`,
                                height: `${40 + (pointCount / clusterPoints.length) * 20}px`
                            }}
                            onClick={() => {
                                const expansionZoom = Math.min(
                                    clusters?.supercluster?.getClusterExpansionZoom(cluster.id),
                                    30
                                );

                                setViewport({
                                    ...viewport,
                                    latitude,
                                    longitude,
                                    zoom: expansionZoom,
                                    transitionDuration: 2500,
                                    transitionInterpolator: new FlyToInterpolator(),
                                    transitionEasing: easeCubic
                                });
                            }}
                        >
                            {pointCount}
                        </div>
                    </Marker>
                );
            }

            return (
                <Marker
                    key={`${type}-${cluster.properties.id}`}
                    latitude={latitude}
                    longitude={longitude}
                >
                    {/* Customize the marker for different types if needed */}
                    <Popover content={<PopoverContent item={cluster.properties} />} title={cluster.properties.name} trigger="click">
                        <div className="icon-circle" style={{ backgroundColor: option.color }}>
                            <i className={`fas fa-${option.icon} text-white`}></i>
                            {/* Add your icon or content here based on type */}
                        </div>
                    </Popover>
                </Marker>
            );
        });
    }
    // renderClusters


    const dataMappings = {
        state_offices: state_offices?.data || [],
        labs: labs?.data || [],
        warehouses: warehouses?.data || [],
        others: others?.data || []
    };


    const CheckVisibility = (type, state) => {
        console.log(type, state?.[type]?.visible)
        return state?.[type]?.visible;
    }



    const mapState = useSelector(state => state.map);


    const PopoverContent = ({ item }) => {
        return (
            <div className="flex flex-col p-2 bg-slate-200">
                <p className="text-sm font-semibold">Name: {item.name}</p>
                <p className="text-sm font-semibold">Address: {item.address_1}</p>
                <p className="text-sm font-semibold">City: {item.city}</p>
                <p className="text-sm font-semibold">State: {item.state}</p>


            </div>
        );
    };



    return (<div className="mapCont">

        <MapMenu children={<LocationSelectionMenu />} />
        <div className="map">
            {/* <div className="map-container" ref={mapContainer}>
                {loadwifiMarkers()}
                </div> */}
            <MapSearch goToNYC={goToNYC} />
            {/* <MapStatsCard /> */}
            <div id="static" style={{ backgroundImage: `url(${background})` }}></div>
            <div className="map-container" ref={mapContainer}>
                <MapGL height={'100%'}
                    {...viewport}
                    mapStyle="mapbox://styles/mapbox/light-v10"
                    onViewportChange={nextViewport => setViewport(nextViewport)}
                    mapboxApiAccessToken={mapboxToken}
                    onClick={onRegionClick}
                    ref={mapRef}
                >




                    {/* clustering attempt */}

                    {options.map(option => {
                        const clusterPoints = generateClusters(dataMappings[option.type]);
                        // if redux state[option.type].visible is true, render clusters else null


                        return renderClusters(clusterPoints, option.type);
                    })}



                    {/* clustering attempt */}


                    <NavigationControl style={navControlStyle} />
                </MapGL>
            </div>
        </div>



    </div>)
}
const mapStateToProps = (state) => ({
    consultants: state.map.consultants,
    patients: state.map.patients,
    facilities: state.map.facilities,
    consultantSchedule: state.map.consultantSchedule,
    visibleSchedule: state.map.visibleSchedule,
    selectedConslultant: state.map.selectedConslultant,
    activeUser: state.auth?.activeUser,
    warehouses: state.map.warehouses,
    labs: state.map.labs,
    state_offices: state.map.state_offices,
    others: state.map.others,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Map);
