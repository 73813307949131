/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Body1, Body2, Headline6, Subtitle1 } from '../../../../components/general'
import HeaderTop from '../../../../components/HeaderTop'
import { Breadcrumb, Tabs, Popover } from 'antd';
import { containedButton, emptyFun, getHeaderLeftNav, getWoundStatus, horStack } from "../../../../common/helpers";
import { setfacilityWoundNavs } from "../../../../app/Reducers/NavSlice";
import { genWoundsReport } from "../../../../app/Apis/commonApi";
import PdfViewer from "../../../../components/PdfViewer";
import WoundDetails from './WoundDetails'
import PatientSummary from '../../../Patients/components/PatientSummary'
import PatientHealthData from '../../../Patients/components/PatientHealthData'
import Documents from '../../../Patients/components/PatientDocuments/Documents'
import { apiRequest } from '../../../../app/Apis';
import dayjs from 'dayjs';
import { getWoundPlanofcares } from '../../../../app/Reducers';
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
import {HistoryOutlined} from '@ant-design/icons';
const TabList = ["Wound Track", "Patient Details", "Documents", "Health Data"]
const { TabPane } = Tabs;

const WoundPage = ({  wound, patient, appConf,  navs,  plansOfCare }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('Wound Track');
  const can_view_plan_of_care_summary = usePermissionCheck('view_plan_of_care_summary', false, [])
  const [isWoundsProgressPrinting, setWoundsProgressPrinting] = useState(false)
  const [visitStatusModal, setStatusModal] = useState(false)
  const [pdfShowing, setPdfShowing] = useState(false);
  const [pdfLoading, setLoading] = useState(false);
  const [pdf, setPdf] = useState(null);

  const changTab = async (tab) => {
    console.log('changTab:  ', tab)
    setActiveTab(tab)
    await dispatch(setfacilityWoundNavs({ firstTab: tab }))
    // dispatch(setfacilityWoundNavs({ firstTab: tab }))
  }
  let defParam = { filterData: { wound: wound?.id, patient: patient?.id } }
  useEffect(() => {
    fetchProgress()
    can_view_plan_of_care_summary && dispatch(getWoundPlanofcares(appConf.patientAssessmentFilters[0] ? appConf.patientAssessmentFilters[1] : defParam));
    return emptyFun
  }, [wound])


  const genWoundProgressReport = () => genWoundsReport(wound, setWoundsProgressPrinting, setStatusModal, setPdf, setLoading, setPdfShowing)
  const [progress, setProgress] = useState([])
  const fetchProgress = async () => {
    const response = await apiRequest.get(`/wounds/${wound?.id}/progress`)
    if (response && response.data) {
      let progress = response.data.data.map(item => ({
        name: dayjs(item.appointment_start_date).format("YYYY-MM-DD"), Depth: !!item.depth ? item.depth : 0,
        Width: !!item.width ? item.width : 0, Length: !!item.length ? item.length : 0,
        Area: !!item.area ? item.area : 0, Volume: !!item.volume ? item.volume : 0,
        ...item
      }))
      setProgress([...progress])
    }
  }
  return (<div className="main-page">
    <HeaderTop child={getHeaderLeftNav(navigate, 'Wound Details')} />
    <div className="page-header">
      <div className="flex-boy-row">
        <Breadcrumb>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>

          <Breadcrumb.Item>Wound Details</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="flex-boy-row-space">
        <div className='flex-boy-row'>
          <Headline6>{patient.name} </Headline6>
          <Body1 color='grey' style={{ margin: '2px' }}> Id:{patient.id}</Body1>
        </div>
        {/* <div>
                    <RegularButton style={{ marginBottom: '15px' }}>
                        <FontAwesomeIcon color="white" icon={["fas", "chart-line"]} />
                        <Subtitle1 style={{ marginLeft: '5px' }} color='white'>Generate Report</Subtitle1>
                    </RegularButton>
                    <RegularButton className="flex-boy-row-space" color="grey" background="transparent"
                        style={{ marginRight: "10px", borderRadius: 14, cursor: 'default' }} borderColor="gainsboro">
                        <div className="dot-circle-active"></div>
                        &nbsp; &nbsp;
                        <Subtitle1>{wound.status}</Subtitle1>
                        &nbsp; &nbsp;

                    </RegularButton>
                </div> */}

        {horStack([

          // <RegularButton className="flex-boy-row-space" color="grey" background="transparent"
          //               style={{marginRight: "10px", borderRadius: 14, cursor: 'default'}} borderColor="gainsboro">
          //   <div className="dot-circle-active"></div>
          //   &nbsp; &nbsp;
          //   <Subtitle1>{wound.status}</Subtitle1>
          //   &nbsp; &nbsp;

          // </RegularButton>,
          getWoundStatus(wound?.status?.toLowerCase()),

          (progress.length < 2 ?
            <Popover content={<Body2 color='crimson'>Wound doesn't have enough visits to generate a report.</Body2>} title="" trigger="click">
              {containedButton(null, 'Generate Report', isWoundsProgressPrinting, 'Printing', null, { icon: <HistoryOutlined /> })}
            </Popover> :
            !plansOfCare?.length ?
              <Popover content={<Body2 color='crimson'>Wound has no plan of care. Please create a plan of care to generate a report.</Body2>} title="" trigger="click">
                {containedButton(null, 'Generate Report', isWoundsProgressPrinting, 'Printing', null, { icon: <HistoryOutlined /> })}
              </Popover>
              : containedButton(genWoundProgressReport, 'Generate Report', isWoundsProgressPrinting, 'Printing', null, { icon: <HistoryOutlined /> })),
        ])}
      </div>
      <Tabs defaultActiveKey={navs.facilityWoundNavs.firstTab} onChange={changTab} tabBarStyle={{ marginBottom: 0 }}>
        {TabList.map(item => <TabPane key={item} tab={<Subtitle1>{item}</Subtitle1>}>

        </TabPane>)}

      </Tabs>
    </div>
    <div className="main-body" style={{ padding: "10px", backgroundColor: 'wwhitesmoke' }}>
      <PdfViewer pdf={pdf} setLoading={setLoading} loading={pdfLoading} onCancel={() => setPdfShowing(false)} visible={pdfShowing} />

      {navs.facilityWoundNavs.firstTab === "Patient Details" ?
        <PatientSummary /> : navs.facilityWoundNavs.firstTab === "Wound Track" ?
          <WoundDetails /> : navs.facilityWoundNavs.firstTab === "Documents" ?
            <Documents /> :
            <PatientHealthData />}

    </div>
  </div>)
}
const mapStateToProps = (state) => ({ wound: state.patients.selectedWound, patient: state.patients.selectedPatient, encountersList: state.appointments.encounters,
  appConf: state.appConf, woundEncounters: state.patients.woundEncounters,
  appointments: state.patients.patientAppointments, navs: state.navs,
  plansOfCare: state.patients.woundPlansOfCare,
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(WoundPage)
