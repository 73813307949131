import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setVisibilty } from '../../../app/Reducers/mapSlice';

const LocationSelectionMenu = () => {
    const dispatch = useDispatch()
    const [checkedList, setCheckedList] = useState([]);

    const options = [
        { name: 'State Office', color: '#ff73c3', type: "state_offices", icon: 'building' },
        {
            name: 'Lab Offices', color: '#ff5f5f', type: "labs", icon: 'flask'
        },
        { name: 'DME warehouses', color: '#4b9bff', type: "warehouses", icon: 'warehouse' },
        { name: 'WP owned properties', color: '#af78ff', type: "others", icon: 'home' }
    ]

    const handleCheckboxChange = (value) => {
        console.log(value)
        if (checkedList.includes(value)) {
            setCheckedList(prevList => prevList.filter(item => item !== value));
        } else {
            setCheckedList(prevList => [...prevList, value]);
        }
        // setvisibility
        dispatch(setVisibilty({ key: value, value: !checkedList.includes(value) }))
    };

 
    const CheckVisibility = (type, state) => {
            console.log( type, state?.[type]?.visible)
            return state?.[type]?.visible;
        }
    
  
    
    const mapState = useSelector(state => state.map);

    useEffect(() => {
        setCheckedList(options.map(option => CheckVisibility(option.type, mapState) ? option.type : null).filter(item => item !== null))
    }, [mapState]);

    useEffect(() => {
        return () => {
            setCheckedList([]);
        }
    }, []);

    return (
        <div className="p-4 bg-customGray shadow-lg rounded-lg">
            <p className="mb-4 font-semibold text-lg text-customBlue text-left">Show on map</p>
            {options.map((option, index) => (
                <div key={index} className="relative flex items-start my-3">
                    <div className="flex h-6 items-center">
                        <input
                            type="checkbox"
                            id={option.type}
                            checked={checkedList.includes(option.type)}
                            onChange={() => handleCheckboxChange(option.type)}
                            className={`h-4 w-4 rounded relative border-gray-300 focus:ring-0 focus:ring-offset-0 focus:ring-opacity-0`}
                            style={{
                                accentColor: `${option.color}`,
                                backgroundColor: 'white'
                            }}
                        />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                        <label htmlFor="candidates" className="font-medium text-gray-900">
                            {option.name}
                        </label>{' '}

                    </div>
                </div>
            ))}
        </div>
    );
}

export default LocationSelectionMenu;
