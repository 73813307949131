import React, {useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Subtitle1} from '../../components'
import HeaderTop from '../../components/HeaderTop'
import {Button, Input, Menu, Modal} from 'antd';
// import { masterList } from './masterList';
import VideoChat from './Components/Video'
import NewGroupChat from './Components/NewGroupChat';
// import { data } from './masterListRaw'

const { SubMenu } = Menu;
const { Search } = Input;
export const Chat = ({ masterList, conversation }) => {
  const dispatch = useDispatch()
  const [visible, setvisible] = useState(true);
  
  const [isOpen, setOpen] = useState(false)
  const [isExecuting, setisExecuting] = useState(false)
  const [selectedConversation, setselectedconversation] = useState(false)
  
  const [location, setLocation] = useState({
    parent: 'Master',
    module: "Assessments",
    sub_module: "Start Assessment"
    
  })
  
  const showDrawer = () => {
    setvisible(true)
  };
  
  const onClose = () => {
    setvisible(false)
  };
  
  
  const [searchKey, setsearchKey] = useState('');
  const onSearch = async value => {
    setsearchKey(value.trim())
    let { sub_module, module } = location
    if (!!value && value.trim().length > 0) {
    
    }
    if (!value || value === '') {
    
    }
  }

// new group modal
  const [loading, setLoading] = useState(false);
  const [newGroupvisible, setNewGroupVisible] = useState(false);
  
  const showNewGroupModal = () => {
    setNewGroupVisible(true);
  };
  
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setNewGroupVisible(false);
    }, 3000);
  };
  
  const handleNewGroupCancel = () => {
    setNewGroupVisible(false);
  };
  return (
    <div className="main-page">
      <Modal
        title={null}
        destroyOnClose={true}
        open={newGroupvisible}
        bodyStyle={{ padding: 0, margin: 0, minHeight: "300px" }}
        
        // style={{ top: 0, background: 'blue', padding: 0, marginBottom: 0 }}
        onCancel={handleNewGroupCancel}
        // closable={false}
        onOk={handleOk}
        // closeIcon={modalClose(onCancel)}
        maskClosable={loading}
        width={550}
        footer={[
          <Button key="back" onClick={handleNewGroupCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            Create
          </Button>
        
        ]}
      >
        <NewGroupChat/>
      </Modal>
      <HeaderTop child={<Subtitle1>Messenger</Subtitle1>}/>
      <div className="flex-boy-row">
        
        <div className="chat-block-content" style={{ flex: 1, textAlign: 'left', background: 'whitesmoke' }}>
          <VideoChat/>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  masterList: state.masters.masterList,
  localStorage: state.masters.location,
  conversation: state.chat.selectedChat
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Chat);
