/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Col, Grid, Modal, Row} from 'antd'
import {Headline6, Subtitle1} from '../../../../components'
import {deleteFamilyMember, fetchFamilyHistory} from '../../../../app/Reducers';
import dayjs from 'dayjs';

import {
  addButton,
  boldKeyCapitalizedValue,
  containedButton,
  dialogCloser,
  emptyFamilyHistory,
  emptyFun,
  formSubmitButton,
  horStack,
  kebabMenus,
  modalClose,
  outlinedButton
} from "../../../../common/helpers";

import NewHistoryForm from "./forms/FamilyHistoryForm";
import KebabMenu from "../../../../components/KebabMenu";
import AuditLogViewer from "../../../../components/AuditLogs/AuditLogViewer";
import {useSubscription} from "../../../../hooks/MqttHooks/useSubscription";
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';
const { useBreakpoint } = Grid;
export const FamilyHistorySection = ({ patient, familyHistory, activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState=useBreakpoint()
  const [allergiesForm, setAllergiesForm] = useState(false);
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [isExecuting, setisExecuting] = useState(false);
  const dispatch = useDispatch()
  const { message } = useSubscription(`view_patient_next_of_kin/${patientId}`, { qos: 2, nl: true });
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  const can_create_patient_next_of_kin = usePermissionCheck('create_patient_next_of_kin', false, [])
  const can_update_patient_next_of_kin = usePermissionCheck('update_patient_next_of_kin', false, [])
  const can_delete_patient_next_of_kin = usePermissionCheck('delete_patient_next_of_kin', false, [])
  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message)
      loadFamilyHistory(true)
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    loadFamilyHistory(false)
    return emptyFun
  }, [])
  const loadFamilyHistory = (isMQMessage) => {
    /*if (!!familyHistory && !!familyHistory[0] && !isMQMessage && familyHistory.every(item => item.patient_id === patientId)) {
      return
    }*/
    dispatch(fetchFamilyHistory(patientId))
  }
  const [selected, setSelected] = React.useState(null);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [editingForm, setEditingForm] = useState(false);
  const [member, setMember] = useState(null);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const showPopconfirm = (member) => {
    setSelected(member.id);
  }
  const handleCancel = () => setSelected(null)
  const handleOk = async (id) => {
    await setConfirmLoading(true);
    let data = { id, patient_id: patientId }
    await dispatch(deleteFamilyMember(data))
    await setSelected(null)
    await setConfirmLoading(false);
  }
  const actionMenus = kebabMenus((can_update_patient_next_of_kin && !patient.deleted_at), can_view_audit_logs, can_delete_patient_next_of_kin)
  
  async function kebabMenuClick(e, member) {
    await setMember(member)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true)
        await setAllergiesForm(true)
        break
      case 'Delete':
        await setEditingForm(false)
        showPopconfirm(member)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
        await setEditingForm(false)
    }
  }
  
  /*const menu = (item) => {
    return <Menu onClick={(e) => handleMenuClick(e, item)}>
      {editMenus.map((item, index) => <Menu.Item key={index} icon={item.icon}> {item.title}  </Menu.Item>)}
    </Menu>
  }*/
  const addClick = () => {
    setEditingForm(false)
    setAllergiesForm(true);
  }
  
  
  return (
    (<div className="">
      {(!!member && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)}
                                                        resourceType={'Patient'}
                                                        loading={false} resourceDescription={`${patient.name}'s family history`}
                                                        defaultValues={{ auditableId: member?.id, auditable: 'family_history', format: 'json' }}/>}
      <Modal
        title={editingForm ? 'Edit Family member' : "Add Family Member"}
        open={allergiesForm}
        onOk={dialogCloser(setAllergiesForm)}
        onCancel={dialogCloser(setAllergiesForm)}
        closeIcon={modalClose(dialogCloser(setAllergiesForm))}
        maskClosable={false}
        destroyOnClose
        width={700}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(dialogCloser(setAllergiesForm), 'Cancel'),
            formSubmitButton('familyHistoryForm', editingForm ? 'Update' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
        </div>]}>
        <NewHistoryForm editingForm={editingForm} member={member} setisExecuting={setisExecuting} closeModal={dialogCloser(setAllergiesForm)} patient={patient}/>
      </Modal>
      <div className="flex-boy-row-space" style={{ width: "100%" }}>
        <Headline6>Family History</Headline6>
        {(can_create_patient_next_of_kin && !patient.deleted_at) ? addButton(addClick) : null}
      </div>
      {!!familyHistory && !!familyHistory[0] ?
        
        < Row gutter={16}>
          {
            familyHistory?.map((item, index) => {
              return (<Col span={8} key={index}>
                <div style={{ margin: 10 }}>
                  <div className="card-box" style={{ flexGrow: 1, margin: 10 }}>
                    <div className="flex-boy-row-space" style={{ width: "100%" }}>
                      <Subtitle1>{item.subject}</Subtitle1>
                      {/*<Dropdown dropdownRender={()=>menu(item)} trigger={['click']} disabled={selected === item.id}>
                      {appConfirm(
                        <MoreOutlined />, selected === item.id, null, 'Are you sure of deleting this family member?', () => handleOk(item.id), handleCancel, confirmLoading)}
                    </Dropdown>*/}
                      <KebabMenu menus={actionMenus}
                                 recordItem={item}
                                 handleMenuClick={kebabMenuClick} resource={'family history'} handleOk={() => handleOk(item.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
                                 showConfirmDialog={selected === item.id}/>
                    </div>
                    <div style={{ width: "100%" }}>
                      {boldKeyCapitalizedValue('Gender:', item.gender || 'N/A')}
                      {boldKeyCapitalizedValue('Date Of Birth:', dayjs(item?.date_of_birth).format('YYYY-MM-DD'))}
                      {item.date_of_death && boldKeyCapitalizedValue('Date Of Death:', item.date_of_death ? dayjs(item.date_of_death).format('YYYY-MM-DD') : 'N/A')}
                      {(item.cause_of_death || item.date_of_death) && boldKeyCapitalizedValue('Cause Of Death:', item.cause_of_death || 'N/A')}
                      {boldKeyCapitalizedValue('Relationship:', item.relationship || 'N/A')}
                      {boldKeyCapitalizedValue('Observation:', item.observation || 'N/A')}
                      {item.medical_histories.map((disease) => {
                        return (<div style={{ width: "100%", alignItems: "normal", borderTop: '1px solid gray' }}>
                          {boldKeyCapitalizedValue('Disease:', disease.disease_name || 'N/A')}
                          {disease.status === "active" || disease.status === 'Active' ? <div className="status-container">
                            <div className="dot-circle-active"></div>
                            <Subtitle1>Active</Subtitle1>
                          </div> : <div className="status-container">
                            <div className="dot-circle-inactive"></div>
                            <Subtitle1>Inactive</Subtitle1>
                          </div>}
                          {boldKeyCapitalizedValue('Age Of Diagnosis:', disease.age_of_diagnosis || 'N/A')}
                        </div>)
                      })}
                    </div>
                  </div>
                
                </div>
              </Col>)
            })
          }
        </Row>
        :
        emptyFamilyHistory('Family members', (can_create_patient_next_of_kin && !patient.deleted_at) ? containedButton(addClick, 'Add Family History', false, null, {}, {
          icon: <PlusOutlined />
        }) : null)
      }
    </div>)
  );
}
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser, familyHistory: state.patients.healthData.familyHistory,
  patient: state.patients.selectedPatient,

  
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(FamilyHistorySection)
