/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Subtitle1} from '../../../components'

import {Avatar, Form, Grid, Input, Modal, Table, Tabs, Tag} from 'antd';
// import ShedulePendingSchedule from './ShedulePendingSchedule';
import dayjs from 'dayjs'
import {fetchPendingSchedule, resetAppointmentModuleState, resetPendingSchedulePagination, setPendingScheduleLoaded, updatePendingScheduleFilters} from '../../../app/Reducers';
import {
  containedButton,
  dialogCloser,
  emptyFun,
  emptyTable,
  formSubmitButton,
  getSkels,
  horStack,
  kebabMenus,
  makeSelectOptions,
  modalClose,
  onTableChange,
  outlinedButton,
  responsiveTbl,
  stateFilter
} from "../../../common/helpers";

import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import TableFilters from "../../../components/InputComponents/TableFilters";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {apiRequest} from '../../../app/Apis';
import SheduleAppointment from './ScheduleAppointment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {EditOutlined, PlusOutlined} from '@ant-design/icons';
import {useFacilitySearch} from '../../../hooks/useFacilitySearch';
const { useBreakpoint } = Grid;
const PendingScheduleList = ({ pendingSchedule, loading, appConf, navs, pendingSchedulePagination, activeUser }) => {
  const windowState = useBreakpoint();
  const [activeTab, setActiveTab] = useState('patients');
  const [isOpen, setOpen] = useState(false)
  const dispatch = useDispatch()
  const [isVisible, setIsVisible] = useState(false);
  const [isExecuting, setisExecuting] = useState(false)
  const [editingForm, setEditingForm] = useState(false);
  const [pendingScheduleItem, setPendingScheduleItem] = useState(null);
  const { message } = useSubscription(`view_pre_scheduled`, { qos: 2, nl: true });
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const can_update_pendingSchedule = usePermissionCheck('update_pendingSchedule', false, [])
  const can_view_pendingSchedule = usePermissionCheck('view_pendingSchedule', false, [])
  const can_create_pendingSchedule = usePermissionCheck('create_pendingSchedule', false, [])
  const can_delete_pendingSchedule = usePermissionCheck('delete_pendingSchedule', false, [])
  const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_create_appointment = usePermissionCheck('create_appointment', false, [])
  const [patient, setPatient] = useState(null)
  let defaultList = !!activeUser?.facilityProfile ? [{ ...activeUser?.facilityProfile, label: activeUser?.facilityProfile.name, value: activeUser?.facilityProfile.id }] : []
  const [facilityOptions, debouncedFacilitiesSearch,facilitySearchLoading] = useFacilitySearch([], 'id', defaultList)
  const fetchPatient = async (id) => {
    const { data } = await apiRequest.get(`/patients/${id}`)
    if (data) {
      setPatient(data.data)
      setOpen(true)
    }
  }
  
  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message)
      loadPendingSchedule(true).catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  let onCell = (record, rowIndex) => ({
    onClick: event => {
      (async () => {
        if (!can_create_appointment){
          return
        }
        if (record.id !== navs.selectedPendingScheduleID) {
          await dispatch(resetAppointmentModuleState())
        }
        // fetch patientby record.patient_id
        fetchPatient(record.patient_id)
        // setPatient(patient)
        
        
      })().catch(e => console.log(e))
    }
  })
  const columns = [
    {
      title: 'Patient', dataIndex: 'patient_name', key: 'patientName',
      /* sorter: (a, b) => a.name - b.name, fltr: {filterable: true, type: 'text'},*/ render: (name) => (
        <div className="flex-boy-row-space" style={{ justifyContent: 'normal' }}>
          <Subtitle1 style={{ textAlign: "left", marginLeft: '10px' }}>{name}</Subtitle1>
        </div>), onCell,
      fltr: { filterable: true, type: 'text' }
    }, {
      title: 'Approval Date', dataIndex: 'approved_date', key: 'approvalDate', fltr: { filterable: true, type: 'date_range', keys: ['approvalDate[start_at]', 'approvalDate[end_at]'], baseKey: 'approval_range_filter'   },
      sorter: { multiple: 3, compare: (a, b) => dayjs(a.approved_date).unix() - dayjs(b.approved_date).unix() },
      render: (approved_date, record) => (
        <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(approved_date).format("MM/DD/YYYY")}</Subtitle1>), onCell
    },
    {
      title: 'Assignment Date', dataIndex: 'consultant_assignment_date', key: 'consultant_assignment_date', fltr: { filterable: true, type: 'date_range', keys: ['assignmentDate[start_at]', 'assignmentDate[end_at]'], baseKey: 'assignment_range_filter' },
      sorter: { multiple: 3, compare: (a, b) => dayjs(a.consultant_assignment_date).unix() - dayjs(b.consultant_assignment_date).unix() },
      render: (consultant_assignment_date, record) => (
        <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(consultant_assignment_date).format("MM/DD/YYYY")}</Subtitle1>), onCell
    },
    {
      title: 'Office', dataIndex: 'facility_name', key: 'facility',
      fltr: { filterable: true, type: 'select', options: facilityOptions,
        props: {
          disabled: !!activeUser?.facilityProfile,loading:facilitySearchLoading,
          onSearch: (value) => debouncedFacilitiesSearch(value), showSearch: true, filterOption: false
        },
        fiprops:{
          initialValue:activeUser?.facilityProfile ? activeUser?.facilityProfile?.id : undefined
        } }, onCell
    },
    // {
    //     title: 'Referral Type', dataIndex: "service_type", key: 'referral_type',
    //     fltr: {
    //         filterable: true, type: 'autocomplete',
    //         options: makeAutoCompleteOptions([{ label: 'Wound Care', value: 'wound care' }, { value: 'podiatry', label: 'Podiatry' }, {
    //             value: 'wound care and podiatry',
    //             label: 'Wound Care and Podiatry'
    //         }])
    //     }, visible: !activeUser?.facilityProfile
    // },
    {
      title: 'Assigned to', dataIndex: 'assigned_consultant_name', key: 'provider', fltr: { filterable: true, type: 'text' }, onCell,
      render: (assigned_consultant_name) => (
        // avatar with name
        <div className="flex flex-row items-center">
          <Avatar className="mr-2" size="small" alt={assigned_consultant_name}>
            
            {assigned_consultant_name?.split(" ").map((item, index) => {
              return index < 2 ? item[0] : null
            })}
          </Avatar>
          <Subtitle1 style={{ textAlign: 'left' }}>{assigned_consultant_name}</Subtitle1>
        </div>
      )
    },
    {
      title: 'Time to resolution',
      dataIndex: 'approved_date',
      key: 'status',
      fltr: {
        filterable: false,
        type: 'select',
        options: makeSelectOptions(['assigned', 'unassigned'])
      },
      render: (approved_date) => {
        const deadline = dayjs(approved_date).add(2, 'day');
        // console.log('deadline: ', deadline.format('D MMM, h:mma'));
        const now = dayjs();
        const diff = deadline.diff(now, 'hour');
        // console.log('deadline: ', deadline.format('D MMM, h:mma'), "apprcal: ", dayjs(approved_date).format('D MMM, h:mma'), "now: ", now.format('D MMM, h:mma'), "diff: ", diff);
        const overdue = diff < 0;
        const absDiff = Math.abs(diff);
        // convert hours to days
        const days = Math.floor(absDiff / 24);
        const formattedDeadline = deadline.format('D MMM, h:mma');
        const overdueText = overdue ? `(${days}d overdue)` : `(${absDiff}hr left)`;
        let statusColor = overdue ? '#F44336' : '#50D2A0';
        
        if (absDiff < 8) {
          statusColor = '#FFC107';
        }
        
        return (
          <div className="status-container">
            <div className="dot-circle-active" style={{ background: statusColor }}/>
            <Subtitle1 style={{ textAlign: 'left' }}>
              {formattedDeadline} {overdueText}
            </Subtitle1>
          </div>
        );
      }, onCell
    },
    ...(activeUser?.is_qa_user?[{ width:15,
      visible: true,
      title: <FontAwesomeIcon color="grey" icon={['fas', 'table']}/>,
      key: 'operation',
      render: (record) => <Tag className={`${(record.is_qa && activeUser?.is_qa_user)?'visible':'invisible'}`} icon={<EditOutlined/>} color="#55acee">QA</Tag>
    }]:[])
    
    // {
    //     fltr: { filterable: false }, render: (record) => {
    //         const actionMenus = kebabMenus((can_update_patient_resource), masterGlobalAccess, masterGlobalAccess)
    //         return <KebabMenu menus={actionMenus}
    //             recordItem={record}
    //             handleMenuClick={kebabMenuClick} resource={'addmission'} handleOk={() => handleOk(record.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
    //             showConfirmDialog={selected === record.id} />;
    //     }
    // },
  
  
  ]
  const [selected, setSelected] = React.useState(null);
  const handleCancel = () => setSelected(0);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const showPopconfirm = (id) => setSelected(id);
  const actionMenus = kebabMenus(can_update_pendingSchedule, can_view_audit_logs, can_delete_pendingSchedule)
  
  async function kebabMenuClick(e, record) {
    await setPendingScheduleItem(record)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true)
        await setOpen(true)
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
    }
  }
  
  
  async function handleFilterAction(action, values) {
    // console.log('handleFilterAction: ',values)
    await dispatch(resetPendingSchedulePagination())
    if (action === 'filter') {
      console.log('FILTERS:  ', JSON.stringify({ ...values, toLoad: 'pendingSchedule', endpoint: '/pendingSchedule' }));
      let pl = { filterData: values, pagination: pendingSchedulePagination }
      await dispatch(updatePendingScheduleFilters([false, pl, true]))
      await dispatch(fetchPendingSchedule(pl))
      await dispatch(updatePendingScheduleFilters([true, pl, false]))
    }
    if (action === 'reset'/* && appConf.pendingScheduleFilters[0]*/) {
      await dispatch(updatePendingScheduleFilters([appConf.pendingScheduleFilters[0], undefined, true]))
      await dispatch(fetchPendingSchedule())
      await dispatch(updatePendingScheduleFilters([false, undefined, false]))
    }
    // const response = await apiRequest.get(`/pendingSchedule`,{params: {...values,toLoad:'pendingSchedule'}})
    setIsVisible(false)
  }
  
  const loadPendingSchedule = async (isMQ) => {
    /*if (!!pendingSchedule[0] && !isMQ) {
      return
    }*/
    await dispatch(updatePendingScheduleFilters([appConf.pendingScheduleFilters[0], appConf.pendingScheduleFilters[1], !isMQ]))
    await dispatch(fetchPendingSchedule(appConf.pendingScheduleFilters[0] ? appConf.pendingScheduleFilters[1] : undefined))
    // await dispatch(fetchProcedures())
    // await dispatch(fetchLabRegions())
    // await dispatch(loadWoundLocations())
    await dispatch(setPendingScheduleLoaded(true))
    await dispatch(updatePendingScheduleFilters([appConf.pendingScheduleFilters[0], appConf.pendingScheduleFilters[1], false]))
  }
  
  
  useEffect(() => {
    loadPendingSchedule(false).catch(e => console.log(e))
    return emptyFun
  }, [])
  
  const handleOk = async (id) => {
    setpendingScheduleTodeleteID(id)
    setreasonModal(true)
    setSelected(0)
  };
  
  
  const [pendingScheduleTodeleteID, setpendingScheduleTodeleteID] = useState(null)
  const [reasonStatus, setreasonStatus] = useState(null)
  const [reasonModal, setreasonModal] = useState(false)
  const reasonFinish = async (values) => {
    await setConfirmLoading(true);
    // await dispatch(deletePendingSchedule({ pendingSchedule_id: pendingScheduleTodeleteID, ...values }));
    await setSelected(0);
    await setConfirmLoading(false);
    setreasonModal(false)
  }
  
  const [selectedTab, setselectedTab] = useState("1")
  const callback = (key) => {
    setselectedTab(key)
  }
  const Tabs_ = () => {
    return (
      <Tabs defaultActiveKey={selectedTab} onChange={callback}>
        <Tabs.TabPane tab={<Subtitle1>{editingForm ? 'Edit pendingSchedule' : "New PendingSchedule"}</Subtitle1>} key="1">
        
        </Tabs.TabPane>
        filter fix
      
      </Tabs>
    )
  }
  return (
    (<div className="main-page">
      {(!!pendingSchedule && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false}
                                                                 resourceDescription={`${pendingSchedule.patient.name}'s pendingSchedule`} resourceType={'PendingSchedule'}
                                                                 defaultValues={{ auditableId: pendingSchedule?.id, auditable: 'pendingSchedule', format: 'json' }}/>}
      <Modal
        
        title={editingForm ? 'Edit appointment' : "Add appointment"}
        open={isOpen}
        onCancel={dialogCloser(setOpen)}
        closeIcon={modalClose(dialogCloser(setOpen))}
        maskClosable={false}
        destroyOnClose={true}
        width={650}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setOpen), 'Cancel', isExecuting),
            formSubmitButton('appointmentForm', editingForm ? 'Update' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
        </div>]}>
        <SheduleAppointment patient={patient}
                            setisExecuting={setisExecuting} closeModal={dialogCloser(setOpen)}/>
      </Modal>
      <Modal
        title={`Please add a Reason`}
        open={reasonModal}
        destroyOnClose={true}
        
        onOk={() => setreasonModal(false)}
        onCancel={() => setreasonModal(false)}
        closeIcon={modalClose(() => setreasonModal(false))}
        maskClosable={false}
        width={1200}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(() => {
            setreasonModal(false)
            setSelected(0)
          }, 'Cancel', isExecuting),
            formSubmitButton('reasonForm', 'Add Reason', isExecuting, 'Updating')])}
        </div>]}
      >
        <Form
          name="reasonForm"
          onFinish={reasonFinish}
          layout="vertical"
        >
          
          <Form.Item
            style={{ width: '100%' }}
            label="Delete Reason"
            name="delete_reason"
            rules={[{ required: true, message: 'this field is required!' }]}
            wrapperCol={{ span: 24 }}
          >
            <Input autoComplete={'off'}/>
          </Form.Item>
        </Form>
      
      </Modal>
      <div className="w-full">
        
        
        <Table
          title={() => (
            <TableFilters datasource={[...columns, stateFilter/* procedureFilter*/]}
                          setIsVisible={setIsVisible}
                          isVisible={isVisible}
                          windowState={windowState}
                          handleFilterAction={handleFilterAction}
                          filters={appConf.pendingScheduleFilters[1]}
                          showClear={appConf.pendingScheduleFilters[0]}
                          loading={appConf.pendingScheduleFilters[2]}
                          actionButton={can_create_pendingSchedule ? containedButton(() => {
                            setEditingForm(false)
                            setOpen(true);
                          }, 'Add New', false, null, null, { icon: <PlusOutlined /> }) : null}
            />
          )}
          loading={appConf.pendingScheduleFilters[2]} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('pendingSchedule') }}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchPendingSchedule, appConf.pendingScheduleFilters, updatePendingScheduleFilters, null, pendingSchedulePagination)}
          {...getSkels((appConf.pendingScheduleFilters[2]), columns, pendingSchedule)} pagination={pendingSchedulePagination}/>
      </div>
    </div>)
  );
}
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser, pendingSchedule: state.appointments.pendingSchedule,
  loading: state.appointments.loading, appConf: state.appConf, navs: state.navs,
  pendingSchedulePagination: state.appointments.pendingSchedulePagination
  
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PendingScheduleList)
