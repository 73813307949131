import { Empty } from "antd";
import { sentenceCaseNoDash } from "../../../common/helpers";
import { PatientStatsChart } from "../../Facilities/utils";
import { getPatientsBarColorStatus, getFacilityBarColorStatus } from "../../../common/helpers";
import { BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Bar, Tooltip } from "recharts";

export default function LocationDetails({ data, type }) {
  console.log("New Data:", data)
  return (<div style={{ width: '400px' }}>
    {data?.length !== 0 ?
      <div style={{ width: '100%' }}>
        {type === "patients" ? <PatientStats data={data} />
          : type === 'facility' ? <FacilityStats data={data} />
            : type === 'appointments' ? <AppointmentStatsChart data={data} />
              : <AssessmentStatsChart data={data} />}
      </div>
      : <div style={{ width: '100%' }}>
        <Empty description={"No data Found"} />
      </div>}
  </div>)
}

function PatientStats({ data }) {
  console.log("Patient Statistics Data:", data)
  return (<div>
    {data?.map((item, index) => (
      <PatientStatsChart
        key={index}
        title={sentenceCaseNoDash(item?.status)}
        percentage={item?.percentage}
        count={item?.count}
        color={getPatientsBarColorStatus(sentenceCaseNoDash(item?.status))}
        total={item?.count} />
    ))}
  </div>)
}

function FacilityStats({ data }) {
  console.log("Office Statistics Data:", data)
  return (
    <div>
      {data?.map((facility, index) => (
        <PatientStatsChart
          key={index}
          title={facility?.type}
          percentage={facility?.percentage}
          color={getFacilityBarColorStatus(facility?.type)}
          total={facility?.count} />
      ))}
    </div>
  )
}

function AppointmentStatsChart({ data }) {
  return (
    <div className="w-full">
      <ResponsiveContainer width={'100%'} height={550} className='mt-10'>
        <BarChart data={data} margin={{ right: 30, bottom: 5 }}>
          <CartesianGrid strokeDasharray="4 4" />
          <XAxis dataKey={'label'} />
          <YAxis allowDecimals={false}
            axisLine={false}
            domain={[0, 100]}
          />
          <Tooltip />
          <Legend align="left" iconType='circle' style={{ marginLeft: 60 }} />
          <Bar dataKey='cancelled' fill='#FF5F5F' />
          <Bar dataKey='completed' fill='#50D2A0' />
          <Bar dataKey='missed' fill='#FF9F5F' />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

function AssessmentStatsChart({ data }) {
  return (
    <div className="w-full">
      <ResponsiveContainer width={'100%'} height={550} className='mt-10'>
        <BarChart data={data} margin={{ right: 30, bottom: 5 }}>
          <CartesianGrid strokeDasharray="4 4" />
          <XAxis dataKey={'label'} />
          <YAxis allowDecimals={false}
            axisLine={false}
            domain={[0, 100]}
          />
          <Tooltip />
          <Legend align="left" iconType='circle' style={{ marginLeft: 20 }} />
          <Bar dataKey='Biologics Ordering' fill='#AF78FF' />
          <Bar dataKey='Biologics  Application' fill='#FF5F5F' />
          <Bar dataKey='Biologics Pre-assessment' fill='#50D2A0' />
          <Bar dataKey='Lab' fill='#FF9F5F' />
          <Bar dataKey='Podiatry' fill='#83E8FF' label='Podiatry' />
          <Bar dataKey='Wound Debriment' fill='#7785FF' label='Wound Debriment' />
          <Bar dataKey='Wound Evaluation' fill='#2E5AAC' label='Wound Evaluation' />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}