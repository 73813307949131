import React, { useState, useEffect } from 'react';
import { Col, Row, Statistic } from 'antd';
import { apiRequest } from '../../../app/Apis';
import { Subtitle1 } from '../../../components';

const ConsultantsHealingRate = ({ consultant }) => {
  const [stats, setStats] = useState(null)

  async function getConsultantHealingRate(consultantId) {
    return apiRequest.get(`/consultants/${consultantId}/heal-rate-ranking`)
  }
  useEffect(() => {
    if (consultant) {
      (async () => {
        let res = await getConsultantHealingRate(consultant.id)
        setStats(res.data?.data)
      })().catch(e => console.log(e))
    }
  }, [])

  return (
   <div className='shadow-card-box'>
      <Row gutter={16}>
        <Col span={24}>
          <Statistic title={
            <Subtitle1>Healing Rate Rank</Subtitle1>
          } value={stats?.rank} />
        </Col>
        <Col span={24}>
          <Statistic title={
            <Subtitle1>Average Healing Rate</Subtitle1>
          } value={stats?.avg_heal_rate?.toFixed(2)} precision={2} />
        </Col>
      </Row>
   </div>
  )
}

export default ConsultantsHealingRate