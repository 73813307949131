/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Subtitle1} from '../../../components'

import {Form, Grid, Input, Modal, Table, Tabs, Tag} from 'antd';
import SheduleAppointment from './ScheduleAppointment';
import dayjs from 'dayjs'
import {deleteAppointment, fetchSpecificAppointment, resetAppointmentModuleState, selectAppointment, setSelectAppointmentID, updateScheduleFilters} from '../../../app/Reducers';
import {
  dialogCloser,
  emptyTable,
  formSubmitButton,
  getAppointmentStatus,
  getSkels,
  horStack,
  kebabMenus,
  makeSelectOptions,
  modalClose,
  onTableChange,
  outlinedButton,
  responsiveTbl
} from "../../../common/helpers";
import KebabMenu from "../../../components/KebabMenu";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import Timeblocker from './Timeblocker';
import {getToastId, updateToast} from '../../../app/Reducers/reducerUtils';
import {apiRequest} from '../../../app/Apis';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
const { useBreakpoint } = Grid;
const ScheduleAppointmentList = ({  appointments, loading, appConf, navs, schedulePagination, activeUser, handleFilterAction }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState = useBreakpoint();
  const [activeTab, setActiveTab] = useState('patients');
  const [isOpen, setOpen] = useState(false)
  const can_update_appointment = usePermissionCheck('update_appointment', false, [])
  const dispatch = useDispatch()
  const [isVisible, setIsVisible] = useState(false);
  const [isExecuting, setisExecuting] = useState(false)
  const [editingForm, setEditingForm] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  const can_view_appointment = usePermissionCheck('view_appointment', false, [])
  const can_delete_appointment = usePermissionCheck('delete_appointment', false, [])
  const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  // useEffect(() => {
  //   if (!!message) {
  //     console.log('MQTT MESSAGE:  ', message)
  //     loadAppointments(true).catch(e=>console.log(e))
  //   }
  //   return emptyFun
  // }, [message])
  let onCell = (record, rowIndex) => ({
    onClick: event => {
      (async () => {
        console.log({ record })
        
        if (record.id !== navs.selectedAppointmentID) {
          await dispatch(resetAppointmentModuleState())
        }
        // await dispatch(fetchSpecificAppointment(record.id))
        // await dispatch(selectAppointmentPatient(record.patient))
        await dispatch(selectAppointment({navigate, record: record}))
        await dispatch(setSelectAppointmentID(record.id))
        if (can_view_appointment) {
          navigate(`/appointments/${record.id}`)
        }
      })().catch(e => console.log(e))
    }
  })
  const columns = [{
    title: 'Patient', dataIndex: 'patient_name', key: 'patient_name',
    /* sorter: (a, b) => a.name - b.name, fltr: {filterable: true, type: 'text'},*/ render: (patient_name) => (
      <div className="flex-boy-row-space" style={{ justifyContent: 'normal' }}>
        <Subtitle1 style={{ textAlign: "left", marginLeft: '10px' }}>{patient_name}</Subtitle1>
      </div>), onCell
  }, {
    title: 'Date of appointment', dataIndex: 'start_at', key: 'date', fltr: { filterable: true, type: 'date' },
    sorter: { multiple: 3, compare: (a, b) => dayjs(a.start_at).unix() - dayjs(b.start_at).unix() }, render: (start_at, record) => (
      <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(start_at).format("MM/DD/YYYY")}</Subtitle1>), onCell
  }, {
    title: 'Time Slot', dataIndex: 'start_at', key: 'time_slot', render: (start_at, record) => (<Subtitle1
      style={{ textAlign: 'left' }}>{dayjs(start_at).format("h:mm a")} -{dayjs(record.end_at).format("h:mm a")}</Subtitle1>),
    onCell
  }, {
    title: 'Office', dataIndex: 'medical_facility', key: 'facility',
    fltr: { filterable: true, type: 'text' }, onCell
  }, {
    title: 'Visit Type', dataIndex: 'appointment_visit_type', key: 'visitType', fltr: {
      filterable: true, type: 'select', options: makeSelectOptions(['established visit', 'new visit'])
    }, onCell
  }, {
    title: 'Provider', dataIndex: 'consultant_name', key: 'wcc', fltr: { filterable: true, type: 'text' }, onCell
  }, {
    title: 'Appointment Status', dataIndex: 'status', key: 'status', fltr: {
      filterable: true, type: 'select',
      options: makeSelectOptions(['scheduled', 'rescheduled', 'cancelled_by_patient', 'cancelled_by_wcc', 'missed', 'completed', 'in_progress', 'missed_by_wcc', 'missed_by_patient', 'visited'])
    }, render: (status) => getAppointmentStatus(status?.toLowerCase()), onCell
  },
    {
      fltr: { filterable: false }, render: (record) => {
        const actionMenus = kebabMenus((record.status !== 'completed' && can_update_appointment), can_view_audit_logs, can_delete_appointment)
        return <KebabMenu menus={actionMenus}
                          recordItem={record}
                          handleMenuClick={kebabMenuClick} resource={'appointment'} handleOk={() => handleOk(record.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
                          showConfirmDialog={selected === record.id}/>;
      }
    },
    // {
    //   title: <FontAwesomeIcon color="grey" icon={['fas', 'table']}/>,
    //   key: 'telehealth_appointment',
    //   width: 70,
    //   render: (record) => <>
    //     {record.telehealth_appointment && <Tag color="gainsboro" style={{ color: 'black' }}>Telehealth</Tag>}
    //   </>
    // }
  ]
  const [selected, setSelected] = React.useState(null);
  const handleCancel = () => setSelected(0);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const showPopconfirm = (id) => setSelected(id);
  const actionMenus = kebabMenus(can_update_appointment, can_view_audit_logs, can_delete_appointment)
  
  async function kebabMenuClick(e, record) {
    await setAppointment(record)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await handleedit(record.id)
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
    }
  }
  
  const handleedit = async (id) => {
    const toastId = getToastId("Fetching appointment details")
    const response = await apiRequest.get(`/appointments/${id}`).catch(e => console.log(e))
    if (response?.data?.data) {
      updateToast(toastId, 'Fetched', true);
      await setAppointment(response?.data?.data)
      await setEditingForm(true)
      await setOpen(true)
    }
    
  }
  
  const handleOk = async (id) => {
    setappointmentTodeleteID(id)
    setreasonModal(true)
    setSelected(0)
  };
  
  
  const [appointmentTodeleteID, setappointmentTodeleteID] = useState(null)
  const [reasonStatus, setreasonStatus] = useState(null)
  const [reasonModal, setreasonModal] = useState(false)
  const reasonFinish = async (values) => {
    await setConfirmLoading(true);
    await dispatch(deleteAppointment({ appointment_id: appointmentTodeleteID, ...values }));
    await setSelected(0);
    await setConfirmLoading(false);
    setreasonModal(false)
  }
  
  const [selectedTab, setselectedTab] = useState("1")
  const callback = (key) => {
    setselectedTab(key)
  }
  const Tabs_ = () => {
    return (
      <Tabs defaultActiveKey={selectedTab} onChange={callback}>
        <Tabs.TabPane tab={<Subtitle1>{editingForm ? 'Edit appointment' : "New Appointment"}</Subtitle1>} key="1">
        
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab={<Subtitle1>Add Time Blocker</Subtitle1>} key="2">
        
        </Tabs.TabPane> */}
      
      </Tabs>
    )
  }
  return (
    (<div className="w-full h-full">
      {(!!appointment && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false}
                                                             resourceDescription={`${appointment.patient.name}'s appointment`} resourceType={'Appointment'}
                                                             defaultValues={{ auditableId: appointment?.id, auditable: 'appointment', format: 'json' }}/>}
      <Modal
        title={Tabs_()}
        open={isOpen}
        onOk={dialogCloser(setOpen)}
        onCancel={dialogCloser(setOpen)}
        closeIcon={modalClose(dialogCloser(setOpen))}
        maskClosable={false}
        destroyOnClose={true}
        width={650}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setOpen), 'Cancel', isExecuting),
            formSubmitButton('appointmentForm', editingForm ? 'Update' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
        </div>]}>
        {selectedTab === "1" ?
          <SheduleAppointment handleFilterAction={handleFilterAction} appointment={editingForm ? appointment : null} editingForm={editingForm} setisExecuting={setisExecuting}
                              closeModal={dialogCloser(setOpen)}/>
          : <Timeblocker/>}
      </Modal>
      <Modal
        title={`Please add a Reason`}
        open={reasonModal}
        destroyOnClose={true}
        
        onOk={() => setreasonModal(false)}
        onCancel={() => setreasonModal(false)}
        closeIcon={modalClose(() => setreasonModal(false))}
        maskClosable={false}
        width={1200}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(() => {
            setreasonModal(false)
            setSelected(0)
          }, 'Cancel', isExecuting),
            formSubmitButton('reasonForm', 'Add Reason', isExecuting, 'Updating')])}
        </div>]}
      >
        <Form
          name="reasonForm"
          onFinish={reasonFinish}
          layout="vertical"
        >
          
          <Form.Item
            style={{ width: '100%' }}
            label="Delete Reason"
            name="delete_reason"
            rules={[{ required: true, message: 'this field is required!' }]}
            wrapperCol={{ span: 24 }}
          >
            <Input autoComplete={'off'}/>
          </Form.Item>
        </Form>
      
      </Modal>
      <div className="w-full">
        
        
        <Table
          /*title={() => (
            <TableFilters datasource={[...columns, stateFilter, procedureFilter]}
              setIsVisible={setIsVisible} isVisible={isVisible} windowState={windowState}
              handleFilterAction={handleFilterAction} filters={appConf.appointmentFilters[1]}
              showClear={appConf.appointmentFilters[0]} loading={appConf.appointmentFilters[2]}
              actionButton={can_create_appointment ? containedButton(() => {
                setEditingForm(false)
                setOpen(true);
              }, 'Add New', false, null, null, { icon: <PlusOutlined /> }) : null}
            />
          )}*/
          loading={appConf.scheduleFilters[2]} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('appointments') }}
          onChange={(pagination, filters, sorter) => {
            
            console.log(pagination)
            onTableChange(pagination, filters, sorter, dispatch, null, appConf.scheduleFilters, updateScheduleFilters, null, schedulePagination)
            handleFilterAction('filter', { pagination: { ...pagination } })
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
              } // click row
            };
          }}
          {...getSkels((appConf.scheduleFilters[2]), columns, appointments)} pagination={schedulePagination}/>
      </div>
    </div>)
  );
}
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser, appointments: state.appointments.scheduledAppointments,
  loading: state.appointments.loading, appConf: state.appConf, navs: state.navs,
  schedulePagination: state.appointments.schedulePagination
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleAppointmentList)
