/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Headline6} from '../../../../components/general'
import {Col, Row} from 'antd';
import {emptyFun, boldKeyCapitalizedValue, makeAutoCompleteOptions} from "../../../../common/helpers";
import {apiRequest} from '../../../../app/Apis';
// import { Headline6 } from '../../../../components';

const VisitDetails = ({
                        assessment,  seteditassessmentModal, VisitModal, handlevisitCancel, setVisit, procedures,
                        approveAsessment, setStatusModal, generateReport, generateAllograftReport, isExecuting, isPrinting, isAllograftPrinting, masterListRaw, setShowAuditDialog
                      }) => {
  const [assessmentForm, setassessmentForm] = useState(null)
  const [assessmentDetail, setassessmentDetail] = useState(null)
  const [masterLocations, setMasterLocations] = useState(masterListRaw[0] ? masterListRaw.find(item => item.system_name === 'wound_location')?.options : [])
  const [woundLocations, setwoundLocations] = useState((!!masterLocations && masterLocations[0]) ? makeAutoCompleteOptions(...[masterListRaw.find(item => item.system_name === 'wound_location')?.options]) : [])
  useEffect(() => {
    // console.log('VisitDetails: ', assessment)
    // fetchWoundLocations(setwoundLocations)
    let data = {...assessment}
    data.substantiated_by = data.arterial_perfusion === "non-contributory" ? null : data.substantiated_by
    data.require_vascular_testing = data.arterial_perfusion === "non-contributory" ? null : data.require_vascular_testing
    data.vascular_testing = data.arterial_perfusion === "non-contributory" || data.require_vascular_testing === "no" ? null : data.vascular_testing
    setassessmentDetail({...data})
    // fetchAsssessmentForm(setassessmentForm, "Plan Of Care", procedures)
    return emptyFun
  }, [assessment])
  
  const first_categories = [
    
    "Treatment Goals",
    "Vascular Status"
  
  
  ]
  const second_categories = [
    "Nutrition Status",
    "Nutritional Status",
    "Ongoing Treatment Plan"
  ]
  
  const getForm = async () => {
    let data = await apiRequest.get("/procedures/Plan Of Care/assessment-form").catch(err => console.log(err))
    if (data && data.data.form) {
      setassessmentForm(data.data.form)
    }
    
  }
  useEffect(() => {
    getForm()
    return emptyFun
  }, [])
  
  return (
    <>
      <div>
        <Headline6 style={{marginTop: '10px', marginBottom: '5px'}}>Plan of care</Headline6>
      </div>
      
      {assessmentDetail && <div className="visit-card">
        
        <Row gutter={48} style={{width: "100%"}}>
          <Col span={12}>
            {assessmentForm?.filter(item => item.type === "date" && assessmentDetail[item.field_name]).map(item => {
              return (boldKeyCapitalizedValue(item.label === "Summary Until Date" ? "Summary To Date" : item.label, assessmentDetail[item.field_name]?.toString()))
            })
            }
            <Headline6 style={{marginTop: '10px', marginBottom: '5px'}}>Treatment Goals</Headline6>
            {assessmentForm?.filter(item => item.category === "Treatment Goals").map(item => {
              return (boldKeyCapitalizedValue(item.label === "Summary Until Date" ? "Summary To Date" : item.label, assessmentDetail[item.field_name]?.toString()))
            })
            }
            <Headline6 style={{marginTop: '10px', marginBottom: '5px'}}>Vascular Status</Headline6>
            {assessmentForm?.filter(item => item.category === "Vascular Status" && item.type !== "date" && assessmentDetail[item.field_name]).map(item => {
              return (boldKeyCapitalizedValue(item.label === "Summary Until Date" ? "Summary To Date" : item.label, assessmentDetail[item.field_name]?.toString()))
            })
            }
          
          </Col>
          <Col span={12}>
            <Headline6 style={{marginTop: '10px', marginBottom: '5px'}}>Nutritional Status</Headline6>
            {assessmentForm?.filter(item => item.category === "Nutrition Status").map(item => {
              return (boldKeyCapitalizedValue(item.label === "Summary Until Date" ? "Summary To Date" : item.label, assessmentDetail[item.field_name]?.toString()))
            })
            }
            
            {assessmentForm?.filter(item => item.category === "Nutritional Status").map(item => {
              return (boldKeyCapitalizedValue(item.label === "Summary Until Date" ? "Summary To Date" : item.label, assessmentDetail[item.field_name]?.toString()))
            })
            }
            
            <Headline6 style={{marginTop: '10px', marginBottom: '5px'}}>Ongoing Treatment Plan</Headline6>
            {assessmentForm?.filter(item => item.category === "Ongoing Treatment Plan").map(item => {
              return (boldKeyCapitalizedValue(item.label === "Summary Until Date" ? "Summary To Date" : item.label, assessmentDetail[item.field_name]?.toString()))
            })
            }
            
            <Headline6 style={{marginTop: '20px', marginBottom: '5px'}}></Headline6>
            {assessmentForm?.filter(item => item.category === "Orders").map(item => {
              return (boldKeyCapitalizedValue(item.label === 'Recommended Interventions' ? 'Recommended Interventions/Orders' : item.label, assessmentDetail[item.field_name]?.toString()))
            })
            }
          
          
          </Col>
        
        </Row>
      </div>}
    </>
  )
}
const mapStateToProps = (state) => ({
  masterListRaw: state.masters.masterListRaw,
  procedures: state.appointments.procedures
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(VisitDetails)
