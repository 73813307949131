import React, {useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {
  closeButton,
  containedButton,
  deleteButton,
  dialogCloser,
  editButton,
  emptyTable,
  executeChunkFn,
  formSubmitButton,
  getSkels,
  getTaskStatus,
  horStack,
  kebabMenus,
  outlinedButton
} from '../../../common/helpers'
import {Avatar, Modal, Popconfirm, Table, Tag} from 'antd'
import {Headline6, Subtitle1} from '../../../components'
import dayjs from 'dayjs'
import NewTask from './NewTask'
import ViewTask from './ViewTask'
import {deletePatientTask, selectPatientTask} from '../../../app/Reducers'
import KebabMenu from '../../../components/KebabMenu';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {deleteReasonInputFields} from '../../../common/inputUtils';
import DynamicModalForm from '../../../components/DynamicModalForm';
import * as Sentry from '@sentry/react';
import {errMsg, toastUp} from '../../../app/Reducers/reducerUtils';
import AuditLogViewer from '../../../components/AuditLogs/AuditLogViewer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {EditOutlined, PlusOutlined} from '@ant-design/icons';

export const PatientTasksListView = ({ tasks, patientTaskPagination, appConf, selectedTask,activeUser }) => {
  const dispatch = useDispatch()
  const [selected, setSelected] = React.useState(null);
  const [isOpen, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [actionMenuRecord, setActionMenuRecord] = useState(null);
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_task = usePermissionCheck('update_task', false, [])
  const can_delete_task = usePermissionCheck('delete_task', false, [])
  const addTask = () => {
    setEditingForm(false)
    setNewTaskModal(true)
    // setContent(<PatientTaskForm patient={patient} />)
  }
  let onCell = (record, rowIndex) => ({
    onClick: async event => {
      await dispatch(selectPatientTask(record))
      viewTaskModalOpen()
    }
  })
  const actionMenus = kebabMenus(can_update_task, can_view_audit_logs, can_delete_task)
  const columns = [
    { title: 'Task', dataIndex: 'description', key: 'description', fltr: { filterable: true, type: 'string' }, onCell },
    { title: 'Type', dataIndex: 'task_type', key: 'type', fltr: { filterable: true, type: 'string' }, onCell },
    { title: 'ID', dataIndex: 'id', key: 'id', fltr: { filterable: false, type: 'string' }, onCell },
    {
      title: 'Assigned to',
      dataIndex: 'assignee',
      key: 'assignee',
      fltr: { filterable: true, type: 'string' },
      render: (assignee, record) => (
        <div className="flex flex-row items-center">
          <Avatar className="mr-2" src={assignee?.profile_image_url} alt={assignee?.name}>{assignee?.name?.charAt(0)}</Avatar>
          <Subtitle1 className="ml-2">{assignee.name}</Subtitle1>
        </div>
      ), onCell
    }
    ,
    
    // patient
    { title: 'Patient', dataIndex: ['patient', 'name'], key: 'patient', fltr: { filterable: true, type: 'string' }, onCell },
    
    
    { title: 'Department', dataIndex: ['department', 'name'], key: 'department', fltr: { filterable: true, type: 'string' }, onCell },
    
    {
      title: 'Due Date', dataIndex: ['due_date'], key: 'expires_at', fltr: { filterable: true, type: 'date' },
      render: (due_date, record) => <Subtitle1 style={{ textAlign: 'left' }}>{due_date ? dayjs(due_date).format("MM/DD/YYYY") : ""}</Subtitle1>, onCell
    },
    // creation date
    {
      title: 'Created', dataIndex: ['created_at'], key: 'created_at', fltr: { filterable: true, type: 'date' },
      render: (created_at, record) => <Subtitle1 style={{ textAlign: 'left' }}>{created_at ? dayjs(created_at).format("MM/DD/YYYY") : ""}</Subtitle1>, onCell
    },
    // reporter
    { title: 'Reporter', dataIndex: ['reporter', 'name'], key: 'reporter', fltr: { filterable: true, type: 'string' } },
    // priority
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      fltr: { filterable: true, type: 'string' },
      render: (priority) => <Tag className="capitalize" color={priorityColors[priority]}>{priority}</Tag>,
      onCell
    },
    // status
    { title: 'Status', dataIndex: 'status', key: 'status', fltr: { filterable: true, type: 'string' }, render: (status) => getTaskStatus(status), onCell },
    {
      fltr: { filterable: false }, render: (record) => {
        return <KebabMenu
          menus={actionMenus}
          recordItem={record}
          handleMenuClick={kebabMenuClick}
          resource="task"
          handleOk={() => handleOk(record)}
          showConfirmDialog={selected === record.id}
          handleCancel={handleCancel}
          confirmLoading={confirmLoading}
        />;
      }
    },
    ...(activeUser?.is_qa_user?[{ width:15,
      visible: true,
      title: <FontAwesomeIcon color="grey" icon={['fas', 'table']}/>,
      key: 'operation',
      render: (record) => <Tag className={`${(record.is_qa && activeUser?.is_qa_user)?'visible':'invisible'}`} icon={<EditOutlined/>} color="#55acee">QA</Tag>
    }]:[])
  
  ]
  
  //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
  let dynamicFormInputFields = { 'Delete Task': deleteReasonInputFields }
  // priority tag colors
  const priorityColors = {
    low: '#AF78FF',
    normal: '#7785FF',
    high: 'crimson'
  }
  
  
  const handleOk = async (record) => {
    await setActionMenuRecord(record)
    await setFormName('Delete Task');
    await setDefaultDynamicModalFormValues(!!record ? { id: record.id } : undefined);
    await setDynamicFormOpen(true);
    setSelected(0)
  };
  
  const handleCancel = () => setSelected(0);
  const showPopconfirm = (id) => setSelected(id);
  
  async function kebabMenuClick(e, record) {
    // console.log({actionMenus, e, record, title: actionMenus[e.key].title })
    await setActionMenuRecord(record)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await dispatch(selectPatientTask(record))
        viewTaskModalOpen()
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
    }
  }
  
  // new task modal
  const [newTaskModal, setNewTaskModal] = useState(false)
  
  const newTaskModalOpen = () => {
    setNewTaskModal(true)
  }
  const newTaskModalClose = () => {
    setNewTaskModal(false)
  }
// is executing
  const [isExecuting, setIsExecuting] = useState(false)

// viw task modal
  const [viewTaskModal, setViewTaskModal] = useState(false)
  
  const viewTaskModalOpen = () => {
    setViewTaskModal(true)
  }
  const viewTaskModalClose = () => {
    setViewTaskModal(false)
  }
  const removeTask = async (id, cb) => {
    await dispatch(deletePatientTask({ id }))
    cb()
  }
  
  const [editingForm, setEditingForm] = useState(true)
  
  
  /**
   * This function is handling returned form entries from the dynamic form
   * */
  const onDynamicFormEntriesResult = async (entries) => {
    //console.log({entries})
    try {
      switch (formName) {
        case 'Delete Task':
          await executeChunkFn(dispatch, deletePatientTask, { ...entries.values }, setIsExecuting, dialogCloser(setDynamicFormOpen), dialogCloser(setViewTaskModal))
          break;
        default:
          return
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      toastUp(errMsg(e, false, formName), false);
    }
  };
  return (<>
    {(!!actionMenuRecord && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false}
                                                                resourceDescription={`${actionMenuRecord.patient.name}'s task`} resourceType={'Patient Task'}
                                                                defaultValues={{ auditableId: actionMenuRecord?.id, auditable: 'task', format: 'json' }}/>}
    {(isDynamicFormOpen && !!formName) && (
      <DynamicModalForm
        setDynamicFormOpen={setDynamicFormOpen}
        isDynamicFormOpen={isDynamicFormOpen}
        inputFields={dynamicFormInputFields[formName]}
        onDynamicFormEntriesResult={onDynamicFormEntriesResult}
        closeModal={dialogCloser(setDynamicFormOpen)}
        isExecuting={isExecuting}
        defaultValues={defaultDynamicModalFormValues}
        formName={formName}
      />
    )}
    {/*  new taksk modal */}
    <Modal
      title="New Task"
      open={newTaskModal}
      onCancel={newTaskModalClose}
      
      width={600}
      destroyOnClose={true}
      maskClosable={false}
      centered={true}
      className="new-task-modal"
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        {horStack([outlinedButton(dialogCloser(setNewTaskModal), 'Cancel', isExecuting),
          formSubmitButton('taskForm', editingForm ? 'Update' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
      
      </div>]}
    >
      <NewTask closeModal={newTaskModalClose} editingForm={editingForm} isExecuting={isExecuting} setIsExecuting={setIsExecuting}/>
    </Modal>
    {/* view task modal */}
    <Modal
      closable={false}
      title={<div className="flex flex-1" style={{ justifyContent: 'space-between' }}>
        <Headline6>View Task</Headline6>
        <div className="flex flex-1 flex-row items-center " style={{ justifyContent: 'flex-end' }}>
          <Popconfirm
            placement="top"
            title="Are you sure to delete this task?"
            description=""
            onConfirm={() => {
              removeTask(selectedTask.id, viewTaskModalClose)
            }}
            okText="Yes"
            cancelText="No"
          >
            {deleteButton('Delete', isExecuting, () => {
            }, 'Delete Task')}
          </Popconfirm>
          
          {editButton(() => {
            setEditingForm(true)
            newTaskModalOpen()
          })}
          {closeButton(() => {
            viewTaskModalClose()
          })}
        </div>
      
      
      </div>}
      
      open={viewTaskModal}
      onCancel={viewTaskModalClose}
      onOk={viewTaskModalClose}
      width={1000}
      destroyOnClose={true}
      maskClosable={false}
      centered={true}
      className="view-task-modal"
    
    >
      <ViewTask/>
    </Modal>
    {tasks.length > 0 ?
      
      <div className="w-full">
        <Table
          
          
          locale={{
            emptyText: !appConf?.patientTaskFilters?.[0] ? emptyTable('patient tasks') : emptyTable('patient tasks.')
          }}
          // onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchPatientDocuments, appConf.patientDocFilters, updatePatientDocFilters, { patient: patientId })}
          {...getSkels(appConf?.patientTaskFilters?.[2], columns, tasks)} pagination={patientTaskPagination}/>
      </div>
      
      : emptyTable('Document', containedButton(addTask, 'Add Task', false, null, {}, {
        icon: <PlusOutlined />
      }))}
  </>)
}

const mapStateToProps = (state) => ({
  
  activeUser: state.auth?.activeUser,
  patient: state.patients.selectedPatient,

  patientTaskPagination: state.patients.patientTaskPagination,
  appConf: state.appConf,
  tasks: state.patients.tasks,
  selectedTask: state.patients.selectedTask
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PatientTasksListView)