export const orderStatuses = ["cancelled", "pending fulfillment", "warehouse alert", "label created", "shipped", "delivered"]
export const woundStatuses = ['unchanged', 'improving', 'deteriorating', 'new', 'healed', 'resolved', 'stalled']
export const subRegions = ['New England', 'Middle Atlantic', 'East North Central', 'West North Central', 'South Atlantic', 'East South Central', 'West South Central', 'Mountain', 'Pacific']
export const defaultAreas = [
  {
    emoji: '🇨🇦',
    phoneCode: 1,
    allowed: true,
    short: 'CA'
  },
  {
    emoji: '🇺🇸',
    phoneCode: 1,
    allowed: true,
    short: 'US'
  },
  {
    emoji: '🇧🇸',
    phoneCode: 1242,
    short: 'BS'
  },
  {
    emoji: '🇧🇧',
    phoneCode: 1246,
    short: 'BB'
  },
  {
    emoji: '🇦🇮',
    phoneCode: 1264,
    short: 'AI'
  },
  {
    emoji: '🇦🇬',
    phoneCode: 1268,
    short: 'AG'
  },
  {
    emoji: '🇻🇮',
    phoneCode: 1284,
    short: 'VI'
  },
  {
    emoji: '🇻🇬',
    phoneCode: 1340,
    short: 'VG'
  },
  {
    emoji: '🇰🇾',
    phoneCode: 1345,
    short: 'KY'
  },
  {
    emoji: '🇧🇲',
    phoneCode: 1441,
    short: 'BM'
  },
  {
    emoji: '🇬🇩',
    phoneCode: 1473,
    short: 'GD'
  },
  {
    emoji: '🇹🇨',
    phoneCode: 1649,
    short: 'TC'
  },
  {
    emoji: '🇲🇸',
    phoneCode: 1664,
    short: 'MS'
  },
  {
    emoji: '🇬🇺',
    phoneCode: 1671,
    short: 'GU'
  },
  {
    emoji: '🇦🇸',
    phoneCode: 1684,
    short: 'AS'
  },
  {
    emoji: '🇳🇱',
    phoneCode: 1721,
    short: 'SX'
  },
  {
    emoji: '🇱🇨',
    phoneCode: 1758,
    short: 'LC'
  },
  {
    emoji: '🇩🇲',
    phoneCode: 1767,
    short: 'DM'
  },
  {
    emoji: '🇻🇨',
    phoneCode: 1784,
    short: 'VC'
  },
  {
    emoji: '🇵🇷',
    phoneCode: 1787,
    short: 'PR'
  },
  {
    emoji: '🇩🇴',
    phoneCode: 1809,
    short: 'DO'
  },
  {
    emoji: '🇹🇹',
    phoneCode: 1868,
    short: 'TT'
  },
  {
    emoji: '🇰🇳',
    phoneCode: 1869,
    short: 'KN'
  },
  {
    emoji: '🇯🇲',
    phoneCode: 1876,
    short: 'JM'
  },
  {
    emoji: '🇪🇬',
    phoneCode: 20,
    short: 'EG'
  },
  {
    emoji: '🇲🇦',
    phoneCode: 212,
    short: 'MA'
  },
  {
    emoji: '🇩🇿',
    phoneCode: 213,
    short: 'DZ'
  },
  {
    emoji: '🇹🇳',
    phoneCode: 216,
    short: 'TN'
  },
  {
    emoji: '🇱🇾',
    phoneCode: 218,
    short: 'LY'
  },
  {
    emoji: '🇬🇲',
    phoneCode: 220,
    short: 'GM'
  },
  {
    emoji: '🇸🇳',
    phoneCode: 221,
    short: 'SN'
  },
  {
    emoji: '🇲🇷',
    phoneCode: 222,
    short: 'MR'
  },
  {
    emoji: '🇲🇱',
    phoneCode: 223,
    short: 'ML'
  },
  {
    emoji: '🇬🇳',
    phoneCode: 224,
    short: 'GN'
  },
  {
    emoji: '🇨🇮',
    phoneCode: 225,
    short: 'CI'
  },
  {
    emoji: '🇧🇫',
    phoneCode: 226,
    short: 'BF'
  },
  {
    emoji: '🇳🇪',
    phoneCode: 227,
    short: 'NE'
  },
  {
    emoji: '🇹🇬',
    phoneCode: 228,
    short: 'TG'
  },
  {
    emoji: '🇧🇯',
    phoneCode: 229,
    short: 'BJ'
  },
  {
    emoji: '🇲🇺',
    phoneCode: 230,
    short: 'MU'
  },
  {
    emoji: '🇱🇷',
    phoneCode: 231,
    short: 'LR'
  },
  {
    emoji: '🇸🇱',
    phoneCode: 232,
    short: 'SL'
  },
  {
    emoji: '🇬🇭',
    phoneCode: 233,
    short: 'GH'
  },
  {
    emoji: '🇳🇬',
    phoneCode: 234,
    short: 'NG'
  },
  {
    emoji: '🇹🇩',
    phoneCode: 235,
    short: 'TD'
  },
  {
    emoji: '🇨🇫',
    phoneCode: 236,
    short: 'CF'
  },
  {
    emoji: '🇨🇲',
    phoneCode: 237,
    short: 'CM'
  },
  {
    emoji: '🇨🇻',
    phoneCode: 238,
    short: 'CV'
  },
  {
    emoji: '🇸🇹',
    phoneCode: 239,
    short: 'ST'
  },
  {
    emoji: '🇬🇶',
    phoneCode: 240,
    short: 'GQ'
  },
  {
    emoji: '🇬🇦',
    phoneCode: 241,
    short: 'GA'
  },
  {
    emoji: '🇨🇬',
    phoneCode: 242,
    short: 'CG'
  },
  {
    emoji: '🇨🇩',
    phoneCode: 243,
    short: 'CD'
  },
  {
    emoji: '🇦🇴',
    phoneCode: 244,
    short: 'AO'
  },
  {
    emoji: '🇬🇼',
    phoneCode: 245,
    short: 'GW'
  },
  {
    emoji: '🇸🇨',
    phoneCode: 248,
    short: 'SC'
  },
  {
    emoji: '🇸🇩',
    phoneCode: 249,
    short: 'SD'
  },
  {
    emoji: '🇷🇼',
    phoneCode: 250,
    short: 'RW'
  },
  {
    emoji: '🇪🇹',
    phoneCode: 251,
    short: 'ET'
  },
  {
    emoji: '🇸🇴',
    phoneCode: 252,
    short: 'SO'
  },
  {
    emoji: '🇩🇯',
    phoneCode: 253,
    short: 'DJ'
  },
  {
    emoji: '🇰🇪',
    phoneCode: 254,
    allowed: true,
    short: 'KE'
  },
  {
    emoji: '🇹🇿',
    phoneCode: 255,
    short: 'TZ'
  },
  {
    emoji: '🇺🇬',
    phoneCode: 256,
    short: 'UG'
  },
  {
    emoji: '🇧🇮',
    phoneCode: 257,
    short: 'BI'
  },
  {
    emoji: '🇲🇿',
    phoneCode: 258,
    short: 'MZ'
  },
  {
    emoji: '🇿🇲',
    phoneCode: 260,
    short: 'ZM'
  },
  {
    emoji: '🇲🇬',
    phoneCode: 261,
    short: 'MG'
  },
  {
    emoji: '🇷🇪',
    phoneCode: 262,
    short: 'RE'
  },
  {
    emoji: '🇿🇼',
    phoneCode: 263,
    short: 'ZW'
  },
  {
    emoji: '🇳🇦',
    phoneCode: 264,
    short: 'NA'
  },
  {
    emoji: '🇲🇼',
    phoneCode: 265,
    short: 'MW'
  },
  {
    emoji: '🇱🇸',
    phoneCode: 266,
    short: 'LS'
  },
  {
    emoji: '🇧🇼',
    phoneCode: 267,
    short: 'BW'
  },
  {
    emoji: '🇸🇿',
    phoneCode: 268,
    short: 'SZ'
  },
  {
    emoji: '🇰🇲',
    phoneCode: 269,
    short: 'KM'
  },
  {
    emoji: '🇾🇹',
    phoneCode: 269,
    short: 'YT'
  },
  {
    emoji: '🇿🇦',
    phoneCode: 27,
    short: 'ZA'
  },
  {
    emoji: '🇪🇷',
    phoneCode: 291,
    short: 'ER'
  },
  {
    emoji: '🇦🇼',
    phoneCode: 297,
    short: 'AW'
  },
  {
    emoji: '🇫🇴',
    phoneCode: 298,
    short: 'FO'
  },
  {
    emoji: '🇬🇱',
    phoneCode: 299,
    short: 'GL'
  },
  {
    emoji: '🇬🇷',
    phoneCode: 30,
    short: 'GR'
  },
  {
    emoji: '🇳🇱',
    phoneCode: 31,
    short: 'NL'
  },
  {
    emoji: '🇧🇪',
    phoneCode: 32,
    short: 'BE'
  },
  {
    emoji: '🇫🇷',
    phoneCode: 33,
    short: 'FR'
  },
  {
    emoji: '🇪🇸',
    phoneCode: 34,
    short: 'ES'
  },
  {
    emoji: '🇬🇮',
    phoneCode: 350,
    short: 'GI'
  },
  {
    emoji: '🇵🇹',
    phoneCode: 351,
    short: 'PT'
  },
  {
    emoji: '🇱🇺',
    phoneCode: 352,
    short: 'LU'
  },
  {
    emoji: '🇮🇪',
    phoneCode: 353,
    short: 'IE'
  },
  {
    emoji: '🇮🇸',
    phoneCode: 354,
    short: 'IS'
  },
  {
    emoji: '🇦🇱',
    phoneCode: 355,
    short: 'AL'
  },
  {
    emoji: '🇲🇹',
    phoneCode: 356,
    short: 'MT'
  },
  {
    emoji: '🇨🇾',
    phoneCode: 357,
    short: 'CY'
  },
  {
    emoji: '🇫🇮',
    phoneCode: 358,
    short: 'FI'
  },
  {
    emoji: '🇧🇬',
    phoneCode: 359,
    short: 'BG'
  },
  {
    emoji: '🇭🇺',
    phoneCode: 36,
    short: 'HU'
  },
  {
    emoji: '🇱🇹',
    phoneCode: 370,
    short: 'LT'
  },
  {
    emoji: '🇱🇻',
    phoneCode: 371,
    short: 'LV'
  },
  {
    emoji: '🇪🇪',
    phoneCode: 372,
    short: 'EE'
  },
  {
    emoji: '🇲🇩',
    phoneCode: 373,
    short: 'MD'
  },
  {
    emoji: '🇦🇲',
    phoneCode: 374,
    short: 'AM'
  },
  {
    emoji: '🇧🇾',
    phoneCode: 375,
    short: 'BY'
  },
  {
    emoji: '🇦🇩',
    phoneCode: 376,
    short: 'AD'
  },
  {
    emoji: '🇲🇨',
    phoneCode: 377,
    short: 'MC'
  },
  {
    emoji: '🇸🇲',
    phoneCode: 378,
    short: 'SM'
  },
  {
    emoji: '🇺🇦',
    phoneCode: 380,
    short: 'UA'
  },
  {
    emoji: '🇷🇸',
    phoneCode: 381,
    short: 'RS'
  },
  {
    emoji: '🇲🇪',
    phoneCode: 382,
    short: 'ME'
  },
  {
    emoji: '🇭🇷',
    phoneCode: 385,
    short: 'HR'
  },
  {
    emoji: '🇸🇮',
    phoneCode: 386,
    short: 'SI'
  },
  {
    emoji: '🇧🇦',
    phoneCode: 387,
    short: 'BA'
  },
  {
    emoji: '🇲🇰',
    phoneCode: 389,
    short: 'MK'
  },
  {
    emoji: '🇮🇹',
    phoneCode: 39,
    short: 'IT'
  },
  {
    emoji: '🇷🇴',
    phoneCode: 40,
    short: 'RO'
  },
  {
    emoji: '🇨🇭',
    phoneCode: 41,
    short: 'CH'
  },
  {
    emoji: '🇨🇿',
    phoneCode: 420,
    short: 'CZ'
  },
  {
    emoji: '🇸🇰',
    phoneCode: 421,
    short: 'SK'
  },
  {
    emoji: '🇱🇮',
    phoneCode: 423,
    short: 'LI'
  },
  {
    emoji: '🇦🇹',
    phoneCode: 43,
    short: 'AT'
  },
  {
    emoji: '🇬🇧',
    phoneCode: 44,
    allowed: true,
    short: 'GB'
  },
  {
    emoji: '🇩🇰',
    phoneCode: 45,
    short: 'DK'
  },
  {
    emoji: '🇸🇪',
    phoneCode: 46,
    short: 'SE'
  },
  {
    emoji: '🇳🇴',
    phoneCode: 47,
    short: 'NO'
  },
  {
    emoji: '🇵🇱',
    phoneCode: 48,
    short: 'PL'
  },
  {
    emoji: '🇩🇪',
    phoneCode: 49,
    short: 'DE'
  },
  {
    emoji: '🇧🇿',
    phoneCode: 501,
    short: 'BZ'
  },
  {
    emoji: '🇬🇹',
    phoneCode: 502,
    short: 'GT'
  },
  {
    emoji: '🇸🇻',
    phoneCode: 503,
    short: 'SV'
  },
  {
    emoji: '🇭🇳',
    phoneCode: 504,
    short: 'HN'
  },
  {
    emoji: '🇳🇮',
    phoneCode: 505,
    short: 'NI'
  },
  {
    emoji: '🇨🇷',
    phoneCode: 506,
    short: 'CR'
  },
  {
    emoji: '🇵🇦',
    phoneCode: 507,
    short: 'PA'
  },
  {
    emoji: '🇵🇲',
    phoneCode: 508,
    short: 'PM'
  },
  {
    emoji: '🇭🇹',
    phoneCode: 509,
    short: 'HT'
  },
  {
    emoji: '🇵🇪',
    phoneCode: 51,
    short: 'PE'
  },
  {
    emoji: '🇲🇽',
    phoneCode: 52,
    short: 'MX'
  },
  {
    emoji: '🇨🇺',
    phoneCode: 53,
    short: 'CU'
  },
  {
    emoji: '🇦🇷',
    phoneCode: 54,
    short: 'AR'
  },
  {
    emoji: '🇧🇷',
    phoneCode: 55,
    short: 'BR'
  },
  {
    emoji: '🇨🇱',
    phoneCode: 56,
    short: 'CL'
  },
  {
    emoji: '🇨🇴',
    phoneCode: 57,
    short: 'CO'
  },
  {
    emoji: '🇻🇪',
    phoneCode: 58,
    short: 'VE'
  },
  {
    emoji: '🇬🇵',
    phoneCode: 590,
    short: 'GP'
  },
  {
    emoji: '🇧🇴',
    phoneCode: 591,
    short: 'BO'
  },
  {
    emoji: '🇬🇾',
    phoneCode: 592,
    short: 'GY'
  },
  {
    emoji: '🇪🇨',
    phoneCode: 593,
    short: 'EC'
  },
  {
    emoji: '🇬🇫',
    phoneCode: 594,
    short: 'GF'
  },
  {
    emoji: '🇵🇾',
    phoneCode: 595,
    short: 'PY'
  },
  {
    emoji: '🇲🇶',
    phoneCode: 596,
    short: 'MQ'
  },
  {
    emoji: '🇸🇷',
    phoneCode: 597,
    short: 'SR'
  },
  {
    emoji: '🇺🇾',
    phoneCode: 598,
    short: 'UY'
  },
  {
    emoji: '🇨🇼',
    phoneCode: 599,
    short: 'CW'
  },
  {
    emoji: '🇲🇾',
    phoneCode: 60,
    short: 'MY'
  },
  {
    emoji: '🇦🇺',
    phoneCode: 61,
    short: 'AU'
  },
  {
    emoji: '🇮🇩',
    phoneCode: 62,
    short: 'ID'
  },
  {
    emoji: '🇵🇭',
    phoneCode: 63,
    allowed: true,
    short: 'PH'
  },
  {
    emoji: '🇳🇿',
    phoneCode: 64,
    short: 'NZ'
  },
  {
    emoji: '🇸🇬',
    phoneCode: 65,
    short: 'SG'
  },
  {
    emoji: '🇹🇭',
    phoneCode: 66,
    short: 'TH'
  },
  {
    emoji: '🇹🇱',
    phoneCode: 670,
    short: 'TL'
  },
  {
    emoji: '🇧🇳',
    phoneCode: 673,
    short: 'BN'
  },
  {
    emoji: '🇵🇬',
    phoneCode: 675,
    short: 'PG'
  },
  {
    emoji: '🇹🇴',
    phoneCode: 676,
    short: 'TO'
  },
  {
    emoji: '🇸🇧',
    phoneCode: 677,
    short: 'SB'
  },
  {
    emoji: '🇻🇺',
    phoneCode: 678,
    short: 'VU'
  },
  {
    emoji: '🇫🇯',
    phoneCode: 679,
    short: 'FJ'
  },
  {
    emoji: '🇵🇼',
    phoneCode: 680,
    short: 'PW'
  },
  {
    emoji: '🇨🇰',
    phoneCode: 682,
    short: 'CK'
  },
  {
    emoji: '🇼🇸',
    phoneCode: 685,
    short: 'WS'
  },
  {
    emoji: '🇰🇮',
    phoneCode: 686,
    short: 'KI'
  },
  {
    emoji: '🇳🇨',
    phoneCode: 687,
    short: 'NC'
  },
  {
    emoji: '🇵🇫',
    phoneCode: 689,
    short: 'PF'
  },
  {
    emoji: '🇰🇿',
    phoneCode: 7,
    short: 'KZ'
  },
  {
    emoji: '🇷🇺',
    phoneCode: 7,
    short: 'RU'
  },
  {
    emoji: '🇯🇵',
    phoneCode: 81,
    short: 'JP'
  },
  {
    emoji: '🇰🇷',
    phoneCode: 82,
    short: 'KR'
  },
  {
    emoji: '🇻🇳',
    phoneCode: 84,
    short: 'VN'
  },
  {
    emoji: '🇭🇰',
    phoneCode: 852,
    short: 'HK'
  },
  {
    emoji: '🇲🇴',
    phoneCode: 853,
    short: 'MO'
  },
  {
    emoji: '🇰🇭',
    phoneCode: 855,
    short: 'KH'
  },
  {
    emoji: '🇱🇦',
    phoneCode: 856,
    short: 'LA'
  },
  {
    emoji: '🇨🇳',
    phoneCode: 86,
    short: 'CN'
  },
  {
    emoji: '🇧🇩',
    phoneCode: 880,
    short: 'BD'
  },
  {
    emoji: '🇹🇼',
    phoneCode: 886,
    short: 'TW'
  },
  {
    emoji: '🇹🇷',
    phoneCode: 90,
    short: 'TR'
  },
  {
    emoji: '🇮🇳',
    phoneCode: 91,
    allowed: true,
    short: 'IN'
  },
  {
    emoji: '🇵🇰',
    phoneCode: 92,
    short: 'PK'
  },
  {
    emoji: '🇦🇫',
    phoneCode: 93,
    short: 'AF'
  },
  {
    emoji: '🇱🇰',
    phoneCode: 94,
    short: 'LK'
  },
  {
    emoji: '🇲🇲',
    phoneCode: 95,
    short: 'MM'
  },
  {
    emoji: '🇲🇻',
    phoneCode: 960,
    short: 'MV'
  },
  {
    emoji: '🇱🇧',
    phoneCode: 961,
    short: 'LB'
  },
  {
    emoji: '🇯🇴',
    phoneCode: 962,
    short: 'JO'
  },
  {
    emoji: '🇸🇾',
    phoneCode: 963,
    short: 'SY'
  },
  {
    emoji: '🇮🇶',
    phoneCode: 964,
    short: 'IQ'
  },
  {
    emoji: '🇰🇼',
    phoneCode: 965,
    short: 'KW'
  },
  {
    emoji: '🇸🇦',
    phoneCode: 966,
    short: 'SA'
  },
  {
    emoji: '🇾🇪',
    phoneCode: 967,
    short: 'YE'
  },
  {
    emoji: '🇴🇲',
    phoneCode: 968,
    short: 'OM'
  },
  {
    emoji: '🇵🇸',
    phoneCode: 970,
    short: 'BL'
  },
  {
    emoji: '🇦🇪',
    phoneCode: 971,
    short: 'AE'
  },
  {
    emoji: '🇮🇱',
    phoneCode: 972,
    short: 'IL'
  },
  {
    emoji: '🇧🇭',
    phoneCode: 973,
    short: 'BH'
  },
  {
    emoji: '🇶🇦',
    phoneCode: 974,
    short: 'QA'
  },
  {
    emoji: '🇧🇹',
    phoneCode: 975,
    short: 'BT'
  },
  {
    emoji: '🇲🇳',
    phoneCode: 976,
    short: 'MN'
  },
  {
    emoji: '🇳🇵',
    phoneCode: 977,
    short: 'NP'
  },
  {
    emoji: '🇮🇷',
    phoneCode: 98,
    short: 'IR'
  },
  {
    emoji: '🇹🇯',
    phoneCode: 992,
    short: 'TJ'
  },
  {
    emoji: '🇹🇲',
    phoneCode: 993,
    short: 'TM'
  },
  {
    emoji: '🇦🇿',
    phoneCode: 994,
    short: 'AZ'
  },
  {
    emoji: '🇬🇪',
    phoneCode: 995,
    short: 'GE'
  },
  {
    emoji: '🇰🇬',
    phoneCode: 996,
    short: 'KG'
  },
  {
    emoji: '🇺🇿',
    phoneCode: 998,
    short: 'UZ'
  }
];
export const defaultAreas1 = [
  {
    "emoji": "🇦🇫",
    "phoneCode": "93",
    "short": "AF",
    "label": "🇦🇫 AF",
    "country": "Afghanistan"
  },
  {
    "emoji": "🇦🇱",
    "phoneCode": "355",
    "short": "AL",
    "label": "🇦🇱 AL",
    "country": "Albania"
  },
  {
    "emoji": "🇩🇿",
    "phoneCode": "213",
    "short": "DZ",
    "label": "🇩🇿 DZ",
    "country": "Algeria"
  },
  {
    "emoji": "🇦🇩",
    "phoneCode": "376",
    "short": "AD",
    "label": "🇦🇩 AD",
    "country": "Andorra"
  },
  {
    "emoji": "🇦🇴",
    "phoneCode": "244",
    "short": "AO",
    "label": "🇦🇴 AO",
    "country": "Angola"
  },
  {
    "emoji": "🇦🇬",
    "phoneCode": "1268",
    "short": "AG",
    "label": "🇦🇬 AG",
    "country": "Antigua and Barbuda"
  },
  {
    "emoji": "🇦🇷",
    "phoneCode": "54",
    "short": "AR",
    "label": "🇦🇷 AR",
    "country": "Argentina"
  },
  {
    "emoji": "🇦🇲",
    "phoneCode": "374",
    "short": "AM",
    "label": "🇦🇲 AM",
    "country": "Armenia"
  },
  {
    "emoji": "🇦🇺",
    "phoneCode": "61",
    "short": "AU",
    "label": "🇦🇺 AU",
    "country": "Australia"
  },
  {
    "emoji": "🇦🇹",
    "phoneCode": "43",
    "short": "AT",
    "label": "🇦🇹 AT",
    "country": "Austria"
  },
  {
    "emoji": "🇦🇿",
    "phoneCode": "994",
    "short": "AZ",
    "label": "🇦🇿 AZ",
    "country": "Azerbaijan"
  },
  {
    "emoji": "🇧🇸",
    "phoneCode": "1242",
    "short": "BS",
    "label": "🇧🇸 BS",
    "country": "Bahamas"
  },
  {
    "emoji": "🇧🇭",
    "phoneCode": "973",
    "short": "BH",
    "label": "🇧🇭 BH",
    "country": "Bahrain"
  },
  {
    "emoji": "🇧🇩",
    "phoneCode": "880",
    "short": "BD",
    "label": "🇧🇩 BD",
    "country": "Bangladesh"
  },
  {
    "emoji": "🇧🇧",
    "phoneCode": "1246",
    "short": "BB",
    "label": "🇧🇧 BB",
    "country": "Barbados"
  },
  {
    "emoji": "🇧🇾",
    "phoneCode": "375",
    "short": "BY",
    "label": "🇧🇾 BY",
    "country": "Belarus"
  },
  {
    "emoji": "🇧🇪",
    "phoneCode": "32",
    "short": "BE",
    "label": "🇧🇪 BE",
    "country": "Belgium"
  },
  {
    "emoji": "🇧🇿",
    "phoneCode": "501",
    "short": "BZ",
    "label": "🇧🇿 BZ",
    "country": "Belize"
  },
  {
    "emoji": "🇧🇯",
    "phoneCode": "229",
    "short": "BJ",
    "label": "🇧🇯 BJ",
    "country": "Benin"
  },
  {
    "emoji": "🇧🇹",
    "phoneCode": "975",
    "short": "BT",
    "label": "🇧🇹 BT",
    "country": "Bhutan"
  },
  {
    "emoji": "🇧🇴",
    "phoneCode": "591",
    "short": "BO",
    "label": "🇧🇴 BO",
    "country": "Bolivia (Plurinational State of)"
  },
  {
    "emoji": "🇧🇦",
    "phoneCode": "387",
    "short": "BA",
    "label": "🇧🇦 BA",
    "country": "Bosnia and Herzegovina"
  },
  {
    "emoji": "🇧🇼",
    "phoneCode": "267",
    "short": "BW",
    "label": "🇧🇼 BW",
    "country": "Botswana"
  },
  {
    "emoji": "🇧🇷",
    "phoneCode": "55",
    "short": "BR",
    "label": "🇧🇷 BR",
    "country": "Brazil"
  },
  {
    "emoji": "🇧🇳",
    "phoneCode": "673",
    "short": "BN",
    "label": "🇧🇳 BN",
    "country": "Brunei Darussalam"
  },
  {
    "emoji": "🇧🇬",
    "phoneCode": "359",
    "short": "BG",
    "label": "🇧🇬 BG",
    "country": "Bulgaria"
  },
  {
    "emoji": "🇧🇫",
    "phoneCode": "226",
    "short": "BF",
    "label": "🇧🇫 BF",
    "country": "Burkina Faso"
  },
  {
    "emoji": "🇧🇮",
    "phoneCode": "257",
    "short": "BI",
    "label": "🇧🇮 BI",
    "country": "Burundi"
  },
  {
    "emoji": "🇨🇻",
    "phoneCode": "238",
    "short": "CV",
    "label": "🇨🇻 CV",
    "country": "Cabo Verde"
  },
  {
    "emoji": "🇰🇭",
    "phoneCode": "855",
    "short": "KH",
    "label": "🇰🇭 KH",
    "country": "Cambodia"
  },
  {
    "emoji": "🇨🇲",
    "phoneCode": "237",
    "short": "CM",
    "label": "🇨🇲 CM",
    "country": "Cameroon"
  },
  {
    "emoji": "🇨🇦",
    "phoneCode": "1",
    "short": "CA",
    "label": "🇨🇦 CA",
    "country": "Canada"
  },
  {
    "emoji": "🇨🇫",
    "phoneCode": "236",
    "short": "CF",
    "label": "🇨🇫 CF",
    "country": "Central African Republic"
  },
  {
    "emoji": "🇹🇩",
    "phoneCode": "235",
    "short": "TD",
    "label": "🇹🇩 TD",
    "country": "Chad"
  },
  {
    "emoji": "🇨🇱",
    "phoneCode": "56",
    "short": "CL",
    "label": "🇨🇱 CL",
    "country": "Chile"
  },
  {
    "emoji": "🇨🇳",
    "phoneCode": "86",
    "short": "CN",
    "label": "🇨🇳 CN",
    "country": "China"
  },
  {
    "emoji": "🇨🇴",
    "phoneCode": "57",
    "short": "CO",
    "label": "🇨🇴 CO",
    "country": "Colombia"
  },
  {
    "emoji": "🇰🇲",
    "phoneCode": "269",
    "short": "KM",
    "label": "🇰🇲 KM",
    "country": "Comoros"
  },
  {
    "emoji": "🇨🇬",
    "phoneCode": "242",
    "short": "CG",
    "label": "🇨🇬 CG",
    "country": "Congo"
  },
  {
    "emoji": "🇨🇩",
    "phoneCode": "243",
    "short": "CD",
    "label": "🇨🇩 CD",
    "country": "Congo, Democratic Republic of the"
  },
  {
    "emoji": "🇨🇷",
    "phoneCode": "506",
    "short": "CR",
    "label": "🇨🇷 CR",
    "country": "Costa Rica"
  },
  {
    "emoji": "🇭🇷",
    "phoneCode": "385",
    "short": "HR",
    "label": "🇭🇷 HR",
    "country": "Croatia"
  },
  {
    "emoji": "🇨🇺",
    "phoneCode": "53",
    "short": "CU",
    "label": "🇨🇺 CU",
    "country": "Cuba"
  },
  {
    "emoji": "🇨🇾",
    "phoneCode": "357",
    "short": "CY",
    "label": "🇨🇾 CY",
    "country": "Cyprus"
  },
  {
    "emoji": "🇨🇿",
    "phoneCode": "420",
    "short": "CZ",
    "label": "🇨🇿 CZ",
    "country": "Czechia"
  },
  {
    "emoji": "🇨🇮",
    "phoneCode": "225",
    "short": "CI",
    "label": "🇨🇮 CI",
    "country": "Côte d'Ivoire"
  },
  {
    "emoji": "🇩🇰",
    "phoneCode": "45",
    "short": "DK",
    "label": "🇩🇰 DK",
    "country": "Denmark"
  },
  {
    "emoji": "🇩🇯",
    "phoneCode": "253",
    "short": "DJ",
    "label": "🇩🇯 DJ",
    "country": "Djibouti"
  },
  {
    "emoji": "🇩🇲",
    "phoneCode": "1767",
    "short": "DM",
    "label": "🇩🇲 DM",
    "country": "Dominica"
  },
  {
    "emoji": "🇩🇴",
    "phoneCode": "1809",
    "short": "DO",
    "label": "🇩🇴 DO",
    "country": "Dominican Republic"
  },
  {
    "emoji": "🇪🇨",
    "phoneCode": "593",
    "short": "EC",
    "label": "🇪🇨 EC",
    "country": "Ecuador"
  },
  {
    "emoji": "🇪🇬",
    "phoneCode": "20",
    "short": "EG",
    "label": "🇪🇬 EG",
    "country": "Egypt"
  },
  {
    "emoji": "🇸🇻",
    "phoneCode": "503",
    "short": "SV",
    "label": "🇸🇻 SV",
    "country": "El Salvador"
  },
  {
    "emoji": "🇬🇶",
    "phoneCode": "240",
    "short": "GQ",
    "label": "🇬🇶 GQ",
    "country": "Equatorial Guinea"
  },
  {
    "emoji": "🇪🇷",
    "phoneCode": "291",
    "short": "ER",
    "label": "🇪🇷 ER",
    "country": "Eritrea"
  },
  {
    "emoji": "🇪🇪",
    "phoneCode": "372",
    "short": "EE",
    "label": "🇪🇪 EE",
    "country": "Estonia"
  },
  {
    "emoji": "🇸🇿",
    "phoneCode": "268",
    "short": "SZ",
    "label": "🇸🇿 SZ",
    "country": "Eswatini"
  },
  {
    "emoji": "🇪🇹",
    "phoneCode": "251",
    "short": "ET",
    "label": "🇪🇹 ET",
    "country": "Ethiopia"
  },
  {
    "emoji": "🇫🇯",
    "phoneCode": "679",
    "short": "FJ",
    "label": "🇫🇯 FJ",
    "country": "Fiji"
  },
  {
    "emoji": "🇫🇮",
    "phoneCode": "358",
    "short": "FI",
    "label": "🇫🇮 FI",
    "country": "Finland"
  },
  {
    "emoji": "🇫🇷",
    "phoneCode": "33",
    "short": "FR",
    "label": "🇫🇷 FR",
    "country": "France"
  },
  {
    "emoji": "🇬🇦",
    "phoneCode": "241",
    "short": "GA",
    "label": "🇬🇦 GA",
    "country": "Gabon"
  },
  {
    "emoji": "🇬🇲",
    "phoneCode": "220",
    "short": "GM",
    "label": "🇬🇲 GM",
    "country": "Gambia"
  },
  {
    "emoji": "🇬🇪",
    "phoneCode": "995",
    "short": "GE",
    "label": "🇬🇪 GE",
    "country": "Georgia"
  },
  {
    "emoji": "🇩🇪",
    "phoneCode": "49",
    "short": "DE",
    "label": "🇩🇪 DE",
    "country": "Germany"
  },
  {
    "emoji": "🇬🇭",
    "phoneCode": "233",
    "short": "GH",
    "label": "🇬🇭 GH",
    "country": "Ghana"
  },
  {
    "emoji": "🇬🇷",
    "phoneCode": "30",
    "short": "GR",
    "label": "🇬🇷 GR",
    "country": "Greece"
  },
  {
    "emoji": "🇬🇩",
    "phoneCode": "1473",
    "short": "GD",
    "label": "🇬🇩 GD",
    "country": "Grenada"
  },
  {
    "emoji": "🇬🇹",
    "phoneCode": "502",
    "short": "GT",
    "label": "🇬🇹 GT",
    "country": "Guatemala"
  },
  {
    "emoji": "🇬🇳",
    "phoneCode": "224",
    "short": "GN",
    "label": "🇬🇳 GN",
    "country": "Guinea"
  },
  {
    "emoji": "🇬🇼",
    "phoneCode": "245",
    "short": "GW",
    "label": "🇬🇼 GW",
    "country": "Guinea-Bissau"
  },
  {
    "emoji": "🇬🇾",
    "phoneCode": "592",
    "short": "GY",
    "label": "🇬🇾 GY",
    "country": "Guyana"
  },
  {
    "emoji": "🇭🇹",
    "phoneCode": "509",
    "short": "HT",
    "label": "🇭🇹 HT",
    "country": "Haiti"
  },
  {
    "emoji": "🇭🇳",
    "phoneCode": "504",
    "short": "HN",
    "label": "🇭🇳 HN",
    "country": "Honduras"
  },
  {
    "emoji": "🇭🇺",
    "phoneCode": "36",
    "short": "HU",
    "label": "🇭🇺 HU",
    "country": "Hungary"
  },
  {
    "emoji": "🇮🇸",
    "phoneCode": "354",
    "short": "IS",
    "label": "🇮🇸 IS",
    "country": "Iceland"
  },
  {
    "emoji": "🇮🇳",
    "phoneCode": "91",
    "short": "IN",
    "label": "🇮🇳 IN",
    "country": "India"
  },
  {
    "emoji": "🇮🇩",
    "phoneCode": "62",
    "short": "ID",
    "label": "🇮🇩 ID",
    "country": "Indonesia"
  },
  {
    "emoji": "🇮🇷",
    "phoneCode": "98",
    "short": "IR",
    "label": "🇮🇷 IR",
    "country": "Iran (Islamic Republic of)"
  },
  {
    "emoji": "🇮🇶",
    "phoneCode": "964",
    "short": "IQ",
    "label": "🇮🇶 IQ",
    "country": "Iraq"
  },
  {
    "emoji": "🇮🇪",
    "phoneCode": "353",
    "short": "IE",
    "label": "🇮🇪 IE",
    "country": "Ireland"
  },
  {
    "emoji": "🇮🇱",
    "phoneCode": "972",
    "short": "IL",
    "label": "🇮🇱 IL",
    "country": "Israel"
  },
  {
    "emoji": "🇮🇹",
    "phoneCode": "39",
    "short": "IT",
    "label": "🇮🇹 IT",
    "country": "Italy"
  },
  {
    "emoji": "🇯🇲",
    "phoneCode": "1876",
    "short": "JM",
    "label": "🇯🇲 JM",
    "country": "Jamaica"
  },
  {
    "emoji": "🇯🇵",
    "phoneCode": "81",
    "short": "JP",
    "label": "🇯🇵 JP",
    "country": "Japan"
  },
  {
    "emoji": "🇯🇴",
    "phoneCode": "962",
    "short": "JO",
    "label": "🇯🇴 JO",
    "country": "Jordan"
  },
  {
    "emoji": "🇰🇿",
    "phoneCode": "7",
    "short": "KZ",
    "label": "🇰🇿 KZ",
    "country": "Kazakhstan"
  },
  {
    "emoji": "🇰🇪",
    "phoneCode": "254",
    "short": "KE",
    "label": "🇰🇪 KE",
    "country": "Kenya"
  },
  {
    "emoji": "🇰🇮",
    "phoneCode": "686",
    "short": "KI",
    "label": "🇰🇮 KI",
    "country": "Kiribati"
  },
  {
    "emoji": "🇰🇷",
    "phoneCode": "82",
    "short": "KR",
    "label": "🇰🇷 KR",
    "country": "Korea, Republic of"
  },
  {
    "emoji": "🇰🇼",
    "phoneCode": "965",
    "short": "KW",
    "label": "🇰🇼 KW",
    "country": "Kuwait"
  },
  {
    "emoji": "🇰🇬",
    "phoneCode": "996",
    "short": "KG",
    "label": "🇰🇬 KG",
    "country": "Kyrgyzstan"
  },
  {
    "emoji": "🇱🇦",
    "phoneCode": "856",
    "short": "LA",
    "label": "🇱🇦 LA",
    "country": "Lao People's Democratic Republic"
  },
  {
    "emoji": "🇱🇻",
    "phoneCode": "371",
    "short": "LV",
    "label": "🇱🇻 LV",
    "country": "Latvia"
  },
  {
    "emoji": "🇱🇧",
    "phoneCode": "961",
    "short": "LB",
    "label": "🇱🇧 LB",
    "country": "Lebanon"
  },
  {
    "emoji": "🇱🇸",
    "phoneCode": "266",
    "short": "LS",
    "label": "🇱🇸 LS",
    "country": "Lesotho"
  },
  {
    "emoji": "🇱🇷",
    "phoneCode": "231",
    "short": "LR",
    "label": "🇱🇷 LR",
    "country": "Liberia"
  },
  {
    "emoji": "🇱🇾",
    "phoneCode": "218",
    "short": "LY",
    "label": "🇱🇾 LY",
    "country": "Libya"
  },
  {
    "emoji": "🇱🇮",
    "phoneCode": "423",
    "short": "LI",
    "label": "🇱🇮 LI",
    "country": "Liechtenstein"
  },
  {
    "emoji": "🇱🇹",
    "phoneCode": "370",
    "short": "LT",
    "label": "🇱🇹 LT",
    "country": "Lithuania"
  },
  {
    "emoji": "🇱🇺",
    "phoneCode": "352",
    "short": "LU",
    "label": "🇱🇺 LU",
    "country": "Luxembourg"
  },
  {
    "emoji": "🇲🇬",
    "phoneCode": "261",
    "short": "MG",
    "label": "🇲🇬 MG",
    "country": "Madagascar"
  },
  {
    "emoji": "🇲🇼",
    "phoneCode": "265",
    "short": "MW",
    "label": "🇲🇼 MW",
    "country": "Malawi"
  },
  {
    "emoji": "🇲🇾",
    "phoneCode": "60",
    "short": "MY",
    "label": "🇲🇾 MY",
    "country": "Malaysia"
  },
  {
    "emoji": "🇲🇻",
    "phoneCode": "960",
    "short": "MV",
    "label": "🇲🇻 MV",
    "country": "Maldives"
  },
  {
    "emoji": "🇲🇱",
    "phoneCode": "223",
    "short": "ML",
    "label": "🇲🇱 ML",
    "country": "Mali"
  },
  {
    "emoji": "🇲🇹",
    "phoneCode": "356",
    "short": "MT",
    "label": "🇲🇹 MT",
    "country": "Malta"
  },
  {
    "emoji": "🇲🇷",
    "phoneCode": "222",
    "short": "MR",
    "label": "🇲🇷 MR",
    "country": "Mauritania"
  },
  {
    "emoji": "🇲🇺",
    "phoneCode": "230",
    "short": "MU",
    "label": "🇲🇺 MU",
    "country": "Mauritius"
  },
  {
    "emoji": "🇲🇽",
    "phoneCode": "52",
    "short": "MX",
    "label": "🇲🇽 MX",
    "country": "Mexico"
  },
  {
    "emoji": "🇲🇩",
    "phoneCode": "373",
    "short": "MD",
    "label": "🇲🇩 MD",
    "country": "Moldova, Republic of"
  },
  {
    "emoji": "🇲🇨",
    "phoneCode": "377",
    "short": "MC",
    "label": "🇲🇨 MC",
    "country": "Monaco"
  },
  {
    "emoji": "🇲🇳",
    "phoneCode": "976",
    "short": "MN",
    "label": "🇲🇳 MN",
    "country": "Mongolia"
  },
  {
    "emoji": "🇲🇪",
    "phoneCode": "382",
    "short": "ME",
    "label": "🇲🇪 ME",
    "country": "Montenegro"
  },
  {
    "emoji": "🇲🇦",
    "phoneCode": "212",
    "short": "MA",
    "label": "🇲🇦 MA",
    "country": "Morocco"
  },
  {
    "emoji": "🇲🇿",
    "phoneCode": "258",
    "short": "MZ",
    "label": "🇲🇿 MZ",
    "country": "Mozambique"
  },
  {
    "emoji": "🇲🇲",
    "phoneCode": "95",
    "short": "MM",
    "label": "🇲🇲 MM",
    "country": "Myanmar"
  },
  {
    "emoji": "🇳🇦",
    "phoneCode": "264",
    "short": "NA",
    "label": "🇳🇦 NA",
    "country": "Namibia"
  },
  {
    "emoji": "🇳🇵",
    "phoneCode": "977",
    "short": "NP",
    "label": "🇳🇵 NP",
    "country": "Nepal"
  },
  {
    "emoji": "🇳🇱",
    "phoneCode": "31",
    "short": "NL",
    "label": "🇳🇱 NL",
    "country": "Netherlands"
  },
  {
    "emoji": "🇳🇿",
    "phoneCode": "64",
    "short": "NZ",
    "label": "🇳🇿 NZ",
    "country": "New Zealand"
  },
  {
    "emoji": "🇳🇮",
    "phoneCode": "505",
    "short": "NI",
    "label": "🇳🇮 NI",
    "country": "Nicaragua"
  },
  {
    "emoji": "🇳🇪",
    "phoneCode": "227",
    "short": "NE",
    "label": "🇳🇪 NE",
    "country": "Niger"
  },
  {
    "emoji": "🇳🇬",
    "phoneCode": "234",
    "short": "NG",
    "label": "🇳🇬 NG",
    "country": "Nigeria"
  },
  {
    "emoji": "🇲🇰",
    "phoneCode": "389",
    "short": "MK",
    "label": "🇲🇰 MK",
    "country": "North Macedonia"
  },
  {
    "emoji": "🇳🇴",
    "phoneCode": "47",
    "short": "NO",
    "label": "🇳🇴 NO",
    "country": "Norway"
  },
  {
    "emoji": "🇴🇲",
    "phoneCode": "968",
    "short": "OM",
    "label": "🇴🇲 OM",
    "country": "Oman"
  },
  {
    "emoji": "🇵🇰",
    "phoneCode": "92",
    "short": "PK",
    "label": "🇵🇰 PK",
    "country": "Pakistan"
  },
  {
    "emoji": "🇵🇼",
    "phoneCode": "680",
    "short": "PW",
    "label": "🇵🇼 PW",
    "country": "Palau"
  },
  {
    "emoji": "🇵🇦",
    "phoneCode": "507",
    "short": "PA",
    "label": "🇵🇦 PA",
    "country": "Panama"
  },
  {
    "emoji": "🇵🇬",
    "phoneCode": "675",
    "short": "PG",
    "label": "🇵🇬 PG",
    "country": "Papua New Guinea"
  },
  {
    "emoji": "🇵🇾",
    "phoneCode": "595",
    "short": "PY",
    "label": "🇵🇾 PY",
    "country": "Paraguay"
  },
  {
    "emoji": "🇵🇪",
    "phoneCode": "51",
    "short": "PE",
    "label": "🇵🇪 PE",
    "country": "Peru"
  },
  {
    "emoji": "🇵🇭",
    "phoneCode": "63",
    "short": "PH",
    "label": "🇵🇭 PH",
    "country": "Philippines"
  },
  {
    "emoji": "🇵🇱",
    "phoneCode": "48",
    "short": "PL",
    "label": "🇵🇱 PL",
    "country": "Poland"
  },
  {
    "emoji": "🇵🇹",
    "phoneCode": "351",
    "short": "PT",
    "label": "🇵🇹 PT",
    "country": "Portugal"
  },
  {
    "emoji": "🇶🇦",
    "phoneCode": "974",
    "short": "QA",
    "label": "🇶🇦 QA",
    "country": "Qatar"
  },
  {
    "emoji": "🇷🇴",
    "phoneCode": "40",
    "short": "RO",
    "label": "🇷🇴 RO",
    "country": "Romania"
  },
  {
    "emoji": "🇷🇺",
    "phoneCode": "7",
    "short": "RU",
    "label": "🇷🇺 RU",
    "country": "Russian Federation"
  },
  {
    "emoji": "🇷🇼",
    "phoneCode": "250",
    "short": "RW",
    "label": "🇷🇼 RW",
    "country": "Rwanda"
  },
  {
    "emoji": "🇰🇳",
    "phoneCode": "1869",
    "short": "KN",
    "label": "🇰🇳 KN",
    "country": "Saint Kitts and Nevis"
  },
  {
    "emoji": "🇱🇨",
    "phoneCode": "1758",
    "short": "LC",
    "label": "🇱🇨 LC",
    "country": "Saint Lucia"
  },
  {
    "emoji": "🇻🇨",
    "phoneCode": "1784",
    "short": "VC",
    "label": "🇻🇨 VC",
    "country": "Saint Vincent and the Grenadines"
  },
  {
    "emoji": "🇼🇸",
    "phoneCode": "685",
    "short": "WS",
    "label": "🇼🇸 WS",
    "country": "Samoa"
  },
  {
    "emoji": "🇸🇲",
    "phoneCode": "378",
    "short": "SM",
    "label": "🇸🇲 SM",
    "country": "San Marino"
  },
  {
    "emoji": "🇸🇹",
    "phoneCode": "239",
    "short": "ST",
    "label": "🇸🇹 ST",
    "country": "Sao Tome and Principe"
  },
  {
    "emoji": "🇸🇦",
    "phoneCode": "966",
    "short": "SA",
    "label": "🇸🇦 SA",
    "country": "Saudi Arabia"
  },
  {
    "emoji": "🇸🇳",
    "phoneCode": "221",
    "short": "SN",
    "label": "🇸🇳 SN",
    "country": "Senegal"
  },
  {
    "emoji": "🇷🇸",
    "phoneCode": "381",
    "short": "RS",
    "label": "🇷🇸 RS",
    "country": "Serbia"
  },
  {
    "emoji": "🇸🇨",
    "phoneCode": "248",
    "short": "SC",
    "label": "🇸🇨 SC",
    "country": "Seychelles"
  },
  {
    "emoji": "🇸🇱",
    "phoneCode": "232",
    "short": "SL",
    "label": "🇸🇱 SL",
    "country": "Sierra Leone"
  },
  {
    "emoji": "🇸🇬",
    "phoneCode": "65",
    "short": "SG",
    "label": "🇸🇬 SG",
    "country": "Singapore"
  },
  {
    "emoji": "🇸🇰",
    "phoneCode": "421",
    "short": "SK",
    "label": "🇸🇰 SK",
    "country": "Slovakia"
  },
  {
    "emoji": "🇸🇮",
    "phoneCode": "386",
    "short": "SI",
    "label": "🇸🇮 SI",
    "country": "Slovenia"
  },
  {
    "emoji": "🇸🇧",
    "phoneCode": "677",
    "short": "SB",
    "label": "🇸🇧 SB",
    "country": "Solomon Islands"
  },
  {
    "emoji": "🇸🇴",
    "phoneCode": "252",
    "short": "SO",
    "label": "🇸🇴 SO",
    "country": "Somalia"
  },
  {
    "emoji": "🇿🇦",
    "phoneCode": "27",
    "short": "ZA",
    "label": "🇿🇦 ZA",
    "country": "South Africa"
  },
  {
    "emoji": "🇪🇸",
    "phoneCode": "34",
    "short": "ES",
    "label": "🇪🇸 ES",
    "country": "Spain"
  },
  {
    "emoji": "🇱🇰",
    "phoneCode": "94",
    "short": "LK",
    "label": "🇱🇰 LK",
    "country": "Sri Lanka"
  },
  {
    "emoji": "🇸🇩",
    "phoneCode": "249",
    "short": "SD",
    "label": "🇸🇩 SD",
    "country": "Sudan"
  },
  {
    "emoji": "🇸🇷",
    "phoneCode": "597",
    "short": "SR",
    "label": "🇸🇷 SR",
    "country": "Suriname"
  },
  {
    "emoji": "🇸🇪",
    "phoneCode": "46",
    "short": "SE",
    "label": "🇸🇪 SE",
    "country": "Sweden"
  },
  {
    "emoji": "🇨🇭",
    "phoneCode": "41",
    "short": "CH",
    "label": "🇨🇭 CH",
    "country": "Switzerland"
  },
  {
    "emoji": "🇸🇾",
    "phoneCode": "963",
    "short": "SY",
    "label": "🇸🇾 SY",
    "country": "Syrian Arab Republic"
  },
  {
    "emoji": "🇹🇯",
    "phoneCode": "992",
    "short": "TJ",
    "label": "🇹🇯 TJ",
    "country": "Tajikistan"
  },
  {
    "emoji": "🇹🇿",
    "phoneCode": "255",
    "short": "TZ",
    "label": "🇹🇿 TZ",
    "country": "Tanzania, United Republic of"
  },
  {
    "emoji": "🇹🇭",
    "phoneCode": "66",
    "short": "TH",
    "label": "🇹🇭 TH",
    "country": "Thailand"
  },
  {
    "emoji": "🇹🇱",
    "phoneCode": "670",
    "short": "TL",
    "label": "🇹🇱 TL",
    "country": "Timor-Leste"
  },
  {
    "emoji": "🇹🇬",
    "phoneCode": "228",
    "short": "TG",
    "label": "🇹🇬 TG",
    "country": "Togo"
  },
  {
    "emoji": "🇹🇴",
    "phoneCode": "676",
    "short": "TO",
    "label": "🇹🇴 TO",
    "country": "Tonga"
  },
  {
    "emoji": "🇹🇹",
    "phoneCode": "1868",
    "short": "TT",
    "label": "🇹🇹 TT",
    "country": "Trinidad and Tobago"
  },
  {
    "emoji": "🇹🇳",
    "phoneCode": "216",
    "short": "TN",
    "label": "🇹🇳 TN",
    "country": "Tunisia"
  },
  {
    "emoji": "🇹🇷",
    "phoneCode": "90",
    "short": "TR",
    "label": "🇹🇷 TR",
    "country": "Turkey"
  },
  {
    "emoji": "🇹🇲",
    "phoneCode": "993",
    "short": "TM",
    "label": "🇹🇲 TM",
    "country": "Turkmenistan"
  },
  {
    "emoji": "🇺🇬",
    "phoneCode": "256",
    "short": "UG",
    "label": "🇺🇬 UG",
    "country": "Uganda"
  },
  {
    "emoji": "🇺🇦",
    "phoneCode": "380",
    "short": "UA",
    "label": "🇺🇦 UA",
    "country": "Ukraine"
  },
  {
    "emoji": "🇦🇪",
    "phoneCode": "971",
    "short": "AE",
    "label": "🇦🇪 AE",
    "country": "United Arab Emirates"
  },
  {
    "emoji": "🇬🇧",
    "phoneCode": "44",
    "short": "GB",
    "label": "🇬🇧 GB",
    "country": "United Kingdom of Great Britain and Northern Ireland"
  },
  {
    "emoji": "🇺🇸",
    "phoneCode": "1",
    "short": "US",
    "label": "🇺🇸 US",
    "country": "United States of America"
  },
  {
    "emoji": "🇺🇾",
    "phoneCode": "598",
    "short": "UY",
    "label": "🇺🇾 UY",
    "country": "Uruguay"
  },
  {
    "emoji": "🇺🇿",
    "phoneCode": "998",
    "short": "UZ",
    "label": "🇺🇿 UZ",
    "country": "Uzbekistan"
  },
  {
    "emoji": "🇻🇺",
    "phoneCode": "678",
    "short": "VU",
    "label": "🇻🇺 VU",
    "country": "Vanuatu"
  },
  {
    "emoji": "🇻🇪",
    "phoneCode": "58",
    "short": "VE",
    "label": "🇻🇪 VE",
    "country": "Venezuela (Bolivarian Republic of)"
  },
  {
    "emoji": "🇻🇳",
    "phoneCode": "84",
    "short": "VN",
    "label": "🇻🇳 VN",
    "country": "Viet Nam"
  },
  {
    "emoji": "🇾🇪",
    "phoneCode": "967",
    "short": "YE",
    "label": "🇾🇪 YE",
    "country": "Yemen"
  },
  {
    "emoji": "🇿🇲",
    "phoneCode": "260",
    "short": "ZM",
    "label": "🇿🇲 ZM",
    "country": "Zambia"
  },
  {
    "emoji": "🇿🇼",
    "phoneCode": "263",
    "short": "ZW",
    "label": "🇿🇼 ZW",
    "country": "Zimbabwe"
  }
]
export const nationalLanguages = [
  "English",
  "Spanish",
  "French",
  "Arabic",
  "Hindi",
  "Bengali",
  "Russian",
  "Portuguese",
  "Indonesian",
  "Urdu",
  "German",
  "Japanese",
  "Swahili",
  "Marathi",
  "Telugu",
  "Turkish",
  "Tamil",
  "Vietnamese",
  "Korean",
  "Italian",
  "Thai",
  "Gujarati",
  "Persian",
  "Polish",
  "Yoruba",
  "Malayalam",
  "Kannada",
  "Odia",
  "Maithili",
  "Sundanese",
  "Burmese",
  "Romanian",
  "Dutch",
  "Ukrainian",
  "Bulgarian",
  "Greek",
  "Czech",
  "Swedish",
  "Catalan",
  "Finnish",
  "Hebrew",
  "Norwegian",
  "Slovak",
  "Danish",
  "Lithuanian",
  "Slovene",
  "Irish",
  "Latvian",
  "Estonian",
  "Icelandic",
  "Albanian",
  "Macedonian",
  "Welsh",
  "Luxembourgish",
  "Maltese",
  "Basque",
  "Corsican",
  "Galician",
  "Scottish Gaelic",
  "Breton",
  "Cornish",
  "Manx",
  "Hawaiian",
  "Samoan",
  "Tahitian",
  "Fijian",
  "Tongan",
  "Marshallese",
  "Palauan",
  "Chamorro",
  "Guaraní",
  "Quechua",
  "Aymara",
  "Nahuatl",
  "Inuktitut",
  "Cree",
  "Cherokee",
  "Choctaw"
];