/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Col, Modal, Row} from 'antd'

import {fetchAllergies, fetchDiagnoses, fetchFamilyHistory, fetchImmunisations, fetchMedications, fetchPatientById} from '../../../app/Reducers'
import PatientOpenWounds from "./PatientOpenWounds";
import PatientAppointments from "./PatientAppointments";
import PatientVitals from "./PatientVitals";
import {closeButton, dialogCloser, emptyFun, formSubmitButton, horStack, IsItemEnabled, modalClose, outlinedButton} from "../../../common/helpers";
import PatienDetailsForm from "./patientSummaryForms/patientDetailsForm";
import NextAppointments from './NextAppointments'
import PatientWounds from './PatientWounds/PatientWounds'
import ImmunizationSection from './PatientHealthData/ImmunizationSection'
import {Headline6} from '../../../components'
import {CloseOutlined, RightOutlined} from '@ant-design/icons'
import SocialHistorySection from './PatientHealthData/SocialHistorySection'
import AllergiesSection from './PatientHealthData/AllergiesSection'
import MedicationSection from './PatientHealthData/MedicationSection'
import FamilyHistorySection from './PatientHealthData/FamilyHistorySection'
import DiagnosisSection from './PatientHealthData/DiagnosisSection'
import StickiyNotes from './StickiyNotes'
import PatientOrders from './PatientOrders'
import Familyhistorymini from './PatientHealthData/Familyhistorymini'
import PatientOrderList from './PatientOrdersList'
import TimeLineMini from './TimelineMini'
import PatientBiologics from './BiologicsSection'
import PatientTasks from './PatientTasks'
import PatientTasksMini from './PatientTasksMini'
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';

const PatientSummary = ({ settings, patient, diagnoses, medication, immunizations, allergies, scrolltop, activeUser }) => {
  const myRef = useRef(null);
    const location = useLocation()
      const navigate = useNavigate();
  const can_view_treatment_order = usePermissionCheck('view_treatment_order', false, [])
  const can_view_task = usePermissionCheck('view_task', false, [])
  const can_view_treatment_order_patient = usePermissionCheck('view_treatment_order_patient', false, [])
  const [detailsForm, setDetailsForm] = useState(false);
  const can_view_patient_social_history = usePermissionCheck('view_patient_social_history', false, [])
  const can_view_patient_medication = usePermissionCheck('view_patient_medication', false, [])
  const can_view_patient_diagnosis = usePermissionCheck('view_patient_diagnosis', false, [])
  const can_view_patient_allergy = usePermissionCheck('view_patient_allergy', false, [])
  const can_view_patient_next_of_kin = usePermissionCheck('view_patient_next_of_kin', false, [])
  
  const [isExecuting, setisExecuting] = useState(false)
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const dispatch = useDispatch()
  useEffect(() => {
    console.log('PATIENT ID:', patient)
    // if ((patient && patient.id !== navs.patientDetailsOwner && !navs.patientDetailsLoading) || (patientId !== patient.id)) {
    dispatch(fetchPatientById(Number(patientId)))
    // }
    return emptyFun
  }, [])
  const [showOverLay, setshowOverLay] = useState(false)
  const [currentContent, setcurrentContent] = useState(null)
  const setContent = (val) => {
    setcurrentContent(val)
    setshowOverLay(true)
    scrolltop()
  }
  useEffect(() => {
    loadImmunisations().catch(e => console.log(e))
    return emptyFun
  }, [])
  const loadImmunisations = async () => {
    /*if (!!immunizations && !!immunizations[0] && !isMQMessage && immunizations.every(item => item.patient_id === patientId)) {
      return
    }*/
    dispatch(fetchImmunisations(patientId))
  }
  
  
  useEffect(() => {
    loadDiagnoses()
    return emptyFun
  }, [])
  const loadDiagnoses = () => {
    /*if (!!diagnoses && !!diagnoses[0] && !isMQMessage && diagnoses.every(item => item.patient_id === patientId)) {
      return
    }*/
    dispatch(fetchDiagnoses(patientId))
  }
  
  useEffect(() => {
    loadFamilyHistory()
    return emptyFun
  }, [])
  const loadFamilyHistory = () => {
    /*if (!!familyHistory && !!familyHistory[0] && !isMQMessage && familyHistory.every(item => item.patient_id === patientId)) {
      return
    }*/
    dispatch(fetchFamilyHistory(patientId))
  }
  
  
  useEffect(() => {
    (async () => {
      await dispatch(fetchMedications(patientId))
      await dispatch(fetchAllergies(patientId))
    })()
    return emptyFun
  }, [])
  
  return (
    (<div className="relative">
      <Modal
        destroyOnClose={true}
        title="Patient Information"
        open={detailsForm}
        onOk={dialogCloser(setDetailsForm)}
        onCancel={dialogCloser(setDetailsForm)}
        closeIcon={modalClose(dialogCloser(setDetailsForm))}
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(dialogCloser(setDetailsForm), 'Cancel', isExecuting),
            formSubmitButton('patientForm', 'Update', isExecuting, 'Updating')])}
        </div>]}>
        <PatienDetailsForm setisExecuting={setisExecuting} patient={patient} closeModal={() => console.log("am done")}/>
      </Modal>
      <Row gutter={[8, 4]} align={"stretch"} justify={"start"} style={{ width: '100%', margin: 0, paddingTop: '10px' }}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16} flex={"auto"} style={{ width: '100%' }}>
          <Row gutter={[8, 4]} align={"stretch"} justify={"start"} style={{ width: '100%', margin: 0, paddingTop: '10px' }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}>
              <div className="flex-boy-column">
                {/* {IsItemEnabled(settings, 'modules', 'Task') && can_view_task && <PatientTasksMini setContent={setContent}/>} */}
                <StickiyNotes/>
                <NextAppointments setContent={setContent}/>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}>
              <div className="flex-boy-column">
                {/* {(can_view_treatment_order || can_view_treatment_order_patient) && <PatientOrders setContent={setContent}/>} */}
                <PatientVitals/>
                <PatientOpenWounds changTab={setContent}/>
              </div>
            </Col>
            {!activeUser.facilityProfile?.id && 
            <Col xs={24} sm={24} md={24} lg={24} xl={24} flex={"auto"} style={{ width: '100%' }}>
              <div className="flex-boy-column">
                <TimeLineMini setContent={setContent}/>
              </div>
            </Col>}
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} flex={"auto"} style={{ width: '100%' }}>
          <div className="card-box mt-3">
            <Headline6>Health Data</Headline6>
            <div className="rounded w-full p-2 my-2" style={{ background: '#ebeff4' }}>
              <div className="flex flex-row  justify-between items-center">
                <p className="text-left font-semibold"> Diagnosis</p>
                <RightOutlined onClick={() => setContent("Diagnosis")}/>
              </div>
              {diagnoses?.map(item => <p className=" py-2 text-left">&#8226; {item.name || item.problem_statement?.description}</p>)}
            </div>
            <div className="rounded w-full p-2 my-2" style={{ background: '#ebeff4' }}>
              <div className="flex flex-row  justify-between items-center">
                <p className="text-left font-semibold"> Allergies</p>
                <RightOutlined onClick={() => setContent("Allergies")}/>
              </div>
              
              {allergies?.map(item => <p className="text-left capitalize">&#8226; {item.allergen === "Drug" ? item.drug?.generic_name : item.allergen}</p>)}
            </div>
            <div className="rounded w-full p-2 my-2" style={{ background: '#ebeff4' }}>
              <div className="flex flex-row  justify-between items-center">
                <p className="text-left font-semibold"> Medications (e-RX)</p>
                <RightOutlined onClick={() => setContent("Medication")}/>
              </div>
              {medication?.map(item => <p className=" py-2 text-left">&#8226; {item?.name??item.drug?.generic_name}</p>)}
            
            </div>
            <div className="rounded w-full p-2 my-2" style={{ background: '#ebeff4' }}>
              <div className="flex flex-row  justify-between items-center">
                <p className="text-left font-semibold"> Immunizations</p>
                <RightOutlined onClick={() => setContent("Immunizations")}/>
              </div>
              {immunizations?.map(item => <p className=" py-2 text-left">&#8226; {item.name}</p>)}
            
            </div>
          
          </div>
          {can_view_patient_social_history && <div className="card-box">
            <SocialHistorySection/>
          </div>}
          <div className="card-box">
            <Familyhistorymini setContent={setContent}/>
          </div>
          <div className="">
            <PatientBiologics patient={patient}/>
          </div>
        </Col>
      </Row>
      {showOverLay &&
        
        <div ref={myRef} className="absolute bg-gray-200  top-0 left-0 h-full w-full z-10">
            <div className="flex flex-row items-center bg-white py-2 mx-4 gap-4 px-4 shadow-xl">
              {closeButton(() => setshowOverLay(false),null,{type:'text'})}
              <p className="text-md font-semibold my-auto">{currentContent} List</p>
            </div>
          <div className="w-full p-3 overflow-x-hidden">
            {currentContent === "Appointments" ? <PatientAppointments/> :
              currentContent === "Wounds" ? <PatientWounds/> :
                (currentContent === "Allergies" && can_view_patient_allergy) ? <AllergiesSection/> :
                  currentContent === "Immunizations" ? <ImmunizationSection/> :
                    (currentContent === "Medication" && can_view_patient_medication) ? <MedicationSection/> :
                      IsItemEnabled(settings, 'modules', 'Task') && currentContent === "Tasks" ? <PatientTasks/> :
                        (currentContent === "Diagnosis" && can_view_patient_diagnosis) ? <DiagnosisSection/> :
                          currentContent === "Orders" ? (can_view_treatment_order_patient || can_view_treatment_order) && <PatientOrderList/> :
                            // currentContent === "Prescriptions" ? <PrescriptionSection /> :
                            (currentContent === "Family History" && can_view_patient_next_of_kin) ? <FamilyHistorySection/> :
                              (can_view_treatment_order_patient || can_view_treatment_order) && <PatientOrderList/>}
          </div>
        </div>
      }
    </div>)
  );
}
const mapStateToProps = (state) => ({
  woundsLoading: state.patients.woundsLoading,
  wounds: state.patients.patientWounds, appointments: state.patients.patientAppointments,
  patient: state.patients.selectedPatient, vitals: state.patients.vitals,
  shippingAddresses: state.patients.shippingAddresses, appConf: state.appConf, navs: state.navs, activeUser: state.auth?.activeUser,
  familyHistory: state.patients.healthData.familyHistory,
  medication: state.patients.healthData.medication,
  immunizations: state.patients.healthData.immunizations,
  allergies: state.patients.healthData.allergies,
  diagnoses: state.patients.healthData.diagnosis,
  settings: state.settings?.settings
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PatientSummary)
