import React, {useEffect, useState} from 'react';
import {Headline6} from '../../../components';
import {closeButton, containedButton, deleteButton, dialogCloser, editButton, formSubmitButton, horStack, outlinedButton} from '../../../common/helpers';
import {Modal, Radio} from 'antd';
import {connect, useDispatch} from 'react-redux';
import PatientTasksKanbanView from './PatientTasksKanbanView';
import PatientTasksListView from './PatientTasksListView';
import NewTask from './NewTask';
import ViewTask from './ViewTask';
import {fetchPatientTasks} from '../../../app/Reducers';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';

// list of dummy tasks for testing in this format 

const tasks = [
    {
        "id": 1,
        "department_id": 91900000000079,
        "assignee_id": 398,
        "reporter_id": 399,
        "patient_id": 38,
        "due_date": "2019-12-15",
        "priority": "high",
        "description": "Dicta tempore ex et.",
        "notes": "Dolorem qui consequuntur sit debitis.",
        "status": "completed"
    },
    {
        "id": 2,
        "department_id": 91900000000079,
        "assignee_id": 398,
        "reporter_id": 399,
        "patient_id": 38,
        "due_date": "2019-12-15",
        "priority": "normal",
        "description": "Dicta tempore ex et.",
        "notes": "Dolorem qui consequuntur sit debitis.",
        "status": "done"
    },
    {
        "id": 3,
        "department_id": 91900000000079,
        "assignee_id": 398,
        "reporter_id": 399,
        "patient_id": 38,
        "due_date": "2019-12-15",
        "priority": "high",
        "description": "Dicta tempore ex et.",
        "notes": "Dolorem qui consequuntur sit debitis.",
        "status": "assigned"
    },
    {
        "id": 4,
        "department_id": 91900000000079,
        "assignee_id": 398,
        "reporter_id": 399,
        "patient_id": 38,
        "due_date": "2019-12-15",
        "priority": "low",
        "description": "Dicta tempore ex et.",
        "notes": "Dolorem qui consequuntur sit debitis.",
        "status": "in progress"
    }
]


export const PatientTasks = ({navs, patient, activeUser }) => {
    const location = useLocation()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))

    // view usesState to toggle between list and kanban view
    const [view, setView] = useState('list')
    const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
    const onViewChange = (e) => {
        setView(e.target.value)

    }
// new task modal
    const [newTaskModal, setNewTaskModal] = useState(false)

    const newTaskModalOpen = () => {
        setNewTaskModal(true)
    }
    const newTaskModalClose = () => {
        setNewTaskModal(false)
    }
// is executing
    const [isExecuting, setIsExecuting] = useState(false)

// viw task modal
    const [viewTaskModal, setViewTaskModal] = useState(false)

    const viewTaskModalOpen = () => {
        setViewTaskModal(true)
    }
    const viewTaskModalClose = () => {
        setViewTaskModal(false)
    }

    useEffect(() => {
        dispatch(fetchPatientTasks(patientId))
        return () => {
            // cleanup
        }
    }, [dispatch])


    return (
        <div className="mt-2">

            {/*  new taksk modal */}
            <Modal
                title="New Task"
                open={newTaskModal}
                onCancel={newTaskModalClose}

                width={600}
                destroyOnClose={true}
                maskClosable={false}
                centered={true}
                className="new-task-modal"
                footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
                    {horStack([outlinedButton(dialogCloser(setNewTaskModal), 'Cancel', isExecuting),
                        formSubmitButton('taskForm', 'Create', isExecuting, 'Creating')])}
                </div>]}
            >
                <NewTask closeModal={newTaskModalClose} isExecuting={isExecuting} setIsExecuting={setIsExecuting}/>
            </Modal>
            {/* view task modal */}
            <Modal
                closable={false}
                title={<div className="flex flex-1" style={{ justifyContent: 'space-between' }}>
                    <Headline6>View Task</Headline6>
                    <div className="flex flex-1 flex-row items-center " style={{ justifyContent: 'flex-end' }}>
                        {deleteButton('Delete', isExecuting, () => {
                        }, 'Delete Task')}
                        {editButton(() => {
                            newTaskModalOpen()
                        })}
                        {closeButton(() => {
                            viewTaskModalClose()
                        })}
                    </div>


                </div>}

                open={viewTaskModal}
                onCancel={viewTaskModalClose}

                width={1000}
                destroyOnClose={true}
                maskClosable={false}
                centered={true}
                className="view-task-modal"

            >
                <ViewTask/>
            </Modal>

            <div className="flex-boy-row-space" style={{ width: '100%' }}>
                <div className="flex flex-1"></div>
                {/* ant design tabs with list view or kanban */}

                <Radio.Group className="mr-4" onChange={onViewChange} defaultValue="list"
                             buttonStyle="solid">
                    <Radio.Button value="list"> List View </Radio.Button>
                    <Radio.Button value="kanban">Kanban</Radio.Button>

                </Radio.Group>

                {can_update_patient_resource && !patient?.deleted_at ?
                    containedButton(() => {

                        newTaskModalOpen()
                    }, 'Add New', false, null, null, { icon: <PlusOutlined /> })
                    : null}
            </div>
            {
                view === 'list' ? <PatientTasksListView/> : <PatientTasksKanbanView/>
            }

        </div>
    )
}

const mapStateToProps = (state) => ({

    activeUser: state.auth?.activeUser,
    patient: state.patients.selectedPatient,
    navs: state.navs
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PatientTasks)