import React, {useEffect, useState} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import {Divider, Grid, Input, Modal, Popover, Table} from "antd"
import {deleteMedication, fetchMedications, resetMedicationPagination, setMedicationsLoaded, updateMedicationFilters} from '../../../../app/Reducers';
import dayjs from 'dayjs';
import {
  addButton,
  dialogCloser,
  emptyFun,
  emptyTable,
  formSubmitButton,
  getMedicationStatus, GetRawListOptions,
  getSkels,
  horStack,
  kebabMenus, keyValItemOrders, makeSelectOptions,
  modalClose,
  onTableChange,
  outlinedButton,
  responsiveTbl
} from "../../../../common/helpers";

import {EditFilled} from "@ant-design/icons";
import NewMedicationForm from "./forms/MedicationForm";
import _ from "lodash"
import KebabMenu from "../../../../components/KebabMenu";
import AuditLogViewer from "../../../../components/AuditLogs/AuditLogViewer";
import TableFilters from "../../../../components/InputComponents/TableFilters";
import {useSubscription} from "../../../../hooks/MqttHooks/useSubscription";
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
const { useBreakpoint } = Grid;
const MedicationSection = ({  patient, medication, appConf, navs, medicationPagination, activeUser }) => {
  const [allergiesForm, setAllergiesForm] = useState(false);
  const location = useLocation()
  const navigate = useNavigate();
  const windowState=useBreakpoint()
  // const medication = useSelector(state => state.patients.healthData.medication);
  // const patient = useSelector(state => state.patients.selectedPatient);
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [isExecuting, setisExecuting] = useState(false);
  const dispatch = useDispatch()
  const { Search } = Input;
  const [isVisible, setIsVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [editingForm, setEditingForm] = useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [medication_, setMedication] = useState(null);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const showPopconfirm = (medication) => setSelected(medication.id)
  const handleCancel = () => setSelected(null)
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  const can_delete_patient_medication = usePermissionCheck('delete_patient_medication', false, [])
  const can_create_patient_medication = usePermissionCheck('create_patient_medication', false, [])
  const can_update_patient_medication = usePermissionCheck('update_patient_medication', false, [])
  const handleOk = async (id) => {
    await setConfirmLoading(true);
    let payload = { patient_id: patientId, medication_id: id }
    await dispatch(deleteMedication(payload))
    await setSelected(null)
    await setConfirmLoading(false);
  }
  
  const { message } = useSubscription(`view_patient_medication/${patientId}`, { qos: 2, nl: true });
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await loadMedication(true)
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    (async () => {
      await loadMedication(false)
    })()
    return emptyFun
  }, [])
  const loadMedication = async (isMQMessage) => {
    /*if (!!medication && !!medication[0] && !isMQMessage && medication.every(item => item.patient_id === patientId)) {
      return
    }*/
    await dispatch(updateMedicationFilters([appConf.medicationFilters[0], appConf.medicationFilters[1], !isMQMessage]))
    await dispatch(fetchMedications(appConf.medicationFilters[0] ? appConf.medicationFilters[1] : patientId))
    await dispatch(setMedicationsLoaded(patientId))
    await dispatch(updateMedicationFilters([appConf.medicationFilters[0], appConf.medicationFilters[1], false]))
  }
  
  const actionMenus = kebabMenus((can_update_patient_medication && !patient.deleted_at), can_view_audit_logs, can_delete_patient_medication)
  
  async function kebabMenuClick(e, medication) {
    await setMedication(medication)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true)
        await setAllergiesForm(true)
        break
      case 'Delete':
        await setEditingForm(false)
        showPopconfirm(medication)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
        await setEditingForm(false)
    }
  }
  
  const GetOptions = (system_name) => {
    const masterList = useSelector(state => state.masters.masterListRaw)
    return masterList.find(item => item.system_name === system_name)?.options
  }
  /* const menu = (item) => {
     console.log(item)
     return <Menu onClick={(e) => handleMenuClick(e, item)}>
       {editMenus.map((item, index) => <Menu.Item key={index} icon={item.icon}> {item.title}  </Menu.Item>)}
     </Menu>
   }*/
  const columns = [{
    title: 'Name', dataIndex: 'name',  key: 'name', fltr: {filterable: true, type: 'text'}
  }, {
    title: 'Dose', key: 'doseInMg', fltr: {filterable: false, type: 'string'},
    render: (drug) => (
      <span style={{textAlign: 'left'}}>{drug.dose} {drug.dose_unit}</span>
    )
  }, {
    title: 'Frequency', dataIndex: 'frequency', key: 'frequency', fltr: { filterable: true, type: 'select', options: makeSelectOptions(GetRawListOptions('frequency')) }
  }, {
    title: 'Strength', dataIndex: ['drug', 'strength'], key: 'strength', fltr: { filterable: true, type: 'text' }
  }, {
    title: 'Notes', dataIndex: 'notes', key: 'notes', fltr: { filterable: false, type: 'text' },ellipsis:true, className:'unbreakable-table-column',
    render: (notes) => {
      return <Popover content={<div className={'w-[200px]'}><p>{notes}</p></div>}>
        <p className={'cursor-pointer overflow-ellipsis overflow-clip'}>{notes}</p>
      </Popover>
    }
  },
    {
      title: 'Status', dataIndex: 'status', key: 'status', fltr: { filterable: true, type: 'select',options: makeSelectOptions(['active','inactive']) },
      render: (status) => getMedicationStatus(status?.toLowerCase())
    },
    {
      title: 'Start Date', dataIndex: 'start_date', key: 'startDate', fltr: { filterable: true, type: 'date' },
      render: (date) => (<>
        {date ? dayjs(date).format('MM/DD/YY') : "N/A"}
      </>)
    }, {
      title: 'End Date', dataIndex: 'end_date', key: 'endDate', fltr: { filterable: true, type: 'date' },
      render: (date) => (<>
        {date ? dayjs(date).format('MM/DD/YY') : "N/A"}
      </>)
    }, {
      title: <EditFilled style={{ color: 'gray' }}/>, fltr: { filterable: false },
      render: (record) => (
        <KebabMenu menus={actionMenus}
                   recordItem={record}
                   handleMenuClick={kebabMenuClick} resource={'medication'} handleOk={() => handleOk(record.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
                   showConfirmDialog={selected === record.id}/>
      )
    }]
  
  async function handleFilterAction(action, values) {
    await dispatch(resetMedicationPagination())
    if (action === 'filter') {
      let pl = { filterData: { patient: patientId, ...values }, pagination: medicationPagination }
      await dispatch(updateMedicationFilters([false, pl, true]))
      await dispatch(fetchMedications(pl))
      await dispatch(updateMedicationFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updateMedicationFilters([appConf.medicationFilters[0], undefined, true]))
      await dispatch(fetchMedications(patientId))
      await dispatch(updateMedicationFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  return (
    (<div>
      {(!!medication_ && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false}
                                                             resourceDescription={`${patient.name}'s medication`} resourceType={'Patient'}
                                                             defaultValues={{ auditableId: medication_?.id, auditable: 'medication', format: 'json' }}/>}
      <Modal
        title={editingForm ? "Edit Medication" : "Add Medication"}
        open={allergiesForm}
        destroyOnClose={true}
        onOk={dialogCloser(setAllergiesForm)}
        onCancel={dialogCloser(setAllergiesForm)}
        closeIcon={modalClose(dialogCloser(setAllergiesForm))}
        maskClosable={false}
        width={750}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setAllergiesForm), 'Cancel'),
            formSubmitButton('medicationForm', editingForm ? 'Edit' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
        </div>]}>
        <NewMedicationForm medication={medication_} editingForm={editingForm} setisExecuting={setisExecuting} closeModal={dialogCloser(setAllergiesForm)}/>
      </Modal>
      <div className="w-full">
        <Table
          title={() => (<TableFilters datasource={columns} setIsVisible={setIsVisible} isVisible={isVisible}
                                      handleFilterAction={handleFilterAction} filters={appConf.medicationFilters[1]} windowState={windowState}
                                      actionButton={(can_create_patient_medication && !patient.deleted_at) ? addButton(() => {
                                        setEditingForm(false)
                                        setAllergiesForm(true);
                                      }) : null}
                                      showClear={appConf.medicationFilters[0]} loading={appConf.medicationFilters[2]}/>)}
          loading={appConf.medicationFilters[2] || navs.patientDetailsLoading} size={'small'} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('medications.') }}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchMedications, appConf.medicationFilters, updateMedicationFilters, { patient: patientId })}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
              } // click row
            };
          }}
          {...getSkels((appConf.medicationFilters[2] || navs.patientDetailsLoading), columns, _.orderBy(medication, "start_date", 'desc'))} pagination={medicationPagination} scroll={{ x: 1000 }}/>
      </div>
    </div>)
  );
}
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser, medication: state.patients.healthData.medication,
  patient: state.patients.selectedPatient, appConf: state.appConf, navs: state.navs,
  medicationPagination: state.patients.medicationPagination
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(MedicationSection)
