import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Button, DatePicker, Divider, Dropdown, Grid, Menu, Popover, Spin, Table, Tag} from "antd"
import {Subtitle1} from '../../../components'
import {
  appLog,
  dialogCloser,
  emptyFun,
  emptyOrders,
  getOrderStatus,
  getSkels,
  keyValItemOrders,
  makeAutoCompleteOptions,
  onTableChange,
  responsiveTbl,
  secondaryButton,
  stateFilter
} from "../../../common/helpers";


import {
  bulkUpdateOrderStatus,
  canUpdateOrderStatus,
  fetchConsultantOrders,
  resetConsultantOrdersPagination,
  resetOrdersPagination,
  selectOrder,
  updateConsultantOrdersFilters
} from '../../../app/Reducers';
import TableFilters from "../../../components/InputComponents/TableFilters";
import dayjs from 'dayjs';


import {CaretDownOutlined, EditOutlined, FieldTimeOutlined, LoadingOutlined} from '@ant-design/icons';
import {apiRequest} from '../../../app/Apis';
import {genOrdersReport, genSingleOrderReport} from "../../../app/Apis/commonApi";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";

import _ from "lodash"
// import PatientOrderPreview from './PatientOrderPreview';
// import ReportForm from './ReportForm';
import PdfViewer from '../../../components/PdfViewer';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useLocation, useNavigate} from 'react-router-dom';

var isoWeek = require('dayjs/plugin/isoWeek')
dayjs.extend(isoWeek)

const groupedItems = (data) => {
  const groupedByCompany = _.groupBy(data, 'biologic_product.company_name');
  
  // Get unique sizes for each company
  const uniqueSizes = _.mapValues(groupedByCompany, (items) => _.uniqBy(items, 'size'));
  
  // Add count of sizes to each object
  const result = _.mapValues(uniqueSizes, (items) => {
    return _.map(items, (item) => {
      return { ...item, count: _.filter(groupedByCompany[item.biologic_product.company_name], { 'size': item.size }).length }
    });
  });
  return result
}


const DateRangePicker = ({ onSave }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  
  const handleSave = () => {
    setVisible(false)
    onSave({ startDate, endDate });
    
  };
  
  const [visible, setVisible] = useState(false);
  
  const handleVisibleChange = (visible) => {
    setVisible(visible);
  }
  const content = (
    <div>
      <DatePicker.RangePicker
        onChange={handleDateChange}
        // disabledDate={disabledDate}
      />
      <Button className="ml-2" type="primary" onClick={handleSave}>Generate</Button>
    </div>
  );
  
  return (
    <Popover open={visible}
             onOpenChange={handleVisibleChange} content={content} title="Select Date Range" trigger="click" placement="left">
      <Button type="primary">Generate report</Button>
    </Popover>
  );
};
const { useBreakpoint } = Grid;
const ConsultantOrders = ({ ordersPagination, appConf, navs, orders,  activeUser, consultant, order }) => {
  const windowState = useBreakpoint();
  const location = useLocation()
  const navigate = useNavigate();
  // picker stuff for report
  const [isOpen, setOpen] = useState(false)
  const [previewisOpen, setpreviewOpen] = useState(false)
  const [selectedOrder, setselectedOrder] = useState(null)
  const dispatch = useDispatch()
  const [isVisible, setIsVisible] = useState(false);
  const [isExecuting, setisExecuting] = useState(false)
  const [availableStatuses, setavailableStatuses] = useState([])
  
  const [orderStatuses, setOrderStatuses] = useState([])
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const can_export_treatment_order = usePermissionCheck('export_treatment_order', false, [])
  
  const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_view__orders = usePermissionCheck('view__orders', false, [])
  const can_view_biologic_orders = usePermissionCheck('view_biologic_orders', false, [])
  const can_update_internal_order_status = usePermissionCheck('update_internal_order_status', false, [])
  // const [order, setOrder] = useState(null);
  
  let onCell = (record, rowIndex) => ({
    onClick: event => {
      (async () => {
        // selectOrdere(record)
        await dispatch(selectOrder(record))
        await dispatch(canUpdateOrderStatus(can_update_internal_order_status))
        navigate(`/orders/${record.id}`)
      })().catch(appLog)
    }
  })
  const columns = [
    /*{
      title: 'Patient', dataIndex: ['patient', 'name'], key: 'patient', fltr: { filterable: true, type: 'text' },
      sorter: { multiple: 3, compare: (a, b) => a?.patient?.name < b?.patient?.name }, onCell
    },*/
   
    {
      title: 'Order date', dataIndex: 'created_at', key: 'createdAt', fltr: { filterable: true, type: 'date' }, onCell,
      render: (created_at, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(created_at).format("MM/DD/YYYY")}</Subtitle1>)
    },
    //baseKey is used as form field name on the Filters form while keys attr is used to name filter API parameters
    {
      title: 'DOS', dataIndex: 'date_of_service', key: 'date_of_service',
      fltr: { filterable: true, type: 'date_range', keys: ['dateOfServiceRange[start_at]', 'dateOfServiceRange[end_at]'], baseKey: 'date_of_service_filter' },
      render: (date_of_service, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(date_of_service).format("MM/DD/YYYY")}</Subtitle1>), onCell
    },
    /*{
      title: 'Delivery Date', dataIndex: 'delivered_date', key: 'delivered_date',
      fltr: { filterable: false, type: 'date_range', keys: ['dateOfServiceRange[start_at]', 'dateOfServiceRange[end_at]'], baseKey: 'date_of_service_filter' },
      render: (delivered_date, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{delivered_date ? dayjs(delivered_date).format("MM/DD/YYYY") : ""}</Subtitle1>), onCell
    },*/
    
    {
      title: 'Week no', dataIndex: 'date_of_service', key: 'weekNumber', fltr: { filterable: true, type: 'number' },
      render: (date_of_service, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(date_of_service).isoWeek()}</Subtitle1>), onCell
    },
    { ...stateFilter, title: 'State', dataIndex: 'state', key: 'state', onCell },
    {
      key: 'visitType', title: 'Order type', dataIndex: 'visit_type', fltr: { filterable: false, type: 'autocomplete', options: makeAutoCompleteOptions(['DME', 'Biologics', 'Internal']) }
    },
    /*{
      title: 'Office', dataIndex: ['patient', 'facility'], key: 'facility', fltr: { filterable: true, type: 'text' }, onCell
    },*/
    /*{
      title: 'Wound Location', key: 'wound_location', fltr: { filterable: true, type: 'text' }, onCell,
      
      render: (record) => record.visit_type === "Biologics" ?
        <Popover style={{ cursor: 'pointer' }}
                 content={<div>
                   {Object.keys(groupedItems(record.biologic_kits)).map(item => {
                       return (<div>
                         {keyValItemOrders('Treatment Number:', `${record.assessment_observations?.treatment_number}`)}
                         
                         {keyValItemOrders('Product Name:', `${item} ${record.biologic_kits[0]?.biologic_product?.product_name}`)}
                         {keyValItemOrders('Wound Size (Area):', `${record.assessment_observations?.area}cm`, "2")}
                         {keyValItemOrders('Recommended Grafts:', ([].concat(Object.values(groupedItems(record.biologic_kits)))[0].map(item => (`${item?.size}(${item.count})`))).toString())}
                         <Divider/>
                       </div>)
                     }
                   )}
                 </div>
                 }
        
        >
          
          <p style={{ cursor: 'pointer' }}>{record.wound_location}</p>
        </Popover> : <p style={{ cursor: 'pointer' }}>{record.wound_location}</p>
    },*/
    
   /* {
      onCell,
      key: 'visitType', title: 'Order type', dataIndex: 'visit_type',
      render: (visit_type, record) => visit_type === "Biologics" ?
        <Popover style={{ cursor: 'pointer' }}
                 content={<div>
                   {Object.keys(groupedItems(record.biologic_kits)).map(item => {
                       return (<div>
                         {keyValItemOrders('Treatment Number:', `${record.assessment_observations?.treatment_number}`)}
                         {keyValItemOrders('Product Name:', `${item} ${record.biologic_kits[0]?.biologic_product?.product_name}`)}
                         {keyValItemOrders('Wound Size (Area):', `${record.assessment_observations?.area}cm`, "2")}
                         {keyValItemOrders('Recommended Grafts:', ([].concat(Object.values(groupedItems(record.biologic_kits)))[0].map(item => (`${item?.size}(${item.count})`))).toString())}
                         
                         <Divider/>
                       </div>)
                     }
                   )}
                 </div>
                 }
        >
          
          <p style={{ cursor: 'pointer' }}>{visit_type}</p>
        </Popover> : visit_type,
      fltr: {
        filterable: false, type: 'autocomplete',
        options: makeAutoCompleteOptions([
          ...(can_view__orders ? [''] : []),
          ...(can_view_biologic_orders ? ['biologics'] : []), 'internal'])
      }
    },*/
    {
      onCell,
      title: 'Status', dataIndex: 'status', key: 'status', fltr: {
        filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(availableStatuses)
      }, render: (status) => getOrderStatus(status?.toLowerCase())
    },
    // generate report button
    // {
    //   title: '', dataIndex: '', key: 'x', render: (text, record, index) => record.assessment_status === "approved" && record.visit_type === "DME" && <Button onClick={async () => {
    //     handleCreateSingleReport(record)
    //   }} type="primary" >Report</Button>
    // },
    
    ...(can_view_audit_logs ?
      [{
        title: '', dataIndex: '', key: 'x', render: (text, record, index) => <Button onClick={async () => {
          await dispatch(selectOrder(record))
          await setShowAuditDialog(true)
          
        }} type="dashed" icon={<FieldTimeOutlined/>}>Audit</Button>
      }] : []),
    ...(activeUser?.is_qa_user?[{ width:15,
      visible: true,
      title: <FontAwesomeIcon color="grey" icon={['fas', 'table']}/>,
      key: 'operation',
      render: (record) => <Tag className={`${(record.is_qa && activeUser?.is_qa_user)?'visible':'invisible'}`} icon={<EditOutlined/>} color="#55acee">QA</Tag>
    }]:[])
  
  ]
  useEffect(() => {
    loadOrders().catch(appLog)
    // getOrderFilterStatuses(setOrderStatuses, '').catch(appLog)
    getStatuses().catch(appLog)
    return emptyFun
  }, [])
  const getStatuses = async () => {
    let data = await apiRequest.get(`/treatment-order-statuses`).catch(e => appLog(e))
    if (data && data.data) {
      setavailableStatuses(data.data.data)
    }
  }
  let commonFilter = { visitType: 'Internal', orderState: "internal" }
  useEffect(() => {
    setSelectedRowKeys([])
    
    return emptyFun
  }, [orders])
  const loadOrders = async () => {
    await dispatch(updateConsultantOrdersFilters([appConf.consultantOrdersFilters[0], appConf.consultantOrdersFilters[1], true]))
    await dispatch(fetchConsultantOrders(appConf.consultantOrdersFilters[0] ? { ...appConf.consultantOrdersFilters[1] } : { filterData: { "consultantId": consultant.id, ...commonFilter } }))
    await dispatch(updateConsultantOrdersFilters([appConf.consultantOrdersFilters[0], appConf.consultantOrdersFilters[1], false]))
  }
  
  const handleFilterAction = async (action, values) => {
    appLog({action, values})
    await dispatch(resetConsultantOrdersPagination())
    if (action === 'filter') {
      let pl = { filterData: { ...values, "consultantId": consultant.id, ...commonFilter }, pagination: ordersPagination }
      await dispatch(updateConsultantOrdersFilters([false, pl, true]))
      await dispatch(fetchConsultantOrders(pl))
      await dispatch(updateConsultantOrdersFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updateConsultantOrdersFilters([appConf.consultantOrdersFilters[0], undefined, true]))
      await dispatch(fetchConsultantOrders({ "filterData": { "consultantId": consultant.id, ...commonFilter } }))
      await dispatch(updateConsultantOrdersFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    // appLog('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  
  const rowSelection = { selectedRowKeys, onChange: onSelectChange };
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const handleMenuClick = (e) => {
    let payload = { Orders: [...selectedRowKeys], status: availableStatuses?.filter(item => !reasonableStatuses.includes(item))[e.key] }
    // appLog(e, payload)
    let data = { payload, filters: { "filterData": { "consultantId": consultant.id } } }
    dispatch(bulkUpdateOrderStatus(data))
  }
  
  
  const reasonableStatuses = ["cancelled", "needs review", "returned"]
  const menu = (<Menu onClick={handleMenuClick}>
    {availableStatuses?.filter(item => !reasonableStatuses.includes(item)).map((item, index) => <Menu.Item key={index}
                                                                                                           style={{ textTransform: 'capitalize' }}> {item.replace('-', '').replace(/_/g, ' ')}  </Menu.Item>)}
  </Menu>);
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderExportForm, setOrderExportForm] = useState(false)
  const handleExportOrderCancel = () => {
    setOrderExportForm(false)
  }
  
  const selectOrdere = async (order) => {
    // await dispatch(selectOrder(order))
    await setselectedOrder(order)
    await fetchPatientById()
    await fetchConsultantById()
    setpreviewOpen(true)
  }
  
  const [wcc, setconsultant] = useState(null)
  const [orderPatient, setorderPatient] = useState(null)
  
  
  const fetchConsultantById = async () => {
    if (selectedOrder?.consultant) {
      const response = await apiRequest.get(`/consultants/${selectedOrder.consultant.id}`)
      const consultant = response?.data?.data
      if (consultant) {
        const shippingResponse = await apiRequest.get(`/shipping-addresses?user=${consultant.user_id}`)
        const consultant_addresses = shippingResponse?.data?.data
        consultant.addresses = [...consultant_addresses]
      }
      setconsultant(consultant)
      
    }
  }
  
  const fetchPatientById = async () => {
    
    
    const _consultant = { ...consultant }
    if (_consultant) {
      const shippingResponse = await apiRequest.get(`/shipping-addresses?user=${consultant.user_id}`)
      const consultant_addresses = shippingResponse?.data?.data
      _consultant.addresses = [...consultant_addresses]
    }
    setorderPatient(_consultant)
    
  }
  const handleCreateReport = async (dates) => {
    console.log(dates)
    let start_at = dayjs(dates.startDate).format("YYYY-MM-DD")
    let end_at = dayjs(dates.endDate).format("YYYY-MM-DD")
    
    await dispatch(genOrdersReport(consultant.id, start_at, end_at, setWoundsProgressPrinting, setStatusModal, setPdf, setLoading, setPdfShowing))
    
  }
  
  const handleCreateSingleReport = async (record) => {
    
    
    await dispatch(genSingleOrderReport(consultant.id, record, setWoundsProgressPrinting, setStatusModal, setPdf, setLoading, setPdfShowing))
    
  }
  
  
  const [isWoundsProgressPrinting, setWoundsProgressPrinting] = useState(false)
  const [visitStatusModal, setStatusModal] = useState(false)
  const [pdfShowing, setPdfShowing] = useState(false);
  const [pdfLoading, setLoading] = useState(false);
  const [pdf, setPdf] = useState(null);
  
  return (
    (<div className="main-page">
      {(showAuditDialog && !!order) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)}
                                                       loading={false} resourceDescription={'Treatment order'}
                                                       resourceType={'Treatment order'}
                                                       defaultValues={{ auditableId: order.id, auditable: 'treatment_order', format: 'json' }}/>}
      <PdfViewer pdf={pdf} setLoading={setLoading} loading={pdfLoading} onCancel={() => setPdfShowing(false)} visible={pdfShowing}/>
      {(showAuditDialog && !!selectedOrder) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false} resourceDescription={'Treatment order'}
                                                               resourceType={'Treatment order'}
                                                               defaultValues={{ auditableId: selectedOrder.id, auditable: 'treatment_order', format: 'json' }}/>}

      <div className="w-full">
        
        <Table
          rowKey={(record) => record.id}
          title={() => (
            <>
              <TableFilters datasource={[...columns]} setIsVisible={setIsVisible}
                            isVisible={isVisible}
                            showClear={appConf.consultantOrdersFilters[0]}
                            handleFilterAction={handleFilterAction}
                            loading={appConf.consultantOrdersFilters[2]}
                            filters={appConf.consultantOrdersFilters[1]}
              />
              {selectedRowKeys.length > 0 && <div className="flex-boy-row-space" style={{ backgroundColor: 'teal', marginTop: '10px' }}>
                <Subtitle1 color="white">Selected: {selectedRowKeys.length}</Subtitle1>
                <Dropdown dropdownRender={()=>menu} trigger={['click']}>
                  {secondaryButton(null, "Change Status", updatingStatus, 'Updating',
                    { backgroundColor: 'white' }, null)}
                </Dropdown>
              </div>}
            </>
          )}
          loading={appConf.consultantOrdersFilters[2]} {...responsiveTbl(windowState)} locale={{ emptyText: emptyOrders('orders.') }}
          onChange={(pagination, filters,
                     sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchConsultantOrders, appConf.consultantOrdersFilters, updateConsultantOrdersFilters, { consultantId: consultant.id, ...commonFilter }, ordersPagination)}
          {...getSkels(appConf.consultantOrdersFilters[2], columns, orders)}
          pagination={ordersPagination}
        />
      </div>
    </div>)
  );
}

const mapStateToProps = (state) => ({
  
  orders: state.consultants.consultantOrders,
  consultantOrdersPagination: state.consultants.consultantOrdersPagination,
  ordersPagination: state.consultants.consultantOrdersPagination,
  appConf: state.appConf,
  navs: state.navs, activeUser: state.auth.activeUser,
  consultant: state.consultants.selectedConsultant,
  order: state.orders.selectedOrder
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantOrders)
