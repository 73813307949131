import React, {useEffect, useState} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import SecureLS from "secure-ls";
import 'core-js'
// import {MqttProvider} from "../hooks/MqttHooks/MqttProvider";
import * as Sentry from "@sentry/react";
import GetCookie from '../hooks/GetCookie';
import {storeDynamicLink} from '../app/Reducers';
import {emptyFun} from '../common/helpers';

const PrivateRoute = ({ component: Component }) => {
  const activeUser = useSelector(state => state.auth?.activeUser);
  const MQTTSetupState = useSelector(state => state.appConf?.MQTTSetupState);
  const dispatch = useDispatch();
  const location = useLocation()
  const navigate = useNavigate();
  let initialOptions = { host: process.env.REACT_APP_MQTT_HOST, port: Number(process.env.REACT_APP_MQTT_PORT), protocol: 'wss', clean: true, reconnectPeriod: 1000 }
  const [options, setOptions] = useState({ ...initialOptions });
  const token = GetCookie('accessToken')
  useEffect(() => {
    // console.log('MQTTSetupState:', MQTTSetupState)
    if (MQTTSetupState&&!!token) {
      try {
        const ls = new SecureLS({ encodingType: 'aes', encryptionSecret: token.substring(0, 32) });
        let pass = ls.get("latlongval")
        if (pass) {
          setOptions({ ...options, username: activeUser.email, password: pass, clientId: activeUser.client_id })
          // setOptions({...options, username: 'vbazinga@gmail.com', password: 'password809', clientId: '1010000000442-staging-243cddcf-bf0a-4950-a365-e11c022e44db'})
        }
      } catch (e) {
        Sentry.captureException(e);
        console.error(e)
      }
      dispatch(storeDynamicLink(null))
    } else {
      setOptions({ ...initialOptions })
      // if (dynamicLink !== "/auth/login") {
      
      //   dispatch(storeDynamicLink(rest.location.pathname))
      // }
    }
  }, [MQTTSetupState])
  
  useEffect(() => {
    if (!activeUser) {
      dispatch(storeDynamicLink(location.pathname))
    }
    return emptyFun
  }, [])
  useEffect(() => {
    // console.log({options,MQTTSetupState})
    return emptyFun
  }, [MQTTSetupState])
  useEffect(() => {
    // console.log({ 'user in PrivateRoute': activeUser })
    if (!activeUser) {
      navigate("/auth/login")
    }
    return emptyFun
  }, [activeUser])

  return !!activeUser ? <Component/> : <Navigate to="/auth/login" replace/>
}
const mapStateToProps = (state) => ({ activeUser: state.auth?.activeUser, dynamicLink: state.auth?.dynamicLink, MQTTSetupState: state.appConf?.MQTTSetupState })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
