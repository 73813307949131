/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react'
import {connect, useDispatch} from 'react-redux'
import {Subtitle1} from '../../components'
import HeaderTop from '../../components/HeaderTop'
import PreOrder from './components/PreOrder';
import DmeList from './components/DmeList'
import BiologicsList from './components/BiologicsList';
import {setOrderNavs} from "../../app/Reducers";
import InternalList from './components/InternalList';
import OrderHistories from './components/OrderHistories';
import {Tabs} from "antd";
import FinalOrders from './components/FinalOrders';
import {usePermissionCheck} from '../../hooks/usePermissionCheck';

export const Orders = ({ navs }) => {
  const dispatch = useDispatch()
  const can_view_preorders = usePermissionCheck('view_preorders', false, [])
  const can_view_biologic_final = usePermissionCheck('view_biologic_final', false, [])
  const can_view_biologic_orders = usePermissionCheck('view_biologic_orders', false, [])
  const can_view_dme_orders = usePermissionCheck('view_dme_orders', false, [])
  const can_view_internal_orders = usePermissionCheck('view_internal_orders', false, [])
  const can_view_order_history = usePermissionCheck('view_order_history', false, [])
  const setActiveTab = async (tab) => {
    await dispatch(setOrderNavs({ tab: tab }))
  }
  
  const Tabs = () => {
    
    return (<div className="flex-boy-row patients-tabs">
      
      {can_view_preorders && <div onClick={() => setActiveTab("preorders")} style={{ height: "60px" }}
                                                                        className={navs.orderNavs.tab === "preorders" ? "patient-tab active" : "patient-tab"}>
        <Subtitle1>Pre Orders</Subtitle1>
      </div>}
      
      {can_view_biologic_final && <div onClick={() => setActiveTab("finalorders")} style={{ height: "60px" }}
                                                                             className={navs.orderNavs.tab === "finalorders" ? "patient-tab active" : "patient-tab"}>
        <Subtitle1>Final Orders</Subtitle1>
      
      </div>}
      {can_view_biologic_orders && <div onClick={() => setActiveTab("biologicslist")} style={{ height: "60px" }}
                                                                              className={navs.orderNavs.tab === "biologicslist" ? "patient-tab active" : "patient-tab"}>
        <Subtitle1>Biologics List</Subtitle1>
      
      </div>}
      
      {can_view_dme_orders && <div onClick={() => setActiveTab("dmelist")} style={{ height: "60px" }}
                                                                         className={navs.orderNavs.tab === "dmelist" ? "patient-tab active" : "patient-tab"}>
        <Subtitle1>DME List</Subtitle1>
      </div>}
      
      
      {can_view_internal_orders && <div onClick={() => setActiveTab("internal")} style={{ height: "60px" }}
                                                                              className={navs.orderNavs.tab === "internal" ? "patient-tab active" : "patient-tab"}>
        <Subtitle1>Internal</Subtitle1>
      </div>}
      {can_view_order_history && <div onClick={() => setActiveTab("history")} style={{ height: "60px" }}
                                                                            className={navs.orderNavs.tab === "history" ? "patient-tab active" : "patient-tab"}>
        <Subtitle1>Order History</Subtitle1>
      </div>}
    </div>)
    // return
    // <Tabs defaultActiveKey={navs.orderNavs.tab} onChange={setActiveTab} tabBarStyle={{ marginBottom: 0 }}>
    //   {TabList.map(item => <TabPane key={item} tab={<Subtitle1>{item}</Subtitle1>}>
    //   </TabPane>)}
    // </Tabs>
  }
  return (<div className="main-page">
    <HeaderTop child={<Tabs/>}/>
    {navs.orderNavs.tab === 'preorders' ? <PreOrder/> : navs.orderNavs.tab === 'dmelist' ?
      <DmeList/> : navs.orderNavs.tab === 'finalorders' ? <FinalOrders/> : navs.orderNavs.tab === "internal" ? <InternalList/> : navs.orderNavs.tab === "history" ? <OrderHistories/> :
        <BiologicsList/>}
  </div>)
}
const mapStateToProps = (state) => ({
  navs: state.navs
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Orders)

