/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Subtitle1 } from '../general';
import { Avatar, Badge, Drawer, Dropdown, Image, Menu, Select, Tooltip } from 'antd';
import { logOut } from '../../app/Reducers';

import { fetchFacilityById, fetchStaffFacilityList, markAllRead } from '../../app/Reducers';

import {useLocation, useNavigate} from 'react-router-dom';
import Notifications from '../../content/Notifications';
import SupportButton from '../SupportButton';
import { unset } from 'lodash';
// import { logout as ChatLogout} from '../../app/Helpers/Utility';

export const useFindPath = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState();
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);
  return currentPath;
};

export const HeaderTop = ({
  child,
  activeUser,
  customStyle,
  
  notifications,
  rcopianotifications,
  facilities,
  facility,
  provider
}) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const path = useFindPath();
  const logout = async () => {
    // if (activeUser.mirrorfly_username) {
    //    ChatLogout()
    // }
    await dispatch(logOut(navigate));
    // document.getElementsByClassName('minimized')[0].style.visibility = 'hidden';
  };
  // const handleLoaded = () => {
  //   ZendeskAPI("launcher", "closed");
  // };
  const { Option } = Select

  const [visible, setvisible] = useState(false);
  const onClose = () => {
    setvisible(false);
  };
  const readAll = () => {
    dispatch(markAllRead());
  };
  const menu = (
    <Menu>
      <Menu.Item key="0"></Menu.Item>
      {/* <Menu.Item
        key='1'
        onClick={() =>
          window.open(
            'https://tellanbusiness.atlassian.net/servicedesk/customer/portal',
            '_blank'
          )
        }
      >
        Help Desk
      </Menu.Item> */}
      <Menu.Divider />
      <Menu.Item
        onClick={() => {
          // console.log('activeUser:  ', activeUser)
          //  dispatch(resetProvider())
          navigate(`/user-profile/${activeUser.id}`);
        }}
        key="2"
      >
        Profile
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={() => logout()} key="3">
        Log-Out
      </Menu.Item>
    </Menu>
  );


  useEffect(() => {
    if (activeUser?.facilityProfile) {
      dispatch(fetchStaffFacilityList())
    }

    return () => {

    }
  }, [activeUser])
  const handleChange = async (value) => {
    // await dispatch(unsetFacility())
    await dispatch(fetchFacilityById(value))
  };
  const pathstohide = ["patients/", "wounds/"]
  const NotificationHeader = () => (
    <div className="flex-boy-row-space" style={{ width: '100%' }}>
      <Subtitle1>Notifications</Subtitle1>
      <div className="flex-boy-row-space" style={{ width: '80px' }}>
        <Tooltip title="Mark all as read">
          <FontAwesomeIcon
            onClick={readAll}
            color="gray"
            size="md"
            icon={['fas', 'check-double']}
          />
        </Tooltip>
        <FontAwesomeIcon
          onClick={() => setvisible(false)}
          color="gray"
          size="lg"
          icon={['fas', 'times']}
        />
      </div>
    </div>
  );
  return (
    (<div id="header-top" className="flex-boy-row">

      <div className="header-children">{child}</div>
      {activeUser?.facilityProfile && !pathstohide.some(item => path?.includes(item)) ? (

        <div className="flex fel-row justify-between items-center" style={{ flex: 1, textAlign: 'left' }}>
          {activeUser?.facilityProfile && facility?.logo && <Image className="mx-2" height={50} src={facility?.logo?.logo_url} />}
          {facilities.length > 1 ? <Select
            size="large"
            defaultValue={facility?.name}
            onChange={handleChange}
          >
            {facilities?.map(item => <Option value={item.id}>{item.name}</Option>)}

          </Select> : <Subtitle1>{facility?.name}</Subtitle1>}

        </div>
      ) : null}
      <div className="flex-boy-row">
        {/*<div className="main-menu-item">*/}
        {/*  <FontAwesomeIcon color="gray" size="lg" icon={["fas", "plus"]} />*/}
        {/*</div>*/}
        <SupportButton />
        <div onClick={() => setvisible(true)} className="main-menu-item">
          <Badge
            count={
              notifications?.filter((item) => !item.read_at).length ||
                (activeUser?.rcopia_username &&
                  rcopianotifications?.reduce((accumulator, object) => {
                    return accumulator + parseFloat(object.number);
                  }, 0))
                ?
                notifications.filter((item) => !item.read_at).length
                +
                parseFloat(rcopianotifications?.length && rcopianotifications?.reduce((accumulator, object) => {
                  return accumulator + parseFloat(object.number);
                }, 0) || 0)
                : 0
            }
          >
            <FontAwesomeIcon color="gray" size="lg" icon={['far', 'bell']} />
          </Badge>
        </div>
        <Dropdown trigger={'click'} dropdownRender={()=>menu}>
          <div className={'cursor-pointer'}>
            <Avatar
              /*onClick={() => {
                navigate(`/user-profile/${activeUser?.id}`);
              }}*/
              src={
                <Image preview={false}
                  src={
                    provider?.profile_image_url || activeUser?.profile_image_url ||
                    require('../../assets/user.png')
                  }
                />
              }
            />
            <FontAwesomeIcon
              style={{ marginLeft: '5px' }}
              color="gray"
              icon={['fas', 'chevron-down']}
            />

          </div>
        </Dropdown>
      </div>
      <Drawer
        title={<NotificationHeader />}
        placement="right"
        closable={false}
        destroyOnClose={true}
        onClose={onClose}
        open={visible}
        width={500}>
        <Notifications />
      </Drawer>
    </div>)
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser,
  notifications: state.notifications.notifications,
  rcopianotifications: state.notifications.rcopianotifications,
  facilities: state.facilities.allowedFacilities,
  facility: state.facilities.selectedFacility,
  provider: state.consultants.selectedProvider
});
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderTop);
