import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Form, Select } from 'antd'
import dayjs from "dayjs"
import { updateProvider } from '../../../app/Reducers'
import { apiRequest } from '../../../app/Apis'

import { emptyFun } from "../../../common/helpers";
import * as Sentry from "@sentry/react";


const services = ['Specialized wound dressings',
  'Debridement',
  'Compression therapy',
  'Hyperbaric oxygen therapy (HBOT)',
  'Prescription growth factors',
  'Engineered skin grafting',
  'Edema management',
  'Non-invasive vascular assessment']

export const EditServices = ({ closeModal, setisExecuting, provider }) => {
  const dateFormat = 'YYYY-MM-DD';
  const { Option } = Select;
  const dispatch = useDispatch();

  // const [roles, setRoles] = useState([]);
  useEffect(() => {
    // fetchStates();
    fetchRoles()
    return emptyFun
  }, [])

  const fetchRoles = async () => {
    const response = await apiRequest.get('/roles').catch(function (err) {
      console.log(err)
    })
    console.log(response.data.data)
    if (response && response.data && response.data.data) {
      // setRoles(response.data.data)
    }
  }
  const onFinish = async (values) => {

    setisExecuting(true)
    const payload = {
      ...values, timezone: "PDT", name: values.name,
      birth_date: dayjs(values.birth_date).format(dateFormat),
      date_of_hire: dayjs(values.date_of_hire).format(dateFormat)
    }
    console.log(payload)
    let params = { data: payload, consultant_id: provider.id }
    try {
      await dispatch(updateProvider(params))
      // await apiRequest.patch(`/chief-practices/${provider.id}`, payload)
      // await dispatch(fetchProvider(provider.id))
      setisExecuting(false)
      closeModal()
    } catch (e) {
      Sentry.captureException(e);
      console.log("eerrrr", e);
      setisExecuting(false)
    }
  }

  return (<Form
    name="providerForm"
    id="providerForm"
    labelCol={{
      span: 24
    }}
    layout="vertical"
    onFinish={onFinish}
    initialValues={{
      ...provider, birth_date: dayjs(provider.birth_date), date_of_hire: dayjs(provider.date_of_hire)
    }}
  >
    <div className="flex-boy-row-space">
      <Form.Item
        style={{ width: '100%' }}
        name="services"
        label="Services"
        rules={[{ required: true, message: 'Missing SSN' }]}
      >
        <Select
          mode="multiple"
          style={{ width: '100%' }}
        >
          {services.map(item => <Option value={item}>{item}</Option>)}

        </Select>
      </Form.Item>
    </div>


  </Form>)
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(EditServices)
