import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Subtitle1} from '../../../components'
// import { NewPatentCard } from './components/NewPatentCard';
import {Modal, Table, Tag, Button, Grid} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {fetchDrafts, resetDraftsPagination, resetPatientModuleState, selectAssessment, selectPatient, setDraftsLoaded, updateDraftListFilters} from '../../../app/Reducers';
import {toast} from "react-toastify"
import dayjs from 'dayjs';
import {apiRequest} from '../../../app/Apis';

import {
  dialogCloser,
  emptyFun,
  emptyTable,
  formSubmitButton,
  getAssessmentStatus,
  getSkels,
  horStack,
  makeAutoCompleteOptions,
  modalClose,
  onTableChange,
  outlinedButton,
  responsiveTbl,
  stateFilter
} from "../../../common/helpers";
import useExitPrompt from "../../../hooks/useExitPrompt";
import VisitStatus from "./VisitStatus";
import DraftAssessment from './DraftAssessment';
import VisitDetails from "./VisitDetails";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import TableFilters from "../../../components/InputComponents/TableFilters";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {EditOutlined} from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';
import {useProcedureSearch} from '../../../hooks/useProcedureSearch';
const { useBreakpoint } = Grid;
const DraftList = ({  drafts, appConf,  appointments, navs, draftsPagination, activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState = useBreakpoint();
  const can_view_assessment = usePermissionCheck('view_assessment', false, [])
  const [procedureOptions, debouncedProcedureSearch, procedureSearchLoading] = useProcedureSearch([], 'name')
  const columns = [{
    title: 'Patient', dataIndex: ['appointment', 'patient', 'name'], key: 'patientName',
    onCell: (record, rowIndex) => {
      return {
        onClick: event => {
          (async () => {
            let patient = record.appointment.patient
            if (patient.id !== navs.patientDetailsOwner) {
              await dispatch(resetPatientModuleState())
            }
            await dispatch(selectPatient({ record: patient }))
            navigate(`/patients/${record.appointment.patient.id}`)
          })().catch(e => console.log(e))
        } // click cell
      };
    }, sorter: { multiple: 3, compare: (a, b) => a.name - b.name }, fltr: { filterable: true, type: 'text' },
    render: (name) => ((<Button size={"middle"} type={"text"}
                                   style={{
                                     textTransform: 'none', margin: 0, width: '100%', justifyContent: "flex-start",
                                     backgroundColor: 'transparent', color: '#038A96'
                                   }}>{name}</Button>)
      /*<>
        <div className="flex-boy-row-space" style={{justifyContent: 'normal',cursor:'pointer'}}>

          <Subtitle1 style={{textAlign: "left", marginLeft: '10px'}}>{name}</Subtitle1>
        </div>
      </>*/)
  }, {
    title: 'Date', dataIndex: ['appointment', 'start_at'], key: 'date', fltr: { filterable: true, type: 'date' },
    render: (start_at, record) => (
      <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(start_at).format("MM/DD/YYYY")}</Subtitle1>)
  }, {
    title: 'Wound Location', fltr: { filterable: true, type: 'text' }, key: 'woundLocation',
    render: (record) => (<Subtitle1
      style={{ textAlign: 'left' }}>{`${record.wound_position && record.wound_side ? record.wound_side : ''} ${record.wound_position || ''} ${record.location || ''}`}</Subtitle1>)
  }, {
    title: 'Procedure Type', dataIndex: 'procedure', key: 'procedure', fltr: {
      filterable: true, type: 'select', options: procedureOptions,
      props: {
        loading: procedureSearchLoading,
        onSearch: (value) => debouncedProcedureSearch(value), showSearch: true, filterOption: false
      }
    }, render: (recommended_procedure) => {
      if (recommended_procedure && Array.isArray(recommended_procedure) && recommended_procedure.length > 0) {
        return recommended_procedure.join(', ')
      } else {
        return recommended_procedure
      }
    }
  }, {
    title: 'Office', dataIndex: ['appointment', 'patient', 'medical_facility'], key: 'facility'
    // fltr: {filterable: true, type: 'text'}
  }, {
    title: 'Provider', dataIndex: ['appointment', 'consultant', 'name'], key: 'provider',
    fltr: { filterable: true, type: 'text' }
  }, {
    title: 'Visit Type', dataIndex: ['appointment', 'appointment_visit_type'], key: 'visitType',
    fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(['established visit', 'new visit']) }
  }, {
    title: 'Assessment Status', dataIndex: 'review', key: 'reviewStatus', fltr: {
      filterable: false, type: 'autocomplete', options: makeAutoCompleteOptions(['rejected', 'approved', 'pending_review'])
    }, render: (review) => getAssessmentStatus(review?.status?.toLowerCase())
  },
    ...(activeUser?.is_qa_user?[{ width:15,
      visible: true,
      title: <FontAwesomeIcon color="grey" icon={['fas', 'table']}/>,
      key: 'operation',
      render: (record) => <Tag className={`${(record.is_qa && activeUser?.is_qa_user)?'visible':'invisible'}`} icon={<EditOutlined/>} color="#55acee">QA</Tag>
    }]:[])]
  const dispatch = useDispatch()
  const [visitStatus, setStatus] = useState("Approve")
  const [sellectedVisit, setsellectedVisit] = useState(null)
  const [visitStatusModal, setStatusModal] = useState(false)
  const [editassessmentModal, seteditassessmentModal] = useState(false)
  const [isVisible, setIsVisible] = useState(false);
  const [isExecuting, setisExecuting] = useState(false)
  const [isPrinting, setPrinting] = useState(false)
  const [isAllograftPrinting, setAllograftPrinting] = useState(false)
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const { message } = useSubscription(`view_assessment`, { qos: 2, nl: true });
  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message)
      loadEncounters(true).catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  const loadEncounters = async (isMq) => {
    /* if (!!drafts[0] && !isMq) {
       return
     }*/
    await dispatch(updateDraftListFilters([appConf.draftListFilters[0], appConf.draftListFilters[1], !isMq]))
    await dispatch(fetchDrafts(appConf.draftListFilters[0] ? appConf.draftListFilters[1] : undefined))
    await dispatch(setDraftsLoaded(true))
    await dispatch(updateDraftListFilters([appConf.draftListFilters[0], appConf.draftListFilters[1], false]))
  }
  const syncPageStatus = () => {
  }
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(true, loadEncounters, syncPageStatus);
  
  async function handleFilterAction(action, values) {
    await dispatch(resetDraftsPagination())
    if (action === 'filter') {
      console.log('FILTERS:  ', JSON.stringify({ ...values, toLoad: 'drafts', endpoint: '/assessments' }))
      let pl = { filterData: values, pagination: draftsPagination }
      await dispatch(updateDraftListFilters([false, pl, true]))
      await dispatch(fetchDrafts(pl))
      await dispatch(updateDraftListFilters([true, pl, false]))
    }
    if (action === 'reset'/* && appConf.draftListFilters[0]*/) {
      await dispatch(updateDraftListFilters([appConf.draftListFilters[0], undefined, true]))
      await dispatch(fetchDrafts())
      await dispatch(updateDraftListFilters([false, undefined, false]))
    }
    // const response = await apiRequest.get(`/assessments`, { params: { ...values, toLoad: 'drafts' } })
    setIsVisible(prevState => false)
  }
  
  const [VisitModal, setVisit] = useState(false)
  useEffect(() => {
    loadEncounters(false).catch(e => console.log(e))
    return emptyFun
  }, [])
  const approveAsessment = async () => {
    await setisExecuting(true)
    const toastId = toast("Updating Assesment", { type: "info", autoClose: false });
    const response = await apiRequest.post(`/assessments/${sellectedVisit.id}/status`, { status: "approved" }).catch(async function (err) {
      await setisExecuting(false)
      console.log(err.response.data.message)
      toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
      await dispatch(fetchDrafts(appConf.draftListFilters[0] ? appConf.draftListFilters[1] : undefined))
    })
    if (response && response.data) {
      toast.update(toastId, { render: "Assessment Approved", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      await dispatch(fetchDrafts(appConf.draftListFilters[0] ? appConf.draftListFilters[1] : undefined))
    }
    await setisExecuting(false)
    setVisit(false)
    setStatusModal(false)
  }
  const declineAssessment = async (payload) => {
    await setisExecuting(true)
    const toastId = toast("Updating Assessment", { type: "info", autoClose: false });
    let data = { notes: payload.notes, status: "rejected" }
    const response = await apiRequest.post(`/assessments/${sellectedVisit.id}/status`, data).catch(async function (err) {
      await setisExecuting(false)
      console.log(err)
      toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
      await dispatch(fetchDrafts(appConf.draftListFilters[0] ? appConf.draftListFilters[1] : undefined))
    })
    if (response && response.data) {
      toast.update(toastId, { render: "Assessment Rejected", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      await dispatch(fetchDrafts(appConf.draftListFilters[0] ? appConf.draftListFilters[1] : undefined))
    }
    await setisExecuting(false)
    setVisit(false)
    setStatusModal(false)
  }
  const handlevisitOk = () => setVisit(false)
  const handlevisitCancel = () => setVisit(false)
  const generateReport = async () => {
    await setPrinting(true)
    const toastId = toast("Printing Report", { type: "info", autoClose: false });
    const response = await apiRequest.get(`/assessments/${sellectedVisit.id}/report-pdf`).catch(async function (err) {
      await setPrinting(false)
      console.log(err.response.data)
      toast.update(toastId, { render: `${err.response.data.message}`, type: toast.TYPE.ERROR, autoClose: 5000 });
    })
    if (response && response.data) {
      toast.update(toastId, { render: "Assessment Printed", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      // dispatch(fetchDrafts())
      window.open(response.data.reportUrl, '_blank');
    }
    await setPrinting(false)
    setVisit(false)
    setStatusModal(false)
  }
  const generateAllograftReport = async () => {
    await setAllograftPrinting(true)
    const toastId = toast("Printing Report", { type: "info", autoClose: false });
    const response = await apiRequest.get(`/assessments/${sellectedVisit.id}/allograft-pdf`).catch(async function (err) {
      await setAllograftPrinting(false)
      console.log(err.response.data)
      toast.update(toastId, { render: `${err.response.data.message}`, type: toast.TYPE.ERROR, autoClose: 5000 });
    })
    if (response && response.data) {
      toast.update(toastId, { render: "Allograft Report Printed", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      // dispatch(fetchDrafts())
      window.open(response.data.reportUrl, '_blank');
    }
    await setAllograftPrinting(false)
    setVisit(false)
    setStatusModal(false)
  }
  
  return (
    (<div className="main-page">
      {(!!sellectedVisit && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)}
                                                                resourceType={'Assessment'}
                                                                loading={false}
                                                                resourceDescription={`${sellectedVisit.appointment.patient.name}'s ${sellectedVisit.procedure} assessment`}
                                                                defaultValues={{ auditableId: sellectedVisit?.id, auditable: 'assessment', format: 'json' }}/>}
      {!!sellectedVisit &&
        <Modal
          title={`Draft assessment on ${sellectedVisit.appointment.patient.name}`}
          open={editassessmentModal}
          onOk={() => seteditassessmentModal(false)}
          onCancel={() => seteditassessmentModal(false)}
          closeIcon={modalClose(() => seteditassessmentModal(false))}
          maskClosable={false}
          destroyOnClose={true}
          width={1500}
          footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([outlinedButton(() => seteditassessmentModal(false), 'Cancel', isExecuting),
              formSubmitButton('updateappointmentForm', 'Create Assessment', isExecuting, 'Creating')])}
          </div>]}>
          <DraftAssessment setVisit={setVisit} setisExecuting={setisExecuting}
                           setassessmentModal={seteditassessmentModal}
            // assessment={sellectedVisit}
                           appointment={sellectedVisit.appointment} procedure={sellectedVisit.procedure}/>
        
        </Modal>}
      {(VisitModal && !!sellectedVisit) &&
        <VisitDetails setShowAuditDialog={setShowAuditDialog}
          // assessment={sellectedVisit}
                      setVisit={setVisit} approveAsessment={approveAsessment}
                      VisitModal={VisitModal} seteditassessmentModal={seteditassessmentModal}
                      isPrinting={isPrinting} isExecuting={isExecuting}
                      setStatusModal={setStatusModal} handlevisitOk={handlevisitOk}
                      handlevisitCancel={handlevisitCancel}
                      generateReport={generateReport}
                      generateAllograftReport={generateAllograftReport}/>}
      <VisitStatus visitStatusModal={visitStatusModal} setStatusModal={setStatusModal} isExecuting={isExecuting} declineAssessment={declineAssessment}/>
      <div className="w-full">
        <Table
          title={() => <TableFilters datasource={[...columns, stateFilter]} setIsVisible={setIsVisible}
                                     isVisible={isVisible} filters={appConf.draftListFilters[1]}
                                     handleFilterAction={handleFilterAction} windowState={windowState}
                                     showClear={appConf.draftListFilters[0]} loading={appConf.draftListFilters[2]}/>}
          size={'small'} loading={appConf.draftListFilters[2]} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('draft assessments') }}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchDrafts, appConf.draftListFilters, updateDraftListFilters, null, draftsPagination)}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                (async () => {
                  await dispatch(selectAssessment(record))
                  if (can_view_assessment) {
                    setsellectedVisit(record)
                    setVisit(true)
                  }
                })().catch(e => console.log(e))
              } // click row
            };
          }}
          pagination={draftsPagination} {...getSkels((appConf.draftListFilters[2]), columns, drafts)} />
      </div>
    </div>)
  );
}
const mapStateToProps = (state) => ({
  drafts: state.appointments.drafts, appConf: state.appConf, navs: state.navs,
  appointments: state.appointments.appointments,
  draftsPagination: state.appointments.draftsPagination,
  activeUser: state.auth.activeUser
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(DraftList)
