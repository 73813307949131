/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Body2, Headline6, RegularButton, Subtitle1} from '../../../../components'
import {AutoComplete, Divider, Form, Input, Modal, Radio, Select, Tag} from 'antd';
import dayjs from 'dayjs';
import {apiRequest} from '../../../../app/Apis';
import {dialogCloser, emptyFun, executeChunkFn, formSubmitButton, horStack, modalClose, outlinedButton} from "../../../../common/helpers";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {createOrder, editWCCSignature, fetchAuditLogs, updateOrder} from '../../../../app/Reducers';
import _ from "lodash"
import {useConsultantSearch} from '../../../../hooks/useConsultantSearch';

const CreateOrder = ({ OrderModal, handleOrderCancel, handleOrderOk, setOpen, type, orderToEdit, loadOrders }) => {
  const { Option } = Select;
  const dispatch = useDispatch()
  const [form] = Form.useForm();
  const [consultant, setWcc] = useState(null)
  const [consultantOptions, setWccOptions] = useState([])
  const [addressOptions, setAddressOptions] = useState([])
  const [treatmentorderItems, setTreatmentorderItems] = useState([])
  const [newTreatmentSupply, setnewTreatmentSupply] = useState(false)
  const [newPlanofCare, setnewPlanofCare] = useState(false)
  const [treatmentSupplies, setTreatmentSupplies] = useState([])
  
  const [ppetreatmentorderItems, setppeTreatmentorderItems] = useState([])
  const [newppeTreatmentSupply, setnewppeTreatmentSupply] = useState(false)
  const [newppePlanofCare, setnewppePlanofCare] = useState(false)
  const [ppetreatmentSupplies, setppeTreatmentSupplies] = useState([])
  const debouncedConsultantSearch = useConsultantSearch(setWccOptions, [])
  const [isExecuting, setisExecuting] = useState(false)
  const [order_type, setorder_type] = useState(orderToEdit?.internal_order_resource_type || type)
  
  const [validationerrors, setvalidationerrors] = useState({
    supplyItems: false,
    ppesupplyItems: false,
    delivery_location: false
  })
  useEffect(() => {
    if (orderToEdit) {
      setWccOptions([
        {
          label: orderToEdit.consultant.name || `${orderToEdit.consultant?.first_name || ''} ${orderToEdit.consultant?.last_name || ''}`,
          value: orderToEdit.consultant.name || `${orderToEdit.consultant?.first_name || ''} ${orderToEdit.consultant?.last_name || ''}`,
          ...orderToEdit.consultant
        }
      ])
      setorder_type(orderToEdit?.internal_order_resource_type)
      setTreatmentorderItems(orderToEdit?.dme_supplies)
      setppeTreatmentorderItems(orderToEdit?.ppe_kits.map(item => ({ ...item })));
      setWcc(orderToEdit?.consultant)
      setAddressOptions(orderToEdit.consultant.addresses.map((item, index, array) => {
        return {
          value: item.street_address,
          label: (<div className="flex-boy-row-space">
            <span>{item.physical_address ? `${item.physical_address} -` : null}{item.street_address}</span>
            {item.default && <div style={{ textAlign: 'left' }}><Tag style={{ textAlign: 'left' }} color="#108ee9">Default</Tag></div>}
          </div>)
        }
      }))
    }
    return emptyFun
  }, [orderToEdit])
  const onFinish = async (values) => {
    let defaultConsultantAddress = consultant?.addresses?.find(item => item.street_address === values.shipping_address_1)
    let payload = {
      reason: values.reason,
      consultant_id: consultant.id,
      internal_order_resource_type: order_type,
      date_of_service: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      shipping_address_1: defaultConsultantAddress.street_address,
      state: defaultConsultantAddress.state,
      city: defaultConsultantAddress.city,
      shipping_address_2: defaultConsultantAddress.physical_address || "",
      visit_type: "Internal"
      
    }
    
    if (order_type === "DME") {
      if (treatmentorderItems.length === 0 || treatmentorderItems.some(item => !item.unit_count)) {
        setvalidationerrors({ ...validationerrors, supplyItems: true })
        return
      } else {
        setvalidationerrors({ ...validationerrors, supplyItems: false })
        payload.dme_supplies = treatmentorderItems.map(item => {
          return (
            {
              id: item.id,
              unit_count: item.unit_count,
              pack_amount: item.pack_amount || item.default_pack_amount,
              type: "Primary Dressing"
            }
          )
        })
      }
    } else {
      
      //  if any of the fields are empty, set the validation error to true
      if (ppetreatmentorderItems.length === 0 || ppetreatmentorderItems.some(item => !item.unit_count)) {
        setvalidationerrors({ ...validationerrors, ppesupplyItems: true })
        return
      } else {
        setvalidationerrors({ ...validationerrors, ppesupplyItems: false })
        payload.ppe_kits = ppetreatmentorderItems.map(item => {
          return (
            {
              id: item.id,
              unit_count: item.unit_count
            }
          )
        })
      }
    }
    
    setisExecuting(true)
    
    const { kits, wcc, ...rest } = { ...payload, ...(orderToEdit && { id: orderToEdit.id }) }
    await executeChunkFn(dispatch, orderToEdit ? updateOrder : createOrder, rest, setisExecuting, handleOrderCancel, loadOrders)
  }
  
  const onManagerSelect = async (value, option) => {
    
    let consultant = consultantOptions.find(item => item.name === value)
    if (consultant) {
      const shippingResponse = await apiRequest.get(`/shipping-addresses?user=${consultant.user_id}`)
      const consultant_addresses = shippingResponse?.data?.data
      setAddressOptions(consultant_addresses)
      consultant.addresses = [...consultant_addresses]
    }
    setWcc(consultant)
  };
  
  // treatment supplies
  const handlesupplysearch = async (value) => {
    const { data } = await apiRequest.get(`/treatment-supplies?search=${value}`)
    if (data) {
      //console.log(data.data)
      setTreatmentSupplies(data.data);
    }
  };
  const onsupplySelect = (value) => {
    //console.log('onSelect', value);
    let supply = treatmentSupplies.find(item => item.name === value)
    let supplies = [...treatmentorderItems]
    supplies.push(supply)
    setTreatmentorderItems(supplies)
    setnewTreatmentSupply(false)
  };
  const removeTreatmentItem = (index) => {
    let supplies = [...treatmentorderItems]
    supplies.splice(index, 1);
    setTreatmentorderItems(supplies)
  }
  const unitChange = (item, index, value) => {
    let supplies = [...treatmentorderItems]
    supplies[index].unit_count = value
    setTreatmentorderItems(supplies)
  }
  
  // PPE treatment supplies
  const handleppesupplysearch = async (value) => {
    const { data } = await apiRequest.get(`/ppe-kits?search=${value}`)
    if (data) {
      //console.log(data.data)
      setppeTreatmentSupplies(data.data);
    }
  };
  const onppesupplySelect = (value) => {
    //console.log('onSelect', value);
    let supply = ppetreatmentSupplies.find(item => item.name === value)
    let supplies = [...ppetreatmentorderItems]
    supplies.push(supply)
    setppeTreatmentorderItems(supplies)
    setnewppeTreatmentSupply(false)
  };
  const removeppeTreatmentItem = (index) => {
    let supplies = [...ppetreatmentorderItems]
    supplies.splice(index, 1);
    setppeTreatmentorderItems(supplies)
  }
  const ppeunitChange = (item, index, value) => {
    console.log(item, index, value)
    let supplies = [...ppetreatmentorderItems]
    supplies[index].unit_count = value
    setppeTreatmentorderItems(supplies)
  }
  const handleTypeChange = (type) => {
    //console.log(type)
    setorder_type(type.target.value)
  }
  
  
  const handleCancel = () => {
    
    setWcc(null)
    handleOrderCancel()
    
  }
  return (
    (<Modal
      open={OrderModal}
      onOk={handleOrderOk}
      onCancel={handleCancel}
      destroyOnClose={true}
      width={800}
      closeIcon={modalClose(handleCancel)}
      title={orderToEdit ?"Edit Internal Order" :"New Internal Order"}
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        {horStack([
          outlinedButton(handleCancel, 'Cancel', isExecuting),
          formSubmitButton('newordeForm', orderToEdit ? "Edit":'Create', isExecuting, orderToEdit ? "Updating" : "Creating")])}
      </div>]}
    >
      <Form
        name="neworderForm"
        preserve={false}
        id="newordeForm"
        labelCol={{ span: 24 }}
        initialValues={{ internal_order_resource_type: type, ...orderToEdit }}
        layout="vertical"
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Order Resource Type"
          name="internal_order_resource_type"
          rules={[{ required: true, message: 'this field is required!' }]}
          wrapperCol={{ span: 24 }}>
          <Radio.Group onChange={handleTypeChange}>
            <Radio value="DME">DME</Radio>
            <Radio value="PPE">PPE</Radio>
          
          </Radio.Group>
        </Form.Item>
        
        <Form.Item
          name="wcc"
          label="WCC"
          style={{ width: '100%' }}
          rules={[{ required: true, message: 'Missing Sales Manager' }]}>
          <AutoComplete popupMatchSelectWidth={false} options={consultantOptions}
                        style={{ width: "100%" }}
                        onSelect={onManagerSelect}
                        onSearch={(value) => debouncedConsultantSearch(value)}
                        placeholder="Search for WCC"
          >
            {/*{consultantOptions.map(item => (<Option key={item.id} value={item.name}>
              {item.name}
            </Option>))}*/}
          </AutoComplete>
        </Form.Item>
        {/* <Subtitle1 className="Validator" style={{ textAlign: 'left', marginBottom: '10px' }}>Deliver To</Subtitle1> */}
        {consultant && <Form.Item
          name="shipping_address_1"
          label="shipping Address 1"
          rules={[{ required: true, message: 'Missing shipping address 1' }]}
        >
          <Radio.Group>
            <Subtitle1 style={{ textAlign: 'left', marginBottom: '10px' }}>Consultant Addresses:</Subtitle1>
            {consultant?.addresses?.map(item => {
              return (
                <Radio value={item.street_address}>
                  <div className="flex-boy-row-space">
                    <span>{item.street_address}</span>
                    {item.default && <div style={{ textAlign: 'left' }}><Tag style={{ textAlign: 'left' }} color="#108ee9">Default</Tag></div>}
                  </div>
                </Radio>
              )
            })}
          
          
          </Radio.Group>
        </Form.Item>}
        <Form.Item
          label="Reason"
          name="reason"
          rules={[{ required: true, message: 'this field is required!' }]}
          wrapperCol={{ span: 24 }}
        >
          <Input autoComplete={'off'}/>
        </Form.Item>
        <Divider/>
        
        <Headline6 className="Validator">Supplies</Headline6>
        
        
        {order_type === "DME" && <>
          {treatmentorderItems?.map((item, index) => <div key={index} className="order-item flex-boy-row-space">
            <div className="flex-boy-row-space" style={{
              flex: 1, background: "#F1F2F5", border: "1px solid #F1F2F5", boxSizing: "border-box",
              borderRadius: "4px", height: "30px"
            }}><Subtitle1>{item.name}</Subtitle1></div>
            <div className="flex-boy-row-space" style={{
              flex: 1, background: "#F1F2F5", border: "1px solid #F1F2F5", boxSizing: "border-box",
              borderRadius: "4px", height: "30px"
            }}><Subtitle1>{item.pack_amount || item.default_pack_amount} {item.unit_of_measure}</Subtitle1></div>
            <div style={{ width: "150px", marginLeft: "20px" }}>
              <Input onChange={(e) => unitChange(item, index, e.target.value)} value={item.unit_count}
              />
            </div>
            <FontAwesomeIcon onClick={() => removeTreatmentItem(item)} style={{ marginLeft: "10px" }} color="grey" icon={["fas", "trash-alt"]}/>
          </div>)}
          {validationerrors.supplyItems ?
            <Body2 style={{ fontSize: '1em' }} color="crimson">Please ensure all supplies items have
              values!</Body2> : null}
          {newTreatmentSupply ? <AutoComplete popupMatchSelectWidth={false}
                                              style={{ width: 400, marginBottom: "20px" }}
                                              onSelect={onsupplySelect}
                                              onSearch={handlesupplysearch}
                                              placeholder="Search for supply">
            {treatmentSupplies.map(item => (<Option key={item.name} value={item.name}> {item.name} </Option>))}
          </AutoComplete> : null}
          <RegularButton style={{ width: "fit-content", marginTop: "40px" }} onClick={() => setnewTreatmentSupply(true)} background="white" color="grey" borderColor="grey">
            <FontAwesomeIcon style={{ marginLeft: "10px" }} color="grey" icon={["fas", "plus"]}/>
            Add item
          </RegularButton>
        </>}
        
        
        {order_type === "PPE" && <>
          {ppetreatmentorderItems?.map((item, index) => <div key={index} className="order-item flex-boy-row-space">
            <div className="flex-boy-row-space" style={{
              flex: 1, background: "#F1F2F5", border: "1px solid #F1F2F5", boxSizing: "border-box",
              borderRadius: "4px", height: "30px"
            }}><Subtitle1>{item.name}</Subtitle1></div>
            
            <div style={{ width: "150px", marginLeft: "20px" }}>
              <Input onChange={(e) => ppeunitChange(item, index, e.target.value)} value={item.unit_count}
              />
            </div>
            <FontAwesomeIcon onClick={() => removeppeTreatmentItem(item)} style={{ marginLeft: "10px" }} color="grey" icon={["fas", "trash-alt"]}/>
          </div>)}
          {validationerrors.ppesupplyItems ?
            <Body2 style={{ fontSize: '1em' }} color="crimson">Please ensure all supplies items have
              values!</Body2> : null}
          {newppeTreatmentSupply ? <AutoComplete popupMatchSelectWidth={false}
                                                 style={{ width: 400, marginBottom: "20px" }}
                                                 onSelect={onppesupplySelect}
                                                 onSearch={handleppesupplysearch}
                                                 placeholder="Search for supply">
            {ppetreatmentSupplies.map(item => (<Option key={item.name} value={item.name}> {item.name} </Option>))}
          </AutoComplete> : null}
          <RegularButton style={{ width: "fit-content", marginTop: "40px" }} onClick={() => setnewppeTreatmentSupply(true)} background="white" color="grey" borderColor="grey">
            <FontAwesomeIcon style={{ marginLeft: "10px" }} color="grey" icon={["fas", "plus"]}/>
            Add item
          </RegularButton>
        </>}
      
      
      </Form>
    </Modal>)
  );
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrder)
