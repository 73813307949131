import React, { useEffect, useState } from "react"
import { Form, Image as ImageTag } from "antd"
import { emptyFun } from "../../../common/helpers"
import { apiRequest } from "../../../app/Apis"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ProspectDocumentsView({ file, documentList, setDocumentList }) {
  const [url, setUrl] = useState("")
  async function getDocumentById(id) {
    let doc = await apiRequest.get(`/files/${id}`)
    console.log("Doc Url", doc)
    setUrl(doc.data?.data?.url)
  }
  useEffect(() => {
    if (file?.image) {
      setUrl(file?.image?.url)
    } else {
      getDocumentById(file?.image_id)
    }
    return emptyFun
  }, [file])
  async function removeDocument(id) {
    let arr = documentList.filter((item) => item.image_id !== id)
    setDocumentList(arr)
  }
  return (
    <ImageTag.PreviewGroup>
      <div className="flex-boy-row-space"
        style={{ width: '100%', maxHeight: 100, marginBottom: 10 }}><ImageTag
          fallback={require('../../../assets/doc.png')} height={100}
          src={url}>{file?.name}</ImageTag>
        <span>Name: {file?.name}</span> <br />
        <span>Type: {file?.type}</span>
        {setDocumentList && <FontAwesomeIcon color="grey" icon={["fas", "trash"]} onClick={() => removeDocument(file?.image_id)} />}
      </div>
    </ImageTag.PreviewGroup>
  )
}