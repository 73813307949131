import {createSlice} from '@reduxjs/toolkit';
import {appWindow} from "./initialState";

const initialState = {...appWindow}
export const appWindowSlice = createSlice({
  name: 'appWindow', initialState, // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateWindowState: (state, action) => {
      if (action.payload) {
        Object.keys(state).map(key => state[key] = false)
        Object.keys(action.payload).map(key => state[key] = action.payload[key])
      }
    },
  }
});
export const {updateWindowState} = appWindowSlice.actions;
export default appWindowSlice.reducer;
