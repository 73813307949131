/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Subtitle1} from '../../components'

import {Grid, Table, Tabs} from 'antd';
import {
  createOrganisation,
  deleteOrganisation,
  editBillingGroup,
  editOrganisation,
  fetchOrganisations,
  resetOrganisationsPagination,
  selectOrganisation,
  updateOrganisationFilters
} from '../../app/Reducers';
import {
  containedButton,
  createFormInputsFromTableColumns,
  dialogCloser,
  emptyFun,
  emptyTable,
  executeChunkFn,
  getSkels,
  kebabMenus,
  onTableChange,
  responsiveTbl,
  stateFilter
} from "../../common/helpers";

import KebabMenu from "../../components/KebabMenu";
import AuditLogViewer from "../../components/AuditLogs/AuditLogViewer";
import TableFilters from "../../components/InputComponents/TableFilters";
import {useSubscription} from "../../hooks/MqttHooks/useSubscription";
import DynamicModalForm from '../../components/DynamicModalForm';
import * as Sentry from '@sentry/react';
import {errMsg, toastUp} from '../../app/Reducers/reducerUtils';
import {usePermissionCheck} from '../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';
import {useAddressSearch} from '../../hooks/useAddressSearch';
const { useBreakpoint } = Grid;
const Organisations = ({ organisations, loading, appConf, navs, organisationsPagination, activeUser}) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState=useBreakpoint()
  const [activeTab, setActiveTab] = useState('patients');
  const [isOpen, setOpen] = useState(false)
  const dateFormat = "YYYY-MM-DD";
  const dispatch = useDispatch()
  const [isVisible, setIsVisible] = useState(false);
  const [isExecuting, setisExecuting] = useState(false)
  const [editingForm, setEditingForm] = useState(false);
  const [organisation, setOrganisation] = useState(null);
  const {message} = useSubscription(`organisations`, {qos: 2, nl: true});
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [phoneValue, setPhoneValue] = useState(undefined);
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  const [addressOptions, setAddressOptions] = useState([])
  const debouncedAddressSearch = useAddressSearch(setAddressOptions, [])
  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message)
      loadOrganisations(true).catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  let onCell = (record, rowIndex) => ({
    onClick: event => {
      (async () => {
        console.log({record})
        await dispatch(selectOrganisation(record))
        navigate(`/facilities/organisation/${record.id}`)
      })().catch(e => console.log(e))
    }
  })
  
  
  let passMessage = 'Must be at least 10 chars long. Must contain at least one of lowercase letter, uppercase letter, number and one special character among (@$!%*#?&).'
  let pwdRules = [{required: true, message: 'Missing Confirm Password'},
    {pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/, message: passMessage}]
  let pwdConfirmRules = [{required: true, message: 'Missing Confirm Password'},
    ({getFieldValue}) => ({
      validator(_, value, callback) {
        if (!value || getFieldValue('password') === value) {
          callback()
        }
        callback('Passwords do not match!')
      }
    })]
  const columns = [
    {onCell, title: 'Organisation Name', dataIndex: 'name', key: 'name', fltr: {filterable: true, type: 'text', editableCol: true, required: true}},
    {onCell, title: 'Contact person', dataIndex: 'contact_person', key: 'contact_person', fltr: {filterable: true, type: 'text', editableCol: true, required: true}},
    {onCell, title: 'Date Created', dataIndex: 'created_at', key: 'created_at', fltr: {filterable: true, type: 'date'}},
    {onCell, ...{...stateFilter, fltr: {...stateFilter.fltr, editableCol: true, required: true}}, title: 'US State', dataIndex: 'state', key: 'state'},
    {onCell, title: 'Email', dataIndex: 'email', key: 'email', fltr: {filterable: true, type: 'text', editableCol: true, required: true}},
    {
      title: 'Address', dataIndex: 'address', key: 'address',
      fltr: {
        filterable: true, type: 'select',options: addressOptions, editableCol: true, required: true, fullRow: true,
        props: {
          onSearch: (value) => debouncedAddressSearch(value), showSearch: true, filterOption: false, fullRow: true,
          currentAddress: defaultDynamicModalFormValues?.address
        }
      }, invisible: true
    },
    {onCell, title: 'Primary Phone', dataIndex: 'primary_phone', key: 'primary_phone', fltr: {filterable: true, type: 'phone', editableCol: true, required: true}},
    {onCell, title: 'PCC Org ID.', dataIndex: 'pcc_org_id', key: 'pcc_org_id', fltr: {filterable: true, type: 'text', editableCol: true, required: true}},
    {
      title: 'Password', dataIndex: 'password', key: 'password',
      fltr: {filterable: true, type: 'text', editableCol: true, fullRow: false, required: true, fiprops: {rules: pwdRules}}, invisible: true
    },
    {
      title: 'Password Confirmation', dataIndex: 'password_confirmation', key: 'password_confirmation',
      fltr: {filterable: true, type: 'text', editableCol: true, fullRow: false, required: true, fiprops: {rules: pwdConfirmRules}}, invisible: true
    },
    {
      title: 'Description', dataIndex: 'description', key: 'description',
      fltr: {filterable: true, type: 'textarea', editableCol: true, fullRow: true, required: true}, invisible: true
    },
    {
      fltr: {filterable: false}, render: (record) => {
        const actionMenus = kebabMenus(can_view_audit_logs, can_view_audit_logs, false)
        return <KebabMenu menus={actionMenus}
                          recordItem={record}
                          handleMenuClick={kebabMenuClick} resource={'organisation'} handleOk={() => handleOk(record.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
                          showConfirmDialog={selected === record.id}/>;
      }
    }
  ]
  let tableColumns = columns.filter(item => !item.invisible)
  let orgFormInputs = createFormInputsFromTableColumns(false, columns, phoneValue, setPhoneValue, null, null, dateFormat)
  //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
  let dynamicFormInputFields = {
    'Edit Organisation': orgFormInputs,
    'Add New Organisation': orgFormInputs
  }
  const [selected, setSelected] = React.useState(null);
  const handleCancel = () => setSelected(0);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const showPopconfirm = (id) => setSelected(id);
  const actionMenus = kebabMenus(( can_update_patient_resource), can_view_audit_logs, false)
  
  async function kebabMenuClick(e, record) {
    await setOrganisation(record)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await openOrgDynamicForm(record)
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
    }
  }
  
  
  async function handleFilterAction(action, values) {
    // console.log('handleFilterAction: ',values)
    await dispatch(resetOrganisationsPagination())
    if (action === 'filter') {
      let pl = {filterData: values, pagination: organisationsPagination}
      await dispatch(updateOrganisationFilters([false, pl, true]))
      await dispatch(fetchOrganisations(pl))
      await dispatch(updateOrganisationFilters([true, pl, false]))
    }
    if (action === 'reset'/* && appConf.organisationFilters[0]*/) {
      await dispatch(updateOrganisationFilters([appConf.organisationFilters[0], undefined, true]))
      await dispatch(fetchOrganisations())
      await dispatch(updateOrganisationFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  const loadOrganisations = async (isMQ) => {
    /*if (!!organisations[0] && !isMQ) {
      return
    }*/
    await dispatch(updateOrganisationFilters([appConf.organisationFilters[0], appConf.organisationFilters[1], !isMQ]))
    await dispatch(fetchOrganisations(appConf.organisationFilters[0] ? appConf.organisationFilters[1] : undefined))
    // await dispatch(fetchProcedures())
    // await dispatch(fetchLabRegions())
    // await dispatch(loadWoundLocations())
    await dispatch(updateOrganisationFilters([appConf.organisationFilters[0], appConf.organisationFilters[1], false]))
  }
  
  
  useEffect(() => {
    loadOrganisations(false).catch(e => console.log(e))
    return emptyFun
  }, [])
  
  const handleOk = async (id) => {
    setreasonModal(true)
    setSelected(0)
  };
  
  
  const [organisationTodeleteID, setorganisationTodeleteID] = useState(null)
  const [reasonModal, setreasonModal] = useState(false)
  const reasonFinish = async (values) => {
    await setConfirmLoading(true);
    await dispatch(deleteOrganisation({organisation_id: organisationTodeleteID, ...values}));
    await setSelected(0);
    await setConfirmLoading(false);
    setreasonModal(false)
  }
  
  const [selectedTab, setselectedTab] = useState("1")
  const callback = (key) => {
    setselectedTab(key)
  }
  const Tabs_ = () => {
    return (
      <Tabs defaultActiveKey={selectedTab} onChange={callback}>
        <Tabs.TabPane tab={<Subtitle1>{editingForm ? 'Edit organisation' : "New Organisation"}</Subtitle1>} key="1">
        
        </Tabs.TabPane>
        filter fix
      
      </Tabs>
    )
  }
  
  const openOrgDynamicForm = async (record) => {
    //console.log({record})
    await setFormName(!!record ? 'Edit Organisation' : 'Add New Organisation');
    await setDefaultDynamicModalFormValues(!!record ? {...record} : {root_user_id:activeUser.id});
    await setDynamicFormOpen(true);
  };
  /**
   * This function is handling returned form entries from the dynamic form
   * */
  const onDynamicFormEntriesResult = async (entries) => {
    console.log('entries.values: ', entries.values)
    try {
      switch (formName) {
        case 'Add New Organisation':
          await executeChunkFn(dispatch, createOrganisation, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen), null)
          break;
        case 'Edit Organisation':
          await executeChunkFn(dispatch, editOrganisation, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen), null)
          break;
        default:
          return
      }
    } catch (e) {
      Sentry.captureException(e);
      //console.log(e);
      toastUp(errMsg(e, false, formName), false);
    }
  };
  
  return (
    (<div className="main-page">
      {(isDynamicFormOpen && !!formName) && (
        <DynamicModalForm
          setDynamicFormOpen={setDynamicFormOpen}
          isDynamicFormOpen={isDynamicFormOpen}
          inputFields={dynamicFormInputFields[formName]}
          onDynamicFormEntriesResult={onDynamicFormEntriesResult}
          closeModal={dialogCloser(setDynamicFormOpen)}
          isExecuting={isExecuting} phoneValue={phoneValue}
          defaultValues={defaultDynamicModalFormValues}
          formName={formName}
        />
      )}
      {(!!organisation && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false}
                                                              resourceDescription={`${organisation.name}'s organisation`} resourceType={'Organisation'}
                                                              defaultValues={{auditableId: organisation?.id, auditable: 'organisation', format: 'json'}}/>}
      <div className="w-full">
        
        
        <Table
          title={() => (
            <TableFilters datasource={[...columns]}
                          setIsVisible={setIsVisible} isVisible={isVisible} windowState={windowState}
                          handleFilterAction={handleFilterAction} filters={appConf.organisationFilters[1]}
                          showClear={appConf.organisationFilters[0]} loading={appConf.organisationFilters[2]}
                          actionButton={containedButton(() => openOrgDynamicForm(), 'Add New', false, null, null, {icon: <PlusOutlined />})}
            />
          )}
          loading={appConf.organisationFilters[2]} {...responsiveTbl(windowState)} locale={{emptyText: emptyTable('organisations')}}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchOrganisations, appConf.organisationFilters, updateOrganisationFilters, null, organisationsPagination)}
          {...getSkels((appConf.organisationFilters[2]), tableColumns, organisations)} pagination={organisationsPagination}/>
      </div>
    </div>)
  );
}
const mapStateToProps = (state) => ({ activeUser: state.auth?.activeUser, organisations: state.organisations.organisations,
  loading: state.organisations.loading, appConf: state.appConf, navs: state.navs,
  organisationsPagination: state.organisations.organisationsPagination
  
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Organisations);
