/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Grid, Table} from 'antd';
import {createOrganisationStaff, deactivateOrganisationStaff, editOrganisationStaff, fetchOrgUsers, resetOrgUsersPagination, setFacilitiesLoaded, updateOrgUsersFilters} from '../../../app/Reducers';
import {containedButton, createFormInputsFromTableColumns, dialogCloser, emptyFun, emptyTable, executeChunkFn, getSkels, kebabMenus, onTableChange, responsiveTbl} from "../../../common/helpers";
import TableFilters from "../../../components/InputComponents/TableFilters";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import {apiRequest} from '../../../app/Apis';
import DynamicModalForm from '../../../components/DynamicModalForm';
import * as Sentry from '@sentry/react';
import {errMsg, toastUp} from '../../../app/Reducers/reducerUtils';
import KebabMenu from '../../../components/KebabMenu';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';
const { useBreakpoint } = Grid;
const OrganisationUsers = ({  organisation, orgUsers, orgUsersPagination, appConf, activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
    const windowState=useBreakpoint()
    const dispatch = useDispatch();
    const dateFormat = "YYYY-MM-DD";
    const organisationId = organisation?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
    const { message } = useSubscription(`organisationUsers`, { qos: 2, nl: true });
    const [permGroupOptions, setPermGroupOptions] = useState([])
    const [permGroupLoading, setPermGroupLoading] = useState(false)
    const [phoneValue, setPhoneValue] = useState(undefined);
    const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
    const [formName, setFormName] = useState(undefined);
    const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [selected, setSelected] = useState(null)
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
    console.log({ orgUsers })
    useEffect(() => {
      if (!!message) {
        console.log('MQTT MESSAGE:  ', message);
        loadFacilities(true).catch(e => console.log(e));
      }
      return emptyFun;
    }, [message]);
    useEffect(() => {
      loadFacilities(false).catch(e => console.log(e));
      return emptyFun;
    }, []);
    
    const loadFacilities = async (isMQ) => {
      /*if (!!orgUsers[0] && !isMQ) {
        return
      }*/
      await dispatch(updateOrgUsersFilters([appConf.orgUsersFilters[0], appConf.orgUsersFilters[1], !isMQ]));
      await dispatch(fetchOrgUsers(appConf.orgUsersFilters[0] ? appConf.orgUsersFilters[1] : { organisationId }));
      await dispatch(setFacilitiesLoaded(true));
      await dispatch(
        updateOrgUsersFilters([
          appConf.orgUsersFilters[0],
          appConf.orgUsersFilters[1],
          false
        ])
      );
    };
    
    const handlePermGroupSearch = async (value) => {
      if (!value || value?.trim() === '') {
        await setPermGroupLoading(false)
        setPermGroupOptions([])
        return
      }
      await setPermGroupLoading(true)
      if (value) {
        setPermGroupOptions([])
        const { data } = await apiRequest.get(`/organizations/${organisationId}/groups?search=${value}`)
        let ops = data.data.map(item => ({ ...item, label: item.name, value: item.id }))
        //console.log({value, data, ops})
        if (data) {
          setPermGroupOptions(ops);
        }
        await setPermGroupLoading(false)
      } else {
        await setPermGroupLoading(false)
        setPermGroupOptions([])
      }
    };
    const actionMenus = kebabMenus(can_view_audit_logs, can_view_audit_logs, true)
    const columns = [
      { title: 'Name', dataIndex: 'name', key: 'name', fltr: { filterable: true, type: 'text', editableCol: true, required: true } },
      { title: 'Position', dataIndex: 'position', key: 'position', fltr: { filterable: true, type: 'text', editableCol: true, required: true } },
      { title: 'Email', dataIndex: 'email', key: 'email', fltr: { filterable: true, type: 'text', editableCol: true, required: true } },
      {
        title: 'Groups', dataIndex: 'groups', key: 'groups', fltr: {
          filterable: false, type: 'autocomplete', editableCol: true, required: true, options: permGroupOptions,
          props: {
            mode: 'multiple', showSearch: true, filterOption: false, autoClearSearchValue: true, onSearch: handlePermGroupSearch, defaultActiveFirstOption: false, showArrow: !permGroupLoading,
            placeholder: 'Search groups', loading: permGroupLoading
          }
        },
        render: (groups) => {
          console.log({ groups })
          return !!groups ? groups.map(item => item.name).join(', ') : 'Unknown'
        }
      },
      {
        fltr: { filterable: false }, render: (record) => {
          return <KebabMenu menus={actionMenus}
                            recordItem={record}
                            handleMenuClick={kebabMenuClick}
                            resource={'organisation user'}
                            handleOk={() => handleOk(record.id)}
                            handleCancel={handleCancel}
                            confirmLoading={confirmLoading}
                            showConfirmDialog={selected === record.id}/>;
        }
      }
    
    ];
    const [patientForm, setPatientForm] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    
    let userFormInputs = createFormInputsFromTableColumns(false, columns, phoneValue, setPhoneValue, null, null, dateFormat)
    //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
    let dynamicFormInputFields = {
      'Edit Organisation User': userFormInputs,
      'Add New Organisation User': userFormInputs
    }
    
    async function kebabMenuClick(e, record) {
      switch (actionMenus[e.key].title) {
        case 'Edit':
          await openUserDynamicForm(record)
          break
        case 'Delete':
          showPopconfirm(record.id)
          break
        case 'Audit':
          break
        default:
      }
    }
    
    const showPopconfirm = (id) => setSelected(id);
    const handleOk = async (id) => {
      await setConfirmLoading(true)
      await dispatch(deactivateOrganisationStaff({ organisationId, id }))
      await setSelected(0)
      await setConfirmLoading(false)
    }
    const handleCancel = () => setSelected(0);
    
    async function handleFilterAction(action, values) {
      await dispatch(resetOrgUsersPagination());
      if (action === 'filter') {
        let pl = { filterData: { ...values, organisationId }, pagination: orgUsersPagination };
        await dispatch(updateOrgUsersFilters([false, pl, true]));
        await dispatch(fetchOrgUsers(pl));
        await dispatch(updateOrgUsersFilters([true, pl, false]));
      }
      if (action === 'reset' /* && appConf.orgUsersFilters[0]*/) {
        await dispatch(
          updateOrgUsersFilters([appConf.orgUsersFilters[0], undefined, true])
        );
        await dispatch(fetchOrgUsers({ filterData: { organisationId } }));
        await dispatch(updateOrgUsersFilters([false, undefined, false]));
      }
      setIsVisible(false);
    }
    
    const [isExecuting, setisExecuting] = useState(false);
    // const formButtonText = isPreview ? 'Save Office' : 'Preview Office';
    
    const openUserDynamicForm = async (record) => {
      //console.log({record})
      await setFormName(!!record ? 'Edit Organisation User' : 'Add New Organisation User');
      await setDefaultDynamicModalFormValues(!!record ? { ...record, organisationId } : { organisationId });
      await setDynamicFormOpen(true);
    };
    /**
     * This function is handling returned form entries from the dynamic form
     * */
    const onDynamicFormEntriesResult = async (entries) => {
      console.log('entries.values: ', entries.values)
      try {
        switch (formName) {
          case 'Add New Organisation User':
            await executeChunkFn(dispatch, createOrganisationStaff, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen), null)
            break;
          case 'Edit Organisation User':
            await executeChunkFn(dispatch, editOrganisationStaff, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen), null)
            break;
          default:
            return
        }
      } catch (e) {
        Sentry.captureException(e);
        //console.log(e);
        toastUp(errMsg(e, false, formName), false);
      }
    };
    return (
      (<div className="main-page">
        {(isDynamicFormOpen && !!formName) && (
          <DynamicModalForm
            setDynamicFormOpen={setDynamicFormOpen}
            isDynamicFormOpen={isDynamicFormOpen}
            inputFields={dynamicFormInputFields[formName]}
            onDynamicFormEntriesResult={onDynamicFormEntriesResult}
            closeModal={dialogCloser(setDynamicFormOpen)}
            isExecuting={isExecuting} phoneValue={phoneValue}
            defaultValues={defaultDynamicModalFormValues}
            formName={formName}
          />
        )}
        <div className="w-full">
          <Table
            title={() => (<TableFilters
              datasource={columns}
              setIsVisible={setIsVisible}
              isVisible={isVisible}
              filters={appConf.orgUsersFilters[1]}
              actionButton={
                containedButton(() => openUserDynamicForm(), 'Add New', false, null, null, { icon: <PlusOutlined /> })}
              windowState={windowState}
              handleFilterAction={handleFilterAction}
              showClear={appConf.orgUsersFilters[0]}
              loading={appConf.orgUsersFilters[2]}
            />)}
            rowKey={'id'}
            loading={appConf.orgUsersFilters[2]}
            {...responsiveTbl(windowState)}
            locale={{ emptyText: emptyTable('organisation facilities') }}
            onChange={(pagination, filters, sorter) =>
              onTableChange(pagination, filters, sorter, dispatch, fetchOrgUsers, appConf.orgUsersFilters, updateOrgUsersFilters, { organisationId })
            }
            {...getSkels(appConf.orgUsersFilters[2], columns, orgUsers)}
            pagination={orgUsersPagination}
          />
        </div>
      </div>)
    );
  }
const mapStateToProps = (state) => ({
  orgUsers: state.organisations.orgUsers,
  appConf: state.appConf,
  navs: state.navs, activeUser: state.auth?.activeUser,
  orgUsersPagination: state.organisations.orgUsersPagination,
   organisation: state.organisations.selectedOrganisation
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(OrganisationUsers);
