import React from 'react';
import {dialogCloser, formSubmitButton, horStack, modalClose, outlinedButton} from "../../../common/helpers";
import {Modal} from "antd";
import EditAssessmentCard from "./EditAssessmentCard";

const EditAssessmentModal = ({editassessmentModal, seteditassessmentModal, isExecuting, setVisit, setisExecuting, sellectedVisit, loadEncounters}) => {
  return (
    (<Modal
        title={`Assessment on ${sellectedVisit.appointment.patient.name}`}
      open={editassessmentModal}
      onOk={dialogCloser(seteditassessmentModal)}
      onCancel={dialogCloser(seteditassessmentModal)}
      closeIcon={modalClose(dialogCloser(seteditassessmentModal))}
      maskClosable={false}
      destroyOnClose={true}
      width={1400}
      footer={[<div className="entity-filters flex-boy-row" style={{justifyContent: 'flex-end'}}>
        {horStack([outlinedButton(dialogCloser(seteditassessmentModal), 'Cancel', isExecuting),
          formSubmitButton('updateappointmentForm', 'Update Assessment', isExecuting, 'Updating')])}
      </div>]}>
      <EditAssessmentCard setVisit={setVisit} setisExecuting={setisExecuting} setassessmentModal={seteditassessmentModal} loadEncounters={loadEncounters}
                          // assessment={sellectedVisit}
                          appointment={sellectedVisit.appointment} procedure={sellectedVisit.procedure}/>
    </Modal>)
  );
};
export default EditAssessmentModal;
