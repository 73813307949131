import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Divider,
  Empty, Flex,
  Form,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Skeleton,
  TimePicker,
  Tooltip,
  Typography,
  Upload
} from "antd";
import React, { useEffect } from "react";
import { useSelector } from 'react-redux'
import { Body2, Subtitle1 } from "../components";
import { AuditOutlined, CloseOutlined, DeleteOutlined, EditOutlined, FieldTimeOutlined, LeftOutlined, LoadingOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import parsePhoneNumber from 'libphonenumber-js'
import Input from "antd/es/input/Input";
import dayjs from "dayjs";
import * as Sentry from "@sentry/react";
import Checkbox from "antd/es/checkbox/Checkbox";
import NumberFormat from "react-number-format";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AppPhoneInput from '../components/InputComponents/AppPhoneInput';

const { Text } = Typography;
const { RangePicker } = DatePicker;
/**
 * Use of appLog is now optional since a global override has been done in index.html. You can use the usual console.log
 * */
export const appLog = (...data) => {
  console.log(...data)
  //todo: Remove global override in index.html to use the option below in case of white screen issue
  /*console.log('process.env.REACT_APP_CURRENT_ENV: ', process.env.REACT_APP_CURRENT_ENV)
  if (process.env.REACT_APP_CURRENT_ENV !== 'production') {
    console.log(...data);
  }*/
}

export const priorityColors = {
  low: '#AF78FF',
  normal: '#7785FF',
  high: 'crimson'
}

export const makeAutoCompleteOptions = (options) => options?.map(item => {
  if (!!item.label) {
    return { ...item, actualValue: item.value };
  } else {
    let label = item.replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())
    return ({ actualValue: item, label, value: label });
  }
})

export const makeSelectOptions = (options) => options?.map(item => {
  if (!!item.label) {
    return { ...item };
  } else {
    let label = item.replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())
    return ({ label, value: item });
  }
})
export const makeSelectOptionsRaw = (options) => options?.map(item => {
  if (!!item.label) {
    return { ...item };
  } else {
    let label = item.replace(/^\w/, c => c.toUpperCase())
    return ({ label, value: item });
  }
})

export function titleCase(str) {
  return str.toLowerCase().replaceAll('_', ' ').replace(/\b\w/g, (s) => s.toUpperCase());
}

export const sentenceCaseNoDash = (text) => !text ? null : text.replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase());
export const getSkels = (loading, columns, datasource) => {
  // console.log({datasource})
  return ({
    columns: loading ? columns.map((item, i) => ({ ...item, render: () => (<Skeleton.Input active />) })) : columns,
    dataSource: loading ? columns.map(item => ({ [item.dataIndex]: '' })) : datasource, size: loading ? 'small' : 'middle'
  });
}

export const skelBars = () => {
  return (<div className={'w-full flex flex-col justify-center gap-2'}>
    <Skeleton paragraph={{ rows: 4 }} active />
  </div>)
}
export const emptyFun = () => ({})
export const getHeaderLeftNav = (navigate, pageName) => {
  return <div className="flex-boy-row">
    <Button size={"large"} type={"text"} icon={<LeftOutlined />}
      style={{ textTransform: 'none', fontWeight: 600, backgroundColor: 'transparent' }}
      onClick={() => navigate(-1)}>{pageName}</Button>
  </div>
}
export const getPatientIndicator = (status) => {
  switch (status) {
    case "active":
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'deceased':
      return <div className="dot-circle-active" style={{ background: 'crimson' }} />
    case 'hold':
      return <div className="dot-circle-active" style={{ background: 'crimson' }} />
    case 'discharged':
      return <div className="dot-circle-active" style={{ background: '#FFB46E' }} />
    case 'in-active':
      return <div className="dot-circle-active" style={{ background: '#3f3f40' }} />
    case 'refused_treatment':
      return <div className="dot-circle-active" style={{ background: '#ae274f' }} />
    case 'cancelled':
      return <div className="dot-circle-active" style={{ background: '#de0c27' }} />
    case 'hospitalized':
      return <div className="dot-circle-active" style={{ background: '#9c27b0' }} />
    case 'healed':
      return <div className="dot-circle-active" style={{ background: '#4B9BFF' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}

export function getPatientsBarColorStatus(status) {
  if (status === 'Active') {
    return '#50D2A0';
  } else if (status === 'Cancelled') {
    return '#4B9BFF';
  } else if (status === 'In-Active') {
    return '#2E5AAC';
  } else if (status === 'Healed') {
    return '#f4e409';
  } else if (status === 'Deceased') {
    return '#a8dcd9';
  } else if (status === 'Refused Treatment') {
    return '#bdfffd';
  } else if (status === 'Hold') {
    return '#315659';
  } else if (status === 'Discharged') {
    return '#7cffc4';
  } else if (status === 'Hospitalized') {
    return '#5e6973';
  }
}

export function getFacilityBarColorStatus(status) {
  if (status === 'Hospice Care') {
    return '#50D2A0';
  } else if (status === 'SNF') {
    return '#4B9BFF';
  } else if (status === 'Palliative Care') {
    return '#2E5AAC';
  } else if (status === 'Home Health') {
    return '#f4e409';
  } else if (status === 'Private Practice') {
    return '#a8dcd9';
  } else if (status === 'Refused Treatment') {
    return '#bdfffd';
  } else if (status === 'Home Health & Hospice Care') {
    return '#315659';
  } else if (status === 'Assisted Living') {
    return '#7cffc4';
  }
}

export const getDualIndicator = (status) => {
  switch (status) {
    case "active":
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'in-active':
      return <div className="dot-circle-active" style={{ background: 'crimson' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}
export const getWoundIndicator = (status) => {
  switch (status) {
    case 'new':
      return <div className="dot-circle-active" style={{ background: '#fc223e' }} />
    case 'resolved':
      return <div className="dot-circle-active" style={{ background: '#FFB46E' }} />
    case 'improving':
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'unchanged':
      return <div className="dot-circle-active" style={{ background: '#3f3f40' }} />
    case 'deteriorating':
      return <div className="dot-circle-active" style={{ background: '#ae274f' }} />
    case 'healed':
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'stalled':
      return <div className="dot-circle-active" style={{ background: '#fc223e' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}
export const getAssessmentIndicator = (status) => {
  switch (status) {
    case 'approved':
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'pending_review':
      return <div className="dot-circle-active" style={{ background: '#FFB46E' }} />
    case 'rejected':
      return <div className="dot-circle-active" style={{ background: '#ae274f' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}

export const getLabIndicator = (status) => {
  switch (status) {
    case 'completed':
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'in progress':
      return <div className="dot-circle-active" style={{ background: '#FFB46E' }} />
    case 'ready for dispatch':
      return <div className="dot-circle-active" style={{ background: '#4B9BFF' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#FFB46E' }} />
  }
}


export const getAdmissionIndicator = (status) => {
  switch (status) {
    case 'assigned':
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'unassigned':
      return <div className="dot-circle-active" style={{ background: '#FFB46E' }} />
    case 'rejected':
      return <div className="dot-circle-active" style={{ background: '#ae274f' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}
export const getPendingScheduleIndicator = (status) => {
  switch (status) {
    case 'approved':
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'pending_review':
      return <div className="dot-circle-active" style={{ background: '#FFB46E' }} />
    case 'rejected':
      return <div className="dot-circle-active" style={{ background: '#ae274f' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}


export const getOrderIndicator = (status) => {
  switch (status) {
    case 'approved':
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'pending':
      return <div className="dot-circle-active" style={{ background: '#FFB46E' }} />
    case 'rejected':
      return <div className="dot-circle-active" style={{ background: '#ae274f' }} />
    case 'cancelled':
      return <div className="dot-circle-active" style={{ background: '#ae274f' }} />
    case 'review order':
      return <div className="dot-circle-active" style={{ background: '#ae274f' }} />
    case 'delivered':
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'applied':
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'pending applied':
      return <div className="dot-circle-active" style={{ background: '#FFB46E' }} />
    case 'not applied':
      return <div className="dot-circle-active" style={{ background: '#ae274f' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}
export const getOrderIndicatorColor = (status) => {
  switch (status) {
    case 'approved':
      return '#50D2A0'
    case 'pending':
      return '#FFB46E'
    case 'rejected':
      return '#ae274f'
    case 'cancelled':
      return '#ae274f'
    case 'review order':
      return '#ae274f'
    case 'delivered':
      return '#50D2A0'
    case 'applied':
      return '#50D2A0'
    case 'pending applied':
      return '#FFB46E'
    case 'not applied':
      return '#ae274f'
    default:
      return '#363646'
  }
}

export const getReferralIndicator = (status) => {
  switch (status) {
    case 'Approved':
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'Pending':
      return <div className="dot-circle-active" style={{ background: '#FFB46E' }} />
    case 'Pending Insurance Review':
      return <div className="dot-circle-active" style={{ background: 'FFB46E' }} />
    case 'Insurance Review Completed':
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'Rejected':
      return <div className="dot-circle-active" style={{ background: '#ae274f' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}

export const getReferralIndicatorColor = (status) => {
  switch (status) {
    case 'Approved':
      return '#50D2A0'
    case 'Pending':
      return '#FFB46E'
    case 'Pending Insurance Review':
      return '#FFB46E'
    case 'Insurance Review Completed':
      return '#50D2A0'
    case 'Rejected':
      return '#ae274f'
    default:
      return '#363646'
  }
}

// submit indicator
export const getSubmitIndicator = (status) => {
  switch (status) {
    case 'success':
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'failed':
      return <div className="dot-circle-active" style={{ background: '#FFB46E' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}


export const getTaskIndicator = (status) => {
  switch (status) {
    case 'in_review':
      return <div className="dot-circle-active" style={{ background: '#404240' }} />
    case 'assigned':
      return <div className="dot-circle-active" style={{ background: '#FFB46E' }} />
    case 'done':
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'in_progress':
      return <div className="dot-circle-active" style={{ background: '#4B9BFF' }}/>

    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}
export const getBiologicAppliedIndicator = (status) => {
  switch (status) {
    case 'applied':
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'pending applied':
      return <div className="dot-circle-active" style={{ background: '#FFB46E' }} />
    case 'not applied':
      return <div className="dot-circle-active" style={{ background: '#ae274f' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}
export const getMedicationIndicator = (status) => {
  switch (status) {
    case 'active':
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'inactive':
      return <div className="dot-circle-active" style={{ background: '#ae274f' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}

export const getAppointmentIndicatorColor = (status) => {
  switch (status) {
    case 'confirmed':
      return '#f4f4f4'
    case 'scheduled':
      return '#4B9BFF'
    case 'rescheduled':
      return '#FF73C3'
    case 'in_progress':
      return '#e1cc07'
    case 'completed':
      return '#50D2A0'
    case 'visited':
      return '#287D3C'
    case 'cancelled_by_patient':
    case 'cancelled_by_wcc':
    case 'missed':
    case 'canceled':
    case 'missed_by_wcc':
    case 'missed_by_patient':
      return '#C21700'
    default:
      return '#3f3f40'
  }
}
export const getAppointmentIndicator = (status) => {
  return <div className="dot-circle-active" style={{ background: getAppointmentIndicatorColor(status) }} />
}
// billing indicator
export const getBillingIndicator = (status) => {
  switch (status) {
    case 'pending review':
      return <div className="dot-circle-active" style={{ background: '#FFFF00' }} />
    case 'ready for submission':
      return <div className="dot-circle-active" style={{ background: '#FFB46E' }} />
    case 'submitted':
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'rejected':
      return <div className="dot-circle-active" style={{ background: '#ae274f' }} />
    case 'failed':
      return <div className="dot-circle-active" style={{ background: '#ae274f' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}
export const getProspectIndicator = (status) => {
  switch (status) {
    case "Pending":
      return <div className="dot-circle-active" style={{ background: '#FFB46E' }} />
    case 'Pending Insurance Review':
      return <div className="dot-circle-active" style={{ background: '#FFB46E' }} />
    case 'Insurance Review Completed':
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case "Approved":
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case 'Not Approved':
      return <div className="dot-circle-active" style={{ background: '#ae274f' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}
export const getTripStatusIndicator = (status) => {
  switch (status) {
    case "finished":
      return <div className="dot-circle-active" style={{ background: '#4B9BFF' }} />
    case "on":
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case "cancelled":
      return <div className="dot-circle-active" style={{ background: '#ae274f' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}
export const getDiagnosisIndicator = (status) => {
  switch (status) {
    case "active":
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case "inactive":
      return <div className="dot-circle-active" style={{ background: '#fc223e' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}
export const getOrgIndicator = (status) => {
  switch (status) {
    case "Active":
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    case "Inactive":
      return <div className="dot-circle-active" style={{ background: '#fc223e' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}
export const getOrgStatus = (status) => {
  return (
    <div className="status-container"> {getOrgIndicator(status)} <Subtitle1 style={{ textTransform: 'capitalize' }}>
      {!!status ? status.replace(/_/g, ' ') : 'Unknown'}</Subtitle1></div>
  );
}
export const getDiagnosisStatus = (status) => {
  return (
    <div className="status-container"> {getDiagnosisIndicator(status)} <Subtitle1 style={{ textTransform: 'capitalize' }}>
      {!!status ? status.replace(/_/g, ' ') : 'Unknown'}</Subtitle1></div>
  );
}
export const getPatientStatus = (status) => {
  return (
    <div className="status-container"> {getPatientIndicator(status)} <Subtitle1 style={{ textTransform: 'capitalize' }}>
      {!!status ? status.replace(/_/g, ' ') : 'Unknown'}</Subtitle1></div>
  );
}
export const getWoundStatus = (status) => {
  return (
    <div className="status-container"> {getWoundIndicator(status)} <Subtitle1 style={{ textTransform: 'capitalize' }}>
      {!!status ? status.replace(/_/g, ' ') : 'Unknown'}</Subtitle1></div>
  );
}
export const getAppointmentStatus = (status) => {
  return (
    <div className="status-container"> {getAppointmentIndicator(status)} <Subtitle1
      style={{ textTransform: 'capitalize' }}> {!!status ? status.replace(/_/g, ' ') : 'Unknown'}</Subtitle1></div>
  );
}
export const getProspectStatus = (status) => {
  return (
    <div className="status-container"> {getProspectIndicator(status)} <Subtitle1 style={{ textTransform: 'capitalize' }}>
      {!!status ? status.replace(/_/g, ' ') : 'Unknown'}</Subtitle1>
    </div>
  );
}
export const getTripStatus = (status) => {
  return (
    <div className="status-container"> {getTripStatusIndicator(status)} <Subtitle1 style={{ textTransform: 'capitalize' }}>
      {!!status ? status.replace(/_/g, ' ') : 'Unknown'}</Subtitle1>
    </div>
  );
}
export const getAssessmentStatus = (status) => {
  return (
    <div className="status-container"> {getAssessmentIndicator(status)} <Subtitle1
      style={{ textTransform: 'capitalize' }}>{!!status ? status.replace(/_/g, ' ') : 'Unknown'}</Subtitle1></div>
  );
}
export const getLabStatus = (status) => {
  return (
    <div className="status-container"> {getLabIndicator(status)} <Subtitle1
      style={{ textTransform: 'capitalize' }}>{!!status ? status.replace(/_/g, ' ') : 'Unknown'}</Subtitle1></div>
  );
}


// admission status

export const getAdmissionStatus = (status) => {
  return (
    <div className="status-container"> {getAdmissionIndicator(status)} <Subtitle1
      style={{ textTransform: 'capitalize' }}>{!!status ? status.replace(/_/g, ' ') : 'Unknown'}</Subtitle1></div>
  );
}

// pending schedule status
export const getPendingScheduleStatus = (status) => {
  return (
    <div className="status-container"> {getPendingScheduleIndicator(status)} <Subtitle1
      style={{ textTransform: 'capitalize' }}>{!!status ? status.replace(/_/g, ' ') : 'Unknown'}</Subtitle1></div>
  );
}


// billing status
export const getprogressStatus = (status) => {
  return (
    <div className="status-container"> {getBillingIndicator(status)} <Subtitle1
      style={{ textTransform: 'capitalize' }}>{!!status ? status.replace(/_/g, ' ') : 'Unknown'}</Subtitle1></div>
  );
}

//  submit status
export const getSubmitStatus = (status) => {
  return (
    (<div className="status-container"> {getSubmitIndicator(status)} <Subtitle1
      style={{ textTransform: 'capitalize' }}>{!!status ? status.replace(/_/g, ' ') : 'Unknown'}</Subtitle1></div>)
  );
}

export const getDeliveryStatus = (status) => {
  return (
    (<div className="status-container"> {getUPSDeliveryIndicator(status)} <Subtitle1
      style={{ textTransform: 'capitalize' }}>{!!status ? status.replace(/_/g, ' ') : 'Not Delivered'}</Subtitle1></div>)
  );
}

export const getOrderStatus = (status) => {
  return (
    <div className="status-container"> {getOrderIndicator(status)} <Subtitle1 style={{ textTransform: 'capitalize', whiteSpace:'nowrap' }}>{!!status ? status.replace(/_/g, ' ') : 'Unknown'}</Subtitle1>
    </div>
  );
}
// get task status
export const getTaskStatus = (status) => {
  return (
    <div className="status-container"> {getTaskIndicator(status)} <Subtitle1
      style={{ textTransform: 'capitalize' }}>{!!status ? (status === "in_review" ? "In Review" : status.replace(/_/g, ' ')) : 'Unknown'}</Subtitle1>
    </div>
  );
}

export const getOrderAppliedStatus = (status, duration) => {
  return (
    <div className="status-container"> {getBiologicAppliedIndicator(status)} <Subtitle1
      style={{ textTransform: 'capitalize' }}>{!!status && status === "applied" ? status.replace(/_/g, ' ') : `Pending application-${duration}d`}</Subtitle1></div>
  );
}

export const getMedicationStatus = (status) => {
  return (
    <div className="status-container"> {getMedicationIndicator(status)} <Subtitle1
      style={{ textTransform: 'capitalize' }}>{!!status ? status.replace(/_/g, ' ') : 'Unknown'}</Subtitle1></div>
  );
}
export const getDualStatus = (status) => {
  return (
    <div className="status-container"> {getDualIndicator(status)} <Subtitle1 style={{ textTransform: 'capitalize' }}>
      {!!status ? status.replace(/_/g, ' ') : 'Unknown'}</Subtitle1></div>
  );
}
export const modalClose = (onClose) => <Button type={'text'} shape={'circle'} icon={<CloseOutlined />} size={"middle"} onClick={onClose} />
export const addButton_ = (onClick, color) => {
  return (<Button shape={'circle'} style={{ ...(!!color && { color, borderColor: color }) }} type={!color ? 'primary' : 'default'} size={"small"} onClick={onClick}
    icon={<PlusOutlined style={{ ...(!!color && { color }) }} />}>
  </Button>)
}

export const addButton = (onClick, color, componentProps) => {
  return (<Button type={!!color ? "text" : 'primary'} shape={'circle'} size={"middle"} onClick={onClick} {...componentProps} icon={<PlusOutlined />} />)
}
export const addButtonClean = (onClick, color) => {
  return (<PlusOutlined className="mx-2" onClick={onClick} style={{ fontSize: '20px' }} />)
}
export const deleteButton = (onClick, otherProps) => (<Button {...otherProps} shape={'circle'} type={"text"} size={"middle"} onClick={onClick} icon={<DeleteOutlined />} />)
export const editButton = (onClick) => (<Button type={'text'} shape={'circle'} onClick={onClick} icon={<FontAwesomeIcon color="grey" icon={["fas", "pencil-alt"]} />} />)
// close icon

export const closeButton = (onClick, styleProps, componentProps) => (<Button shape={'circle'} size={"middle"} onClick={onClick} {...componentProps}> <CloseOutlined className={'font-bold'} /> </Button>)

export const outlinedButton = (onClick, text, disabled, styleProps, componentProps) => {
  return (<Button {...componentProps}
                  // disabled={disabled}
                  size={"middle"}
                  onClick={onClick}
                  type={componentProps?.type||"default"}
                  className={'min-w-[100px]'}
                     disableFocusRipple={true}
                     focusRipple={false}
                     key={text}
                     style={{
                       minWidth: 100,
                       ...styleProps
                     }}>{text} {componentProps?.endIcon||''}</Button>)
}
export const textButton = (onClick, text, disabled, color, styleProps, componentProps) => {
  return (<Button {...componentProps}
    // disabled={disabled}
    size={"middle"}
    onClick={onClick}
    type={"text"}
    className={'min-w-[100px]'}
    style={{
      color: color ? color : null, ...styleProps
    }}>{text} {componentProps?.endIcon || ''}</Button>)
}
//Can be used for API requests
export const secondaryButton = (onClick, text, loading, loadingText, styleProps, componentProps) => {
  return (<Button {...componentProps}
    // disabled={loading}
    size={"middle"}
    onClick={onClick} type={"default"}
    className={'min-w-[100px]'}
    style={styleProps}
    loading={loading}> {!loading ? text : loadingText + '...'} {componentProps?.endIcon || ''}</Button>)
}

export const containedButton = (onClick, text, loading, loadingText, styleProps, componentProps) => {
  return (
    <Button
      // disabled={loading}
            {...componentProps}
            loading={loading}
            className={'min-w-[100px]'}
               size={"middle"}
            style={styleProps}
            onClick={onClick}
            type={"primary"}> {!loading ? text : loadingText + '...'}  {componentProps?.endIcon||''}</Button>)
}
export const formSubmitButton = (formName, text, loading, loadingText, buttonProps, buttonStyle) => {
  return (<Button
    // disabled={loading}
    loading={loading} form={formName} htmlType={'submit'} key="submit"
    className={'min-w-[100px]'}
    size={"middle"} type={"primary"}
    {...buttonProps}>
    {!loading ? text : loadingText + '...'} {buttonProps?.endIcon || ''}</Button>)
}
export const inputRow = (children) => {
  let normalSize = 24 / children.length
  return <Row className={'w-full m-0'} gutter={[4, 0]} align={"stretch"} justify={"start"}>
    {children.map(child => <Col className={'w-full'} xs={24} sm={24} md={normalSize} lg={normalSize} xl={normalSize} flex={"auto"}>{child}</Col>)}
  </Row>
}
export const divInputRow = (children) => {
  return <div className={'w-full m-0 flex flex-row flex-wrap gap-1 items-center'}>
    {children}
  </div>
}

export const horStack = (children, props) => (<Flex wrap="wrap" gap="small">{children}</Flex>)

export const appConfirm = (btnIcon, visible, onClick, message, onConfirm, onCancel, loading, otherProps) => {
  return (
    <Popconfirm
      //Hide icon if this is HTML node e.g delete reason Form
      {...(message !== 'string' && { icon: null })}
      title={message}
      open={visible}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
      onConfirm={onConfirm}
      onCancel={onCancel}
      destroyTooltipOnHide={true}
      {...otherProps}
    >
      {<Button shape={'circle'} disabled={visible} onClick={onClick} type={'text'} size={"middle"} icon={btnIcon} />}
    </Popconfirm>);
}
export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
export const updatedArrayState = (stateArray, action, key) => {
  let new_state = [...(stateArray ? stateArray : [])]
  if (new_state.length > 0) {
    let index = new_state.findIndex(item => item[key] === action.payload[key])
    if (index !== -1) {
      new_state.splice(index, 1, action.payload)
      return new_state
    } else {
      return new_state
    }
  } else {
    return new_state
  }
}
export const newResourceArray = (stateArray, action) => {
  let new_state = [...(stateArray ? stateArray : [])]
  new_state.unshift(action.payload)
  return new_state
}
export const arrayStateAfterDelete = (stateArray, action, key) => {
  let new_state = [...(stateArray ? stateArray : [])]
  if (new_state.length > 0) {
    let index = new_state.findIndex(item => item[key] === action.payload[key])
    if (index !== -1) {
      new_state.splice(index, 1)
      return new_state
    } else {
      return new_state
    }
  } else {
    return new_state
  }
}
export const cleanObject = (obj) => {
  Object.keys(obj).forEach(k => ((!obj[k] && obj[k] !== false) || obj[k] === "") && delete obj[k])
  return obj
}
export const showCurrentAddress = (address) => address ? (<><p className={'text-xs'}><b>Current address: </b>{address}</p> <Divider /> </>) : null
export const appMessage = (message_, type) => message[type](message_)
export const restrictEdit = (node) => <Tooltip placement="topLeft" title={'Edit restricted on this field'}>{node}</Tooltip>
export const showImagePreview = (visible, title, onClose, uri) => {
  return <Modal open={visible} title={title} footer={null} onCancel={onClose} closeIcon={modalClose(onClose)}> <img alt="example" style={{ width: '100%' }} src={uri} /> </Modal>;
}
export const dialogOpener = (cb) => () => cb(true)
export const openDialog = (cb) => cb(true)
export const dialogCloser = (cb) => () => cb(false)
export const closeDialog = (cb) => cb(false)


export const editMenus = [{ title: 'Edit', icon: <EditOutlined fontSize={"small"} style={{ color: 'grey' }} /> },
{ title: 'Delete', icon: <DeleteOutlined fontSize={"small"} style={{ color: 'grey' }} /> },
{ title: 'Dismiss', icon: <CloseOutlined /> }]

export const kebabMenus = (editable, auditable, deletable) => {
  return [editable, auditable, deletable].every(item => item === false) ? [] : [
    ...(editable ? [{ title: 'Edit', icon: <EditOutlined fontSize={"small"} style={{ color: 'grey' }} /> }] : []),
    ...(auditable ? [{ title: 'Audit', icon: <AuditOutlined /> }] : []),
    ...(deletable ? [{ title: 'Delete', icon: <DeleteOutlined fontSize={"small"} style={{ color: 'grey' }} /> }] : []),
    { title: 'Dismiss', icon: <CloseOutlined /> }];
}
export const createMenus = (options) => {
  return options.map(item=>{
    return{
      key:item,
      icon:null,
      label:<p className={'text-start'}>{item}</p>,
    }
  })
}
export const stateFilter = {
  key: 'state', title: 'State', fltr: {
    filterable: true, type: 'autocomplete',
    options: makeAutoCompleteOptions(['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'])
  }
}
export const patientAdmissionFilter = {
  key: 'admissionStatus', title: 'Admission status', fltr: {
    filterable: true, type: 'select',
    options: makeSelectOptions(['unassigned', 'Assigned', 'no_coverage', 'on_hold'])
  }
}

export const regionFilter = { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(['Eastern', 'Western', 'MidWest']) }


/**
 * @param chunkFn
 * @param filterState
 * @param defaultParams are default parameters are always passed to chunkFn() loading table data
 * --------------------
 * @param pagination
 * @param filterUpdateFn
 * @param filters
 * @param sorter_
 * @param dispatch
 * @param previousPagination
 * @param sortLocalData2
 * */
export const onTableChange = async (pagination, filters, sorter_, dispatch, chunkFn, filterState, filterUpdateFn, defaultParams, previousPagination) => {
  //Local data Sorters are now implemented in the column definitions so set to none always
  let sorter = {}
  if (pagination?.current === previousPagination?.current) {
    //this is an attempt to sort column data. No need to make APi request unless page has changed
    return
  }
  //console.log({filterState, pagination, filters, sorter, defaultParams, previousPagination})
  let params
  if (filterState[0]) {
    params = { ...filterState[1] }//escape js object's read only restriction
    delete params.pagination //Remove any previous pagination data since default onTableChange has new pagination data
  }

  params = { ...params, pagination, ...filters, ...(!!defaultParams && { filterData: { ...params?.filterData, ...defaultParams } }) }//pass the default params if any
  await dispatch(filterUpdateFn([!!filterState[0], filterState[0] ? filterState[1] : undefined, true]))
  await dispatch(chunkFn(params));
  await dispatch(filterUpdateFn([!!filterState[0], filterState[0] ? filterState[1] : undefined, false]))
}


let commonStyleSimple = (marginEnd) => ({ style: { textAlign: 'left', flex: 1, ...(marginEnd && { marginRight: 10 }) } })
let commonStyle = (marginEnd) => ({ style: { textAlign: 'left', flex: 1, width: '100%', ...(marginEnd && { marginRight: 10 }) } })
export const keyValItemSimple = (key, val) => (
  <Row gutter={[4, 1]} align={"stretch"} justify={"start"} style={{ marginTop: 6, overflowWrap: 'break-word' }}>
    <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"}
      style={{ width: '100%', alignSelf: 'center' }}><Subtitle1 {...commonStyleSimple(true)}>{key?.toString()}</Subtitle1></Col>
    <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}><Body2 color="black" {...commonStyleSimple()}>{val}</Body2></Col>
  </Row>)
export const keyValItemForComponent = (key, val, extraStyles) => {
  return (<div className={'flex flex-row items-center'} style={{ marginTop: 6, ...extraStyles }}>
    <Text style={{ whiteSpace: 'nowrap' }}>{key}{' '}</Text>
    {/*The below value is a custom component*/}
    {val}
  </div>);
}

export const keyValItemForReferral = (key, val, extraStyles) => {
  return (
    <Row gutter={[4, 1]} className="pl-1" align={"stretch"} justify={"start"} style={{ width: '100%', marginTop: 6, overflowWrap: 'break-word' }}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}>
        <Text>{key}{' '}</Text>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}>
        <Subtitle1 {...commonStyle(true)}>{val}</Subtitle1>
      </Col>
    </Row>);
}

export const keyValItemGreyForReferral = (key, val, extraStyles) => {
  return (
    <Row gutter={[4, 1]} className="pl-1" align={"stretch"} justify={"start"} style={{ width: '100%', marginTop: 6, overflowWrap: 'break-word', background: '#f7f7f7' }}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}>
        <Text>{key}{' '}</Text>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}>
        <Subtitle1 {...commonStyle(true)}>{val}</Subtitle1>
      </Col>
    </Row>);
}

export const boldKeyUntransformedValue = (key, val, sup) => (
  <Row gutter={[4, 1]} className="pl-1" align={"stretch"} justify={"start"} style={{ width: '100%', marginTop: 6, overflowWrap: 'break-word' }}>
    <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}><Subtitle1 {...commonStyle(true)}>{key?.toString()}</Subtitle1></Col>
    <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}><Body2 color="black" {...commonStyle()}>{val}{sup && <sup>{sup}</sup>}</Body2></Col>
  </Row>)
export const boldKeyCapitalizedValue = (key, val, sup) => (
  <Row gutter={[4, 1]} className="pl-1" align={"stretch"} justify={"start"} style={{ width: '100%', marginTop: 6, overflowWrap: 'break-word' }}>
    <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}><Subtitle1 {...commonStyle(true)}>{key?.toString()}</Subtitle1></Col>
    <Col className="capitalize" xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}><Body2 color="black" {...commonStyle()}>{val}{sup && <sup>{sup}</sup>}</Body2></Col>
  </Row>)

export const boldKeyCapitalizedValueGrayBackground = (key, val, sup) => (
  <Row gutter={[4, 1]} className="py-1 pl-1" align={"stretch"} justify={"start"} style={{ width: '100%', marginTop: 6, overflowWrap: 'break-word', background: '#f7f7f7' }}>
    <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}><Subtitle1 {...commonStyle(true)}>{key?.toString()}</Subtitle1></Col>
    <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}><Body2 color="black" {...commonStyle()}>{val}<sup>{sup}</sup></Body2></Col>
  </Row>)

export const keyValItemOrders = (key, val, sup) => (
  <Row gutter={[4, 1]} align={"stretch"} justify={"start"} style={{ width: '100%', marginTop: 6, overflowWrap: 'break-word' }}>
    <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}><Body2 color="black"
      style={{
        fontWeight: 'bold',
        textAlign: 'left',
        flex: 1,
        width: '100%'
      }}>{key?.toString()}</Body2></Col>
    <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}><Body2 color="black">{val}<sup>{sup}</sup></Body2></Col>
  </Row>)
export const keyValItemCaps = (key, val, sup) => (
  <Row gutter={[4, 1]} align={"stretch"} justify={"start"} style={{ width: '100%', marginTop: 6, overflowWrap: 'break-word', textTransform: 'capitalize' }}>
    <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}><Subtitle1 {...commonStyle(true)}>{key?.toString()}</Subtitle1></Col>
    <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}><Body2 color="black" {...commonStyle()}>{val}<sup>{sup}</sup></Body2></Col>
  </Row>)
export const span8UI = (child) => (<Col xs={24} sm={24} md={24} lg={8} xl={8} flex={"auto"} style={{ width: '100%' }}>{child}</Col>)
export const responsiveTbl = (windowState) =>( windowState.sm || windowState.xs|| windowState.md) ? { scroll: { x: 900 },virtual:true } : { scroll: { x: 0 } }
/**
 * Used to ensure form dialogs dont close if requests are unsuccessful, reusing redundant code
 * if there are any other code to be executed based on success or failure of this chunk call apart from chunkFn, setProgress and closerFn, then call this function from a try catch and add the extra statements under the call. see createProspect call as example
 * */
export const executeChunkFn = (dispatch, chunkFn, payload, setProgress, closerFn, cleanupFn) => {
  return new Promise(async (resolve, reject) => {
    try {
      await setProgress(true)
      await dispatch(chunkFn(payload)).unwrap()
      if (!!cleanupFn) {
        await cleanupFn()
      }
      setProgress(false)
      if (!!closerFn) {
        closerFn()
      }
      resolve()
    } catch (e) {
      Sentry.captureException(e);
      console.log(e, "should not close")
      setProgress(false)
      reject()
    }
  })

}

export const sanitizePayload = (payload) => {
  return Object.keys(payload).reduce((acc, key) => {
    acc[key] = payload[key] ?? null;
    return acc;
  }, {});
}
export const roundToDp = (number_, decimalPlaces) => {
  const x = Math.pow(10, decimalPlaces);
  return Math.round(number_ * x) / x;
}

export const emptyTable = (message, addButton) => <div className={'flexy-column'} style={{ justifyContent: "center" }}>

  <Empty image={require('../assets/empties/empty_table.svg').default} imageStyle={{ height: '100%', textAlign: 'center' }} description={``}/>
  {addButton}
</div>


export const emptyOrders = (message) => <div className={'flexy-column'} style={{ justifyContent: "center" }}>
  <Empty image={require('../assets/empties/no_orders.svg').default} imageStyle={{ height: '100%' }} description={``} /></div>

export const emptyAllergies = (message, addButton) => <div className={'flexy-column'} style={{ justifyContent: "center" }}>
  <Empty image={require('../assets/empties/no_allergies.svg').default} imageStyle={{ height: '100%' }} description={``} />
  {addButton}
</div>
export const emptyImmunization = (message, addButton) => <div className={'flexy-column'} style={{ justifyContent: "center" }}>
  {<Empty image={require('../assets/empties/no_immunization.svg').default} imageStyle={{ height: '100%' }} description={``} />}
  {addButton}
</div>
export const emptyDiagnosis = (message, addButton) => <div className={'flexy-column'} style={{ justifyContent: "center" }}>
  {<Empty image={require('../assets/empties/no_diagnosis.svg').default} imageStyle={{ height: '100%' }} description={``} />}
  {addButton}
</div>
export const emptyDocuments = (message, addButton) => <div className={'flexy-column'} style={{ justifyContent: "center" }}>
  {<Empty image={require('../assets/empties/no_documents.svg').default} imageStyle={{ height: '100%' }} description={``} />}
  {addButton}
</div>
export const emptyInsurance = (message, addButton) => <div className={'flexy-column'} style={{ justifyContent: "center" }}>
  {<Empty image={require('../assets/empties/no_results.svg').default} imageStyle={{ height: '100%' }} description={``} />}
  {addButton}
</div>
export const emptyFamilyHistory = (message, addButton) => <div className={'flexy-column'} style={{ justifyContent: "center" }}>
  {<Empty image={require('../assets/empties/no_familyhistory.svg').default} imageStyle={{ height: '100%' }} description={``} />}
  {addButton}
</div>

export const noVehicle = (message) => <div className={'flexy-column'} style={{ justifyContent: "center" }}>
  <Empty image={require('../assets/empties/no_vehicle.svg').default} imageStyle={{ height: '100%' }} description={``} /></div>

export const noAvailability = <div className={'flexy-column'} style={{ justifyContent: "center" }}>
  <Empty image={require('../assets/empties/no_availability_time.svg').default} imageStyle={{ height: '100%' }} description={""} /></div>

export const noLogs = <div className={'flexy-column'} style={{ justifyContent: "center" }}>
  <Empty image={require('../assets/empties/no_logs.svg').default} imageStyle={{ height: '100%' }} description={""} /></div>

export const noSchedule = <div className={'flexy-column'} style={{ justifyContent: "center" }}>
  <Empty image={require('../assets/empties/no_schedule.svg').default} imageStyle={{ height: '100%' }} description={""} /></div>

export const noEncounterList = <div className={'flexy-column'} style={{ justifyContent: "center" }}>
  <Empty image={require('../assets/empties/no_encountelist.svg').default} imageStyle={{ height: '100%' }} description={""} /></div>

export const noMessages = <div className={'flexy-column'} style={{ justifyContent: "center" }}>
  <Empty image={require('../assets/empties/no_messages.svg').default} imageStyle={{ height: '100%' }} description={""} /></div>


export const approval_statuses = ['Approved', 'Approved/Wait', 'DME/Approved', 'DME/Approved & Medical/Approved', 'HMO', 'Medical/Approved', 'Not Approved', 'Approved/SNF-100 days', 'Pending Insurance Review', 'Insurance Review Completed']

export const getUPSDeliveryIndicator = (status) => {
  switch (status) {
    case "Delivered":
      return <div className="dot-circle-active" style={{ background: '#50D2A0' }} />
    default:
      return <div className="dot-circle-active" style={{ background: '#363646' }} />
  }
}

export const GetOptions = (system_name) => {
  const masterList = useSelector(state => state.masters.masterListRaw)
  const { Option } = Select;
  return <>
    {masterList.find(item => item.system_name === system_name)?.options?.map(item => <Option
      value={item}>{item.toString().replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}</Option>) || []}
  </>;

}
export const GetRawListOptions = (system_name) => {
  const masterList = useSelector(state => state.masters.masterListRaw)
  // console.log({masterList})
  let listItem = masterList.find(item => item.system_name === system_name)
  // console.log({listItem})
  return [...listItem?.options] || []
}

export const getSortedWoundLocations = () => {
  const masterLocations = [...GetRawListOptions('wound_location')]
  if (masterLocations[0]) {
    masterLocations.sort((a, b) => {
      // console.log(a,': ', b)
      // console.log(a.indexOf(value), b.indexOf(value))
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1
      }
      return 0
    })
    // console.log({masterLocations})
    return makeAutoCompleteOptions(masterLocations)
  }
  return []
}

// export const GetNumericalListOptions = (number) => {
//   const { Option } = Select;
//   let options = []
//   for (let i = 0; i <= number; i++) {
//     options.push(i);
//   }
//   return options.map(item => <Option value={item}>{`${item}`}</Option>)
// }

export const getPhoneParams = (phone, inputName) => {
  let def = `defaultValue-${inputName}`
  if (!phone) {
    return { [inputName]: undefined, [def]: phone }
  }
  const phoneNumber = parsePhoneNumber(phone)
  if (!phoneNumber) {
    //Return the invalid phone to be used as default value and edited from the form
    return { [inputName]: phone, [def]: phone }
  }
  return { [inputName]: phoneNumber?.nationalNumber, [def]: phone }
}
export const GetNumericalListOptions = (number) => {
  const { Option } = Select;
  let range = 50;
  let options = []
  for (let i = Math.max(0, number - range); i <= number + range; i++) {
    options.push(i);
  }
  return options.map(item => <Option value={item}>{`${item}`}</Option>)
}
export const formatPhone = (phone) => phone.replaceAll(/\(/, '').replaceAll(/\)/, '').replaceAll(/-/, '').replaceAll(' ', '')
export const getInputField = (form, field, phoneValue, setPhoneValue) => {
  if (field?.shouldNotShow) return null
  const { TextArea } = Input;
  switch (field.type) {
    case "string":
    case "text":
      return <Form.Item
        className={'m-0 mb-2 w-full'}
        name={field.name}
        label={field.label}
        rules={[{ required: field.required, message: `Missing ${field.label}` }]}
        {...field?.fiprops}>
        <Input type={field.type} autoComplete={'off'} {...field.props} />
      </Form.Item>
    case "number":
      return <Form.Item
        className={'m-0 mb-2 w-full'}
        name={field.name}
        label={field.label}
        rules={[{ required: field.required, message: `Missing ${field.label}` }]}
        {...field?.fiprops}>
        <InputNumber type={'number'} {...field.props} style={{ width: '100%', padding: '0.8px' }} />
      </Form.Item>
    case "formatted_number_string":
      return <Form.Item
        className={'m-0 mb-2 w-full'}
        name={field.name}
        label={field.label}
        rules={[{ required: field.required, message: `Missing ${field.label}` }]}
        {...field?.fiprops}>
        <NumberFormat
          autoComplete={'off'}
          customInput={Input}
          format="+# (###) ### ####"
          placeholder={field?.props?.format || ''}
          {...field.props}
        />
      </Form.Item>
    case "textarea":
      return <Form.Item
        className={'m-0 mb-2 w-full'}
        name={field.name}
        label={field.label}
        rules={[{ required: field.required, message: `Missing ${field.label}` }]}
        {...field?.fiprops}>
        <TextArea rows={4} placeholder="Type..." autoComplete={'off'} {...field.props} />
      </Form.Item>
    case "select":
      // console.log('field: ', field)
      // console.log('inputFields: ', inputFields)
      return <Form.Item
        className={'m-0 mb-2 w-full'}
        name={field.name}
        label={field.label}
        rules={[{ required: field.required, message: `Missing ${field.label}` }]}
        {...field?.fiprops}>
        <Select style={{ width: '100%' }} options={field.options} {...field.props}/>
      </Form.Item>
    case "checkbox":
      // console.log('field: ', field)
      // console.log('inputFields: ', inputFields)
      return <Form.Item
        className={'m-0 mb-2 w-full'}
        name={field.name}
        valuePropName="checked"
        // label={field.label}
        rules={[{ required: field.required, message: `Missing ${field.label}` }]}
        {...field?.fiprops}>
        <Checkbox style={{ width: '100%', fontWeight: 'bold' }}>{field.label}</Checkbox>
      </Form.Item>
    case "autocomplete":
      // console.log('field: ', field)
      // console.log('inputFields: ', inputFields)
      return <Form.Item
        className={'m-0 mb-2 w-full'}
        name={field.name}
        label={field.label}
        rules={[{
          required: field.required, message: `Missing ${field.label}`, validator: (rule, value, callback) => {
            if (field.required) {
              if (!!value && field.options.some(item => item.actualValue === value || item.value === value)) {
                callback();
              } else {
                callback(`Missing/Invalid ${field.label}`);
              }
            } else {
              callback()
            }
          }
        }]}
        {...field?.fiprops}>
        <AutoComplete popupMatchSelectWidth={false} style={{ width: '100%', borderRadius: "4px" }} placeholder={'Search...'}
          options={field.options}
          // value={filterData[dataItem.key]}
          filterOption={true}
          {...field.props}>
        </AutoComplete>
      </Form.Item>
    case "multiselect":
      // console.log('multiselect field: ', field)
      // console.log('inputFields: ', inputFields)
      return <Form.Item
        className={'m-0 mb-2 w-full'}
        name={field.name}
        label={field.label}
        rules={[{ required: field.required, message: `Missing ${field.label}` }]}
        {...field?.fiprops}>
        <Select placeholder={'Select'} mode={'multiple'} allowClear={true} style={{ width: '100%' }} {...field.props} {...(!!field?.options[0]?.label && { options: field.options })}>
          {!field?.options[0]?.label && Object.keys(field.options).map(item => <Select.Option value={item}>{field.options[item]}</Select.Option>)} </Select>
      </Form.Item>
    case 'date_range':
      // console.log('field: ', field)
      return <Form.Item
        className={'m-0 mb-2 w-full'}
        name={field.name}
        label={field.label}
        rules={[{ required: field.required, message: `Missing ${field.label}` }]}
        {...field?.fiprops}>
        <RangePicker
          // popupClassName={'custom-datepicker-popup'}
          // popupStyle={{zIndex:9999}}
          style={{ width: '100%' }}
          format={field.dateFormat}
          {...field.props} />
      </Form.Item>
    case 'time_range':
      // console.log('field: ', field)
      return <Form.Item
        className={'m-0 mb-2 w-full'}
        name={field.name}
        label={field.label}
        rules={[{ required: field.required, message: `Missing ${field.label}` }]}
        {...field?.fiprops}>
        <TimePicker.RangePicker popupStyle={{ zIndex: 9999 }} style={{ width: '100%' }}   {...field.props} />
      </Form.Item>
    case 'radio_button_group':
      // console.log('field: ', field)
      return <Form.Item
        className={'m-0 mb-2 w-full'}
        name={field.name}
        label={field.label}
        rules={[{ required: field.required, message: `Missing ${field.label}` }]}
        {...field?.fiprops}>
        <Radio.Group options={field?.options} style={{ width: '100%' }} optionType="button" {...field.props} />

      </Form.Item>
    case 'date':
      // console.log('field: ', field)
      return <Form.Item
        className={'m-0 mb-2 w-full'}
        name={field.name}
        label={field.label}
        rules={[{ required: field.required, message: `Missing ${field.label}` }]}
        {...field?.fiprops}>
        <DatePicker style={{ width: '100%' }} placeholder={field.dateFormat} format={field.dateFormat} {...field.props} />
      </Form.Item>
    case 'upload':
      // console.log('field: ', field)
      return <Form.Item
        className={'m-0 mb-2 w-full'}
        name={field.name}
        label={field.label}
        rules={[{ required: field.required, message: `Missing ${field.label}` }]}
        {...field?.fiprops}>
        <div className={'w-full'}>
          <Upload {...field.props}>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
          <Input autoComplete={'off'} style={{ width: '100%', display: 'none' }} />
        </div>
      </Form.Item>
    case 'phone':
      // console.log('field: ', field)
      return <AppPhoneInput name={field.name} formItemProps={{ style: { width: '100%' }, required: field.required }} {...field.props}
        setPhoneValue={setPhoneValue} phoneValue={phoneValue} />
    default:
      return null
  }
}
/**
 * Use this if you want to do any special manipulation on any given input type returning formatted datestring from date types. See usages
 * */
export const dynamicFormOnFinish = async (formInputs, values_, isFilter) => {
  let values = { ...values_ }
  // console.log({ values, formInputs, values_ })
  Object.keys(values_).map(valKey => {
    // console.log({ valKey })
    let value = values[valKey]
    let formInput = formInputs.fields.find(item => item.name === valKey)
    switch (formInput.type) {
      case 'date':
        //Avoid setting current date as default when values are not set
        if (!!value) {
          //Return start and end if the following picker modes are passed with date type
          if (!!formInput?.props?.picker && ['week', 'month', 'year'].includes(formInput?.props?.picker)) {
            const startdayjsFirstDay = dayjs(value).startOf(formInput?.props?.picker).format(isFilter ? 'YYYY-MM-DD' : formInput.dateFormat);
            const enddayjsLastDay = dayjs(value).endOf(formInput?.props?.picker).format(isFilter ? 'YYYY-MM-DD' : formInput.dateFormat);
            values[formInput.keys[0]] = startdayjsFirstDay
            values[formInput.keys[1]] = enddayjsLastDay
            delete values[valKey]
          } else {
            values[valKey] = dayjs(value).format(isFilter ? 'YYYY-MM-DD' : formInput.dateFormat)
          }
        }
        break;
      case 'date_range':
        //console.log('value: ', value)
        // console.log('formInput: ', formInput)
        //Avoid setting current date as default when values are not set
        if (!!value && !!value[0] && !!value[1]) {
          if (!!formInput.keys) {
            //Add custom start and end keys that matches what the API expects if it is meant for GET request
            if (!!formInput?.props?.picker && formInput?.props?.picker !== 'day') {
              //This returns only the first and ending das of week or month selected from the range-picker in case of week/month range input
              const startdayjsFirstDay = dayjs(value[0]).startOf(formInput?.props?.picker).format(isFilter ? 'YYYY-MM-DD' : formInput.dateFormat);
              const enddayjsLastDay = dayjs(value[1]).endOf(formInput?.props?.picker).format(isFilter ? 'YYYY-MM-DD' : formInput.dateFormat);
              values[formInput.keys[0]] = startdayjsFirstDay
              values[formInput.keys[1]] = enddayjsLastDay
            } else {
              //this is a normal date range response
              values[formInput.keys[0]] = dayjs(value[0]).format(isFilter ? 'YYYY-MM-DD' : formInput.dateFormat)
              values[formInput.keys[1]] = dayjs(value[1]).format(isFilter ? 'YYYY-MM-DD' : formInput.dateFormat)
            }
          } else {
            //Add custom start and end keys that matches what the API expects if it is meant for POST request
            values[valKey] = [dayjs(value[0]).format(isFilter ? 'YYYY-MM-DD' : formInput.dateFormat), dayjs(value[1]).format(isFilter ? 'YYYY-MM-DD' : formInput.dateFormat)]
          }
        }
        //delete baseKey not to be added to the API request
        delete values[valKey]
        break;
      case 'autocomplete':
        //Handle both newly set values and those set from filter state in case of filter
        let selectedItem = formInput.options.find(item => (item.value === value || item.actualValue === value))
        // console.log('formInput: ', formInput)
        // console.log('value: ', value)
        console.log('selectedItem: ', selectedItem)
        values[valKey] = selectedItem?.actualValue
        break;
      case 'checkbox':
        //Make sure unchecked Checkboxes which did not have default values are set from undefined to boolean false
        values[valKey] = Boolean(values[valKey])
        break;
      case 'number':
        values[valKey] = Number(values[valKey])
        break;
    }
  })
  // console.log("👹", values)
  return cleanObject(values)
}
/**
 * Use this to convert default values provided to formats that are used bu the Form items e.g converting Date string to dayjs() object to be set as default values in date type fields. See usages
 * */
export const getDynamicFormDefaultValues = (defaultValues, formInputs, isFilter) => {
  // appLog({defaultValues, formInputs})
  let autocompleteFields = [], dateFields = [], dateRangeFields = []
  for (const field of formInputs.fields) {
    if (field.type === 'autocomplete') {
      autocompleteFields.push(field)
    } else if (field.type === 'date') {
      dateFields.push(field)
    } else if (field.type === 'date_range') {
      dateRangeFields.push(field)
    }
  }
  let defaultValues_ = { ...defaultValues }
  if (dateFields.length > 0) {
    let keys = dateFields.map(item => item.name)
    for (const key of keys) {
      if (!!defaultValues[key]) {
        defaultValues_ = { ...defaultValues_, [key]: dayjs(defaultValues[key]) }
      }
    }
  }
  // console.log({dateRangeFields})
  if (dateRangeFields.length > 0) {
    for (const dateRangeField of dateRangeFields) {
      let baseKey = dateRangeField.name
      // console.log('Setting Date Range: ', defaultValues[baseKey])
      let formInput = formInputs.fields.find(item => item.name === baseKey)
      //console.log({formInput})
      let rangeKeys = dateRangeField.keys
      //Filter out default values that belong to this range picker's base key
      let baseKeyDefaultValues = rangeKeys?.map(key => !!defaultValues[key] ? dayjs(defaultValues[key], isFilter ? 'YYYY-MM-DD' : formInput.dateFormat) : undefined)
      defaultValues_ = { ...defaultValues_, [baseKey]: baseKeyDefaultValues }
    }
  }
  if (autocompleteFields.length > 0) {
    let keys = autocompleteFields.map(item => item.key)
    for (const key of keys) {
      let formInput = formInputs.fields.find(item => item.name === key)
      if (defaultValues[key]) {
        let selectedItem = formInput.options.find(item => item.actualValue === defaultValues[key])
        defaultValues_ = { ...defaultValues_, [key]: selectedItem['label'] }
      }
    }
  }
  return defaultValues_
}
export const createFormInputsFromTableColumns = (usedAsTableFilter, columns, phoneValue, setPhoneValue, setPopupOpen, setDatePopupOpen, dateFormat) => {
  let data
  if (usedAsTableFilter) {
    data = columns.filter(item => item.fltr && item.fltr.filterable)
  } else {
    data = columns.filter(item => item?.fltr && item?.fltr?.editableCol)
  }
  
  let fields = []
  let inputCardGroups = {}
  for (const item of data) {
    let inputGroupName = item?.fltr?.inputGroup || 'undefinedInputGroup'
    if (!inputCardGroups[inputGroupName]) {
      inputCardGroups[inputGroupName] = []
    }
    let field = {
      name: item?.fltr?.baseKey || item.key,
      label: item?.fltr?.filterLabel || item?.title,
      inputGroup: item?.fltr?.inputGroup || item?.inputGroup,
      //make sure filter fields are not strictly required in filter form except when creating form input fields for other forms
      required: usedAsTableFilter ? false : !!item.required || !!item.fltr?.required,
      type: /*item.fltr.type === 'phone' ? 'text' :*/ item.fltr.type,
      fullRow: usedAsTableFilter || item?.fltr?.fullRow
    }
    if (item.fltr.type === 'autocomplete') {
      field.options = item.fltr.options
    }
    if (item.fltr.type === 'select') {
      field.options = item.fltr.options
    }
    if (item.fltr.type === 'date') {
      field.dateFormat = usedAsTableFilter ? 'MM-DD-YYYY' : dateFormat
    }
    if (item.fltr.type === 'date_range') {
      field.dateFormat = usedAsTableFilter ? 'MM-DD-YYYY' : dateFormat
      field.keys = item.fltr.keys
    }
    field.props = item?.fltr?.props
    if (!!item?.fltr?.fiprops) {
      field.fiprops = item.fltr.fiprops
    }
    if (!field.props) {
      field.props = {}
      // fields.push(field)
      // continue
    }

    if ((item.fltr.type === 'autocomplete' && !!setPopupOpen)) {
      field.props.onDropdownVisibleChange = open => setPopupOpen(open)
      //Fix zIndex in the filter dropdown
      // field.props.getPopupContainer = (triggerNode) => triggerNode.parentNode
    }
    if ((item.fltr.type === 'select' && !!setPopupOpen)) {
      field.props.onDropdownVisibleChange = open => setPopupOpen(open)
      //Fix zIndex in the filter dropdown
      // field.props.getPopupContainer = (triggerNode) => triggerNode.parentNode
    }
    if (item.fltr.type === 'date' && !!setDatePopupOpen) {
      field.props.onOpenChange = (open) => setDatePopupOpen(open)
      field.props.dateFormat = dateFormat
      //Fix zIndex in the filter dropdown
      // field.props.getPopupContainer = (triggerNode) => triggerNode.parentNode
    }
    if (item.fltr.type === 'date_range' && !!setDatePopupOpen) {
      field.props.onOpenChange = (open) => setDatePopupOpen(open)
      field.props.dateFormat = dateFormat
      //Fix zIndex in the filter dropdown
      // field.props.getPopupContainer = (triggerNode) => triggerNode.parentNode
    }
    if (item.fltr.type === 'phone') {
      field.props.setSelectPopupOpen = setPopupOpen
    }
    inputCardGroups[inputGroupName].push(field)
    fields.push(field)
  }

  // return { fields }
  return { inputCardGroups, fields }
}

export const generateString = length => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const IsItemEnabled = (settings, group, item) => {
  const isEnabled = settings?.[group]?.[item]?.enabled;
  return isEnabled === 'yes'
}


export function capitalizeFirstLetter(string) {
  return string?.[0].toUpperCase() + string?.slice(1);
}


export const keyValItemRorReports = (key, val, sup) => (
  <Row gutter={[4, 1]} className="pl-1" align={"stretch"} justify={"start"} style={{ width: '100%', marginTop: 6, overflowWrap: 'break-word' }}>
    <Col xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}><Subtitle1 {...commonStyle(true)}>{key?.toString()}</Subtitle1></Col>
    <Col className="capitalize" xs={24} sm={24} md={24} lg={12} xl={12} flex={"auto"} style={{ width: '100%' }}><Body2 color="black" {...commonStyle()}>{val}<sup>{sup}</sup></Body2></Col>
  </Row>)