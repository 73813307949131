/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Subtitle1} from '../../../components'


import {Avatar, Button, Form, Grid, Input, Modal, Table, Tabs, Tag} from 'antd';
// import SheduleAdmission from './SheduleAdmission';
import dayjs from 'dayjs'
import {
    deleteAdmission,
    fetchAdmissions,
    resetAdmissionsPagination,
    resetAppointmentModuleState,
    resetPatientModuleState,
    selectAdmission,
    setAdmissionsLoaded,
    updateAdmissionFilters
} from '../../../app/Reducers';
import {
    dialogCloser,
    emptyFun,
    emptyTable,
    formSubmitButton,
    getAdmissionStatus,
    getSkels,
    horStack,
    kebabMenus,
    makeSelectOptions,
    modalClose,
    onTableChange,
    outlinedButton,
    responsiveTbl,
    stateFilter
} from "../../../common/helpers";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import TableFilters from "../../../components/InputComponents/TableFilters";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import NewAdmission from '../NewAdmission';
import {apiRequestBlob} from '../../../app/Apis';
import download from "downloadjs";
import {EditOutlined} from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';
import {useFacilitySearch} from '../../../hooks/useFacilitySearch';
const { useBreakpoint } = Grid;
const AdmissionsList = ({ admissions, loading, appConf, navs, admissionsPagination, activeUser }) => {
  const [activeTab, setActiveTab] = useState('patients');
  const location = useLocation()
  const navigate = useNavigate();
  const windowState=useBreakpoint()
  const [isOpen, setOpen] = useState(false)
  const dispatch = useDispatch()
  const [isVisible, setIsVisible] = useState(false);
  const [isExecuting, setisExecuting] = useState(false)
  const [editingForm, setEditingForm] = useState(false);
  const [admission, setAdmission] = useState(null);
  const { message } = useSubscription(`view_admissions`, { qos: 2, nl: true });
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const can_update_admission = usePermissionCheck('update_admission', false, [])
  const can_view_admission = usePermissionCheck('view_admission', false, [])
  const can_create_admission = usePermissionCheck('assign_consultant', false, [])
  const can_delete_admission = usePermissionCheck('delete_admission', false, [])
  const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  let defaultList = !!activeUser?.facilityProfile ? [{ ...activeUser?.facilityProfile, label: activeUser?.facilityProfile.name, value: activeUser?.facilityProfile.id }] : []
  const [facilityOptions, debouncedFacilitiesSearch,facilitySearchLoading] = useFacilitySearch([], 'id', defaultList)
  useEffect(() => {
    if (!!message) {
      console.log('MQTT MESSAGE:  ', message)
      loadAdmissions(true).catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  let onCell = (record, rowIndex) => ({
    onClick: event => {
      (async () => {
        if (record.id !== navs.selectedAdmissionID) {
          await dispatch(resetAppointmentModuleState())
        }
        await dispatch(selectAdmission({ ...record }))
        if (can_create_admission) {
          setOpen(true)
        }
        
        // if (can_view_admission) {
        //     navigate(`/admissions/${record.id}`)
        // }
      })().catch(e => console.log(e))
    }
  })
  
  
  const columns = [{
    title: 'Patient', dataIndex: "patient_name", key: 'patient_name',
    /* sorter: (a, b) => a.name - b.name, fltr: {filterable: true, type: 'text'},*/
    onCell: (record, rowIndex) => {
      return {
        onClick: event => {
          (async () => {
            if (record.patient_id !== navs.patientDetailsOwner) {
              await dispatch(resetPatientModuleState())
            }
            // await dispatch(selectPatient({ record: patient }))
            navigate(`/patients/${record.patient_id}`)
          })().catch(e => console.log(e))
        } // click cell
      };
    },
    
    /* sorter: (a, b) => a.name - b.name, fltr: {filterable: true, type: 'text'},*/
    render: (name) => (<Button size={"large"} type={"text"}
                                  style={{
                                    textTransform: 'none', margin: 0, width: '100%', display: "flex", justifyContent: "flex-start",
                                    backgroundColor: 'transparent', color: '#038A96', textAlign: 'left'
                                  }}>{name}</Button>
    ),
    fltr: { filterable: true, type: 'text' }
  }, {
    title: 'Approval Date',
    dataIndex: 'approved_date',
    key: 'approval_date',
    fltr: { filterable: true, type: 'date_range', keys: ['approval_date[start_at]', 'approval_date[end_at]'], baseKey: 'approval_range_filter' },
    sorter: { multiple: 3, compare: (a, b) => dayjs(a.approved_date).unix() - dayjs(b.approved_date).unix() },
    render: (approved_date, record) => (
      <Subtitle1 style={{ textAlign: 'left' }}>{dayjs(approved_date).format("MM/DD/YYYY")}</Subtitle1>),
    onCell
  }, {
    title: 'Office', dataIndex: 'facility_name', key: 'facility',
    fltr: { filterable: true, type: 'select', options: facilityOptions,
      props: {
        disabled: !!activeUser?.facilityProfile,loading:facilitySearchLoading,
        onSearch: (value) => debouncedFacilitiesSearch(value), showSearch: true, filterOption: false
      },
      fiprops:{
        initialValue:activeUser?.facilityProfile ? activeUser?.facilityProfile?.id : undefined
      } }, onCell
  },
    
    // {
    //     title: 'Referral Type', dataIndex: "service_type", key: 'referral_type',
    //     fltr: {
    //         filterable: true, type: 'autocomplete',
    //         options: makeAutoCompleteOptions([{ label: 'Wound Care', value: 'wound care' }, { value: 'podiatry', label: 'Podiatry' }, {
    //             value: 'wound care and podiatry',
    //             label: 'Wound Care and Podiatry'
    //         }])
    //     }, visible: !activeUser?.facilityProfile
    // },
    // {
    //     title: 'Assigned to', dataIndex: ['consultant', 'name'], key: 'wcc', fltr: { filterable: true, type: 'text' }, onCell,
    //     render: (record, name) => (
    //         // avatar with name
    //         <div className="flex flex-row items-center">
    //             <Avatar size="small" src={record.consultant.profileImageUrl} alt={name} />
    //             <Subtitle1 style={{ textAlign: 'left' }}>{name}</Subtitle1>
    //         </div>
    //     )
    //     },
    {
      title: 'Status', dataIndex: 'admission_status', key: 'admissionStatus', fltr: {
        filterable: true, type: 'select',
        options: makeSelectOptions([/*'assigned',*/ 'unassigned', 'no_coverage', 'on_hold'])
      }, render: (status) => getAdmissionStatus(status), onCell
    },
    ...(activeUser?.is_qa_user?[{ width:15,
      visible: true,
      title: <FontAwesomeIcon color="grey" icon={['fas', 'table']}/>,
      key: 'operation',
      render: (record) => <Tag className={`${(record.is_qa && activeUser?.is_qa_user)?'visible':'invisible'}`} icon={<EditOutlined/>} color="#55acee">QA</Tag>
    }]:[])
    // {
    //     fltr: { filterable: false }, render: (record) => {
    //         const actionMenus = kebabMenus((can_update_patient_resource), masterGlobalAccess, masterGlobalAccess)
    //         return <KebabMenu menus={actionMenus}
    //             recordItem={record}
    //             handleMenuClick={kebabMenuClick} resource={'addmission'} handleOk={() => handleOk(record.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
    //             showConfirmDialog={selected === record.id} />;
    //     }
    // },
  
  
  ]
  const [selected, setSelected] = React.useState(null);
  const handleCancel = () => setSelected(0);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const showPopconfirm = (id) => setSelected(id);
  const actionMenus = kebabMenus(can_update_admission, can_view_audit_logs, can_delete_admission)
  
  async function kebabMenuClick(e, record) {
    await setAdmission(record)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true)
        await setOpen(true)
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
    }
  }
  
  
  async function handleFilterAction(action, values) {
    // console.log('handleFilterAction: ',values)
    await dispatch(resetAdmissionsPagination())
    if (action === 'filter') {
      console.log('FILTERS:  ', JSON.stringify({ ...values, toLoad: 'admissions', endpoint: '/admissions' }));
      let pl = { filterData: values, pagination: admissionsPagination }
      await dispatch(updateAdmissionFilters([false, pl, true]))
      await dispatch(fetchAdmissions(pl))
      await dispatch(updateAdmissionFilters([true, pl, false]))
    }
    if (action === 'reset'/* && appConf.admissionFilters[0]*/) {
      await dispatch(updateAdmissionFilters([appConf.admissionFilters[0], undefined, true]))
      await dispatch(fetchAdmissions())
      await dispatch(updateAdmissionFilters([false, undefined, false]))
    }
    // const response = await apiRequest.get(`/admissions`,{params: {...values,toLoad:'admissions'}})
    setIsVisible(false)
  }
  
  const loadAdmissions = async (isMQ) => {
    /*if (!!admissions[0] && !isMQ) {
      return
    }*/
    await dispatch(updateAdmissionFilters([appConf.admissionFilters[0], appConf.admissionFilters[1], !isMQ]))
    await dispatch(fetchAdmissions(appConf.admissionFilters[0] ? appConf.admissionFilters[1] : undefined))
    // await dispatch(fetchProcedures())
    // await dispatch(fetchLabRegions())
    // await dispatch(loadWoundLocations())
    await dispatch(setAdmissionsLoaded(true))
    await dispatch(updateAdmissionFilters([appConf.admissionFilters[0], appConf.admissionFilters[1], false]))
  }
  
  
  useEffect(() => {
    loadAdmissions(false).catch(e => console.log(e))
    return emptyFun
  }, [])
  
  const handleOk = async (id) => {
    setadmissionTodeleteID(id)
    setreasonModal(true)
    setSelected(0)
  };
  
  
  const [admissionTodeleteID, setadmissionTodeleteID] = useState(null)
  const [reasonStatus, setreasonStatus] = useState(null)
  const [reasonModal, setreasonModal] = useState(false)
  const reasonFinish = async (values) => {
    await setConfirmLoading(true);
    await dispatch(deleteAdmission({ admission_id: admissionTodeleteID, ...values }));
    await setSelected(0);
    await setConfirmLoading(false);
    setreasonModal(false)
  }
  
  const [selectedTab, setselectedTab] = useState("1")
  const callback = (key) => {
    setselectedTab(key)
  }
  const Tabs_ = () => {
    return (
      <Tabs defaultActiveKey={selectedTab} onChange={callback}>
        <Tabs.TabPane tab={<Subtitle1>{editingForm ? 'Edit admission' : "New Admission"}</Subtitle1>} key="1">
        
        </Tabs.TabPane>
        filter fix
      
      </Tabs>
    )
  }
  const ExportAdmissions = async () => {
    const response = await apiRequestBlob.get('/admissions-export')
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    download(response.data, `admissions-${dayjs().format("YYYY-MMM-DD hh:mm")}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    
  }
  
  return (
    (<div className="main-page">
      {(!!admission && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false}
                                                           resourceDescription={`${admission.patient.name}'s admission`} resourceType={'Admission'}
                                                           defaultValues={{ auditableId: admission?.id, auditable: 'admission', format: 'json' }}/>}
      <Modal
        title={Tabs_()}
        open={isOpen}
        onOk={dialogCloser(setOpen)}
        onCancel={dialogCloser(setOpen)}
        closeIcon={modalClose(dialogCloser(setOpen))}
        maskClosable={false}
        destroyOnClose={true}
        width={1200}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setOpen), 'Cancel', isExecuting),
            formSubmitButton('admissionForm', 'Save', isExecuting, 'Saving')])}
        </div>]}>
        
        <NewAdmission setisExecuting={setisExecuting} closeModal={dialogCloser(setOpen)}/>
      
      </Modal>
      <Modal
        title={`Please add a Reason`}
        open={reasonModal}
        destroyOnClose={true}
        
        onOk={() => setreasonModal(false)}
        onCancel={() => setreasonModal(false)}
        closeIcon={modalClose(() => setreasonModal(false))}
        maskClosable={false}
        width={1200}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(() => {
            setreasonModal(false)
            setSelected(0)
          }, 'Cancel', isExecuting),
            formSubmitButton('reasonForm', 'Add Reason', isExecuting, 'Updating')])}
        </div>]}
      >
        <Form
          name="reasonForm"
          onFinish={reasonFinish}
          layout="vertical"
        >
          
          <Form.Item
            style={{ width: '100%' }}
            label="Delete Reason"
            name="delete_reason"
            rules={[{ required: true, message: 'this field is required!' }]}
            wrapperCol={{ span: 24 }}
          >
            <Input autoComplete={'off'}/>
          </Form.Item>
        </Form>
      
      </Modal>
      <div className="w-full">
        
        
        <Table
          title={() => (
            <TableFilters datasource={[...columns, stateFilter /*procedureFilter*/]}
                          setIsVisible={setIsVisible} isVisible={isVisible} windowState={windowState}
                          handleFilterAction={handleFilterAction} filters={appConf.admissionFilters[1]}
                          showClear={appConf.admissionFilters[0]} loading={appConf.admissionFilters[2]}
                          actionButton={
                            <div>
                              <Button type={'default'} size={'middle'} onClick={() => ExportAdmissions()}
                                      icon={<FontAwesomeIcon icon={["far", 'file-excel']}/>}>Export to CSV
                              </Button>
                              {/* {


                                        can_create_admission ? containedButton(() => {
                                            setEditingForm(false)
                                            setOpen(true);
                                        }, 'Add New', false, null, null, { icon: <PlusOutlined /> }) : null
                                    } */}
                            </div>
                            
                          }
            />
          )}
          loading={appConf.admissionFilters[2]} {...responsiveTbl(windowState)} locale={{ emptyText: emptyTable('admissions') }}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchAdmissions, appConf.admissionFilters, updateAdmissionFilters, null, admissionsPagination)}
          {...getSkels((appConf.admissionFilters[2]), columns.filter(value => !value.invisible), admissions)} pagination={admissionsPagination}/>
      </div>
    </div>)
  );
}
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser, admissions: state.appointments.admissions,
  loading: state.appointments.admissions_loading, appConf: state.appConf, navs: state.navs,
  admissionsPagination: state.appointments.admissionsPagination
  
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(AdmissionsList)
