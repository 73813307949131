/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Subtitle1} from '../../../components';
import {Grid, Modal, Table, Tag} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {fetchOrgFacilities, resetOrgFacilitiesPagination, selectFacility, setFacilitiesLoaded, updateOrgFacilitiesFilters} from '../../../app/Reducers';
import NewFacilityCard from '../components/NewFacility';
import {
  containedButton,
  dialogCloser,
  emptyFun,
  emptyTable,
  formSubmitButton,
  getDualStatus,
  getSkels,
  horStack,
  makeAutoCompleteOptions,
  modalClose,
  onTableChange,
  outlinedButton,
  regionFilter,
  responsiveTbl,
  stateFilter
} from "../../../common/helpers";
import TableFilters from "../../../components/InputComponents/TableFilters";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {CustomerServiceOutlined, EditOutlined, LinkedinOutlined, PlusOutlined} from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';
const { useBreakpoint } = Grid;
const OrganisationFacilitiesList = ({  organisation, orgFacilities, orgFacilitiesPagination, appConf, navs,activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState=useBreakpoint()
    const dispatch = useDispatch();
    const organisationId = organisation?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
    const can_view_medical_facility = usePermissionCheck('view_medical_facility', false, [])
    const { message } = useSubscription(`organisationFacilities`, { qos: 2, nl: true });
    const subRegionsRaw = useSelector(state => state.masters.masterListRaw.find(item => item.system_name === 'sub_region')?.options)
    useEffect(() => {
      if (!!message) {
        console.log('MQTT MESSAGE:  ', message);
        loadFacilities(true).catch(e => console.log(e));
      }
      return emptyFun;
    }, [message]);
    useEffect(() => {
      loadFacilities(false).catch(e => console.log(e));
      return emptyFun;
    }, []);
    
    const loadFacilities = async (isMQ) => {
      await dispatch(updateOrgFacilitiesFilters([appConf.orgFacilitiesFilters[0], appConf.orgFacilitiesFilters[1], !isMQ]));
      await dispatch(fetchOrgFacilities(appConf.orgFacilitiesFilters[0] ? appConf.orgFacilitiesFilters[1] : { organisationId }));
      await dispatch(setFacilitiesLoaded(true));
      await dispatch(updateOrgFacilitiesFilters([appConf.orgFacilitiesFilters[0], appConf.orgFacilitiesFilters[1], false]));
    };
    
    const columns = [
      { title: 'Office Name', dataIndex: 'name', key: 'name', fltr: { filterable: true, type: 'text' } },
      { title: 'Region', dataIndex: 'region', key: 'regionName', fltr: regionFilter },
      {
        title: 'Sub-region', dataIndex: 'sub_region', key: 'sub_region', fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(subRegionsRaw) }
      },
      { ...stateFilter, title: 'State', dataIndex: 'state', key: 'state' },
      { title: 'City', dataIndex: 'city', key: 'city', fltr: { filterable: true, type: 'text' } },
      { title: 'Contact Phone', dataIndex: 'phone_no', key: 'phoneNo', fltr: { filterable: true, type: 'phone' } },
      {
        title: 'Regional VP Sales', dataIndex: 'sales_managers', key: 'salesManager', fltr: { filterable: true, type: 'text' },
        render: (managers) => (<Subtitle1 style={{ textAlign: 'left' }}> {managers?.map((item) => item.name).toString()} </Subtitle1>)
      },
      {
        title: 'Status', dataIndex: 'status', key: 'status',
        fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(['active', 'in-active']) },
        render: (status) => getDualStatus(status)
      },
      ...(activeUser?.is_qa_user?[{ width:15,
            visible: true,
            title: <FontAwesomeIcon color="grey" icon={['fas', 'table']}/>,
            key: 'operation',
            render: (record) => <Tag className={`${(record.is_qa && activeUser?.is_qa_user)?'visible':'invisible'}`} icon={<EditOutlined/>} color="#55acee">QA</Tag>
        }]:[])
    ];
    const [patientForm, setPatientForm] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    
    async function handleFilterAction(action, values) {
      await dispatch(resetOrgFacilitiesPagination());
      if (action === 'filter') {
        let pl = { filterData: { ...values, organisationId }, pagination: orgFacilitiesPagination };
        await dispatch(updateOrgFacilitiesFilters([false, pl, true]));
        await dispatch(fetchOrgFacilities(pl));
        await dispatch(updateOrgFacilitiesFilters([true, pl, false]));
      }
      if (action === 'reset' /* && appConf.orgFacilitiesFilters[0]*/) {
        await dispatch(
          updateOrgFacilitiesFilters([appConf.orgFacilitiesFilters[0], undefined, true])
        );
        await dispatch(fetchOrgFacilities({ filterData: { organisationId } }));
        await dispatch(updateOrgFacilitiesFilters([false, undefined, false]));
      }
      setIsVisible(false);
    }
    
    const [isExecuting, setisExecuting] = useState(false);
    // const formButtonText = isPreview ? 'Save Office' : 'Preview Office';
    
    return (
      (<div className="main-page">
        <Modal
          title="New Office"
          open={patientForm}
          onOk={dialogCloser(setPatientForm)}
          destroyOnClose
          width={650}
          onCancel={dialogCloser(setPatientForm)}
          closeIcon={modalClose(dialogCloser(setPatientForm))}
          maskClosable={false}
          footer={[
            <div
              className="entity-filters flex-boy-row"
              style={{ justifyContent: 'flex-end' }}
            >
              {horStack([
                outlinedButton(dialogCloser(setPatientForm), 'Cancel', isExecuting),
                formSubmitButton('facilityForm', 'Create Office', isExecuting, 'Creating')
              ])}
            </div>
          ]}
        >
          <NewFacilityCard setisExecuting={setisExecuting} closeModal={dialogCloser(setPatientForm)}/>
        </Modal>
        <div className="w-full">
          <Table
            title={() => (<TableFilters
              datasource={columns}
              setIsVisible={setIsVisible}
              isVisible={isVisible}
              filters={appConf.orgFacilitiesFilters[1]}
              actionButton={navs.orgNavs !== 'Facilities' ?
                containedButton(() => setPatientForm(true), 'Add New', false, null, null, { icon: <PlusOutlined /> }) : null}
              windowState={windowState}
              handleFilterAction={handleFilterAction}
              showClear={appConf.orgFacilitiesFilters[0]}
              loading={appConf.orgFacilitiesFilters[2]}
            />)}
            rowKey={'id'}
            loading={appConf.orgFacilitiesFilters[2]}
            {...responsiveTbl(windowState)}
            locale={{ emptyText: emptyTable('organisation facilities') }}
            onChange={(pagination, filters, sorter) =>
              onTableChange(pagination, filters, sorter, dispatch, fetchOrgFacilities, appConf.orgFacilitiesFilters, updateOrgFacilitiesFilters, { organisationId })
            }
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  (async () => {
                    await dispatch(selectFacility(record));
                    if (can_view_medical_facility) {
                      navigate(`/facilities/${record.id}`);
                    }
                  })().catch(e => console.log(e));
                } // click row
              };
            }}
            {...getSkels(appConf.orgFacilitiesFilters[2], columns, orgFacilities)}
            pagination={orgFacilitiesPagination}
          />
        </div>
      </div>)
    );
  }
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser,
  orgFacilities: state.organisations.orgFacilities,
  appConf: state.appConf,
  navs: state.navs,
  orgFacilitiesPagination: state.organisations.orgFacilitiesPagination,
   organisation: state.organisations.selectedOrganisation
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(OrganisationFacilitiesList);
