import { useEffect, useState } from "react";
import { Row, Col, Divider, Form, Select, List, Button, AutoComplete, Empty } from "antd";
import { Subtitle1 } from "../../../components";
import { GetOptions } from "../../../common/helpers";
import { apiRequest } from "../../../app/Apis";
import { toastUp } from "../../../app/Reducers/reducerUtils";
import querystring from "querystring";

export default function TreatmentRecommendation({ assessmentForm, setSelectedTreatment, setnewTreatmentKit, onprotocolSelect, masterList, selectedTreatment, isEditing, appointment }) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [treatments, setTreatments] = useState([])
  const [executing, setExecuting] = useState(false)
  const [selectedOption, setSelectedOption] = useState(selectedTreatment?.id || null)
  const [selectedTreatmentFilters, setSelectedTreatmentFilters] = useState([assessmentForm.getFieldsValue(true).extra_notes])
  const [treatmentOptions, setOptions] = useState([])
  const [onSelectTreatmentKit, onsetSelectedTreatmentKit] = useState(null)
  const [autocomepleteSelect, setAutocompleteSelect] = useState(null)

  async function getDMERecommendations() {
    const tfilters = masterList.find(option => option.system_name === 'treatment_filter')
    console.log("treatment filters:::", tfilters)
    const size = assessmentForm.getFieldsValue(true).size;
    const classification = assessmentForm.getFieldsValue(true).wound_type;
    const location = assessmentForm.getFieldsValue(true).location;
    const exudateAmount = assessmentForm.getFieldsValue(true).exudate_amount;
    const treatmentFilter = assessmentForm.getFieldsValue(true).extra_notes;

    form.setFieldsValue({ classification: classification })
    form.setFieldsValue({ size: size })
    form.setFieldsValue({ location: location })
    form.setFieldsValue({ exudate_amount: exudateAmount })
    form.setFieldsValue({ treatment_filters: selectedTreatmentFilters })
    // run on Finish function
    if (!size || !classification || !location || !exudateAmount) {
      console.log("Not all fields are filled")
      toastUp("Please fill all fields", false)
      form.validateFields('size')
      form.validateFields('treatment_filters')
      setTreatments([])
    } else {
      await submitValues()
    }
  }

  const handleProtocolsearch = async (value) => {
    let params = { ...(!!value && { search: value }) }
    const { data } = await apiRequest.get(`/treatment-scenarios?${querystring.stringify(params)}`)
    if (data) {
      //console.log(data.data)
      let other = { protocol_name: "Custom Kit", treatment_code: "" }
      let options = data.data
      options = options.map(item => ({
        ...item,
        protocol_name: item.treatment_code ? `${item.scenario_number} ${item.scenario_number ? "-" : null} ${item.treatment_code}-${item.protocol_name}` : `${item.scenario_number} ${item.scenario_number ? "-" : null}${item.protocol_name}`
      }))
      options.push(other)
      setOptions(options);
    }
  }

  function handleSelectedOption(value) {
    setAutocompleteSelect(null)
    form.setFieldsValue({ treatment_protocol: null })
    setSelectedOption(value)
    const theSelectedTreatment = treatments.find(treatment => treatment.id === value)
    onprotocolSelect(theSelectedTreatment)
    setnewTreatmentKit(false)
  }

  function onAutocompleteSelect(value) {
    const selectedOption = treatmentOptions.find(option => option.protocol_name === value)
    setSelectedOption(selectedOption)
    onprotocolSelect(selectedOption)
    setnewTreatmentKit(false)
    setAutocompleteSelect(selectedOption)
    // selectedTreatmentKit()
  }

  async function handleTreatmentFilters(value) {
    setSelectedTreatmentFilters(value)
  }

  useEffect(() => {
    getDMERecommendations()
  }, [assessmentForm])

  useEffect(() => {
    submitValues();
  }, [selectedTreatmentFilters]);


  function queryObjectToString(obj) {
    let params = new URLSearchParams();
    for (let o in obj) {
      if (o === 'treatment_filters' && Array.isArray(obj[o])) {
        obj[o].forEach((item, index) => {
          params.append(`${o}[${index}]`, item);
        });
      } else {
        params.append(o, obj[o]);
      }
    }
    return params.toString();
  }

  async function submitValues(values) {
    values = form.getFieldsValue(true);
    setExecuting(true);

    const queryParams = {
     
      type_of_kit:appointment?.type_of_kit,
      wound_size: assessmentForm.getFieldsValue(true).size,
      classification: assessmentForm.getFieldsValue(true).wound_type,
      wound_location: assessmentForm.getFieldsValue(true).location,
      exudate_amount: assessmentForm.getFieldsValue(true).exudate_amount,
      treatment_filters: selectedTreatmentFilters
    };
    try {
      const queryString = queryObjectToString(queryParams);

      const url = `/dme-kits?${queryString}`;
      const response = await apiRequest.get(url);
      if (response.status === 200) {
        setTreatments(response.data.data);
      } else {
        setTreatments([]);
      }
      setExecuting(false);
    } catch (error) {
      console.log("Error in getting treatment recommendations");
      setExecuting(false);
      setTreatments([]);
    }
  }


  const valueMapping = {
    "Silver Alginate": "AG",
    "Non-bordered": "Non bordered foam",
    // add more mappings if needed, or keep the option's name as the default value
  };
  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description="No Treatment Recommendations Found" />
      </span>
    )
  };
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Divider className="mb-4 text-black" />
      <Row className={'h-full w-full'}>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} className="p-4 ">
          <Form
            name="treatmentRecommendation"
            form={form}
            initialValues={isEditing ? selectedTreatment : null}
            className="w-full justify-start">
            <label className="font-bold text-black mb-4">Size</label><br />
            <Form.Item name="size" className="w-full mb-4">
              <Select className="w-full mb-4" placeholder="Read Only" onChange={submitValues} disabled>
                {GetOptions('wound_size')}
              </Select>
            </Form.Item>
            <label className="font-bold text-black">Classification</label><br />
            <Form.Item name='classification' className="w-full mb-4">
              <Select className="w-full mb-4" placeholder="Read Only" disabled={true} />
            </Form.Item>
            <label className="font-bold text-black">Location</label><br />
            <Form.Item name='location' className="w-full mb-4">
              <Select className="w-full mb-4" placeholder="Read Only" disabled />
            </Form.Item>
            <label className="font-bold text-black">Exudate Amount</label><br />
            <Form.Item name='exudate_amount' className="w-full mb-4">
              <Select className="w-full mb-4" placeholder="Read Only" disabled={true} />
            </Form.Item>
            <label className="font-bold text-black">Treatment Filter</label><br />
            <Form.Item name='treatment_filters'>
              <Select popupMatchSelectWidth={false} mode="multiple" placeholder="Select Treatment Filter"
                onChange={handleTreatmentFilters}
                style={{ width: "100%", borderRadius: "4px" }}>
                {masterList.find(option => option.system_name === 'treatment_filter')?.options?.map(option => (
                  <Option value={valueMapping[option] || option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Col>
        {/* <Divider type="vertical" className="h-full text-black" /> */}
        <Col xs={24} sm={24} md={24} lg={18} xl={18} className={'p-4 flex-1'}>
          <div className="w-full">
            <div>
              <div className="flex-boy-row-space">
                <Subtitle1 className="">Select Kit</Subtitle1>
              </div>
              <AutoComplete popupMatchSelectWidth={false}
                name="treatment_protocol"
                style={{ width: "100%", marginBottom: "20px" }}
                onSelect={(value) => onAutocompleteSelect(value)}
                onSearch={handleProtocolsearch}
                placeholder="search for treatment protocols">
                {treatmentOptions.map(item => (<Option key={item.id} value={item.protocol_name}>
                  {item.protocol_name}
                </Option>))}
              </AutoComplete>
              {autocomepleteSelect &&
                <TreatmentRecommendationItem item={autocomepleteSelect} selected={autocomepleteSelect} />
              }
            </div>
            <div className="flex-boy-row-space" style={{ padding: 5 }}>
              <Subtitle1 className="">Treatment Recommendations</Subtitle1>
              <Subtitle1 className="">Found {treatments.length} items</Subtitle1>
            </div>
            <List
              className="w-full"
              itemLayout="horizontal"
              loading={executing}
              locale={locale}
              dataSource={treatments} renderItem={item => (
                <div onClick={() => handleSelectedOption(item.id)}>
                  <TreatmentRecommendationItem item={item} selected={selectedOption} />
                </div>
              )}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

function TreatmentRecommendationItem({ item, selected }) {
  const selectedTreatment = selected !== item.id ? "bg-slate-100 text-stone-800" : "bg-[#EF8100] text-white"
  return (
    <div key={item.id} className={`w-[520px] h-16 px-3 py-2 ${selectedTreatment} rounded-lg flex-col justify-start items-start gap-2 inline-flex mb-2
    hover:bg-[#EF8100] hover:text-white cursor-pointer `}>
      <div className="self-stretch rounded-lg justify-start items-start inline-flex">
        <div className="grow shrink basis-0 h-12 justify-start items-start gap-1 flex">
          <div className="grow shrink basis-0 text-sm font-bold leading-normal">{`${item.scenario_number} - `}{item.protocol_name}</div>
        </div>
      </div>
    </div>
  )
}