/* eslint-disable no-unused-vars */
import React from 'react'
import { connect } from 'react-redux'
import { Body2, Indicator, Subtitle1, Subtitle2 } from '../../general'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Image } from 'antd'
import { status_colors } from '../../../configure/constants'
import dayjs from 'dayjs'

export const AppointmentPopover = ({ status, appointment }) => {
  return (<div className="flex-boy-column" style={{ width: "400px" }}>
    <div className="status-container" style={{ marginBottom: "10px" }}>
      <Indicator color={status_colors[status] || "#4B9BFF"} />
      <Subtitle1>{status}</Subtitle1>
    </div>
    <div className="flex-boy-row" style={{ marginBottom: "10px" }}>
      <div className="icon-circle">
        <FontAwesomeIcon size="1x" icon={['fas', 'user-injured']} />
      </div>
      <div className="flex-boy-row-space" style={{ flex: 1 }}>
        <div>
          <Subtitle1 style={{ textAlign: "left" }}>{appointment.patient.name}</Subtitle1>
          <Body2
            color="grey">{dayjs(appointment.patient.birth_date).format("MM/DD/YYYY")}({dayjs().diff(dayjs(appointment.patient.birth_date), 'years')}y.o.)</Body2>
          <Body2>{appointment.patient.phone_no}</Body2>
        </div>
        <FontAwesomeIcon size="1x" icon={['fas', 'chevron-right']} />
      </div>

    </div>
    <div className="flex-boy-row-space" style={{ marginBottom: "10px" }}>
      <div className="flex-boy-row" style={{ alignItems: "center" }}>
        <Avatar src={<Image src={require("../../../assets/user.png")} />} />
        <Subtitle1 style={{ marginLeft: "20px" }}>{appointment.consultant.name}</Subtitle1>
      </div>

      <FontAwesomeIcon size="1x" icon={['fas', 'chevron-right']} />
    </div>
    <div className="flex-boy-row">
      <div className="icon-circle">
        <FontAwesomeIcon size="1x" icon={['fas', 'user-injured']} />
      </div>
      <div className="flex-boy-row-space" style={{ flex: 1 }}>
        <div>
          <Subtitle1 style={{ textAlign: "left" }}>Phone Evaluation</Subtitle1>
          <Subtitle2>{appointment.wounds[0].status} Wound</Subtitle2>
          <Subtitle2>{appointment.wounds[0].location}</Subtitle2>
        </div>
        <FontAwesomeIcon size="1x" icon={['fas', 'chevron-right']} />
      </div>

    </div>
  </div>);
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentPopover)

