// new department form

import { AutoComplete, Avatar, Form, Input, Select, Tag, message } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { apiRequest } from '../../../app/Apis'
import { createDepartment, updateDepartment } from '../../../app/Reducers'

export const NewDepartment = ({ setIsExecuting, setNewDepartmentModal, editingForm,department }) => {
  const dispatch = useDispatch()
  const [messageApi, contextHolder] = message.useMessage();


    const [form] = Form.useForm()
    const {Option} = Select
  const onFinish = async (values) => {
    if (selectedAssignee === null) {
      return messageApi.error('Please select a valid supervisor!')
    } else {
      setIsExecuting(true)
      console.log('Success:', values)
      let payload = {
        ...values,
        users: selectedConsultants.map(consultant => consultant.user_id),
        supervisor_id: selectedAssignee
      }
      console.log(payload)
      await dispatch(editingForm ? updateDepartment({ id: department.id, ...payload }) : createDepartment(payload))
      setIsExecuting(false)
      setNewDepartmentModal(false)
    }
    }

    //  assigneews
    const [assignees, setassignees] = useState([]);
    const [selectedAssignee, setselectedAssignee] = useState(department? department.supervisor?.id : null);
     
    // handle assignee search

const handleAssigneesearch = async (value) => {
    const {data} = await apiRequest.get(`/consultants?search=${value}`)
    // setOptions(data);
    if (data) {
      console.log(data.data)
      setassignees(data.data);
    }
  };
  
  const [selectedConsultants, setSelectedConsultants] = useState([]);

  

  const handleClose = removedConsultant => {
    const remainingConsultants = selectedConsultants.filter(consultant => consultant.id !== removedConsultant.id);
    setSelectedConsultants(remainingConsultants);
  };
//   initialValues useState
   const [initialValues, setinitialValues] = useState(editingForm?{...department, supervisor_id: department.supervisor?.name,} : {})


   const handleKeyDown = (event) => {
    // Prevent form submission on Enter key press
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  return (
    <div className='bg-gray-100 p-2'>
      {contextHolder}
        {/* new department form ant design */}
        <Form
            name="departmentForm"
            initialValues={{...initialValues}}
            onFinish={onFinish}
           
            autoComplete="off"
            layout="vertical"
            form={form}
            
           
            className="mt-4"
            >
                 <div className="w-full bg-white rounded-md p-2 shadow mb-4">
              <Form.Item  onKeyDown={handleKeyDown}
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please type department name!' }]}
            >
                <Input />
            </Form.Item> 
            
             <Form.Item  onKeyDown={handleKeyDown}
                label="Supervisor"
                name="supervisor_id"
                rules={[{ required: true, message: 'Please type department supervisor!' }]}
            >
               
            {/* autocomplete */}
            <AutoComplete popupMatchSelectWidth={false}

                              style={{width: "100%", marginBottom: "20px"}}
                              onSearch={handleAssigneesearch}
                              onSelect={(value) =>{
                                // find user_id of selected assignee
                                const selectedAssignee = assignees.find(item => item.user_id === value)


                                // const selectedAssignee = assignees.find(item => item.name === value)
                                setselectedAssignee(selectedAssignee.user_id )

                                form.setFieldsValue({supervisor_id: selectedAssignee.name})
                              }}
                              placeholder="search for staff members">
                              
          {assignees.map(item => (<Option key={item.id} value={item.user_id}>{item.name}</Option>))}
        </AutoComplete>
       

        
        </Form.Item>
        </div>
        <div className="w-full bg-white rounded-md p-2 shadow mb-4">
              <Form.Item  onKeyDown={handleKeyDown}
                label=" Department Description"
                name="description"
                rules={[{ required: true, message: 'Please type department description!' }]}
            >
            <TextArea 
              showCount
              rows={6} maxLength={1000} />

            </Form.Item>
            </div>
         {!editingForm &&   <div className="w-full bg-white rounded-md p-2 shadow mb-4">
         <Form.Item  onKeyDown={handleKeyDown}
                label="Add users"
                name="users"
                rules={[{ required: false, message: 'Please type department supervisor!' }]}
            >
               
            {/* autocomplete */}
            <AutoComplete popupMatchSelectWidth={false}

                              style={{width: "100%", marginBottom: "20px"}}
                              onSearch={handleAssigneesearch}
                              onSelect={(value) =>{
                                // find user_id of selected assignee
                                const selectedAssignee = assignees.find(item => item.user_id === value)


                                // const selectedAssignee = assignees.find(item => item.name === value)
                                setSelectedConsultants([...selectedConsultants, selectedAssignee])

                                form.setFieldsValue({users:''})

                              }}
                              placeholder="search for staff members">
                              
          {assignees.map(item => (<Option key={item.id} value={item.user_id}>{item.name}</Option>))}
        </AutoComplete>
        </Form.Item>
         <Form.Item  onKeyDown={handleKeyDown}>
           <div className="flex flex-row w-full flex-wrap">
           {selectedConsultants.map(consultant => (
          <Tag
            key={consultant.id}
            closable
            onClose={() => handleClose(consultant)}
            className='flex flex-row items-center p-2 m-2'

          >
            <div className="flex flex-row items-center">
                <Avatar size="small" className="mr-2" src={consultant.avatar} />
                <span className='mr-2'>{consultant.name}</span>
            </div>
            
          </Tag>
        ))}
           </div>

        
      </Form.Item>
            
      </div> } 
          
        </Form>
    </div>
  )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(NewDepartment)