import {Avatar, Button, Modal, Table, Tag} from 'antd'
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Subtitle1} from '../../../components'
import {departments} from '../../../app/Reducers/initialState'
import {containedButton, dialogCloser, emptyTable, formSubmitButton, getSkels, horStack, kebabMenus, onTableChange, outlinedButton} from '../../../common/helpers'
import {deleteDepartment, fetchDepartments, resetDepartmentPagination, selectDepartment, updateDepartmentFilters} from '../../../app/Reducers'
import NewDepartment from './NewDepartment'
import KebabMenu from '../../../components/KebabMenu'
import TableFilters from '../../../components/InputComponents/TableFilters'
import {EditOutlined, FieldTimeOutlined, PlusOutlined} from '@ant-design/icons';
import AuditLogViewer from '../../../components/AuditLogs/AuditLogViewer';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useDepartmentSearch} from '../../../hooks/useDepartmentSearch';
import {useConsultantSearch} from '../../../hooks/useConsultantSearch';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useLocation, useNavigate} from 'react-router-dom';

export const Index = ({ appConf, departmentPagination, departments, activeUser }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate();
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_create_department = usePermissionCheck('create_department', false, [])
  const can_delete_department = usePermissionCheck('delete_department', false, [])
  const can_update_department = usePermissionCheck('update_department', false, [])
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [supervisorOptions, setSupervisorOptions] = useState([])
  const debouncedDepartmentSearch = useDepartmentSearch(setDepartmentOptions,[])
  const debouncedConsultantSearch = useConsultantSearch(setSupervisorOptions,[])
  let onCell = (record, rowIndex) => {
    return {
      onClick: async event => {
        await dispatch(selectDepartment(record))
        navigate('/departments/' + record.id)
      }
    }
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fltr: { filterable: true, type: 'select',options:departmentOptions,
        props: {
          showSearch: true,
          filterOption: false,
          onSearch: (value) => debouncedDepartmentSearch(value),
          defaultActiveFirstOption: false,
          showArrow: true,
          placeholder: 'Search...'
        } }, onCell
    },
    // userCount
    {
      title: 'Users',
      dataIndex: 'users_count',
      key: 'users',
      fltr: { filterable: true, type: 'number' }, onCell
      
    },
    // description
    
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      fltr: { filterable: false, type: 'string' }, onCell,
      ellipsis: true
      // render:(desc)=> <div style={{ width: '40%', textOverflow: 'ellipsis' }}>{desc}</div>
    },
    
    // suppervisor
    {
      title: 'Supervisor',
      dataIndex: 'supervisor',
      key: 'supervisor',
      fltr: { filterable: true, type: 'select',options:supervisorOptions,
        props: {
          showSearch: true,
          filterOption: false,
          onSearch: (value) => debouncedConsultantSearch(value),
          defaultActiveFirstOption: false,
          showArrow: true,
          placeholder: 'Search...'
        } },
      render: (supervisor, record) => (
        <div className="flex flex-row items-center">
          <Avatar className="mr-2" src={supervisor?.profile_image_url} alt={supervisor?.name}>{supervisor?.name?.charAt(0)}</Avatar>
          <Subtitle1 className="ml-2">{supervisor?.name}</Subtitle1>
        </div>
      ), onCell
    },
    ...(can_view_audit_logs ?
      [{
        title: '', dataIndex: '', key: 'x', render: (text, record, index) => <Button onClick={async () => {
          await setDepartment(record)
          await setShowAuditDialog(true)
          
        }} type="dashed" icon={<FieldTimeOutlined/>}>Audit</Button>
      }] : []),
    // actions
    {
      fltr: { filterable: false }, render: (record) => {
        const actionMenus = kebabMenus((can_update_department || record?.supervisor_id === activeUser?.id || record?.created_by === activeUser?.id), can_view_audit_logs, (can_delete_department || record?.supervisor_id === activeUser?.id || record?.created_by === activeUser?.id))
        return <KebabMenu menus={actionMenus}
                          recordItem={{ ...record, actionMenus }}
                          
                          handleMenuClick={kebabMenuClick} resource={'department'} handleOk={() => handleOk(record.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
                          showConfirmDialog={selected === record.id}/>;
      }
    },
    ...(activeUser?.is_qa_user?[{ width:15,
      visible: true,
      title: <FontAwesomeIcon color="grey" icon={['fas', 'table']}/>,
      key: 'operation',
      render: (record) => <Tag className={`${(record.is_qa && activeUser?.is_qa_user)?'visible':'invisible'}`} icon={<EditOutlined/>} color="#55acee">QA</Tag>
    }]:[])
  
  
  ]
  
  
  const handleOk = async (id) => {
    await dispatch(deleteDepartment({ id }))
    handleCancel()
    
  }
  
  const handleCancel = () => {
    setSelected(null)
  }
  
  // department
  const [department, setDepartment] = useState(null)
  const [selected, setSelected] = React.useState(null)
  // const actionMenus = kebabMenus(can_update_department, true, (can_delete_department || department?.supervisor?.id === activeUser?.id || department?.created_by === activeUser?.id))
  const [confirmLoading, setConfirmLoading] = React.useState(false)
  
  
  async function kebabMenuClick(e, record) {
    const { actionMenus, ...rest } = record
    await setDepartment(rest)
    // console.log('kebabMenuClick: ', e, rest,actionMenus)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true)
        await setNewDepartmentModal(true)
        break
      case 'Audit':
        await setDepartment(record)
        await setShowAuditDialog(true)
        break
      case 'Delete':
        setSelected(rest.id)
        break
      default:
    }
  }
  
  
  const addDepartment = () => {
    // navigate('/consultants/departments/add')
    setEditingForm(false)
    setNewDepartmentModal(true)
  }
  
  useEffect(() => {
    dispatch(fetchDepartments())
    
    return () => {
    
    }
  }, [])
  
  //new department modal usestate
  const [newDepartmentModal, setNewDepartmentModal] = useState(false)
  
  // is executing use state
  const [isExecuting, setIsExecuting] = useState(false)
// new department modal close
  const deparm = () => {
    setNewDepartmentModal(false)
  }
// is editmodal usestate
  const [editingForm, setEditingForm] = useState(false)
  
  // isVisible usestate
  const [isVisible, setIsVisible] = useState(false)
  
  // handlefilter action
  async function handleFilterAction(action, values) {
    // console.log('handleFilterAction: ',values)
    await dispatch(resetDepartmentPagination())
    if (action === 'filter') {
      console.log('FILTERS:  ', JSON.stringify({ ...values, toLoad: 'departments', endpoint: '/departments' }));
      let pl = { filterData: values, pagination: departmentPagination }
      await dispatch(updateDepartmentFilters([false, pl, true]))
      await dispatch(fetchDepartments(pl))
      await dispatch(updateDepartmentFilters([true, pl, false]))
    }
    if (action === 'reset'/* && appConf.appointmentFilters[0]*/) {
      await dispatch(updateDepartmentFilters([appConf.departmentFilters[0], undefined, true]))
      await dispatch(fetchDepartments())
      await dispatch(updateDepartmentFilters([false, undefined, false]))
    }
    // const response = await apiRequest.get(`/appointments`,{params: {...values,toLoad:'appointments'}})
    setIsVisible(false)
  }
  
  return (
    <div>
      {(showAuditDialog && !!department) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false} resourceDescription={'Department'}
                                                            resourceType={'Department'}
                                                            defaultValues={{ auditableId: department.id, auditable: 'department', format: 'json' }}/>}
      {/* new department modal */}
      <Modal
        title="Add Department"
        open={newDepartmentModal}
        onCancel={deparm}
        width={600}
        destroyOnClose={true}
        maskClosable={false}
        centered={true}
        className="new-department-modal"
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(dialogCloser(setNewDepartmentModal), 'Cancel', isExecuting),
            formSubmitButton('departmentForm', editingForm ? "Update" : 'Create', isExecuting, editingForm ? "Updating" : 'Creating')])}
        </div>]}
      >
        <NewDepartment editingForm={editingForm} department={department} setNewDepartmentModal={setNewDepartmentModal} setIsExecuting={setIsExecuting}/>
      </Modal>
      
      {/* department list */}
      <div className="w-full">
        <Table
          title={() => (<TableFilters
            setIsVisible={setIsVisible} isVisible={isVisible}
            datasource={[...columns]}
            handleFilterAction={handleFilterAction}
            filters={appConf.departmentFilters[1]}
            showClear={appConf.departmentFilters[0]}
            loading={appConf.departmentFilters[2]}
            actionButton={can_create_department ? containedButton(addDepartment, 'Add Department', false, null, {}, {
              icon: <PlusOutlined />
            }) : null}
          />)}
          
          locale={{
            emptyText: !appConf?.departmentFilters?.[0] ? emptyTable('departments') : emptyTable('departments')
          }}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchDepartments, appConf.departmentFilters, updateDepartmentFilters, {})}
          {...getSkels(appConf?.departmentFilters?.[2], columns, departments)} pagination={departmentPagination}/>
      </div>
    
    
    </div>
  )
}

const mapStateToProps = (state) => ({
  departments: state.departments.departments,
  appConf: state.appConf,
  departmentPagination: state.departments.pagination,
  activeUser: state.auth?.activeUser
  
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Index)