/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Avatar, Button, Col, Drawer, Dropdown, Empty, Input, Modal, Row, Select, Tabs} from 'antd';
// import { NewPatentCard } from './components/NewPatentCard';
import dayjs from 'dayjs';
import {fetchSchedule, fetchTimeBlockers, resetSchedule, resetSchedulePagination, setScheduleMode, updateScheduleFilters} from '../../../app/Reducers';
import SheduleAppointment from './ScheduleAppointment';
import BigCalendar from './BigCalendar';
import {Week} from 'react-big-calendar';
import {appLog, dialogCloser, emptyFun, formSubmitButton, horStack, modalClose, outlinedButton, secondaryButton} from "../../../common/helpers";
import SchedulesideBar from './SchedulesideBar';
import ScheduleHeader from './ScheduleHeader';
import Timeblocker from './Timeblocker';
import {Subtitle1} from '../../../components';
import Scheduler from "./Scheduler"
import _ from "lodash"
import {apiRequest} from '../../../app/Apis';
import ExportToCsvForm from './ExportToCsv';
import {CloudUploadOutlined} from '@ant-design/icons';
import ScheduleAppointmentList from './ScheduleAppointmentList';

export const Schedule = ({ appointments, appConf, mode, activeUser, facility }) => {
  const { Search } = Input;
  const { Option } = Select;
  const dispatch = useDispatch()
  const onSearch = value => console.log(value);
  const [selectedPeriod, setSelectedPeriod] = useState(dayjs().format("YYYY-MM-DD"))
  const [editingForm, setEditingForm] = useState(false);
  const [patientForm, setPatientForm] = useState(false)
  const [isExecuting, setisExecuting] = useState(false)
  const [isVisible, setIsVisible] = useState(false);
  const [isExportVisible, setIsExportVisible] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState();
  const [appointment, setAppointment] = useState(null);
  const [isOpen, setisOpen] = useState(true)
  const [listRange, setlistRange] = useState("day")
  const [scheduleRange, setScheduleRange] = useState(null)
  const [showTimeBlocker, setShowTimeBlocker] = useState(false)
  const [isNotified, setIsNotified] = useState(null)
  const [exportForm, setExportForm] = useState(false)
  
  
  useEffect( () => {
    (async ()=>{
      await dispatch(resetSchedulePagination())
      if (listRange === 'day') {
        await handleFilterAction('filter', { scheduledFor: selectedPeriod }).catch(e => console.log(e))
      }
    })()
    return emptyFun
  }, [listRange])
  
  
  useEffect(() => {
    if (activeUser?.facilityProfile && facility) {
      filterbyFacility()
    }
    return emptyFun
  }, [facility])
  
  useEffect(() => {
    let weekStart = dayjs(selectedPeriod).clone().startOf('isoWeek');
    let weekEnd = dayjs(selectedPeriod).clone().endOf('isoWeek');
    let monthStart = dayjs(selectedPeriod).startOf('month')
    let monthEnd = dayjs(selectedPeriod).endOf('month')
    
    if (mode === 'week' || mode === "month" || mode === "agenda") {
      onRangeChange(mode === 'week' || mode === "agenda" ? [weekStart, weekEnd] : [monthStart, monthEnd])
    }
    
    checkIfNotified()
    return emptyFun
  }, [])
  
  const onRangeChange = async (range) => {
    setScheduleRange([dayjs(range[0]).format("YYYY-MM-DD"), dayjs(range[1]).format("YYYY-MM-DD")])
    // handleFilterAction('filter', { 'scheduledForRange[start_at]': dayjs(range[0]).format("YYYY-MM-DD"), 'scheduledForRange[end_at]': dayjs(range[1]).format("YYYY-MM-DD") })
  }
  
  const checkIfNotified = async () => {
    const response = await apiRequest.get('/appointments/calendar/notifications/track');
    const data = response.data.data;
    const allNotified = data.all_stakeholders_notified;
    console.log('🚀 Notified:::', allNotified)
    if (!!!allNotified) {
      setIsNotified(false);
    } else {
      setIsNotified(true)
    }
  }
  
  useEffect(() => {
    checkIfNotified().catch(appLog)
   return  emptyFun
  }, [isNotified])
  
  const setMode = async (val) => {
    await dispatch(setScheduleMode(val))
  }
  const onRadioChange = e => {
    console.log('radio checked', e.target.value);
    setMode(e.target.value);
  };
  
  
  useEffect( () => {
    async function handleUpdates(){
      await dispatch(resetSchedule())
      if (mode === 'day') {
        setlistRange('day')
        await handleFilterAction('filter', { scheduledFor: selectedPeriod })
      }
      if (mode === 'list') {
        await dispatch(resetSchedulePagination())
        await handleFilterAction('filter', { scheduledFor: selectedPeriod })
      }
      if (mode === 'agenda') {
        await handleFilterAction('filter', { scheduledFor: selectedPeriod })
      }
    }
    handleUpdates().catch(appLog)
    return emptyFun
  }, [mode])
  
  
  useEffect(() => {
    (async () => {
      await handleFilterAction('filter', { scheduledFor: selectedPeriod })
    })()
    return emptyFun
  }, [selectedPeriod, scheduleRange])
  
  const handleCalendarFilters = async (action, values) => {
    if (action === 'filter') {
      // await setDefaultFilters(values)
      await handleFilterAction(action, values, true)
    } else {
      // await setDefaultFilters(undefined)
      await handleFilterAction(action)
    }
  }
  
  const filterbyFacility = async () => {
    let prev_filters = appConf.scheduleFilters[1]?.filterData
    let filter_data = {
      ...prev_filters,
      facility: [facility.name]
    }
    await updateScheduleFilters([false, { filterData: filter_data }, true])
    await handleFilterAction('filter', { facility: [facility.name] })
  }
  
  async function handleFilterAction(action, values, excludeExistingFilters) {
    //console.log({ values, scheduleRange })
    
    if (action === 'filter') {
      
      let { scheduledFor, pagination, ...rest } = values;
      
      let prev_filters = appConf.scheduleFilters[1]?.filterData
      
      
      let filter_data = activeUser?.facilityProfile && facility ? {
        ...(!excludeExistingFilters && prev_filters),
        ...rest,
        ...(!Object.keys(values).includes('scheduledForRange[end_at]') ? { scheduledFor: values.scheduledFor || selectedPeriod } : {}),
        facility: [facility?.name]
      } : {
        ...(!excludeExistingFilters && prev_filters),
        ...rest,
        ...(!Object.keys(values).includes('scheduledForRange[end_at]') ? { scheduledFor: values.scheduledFor || selectedPeriod } : {})
      }
      
      let weekStart = dayjs(selectedPeriod).clone().startOf('isoWeek');
      let weekEnd = dayjs(selectedPeriod).clone().endOf('isoWeek');
      let pl = {
        mode: mode,
        filterData: (mode === 'day' || (mode === 'list' && listRange === 'day')) ? _.omit({
          ...filter_data, scheduledFor: selectedPeriod
        }, ["scheduledForRange[end_at]", "scheduledForRange[start_at]"]) : _.omit({
          ...filter_data,
          'scheduledForRange[start_at]': dayjs(!!scheduleRange ? scheduleRange[0] : dayjs(weekStart).toDate()).format("YYYY-MM-DD"),
          'scheduledForRange[end_at]': dayjs(!!scheduleRange ? scheduleRange[1] : dayjs().add(7, 'days')).format("YYYY-MM-DD")
        }, 'scheduledFor'),
        pagination: { ...pagination }
      };
      
      
      await dispatch(updateScheduleFilters([false, {
        filterData: Object.keys(_.omit({ ...filter_data }, ["scheduledForRange[end_at]", "scheduledForRange[start_at]", "scheduledFor"])).length ?
          _.omit({ ...filter_data }, ["scheduledForRange[end_at]", "scheduledForRange[start_at]", "scheduledFor"]) : undefined
      },
        true]))
      if (showTimeBlocker) {
        await dispatch(fetchTimeBlockers(pl))
      } else {
        if (mode === 'list' || filter_data.wcc || filter_data.facility || filter_data.patientName) {
          await dispatch(fetchSchedule(pl))
        }
      }
      
      await dispatch(updateScheduleFilters([true, {
        filterData: Object.keys(_.omit({ ...filter_data }, ["scheduledForRange[end_at]", "scheduledForRange[start_at]", "scheduledFor"])).length ?
          _.omit({ ...filter_data }, ["scheduledForRange[end_at]", "scheduledForRange[start_at]", "scheduledFor"]) : undefined
      },
        false]))
    }
    
    if (action === 'reset') {
      console.log(" 🤯")
      await setSelectedPeriod(dayjs().format("YYYY-MM-DD"))
      await dispatch(updateScheduleFilters([appConf.scheduleFilters[0], activeUser?.facilityProfile && facility ? { facility: [facility.name] } : undefined, true]))
      if (showTimeBlocker) {
        await dispatch(resetSchedule())
        await dispatch(fetchTimeBlockers())
      } else {
        if (mode === 'list') {
          await dispatch(fetchSchedule(
            {
              "filterData": (mode === 'day' || (mode === 'list' && listRange === 'day')) ? (activeUser?.facilityProfile && facility ? {
                  scheduledFor: selectedPeriod,
                  facility: [facility.name]
                } : { scheduledFor: selectedPeriod }) :
                
                (activeUser?.facilityProfile && facility ? {
                  'scheduledForRange[start_at]': dayjs(scheduleRange[0]).format("YYYY-MM-DD"),
                  'scheduledForRange[end_at]': dayjs(scheduleRange[1] || scheduleRange.endDate).format("YYYY-MM-DD"),
                  facility: [facility.name]
                } : {
                  'scheduledForRange[start_at]': dayjs(scheduleRange[0]).format("YYYY-MM-DD"),
                  'scheduledForRange[end_at]': dayjs(scheduleRange[1] || scheduleRange.endDate).format("YYYY-MM-DD")
                })
            }))
        } else {
          await dispatch(resetSchedule())
        }
      }
      
      await dispatch(updateScheduleFilters([false, { "filterData": activeUser?.facilityProfile && facility ? { facility: [facility.name] } : undefined }, false]))
    }
    setIsVisible(false)
  }
  
  useEffect(() => {
    if (showTimeBlocker) {
      (async () => {
        await dispatch(resetSchedule())
        await handleFilterAction('filter', { scheduledFor: selectedPeriod })
      })()
    }
    
    
    return emptyFun
  }, [showTimeBlocker])
  
  
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  
  const [selectedTab, setselectedTab] = useState("1")
  const callback = (key) => {
    setselectedTab(key)
  }
  
  
  const Tabs_ = () => {
    return (
      <Tabs defaultActiveKey={selectedTab} onChange={callback}>
        <Tabs.TabPane tab={<Subtitle1>{editingForm ? 'Edit appointment' : "New Appointment"}</Subtitle1>} key="1">
        
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab={<Subtitle1>Add Time Blocker</Subtitle1>} key="2">

        </Tabs.TabPane> */}
      
      </Tabs>
    )
  }
  const rangeOptions = [{ label: 'Day', value: "day" }, { label: "Multi Day", value: 'day_range' }, { label: 'Week', value: 'week' },
    { label: "Multi week", value: 'week_range' }, { label: 'Month', value: "month" }, { label: "Multi Month", value: 'month_range' }]
  return (
    (<div className=" relative overflow-x-hidden">
      <Modal
        title={editingForm ? 'Edit appointment' : "New Appointment"}
        open={patientForm}
        destroyOnClose={true}
        onOk={dialogCloser(setPatientForm)}
        onCancel={dialogCloser(setPatientForm)}
        closeIcon={modalClose(dialogCloser(setPatientForm))}
        maskClosable={false}
        width={650}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(dialogCloser(setPatientForm), 'Cancel', isExecuting),
            formSubmitButton('appointmentForm', editingForm ? 'Update' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
        </div>]}>
        <SheduleAppointment handleFilterAction={handleFilterAction} setisExecuting={setisExecuting} closeModal={dialogCloser(setPatientForm)}/>
      </Modal>
      
      <div className=" overflow-x-hidden p-2" id="schedule-body" style={{ overflowY: "hidden", width: "100%" }}>
        <Row className="h-full">
          <Col className="ease-in" span={isOpen ? 6 : 0}>
            <SchedulesideBar setShowTimeBlocker={setShowTimeBlocker} showTimeBlocker={showTimeBlocker} setScheduleRange={setScheduleRange} defaultFilters={defaultFilters} listRange={listRange}
                             mode={mode} handleFilterAction={handleFilterAction} handleCalendarFilters={handleCalendarFilters} setSelectedPeriod={setSelectedPeriod}
                             selectedPeriod={selectedPeriod}/>
          </Col>
          
          <Col className="event-list relative" id="schedule-events" style={{ background: 'whitesmoke' }} span={isOpen ? 18 : 24}>
            <Drawer
              title={Tabs_()}
              placement="right"
              destroyOnClose
              closable={false}
              onClose={onClose}
              open={open}
              getContainer={"#schedule-body"}
              rootStyle={{ position: 'absolute' }}
              width={650}
            >
              <div rootClassName="flex flex-col text-left pb-4 pr-1" rootStyle={{ background: 'whitesmoke' }}>
                {selectedTab === "1" ? <>
                    <SheduleAppointment handleFilterAction={handleFilterAction} appointment={editingForm ? appointment : null} editingForm={editingForm} setisExecuting={setisExecuting}
                                        closeModal={dialogCloser(setOpen)}/>
                    <div rootClassName="p-2 m-2 rounded shadow-md bg-white">
                      <div rootClassName="entity-filters flex-boy-row" rootStyle={{ justifyContent: 'flex-end' }}>
                        {horStack([outlinedButton(() => onClose(), 'Cancel', isExecuting),
                          formSubmitButton('appointmentForm', editingForm ? 'Update' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
                      </div>
                    </div>
                  </> :
                  <>
                    <Timeblocker setisExecuting={setisExecuting} closeModal={dialogCloser(setOpen)}/>
                    <div rootClassName="p-2 m-2 rounded shadow-md bg-white">
                      <div rootClassName="entity-filters flex-boy-row" rootStyle={{ justifyContent: 'flex-end' }}>
                        {horStack([outlinedButton(() => onClose(), 'Cancel', isExecuting),
                          formSubmitButton('timeblockerForm', editingForm ? 'Update' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
                      </div>
                    </div>
                  </>}
              </div>
            </Drawer>
            <ScheduleHeader handleFilterAction={handleFilterAction} listRange={listRange} mode={mode} isOpen={isOpen} setisOpen={setisOpen} addnew={showDrawer}
                            setSelectedPeriod={setSelectedPeriod}
                            showtimeBlocker={showTimeBlocker}
                            selectedPeriod={selectedPeriod} setMode={setMode} isNotified={isNotified} checkIfNotified={checkIfNotified}/>
            {mode === "list" &&
              <div className="flex-boy-row-space" style={{ marginBottom: 4 }}>
                <div className="flex-boy-row" style={{ alignItems: 'center' }}>
                  <Subtitle1 className="mr-2">Date Range: </Subtitle1>
                  <Select
                    className="ml-2"
                    options={rangeOptions}
                    defaultValue={listRange}
                    style={{ width: 120 }}
                    onChange={setlistRange}/>
                </div>
                <Dropdown
                  dropdownRender={(menu) => <ExportToCsvForm setExportForm={setIsExportVisible}/>}
                  trigger={['click']}
                  overlayStyle={{ width: 300 }}
                  open={isExportVisible}
                >
                  {secondaryButton(() => setIsExportVisible((prev) => !prev), 'Export to CSV', false, null, { marginRight: 10 }, { icon: <CloudUploadOutlined /> })}
                </Dropdown>
              </div>
            }
            
            {mode !== 'list' && (appConf.scheduleFilters[1]?.filterData?.wcc || appConf.scheduleFilters[1]?.filterData?.facility || appConf.scheduleFilters[1]?.filterData?.patientName || showTimeBlocker) ? null : mode !== "list" && mode !== "scheduler" &&
              <p className="text-blue-500 mb-8 font-semibold text-base text-center">Please add a patient, provider or facility to the filters to populate appointments!</p>}
            
            {mode === "list" ? <ScheduleAppointmentList handleFilterAction={handleFilterAction}/> : mode === "scheduler" ?
              <Scheduler scheduleRange={scheduleRange} handleFilterAction={handleFilterAction} mode={mode} date={selectedPeriod} setSelectedPeriod={setSelectedPeriod} selectedPeriod={selectedPeriod}
                         setMode={setMode}/> : mode !== "agenda" &&
              <BigCalendar showtimeBlocker={showTimeBlocker} scheduleRange={scheduleRange} handleFilterAction={handleFilterAction} mode={mode} date={selectedPeriod}
                           setSelectedPeriod={setSelectedPeriod}
                           selectedPeriod={selectedPeriod}
                           setMode={setMode}/>}
            
            
            {mode === 'agenda' && (appConf.scheduleFilters[1]?.filterData?.wcc || appConf.scheduleFilters[1]?.filterData?.facility || appConf.scheduleFilters[1]?.filterData?.patientName) ?
              <BigCalendar scheduleRange={scheduleRange} handleFilterAction={handleFilterAction} mode={mode} date={selectedPeriod} setSelectedPeriod={setSelectedPeriod} selectedPeriod={selectedPeriod}
                           setMode={setMode}/>
              : mode === 'agenda' && <div className={'flexy-column'} style={{ justifyContent: "center" }}>
              <Empty image={require('../../../assets/empties/no_results.svg').default} imageStyle={{ height: '100%' }} description={""}/></div>}
          </Col>
        </Row>
      </div>
    </div>)
  );
}
const mapStateToProps = (state) => ({
  appointments: state.appointments.scheduledAppointments, appConf: state.appConf,  mode: state.appointments.scheduleMode,
  activeUser: state.auth?.activeUser,
  facility: state.facilities.selectedFacility
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Schedule)
