import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Select, Upload} from "antd";
import {emptyFun, GetOptions, inputRow} from "../../../common/helpers";
import {UploadOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import dayjs from "dayjs";
import GetCookie from '../../../hooks/GetCookie';

function DocForm({editingForm, doc, setFacesheetForm, setSubmitting, uploadName, uploadProps, updateDocument, createDocument}) {
  const uploadUrl = process.env.REACT_APP_ENDPOINT + '/files'
  const token = GetCookie('accessToken')
  const dateFormat = 'MM/DD/YYYY';
  const dispatch = useDispatch()
  const [faceForm] = Form.useForm();
  const [fileList, setFileList] = useState([])
  const [initialValues, setInitialValues] = useState(editingForm ? {...doc, ...(!!doc.expires_at ? {expires_at: dayjs(doc.expires_at)} : {expires_at: undefined})} : {});
  useEffect(() => {
    setInitialValues(editingForm ? {...doc, ...(!!doc.expires_at ? {expires_at: dayjs(doc.expires_at)} : {expires_at: undefined})} : {})
    return emptyFun
  }, [doc, editingForm])
  
  const onFacesheetChange = ({fileList: newFileList}) => {
    newFileList = newFileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
        faceForm.setFieldsValue({file_id: file.response.data.id})
      }
      return file;
    });
    setFileList(newFileList.map(file => file.response ? {
      uid: file.uid,
      status: file.status,
      url: file.response.data.url,
      thumbUrl: file.thumbUrl
    } : file))
    
  };
  
  const onFaceFinish = async (values) => {
    await setSubmitting(true)
    if (editingForm) {
      await updateDocument(doc.id, values)
    } else {
      await createDocument(values)
    }
    await setSubmitting(false)
    setFacesheetForm(false);
  }
  
  
  const disabledPastDates = (current) => {
    let customDate = dayjs();
    return current && current < dayjs(customDate, "YYYY-MM-DD");
  }
  return (
    <Form
      form={faceForm}
      name="faceSheetForm"
      id="faceSheetForm"
      labelCol={{span: 16}}
      layout="vertical"
      initialValues={{...initialValues}}
      onFinish={onFaceFinish}>
      <Form.Item
        onChange={() => faceForm.setFieldsValue({name: faceForm.getFieldValue('title')})}
        name="title"
        label="Document Title"
        rules={[{required: true, message: 'Missing Document title'}]}>
        <Input autoComplete={'off'} placeholder=""/>
      </Form.Item>
      <Form.Item
        style={{width: '100%', display: 'none'}}
        name="name"
        label="Document Name">
        <Input autoComplete={'off'} placeholder=""/>
      </Form.Item>
      {inputRow([
        <Form.Item
          style={{width: '100%'}}
          name="type"
          label="Document Type"
          rules={[{required: true, message: 'Missing Document type'}]}>
          <Select popupMatchSelectWidth={false}
            // onChange={handleChange}
            // defaultValue="elbow"
                  style={{width: '100%'}}>
            {GetOptions('document_type')}
          </Select>
        </Form.Item>
      
      ])}
      {!editingForm && <Form.Item
        style={{width: '100%'}}
        name="file_id"
        rules={[{required: true, message: 'Missing Document'}]}
        label="Documents">
        <Upload {...uploadProps} accept="image/*,.pdf"
                action={uploadUrl}
                headers={{'Authorization': `Bearer ${token}`}}
                defaultFileList={[...fileList]}
                onChange={onFacesheetChange}
                maxCount={1}>
          <Button icon={<UploadOutlined/>}>Upload</Button>
        </Upload>
        <Input autoComplete={'off'} style={{width: '100%', display: 'none'}}/>
      </Form.Item>
      }
    
    
    </Form>
  );
}

export default DocForm;
