




import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";


const firebaseConfig = {
    // ...
    
    apiKey: "AIzaSyCp-jeRMcl4TBKk2X4vk_eXlY2Z5nm7rTI",
    authDomain: "woundtrek-staging.firebaseapp.com",
    projectId: "woundtrek-staging",
    storageBucket: "woundtrek-staging.appspot.com",
    messagingSenderId: "1071080162191",
    appId: "1:1071080162191:web:2a532cb95e0eadd79ec1fa",
    measurementId: "G-R18WQNCLDE"

};


const app = initializeApp(firebaseConfig);



const messaging = getMessaging(app);

export const askForPermissionToReceiveNotifications = async () => {
    try {
        const messaging = getMessaging(app);
      await messaging.requestPermission();
      const token = await messaging.getToken();
      console.log('Your token is:', token);
      
      return token;
    } catch (error) {
      console.error(error);
    }
  }

  export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

export { messaging, onMessage, getToken };