
import { createClient, createMicrophoneAndCameraTracks } from "agora-rtc-react";
import { store } from '../../../../app/store'
export const appId = "17ca3fc090b549cca97fed8308c46642";
// export const token = "00617ca3fc090b549cca97fed8308c46642IABwZfN5iAlcZGGkMpLcrbvPZrptiVo9oCv89T4T508qCQe2r/QAAAAAEACDxJolaZe1YgEAAQBol7Vi"



export const config = { mode: "rtc", codec: "vp8", appId: appId };
export const useClient = createClient(config);
export const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

// import React from 'react'
// import { connect } from 'react-redux'

// export const Settings = ({token}) => {
//   return (
//     token
//   )
// }

// const mapStateToProps = (state) => ({})

// const mapDispatchToProps = {}

// export default connect(mapStateToProps, mapDispatchToProps)(Settings)
