/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Body1, RegularButton, Subtitle1 } from '../../components'
import { Button, Form, Input } from 'antd';
import { sendResetEmail } from '../../app/Reducers';
import { appLog } from "../../common/helpers";
import {useLocation, useNavigate} from 'react-router-dom';

export const PasswordReset = (props) => {
    const location = useLocation()
      const navigate = useNavigate();
  const dispatch = useDispatch()
  const onFinish = async (values) => {
    await dispatch(sendResetEmail(values))
    navigate("/auth/login")
    setSent(false)
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const [sent, setSent] = useState(false)
  return (<div className="login-div">
    {!sent ? <Body1 color="black">
      Please enter your email address and we'll send you instructions on how to reset your password.
    </Body1> : null}
    {!sent ? <Form
      name="Agency"
      layout="vertical"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Email "
        name="email"
        rules={[{ required: true, message: 'Please type email' }]}
      >
        <Input autoComplete={'off'} placeholder="Email@example.com" />
      </Form.Item>

      <Button htmlType="submit" className="login-form-button mb-4">
        Send
      </Button>
    </Form> : <div className="sent-message">
      <img src={require('../../assets/letter1.svg').default} alt="letter" />
      <Body1 color="black" style={{ textAlign: "center", width: "200px" }}>
        We sent instructions to
        name@example.com
        please, check your mail.
      </Body1>
      <RegularButton style={{ width: "100%" }} onClick={() => navigate("/auth/login")}>
        Back to Login
        </RegularButton>
        
       
    </div>}
    {!sent ? <Subtitle1 style={{ cursor: "pointer" }} onClick={() => navigate("/auth/login")}>&lt; &#32;  Back to
      Login </Subtitle1> : null}
    <p className='mt-2 italic text-left text-sm text-gray-600' color="grey" style={{  width: "100%" }}>
      If you encounter any difficulties accessing your account, we kindly request that you reach out to our dedicated help desk support team for assistance. Please feel free to send an email to <a>helpdesksupport@thewoundpros.com</a>, or call  <a>(844) 336-9295 </a>(Available from 5:00 AM - 5:00 PM Pacific Time) and and our staff will promptly address your concerns and provide the necessary guidance to resolve the issue.
    </p>
  </div>)
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)
