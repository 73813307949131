import React from "react";
import {Card, Divider, Typography} from "antd";
import {kebabMenus, boldKeyCapitalizedValue, boldKeyCapitalizedValueGrayBackground} from '../../../../common/helpers';
import KebabMenu from '../../../../components/KebabMenu';

const { Title, Paragraph, Text } = Typography;

const PermissionCard = ({ permissions, removePermission, openPermDynamicForm }) => {
  
  const actionMenus = kebabMenus(true, false, true)
  console.log({ permissions })
  
  async function kebabMenuClick(e, permissions) {
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await openPermDynamicForm(permissions);
        break
      case 'Delete':
        removePermission(permissions)
        break
      default:
    }
  }
  
  
  return (
    <Card>
      <div className="flex-boy-row-space" style={{ width: '100%' }}>
        <Card.Meta style={{ width: '100%' }}
                   title={
                     <div className={"flexy-row"} style={{ width: '100%' }}>
                       <Text style={{ width: '80%' }} ellipsis={true}>
                         {`Resource: ${permissions.resource_name==='*' ? 'All' :
                           permissions.resource_name.replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}`}
                       </Text>
                       <KebabMenu
                         menus={actionMenus}
                         recordItem={permissions}
                         handleMenuClick={kebabMenuClick}
                         resource={'permissions'}
                         showConfirmDialog={false}
                       />
                     </div>}
                   description={
                     <div>
                       <Divider/>
                       {Object.keys(permissions).filter(key => key !== 'resource_name').map((key, index) => {
                         return index % 2 === 0 ? boldKeyCapitalizedValue(`${key.replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}:`, `${permissions[key].includes('*') ? 'All' :   permissions[key].join(', ')}`) :
                           boldKeyCapitalizedValueGrayBackground(`${key.replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}:`, `${permissions[key].includes('*') ? 'All' :  permissions[key].join(', ')}`);
                       })}
                     </div>
                   }
        />
      
      </div>
    </Card>
  );
};
export default PermissionCard;
