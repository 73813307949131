import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, DatePicker, Form, Input, Select, TimePicker } from 'antd';
import { fetchAppointments, fetchConsultants, fetchPatientAppointments, fetchProcedures, fetchSpecificAppointment } from '../../../app/Reducers'
import dayjs from 'dayjs'
import { apiRequest } from '../../../app/Apis';
import { toast } from 'react-toastify';
import { emptyFun } from "../../../common/helpers";

const NextVisit = ({ patients, patient, activeUser, procedures, handlepatientCancel, appointment, closeNextVisit, setisExecuting }) => {
    const { Option } = Select;
    const dateFormat = 'YYYY-MM-DD';
    // treatment protocols stuff
    const [consultant, setConsultant] = useState(null)
    const [consultantOptions, setconsultantOptions] = useState([])
    const [woundLocations, setwoundLocations] = useState([])
    const [woundProcedures, setWoundProcedures] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        //console.log({activeUser, currentUser})

        fetchwoundLocations()
        fetchwoundProcedures()
        // dispatch(fetchPatients())
        dispatch(fetchConsultants())
        dispatch(fetchProcedures())
        return emptyFun
    }, [])

    const fetchwoundLocations = async () => {
        // const response = await apiRequest.get('/wound-locations').catch(function (err) {
        //   console.log(err)
        // })
        // // console.log(response.data.data)
        // if (response && response.data && response.data.data) {

        //   setwoundLocations(response.data.data.map(item => `${item.name}`))
        // }
        setwoundLocations(appointment.wounds.map(item => ` ${item?.side || ''} ${item?.position || ""} ${item?.location || ''}`))

    }
    const fetchwoundProcedures = async () => {
        const response = await apiRequest.get('/procedures').catch(function (err) {
            console.log(err)
        })
        if (response && response.data && response.data.data) {
            setWoundProcedures(response.data.data)
        }
    }

    const handleConsultantssearch = async (value) => {
        const { data } = await apiRequest.get(`/consultants?search=${value}`)

        // setOptions(data);
        if (data) {
            console.log(data.data)
            setconsultantOptions(data.data);
        }

    };
    const onconsultantSelect = (value) => {
        console.log('onSelect', value);
        let wcc = consultantOptions.find(item => item.name === value)

        setConsultant(wcc)
    };


    const disabledPastDates = (current) => {
        let customDate = dayjs(appointment.start_at).startOf('day').add(1, 'd');
        return current < dayjs(customDate, dateFormat);
    }
    const onFinish = async (values) => {
        // console.log(values);
        // setisExecuting(true)
        let data = { ...values }
        console.log(values)
        let _wounds = values.wounds.map(item => item.split('-')[0])
        console.log(values, _wounds, appointment.wounds)
        let wounds = [...appointment.wounds].slice().filter(item => _wounds.includes(` ${item?.side || ''} ${item?.position || ""} ${item?.location || ''}`))
        console.log(wounds)
        data.patient_id = appointment.patient.id
        data.consultant_id = appointment.consultant.id
        data.wounds = wounds.map(item => {
            console.log(item)
            return { ...item, recommended_procedure: [...item.recommended_procedure.filter(proc => !proc.includes('Biologics'))] }
        })
        data.start_at = `${dayjs(values.date).format("YYYY-MM-DD")} ${dayjs(data.start_at).format("HH:mm:ss")}`
        data.end_at = `${dayjs(values.date).format("YYYY-MM-DD")} ${dayjs(data.end_at).format("HH:mm:ss")}`
        data.telehealth_appointment = data.telehealth_appointment ? data.telehealth_appointment : false
        data.appointment_visit_type = "established visit"

        console.log(data)
        const toastId = toast("Creating Appointment", { type: "info", autoClose: false });
        const response = await apiRequest.post('/appointments', data).catch(function (err) {


            console.log(err.response.data)
            setisExecuting(false)
            toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
        })
        if (response && response.data.data) {
            if (data.telehealth_appointment && response?.data.data) {
                const callLink = await apiRequest.post('/agora/call-link', {
                    appointment_id: response.data.data.id,
                    title: `${response?.data?.data?.consultant?.name}-${response?.data?.data?.patient?.name}-meeting`
                });
                response.data.data.call_link = callLink.data.data.host;
            }

            toast.update(toastId, { render: "Appointment created", type: toast.TYPE.SUCCESS, autoClose: 5000 });
            dispatch(fetchSpecificAppointment(appointment?.id))
            dispatch(fetchPatientAppointments(appointment.patient.id))
            dispatch(fetchAppointments())
            setisExecuting(false)
            closeNextVisit()

        } else {
            setisExecuting(false)
        }
        return response.data


    };
    const [form] = Form.useForm();

// initial values
const [initialValues, setInitialValues] = useState({ wounds: appointment.wounds.map((item, index) => ` ${item?.side || ''} ${item?.position || ""} ${item?.location || ''}-${index}`)})
    return (
        <Form
            name="new-patient"
            id="appointmentForm"
            labelCol={{
                span: 16
            }}
            layout="vertical"
            initialValues={{
                ...initialValues
            }}
            onFinish={onFinish}
            form={form}
            onValuesChange={(changedValues, allValues) => {
                // console.log('changedValues:', changedValues)
                console.log('allValues:', allValues)
                setInitialValues(allValues)
            }}
        >


            {/* <Form.Item name="telehealth_appointment"
                valuePropName="checked" style={{ width: '100%' }} label="">
                <Checkbox>Telehealth Appointment</Checkbox>
            </Form.Item> */}
            <div className="flex">
                    <Form.Item
                        label="Date"
                        style={{ width: '35%' }}
                        name="date"
                        rules={[{ required: true, message: 'this field is required!' }]}>
                        <DatePicker format={dateFormat} style={{ width: '100%' }} />
                    </Form.Item>
                    <div className="flex justify-between items-start w-[65%]">
                        <Form.Item
                            label="Start Time"
                            style={{ width: '50%' }}
                            name="start_at"
                            onChange={(e) => { console.log('Start Time:', e) }}
                            rules={[{ required: true, message: 'Start time is required!' }]}
                        >
                            <TimePicker
                                defaultOpenValue={dayjs('12:00 am', 'hh:mm a')}
                               format={'HH:mm'}
                                style={{ width: '100%' }}
                                minuteStep={15}
                                onBlur={(e) => console.log('onBlur Start Time:', e)}
                            />
                        </Form.Item>

                        <Form.Item
                            label="End Time"
                            style={{ width: '50%' }}
                            name="end_at"
                            onChange={(e) => { console.log('End Time:', e) }}
                            rules={[
                                { required: true, message: 'End time is required!' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || !getFieldValue('start_at')) {
                                            return Promise.resolve();
                                        }
                                        if (value.isSameOrAfter(getFieldValue('start_at'))) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('End time cannot be earlier than start time!'));
                                    },
                                }),
                            ]}
                        >
                            <TimePicker
                                defaultOpenValue={dayjs('12:00 am', 'HH:mm a')}
                         format={'HH:mm'}
                                style={{ width: '100%' }}
                                minuteStep={15}
                                onBlur={(e) => console.log('onBlur End Time:', e)}
                                disabledHours={() => {
                                    const start = form.getFieldValue('start_at');
                                    if (start) {
                                        return Array.from({ length: start.hour() }, (_, i) => i);
                                    }
                                    return [];
                                }}
                                disabledMinutes={(selectedHour) => {
                                    const start = form.getFieldValue('start_at');
                                    if (start && selectedHour === start.hour()) {
                                        return Array.from({ length: Math.floor(start.minute() / 15) }, (_, i) => i * 15);
                                    }
                                    return [];
                                }}
                               
                                disabled={!form.getFieldValue('start_at')}
                            />
                        </Form.Item>
                    </div>

                    </div>
            <Form.Item
                name="wounds"

                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: 'Missing Wound location' }]}


                label="Wound Location"
            >
                <Select
                    mode="multiple"
                    style={{

                        borderRadius: "4px"
                    }}>
                    {woundLocations.map((item, index) => <Option key={index} value={` ${item?.side || ''} ${item?.position || ""} ${item?.location || ''}-${index}`}>{item}</Option>)}

                </Select>
            </Form.Item>


            <Form.Item
                label="Reason"
                name="visit_reason"
                rules={[{ required: true, message: 'this field is required!' }]}
                wrapperCol={{ span: 24 }}
            >
                <Input autoComplete={'off'} />
            </Form.Item>
        </Form>
    )
}

const mapStateToProps = (state) => ({
    appointment: state.appointments.selectedAppointment,
    patients: state.patients.patients,
    consultants: state.consultants.consultants,
    procedures: state.appointments.procedures,
    activeUser: state.auth?.activeUser
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(NextVisit)
