import algoliasearch from 'algoliasearch/lite';

export const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_APP_SECRET);
export const patientsSearchIndex = process.env.REACT_APP_ALGOLIA_PATIENTS_INDEX;
export const consultantsSearchIndex = process.env.REACT_APP_ALGOLIA_CONSULTANTS_INDEX;
export const facilitiesSearchIndex = process.env.REACT_APP_ALGOLIA_FACILITES_INDEX;
export const mapboxToken = "pk.eyJ1Ijoid3AtbWFwYm94IiwiYSI6ImNrbnlocmdhdDFnZHEyb3FuNW5wNGVqNDYifQ.8xpbLk2MELO03OLBaxxOAg"
export const woundLocations = ['Lateral Foot', 'Medial Foot', 'Plantar Foot', 'Lateral Ankle', 'Medial Ankle',
  'Anterior Shin', 'Anterior Leg', 'Posterior Leg', 'Lateral Leg', 'Medial Leg', 'Trochanter', 'Right Buttocks',
  'Sacrum', 'Coccyx', 'Sacrococcyx', 'Lower back', 'Upper back', 'Arm', 'Elbow', 'Shoulder', 'Scalp', 'Mid Upper Back',
  'Left Upper Back', 'Other', 'Left Buttocks', 'Left Heel', 'Right Ischium', 'Left Ischium', 'Left Lower Leg',
  'Right Lower Leg', 'Right Foot', 'Left Foot', 'Left Hip', 'Right Hip', 'Back', 'Left Plantar Foot',
  'Right Plantar Foot', 'Sacrococcyx Extending to the Bilateral']
export const status_colors = {
  scheduled: '#0088FE', done: '#00fea9', completed: '#00fea9', missed: '#EF8100;', cancelled: '#FFA500',
  cancelled_by_wcc: '#FFA500', cancelled_by_patient: '#FFA500'
}