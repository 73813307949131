/* eslint-disable no-unused-vars */
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {errMsg, toastUp} from "./reducerUtils";
import {chat} from "./initialState";
import SDK from '../../SDK';

const initialState = { ...chat }


export const getChatMessages = createAsyncThunk('/get_chatMessages', async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
        try {
            let state = thunkAPI.getState()
            let conversation = state.chat.selectedChat
            const userJid = SDK.getJid(conversation.fromUserId)
            const messages = await SDK.getChatMessages(userJid.userJid);
            if (messages && messages.statusCode === 200) {
                resolve(messages.data)
            } else {
                //console.log("slight issue")
            }

        } catch (e) {
            //console.log(e)
            toastUp(errMsg(e, false), false)
            reject(e)
        }
    })
})

export const getChats = createAsyncThunk('/get_chats', async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
        try {
            let state = thunkAPI.getState()
            let userName = state.chat.userName

            const userJid = SDK.getJid(userName)
            const recentChatsRes = await SDK.getRecentChats(userJid.userJid);
            if (recentChatsRes && recentChatsRes.statusCode === 200) {
                //console.log("recent", recentChatsRes.data)
                resolve([...recentChatsRes.data])
            }

        } catch (e) {
            //console.log(e)
            toastUp(errMsg(e, false), false)
            reject(e)
        }
    })
})

export const makeCall = createAsyncThunk('/make_call', async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
        try {
            let state = thunkAPI.getState()
            let userName = state.chat.userName

            const jids = payload.jids.map((id) => SDK.getJid(id).userJid)
            //console.log(jids)
            const callRes = await SDK.makeVideoCall([...jids]);
            if (callRes && callRes.statusCode === 200) {
                //console.log("recent", callRes.data)
                resolve(callRes.data)
            }

        } catch (e) {
            //console.log(e)
            toastUp(errMsg(e, false), false)
            reject(e)
        }
    })
})
export const rejectCall = createAsyncThunk('/decline_call', async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
        try {

            const callRes = await SDK.declineCall();
            if (callRes && callRes.statusCode === 200) {
                //console.log("recent", callRes)
                resolve(callRes)
            }

        } catch (e) {
            //console.log(e)
            toastUp(errMsg(e, false), false)
            reject(e)
        }
    })
})
export const acceptCall = createAsyncThunk('/accept_call', async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
        try {
            const callRes = await SDK.answerCall();
            if (callRes && callRes.statusCode === 200) {
                //console.log("recent", callRes.data)
                resolve(callRes.data)
            }

        } catch (e) {
            //console.log(e)
            toastUp(errMsg(e, false), false)
            reject(e)
        }
    })
})


export const ChatSlice = createSlice({
    name: 'chat', initialState, // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        selectChat: (state, action) => {
            state.selectedChat = action.payload;
        },
        selectIncomingCall: (state, action) => {
            state.incomingcall = action.payload
        },
        setVideoIsOn: (state, action) => {
            state.videoIsOn = action.payload
        },
        setChannelName: (state, action) => {
            state.channelName = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setConnectionState: (state, action) => {
            state.isConnected = action.payload
        },
        setIncomingCall: (state, action) => {
            state.incomingCall = action.payload
        },
        setUserCredentials: (state, action) => {
            state.userName = action.payload.username
            state.password = action.payload.password
        },
        doResetChatSlice: (state, action) => {
            Object.keys(initialState).map((key) => (state[key] = initialState[key]));
        }

    }, extraReducers: (builder) => {
        builder.addCase(getChatMessages.fulfilled, (state, action) => {
            state.singleChatMessages = [...action.payload]
        }).addCase(getChats.fulfilled, (state, action) => {
            state.chatList = [...action.payload]
        })

    }
});
export const {
    selectChat, setIncomingCall, setVideoIsOn, setChannelName, setToken, setUserCredentials, setConnectionState, doResetChatSlice
} = ChatSlice.actions
export default ChatSlice.reducer
