import React, {useEffect, useState} from 'react';
import {Headline6} from "../../../components";
import {dialogCloser, emptyFun, executeChunkFn, getPhoneParams, kebabMenus, boldKeyCapitalizedValue, boldKeyCapitalizedValueGrayBackground, makeSelectOptions, skelBars} from "../../../common/helpers";
import {connect, useDispatch} from "react-redux";

import {fetchPatientById, setPatientDetailsLoading, updatePatientDetails, updatePatientDetailsOwner} from "../../../app/Reducers";
import KebabMenu from "../../../components/KebabMenu";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import * as Sentry from "@sentry/react";
import {errMsg, toastUp} from "../../../app/Reducers/reducerUtils";
import DynamicModalForm from "../../../components/DynamicModalForm";
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';

let diabeticTypeRule = [
  (formInstance) => ({
    validator(rule, value, callback) {
      const diabetic = formInstance.getFieldValue('diabetic');
      if (!value && diabetic === 'No') {
        callback()
      } else if (diabetic === 'Yes' && !!value) {
        callback()
      } else {
        callback('Field is required based on value of diabetic')
      }
    }
  })
]
const PatientAdditionalInformation = ({ navs, patient, activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState(undefined);
  const [phoneValue, setPhoneValue] = useState(!!patient?.additional_contacts ?
    {
      ...getPhoneParams(patient.additional_contacts['primary_family_member_phone'], 'primary_family_member_phone'),
      ...getPhoneParams(patient.additional_contacts['case_manager_phone'], 'case_manager_phone')
    } : undefined);
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [inputFields, setInputFields] = useState(undefined);
  const [isExecuting, setisExecuting] = useState(false)
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  const { message } = useSubscription(`view_patient/${patientId}`, { qos: 2, nl: true });
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])

  let additionalInfoFormInputs = {
    fields: [
      { name: 'primary_family_member_name', label: "Family Member Name", required: true, type: 'text' },
      {
        name: 'primary_family_member_phone', label: 'Family Member Phone number', required: true, type: 'phone',
        props: { formItemProps: { style: { width: '100%' }, required: false, label: 'Family Member Phone number' } }
      },
      { name: 'case_manager_name', label: "Case Manager Name", required: true, type: 'text' },
      {
        name: 'case_manager_phone', label: 'Case manager number', required: true, type: 'phone',
        props: { formItemProps: { style: { width: '100%' }, required: false, label: 'Case manager number' } }
      },
      { name: 'diabetic', label: 'Diabetic', required: true, type: 'select', options: makeSelectOptions(['Yes', 'No']) },
      //fiprops:  Means FormItem props
      {
        name: 'diabetic_type', label: 'Diabetic type', required: true, type: 'select', options: makeSelectOptions(["Type 1", "Type 2"]),
        fiprops: { rules: diabeticTypeRule }, props: { allowClear: true }
      },
      { label: "Has patient been hospitalized in the past 100 Days?", name: 'hospitalize', required: true, type: 'select', options: makeSelectOptions(['yes', 'no']) },
      {
        label: "Where do you want to receive the Medical Records?",
        name: 'medical_record_access_option', required: true, type: 'select', options: makeSelectOptions(['email', 'fax', 'email and fax'])
      },
      { label: 'Attorney name:', name: 'power_of_attorney_name', required: patient?.consent!=='self', type: 'text' },
      {
        name: 'power_of_attorney_contact_number', label: 'Attorney\'s phone:', required: true, type: 'phone',
        props: { formItemProps: { style: { width: '100%' }, required: false, label: 'Attorney\'s phone:' } }
      }
    ]
  }
  //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
  let dynamicFormInputFields = {
    'Edit Additional information': additionalInfoFormInputs
  }
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await loadAdditionalContacts(patientId, true)
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    (async () => {
      //console.log({patient})
      if (!patient || (patientId !== patient.id)) {
        await loadAdditionalContacts(patientId)
      }
    })().catch(e => console.log(e))
    return emptyFun
  }, [])
  const loadAdditionalContacts = async (patientId, isMQMessage) => {
    await dispatch(setPatientDetailsLoading(!isMQMessage))
    await dispatch(fetchPatientById(Number(patientId)))
    await dispatch(setPatientDetailsLoading(false))
    await dispatch(updatePatientDetailsOwner(patientId))
  }
  const actionMenus = kebabMenus((can_update_patient_resource && !patient.deleted_at), can_view_audit_logs, false)
  
  async function kebabMenuClick(e) {
    console.log(actionMenus[e.key].title)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        let initialValues = patient ? {
          patient_id: patient.id,
          diabetic: patient?.diabetic,
          diabetic_type: patient?.diabetic_type,
          hospitalize: patient?.hospitalize,
          medical_record_access_option: patient?.medical_record_access_option,
          power_of_attorney_name: patient?.power_of_attorney_name,
          ...(!!patient?.additional_contacts && {
            ...patient.additional_contacts,
            ...getPhoneParams(patient?.additional_contacts['primary_family_member_phone'], 'primary_family_member_phone'),
            ...getPhoneParams(patient?.additional_contacts['case_manager_phone'], 'case_manager_phone'),
            ...getPhoneParams(patient?.power_of_attorney_contact_number, 'power_of_attorney_contact_number')
          })
        } : undefined;
        await openAdditionalInfoDynamicForm(initialValues)
        break
      case 'Delete':
        // showPopconfirm(address)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
    }
  }
  
  const openAdditionalInfoDynamicForm = async (record) => {
    await setFormName(!!record ? 'Edit Additional information' : 'N/A');
    await setDefaultDynamicModalFormValues(!!record ? { ...record, patient_id: patient.id } : undefined);
    await setDynamicFormOpen(true);
  };
  /**
   * This function is handling returned form entries from the dynamic form
   * */
  const onDynamicFormEntriesResult = async (entries) => {
    // console.log('onDynamicFormEntriesResult: ', entries.values)
    // console.log('phoneValue: ', phoneValue)
    try {
      switch (formName) {
        case 'Edit Additional information':
          await executeChunkFn(dispatch, updatePatientDetails, { ...entries.values }, setisExecuting, dialogCloser(setDynamicFormOpen), null)
          break;
        /*case 'Edit biologic kit':
          await executeChunkFn(dispatch, editBiologicKit, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen), null)
          break;
        case 'Add biologic product':
          await executeChunkFn(dispatch, createBiologicProduct, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen))
          break;
        case 'Edit biologic product':
          await executeChunkFn(dispatch, editBiologicProduct, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen))
          break;*/
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      toastUp(errMsg(e, false, formName), false);
    }
  };
  return (
    <div className="card-box">
      {(isDynamicFormOpen && !!formName) && (
        <DynamicModalForm
          setDynamicFormOpen={setDynamicFormOpen}
          isDynamicFormOpen={isDynamicFormOpen}
          inputFields={dynamicFormInputFields[formName]}
          onDynamicFormEntriesResult={onDynamicFormEntriesResult}
          closeModal={dialogCloser(setDynamicFormOpen)}
          isExecuting={isExecuting} phoneValue={phoneValue} setPhoneValue={setPhoneValue}
          defaultValues={defaultDynamicModalFormValues}
          formName={formName}
          useUpdateTerminology={['Update', 'Updating']}
        />
      )}
      {showAuditDialog &&
        <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false} resourceDescription={`${patient.name}'s additional information`}
                        resourceType={'Patient'}
                        defaultValues={{ auditableId: patientId, auditable: 'patient_additional_contacts', format: 'json' }}/>}
      <div className="flex-boy-row-space" style={{ width: "100%" }}>
        <Headline6>Additional Information</Headline6>
        {/*{(can_update_patient_resource && !patient.deleted_at) &&
        <IconButton type={"button"} size={"small"} onClick={() => setContactsForm(true)}> <EditOutlined fontSize={"small"}/>
        </IconButton>
        
        }*/}
        <KebabMenu menus={actionMenus}
                   recordItem={null}
                   handleMenuClick={kebabMenuClick} resource={'contact'} handleOk={null} handleCancel={null} confirmLoading={null}
                   showConfirmDialog={false}/>
      </div>
      
      {navs.patientDetailsLoading ? skelBars() : <div style={{ width: "100%" }}>
        {boldKeyCapitalizedValue('Case manager:', patient?.additional_contacts?.case_manager_name || 'N/A')}
        {boldKeyCapitalizedValueGrayBackground('Case manager\'s phone:', patient?.additional_contacts?.case_manager_phone || 'N/A')}
        {/*{boldKeyCapitalizedValue('Office:', patient?.additional_contacts?.facility_name || 'N/A')}*/}
        {boldKeyCapitalizedValue('Family member:', patient?.additional_contacts?.primary_family_member_name || 'N/A')}
        {boldKeyCapitalizedValueGrayBackground('Family contact:', patient?.additional_contacts?.primary_family_member_phone || 'N/A')}
        {boldKeyCapitalizedValue('Diabetic:', patient?.diabetic || 'N/A')}
        {boldKeyCapitalizedValueGrayBackground('Diabetic type:', patient?.diabetic_type || 'N/A')}
        {boldKeyCapitalizedValue('Hospitalized in the past 100 Days:', patient?.hospitalize?.replace('-', '')?.replace(/_/g, ' ')?.replace(/^\w/, c => c.toUpperCase()) || 'N/A')}
        {boldKeyCapitalizedValueGrayBackground('Medical Records sent via:', patient?.medical_record_access_option?.replace('-', '')?.replace(/_/g, ' ')?.replace(/^\w/, c => c.toUpperCase()) || 'N/A')}
        {boldKeyCapitalizedValue('Attorney name:', patient?.power_of_attorney_name || 'N/A')}
        {boldKeyCapitalizedValueGrayBackground('Attorney\'s phone:', patient?.power_of_attorney_contact_number || 'N/A')}
      </div>}
    </div>
  );
};
const mapStateToProps = (state) => ({ activeUser: state.auth?.activeUser, patient: state.patients.selectedPatient, navs: state.navs })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PatientAdditionalInformation)
