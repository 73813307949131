import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, DatePicker, Divider, Form, Radio, TimePicker } from 'antd'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { apiRequest } from '../../../app/Apis'
import { fetchConsultantTimes } from '../../../app/Reducers'
import { appLog } from "../../../common/helpers";

export const AddAvailability = ({ type, setIsAvailabilityModalVisible, consultant, setisExecuting }) => {
  const [availability, setAvailability] = useState([{
    date: dayjs(), time_from: dayjs(), time_to: dayjs().endOf("hour")
  }])
  const dispatch = useDispatch()
  const [oneDayAllCheck, setoneDayAllCheck] = useState(false)
  const [timeFromAllCheck, settimeFromAllCheck] = useState(false)
  const [timetoAllCheck, settimeToAllCheck] = useState(false)
  const [timeFrame, setTimeframe] = useState("one day")
  const onRadioChange = e => {
    console.log('radio checked', e.target.value);
    setTimeframe(e.target.value);
  };
  const oncheckChange = (type, value) => {
    if (type === "one_day") {
      setoneDayAllCheck(value.target.checked)
    } else if (type === "time_from") {
      settimeFromAllCheck(value.target.checked)
    } else if (type === "time_to") {
      settimeToAllCheck(value.target.checked)
    }
  }
  const onFinish = async (values) => {
    await setisExecuting(true)
    console.log(values)
    if (timeFrame === "one day") {
      let data = {
        start_at: `${dayjs(values.date).format("YYYY-MM-DD")} ${values.time_from && !oneDayAllCheck ? dayjs(values.time_from).format("HH:mm:ss") : dayjs().startOf('day').format("HH:mm:ss")}`,
        end_at: `${dayjs(values.date).format("YYYY-MM-DD")} ${values.time_to && !oneDayAllCheck ? dayjs(values.time_to).format("HH:mm:ss") : dayjs().endOf('day').format("HH:mm:ss")}`,
        is_available: type === "Availability" ? true : false
      }
      console.log(data)
      const toastId = toast("Updating Availability", { type: "info", autoClose: false });
      const response = await apiRequest.post(`/consultant-availability-datetimes`, data).catch(async function (err) {
        await setisExecuting(false)
        console.log(err)
        toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
      })
      if (response && response.data) {
        toast.update(toastId, { render: "Availability updated", type: toast.TYPE.SUCCESS, autoClose: 5000 });
        await dispatch(fetchConsultantTimes(consultant))
        await setisExecuting(false)
        setIsAvailabilityModalVisible(false)
      }
    } else {
      let data = {
        start_at: `${dayjs(values.date_from).format('YYYY-MM-DD')} ${values.time_from && !timeFromAllCheck ? dayjs(values.time_from).format("HH:mm:ss") : dayjs().startOf('day').format("HH:mm:ss")}`,
        end_at: `${dayjs(values.date_).format('YYYY-MM-DD')} ${values.time_to && !timetoAllCheck ? dayjs(values.time_to).format("HH:mm:ss") : dayjs().endOf('day').format("HH:mm:ss")}`,
        is_available: type === "Availability" ? true : false
      }
      const toastId = toast("Updating Availability", { type: "info", autoClose: false });
      const response = await apiRequest.post(`/consultant-availability-datetimes`, data).catch(async function (err) {
        await setisExecuting(false)
        console.log(err)
        toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
      })
      if (response && response.data) {
        toast.update(toastId, { render: "Availability updated", type: toast.TYPE.SUCCESS, autoClose: 5000 });
        await dispatch(fetchConsultantTimes(consultant))
        await setisExecuting(false)
        setIsAvailabilityModalVisible(false)
      }
    }
  }
  return (<div>
    <Radio.Group onChange={onRadioChange} value={timeFrame}>
      <Radio value={"one day"}>One Day</Radio>
      <Radio value={"time period"}>Time Period</Radio>

    </Radio.Group>
    <Divider />
    {timeFrame === "one day" ? <Form
      name="availability-form"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      initialValues={{ remember: true }}
      layout="vertical"
      style={{ width: "100%" }}
    >


      <div className="flex-boy-row-space" style={{ padding: 0 }}>
        <Form.Item
          label="Date"
          name="date"
          rules={[{ required: true, message: 'this field is required!' }]}
          wrapperCol={{ span: 24 }}
        >
          <DatePicker format={"YYYY-MM-DD"} />
        </Form.Item>
        <Form.Item
          label="Time"
          name="time_from"

          rules={[{ required: !oneDayAllCheck, message: 'this field is required!' }]}
          wrapperCol={{ span: 24 }}
        >
          <TimePicker minuteStep={15} use12Hours format="h:mm a" disabled={oneDayAllCheck} />
        </Form.Item>
        -
        <Form.Item

          name="time_to"
          rules={[{ required: !oneDayAllCheck, message: 'this field is required!' }]}
          wrapperCol={{ span: 24 }}
        >
          <TimePicker minuteStep={15} use12Hours format="h:mm a" disabled={oneDayAllCheck}
            style={{ marginTop: "30px" }} />
        </Form.Item>
        <Form.Item

          name="all_day"
          valuePropName="checked"

          wrapperCol={{ span: 24 }}
        >
          <Checkbox onChange={(e) => oncheckChange("one_day", e)} style={{ marginTop: "30px" }}>All day</Checkbox>
        </Form.Item>
      </div>

    </Form> : <Form
      name="availability-form"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      initialValues={{ remember: true }}
      layout="vertical"
      style={{ width: "100%" }}
    >


      <div className="flex-boy-row-space" style={{ padding: 0 }}>
        <Form.Item
          label="Date"
          name="date_from"
          rules={[{ required: true, message: 'this field is required!' }]}
          wrapperCol={{ span: 24 }}
        >
          <DatePicker format={"YYYY-MM-DD"} />
        </Form.Item>
        <Form.Item
          label="Time From"
          name="time_from"
          rules={[{ required: !timeFromAllCheck, message: 'this field is required!' }]}
          wrapperCol={{ span: 24 }}
        >
          <TimePicker minuteStep={15} use12Hours format="h:mm a" disabled={timeFromAllCheck} />
        </Form.Item>

        {/* <Form.Item
                                              
                                              name="time_to"
                                              rules={[{ required: true, message: 'this field is required!' }]}
                                              wrapperCol={{ span: 24 }}
                                          >
                                              <TimePicker style={{marginTop:"30px"}}/>
                                          </Form.Item> */}
        <Form.Item

          name="all_day_from"
          valuePropName="checked"

          wrapperCol={{ span: 24 }}
        >
          <Checkbox onChange={(e) => oncheckChange("time_from", e)} style={{ marginTop: "30px" }}>All day</Checkbox>
        </Form.Item>
      </div>
      <div className="flex-boy-row-space" style={{ padding: 0 }}>
        <Form.Item
          label="Date"
          name="date_to"
          rules={[{ required: !timeFromAllCheck, message: 'this field is required!' }]}
          wrapperCol={{ span: 24 }}
        >
          <DatePicker format={"YYYY-MM-DD"} />
        </Form.Item>


        <Form.Item
          label="Time to"
          name="time_to"
          rules={[{ required: !timetoAllCheck, message: 'this field is required!' }]}
          wrapperCol={{ span: 24 }}
        >
          <TimePicker minuteStep={15} use12Hours format="h:mm a" disabled={timetoAllCheck} />
        </Form.Item>
        <Form.Item

          name="all_day_to"
          valuePropName="checked"

          wrapperCol={{ span: 24 }}
        >
          <Checkbox value={timetoAllCheck} onChange={(e) => oncheckChange("time_to", e)}
            style={{ marginTop: "30px" }}>All day</Checkbox>
        </Form.Item>
      </div>

    </Form>}

  </div>)
}
const mapStateToProps = (state) => ({
  consultant: state.consultants.selectedConsultant
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(AddAvailability)
