import React, {useEffect, useState} from 'react';
import {Card, Checkbox, Col, Row, Select} from 'antd';
import {appLog, appMessage, containedButton, emptyFun, horStack, makeSelectOptions, outlinedButton, secondaryButton} from '../../common/helpers';
import {apiRequest} from '../../app/Apis';
import {AuditOutlined, UndoOutlined} from '@ant-design/icons';
import {errMsg, toastUp} from '../../app/Reducers/reducerUtils';

const RoleCard = ({ role, permissions, loading }) => {
  const [rolePermissions, setRolePermissions] = useState([])
  const [freeze, setFreeze] = useState(false)
  const [updateDetected, setUpdateDetected] = useState(false)
  const [permissionGroups, setPermissionGroups] = useState({})
  const [filter, setFilter] = useState(null)
  const [moduleOptions, setModuleOptions] = useState([])
  const [selectedModule, setSelectedModule] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false)
  useEffect(() => {
    if (!!role) {
      setInitialPerms().catch(appLog)
    }
    return emptyFun
  }, [role, permissions]);
  const setInitialPerms = async (moduleName) => {
    await setFreeze(true)
    let rolePerms = role?.permissions.map(item => item.id)
    await setRolePermissions(rolePerms)
    let groupedPermissions = {}
    for (const permission of permissions) {
      if (!groupedPermissions[permission.module_name]) {
        groupedPermissions[permission.module_name] = []
      }
      groupedPermissions[permission.module_name]?.push(permission)
    }
    //Sort and add checked
    for (const permGroup of Object.values(groupedPermissions)) {
      groupedPermissions[permGroup[0].module_name] = permGroup?.map(item => ({
        ...item,
        value: item.id,
        label: item.name.replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()),
        checked: rolePerms.includes(item.id)
      })).sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0)
    }
    await setSelectedModule(moduleName)
    await setFilter(moduleName)
    await setPermissionGroups(moduleName ? { [moduleName]: groupedPermissions[moduleName] } : groupedPermissions)
    await setModuleOptions(makeSelectOptions(Object.keys(groupedPermissions).sort()))
    await setUpdateDetected(false)
    await setFreeze(false)
  }
  useEffect(() => {
    let checkedIds = Object.values(permissionGroups).flat(1).filter(item => item.checked).map(item => item.value)
    console.log({ checked: checkedIds.length, ids: rolePermissions.length })
    let rolePerms = !selectedModule ? rolePermissions : role?.permissions.filter(value => value.module_name === selectedModule).map(item => item.id)
    setUpdateDetected((checkedIds.every(value => !rolePerms.includes(value)) || checkedIds.length !== rolePerms.length)&&!!checkedIds[0])
    return emptyFun
  }, [permissionGroups]);
  const updateRole = async () => {
    try {
      await setFreeze(true)
      await setIsUpdating(true)
      let permission_ids = []
      permission_ids = Object.values(permissionGroups).flat(1).filter(item => item.checked).map(item => item.value)
      appMessage('Operation not allowed at the moment', 'info')
      return
      await apiRequest.patch('/roles', { role: role.name, permission_ids })
    }catch (err) {
      toastUp(errMsg(err), false)
    }finally {
      await setIsUpdating(true)
      await setFreeze(false)
    }
  }
  return (
    <div className={'flex flex-col gap-2  h-[100%] overflow-x-hidden px-2'}>
      <div className={'flexy-row py-1.5 px-4 items-center'}>
        {horStack([
          <p className={`font-semibold text-start my-auto ${!!role ? 'me-10' : ''}`}>{role?.name}</p>,
          <Select popupMatchSelectWidth={false}
                  onClear={() => setInitialPerms()}
                  value={filter}
                  options={moduleOptions}
                  placeholder={'Filter module'}
                  onSelect={(value, option) => {
                    console.log({ value, option })
                    setInitialPerms(value)
                  }}
                  className={'min-w-[200px]'}
                  allowClear
          />,
          ...(!!selectedModule ? [outlinedButton(() => setInitialPerms(), 'Clear Filter', false, null, { type: 'dashed', danger: true })] : []),
          ...(!role?.is_pending_approval ? [secondaryButton(() => appMessage('Feature pending', 'info'), 'Approve Changes', isUpdating, 'Approving', null, { danger: true })] : [])
        
        ])}
        {horStack([
          ...(!!role?[outlinedButton(() => appMessage('Feature pending', 'info'), 'Audit', false, null, { type: 'dashed', icon: <AuditOutlined /> })]:[]),
          ...(updateDetected ? [outlinedButton(() => setInitialPerms(), 'Reset', false, null, { type: 'dashed', icon: <UndoOutlined/> })] : []),
          ...(updateDetected ? [containedButton(updateRole, 'Save', isUpdating, 'Saving', null, null)] : [])
        ])}
      </div>
      <Row gutter={[8, 8]} justify={"space-between"} className={'overflow-y-auto overscroll-contain pb-4'}>
        {Object.keys(permissionGroups).map((permGroup, index) =>
          (<Col xs={24} sm={24} md={24} lg={selectedModule ? 24 : 12} xl={selectedModule ? 24 : 12} className={'w-full'}>
            <Card title={permGroup} className={'w-full h-[100%]'} key={index} hoverable>
              <Row gutter={[8, 8]} align={'stretch'} justify={"start"} className={'w-full '}>
                {permissionGroups[permGroup].map((item, index1) =>
                  (<Col xs={24} sm={24} md={24} lg={selectedModule ? 6 : 12} xl={selectedModule ? 6 : 12} className={'w-full'}>
                    <Checkbox key={index1}
                              disabled={freeze}
                              onChange={async e => {
                                await setFreeze(true)
                                let permGroupBeforeToggle = { ...permissionGroups }
                                permGroupBeforeToggle[permGroup] = [...permGroupBeforeToggle[permGroup]
                                  .map(item => ({
                                    ...item,
                                    checked: item.value === e.target.value ? !item.checked : item.checked
                                  }))]
                                //toggle checked state
                                //Toggle check to determine whether to show the update/reset buttons
                                await setPermissionGroups(permGroupBeforeToggle)
                                await setFreeze(false)
                              }}
                              checked={item.checked}
                              className={'w-full'}
                              value={item.value}>{item.label}
                    </Checkbox>
                  </Col>))}
              </Row>
            </Card>
          </Col>)
        )}
      </Row>
    </div>
  );
};

export default RoleCard;