import React from 'react'
import { connect } from 'react-redux'
import { Carousel, Icon } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { FileImageOutlined } from '@ant-design/icons';
import { appLog } from "../../../../common/helpers";
const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

export const ImageCarousel = ({ currentImage, progress }) => {
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };
    return (
        <Carousel autoplay arrows={true} style={{ width: '240px', height: '300px' }} prevArrow={<LeftOutlined />} nextArrow={<RightOutlined />}>
            {progress.map(item => {
                return (
                    item.image_url ? <img src={item.image_url} style={{ margin: '10px', width: '240px', height: '300px' }} /> : <div className='flex-boy-row' style={{ margin: '10px', width: '240px', height: '300px', background: 'whitesmoke', justifyContent: 'center', alignItems: 'center' }}>
                        <FileImageOutlined style={{ fontSize: '60px', color: 'grey' }} />
                    </div>
                )
            })}

        </Carousel>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ImageCarousel)
