import React, {useEffect, useState} from 'react';
import {Grid, Table} from "antd";
import {connect, useDispatch} from "react-redux";
import {fetchWPRegions, fetchWPStates, fetchWPSubRegions, resetWpStatesPagination} from "../../../app/Reducers";
import {updateWpStatesFilters} from "../../../app/Reducers";
import TableFilters from "../../../components/InputComponents/TableFilters";
import {emptyFun, emptyTable, getSkels, makeSelectOptions, onTableChange, responsiveTbl} from "../../../common/helpers";

const { useBreakpoint } = Grid;
const WPStates = ({appConf, wpStatesPagination, wpStates, wpRegions, wpSubRegions}) => {
  const dispatch = useDispatch();
  const windowState=useBreakpoint()
  const dateFormat = "YYYY-MM-DD";
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [auditItem, setAuditItem] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [selected, setSelected] = useState(null)
  const [isExecuting, setisExecuting] = useState(false)
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  const [regionOptions, setRegionOptions] = useState(wpRegions.map(item => item.name));
  const [subRegionOptions, setSubRegionOptions] = useState(wpSubRegions.map(item => item.name));
  const [stateOptions, setStateOptions] = useState([]);
  useEffect(() => {
    dispatch(fetchWPRegions());
    dispatch(fetchWPSubRegions());
    dispatch(fetchWPStates());
    return emptyFun
  }, []);
  const columns = [
    {
      title: 'Name', dataIndex: 'name', key: 'name', fltr: {filterable: true, type: 'text', editableCol: true, required: true, fullRow: true}
    },
    {
      title: 'Region', dataIndex: ['region_boundary', 'name'], key: 'region', fltr: {filterable: true, type: 'select', options: makeSelectOptions(regionOptions)}
    },
    {
      title: 'Sub-region', dataIndex: ['sub_region_boundary', 'name'], key: 'subRegion', fltr: {filterable: true, type: 'select', options: makeSelectOptions(subRegionOptions)}
    }
  ]
  
  async function handleFilterAction(action, values) {
    await dispatch(resetWpStatesPagination())
    if (action === 'filter') {
      let pl = {filterData: {...values}, pagination: wpStatesPagination}
      await dispatch(updateWpStatesFilters([false, pl, true]))
      await dispatch(fetchWPStates(pl))
      await dispatch(updateWpStatesFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updateWpStatesFilters([appConf.wpStatesFilters[0], undefined, true]))
      await dispatch(fetchWPStates())
      await dispatch(updateWpStatesFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  return (
    <div className="w-full">
      
      <Table
        title={() => (<TableFilters datasource={columns} setIsVisible={setIsVisible} isVisible={isVisible}
                                    handleFilterAction={handleFilterAction} filters={appConf.wpStatesFilters[1]}
                                    showClear={appConf.wpStatesFilters[0]} loading={appConf.wpStatesFilters[2]}
        />)}
        loading={appConf.wpStatesFilters[2]} size={'small'} {...responsiveTbl(windowState)} locale={{emptyText: emptyTable('regions')}}
        onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchWPStates, appConf.wpStatesFilters, updateWpStatesFilters, null)}
        onRow={(record) => ({
          onClick: async (event) => {
            await setAuditItem(record)
            await setShowAuditDialog(true)
          }
        })}
        {...getSkels((appConf.wpStatesFilters[2]), columns, wpStates)} pagination={wpStatesPagination}
      />
    </div>
  
  );
};


const mapStateToProps = (state) => ({

  activeUser: state.auth?.activeUser,
  wpStates: state.masters.wpStates,
  wpStatesPagination: state.masters.wpStatesPagination,
  wpSubRegions: state.masters.wpSubRegions,
  appConf: state.appConf,
  wpRegions: state.masters.wpRegions
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(WPStates);
