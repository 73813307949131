import React, {useState, useEffect} from 'react'
import { connect, useDispatch } from 'react-redux'
import {AutoComplete, Avatar, Checkbox, DatePicker, Divider, Dropdown, Form, Input, Menu, Radio, Select, Space, TimePicker, Typography} from 'antd';
import MapGL, { FlyToInterpolator, Layer, LinearInterpolator, Marker, NavigationControl, Source, WebMercatorViewport } from 'react-map-gl';
import { mapboxToken } from '../../configure/constants';
import {dialogCloser, executeChunkFn, getAdmissionStatus, keyValItemSimple, showCurrentAddress} from '../../common/helpers';
import { apiRequest } from '../../app/Apis';
import { on } from 'events';
import {RegularButton, Subtitle1} from '../../components';
import {AssignPatient, fetchPatientById, fetchPatients, updateAdmission, updateConsultant, updateNewAdmissionStatus, updatePatientDetails} from '../../app/Reducers';
import {el, tr} from 'date-fns/locale';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {usePermissionCheck} from '../../hooks/usePermissionCheck';
import DynamicModalForm from '../../components/DynamicModalForm';
import * as Sentry from '@sentry/react';
import {errMsg, toastUp} from '../../app/Reducers/reducerUtils';

 const NewAdmission = ({ admission, closeModal,isExecuting, setisExecuting, patient }) => {
  const [form] = Form.useForm();
const dispatch = useDispatch()
  const { Option } = Select;
  const { Search } = Input;
// selected patient
  const [patient_, setPatient_] = useState({ name: admission?.patient_name, id: admission?.patient_id });
  let stati = [ 'no_coverage'  ,'unassigned','on_hold']
  const can_update_new_admission_status = usePermissionCheck('update_new_admission_status', false, [])
   const [actionMenuRecord, setActionMenuRecord] = useState(null);
   const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
   const [formName, setFormName] = useState(undefined);
   const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
   console.log({admission})
   //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
   let dynamicFormInputFields = { 'Change admission status':  {
       fields: [
         {name: 'reason_admission_status_changed', label: 'Status change reason', required: true, type: 'textarea', fullRow: true},
       ],
       defaultValues: {}
     } }

   // initial values
  const [initialValues, setInitialValues] = useState(admission ? {
    patient_name: admission?.patient_name,
    patient_id: admission?.patient_id,
    referral_type: admission?.service_type,
    consultant: admission?.assigned_to?.name
  } : {
      patient_name: '',

  })

  const admissionMenu = (<Menu onClick={handleMenuClick}>
    {stati.map((item, index) => <Menu.Item key={index}
                                           style={{ textTransform: 'capitalize' }}> {item.replace('-', '').replace(/_/g, ' ')}  </Menu.Item>)}
  </Menu>);

  async function handleMenuClick(e) {
    console.log(stati[e.key])
    let pl = { admission_status: stati[e.key], patient_id: patient_.id }
    if (['no_coverage','on_hold'].includes(stati[e.key])) {
      await setFormName('Change admission status');
      await setDefaultDynamicModalFormValues({ admission_status: stati[e.key], patient_id: patient_.id });
      await setDynamicFormOpen(true);
    }else {
      await dispatch(updateNewAdmissionStatus(pl))
    }
  }
  const handleProtocolsearch = async (value) => {
    const { data } = await apiRequest.get(`/patients?search=${value}`)
    // setOptions(data);
    if (data) {
      console.log(data.data)
      setOptions(data.data.filter(item => item.status === "active" || item.status === "hospitalized"));
    }
  };
  const onprotocolSelect = (value) => {
    console.log('onSelect', value);
    let patient = treatmentOptions.find(item => item?.name === value)
   
    setPatient_(patient)
    getListAvailableConsultants(patient.id)
  };



  const getListAvailableConsultants = async (id) => {
    const { data } = await apiRequest.get(`consultant-scheduling/${id}`)
    if (data) {
      console.log(data.data)
      setconsultantListOptions(data.data);
    }
  }

  const [treatmentOptions, setOptions] = useState([]);
  
useEffect(() => {
  if (admission) {
    // serach for patient and set the first value as patient_
    fetchPatient(admission?.patient_id)
  }

  return () => {
   
  }
}, [admission])
  
  const fetchPatient = async (id) => {
    const { data } = await apiRequest.get(`/patients/${admission?.patient_id}`)
    if (data) { 
      setPatient_(data.data)
      setInitialValues({
        patient_name: data.data?.name,
      })
      getListAvailableConsultants(data.data.id)
      setViewState({
        latitude: data.data.lat,
        longitude: data.data.lng,
        zoom: 3.5
      })
      form.setFieldsValue({
        patient_name: data.data?.name,
      });
    }
  }
  const [checked, setChecked] = useState(true);
  
  const onChange = (e) => {
    console.log('checked = ', e.target.checked);
    setChecked(e.target.checked);
  };
  const [consultantsError, setconsultantsError] = useState(false);
  const [consultantListOptions, setconsultantListOptions] = useState([]);



  const onSearch = async (e) => {
  let value = e.target.value
    const data = await apiRequest.get(`consultant-scheduling/${patient_.id}?search=${value}`).catch(function (err) {
      console.log(err)
      setconsultantsError(true)
    })
    if (data && data.data) {
      console.log(data.data, data)
      // setconsultantOptions(data.data.data);
      setconsultantListOptions(data.data.data)
      setconsultantsError(false)
    }
  }

  //  debounce search for consultants
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
  const debouncedSearch = debounce(onSearch, 500);
 
  const [consultant, setConsultant] = useState(null);

  // map state
  const [viewState, setViewState] = React.useState({
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 3.5
  });

  const onFinish = async (values) => {
    console.log(values)
    let data = {
      patient_id: patient_.id,
      consultant_id: consultant,
      reason: values.reason,
      referral_type: values.referral_type,
    }
    console.log(data)

    let payload = {
      admission_id: admission?.assigned_to?.assignment_id,
      data: data
    }

    try {
      if (admission?.assigned_to) {
        console.log('update', payload)
        await executeChunkFn(dispatch,  updateAdmission ,  payload , setisExecuting, closeModal, checkPatient)
      }
      else {
        await executeChunkFn(dispatch,  AssignPatient,  data, setisExecuting, closeModal, checkPatient)
      }
      
    } catch (err) {
      console.log(err)
    }
   
  }

  const checkPatient = async () => {

    if (patient) {
      await dispatch(fetchPatientById(Number(patient.id)))
    }
  }
   
   
   /**
    * This function is handling returned form entries from the dynamic form
    * */
   const onDynamicFormEntriesResult = async (entries) => {
     //console.log({entries})
     try {
       switch (formName) {
         case 'Change admission status':
           await executeChunkFn(dispatch, updateNewAdmissionStatus, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen), closeModal)
           //Not necessary
           break;
         default:
           return
       }
     } catch (e) {
       Sentry.captureException(e);
       console.log(e);
       toastUp(errMsg(e, false, formName), false);
     }
   };
  return (
    <div className="p2">
      {(isDynamicFormOpen && !!formName) && (
        <DynamicModalForm
          setDynamicFormOpen={setDynamicFormOpen}
          isDynamicFormOpen={isDynamicFormOpen}
          inputFields={dynamicFormInputFields[formName]}
          onDynamicFormEntriesResult={onDynamicFormEntriesResult}
          closeModal={dialogCloser(setDynamicFormOpen)}
          isExecuting={isExecuting}
          defaultValues={defaultDynamicModalFormValues}
          formName={formName}
        />
      )}
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        layout="vertical"
        name="admissionForm"
        id="admissionForm"
      >
        <div className="grid grid-cols-12" style={{ height: '60vh' }}>
        
          <div className="col-span-9 h-full relative" style={{height:'100%'}} >
            <div className="rounded p-2 max-h-max bg-white sh" style={{width:'40%', position:'absolute', top:'20px', left:'20px', zIndex:'10', maxHeight:'90%'}}>
              {/* search input for consultants */}
              <Search
                placeholder="Search for consultants"
                onSearch={onSearch}
                onChange={debouncedSearch}
                enterButton
                style={{
                  width: "100%",
                  marginBottom: '10px'
                }}
                
              />
              {admission?.assigned_to ? <Typography variant={"caption"}><b>Current assignee: </b>{admission?.assigned_to?.name}</Typography> : null}
              {/* <Checkbox checked={checked}  onChange={onChange}>
               Show only Matches
              </Checkbox> */}
            
              <Divider />
              {/* consultant list */}

              <div className="overflow-y-scroll" style={{ maxHeight: '40vh' }}>
                <Form.Item
                 
                  name="consultant"
                  rules={[{ required: true, message: 'this field is required!' }]}
                  wrapperCol={{ span: 24 }}
                >
                <Radio.Group className='w-full' onChange={(e) => setConsultant(e.target.value)} value={consultant}>
                {consultantListOptions.filter(item => item.name).map((item, index) => {
                  return (
                    <div key={index} className="flex justify-between w-full items-center p-2 border-b border-gray-200">
                      {/* avatar */}
                      <Avatar size={40} src={item.avatar} alt={item?.name}>
                        {/* get first characters of name separated by space */}

                        {item?.name?.split(" ").map((item, index) => {
                          return index < 2 ? item[0] : null
                        })}
                      </Avatar>
                      <div className='flex-1 px-2'>
                        <div className="text-sm font-semibold">{item?.name}</div>
                        <p className='font-normal text-sm' style={{textAlign:'left'}}>{item.distance_from_patient?.toFixed(2) || 0} mi /  {item.assigned_patients || 0} active patients</p>
                        <div />
                      </div>
                        <Radio key={item.id} value={item.id}></Radio>
                      
                      </div>
                  )
                })}
                    </Radio.Group>
</Form.Item>
              </div>
              </div>



            {/* react mapgl map */}
            {patient_.lat ?<MapGL
              mapStyle="mapbox://styles/mapbox/streets-v11"
              {...viewState}
              width="100%"
              height="100%"
             
              mapboxApiAccessToken={mapboxToken}
            >
             {patient_.lat ? <Marker
                latitude={patient_.lat}
                longitude={patient_.lng}
                offsetLeft={-20}
                offsetTop={-10}
              >
                <div className='rounded-full bg-red-800' style={{height:'20px' , width:'20px'}}></div>
              </Marker> : null}
            </MapGL> : null}


            
            
            
        </div>
          <div className="col-span-3 bg-slate-100 p-2">
            <div className="rounded p-2 my-2 shadow-md bg-white">
              {!can_update_new_admission_status ?
                  <Dropdown dropdownRender={()=>admissionMenu} trigger={['click']}>
                    <RegularButton
                        /*onClick={() => setIsVisible(prevState => !prevState)}*/
                        className="flex-boy-row-space" color="grey" background="transparent"
                        style={{ marginRight: "10px" }} borderColor="gainsboro">
                    {getAdmissionStatus(admission?.admission_status ? admission?.admission_status : 'unassigned')}
                      &nbsp; &nbsp;
                      <FontAwesomeIcon color="grey" icon={["fas", "caret-down"]} />
                    </RegularButton>
                </Dropdown> : getAdmissionStatus(admission?.admission_status ? admission?.admission_status: 'unassigned')}
              <Divider/>
              {(['no_coverage','on_hold'].includes(admission?.admission_status))&&keyValItemSimple('Status reason:',admission?.reason_admission_status_changed||'N/A')}
              <Divider/>
              <Form.Item
                label="Patient Name"
                name="patient_name"
                rules={[{ required: true, message: 'Please input patient name!' }]}
                className='mt-4'
              >


                <AutoComplete popupMatchSelectWidth={false}
                  value={patient_ ? patient_?.name : ''}
                  style={{ width: "100%", }}
                  onSelect={onprotocolSelect}
                  onSearch={handleProtocolsearch}
                  placeholder="search for patients">
                  {treatmentOptions.map(item => (<Option key={item.id} value={item?.name}>{item?.name}</Option>))}
                </AutoComplete>
              </Form.Item>
              {/* <Form.Item
                label="Referral Type"
                name="referral_type"
                rules={[{ required: true, message: 'this field is required!' }]}
                wrapperCol={{ span: 24 }}
              >
                <Radio.Group>
                  <Space direction="vertical">
                  <Radio value="wound care">Wound Care</Radio>
                 <Radio value="podiatry">Podiatry</Radio>
                    <Radio value="wound care and podiatry">Wound Care and Podiatry</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item> */}
            </div>
            <div className="rounded p-2 my-2 shadow-md bg-white">
              <Form.Item
                label="Reason" name="reason" rules={[{ required: true, message: 'this field is required!' }]}
                wrapperCol={{ span: 24 }}>
                <Input autoComplete={'off'} />
              </Form.Item>
            </div>
           
          </div>
        </div>
        </Form>
    </div>
  )
}

const mapStateToProps = (state) => ({
  admission: state.appointments.selectedAdmission
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(NewAdmission) 