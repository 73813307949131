/* eslint-disable no-unused-vars */
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {toast} from 'react-toastify';
import {authState} from './initialState';
import {appLog} from "../../common/helpers";
import RemoveCookie from '../../hooks/RemoveCookie';
import SetCookie from '../../hooks/setCookie';
import ReactGA from "react-ga4";
import GetCookie from '../../hooks/GetCookie';
import {doResetSettingsSlice, fetchSettings} from './SettingSlice';
import {apiRequest, clientSecret} from '../Apis';
import {errMsg, getToastId, updateToast} from './reducerUtils';
import {doResetConsultantSlice, selectConsultant} from './ConsultantSlice';
import {doResetFacilitySlice, selectFacility} from './FacilitiesSlice';
import {doResetConfigSlice, setMQTTSetupState} from './ConfigSlice';
import {doResetPatientSlice} from './PatientSlice';
import {doResetAppointmentSlice} from './AppointmentsSlice';
import {doResetAuditLogSlice} from './AuditLogSlice';
import {doResetBillingSlice} from './BillingSlice';
import {doResetChatSlice} from './ChatSlice';
import {doResetDepartmentSlice} from './DepartmentSlice';
import {doResetMapSlice} from './mapSlice';
import {doResetMasterListState} from './MasterSlice';
import {doResetNavSlice} from './NavSlice';
import {doResetNotificationSlice} from './NotificationSlice';
import {doResetOrderSlice} from './OrdersSlice';
import {doResetOrganisationSlice} from './OrganisationSlice';
import {doResetStatsSlice} from './StatsSlice';
import {doResetTaskSlice} from './TaskSlice';


const activeUser = GetCookie('activeUser')
const initialState = {
  ...authState,
  activeUser: activeUser ? JSON.parse(activeUser) : null,
  permissions: activeUser ? JSON.parse(activeUser).roles.map((item) => item.permissions).flat(1) : []
};
export const loginUser = createAsyncThunk('loginUser', (payload, thunkAPI) => {
    // console.log(process.env);
    return new Promise(async (resolve, reject) => {
      console.log('loginUser')
      const toastId = toast('Logging in', { type: 'info', autoClose: false });
      try {
        const state = await thunkAPI.getState();
        // console.log(process.env);
        const formData = new FormData();
        const navigate = payload.navigate;
        formData.append('password', payload.password);
        formData.append('client_secret', clientSecret);
        formData.append('scope', '*');
        formData.append('username', payload.username);
        formData.append('client_id', 2);
        formData.append('grant_type', 'password');
        let config = { headers: { accept: 'application/json' } };
        const response = await axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/oauth/token`, formData, config);
        console.log({response})
        if (response.data) {
          toast.update(toastId, { render: 'Login success', type: toast.TYPE.SUCCESS, autoClose: 5000 });
          // RemoveCookie('accessToken')
         await SetCookie('accessToken', response.data.access_token)
          // localStorage.setItem('accessToken', response.data.access_token);
          // RemoveCookie('refresh_token')
         await SetCookie('refresh_token', response.data.refresh_token)
         await SetCookie('userLoggedIn', true)
          // localStorage.setItem('refresh_token', response.data.refresh_token);
          // sessionStorage.setItem('userLoggedIn', true);
          // sessionStorage.setItem('accessToken', response.data.access_token)
          const user = await apiRequest.get('/me/profile', formData, config);
          console.log({user})
          const activeUser=user.data.data
          // RemoveCookie('activeUser')
          await SetCookie('activeUser', JSON.stringify(activeUser))
          // localStorage.setItem('activeUser', JSON.stringify(activeUser));
          if (activeUser && activeUser.facilityProfile) {
            thunkAPI.dispatch(selectFacility(activeUser.facilityProfile));
          } else if (activeUser && activeUser.consultantProfile) {
            thunkAPI.dispatch(selectConsultant({ record: { ...activeUser, ...activeUser.consultantProfile } }));
          }
          //GA4 event login
          ReactGA.event({
            category: "login",
            action: "login",
            label: "login",
            value: 1
          });
          resolve(activeUser);
        }
      } catch (e) {
        console.log(e);
        toast.update(toastId, { render: errMsg(e, false, null, 'Login unsuccessful. Please try again'), type: toast.TYPE.ERROR, autoClose: 5000 });
        reject(e);
      }
    });
  }
);
export const loginFacility = createAsyncThunk('loginFacility', (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = toast('Logging in', { type: 'info', autoClose: false });
      try {
        //console.log(process.env);
        const formData = new FormData();
        formData.append('password', payload.password);
        formData.append('email', payload.username);
        formData.append('user_type', 'facility');
        let config = { headers: { accept: 'application/json' } };
        const response = await axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/api/login`, formData, config);
        if (response.data) {
          toast.update(toastId, { render: 'Login success', type: toast.TYPE.SUCCESS, autoClose: 5000 });
          // RemoveCookie('accessToken')
          SetCookie('accessToken', response.data.access_token)
          // localStorage.setItem('accessToken', response.data.access_token);
          // RemoveCookie('refresh_token')
          SetCookie('refresh_token', response.data.refresh_token)
          SetCookie('userLoggedIn', true)
          // localStorage.setItem('refresh_token', response.data.refresh_token);
          // sessionStorage.setItem('userLoggedIn', true);
          // sessionStorage.setItem('accessToken', response.data.access_token)
          const user = await apiRequest.get('/me/profile', formData, config);
          // RemoveCookie('activeUser')
          SetCookie('activeUser', JSON.stringify(user.data.data))
          // localStorage.setItem('activeUser', JSON.stringify(user.data.data));
          if (user.data.data && user.data.data.facilityProfile) {
            thunkAPI.dispatch(selectFacility(user.data.data.facilityProfile));
          } else if (user.data.data && user.data.data.consultantProfile) {
            thunkAPI.dispatch(selectConsultant({ record: { ...user.data.data, ...user.data.data.consultantProfile } }));
          }
          if (!user?.data?.data?.password_was_generated) {
            thunkAPI.dispatch(fetchSettings())
          }
          resolve({ ...user.data.data });
        }
      } catch (e) {
        //console.log(e);
        toast.update(toastId, {
          render: errMsg(
            e,
            false,
            null,
            'Login unsuccessful. Please try again'
          ),
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
        reject(e);
      }
    });
  }
);

export const LoginWithSSO = createAsyncThunk(
  '/login/sso',
  
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Logging in');
      const navigate = payload.navigate;
      const formData = new FormData();
      
      let config = {
        headers: {
          accept:
            'application/json' /* Setting the content type of the request to application/json. */,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      };
      const endpoint = `${process.env.REACT_APP_SERVER_ENDPOINT}/sso/${payload?.driver}/verify${payload?.query}`;
      try {
        const state = await thunkAPI.getState();
        const response = await axios.get(endpoint, config);
        if (response?.data?.data) {
          toast.update(toastId, { render: 'Login with SSO success', type: toast.TYPE.SUCCESS, autoClose: 5000 });
          localStorage.setItem('accessToken', response?.data?.data?.access_token);
          SetCookie('accessToken', response?.data?.data?.access_token)
          SetCookie('userLoggedIn', true)
          // sessionStorage.setItem('accessToken', response?.data?.data?.access_token)
          // sessionStorage.setItem('userLoggedIn', true);
          formData.append('username', response?.data?.data?.email);
          const user = await apiRequest.get('/me/profile', formData, config);
          //console.log('User Response sso', user);
          // RemoveCookie('activeUser')
          SetCookie('activeUser', JSON.stringify(user.data.data))
          if (user.data.data && user.data.data.facilityProfile) {
            thunkAPI.dispatch(selectFacility(user.data.data.facilityProfile));
          } else if (user.data.data && user.data.data.consultantProfile) {
            thunkAPI.dispatch(selectConsultant({ record: { ...user.data.data, ...user.data.data.consultantProfile } }));
          }
          thunkAPI.dispatch(fetchSettings())
          resolve({ ...user.data.data });
        } else {
          toast.update(toastId, { render: 'Login with SSO failed', type: toast.TYPE.ERROR, autoClose: 5000 });
          window.location.href = '/'
        }
      } catch (e) {
        window.location.href = '/'
        appLog('SSO Error', e);
        updateToast(toastId, errMsg(e, false, null, 'Login unsuccessful. Please try again'), false);
        reject(e);
      }
    });
  }
);

export const sendResetEmail = createAsyncThunk(
  'sendResetEmail',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Sending Email');
      try {
        let email = payload.email;
        const response = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/forgot-password/email`,
          { email }
        );
        updateToast(toastId, response.data.message, true);
        resolve(true);
      } catch (e) {
        updateToast(
          toastId,
          errMsg(e, false, null, 'Email not sent. Please try again'),
          false
        );
        reject(e);
      }
    });
  }
);
export const sendResetPassword = createAsyncThunk(
  'sendResetPassword',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Setting New Password');
      try {
        const response = await axios.patch(`${process.env.REACT_APP_ENDPOINT}/passwords/update`, payload);
        updateToast(toastId, response.data.message, true);
        resolve(true);
      } catch (e) {
        updateToast(
          toastId,
          errMsg(e, false, null, 'Resending failed. Please try again'),
          false
        );
        reject(e);
      }
    });
  }
);
export const updateNewPassword = createAsyncThunk('updateNewPassword', async (payload, thunkAPI) => {
      return new Promise(async (resolve, reject) => {
        const toastId = getToastId('Setting New Password');
        try {
            await axios.post(`${process.env.REACT_APP_ENDPOINT}/reset-password`, payload);
          updateToast(toastId, 'Updated successfully', true);
          resolve(true);
        } catch (e) {
          updateToast(toastId, errMsg(e, false, null, 'Resending failed. Please try again'), false);
          reject(e);
        }
      });
    }
);
export const updateGeneratedPassword = createAsyncThunk('updateGeneratedPassword', async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Updating New Password');
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_ENDPOINT}/passwords/update`,
          payload
        );
        updateToast(toastId, 'Password updated successfully', true);
        resolve(true);
      } catch (e) {
        updateToast(
          toastId,
          errMsg(e, false, null, 'Update failed. Please try again'),
          false
        );
        reject(e);
      }
    });
  }
);

export const logOut = createAsyncThunk('logOut',
  async (navigate, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = toast('Logging out', { type: 'info', autoClose: false });
      const clearState = async () => {
        await thunkAPI.dispatch(setMQTTSetupState(false))
        await thunkAPI.dispatch(doResetAuthSlice())
        await thunkAPI.dispatch(doResetAppointmentSlice())
        await thunkAPI.dispatch(doResetAuditLogSlice())
        await thunkAPI.dispatch(doResetBillingSlice())
        await thunkAPI.dispatch(doResetChatSlice())
        await thunkAPI.dispatch(doResetConfigSlice())
        await thunkAPI.dispatch(doResetConsultantSlice())
        await thunkAPI.dispatch(doResetDepartmentSlice())
        await thunkAPI.dispatch(doResetFacilitySlice())
        await thunkAPI.dispatch(doResetMapSlice())
        await thunkAPI.dispatch(doResetMasterListState())
        await thunkAPI.dispatch(doResetNavSlice())
        await thunkAPI.dispatch(doResetNotificationSlice())
        await thunkAPI.dispatch(doResetOrderSlice())
        await thunkAPI.dispatch(doResetOrganisationSlice())
        await thunkAPI.dispatch(doResetPatientSlice())
        await thunkAPI.dispatch(doResetSettingsSlice())
        await thunkAPI.dispatch(doResetStatsSlice())
        await thunkAPI.dispatch(doResetTaskSlice())
        toast.update(toastId, { render: 'Logout success', type: toast.TYPE.SUCCESS, autoClose: 5000 });
        navigate('/auth/login', { replace: true })
      }
      try {
        await apiRequest.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/api/logout`);
        await clearState()
        resolve()
      } catch (e) {
        console.error(e)
        if (axios.isCancel(e)) {
          await clearState()
        }
        updateToast(toastId, errMsg(e, false, null, 'Couldn\'t Sign out'), false);
        reject(e);
      }
    })
  }
)
export const acknowledgeReleaseNotes = createAsyncThunk('acknowledgeReleaseNotes',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Acknowledging release notes');
      try {
        await apiRequest.post(`/acknowledge-release-notes-version`, payload);
        updateToast(toastId, 'Acknowledgement successful', true);
        resolve()
      } catch (e) {
        updateToast(toastId, errMsg(e, false, null, 'Acknowledgement failed'), false);
        reject(e);
      }
    })
  }
)

export const logOutunauth = createAsyncThunk('logOutunauth',
  async () => {
    return new Promise(async (resolve, reject) => {
      // const toastId = toast('Logging in', { type: 'info', autoClose: false });
      try {
        const response = await apiRequest.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/api/logout`);
        if (response.data) {
          
          resolve(true)
          
        }
      } catch (e) {
        
        
        reject(e);
      }
    })
  }
)

export const getAgoraToken = createAsyncThunk('getAgoraToken',
  async () => {
    return new Promise(async (resolve, reject) => {
      // const toastId = toast('Logging in', { type: 'info', autoClose: false });
      try {
        const response = await apiRequest.post(`/agora/token`, { "channel_name": "agora-video-chat" });
        if (response.data?.data) {
          
          resolve(response.data.data)
          
        }
      } catch (e) {
        
        
        reject(e);
      }
    })
  }
)

export const authSlice = createSlice({
  name: 'Auth',
  initialState, // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    
    setActiveUser: (state, action) => {
      state.activeUser = { ...action.payload };
      state.permissions = action.payload.roles
        .map((item) => item.permissions)
        .flat(1);
    },
    storeDynamicLink: (state, action) => {
      state.dynamicLink = action.payload;
    },
    resetPermissions: (state, action) => {
      state.permissions = [];
    },
    doResetAuthSlice: (state, action) => {
      Object.keys(initialState).map((key) => (state[key] = initialState[key]));
      RemoveCookie('accessToken')
      RemoveCookie('activeUser')
    }
    
  },
  
  extraReducers:(builder)=> {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.activeUser = action.payload;
      state.permissions = action.payload.roles
        .map((item) => item.permissions)
        .flat(1);
    }).addCase(logOut.fulfilled,(state, action) => {
      state.dynamicLink = null
      state.activeUser = null;
      state.permissions = [];
      RemoveCookie('accessToken');
      RemoveCookie('refresh_token');
      RemoveCookie('activeUser');
      // sessionStorage.removeItem('userLoggedIn');
      // sessionStorage.removeItem("order_caution")
      
      // document.getElementsByClassName('minimized')[0].style.visibility = 'hidden';
    }).addCase(loginFacility.fulfilled,(state, action) => {
      state.activeUser = { ...action.payload };
      state.permissions = action.payload.roles
        .map((item) => item.permissions)
        .flat(1);
    }).addCase(LoginWithSSO.fulfilled,(state, action) => {
      state.activeUser = { ...action.payload };
      state.permissions = action.payload.roles
        .map((item) => item.permissions)
        .flat(1);
    }).addCase(sendResetEmail.fulfilled,(state, action) => {
      state.passwordReset = true;
    }).addCase(sendResetPassword.fulfilled,(state, action) => {
      state.passwordReset = true;
    }).addCase(getAgoraToken.fulfilled,(state, action) => {
      state.agoraToken = action.payload
    })
  }
});


export const { setActiveUser, storeDynamicLink, resetPermissions, doResetAuthSlice } = authSlice.actions;
export default authSlice.reducer;
