/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Menu } from 'antd';

import { Subtitle1 } from '../../../components';
import { setVisibilty } from '../../../app/Reducers/mapSlice';

const { SubMenu } = Menu;
const regions = {
    western: ["California"], eastern: ["Texas"]
}
const MapMenu = ({children, navs}) => {
    const dispatch = useDispatch();
    const [isCollapsed, setCollapsed] = useState(true)
    const treeData = [{
        title: 'Western', key: 'western', children: regions.western.map(item => {
            return ({ title: item, key: item, children: [] })
        })
    }, {
        title: 'Eastern', key: 'eastern', children: regions.eastern.map(item => {
            return ({ title: item, key: item, children: [] })
        })
    }]
   
    return (<Fragment>

        <div className="map-menu-collapsed" style={{ width: isCollapsed ? "64px" : "0px" }}
            onClick={() => setCollapsed(false)}>
            <FontAwesomeIcon onClick={() => console.log("heh")} color="grey" icon={["fas", 'map']} />
        </div>
        <div className="flex-boy-column-space collapsible map-menu"
            style={{ width: isCollapsed ? '0px' : "320px", height: "calc(100vh - 64px)" }}>

            <div className="top" style={{ opacity: isCollapsed ? 0 : 1 }}>
                <div className="header flex-boy-row-space">

                    {isCollapsed ? null : <Subtitle1 color="black">{navs.mapNavs.current}</Subtitle1>}
                    <div className="circle-border">
                        {isCollapsed ?
                            <FontAwesomeIcon onClick={() => setCollapsed(false)} color="grey" icon={["fas", 'chevron-right']} /> :
                            <FontAwesomeIcon onClick={() => setCollapsed(true)} color="grey" icon={["fas", 'chevron-left']} />}
                    </div>

                </div>

               
                {children}

            </div>
                        
            <div style={{ width: "320px" }}></div>

        </div>
    </Fragment>)
}
const mapStateToProps = (state) => ({
    regions: state.map.regions, consultants: state.map.consultants.count,
    patients: state.map.patients.count, facilities: state.map.facilities.count, patients_visibility: state.map.patients.visible,
    consultants_visibility: state.map.consultants.visible, facilities_visibility: state.map.facilities.visible,
    navs: state.navs, activeUser: state.auth?.activeUser
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(MapMenu)
