/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Body2, Headline6, Subtitle1 } from '../../../general'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { selectConsultant, selectFacility, selectPatient } from '../../../../app/Reducers';


import { Input } from 'antd';
import { apiRequest } from '../../../../app/Apis';
import _ from 'lodash';
import { useCallback } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';



export const ElasticSearchComponent = ({ goToNYC,  consultants }) => {
    const location = useLocation()
    const navigate = useNavigate();
    const [searchValue, setsearchValue] = useState('')
    const { Search } = Input;
    const [searchState, setSearchState] = React.useState({});
    const dispatch = useDispatch()
    const PatientHit = ({ hit }) => {
        return (
            <div onClick={() => goToPatient(hit)} className="flex-boy-row-space" style={{ width: "100%", cursor: 'pointer', backgroundColor: 'white', margin: '5px' }}>


                <FontAwesomeIcon size="1x" icon={['fas', 'user-injured']} />
                <div style={{ flex: 1, marginLeft: "10px" }}>
                    <Subtitle1 style={{ textAlign: "left" }}>{hit.name}</Subtitle1>
                    <Body2 color="black">{hit.address}</Body2>
                </div>
            </div>)
    }
    const FacilityHit = ({ hit }) => {
        if (hit) {
            return (<div onClick={() => goToFacility(hit)} className="flex-boy-row-space"
                style={{ width: '100%', cursor: 'pointer', backgroundColor: 'white' }}>

                <FontAwesomeIcon size="1x" icon={['fas', 'star-of-life']} />
                <div style={{ flex: 1, marginLeft: "10px" }}>
                    <Subtitle1 style={{ textAlign: "left" }}>{hit.name}</Subtitle1>
                    <Body2 color="black">{hit.address}</Body2>
                </div>
            </div>)
        }
    }
    const CounsultantHit = ({ hit }) => {
        if (hit) {
            return (<div onClick={() => goToConsultant(hit)} className="flex-boy-row-space"
                style={{ width: "100%", cursor: 'pointer', backgroundColor: 'white' }}>

                <FontAwesomeIcon size="1x" icon={['fas', 'user']} />
                <div style={{ flex: 1, marginLeft: "10px" }}>
                    <Subtitle1 style={{ textAlign: "left" }}>{hit.name}</Subtitle1>
                    <Body2 color="black">{hit.address}</Body2>
                </div>
            </div>)
        }
    }
    const goToPatient = async (project) => {
        console.log(project)
        // goToNYC(project)
        dispatch(selectPatient({ record: project }))
        navigate(`/patients/${project.id}`)
        clearSearch()
    }
    const goToConsultant = async (project) => {

        let consultant = consultants.data.find(item => item.id === project.id)
        console.log(consultant)
        const response = await apiRequest.get(`consultants/${project.id}`);


        if (consultant) {
            goToNYC({ ...project, _geoloc: { lat: consultant.lat || 0, lng: consultant.lng || 0 } })
            console.log(console.log(project))
        } else {
            if(response?.data?.data){
                dispatch(selectConsultant({ record: response.data.data }))
                navigate(`/consultants/${project.id}`)
            }
           
        }

        clearSearch()

        // clearSearch()
    }
    const goToFacility = async (project) => {
        let hit = { ...project, _geoloc: { lat: project.lat || 0, lng: project.lng || 0 } }
        // goToNYC(hit)
        dispatch(selectFacility(project))
        navigate(`/facilities/${project.id}`)
        console.log(console.log(project))
        clearSearch()
    }
    const clearSearch = () => {
        setSearchState({ ...searchState, query: '' })
    }
    // const CustomStateResults = connectStateResults(Results);
    const [searchResults, setsearchResults] = useState(null)
    const searchSuggestions = async (value) => {
        let response = await apiRequest.get(`suggest?query=${value}`).catch(e => console.log(e))
        if (response?.data) {
            setsearchResults(response.data)
        }
    }

    const debouncedChangeHandler = useCallback(
        _.debounce(function (value) {
            searchSuggestions(value)
        }, 500),
        []
    );

    return (<div className="instant-search-elastic">
        <Input placeholder="Search for patients, consultants and facilities here..." prefix={<SearchOutlined />} width={"100%"} onChange={(e) => {
            setsearchValue(e.target.value)
            debouncedChangeHandler(e.target.value)
        }} />
        {searchValue.length > 0 && <div className="search-results">
            <Headline6>Patients</Headline6>
            {searchResults?.patients_suggest?.map(item => PatientHit({ hit: item._source }))}
            <Headline6>Offices</Headline6>
            {searchResults?.facilities_suggest?.map(item => FacilityHit({ hit: item._source }))}
            <Headline6>Consultants</Headline6>
            {searchResults?.consultants_suggest?.map(item => CounsultantHit({ hit: item._source }))}
        </div>}
    </div>)
}
const mapStateToProps = (state) => ({
    
    consultants: state.map.consultants,
})
const mapDispatchToProps = (dispatch) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(ElasticSearchComponent)
