import React from "react";
import {appLog} from './helpers';

export const patientColumns = [
  {title: 'First & Last Name', dataIndex: 'name', key: 'name'},
  {title: 'Region', dataIndex: 'region', key: 'regionName'},
  {title: 'Office', dataIndex: 'medical_facility', key: 'facility'},
  {title: 'Contact Phone', dataIndex: 'phone_no', key: 'phone_no'},
  {title: 'DOB', dataIndex: 'birth_date', key: 'birth_date'},
  {title: 'Approval status', dataIndex: 'approval_status', key: 'approval_status'},
  {title: 'Status', dataIndex: 'status', key: 'status'}]
export const copayRules =[
  {
    validator: (rule, value, callback) => {
      //console.log({rule})
      //console.log({value})
      if (!!value && Number(value) < 1) {
        appLog('Can neither be a negative value nor 0')
        callback('Can neither be a negative value nor 0');
      } else {
        callback();
      } /*else {
          callback('Invalid value, field will reset');
        }*/
      appLog('We are here')
    }
  }
]
export const deleteReasonInputFields = {
  fields: [
    {name: 'delete_reason', label: 'Deletion reason', required: false, type: 'textarea', fullRow: true},
  ],
  defaultValues: {}
}