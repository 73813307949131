/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Body2, Headline6, Indicator, Subtitle1 } from '../../../components'
import { Alert, Button, Checkbox, Col, Divider, Image, Modal, Row } from 'antd';
import dayjs from 'dayjs';
import { apiRequest } from '../../../app/Apis';
import { boldKeyCapitalizedValue, closeButton, containedButton, emptyFun, horStack, keyValItemCaps, makeAutoCompleteOptions, modalClose, outlinedButton } from "../../../common/helpers";
import _ from "lodash"
import { AuditOutlined, EditOutlined } from '@ant-design/icons';
import { usePermissionCheck } from '../../../hooks/usePermissionCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VisitDetails = ({
    assessment, seteditassessmentModal, VisitModal, handlevisitCancel, setVisit,
    approveAsessment, setStatusModal, generateReport, generateLabRequest, generateAllograftReport, isExecuting, isPrinting, isLabPrinting, isAllograftPrinting, masterListRaw, setShowAuditDialog,
    generateLabReport
}) => {
    const dispatch = useDispatch()
    const can_approve_assessment = usePermissionCheck('approve_assessment', false, [])
    const can_update_assessment = usePermissionCheck('update_assessment', false, [])
    const can_view_hl7 = usePermissionCheck('view_hl7', false, [])
    const can_printReport = usePermissionCheck(['print_reports', 'view_assessment'], true, [])
    const [masterLocations] = useState(masterListRaw[0] ? masterListRaw.find(item => item.system_name === 'wound_location')?.options : [])
    const [woundLocations] = useState((!!masterLocations && masterLocations[0]) ? makeAutoCompleteOptions(...[masterListRaw.find(item => item.system_name === 'wound_location')?.options]) : [])

    const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
    useEffect(() => {
        // fetchWoundLocations(setwoundLocations)
        fetchvitals()
        return emptyFun
    }, [assessment])

    const [vitals, setvitals] = useState(null)
    const fetchvitals = async () => {
        const response = await apiRequest.get(`/patient-vitals?appointment=${assessment.appointment_id}`).catch(err => console.log(err))
        if (response && response.data && response.data.data) {
            setvitals(response.data.data[0])
        }
    }
    const checkAssessment = (assessment) => (assessment && !assessment.review) || (assessment && assessment.review && assessment.review.status === 'pending_review' && can_approve_assessment);
    return (
        (<Modal
            title={
                <div
                    className="flexy-row"
                    style={{ marginRight: '30px', padding: 0 }}
                >
                    <Headline6>Visit Details</Headline6>
                    <div className="flex flex-row gap-2">
                        {can_view_audit_logs &&
                            assessment
                            ? outlinedButton(
                                () => setShowAuditDialog(true),
                                'Audit',
                                false,
                                null,
                                { icon: <AuditOutlined /> }
                            )
                            : null}
                        {can_update_assessment &&
                            assessment
                            ? outlinedButton(
                                () => seteditassessmentModal(true),
                                'Edit',
                                false,
                                null,
                                { icon: <EditOutlined /> }
                            )
                            : null}
                        {/* {can_view_hl7 && assessment && assessment.published_at
              ? containedButton(
                () => dispatch(retriggerHl7(assessment)),
                'Retrigger HL7',
                false,
                null,
                { marginLeft: 10 },
                { icon: <UndoOutlined /> }
              )
              : null} */}

                        {closeButton(handlevisitCancel, null, { type: 'text' })}
                    </div>
                </div>
            }
            open={VisitModal}
            closable={false}
            onCancel={handlevisitCancel}
            // closeIcon={modalClose(handlevisitCancel)}
            maskClosable={false}
            destroyOnClose
            width={1200}
            footer={[
                <div
                    className="entity-filters flex-boy-row"
                    style={{ justifyContent: 'flex-end' }}
                >
                    {horStack([
                        outlinedButton(() => setVisit(false), 'Cancel', isPrinting || isExecuting),
                        ...(checkAssessment(assessment) && assessment.published_at ?
                            [outlinedButton(() => setStatusModal(true), 'Decline', isPrinting || isExecuting)] : []),
                        ...((checkAssessment(assessment) && assessment.published_at && can_approve_assessment)
                            ? [containedButton(approveAsessment, 'Approve', isExecuting, 'Approving')] : []),
                        ...(can_printReport && assessment.procedure.includes('Biologics Application') &&
                            assessment.published_at ? [containedButton(() => generateAllograftReport(), 'Allograft Tracking', isAllograftPrinting, 'Printing')]
                            : []),
                        ...(can_printReport && assessment.procedure !== 'Wound Evaluation' &&
                            assessment.published_at &&
                            !assessment.procedure.includes('Biologics Ordering')
                            ? [containedButton(() => generateReport(), 'Print Report', isPrinting, 'Printing')] : []),
                        ...(can_printReport &&
                            (assessment.procedure === 'Wound Evaluation' && assessment.status === "Healed") &&
                            assessment.published_at ? [containedButton(() => generateReport(), 'Print Report', isPrinting, 'Printing')] : [])
                    ])}
                </div>
            ]}
        >
            <div>

                {assessment && (
                    <>
                        <Row gutter={16}>
                            <Col span={24}>
                                {assessment.review &&
                                    assessment.review.status === 'rejected' ? (
                                    <Alert
                                        style={{ color: 'red !important', marginBottom: '10px' }}
                                        message={`Decline Reason: ${assessment.review.notes}`}
                                        type="error"
                                        showIcon
                                    />
                                ) : null}
                                {assessment.review &&
                                    assessment.review.status === 'needs_review' ? (
                                    <Alert
                                        style={{ color: 'red !important', marginBottom: '10px' }}
                                        message={`Review Notes: ${assessment.review.notes}`}
                                        type="warning"
                                        showIcon
                                    />
                                ) : null}
                                <div className="card-box">
                                    <div className="flex-boy-row-space" style={{ width: '100%' }}>
                                        <Headline6>Assessment Details</Headline6>
                                        {assessment.review &&
                                            assessment.review.status === 'approved' ? (
                                            <div className="status-container">
                                                <div className="dot-circle-active" />
                                                <Subtitle1>Approved</Subtitle1>
                                            </div>
                                        ) : assessment.review &&
                                            assessment.review.status === 'rejected' ? (
                                            <div className="status-container">
                                                <div className="dot-circle-inactive" />
                                                <Subtitle1>Rejected</Subtitle1></div>
                                        ) : (
                                            <div className="status-container">
                                                <Indicator color="orange" />{' '}
                                                <Subtitle1>Waiting for Approval</Subtitle1>
                                            </div>
                                        )}
                                    </div>
                                    <Row gutter={48} style={{ width: '100%' }}>
                                        <Col span={8}>
                                            {boldKeyCapitalizedValue('Patient', assessment.appointment.patient.name)}
                                            {boldKeyCapitalizedValue('Visit', dayjs(assessment.appointment.start_at).format('MM/DD/YYYY'))}
                                            {boldKeyCapitalizedValue('Procedure', assessment.procedure)}
                                            {boldKeyCapitalizedValue('Consultant', assessment.appointment.consultant.name)}
                                        </Col>
                                        <Col span={6}>
                                            {boldKeyCapitalizedValue('Location', `${assessment.wound_position && assessment.wound_side ? assessment.wound_side : ''} ${assessment.wound_position ||
                                                ''} ${assessment.location || ''}`)}
                                            {boldKeyCapitalizedValue('Status', assessment.status)}
                                            {boldKeyCapitalizedValue('Classification', assessment.wound_type)}
                                            {boldKeyCapitalizedValue('Stage', assessment.stage)}
                                            {assessment.procedure.includes('Biologics') &&
                                                boldKeyCapitalizedValue('Treatment Number', assessment.treatment_number)}
                                            {/* {boldKeyCapitalizedValue('Exudate', assessment.exudate)}
                  {boldKeyCapitalizedValue('Exudate Type', assessment.exudate_type)} */}
                                        </Col>
                                        <Col span={10} style={{ justifyContent: 'flex-end' }}>
                                            <Image
                                                style={{ width: '150px' }}
                                                src={assessment.woundImage.url}
                                                height={150}
                                            />
                                            {assessment.woundLocationPath ? (
                                                <Image
                                                    src={assessment.woundLocationPath ? assessment.woundLocationPath.url : woundLocations.find((item) => item === assessment.location) ? woundLocations.find((item) => item === assessment.location).image : require('../../../assets/woundLoc.png')}
                                                    height={150}
                                                />
                                            ) : null}
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="visit-card"><Subtitle1>Addendum</Subtitle1><Body2 color="black">{assessment?.visit_summary || 'N/A'} </Body2></div>
                            </Col>
                            <Divider />
                            <Col span={7}>
                                <div className="visit-card">
                                    <Subtitle1>Dimensions</Subtitle1>
                                    {boldKeyCapitalizedValue('Length', `${parseFloat(assessment.length).toFixed(2)}cm`)}
                                    {boldKeyCapitalizedValue('Width', `${parseFloat(assessment.width).toFixed(2)}cm`)}
                                    {boldKeyCapitalizedValue('Depth', `${parseFloat(assessment.depth).toFixed(2)}cm`)}
                                    {boldKeyCapitalizedValue('Area', `${parseFloat(assessment.area).toFixed(2)}cm`, '2')}
                                    {boldKeyCapitalizedValue('Volume', `${parseFloat(assessment.volume).toFixed(2)}cm`, '3')}
                                    {(assessment.procedure === 'Wound Debridement' ||
                                        assessment.procedure.includes('Biologics')) && (
                                            <div style={{ marginTop: '20px' }}>
                                                <Subtitle1>Post Debridement Dimensions</Subtitle1>
                                                {boldKeyCapitalizedValue('Length', `${parseFloat(assessment.new_length).toFixed(2)}cm`)}
                                                {boldKeyCapitalizedValue('Width', `${parseFloat(assessment.new_width).toFixed(2)}cm`)}
                                                {boldKeyCapitalizedValue('Depth', `${parseFloat(assessment.new_depth).toFixed(2)}cm`)}
                                            </div>
                                        )}
                                </div>
                            </Col>
                            <Col span={17}>
                                <div className="visit-card">
                                    <Subtitle1>Wound Details</Subtitle1>
                                    <Row gutter={48} style={{ width: '100%' }}>
                                        <Col span={12}>
                                            {/* {boldKeyCapitalizedValue('Wound side', assessment.wound_side)} */}
                                            
                                            {boldKeyCapitalizedValue('Wound size', assessment.size)}
                                            {boldKeyCapitalizedValue('Wound Bed', assessment.wound_bed?.toString())}
                                            {boldKeyCapitalizedValue('Granulation Tissue', `${parseFloat(assessment.granulation_tissue).toFixed(2)}`)}
                                            {boldKeyCapitalizedValue('Necrotic Tissue', assessment.necrotic_tissue)}
                                            {boldKeyCapitalizedValue('FibrousTissue', assessment.fibrous_tissue)}
                                            {/* {boldKeyCapitalizedValue('Slough Tissue', assessment.slough_tissue)}
                      {boldKeyCapitalizedValue('Eschar Tissue', assessment.eschar_tissue)} */}
                                            {boldKeyCapitalizedValue('Exudate Amount', assessment.exudate_amount)}
                                            {boldKeyCapitalizedValue('Exudate Type', assessment.exudate_type)}
                                            {boldKeyCapitalizedValue('Periwound Color', assessment?.periwound_color)}
                                            {boldKeyCapitalizedValue('Diagnosis', assessment.diagnosis?.toString())}
                                            {!assessment.procedure.includes('Biologics') && boldKeyCapitalizedValue('Wound Condition', assessment.wound_condition)}
                                            {boldKeyCapitalizedValue('Wound Duration', assessment.wound_duration)}

                                            {(assessment.procedure === 'DME') && boldKeyCapitalizedValue('Response to Therapy', assessment.response_to_therapy)}

                                        </Col>
                                        <Col span={12}>
                                            {boldKeyCapitalizedValue('Wound edges', assessment.wound_edges)}
                                            {(assessment.procedure === 'Wound Debridement' || assessment.procedure === "Wound Evaluation") && boldKeyCapitalizedValue('Wound Dressing', assessment.wound_dressing)}
                                            {boldKeyCapitalizedValue('Undermining', assessment.undermining)}
                                            {boldKeyCapitalizedValue('Epithelialization', assessment.epithelialization)}
                                            {boldKeyCapitalizedValue('Tunneling', assessment.tunneling_size && assessment.tunneling_direction !== 'N/A' ? `${assessment.tunneling_size}cm at ${assessment.tunneling_direction}` : 'N/A')}
                                            {boldKeyCapitalizedValue('Sinus Tract', assessment.sinus_tract ? `${assessment.sinus_tract}cm` : 'N/A')}
                                            {boldKeyCapitalizedValue('Odor', assessment.odor)}

                                            {boldKeyCapitalizedValue('Infection', assessment.infection)}
                                            {boldKeyCapitalizedValue('Patient Symptoms', assessment.patient_symptoms?.toString()?.replaceAll(',', ', '))}
                                            {boldKeyCapitalizedValue('Clinical Signs of Infection', assessment.clinical_signs_of_infection?.toString()?.replaceAll(',', ', '))}
                                            {assessment.procedure.includes('Biologics') && boldKeyCapitalizedValue('Smoking history', assessment.smoking_history)}
                                            {boldKeyCapitalizedValue('Pain Level', assessment.pain_level)}
                                            {boldKeyCapitalizedValue('Exposed Structures', Array.isArray(assessment?.exposed_structures) ? assessment?.exposed_structures.join(', ') : assessment?.exposed_structures)}
                                            {assessment.procedure === 'Wound Debridement' && boldKeyCapitalizedValue('Debridement Type', assessment.debridement_type)}
                                            {boldKeyCapitalizedValue('Orders/Recommendations', assessment.other_interventions?.toString())}
                                            {boldKeyCapitalizedValue('Other Related Factors', assessment.other_related_factors?.toString() || 'N/A')}

                                            {(assessment.procedure === 'Wound Debridement' || assessment.procedure === 'Biologics Application') && boldKeyCapitalizedValue('Anesthesia Used', assessment.anesthesia_used)}
                                        </Col>

                                        {assessment.procedure === 'Wound Debridement' && (
                                            <>
                                                <Col span={12}>
                                                    <Subtitle1 style={{ marginBottom: '20px', marginTop: '20px' }}> Vascular Measurements </Subtitle1>
                                                    <div>
                                                        {boldKeyCapitalizedValue('Right DP', assessment.right_d_p)}
                                                        {boldKeyCapitalizedValue('Left DP', assessment.left_d_p)}
                                                        {boldKeyCapitalizedValue('Right PT', assessment.right_p_t)}
                                                        {boldKeyCapitalizedValue('Left PT', assessment.left_p_t)}
                                                        {boldKeyCapitalizedValue('Skin Temperature Right', assessment.skin_temperature_right)}
                                                        {boldKeyCapitalizedValue('Skin Temperature Left', assessment.skin_temperature_right)}
                                                        {boldKeyCapitalizedValue('Digital Hair Right', assessment.digital_hair_right)}
                                                        {boldKeyCapitalizedValue('Digital Hair Left', assessment.digital_hair_left)}
                                                    </div>
                                                </Col>

                                                <Col span={12}>
                                                    <Subtitle1 style={{ marginBottom: '20px', marginTop: '20px' }}> Neurology Measurements </Subtitle1>
                                                    <div>
                                                        {boldKeyCapitalizedValue('Monofilament Testing Right', assessment.monofilament_testing_right)}
                                                        {boldKeyCapitalizedValue('Monofilament Testing Left', assessment.monofilament_testing_left)}
                                                        {boldKeyCapitalizedValue('Pin Prick Right', assessment.pin_prick_right)}
                                                        {boldKeyCapitalizedValue('Pin Prick Left', assessment.pin_prick_left)}
                                                        {boldKeyCapitalizedValue('Soft Touch Right', assessment.soft_touch_right)}
                                                        {boldKeyCapitalizedValue('Soft Touch Left', assessment.soft_touch_left)}
                                                    </div>
                                                </Col>
                                            </>
                                        )}
                                        {assessment.procedure.includes('Biologics') && (
                                            <>
                                                <Col span={24}>
                                                    <Subtitle1 style={{ marginBottom: '20px', marginTop: '20px' }}> Assessment Observations </Subtitle1>
                                                    <div>
                                                        {assessment.treatment_number !==
                                                            'Pre-treatment Assessment - Upper Extremity' && boldKeyCapitalizedValue('Circulation', assessment.circulation)}
                                                        {boldKeyCapitalizedValue('Comorbidites', assessment.comorbidities)}
                                                        {boldKeyCapitalizedValue('Primary Care Physician', assessment.primary_care_physician)}
                                                        {boldKeyCapitalizedValue('Conservative Treatment', assessment.conservative_treatment?.toString())}
                                                        {assessment.treatment_number !== 'Pre-treatment Assessment - Upper Extremity' && boldKeyCapitalizedValue('Neurologic Status', assessment.neurologic_status)}
                                                        {boldKeyCapitalizedValue('Wound Duration', assessment.wound_duration)}
                                                    </div>
                                                </Col>
                                            </>
                                        )}

                                        <Col span={12}>
                                            {(assessment.procedure === 'Wound Debridement' || assessment.procedure.includes('Biologics')) && <Subtitle1
                                                style={{ marginBottom: '20px', marginTop: '20px' }}
                                            >
                                                Wound Debridement</Subtitle1>}
                                            {(assessment.procedure === 'Wound Debridement' || assessment.procedure.includes('Biologics')) &&
                                                boldKeyCapitalizedValue('Debridement Methods', assessment.debridement_methods?.toString())}
                                            {(assessment.procedure === 'Wound Debridement' || assessment.procedure.includes('Biologics')) &&
                                                boldKeyCapitalizedValue('Tissue Debrided', assessment.tissue_debrided?.toString())}
                                            {(assessment.procedure === 'Wound Debridement' || assessment.procedure.includes('Biologics')) &&
                                                boldKeyCapitalizedValue('Instrument Used', assessment.instruments_used?.toString())}
                                            {(assessment.procedure === 'Wound Debridement' || assessment.procedure.includes('Biologics')) &&
                                                boldKeyCapitalizedValue('Level of Debridement', assessment.level_of_debridement?.toString())}
                                            {(assessment.procedure === 'Wound Debridement' || assessment.procedure.includes('Biologics')) &&
                                                boldKeyCapitalizedValue('Hemostasis', assessment.hemostasis?.toString())}
                                            {assessment.procedure === 'Wound Debridement' &&
                                                boldKeyCapitalizedValue(' Wound Cleanser', assessment.wound_cleanser?.toString())}
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Divider />
                            {assessment.procedure !== 'Wound Debridement' &&
                                !assessment.procedure.includes('Lab') &&
                                assessment.procedure !== 'Wound Evaluation' ? (
                                <>
                                    <Col span={8}>
                                        <div className="visit-card">
                                            <Subtitle1>Treatment Orders</Subtitle1>
                                            {assessment.procedure.includes('Biologics') ? (
                                                <>
                                                    <Subtitle1>Biologics</Subtitle1>
                                                    {(() => {
                                                        if (assessment?.biologic_kits && !!assessment.biologic_kits[0]) {
                                                            let productNames = [];
                                                            let assessmentKits_ = [];
                                                            for (const bioKit of assessment?.biologic_kits) {
                                                                let name = `${bioKit.biologic_product.company_name} ${bioKit.biologic_product.product_name}`;
                                                                if (!productNames.includes(name)) {
                                                                    let productKits = assessment.biologic_kits.filter((item) => `${item.biologic_product.company_name} ${item.biologic_product.product_name}` === name);
                                                                    let biologic_kits = productKits.map(
                                                                        (item) => ({
                                                                            id: item.id, size: item.size, quantity: item.quantity, biologic_kit_code: item.biologic_kit_code,
                                                                            ...(!!item.tissue_id && { tissue_id: item.tissue_id }),
                                                                            ...(!!item.serial_number && { serial_number: item.serial_number }),
                                                                            ...(!!item.ocr_data && !!item.ocr_data[0] && { ocr_data: [...item.ocr_data] })
                                                                        })
                                                                    );
                                                                    assessmentKits_ = [...assessmentKits_, { product: name, biologic_kits }];
                                                                    productNames.push(name);
                                                                }
                                                            }
                                                            return assessmentKits_.map((bioKit) => {
                                                                return (
                                                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                                                        <p className={'text-sm font-semibold'}>{bioKit.product}:{' '}</p>
                                                                        {bioKit?.biologic_kits.map((item, i) => {
                                                                            // console.log('ocr:  ', item)
                                                                            // console.log('!item.ocr_data:  ', !item.ocr_data)
                                                                            // <Typography key={`${i}`} variant={"body2"}>{`${item.biologic_kit_code}`}:&nbsp;     &nbsp;{`${item.size}`}&nbsp;     &nbsp;{`Quantity: ${item.quantity}`}</Typography>
                                                                            return (
                                                                                <Row
                                                                                    gutter={[4, 1]}
                                                                                    align={'stretch'}
                                                                                    justify={'start'}
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        overflowWrap: 'break-word'
                                                                                    }}
                                                                                >
                                                                                    <Col
                                                                                        xs={24}
                                                                                        sm={24}
                                                                                        md={8}
                                                                                        lg={8}
                                                                                        xl={8}
                                                                                        flex={'auto'}
                                                                                        style={{ width: '100%' }}
                                                                                    >
                                                                                        <p className={'text-sm mt-6'}>{`${item.biologic_kit_code}`}: </p>
                                                                                    </Col>
                                                                                    <Col
                                                                                        xs={24}
                                                                                        sm={24}
                                                                                        md={16}
                                                                                        lg={16}
                                                                                        xl={16}
                                                                                        flex={'auto'}
                                                                                        style={{ width: '100%' }}
                                                                                    >
                                                                                        <div style={{ width: '100%' }}>
                                                                                            {boldKeyCapitalizedValue(
                                                                                                'Size:',
                                                                                                `${item.size}`
                                                                                            )}
                                                                                            {boldKeyCapitalizedValue(
                                                                                                'Quantity:',
                                                                                                `${item.quantity}`
                                                                                            )}
                                                                                            {assessment.procedure.includes(
                                                                                                'Application'
                                                                                            ) &&
                                                                                                !item.ocr_data &&
                                                                                                !!item.tissue_id &&
                                                                                                boldKeyCapitalizedValue(
                                                                                                    'Tissue ID:',
                                                                                                    `${item.tissue_id || 'N/A'}`
                                                                                                )}
                                                                                            {assessment.procedure.includes(
                                                                                                'Application'
                                                                                            ) &&
                                                                                                !item.ocr_data &&
                                                                                                !!item.serial_number &&
                                                                                                boldKeyCapitalizedValue(
                                                                                                    'Serial No:',
                                                                                                    `${item.serial_number ||
                                                                                                    'N/A'}`
                                                                                                )}
                                                                                            {assessment.procedure.includes(
                                                                                                'Application'
                                                                                            ) &&
                                                                                                !!item.ocr_data &&
                                                                                                item.ocr_data.map((ocr) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            {boldKeyCapitalizedValue(
                                                                                                                'Serial No:',
                                                                                                                `${ocr.serial_number ||
                                                                                                                'N/A'}`
                                                                                                            )}
                                                                                                            {boldKeyCapitalizedValue(
                                                                                                                'Tissue ID:',
                                                                                                                `${ocr.tissue_id ||
                                                                                                                'N/A'}`
                                                                                                            )}
                                                                                                            <Divider />
                                                                                                        </>
                                                                                                    );
                                                                                                })}
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Divider style={{ margin: 4 }} />
                                                                                </Row>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                );
                                                            });
                                                        } else return 'N/A';
                                                    })()}
                                                </>
                                            ) : (
                                                <>
                                                    <Subtitle1>
                                                        Scenario:{' '}
                                                        {assessment.recommended_treatment_protocol
                                                            ? assessment.recommended_treatment_protocol
                                                            : null}
                                                    </Subtitle1>
                                                    <Subtitle1 color="grey">Primary Dressing</Subtitle1>
                                                    {assessment.treatment_order_items
                                                        .filter((item) => item.type === 'Primary Dressing')
                                                        .map((item, i) => (
                                                            <div
                                                                className="flex-boy-row-space"
                                                                style={{ width: '100%' }}
                                                                key={`${i}`}
                                                            >
                                                                <Body2
                                                                    color="black"
                                                                    style={{ textAlign: 'left', flex: 1, lineHeight: '21px' }}
                                                                >
                                                                    {item.name} ({item.pack_amount} {item.unit_of_measure})
                                                                </Body2>
                                                                <Body2 color="black" style={{ textAlign: 'left', lineHeight: '21px', flex: 'initial' }}>
                                                                    {item.unit_count}{' '}
                                                                    {item.unit_of_measure === 'box' ? 'boxe' : item.unit_of_measure === 'oz' ? 'oz' : item.unit_of_measure}{item.unit_count > 1 && "s"}
                                                                </Body2>
                                                            </div>
                                                        ))}
                                                    <Subtitle1 color="grey">Secondary Dressing</Subtitle1>
                                                    {assessment.treatment_order_items.filter((item) => item.type === 'Secondary Dressing').map((item, i) => (
                                                        <div
                                                            className="flex-boy-row-space"
                                                            style={{ width: '100%' }}
                                                            key={`${i}`}
                                                        >
                                                            <Body2
                                                                color="black"
                                                                style={{
                                                                    textAlign: 'left',
                                                                    flex: 1,
                                                                    lineHeight: '21px'
                                                                }}
                                                            >
                                                                {item.name} ({item.pack_amount} {item.unit_of_measure})
                                                            </Body2>
                                                            <Body2
                                                                color="black"
                                                                style={{
                                                                    textAlign: 'left',
                                                                    lineHeight: '21px',
                                                                    flex: 'initial'
                                                                }}
                                                            >
                                                                {item.unit_count}{' '}
                                                                {item.unit_count > 1 && item.unit_of_measure === 'Box'
                                                                ? 'Boxe' :
                                                                    item.unit_of_measure === 'Bottle(s)'
                                                                        ? 'Bottle'
                                                                        : item.unit_of_measure === 'Oz'
                                                                            ? 'oz'
                                                                            : item.unit_of_measure}
                                                                {item.unit_count > 1 && "s"}
                                                            </Body2>
                                                        </div>
                                                    ))}
                                                    <Subtitle1 color="grey">Additional Supplies</Subtitle1>
                                                    {assessment.treatment_order_items.filter((item) => item.type === 'Additional Dressing').map((item, i) => (
                                                        <div
                                                            className="flex-boy-row-space"
                                                            style={{ width: '100%' }}
                                                            key={`${i}`}
                                                        >
                                                            <Body2
                                                                color="black"
                                                                style={{
                                                                    textAlign: 'left',
                                                                    flex: 1,
                                                                    lineHeight: '21px'
                                                                }}
                                                            >
                                                                {item.name} ({item.pack_amount} {item.unit_of_measure})
                                                            </Body2>
                                                            <Body2
                                                                color="black"
                                                                style={{
                                                                    textAlign: 'left',
                                                                    lineHeight: '21px',
                                                                    flex: 'initial'
                                                                }}
                                                            >
                                                                {item.unit_count}{' '}
                                                                {item.unit_count > 1 && item.unit_of_measure === 'Box'
                                                                     ? 'Boxe' :
                                                                    item.unit_of_measure === 'Bottle(s)'
                                                                        ? 'Bottle'
                                                                        : item.unit_of_measure === 'oz'
                                                                            ? 'oz'
                                                                            : item.unit_of_measure}
                                                                {item.unit_count > 1 && "s"}
                                                            </Body2>
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <div className="visit-card">
                                            {assessment.procedure.includes('Biologics') ||
                                                assessment.procedure === 'Wound Evaluation' ? null : (
                                                <Headline6>Plan of care</Headline6>
                                            )}
                                            <Row gutter={48} style={{ width: '100%' }}>
                                                <Col span={24}>
                                                    {assessment.procedure.includes('Biologics') ? null : (
                                                        <>
                                                            {assessment.recommended_treatment_protocol ===
                                                                'Custom Kit'
                                                                ? assessment.plan_of_care_items.map(
                                                                    (step, index) => (
                                                                        <div
                                                                            key={index}
                                                                            className="order-item flex-boy-row-space"
                                                                        >
                                                                            <Checkbox checked={true} disabled>
                                                                                {' '}
                                                                            </Checkbox>
                                                                            <div
                                                                                className="flex-boy-row-space"
                                                                                style={{
                                                                                    flex: 1,
                                                                                    background: '#F1F2F5',
                                                                                    border: '1px solid #F1F2F5',
                                                                                    boxSizing: 'border-box',
                                                                                    borderRadius: '4px',
                                                                                    height: '30px'
                                                                                }}
                                                                            >
                                                                                <Subtitle1>{step.step}</Subtitle1>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )
                                                                : _.orderBy(assessment.plan_of_care_items, 'step_number_order').map((step, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className="order-item flex-boy-row-space"
                                                                    >
                                                                        <Checkbox checked={true} disabled>
                                                                            {' '}
                                                                        </Checkbox>
                                                                        <div
                                                                            className="flex-boy-row-space"
                                                                            style={{
                                                                                flex: 1,
                                                                                background: '#F1F2F5',
                                                                                border: '1px solid #F1F2F5',
                                                                                boxSizing: 'border-box',
                                                                                borderRadius: '4px',
                                                                                height: '30px'
                                                                            }}
                                                                        >
                                                                            <Subtitle1>{step.step}</Subtitle1>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </>
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Divider />
                                </>
                            ) : null}
                            {assessment.procedure.includes("Lab") && (
                                <Col span={24}>
                                    <>
                                        <Subtitle1 style={{ marginBottom: '20px' }}>
                                            Lab Tests
                                        </Subtitle1>
                                        <Row gutter={48}>
                                            {assessment.lab_tests.map((item) => {
                                                return (
                                                    <Col span={12}>
                                                        <div className="visit-card">
                                                            {keyValItemCaps('Sample Id', item.sample_id)}
                                                            {keyValItemCaps('PCR Type', item.pcr_type.toString())}
                                                            {keyValItemCaps('Lab Region', item.lab_region)}
                                                            <div className="flex flex-row justify-between w-full items-center my-2">
                                                                <div className="flex-1">
                                                                    <Subtitle1>Lab Requisition</Subtitle1>
                                                                </div>

                                                                <div className='flex-1'>
                                                                    <Button type={'default'} size={'middle'} onClick={() => generateLabRequest()}
                                                                        loading={isLabPrinting} icon={<FontAwesomeIcon icon={["fas", "download"]} />}>{isLabPrinting ? "Downloading" : "Download"}
                                                                    </Button>
                                                                </div>

                                                            </div>

                                                            {keyValItemCaps('Intake Date', dayjs(item.intake_date).format("MM-DD-YYYY"))}
                                                            {keyValItemCaps('Status', item.status)}
                                                            {assessment.lab_tests?.[0]?.report?.url && <p className='mt-2 mb-1 font-bold'>Lab results</p>}
                                                            {assessment.lab_tests?.[0]?.results_reviewed_at && keyValItemCaps('Reviewed', dayjs(assessment.lab_tests?.[0]?.results_reviewed_at).format('MM-DD-YYYY'))}
                                                            {assessment.lab_tests?.[0]?.reviewed_by && keyValItemCaps('Reviewed By', assessment.appointment.consultant.name)}


                                                            {assessment.lab_tests?.[0]?.report?.url && <div className="flex flex-row justify-between w-full items-center">
                                                                <div className="flex-1">
                                                                    <Subtitle1>Lab Report</Subtitle1>
                                                                </div>

                                                                <div className='flex-1'>
                                                                    <Button type={'default'} size={'middle'} 
                                                                    onClick={(e) => {
                                                                        generateLabReport(assessment.lab_tests?.[0]?.report?.url)

                                                                    }}
                                                                        icon={<FontAwesomeIcon icon={["fas", "download"]} />}
                                                                    >
                                                                   Download
                                                                    </Button>
                                                                    {/* {containedButton(() => generateReport(), 'Print Report', isPrinting, 'Printing')} */}
                                                                </div>

                                                            </div>}

                                                        </div>
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </>
                                </Col>
                            )}
                            <Divider />
                            <Col span={8}>
                                {vitals && (
                                    <div className="visit-card">
                                        <Subtitle1>Vitals</Subtitle1>
                                        {boldKeyCapitalizedValue('Weight', vitals.weight || 'N/A')}
                                        {boldKeyCapitalizedValue('Height', vitals.height || 'N/A')}
                                        {boldKeyCapitalizedValue('Respiration', vitals.respiration || 'N/A')}
                                        {boldKeyCapitalizedValue('Heart Rate(BPM)', vitals.heart_rate || 'N/A')}
                                        {boldKeyCapitalizedValue('BMI', `${vitals.weight && vitals.height ? ((parseFloat(vitals.weight) / parseFloat(vitals.height) ** 2) * 703).toFixed(2) : 'N/A'}`)}
                                        {boldKeyCapitalizedValue('Blood Pressure(Systolic)', vitals.blood_pressure || 'N/A')}
                                        {boldKeyCapitalizedValue('Blood Pressure(Dystolic)', vitals.diastolic_blood_pressure || 'N/A')}
                                        {boldKeyCapitalizedValue('Scanned', 'Yes')}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        </Modal>)
    );
}
const mapStateToProps = (state) => ({
    masterListRaw: state.masters.masterListRaw, activeUser: state.auth.activeUser,
    assessment: state.appointments.selectedAssessment
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(VisitDetails)
