import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Divider, Form, Modal, Radio, Tag} from 'antd'
import React, {useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {updateOrder} from '../../../../app/Reducers'
import {dialogCloser, editButton, formSubmitButton, horStack, modalClose, outlinedButton} from '../../../../common/helpers'
import {Subtitle1} from '../../../../components'
import _ from "lodash"
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';

export const ShippingAddress = ({ order, patient, consultant, isPatient }) => {
  const [addressForm, setAddressForm] = useState(false)
  const can_update_treatment_order = usePermissionCheck('update_treatment_order', false, [])
  const dispatch = useDispatch()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderAddress, setorderAddress] = useState(order?.shipping_address_1)
  const onFinish = async (values) => {
    //console.log(values)
    let payload = {
      ...order,
      shipping_address_1: values.address.physical_address ? `${values.address.physical_address}-${values.address.street_address}` : values.address.street_address,
      state: values.address.state,
      city: values.address.city,
      shipping_address_2: values.address.physical_address || values.address.city || "",
      consultant_id: consultant.id,
      biologic_kits: order?.biologic_kits.map(kit => {
        return ({
          "id": kit.id,
          "biologic_product_id": kit.biologic_product.id,
          "quantity": kit.quantity
        })
      })
    }
    await dispatch(updateOrder(_.omitBy(payload, v => _.isNil(v) || (_.isArray(v) && !v.length))))
    setAddressForm(false)
  }
  const postShippedStatuses = ["shipped", "in transit", "delivered", "label created"]
  return (
    (<div className="shadow-card-box" style={{ minHeight: "40px" }}>
      <Modal
        title={"Change Shipping Address"}
        open={addressForm}
        onOk={dialogCloser(setAddressForm)}
        destroyOnClose
        onCancel={dialogCloser(setAddressForm)}
        closeIcon={modalClose(dialogCloser(setAddressForm))}
        
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setAddressForm), 'Cancel', isSubmitting),
            formSubmitButton('change-address', "Save", isSubmitting, 'Updating')])}
        </div>]}>
        <Form
          initialValues={{ address: order?.shipping_address_1 }}
          name="change-address"
          onFinish={onFinish}>
          
          <Form.Item
            name="address"
          
          >
            <Radio.Group>
              {order?.patient &&
                <>
                  <Subtitle1 style={{ textAlign: 'left', marginBottom: '10px' }}>Patient Addresses:</Subtitle1>
                  {patient?.addresses?.map(item => {
                    return (
                      <Radio value={item}>
                        <div className="flex-boy-row-space">
                          <span>{item.physical_address ? `${item.physical_address} -` : null}{item.street_address}</span>
                          {item.default && <div style={{ textAlign: 'left' }}><Tag style={{ textAlign: 'left' }} color="#108ee9">Default</Tag></div>}
                        </div>
                      </Radio>
                    )
                  })}
                  <Divider/>
                </>}
              {!isPatient &&
                <>
                  <Subtitle1 style={{ textAlign: 'left', marginBottom: '10px' }}>Consultant Addresses:</Subtitle1>
                  {consultant?.addresses?.map(item => {
                    return (
                      <Radio value={item}>
                        <div className="flex-boy-row-space">
                          <span>{item.physical_address ? `${item.physical_address} -` : null}{item.street_address}</span>
                          {item.default && <div style={{ textAlign: 'left' }}><Tag style={{ textAlign: 'left' }} color="#108ee9">Default</Tag></div>}
                        </div>
                      </Radio>
                    )
                  })}
                </>
              }
            
            
            </Radio.Group>
          </Form.Item>
        </Form>
      
      </Modal>
      <div className="flex-boy-row-space  px-2 w-full" >
        <Subtitle1>Shipping Address</Subtitle1>
        {(!postShippedStatuses.some(item => order?.status === item) && can_update_treatment_order) && editButton(() => setAddressForm(true))}
      </div>
      <div className="bg-[#F7F8F9] w-full text-start flex py-1 rounded-lg px-2"><p className={'my-auto '}>{order?.shipping_address_1}</p></div>
    </div>)
  );
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddress)
