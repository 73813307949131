import React, {useEffect, useState} from 'react';
import {Headline6} from "../../../components";
import {Avatar, Form, Input, Modal} from "antd";
import {deleteAppointment, fetchPatientAppointments} from "../../../app/Reducers";
import {addButtonClean, dialogCloser, emptyFun, emptyTable, formSubmitButton, horStack, kebabMenus, modalClose, outlinedButton} from "../../../common/helpers";
import {updatePatientAppointmentsFilters} from "../../../app/Reducers";
import dayjs from "dayjs";
import {connect, useDispatch} from "react-redux";

import {setPatientAppointmentsLoaded} from "../../../app/Reducers";
import SheduleAppointment from "../../Appointments/components/ScheduleAppointment";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import {apiRequest} from "../../../app/Apis";
import {RightOutlined} from '@ant-design/icons';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';

function NextAppointments({ appConf, patient, appointments, setContent }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate();
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [isOpen, setOpen] = useState(false);
  const [isExecuting, setisExecuting] = useState(false)
  const [editingForm, setEditingForm] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [, setpatientAppointments] = useState([])
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  usePermissionCheck('view_appointment', false, []);
  const can_delete_appointment = usePermissionCheck('delete_appointment', false, [])
  const can_create_appointment = usePermissionCheck('create_appointment', false, [])
  
  
  
  const [, setSelected] = React.useState(null);
  
  const [, setConfirmLoading] = React.useState(false);
  
  
  kebabMenus((can_update_patient_resource), can_view_audit_logs, can_delete_appointment);
  
  
  const [appointmentTodeleteID] = useState(null)
  
  const [reasonModal, setreasonModal] = useState(false)
  const reasonFinish = async (values) => {
    await setConfirmLoading(true);
    await dispatch(deleteAppointment({ appointment_id: appointmentTodeleteID, ...values, patient_id: patient.id }));
    await setSelected(0);
    await setConfirmLoading(false);
    setreasonModal(false)
  }
  
  useEffect(() => {
    fetchPatientAppointmentsWithWound().catch(e => console.log(e))
    return emptyFun
  }, [patientId])
  
  const { message } = useSubscription(`view_patient_appointments/${patientId}`, { qos: 2, nl: true });
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await loadAppointments(true)
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  const loadAppointments = async (isMQMessage) => {
    /*if (!!appointments[0] && !isMQMessage && appointments.every(item => item?.patient?.id === patientId)) {
        return
    }*/
    await dispatch(updatePatientAppointmentsFilters([appConf.patientAppointmentsFilters[0], appConf.patientAppointmentsFilters[1], !isMQMessage]))
    await dispatch(fetchPatientAppointments(appConf.patientAppointmentsFilters[0] ? appConf.patientAppointmentsFilters[1] : patientId))
    await dispatch(updatePatientAppointmentsFilters([appConf.patientAppointmentsFilters[0], appConf.patientAppointmentsFilters[1], false]))
    await dispatch(setPatientAppointmentsLoaded(patientId))
  }
  useEffect(() => {
    (async () => await loadAppointments(false))().catch(e => console.log(e))
    return emptyFun
  }, [])
    
    const fetchPatientAppointmentsWithWound = async () => {
    //todo request new endpoint
    const wounds = await apiRequest.post(`/patient-established-visit/${patientId}/wounds`)
    if (wounds && wounds.data.data) {
      console.log(wounds.data.data)
      await setpatientAppointments(wounds.data.data)
    }
  }
  
  
  return (
    (<div className="card-box">
      {(!!appointment && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false}
                                                             resourceDescription={`${appointment.patient.name}'s appointment`} resourceType={'Appointment'}
                                                             defaultValues={{ auditableId: appointment?.id, auditable: 'appointment', format: 'json' }}/>}
      <div className="flex-boy-row-space" style={{ width: "100%" }}>
        <Modal
          
          title={editingForm ? 'Edit appointment' : "Add appointment"}
          open={isOpen}
          onCancel={dialogCloser(setOpen)}
          closeIcon={modalClose(dialogCloser(setOpen))}
          maskClosable={false}
          destroyOnClose={true}
          width={650}
          footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([
              outlinedButton(dialogCloser(setOpen), 'Cancel', isExecuting),
              formSubmitButton('appointmentForm', editingForm ? 'Update' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
          </div>]}>
          <SheduleAppointment appointment={appointment} editingForm={editingForm} patient={patient}
                              setisExecuting={setisExecuting} closeModal={dialogCloser(setOpen)}/>
        </Modal>
        <Headline6>Appointments</Headline6>
        <div className="flex flex-row items-center">
          {((patient.status === 'active' || patient.status === "hospitalized") && can_update_patient_resource && can_create_appointment && !patient.deleted_at) && addButtonClean(() => {
            setEditingForm(false)
            setOpen(true);
          })}
          <RightOutlined onClick={() => setContent("Appointments")}/>
        </div>
      </div>
      <Modal
        title={`Please add a Reason`}
        open={reasonModal}
        destroyOnClose={true}
        
        onOk={() => setreasonModal(false)}
        onCancel={() => setreasonModal(false)}
        closeIcon={modalClose(() => setreasonModal(false))}
        maskClosable={false}
        width={1200}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(() => {
            setreasonModal(false)
            setSelected(0)
          }, 'Cancel', isExecuting),
            formSubmitButton('reasonForm', 'Add Reason', isExecuting, 'Updating')])}
        </div>]}
      >
        <Form
          name="reasonForm"
          onFinish={reasonFinish}
          layout="vertical"
        >
          
          <Form.Item
            style={{ width: '100%' }}
            label="Delete Reason"
            name="delete_reason"
            rules={[{ required: true, message: 'this field is required!' }]}
            wrapperCol={{ span: 24 }}
          >
            <Input autoComplete={'off'}/>
          </Form.Item>
        </Form>
      
      </Modal>
      {appointments.length ? appointments.slice(0, 3).map(item => {
        return (
          <div onClick={async () => {
            await setAppointment(item)
            await setEditingForm(true)
            await setOpen(true)
            
          }} className="rounded w-full p-2 my-2" key={item.id} style={{ background: '#ebeff4' }}>
            <p
              className="text-left text-xs  font-semibold">{dayjs(item.start_at).format("DD MMM YYYY")} / {dayjs(item.start_at).format("h:mm a")} - {dayjs(item.end_at).format("h:mm a")} ({dayjs(item.end_at).diff(dayjs(item.start_at), 'minutes') / 60 >= 1 ? dayjs(item.end_at).diff(dayjs(item.start_at), 'hours') : dayjs(item.end_at).diff(dayjs(item.start_at), 'minutes')} {dayjs(item.end_at).diff(dayjs(item.start_at), 'minutes') / 60 >= 1 ? "h" : "min"} ) </p>
            <div className="flex flex-row w-full mt-2 justify-between items-center">
              <div className="flex flex-row items-center">
                <Avatar className="mr-2"/>
                <p>{item.consultant?.name}</p>
              </div>
              <p>{item.appointment_visit_type}</p>
            </div>
          
          </div>
        )
      }) : <div className="flex  mx-auto justify-center" style={{ height: "180px", width: '180px', marginBottom: '25px' }}> {emptyTable()} </div>}
    </div>)
  );
}

const mapStateToProps = (state) => ({ patient: state.patients.selectedPatient,
  patientAppointmentPagination: state.patients.patientAppointmentPagination, activeUser: state.auth?.activeUser,
  appointments: state.patients.patientAppointments, appConf: state.appConf, navs: state.navs, appWindow: state.appWindow
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(NextAppointments)
