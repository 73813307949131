/* eslint-disable no-unused-vars,react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Col, Dropdown, Form, Grid, Input, Row, Space} from "antd";
import {appLog, createFormInputsFromTableColumns, dynamicFormOnFinish, emptyFun, getDynamicFormDefaultValues, getInputField, useOnClickOutside} from "../../common/helpers";
import {CaretDownOutlined, FilterOutlined, RedoOutlined} from "@ant-design/icons";
import _ from "lodash";

const { useBreakpoint } = Grid;
/**
 *@param datasource is the columns used for the table
 *@param searchOnly
 *@param setIsVisible is used to toggle visibility of the filter dropdown form
 *@param isVisible is whether the filter is visible or not
 *@param handleFilterAction is used to trigger filtering in the calling component
 *@param showClear is used to decide whether to show "Clear Filters" button
 *@param loading shows whether filter API is still loading
 *@param filters are any currently persisted filters to autofill
 *@param actionButton is the optional far right component in the Filters component , mostly a button, can be any component
 *@param extraLeftActions are the optional extra components added after the Refresh button in the Filters component. see DuplicateWounds component
 *@param inputFields is only passed when using form inputs as filter body, not table columns, like in Diagnosis section
 * @param props are any extra props
 * */
export const TableFilters = ({ datasource, searchOnly, setIsVisible, isVisible, handleFilterAction, showClear, loading, filters, actionButton, extraLeftActions, inputFields, ...props }) => {
  const ref = useRef();
  const { Search } = Input;
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";
  const point = useBreakpoint()
  const [searchVal, setsearchVal] = useState(null)
  const [searchLoading, setsearchLoading] = useState(false)
  const [datePopupOpen, setDatePopupOpen] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const [showSearch, setShowSearch] = useState(true)
  const [phoneValue, setPhoneValue] = useState(undefined);
  const [filterData, setFilterData] = useState(showClear && !!filters ? { ...filters.filterData } : {})
  const [filterHasData, setFilterHasData] = useState(showClear && showClear && !!filters?.filterData)
  // console.log({point})
  // in case of inputFields, add dropdown checks like this to maintains form states/listeners from parent Component. Do not do {...inputFields}
  if (!!inputFields) {
    for (const item of inputFields.fields) {
      if (!item.props) {
        item.props = {}
      }
      if (item.type === 'autocomplete') {
        item.props.onDropdownVisibleChange = open => setPopupOpen(open)
      }
      if (item.type === 'select') {
        item.props.onDropdownVisibleChange = open => setPopupOpen(open)
      }
      if (item.type === 'phone') {
        item.props.setSelectPopupOpen = open => setPopupOpen(open)
      }
      if (item.type === 'date') {
        item.props.onOpenChange = open => setDatePopupOpen(open)
      }
      if (item.type === 'date_range') {
        item.props.onOpenChange = open => setDatePopupOpen(open)
      }
    }
  }
  const [formInputs, setFormInputs] = useState(!!inputFields ? inputFields : { fields: [] })
  useEffect(() => {
    (async () => {
      if (!!datasource[0] && !inputFields) {
        let formInputs = createFormInputsFromTableColumns(true, datasource, phoneValue, setPhoneValue, setPopupOpen, setDatePopupOpen, dateFormat)
        await setFormInputs(formInputs)
      } else if (!!inputFields) {
        await setFormInputs(inputFields)
      }
    })().catch(e => console.log(e))
    return emptyFun
  }, [datasource])
  useEffect(() => {
    setFilterHasData(showClear)
    return emptyFun
  }, [showClear])
  useEffect(() => {
    console.log({ isVisible })
    return emptyFun
  }, [isVisible])
  useEffect(() => {
    // console.log('Filters:::: ', filters)
    if (!!filters?.filterData) {
      setFilterData({ ...filters.filterData })
    }
    // console.log('filterData::::1 ', filterData)
    if (!isVisible) {
      return emptyFun
    }
    if (!filters) {
      form.setFieldsValue({})
      setFilterData({})
      return emptyFun
    }
    if (Object.keys(filters?.filterData).length > 0) {
      if (!filters?.filterData?.search) {
        let filterData_ = getDynamicFormDefaultValues(filters.filterData, formInputs,true)
        console.log('Setting: ', filterData_)
        setFilterData({ filterData_ })
        form.setFieldsValue({ ...filterData_ })
      } else {
        // console.log('Search: ')
        form.setFieldsValue({ ...(!!filters?.filterData?.search && { search: filters?.filterData?.search }) })
      }
    } else {
      form.setFieldsValue({})
    }
    return emptyFun
  }, [filters, showClear, isVisible])
  const onSearch = async value => {
    if (!!value && value.trim().length > 0) {
      setsearchLoading(true)
      await setFilterData({ search: value })
      handleFilterAction('filter', { search: value.trim() });
    }
    if (!value || value === '') {
      setsearchLoading(true)
      setFilterData({})
      clearFilters()
    }
  }
  const renderEmpty = () => (<div style={{ textAlign: 'center' }}>
    <FilterOutlined/>
    <p style={{ color: 'gray' }}>No filter criteria!</p>
  </div>)
  
  const filter = async () => {
    if (loading) {
      return
    }
    let values = { ...form.getFieldsValue(), ...phoneValue }
    // appLog({values})
    Object.keys(values).forEach(k => (!values[k] || values[k] === "") && delete values[k])
    // appLog({values})
    let filters = await dynamicFormOnFinish(formInputs, values, true)
    // appLog({filters})
    if (!!Object.keys(filters)[0]) {
      // filters.pagination = 1
      // console.log('filters: ', filters)
      handleFilterAction('filter', filters)
    } else if (Object.keys(filterData).length > 0) {
      handleFilterAction('filter', filterData)
    } else {
      clearFilters()
    }
  }
  const clearFilters = () => {
    setsearchVal(undefined)
    if (loading) {
      return
    }
    form.resetFields()
    setShowSearch(true)
    handleFilterAction('reset');
    setFilterData({})
  }
  const cancelFilter = () => {
    if (loading) {
      return
    }
    setShowSearch(true)
    handleFilterAction('cancel')
  }
  useOnClickOutside(ref, () => {
    if (!datePopupOpen && !popupOpen) {
      setIsVisible(false)
    }
  });
  const debouncedChangeHandler = useCallback(
    _.debounce(value => {
      onSearch(value).catch(e => console.log(e))
    }, 800),
    []
  );
  useEffect(() => {
    const keyDownHandler = event => {
      // console.log('User pressed: ', event.key);
      if (event.key === 'Enter' && isVisible) {
        event.preventDefault();
        filter().catch(console.log)
      }
    };
    
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [isVisible]);
  
  const [docHeight, setDocHeight] = useState(`${window.innerHeight * 0.8}px`);
  useEffect(() => {
    function handleResize() {
      setDocHeight(`${window.innerHeight * 0.8}px`)
    }
    
    window.addEventListener('resize', handleResize)
    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })
  return <div
    style={{
      display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: 0, paddingBottom: 0, paddingTop: 0, margin: 0, justifyContent: 'flex-start',
      alignItems: 'center', alignContent: 'center', width: '100%', justifyItems: 'center'
    }}>
    {!searchOnly && <Dropdown destroyPopupOnHide={true} dropdownRender={()=>
      <Row style={{
        width: '100%', background: 'white', borderStyle: 'solid', borderRadius: 6, borderColor: 'gainsboro', borderWidth: 1,
        boxShadow: '6px 6px 8px gainsboro', maxHeight: docHeight, overflowY: 'auto'
      }}>
        <Col flex={"auto"} style={{ width: '100%' }}>
          <Form
            style={{ width: '100%' }}
            labelCol={{ sm: 24, xs: 24, md: 24, lg: 24, xl: 24, xxl: 24 }}
            name="filters"
            preserve={false}
            onValuesChange={(changedValues, allValues) => {
              // console.log('changedValues: ', changedValues)
              // console.log('allValues: ', allValues)
              let values = { ...allValues }
              setShowSearch(Object.keys(values).length === 0)
            }}
            form={form}
            layout="vertical">
            <Row justify={"space-around"} ref={ref} style={{ padding: 0, width: '100%' }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} flex={"auto"} style={{ width: '100%', height: '100%', padding: 10 }}>
                {
                  !!formInputs.fields[0] ? <Row gutter={[4, 0]} align={"stretch"} justify={"start"} style={{ width: '100%', margin: 0, marginBottom: 14 }}>
                    {formInputs.fields.map((field, index) => field.type !== 'textarea' &&
                      <Col key={index} xs={24} sm={24} md={24} lg={24} xl={24} flex={"auto"} style={{ width: '100%' }}>{getInputField(form, field, phoneValue, setPhoneValue)}</Col>)}
                  </Row> : renderEmpty()
                }
                {!!formInputs.fields[0] && <Row style={{ width: '100%' }} justify={"end"}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center' }}>
                    <Space>
                      <Button onClick={() => cancelFilter()} size="small" disabled={loading} type="default" style={{ width: 90, marginRight: 30 }}>Cancel</Button>
                      {/*<Button onClick={() => clearFilters()}
                          type={"dashed"}
                          size="small" style={{width: 90, color: '#EF8100'}}>
                    Reset
                  </Button>*/}
                      <Button style={{ width: 90 }} type="primary" size="small" loading={loading} onClick={() => filter()}> Filter </Button>
                    </Space>
                  </Col>
                </Row>}
              </Col>
            </Row>
          </Form>
        
        </Col>
      </Row>}
                              
                              open={isVisible} arrow={true}
                              overlayStyle={{ width: point.xxl ? '18%' : point.xl ? '25%' : point.lg ? '30%' : point.md ? '40%' : point.sm ? '50%' : point.xs ? '70%' : '70%' }}>
      <Button onClick={() => setIsVisible(prevState => !prevState)} icon={<FilterOutlined/>}>Filters <CaretDownOutlined/></Button>
    </Dropdown>}
    {(!isVisible) &&
      <Search placeholder="Search" enterButton={!loading} defaultValue={filterData.search || undefined} value={searchVal || undefined}
              onChange={(event => {
                // console.log({event: event.target.value})
                setsearchVal(event.target.value)
                debouncedChangeHandler(event.target.value);
              })}
              loading={loading}
              onPressEnter={e => onSearch(e.target.value)}
              size={"middle"}
              style={{ width: 190, backgroundColor: 'transparent', marginLeft: 6 }}
        // disabled={loading}
      />}
    <div className={'flexy-row'} style={{ marginLeft: 6, width: 'auto', marginRight: 10  }}>{extraLeftActions}</div>
    {(!isVisible && filterHasData) &&
      <Button onClick={() => clearFilters()} color={'#EF8100'} type={"dashed"} size="middle" danger={true} loading={loading}
              style={{ color: '#EF8100', borderRadius: 6, fontWeight: 600, marginLeft: 6, marginRight: 6 }}>
        Clear Filters
      </Button>}
    {(!isVisible) &&
      <Button loading={loading} type={'default'} style={{ marginLeft: 6, cursor: loading ? 'not-allowed' : 'pointer' }} shape="circle" onClick={loading ? null : () => filter()}
              icon={<RedoOutlined/>}/>}
    {!!actionButton && <div style={{ flexGrow: 1, textAlign: point.xs ? 'start' : 'end' }}>{actionButton}</div>}
  </div>
};
export default TableFilters
