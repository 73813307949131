import {Card, Avatar, Typography, Spin, Skeleton} from 'antd';
import { Headline6, Headline4, Subtitle1 } from '../../../components';
import {
  CopyPasteIcon,
  PersonIcon,
  UpArrow,
  DownArrow,
} from '../../Facilities/Dashboard/components/Icons/facility-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VscPulse } from 'react-icons/vsc';
import React from 'react';

export default function StatsCard({ logo, schedule, loading }) {
  function getColorByCount(count) {
    if (count > 0) {
      return (
        <div className='flex-boy-row'> <UpArrow />
          <Subtitle1 style={{ color: '#5ACA75' }}>{count}%</Subtitle1>
        </div>
      );
    } else if (count < 0) {
      return (
        <div className='flex-boy-row'>
          <DownArrow />
          <Subtitle1 style={{ color: '#f5222d' }}>{count}% </Subtitle1>
        </div>
      );
    }
  }

  return (
    <div className='w-full' style={{}}>
      <Card
        style={{
          // width: "85%",
          borderRadius: '12px',
          padding: '5px',
        }}
      >
        <div className='flex-boy-column' style={{ alignItems: 'flex-start', height: '100%' }}>
          <Subtitle1 style={{ color: '#000', fontWeight: 'bold', fontSize: '18px', marginBottom: 10 }} >
            {logo === 'user'
              ? 'Total Scheduled Appointments'
              : logo === 'schedule' ? 'Average patients per consultant per day'
                : logo === 'consultant' ? 'All Consultants' : null
            }
          </Subtitle1>
          {schedule ? (
            <Headline6 style={{ fontWeight: 'bold', fontSize: '20px' }}>
              {logo === 'user' ? schedule?.today_total : logo === 'schedule' ? schedule?.prev_week_avg : schedule?.total}
            </Headline6>
          ) : null}
        </div>
        {schedule ? (
          <div className='flex-boy-row-space'>
            <div className='flex-boy-row-space'>
              {schedule?.percent_change !== 0 && schedule?.percentage_change === "" ? (
                getColorByCount(schedule?.percent_change.toFixed(2))
              ) : (
                <Subtitle1 style={{ color: '#f5222d', fontWeight: 'bold', marginRight: '10px' }}>
                  {schedule?.percent_change === "" ? 0 : schedule?.percent_change}%
                </Subtitle1>
              )}
              <Subtitle1 style={{ color: '#827F86', fontWeight: 'bold' }}>
                {`Since ${schedule?.duration || 'past week'}`}
              </Subtitle1>
            </div>
            {logo === 'user' ? (
              <Avatar
                icon={
                  <FontAwesomeIcon color='#A7A9BC' icon={['fas', 'calendar-alt']} />
                }
                size={60}
                style={{
                  backgroundColor: '#F1F2F5',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '15px',
                }}
              />
            ) : logo === 'schedule' ? (
              <Avatar
                icon={<FontAwesomeIcon color='#A7A9BC' icon={['fas', 'user-md']} />}
                size={60}
                style={{
                  backgroundColor: '#F1F2F5',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '20px',
                  marginTop: '10px',
                }}
              />
            ) : (
              <Avatar
                    icon={<FontAwesomeIcon color='#A7A9BC' icon={['fas', 'user-alt']} />}
                size={60}
                style={{
                  backgroundColor: '#F1F2F5',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '5px',
                }}
              />
            )}
          </div>
        ) : (
          <div className={'w-full flex flex-col justify-center gap-2'}>
            <Skeleton paragraph={{ rows: 4 }} active  />
          </div>
        )}
      </Card>
    </div>
  )
}