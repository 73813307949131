import { Divider, Dropdown } from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { kebabMenus } from '../../../common/helpers';
import { Subtitle1, Headline6, Subtitle2 } from '../../../components';
import { deleteAppointmentNote } from '../../../app/Reducers';
import KebabMenu from '../../../components/KebabMenu';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';

export default function NoteCard({ note, setSelectedNote, setEditNoteModal , appointment}) {
  const dateFormat = 'DD/MM/YY';
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.auth?.activeUser);
  const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const actionMenus = kebabMenus((note.createdBy.id === activeUser?.id), false, (note.createdBy.id === activeUser?.id));
  console.log({note})
  async function kebabMenuClick(e, note) {
    switch (actionMenus[e.key].title) {
      case 'Edit':
        setSelectedNote(note);
        setEditNoteModal(true);
        console.log('Edit');
        break;
      case 'Delete':
        dispatch(deleteAppointmentNote({ id: note.id, appointment_id: appointment.id }));
      default:
        console.log('Default');
    }
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }} >
      <div className='flex-boy-row-space'>
        <div className='flex-boy-row'>
          <Subtitle1 style={{ marginRight: '8px' }}>{note.createdBy.name}  </Subtitle1> /  <Subtitle1 style={{ fontWeight: 'normal', marginLeft: '5px' }}>{dayjs(note.created_at).format(dateFormat)}</Subtitle1>
        </div>
        <div>
          <KebabMenu menus={actionMenus} handleMenuClick={kebabMenuClick} recordItem={note} resource='note' showConfirmDialog={false} />
        </div>
      </div>
      <div className='flex-boy-row'>
        <Subtitle2 style={{ textAlign: 'left', fontWeight: 'normal', }}   >{note.note}  </Subtitle2>
      </div>
      <Divider style={{ margin: '4px', color: '#E2E4EA', width: '100%', }} />
    </div>
  );
}
