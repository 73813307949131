import { ChannelList } from 'stream-chat-react';
import { ChannelSearch } from '../ChannelSearch/ChannelSearch';
import { TeamChannelList } from '../TeamChannelList/TeamChannelList';
import { ChannelPreview } from '../ChannelPreview/ChannelPreview';
import { CompanyLogo } from './icons';

const options = { state: true, watch: true, presence: true, limit: 10 };
const sort = { last_message_at: -1, updated_at: -1 };
const FakeCompanySelectionBar = () => (<div className='sidebar__company-selection-bar'>
  <div className='sidebar__company-badge'>
    <CompanyLogo />
  </div>
</div>);
const customChannelTeamFilter = (channels) => {
  return channels.filter((channel) => channel.type === 'team');
};
const customChannelMessagingFilter = (channels) => {
  return channels.filter((channel) => channel.type === 'messaging');
};
const TeamChannelsList = ({ filters }) => (<ChannelList channelRenderFilterFn={customChannelTeamFilter} filters={filters[0]} options={options} sort={sort} List={(listProps) => (<TeamChannelList {...listProps} type='team' />)} Preview={(previewProps) => (<ChannelPreview {...previewProps} type='team' />)} />);
const MessagingChannelsList = ({ filters }) => (<ChannelList channelRenderFilterFn={customChannelMessagingFilter} filters={filters[1]} options={options} sort={sort} setActiveChannelOnMount={false} List={(listProps) => (<TeamChannelList {...listProps} type='messaging' />)} Preview={(previewProps) => (<ChannelPreview {...previewProps} type='messaging' />)} />);
export const Sidebar = ({user}) => {
  const filters = [
    { type: 'team', members: { $in: [user] } },
    { type: 'messaging', members: { $in: [user] } },
  ];
  return (<div className='sidebar'>
   {/* <FakeCompanySelectionBar/> */}
    <div className='channel-list-bar'>
      
      <ChannelSearch />
      <TeamChannelsList filters={filters} />
      <MessagingChannelsList filters={filters} />
    </div>
  </div>);
};
