// redux toolkit sclice

// Path: src/app/Reducers/TaskSlice.js
// Compare this snippet from orderSlice.js:

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {apiRequest} from "../Apis"
import {errMsg, getToastId, toastUp, updateToast} from "./reducerUtils";
import {tasks} from "./initialState";

const initialState = { ...tasks }
export const fetchTasks = createAsyncThunk('tasks/fetchTasks', async (requestParams, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        let state = thunkAPI.getState();
        let pagination = requestParams?.pagination ? requestParams.pagination : state.tasks.taskPagination
        let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
        params.page = pagination.current
        let response = await apiRequest.get(`/tasks`, { params });
        let data = response.data.data
        const payload = { data, pagination: { ...pagination, total: response?.data?.meta?.total } }
        resolve(payload)
      } catch (e) {
        console.error(e)
        reject(e)
        toastUp(errMsg(e, true, 'tasks'), false)
      }
    })
  }
)

// create task
export const createTask = createAsyncThunk('tasks/createTask', async (params, thunkAPI) => {
  const toastId = getToastId("Creating Task")
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/tasks`, params)
      updateToast(toastId, "Task Created", true)
      await thunkAPI.dispatch(fetchTasks())
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, true, 'tasks'), false);
      reject(e)
      
    }
  })
})


// update task
export const updateTask = createAsyncThunk('tasks/updateTask', async (params, thunkAPI) => {
  
  const toastId = getToastId("Updating Task")
  
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/tasks/${params.id}`, params)
      updateToast(toastId, "Task Updated", true)
      await thunkAPI.dispatch(selectTask(response.data.data))
      await thunkAPI.dispatch(fetchTasks())
      resolve(response.data.data)
    } catch (e) {
      toastUp(errMsg(e, true, 'tasks'), false);
      reject(e)
      
    }
  })
})

// update task
export const updateTaskStatus = createAsyncThunk('tasks/updateTaskStatus', async (params, thunkAPI) => {
  
  const toastId = getToastId("Updating Task status")
  
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/tasks/${params.id}/status`, params.payload)
      updateToast(toastId, "Task Updated", true)
      await thunkAPI.dispatch(selectTask(response.data.data))
      await thunkAPI.dispatch(fetchTasks())
      
      resolve(response.data.data)
    } catch (e) {
      toastUp(errMsg(e, true, 'tasks'), false);
      reject(e)
      
    }
  })
})
// delete task
export const deleteTask = createAsyncThunk('tasks/deleteTask', async (params, thunkAPI) => {
  
  const toastId = getToastId("Deleting Task")
  
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/tasks/${params.id}`)
      
      updateToast(toastId, "Task Deleted", true)
      await thunkAPI.dispatch(fetchTasks())
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, true, 'tasks'), false);
      reject(e)
      
    }
  })
  
})


// create task comment
export const createTaskComment = createAsyncThunk('tasks/createTaskComment', async (params, thunkAPI) => {
  const toastId = getToastId("Creating Task Comment")
  
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/tasks/${params.id}/comments`, params)
      updateToast(toastId, "Task Comment Created", true)
      await thunkAPI.dispatch(fetchTaskComments({ id: params.id }))
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, true, 'tasks'), false);
      reject(e)
      
    }
  })
})

// update task comment
export const updateTaskComment = createAsyncThunk('tasks/updateTaskComment', async (params, thunkAPI) => {
  const toastId = getToastId("Updating Task Comment")
  
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/tasks/${params.taskId}/comments/${params.id}`, params)
      updateToast(toastId, "Task Comment Updated", true)
      await thunkAPI.dispatch(fetchTaskComments({ id: params.taskId }))
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, true, 'tasks'), false);
      reject(e)
      
    }
  })
})

// delete task comment

export const deleteTaskComment = createAsyncThunk('tasks/deleteTaskComment', async (params, thunkAPI) => {
  const toastId = getToastId("Deleting Task Comment")
  
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/tasks/${params.taskId}/comments/${params.id}`)
      updateToast(toastId, "Task Comment Deleted", true)
      await thunkAPI.dispatch(fetchTaskComments({ id: params.taskId }))
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, true, 'tasks'), false);
      reject(e)
      
    }
  })
})
// fetch task comments
export const fetchTaskComments = createAsyncThunk('tasks/fetchTaskComments', async (params, thunkAPI) => {
  // const toastId = getToastId("Fetching Task Comments")
  
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/tasks/${params.id}/comments`)
      // updateToast(toastId, "Task Comments Fetched", true)
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, true, 'tasks'), false);
      reject(e)
      
    }
  })
})
// fetch task attachments
export const fetchTaskAttachments = createAsyncThunk('tasks/fetchTaskAttachments', async (params, thunkAPI) => {
  // const toastId = getToastId("Fetching Task Attachments")
  
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/tasks/${params.id}/attachments`)
      // updateToast(toastId, "Task Attachments Fetched", true)
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, true, 'tasks'), false);
      reject(e)
      
    }
  })
})
// create task attachment
export const createTaskAttachment = createAsyncThunk('tasks/createTaskAttachment', async (params, thunkAPI) => {
  const toastId = getToastId("Creating Task Attachment")
  
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/tasks/${params.id}/attachments`, params)
      updateToast(toastId, "Task Attachment Created", true)
      await thunkAPI.dispatch(fetchTask({ id: params.id }))
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, true, 'tasks'), false);
      reject(e)
      
    }
  })
  
})
// delete task attachment
export const deleteTaskAttachment = createAsyncThunk('tasks/deleteTaskAttachment', async (params, thunkAPI) => {
  const toastId = getToastId("Deleting Task Attachment")
  
  return new Promise(async (resolve, reject) => {
    
    try {
      const response = await apiRequest.delete(`/tasks/${params.taskId}/attachments/${params.id}`)
      updateToast(toastId, "Task Attachment Deleted", true)
      await thunkAPI.dispatch(fetchTask({ id: params.taskId }))
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, true, 'tasks'), false);
      reject(e)
      
    }
  })
  
})

// fetch specific task
export const fetchTask = createAsyncThunk('tasks/fetchTask', async (params, thunkAPI) => {
  // const toastId = getToastId("Fetching Task")
  
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/tasks/${params.id}`)
      // updateToast(toastId, "Task Fetched", true)
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, true, 'tasks'), false);
      reject(e)
      
    }
  })
})



// create Taskslice
const TaskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    createTask: (state, action) => {
      state.tasks = [...state
        .tasks, action.payload]
    },
    updateTaskFilters: (state, action) => {
      state.taskFilters = [...action.payload]
    },
    selectTask: (state, action) => {
      state.selectedTask = action.payload
    },
    resetTaskPagination: (state, action) => {
      state.taskPagination = { ...initialState.taskPagination }
    },
    setViewTask: (state, action) => {
      state.ViewTaskOpen = action.payload
    },
    setViewTaskNotification: (state, action) => {
      state.viewTaskNotification = action.payload
    }, doResetTaskSlice: (state) => {
      Object.keys(initialState).map(key => state[key] = initialState[key])
    },
    
  },
  extraReducers: (builder)=>{
    builder.addCase(fetchTasks.fulfilled, (state, action) => {
      state.tasks = [...action.payload.data]
      state.taskPagination = action.payload.pagination
    }).addCase(updateTask.fulfilled, (state, action) => {
      state.selectedTask = action.payload
    }).addCase(updateTaskStatus.fulfilled, (state, action) => {
      state.selectedTask = action.payload
    }).addCase(fetchTaskComments.fulfilled, (state, action) => {
      state.comments = action.payload.data.data
    }).addCase(fetchTaskAttachments.fulfilled, (state, action) => {
      state.attachments = action.payload.data.data
    }).addCase(fetchTask.fulfilled, (state, action) => {
      state.selectedTask = action.payload.data.data
    })
    
  }
})

export const { doResetTaskSlice,selectTask, setViewTask, resetTaskPagination,setViewTaskNotification } = TaskSlice.actions


export default TaskSlice.reducer