import {Button, DatePicker, Grid, Modal, Select, Table, Tag} from 'antd'
import dayjs from 'dayjs'
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Subtitle1} from '../../../components'
import {
  appLog,
  emptyFun,
  emptyTable,
  formSubmitButton,
  getprogressStatus,
  getSkels,
  horStack,
  keyValItemForComponent,
  makeAutoCompleteOptions,
  makeSelectOptions,
  modalClose,
  onTableChange,
  outlinedButton,
  responsiveTbl
} from '../../../common/helpers'
import {fetchBillingEncounter, fetchBillingEncounters, resetBillingPendingPagination, updatePendingBillingFilters} from '../../../app/Reducers'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import TableFilters from '../../../components/InputComponents/TableFilters'
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import ReportForm from './ReportForm'
import {US_STATES} from '../../../common/States';
import {EditOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {useProcedureSearch} from '../../../hooks/useProcedureSearch';

const { useBreakpoint } = Grid;
export const PendingReviewEncounters = ({ billingEncounters, billingPendingPagination, appConf, activeUser }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const windowState = useBreakpoint()
  const [isVisible, setIsVisible] = useState(false);
  const [isExecuting, setisExecuting] = useState(false)
  const can_export_treatment_order = usePermissionCheck('export_treatment_order', false, [])
  const [procedureOptions, debouncedProcedureSearch, procedureSearchLoading] = useProcedureSearch([], 'name')
  
  let onCell = (record, rowIndex) => ({
    onClick: event => {
      (async () => {
        await dispatch(fetchBillingEncounter(record))
        navigate(`/billing/${record.id}`)
      })().catch(appLog)
    }
  })
  
  useEffect(() => {
    loadBillingEncounters().catch(appLog)
    return emptyFun
  }, [])
  
  
  // loadBillingEncounters
  const loadBillingEncounters = async () => {
    await dispatch(updatePendingBillingFilters([appConf.pendingBillingFilters[0], appConf.pendingBillingFilters[1], true]))
    await dispatch(fetchBillingEncounters(appConf.pendingBillingFilters[0] ? { ...appConf.pendingBillingFilters[1] } : { filterData: { progressStatus: "pending review" } }))
    await dispatch(updatePendingBillingFilters([appConf.pendingBillingFilters[0], appConf.pendingBillingFilters[1], false]))
  }
  
  const columns = [
    {
      title: 'Patient', dataIndex: 'patient', key: 'patient', fltr: { filterable: true, type: 'text' }, onCell,
      sorter: {
        multiple: 3, compare: (a, b) => {
          // console.log({a,b})
          return a?.patient?.first_name?.toLowerCase().localeCompare(b?.patient?.first_name?.toLowerCase());
        }
      },
      render: (patient) => {
        return <p className="mb-0">{patient.full_name}</p>
      }
    },
    
    {
      title: 'Office', dataIndex: ['patient', 'facility', 'name'], key: 'facility', fltr: { filterable: true, type: 'text' }, onCell
    },
    
    {
      title: 'Office Type', dataIndex: ['patient', 'facility', 'type'], key: 'facility type', fltr: { filterable: true, type: 'text' }, onCell
    },
    // provider
    {
      title: 'Provider', dataIndex: 'consultant', key: 'consultant', fltr: { filterable: true, type: 'text' }, onCell,
      render: (consultant) => {
        return <p className="mb-0">{consultant.full_name}</p>
      }
    },
    
    // visit type
    {
      title: 'Visit Type', dataIndex: 'visit_type', key: 'visitType',
      fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(['established visit', 'new visit']) }
    },
    
    // Visit Date
    {
      title: 'Appointment Date', dataIndex: 'visit_date', key: 'visitDate', fltr: { filterable: true, type: 'date' },
      sorter: {
        multiple: 3, compare: (a, b) => {
          return dayjs(a.visit_date).unix() - dayjs(b.visit_date).unix()
        }
      },
      render: (visit_date) => dayjs(visit_date).format('MM-DD-YYYY')
    },
    
    
    ...(activeUser?.is_qa_user ? [{
      width: 15,
      visible: true,
      title: <FontAwesomeIcon color="grey" icon={['fas', 'table']}/>,
      key: 'operation',
      render: (record) => <Tag className={`${(record.is_qa && activeUser?.is_qa_user) ? 'visible' : 'invisible'}`} icon={<EditOutlined/>} color="#55acee">QA</Tag>
    }] : [])
  
  
  ]
  
  const claim_columns = [
    {
      title: 'Service Type', dataIndex: 'procedure_type', key: 'procedure', fltr: {
        filterable: true, type: 'select',options:procedureOptions,
      props: {
        loading:procedureSearchLoading,
        onSearch: (value) => debouncedProcedureSearch(value), showSearch: true, filterOption: false
      }
      }
    },
    // date of service
    {
      title: 'Date of Service', dataIndex: 'date_of_service', key: 'date_of_service', fltr: { filterable: true, type: 'date' },
      render: (date_of_service, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(date_of_service).format("MM/DD/YYYY")}</Subtitle1>)
    },
    // // date created
    // {
    //   title: 'Date Created', dataIndex: 'created_at', key: 'created_at', fltr: { filterable: true, type: 'date' }, onCell,
    //   render: (created_at, record) => (<Subtitle1 style={{ textAlign: 'left' }}>{dayjs(created_at).format("MM/DD/YYYY")}</Subtitle1>)
    // },
    // {
    //   title: 'Wound',
    //   dataIndex: 'wound',
    // },
    
    // progress status
    {
      title: 'Progress Status', dataIndex: 'progress_status', key: 'progressStatus', fltr: {
        filterable: false, type: 'autocomplete', options: makeAutoCompleteOptions(['pending review', 'pending submission', 'submitted', 'paid'])
      }, render: (progress_status) => getprogressStatus(progress_status?.toLowerCase())
    }
  
  ];
  
  const handleFilterAction = async (action, values) => {
    // appLog({action, values})
    await dispatch(resetBillingPendingPagination())
    if (action === 'filter') {
      let pl = { filterData: { ...values, "progressStatus": "pending review" }, pagination: billingPendingPagination }
      await dispatch(updatePendingBillingFilters([false, pl, true]))
      await dispatch(fetchBillingEncounters(pl))
      await dispatch(updatePendingBillingFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updatePendingBillingFilters([appConf.pendingBillingFilters[0], undefined, true]))
      await dispatch(fetchBillingEncounters({ "filterData": { "progressStatus": "pending review" } }))
      await dispatch(updatePendingBillingFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  let stateOptions = makeSelectOptions(US_STATES.map(item => item.State))
  
  const onDateChange = (date, dateString, filterName) => {
    let filters = { ...appConf.pendingBillingFilters[1]?.filterData }
    if (dateString === '' || !dateString) {
      delete filters[filterName]
    } else {
      filters[filterName] = dayjs(dateString).format('YYYY-MM-DD')
    }
    handleFilterAction('filter', filters).catch(appLog)
  };
  const onListSelect = (value, option, filterName) => {
    let filters = { ...appConf.pendingBillingFilters[1]?.filterData }
    if (value === '' || !value) {
      delete filters[filterName]
    } else {
      filters[filterName] = value
    }
    handleFilterAction('filter', filters).catch(appLog)
  };
  const billingFilters = [
    keyValItemForComponent('Date of service: ',
      <DatePicker
        {...(appConf.pendingBillingFilters[1]?.filterData?.date_of_service && { value: dayjs(appConf.pendingBillingFilters[1]?.filterData?.date_of_service) })}
        onChange={(date, dateString) => onDateChange(date, dateString, 'date_of_service')} placeholder={'MM-DD-YYYY'} format={'MM-DD-YYYY'}/>, { marginTop: 0 }),
    keyValItemForComponent('Date created:',
      <DatePicker
        {...(appConf.pendingBillingFilters[1]?.filterData?.created_at && { value: dayjs(appConf.pendingBillingFilters[1]?.filterData?.created_at) })}
        onChange={(date, dateString) => onDateChange(date, dateString, 'created_at')} placeholder={'MM-DD-YYYY'} format={'MM-DD-YYYY'}/>, { marginTop: 0 }),
    /*keyValItemForComponent('Date of submission:',
      <DatePicker
        {...(appConf.pendingBillingFilters[1]?.filterData?.dateOfSubmission && { value: dayjs(appConf.pendingBillingFilters[1]?.filterData?.dateOfSubmission) })}
        onChange={(date, dateString) => onDateChange(date, dateString, 'dateOfSubmission')} placeholder={'MM-DD-YYYY'} format={'MM-DD-YYYY'}/>, { marginTop: 0 }),*/
    keyValItemForComponent('Procedure:',
      <Select
        placeholder={'Select'}
        value={appConf.pendingBillingFilters[1]?.filterData?.procedure}
        popupMatchSelectWidth={false}
        style={{ minWidth: 80 }}
        options={procedureOptions}
        loading={procedureSearchLoading}
        showSearch={true}
        filterOption={false}
        onSearch={(value) => debouncedProcedureSearch(value)}
        onSelect={(value, option) => onListSelect(value, option, 'procedure')}/>, { marginTop: 0 }),
    keyValItemForComponent('State:',
      <Select
        placeholder={'Select'}
        value={appConf.pendingBillingFilters[1]?.filterData?.state}
        popupMatchSelectWidth={false} style={{ minWidth: 80 }} options={stateOptions}
        onSelect={(value, option) => onListSelect(value, option, 'state')}/>, { marginTop: 0 })
    /*keyValItemForComponent('Status:',
      <Select
        placeholder={'Select'}
        value={appConf.pendingBillingFilters[1]?.filterData?.status}
         popupMatchSelectWidth={false} style={{ minWidth: 80 }} options={statusOptions}
        onSelect={(value, option) => onListSelect(value, option, 'status')}/>, { marginTop: 0 })*/
  ]
  
  // billing export form
  const [billingExportForm, setBillingExportForm] = useState(false)
  
  // handle billing export form cancel
  const handleBillingExportFormCancel = () => {
    setBillingExportForm(false)
  }
  // isSubmitting
  const [isSubmitting, setIsSubmitting] = useState(false)
  
  
  return (
    <div className="main-page">
      {/* <Modal
        title="Order Report"
        open={orderExportForm}
        destroyOnClose
        onCancel={handleExportOrderCancel}
        closeIcon={modalClose(handleExportOrderCancel)}
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(() => setOrderExportForm(false), 'Cancel', isExecuting),
          formSubmitButton('new-order-report', 'Download', isExecuting, 'Downloading')])}
        </div>]}>
        <ReportForm closeModal={handleExportOrderCancel} setIsSubmitting={setIsSubmitting} state={"pre-order"} />
      </Modal> */}
      {/* billing exort modal */}
      <Modal
        title="Billing export"
        open={billingExportForm}
        destroyOnClose
        onCancel={handleBillingExportFormCancel}
        closeIcon={modalClose(handleBillingExportFormCancel)}
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(() => setBillingExportForm(false), 'Cancel', isExecuting),
            formSubmitButton('new-order-report', 'Download', isExecuting, 'Downloading')])}
        </div>]}>
        <ReportForm closeModal={handleBillingExportFormCancel} setIsSubmitting={setIsSubmitting} type={"pending review"}/>
      </Modal>
      
      
      <div className="w-full">
        
        <Table
          rowKey={'id'}
          title={() =>
            <>
              <TableFilters datasource={[...columns]} setIsVisible={setIsVisible}
                            
                            isVisible={isVisible}
                            showClear={appConf.pendingBillingFilters[0]}
                            handleFilterAction={handleFilterAction}
                            searchOnly={true}
                            loading={appConf.pendingBillingFilters[2]}
                            filters={appConf.pendingBillingFilters[1]}
                            extraLeftActions={billingFilters}
                            actionButton={
                              <div className="flex-boy-row" style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                                {can_export_treatment_order &&
                                  <Button type={'default'} size={'middle'} onClick={() => setBillingExportForm(true)}
                                          icon={<FontAwesomeIcon icon={["far", 'file-excel']}/>}>Export to CSV
                                  </Button>}
                              </div>
                              
                            }
              
              />
            </>
          }
          loading={appConf.preOrderFilters[2]}
          {...responsiveTbl(windowState)}
          locale={{ emptyText: emptyTable('billing.') }}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchBillingEncounters, appConf.taskFilters, updatePendingBillingFilters, { "progressStatus": "pending review" })}
          {...getSkels(appConf.pendingBillingFilters[2], columns, billingEncounters)}
          pagination={billingPendingPagination}
          expandable={{
            columnWidth: 10,
            expandedRowRender: (record) => {
              return (
                <div className="p-4">
                  <Table
                    rowKey={'id'}
                    columns={claim_columns}
                    dataSource={record.claims}
                    pagination={false}
                    size="small"
                  />
                </div>
              
              )
              
            }
            
            
          }}
        />
      
      
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  billingEncounters: state.billing.billingEncounters,
  billingPendingPagination: state.billing.billingPendingPagination,
  appConf: state.appConf,
  navs: state.navs, activeUser: state.auth.activeUser,
  
  
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PendingReviewEncounters)