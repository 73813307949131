import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { DatePicker, Form, Input, Select } from 'antd'
import { appLog } from '../../../../common/helpers'
import dayjs from 'dayjs'
import _ from "lodash"
import { apiRequestBlob } from '../../../../app/Apis'
import download from "downloadjs";
import querystring from "querystring";

const { RangePicker } = DatePicker;
const stati = [
  "pending",
  "approved",
  "cancelled",
  "pending fulfillment",
  "warehouse alert",
  "label created",
  "shipped",
  "in transit",
  "delivered",
  "final",
  "needs review"
]
const types = [
  "biologics",
  "dme",
  "internal",
]

const { Option } = Select;
export const NewInternalNote = ({ closeModal, setIsSubmitting, state, type }) => {
  const { TextArea } = Input
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState({ visitType: type });

  const onFinish = async (values) => {
    console.log(values)
    let createdAtRange = {}
    if (values.dates) {

      createdAtRange = {
        start_at: dayjs(values.dates[0]).format("YYYY-MM-DD"),
        end_at: dayjs(values.dates[1]).format("YYYY-MM-DD")

      }
    } else {
      createdAtRange = null
    }
    let pl = { filterData: _.omit({ ..._.omitBy(values, _.isNil) }, "dates") }
    if (state) {
      pl.filterData.orderState = state
    }

    let payload = { ...pl }
    console.log(payload)


    let queryString = querystring.stringify(pl.filterData)
    if (values.dates) {
      queryString = queryString + `&dateOfServiceRange[start_at]=${dayjs(values.dates[0]).format("YYYY-MM-DD")}&dateOfServiceRange[end_at]=${dayjs(values.dates[1]).format("YYYY-MM-DD")}`
    }
    const response = await apiRequestBlob.get(`/treatment-orders-export?${queryString}`)
    download(response.data, `orders-${dayjs().format("YYYY-MMM-DD hh:mm")}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    closeModal()
  }


  return (<Form
    name="new-order-report"
    onFinish={onFinish}
    labelCol={{ span: 16 }}
    initialValues={{ ...initialValues }}
    layout="vertical">
    <Form.Item name="dates" label="Dates" rules={[{ required: true, message: 'Missing dates' }]}>
      <RangePicker style={{ width: '100%' }} />
    </Form.Item>
    <Form.Item name="consultant" label="Provider" rules={[{ required: false, message: 'Missing dates' }]}>
      <Input autoComplete={'off'} />
    </Form.Item>
    <Form.Item name="patient" label="Patient" rules={[{ required: false, message: 'Missing dates' }]}>
      <Input autoComplete={'off'} />
    </Form.Item>
    <Form.Item name="visitType" label=" Order type" rules={[{ required: false, message: 'Missing notes' }]}>
      <Select
        // mode="multiple"
        allowClear
        style={{ width: '100%' }}
        placeholder="Please select"
        disabled={type}

      >
        {types.map(item => <Option value={item}>{item}</Option>)}
      </Select>
    </Form.Item>

    <Form.Item name="status" label="Status" rules={[{ required: false, message: 'Missing notes' }]}>
      <Select
        // mode="multiple"
        allowClear
        style={{ width: '100%' }}
        placeholder="Please select"


      >
        {stati.map(item => <Option value={item}>{item}</Option>)}
      </Select>
    </Form.Item>
  </Form>)
}
const mapStateToProps = (state) => ({  order: state.orders.selectedOrder })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(NewInternalNote)
