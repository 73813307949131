import {useCallback, useEffect, useState} from "react";
import _ from 'lodash';
import {apiRequest} from '../app/Apis';

export const useFacilitySearch = (deps, valueAttribute, defaultList) => {
  const [isRun, setIsRun] = useState(false);
  const [facilitySearchLoading, setFacilitySearchLoading] = useState(false);
  
  const [facilityOptions, setFacilityOptions] = useState(defaultList)
 /* if (!isRun) {
    setIsRun(true);
  }*/
  
 const debouncedFacilitiesSearch=  useCallback(_.debounce(async function (value) {
     if (!value||value===''){
       setFacilityOptions([])
         return
     }
     await setFacilitySearchLoading(true)
    const { data } = await apiRequest.get(`/medical-facilities?search=${value}`)
    if (data) {
      await setFacilityOptions(data.data.map(item => ({...item, label: item.name, value: valueAttribute ? item?.[valueAttribute] : item.name })));
    }
   await setFacilitySearchLoading(false)
  }, 1000), deps)
  return [facilityOptions,debouncedFacilitiesSearch,facilitySearchLoading]
};