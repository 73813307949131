import { Typography } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Indicator, Subtitle1 } from '../../../components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { usePermissionCheck } from '../../../hooks/usePermissionCheck'
import { selectAppointment } from '../../../app/Reducers'
import { getAppointmentIndicatorColor } from '../../../common/helpers'
import {useLocation, useNavigate} from 'react-router-dom';


      

export const ConsultantAppointmentCard = ({ appointment }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate();
    const durationInMinutes = dayjs(appointment.end_at).diff(dayjs(appointment.start_at), 'minute');
    const duration = durationInMinutes < 60 ? `${durationInMinutes}m` : `${Math.floor(durationInMinutes / 60)}h`;
    const can_view_appointment = usePermissionCheck('view_appointment', false, [])
    const clickAppointment = async() => {
        
            await dispatch(selectAppointment({ navigate, record: appointment }))
            // let initialAppointment = appointments.find(item => (item.appointment_visit_type === 'new visit' && item.wounds.some(item => item?.id === record?.wounds[0]?.id)))
            // console.log({ initialAppointment })
            // await dispatch(selectPatientInitialAppointment(initialAppointment))
            if (can_view_appointment) {
                navigate(`/appointments/${appointment.id}`)
            }
    }
    

    const hexToRgba = (hex, alpha) => {
        const [r, g, b] = [
            parseInt(hex.slice(1, 3), 16),
            parseInt(hex.slice(3, 5), 16),
            parseInt(hex.slice(5, 7), 16),
        ];
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };
   
  return (
      <div className="rounded 
    m-2
      cursor-pointer
      p-2"
      onClick={clickAppointment}
          style={{
          backgroundColor: hexToRgba(getAppointmentIndicatorColor(appointment.status), 0.3),
        
      }}>
            
           {/* <div className={` bg-[#dfebfa] h-full`} style={{ width: `${widths.scheduled}%` }}>
       </div>
          <div className={` bg-[#e9fcf6] h-full`} style={{ width: `${widths.completed}%` }}>
          </div>
          <div className={` bg-[#fae6ec] h-full`} style={{ width: `${widths.missed}%` }}>
       </div>
          <div className={` bg-[#fdf9d2] h-full`} style={{ width: `${widths.cancelled}%` }}>
          </div> */}
          <div >
              <div className="flex flex-row justify-between">
                  {/* day js appointment time in format dd MMM YYYY/ dayjs(appointment.start_at).format("HH:mm a")} -{dayjs(appointment.end_at).format("HH:mm a") (appointment time lenth eg 1h or 30 min) */}
                  <p className='font-bold' fontWeight={600}> {dayjs(appointment.start_at).format('DD MMM YYYY')}/{dayjs(appointment.start_at).format("HH:mm a")} -{dayjs(appointment.end_at).format("HH:mm a")}  ({duration})</p>

                  <div className="flex flex-row">
                      <div className="status-container-border mx-2">

                          <Subtitle1>{appointment.appointment_visit_type}</Subtitle1>
                      </div>
                      <div className="status-container">
                          <Indicator color={getAppointmentIndicatorColor(appointment.status)} borderColor={getAppointmentIndicatorColor(appointment.status)} />
                          <Subtitle1>{appointment.status}</Subtitle1>
                      </div>
                  </div>

              </div>
              <div className="flex-boy-row-space" style={{ width: "100%" }}>
                  <FontAwesomeIcon onClick={() => console.log("heh")} color="grey" icon={["fas", 'user']} />
                  <Subtitle1 className="name" style={{ flex: 1, textAlign: 'left' }}>{appointment ? appointment.patient.name : "Patient Name"}</Subtitle1>

              </div>

            </div>
         
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantAppointmentCard)
