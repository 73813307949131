import React from 'react';
import { Body2, Headline6, Subtitle1 } from "../../../components";
import { boldKeyCapitalizedValue, boldKeyCapitalizedValueGrayBackground, skelBars } from "../../../common/helpers";
import dayjs from "dayjs";
import { connect } from "react-redux";


const PatientInitiation = ({ navs, patient }) => {
  return (<div className="card-box">
    <div className="flex-boy-row-space" style={{ width: "100%" }}>
      <Headline6>Initiation</Headline6>

      {/* <FontAwesomeIcon color="grey" icon={["fas", "pencil-alt"]} /> */}

    </div>
    {navs.patientDetailsLoading ? skelBars() : <div style={{ width: "100%" }}>
      {boldKeyCapitalizedValue('Referral:', patient.initiation.prospect || 'N/A')}
      {boldKeyCapitalizedValueGrayBackground('Date:', `${patient.initiation.prospect_creation_date ? dayjs(patient.initiation.prospect_creation_date).format('MM/DD/YYYY') : 'N/A'}`)}
      {boldKeyCapitalizedValue('Office:', patient.initiation.facility_name || 'N/A')}
      {boldKeyCapitalizedValueGrayBackground('Approval date:', `${patient.initiation.approved_date ? dayjs(patient.initiation.approved_date).format('MM/DD/YYYY') : 'N/A'}`)}
      {boldKeyCapitalizedValue('Approved By:', patient.initiation.approved_by || 'N/A')}
      {boldKeyCapitalizedValueGrayBackground('Agreement status:', patient.initiation.agreement_status || 'N/A')}
      {!!patient?.case_manager && boldKeyCapitalizedValue('Case Manager:', patient.case_manager || 'N/A')}
    </div>}
  </div>);
};
const mapStateToProps = (state) => ({ patient: state.patients.selectedPatient, navs: state.navs })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PatientInitiation)
