import { createContext, useEffect, useState } from "react";
import { emptyFun } from "../../common/helpers";
// import mqtt from "mqtt";


export const MQttContext = createContext(null);
export const MqttProvider = ({ brokerUrl, options, children }) => {
  const [mqttClient, setMqClient] = useState(null)
  const [sameClient, setSameClient] = useState(mqttClient?.options?.username === options?.username && mqttClient?.options?.clientId === options?.clientId && mqttClient?.options?.password === options?.password && mqttClient?.options?.host === options?.host)
  useEffect(() => {
    if (!!mqttClient) {
      if (mqttClient?.options?.username === options?.username && mqttClient?.options?.clientId === options?.clientId && mqttClient?.options?.password === options?.password && mqttClient?.options?.host === options?.host) {
        setSameClient(true)
        mqttClient.reconnect()
        return
      }
      // mqttClient.unsubscribe('#', emptyFun)
      mqttClient.on('error', emptyFun)
      mqttClient.end(true, emptyFun)
      setMqClient(null)
    }
    if (!!options.clientId && !!options.password && !!options.username) {
      let client = window.mqtt.connect(brokerUrl, options)
      client.on("connect", () => {
        console.log("MQTT connected  with status: " + client.connected)
        client.subscribe('presence', function (err) {
          if (!err) {
            client.publish('presence', 'Hello mqtt')
          } else {
            console.log('SUB: ', err.message);
          }
        })
      })
      client.on('error', (error) => {
        //Only print errors of the current connection
        if ((!!mqttClient?.options?.username && mqttClient?.options?.username !== options?.username) || sameClient || !mqttClient?.options?.username) {
          console.log(error.message);
        }
      })
      setMqClient(client)
      /*mqClient.on('reconnect', () => console.log('MQTT client reconnected ', mqClient.connected))
      mqClient.on('close', () => console.log('MQTT client closed'))
      mqClient.on('disconnect', () => console.log('MQTT client disconnected'))
      mqClient.on('offline', () => console.log('MQTT client offline'))
      mqClient.on('end', () => console.log('MQTT client ended'))*/

    }
    return () => {
      mqttClient?.unsubscribe('#', emptyFun)
      mqttClient?.on('error', emptyFun)
      mqttClient?.end(true, emptyFun)
      setMqClient(null)
    }
  }, [options])

  return (<MQttContext.Provider value={mqttClient}>{children}</MQttContext.Provider>);
}
