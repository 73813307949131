import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {useMqtt} from "./useMqtt";
import {matches} from "mqtt-pattern";


export const useSubscription = (topic, options) => {
  const mqttClient = useMqtt()
  const [message, setMessage] = useState(null)
  const subscribe = useCallback(async () => {
    mqttClient?.subscribe(topic, options);
  }, [mqttClient, options, topic]);
  
  const callback = useCallback((receivedTopic, receivedMessage) => {
      if ([topic].flat().some(rTopic => matches(rTopic, receivedTopic))) {
        setMessage({topic: receivedTopic, message: receivedMessage.toString()});
      }
    },
    [topic],
  );
  
  useEffect(() => {
    if (mqttClient?.connected) {
      subscribe();
      mqttClient.on('message', callback);
    }
    return () => {
      // mqttClient?.off('message', callback);
    };
  }, [callback, mqttClient, subscribe]);
  return {message}
};
