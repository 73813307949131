import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Divider, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { Body2, DotCircle, Subtitle1, Subtitle2 } from '../../components'
import {
  fetchDepartment,
  fetchEncounterById,
  fetchPatientById,
  fetchProspectById,
  fetchSpecificAppointment,
  fetchSpecificOrder,
  fetchTask,
  markSpecificRead,
  resetPatientModuleState,
  resetPatientNavs,
  setAppointmentNavs,
  setAssessmentNotificationOpen,
  setPatientNavs,
  setProspectNotificationOpen,
  setViewTaskNotification
} from '../../app/Reducers'

import { usePermissionCheck } from '../../hooks/usePermissionCheck';
import { emptyFun } from '../../common/helpers';
import {useLocation, useNavigate} from 'react-router-dom';

const Notifications = ({ notifications, activeUser, rcopianotifications }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate();
  const can_view_patient = usePermissionCheck('view_patient', false, [])
  const can_view_treatment_order = usePermissionCheck('view_treatment_order', false, [])
  const can_view_task = usePermissionCheck('view_task', false, [])
  const can_view_prospect = usePermissionCheck('view_prospect', false, [])
  const can_view_department = usePermissionCheck('view_department', false, [])
  const can_view_assessment = usePermissionCheck('view_assessment', false, [])
  const can_view_appointment = usePermissionCheck('view_appointment', false, [])
  const [loadedNotifications, setNotifications] = useState([])
  useEffect(() => {
    if (!notifications[0]) return emptyFun

    // let orderAlert={read_at: null,data:{resource_type:'treatmentorder',"title": "Order Test","message": "Order Test","resource_id": 9010000004283},"created_at": "2023-09-18 17:45:48"}
    let newList = notifications.filter(item => canViewNotification(item))
    // newList.push(orderAlert)
    setNotifications(newList);
    return emptyFun
  }, [notifications]);
  const handleRead = (alert) => {
    if (!alert.read_at) {
      dispatch(markSpecificRead(alert))
    }
  }
  const handleRcopiaMessages = async () => {
    await dispatch(setPatientNavs({ patientsTab: "Notifications" }));
    navigate("/patients")
  }
  const canViewNotification = async (alert) => {

    switch (alert.data.resource_type) {
      case 'treatmentorder':
      case 'order':
        return can_view_treatment_order
      case 'task':
        return can_view_task
      case 'prospect':
        return can_view_prospect
      case 'patient':
        return can_view_patient
      case 'department':
        return can_view_department
      case 'assessment':
        return can_view_assessment
      case 'appointment':
        return can_view_appointment
    }
  }
  const openNotification = async (alert) => {
    console.log("This Alert::", alert.read_at)
    if (!alert.read_at) {
      dispatch(markSpecificRead(alert))
    }
    switch (alert.data.resource_type) {
      case 'treatmentorder':
        await dispatch(fetchSpecificOrder(alert.data.resource_id))
        navigate(`/orders/${alert.data.resource_id}`)
        break
      case 'task':
        await dispatch(fetchTask({ id: alert.data.resource_id }))
        await dispatch(setViewTaskNotification(true))
        navigate(`/tasks/${alert.data.resource_id}`)
        break
      case 'prospect':
        await dispatch(fetchProspectById(alert.data.resource_id));
        await dispatch(setPatientNavs({ patientsTab: "Prospects" }));
        dispatch(setProspectNotificationOpen(true))
        navigate("/patients")
        break
      case 'patient':
        await dispatch(fetchPatientById(alert.data.resource_id));
        await dispatch(setPatientNavs({ patientsTab: "Patients" }));
        await dispatch(resetPatientModuleState())
        await dispatch(resetPatientNavs())
        if (can_view_patient) {
          navigate(`/patients/${alert.data.resource_id}`)
        }
        break
      case 'department':
        await dispatch(fetchDepartment({ id: alert.data.resource_id }));
        navigate(`/departments/${alert.data.resource_id}`)
        break
      case 'assessment':
        await dispatch(fetchEncounterById(alert.data.resource_id));
        await dispatch(setAppointmentNavs({ tab: 'Encounter List' }))
        dispatch(setAssessmentNotificationOpen(true))
        navigate("/appointments")
        break
      case 'appointment':
        await dispatch(fetchSpecificAppointment({ id: alert.data.resource_id }));
        navigate(`/appointments/${alert.data.resource_id}`)
        break
      case 'order':
        await dispatch(fetchSpecificOrder(alert.data.resource_id));
        navigate(`/orders/${alert.data.resource_id}`)
        break
    }
  }
  return (
    <>
      {activeUser.rcopia_username ? <div style={{ width: '100%', marginBottom: '20px', cursor: 'pointer' }}>
        <Body2 onClick={handleRcopiaMessages} color="grey">RCOPIA</Body2>
        <div className="flex-boy-row-space" style={{ alignItems: 'baseline', width: "100%" }}>
          <div onClick={handleRcopiaMessages} style={{ width: '90%', textOverflow: 'ellipsis' }}>
            {rcopianotifications?.reduce((accumulator, object) => {
              return accumulator + parseFloat(object.number);
            }, 0) > 0 ? rcopianotifications?.map(item => <Subtitle1 style={{ marginRight: '10px', textAlign: 'left' }}>You have {item.number} {item.description} to attend to. </Subtitle1>) : null}

          </div>
          <div onClick={handleRcopiaMessages}>
            <Tooltip title={!rcopianotifications?.length ? "Read" : 'Mark as read'}>
              <DotCircle color={!rcopianotifications?.length ? "grey" : 'crimson'} size="1px" />
            </Tooltip>
          </div>
        </div>
      </div> : null}

      {loadedNotifications.filter(item => dayjs().diff(dayjs(item.created_at), "days") < 1).length ?
        <div className="flex flex-col items-start justify-start p-4 bg-white shadow rounded-xl" style={{ width: '100%', marginBottom: '20px', cursor: 'pointer' }}>
          <Body2 color="grey">TODAY</Body2>
          {loadedNotifications.filter(item => dayjs().diff(dayjs(item.created_at), "days") < 1).map((alert, index) => (
            <>
              <div onClick={() => openNotification(alert)} className="flex-boy-row-space" style={{ alignItems: 'baseline', width: "100%", overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <div style={{ width: '100%', textOverflow: 'ellipsis' }}>
                  <div className="flex-boy-row-space" style={{ alignItem: 'start' }}>
                    <Subtitle1 style={{}}>{alert.data.title}</Subtitle1>
                    <Body2 color="grey">{dayjs(alert.created_at).fromNow()}</Body2>
                  </div>
                  <Subtitle2 style={{ width: '100%' }}>
                    {alert.data.message}
                  </Subtitle2>
                </div>
                <div onClick={() => handleRead(alert)}>
                  <Tooltip title={alert.read_at ? "Read" : 'Mark as read'}>
                    <DotCircle color={alert.read_at ? "grey" : 'crimson'} size="1px" />
                  </Tooltip>
                </div>
              </div>
              <Subtitle1 style={{ color: "#038A96", marginLeft: 5 }} onClick={() => openNotification(alert)}>Open Notification</Subtitle1>
              {index < loadedNotifications.filter(item => dayjs().diff(dayjs(item.created_at), "days") < 1).length - 1 && <Divider style={{ width: '100%' }} />}
            </>
          ))}
        </div>
        : null}
      {loadedNotifications.filter(item => dayjs().diff(dayjs(item.created_at), "days") > 1 && dayjs().diff(dayjs(item.created_at), "days") < 2).length ?
        <div className="flex flex-col items-start justify-start p-4 bg-white shadow rounded-xl" style={{ width: '100%', marginBottom: '20px' }}>
          <Body2 color="grey">YESTERDAY</Body2>
          {loadedNotifications.filter(item => dayjs().diff(dayjs(item.created_at), "days") < 2).map(alert, index => (
            <>
              <div onClick={() => openNotification(alert)} className="flex-boy-row-space"
                style={{ alignItems: 'baseline', width: "100%", cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <div style={{ width: '100%', textOverflow: 'ellipsis' }}>
                  <div className="flex-boy-row" style={{ alignItem: 'start' }}>
                    <Subtitle1 style={{}}>{alert.data.title}</Subtitle1>
                    <Body2 color="grey">{dayjs(alert.created_at).fromNow()}</Body2>
                  </div>
                  <Subtitle2 style={{ width: '100%' }}>
                    {alert.data.message}
                  </Subtitle2>
                </div>
                <div onClick={() => handleRead(alert)} className="ml-2">
                  <Tooltip title={alert.read_at ? "Read" : 'Mark as read'}>
                    <DotCircle color={alert.read_at ? "grey" : 'crimson'} size="1px" />
                  </Tooltip>
                </div>
              </div>
              {index < loadedNotifications.filter(item => dayjs().diff(dayjs(item.created_at), "days") < 2).length - 1 && <Divider style={{ width: '100%' }} />}
            </>
          ))}
        </div>
        : null}
      {loadedNotifications.filter(item => dayjs().diff(dayjs(item.created_at), "days") > 2).length ?
        <div className="flex flex-col items-start justify-start p-4 bg-white shadow rounded-xl " style={{ width: '100%', marginBottom: '20px' }}>
          <Body2 color="grey">OLDER</Body2>
          {loadedNotifications.filter(item => dayjs().diff(dayjs(item.created_at), "days") > 2).map(alert => (
            <>
              <div div onClick={() => openNotification(alert)} className="flex-boy-row-space"
                style={{ alignItems: 'baseline', width: "100%", cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <div style={{ width: '100%', textOverflow: 'ellipsis' }}>
                  <div className="flex-boy-row-space" style={{ alignItem: 'start' }}>
                    <Subtitle1>{alert.data.title}</Subtitle1>
                    <Body2 color="grey">{dayjs(alert.created_at).fromNow()}</Body2>
                  </div>
                  <Subtitle2 style={{ width: '100%' }} className="text-ellipsis">
                    {alert.data.message}
                  </Subtitle2>
                </div>
                <div onClick={() => handleRead(alert)}>
                  <Tooltip title={alert.read_at ? "Read" : 'Mark as read'}>
                    <DotCircle color={alert.read_at ? "grey" : 'crimson'} size="1px" />
                  </Tooltip>
                </div>
              </div>
              {loadedNotifications.filter(item => dayjs().diff(dayjs(item.created_at), "days") > 2).length - 1 && <Divider style={{ width: '100%' }} />}
            </>
          ))}
        </div>
        : null}
    </>
  )
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.notifications,
  rcopianotifications: state.notifications.rcopianotifications,
  activeUser: state.auth?.activeUser
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)