import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {Breadcrumb, Button, Checkbox, Col, Divider, Dropdown, Form, Input, Menu, Modal, Row, Select, Spin, Switch} from 'antd';
import { errMsg, getToastId, updateToast } from '../../../../app/Reducers/reducerUtils';
import { apiRequest } from '../../../../app/Apis';
import _ from "lodash"
import { fetchPatientVitals } from '../../../../app/Reducers';
const vitals_list = [{ title: 'height', units: 'inches' }, { title: 'weight', units: 'lbs' },
{
    title: 'bmi', units: null
}, { title: 'respiration', units: null }, { title: 'heart_rate', units: 'bpm' }, {
    title: 'temperature', units: 'oF'
}, { title: 'blood_pressure', units: 'systolic' }, { title: 'diastolic_blood_pressure', units: 'diastolic' }]

export const Vitalsform = ({ vitals, patient, closeModal, isEditForm }) => {
    const [form] = Form.useForm();

    const dispatch = useDispatch()
    const vitalsChange = (e) => {
        const height = form.getFieldValue('height');
        const weight = form.getFieldValue('weight');
        console.log(e, parseFloat(weight), (parseFloat(height) ** 2))
        form.setFieldsValue({
            bmi: isNaN(((parseFloat(weight) / ((parseFloat(height) ** 2))) * 703).toFixed(2)) ? 0 : ((parseFloat(weight) / ((parseFloat(height) ** 2))) * 703).toFixed(2)
        })
    }

    const VitalsFinish = async (values) => {
        const toastId = getToastId("Saving Vitals")
        try {

            let data = { ...values, patient_id: patient.id }

            const vitalsresponse = await apiRequest.post('/patient-vitals', data)
            if (vitalsresponse && vitalsresponse.data.data) {
                updateToast(toastId, "Vitals Saved", true)
                await await dispatch(fetchPatientVitals(patient.id))
                closeModal()
            }

        } catch (e) {

            console.log(e)
            updateToast(toastId, errMsg(e, false, null, 'Vitals saving failed'), false)
        }
    }
    return (
        <div>
            <Form
                name="vitals"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 16 }}
                onFinish={VitalsFinish}
                initialValues={isEditForm ? { ...vitals?.[0] } :null }
                layout="vertical"
                form={form}
                style={{ width: "100%" }}>
                <Row gutter={16}>
                    {vitals_list.map((item, index) => (<Col key={index} span={12}>
                        <Form.Item
                            label={item.title === 'bmi' ? "BMI" : item.title === "diastolic_blood_pressure" ? "Blood Pressure (diastolic)" : `${_.startCase(item.title)} ${item.units ? `(${item.units})` : ''}`}
                            name={item.title}
                            rules={[{ required: false, message: 'this field is required!' }]}
                            wrapperCol={{ span: 24 }}>
                            <Input autoComplete={'off'} onChange={e => vitalsChange(e)} />
                        </Form.Item>
                    </Col>))}
                </Row>
                <Form.Item wrapperCol={{ span: 1 }} label="" name="scanned"
                           valuePropName="checked" initialValue={false}>
                    <Checkbox>Scanned</Checkbox>
                </Form.Item>

            </Form>
        </div>
    )
}

const mapStateToProps = (state) => ({
    vitals: state.patients.vitals, patient: state.patients.selectedPatient, navs: state.navs
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Vitalsform)