import {useCallback, useEffect, useState} from "react";
import _ from 'lodash';
import {apiRequest} from '../app/Apis';

export const useConsultantSearch = (setState, deps) => {
  const [isRun, setIsRun] = useState(false);
  
 /* if (!isRun) {
    setIsRun(true);
  }*/
  
 return  useCallback(_.debounce(async function (value) {
     if (!value||value===''){
         setState([])
         return
     }
    const { data } = await apiRequest.get(`/consultants?search=${value}`)
    if (data) {
      setState(data.data.map(item => ({ label: item.name||`${item?.first_name||''} ${item?.last_name||''}`, value: item.name||`${item?.first_name||''} ${item?.last_name||''}`, ...item })));
    }
  }, 1000), deps)
};