import React, {useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {closeButton, containedButton, deleteButton, dialogCloser, editButton, emptyTable, formSubmitButton, getSkels, horStack, onTableChange, outlinedButton} from '../../../common/helpers'
import {Modal, Popconfirm, Table} from 'antd'
import {Headline6} from '../../../components'
import NewTask from './Taskforms/NewTask';
import ViewTask from './Taskforms/ViewTask';
import {deleteTask, fetchTasks, setViewTask, updateTaskFilters} from '../../../app/Reducers'
import {PlusOutlined} from '@ant-design/icons';

export const PatientTasksListView = ({ tasks, columns, taskPagination, patient, appConf, selectedTask, ViewTaskOpen }) => {
  const dispatch = useDispatch()
  const addTask = async() => {
    await setEditingForm(false)
    newTaskModalOpen()
    
    // setContent(<PatientTaskForm patient={patient} />)
  }
  
  // new task modal
  const [newTaskModal, setNewTaskModal] = useState(false)
  
  const newTaskModalOpen = () => {
    setNewTaskModal(true)
  }
  const newTaskModalClose = () => {
    setNewTaskModal(false)
  }
// is executing
  const [isExecuting, setIsExecuting] = useState(false)

// viw task modal
  const [viewTaskModal, setViewTaskModal] = useState(false)
  
  const viewTaskModalOpen = () => {
    dispatch(setViewTask(true))
  }
  const viewTaskModalClose = () => {
    dispatch(setViewTask(false))
  }
  const removeTask = async (id, cb) => {
    await dispatch(deleteTask({ id }))
    cb()
  }
  
  const [editingForm, setEditingForm] = useState(true)
  
  return (<>
    
    {/*  new taksk modal */}
    <Modal
      title="New Task"
      open={newTaskModal}
      onCancel={newTaskModalClose}
      
      width={600}
      destroyOnClose={true}
      maskClosable={false}
      centered={true}
      className="new-task-modal"
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        {horStack([outlinedButton(dialogCloser(setNewTaskModal), 'Cancel', isExecuting),
          formSubmitButton('taskForm', editingForm ? 'Update' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
      </div>]}
    >
      <NewTask closeModal={newTaskModalClose} editingForm={editingForm} isExecuting={isExecuting} setIsExecuting={setIsExecuting}/>
    </Modal>
    {/* view task modal */}
    <Modal
      closable={false}
      title={<div className="flex flex-1" style={{ justifyContent: 'space-between' }}>
        <Headline6>View Task</Headline6>
        <div className="flex flex-1 flex-row items-center " style={{ justifyContent: 'flex-end' }}>
          <Popconfirm
            placement="top"
            title="Are you sure to delete this task?"
            description=""
            onConfirm={() => {
              removeTask(selectedTask.id, viewTaskModalClose)
            }}
            okText="Yes"
            cancelText="No"
          >
            {deleteButton('Delete', isExecuting, () => {
            }, 'Delete Task')}
          </Popconfirm>
          
          {editButton(() => {
            newTaskModalOpen()
          })}
          {closeButton(() => {
            viewTaskModalClose()
          })}
        </div>
      
      
      </div>}
      
      open={ViewTaskOpen}
      onCancel={viewTaskModalClose}
      onOk={viewTaskModalClose}
      width={1000}
      destroyOnClose={true}
      maskClosable={false}
      centered={true}
      className="view-task-modal"
    
    >
      <ViewTask/>
    </Modal>
    {tasks.length > 0 ?
      
      <div className="w-full">
        <Table
          locale={{
            emptyText: !appConf?.taskFilters?.[0] ? emptyTable('patient tasks' ) : emptyTable('patient tasks.')
          }}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchTasks, appConf.taskFilters, updateTaskFilters, {})}
          /*onRow={(record, rowIndex) => ({
            onClick: async event => {
              await dispatch(selectTask(record))
              viewTaskModalOpen()
            } // click row
          })}*/
          {...getSkels(appConf?.taskFilters?.[2], columns, tasks)}
          pagination={taskPagination}
        />
      </div>
      
      : emptyTable('Document', containedButton(addTask, 'Add Task', false, null, {}, {
        icon: <PlusOutlined />
      }))}
  </>)
}

const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser,
  patient: state.patients.selectedPatient,

  taskPagination: state.tasks.taskPagination,
  appConf: state.appConf,
  tasks: state.tasks.tasks,
  selectedTask: state.tasks.selectedTask,
  ViewTaskOpen: state.tasks.ViewTaskOpen
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PatientTasksListView)