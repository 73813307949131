import React, {useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Col, Modal, Row} from 'antd'
import {Body2, Headline6, Subtitle1} from '../../../components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import SignaturePad from 'react-signature-canvas';
// import './sigCanvas.css';
import {EditServices} from './EditServices'
import {toast} from 'react-toastify'
import {apiRequest} from '../../../app/Apis'
import {ProviderDocument} from './ProviderDocuments'
import {fetchProvider, setActiveUser} from '../../../app/Reducers'
import {closeDialog, containedButton, formSubmitButton, horStack, modalClose, outlinedButton} from "../../../common/helpers";
import EditProvider from './EditProvider'
import RemoveCookie from '../../../hooks/RemoveCookie';
import SetCookie from '../../../hooks/setCookie';


export const ProviderSummary = ({  provider }) => {
  
  const [signatureModal, setSignatureModal] = useState(false);
  const [vehicleForm, setVehicleForm] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const [servicessModal, setServicessModal] = useState(false)
  const [imageUrl, setImageUrl] = useState(null);
  const [isExecuting, setisExecuting] = useState(false)
  const sigCanvas = useRef({});
  console.log("provider", provider)
  const clear = () => sigCanvas.current.clear();
  
  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]); else byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }
  
  const dispatch = useDispatch()
  const save = async () => {
    await setisExecuting(true)
    setImageUrl(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
    const toastId = toast("updating signature", { type: "info", autoClose: false });
    let formData = new FormData();
    const file_64 = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
    let file = dataURItoBlob(file_64)
    formData.append("file", file);
    const { data } = await apiRequest.post('/files', formData)
    const image_id = data.data.id
    const signature = await apiRequest.post(`consultants/${provider.consultantProfile.id}/signature`, { signature_id: image_id })
    
    if (signature && signature.data.data) {
      const user = await apiRequest.get('/me/profile').catch(async function (err) {
        await setisExecuting(false)
        console.log(err.response.data)
        toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
      })
      if (user && user.data) {
        // RemoveCookie('activeUser')
        SetCookie('activeUser', JSON.stringify(user.data.data))
        // localStorage.setItem('activeUser', JSON.stringify(user.data.data))
        await dispatch(setActiveUser(user.data.data))
        await dispatch(fetchProvider(provider.practiceProfile?.id || provider.id))
      }
      toast.update(toastId, { render: "Signature updated", type: toast.TYPE.SUCCESS, autoClose: 5000 });
    }
    await setisExecuting(false)
    handleSignatureCancel()
  };
  const handleSignatureOk = () => {
  }
  const handleSignatureCancel = () => setSignatureModal(false)
  
  const handleVehiclesCancel = () => {
    setVehicle(null)
    setVehicleForm(false)
  }
  const [providerForm, setproviderForm] = useState(false)
  
  return (
    (<div className="">
      <Modal
        destroyOnClose={true}
        title={"Edit Provider"}
        open={providerForm}
        onCancel={() => closeDialog(setproviderForm)}
        // closeIcon={() => modalClose(closeDialog(setproviderForm))}
        maskClosable={false}
        footer={[
          <div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([
              outlinedButton(() => closeDialog(setproviderForm), 'Cancel', isExecuting),
              formSubmitButton('providerForm', 'Save', isExecuting, 'Saving')
            ])}
          </div>
        ]}>
        <EditProvider provider={provider} handlepatientCancel={() => closeDialog(setproviderForm)} setisExecuting={setisExecuting}/>
      </Modal>
      <Modal
        title="Signature Pad"
        open={signatureModal}
        onOk={handleSignatureOk}
        onCancel={() => handleSignatureCancel}
        closeIcon={() => modalClose(handleSignatureCancel)}
        maskClosable={false}
        width={600}
        footer={[
          <div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([
              outlinedButton(() => handleSignatureCancel(), 'Cancel', isExecuting),
              outlinedButton(clear, 'Clear', isExecuting),
              containedButton(save, 'Save', isExecuting, 'Saving')
            ])}
          </div>
        ]}>
        <SignaturePad ref={sigCanvas} canvasProps={{ width: 552, height: 500, className: 'signatureCanvas' }}/>
      </Modal>
      <Modal
        title="Provider Services"
        open={servicessModal}
        onCancel={() => closeDialog(setServicessModal)}
        closeIcon={() => modalClose(closeDialog(setServicessModal))}
        maskClosable={false}
        width={600}
        footer={[
          <div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([
              outlinedButton(() => closeDialog(setServicessModal), 'Cancel', isExecuting),
              formSubmitButton('providerForm', 'Save', isExecuting, 'Saving')
            ])}
          </div>
        ]}>
        <EditServices setisExecuting={setisExecuting} closeModal={() => closeDialog(setServicessModal)}
                      provider={provider}/>
      </Modal>
      <Modal
        destroyOnClose={true}
        title={"Add Document"}
        open={vehicleForm || !!vehicle}
        onCancel={handleVehiclesCancel}
        closeIcon={() => modalClose(handleVehiclesCancel)}
        maskClosable={false}
        footer={[
          <div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([
              outlinedButton(() => handleVehiclesCancel(), 'Cancel', isExecuting),
              formSubmitButton('providerForm', 'Save', isExecuting, 'Saving')
            ])}
          </div>
        ]}>
        <ProviderDocument provider={provider} vehicle={vehicle} handleShippingCancel={handleVehiclesCancel}/>
      </Modal>
      <Row gutter={16}>
        <Col span={24}>
          <div className="flex-boy-column">
            <div className="card-box">
              <div className="flex-boy-row-space" style={{ width: "100%" }}>
                <Headline6>Primary Information</Headline6>
                <FontAwesomeIcon onClick={() => setproviderForm(true)} color="grey" icon={["fas", "pencil-alt"]}/>
              </div>
              <Row gutter={48} style={{ width: "100%" }}>
                <Col span={12}>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Name</Subtitle1>
                    <Body2 color="black" style={{ textAlign: 'left', flex: 1 }}>{provider.name}</Body2>
                  </div>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Gender</Subtitle1>
                    <Body2 color="black"
                           style={{ textAlign: 'left', flex: 1, textTransform: 'capitalize' }}>{provider.gender}</Body2>
                  </div>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Date of birth</Subtitle1>
                    <Body2 color="black" style={{
                      textAlign: 'left', flex: 1
                    }}> {provider?.birth_date ?  dayjs(provider?.birth_date).format("MM/DD/YYYY") + ` (${dayjs().diff(dayjs(provider?.birth_date), 'years')}y.o.)` : "N/A"} </Body2>
                  </div>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Date of hire</Subtitle1>
                    <Body2 color="black" style={{
                      textAlign: 'left', flex: 1
                    }}>{dayjs(provider.practiceProfile?.date_of_hire || provider.consultantProfile?.date_of_hire).format("MM/DD/YYYY")}</Body2>
                  </div>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>NPI</Subtitle1>
                    <Body2 color="black" style={{ textAlign: 'left', flex: 1 }}>{provider.practiceProfile?.npi || provider.consultantProfile?.npi || "N/A"}</Body2>
                  </div>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>CAQH ID</Subtitle1>
                    <Body2 color="black" style={{ textAlign: 'left', flex: 1 }}>{provider.practiceProfile?.caqh_id || provider.consultantProfile?.caqh_id || "N/A"}</Body2>
                  </div>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>BHPN ID</Subtitle1>
                    <Body2 color="black" style={{ textAlign: 'left', flex: 1 }}>{provider.practiceProfile?.bhpn_practitioner_id || provider.consultantProfile?.bhpn_practitioner_id || "N/A"}</Body2>
                  </div>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Language</Subtitle1>
                    <Body2 color="black" style={{
                      textAlign: 'left', flex: 1, textTransform: "capitalize"
                    }}>{provider.practiceProfile?.languages.join(",") || provider.consultantProfile?.language}</Body2>
                  </div>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Degree</Subtitle1>
                    <Body2 color="black"
                           style={{ textAlign: 'left', flex: 1, textTransform: "capitalize" }}>{provider.practiceProfile?.degree || provider.consultantProfile?.degree}</Body2>
                  </div>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Experience</Subtitle1>
                    <Body2 color="black"
                           style={{
                             textAlign: 'left',
                             flex: 1,
                             textTransform: "capitalize"
                           }}>{provider.practiceProfile?.experience_years || provider.consultantProfile?.experience_years || "N/A"}</Body2>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>User Name</Subtitle1>
                    <Body2 color="black"
                           style={{ textAlign: 'left', flex: 1, textTransform: "capitalize" }}>{provider.name}</Body2>
                  </div>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Role</Subtitle1>
                    <Body2 color="black" style={{ textAlign: 'left', flex: 1 }}>{provider.roles.map(item => item.name)}</Body2>
                  </div>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>SSN</Subtitle1>
                    <Body2 color="black" style={{ textAlign: 'left', flex: 1 }}>{provider.ssn}</Body2>
                  </div>
                  
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Tax ID</Subtitle1>
                    <Body2 color="black" style={{ textAlign: 'left', flex: 1 }}>{provider.practiceProfile?.tax_id || provider.consultantProfile?.tax_id || 'N/A'} </Body2>
                  </div>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Phone</Subtitle1>
                    <Body2 style={{ textAlign: 'left', flex: 1 }}>{provider.phone_no}</Body2>
                  </div>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Email</Subtitle1>
                    <Body2 style={{ textAlign: 'left', flex: 1 }}>{provider.email}</Body2>
                  </div>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>Address</Subtitle1>
                    <Body2 color="black"
                           style={{ textAlign: 'left', flex: 1 }}>{provider.address_1 || provider.zip_code}</Body2>
                  </div>
                  <div className="flex-boy-row-space" style={{ width: "100%" }}>
                    <Subtitle1 style={{ textAlign: 'left', flex: 1 }}>About</Subtitle1>
                    <Body2 color="black" style={{ textAlign: 'left', flex: 1 }}>{provider.about_me || "N/A"}</Body2>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>)
  );
}
const mapStateToProps = (state) => ({
  consultant: state.consultants.selectedConsultant,
  provider: state.consultants.selectedProvider,
  appointments: state.consultants.consultantSchedule, vehicles: state.consultants.vehicles
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ProviderSummary)
