import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Segmented, Divider, Tooltip, Empty, Spin, DatePicker, Form, Select } from "antd";
import { apiRequest } from "../../../app/Apis";
import { emptyFun } from "../../../common/helpers";
import TinyTable from "./TinyTable";
import { Headline6 } from "../../../components";
import { TrimmedStateArray } from "../../Master/MasterList";

export default function BiologicsCharts({ region, activeUser }) {
  const dateFormat = 'YY-MM-DD';
  const [option, setOption] = useState('false')
  const [dateOption, setDateOption] = useState('week')
  const [biologics, setBiologics] = useState([])
  const [loading, setLoading] = useState(true)
  const [step, setStep] = useState(0)
  const [navParams, setNavParams] = useState(null);
  const [dateValues, setDateValues] = useState(null)
  const [endDate, setEndDate] = useState(dayjs().format(dateFormat));
  const [startDate, setStartDate] = useState(dayjs().subtract(5, 'days').format(dateFormat));
  const [selectedStates, setSelectStates] = useState('')

  const disabledFutureDates = (current) => current && current > dayjs(dayjs())

  const { RangePicker } = DatePicker;
  const defaultOptions = [
    { label: 'By Office', value: 'facility' },
    { label: 'By State', value: 'state' }
  ]

  const dateOptions = [
    { label: 'Last 5 Days', value: 'week' },
    { label: 'Last Month', value: 'month' },
  ]

  function onChangeLocation(option) {
    setOption(option)
  }

  function onChangeDate(option) {
    setDateOption(option)
    if (option === 'week') {
      setStartDate(dayjs().subtract(5, 'days').format(dateFormat))
      setEndDate(dayjs().format(dateFormat))
    } else if (option === 'month') {
      setStartDate(dayjs().subtract(1, 'month').format(dateFormat))
      setEndDate(dayjs().format(dateFormat))
    }
  }

  const onChange = async (value) => {
    if (value !== null) {
      console.log(value);
      setDateValues(value);

      let start = dayjs(value[0])?.format(dateFormat);
      let end = dayjs(value[1])?.format(dateFormat);
      setStartDate(start);
      setEndDate(end);
    } else {
      setDateValues(null);
    }
  };

  async function fetchBiologicsData(option) {
    setLoading(true);
    let queryString = ''
    let state = ''
    if (!!region && region !== 'all') {
      queryString = `&region=${region}`
    }

    if (!!selectedStates && selectedStates !== '') {
      // check if state is empty
      state = `&state=${selectedStates}`
    }

    const group = option === 'state' ? `&groupBy=${"state"}` : `&groupBy=${"facility"}`
    const dates = `?dateRange[start_at]=${startDate}&dateRange[end_at]=${endDate}`
    const response = await apiRequest.get(`/statistics/biologic-applications${dates}${group}${queryString}${state}`)

    const res = response.data.data
    setBiologics(res)
    setLoading(false)
  }

  useEffect(() => {
    fetchBiologicsData(option)
    return emptyFun
  }, [startDate, endDate, option, region, selectedStates])

  return <div className="stats-card-box">
    <div className="flex-boy-row-space" style={{ width: '100%', marginBottom: 2 }}>
      <div style={{ width: '100%', padding: '5px' }}>
        <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>Biologics Statistics</Headline6>
        <Divider style={{ margin: '4px', color: '#E2E4EA', width: '100%' }} />
      </div>
      <div style={{ width: '100%', padding: '5px' }}>
        <Segmented options={dateOptions} onChange={(value) => onChangeDate(value)}></Segmented>
      </div>
    </div>
    <div className="">
      <div className="flex justify-start p-4">
        <Segmented options={defaultOptions} onChange={(value) => onChangeLocation(value)}></Segmented>
        {/* {option === 'state' &&
          <Select
            size={'middle'}
            defaultValue={TrimmedStateArray[0]}
            onChange={(value) => setSelectStates(value)}
            style={{ width: 150, marginLeft: '10px' }}
            options={TrimmedStateArray.map((state) => ({ label: state, value: state }))}
          />
        } */}
      </div>
      <Spin spinning={loading}>
        <TinyTable patients={biologics} type={"facility"} option={option} setStep={setStep} setNavParams={setNavParams} disabled={true} />
      </Spin>
    </div>
    <RangePicker
      style={{
        borderRadius: '7px',
        cursor: 'pointer',
        marginTop: '15px',
        float: 'right'
      }}
      disabledDate={disabledFutureDates}
      allowClear={true}
      format={dateFormat}
      onChange={(dateStrings) => onChange(dateStrings)}
      onCalendarChange={(dateStrings) => setDateValues(dateStrings)}
    />
  </div>
}