import { Avatar, Divider, Dropdown, Image, Modal, Menu } from 'antd'
import React, {useEffect, useState} from 'react'
import { connect, useDispatch } from 'react-redux'
import { Body2, Headline6, RegularButton, Subtitle1 } from '../../components'
import dayjs from 'dayjs'
import { usePermissionCheck } from '../../hooks/usePermissionCheck'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { updateConsultant } from '../../app/Reducers'
import EditConsultant from './components/EditConsultant'
import {appMessage, dialogCloser, emptyFun, formSubmitButton, horStack, kebabMenus, modalClose, outlinedButton} from '../../common/helpers'
import KebabMenu from '../../components/KebabMenu'
import ConsultantsHealingRate from './components/ConsultantHealingRate'
import ConsultantBiologics from './components/ConsultantBiologics'
import AuditLogViewer from '../../components/AuditLogs/AuditLogViewer';
import {apiRequest} from '../../app/Apis';

export const ConsultantInfoPane = ({ consultant, activeUser, consultantStateLicences }) => {
    const can_deactivate_consultant = usePermissionCheck('deactivate_consultant', false, [])
     const can_view_consultant_biologics_applications = usePermissionCheck('view_consultant_biologics_applications', false, [])
    let stati = ['active', 'in-active']
    const dispatch = useDispatch()
    async function handleMenuClick(e) {
        console.log(stati[e.key])
        let pl = { data: { status: stati[e.key] }, consultant_id: consultant.id }
        await dispatch(updateConsultant(pl))
    }

    const approvalMenu = (<Menu onClick={handleMenuClick}>
        {stati.map((item, index) => <Menu.Item key={index}
            style={{ textTransform: 'capitalize' }}> {item.replace('-', '').replace(/_/g, ' ')}  </Menu.Item>)}
    </Menu>);


    const getIndicator = (text) => {
        return text === "active" ? <div className="dot-circle-active" /> : text === 'in-active' ?
            <div className="dot-circle-inactive" /> : <div className="dot-circle-inactive" />
    }
    const [consultantForm, setconsultantForm] = useState(false)
    const [isExecuting, setisExecuting] = useState(false)
    const [showAuditDialog, setShowAuditDialog] = useState(false);
    const can_update_consultant = usePermissionCheck('update_consultant', false, [])
    const can_update_consultant_global = usePermissionCheck('update_consultant_global', false, [])
    const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
    

    const actionMenus = kebabMenus((can_update_consultant_global || consultant.user_id === activeUser.id), can_view_audit_logs, false)

    async function kebabMenuClick(e) {
        switch (actionMenus[e.key].title) {
            case 'Edit':
                await setconsultantForm(true)
                break
            case 'Audit':
                setShowAuditDialog(true);
                break
            case 'Delete':
                appMessage('Operation not allowed', 'error')
                break
            default:
                await setShowAuditDialog(false);
        }
    }
  return (
      <div className="card-box h-full break-words">
          {(!!consultant && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false}
                                                                      resourceDescription={`${consultant.name}'s task`} resourceType={'Consultant'}
                                                                      defaultValues={{ auditableId: consultant?.id, auditable: 'consultant', format: 'json' }}/>}
          <Modal
              destroyOnClose={true}
              title={"Edit Consultant"}
              open={consultantForm}
              // onOk={handleShippingOk}
              onCancel={dialogCloser(setconsultantForm)}
              closeIcon={modalClose(dialogCloser(setconsultantForm))}
              maskClosable={false}
              width={800}
              footer={[
                  <div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
                      {horStack([
                          outlinedButton(() => dialogCloser(setconsultantForm)(), 'Cancel', isExecuting),
                          formSubmitButton('consultantForm', 'Update', isExecuting, 'Updating')
                      ])}
                  </div>
              ]}>
              <EditConsultant consultant={consultant} closeModal={dialogCloser(setconsultantForm)} setisExecuting={setisExecuting} />
          </Modal>
          <div className="flex-boy-col items-center" style={{ width: "100%" }}>
          <Avatar size={64} src={<Image src={consultant?.profile_image_url || require("../../assets/user.png")} />} />
          <div className="mb-2" style={{ textAlign: 'center' }}>
                  <Headline6 style={{ textAlign: 'center' }} >{consultant.name}</Headline6>
                  <Subtitle1 style={{ textAlign: 'center' }}>{consultant?.roles?.toString() || "N/A"}</Subtitle1>
              <Body2
                  color="grey">{dayjs().diff(dayjs(consultant.birth_date), 'years')}y.o.({dayjs(consultant.birth_date).format("MM/DD/YYYY")}), {consultant.gender}</Body2>
              </div>
              <div className="flex-boy-row-space justify-center">
                  {can_deactivate_consultant ?
                      <Dropdown dropdownRender={()=>approvalMenu} trigger={['click']}>
                          <RegularButton
                              /*onClick={() => setIsVisible(prevState => !prevState)}*/
                              className="flex-boy-row-space" color="grey" background="transparent"
                              style={{ marginRight: "10px" }} borderColor="gainsboro">
                              {getIndicator(consultant.status ? consultant.status : 'in-active')}
                              &nbsp; &nbsp;
                              <Subtitle1
                                  style={{ textTransform: 'capitalize' }}>{consultant.status ? consultant.status : 'Unspecified'}</Subtitle1>
                              &nbsp; &nbsp;
                              <FontAwesomeIcon color="grey" icon={["fas", "caret-down"]} />
                          </RegularButton>
                      </Dropdown> : null}
                  {can_update_consultant &&
                      <KebabMenu menus={actionMenus}
                          recordItem={consultant}
                          handleMenuClick={kebabMenuClick} resource={'consultant'} handleOk={null} handleCancel={null} confirmLoading={null}
                          showConfirmDialog={false} />

                  }
                  
              </div>
              <Divider />
              <div className="w-full text-left">
                  
             

              <div className=" my-4 flex flex-row justify-between w-full">
                  <p className="mb-3 text-xs">PST Time zone</p>
                  <p className="mb-3 text-xs">{consultant?.timezone || "N/A"}</p>
              </div>
              <p className="my-6 text-blue-500">{consultant?.phone_no} </p>
              <div className="my-4"></div>
              <p className="my-6  text-blue-500">{consultant?.email}</p>
              <div className="my-4"></div>
              <p className="mb-4  text-xs">{consultant?.address_1}, {consultant?.zip ? consultant?.zip + ',' : ''} {consultant?.city ? consultant?.city + ',' : ''} {consultant?.state}</p>
              <div className="my-4"></div>
              <p className="mb-4  text-xs">{consultant?.address_2}</p>
              <div className="my-4"></div>
              <div className=" mt-2 flex flex-row justify-between w-full">
                  <p className="mb-3 text-xs">ID: {consultant?.id}</p>
              </div>
                  {!!consultantStateLicences[0] && <div className="flexy-column mt-2 justify-between">
                      {consultantStateLicences?.map((item) => {
                          return item?.licenses?.map(value => {
                              return dayjs(value.expiry_date).isBefore(dayjs().add(2, 'months')) ?
                                <p className="mb-1 text-xs bg-[#F7F8F9] py-2 px-2"><span className={'font-bold text-lg text-red-500'}>⚠️</span> Your {value.license_type} licence <span className={'font-semibold'}>{value.license_name}</span> for {item.state_abbreviation}<span className={'font-semibold'}>{dayjs(value.expiry_date).isBefore(dayjs())?' has expired':` expires on ${dayjs(value.expiry_date).format('YYYY-MM-DD')}`}.</span> Please act on
                                    it!</p> : null
                          })
                      }).flat(1)}
                  </div>}
              
              </div>
              <Divider />
              {consultant.roles?.includes('WoundCareConsultant') && <div>
                  <ConsultantsHealingRate consultant={consultant} />
              </div>}
              {can_view_consultant_biologics_applications && <div>
                  <ConsultantBiologics consultant={consultant} />
              </div>}
              
              </div>
                          
          </div>
    )
    
}

const mapStateToProps = (state) => ({
    consultant: state.consultants.selectedConsultant,
    consultantStateLicences: state.consultants.consultantStateLicences,
    navs: state.navs,
    activeUser: state.auth.activeUser
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantInfoPane)