/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Avatar, Button, Form, Input, Spin} from 'antd'
import {createProgressNote} from '../../../app/Reducers'
import PatientAppointments from "./PatientAppointments";
import {appLog, emptyTable, executeChunkFn, skelBars} from "../../../common/helpers";
import PatientWounds from './PatientWounds/PatientWounds'
import ImmunizationSection from './PatientHealthData/ImmunizationSection'
import {CloseOutlined, DownloadOutlined, LoadingOutlined, RightOutlined, UserOutlined} from '@ant-design/icons'
import AllergiesSection from './PatientHealthData/AllergiesSection'
import MedicationSection from './PatientHealthData/MedicationSection'
import FamilyHistorySection from './PatientHealthData/FamilyHistorySection'
import DiagnosisSection from './PatientHealthData/DiagnosisSection'
import dayjs from 'dayjs'
import PatientOrderList from './PatientOrderDme'
import download from "downloadjs";
import {apiRequest} from '../../../app/Apis'
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const TimeLine = ({ patient, changeTab }) => {
  const can_view_patient_medication = usePermissionCheck('view_patient_medication', false, [])
  const can_view_patient_diagnosis = usePermissionCheck('view_patient_diagnosis', false, [])
  const can_view_patient_allergy = usePermissionCheck('view_patient_allergy', false, [])
  const can_view_patient_next_of_kin = usePermissionCheck('view_patient_next_of_kin', false, [])
  const [timelineNotes, setTimeLineNotes] = useState("")
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    fetchUpdates().catch(appLog)
    return emptyTable
  }, [patient])
  
  const [activities, setactivities] = useState([])
  const fetchUpdates = async () => {
    await setLoading(true)
    const { data } = await apiRequest.get(`/patients/${patient.id}/activity-log`).catch(err => console.log(err))
    // console.log({ activities: data.data })
    await setactivities(data?.data || [])
    await setLoading(false)
    
  }
  const [showOverLay, setshowOverLay] = useState(false)
  const [currentContent, setcurrentContent] = useState(null)
  const [isExecuting, setisExecuting] = useState(false)
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  const setContent = (val) => {
    setcurrentContent(val)
    setshowOverLay(true)
    
  }
  const dispatch = useDispatch()
  const onFinish = async (values) => {
    console.log(values)
    let payload, pl = { note: values.note, patient_id: patient.id }
    
    payload = pl
    
    await executeChunkFn(dispatch, createProgressNote, payload, setisExecuting, null)
    await fetchUpdates()
    clearInput()
  }
  
  
  const inputRef = useRef();
  
  
  const clearInput = () => {
    setTimeLineNotes("")
  };
  
  const onTimelineNote = async () => {
    let payload = { note: timelineNotes, patient_id: patient.id }
    await executeChunkFn(dispatch, createProgressNote, payload, setisExecuting, null, async () => {
      await fetchUpdates()
      clearInput()
    })
  }
  return (
    <div className="w-full card-box my-2 relative timeline">
      <div className="flex  w-full">
        <p className="font-semibold text-xl">Last 30 Days updates</p>
      </div>
      {(can_update_patient_resource  && !loading) && <div className="my-0 w-full flex flex-row  h-20">
        <div className="flex flex-col items-end justify-start content-center w-1/5  py-8 px-4">
          <p className="text-[14px] font-semibold text-rigth my-auto">Today</p>
        </div>
        {activities[0] && <div className={' h-full flex flex-col items-center bg-white'}>
          <div className={'w-[0.2px] bg-transparent flex-1'}></div>
          <div className={'w-[0.2px] bg-gray-500 flex-1'}></div>
        </div>}
        <div className="add-note bg-white flex-1 flex flex-row items-center  py-8">
          {activities[0] && <div className={'w-[30px] bg-gray-500 h-[0.2px]'}></div>}
          <div className="flex-1 h-10 items-center flex rounded-lg bg-white border-[0.1px] border-gray-400 border-solid">
            <input disabled={isExecuting}
                   type="text" value={timelineNotes}
                   onChange={event => setTimeLineNotes(event.target.value)}
                   className="w-full h-full py-4 px-4 text-[14px] text-black rounded-lg outline-none border-0"
                   placeholder="Add note..."
            />
            
            <Button onClick={onTimelineNote} disabled={isExecuting} className={'m-2'} shape={'circle'} type={'text'}
                    icon={isExecuting ? <Spin size={"small"} spinning={isExecuting} indicator={<LoadingOutlined style={{ color: 'grey' }}/>}/> :
                    <FontAwesomeIcon icon={['fas', 'paper-plane']} style={{ color: 'grey' }} />}></Button>
          </div>
        </div>
      </div>}
      
      {activities[0] ?
        activities.map((item, index) => {
          let hasNextActivity = !!activities[index + 1]
          return (
            <div key={item?.id} className={`my-0 w-full flex flex-row h-fit`}>
              <div className={`flex flex-col items-end w-1/5 px-4 py-2`}>
                <p className="text-[14px] font-semibold text-right mb-0.5 px-2">{dayjs(item.created_at).format("MMM DD, ddd YYYY")}</p>
                <p className="text-right text-[12px] text-gray-600 px-2 py-0 ">{dayjs(item.created_at).format("hh:mm a")}</p>
              </div>
              <div className={'flex flex-col items-center'}>
                <div className={'w-[0.2px] bg-gray-500 flex-1'}></div>
                <div className={`w-[0.2px] ${hasNextActivity ? 'bg-gray-500' : 'bg-transparent'} flex-1`}></div>
              </div>
              <div className=" flex-1 py-2">
                <div className="flex flex-row items-center">
                  <div className={'w-[30px] bg-gray-500 h-[0.1px]'}></div>
                  <div className="flex flex-row items-center gap-2">
                    <Avatar className={'my-auto'} size={'small'} src={item?.actor?.profile_img} icon={<UserOutlined/>}
                      // className={`${!item?.profile_img ? `bg-[${item.action === 'updated' ? '#3386c0' : item.action === 'created' ? '#87d068' : '#EF8100'}]` : ''}`}
                            {...(!item?.actor?.profile_img && { style: { backgroundColor: item.action === 'updated' ? '#108ee9' : item.action === 'created' ? 'green' : '#EF8100' } })}
                    />
                    <p className="text-md font-semibold text-left">{item.actor.name}</p>
                    <p className={'text-xs'}>{item.action}</p>
                    <div className="rounded w-fit bg-gray-200 py-1 px-2">
                      <p
                        className="font-semibold text-center my-auto text-[12px]">{item.resource_type === "Immunisation" ? "Immunization" : (!item?.resource_type || item?.resource_type?.trim() === '') ? 'patient' : item.resource_type}</p>
                    </div>
                    {/* {kebabMenus()} */}
                  </div>
                </div>
                <div className={`ml-[30px] rounded w-fit my-2 bg-gray-100 px-2`}>
                  {item.resource_type === "Appointment" ? <p className="text-md  text-center capitalize" dangerouslySetInnerHTML={{ __html: item.resource.description }}/> :
                    item.resource_type === "FaceSheet" ?
                      <div className="flex  flex-row items-center"><DownloadOutlined onClick={() => download(item.resource.download_url)} style={{ fontweight: 'bold' }}/> <p
                        className="text-md ml-2 text-center">{item.resource.description}</p></div> :
                      item.resource_type === "Immunisation" ? <div className="flex  flex-row items-center"><RightOutlined onClick={() => setContent("Immunization")} style={{ fontweight: 'bold' }}/>
                          <p className="text-md ml-2 text-center">{item.resource.description}</p></div> :
                        item.resource_type === "Immunisation" ?
                          <div className="flex  flex-row items-center"><RightOutlined onClick={() => setContent("Immunization")} style={{ fontweight: 'bold' }}/> <p
                            className="text-md ml-2 text-center">{item.resource.description}</p></div> :
                          item.resource_type === "Medication" ? <div className="flex  flex-row items-center"><RightOutlined onClick={() => setContent("Medication")} style={{ fontweight: 'bold' }}/>
                              <p className="text-md ml-2 text-center">{item.resource.description}</p></div> :
                            item.resource_type === "Allergy" ? <div className="flex  flex-row items-center"><RightOutlined onClick={() => setContent("Allergies")} style={{ fontweight: 'bold' }}/>
                                <p className="text-md ml-2 text-center">{item.resource.description}</p></div> :
                              item.resource_type === "Diagnosis" ? <div className="flex  flex-row items-center"><RightOutlined onClick={() => setContent("Diagnosis")} style={{ fontweight: 'bold' }}/> <p
                                  className="text-md ml-2 text-center">{item.resource.description}</p></div> :
                                item.resource_type === "Patient Assignment" ? <div className="flex  flex-row items-center">
                                    <p className="text-md ml-2 text-center"> Patient {item?.resource?.old_consultant?.name ? 'reassigned' : 'assigned'} {item.resource.assigned_consultant.name && <>to < span
                                      className={'font-semibold'}>{item.resource.assigned_consultant.name}</span></>} {item.resource.old_consultant.name && <>from < span
                                      className={'font-semibold'}>{item.resource.old_consultant.name}</span></>}</p></div> :
                                  <p className="text-md  text-center">{item.resource.description} {item.resource.new_value && <>to < span
                                    className={'font-semibold'}>{item.resource.new_value}</span></>} {item.resource.old_value && <>from < span
                                    className={'font-semibold'}>{item.resource.old_value}</span></>}</p>}
                </div>
              </div>
            </div>)
        }) : loading ? skelBars() : <div className="w-full flex flex-row items-center">{emptyTable()} </div>}
      
      {showOverLay &&
        
        <div className="absolute bg-gray-200 top-0 left-0 h-full w-full z-10">
          <div className="bg-white p-3">
            <div className="flex flex-row">
              <CloseOutlined onClick={() => setshowOverLay(false)} className="text-lg mr-2 font-semibold cursor-pointer"/>
              <p className="text-md font-semibold">{currentContent} List</p>
            </div>
          </div>
          <div className="w-full p-3">
            {currentContent === "Appointments" ?
              <PatientAppointments/> : currentContent === "Wounds" ? <PatientWounds/> : (currentContent === "Allergies" && can_view_patient_allergy) ? <AllergiesSection/> :
                currentContent === "Immunizations" ? <ImmunizationSection/> :
                  (currentContent === "Medication" && can_view_patient_medication) ? <MedicationSection/> :
                    (currentContent === "Diagnosis" && can_view_patient_diagnosis) ? <DiagnosisSection/> :
                      currentContent === "Orders" ? <PatientOrderList/> :
                        // currentContent === "Prescriptions" ? <PrescriptionSection /> :
                        (currentContent === "Family History" && can_view_patient_next_of_kin) ? <FamilyHistorySection/> : <PatientOrderList/>}
          </div>
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  patient: state.patients.selectedPatient
  
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TimeLine)