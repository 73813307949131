/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '../Apis';
import { newResourceArray, updatedArrayState } from '../../common/helpers';
import { fetchPatientAppointments, fetchPatientById } from './PatientSlice';
import { errMsg, getToastId, toastUp, updateToast } from './reducerUtils';
import { appointments } from './initialState';
import _ from "lodash"
import ReactGA from "react-ga4";

const initialState = { ...appointments };
export const fetchAppointments = createAsyncThunk(
  '/appointments',
  async (requestParams, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        let state = thunkAPI.getState();
        let pagination = requestParams?.pagination ? requestParams.pagination : state.appointments.appointmentsPagination;
        let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
        params.page = pagination.current
        const response = await apiRequest.get(`/appointments`, { params });
        let data = response.data.data.map((item) => ({ ...item }));
        const payload = { data, pagination: { ...pagination, total: response.data.meta.total } };
        resolve(payload);
      } catch (e) {
        //console.log(e);
        reject(e);
        toastUp(errMsg(e, true, 'appointments'), false);
      }
    });
  }
);

// fetch addmissions
export const fetchAdmissions = createAsyncThunk(
  '/admissions',
  async (requestParams, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        let state = thunkAPI.getState();
        let pagination = requestParams?.pagination ? requestParams.pagination : state.appointments.admissionsPagination;
        let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
        params.page = pagination.current
        const response = await apiRequest.get(`/admissions`, { params });
        let data = response.data.data.map((item) => ({ ...item }));
        const payload = { data, pagination: { ...pagination, total: response.data.meta.total } };
        resolve(payload);
      } catch (e) {
        //console.log(e);
        reject(e);
        toastUp(errMsg(e, true, 'admissions'), false);
      }
    });
  }
);

// fetch pendingshedule
export const fetchPendingSchedule = createAsyncThunk(
  '/pending-schedule',
  async (requestParams, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        let state = thunkAPI.getState();
        let pagination = requestParams?.pagination ? requestParams.pagination : state.appointments.pendingSchedulePagination;
        let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
        params.page = pagination.current
        const response = await apiRequest.get(`/pending-schedules`, { params });
        let data = response.data.data.map((item) => ({ ...item, latlng: [item.lng, item.lat] }));
        const payload = { data, pagination: { ...pagination, total: response.data.meta.total } };
        resolve(payload);
      } catch (e) {
        //console.log(e);
        reject(e);
        toastUp(errMsg(e, true, 'pending schedule'), false);
      }
    });
  }
);



export const fetchEncounters = createAsyncThunk(
  '/assessments',
  async (requestParams, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        let state = thunkAPI.getState();
        let pagination = requestParams?.pagination ? requestParams.pagination : state.appointments.encountersPagination;
        let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
        params.page = pagination.current
        params.published = true
        const response = await apiRequest.get(`/assessments`,{params});
        let data = response.data.data.map((item) => ({ ...item, latlng: [item.lng, item.lat] }));
        const payload = { data, pagination: { ...pagination, total: response.data.meta.total } };
        resolve(payload);
      } catch (e) {
        toastUp(errMsg(e, true, 'encounters'), false);
        reject(e);
      }
    });
  }
);
export const fetchEncounterById = createAsyncThunk('fetchEncounterById', async (id, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/assessments/${id}`);
      let data = response.data.data
      resolve(data);
    } catch (e) {
      toastUp(errMsg(e, true, 'assessment'), false);
      reject(e);
    }
  });
}
);
export const fetchDrafts = createAsyncThunk(
  '/assessment-drafts',
  async (requestParams, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        let state = thunkAPI.getState();
        let pagination = requestParams?.pagination ? requestParams.pagination : state.appointments.draftsPagination;
        let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
        params.page = pagination.current
        params.published = false
        const response = await apiRequest.get(`/assessments`, { params });
        let data = response.data.data.map((item) => ({ ...item, latlng: [item.lng, item.lat] }));
        const payload = { data, pagination: { ...pagination, total: response.data.meta.total } };
        resolve(payload);
      } catch (e) {
        toastUp(errMsg(e, true, 'drafts'), false);
        reject(e);
      }
    });
  }
);
export const fetchProcedures = createAsyncThunk(
  '/appointmentprocedures',
  async (params, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiRequest.get('/procedures');
        let data = response.data.data;
        const state = await thunkAPI.getState();
        const settings = state.settings.settings?.procedures;
        let filteredList = Object.keys(settings).filter((proc) => {
          return settings[proc].enabled === 'yes';
        })
        let newData = data.filter((item) => filteredList.includes(item.name))
        resolve(newData);
      } catch (e) {
        toastUp(errMsg(e, true, 'procedures'), false);
        reject(e);
      }
    });
  }
);
export const fetchTreatmentSupplies = createAsyncThunk(
  'fetchTreatmentSupplies',
  async (params, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiRequest.get('/treatment-supplies');
        let data = response.data.data;
        resolve(data);
      } catch (e) {
        toastUp(errMsg(e, true, 'treatment supplies'), false);
        reject(e);
      }
    });
  }
);
export const fetchTreatmentScenarios = createAsyncThunk(
  'fetchTreatmentScenarios',
  async (params, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiRequest.get('/treatment-scenarios');
        let data = response.data.data;
        resolve(data);
      } catch (e) {
        toastUp(errMsg(e, true, 'treatment scenarios'), false);
        reject(e);
      }
    });
  }
);
export const fetchPlanOfCareItems = createAsyncThunk(
  'fetchPlanOfCareItems',
  async (params, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiRequest.get('/plans-of-care');
        let data = response.data.data;
        resolve(data);
      } catch (e) {
        toastUp(errMsg(e, true, 'plan of cae items'), false);
        reject(e);
      }
    });
  }
);
export const createTreatmentSupplies = createAsyncThunk(
  'createTreatmentSupplies',
  async (supplies, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiRequest.post('/treatment-supplies', supplies);
        await thunkAPI.dispatch(fetchTreatmentSupplies());
        resolve(response);
      } catch (e) {
        toastUp(errMsg(e, true, 'treatment supplies'), false);
        reject(e);
      }
    });
  }
);
export const createTreatmentScenario = createAsyncThunk(
  'createTreatmentScenario',
  async (scenario, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiRequest.post('/treatment-scenarios', scenario);
        await thunkAPI.dispatch(fetchTreatmentScenarios());
        resolve(response);
      } catch (e) {
        toastUp(errMsg(e, true, 'treatment scenario'), false);
        reject(e);
      }
    });
  }
);
export const updateTreatmentSupplies = createAsyncThunk(
  'updateTreatmentSupplies',
  async (supplies, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { id, ...rest } = supplies;
        const response = await apiRequest.patch(`/treatment-supplies/${id}`, rest);
        await thunkAPI.dispatch(fetchTreatmentSupplies());
        resolve(response);
      } catch (e) {
        toastUp(errMsg(e, true, 'treatment supplies'), false);
        reject(e);
      }
    });
  }
);
export const updateTreatmentScenario = createAsyncThunk(
  'updateTreatmentScenario',
  async (scenario, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { id, ...rest } = scenario;
        const response = await apiRequest.patch(`/treatment-scenarios/${id}`, rest);
        await thunkAPI.dispatch(fetchTreatmentScenarios());
        resolve(response);
      } catch (e) {
        toastUp(errMsg(e, true, 'treatment supplies'), false);
        reject(e);
      }
    });
  }
);
export const deleteTreatmentSupplies = createAsyncThunk(
  'deleteTreatmentSupplies',
  async (id, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiRequest.delete(`/treatment-supplies/${id}`);
        await thunkAPI.dispatch(fetchTreatmentSupplies());
        resolve(response);
      } catch (e) {
        toastUp(errMsg(e, true, 'treatment supplies'), false);
        reject(e);
      }
    });
  }
);
export const fetchLabRegions = createAsyncThunk(
  'fetchLabRegions',
  async (params, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiRequest.get(`/labs`);
        resolve(response.data.data);
      } catch (e) {
        toastUp(errMsg(e, true, 'lab regions'), false);
        reject(e);
      }
    });
  }
);
export const loadWoundLocations = createAsyncThunk(
  'loadWoundLocations',
  async (params, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiRequest.get('/wound-locations');
        resolve(response.data.data);
      } catch (e) {
        toastUp(errMsg(e, true, 'wound locations'), false);
        reject(e);
      }
    });
  }
);
export const fetchAppointmentDaetails = createAsyncThunk(
  '/appointmentdetails',
  async (appointment, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const availabilityDateTime = await apiRequest.get(`/appointment-availability-datetimes?appointment=${appointment.id}&available=yes`);
        // const availabilityShchedule = await apiRequest.get(`/appointment-availability-schedules?appointment=${appointment.id}`)
        // const appointmentvehicles = await apiRequest.get(`/appointments/${appointment.id}/vehicles`)
        // console.log(availabilityDateTime, availabilityShchedule)
        resolve(availabilityDateTime);
      } catch (e) {
        toastUp(errMsg(e, true, 'appointment details'), false);
        reject(e);
      }
    });
  }
);
export const fetchSpecificAppointment = createAsyncThunk('/specific-appointments', async (appointment, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/appointments/${appointment}`);
      const assessments = await apiRequest.get(`appointments/${appointment}/assessments?page_size=150`);
      let data = response.data.data;
      //console.log({ assessments: data })
      data.previous_assessments = assessments.data.data;
      data.wounds = data.wounds || [];
      resolve(data);
    } catch (e) {
      toastUp(errMsg(e, true, 'appointment'), false);
      reject(e);
    }
  });
});
export const fetchInitialAppointment = createAsyncThunk('fetchInitialAppointment', async (appointment, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`appointments/${appointment}/initial-visit`);
      const assessments = await apiRequest.get(`appointments/${appointment}/assessments?page_size=150`);
      let data = response.data.data;
      //console.log({ assessments: data })
      data.previous_assessments = assessments.data.data;
      data.wounds = data.wounds || [];
      //console.log({ fetchInitialAppointment: data })
      resolve(data);
    } catch (e) {
      toastUp(errMsg(e, true, 'appointment'), false);
      reject(e);
    }
  });
});
export const loadAppointmentAndAssessment = createAsyncThunk(
  'loadAppointmentAndAssessment',
  async (appointment, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiRequest.get(`/appointments/${appointment}`);
        const assessments = await apiRequest.get(`appointments/${appointment}/assessments?page_size=150`);
        let data = response.data.data;
        data.previous_assessments = assessments.data.data;
        data.wounds = data.wounds || [];
        resolve(data);
      } catch (e) {
        toastUp(errMsg(e, true, 'appointment'), false);
        reject(e);
      }
    });
  }
);
export const createAppointment = createAsyncThunk(
  '/createAppointment',
  async (appointment, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Creating Appointment');
      try {
        const response = await apiRequest.post('/appointments', appointment);
        updateToast(toastId, 'Appointment created', true);
        // gerenate call link if appointment is telehealth
        if (appointment.telehealth_appointment && response?.data.data) {
          const callLink = await apiRequest.post('/agora/call-link', {
            appointment_id: response.data.data.id,
            title: `${response?.data?.data?.consultant?.name}-${response?.data?.data?.patient?.name}-meeting`
          });
          response.data.data.call_link = callLink.data.data.host;
        }


        await thunkAPI.dispatch(fetchAppointments());
        //appointment_created
        ReactGA.event({
          category: "appointment_created",
          action: "appointment_created",
          label: "appointment_created",
          value: 1
        });
        resolve(response);
      } catch (e) {
        //console.log(e);
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);
export const updateAppointment = createAsyncThunk(
  '/updateAppointment',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Updating Appointment');
      try {
        const response = await apiRequest.patch(
          `/appointments/${payload.appointment_id}`,
          payload.data
        );
        if (payload?.data?.telehealth_appointment && response?.data.data) {
          const callLink = await apiRequest.post('/agora/call-link', {
            appointment_id: response.data.data.id,
            title: `${response?.data?.data?.consultant?.name}-${response?.data?.data?.patient?.name}`
          });
          response.data.data.call_link = callLink.data.data.call_link;
        }

        if (payload.patient_id) {
          fetchPatientAppointments(payload.patient_id);
        } else {
          await thunkAPI.dispatch(fetchAppointments());
          await thunkAPI.dispatch(fetchSpecificAppointment(payload.appointment_id));
        }
        updateToast(toastId, 'Appointment updated', true);
        //appointment_updated
        ReactGA.event({
          category: "appointment_updated",
          action: "appointment_updated",
          label: "appointment_updated",
          value: 1
        });
        let data = response.data.data;
        resolve(data);
      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);


export const deleteAppointment = createAsyncThunk(
  '/deleteAppointment',
  async (payload, thunkAPI) => {
    //console.log(payload)
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Deleting Appointment');
      try {
        const response = await apiRequest.delete(`/appointments/${payload.appointment_id}`, { data: { ...payload } });
        if (payload.patient_id) {
          await thunkAPI.dispatch(fetchPatientAppointments(payload.patient_id))
          updateToast(toastId, 'Appointment deleted', true);
          //appointment_deleted
          ReactGA.event({
            category: "appointment_deleted",
            action: "appointment_deleted",
            label: "appointment_deleted",
            value: 1
          });
          resolve(response);
        } else {
          await thunkAPI.dispatch(fetchAppointments());
          updateToast(toastId, 'Appointment deleted', true);
          resolve(response);
        }


      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);
// update admission

export const updateAdmission = createAsyncThunk(
  '/updateAdmission',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Updating Admission');
      try {
        const response = await apiRequest.patch(
          `/patient-consultant-assignments/${payload.admission_id}`,
          payload.data
        );
        await thunkAPI.dispatch(fetchPatientById(payload.data.patient_id));
        await thunkAPI.dispatch(fetchAdmissions());
        updateToast(toastId, 'Admission updated', true);
        //admission_updated
        ReactGA.event({
          category: "admission_updated",
          action: "admission_updated",
          label: "admission_updated",
          value: 1
        });
        resolve(response);
      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);

export const updateNewAdmissionStatus = createAsyncThunk('updateNewAdmissionStatus', async (payload, thunkAPI) => {
        return new Promise(async (resolve, reject) => {
            const toastId = getToastId('Updating Admission');
          const state = await thunkAPI.getState();
          try {
            const { patient_id, ...rest }=payload
            const response = await apiRequest.patch(`/admissions/${patient_id}`, rest);
            //At the moment, there is no endpoint to load specific selectedAdmission hence state is updated manually
            await thunkAPI.dispatch(selectAdmission({...state.appointments.selectedAdmission,...rest}));
            await thunkAPI.dispatch(fetchAdmissions());
            updateToast(toastId, 'Admission updated', true);
            //admission_updated
            ReactGA.event({
              category: "admission_updated",
              action: "admission_updated",
              label: "admission_updated",
              value: 1
            });
            resolve(response);
            } catch (e) {
                updateToast(toastId, errMsg(e, false), false);
                reject(e);
            }
        });
    }
);


// delete admission
export const deleteAdmission = createAsyncThunk(
  '/deleteAdmission',
  async (payload, thunkAPI) => {
    //console.log(payload)
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Deleting Admission');
      try {
        const response = await apiRequest.delete(`/admissions/${payload.admission_id}`, { data: { ...payload } });
        await thunkAPI.dispatch(fetchAdmissions());
        updateToast(toastId, 'Admission deleted', true);
        //admission_deleted
        ReactGA.event({
          category: "admission_deleted",
          action: "admission_deleted",
          label: "admission_deleted",
          value: 1
        });
        resolve(response);

      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);
// end of delete admission

// create admission
export const AssignPatient = createAsyncThunk(
  '/createAdmission',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Assigning Patient');
      try {
        const response = await apiRequest.post('/patient-consultant-assignments', payload);
        updateToast(toastId, 'Patient Assigned', true);
        await thunkAPI.dispatch(fetchAdmissions());
        //admission_created
        ReactGA.event({
          category: "Assignment_created",
          action: "Assignment_created",
          label: "Assignment_created",
          value: 1
        });
        resolve(response);
      } catch (e) {
        //console.log(e);
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);
// end of create admission



// time blocks

export const fetchTimeBlockers = createAsyncThunk(
  '/timeBlockers',
  async (requestParams, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        let state = thunkAPI.getState();
        let pagination = requestParams?.pagination ? requestParams.pagination : state.appointments.timeblockersPagination;
        let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
        params.page = pagination.current
        params.page_size = 500
        delete params["scheduledForRange"]
        delete params["scheduledRange"]
        const response = await apiRequest.get(`/time-blocker`,{params});
        let data = response.data.data.map((item) => ({ ...item }));
        const payload = { data, pagination: { ...pagination, total: response.data.meta.total } };
        resolve(payload);
      } catch (e) {
        //console.log(e);
        reject(e);
        toastUp(errMsg(e, true, 'time-blocks'), false);
      }
    });
  }
);


export const createTimeBlocker = createAsyncThunk(
  '/createTimeBlocker',
  async (blocker, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Creating TimeBlocker');
      try {
        const response = await apiRequest.post('/time-blocker', blocker);
        updateToast(toastId, 'TimeBlocker created', true);
        await thunkAPI.dispatch(fetchTimeBlockers());
        resolve(response);
      } catch (e) {
        //console.log(e);
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);
export const updateTimeBlocker = createAsyncThunk(
  '/updateTimeBlocker',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Updating TimeBlocker');
      try {
        const response = await apiRequest.patch(`/time-blocker/${payload.timeblock_id}`, payload.data);
        await thunkAPI.dispatch(fetchTimeBlockers());
        updateToast(toastId, 'TimeBlocker updated', true);
        resolve(response);
      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);

export const deleteTimeBlocker = createAsyncThunk(
  '/deleteTimeBlocker',
  async (payload, thunkAPI) => {
    //console.log(payload)
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Deleting TimeBlocker');
      try {
        const response = await apiRequest.delete(`/time-blocker/${payload.timeblock_id}`,
            { data: { delete_mode: "current and following" } });
        await thunkAPI.dispatch(fetchTimeBlockers());
        updateToast(toastId, 'TimeBlocker deleted', true);
        resolve(response);
      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);

// end of time blocks
export const deleteAssessment = createAsyncThunk(
  '/deleteAssessment',
  async (payload, thunkAPI) => {
    //console.log(payload)
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Deleting Assessment');
      try {
        const response = await apiRequest.delete(`/assessments/${payload.assessment_id}`, { data: { ...payload } });

        updateToast(toastId, 'Assessment deleted', true);
        //assessment_deleted
        ReactGA.event({
          category: "assessment_deleted",
          action: "assessment_deleted",
          label: "assessment_deleted",
          value: 1
        });
        resolve(response);

      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);


export const createWounds = createAsyncThunk(
  '/createWounds',
  async (wounds, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Creating wounds');
      try {
        //console.log(wounds);
        let promises = [];
        //todo: Replace this implementation with one endpoint creating several wounds
        for (let item of wounds) {
          promises.push(apiRequest.post('/wounds', _.omit(item, 'wound_create')));
        }
        await Promise.all(promises);
        await thunkAPI.dispatch(fetchAppointments());
        await thunkAPI.dispatch(fetchSpecificAppointment(wounds[0].appointment_id));
        await thunkAPI.dispatch(loadAppointmentAndAssessment(wounds[0].appointment_id));
        updateToast(toastId, 'wounds created', true);
        //wound_created
        ReactGA.event({
          category: "wound_created",
          action: "wound_created",
          label: "wound_created",
          value: 1
        });
        resolve();
      } catch (e) {
        reject(e);
        updateToast(toastId, errMsg(e, false), false);
      }
    });
  }
);
const makeRepeated = (arr, repeats) =>
  [].concat(...Array.from({ length: repeats }, () => arr));


const stringifyArray = (myObj) => {
  const searchParams = new URLSearchParams();
  for (const [key, value] of Object.entries(myObj)) {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        searchParams.append(`${key}[]`, item);
      });
    } else {
      searchParams.append(key, value);
    }
  }
  return searchParams.toString()
}


export const fetchSchedule = createAsyncThunk(
  '/appointment-schedules',

  async (requestParams, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        let state = thunkAPI.getState();
        let pagination = requestParams?.pagination ? requestParams.pagination : state.appointments.schedulePagination;
        let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
        params.page = pagination.current
        params = { ...params, ...(params.mode === 'list' ? { page: pagination.current } : { page_size: 500 }) }
        const response = await apiRequest.get(`/v2/appointments`, { params })
        let data = response.data.data
        const payload = { data, pagination: { ...pagination, total: response.data.meta.total } };
        resolve(payload);
      } catch (e) {
        toastUp(errMsg(e, true, 'schedule'), false);
        reject(e);
      }
    });
  }
);


export const retriggerHl7 = createAsyncThunk(
  '/retrigger-hl7',
  async (params, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Resending files');
      //console.log('HL7 Documents', params);
      try {
        const response = await apiRequest.post(
          `/assessments/${params.id}/reprocess-hl7`
        );
        updateToast(toastId, response.data.message, true);
        resolve(response.data.data);
      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);
export const saveFilterProfile = createAsyncThunk('saveFilterProfile', async (data, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId('Saving filter profile');
    try {
      await apiRequest.post(`/appointments-filter-profile`, data);
      await thunkAPI.dispatch(loadFilterProfiles());
      updateToast(toastId, 'Added successfully', true);
      resolve();
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false);
      reject(e);
    }
  });
}
);
export const loadFilterProfiles = createAsyncThunk('loadFilterProfiles', async (data, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    // const toastId = getToastId('Loading filter profile');
    try {
      const response = await apiRequest.get(`/appointments-filter-profile`);
      let data = response.data.data
      const payload = { data };
      // updateToast(toastId, 'Added successfully', true);
      resolve(payload);
    } catch (e) {
      // updateToast(toastId, errMsg(e, false), false);
      reject(e);
    }
  });
}
);
export const deleteFilterProfiles = createAsyncThunk('deleteFilterProfiles', async (id, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId('Deleting filter profile');
    try {
      await apiRequest.delete(`/appointments-filter-profile/${id}`);
      await thunkAPI.dispatch(loadFilterProfiles());
      updateToast(toastId, 'Deleted successfully', true);
      resolve();
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false);
      reject(e);
    }
  });
}
);


// update pending schedule

export const updatePendingSchedule = createAsyncThunk(
  '/updatePendingSchedule',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Updating Pending Schedule');
      try {
        const response = await apiRequest.patch(
          `/pending-schedules/${payload.pending_schedule_id}`,
          payload.data
        );
        await thunkAPI.dispatch(fetchPendingSchedule());
        updateToast(toastId, 'Pending Schedule updated', true);
        //pending_schedule_updated
        ReactGA.event({
          category: "pending_schedule_updated",
          action: "pending_schedule_updated",
          label: "pending_schedule_updated",
          value: 1
        });
        resolve(response);
      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);

// export const createFacilityNotes = createAsyncThunk(
//   'facility/createNotes',
//   async (payload, thunkAPI) => {
//     return new Promise(async (resolve, reject) => {
//       const toastId = getToastId('Creating Office Notes');
//       try {
//         const response = await apiRequest.post(
//           `/medical_facility-treatment_orders/note`,
//           payload
//         );

//         await thunkAPI.dispatch(fetchFacilityNotes({ id: payload.medical_facility_id }));
//         updateToast(toastId, 'Office Notes created', true);
//         //GA4 event facility_notes_created
//         ReactGA.event({
//           category: "facility_notes_created",
//           action: "facility_notes_created",
//           label: "facility_notes_created",
//           value: 1
//         });
//         resolve(response.data);
//       } catch (e) {
//         updateToast(toastId, errMsg(e, false), false);
//         reject(e);
//       }
//     });
//   }
// );

// export const fetchFacilityNotes = createAsyncThunk(
//   '/facilityNotes',
//   async (payload, thunkAPI) => {
//     return new Promise(async (resolve, reject) => {
//       try {
//         const response = await apiRequest.get(
//           `/medical_facility-treatment_orders/note?facility=${payload.id}`
//         );
//         //console.log('Notes: ', response.data.data);
//         resolve(response.data.data);
//       } catch (e) {
//         toastUp(errMsg(e, true, 'facility'), false);
//         reject(e);
//       }
//     });
//   }
// );

// export const updateFacilityNote = createAsyncThunk(
//   'facility/updateNote',
//   async (payload, thunkAPI) => {
//     return new Promise(async (resolve, reject) => {
//       const toastId = getToastId('Updating Office Note');
//       try {
//         const response = await apiRequest.patch(
//           `/medical_facility-treatment_orders/note/${payload.id}`,
//           payload
//         );
//         await thunkAPI.dispatch(fetchFacilityNotes({ id: payload.medical_facility_id }));
//         updateToast(toastId, 'Office Note update successful', true);
//         //GA4 event facility_notes_updated
//         ReactGA.event({
//           category: "facility_notes_updated",
//           action: "facility_notes_updated",
//           label: "facility_notes_updated",
//           value: 1
//         });
//         resolve(response.data);
//       } catch (e) {
//         updateToast(toastId, errMsg(e, false), false);
//         reject(e);
//       }
//     });
//   }
// );

// export const deleteFacilityNote = createAsyncThunk(
//   'facility/deleteNote',
//   async (payload, thunkAPI) => {
//     return new Promise(async (resolve, reject) => {
//       const toastId = getToastId('Deleting Office Note');
//       try {
//         const response = await apiRequest.delete(
//           `/medical_facility-treatment_orders/note/${payload.id}`
//         );
//         await thunkAPI.dispatch(fetchFacilityNotes({ id: payload.facility_id }));
//         updateToast(toastId, 'Office Note deleted', true);
//         resolve(response.data);
//       } catch (e) {
//         updateToast(toastId, errMsg(e, false), false);
//         reject(e);
//       }
//     });
//   }
// );

// fetch create and delete appointment notes
export const fetchAppointmentNotes = createAsyncThunk(
  '/appointmentNotes',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiRequest.get(
          `/appointment-notes/${payload.id}/list`
        );
        //console.log('Notes: ', response.data.data);
        resolve(response.data.data);
      } catch (e) {
        toastUp(errMsg(e, true, 'appointment'), false);
        reject(e);
      }
    }
    );
  }
);

export const createAppointmentNotes = createAsyncThunk(
  'appointment/createNotes',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Creating Appointment Notes');
      try {
        const response = await apiRequest.post(
          `/appointment-notes/${payload.appointment_id}/create`,
          payload
        );
        await thunkAPI.dispatch(fetchAppointmentNotes({ id: payload.appointment_id }));
        updateToast(toastId, 'Appointment Notes created', true);
        //GA4 event appointment_notes_created
        ReactGA.event({
          category: "appointment_notes_created",
          action: "appointment_notes_created",
          label: "appointment_notes_created",
          value: 1
        });
        resolve(response.data);
      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);

export const updateAppointmentNote = createAsyncThunk(
  'appointment/updateNote',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Updating Appointment Note');
      try {
        const response = await apiRequest.patch(
          `/appointment-notes/${payload.id}/update`,
          payload
        );
        await thunkAPI.dispatch(fetchAppointmentNotes({ id: payload.appointment_id }));
        updateToast(toastId, 'Appointment Note update successful', true);
        //GA4 event appointment_notes_updated
        ReactGA.event({
          category: "appointment_notes_updated",
          action: "appointment_notes_updated",
          label: "appointment_notes_updated",
          value: 1
        });
        resolve(response.data);
      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });

  }


);

export const deleteAppointmentNote = createAsyncThunk(
  'appointment/deleteNote',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Deleting Appointment Note');
      try {
        const response = await apiRequest.delete(
          `/appointment-notes/${payload.id}/delete`
        );
        await thunkAPI.dispatch(fetchAppointmentNotes({ id: payload.appointment_id }));
        updateToast(toastId, 'Appointment Note deleted', true);
        //GA4 event appointment_notes_deleted
        ReactGA.event({
          category: "appointment_notes_deleted",
          action: "appointment_notes_deleted",
          label: "appointment_notes_deleted",
          value: 1
        });
        resolve(response.data);
      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);




export const appointmentSlice = createSlice({
  name: 'appointments',
  initialState, // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    newAppointment: (state, action) => {
      let newState = newResourceArray(state.appointments, action);
      state.appointments = [...newState];
    },
    appointmentUpdated: (state, action) => {
      let newState = updatedArrayState(state.appointments, action, 'id');
      state.appointments = [...newState];
    },
    newAssessment: (state, action) => {
      let newState = newResourceArray(state.encounters, action);
      state.encounters = [...newState];
    },
    selectAssessment: (state, action) => {
      // console.log({selectAssessment:action.payload})
      state.selectedAssessment = action.payload;
    },
    assessmentUpdated: (state, action) => {
      let newState = updatedArrayState(state.encounters, action, 'id');
      state.encounters = [...newState];
    }, // Use the PayloadAction type to declare the contents of `action.payload`
    selectAppointment: (state, action) => {
      //console.log(action.payload);
      state.selectedAppointment = action.payload.record;
    },
    selectAdmission: (state, action) => {
      //console.log(action.payload);
      state.selectedAdmission = action.payload;
    },
    selectPendingSchedule: (state, action) => {
      //console.log(action.payload);
      state.selectedPendingSchedule = action.payload;
    },
    selectAppointmentPatient: (state, action) => {
      //console.log(action.payload);
      state.selectedAppointmentPatient = action.payload;
    },
    selectPatientInitialAppointment: (state, action) => {
      // console.log({selectPatientInitialAppointment1: action.payload})
      state.patientInitialAppointment = action.payload;
    },
    setpagination: (state, action) => {
      state.pagination = action.payload;
    },
    setAssessmentNotificationOpen: (state, action) => {
      state.assessmentNotificationOpen = action.payload;
    },
    resetAppointmentModuleState: (state, action) => {
      let localState = { ...initialState };
      delete localState.appointments;
      delete localState.admissions;
      delete localState.pendingSchedule;
      delete localState.encounters;
      delete localState.drafts;
      delete localState.procedures;
      delete localState.scheduledAppointments;
      Object.keys(localState).map((key) => (state[key] = localState[key]));
    },
    resetAppointmentsPagination: (state) => {
      state.appointmentsPagination = initialState.appointmentsPagination;
    },
    // admission pagination
    resetAdmissionsPagination: (state) => {
      state.admissionsPagination = initialState.admissionsPagination;
    },
    // pending schedule pagination

    resetPendingSchedulePagination: (state) => {
      state.pendingSchedulePagination = initialState.pendingSchedulePagination;
    },

    resetSchedule: (state) => {
      state.scheduledAppointments = []
      state.schedulePagination = initialState.schedulePagination;
    },
    resetSchedulePagination: (state) => {
      state.schedulePagination = initialState.schedulePagination;
    },
    resetEncountersPagination: (state) => {
      state.encountersPagination = initialState.encountersPagination;
    },
    resetDraftsPagination: (state) => {
      state.draftsPagination = initialState.draftsPagination;
    },
    setScheduleMode: (state, action) => {
      state.scheduleMode = action.payload
    },
    setNotifiedStakeholder: (state, action) => {
      state.notifiedStakeholders = action.payload
    },
    doResetAppointmentSlice: (state, action) => {
      Object.keys(initialState).map((key) => (state[key] = initialState[key]));
    }
  }, extraReducers: (builder) => {
    builder.addCase(fetchAppointments.fulfilled, (state, action) => {
      //console.log(action.payload);
      state.appointments = [...action.payload.data];
      state.appointmentsPagination = action.payload.pagination;
    }).addCase(loadFilterProfiles.fulfilled, (state, action) => {
      state.filterProfiles = [...action.payload.data];
    }).addCase(fetchEncounters.fulfilled, (state, action) => {
      state.encounters = [...action.payload.data];
      state.encountersPagination = action.payload.pagination;
    }).addCase(fetchDrafts.fulfilled, (state, action) => {
      state.drafts = [...action.payload.data];
      state.draftsPagination = action.payload.pagination;
    }).addCase(fetchSpecificAppointment.fulfilled, (state, action) => {
      state.selectedAppointment = action.payload;
    }).addCase(fetchEncounterById.fulfilled, (state, action) => {
      state.selectedAssessmentEncounter = action.payload;
    }).addCase(fetchInitialAppointment.fulfilled, (state, action) => {
      state.patientInitialAppointment = action.payload;
    }).addCase(fetchProcedures.fulfilled, (state, action) => {
      state.procedures = [...action.payload];
    }).addCase(fetchTreatmentSupplies.fulfilled, (state, action) => {
      state.treatmentSupplies = [...action.payload];
    }).addCase(fetchTreatmentScenarios.fulfilled, (state, action) => {
      state.treatmentScenarios = [...action.payload];
    }).addCase(fetchPlanOfCareItems.fulfilled, (state, action) => {
      state.planOfCareItems = [...action.payload];
    }).addCase(fetchLabRegions.fulfilled, (state, action) => {
      state.labRegions = [...action.payload];
    }).addCase(loadWoundLocations.fulfilled, (state, action) => {
      state.woundLocations = [...action.payload];
    }).addCase(fetchSchedule.pending, (state, action) => {
      state.scheduleLoading = true
    }).addCase(fetchSchedule.fulfilled, (state, action) => {
      state.scheduledAppointments = [...action.payload.data];
      state.schedulePagination = action.payload.pagination;
      state.scheduleLoading = false
    }).addCase(fetchTimeBlockers.fulfilled, (state, action) => {
      state.timeblockers = [...action.payload.data];
      state.timeblockersPagination = action.payload.pagination;
    }).addCase(fetchAdmissions.fulfilled, (state, action) => {
      state.admissions = [...action.payload.data];
      state.admissionsPagination = action.payload.pagination;
    }).addCase(fetchPendingSchedule.fulfilled, (state, action) => {
      state.pendingSchedule = [...action.payload.data];
      state.pendingSchedulePagination = action.payload.pagination;
    }).addCase(fetchAppointmentNotes.fulfilled, (state, action) => {
      state.appointmentNotes = [...action.payload];
    })
  }
});

export const {
  selectAppointment, selectAppointmentPatient,
  assessmentUpdated,
  newAssessment,
  newAppointment,
  resetEncountersPagination,
  appointmentUpdated,
  resetAppointmentModuleState,
  resetAppointmentsPagination,
  resetDraftsPagination,
  selectPatientInitialAppointment,
  selectAssessment,
  resetSchedulePagination,
  resetAdmissionsPagination,
  resetPendingSchedulePagination,
  selectAdmission,
  selectPendingSchedule,
  resetSchedule,
  setScheduleMode,
  setNotifiedStakeholder, setAssessmentNotificationOpen, doResetAppointmentSlice
} = appointmentSlice.actions;
export default appointmentSlice.reducer;
