/* eslint-disable no-unused-vars */
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {apiRequest} from "../Apis"
import querystring from 'querystring'
import { newResourceArray, updatedArrayState, sanitizePayload } from "../../common/helpers";
import {patients, tasks} from "./initialState";
import {errMsg, getToastId, toastUp, updateToast} from "./reducerUtils";
import _ from "lodash"
import ReactGA from "react-ga4";

const initialState = { ...patients }
export const fetchPatients = createAsyncThunk('fetchPatients', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.patients.pagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      let response = await apiRequest.get(`/patients`, { params });
      let data = response.data.data.map(item => ({
        ...item, latlng: [item.lng, item.lat],
        ...((!!requestParams && !!params?.facility_id) && { facility_id: params['filterData']?.facility_id })
      }))
      // console.log('props.pagination:  ', props?.pagination)
      const payload = { data, pagination: { ...pagination, total: response.data.meta.total } }
      // console.log({payload})
      resolve(payload)
    } catch (e) {
      toastUp(errMsg(e, true, 'patients'), false)
      reject(e)
    }
  })
})
export const fetchArchivedPatients = createAsyncThunk('fetchArchivedPatients', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.patients.archivedPatientPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      let response = await apiRequest.get(`/archived-patients`,{params});
      //Add facility_id used to check and avoid redundant API reload
      let data = response.data.data.map(item => ({
        ...item,        latlng: [item.lng, item.lat],
        ...(( !!params?.facility_id) && { facility_id: params.facility_id })
      }))
      const payload = { data, pagination: { ...pagination, total: response.data.meta.total } }
      resolve(payload)
    } catch (e) {
      toastUp(errMsg(e, true, 'patients'), false)
      reject(e)
    }
  })
})
export const fetchPatientWounds = createAsyncThunk('fetchPatientWounds', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log({requestParams})
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.patients.patientWoundsPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const response = await apiRequest.get(`/patients/${params?.patient || requestParams?.id}/wounds`, { params })
      const payload = {
        wounds: response.data.data.map(item => ({ ...item, patient_id: params.id })),
        pagination: { ...pagination, total: response.data.meta.total }
      }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'patient wounds'), false)
      reject(e)
    }
  })
})
export const fetchDuplicatePatientWounds = createAsyncThunk('fetchDuplicatePatientWounds', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.patients.duplicateWoundsPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const response = await apiRequest.get(`/wounds/${requestParams.id||params.id}/duplicates`,{params})
      const payload = {
        wounds: response.data.data.map(item => ({ ...item, patient_id: params.id })),
        pagination:  { ...pagination, total: response.data.meta?.total }
      }
      // console.log({fetchDuplicatePatientWounds: payload})
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'duplicate wounds'), false)
      reject(e)
    }
  })
})
export const squashDuplicateWounds = createAsyncThunk('squashDuplicateWounds', async (data, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      // console.log({squashDuplicateWounds:data})
      const { patient_id, ...rest } = data
      await apiRequest.post(`duplicate-wounds`, rest)
      await thunkAPI.dispatch(fetchDuplicatePatientWounds({ id: rest.target, patient_id }));
      await thunkAPI.dispatch(fetchPatientWounds({ id: patient_id }))
      resolve()
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'patient wounds'), false)
      reject(e)
    }
  })
})
/**
 * Uses patientOpenWoundsPagination and filters
 * */
export const fetchOpenPatientWounds = createAsyncThunk('fetchOpenPatientWounds', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.patients.patientOpenWoundsPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const response = await apiRequest.get(`/patients/${requestParams.id||params.patient}/wounds`,{params})
      const payload = {
        wounds: response.data.data.map(item => ({ ...item, patient_id: params.id })),
        pagination: { ...pagination, total: response.data.meta.total }
      }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'patient wounds'), false)
      reject(e)
    }
  })
})
export const fetchPatientNotes = createAsyncThunk('fetchPatientNotes', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.patients.notesPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {patient:requestParams.id}
      params.page = pagination.current
      params.patient = params.patient||requestParams.id
      const response = await apiRequest.get(`patients/${params.patient}/progress-notes`, { params })
      const payload = {
        progressNotes: response.data.data.map(item => ({ ...item, patient_id: params.patient })),
        pagination: { ...pagination, total: response.data.meta.total }
      }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'patient\'s internal notes '), false)
      reject(e)
    }
  })
})
export const fetchPatientVitals = createAsyncThunk('/fetchPatientVitals', async (patientId, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const vitals = await apiRequest.get(`/patient-vitals?patient=${patientId}`)
      resolve(
        [...vitals.data.data].map(item => ({ ...item, patient_id: patientId })))

    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'patient vitals '), false)
      reject(e)
    }
  })
})
export const deletePatientVitals = createAsyncThunk('deletePatientVitals', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log({payload})
      const {id,patientId,...rest}=payload
      await apiRequest.delete(`/patient-vitals/${id}`, { data:rest })
      await thunkAPI.dispatch(fetchPatientVitals(patientId))
      resolve()
      
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'patient vitals '), false)
      reject(e)
    }
  })
})
export const fetchPatientAppointments = createAsyncThunk('fetchPatientAppointments', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.patients.patientAppointmentPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : { patient: requestParams }
      params.page = pagination.current
      const response = await apiRequest.get(`/appointments`, { params })
      const payload = {
        data: response.data.data,
        pagination: { ...pagination, total: response.data.meta.total }
      }
      resolve(payload)
    } catch (e) {
      toastUp(errMsg(e, true, 'patient appointments '), false)
      reject(e)
    }
  })
})


export const fetchPatientOrders = createAsyncThunk('/patientOrdersById', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let visitType = requestParams?.['filterData']?.visitType
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : visitType === 'biologics' ? state.patients.bioOrdersPagination :
          visitType === 'DME' ? state.patients.dmeOrdersPagination : state.patients.ordersPagination
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const response = await apiRequest.get(`/treatment-orders`, { params })
      let data = response.data.data.map(item => ({ ...item, latlng: [item.lng, item.lat] }))
      let paginationName = visitType === 'biologics' ? 'bioOrdersPagination' : visitType === 'DME' ? 'dmeOrdersPagination' : 'ordersPagination'
      const payload = { orders: data, [paginationName]: { ...pagination, total: response.data.meta.total } }
      // console.log(payload)
      resolve(payload)
    } catch (e) {
      //console.log(e)
      reject(e)
      toastUp(errMsg(e, true, 'orders'), false)
    }
  })
})

export const fetchPatientDocuments = createAsyncThunk('/patientDocumentsById', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.patients.patientDocPagination
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : { patient: requestParams }
      params.page = pagination.current
      const response = await apiRequest.get(`/patients/${params.patient||requestParams}/documents`, { params })
      let data = response.data.data.map(item => ({ ...item, patient_id: params.patient || requestParams }))
      const payload = { data, pagination: { ...pagination, total: response.data.meta?.total } }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'patient documents'), false)
      reject(e)
    }
  })
})
export const fetchPatientConsentForms = createAsyncThunk('fetchPatientConsentForms', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.patients.patientConsentPagination
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : { patient: requestParams }
      params.page = pagination.current
      const response = await apiRequest.get( `/patients/${params.patient||requestParams}/consent-form`, { params })
      let data = response.data.data.map(item => ({ ...item, patient_id: params.patient || requestParams }))
      const payload = { data, pagination: { ...pagination, total: response.data.meta?.total } }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'patient documents'), false)
      reject(e)
    }
  })
})
export const fetchPatientById = createAsyncThunk('fetchPatientById', async (_id, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/patients/${_id}`)
      // console.log("Patient Information", response.data.data)
      resolve(response.data.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'patient'), false)
      reject(e)
    }
  })
})
export const fetchPatientInsurance = createAsyncThunk('fetchPatientInsurance', async (_id, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/patients/${_id}/insurance`)
      resolve(response.data.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'patient insurance'), false)
      reject(e)
    }
  })
})
export const createInsurancePlan = createAsyncThunk('createInsurancePlan', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Adding insurance")
    try {
      const response = await apiRequest.post(`/patient-insurance`, payload.data)
      await thunkAPI.dispatch(fetchPatientInsurance(payload.data.patient_id));
      updateToast(toastId, `Insurance added successfully`, true)
      //patient_insurance_added
      ReactGA.event({
        category: "patient_insurance_added",
        action: "patient_insurance_added",
        label: "patient_insurance_added",
        value: 1
      });
      resolve(response.data)
    } catch (e) {
      updateToast(toastId, errMsg(e, true, 'patient insurance plan'), false)
      reject(e)
    }
  })
})
export const updateInsurance = createAsyncThunk('updateInsurance', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/patient-insurance/${payload.insurance_id}`, payload.data)
      await thunkAPI.dispatch(fetchPatientInsurance(payload.data.patient_id));
      toastUp("Insurance info updated successfully", true)
      //patient_insurance_updated
      ReactGA.event({
        category: "patient_insurance_updated",
        action: "patient_insurance_updated",
        label: "patient_insurance_updated",
        value: 1
      });
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false, 'patient insurance'), false)
      reject(e)
    }
  })
})
export const deleteInsurance = createAsyncThunk('deleteInsurance', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/patient-insurance/${payload.insurance_id}`)
      await thunkAPI.dispatch(fetchPatientInsurance(payload.patient_id));
      toastUp("Insurance deleted successfully", true)
      resolve(response.data)
    } catch (e) {
      toastUp(errMsg(e, false), false)
      //console.log(e)
      reject(e)
    }
  })
})
export const createPatient = createAsyncThunk('/createPatient', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Adding patient")
    try {
      const response = await apiRequest.post(`/patients`, payload)
      await thunkAPI.dispatch(fetchPatients());
      updateToast(toastId, "Added successfully", true)
      //patient_created
      ReactGA.event({
        category: "patient_created",
        action: "patient_created",
        label: "patient_created",
        value: 1
      });
      resolve(response.data)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})
export const uploadImage = createAsyncThunk('uploadImage', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/files`, payload)
      if (response.data) {
        toastUp("Added successfully", true)
      }
      resolve(response.data)
    } catch (e) {
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const fetchProspects = createAsyncThunk('fetchProspects', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.patients.prospectPagination
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : { patient: requestParams }
      params.page = pagination.current
      const response = await apiRequest.get(`/prospects`,{params})
      //Add facility_id used to check and avoid redundant API reload
      let data = response.data.data.map(item => ({
        ...item,
        latlng: [item.lng, item.lat], ...((!!params?.facility_id) && { facility_id: params?.facility_id })
      }))
      const payload = {
        data,
        pagination: { ...pagination, total: response.data.meta.total }
      }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'prospects'), false)
      reject(e)
    }
  })
})
export const fetchProspectById = createAsyncThunk('fetchProspectById', async (id, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/prospects/${id}`)
      //Add facility_id used to check and avoid redundant API reload
      let item = response.data.data
      let data = { ...item, latlng: [item.lng, item.lat] }
      console.log({ prospect: data })
      resolve(data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'prospects'), false)
      reject(e)
    }
  })
})
export const importProspect = createAsyncThunk('/importProspect', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId(`Importing`)
    try {
      let state = thunkAPI.getState()
      let type = 'Referral'
      const response = await apiRequest.post(`/pointclickcare/refer-patient`, payload)
      await thunkAPI.dispatch(fetchProspects());
      updateToast(toastId, `${type} imported successfully`, true)
      resolve(response.data)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})
export const createProspect = createAsyncThunk('/createProspect', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    let state = thunkAPI.getState()
    let type = 'Referral'
    const toastId = getToastId(`Adding ${type}`)
    try {
      let activeUser = state.auth.activeUser
      let selectedFacility = state.facilities.selectedFacility
      let defaultFilters = !!activeUser?.facilityProfile ? { filterData: { facility: selectedFacility?.name } } : undefined
      const response = await apiRequest.post(`/prospects`, payload)
      await thunkAPI.dispatch(fetchProspects({ ...defaultFilters }));
      updateToast(toastId, `${type} added successfully`, true)
      //prospect_created
      ReactGA.event({
        category: "prospect_created",
        action: "prospect_created",
        label: "prospect_created",
        value: 1
      });
      resolve(response.data)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})
export const updateProspect = createAsyncThunk('updateProspect', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    let state = thunkAPI.getState()
    let type =  'Referral'
    const toastId = getToastId(`Updating ${type}`)
    try {
      let activeUser = state.auth.activeUser
      let selectedFacility = state.facilities.selectedFacility
      let defaultFilters = !!activeUser?.facilityProfile ? { filterData: { facility: selectedFacility?.name } } : undefined
      const response = await apiRequest.patch(`/prospects/${payload.prospect_id}`, payload.data)
      await thunkAPI.dispatch(fetchProspects({ ...defaultFilters }));
      updateToast(toastId, `${type} updated successfully`, true)
      //patient_updated
      ReactGA.event({
        category: "patient_updated",
        action: "patient_updated",
        label: "patient_updated",
        value: 1
      });
      resolve(response.data)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})
export const filterPatientEncounters = createAsyncThunk('filterPatientEncounters', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.patients.patientEncountersPagination
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : { patient: requestParams }
      params.page = pagination.current
      params.lomit = 100
      const response = await apiRequest.get(`/assessments`,{params})
      let new_list = []
      if (params["Lab"]) {
        let my_list = response.data.data
        for (const element of my_list) {
          for (const item of element.lab_tests) {
            let assessment = { ...item, ...element }
            assessment.id = item.assessment_id
            assessment.status = element.status
            assessment.assessment_status = item.status
            new_list.push(assessment)
          }
        }
      } else {
        new_list = response.data.data
      }
      const payload = {
        patientEncounters: !!params?.type ? { [params.type]: new_list } : { 'All': new_list },
        pagination: { ...pagination, total: response.data.meta.total }
      }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'patient encounters'), false)
      reject(e)
    }
  })
})
export const getWoundEncounters = createAsyncThunk('getWoundEncounters', async (params, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      //console.log(params)
      let queryString = (params && params['filterData']) ? '?' + querystring.stringify({ ...params['filterData'], page_size: 200 }) : "";
      // queryString += `&page=${params.pagination.current}`
      const encounters = await apiRequest.get(`/assessments${queryString}`)

      resolve(encounters.data.data)
    } catch (e) {
      toastUp(errMsg(e, true, 'wound encounters'), false)
      reject(e)
    }
  })
})


export const getWoundPlanofcares = createAsyncThunk('getWoundPlanofcares', async (params, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      //console.log(params)
      let queryString = (params && params['filterData']) ? '?' + new URLSearchParams(params['filterData']).toString() : "";
      // queryString += `&page=${params.pagination.current}`
      const encounters = await apiRequest.get(`wounds/${params['filterData'].wound}/plan-of-care-summaries${queryString}`)
      resolve(encounters.data.data)
    } catch (e) {
      toastUp(errMsg(e, true, 'wound plans of care'), false)
      reject(e)
    }
  })
})
//
// patient health Data
//
export const fetchAllergies = createAsyncThunk('fetchAllergies', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.patients.allergiesPagination
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : { patient: requestParams }
      params.page = pagination.current
      const response = await apiRequest.get(`/patients/${params.patient||requestParams}/allergies`,{params})
      let data = response.data.data.map(item => ({ ...item, patient_id: params?.patient || requestParams }))
      const payload = {
        data, pagination:{ ...pagination, total: response.data.meta.total }
      }
      resolve(payload)
    } catch (e) {
      toastUp(errMsg(e, true, 'allergies'), false)
      reject(e)
    }
  })
})
export const createAllergy = createAsyncThunk('createAllergy', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Adding allergy")
    try {
      const response = await apiRequest.post(`/patients/${payload.patient_id}/allergies`, payload)
      // const response = await apiRequest.post(`/allergies`, payload)
      await thunkAPI.dispatch(fetchAllergies(payload.patient_id));
      updateToast(toastId, "Added successfully", true)
      //patient_allergies_added
      ReactGA.event({
        category: "patient_allergies_added",
        action: "patient_allergies_added",
        label: "patient_allergies_added",
        value: 1
      });
      resolve(response)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})
export const deleteAllergy = createAsyncThunk('deleteAllergy', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/patients/${payload.patient_id}/allergies/${payload.allergy_id}`)
      // const response = await apiRequest.delete(`/allergies/${payload.allergy_id}`)
      await thunkAPI.dispatch(fetchAllergies(payload.patient_id));
      toastUp("Deleted successfully", true)
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const updateAllergy = createAsyncThunk('updateAllergy', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(payload)
      let data = sanitizePayload(payload)
      const response = await apiRequest.patch(`/patients/${payload.patient_id}/allergies/${payload.allergy_id}`, data)
      // const response = await apiRequest.patch(`/allergies/${payload.allergy_id}`, payload)
      await thunkAPI.dispatch(fetchAllergies(payload.patient_id));
      toastUp("Updated successfully", true)
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, true), false)
      reject(e)
    }
  })
})
export const createProgressNote = createAsyncThunk('createProgressNote', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Adding Internal Note")
    try {
      const response = await apiRequest.post(`/patients/${payload.patient_id}/progress-notes`, payload)
      // const response = await apiRequest.post(`/progress-notes`, payload)
      let parm = { id: payload.patient_id }
      await thunkAPI.dispatch(fetchPatientNotes(parm))
      updateToast(toastId, "Added successfully", true)
      resolve(response)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})
export const deleteProgressNote = createAsyncThunk('deleteProgressNote', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/patients/${payload.patient_id}/progress-notes/${payload.note_id}`)
      // const response = await apiRequest.delete(`/progress-notes/${payload.note_id}`)
      let parm = { id: payload.patient_id }
      await thunkAPI.dispatch(fetchPatientNotes(parm));
      toastUp("Deleted successfully", true)
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const updateProgressNote = createAsyncThunk('updateProgressNote', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/patients/${payload.data.patient_id}/progress-notes/${payload.note_id}`, payload.data)
      let parm = { id: payload.data.patient_id }
      await thunkAPI.dispatch(fetchPatientNotes(parm));
      toastUp("Updated successfully", true)
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})


// sticky notes


export const fetchStickyNotes = createAsyncThunk('fetchStickyNotes', async (requestParams, thunkAPI) => {
  //console.log(requestParams)
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.patients.stickynotesPagination
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : { patient: requestParams.id }
      params.page = pagination.current
      const response = await apiRequest.get(`patients/${params.id||requestParams.id}/sticky-notes`,{params})
      const payload = {
        progressNotes: response.data.data.map(item => ({ ...item, patient_id: params.id||requestParams.id })),
        pagination:  { ...pagination, total: response.data?.data?.length }
      }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'patient\'s sticky notes '), false)
      reject(e)
    }
  })
})

export const createStickyNote = createAsyncThunk('createStickysNote', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Adding Sticky Note")
    try {
      const response = await apiRequest.post(`/patients/${payload.patient_id}/sticky-notes`, payload)
      // const response = await apiRequest.post(`/progress-notes`, payload)
      let parm = { id: payload.patient_id }
      await thunkAPI.dispatch(fetchStickyNotes(parm))
      updateToast(toastId, "Added successfully", true)
      resolve(response)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})
export const deleteStickyNote = createAsyncThunk('deleteStickyNote', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/patients/${payload.patient_id}/sticky-notes/${payload.note_id}`)
      // const response = await apiRequest.delete(`/progress-notes/${payload.note_id}`)
      let parm = { id: payload.patient_id }
      await thunkAPI.dispatch(fetchStickyNotes(parm));
      toastUp("Deleted successfully", true)
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const updateStickyNote = createAsyncThunk('updateStickyNote', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/patients/${payload.data.patient_id}/sticky-notes/${payload.note_id}`, payload.data)
      let parm = { id: payload.data.patient_id }
      await thunkAPI.dispatch(fetchStickyNotes(parm));
      toastUp("Updated successfully", true)
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
// end of sticky notes

export const fetchImmunisations = createAsyncThunk('fetchImmunisations', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.patients.immunizationPagination
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : { patient: requestParams }
      params.page = pagination.current
      const response = await apiRequest.get(`/patients/${params.patient || requestParams}/immunisations`, { params })
      let data = response.data.data.map(item => ({ ...item, patient_id: params.patient || requestParams }))
      const payload = { data, pagination: { ...pagination, total: response.data.meta?.total } }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'immunisations'), false)
      reject(e)
    }
  })
})
export const createImmunization = createAsyncThunk('createImmunization', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Adding immunization")
    try {
      const response = await apiRequest.post(`/patients/${payload.patient_id}/immunisations`, payload)
      // const response = await apiRequest.post(`/immunisations`, payload)
      await thunkAPI.dispatch(fetchImmunisations(payload.patient_id));
      updateToast(toastId, "Added successfully", true)
      //patient_immunization_added
      ReactGA.event({
        category: "patient_immunization_added",
        action: "patient_immunization_added",
        label: "patient_immunization_added",
        value: 1
      });
      resolve(response.data)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})
export const updateImmunization = createAsyncThunk('updateImmunization', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Updating immunization")
    try {
      const response = await apiRequest.patch(`/patients/${payload.data.patient_id}/immunisations/${payload.immunization_id}`, payload.data)
      // const response = await apiRequest.patch(`/immunisations/${payload.immunization_id}`, payload.data)
      await thunkAPI.dispatch(fetchImmunisations(payload.data.patient_id));
      updateToast(toastId, `Updated successfully`, true)
      resolve(response.data)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})
export const deleteImmunization = createAsyncThunk('deleteImmunization', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Deleting immunization")
    try {
      const response = await apiRequest.delete(`/patients/${payload.patient_id}/immunisations/${payload.immunization_id}`)
      // const response = await apiRequest.delete(`/immunisations/${payload.immunization_id}`)
      await thunkAPI.dispatch(fetchImmunisations(payload.patient_id));
      updateToast(toastId, `Deleted successfully`, true)
      resolve(response)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})
export const fetchDiagnoses = createAsyncThunk('fetchDiagnoses', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.patients.patientDiagnosisPagination
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const response = await apiRequest.get(`/patients/${params.patient || requestParams}/diagnoses`, { params })
      const payload = {
        data: response.data.data.map(item => ({ ...item, patient_id: params.patient || requestParams })),
        pagination: { ...pagination, total: response.data.meta?.total }
      }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'diagnoses'), false)
      reject(e)
    }
  })
})
export const deleteDiagnoses = createAsyncThunk('/deleteDiagnoses', async (params, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      await apiRequest.delete(`/patients/${params.patientId}/diagnoses/${params.diagnosisID}`)
      // await apiRequest.delete(`/diagnoses/${params.diagnosisID}`)
      resolve()
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const createDiagnosis = createAsyncThunk('createDiagnosis', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Adding diagnosis")
    try {
      const response = await apiRequest.post(`/patients/${payload.patient_id}/diagnoses`, payload.data)
      await thunkAPI.dispatch(fetchDiagnoses(payload.patient_id));
      updateToast(toastId, "Added successfully", true)
      //patient_diagnosis_added
      ReactGA.event({
        category: "patient_diagnosis_added",
        action: "patient_diagnosis_added",
        label: "patient_diagnosis_added",
        value: 1
      });
      resolve(response.data)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})
export const updateDiagnosis = createAsyncThunk('updateDiagnosis', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Updating diagnosis")
    try {
      const response = await apiRequest.patch(`/patients/${payload.patient_id}/diagnoses/${payload.data.id}`, payload.data)
      await thunkAPI.dispatch(fetchDiagnoses(payload.data.patient_id));
      updateToast(toastId, `Updated successfully`, true)
      resolve(response.data)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})
export const fetchFamilyHistory = createAsyncThunk('fetchFamilyHistory', async (params, { getState }, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let queryString = ""
      if (params) {
        queryString = `patient=${params}`
      }
      const response = await apiRequest.get(`/patients/${params}/next-of-kin`)
      // const response = await apiRequest.get(`/next-of-kin?${queryString}`)
      resolve(response.data.data)
    } catch (e) {
      toastUp(errMsg(e, true, 'family history'), false)
      reject(e)
    }
  })
})
export const createFamilyMember = createAsyncThunk('createFamilyMember', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Adding family member")
    try {
      const response = await apiRequest.post(`/patients/${payload.patient_id}/next-of-kin`, payload)
      // const response = await apiRequest.post(`/next-of-kin`, payload)
      await thunkAPI.dispatch(fetchFamilyHistory(payload.patient_id));
      updateToast(toastId, "Added successfully", true)
      resolve(response.data)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      //console.log(e)
      reject(e)
    }
  })
})
export const updateFamilyMember = createAsyncThunk('updateFamilyMember', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Updating family member")
    try {
      const response = await apiRequest.patch(`/patients/${payload.patient_id}/next-of-kin/${payload.member_id}`, payload)
      // const response = await apiRequest.patch(`/next-of-kin/${payload.member_id}`, payload)
      await thunkAPI.dispatch(fetchFamilyHistory(payload.patient_id));
      updateToast(toastId, `Updated successfully`, true)
      resolve(response.data)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      //console.log(e)
      reject(e)
    }
  })
})
export const deleteFamilyMember = createAsyncThunk('deleteFamilyMember', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/patients/${payload.patient_id}/next-of-kin/${payload.id}`)
      // const response = await apiRequest.delete(`/next-of-kin/${payload.id}`)
      await thunkAPI.dispatch(fetchFamilyHistory(payload.patient_id));
      toastUp(`Deleted successfully`, true)
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, false), false)
      //console.log(e)
      reject(e)
    }
  })
})
export const fetchMedications = createAsyncThunk('fetchMedications', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.patients.medicationPagination
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : { patient: requestParams }
      params.page = pagination.current
      params.patient=params?.patient||requestParams
      const response = await apiRequest.get(`/patients/${params?.patient }/medications`, { params })
      let data = response.data.data.map(item => ({ ...item, patient_id: params.patient }))
      const payload = {
        data,
        pagination: { ...pagination, total: response.data.meta.total }
      }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'medications'), false)
      reject(e)
    }
  })
})
export const createMedication = createAsyncThunk('createMedication', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Adding medication")
    try {
      const response = await apiRequest.post(`/patients/${payload.patient_id}/medications`, payload)
      // const response = await apiRequest.post(`/medications`, payload)
      await thunkAPI.dispatch(fetchMedications(payload.patient_id));
      updateToast(toastId, "Added successfully", true)
      //GA4 event patient_medication_added
      ReactGA.event({
        category: "patient_medication_added",
        action: "patient_medication_added",
        label: "patient_medication_added",
        value: 1
      });
      resolve(response)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})
export const updateMedication = createAsyncThunk('updateMedication', async (payload, thunkAPI) => {
  //console.log(payload)
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Updating medication")
    try {
      const response = await apiRequest.patch(`/patients/${payload.patient_id}/medications/${payload.medication_id}`, payload)
      // const response = await apiRequest.patch(`/medications/${payload.medication_id}`, payload)
      await thunkAPI.dispatch(fetchMedications(payload.patient_id));
      updateToast(toastId, `updated successfully`, true)
      //GA4 event patient_medication_updated
      ReactGA.event({
        category: "patient_medication_updated",
        action: "patient_medication_updated",
        label: "patient_medication_updated",
        value: 1
      });
      resolve(response)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})
export const deleteMedication = createAsyncThunk('deleteMedication', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Deleting medication")
    try {
      const response = await apiRequest.delete(`/patients/${payload.patient_id}/medications/${payload.medication_id}`)
      // const response = await apiRequest.delete(`/medications/${payload.medication_id}`)
      await thunkAPI.dispatch(fetchMedications(payload.patient_id));
      updateToast(toastId, `Deleted successfully`, true)
      //GA4 event patient_medication_deleted
      ReactGA.event({
        category: "patient_medication_deleted",
        action: "patient_medication_deleted",
        label: "patient_medication_deleted",
        value: 1
      });
      resolve(response)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)
    }
  })
})
export const fetchPrescriptions = createAsyncThunk('fetchPrescriptions', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.patients.prescriptionsPagination
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : { patient: requestParams }
      params.page = pagination.current
      const response = await apiRequest.get(`patients/${params.patient||requestParams}/prescriptions`,{params})
      const payload = {
        data: response.data.data,
        pagination: { ...pagination, total: response.data.meta.total }
      }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'medications'), false)
      reject(e)
    }
  })
})

export const fetchShippingAddresses = createAsyncThunk('fetchShippingAddresses', async (_id, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let queryString = `user=${_id}`
      const response = await apiRequest.get(`/shipping-addresses?${queryString}`)
      let data = response.data.data.map(item => ({ ...item, user_id: _id }))
      resolve(data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'shipping addresses'), false)
      reject(e)
    }
  })
})
export const createShippingAddress = createAsyncThunk('createShippingAddress', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const toastId = getToastId("Adding shipping address")
    try {
      const response = await apiRequest.post(`/shipping-addresses`, payload.data)
      await thunkAPI.dispatch(fetchShippingAddresses(payload.data.user_id));
      updateToast(toastId, "Added successfully", true)
      //patient_shippingAddress_added
      ReactGA.event({
        category: "patient_shippingAddress_added",
        action: "patient_shippingAddress_added",
        label: "patient_shippingAddress_added",
        value: 1
      });
      resolve(response.data)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      //console.log(e)
      reject(e)
    }
  })
})
export const updateShippingAddress = createAsyncThunk('updateShippingAddress', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/shipping-addresses/${payload.address_id}`, payload.data)
      await thunkAPI.dispatch(fetchShippingAddresses(payload.data.user_id));
      toastUp("Shipping address updated successfully", true)
      //patient_shippingAddress_updated
      ReactGA.event({
        category: "patient_shippingAddress_updated",
        action: "patient_shippingAddress_updated",
        label: "patient_shippingAddress_updated",
        value: 1
      });
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const deleteShippingAddress = createAsyncThunk('deleteShippingAddress', async (data, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/shipping-addresses/${data.address_id}`)
      await thunkAPI.dispatch(fetchShippingAddresses(data.user_id));
      resolve(response)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})

export const deleteFacesheet = createAsyncThunk('deleteFacesheet', async (_id, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/face-sheets/${_id}`)
      toastUp("Facesheet deleted successfully", true)
      resolve(response.data)
    } catch (e) {
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const fetchSocialHistory = createAsyncThunk('fetchSocialHistory', async (_id,  thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/patients/${_id}/social-history`)
      // const response = await apiRequest.get(`/social-history?${queryString}`)
      resolve({ ...response.data.data, patient_id: _id })
    } catch (e) {
      toastUp(errMsg(e, true, 'social history'), false)
      reject(e)
    }
  })
})
export const updateSocialHistory = createAsyncThunk('updateSocialHistory', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/patients/${payload.data.patient_id}/social-history/${payload.id}`, payload.data)
      // const response = await apiRequest.patch(`/social-history/${payload.id}`, payload.data)
      await thunkAPI.dispatch(fetchSocialHistory(payload.data.patient_id));
      toastUp("updated successfully", true)
      //patient_social-history_updated
      ReactGA.event({
        category: "patient_social-history_updated",
        action: "patient_social-history_updated",
        label: "patient_social-history_updated",
        value: 1
      });
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const deleteSocialHistory = createAsyncThunk('deleteSocialHistory', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/patients/${payload.patientId}/social-history/${payload.sHistoryID}`)
      await thunkAPI.dispatch(fetchSocialHistory(payload.patientId));
      toastUp("deleted successfully", true)
      //patient_deleted
      ReactGA.event({
        category: "patient_deleted",
        action: "patient_deleted",
        label: "patient_deleted",
        value: 1
      });
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false, 'social history', null), false)
      reject(e)
    }
  })
})
export const createSocialHistory = createAsyncThunk('createSocialHistory', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/patients/${payload.data.patient_id}/social-history`, payload.data)
      // const response = await apiRequest.post(`/social-history`, payload.data)
      await thunkAPI.dispatch(fetchSocialHistory(payload.data.patient_id));
      toastUp(`Social history added successfully`, true)
      //GA4 event patient_social-history_added
      ReactGA.event({
        category: "patient_social-history_added",
        action: "patient_social-history_added",
        label: "patient_social-history_added",
        value: 1
      });
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const updatePatientDetails = createAsyncThunk('updatePatientDetails', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { patient_id, ...rest } = payload
      const response = await apiRequest.patch(`/patients/${patient_id}`, rest)
      // if nest.approval_Status === not approved 
      if(rest.approval_status !== "Not Approved"){
      await thunkAPI.dispatch(fetchPatientById(patient_id));
      }
     
      toastUp("updated successfully", true)
      resolve(response.data)
    } catch (e) {
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})

export const deletePatient = createAsyncThunk('deletePatient', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/patients/${payload.data.patient_id}`, payload.data)
      await thunkAPI.dispatch(fetchPatientById(payload.data.patient_id));
      toastUp("updated successfully", true)
      resolve(response.data)
    } catch (e) {
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})

export const archivePatient = createAsyncThunk('archivePatient', async (patient_id, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/patients/${patient_id}`)
      toastUp("Archived successfully", true)
      resolve(response.data)
    } catch (e) {
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const restorePatient = createAsyncThunk('restorePatient', async (patient_id, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/patients/${patient_id}/restore`)
      toastUp("Patient restored successfully", true)
      resolve(response.data)
    } catch (e) {
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const approveProspect = createAsyncThunk('/approve-prospect', async (params, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { id, ...rest } = params
      const response = await apiRequest.post(`/prospects/${id}/approve`, rest)
      toastUp("Referral status changed successfully", true)
      resolve(response.data.data)
    } catch (e) {
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const declineProspect = createAsyncThunk('/decline-prospect', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/prospects/${payload.id}/decline`, payload.data)
      await thunkAPI.dispatch(fetchProspects())
      toastUp("Referral declined successfully", true)
      resolve(response.data.data)
    } catch (e) {
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})

// patient tasks
export const fetchPatientTasks = createAsyncThunk('fetchPatientTasks', async (requestParams, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        let state = thunkAPI.getState();
        let pagination = requestParams?.pagination ? requestParams.pagination : state.tasks.taskPagination
        let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : { }
        params.page = pagination.current
        params.patientId = state.patients?.selectedPatient?.id
        let response = await apiRequest.get(`/tasks`, { params });
        let data = response.data.data
        const payload = { tasks: data, taskPagination: { ...pagination, total: response.data.meta.total } }
        resolve(payload)
      } catch (e) {
        console.log({ fetchPatientTasks:e })
        reject(e)
        toastUp(errMsg(e, true, 'tasks'), false)
      }
    })
  }
)

// create task
export const createPatientTask = createAsyncThunk('createPatientTask', async (params, thunkAPI) => {

  const toastId = getToastId("Creating PatientTask")

  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/tasks`, params)
      updateToast(toastId, "PatientTask Created", true)
      await thunkAPI.dispatch(fetchPatientTasks())
      resolve(response)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)

    }
  })
})


// update task
export const updatePatientTask = createAsyncThunk('updatePatientTask', async (params, thunkAPI) => {

  const toastId = getToastId("Updating PatientTask")

  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/tasks/${params.id}`, params)
      updateToast(toastId, "PatientTask Updated", true)
      // await thunkAPI.dispatch(selectPatientTask(response.data.data))
      await thunkAPI.dispatch(fetchPatientTasks())
      resolve(response?.data?.data)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)

    }
  })
})

// update task
export const updatePatientTaskStatus = createAsyncThunk('updatePatientTaskStatus', async (params, thunkAPI) => {

  const toastId = getToastId("Updating PatientTask status")

  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/tasks/${params.id}/status`, params.payload)
      updateToast(toastId, "PatientTask Updated", true)
      // await thunkAPI.dispatch(selectPatientTask(response.data.data))
      await thunkAPI.dispatch(fetchPatientTasks())

      resolve(response?.data?.data)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)

    }
  })
})
// delete task
export const deletePatientTask = createAsyncThunk('tasks/deletePatientTask', async (params, thunkAPI) => {

  const toastId = getToastId("Deleting PatientTask")

  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/tasks/${params.id}`)

      updateToast(toastId, "PatientTask Deleted", true)
      await thunkAPI.dispatch(fetchPatientTasks())
      resolve(response)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)

    }
  })

})

// create task comment
export const createPatientTaskComment = createAsyncThunk('createPatientTaskComment', async (params, thunkAPI) => {
  const toastId = getToastId("Creating PatientTask Comment")

  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/tasks/${params.id}/comments`, params)
      updateToast(toastId, "PatientTask Comment Created", true)
      await thunkAPI.dispatch(fetchPatientTaskComments({ id: params.id }))
      resolve(response)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)

    }
  })
})

// update task comment
export const updatePatientTaskComment = createAsyncThunk('updatePatientTaskComment', async (params, thunkAPI) => {
  const toastId = getToastId("Updating PatientTask Comment")

  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/tasks/${params.taskId}/comments/${params.id}`, params)
      updateToast(toastId, "PatientTask Comment Updated", true)
      await thunkAPI.dispatch(fetchPatientTaskComments({ id: params.taskId }))
      resolve(response)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)

    }
  })
})

// delete task comment

export const deletePatientTaskComment = createAsyncThunk('deletePatientTaskComment', async (params, thunkAPI) => {
  const toastId = getToastId("Deleting PatientTask Comment")

  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/tasks/${params.taskId}/comments/${params.id}`)
      updateToast(toastId, "PatientTask Comment Deleted", true)
      await thunkAPI.dispatch(fetchPatientTaskComments({ id: params.taskId }))
      resolve(response)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)

    }
  })
})
// fetch task comments
export const fetchPatientTaskComments = createAsyncThunk('fetchPatientTaskComments', async (params, thunkAPI) => {
  // const toastId = getToastId("Fetching PatientTask Comments")

  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/tasks/${params.id}/comments`)
      // updateToast(toastId, "PatientTask Comments Fetched", true)
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, true, 'tasks'), false);
      reject(e)

    }
  })
})
// fetch task attachments
export const fetchPatientTaskAttachments = createAsyncThunk('fetchPatientTaskAttachments', async (params, thunkAPI) => {
  // const toastId = getToastId("Fetching PatientTask Attachments")

  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/tasks/${params.id}/attachments`)
      // updateToast(toastId, "PatientTask Attachments Fetched", true)
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, true, 'tasks'), false);
      reject(e)

    }
  })
})
// create task attachment
export const createPatientTaskAttachment = createAsyncThunk('createPatientTaskAttachment', async (params, thunkAPI) => {
  const toastId = getToastId("Creating PatientTask Attachment")

  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/tasks/${params.id}/attachments`, params)
      updateToast(toastId, "PatientTask Attachment Created", true)
      await thunkAPI.dispatch(fetchPatientTask({ id: params.id }))
      resolve(response)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)

    }
  })

})
// delete task attachment
export const deletePatientTaskAttachment = createAsyncThunk('deletePatientTaskAttachment', async (params, thunkAPI) => {
  const toastId = getToastId("Deleting PatientTask Attachment")

  return new Promise(async (resolve, reject) => {

    try {
      const response = await apiRequest.delete(`/tasks/${params.taskId}/attachments/${params.id}`)
      updateToast(toastId, "PatientTask Attachment Deleted", true)
      await thunkAPI.dispatch(fetchPatientTask({ id: params.taskId }))
      resolve(response)
    } catch (e) {
      updateToast(toastId, errMsg(e, false), false)
      reject(e)

    }
  })

})

// fetch specific task
export const fetchPatientTask = createAsyncThunk('deletePatientTaskAttachment', async (params, thunkAPI) => {
  // const toastId = getToastId("Fetching PatientTask")

  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/tasks/${params.id}`)
      // updateToast(toastId, "PatientTask Fetched", true)
      resolve(response)
    } catch (e) {
      toastUp(errMsg(e, true, 'tasks'), false);
      reject(e)

    }
  })
})


export const patientSlice = createSlice({
  name: 'patients', initialState, // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    selectPatient: (state, action) => {
      state.selectedPatient = action.payload.record;
    }, setSelectedProspect: (state, action) => {
      state.selectedProspect = action.payload;
    }, setProspectNotificationOpen: (state, action) => {
      state.prospectNotificationOpen = action.payload;
    }, setPatientNotificationOpen: (state, action) => {
      state.patientNotificationOpen = action.payload;
    }, selectWound: (state, action) => {
      state.selectedWound = action.payload.record
    },archivedPatientFilters: (state, action) => {
      state.archivedPatientFilters = { isOn: false, filters: action.payload.filters }
    }, patientCreated: (state, action) => {
      let newState = newResourceArray(state.patients, action)
      state.patients = [...newState]
    }, prospectCreated: (state, action) => {
      let newState = newResourceArray(state.prospects, action)
      state.prospects = [...newState]
    }, vitalsCreated: (state, action) => {
      let newState = newResourceArray(state.vitals, action)
      state.vitals = [...newState]
    }, shippingAddrCreated: (state, action) => {
      let newState = newResourceArray(state.shippingAddresses, action)
      state.shippingAddresses = [...newState]
    }, woundCreated: (state, action) => {
      let newState = newResourceArray(state.patientWounds, action)
      state.patientWounds = [...newState]
    }, insuranceCreated: (state, action) => {
      let newState = newResourceArray(state.insurances, action)
      state.insurances = [...newState]
    }, updatePatientAppointment: (state, action) => {
      state.patientAppointments = action.payload
    }, patientAppointmentUpdated: (state, action) => {
      let newState = updatedArrayState(state.patientAppointments, action, 'id')
      state.patientAppointments = [...newState]
    }, insuranceUpdated: (state, action) => {
      let newState = updatedArrayState(state.insurances, action, 'id')
      state.insurances = [...newState]
    }, woundUpdated: (state, action) => {
      let newState = updatedArrayState(state.patientWounds, action, 'id')
      state.patientWounds = [...newState]
    }, patientUpdated: (state, action) => {
      let newState = updatedArrayState(state.patients, action, 'id')
      state.patients = [...newState]
    }, singlePatientUpdated: (state, action) => {
      state.selectedPatient = { ...action.payload }
    }, prospectUpdated: (state, action) => {
      let newState = updatedArrayState(state.prospects, action, 'id')
      state.prospects = [...newState]
    }, shippingAddrUpdated: (state, action) => {
      let newState = updatedArrayState(state.shippingAddresses, action, 'id')
      state.shippingAddresses = [...newState]
    }, resetPatientModuleState: (state) => {
      let localState = { ...initialState }
      delete localState.patients
      delete localState.prospects
      delete localState.pagination
      Object.keys(localState).map(key => state[key] = initialState[key])
    }, resetPatientPagination: (state) => {
      state.pagination = initialState.pagination
    }, resetArchivedPatientPagination: (state) => {
      state.archivedPatientPagination = initialState.archivedPatientPagination
    }, resetfArchivedPatientPagination: (state) => {
      state.farchivedPatientPagination = initialState.farchivedPatientPagination
    }, resetProspectPagination: (state) => {
      state.prospectPagination = initialState.prospectPagination
    }, resetPatientWoundsPagination: (state) => {
      state.patientWoundsPagination = initialState.patientWoundsPagination
    }, resetPatientDiagnosisPagination: (state) => {
      state.patientDiagnosisPagination = initialState.patientDiagnosisPagination
    }, resetDuplicateWoundsPagination: (state) => {
      state.duplicateWoundsPagination = initialState.duplicateWoundsPagination
    }, resetPatientOpenWoundsPagination: (state) => {
      state.patientOpenWoundsPagination = initialState.patientOpenWoundsPagination
    }, resetPatientAppointmentPagination: (state) => {
      state.patientAppointmentPagination = initialState.patientAppointmentPagination
    }, resetPatientEncountersPagination: (state) => {
      state.patientEncountersPagination = initialState.patientEncountersPagination
    }, resetAllergiesPagination: (state) => {
      state.allergiesPagination = initialState.allergiesPagination
    }, resetImmunizationPagination: (state) => {
      state.immunizationPagination = initialState.allergiesPagination
    }, resetPatientDocPagination: (state) => {
      state.patientDocPagination = initialState.patientDocPagination
    }, resetPatientConsentPagination: (state) => {
      state.patientConsentPagination = initialState.patientConsentPagination
    }, resetMedicationPagination: (state) => {
      state.medicationPagination = initialState.medicationPagination
    }, resetPrescriptionsPagination: (state) => {
      state.prescriptionsPagination = initialState.prescriptionsPagination
    }
    , resetNotesPagination: (state) => {
      state.notesPagination = initialState.notesPagination
    }, doResetPatientSlice: (state) => {
      Object.keys(initialState).map(key => state[key] = initialState[key])
    },
    resetPatientOrdersPagination: (state) => {
      state.patientOrdersPagination = initialState.patientOrdersPagination
    }
    ,
    createPatientTask: (state, action) => {
      state.tasks = [...state.tasks, action.payload]
    },
    updatePatientTaskFilters: (state, action) => {
      state.taskFilters = [...action.payload]
    },
    selectPatientTask: (state, action) => {
      state.selectedPatientTask = action.payload
    },
    resetPatientTaskPagination: (state, action) => {
      state.taskPagination = { ...initialState.taskPagination }
    }
  }, extraReducers: (builder) => {
    builder.addCase(fetchPatientById.fulfilled, (state, action) => {
      state.selectedPatient = { ...action.payload }
    }).addCase(fetchProspectById.fulfilled, (state, action) => {
      state.selectedProspect = { ...action.payload }
    }).addCase(fetchPatientOrders.fulfilled, (state, action) => {
      //console.log('action.payload: ', action.payload)
      Object.keys(action.payload).forEach(key => state[key] = action.payload[key])
    }).addCase(fetchPatients.fulfilled, (state, action) => {
      state.patients = [...action.payload.data]
      state.pagination = action.payload.pagination
      state.loading = false
    }).addCase(fetchArchivedPatients.fulfilled, (state, action) => {
      state.archivedPatients = [...action.payload.data]
      state.archivedPatientPagination = action.payload.pagination
    }).addCase(fetchPatientInsurance.fulfilled, (state, action) => {
      state.insurances = [...action.payload]
    }).addCase(fetchPatientVitals.fulfilled, (state, action) => {
      state.vitals = [...action.payload]
    }).addCase(fetchPatientNotes.fulfilled, (state, action) => {
      state.patientProgressNotes = [...action.payload.progressNotes]
      state.notesPagination = action.payload.pagination
    }).addCase(fetchStickyNotes.fulfilled, (state, action) => {
      state.patientStickyNotes = [...action.payload.progressNotes]
      state.stickynotesPagination = action.payload.pagination
    }).addCase(fetchPatientWounds.fulfilled, (state, action) => {
      state.patientWounds = [...action.payload.wounds]
      state.patientWoundsPagination = action.payload.pagination
    }).addCase(fetchDuplicatePatientWounds.fulfilled, (state, action) => {
      state.duplicateWounds = [...action.payload.wounds]
      state.duplicateWoundsPagination = action.payload.pagination
    }).addCase(fetchOpenPatientWounds.fulfilled, (state, action) => {
      state.patientOpenWounds = [...action.payload.wounds]
      state.patientOpenWoundsPagination = action.payload.pagination
    }).addCase(fetchProspects.fulfilled, (state, action) => {
      state.prospects = [...action.payload.data]
      state.prospectPagination = action.payload.pagination
    }).addCase(fetchAllergies.fulfilled, (state, action) => {
      state.healthData.allergies = [...action.payload.data]
      state.allergiesPagination = action.payload.pagination
    }).addCase(fetchDiagnoses.fulfilled, (state, action) => {
      state.healthData.diagnosis = Array.isArray(action.payload.data) ? [...action.payload.data] : []
      state.patientDiagnosisPagination = action.payload.pagination
    }).addCase(fetchFamilyHistory.fulfilled, (state, action) => {
      state.healthData.familyHistory = [...action.payload]
    }).addCase(fetchImmunisations.fulfilled, (state, action) => {
      state.healthData.immunizations = [...action.payload.data]
      state.immunizationPagination = action.payload.pagination
    }).addCase(fetchMedications.fulfilled, (state, action) => {
      state.healthData.medication = [...action.payload.data]
      state.medicationPagination = action.payload.pagination
    }).addCase(fetchPrescriptions.fulfilled, (state, action) => {
      state.healthData.prescriptions = [...action.payload.data]
      state.prescriptionsPagination = action.payload.pagination
    }).addCase(fetchSocialHistory.fulfilled, (state, action) => {
      state.healthData.socialHistory = { ...action.payload }
    }).addCase(filterPatientEncounters.fulfilled, (state, action) => {
      state.patientEncounters = { ...state.patientEncounters, ...action.payload.patientEncounters }
      state.patientEncountersPagination = action.payload.pagination
    }).addCase(getWoundEncounters.fulfilled, (state, action) => {
      state.woundEncounters = [...action.payload]
    }).addCase(getWoundPlanofcares.fulfilled, (state, action) => {
      state.woundPlansOfCare = [...action.payload]
    }).addCase(fetchShippingAddresses.fulfilled, (state, action) => {
      state.shippingAddresses = [...action.payload]
    }).addCase(fetchPatientAppointments.fulfilled, (state, action) => {
      state.patientAppointments = action.payload.data
      state.patientAppointmentPagination = action.payload.pagination
    }).addCase(fetchPatientDocuments.fulfilled, (state, action) => {
      state.patientDocuments = [...action.payload.data]
      state.patientDocPagination = action.payload.pagination
    }).addCase(fetchPatientConsentForms.fulfilled, (state, action) => {
      state.patientConsentForms = [...action.payload.data]
      state.patientConsentPagination = action.payload.pagination
    }).addCase(fetchPatientTasks.fulfilled, (state, action) => {
      Object.keys(action.payload).forEach(key => state[key] = action.payload[key])
      state.loadingPatientTasks = false
    }).addCase(updatePatientTask.fulfilled, (state, action) => {
      state.selectedPatientTask = action.payload
    }).addCase(updatePatientTaskStatus.fulfilled, (state, action) => {
      state.selectedPatientTask = action.payload
    }).addCase(fetchPatientTaskComments.fulfilled, (state, action) => {
      state.comments = action.payload.data.data
    }).addCase(fetchPatientTaskAttachments.fulfilled, (state, action) => {
      state.attachments = action.payload.data.data
    }).addCase(fetchPatientTask.fulfilled, (state, action) => {
      state.selectedPatientTask = action.payload.data.data
    })
  }
});
export const {
  selectPatient, setSelectedProspect, resetPatientConsentPagination,
  selectWound,
  resetPatientModuleState,
  resetNotesPagination,
  resetArchivedPatientPagination,
  resetfArchivedPatientPagination,
  doResetPatientSlice,
  resetAllergiesPagination, resetImmunizationPagination, resetPatientDocPagination,
  resetMedicationPagination,
  resetPatientWoundsPagination,
  resetDuplicateWoundsPagination,
  resetPatientAppointmentPagination,
  resetPrescriptionsPagination,
  updatePatientAppointment,
  resetPatientPagination,
  resetProspectPagination,
  resetPatientEncountersPagination,
  resetPatientOrdersPagination, resetPatientOpenWoundsPagination, resetPatientDiagnosisPagination, selectPatientTask, setProspectNotificationOpen, setPatientNotificationOpen
} = patientSlice.actions;
export default patientSlice.reducer;
