/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Headline6, Subtitle1 } from '../general'
import { Avatar, Divider, Menu } from 'antd';
import { unpin } from "../../assets"
import AppointmentCard from './Components/AppointmentCard';
import { unpinConsultant } from '../../app/Reducers/mapSlice';
import { apiRequest } from '../../app/Apis';
import { status_colors } from '../../configure/constants';
import { emptyFun } from "../../common/helpers";

const { SubMenu } = Menu;
const regions = {
  western: ["California", "Nevada", "Arizonia", "Utah"], eastern: []
}
export const MapMenu = (props) => {
  const [isCollapsed, setCollapsed] = useState(true)
  const pinnedconsultants = useSelector(state => state.map.pinnedConsultants)
  const [show, setShow] = useState(true)
  const [appointments, setappointments] = useState([])
  const [activeConsultant, setactiveConsultant] = useState(pinnedconsultants[0])
  const dispatch = useDispatch()
  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };
  useEffect(() => {
    setactiveConsultant(pinnedconsultants[0])
    if (pinnedconsultants.length) {
      fetchConsultantshedule(pinnedconsultants[0])
    }
    return emptyFun
  }, [pinnedconsultants])
  const onCheck = (checkedKeys, info) => {
    console.log('onCheck', checkedKeys, info);
  };
  const fetchConsultantshedule = async (item) => {
    let queryString = `consultant=${item.id}&scheduledFor=today`
    const response = await apiRequest.get(`/appointments?${queryString}`)
    let data = response.data.data.map(item => {
      return item
    })
    console.log(data)
    setappointments(data)
  }
  return (<Fragment>

    <div className="consultant-menu-collapsed " style={{ width: isCollapsed ? "64px" : "0px" }}
      onClick={() => setCollapsed(false)}>
      <FontAwesomeIcon onClick={() => console.log("heh")} color="grey" icon={["fas", 'users']} />
    </div>
    <div className="flex-boy-column-space collapsible consultant-menu"
      style={{ width: isCollapsed ? '0px' : "350px", height: "calc(100vh - 64px)" }}>
      <div className="flex-boy-row top">
        <div className="consultants">
          <div className="circle-border">
            {isCollapsed ?
              <FontAwesomeIcon onClick={() => setCollapsed(false)} color="grey" icon={["fas", 'chevron-left']} /> :
              <FontAwesomeIcon onClick={() => setCollapsed(true)} color="grey" icon={["fas", 'chevron-right']} />}
          </div>
          <Divider />
          {pinnedconsultants.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i).map(item => {
            return (<div onClick={() => {
              setactiveConsultant(item)
              fetchConsultantshedule(item)
            }} key={item.id}>
              <Avatar src={require("../../assets/user.png")} />
              <Divider />
            </div>)
          })}


          {/* <FontAwesomeIcon onClick={() => console.log("heh")} color="grey" icon={["fas", 'plus']}/> */}
        </div>
        <div className="checklists">
          <div className="flex-boy-row-space top">
            <Headline6>Checklist</Headline6>
            <div onClick={() => setCollapsed(true)} className="circle-border">
              <FontAwesomeIcon color="grey" size="1x" icon={["fas", 'times']} />
            </div>

          </div>
          {activeConsultant ? <>
            <div className="checklists-detail">
              <div className="flex-boy-row-space">
                <Avatar src={require("../../assets/user.png")} />
                <div className="name">
                  <Subtitle1>
                    {activeConsultant.name}
                  </Subtitle1>


                </div>
                <div className="flex-boy-row-space icons">
                  <FontAwesomeIcon onClick={() => console.log("heh")} color="grey" size="1x" icon={["fas", 'map']} />
                  <FontAwesomeIcon onClick={() => console.log("heh")} color="grey" size="1x"
                    icon={["far", 'comment-alt']} />
                  <img src={unpin} alt="unpin" onClick={() => {
                    dispatch(unpinConsultant(activeConsultant))
                  }} />
                </div>
              </div>


            </div>
            <div className="checklists-appointments">
              {/* <AppointmentCardContainer />
                                    <AppointmentCardContainer />
                                    <AppointmentCardContainer /> */}
              {appointments.map(item => <AppointmentCard key={item.id} appointment={item}
                color={status_colors[`${item.status}`]} />)}
            </div>
          </> : <Subtitle1>No consultant selected</Subtitle1>}
        </div>
      </div>


    </div>
  </Fragment>)
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(MapMenu)
