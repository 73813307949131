/* eslint-disable no-unused-vars */
import React, {useState} from 'react'
import {connect} from 'react-redux'
import RouteSearchComponent from "../RouteSearchComponent";

const RouteMapSearch = ({handleConsultant, filterRoutes, showActiveTrips, loadingTrips,searchTrip}) => {
  const [isCollapsed, setCollapsed] = useState(true)
  
  //top: '50%', marginTop: '-25px'
  return (<div className="trip-map-search flex-boy-row-space" style={{padding: 0}}>
    <RouteSearchComponent handleConsultant={handleConsultant} filterRoutes={filterRoutes} loadingTrips={loadingTrips} showActiveTrips={showActiveTrips} searchTrip={searchTrip}/>
  </div>)
}
const mapStateToProps = (state) => ({})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(RouteMapSearch)
