// redux toolkit slice

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// initial state
import { billing } from "./initialState";
import { errMsg, getToastId, toastUp, updateToast } from './reducerUtils';
import { apiRequest } from '../Apis';
import querystring from "querystring";
import _, { rest } from 'lodash';
const initialState = { ...billing };


// fetch data from api
// export const fetchOrders = createAsyncThunk('/orders', async (parms, thunkAPI) => {
//     return new Promise(async (resolve, reject) => {
//         try {
//             const state = await thunkAPI.getState();
//             let params = typeof parms === 'object' ? { ...parms } : parms
//             let queryString = ""
//             let props;
//             if (params && params['filterData']) {
//                 queryString = (params && params['filterData']) ? '?' + querystring.stringify(params['filterData']) : ""
//                 let visitType = params['filterData']?.visitType
//                 let orderState = params['filterData']?.orderState
//                 // console.log({params,visitType,orderState})
//                 // console.log('XXXX:',state.orders.dmeOrdersPagination)
//                 props = !!params.pagination ? params : {
//                     pagination:
//                         orderState === 'pre-order' ? state.orders.ordersPagination :
//                             visitType === 'biologics' ? state.orders.bioOrdersPagination :
//                                 visitType === 'dme' ? state.orders.dmeOrdersPagination :
//                                     visitType === 'internal' ? state.orders.internalOrdersPagination :
//                                         visitType === 'final' ? state.orders.finalOrdersPagination :
//                                             orderState === 'order-history' ? state.orders.historyOrdersPagination :
//                                                 null
//                 };
//                 // console.log({props})
//                 queryString += `&page=${props.pagination.current}`
//             } else {
//                 //when called e.g after updating/creating new order
//                 props = (params && Object.keys(params).length > 0) ? params : { pagination: state.orders.ordersPagination };
//                 queryString = props && props.pagination && props.pagination.current ? `?page=${props.pagination.current}` : ""
//             }
//             // console.log(props, state)
//             const response = await apiRequest.get(`/treatment-orders${queryString}`)
//             let data = response.data.data.map(item => ({ ...item, latlng: [item.lng, item.lat] }))
//             let visitType = params?.['filterData']?.visitType
//             let orderState = params?.['filterData']?.orderState
//             let pagination = orderState === 'pre-order' ? 'ordersPagination' :
//                 orderState === 'final' ? 'finalOrdersPagination' :
//                     visitType === 'biologics' ? 'bioOrdersPagination' :
//                         visitType === 'dme' ? 'dmeOrdersPagination' :
//                             visitType === 'internal' ? 'internalOrdersPagination' :
//                                 visitType === 'final' ? 'finalOrdersPagination' :
//                                     orderState === 'order-history' ? 'historyOrdersPagination' :
//                                         'ordersPagination'
//             const payload = { orders: data, [pagination]: { ...props.pagination, total: response.data.meta.total } }
//             // console.log(payload)
//             resolve(payload)
//         } catch (e) {
//             //console.log(e)
//             reject(e)
//             toastUp(errMsg(e, true, 'orders'), false)
//         }
//     })
// })

const types = ['Pending review', 'Ready for submission', 'Submitted']

export const fetchBillingEncounters = createAsyncThunk('/billing/encounters', async (parms, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
        try {
            const state = await thunkAPI.getState();
            let params = typeof parms === 'object' ? { ...parms } : parms
            let queryString = ""
            let props;
            if (params && params['filterData']) {
                queryString = (params && params['filterData']) ? '?' + querystring.stringify(params['filterData']) : ""

                let progressStatus = params['filterData']?.progressStatus
                // console.log({params,billingType,billingState})
                // console.log('XXXX:',state.orders.dmeOrdersPagination)
                props = !!params.pagination ? params : {
                    pagination:
                        progressStatus === 'pending review' ? state.billing.billingPendingPagination :
                            progressStatus === 'ready for submission' ? state.billing.billingReadyPagination :
                                progressStatus === 'submitted' ? state.billing.billingSubmittedPagination :
                                    null
                };
                console.log({ props })
                queryString += `&page=${props.pagination.current}`
            } else {
                //when called e.g after updating/creating new order
                props = (params && Object.keys(params).length > 0) ? params : { pagination: state.billing.billingPendingPagination };
                queryString = props && props.pagination && props.pagination.current ? `?page=${props.pagination.current}` : ""
            }
            // console.log(props, state)
            const response = await apiRequest.get(`/appointment-billing${queryString}`)
            let data = response.data.data.map(item => ({ ...item }))

            let progressStatus = params?.['filterData']?.progressStatus
            let pagination = progressStatus === 'pending review' ? 'billingPendingPagination' :
                progressStatus === 'ready for submission' ? 'billingReadyPagination' :
                    progressStatus === 'submitted' ? 'billingSubmittedPagination' :
                        'billingPagination'
            const payload = { billingEncounters: data, [pagination]: { ...props.pagination, total: response.data.meta.total } }
            // console.log(payload)
            resolve(payload)
        } catch (e) {
            //console.log(e)

            reject(e)
            toastUp(errMsg(e, true, 'billing'), false)
        }
    })
})

// submit billing encounter
export const submitBillingEncounter = createAsyncThunk('/billing/submit', async (params, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiRequest.post(`/billing/${params.id}`, params.data)
            let data = response.data.data
            let { assessment, ...rest } = data
            let payload = { billing: { ...rest }, ...assessment }
            resolve(payload)
        } catch (e) {
            reject(e)
            toastUp(errMsg(e, true, 'billing'), false)
        }
    })
})


// update billing encounter
export const updateBillingEncounter = createAsyncThunk('/billing/update', async (params, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiRequest.patch(`/billing/${params.id}/${params.billing_id}`, params.data)

            let data = response.data.data
            let { assessment, ...rest } = data
            let payload = { billing: { ...rest }, ...assessment }
            resolve(payload)

        } catch (e) {
            reject(e)
            toastUp(errMsg(e, true, 'billing'), false)
        }
    })
})

// fetch specific billing encounter
export const fetchBillingEncounter = createAsyncThunk('/billing/encounter', async (params, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiRequest.get(`/appointments/${params.id}`)
            let data = response.data.data
            resolve(data)
        } catch (e) {
            reject(e)
            toastUp(errMsg(e, true, 'billing'), false)
        }
    })
})

// submit billing claims
export const submitBillingClaims = createAsyncThunk('/billing/submit-claims', async (params, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiRequest.post(`/claim-group-charges`, params)
            let data = response.data.data
            resolve(data)
        } catch (e) {
            reject(e)
            toastUp(errMsg(e, true, 'billing'), false)
        }
    })
})

// submit split billing claims
export const submitSplitBillingClaims = createAsyncThunk('/billing/submit-split-claims', async (params, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
        const toastId = getToastId("Creating claims")
        try {

            const response = await apiRequest.post(`/group-claims-split-charges`, params)
            let data = response.data.data
            updateToast(toastId, 'Claims Created', true);
            resolve(data)
        } catch (e) {
            reject(e)
            toastUp(errMsg(e, true, 'billing'), false)
        }
    })
})


export const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {
        selectEncounter: (state, action) => {
            state.selectedEncounter = action.payload
        },
        resetBillingPendingPagination: (state, action) => {
            state.billingPendingPagination = initialState.billingPendingPagination
        },
        resetBillingReadyPagination: (state, action) => {
            state.billingReadyPagination = initialState.billingReadyPagination
        },
        resetBillingSubmittedPagination: (state, action) => {
            state.billingSubmittedPagination = initialState.billingSubmittedPagination
        },
        doResetBillingSlice: (state, action) => {
            Object.keys(initialState).map((key) => (state[key] = initialState[key]));
        }


    },
    extraReducers: (builder) => {
        builder.addCase(fetchBillingEncounters.fulfilled, (state, action) => {
            Object.keys(action.payload).forEach(key => state[key] = action.payload[key])
        }).addCase(submitBillingEncounter.fulfilled, (state, action) => {
            state.selectedEncounter = action.payload
        }).addCase(updateBillingEncounter.fulfilled, (state, action) => {
            state.selectedEncounter = action.payload
        }).addCase(fetchBillingEncounter.fulfilled, (state, action) => {
            state.selectedEncounter = action.payload
        })

    }
});

export const { selectEncounter, resetBillingPendingPagination, resetBillingReadyPagination, resetBillingSubmittedPagination, doResetBillingSlice } = billingSlice.actions;

export default billingSlice.reducer;
