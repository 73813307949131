import React, { useEffect, useState } from 'react';
import { Headline6 } from '../../../components';
import { addButton, dialogCloser, emptyFun, formSubmitButton, horStack, kebabMenus, boldKeyCapitalizedValue, modalClose, noVehicle, outlinedButton } from '../../../common/helpers';
import dayjs from 'dayjs';
import { connect, useDispatch } from 'react-redux';
import { deleteVehicle, fetchConsultantVehicles } from '../../../app/Reducers';
import { Modal } from "antd";
import KebabMenu from "../../../components/KebabMenu";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import ConsultantVehicle from "./ConsultantVehicle";
import { useSubscription } from "../../../hooks/MqttHooks/useSubscription";
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';

const ConsultantVehicles = ({ vehicles,  consultant, activeUser }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState(0);
  const handleCancel = () => setSelected(0);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [vehicleForm, setVehicleForm] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const [isExecuting, setisExecuting] = useState(false)
  const { message: vehiclesMessage } = useSubscription(`view_consultant_vehicle/${consultant.id}`, { qos: 2, nl: true, });
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_create_consultant_vehicle = usePermissionCheck('create_consultant_vehicle', false, [])
  const can_update_consultant_vehicle = usePermissionCheck('update_consultant_vehicle', false, [])
  const can_update_consultant_global = usePermissionCheck('update_consultant_global', false, [])
  useEffect(() => {
    if (!!vehiclesMessage) {
      console.log('MQTT MESSAGE:  ', vehiclesMessage);
      dispatch(fetchConsultantVehicles(consultant.id));
    }
    return emptyFun;
  }, [vehiclesMessage]);

  // fetch vehicles on first load
  useEffect(() => {
    dispatch(fetchConsultantVehicles(consultant.id));
    return emptyFun;
  }, []);
  

  const closeModal = () => {
    setVehicle(null)
    setVehicleForm(false)
  }
  const showPopconfirm = (vehicle) => {
    setSelected(vehicle.id)
  };

  async function deleteVehicleData(data) {
    let payload = { data: { consultant_id: consultant.id, vehicle_id: data.id } };
    dispatch(deleteVehicle(payload));
    // console.log('DELETE VEHICLE⚠️: ', payload);
  }

  const handleOk = async (vehicleId) => {
    let payload = { data: { consultant_id: consultant.id, vehicle_id: vehicleId } };
    await setConfirmLoading(true);
    await dispatch(deleteVehicle(payload))
    await setSelected(null)
    await setConfirmLoading(false);
  };
  const actionMenus = kebabMenus((can_update_consultant_vehicle), can_view_audit_logs, true)

  async function kebabMenuClick(e, item) {
    await setVehicle(item);
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setVehicleForm(true)
        break;
      case 'Delete':
        showPopconfirm(item)
        break;
      case 'Audit':
        await setShowAuditDialog(true);
        break
      default:
        await setVehicle(false);
    }
  }


  return (
    (<div className='shadow-card-box'>
      {(!!vehicle && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} resourceType={'Consultant'}
        onCancel={dialogCloser(setShowAuditDialog)} loading={false} resourceDescription={`${consultant.name}'s vehicle`}
        defaultValues={{ auditableId: vehicle?.id, auditable: 'consultant_vehicle', format: 'json' }} />}
      <Modal
        destroyOnClose={true}
        title={!!vehicleForm ? "Edit Vehicle" : "Add Vehicle"}
        open={vehicleForm}
        onCancel={closeModal}
        closeIcon={modalClose(closeModal)}
        maskClosable={false}
        footer={[
          <div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([
              outlinedButton(() => closeModal(), 'Cancel', isExecuting),
              formSubmitButton('consultantForm', 'Save', isExecuting, 'Saving')
            ])}
          </div>
        ]}>
        <ConsultantVehicle consultant={consultant} vehicle={vehicle} closeModal={closeModal} setisExecuting={setisExecuting} />
      </Modal>
      <div className='flex-boy-row-space' style={{ width: '100%' }}>
        <Headline6>Vehicle Details</Headline6>
        {(can_create_consultant_vehicle&&(can_update_consultant_global || consultant.user_id === activeUser.id)) && addButton(() => setVehicleForm(true))}
      </div>
      {vehicles.length > 0
        ? vehicles.map((item, index) => (
          <div key={index} style={{ width: '100%' }}>
            <div className='flex-boy-row-space' style={{ width: '100%' }}>
              <div>
                {boldKeyCapitalizedValue('Registration No:', item.registration_no)}
                {boldKeyCapitalizedValue('Make:', item.make)}
                {boldKeyCapitalizedValue('Model:', item.model)}
                {boldKeyCapitalizedValue('Year:', dayjs(item.year).format('YYYY'))}
                {boldKeyCapitalizedValue('Fuel Type:', item.fuel_type)}
              </div>
              <div style={{ marginBottom: '120px' }}>
                {can_update_consultant_vehicle &&
                  (
                    (<KebabMenu menus={actionMenus}
                      recordItem={item}
                      handleMenuClick={kebabMenuClick} resource={'vehicle'} handleOk={() => handleOk(item.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
                      showConfirmDialog={selected === item.id} />)

                    /*<Dropdown
                      dropdownRender={()=>menu(item)}
                      trigger={['click']}
                      disabled={selected === item.id}
                    >
                      {appConfirm(
                        <MoreOutlined />,
                        selected === item.id, null, 'Are you sure of deleting this Vehicle?', () => handleMenuClick(item.id), handleCancel, confirmLoading
                      )}
                    </Dropdown>*/
                  )}
              </div>
            </div>
          </div>
        ))
        : noVehicle('vehicle details')}
    </div>)
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser,
  consultant: state.consultants.selectedConsultant,
  appointments: state.consultants.consultantSchedule,
  vehicles: state.consultants.vehicles,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ConsultantVehicles);
