/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Subtitle1} from '../general';
import {logo} from '../../assets';
import {setAppointmentNavs, setConsultantNavs, setPatientNavs} from '../../app/Reducers';
import {emptyFun, IsItemEnabled} from '../../common/helpers';
import ReactGA from "react-ga4";
import {usePermissionCheck} from '../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';

const Menu = ({ activeUser, isConnected, chatClient, facility, settings }) => {
  const dispatch = useDispatch();
  const location = useLocation()
  const navigate = useNavigate();
  const can_view_settings = usePermissionCheck('view_settings', false, [])
  const can_view_map = usePermissionCheck('view_map', false, [])
  const can_view_appointment_listing = usePermissionCheck('view_appointment_listing', false, [])
  const can_view_treatment_order = usePermissionCheck('view_treatment_order', false, [])
  const can_view_consultant_listing = usePermissionCheck('view_consultant_listing', false, [])
  const can_view_task = usePermissionCheck('view_task', false, [])
  const can_view_billing_portal = usePermissionCheck('view_billing_portal', false, [])
  const menuList = [
    {
      name: 'map',
      title: 'Map',
      icon: 'map',
      visible: IsItemEnabled(settings, 'modules', 'Maps') && activeUser ? can_view_map : false
    },
    // {
    //   name: 'encounters',
    //   title: 'Encounter List',
    //   icon: 'handshake-o',
    //   visible: activeUser ? !activeUser?.facilityProfile : true,
    // },
    {
      name: `facility/${activeUser?.facilityProfile?.id}/stats`,
      title: 'Dashboard',
      icon: 'tachometer-alt',
      visible: activeUser ? activeUser?.facilityProfile : true
    },
    {
      name: 'patients',
      children: ['wounds'],
      title: 'Patients',
      icon: 'user-injured',
      visible: IsItemEnabled(settings, 'modules', 'Patients') && activeUser ? activeUser : false
    },
    {
      name: 'appointments',
      title: 'Appointments',
      icon: 'calendar-alt',
      visible: IsItemEnabled(settings, 'modules', 'Appointments') && can_view_appointment_listing
    },
    {
      name: 'billing',
      title: 'Billing',
      icon: 'file-invoice-dollar',
      visible: (IsItemEnabled(settings, 'modules', 'Billing') && !activeUser?.facilityProfile && can_view_billing_portal)
      // visible: activeUser ? !activeUser?.facilityProfile  : true
    },
    {
      name: 'orders',
      title: 'Orders',
      icon: 'boxes',
      visible: IsItemEnabled(settings, 'modules', 'Ordering') && !activeUser?.facilityProfile && can_view_treatment_order
    },
    {
      name: 'Tasks',
      title: 'Tasks',
      icon: 'list',
      visible: IsItemEnabled(settings, 'modules', 'Task') && !activeUser?.facilityProfile && can_view_task
    },
    {
      name: can_view_consultant_listing ? 'consultants' : 'departments',
      title: can_view_consultant_listing ? 'Consultants' : 'Departments',
      icon: 'user-friends',
      visible: IsItemEnabled(settings, 'modules', 'Consultants') && !activeUser?.facilityProfile
    },
    
    {
      name: 'facilities',
      title: 'Offices',
      icon: 'star-of-life',
      visible: IsItemEnabled(settings, 'modules', 'Facilities') && activeUser ? !activeUser?.facilityProfile : true
    },
    {
      name: 'stats',
      title: 'Stats',
      icon: "poll",
      visible: IsItemEnabled(settings, 'modules', 'Statistics') && activeUser ? !activeUser?.facilityProfile : true
    },
    {
      name: 'facilities/:id',
      title: 'Office',
      icon: 'star-of-life',
      visible: IsItemEnabled(settings, 'modules', 'Facilities') && activeUser ? activeUser?.facilityProfile : false
    },
    
    {
      name: 'chat',
      title: 'Chat',
      icon: 'comment-alt',
      // visible:false ,
      visible: IsItemEnabled(settings, 'integrations', 'agora_integration') && IsItemEnabled(settings, 'modules', 'Chats') && activeUser ? !activeUser?.facilityProfile && chatClient : false
    },
    {
      name: 'consultants/:id',
      title: 'My Profile',
      icon: 'user',
      visible: activeUser ? !activeUser?.facilityProfile && activeUser?.consultantProfile && !can_view_consultant_listing : false
    },
    {
      name: 'help',
      title: 'Help',
      icon: 'question-circle',
      visible: false
      // visible: activeUser ? !activeUser?.facilityProfile : false,
    },
    {
      name: 'settings',
      title: 'Settings',
      icon: 'cog',
      visible: activeUser
        ? can_view_settings && !activeUser?.facilityProfile : false
    },
    {
      name: 'roles',
      title: 'Roles',
      icon: 'lock',
      visible: activeUser ? can_view_settings && !activeUser?.facilityProfile && process.env.REACT_APP_CURRENT_ENV === 'staging' : false
    }
  ];
  const [activeMenu, setActive] = useState('map');
  const [isCollapsed, setCollapsed] = useState(true);
  useEffect(() => {
    menuList.map((item) => {
      if (location.pathname.includes(item.name)) {
        setActive(item.name);
      }
      return item;
    });
    return emptyFun;
  }, [location]);
  const setMenuState = (item) => {
    (async () => {
      console.log('setMenuState:  ', item);
      if (item === 'patients') {
        console.log('Qualified:  ', item);
        await dispatch(setPatientNavs({ patientsTab: 'Patients' }));
      }
      if (item === 'appointments') {
        console.log('Qualified:  ', item);
        await dispatch(setAppointmentNavs({ tab: 'Appointments' }));
      }
      if (item === 'consultants') {
        console.log('Qualified:  ', item);
        await dispatch(setConsultantNavs({ tab: 'Summary' }));
      }
      if (item === 'roles') {
        await dispatch(setConsultantNavs({ tab: 'Summary' }));
      }
      setActive(item);
      navigate(`/${item}`)
    })().catch(e => console.log(e));
  };
  return (
    <div
      id="main-menu"
      style={{ width: !isCollapsed ? '200px' : '60px' }}
      className={`flex-boy-column-space ${isCollapsed ? 'collapsed-menu' : 'non-collapsed-menu'} `}
    >
      <div className="flex-boy-column-space">
        <div
          className="flex-boy-row-space"
          style={{
            width: '100%',
            justifyContent: isCollapsed ? 'center' : 'space-between',
            marginBottom: '10px'
          }}
        >
          <div onClick={() => setCollapsed(!isCollapsed)} className="logo-div">
            <img
              style={{ width: '70%', position: 'relative', left: '14%' }}
              src={logo}
              alt="logo"
            />
          </div>
          {/* {isCollapsed ? <FontAwesomeIcon onClick={() => setCollapsed(true)} size="2x" color="gainsboro" icon={["fas", "caret-square-left"]} /> : <FontAwesomeIcon onClick={() => setCollapsed(false)} size="2x" color="gainsboro" icon={["fas", "caret-square-right"]} />} */}
        </div>
        
        {menuList
          .filter((item) => item.visible)
          .map((item) => {
            return (
              <div
                onClick={() => {
                  setMenuState(item.name)
                  // pageview tracker
                  ReactGA.send({ hitType: "pageview", page: "/" + item.name, title: item.title });
                }}
                key={item.name}
                style={{ width: isCollapsed ? 'fit-content' : '100%' }}
                className={`main-menu-item ${location.pathname.includes(item.name) ||
                location.pathname.includes(item.children) ? 'menu-active' : null}`}>
                <FontAwesomeIcon color="white" icon={['fas', item.icon]}/>
                {isCollapsed ? null : (<Subtitle1 className="mt-2" style={{ textAlign: 'left', flex: 1, paddingLeft: '10px', marginTop: "5px" }} color="white"> {item.title} </Subtitle1>)}
                <div></div>
              </div>
            );
          })}
      </div>
      <div className="end flex-boy-column-space"></div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser,
  isConnected: state.chat.isConnected,
  facility: state.facilities.selectedFacility,
  settings: state?.settings?.settings
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
