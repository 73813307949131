import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MD5 from "crypto-js/md5";
import dayjs from 'dayjs';
import { apiRequest } from '../../../../app/Apis';
import { appLog } from "../../../../common/helpers";


export const Rcopia = ({ activeUser }) => {
    const [time, settime] = useState(dayjs().utc().format('MMDDYYHHmmss'))
    useEffect(() => {

        LogSSOAttempt()
        return () => {

        }
    }, [])
    const param_string = `rcopia_portal_system_name=${activeUser.rcopia_vendor_and_practice.vendor_username}&rcopia_practice_user_name=${activeUser.rcopia_vendor_and_practice.practice_username}&rcopia_user_id=${activeUser.rcopia_username}&rcopia_user_external_id=${activeUser.id}&service=rcopia&action=login&startup_screen=message&limp_mode=y&time=${time}${activeUser.rcopia_vendor_and_practice.vendor_password}`

    const LogSSOAttempt = async () => {
        let payload = {
            "message": "SSO login attempt Notifications",
            "level_name": "INFO",
            "context": {
                "payload": param_string,
                "consultant": activeUser.name,
            }
        }
        const data = await apiRequest.post("/integrations/dr_first/sso_logs", payload).catch(err => console.log(err))
        if (data && data.data) {
            console.log("done")
        }
    }


    // const param_string = `rcopia_portal_system_name=${activeUser.rcopia_vendor_and_practice.vendor_username}&rcopia_practice_user_name=${activeUser.rcopia_vendor_and_practice.practice_username}&rcopia_user_id=${activeUser.rcopia_username}&rcopia_user_external_id=${activeUser.id}&service=rcopia&action=login&startup_screen=message&time=${time}${activeUser.rcopia_vendor_and_practice.vendor_password}`
    // console.log(time,param_string)
    return (
        <div className='rcopia-iframe flex-1 w-full' >
            {/* {time}{param_string} */}
            <iframe src={`${process.env.REACT_APP_DR_FIRST_URL}?rcopia_portal_system_name=${activeUser.rcopia_vendor_and_practice.vendor_username}&rcopia_practice_user_name=${activeUser.rcopia_vendor_and_practice.practice_username}&rcopia_user_id=${activeUser.rcopia_username}&rcopia_user_external_id=${activeUser.id}&service=rcopia&action=login&startup_screen=message&limp_mode=y&time=${time}&MAC=${MD5(param_string).toString().toUpperCase()}`} frameborder="0"></iframe>
        </div>
    )
}

const mapStateToProps = (state) => ({
    activeUser: state.auth?.activeUser,

})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Rcopia)
