import React, {useEffect, useState} from 'react';
import {Headline6} from "../../../../components";
import {Avatar, Col, Divider, Grid, Image, Row, Skeleton} from "antd";
import dayjs from "dayjs";
import {connect, useDispatch} from "react-redux";

import {fetchPatientById, fetchPatientInsurance, resetPatientModuleState, resetPatientNavs, selectPatient, setPatientDetailsLoading, updatePatientDetailsOwner} from "../../../../app/Reducers";
import {boldKeyCapitalizedValue, boldKeyCapitalizedValueGrayBackground, dialogCloser, emptyFun, getPatientStatus, secondaryButton, skelBars} from "../../../../common/helpers";
import AuditLogViewer from "../../../../components/AuditLogs/AuditLogViewer";
import {useSubscription} from "../../../../hooks/MqttHooks/useSubscription";
import {dismissToast, errMsg, getToastId, updateToast} from '../../../../app/Reducers/reducerUtils';
import {apiRequest} from '../../../../app/Apis';
import * as Sentry from '@sentry/react';
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';
import {UserOutlined} from '@ant-design/icons';
const { useBreakpoint } = Grid;
const AppointmentPatientInfoPane = ({ patient, setDetailsForm, navs, activeUser, insurances, appointment, loadingPatient, order,...props }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState=useBreakpoint()
  const [ploading, setpLoading] = useState(false);
  const dispatch = useDispatch()
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const patientId = patient?.patientId
  const { message } = useSubscription(`view_patient/${patientId}`, { qos: 2, nl: true });
  const can_view_patient_ssn = usePermissionCheck('view_patient_ssn', false, [])
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await dispatch(fetchPatientById(Number(patientId)))
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    (async () => {
      if (!patientId){
        return
      }
      await dispatch(fetchPatientInsurance(patientId));
      if (!patient || (patientId !== patient?.id)) {
        await dispatch(setPatientDetailsLoading(true))
        await dispatch(fetchPatientById(Number(patientId)))
        await dispatch(setPatientDetailsLoading(false))
        await dispatch(updatePatientDetailsOwner(patientId))
        await dispatch(fetchPatientInsurance(patientId));
      }
      console.log('PATIENT ID: ', patientId)
    })().catch(e => console.log(e))
    return emptyFun
  }, [patient])


  const fetchPatient = async (id) => {
    const toastId = getToastId('Getting patient details')
    setpLoading(true)
    try {
      const { data } = await apiRequest.get(`/patients/${id}`)
      if (data && data.data) {
        let patient = data.data
        if (patient.id !== navs.patientDetailsOwner) {
          await dispatch(resetPatientModuleState())
        }
        await dispatch(selectPatient({ record: patient }))
        await dispatch(resetPatientNavs())
        dismissToast(toastId)
        setpLoading(false)
        await dispatch(resetPatientModuleState())
        navigate(`/patients/${id}`)
      }
    } catch (e) {
      Sentry.captureException(e);
      setpLoading(false)
      console.log(e)
      updateToast(toastId, errMsg(e, true, 'patient info'), false)
    }
  }
  return (
    <div className="card-box min-h-full h-fit">
      {showAuditDialog && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)}
                                          loading={false} resourceDescription={patient?.name} resourceType={'Patient'}
                                          defaultValues={{ auditableId: patientId, auditable: 'patient', format: 'json' }}/>}
      <div className="flex-boy-col items-center" style={{ width: "100%" }}>
        <Avatar size={64}
                icon={<UserOutlined />}
                {...(!!patient&& { src: <Image src={patient?.profile_image_url || require("../../../../assets/user.png")}/>})}/>
        <div className="mb-2 w-full text-center flex flexy-row items-center justify-center mt-2">
          {loadingPatient ?
            <Skeleton  active/> :
            <p className={'text-[14px] font-semibold py-1 w-fit'}>{ patient?.name || 'No Patient Info'}</p>}
          {/*<Body2
            color="grey">{dayjs(patient?.birth_date).format("MM/DD/YYYY")}({dayjs().diff(dayjs(patient?.birth_date), 'years')}y.o.) {patient?.gender}</Body2>*/}
        </div>
        {!!patient&&<div className="flex flex-row justify-center w-full">
          {secondaryButton(() => fetchPatient(patient.id), 'View Full Card', ploading, 'Loading')}
        </div>}
      </div>
      <Divider/>
      {loadingPatient ? skelBars() : (!!patient||loadingPatient)?<Row gutter={[4, 4]} align={"stretch"} justify={"start"} style={{ width: '100%', margin: 0 }}>
        <Col className="text-left" xs={24} sm={24} md={24} lg={24} xl={24} flex={"auto"} style={{ width: '100%' }}>
          <div className=" my-2 flex flex-row justify-between w-full">
            <p className=" text-xs">Status</p>
            <p className=" text-xs">{getPatientStatus(patient?.status ? patient?.status : null)}</p>
          </div>
          <div className=" my-2 flex flex-row justify-between w-full">
            <p className=" text-xs">PST Time zone</p>
            <p className=" text-xs">{dayjs().format("HH:MM A")}</p>
          </div>
          <p className="my-1 text-blue-500">{patient?.phone_no} </p>
          <div className="my-4"></div>
          <p className="my-6  text-blue-500">{patient?.email}</p>
          <div className="my-4"></div>
          <p className="mb-4  text-xs">{patient?.address_1 ? patient?.address_1 + ',' : ''} {patient?.zip ? patient?.zip + ',' : ''} {patient?.city ? patient?.city + ',' : ''} {patient?.state}</p>
          <div className="my-4"></div>
          <p className="mb-4  text-xs">{patient?.address_2 || ''}</p>
          <div className="my-4"></div>
           <div className=" mt-2 flex flex-row justify-between w-full">
            {can_view_patient_ssn && <p className="mb-3 text-xs">SSN:{patient?.ssn || "N/A"}</p>}
             {/* {!activeUser.facilityProfile && <p className="mb-3 text-xs">Smartsheet ID: {patient?.smartsheet_id || "N/A"}</p>} */}
            <p className="mb-3 text-xs">Patient ID: {patient?.id || 'N/A'}</p>
          </div>
          <Divider/>
          <div className="my-4"></div>
          <p className="mb-3 text-xs">Office:</p>
          <p className=" mb-3 text-xs font-semibold">{patient?.medical_facility || "-"}</p>
          <div className="my-4 text-xs">Office Primary Contact:</div>
          <p className="mb-3 text-xs font-semibold">{patient?.additional_contacts?.facility_primary_contact}</p>

          <div className="my-4 text-xs">Office Phone Number:</div>
          <p className="mb-3 text-xs  text-blue-500 font-semibold">{patient?.additional_contacts?.facility_phone_no}</p>
          <Divider orientation={'left'} type={'horizontal'}></Divider>
          <p className="mb-3 text-xs">Visit Approval status:</p>
          <p className=" mb-3 text-xs font-semibold">{order?.appointment?.visit_approval_status || '-'}</p>
          <p className="mb-3 text-xs">Type of kit to order:</p>
          <p className=" mb-3 text-xs font-semibold">{order?.appointment?.type_of_kit || '-'}</p>

          {/* {boldKeyCapitalizedValue('State', patient?.state)} */}
          <Divider/>
          {
            insurances.filter(item=>item.priority==='primary insurance').map(item=>{
              return(
                <>
                  <div className="my-4"></div>
                  <p className="mb-3 text-md font-semibold w-full mx-auto text-center">Primary Insurance Information:</p>
                  {/*<p className="mb-3 font-semibold">Yes</p>*/}
                  <p className="mb-3 text-xs">Insurance Provider</p>
                  <p className="mb-3 font-semibold">{item?.insurance_provider || 'Unknown'}</p>
                  <p className="mb-3 text-xs">Plan Name</p>
                  <p className="mb-3 font-semibold">{item?.plan || 'Unknown'}</p>
                  <Divider/>
                </>
              )
            })
          }
          {/*<div className="my-4"></div>
          <p className="mb-3 text-xs">Primary Insurance</p>
          <p className="mb-3 font-semibold">{insurances?.[0]?.insurance_provider || 'Unknown'}</p>
          <p className="mb-3 text-xs">Plan Name</p>
          <p className="mb-3 font-semibold">{insurances?.[0]?.plan || 'Unknown'}</p>
          <Divider/>*/}
        </Col>
      </Row>:null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  wounds: state.patients.patientWounds,
  appConf: state.appConf,
  navs: state.navs,
  activeUser: state.auth?.activeUser,
  insurances: state.patients.insurances,
  order: state.orders.selectedOrder,

})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentPatientInfoPane);
