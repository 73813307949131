/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
// import { NewPatentCard } from './components/NewPatentCard';
import {AutoComplete, Form, Radio, Select} from 'antd';
import {createWounds, fetchConsultants, fetchPatients, fetchProcedures} from '../../../app/Reducers'
import {addButton, deleteButton, divInputRow, emptyFun, executeChunkFn, getSortedWoundLocations} from "../../../common/helpers";
import {apiRequest} from '../../../app/Apis';

import _ from "lodash"
import {useWoundLocationSearch} from '../../../hooks/useWoundLocationSearch';

const NewWound = ({ selectedAppointment, procedures, closeModal, setCreatingWound, masterListRaw }) => {
    const { Option } = Select;
    const { woundLocationOptions, debouncedWLocationSearch, woundLocationLoading } = useWoundLocationSearch( [])
    // wounds error
    const [woundsError, setwoundsError] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {

        dispatch(fetchPatients())
        dispatch(fetchConsultants())
        dispatch(fetchProcedures())
        return emptyFun
    }, [])
    useEffect(() => {
        fetchPatientWounds()

        return () => {

        }
    }, [selectedAppointment])

    const onFinish = async (values) => {
        console.log(values)
        // await setCreatingWound(true)
        let data = { ...values }
        let wounds = [ ...data.wounds ]

        wounds.map(item => {
            item.patient_id = selectedAppointment.patient.id
            item.appointment_id = selectedAppointment.id
            item.recommended_procedure = [item.recommended_procedure]
            if (item.wound_create === "use_existing") {
                item.id = item.existing_wound
            }
            return _.omit(item, ['wound_create', 'existing_wound'])
        })
      wounds = await wounds.filter(item => !selectedAppointment.wounds.some(wound => wound.id === item.id))
        
        data.wounds = wounds
       
        if (data.wounds.length === 0) {

            closeModal()
        } else {
            await executeChunkFn(dispatch, createWounds, data.wounds, setCreatingWound, closeModal)
        }

       
    };
    const [patientWounds, setpatientWounds] = useState([])

    const fetchPatientWounds = async () => {
        const wounds = await apiRequest.post(`/patient-established-visit/${selectedAppointment?.patient?.id}/wounds`)
        if (wounds && wounds.data.data) {
            await setpatientWounds(wounds.data.data)
        }
    }
    const [existingWounds, setExistingWounds] = useState([])
    const searchpreExistingWounds = (e, key) => {
        console.log({e, key})
        let wounds_ = [...existingWounds]
        let index = wounds_.findIndex(obj => obj.key === key);
        if (patientWounds.find(item => item.location === e)) {
            console.log("patient already has this wound!")
            let wound = patientWounds.find(item => item.location === e)
            if (index >= 0) {

                wounds_[index] = { name: ` ${wound?.side || ''} ${wound?.position || ""} ${wound?.location || ''}`, key: key, id: wound.id }
            } else {
                wounds_.push({ name: ` ${wound?.side || ''} ${wound?.position || ""} ${wound?.location || ''}`, key: key, id: wound.id })
            }
        } else {
            if (index >= 0) {
                wounds_.splice(index, 1)
            }
        }
        setExistingWounds(wounds_)
    }
// initial values
    const [initialValues, setInitialValues] = useState({ wounds: [''] })
    const [Newform] = Form.useForm()
    const onWoundOption = (e) => {
        if (e.target.value==='use_existing'){
            //No need to proceed creating wound
            closeModal()
        }
    }
    return (
        <Form
            name="new-wound"
            id="new-wound"
            form={Newform}
            labelCol={{ span: 16 }}
            layout="vertical"
            initialValues={{ ...initialValues }}
            onValuesChange={(changedValues, allValues) => {
                // console.log('changedValues:', changedValues)
                // console.log('allValues:', allValues)
                setInitialValues(allValues)
            }}
            onFinish={onFinish}>
            <Form.List lable="Wounds" name="wounds">
                {(fields, { add, remove }) => (<>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <div className={'w-full flex flex-row flex-wrap items-center '} key={key} align="center">
                            {divInputRow([
                                <Form.Item className={'flex-1 min-w-[160px]'}
                                           {...restField}
                                           name={[name, 'location']}

                                           fieldKey={[fieldKey, 'location']}
                                           rules={[{ required: true, message: 'Missing Wound location' }]}
                                           label={'Wound Location'}>
                                    <Select
                                        onSelect={e => searchpreExistingWounds(e, key)} options={woundLocationOptions}
                                        notFoundContent="Couldn't find any matches!"
                                        popupMatchSelectWidth={false}
                                        showSearch={true}
                                        onSearch={(value) => debouncedWLocationSearch(value)}
                                        placeholder="Search ..."/>
                                </Form.Item>,
                                <div className={'flex-1 flex flex-row gap-1 items-center'}>
                                    <Form.Item className={'flex-1 min-w-[160px]'}
                                               {...restField}
                                               name={[name, 'recommended_procedure']}
                                               fieldKey={[fieldKey, 'recommended_procedure']}
                                               rules={[{ required: true, message: 'Missing Procedure type' }]}
                                               label="Visit Type">
                                        <Select popupMatchSelectWidth={false}>
                                            {procedures.filter(item => item.name === "Wound Evaluation").map((item, index) => <Option key={index} value={item.name}>{item.name}</Option>)}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item className={'self-end'}
                                               {...restField}>
                                        {deleteButton(() => remove(name), 'gray')}
                                    </Form.Item>
                                </div>
                            ])}

                            <div style={{ width: "100%" }}>

                                {existingWounds.find(item => item.key === key) && (
                                    <>
                                        <Form.Item
                                            className={'two-rows-label'}
                                            label={`There exists a similar wound ${existingWounds.find(item => item.key === key).name}  for this patient. Do you wish to use that wound or continue creating a new one?`}
                                            {...restField}
                                            name={[name, 'wound_create']}
                                            labelCol={{ style: { width: "100%" } }}

                                            rules={[{ required: true, message: 'This field is required!' }]}
                                            wrapperCol={{ span: 24 }}
                                        >
                                            <Radio.Group >
                                                <Radio value="create_new">Create New</Radio>
                                                <Radio value="use_existing">Use Existing</Radio>
                                            </Radio.Group>
                                        </Form.Item>

                                        {initialValues.wounds[key]?.wound_create === "use_existing" && <Form.Item
                                            {...restField}
                                            name={[name, 'existing_wound']}
                                            labelCol={{ style: { width: "100%" } }}
                                            wrapperCol={{ span: 24 }}
                                            dependencies={[`${name}.wound_create`]}
                                            shouldUpdate={(prevValues, curValues) => prevValues !== curValues}
                                            rules={[({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (getFieldValue(`${name}.wound_create`) === "use_existing" && !value) {
                                                        return Promise.reject('Please select an existing wound.');
                                                    }
                                                    return Promise.resolve();
                                                }
                                            })]}

                                        >

                                            <Radio.Group>
                                                {existingWounds.map((item) => (
                                                    <Radio key={item.key} value={item.id}>
                                                        {item.name}
                                                    </Radio>
                                                ))}
                                            </Radio.Group>

                                        </Form.Item>}
                                    </>
                                )}

                            </div>


                        </div>))}
                    <Form.Item>
                        {addButton(() => add(), 'grey')}
                    </Form.Item>
                </>)}
            </Form.List>


        </Form>
    );
}
const mapStateToProps = (state) => ({
    patients: state.patients.patients, consultants: state.consultants.consultants,
    procedures: state.appointments.procedures, selectedAppointment: state.appointments.selectedAppointment, masterListRaw: state.masters.masterListRaw
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(NewWound)
