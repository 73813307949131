import React, { useEffect, useState } from 'react';
import { Headline3, Headline4, Headline6, Indicator, Subtitle1 } from "../../../components";
import { Avatar, Button, Col, DatePicker, Divider, Image, Row } from "antd";
import dayjs from "dayjs";
import { connect, useDispatch } from "react-redux";
import { apiRequest } from "../../../app/Apis";
import { emptyFun } from "../../../common/helpers";
import { fetchConsultantshedule } from "../../../app/Reducers";
import { useSubscription } from "../../../hooks/MqttHooks/useSubscription";
import { setTripConsultant } from "../../../app/Reducers/mapSlice";
import querystring from "querystring";
import { errMsg, toastUp } from "../../../app/Reducers/reducerUtils";
import * as Sentry from "@sentry/react";
import { RightOutlined } from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';

const Mileage = ({ consultant, appointments, setActiveTab }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate();
    const COLORS = ['#0088FE', '#00fea9', '#EF8100;', '#FFA500'];
    const [mileage, setmileage] = useState(0.0)
    const [pieData, setpieData] = useState([])
    const { message: mileageMessage } = useSubscription(`view_mileage_portal/${consultant.id}`, { qos: 2, nl: true });
    useEffect(() => {
        if (!!mileageMessage) {
            console.log('MQTT MESSAGE:  ', mileageMessage)
            fetchMilage().catch(e => console.log(e))
        }
        return emptyFun
    }, [mileageMessage])
    const { message: appointmentsMessage } = useSubscription(`view_consultant_appointments/${consultant.id}`, { qos: 2, nl: true });
    useEffect(() => {
        if (!!appointmentsMessage) {
            console.log('MQTT MESSAGE:  ', appointmentsMessage)
            dispatch(fetchConsultantshedule(consultant.id))
        }
        return emptyFun
    }, [appointmentsMessage])
    useEffect(() => {
        fetchMilage().catch(e => console.log(e))
        return emptyFun
    }, [consultant])
    const fetchMilage = async () => {
        try {
            // const response = await apiRequest.get(`/consultants/${consultant.id}/mileages`)
            let params = { 'date_range[start_at]': dayjs().subtract(2, 'd').format("YYYY-MM-DD"), 'date_range[end_at]': dayjs().add(1, 'd').format("YYYY-MM-DD"), consultant_id: consultant.id }
            const response = await apiRequest.get(`/consultant-trip-summaries?${querystring.stringify(params)}`)
            if (response && response.data) {
                let total = response.data.data.reduce((previousValue, currentValue) => previousValue + Number(currentValue.mileage), 0)
                // console.log({total})
                // console.log({total1: response.data.data})
                setmileage(total)
            }
        } catch (e) {
            Sentry.captureException(e);
            console.log(e)
            toastUp(errMsg(e, true, 'mileage'), false)
        }
    }

    const fetchMilageRange = async (e) => {
        try {
            console.log(e)
            let start_date = dayjs(e[0]).format("YYYY-MM-DD")
            let end_date = dayjs(e[1]).format("YYYY-MM-DD")
            // const response = await apiRequest.get(`/consultants/${consultant.id}/mileages?startDate=${start_date}&endDate=${end_date}`)
            let params = { 'date_range[start_at]': start_date, 'date_range[end_at]': end_date, consultant_id: consultant.id }
            const response = await apiRequest.get(`/consultant-trip-summaries?${querystring.stringify(params)}`)
            //console.log({ response })
            if (response && response.data) {
                let total = response.data.data.reduce((previousValue, currentValue) => previousValue + Number(currentValue.mileage), 0)
                // console.log({total})
                // console.log({total2: response.data.data})
                setmileage(total)
            }
        } catch (e) {
            Sentry.captureException(e);
            console.log(e)
            toastUp(errMsg(e, true, 'mileage'), false)
        }
    }
    const onPieEnter = () => {
    }
    return (
      
         
                    <div className="card-box" >
            <div className="flex-boy-column-space" style={{  width: '100%', justifyContent: 'flex-start', padding: 0 }}>
                <div className="flex w-full justify-between pr-4">
                    <Headline6 style={{ textAlign: 'left' }}>Mileage</Headline6>
                    <RightOutlined onClick={() => setActiveTab("Trips")} />
                </div>
                            {/* <Divider orientation="left"> <Typography variant="subtitle1" fontWeight={600}>Mileage: </Typography></Divider> */}
                            <div className='flex justify-between items-center w-full'>
                                {/* <div style={{ flexGrow: 1, alignItems: 'left' }}>
                                    <DatePicker.RangePicker style={{ margin: 0 }} defaultValue={[dayjs(), dayjs()]} onChange={fetchMilageRange} />
                                </div> */}
                    <div className="flex-boy-row" style={{ marginTop: 10, alignSelf: 'center' }}>
                        <Headline4>{!!mileage ? mileage?.toFixed(2) : 0.0}</Headline4>
                        <Headline4 color="grey">miles</Headline4>
                    </div>
                                <Button style={{ padding: 0, marginLeft: 10, marginRight: 10, borderWidth: 0 }} size={'large'}
                                    icon={<Avatar shape="square" size={'large'} onClick={() => {
                                        (async () => {
                                            await dispatch(setTripConsultant(consultant))
                                            navigate(`/routemap/${consultant.id}`);
                                        })().catch(e => console.log(e))
                                    }}
                                        src={<Image preview={false} src={require('../../../assets/view_trips.png')} />}
                                    />}>
                                </Button>
                            </div>
                          
                        </div>
                    </div>
              
       
    );
};

const mapStateToProps = (state) => ({ consultant: state.consultants.selectedConsultant, appointments: state.consultants.consultantSchedule })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Mileage)
