import { useState, useEffect } from "react";
import TinyTable from "./TinyTable";
import { Divider, Segmented, Spin } from "antd";
import { Headline6 } from "../../../components";
import { apiRequest } from "../../../app/Apis";
import { emptyFun } from "../../../common/helpers";
import { LeftOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ConsultantLocation({ region }) {
  const [option, setOption] = useState('false');
  const [consultants, setConsultants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [cityData, setCityData] = useState([])
  const [navParams, setNavParams] = useState(null);
  const defaultOptions = [
    { label: 'By Region', value: 'region' },
    { label: 'By State', value: 'state' }
  ];
  function onChangeLocation(option) {
    setStep(0)
    setOption(option)
  }

  async function fetchConsultants(option) {
    setLoading(true)
    let queryString = '';
    let optionString = option === "state" ? `?group_by=${"state"}` : ""
    if (!!region && region !== '' && region !== undefined) {
      let sign = option === "state" ? "&" : "?"
      queryString = `${sign}region=${region}`;
      console.log("QString", queryString)
    }

    const endpoint = `/consultant_stats/location${optionString}${queryString}`
    console.log("Endpoint", endpoint)
    // this will be used to filter by state or region, by default the endpoint brings by region
    const response = await apiRequest.get(`/consultant_stats/location${optionString}${queryString}`)
    const res = response.data.data
    let newRes = res.filter((obj) => obj.label !== null && obj.label !== " Region")
    setConsultants(newRes)
    setLoading(false)
  }

  async function fetchStateData(value) {
    setLoading(true)
    let queryString = option === 'state' ? `?state=${value}` :  `?region=${value}`   // this will be used to filter by state or region, by default the endpoint brings by region
    let groupBy = option === 'state' ? `&group_by=city` : "&group_by=sub_region"
    const response = await apiRequest.get(`/consultant_stats/location${queryString}${groupBy}`)
    const res = response.data.data
    let newRes = res.filter((obj) => obj.label !== null && obj.label !== " Region")
    setCityData(newRes)
    setLoading(false)
  }

  useEffect(() => {
    if (step === 1) {
      fetchStateData(navParams?.label)
    }
  }, [step])

  useEffect(() => {
    fetchConsultants("region")
    return emptyFun
  }, [])

  useEffect(() => {
    fetchConsultants(option)
    return emptyFun
  }, [option])

  useEffect(() => {
    fetchConsultants(option, region)
    return emptyFun
  }, [region])

  return (
    <div className="stats-card-box">
      {step === 0 ?
        <div style={{ width: '100%', padding: '5px' }}>
          <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>Consultants by location</Headline6>
          <Divider style={{ margin: '4px', color: '#E2E4EA', width: '100%' }} />
        </div> :
        <div className='flex-boy-row-space' style={{ width: '100%' }}>
          <div className="flex-boy-row" style={{ cursor: 'pointer' }}>
            <LeftOutlined style={{ marginRight: 30, marginTop: 10 }} onClick={() => setStep(0)} />
            <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>{navParams?.label || 'Unknown'}</Headline6>
          </div>
          <FontAwesomeIcon color="grey" icon={['fas', 'poll']} />
        </div>
      }
      <div className="">
        <div className="flex justify-start p-4">
          <Segmented options={defaultOptions} onChange={(value) => onChangeLocation(value)} />
        </div>
        {step === 0 ?
          <Spin spinning={loading}>
            <TinyTable patients={consultants} type="consultants" setStep={setStep} disabled={false} setNavParams={setNavParams} />
          </Spin>
          :
          <Spin spinning={loading}>
            <TinyTable patients={cityData} type="consultants" disabled={true} />
          </Spin>
        }
      </div>
    </div>
  )
}