import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Spin } from 'antd';

const SupportButton = () => {
  const [isChatOpen, setChatOpen] = useState(false);

  const handleClick = () => {
    setChatOpen(!isChatOpen);
    if (window && !isChatOpen) {
      window?.zE('messenger', 'show');
      window?.zE('messenger', 'open');
      console.log('open zendesk chat', window?.zE);
    } else {
      window?.zE('messenger', 'close');
      console.log('close zendesk chat', window?.zE);
    }

    window?.zE('messenger:on', 'close', function () {
      window?.zE('messenger', 'hide')
      document.querySelector("#myLauncher").style.opacity = 1
    })
  };

  return (
    <button
      id="myLauncher"
      onClick={handleClick}
      style={{ outline: 'none' }}
      className={`transform transition duration-200 ease-in-out rounded-lg p-2 bg-white
              ${!isChatOpen ? 'border-none' : 'border-[#EF8100] rounded-lg p-2 border-solid border-2'}
              ${isChatOpen ? 'scale-95' : 'scale-100'}`}
    >
      {isChatOpen ? (
        <div className='flex-boy-row-space' style={{ marginRight: 5 }}>
          <FontAwesomeIcon color={'#EF8100'} size='md' icon={['fas', 'headset']} />
          <span className='text-[#EF8100]'>Close Support Chat</span>
        </div>
      ) : (
        <div className='flex-boy-row-space' style={{ marginRight: 5 }}>
          <FontAwesomeIcon color='gray' size='md' icon={['fas', 'headset']} />
          <span style={{ color: 'gray' }}>Support</span>
        </div>
      )}
    </button>

  );
};

export default SupportButton;