/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Body2, Subtitle1 } from '../../../components';
import { connect, useDispatch } from 'react-redux'
import dayjs from 'dayjs'


import { selectConsultant } from '../../../app/Reducers';
import { Avatar, Divider, Image } from 'antd';
import { apiRequest } from '../../../app/Apis';
import { LoadingOutlined } from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';


const TimeBlockViewCard = ({  appointment }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate();

  const [specificAppointment, setspecificAppointment] = useState(null)
  useEffect(() => {
    fetchdata()

    return () => {

    }
  }, [appointment])

  const fetchdata = async () => {
    const response = await apiRequest.get(`/time-blocker/${appointment.id}`).catch(e => console.log(e))
    if (response?.data?.data) {
      setspecificAppointment(response?.data?.data)
    }
  }
  const [ploading, setpLoading] = useState(false);
  const [patient, setPatient] = useState()

  const goToConsultant = async () => {

    let consultant = appointment.consultant
    console.log(consultant)

    dispatch(selectConsultant({ record: consultant }))
    navigate(`/consultants/${consultant.id}`)

  }
  const [updatingStatus, setUpdatingStatus] = useState(false);
  return (<div className="flex-boy-column">
    {specificAppointment ? <>
      <div className="flex flex-row justify-between" style={{ marginBottom: "10px" }}>
        <div>
          <Subtitle1 color="black" style={{
            textAlign: 'left', flex: 1
          }}> {dayjs(specificAppointment?.start_at).format("DD MMM, YYYY")} / {specificAppointment.all_day ? "All day" : `${dayjs(specificAppointment?.start_at).format("hh:mm a")} - ${dayjs(specificAppointment?.end_at).format("hh:mm a")}`}</Subtitle1>
          <Subtitle1>{appointment.appointment_visit_type}</Subtitle1>
        </div>


      </div>

      <Divider />
      <div className="flex flex-row justify-between" style={{ marginBottom: "10px" }}>

        <Avatar src={<Image src={require("../../../assets/user.png")} />} />
        <div className="ml-4 flex-1 flex-col">
          <Subtitle1 style={{ textAlign: "left" }}>{specificAppointment?.consultant.name}</Subtitle1>
          <Body2 color="black">{specificAppointment?.consultant.address_1}</Body2>

          <Body2>{specificAppointment?.consultant.email}</Body2>
        </div>


        {/* <Body2 onClick={goToConsultant} className="cursor-pointer">View Provider</Body2> */}
      </div>
      <Divider />


    </> : <LoadingOutlined />}
  </div>);
}
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser,
  patientAppointments: state.patients.patientAppointments,
  procedures: state.appointments.procedures,
  navs: state.navs,
  loading: state.appointments.assessment_form_loading, woundEncounters: state.patients.woundEncounters
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(TimeBlockViewCard)
