import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import { useLocation, useNavigate} from "react-router-dom";
import {Subtitle1} from '../../../../components'
import {Grid, Modal, Popover, Table} from "antd"
import {deleteAllergy, fetchAllergies, resetAllergiesPagination, setAllergiesLoaded, updateAllergyFilters} from '../../../../app/Reducers';
import dayjs from 'dayjs';
import {
  addButton,
  containedButton,
  dialogCloser,
  emptyAllergies,
  emptyFun,
  emptyTable,
  formSubmitButton,
  GetRawListOptions,
  getSkels,
  horStack,
  kebabMenus,
  makeAutoCompleteOptions, makeSelectOptions,
  modalClose,
  onTableChange,
  outlinedButton,
  responsiveTbl
} from "../../../../common/helpers";

import {EditFilled, PlusOutlined} from "@ant-design/icons";
import NewAllergyForm from "./forms/AllergiesForm";
import KebabMenu from "../../../../components/KebabMenu";
import AuditLogViewer from "../../../../components/AuditLogs/AuditLogViewer";
import TableFilters from "../../../../components/InputComponents/TableFilters";
import {useSubscription} from "../../../../hooks/MqttHooks/useSubscription";
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
const { useBreakpoint } = Grid;
const AllergiesSection = ({ patient, allergies, appConf, navs, allergiesPagination, activeUser }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState=useBreakpoint()
  const [allergiesForm, setAllergiesForm] = useState(false);
  const [isExecuting, setisExecuting] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [editingForm, setEditingForm] = useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [allergy, setAllergy] = useState(null);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  const can_delete_patient_allergy = usePermissionCheck('delete_patient_allergy', false, [])
  const can_create_patient_allergy = usePermissionCheck('create_patient_allergy', false, [])
  const can_update_patient_allergy = usePermissionCheck('update_patient_allergy', false, [])
  const showPopconfirm = (allergy) => {
    setSelected(allergy.id);
  }
  const handleCancel = () => setSelected(null)
  const handleOk = async (id) => {
    await setConfirmLoading(true);
    let payload = { patient_id: patientId, allergy_id: id }
    await dispatch(deleteAllergy(payload))
    await setSelected(null)
    await setConfirmLoading(false);
  }
  
  const actionMenus = kebabMenus((can_update_patient_allergy && !patient.deleted_at), can_view_audit_logs, (can_delete_patient_allergy && !activeUser.facilityProfile))
  
  async function kebabMenuClick(e, allergy) {
    await setAllergy(allergy)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true)
        await setAllergiesForm(true)
        break
      case 'Delete':
        await setEditingForm(false)
        showPopconfirm(allergy)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
        await setEditingForm(false)
    }
  }
  
  /*  const menu = (item) => {
      console.log(item)
      return <Menu onClick={(e) => handleMenuClick(e, item)}>
        {editMenus.map((item, index) => <Menu.Item key={index} icon={item.icon}> {item.title}  </Menu.Item>)}
      </Menu>
    }*/
  // const allergies = useSelector(state => state.patients.healthData.allergies);
  // const patient = useSelector(state => state.patients.selectedPatient);
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const dispatch = useDispatch()
  const { message } = useSubscription(`view_patient_allergy/${patientId}`, { qos: 2, nl: true });
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await loadAllergies(true)
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    (async () => {
      await loadAllergies(false)
    })().catch(e => console.log(e))
    return emptyFun
  }, [])
  const loadAllergies = async (isMQMessage) => {
    /*if (!!allergies && !!allergies[0] && !isMQMessage && allergies.every(item => item.patient_id === patientId)) {
      return
    }*/
    await dispatch(updateAllergyFilters([appConf.allergyFilters[0], appConf.allergyFilters[1], !isMQMessage]))
    await dispatch(fetchAllergies(appConf.allergyFilters[0] ? appConf.allergyFilters[1] : patientId))
    await dispatch(updateAllergyFilters([appConf.allergyFilters[0], appConf.allergyFilters[1], false]))
    await dispatch(setAllergiesLoaded(patientId))
  }
  const columns = [
    //   {
    //   title: 'Allergy Type', dataIndex: 'allergy_type', key: 'allergy_type', fltr: { filterable: true, type: 'dropdown', options: makeDropDownOptions(["Drug Class", "Specific Drug", "None dry Allergy"]) }
    // },
    {
      title: 'Allergen', dataIndex: 'allergen', key: 'allergen', fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(["Pollen", "Dust", "Drug", "other"]) }
    },
    {
      title: ' Drug Allergen', dataIndex: ['drug', 'generic_name'], key: 'drug', fltr: { filterable: true, type: 'text' }
    },
    // {
    //   title: 'Drug Group', dataIndex: ['drug_group', 'name'], key: 'drug', fltr: { filterable: true, type: 'text' }
    // },
    {
      // title: <FontAwesomeIcon color="grey" icon={["fas", "table"]} />,
      title: 'Notes', key: 'notes', dataIndex: 'notes', fltr: { filterable: true, type: 'text' },ellipsis:true, className:'unbreakable-table-column',
      render: (notes) => {
        return <Popover content={<div className={'w-[200px]'}><p>{notes}</p></div>}>
          <p className={'cursor-pointer overflow-ellipsis overflow-clip'}>{notes}</p>
        </Popover>
      }
      //
      // width: 70
      // render: () => <a>action</a>,
    },
    {
      title: 'Reaction', dataIndex: 'reaction', key: 'reaction', fltr: { filterable: true, type: 'select', options: makeSelectOptions(GetRawListOptions('reaction')) }
    },
    
    {
      title: 'Severity', dataIndex: 'severity', key: 'severity', fltr: { filterable: true, type: 'select', options: makeSelectOptions(GetRawListOptions('severity')) }
    },
    {
      title: 'Date Created', dataIndex: 'created_at', key: 'date', fltr: { filterable: true, type: 'date' },
      render: (date) => (<>
        {dayjs(date).format('MM/DD/YY')}
      </>)
    }, {
      title: 'Status', dataIndex: 'status', key: 'status', fltr: { filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(["Active", "Inactive"]) },
      render: (status) => (<>
        {status === "active" || status === 'Active' ? <div className="status-container">
          <div className="dot-circle-active"></div>
          <Subtitle1>Active</Subtitle1>
        </div> : <div className="status-container">
          <div className="dot-circle-inactive"></div>
          <Subtitle1>Inactive</Subtitle1>
        </div>}
      </>)
    }, {
      title: <EditFilled style={{ color: 'gray' }}/>, fltr: { filterable: false },
      render: (record) => (
        <KebabMenu menus={actionMenus}
                   recordItem={record}
                   handleMenuClick={kebabMenuClick} resource={'allergy'} handleOk={() => handleOk(record.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
                   showConfirmDialog={selected === record.id}/>
      )
    }]
  
  async function handleFilterAction(action, values) {
    await dispatch(resetAllergiesPagination())
    if (action === 'filter') {
      console.log('FILTERS:  ', JSON.stringify({ ...values, toLoad: 'allergies', endpoint: '/allergies?patient=1' }));
      let pl = { filterData: { patient: patientId, ...values }, pagination: allergiesPagination }
      await dispatch(updateAllergyFilters([false, pl, true]))
      await dispatch(fetchAllergies(pl))
      await dispatch(updateAllergyFilters([true, pl, false]))
    }
    if (action === 'reset'/* && appConf.allergyFilters[0]*/) {
      await dispatch(updateAllergyFilters([appConf.allergyFilters[0], undefined, true]))
      await dispatch(fetchAllergies(patientId))
      await dispatch(updateAllergyFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  const addClick = () => {
    setEditingForm(false)
    setAllergiesForm(true)
  }
  return (
    (<div>
      {(!!allergy && showAuditDialog) &&
        <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} resourceType={'Patient'} loading={false} resourceDescription={`${patient.name}'s allergy info`}
                        defaultValues={{ auditableId: allergy?.id, auditable: 'allergy', format: 'json' }}/>}
      <Modal
        title={editingForm ? "Edit Allergy" : "Add Allergy"}
        open={allergiesForm}
        destroyOnClose={true}
        onOk={dialogCloser(setAllergiesForm)}
        onCancel={dialogCloser(setAllergiesForm)}
        closeIcon={modalClose(dialogCloser(setAllergiesForm))}
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(dialogCloser(setAllergiesForm), 'Cancel', isExecuting),
            formSubmitButton('allergiesForm', editingForm ? 'Update' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
        </div>]}>
        <NewAllergyForm editingForm={editingForm} allergy={allergy} setisExecuting={setisExecuting} closeModal={dialogCloser(setAllergiesForm)}/>
      </Modal>
      <div className="w-full">
        <Table
          title={() => (<TableFilters datasource={columns} setIsVisible={setIsVisible}
                                      isVisible={isVisible} filters={appConf.allergyFilters[1]}
                                      actionButton={(can_create_patient_allergy && !patient.deleted_at) ? addButton(addClick) : null}
                                      windowState={windowState}
                                      handleFilterAction={handleFilterAction}
                                      showClear={appConf.allergyFilters[0]} loading={appConf.allergyFilters[2]}/>)}
          loading={appConf.allergyFilters[2] || navs.patientDetailsLoading} size={'small'} {...responsiveTbl(windowState)}
          locale={{
            emptyText: !appConf.allergyFilters[0] ? emptyAllergies('allergies', (can_create_patient_allergy && !patient.deleted_at) ? containedButton(addClick, 'Add Allergy', false, null, {},
              { icon: <PlusOutlined /> }) : null) : emptyTable('allergies.')
          }}
          onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchAllergies, appConf.allergyFilters, updateAllergyFilters, { patient: patientId })}
          onRow={(record, rowIndex) => ({
            onClick: event => {
            } // click row
          })}
          {...getSkels((appConf.allergyFilters[2] || navs.patientDetailsLoading), columns, allergies)} pagination={allergiesPagination}
          scroll={{ x: 1000 }}/>
      </div>
    </div>)
  );
}
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser, allergies: state.patients.healthData.allergies,
  patient: state.patients.selectedPatient, appConf: state.appConf, navs: state.navs,
  allergiesPagination: state.patients.allergiesPagination
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(AllergiesSection)
