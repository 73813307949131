import React, {useCallback, useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Body2, Headline6, Subtitle1} from '../../../components';
import _ from 'lodash';
import moment from 'moment';
import {Avatar, Modal, Popconfirm, Tag} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import NewTask from './NewTask';
import {closeButton, deleteButton, dialogCloser, editButton, formSubmitButton, horStack, outlinedButton} from '../../../common/helpers';
import ViewTask from './ViewTask';
import {deletePatientTask, selectPatientTask, updatePatientTaskStatus} from '../../../app/Reducers';

const priorityColors = {
  low: '#AF78FF',
  normal: '#7785FF',
  high: 'crimson'
}

export const PatientTasksKanbanView = ({ tasks, selectedTask }) => {
  const dispatch = useDispatch();
  const handleDragEnd = async (result) => {
    const { source, destination, draggableId } = result;
    
    // Check if the destination is valid
    if (!destination) {
      return;
    }
    
    // Check if the source and destination columns are the same
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
    
    // Get the source and destination column data
    const sourceColumn = columns[source.droppableId];
    const destinationColumn = columns[destination.droppableId];
    
    // Remove the task from the source column
    const sourceTaskIds = Array.from(sourceColumn.taskIds);
    sourceTaskIds.splice(source.index, 1);
    
    // Insert the task into the destination column
    const destinationTaskIds = Array.from(destinationColumn.taskIds);
    destinationTaskIds.splice(destination.index, 0, parseInt(draggableId));
    
    // Update the column data with the new taskIds
    const newColumnData = {
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        taskIds: sourceTaskIds
      },
      [destination.droppableId]: {
        ...destinationColumn,
        taskIds: destinationTaskIds
      }
    };
    setcolumns(newColumnData);
    
    // Update the task's status in the taskData state
    
    let params = {
      id: parseInt(draggableId),
      payload: {
        status: destination.droppableId
      }
    }
    await dispatch(updatePatientTaskStatus(params))
    
    
    // const updatedTaskData = taskData.map((task) =>
    //     task?.id === parseInt(draggableId)
    //         ? { ...task, status: destination.droppableId }
    //         : task
    // );
    // setTaskData(updatedTaskData);
  };
  
  const [columns, setcolumns] = useState({
    assigned: {
      id: "assigned",
      title: "Assigned",
      taskIds: []
    },
    "in_progress": {
      id: "in_progress",
      title: "In Progress",
      taskIds: []
    },
    "in_review": {
      id: "in_review",
      title: "In Review",
      taskIds: []
    },
    done: {
      id: "done",
      title: "Done",
      taskIds: []
    }
  })
  
  
  const [taskColumns, setTaskColumns] = useState({});
  
  const generate_columns = useCallback(() => {
    let columns_ = { ...columns };
    let taskColumns_ = { ...taskColumns }; // Create a copy of the current task columns state
    
    _.uniqBy(tasks, 'id').forEach((task) => {
      const currentStatus = task?.status;
      
      // If the task has a previous status, remove it from its old column
      if (taskColumns_[task.id]) {
        const oldColumn = columns_[taskColumns_[task.id]];
        const taskIndex = oldColumn.taskIds.indexOf(task?.id);
        if (taskIndex !== -1) {
          oldColumn.taskIds.splice(taskIndex, 1);
        }
      }
      
      // Add the task to its current status column
      const currentStatusColumn = columns_[currentStatus];
      if (currentStatusColumn) {
        currentStatusColumn.taskIds.push(task?.id);
      }
      
      // Update the task's current status in the task columns state
      taskColumns_[task.id] = currentStatus;
    });
    
    setcolumns(columns_);
    setTaskColumns(taskColumns_); // Update the task columns state
  }, [tasks]);
  
  useEffect(() => {
    if (tasks && tasks.length > 0) {
      generate_columns();
    }
  }, [tasks, generate_columns]);
  
  const Column = ({ id, title, tasks }) => {
    return (
      
      <Droppable droppableId={id}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="space-y-2 "
            style={{ minHeight: "380px" }}
          >
            {_.uniqBy(tasks, 'id').map((task, index) => (
              task &&
              <Task
                key={task?.id}
                id={task?.id}
                content={task?.description}
                priority={task?.priority}
                index={index}
                task={task}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    
    );
  };
  // new task modal
  const [newTaskModal, setNewTaskModal] = useState(false)
  
  const newTaskModalOpen = () => {
    setNewTaskModal(true)
  }
  const newTaskModalClose = () => {
    setNewTaskModal(false)
  }
  // is executing
  const [isExecuting, setIsExecuting] = useState(false)
  
  // viw task modal
  const [viewTaskModal, setViewTaskModal] = useState(false)
  
  const viewTaskModalOpen = async (task) => {
    await dispatch(selectPatientTask(task))
    setViewTaskModal(true)
  }
  const viewTaskModalClose = () => {
    setViewTaskModal(false)
  }
  
  // editing form
  const [editingForm, setEditingForm] = useState(true)
  
  const Task = ({ id, content, priority, index, task }) => {
    return (
      <>
        
        
        <Draggable draggableId={id.toString()} index={index}>
          {(provided) => (
            <div
              onClick={() => viewTaskModalOpen(task)}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={`p-4 bg-white shadow rounded mb-2 border-l-4`}
            >
              
              <div className="flex justify-between my-2">
                <Body2 color={priorityColors[priority]}>Due {moment(task?.due_date).format("DD MMM, YYYY")} </Body2>
                <Tag color={priorityColors[priority]}>{priority}</Tag>
              </div>
              <Subtitle1 style={{ textAlign: 'left' }}>{content}</Subtitle1>
              
              <div className="flex justify-between  my-2">
                <div className="flex flex-row items-center">
                  <div className="rounded-full flex justify-center items-center p-2 bg-gray-200 mr-1">
                    <FontAwesomeIcon size="xs" color="grey" icon={['fas', 'user-friends']}/>
                  </div>
                  <Body2 color="black">{task?.department?.name}</Body2>
                </div>
                <div className="flex flex-row items-center">
                  
                  <Body2 className="mr-2" color="black">{task?.assignee.name}</Body2>
                  <Avatar className="ml-2" size="small" src={task?.assignee.profile_image_url}>{task?.assignee.name?.charAt(0)} </Avatar>
                </div>
              </div>
              <p className="text-sm text-left my-4" style={{ color: priorityColors[priority] }}>ID :{task.id}</p>
              {task.completed_at && <Body2 color="black" className="mr-4 my-4 font-semibold text-left  ">Completed: {moment(task.completed_at).format("DD MMM, YYYY")}</Body2>}
            
            </div>
          )}
        </Draggable>
      </>
    );
  };
  
  
  const removeTask = async (id, cb) => {
    await dispatch(deletePatientTask({ id }))
    cb()
  }
  
  
  return (
    <div className="w-full min-h-full">
      
      {/*  new taksk modal */}
      <Modal
        title="New Task"
        open={newTaskModal}
        onCancel={newTaskModalClose}
        
        width={600}
        destroyOnClose={true}
        maskClosable={false}
        centered={true}
        className="new-task-modal"
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(dialogCloser(setNewTaskModal), 'Cancel', isExecuting),
            formSubmitButton('taskForm', 'Update', isExecuting, 'updating')])}
        </div>]}
      >
        <NewTask closeModal={newTaskModalClose} editingForm={editingForm} isExecuting={isExecuting} setIsExecuting={setIsExecuting}/>
      </Modal>
      {/* view task modal */}
      <Modal
        closable={false}
        title={<div className="flex flex-1" style={{ justifyContent: 'space-between' }}>
          <Headline6>View Task</Headline6>
          <div className="flex flex-1 flex-row items-center " style={{ justifyContent: 'flex-end' }}>
            
            <Popconfirm
              placement="top"
              title="Are you sure to delete this task?"
              description=""
              onConfirm={() => {
                removeTask(selectedTask?.id, viewTaskModalClose)
              }}
              okText="Yes"
              cancelText="No"
            >
              {deleteButton('Delete', isExecuting, () => {
              }, 'Delete Task')}
            </Popconfirm>
            {editButton(() => {
              
              newTaskModalOpen()
            })}
            {closeButton(() => {
              viewTaskModalClose()
            })}
          </div>
        
        
        </div>}
        
        open={viewTaskModal}
        onCancel={viewTaskModalClose}
        onOk={viewTaskModalClose}
        width={1000}
        destroyOnClose={true}
        maskClosable={false}
        centered={true}
        className="view-task-modal"
      
      >
        <ViewTask/>
      </Modal>
      <div className="p-4 min-h-full">
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className="flex space-x-4">
            {Object.values(columns).map((column) => (
              <div style={{ background: 'whitesmoke', minHeight: " 400px" }} className="rounded-lg flex-1  border border-gray-300 h-full">
                <div className="w-full rounded-t-lg p-2 bg-white">
                  <Subtitle1 style={{ textAlign: 'left' }}>{column.title}</Subtitle1>
                </div>
                <div className="p-2 w-full" style={{ minHeight: "380px" }}>
                  <Column
                    key={column.id}
                    id={column.id}
                    title={column.title}
                    tasks={column.taskIds.map((taskId) => tasks.find((task) => task?.id === taskId))}
                  />
                </div>
              
              </div>
            ))}
          </div>
        </DragDropContext>
      </div>
    
    </div>
  )
}

const mapStateToProps = (state) => ({
  tasks: state.patients.tasks,
  selectedTask: state.patients.selectedTask
})

const mapDispatchToProps = {}


export default connect(mapStateToProps, mapDispatchToProps)(PatientTasksKanbanView)