import React, {useEffect, useState} from 'react';
import {Grid, Table} from "antd";
import {connect, useDispatch} from "react-redux";
import {createInsuranceProvider, deleteInsuranceProvider, editInsuranceProvider, fetchWPRegions, fetchWPSubRegions, resetWpSubRegionsPagination} from "../../../app/Reducers";
import {updateWpSubRegionsFilters} from "../../../app/Reducers/ConfigSlice";
import TableFilters from "../../../components/InputComponents/TableFilters";
import {createFormInputsFromTableColumns, dialogCloser, emptyFun, emptyTable, executeChunkFn, getSkels, kebabMenus, makeSelectOptions, onTableChange, responsiveTbl} from "../../../common/helpers";
import * as Sentry from "@sentry/react";
import {errMsg, toastUp} from "../../../app/Reducers/reducerUtils";
import DynamicModalForm from "../../../components/DynamicModalForm";
const { useBreakpoint } = Grid;
const WPSubRegions = ({appConf, wpSubRegionsPagination, wpSubRegions, wpRegions}) => {
  const dispatch = useDispatch();
  const windowState=useBreakpoint()
  const dateFormat = "YYYY-MM-DD";
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [auditItem, setAuditItem] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [selected, setSelected] = useState(null)
  const [isExecuting, setisExecuting] = useState(false)
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  const [regionOptions, setRegionOptions] = useState(wpRegions.map(item => item.name));
  const [stateOptions, setStateOptions] = useState([]);
  useEffect(() => {
    dispatch(fetchWPRegions());
    dispatch(fetchWPSubRegions());
    return emptyFun
  }, []);
  const columns = [
    {
      title: 'Name', dataIndex: 'name', key: 'name', fltr: {filterable: true, type: 'text'}
    },
    {
      title: 'Region', dataIndex: ['region_boundary', 'name'], key: 'region', fltr: {filterable: true, type: 'select', options: makeSelectOptions(regionOptions)}
    }
  ]
  let providerFormInputs = createFormInputsFromTableColumns(false, columns, null, null, null, null, dateFormat)
  //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
  let dynamicFormInputFields = {
    'Edit WP region': providerFormInputs, 'Add WP region': providerFormInputs
  }
  
  const actionMenus = kebabMenus(true, false, true)
  const showPopconfirm = (id) => setSelected(id);
  
  async function kebabMenuClick(e, record) {
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await openProviderForm(record)
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        break
      default:
    }
  }
  
  const handleOk = async (record) => {
    await setConfirmLoading(true)
    await dispatch(deleteInsuranceProvider(record.id))
    await setConfirmLoading(false)
    await setSelected(0)
  }
  
  const handleCancel = () => setSelected(0);
  
  async function handleFilterAction(action, values) {
    await dispatch(resetWpSubRegionsPagination())
    if (action === 'filter') {
      let pl = {filterData: {...values}, pagination: wpSubRegionsPagination}
      await dispatch(updateWpSubRegionsFilters([false, pl, true]))
      await dispatch(fetchWPSubRegions(pl))
      await dispatch(updateWpSubRegionsFilters([true, pl, false]))
    }
    if (action === 'reset') {
      await dispatch(updateWpSubRegionsFilters([appConf.wpSubRegionsFilters[0], undefined, true]))
      await dispatch(fetchWPSubRegions())
      await dispatch(updateWpSubRegionsFilters([false, undefined, false]))
    }
    setIsVisible(false)
  }
  
  
  const openProviderForm = async (record) => {
    await setFormName(!!record ? 'Edit WP region' : 'Add WP region');
    await setDefaultDynamicModalFormValues(!!record ? {...record} : undefined);
    await setDynamicFormOpen(true);
  };
  
  /**
   * This function is handling returned form entries from the dynamic form
   * */
  const onDynamicFormEntriesResult = async (entries) => {
    
    try {
      switch (formName) {
        case 'Add WP region':
          await executeChunkFn(dispatch, createInsuranceProvider, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen), null)
          break;
        case 'Edit WP region':
          await executeChunkFn(dispatch, editInsuranceProvider, entries.values, setisExecuting, dialogCloser(setDynamicFormOpen), null)
          break;
        default:
          return
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      toastUp(errMsg(e, false, formName), false);
    }
  };
  return (
    <div className="w-full">
      {(isDynamicFormOpen && !!formName) && (
        <DynamicModalForm
          setDynamicFormOpen={setDynamicFormOpen}
          isDynamicFormOpen={isDynamicFormOpen}
          inputFields={dynamicFormInputFields[formName]}
          onDynamicFormEntriesResult={onDynamicFormEntriesResult}
          closeModal={dialogCloser(setDynamicFormOpen)}
          isExecuting={isExecuting}
          defaultValues={defaultDynamicModalFormValues}
          formName={formName}
        />
      )}
      <Table
        /*title={() => (<TableFilters datasource={columns} setIsVisible={setIsVisible} isVisible={isVisible}
                                    handleFilterAction={handleFilterAction} filters={appConf.wpSubRegionsFilters[1]}
                                    showClear={appConf.wpSubRegionsFilters[0]} loading={appConf.wpSubRegionsFilters[2]}
          // actionButton={containedButton(() => openProviderForm(), 'Add WP region', false, null, null, {icon: <PlusOutlined />})}
        />)}*/
        loading={appConf.wpSubRegionsFilters[2]} size={'small'} {...responsiveTbl(windowState)} locale={{emptyText: emptyTable('regions')}}
        onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchWPSubRegions, appConf.wpSubRegionsFilters, updateWpSubRegionsFilters, null)}
        onRow={(record) => ({
          onClick: async (event) => {
            await setAuditItem(record)
            await setShowAuditDialog(true)
          }
        })}
        {...getSkels((appConf.wpSubRegionsFilters[2]), columns, wpSubRegions)} pagination={wpSubRegionsPagination}
      />
    </div>
  
  );
};


const mapStateToProps = (state) => ({

  activeUser: state.auth?.activeUser,
  wpSubRegions: state.masters.wpSubRegions,
  wpSubRegionsPagination: state.masters.wpSubRegionsPagination,
  appConf: state.appConf,
  wpRegions: state.masters.wpRegions
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(WPSubRegions);
