/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Headline6} from '../../../components'
import {Modal} from "antd"
import {
  deleteProgressNote,
  deleteStickyNote,
  fetchPatientById,
  fetchPatientNotes,
  fetchStickyNotes,
  resetNotesPagination,
  setPatientDetailsLoading,
  updateNotesFilters,
  updatePatientDetailsOwner
} from "../../../app/Reducers";
import {addButton, dialogCloser, editButton, emptyFun, emptyTable, formSubmitButton, horStack, kebabMenus, modalClose, outlinedButton} from "../../../common/helpers";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import NewStickyNote from './NewStickyNote'
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import KebabMenu from '../../../components/KebabMenu';
import {useLocation, useNavigate} from 'react-router-dom';

 const StickiyNotes = ({ notes, patient, appConf, navs, notesPagination, appWindow, activeUser }) => {
   const navigate=useNavigate()
   const location =useLocation()
  const dispatch = useDispatch()
  const [noteForm, setSickyNoteForm] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [selected, setSelected] = useState(null);
  const [editingForm, setEditingForm] = useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [note, setNote] = useState(null);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const showPopconfirm = (note) => setSelected(note.id)
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient_resource = usePermissionCheck('update_patient_resource', false, [])
  const handleCancel = () => setSelected(null)
  const handleOk = async (id) => {
    await setConfirmLoading(true);
    let payload = { patient_id: patientId, note_id: id }
    await dispatch(deleteStickyNote(payload))
    await setSelected(null)
    await setConfirmLoading(false);
  }
  const actionMenus = kebabMenus((can_update_patient_resource && !patient.deleted_at), can_view_audit_logs, true)
  
  async function kebabMenuClick(e, note) {
    await setNote(note)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditingForm(true)
        await setSickyNoteForm(true)
        break
      case 'Delete':
        await setEditingForm(false)
        showPopconfirm(note)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
        await setEditingForm(false)
    }
  }
  
  /* const menu = (item) => {
     console.log(item)
     return <Menu onClick={(e) => handleMenuClick(e, item)}>
       {editMenus.map((item, index) => <Menu.Item key={index} icon={item.icon}> {item.title}  </Menu.Item>)}
     </Menu>
   }*/
  
  const { message } = useSubscription(`view_patient_sticky_notes/${patientId}`, { qos: 2, nl: true });
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await loadInternalNotes(true)
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  
  useEffect(() => {
    (async () => {
      await loadInternalNotes(false)
    })().catch(e => console.log(e))
    return emptyFun
  }, [])
  const loadInternalNotes = async (isMQ) => {
    await dispatch(updateNotesFilters([appConf.notesFilters[0], appConf.notesFilters[1], !isMQ]))
    //In case of back navigations
    if (!patient || (patientId !== patient.id)) {
      await dispatch(setPatientDetailsLoading(!isMQ))
      await dispatch(fetchPatientById(Number(patientId)))
      await dispatch(setPatientDetailsLoading(false))
      await dispatch(updatePatientDetailsOwner(patientId))
    }
    await dispatch(fetchStickyNotes(appConf.notesFilters[0] ? appConf.notesFilters[1] : { id: patientId }))
    await dispatch(updateNotesFilters([appConf.notesFilters[0], appConf.notesFilters[1], false]))
  }
  const openNoteForm = () => {
    setNote(notes?.[0])
    notes?.[0] && setEditingForm(true)
    setSickyNoteForm(true);
  }
  return (
    (<div className="card-box ">
      {(!!note && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false} resourceDescription={`${patient.name}'s internal notes`}
                                                      resourceType={'Patient'}
                                                      defaultValues={{ auditableId: note?.id, auditable: 'progress_note', format: 'json' }}/>}
      <Modal
        title={editingForm ? "Edit Note" : "Add Note"}
        open={noteForm}
        onOk={dialogCloser(setSickyNoteForm)}
        destroyOnClose
        onCancel={dialogCloser(setSickyNoteForm)}
        closeIcon={modalClose(dialogCloser(setSickyNoteForm))}
        maskClosable={false}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setSickyNoteForm), 'Cancel', isSubmitting),
            formSubmitButton('new-sticky-note', editingForm ? 'Update' : 'Add', isSubmitting, editingForm ? 'Updating' : 'Adding')])}
        </div>]}>
        <NewStickyNote note={note} editingForm={editingForm} setIsSubmitting={setIsSubmitting} closeModal={dialogCloser(setSickyNoteForm)}/>
      </Modal>
      <div className="flex flex-row justify-between items-center w-full">
        <Headline6>Sticky Notes</Headline6>
        {can_update_patient_resource && notes?.[0] ? null : addButton(openNoteForm)}
      
      </div>
      {notes && notes.length ?
        notes.map(item => {
          return (<div className="rounded  p-4 w-full flex flex-row justify-between max-h-[300px] bg-[#ffedcc] overflow-y-scroll h-auto" >
            <p className="text-left whitespace-pre-wrap">{item.note}</p>
            <div ><KebabMenu menus={actionMenus}
                                                                                   recordItem={item}
                                                                                   handleMenuClick={kebabMenuClick} resource={'sticky note'} handleOk={() => handleOk(item.id)} handleCancel={handleCancel} confirmLoading={confirmLoading}
                                                                                   showConfirmDialog={selected === item.id}/></div>
          </div>)
        }) : <div className="flex  mx-auto justify-center" style={{ height: "180px", width: '180px', marginBottom: '25px' }}> {emptyTable()} </div>}
    </div>)
  );
}

const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser,
  notes: state.patients.patientStickyNotes,
  patient: state.patients.selectedPatient,
  appConf: state.appConf,
  navs: state.navs,
  notesPagination: state.patients.notesPagination,
  appWindow: state.appWindow
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(StickiyNotes)