import React, {useEffect} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Button, Form, Input, Upload} from 'antd'
import {emptyFun} from "../../../common/helpers";
import {UploadOutlined} from '@ant-design/icons';
import {fetchProvider} from '../../../app/Reducers'
import {apiRequest} from '../../../app/Apis/index';
import {toast} from 'react-toastify'
import GetCookie from '../../../hooks/GetCookie';


export const ProviderDocument = ({handleShippingCancel, provider}) => {
  const [form] = Form.useForm();
  // const [isSubmitting, setSubmitting] = useState(false);
  const token = GetCookie('accessToken')
  const uploadUrl = process.env.REACT_APP_ENDPOINT + '/files'
  useEffect(() => {
    return emptyFun
  }, [])
  
  
  const dispatch = useDispatch()
  
  const createFaceSheet = async (payload) => {
    const toastId = toast("Adding Documents", {type: "info", autoClose: true});
    try {
      await apiRequest.post(`/documents`, payload)
      toast.update(toastId, {render: "Document added successfully", type: toast.TYPE.SUCCESS, autoClose: 5000});
      await dispatch(fetchProvider(provider.id))
      form.resetFields();
    } catch (error) {
      console.log(error)
      toast.update(toastId, {render: "Request unsuccesful", type: toast.TYPE.ERROR, autoClose: 5000});
    }
  }
  const onFacesheetChange = ({fileList: newFileList}) => {
    newFileList = newFileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
        form.setFieldsValue({image_id: file.response.data.id})
      }
      return file;
    });
  };
  const onFinish = async (values) => {
    // await setSubmitting(true)
    await createFaceSheet(values)
    // await setSubmitting(false)
  }
  
  return (<Form
    name="new-patient"
    form={form}
    id="providerForm"
    preserve={false}
    labelCol={{
      span: 16
    }}
    layout="vertical"
    onFinish={onFinish}
  
  >
    <Form.Item
      style={{width: '100%'}}
      name="image_id"
      label="Document">
      <Upload
        action={uploadUrl}
        headers={{'Authorization': `Bearer ${token}`}}
        listType="picture"
        onChange={onFacesheetChange}
        maxCount={1}>
        <Button icon={<UploadOutlined/>}>Upload</Button>
      </Upload>
      <Input autoComplete={'off'} style={{width: '100%', display: 'none'}}/>
    </Form.Item>
  </Form>)
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ProviderDocument)
