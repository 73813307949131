import React, {useEffect, useState} from 'react';
import {Body2, Headline6, Subtitle1} from "../../../components";
import {Avatar, Button, Col, Divider, Dropdown, Form, Grid, Image, Input, Menu, Modal, Row, Typography} from "antd";
import dayjs from "dayjs";
import {connect, useDispatch, useSelector} from "react-redux";

import {fetchPatientById, fetchPatientInsurance, fetchPatients, selectAdmission, setPatientDetailsLoading, updatePatientDetails, updatePatientDetailsOwner} from "../../../app/Reducers";
import {
  appMessage,
  capitalizeFirstLetter,
  dialogCloser,
  emptyFun,
  executeChunkFn,
  formSubmitButton,
  getAdmissionStatus,
  getPatientIndicator,
  getPatientStatus,
  horStack,
  kebabMenus,
  modalClose,
  outlinedButton,
  secondaryButton,
  skelBars
} from "../../../common/helpers";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import KebabMenu from "../../../components/KebabMenu";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import {CaretDownOutlined} from '@ant-design/icons';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import NewAdmission from '../../Appointments/NewAdmission';
import * as Sentry from '@sentry/react';
import {errMsg, toastUp} from '../../../app/Reducers/reducerUtils';
import DynamicModalForm from '../../../components/DynamicModalForm';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

const { useBreakpoint } = Grid;

function PatientInfoPane({ patient, setDetailsForm, navs, activeUser, insurances, ...props }) {
  const location = useLocation()
  const navigate = useNavigate();
  // const {id:patientId} = useParams();
  // console.log({params,location})
  const approval_statuses = useSelector(state => state?.masters?.masterListRaw.find(item => item.system_name === 'approval_status')?.options)
  
  const dispatch = useDispatch()
  const [popReason, setPopReason] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [form] = Form.useForm();
  let stati = useSelector(state => state.masters?.masterListRaw.find(item => item.system_name === 'patient_status')?.options)
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const { message } = useSubscription(`view_patient/${patientId}`, { qos: 2, nl: true });
  
  const [actionMenuRecord, setActionMenuRecord] = useState(null);
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  
  const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient = usePermissionCheck('update_patient', false, [])
  const can_view_admission = usePermissionCheck('view_admission', false, [])
  const can_create_admission = usePermissionCheck('assign_consultant', false, [])
  const can_reactivate_deceased_patient = usePermissionCheck('reactivate_deceased_patient', false, [])
  const can_view_patient_ssn = usePermissionCheck('view_patient_ssn', false, [])
  
  
  //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
  let dynamicFormInputFields = {
    'Change approval status': {
      fields: [
        { name: 'reason_approval_status_changed', label: 'Status change reason', required: true, type: 'textarea', fullRow: true }
      ],
      defaultValues: {}
    }
  }
  
  useEffect(() => {
    console.log('MQTT MESSAGE PatientInfoPane:  ', message)
    if (!!message) {
      (async () => {
        await dispatch(fetchPatientById(Number(patientId)))
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    (async () => {
      await dispatch(fetchPatientInsurance(patientId));
      
      if (!patient || (patientId !== patient?.id)) {
        await dispatch(setPatientDetailsLoading(true))
        await dispatch(fetchPatientById(Number(patientId)))
        await dispatch(setPatientDetailsLoading(false))
        await dispatch(updatePatientDetailsOwner(patientId))
        await dispatch(fetchPatientInsurance(patientId));
      }
    })().catch(e => console.log(e))
    return emptyFun
  }, [patient])
  
  
  const actionMenus = kebabMenus((can_update_patient && !patient?.deleted_at), can_view_audit_logs, false)
  
  async function kebabMenuClick(e) {
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setDetailsForm(true);
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      case 'Delete':
        appMessage('Operation not allowed', 'error')
        break
      default:
        await setShowAuditDialog(false);
        await setDetailsForm(false);
    }
  }
  
  const menuItems = can_reactivate_deceased_patient && patient?.status === 'deseased' ?
    stati?.filter(item => item === "active")?.map((item, index) => ({
      key: index,
      label: <p key={index} className={'capitalize'}> {item.replace('-', '').replace(/_/g, ' ')}  </p>,
      disabled: !!patient?.deleted_at
    })) :
    stati?.map((item, index) => ({
      key: index,
      label: <p key={index} className={'capitalize'}> {item.replace('-', '').replace(/_/g, ' ')}  </p>,
      disabled: !!patient?.deleted_at
    }))
  
  const [activeTab, setActiveTab] = useState('Summary');
  const [isVisible, setIsVisible] = useState(false);
  
  async function handleMenuClick({ key }) {
    setIsVisible(prevState => !prevState)
    setSelectedStatus(stati[key])
    setPopReason(true)
  }
  
  async function onReasonFinish(values) {
    await setUpdatingStatus(true)
    console.log(values)
    let pl = {
      status: selectedStatus,
      patient_id: patientId||patient?.id,
      reason_status_changed: values.reason_status_changed
    }
    await dispatch(updatePatientDetails(pl))
    await setUpdatingStatus(false)
    await setPopReason(false)
    form.resetFields()
  }
  
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  
  const [updatingStatus, setUpdatingStatus] = useState(false);
  
  
  async function approvalMenuClick(e) {
    await setActionMenuRecord(null)
    await setFormName('Change approval status');
    await setDefaultDynamicModalFormValues({ approval_status: approval_statuses[e.key], patient_id: patientId||patient?.id });
    await setDynamicFormOpen(true);
  }
  
  
  const approvalMenu = (<Menu disabled={!!patient?.deleted_at} onClick={approvalMenuClick}>
    {approval_statuses?.map((item, index) =>
      <Menu.Item key={index} style={{ textTransform: 'capitalize' }}> {item.replace('-', '').replace(/_/g, ' ')}  </Menu.Item>)} </Menu>);


// isOpen
  const [isOpen, setOpen] = useState(false);
  // isExecuting
  const [isExecuting, setisExecuting] = useState(false);
  
  
  const SelectAdmission = async () => {
    let record = {
      patient_id: patientId||patient?.id,
      patient_name: patient?.name,
      assigned_to: patient?.active_assigned_consultant,
      service_type: patient?.service_type,
      admission_status: patient?.admission_status
      
    }
    
    await dispatch(selectAdmission({ ...record }))
    
    await setOpen(true);
  }
  
  
  /**
   * This function is handling returned form entries from the dynamic form
   * */
  const onDynamicFormEntriesResult = async (entries) => {
    //console.log({entries})
    try {
      switch (formName) {
        case 'Change approval status':
          await setDynamicFormOpen(false);
          await executeChunkFn(dispatch, updatePatientDetails, entries.values, setIsApproveLoading, dialogCloser(setDynamicFormOpen),
            async () => {
              // rerout history to patients if approval status is "Not Approved"
              if (entries.values.approval_status === 'Not Approved') {
                await dispatch(fetchPatients())
                navigate('/patients')
              }
            })
          //Not necessary
          break;
        default:
          return
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      toastUp(errMsg(e, false, formName), false);
    }
  };
  
  return (
    <div className="card-box h-full break-words">
      {(isDynamicFormOpen && !!formName) && (
        <DynamicModalForm
          setDynamicFormOpen={setDynamicFormOpen}
          isDynamicFormOpen={isDynamicFormOpen}
          inputFields={dynamicFormInputFields[formName]}
          onDynamicFormEntriesResult={onDynamicFormEntriesResult}
          closeModal={dialogCloser(setDynamicFormOpen)}
          isExecuting={isExecuting}
          defaultValues={defaultDynamicModalFormValues}
          formName={formName}
        />
      )}
      <Modal
        title="Change patient status"
        open={popReason}
        onCancel={dialogCloser(setPopReason)}
        onOk={dialogCloser(setPopReason)}
        destroyOnClose={true}
        maskClosable={false}
        closeIcon={modalClose(dialogCloser(setPopReason))}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([outlinedButton(() => setPopReason(false), 'Cancel', updatingStatus),
            formSubmitButton('reasonForm', 'Submit', updatingStatus, 'Submitting')])}
        </div>]}>
        <Form layout="vertical" onFinish={onReasonFinish} id="reasonForm" form={form}>
          <Form.Item name="reason_status_changed" label="Reason for changing status" rules={[{ required: true, message: 'Please input the reason for changing status' }]}>
            <Input autoComplete={'off'} placeholder=""/>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Assign Patient"
        open={isOpen}
        onOk={dialogCloser(setOpen)}
        onCancel={dialogCloser(setOpen)}
        closeIcon={modalClose(dialogCloser(setOpen))}
        maskClosable={false}
        destroyOnClose={true}
        width={1200}
        footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
          {horStack([
            outlinedButton(dialogCloser(setOpen), 'Cancel', isExecuting),
            formSubmitButton('admissionForm', 'Save', isExecuting, 'Saving')])}
        </div>]}>
        
        <NewAdmission patient={patient} setisExecuting={setisExecuting} isExecuting={isExecuting} closeModal={dialogCloser(setOpen)}/>
      
      </Modal>
      {showAuditDialog && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)}
                                          loading={false} resourceDescription={patient?.name} resourceType={'Patient'}
                                          defaultValues={{ auditableId: patientId, auditable: 'patient', format: 'json' }}/>}
      <div className="flex-boy-col items-center" style={{ width: "100%" }}>
        <Avatar size={64} src={<Image src={patient?.profile_image_url || require("../../../assets/user.png")}/>}/>
        <div className="mb-2" style={{ textAlign: 'center' }}>
          <Headline6 style={{ textAlign: 'center' }}>{patient?.name}</Headline6>
          <Body2
            color="grey">{dayjs(patient?.birth_date).format("MM/DD/YYYY")}({dayjs().diff(dayjs(patient?.birth_date), 'years')}y.o.) {capitalizeFirstLetter(patient?.gender)}</Body2>
        </div>
        <div className="flex flex-row justify-center w-full gap-2">
          {!activeUser?.facilityProfile ?
            can_update_patient ? <Dropdown menu={{ items: menuItems, onClick: handleMenuClick }}
                                           trigger={['click']}>
              {secondaryButton(null, patient?.status ? patient?.status.replace(/_/g, ' ') : 'Inactive', updatingStatus, 'Updating',
                null,
                { icon: getPatientIndicator(patient?.status ? patient?.status : null), endIcon: <CaretDownOutlined/> })}
            </Dropdown> : getPatientStatus(patient?.status ? patient?.status : null)
            : getPatientStatus(patient?.status ? patient?.status : null)}
          <KebabMenu menus={actionMenus}
                     recordItem={patient}
                     handleMenuClick={kebabMenuClick}
                     resource={'patient'}
                     handleOk={null}
                     handleCancel={null}
                     confirmLoading={null}
                     showConfirmDialog={false}/>
        </div>
        {patient?.reason_status_changed && <div className=" mt-4 flex flex-row justify-center w-full ">
          <p className="text-xs my-auto">Reason Status Changed: <span className={'font-semibold'}>{patient?.reason_status_changed || "N/A"}</span></p>
        </div>}
      
      </div>
      <Divider/>
      {navs.patientDetailsLoading ? skelBars() : <Row gutter={[4, 4]} align={"stretch"} justify={"start"} style={{ width: '100%', margin: 0 }}>
        <Col className="text-left" xs={24} sm={24} md={24} lg={24} xl={24} flex={"auto"} style={{ width: '100%' }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            p: 0,
            py: 0,
            m: 0,
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            bgcolor: 'background.paper',
            width: '100%'
          }}>
            {horStack([
              <Typography style={{ alignSelf: 'center' }} variant="body1">Referral status: </Typography>,
              !activeUser?.facilityProfile && can_update_patient ?
                <Dropdown destroyPopupOnHide={true} dropdownRender={()=>approvalMenu} trigger={['click']} disabled={isApproveLoading}>
                  {secondaryButton(null, <span
                    style={{ marginLeft: '10px' }}>{patient?.approval_status ? patient?.approval_status : 'Unassigned'}</span>, isApproveLoading, 'Approving', { padding: '10px' }, {
                    endIcon: <CaretDownOutlined/>
                  })}
                
                </Dropdown> : <Subtitle1 style={{ marginTop: "3px", marginLeft: "3px", marginRight: "3px" }}>{patient?.approval_status ? patient?.approval_status : 'Unassigned'}</Subtitle1>
            ], { direction: { xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' } })}
          
          </div>
          {patient?.reason_approval_status_changed && <div className=" mt-2 flex flex-row justify-between w-full">
            <p className="mb-3 text-xs"><span className="font-semibold">Reason for Referral Status Changed:</span>{patient?.reason_approval_status_changed || "N/A"}</p>
          </div>}
          
          <Divider/>
          <div className="w-full">
            <div className="flex flex-row justify-between w-full items-center">
              <p className="mb-0 text-normal">Assigned to</p>
              {!activeUser?.facilityProfile && can_create_admission && <p className="mb-0 text-xs">{<Button type="link" onClick={() => SelectAdmission()}>Change</Button>}</p>}
            </div>
            
            <div className="flex flex-row w-full items-center">
              {/* avatar person */}
              {patient?.active_assigned_consultant?.name ? <>
                <Avatar size={32} src={<Image src={patient?.assigned_to?.profile_image_url || require("../../../assets/user.png")} />} />
                <p className="mb-0 ml-2 text-normal font-semibold">{patient?.active_assigned_consultant?.name}</p>
              </> : getAdmissionStatus(patient?.admission_status)}
            
            </div>
            {patient?.active_assigned_consultant && <p className="mb-0  mt-2 text-xs"><span className="font-semibold">Reason for Assignment :</span>{patient?.active_assigned_consultant?.reason}</p>}
          
          </div>
          
          <Divider/>
          <div className=" my-4 flex flex-row justify-between w-full">
            <p className="mb-3 text-xs">Referred By</p>
            <p className="mb-3 text-xs">{patient?.referred_by || 'Internal'}</p>
          </div>
          
          <div className=" my-4 flex flex-row justify-between w-full">
            <p className="mb-3 text-xs">PST Time zone</p>
            <p className="mb-3 text-xs">{patient?.timezone || "N/A"}</p>
          </div>
          <p className="my-6 text-blue-500">{patient?.phone_no} </p>
          <div className="my-4"></div>
          <p className="my-6  text-blue-500">{patient?.email}</p>
          <div className="my-4"></div>
          <p className="mb-4  text-xs">{patient?.address_1}, {patient?.zip ? patient?.zip + ',' : ''} {patient?.city ? patient?.city + ',' : ''} {patient?.state}</p>
          <div className="my-4"></div>
          <p className="mb-4  text-xs">{patient?.address_2}</p>
          <div className="my-4"></div>
           <div className=" mt-2 flex flex-row justify-between w-full">
            {can_view_patient_ssn && <p className="mb-3 text-xs">SSN:{patient?.ssn || "N/A"}</p>}
             {/* {!activeUser.facilityProfile && <p className="mb-3 text-xs">Smartsheet ID: {patient?.smartsheet_id || "N/A"}</p>} */}
            <p className="mb-3 text-xs">Patient ID: {patient?.id}</p>
          </div>
          
          <div className="my-4"></div>
          <Divider/>
          <div className="my-4"></div>
          <p className="mb-3 text-xs">Office:</p>
          <p className=" mb-3 font-semibold text-md">{patient?.medical_facility || "N/A"}</p>
          <div className="my-4 text-xs">Office Primary Contact:</div>
          <p className="mb-3 text-xs">{patient?.additional_contacts?.facility_primary_contact}</p>

          <div className="my-4 text-xs">Office Phone Number:</div>
          <p className="mb-3 text-xs  text-blue-500">{patient?.additional_contacts?.facility_phone_no}</p>
          <div className="my-4"></div>
          {/* {boldKeyCapitalizedValue('State', patient?.state)} */}
          <Divider/>
          <div className="my-4"></div>
          <p className="mb-3 text-xs">Primary Insurance</p>
          {insurances?.[0] ? <>
            <p className="mb-3 font-semibold">{insurances?.[0]?.insurance_provider}</p>
            <p className="mb-3 text-xs">Plan Name</p>
            <p className="mb-3 font-semibold">{insurances?.[0]?.plan || "N/A"}</p>
          </> : "N/A"}
        </Col>
      </Row>}
    </div>
  );
}

const mapStateToProps = (state) => ({
  wounds: state.patients.patientWounds, patient: state.patients.selectedPatient,
  appConf: state.appConf, navs: state.navs, activeUser: state.auth?.activeUser,
  insurances: state.patients.insurances
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PatientInfoPane);
