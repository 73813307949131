import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {AutoComplete, Button, DatePicker, Form, Input, Radio, Select, Space, Upload} from 'antd'
import dayjs from "dayjs"
import GetCookie from '../../../../hooks/GetCookie'
import {cleanObject, emptyFun, executeChunkFn, inputRow} from '../../../../common/helpers'
import {apiRequest} from '../../../../app/Apis'
import {createTask, updateTask} from '../../../../app/Reducers'
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import {CloudUploadOutlined} from "@ant-design/icons";

export const NewTask = ({ setIsExecuting, activeUser, closeModal, editingForm, selectedTask, masterList }) => {
  const [form] = Form.useForm()
  const can_view_consultant_listing = usePermissionCheck('view_consultant_listing', false, [])
  const can_add_atachment_to_task = usePermissionCheck('add_atachment_to_task', false, [])
  const { Option } = Select;
  const uploadUrl = process.env.REACT_APP_ENDPOINT + '/files';
  const token = GetCookie('accessToken')
  const [fileList, setFileList] = useState([])
  const dispatch = useDispatch()
  const onFacesheetChange = ({ fileList: newFileList }) => {
    const newFiles = newFileList.map((file) => {
      if (file.response) {
        // Update the file object with the server response URL and ID
        file.url = file.response.url;
        file.id = file.response.data.id;
      }
      return file;
    });
    
    // Update the state with the new file list
    setFileList(newFiles);
    console.log(newFiles)
    // Update the form fields value with the new file IDs
    const attachmentIds = newFiles.map((file) => file.id)
    console.log(attachmentIds)
    form.setFieldsValue({ attachments: [...attachmentIds] });
  };
  
  const GetOptions = (system_name) => {
    
    const { Option } = Select;
    return <>
      {masterList.find(item => item.system_name === system_name)?.options?.map(item => <Option
        value={item}>{item.toString().replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}</Option>) || []}
    </>;
    
  }
// onfinish
  const onFinish = async (values) => {
    // await setIsExecuting(true)
    await console.log(values)
    
    const payload = {
      ...values,
      id: editingForm ? selectedTask.id : null,
      patient_id: selectedPatient,
      department_id: selectedDepartment,
      assignee_id: selectedAssignee,
      reporter_id: selectedReporter,
      status: "assigned",
      due_date: dayjs(values.due_date).format("YYYY-MM-DD"),
      attachments: editingForm ? values.attachments ? [...values.attachments, ...selectedTask.attachments.map(item => item.id)] : [...selectedTask.attachments.map(item => item.id)] : values.attachments ? [...values.attachments] : []
    }
    
    await executeChunkFn(dispatch, editingForm ? updateTask : createTask, cleanObject(payload), setIsExecuting, closeModal, async () => {
    
    })
    
    
    // await setIsExecuting(false)
  }

// list of depoartments useState
  const [departments, setdepartments] = useState([])
  const [assignees, setassignees] = useState([])
  const [patients, setPatients] = useState([])
// department users
  const [departmentUsers, setdepartmentUsers] = useState([])
// selected values
  const [initialValues, setInitialValues] = useState(editingForm ? {
    "department_id": selectedTask.department?.name,
    "assignee_id": selectedTask?.assignee?.name,
    "reporter_id": selectedTask.reporter.name,
    "patient_id": selectedTask.patient.name,
    "description": selectedTask.description,
    "notes": selectedTask.notes,
    "due_date": dayjs(selectedTask.due_date),
    "priority": selectedTask.priority,
    "task_type": selectedTask.task_type
    
    
  } : { reporter_id: activeUser.name });
  const [selectedDepartment, setselectedDepartment] = useState(editingForm ? selectedTask.department.id : null)
  const [selectedAssignee, setselectedAssignee] = useState(editingForm ? selectedTask.assignee.id : null)
  const [selectedPatient, setselectedPatient] = useState(editingForm ? selectedTask.patient.id : null)
  const [selectedReporter, setselectedReporter] = useState(editingForm ? selectedTask.reporter.id : activeUser.id)

// search  patients
  const handlePatientsearch = async (value) => {
    const { data } = await apiRequest.get(`/patients?search=${value}`)
    // setOptions(data);
    if (data) {
      console.log(data.data)
      setPatients(data.data.filter(item => item.status === "active" || item.status === "hospitalized"));
    }
  };

// department error use state
  const [departmentError, setdepartmentError] = useState(false)

// handle department search
  const handleDepartmentsearch = async (value) => {
    if (value === '') {
      setdepartments([]);
    } else {
      const { data } = can_view_consultant_listing ? await apiRequest.get(`/departments?search=${value}`) :
        await apiRequest.get(`/departments?search=${value}`)
      // setOptions(data);
      if (data) {
        console.log(data.data)
        setdepartments(data.data);
        
        if (data.data.length === 1 && data.data[0].id !== selectedDepartment) {
          
          const selected = data.data[0]
          await setselectedDepartment(data.data[0].id)
          setassigneeDisabled(!can_view_consultant_listing && selected.supervisor_id !== activeUser.id)
          form.setFieldsValue({ department_id: data.data[0].name });
          fetchDepartmentUsers()
          setdepartmentError(false)
          // if department supervisor is not the active user then set assignee to active user
          // if (data.data[0].supervisor_id !== activeUser.id) {
          //   setselectedAssignee(activeUser.id)
          //   form.setFieldsValue({ assignee_id: activeUser.name });
          // }
          
        }
        if (data.data.length === 0) {
          setselectedDepartment(null)
          form.setFieldsValue({ department_id: null });
          setdepartmentUsers([])
          setdepartmentError(true)
        }
      }
    }
  };

// handle assignee search
  const handleAssigneesearch = async (value) => {
    const { data } = await apiRequest.get(`/consultants?search=${value}`)
    // setOptions(data);
    if (data) {
      console.log(data.data)
      setassignees(data.data);
    }
  };
// fetch department users and set them as assignees
  const fetchDepartmentUsers = async (value) => {
    const { data } = await apiRequest.get(`/departments/${selectedDepartment}/users?search=${value}`)
    // setOptions(data);
    if (data) {
      console.log(data.data)
      setdepartmentUsers(data.data);
    }
  };

  useEffect(() => {
    selectedDepartment && searchDepartmentUsers()
    return emptyFun
  }, [selectedDepartment])

  // is me assignable
  const [isMeAssignable, setisMeAssignable] = useState(false)

  // serach department users for my name and if found set isMeAssignable to true
  const searchDepartmentUsers = async () => {
    const { data } = await apiRequest.get(`/departments/${selectedDepartment}/users?search=${activeUser.name}`)
    if (data) {
      console.log(data.data)
      const isAssignable = data.data.some(user => user.id === activeUser.id)
      setisMeAssignable(isAssignable)
    }
  };

// assignee disabled
  const [assigneeDisabled, setassigneeDisabled] = useState(true)
  
  return (
    // ant d form
    < div className="bg-gray-100 p-2">
      
      <Form initialValues={{ ...initialValues }} name="taskform" id="taskForm" form={form} layout="vertical" onFinish={onFinish}>
        
        
        <div className="rounded-md p-2 shadow my-4 bg-white">
          {/* department field */}
          <Form.Item label="Department" name="department_id" rules={[{ required: true, message: 'this field is required!' }]}>
            {/* autocomplete */}
            <AutoComplete popupMatchSelectWidth={false}
                          
                          style={{ width: "100%", marginBottom: "20px" }}
                          onSearch={handleDepartmentsearch}
                          
                          onSelect={(value) => {
                            // find user_id of selected assignee
                            const selectedDepartment = departments.find(item => item.id === value)
                            
                            setselectedDepartment(selectedDepartment.id)
                            setassigneeDisabled(!selectedDepartment || (!can_view_consultant_listing && selectedDepartment.supervisor_id !== activeUser.id))
                            // if ((!can_view_consultant_listing && selectedDepartment.supervisor_id !== activeUser.id)) {
                            //   setselectedAssignee(activeUser.id)
                            //   form.setFieldsValue({ assignee_id: activeUser.name });
                            // }
                         
                            form.setFieldsValue({ department_id: selectedDepartment?.name })
                          }}
                          
                          
                          placeholder="search for departments">
              {departments.map(item => (<Option key={item.id} value={item.id} label={item.name}>{item.name}</Option>))}
            </AutoComplete>
            {departmentError && <p className="text-red-500">
            No departments match your search
            </p>}
          </Form.Item>
          
          
          {selectedDepartment && inputRow([
            <Form.Item rules={[{ required: true, message: 'this field is required!' }]} label="Assignee" name="assignee_id">
              {/* autocomplete */}
              <AutoComplete popupMatchSelectWidth={false}
                            // disabled={assigneeDisabled}
                            
                            style={{ width: "100%", marginBottom: "20px" }}
                            onSearch={fetchDepartmentUsers}
                            onSelect={(value) => {
                              // find user_id of selected assignee
                              const selectedAssignee = departmentUsers.find(item => item.id === value) || activeUser
                              
                              
                              // const selectedAssignee = assignees.find(item => item.name === value)
                              setselectedAssignee(selectedAssignee.id || activeUser.id)
                              
                              form.setFieldsValue({ assignee_id: selectedAssignee.name })
                            }}
                            placeholder="search for assignees">
                {/* <Option value={activeUser.id}> Automatically Assign to me</Option> */}
                {departmentUsers.map(item => (<Option key={item.id} value={item.id}>{item.name}</Option>))}
              </AutoComplete>
            </Form.Item>
            ,
            <Form.Item label="Reporter" name="reporter_id" rules={[{ required: true, message: 'this field is required!' }]}>
              <AutoComplete popupMatchSelectWidth={false}
                            
                            style={{ width: "100%", marginBottom: "20px" }}
                            onSearch={handleAssigneesearch}
                            onSelect={(value) => {
                              // find user_id of selected assignee
                              const selectedAssignee = assignees.find(item => item.user_id === value) || activeUser
                              
                              
                              // const selectedAssignee = assignees.find(item => item.name === value)
                              setselectedReporter(selectedAssignee.user_id || activeUser.id)
                              
                              form.setFieldsValue({ reporter_id: selectedAssignee.name })
                            }}
                            
                            
                            placeholder="search for assignees">
                <Option value={activeUser.id}> Automatically Assign to me</Option>
                {assignees.map(item => (<Option key={item.id} label={item.name} value={item.user_id}>{item.name}</Option>))}
              </AutoComplete>
            </Form.Item>
          ])}
          {/* patient */}
          {selectedDepartment && <Form.Item label="Patient" name="patient_id" rules={[{ required: true, message: 'this field is required!' }]}>
            
            <AutoComplete popupMatchSelectWidth={false}
                          
                          style={{ width: "100%", marginBottom: "20px" }}
                          onSelect={(value) => {
                            // find user_id of selected assignee
                            const selectedPatient = patients.find(item => item.id === value)
                            setselectedPatient(selectedPatient.id)
                            form.setFieldsValue({ patient_id: selectedPatient.name })
                          }
                          }
                          
                          onSearch={handlePatientsearch}
                          placeholder="search for patients">
              {patients.map(item => (<Option key={item.id} value={item.id}>{item.name}</Option>))}
            </AutoComplete>
          </Form.Item>}
          
          {selectedDepartment && inputRow([
            <Form.Item label="Due Date" name="due_date" rules={[{ required: true, message: 'this field is required!' }]}>
              <DatePicker/>
            
            </Form.Item>,
            <Form.Item label="Priority" name="priority" rules={[{ required: true, message: 'this field is required!' }]}>
              {/* radio */}
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value="high">High</Radio>
                  <Radio value="normal">Normal</Radio>
                  <Radio value="low">Low</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          ])}
        </div>
        {selectedDepartment && <div className="rounded-md p-2 shadow bg-white">
          
          <Form.Item label="Task Type" name="task_type" rules={[{ required: true, message: 'this field is required!' }]}>
            {/* autocomplete */}
            <AutoComplete popupMatchSelectWidth={false}
                          style={{ width: "100%", marginBottom: "20px" }}
                          options={masterList.find(item => item.system_name === "task_type")?.options?.map(item => {
                            return { value: item, label: item.toString().replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()) }
                          })}
                          filterOption={(inputValue, option) =>
                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                          }
                          onSelect={(value) => {
                            // find user_id of selected assignee
                            
                            form.setFieldsValue({ description: value === "Other" ? "" : value })
                          }}
                          placeholder="search for task types">
            
            </AutoComplete>
          
          </Form.Item>
          
          <Form.Item label="Task Name" name="description" rules={[{ required: true, message: 'this field is required!' }]}>
            <Input placeholder="Task"/>
          </Form.Item>
          <Form.Item label="Notes" name="notes">
            <Input.TextArea placeholder="Notes"/>
          </Form.Item>
          {can_add_atachment_to_task && <Form.Item
            style={{ width: '100%' }}
            name="attachments"
            rules={[{ required: false, message: 'Missing Document' }]}
            label="Attachments">
            <Upload accept="image/*, .pdf"
                    action={uploadUrl}
                    headers={{ 'Authorization': `Bearer ${token}` }}
                    defaultFileList={[...fileList]}
                    listType="picture"
                    onChange={onFacesheetChange}
              // max file size
            
            
            >
              
              <Button icon={<CloudUploadOutlined />}>Upload</Button>
            </Upload>
            <Input autoComplete={'off'} style={{ width: '100%', display: 'none' }}/>
          </Form.Item>}
        </div>}
      
      
      </Form>
    
    </div>
  )
}

const mapStateToProps = (state) => ({
  activeUser: state.auth.activeUser,
  selectedTask: state.tasks.selectedTask,
  masterList: state.masters.masterListRaw,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(NewTask)