import React, { useEffect, useState } from 'react';
import { Card, Spin, Timeline, Typography } from 'antd';
import { Headline6, Subtitle1 } from '../../../../components';
import { boldKeyCapitalizedValue, getDeliveryStatus, secondaryButton, containedButton } from '../../../../common/helpers';
import dayjs from 'dayjs';
import PdfViewer from '../../../../components/PdfViewer';
import _ from 'lodash';
import { genOrderDeliveryReport } from '../../../../app/Apis/commonApi';

const { Title } = Typography;

export default function TrackingInformation({ tracking, order, loading }) {
  const [orderDeliveryPrinting, setOrderDeliverPrinting] = useState(false)
  const [pdf, setPdf] = useState(null)
  const [pdfLoading, setLoading] = useState(false)
  const [renderPdf, setRenderPdf] = useState(false)
  const [pdfShowing, setPdfShowing] = useState(false)

  let activities;

  if (tracking) {
    // console.log('tracking information::::', tracking)
    activities = [...tracking?.activities].sort((a, b) => new Date(b.date) - new Date(a.date));
  }

  useEffect(() => {
    if (pdf !== null) {
      setRenderPdf(true)
    }
  }, [pdf])

  const downloadDeliveryInformation = () => genOrderDeliveryReport(order, setOrderDeliverPrinting, setPdf, setLoading, setPdfShowing)

  return (
    <div className="shadow-card-box">
      {renderPdf && <PdfViewer pdf={pdf} setLoading={setLoading} loading={pdfLoading} onCancel={() => setPdfShowing(false)} visible={pdfShowing} docName={'Status Delivery Report'} />}
      <div className='flex-boy-row-space w-full'>
        <Headline6>Order Tracking Information</Headline6>
        {tracking && <div style={{}}>{getDeliveryStatus(tracking?.delivery_status)} </div>}
      </div>
      <div className={'w-full'}>
        <Spin spinning={loading}>
          <div>
            {tracking ?
              <div>
                {boldKeyCapitalizedValue('Delivery Location:', <p style={{ fontWeight: 'bold' }}>{tracking?.delivery_location}</p>)}
                {/* {boldKeyCapitalizedValue('Tracking Number:', tracking?.tracking_number)} */}
                {boldKeyCapitalizedValue('Delivery Date:', dayjs(tracking?.delivery_date).format('DD/MM/YYYY'))}
                <Title level={5} className='mt-4'>Delivery Activities</Title>
                <div>
                  {activities?.length > 0 && <Timeline mode="left" style={{ padding: '10px', marginRight: 45 }}>
                    {activities?.map((activity, index) =>
                      <Timeline.Item
                        label={new Date(activity.date).toLocaleDateString()}
                        key={index}
                        color={activity?.status === 'Delivered' ? 'green' : 'blue'}>
                        {activity?.status} {activity?.location ? `at ${activity?.location}` : ''}
                      </Timeline.Item>
                    )}
                  </Timeline>}
                </div>
              </div> : <p className={'text-start'}>No tracking information available</p>}
            {(tracking && tracking?.delivery_status === "Delivered" && !["Biologics",'Internal'].includes(order?.visit_type)) && (
              <div style={{ marginTop: '20px' }}>
                {containedButton(downloadDeliveryInformation, 'Download Proof of Delivery', orderDeliveryPrinting, 'Downloading')}
              </div>
            )}
          </div>
        </Spin>
      </div>
    </div>
  );
}