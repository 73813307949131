import React, { useEffect, useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLocation, useNavigate} from "react-router-dom";
import {DatePicker, Form, Input, Select, AutoComplete, InputNumber} from 'antd'
import dayjs from "dayjs"
import { createMedication, updateMedication } from '../../../../../app/Reducers';
import { cleanObject, emptyFun, executeChunkFn, inputRow, GetOptions } from "../../../../../common/helpers";
import _ from "lodash"
import { apiRequest } from '../../../../../app/Apis';
import { Body1 } from '../../../../../components';
import TextArea from 'antd/es/input/TextArea';

const NewMedicationForm = ({ closeModal, editingForm, setisExecuting, medication, patient }) => {
  const dateFormat = 'MM/DD/YYYY';
  const uiFields = (medication) => {
    // cleanObject(medication)
    return {
      ...medication, drug: medication.drug ? `${medication?.drug?.brand_name}-${medication?.drug?.brand_name}-${medication?.drug?.strength}` : '', drug_group: medication?.rxnorm_cui?.id_type, ...(medication.start_date && { start_date: dayjs(medication.start_date) }), ...(medication.end_date && { end_date: dayjs(medication.end_date) })
    }
  }
  const [form] = Form.useForm();
  const { Option } = Select;
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [drugListOptions, setdrugListOptions] = useState([])
  const [selectedDrug, setselectedDrug] = useState(medication?.drug)
  const [selectedDrugGroup, setselectedDrugGroup] = useState(medication?.rxnorm_cui)
  const [drugGroupOptions, setdrugGroupOptions] = useState([])
  const debouncedChangeHandler = useCallback(
    _.debounce(function (value) {

      getListAvailableDrugs(value)
    }, 500),
    []
  );


  // const debouncedChange = (value) => {
  //   debouncedChangeHandler(value)
  // }
  // const getListAvailableDrugs = async (value) => {
  //   setdrugListOptions([])

  //   console.log(value)
  //   if (value.length > 1) {
  //     setLengthError(false)
  //     const { data } = await apiRequest.get(`/integrations/dr_first/search_drugs?search=${value}`)
  //     // setOptions(data);

  //     if (data) {
  //       console.log(data.data)
  //       if (data?.data?.length) {
  //         setnodataError(false)
  //         setdrugListOptions(data.data);
  //       } else {
  //         setnodataError(true)
  //         setdrugListOptions([])
  //       }

  //     }
  //   } else {
  //     setLengthError(true)
  //   }
  // };


  const getListAvailableDrugs = async (value) => {
    console.log(value)
    setdrugListOptions([])
    if (value.length > 1) {
      setLengthError(false)
      const { data } = await apiRequest.get(`/integrations/dr_first/search_drugs?search=${value}`)
      // setOptions(data);
      if (data) {
        console.log(data.data)

        if (data?.data?.length) {
          setnodataError(false)
          setdrugListOptions(data.data);
        } else {
          setnodataError(true)
          setdrugListOptions([])
        }
      }
    } else {
      setLengthError(true)
    }
  };
  const onDrugSelect = (value) => {
    setLengthError(false)
    setnodataError(false)
    console.log('onSelect', value);
    let drug = drugListOptions?.find(item => `${item.brand_name}-${item?.generic_name}-${item.strength}`.replace("-null", "") === value)
    setselectedDrug(drug)
    // setdrugGroupOptions([...drug.drug_groups])
  };
  const onDrugGroupSelect = (value) => {
    console.log('onSelect', value);
    let drug_group = drugGroupOptions.find(item => item.id_type === value)
    setselectedDrugGroup(drug_group)
  };




  const [initialValues, setInitialValues] = useState(editingForm ? uiFields(medication) : {});
  useEffect(() => {
    setInitialValues(editingForm ? uiFields(medication) : {})
    return emptyFun
  }, [medication, editingForm])


  function setUndefinedToNull(obj) {
    for (const key in obj) {
      if (obj[key] === undefined) {
        obj[key] = null;
      }
    }
    return obj;
  }
  const onFinish = async (values) => {

    let payload
   
    if (editingForm) {
      payload = { ...values, patient_id: patientId, medication_id: medication.id, rxnorm_cui: selectedDrugGroup }
      console.log(payload)
      payload= setUndefinedToNull(payload)
    } else {
      payload = { ...values, patient_id: patientId, rxnorm_cui: selectedDrugGroup }
    }
    
    await executeChunkFn(dispatch, editingForm ? updateMedication : createMedication, payload, setisExecuting, closeModal)
    
  };

  const [LengthError, setLengthError] = useState(false)
  const [nodataError, setnodataError] = useState(false)
  const [drugError, setdrugError] = useState(false)
  const optionsList = drugListOptions?.map((item, index) => {
    return (<AutoComplete.Option key={index} value={`${item.brand_name}-${item?.generic_name}-${item.strength}`.replace("-null", "")}>{`${item.brand_name}-${item?.generic_name}-${item.strength}`.replace("-null", "")}</AutoComplete.Option>)
  })
  return (
    <Form
      name="new-patient"
      id="medicationForm"
      form={form}
      initialValues={{ ...initialValues }}
      labelCol={{ span: 16 }}
      onValuesChange={(values) => { console.log(values) }}
      layout="vertical"
      onFinish={onFinish}>
      <Form.Item
        name="name"
        label="Drug "
        rules={[{ required: true, message: 'this field is required!' }]}
        >
        <Input autoComplete={'off'} placeholder="Type here" />
      </Form.Item>
      {/* <div style={{ textAlign: 'left', marginBottom: '10px', marginTop: '-30px' }}>
        {LengthError && <Body1 color='crimson' style={{ textAlign: 'left', marginTop: '20px', marginBottom: '10px' }}>Please use atleast two characters!</Body1>}
        {nodataError && <Body1 color='crimson' style={{ textAlign: 'left', marginBottom: '10px' }}>No record found!</Body1>}
        {drugError && <Body1 color='crimson' style={{ textAlign: 'left', marginBottom: '10px' }}>Please select a valid drug!</Body1>}
      </div> */}
      {/* {selectedDrug && <Form.Item
        label="Rxnorm Cui"
        name="drug_group"
        rules={[{ required: true, message: 'this field is required!' }]}
        wrapperCol={{ span: 24 }}>
        <AutoComplete popupMatchSelectWidth={false}

          style={{ width: "100%", marginBottom: "20px" }}
          onSelect={onDrugGroupSelect}

          placeholder="select rxnorm_cui">
          {drugGroupOptions.map(item => (<Option key={item.id} value={item.id_type}>{item.id_type}-{item.id}</Option>))}
        </AutoComplete>
      </Form.Item>} */}



      {/* <Form.Item
        name="name"
        label="Medication"
        rules={[{ required: true, message: 'Missing Medication' }]}>
        <Input autoComplete={'off'} placeholder="Medication" />
      </Form.Item> */}

      {inputRow([
        <Form.Item
          name="action"
          label="Action"
          style={{ width: '100%' }}
          rules={[{ required: false, message: 'Missing Action' }]}>
          <Select popupMatchSelectWidth={false}
            placeholder="Action"
            style={{ width: '100%' }}
            allowClear
          >
            {GetOptions('action')}
          </Select>
        </Form.Item>,

        <Form.Item
          name="dose"
          label="Dose"
          style={{ width: '100%' }}
          rules={[{ required: false, message: 'Missing Dose' }]}>
          <Select popupMatchSelectWidth={false}
            placeholder="dose"
            style={{ width: '100%' }}
            allowClear
          >
            {GetOptions('dose')}
          </Select>
        </Form.Item>,
        <Form.Item
          name="dose_unit"
          label="Dose Unit"
          style={{ width: '100%' }}
          rules={[{ required: false, message: 'Missing Dose unit' }]}>
          <Select popupMatchSelectWidth={false}
            placeholder="Dose unit"
            style={{ width: '100%' }}
            allowClear
          >
            {GetOptions('dose_unit')}
          </Select>
        </Form.Item>,
        <Form.Item
          name="route"
          label="Route"
          style={{ width: '100%' }}
          rules={[{ required: false, message: 'Missing Route' }]}>
          <Select popupMatchSelectWidth={false}
            placeholder="route"
            style={{ width: '100%' }}
            allowClear
          >
            {GetOptions('route')}
          </Select>

        </Form.Item>
      ])}
      {inputRow([
        <Form.Item
          name="quantity"
          label="Quantity Dispensed"
          style={{ width: '100%' }}
          rules={[{ required: false, message: 'Missing Quantity' }]}>
          <InputNumber min={0} autoComplete={'off'} type="number" placeholder="type here" style={{ width: '100%' }}/>
        </Form.Item>,
        <Form.Item
          name="quantity_unit"
          label="Quantity Unit"
          style={{ width: '100%' }}
          rules={[{ required: false, message: 'Missing Quantity unit' }]}>
          <Select popupMatchSelectWidth={false}
            placeholder="Quantity unit"
            style={{ width: '100%' }}
            allowClear
          >
            {GetOptions('quantity_unit')}
          </Select>
        </Form.Item>,
        <Form.Item
          name="refills"
          label="Refills"
          style={{ width: '100%' }}
          rules={[{
            required: false,
            pattern: new RegExp(/^[0-9]+$/),
            message: "Value should contain just number",
          },
          {
            max: 2,
            message: "Value should be less than 50 character",
          }]}>
          <Input autoComplete={'off'} type="number" placeholder="type here" />
        </Form.Item>,
      ])}
      <Form.Item
        style={{ width: '100%' }}
        name="frequency"
        label="Frequency"
        rules={[{ required: true, message: 'Missing Frequency' }]}>
        <Select popupMatchSelectWidth={false}
          placeholder="frequency"
          style={{ width: '100%' }}
          allowClear
        >
          {GetOptions('frequency')}
        </Select>
      </Form.Item>
      {inputRow([
        <Form.Item
          style={{ width: '100%' }}
          name="start_date"
          label="Start Date"
          rules={[{ required: true, message: 'Missing Start Date' }]}
        >
          <DatePicker style={{ width: '100%' }} format={dateFormat} />
        </Form.Item>,
        <Form.Item
          style={{ width: '100%' }}
          dependencies={['start_date']}
          name="end_date"
          label="End Date"
          rules={[{ required: true, message: 'Missing End Date' }, ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || dayjs(getFieldValue('start_date')) <= dayjs(value)) {

                return Promise.resolve();
              }
              return Promise.reject(new Error('Start date greater than end date'));
            }
          })]}
          hasFeedback
        >
          <DatePicker style={{ width: '100%' }} format={dateFormat} />
        </Form.Item>
      ])}

      <Form.Item
        name="notes"
        label="Notes"
        rules={[{ required: false, message: 'Missing Notes' },{ max: 1000, message: 'Cannot exceed 1000 characters' }]}
      >
        <TextArea autoComplete={'off'} placeholder="Type here" />
      </Form.Item>
    </Form>
  );
}
const mapStateToProps = (state) => ({
  patient: state.patients.selectedPatient
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(NewMedicationForm)
