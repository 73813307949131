import { useState, useCallback, useEffect } from "react";
import { PieChart, Pie, Sector, Cell, Legend } from 'recharts';
import { Divider, Spin } from "antd";
import { Headline6, Primary } from "../../../components";
import { appLog, emptyFun } from "../../../common/helpers";
import { apiRequest } from "../../../app/Apis";

function titleCase(str) {
  let newStr;
  if (str === 'rejected') { newStr = 'Declined' }
  else { newStr = str }
  return newStr.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
}

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>{titleCase(payload?.status)}</text>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
      <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + -2} outerRadius={outerRadius + 20} fill='#4B9BFF' opacity={0.4} />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill='#333'>{`${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill='#999'>{`${Math.floor(percent * 100)}%`}</text>
    </g>
  );
};

const emptyData = [
  { status: 'No Prospects', count: 1 },
]

export default function AllProspects({ region }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [prospects, setProspects] = useState(null);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const getTotal = (arr) => {
    return arr.reduce((acc, obj) => acc + obj.count, 0);
  }

  async function fetchProspects(region) {
    setLoading(true)
    let params = {};
    if (!!region && region !== '' && region !== undefined) {
      params.region=region
    }
    const response = await apiRequest.get(`/prospect_stats`,{params});
    const res = response?.data?.data;
    let total = getTotal(res);
    setTotal(total)
    setProspects(res);
    setLoading(false)
    console.log("Prospects Shenanigans:", res)
  }

  useEffect(() => {
    fetchProspects(region)
    return emptyFun
  }, [region])

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  return (
    <div className="stats-card-box">
      <div style={{ width: '100%', padding: '5px' }}>
        <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>All Referrals</Headline6>
        <Divider style={{ margin: '4px', color: '#E2E4EA', width: '100%' }} />
      </div>
      <div className="flex-boy-column">
        <Headline6>Total Referrals</Headline6>
        <Headline6 style={{ fontWeight: 'bold', fontSize: '20px' }}>{total}</Headline6>
      </div>
      <Spin spinning={loading} style={{ color: Primary }}>
        <PieChart width={400} height={400} style={{ marginTop: '15px' }}>
          {total === 0 ?
            <Pie
              data={emptyData}
              fill='#A09F99'
              dataKey={'count'}
              cx={180}
              cy={200}
              innerRadius={80}
              activeIndex={activeIndex}
              //activeShape={renderActiveShape}
              outerRadius={150}
              onMouseEnter={onPieEnter} /> :
            <Pie data={prospects} cx={180} cy={200} innerRadius={80} activeIndex={activeIndex} activeShape={renderActiveShape} outerRadius={120} dataKey='count' onMouseEnter={onPieEnter} >
              {prospects?.map((entry, index) => (<Cell key={index} fill={entry?.status === 'pending' ? '#4B9BFF' : entry?.status === 'approved' ? '#2E5AAC' : '#7785FF'} />))}
            </Pie>
          }
          {total !== 0 ? <Legend
            iconType='circle'
            payload={
              prospects?.map(
                (item, index) => ({
                  id: index,
                  type: 'circle',
                  value: titleCase(item?.status),
                  color: item?.status === 'pending' ? '#4B9BFF' : item?.status === 'approved' ? '#2E5AAC' : '#7785FF',
                })
              )
            }
          /> :
            <Legend iconType='circle' type="circle"
              payload={
                emptyData?.map(
                  (item, index) => ({
                    id: index,
                    type: 'circle',
                    value: titleCase(item?.status),
                    color: "#A09F99"
                  })
                )
              }
            />
          }
        </PieChart>
      </Spin>
    </div>
  )
}
