/* eslint-disable no-unused-vars */
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {apiRequest} from '../Apis';
import {newResourceArray, updatedArrayState} from '../../common/helpers';
import {facilities} from './initialState';
import {errMsg, getToastId, toastUp, updateToast} from './reducerUtils';
import ReactGA from "react-ga4";
import GetCookie from '../../hooks/GetCookie';
const activeUser = GetCookie('activeUser')
const initialState = { ...facilities,
  selectedFacility: activeUser ? JSON.parse(activeUser).facilityProfile : null, };


export const fetchFacilityPatients = createAsyncThunk('fetchFacilityPatients', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.facilities.facilityPatientPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const { data } = await apiRequest.get(`/patients`,{params});
      let data_ = data.data.map(item => ({
        ...item, latlng: [item.lng, item.lat],
        ...(( !!params?.facility_id) && { facility_id: params?.facility_id })
      }))
      const payload = { data:data_, pagination: { ...pagination, total: data.meta.total } }
      resolve(payload)
    } catch (e) {
      toastUp(errMsg(e, true, 'patients'), false)
      reject(e)
    }
  })
})

export const fetchFacilityProspectsList = createAsyncThunk('fetchFacilityProspectsList', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.facilities.facilityProspectPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const response = await apiRequest.get(`/prospects`,{params})
      let data = response.data.data.map(item => ({
        ...item,
        latlng: [item.lng, item.lat], ...((!!params?.facility_id) && { facility_id: params?.facility_id })
      }))
      const payload = {
        data,
        pagination: Object.keys(params).length > 0 && params.pagination ? { ...params.pagination, total: response.data.meta.total } : {
          ...state.patients.prospectPagination, total: response.data.meta.total
        }
      }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'prospects'), false)
      reject(e)
    }
  })
})
export const fetchFacilities = createAsyncThunk(
  'fetchFacilities',
  async (requestParams, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        let state = thunkAPI.getState();
        let pagination = requestParams?.pagination ? requestParams.pagination : state.facilities.facilitiesPagination;
        let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
        params.page = pagination.current
        const response = await apiRequest.get(`/medical-facilities`, { params });
        let data = response.data.data.map((item) => ({ ...item, latlng: [item.lng, item.lat] }));
        const payload = { data: data, pagination: { ...pagination, total: response.data.meta.total } };
        console.log({ response, payload });
        resolve(payload);
      } catch (e) {
        //console.log(e);
        toastUp(errMsg(e, false), false);
        reject(e);
      }
    });
  });
export const fetchPatientUpdates = createAsyncThunk('fetchPatientUpdates', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.facilities.patientUpdatePagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const response = await apiRequest.get(`/facilities/${params.facility_id}/progress`,{params});
      let data = response.data.data
      const payload = { data, pagination: { ...pagination, total: response.data.meta.total } };
      resolve(payload);
    } catch (e) {
      toastUp(errMsg(e, false), false);
      reject(e);
    }
  });
});
export const createFacility = createAsyncThunk(
  'facility/create',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Creating Office');
      try {
        const response = await apiRequest.post(`/medical-facilities`, payload);
        await thunkAPI.dispatch(fetchFacilities());
        updateToast(toastId, 'Office created', true);
        //facility_created
        ReactGA.event({
          category: "facility_created",
          action: "facility_created",
          label: "facility_created",
          value: 1
        });
        resolve(response.data);
      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);
export const updateFacilityDetails = createAsyncThunk(
  'facility/update',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Updating Office');
      try {
        let dispatch = thunkAPI.dispatch;
        const response = await apiRequest.patch(
          `/medical-facilities/${payload.id}`,
          payload
        );
        let data = response.data;
        await dispatch(fetchFacilityById(payload.id));
        await thunkAPI.dispatch(fetchFacilities());
        updateToast(toastId, 'Office update successful', true);
        //GA4 event facility_updated
        ReactGA.event({
          category: "facility_updated",
          action: "facility_updated",
          label: "facility_updated",
          value: 1
        });
        resolve(data);
      } catch (e) {
        //console.log(e);
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);
export const fetchFacilityById = createAsyncThunk(
  '/faciliyById',
  async (_id, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiRequest.get(`/medical-facilities/${_id}`);
        resolve(response.data.data);
      } catch (e) {
        toastUp(errMsg(e, true, 'facility'), false);
        reject(e);
      }
    });
  }
);

export const fetchFacilityStatsById = createAsyncThunk(  'fetchFacilityStatsById',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiRequest.get(`/facilities/${payload}/stats`);
        console.log({fetchFacilityStatsById:response})
        resolve(response.data.data);
      } catch (e) {
        console.log({fetchFacilityStatsById:e})
        toastUp(errMsg(e, true, 'facility stats'), false);
        reject(e);
      }
    });
  }
);

export const fetchFacilityAppointments = createAsyncThunk(
  '/appointment-stats',
  async (requestParams, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        //console.log('fetching facility appointments');
        const response = await apiRequest.get(`/appointment_stats`);
        let data = response.data.data.appointments;
        resolve(data);
      } catch (e) {
        toastUp(errMsg(e, true, 'appointments'), false);
        reject(e);
      }
    });
  }
);

// export const fetchFacilityPatients = createAsyncThunk(
//   '/patients-stats',
//   async (requestParams, thunkAPI) => {
//     return new Promise(async function(resolve, reject) {
//       try {
//         console.log('fetching facility patients');
//         const response = await apiRequest.get(`/patient_stats`);
//         let data = response.data.data;
//         resolve(data);
//       } catch (e) {
//         toastUp(errMsg(e, true, 'patients'), false);
//         reject(e);
//       }
//     });
//   }
// );

export const fetchStaffFacilityList = createAsyncThunk(
  '/allowed',
  async (_id, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiRequest.get(`staff/allowed-facilities`);

        resolve(response.data.data);
      } catch (e) {
        toastUp(errMsg(e, true, 'facility'), false);
        reject(e);
      }
    });
  }
);

export const fetchFacilityProgress = createAsyncThunk(
  '/progress-stats',
  async (requestParams, thunkAPI) => {
    return new Promise(async function (resolve, reject) {
      try {
        let state = thunkAPI.getState();
        let pagination = requestParams?.pagination ? requestParams.pagination : /*Replace this accordingly in future when this API becomes pageable*/'state.facilities.patientUpdatePagination';
        let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
        params.page = pagination.current
        params['dateRange[start_at]'] = requestParams?.startDate
        params['dateRange[end_at]'] = requestParams?.endDate
        const response = await apiRequest.get(`/facilities/${requestParams?.id}/progress`, { params });
        let data = response.data.data;
        resolve(data);
      } catch (e) {
        toastUp(errMsg(e, true, 'progress'), false);
        reject(e);
      }
    });
  }
);

export const fetchFacilityProspects = createAsyncThunk(
  '/prospects-stats',
  async (requestParams, thunkAPI) => {
    return new Promise(async function (resolve, reject) {
      try {
        //console.log('fetching facility prospects');
        const response = await apiRequest.get(`/prospect_stats`);
        let data = {
          data: response.data.data.prospects,
          pagination: 1,
        }
        resolve(data);
      } catch (e) {
        console.log(e);
        toastUp(errMsg(e, true, 'prospect stats'), false);
        reject(e);
      }
    });
  }
);

export const createFacilityNotes = createAsyncThunk(
  'facility/createNotes',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Creating Office Notes');
      try {
        const response = await apiRequest.post(
          `/medical_facility-treatment_orders/note`,
          payload
        );

        await thunkAPI.dispatch(fetchFacilityNotes({ id: payload.medical_facility_id }));
        updateToast(toastId, 'Office Notes created', true);
        //GA4 event facility_notes_created
        ReactGA.event({
          category: "facility_notes_created",
          action: "facility_notes_created",
          label: "facility_notes_created",
          value: 1
        });
        resolve(response.data);
      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);

export const fetchFacilityNotes = createAsyncThunk(
  '/facilityNotes',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiRequest.get(
          `/medical_facility-treatment_orders/note?facility=${payload.id}`
        );
        //console.log('Notes: ', response.data.data);
        resolve(response.data.data);
      } catch (e) {
        toastUp(errMsg(e, true, 'facility'), false);
        reject(e);
      }
    });
  }
);

export const updateFacilityNote = createAsyncThunk(
  'facility/updateNote',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Updating Office Note');
      try {
        const response = await apiRequest.patch(
          `/medical_facility-treatment_orders/note/${payload.id}`,
          payload
        );
        await thunkAPI.dispatch(fetchFacilityNotes({ id: payload.medical_facility_id }));
        updateToast(toastId, 'Office Note update successful', true);
        //GA4 event facility_notes_updated
        ReactGA.event({
          category: "facility_notes_updated",
          action: "facility_notes_updated",
          label: "facility_notes_updated",
          value: 1
        });
        resolve(response.data);
      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);

export const deleteFacilityNote = createAsyncThunk(
  'facility/deleteNote',
  async (payload, thunkAPI) => {
    return new Promise(async (resolve, reject) => {
      const toastId = getToastId('Deleting Office Note');
      try {
        const response = await apiRequest.delete(`/medical_facility-treatment_orders/note/${payload.id}`);
        await thunkAPI.dispatch(fetchFacilityNotes({ id: payload.facility_id }));
        updateToast(toastId, 'Office Note deleted', true);
        resolve(response.data);
      } catch (e) {
        updateToast(toastId, errMsg(e, false), false);
        reject(e);
      }
    });
  }
);



export const fetchFacilPatients = createAsyncThunk('fetchPatients', async (parms, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const state = await thunkAPI.getState();
      let params = typeof parms === 'object' ? { ...parms } : parms
      let queryString
      let props;
      // console.log('fetchPatientsPagination', state.patients.pagination)
      if (params && params['filterData']) {
        queryString = (params && params['filterData']) ? '?' + queryString.stringify(params['filterData']) : ""
        props = params.pagination ? params : { pagination: state.patients.pagination };
        queryString += `&page=${props.pagination.current}`
      } else {
        props = params && params.pagination ? params : { pagination: state.patients.pagination };
        queryString = props && props.pagination && props.pagination.current ? `?page=${props.pagination.current}` : ""
      }
      let response;
      let data;
      // console.log('queryString', queryString)
      response = await apiRequest.get(`/patients${queryString}`);
      //Add facility_id used to check and avoid redundant API reload
      data = response.data.data.map(item => ({
        ...item,
        latlng: [item.lng, item.lat], ...((!!parms && !!params['filterData']?.facility_id) && { facility_id: params['filterData']?.facility_id })
      }))
      // console.log('props.pagination:  ', props?.pagination)
      const payload = { data, pagination: { ...props.pagination, total: response.data.meta.total } }
      // console.log({payload})
      resolve(payload)
    } catch (e) {
      toastUp(errMsg(e, true, 'patients'), false)
      reject(e)
    }
  })
})

export const facilitiesSlice = createSlice({
  name: 'facilities',
  initialState, // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    newFacility: (state, action) => {
      let newState = newResourceArray(state.facilities, action);
      state.facilities = [...newState];
    },
    facilityUpdated: (state, action) => {
      let newState = updatedArrayState(state.facilities, action, 'id');
      state.facilities = [...newState];
    },
    selectedFacilityUpdated: (state, action) => {
      state.selectedFacility = { ...action.payload };
    }, // Use the PayloadAction type to declare the contents of `action.payload`
    selectFacility: (state, action) => {
      state.selectedFacility = action.payload;
    },
    unsetFacility: (state) => {
      state.selectedFacility = null;
    },
    setpagination: (state, action) => {
      state.pagination = action.payload;
    },
    doResetFacilitySlice: (state) => {
      Object.keys(initialState).map((key) => (state[key] = initialState[key]));
    },
    resetFacilitiesPagination: (state) => {
      state.facilitiesPagination = initialState.facilitiesPagination;
    },
    resetFacilityPatientPagination: (state) => {
      state.facilityPatientPagination = initialState.facilityPatientPagination;
    },
    resetFacilityProspectPagination: (state) => {
      state.facilityProspectPagination =
        initialState.facilityProspectPagination;
    },
    clearFacilityModuleState: (state) => {
      Object.keys(initialState).map((key) => (state[key] = initialState[key]));
    },
    resetPatientUpdatePagination: (state, action) => {
      state.patientUpdatePagination = { ...initialState.patientUpdatePagination }
    },
  },
  extraReducers: (builder)=>{
    builder.addCase(fetchFacilities.fulfilled, (state, action) => {
      state.facilities = [...action.payload.data];
      state.facilitiesPagination = action.payload?.pagination;
    }).addCase(fetchFacilityPatients.fulfilled, (state, action) => {
      state.patients = [...action.payload.data]
      state.facilityPatientPagination = action.payload.pagination
    }).addCase(fetchFacilityProspectsList.fulfilled, (state, action) => {
      state.prospects = [...action.payload.data]
      state.facilityProspectPagination = action.payload.pagination
    }).addCase(fetchPatientUpdates.fulfilled, (state, action) => {
      state.patientUpdates = [...action.payload.data];
      state.patientUpdatePagination = action.payload?.pagination;
    }).addCase(fetchStaffFacilityList.fulfilled, (state, action) => {
      state.allowedFacilities = [...action.payload];
    }).addCase(fetchFacilityById.pending, (state, action) => {
      state.patientUpdates = [];
      state.FacilityLoading = true;
      state.loadingFacilityPatients = true;
      state.facilityPatients = null
    }).addCase(fetchFacilityById.fulfilled, (state, action) => {
      state.selectedFacility = { ...action.payload };
      state.FacilityLoading = false;
    }).addCase(fetchFacilityAppointments.pending, (state, action) => {
      state.facilityAppointments = null;
      state.totalAppointments = null;
      state.loadingFacilityAppointments = true;
    }).addCase(fetchFacilityProgress.pending, (state, action) => {
      state.loadingFacilityPatients = true;
    }).addCase(fetchFacilityProspects.pending, (state, action) => {
      state.loadingFacilityProspects = true;
    }).addCase(fetchFacilityProspects.fulfilled, (state, action) => {
      state.facilityProspects = action.payload.data;
      state.facilityProspectPagination = action.payload?.pagination;  
      state.loadingFacilityProspects = false;
    }).addCase(fetchFacilityProgress.fulfilled, (state, action) => {
      //console.log("Office Patients:::", action.payload)
      state.facilityPatients = action.payload;
      state.loadingFacilityPatients = false;
    }).addCase(fetchFacilityStatsById.fulfilled, (state, action) => {
      state.facilityStats = action.payload;
    }).addCase(fetchFacilityNotes.fulfilled, (state, action) => {
      state.facilityNotes = action.payload;
      state.loadingFacilityNotes = false;
    }).addCase(fetchFacilityNotes.pending, (state, action) => {
      state.loadingFacilityNotes = true;
    })
  }
});
export const {
  selectFacility,
  doResetFacilitySlice,
  clearFacilityModuleState,
  selectedFacilityUpdated,
  resetFacilitiesPagination,
  resetFacilityProspectPagination,
  resetFacilityPatientPagination,
  newFacility,
  facilityUpdated, resetPatientUpdatePagination,
  unsetFacility
} = facilitiesSlice.actions;
export default facilitiesSlice.reducer;
