/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Subtitle1 } from '../../components'
import HeaderTop from '../../components/HeaderTop'
import { setMapNavs } from "../../app/Reducers";
import { Tabs } from "antd";
import { usePermissionCheck } from '../../hooks/usePermissionCheck';
import Map from '../../components/map';
import ZonalMap from './ZonalMap';
import ScheduleMap from './ScheduleMap';
import LocationMap from './LocationMap';
import { fetchAllMacs, fetchAllMarketingRegions, fetchAllStateBoundaries } from '../../app/Reducers/mapSlice';

const { TabPane } = Tabs;
export const Index = ({ navs, activeUser }) => {
    const dispatch = useDispatch()
    const setActiveTab = async (tab) => {
        await dispatch(setMapNavs({ tab: tab }))
    }

    useEffect(() => {
        if (activeUser?.facilityProfile?.id) {
            setActiveTab('Schedule')
        }
    }, [activeUser])
    useEffect(() => {
        dispatch(fetchAllMacs())
        dispatch(fetchAllMarketingRegions())
        dispatch(fetchAllStateBoundaries())
    }, [])
    const can_view_admission = usePermissionCheck('view_admission', false, [])
    const can_view_schedule = usePermissionCheck('view_pre_scheduled', false, [])

    const TabList =["Live Map","Location", "Zonal"]
    const Tabs_ = () => {
        return (<Tabs defaultActiveKey={navs.mapNavs.tab} onChange={setActiveTab} tabBarStyle={{ marginBottom: 0 }}>
            {TabList.map(item => <TabPane key={item} tab={<Subtitle1>{item}</Subtitle1>}>
            </TabPane>)}
        </Tabs>)
    }
    return (<div className="main-page ">
        <HeaderTop child={<Tabs_ />} customStyle={{ style: { paddingBottom: 0 } }} />
        {navs.mapNavs?.tab === 'Live Map' ? <Map /> : navs.mapNavs.tab === 'Location' ? <LocationMap /> : navs.mapNavs.tab === 'Zonal' ? <ZonalMap />  : null}
     
    </div>)
}

const mapStateToProps = (state) => ({
    navs: state.navs, activeUser: state.auth?.activeUser
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Index)

