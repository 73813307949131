export function UpArrow() {
  return (
    <div style={{ width: '17px', height: '12px', marginRight: '10px' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 20 20'
        fill='#5ACA75'
        stroke='#5ACA75'
      >
        <path
          fillRule='evenodd'
          d='M3.22 16.78a.75.75 0 001.06 0L15.5 5.56v7.69a.75.75 0 001.5 0v-9.5a.75.75 0 00-.75-.75h-9.5a.75.75 0 000 1.5h7.69L3.22 15.72a.75.75 0 000 1.06z'
          clipRule='evenodd'
        />
      </svg>
    </div>
  );
}

export function DownArrow() {
  return (
    <div style={{ width: '17px', height: '12px', marginRight: '10px' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 20 20'
        fill='#f5222d'
        stroke='#f5222d'
      >
        <path
          fillRule='evenodd'
          d='M16.78 3.22a.75.75 0 00-1.06 0L4.5 14.44V6.75a.75.75 0 00-1.5 0v9.5c0 .414.336.75.75.75h9.5a.75.75 0 000-1.5H5.56L16.78 4.28a.75.75 0 000-1.06z'
          clipRule='evenodd'
        />
      </svg>
    </div>
  );
}

export function VolumeDownArrow() {
  return (
    <div style={{ width: '17px', height: '12px', marginRight: '10px' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 20 20'
        fill='#5ACA75'
        stroke='#5ACA75'
      >
        <path
          fillRule='evenodd'
          d='M16.78 3.22a.75.75 0 00-1.06 0L4.5 14.44V6.75a.75.75 0 00-1.5 0v9.5c0 .414.336.75.75.75h9.5a.75.75 0 000-1.5H5.56L16.78 4.28a.75.75 0 000-1.06z'
          clipRule='evenodd'
        />
      </svg>
    </div>
  );
}

export function VolumeUpArrow() {
  return (
    <div style={{ width: '17px', height: '12px', marginRight: '10px' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 20 20'
        fill='#f5222d'
        stroke='#f5222d'
      >
        <path
          fillRule='evenodd'
          d='M3.22 16.78a.75.75 0 001.06 0L15.5 5.56v7.69a.75.75 0 001.5 0v-9.5a.75.75 0 00-.75-.75h-9.5a.75.75 0 000 1.5h7.69L3.22 15.72a.75.75 0 000 1.06z'
          clipRule='evenodd'
        />
      </svg>
    </div>
  );
}

export function CopyPasteIcon() {
  return (
    <div style={{ width: '30px', height: '30px' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        fill='none'
        stroke='#A7A9BC'
        className='w-5 h-5'
      >
        <path
          fillRule='evenodd'
          d='M15.988 3.012A2.25 2.25 0 0118 5.25v6.5A2.25 2.25 0 0115.75 14H13.5V7A2.5 2.5 0 0011 4.5H8.128a2.252 2.252 0 011.884-1.488A2.25 2.25 0 0112.25 1h1.5a2.25 2.25 0 012.238 2.012zM11.5 3.25a.75.75 0 01.75-.75h1.5a.75.75 0 01.75.75v.25h-3v-.25z'
          clipRule='evenodd'
        />
        <path
          fillRule='evenodd'
          d='M2 7a1 1 0 011-1h8a1 1 0 011 1v10a1 1 0 01-1 1H3a1 1 0 01-1-1V7zm2 3.25a.75.75 0 01.75-.75h4.5a.75.75 0 010 1.5h-4.5a.75.75 0 01-.75-.75zm0 3.5a.75.75 0 01.75-.75h4.5a.75.75 0 010 1.5h-4.5a.75.75 0 01-.75-.75z'
          clipRule='evenodd'
        />
      </svg>
    </div>
  );
}

export function PersonIcon() {
  return (
    <div style={{ width: '30px', height: '30px' }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 20 20'
        fill='#A7A9BC'
        className='w-5 h-5'
      >
        <path d='M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z' />
      </svg>
    </div>
  );
}
