import {useEffect, useRef, useState} from 'react';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector, Tooltip} from 'recharts';
import {DatePicker, Skeleton, Spin} from 'antd';
import dayjs from 'dayjs';
import {apiRequest} from '../../../../app/Apis';
import {emptyFun} from '../../../../common/helpers';

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}> {payload.name}</text>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill}/>
      <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + -2} outerRadius={outerRadius + 20} fill="#4B9BFF" opacity={0.4}/>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">{`${Math.floor(percent * 100)}%`}</text>
    </g>
  );
};

let defaultAppointmentData = [
  {
    "name": "Scheduled",
    "value": 0,
    "fill": "#2E5AAC"
  },
  {
    "name": "Completed",
    "value": 0,
    "fill": "#50D2A0"
  },
  {
    "name": "Missed",
    "value": 0,
    "fill": "#FF9F5F"
  },
  {
    "name": "In Progress",
    "value": 0,
    "fill": "#FFC04F"
  },
  {
    "name": "Cancelled",
    "value": 0,
    "fill": "#FF5F5F"
  }
]
export default function NewAppointmentChart({ appointments, facility, filterState }) {
  const dateFormat = 'YY-MM-DD';
  const { RangePicker } = DatePicker;
  const [appointmentData, setAppointmentData] = useState(generatePieData(appointments));
  const [startDate, setStartDate] = useState(dayjs().subtract(5, 'days').format(dateFormat));
  const [endDate, setEndDate] = useState(dayjs().format(dateFormat));
  const [dateValues, setDateValues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const spaceRef = useRef(null);
  const disabledFutureDates = (current) => {
    if (!dateValues) {
      return false;
    }
    
    let filterByValue = filterState === 'Week' ? 7 : 30;
    const tooLate = dateValues[0] && current.diff(dateValues[0], 'days') > filterByValue;
    const tooEarly = dateValues[1] && dateValues[1].diff(current, 'days') > filterByValue;
    return !!tooEarly || !!tooLate;
  };
  console.log({ appointmentData, appointments, isLoading })
  
  async function fetchAppointmentData(start, end) {
    try {
      await setIsLoading(true)
      const { data } = await apiRequest.get(`/facilities/${facility?.id}/stats`, { params: { appointment_sd: start, appointment_ed: end } });
      let filtered = data?.data?.appointments_by_status;
      console.log('Filtered Appointments', filtered);
      if (filtered[0]) {
        let chartData = generatePieData(filtered)
        console.log({ chartData })
        await setAppointmentData(chartData);
      } else {
        await setAppointmentData([]);
      }
      await setIsLoading(false)
    } catch (e) {
      console.error(e)
      await setIsLoading(false)
    }
  }
  
  
  const onChange = async (value) => {
    if (value !== null) {
      console.log({ value });
      setDateValues(value);
      
      let start = dayjs(value[0])?.format(dateFormat);
      let end = dayjs(value[1])?.format(dateFormat);
      setStartDate(start);
      setEndDate(end);
      
      console.log('Fetching Appointment Data1', facility);
      await fetchAppointmentData(start, end);
    } else {
      console.log('Resetting Appointment Data1', facility);
      setDateValues(null);
      setAppointmentData([]);
    }
  };
  useEffect(() => {
    console.log('Fetching Appointment Data2', facility);
    fetchAppointmentData(startDate, endDate).catch((err) => console.log(`Damn: ${err}`));
    return emptyFun;
  }, [appointments, facility, filterState]);
  
  
  function generatePieData(data) {
    if (data?.length) {
      const pieData = data?.reduce((acc, curr) => {
        acc.scheduled += curr.scheduled;
        acc.completed += curr.completed;
        acc.missed += curr.missed;
        acc.in_progress += curr.in_progress;
        acc.cancelled += curr.cancelled;
        
        return acc;
      }, { scheduled: 0, completed: 0, missed: 0, in_progress: 0, cancelled: 0, total: 0 });
      
      const pieDataArray = [
        { name: 'Scheduled', value: pieData.scheduled, fill: '#2E5AAC' },
        { name: 'Completed', value: pieData.completed, fill: '#50D2A0' },
        { name: 'Missed', value: pieData.missed, fill: '#FF9F5F' },
        { name: 'In Progress', value: pieData.in_progress, fill: '#FFC04F' },
        { name: 'Cancelled', value: pieData.cancelled, fill: '#FF5F5F' }
      
      ];
      return pieDataArray;
    } else {
      return []
    }
  }
  
  function CustomTooltip({ payload, label, active }) {
    if (active) {
      return (
        <div className="bg-white bg-opacity-70 border-solid border-gray-500 border rounded-lg p-4">
          <p className="font-semibold">ⓘ Empty Chart</p>
          <p className="text-gray-700">No appointment was found for all statuses</p>
        </div>
      );
    }
    
    return null;
  }
  
  return (
    <>
      {!isLoading ?
        <div>
          <div>
            <ResponsiveContainer width="100%" height={300} className={'pt-20'}>
              <PieChart width={300} height={300}>
                <Pie
                  data={appointmentData[0] ? appointmentData : [{ name: 'No Appointments for all statuses', value: 1, fill: 'gray' }]}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  innerRadius={60}
                  fill="#8884d8"
                  {...(appointmentData[0] && { label: true })}
                >
                  {appointmentData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.fill}/>
                  ))}
                </Pie>
                <Tooltip {...(!appointmentData[0] && { content: <CustomTooltip/> })} />
                
                <Legend align="left" iconType="circle" margin={10}/>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div ref={spaceRef} className="mt-6 relative" style={{ display: 'flex-reverse', flexDirection: 'row' }}>
            <RangePicker getPopupContainer={() => spaceRef.current}
                         {...(startDate && endDate) && { defaultValue: [dayjs(startDate, dateFormat), dayjs(endDate, dateFormat)] }}
                         popupStyle={{ transform: "translateX(-50%)", left: "20px" }}
                         format={"MM/DD/YY"}
                         onChange={(dateStrings) => onChange(dateStrings)} disabledDate={disabledFutureDates}
                         onCalendarChange={(dateStrings) => setDateValues(dateStrings)}
                         allowClear={true}
                         style={{ borderRadius: '7px', cursor: 'pointer', marginTop: '-25px' }}/>
          </div>
        </div>
        :
        <div>
          <Spin spinning={isLoading} style={{ color: 'blue' }}/>
          <Skeleton active/>
        </div>
      }
    </>
  );
}
