/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import SearchComponent from './searchComponent/ElasticSearchComponent'

export const MapSearch = ({ goToNYC }) => {
  const [isCollapsed, setCollapsed] = useState(true)
  return (<div className="map-search flex-boy-row-space">
    <SearchComponent goToNYC={goToNYC} />
  </div>)
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(MapSearch)
