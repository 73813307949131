import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, Col, Divider, Dropdown, Form, Input, Menu, Modal, Popconfirm, Row, Select, Spin } from 'antd';
import AssessmentCard from './AssessmentCard'
import {
  deleteAssessment,
  fetchPatientAppointments,
  fetchSpecificAppointment,
  getWoundEncounters,
  resetPatientModuleState,
  resetPatientNavs,
  selectAppointment,
  selectAssessment,
  selectPatient,
  updateAppointment
} from '../../../app/Reducers'
import dayjs from 'dayjs'
import _ from "lodash"
import { v4 as uuidv4 } from 'uuid';
import NewWound from './NewWound'
import { apiRequest } from '../../../app/Apis'
import EditAssessmentCard from './EditAssessmentCard'
import { toast } from 'react-toastify'
import { addButton, appConfirm, containedButton,dialogCloser,dialogOpener, emptyFun,formSubmitButton,getAppointmentIndicator,getAppointmentIndicatorColor,horStack, boldKeyCapitalizedValue, keyValItemCaps,
  boldKeyCapitalizedValueGrayBackground, boldKeyUntransformedValue, modalClose, outlinedButton,textButton,  editButton
} from "../../../common/helpers";
import { dismissToast, errMsg, getToastId, toastUp, updateToast } from "../../../app/Reducers/reducerUtils";
import querystring from "querystring";
import { Headline6, Indicator, Subtitle1 } from "../../../components";
import { genAllograftReport, generateLabRequisition, genReport } from "../../../app/Apis/commonApi";
import VisitStatus from "./VisitStatus";
import VisitDetails from "./VisitDetails";
import PdfViewer from "../../../components/PdfViewer";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import { useSubscription } from "../../../hooks/MqttHooks/useSubscription";
import * as Sentry from "@sentry/react";
import {
  CaretDownOutlined, CloseOutlined,
  createFromIconfontCN,
  DeleteOutlined, EyeOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  MinusOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
  SmileOutlined,
  WarningOutlined
} from "@ant-design/icons";
import NextVisit from "./NextVisit";
import ReactGA from "react-ga4";
import { useLocation, useNavigate} from 'react-router-dom';
import DynamicForm from '../../../components/DynamicForm';
import { usePermissionCheck } from '../../../hooks/usePermissionCheck';

import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import advanced from "dayjs/plugin/advancedFormat"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import AppointmentNotes from './AppointmentNotes';


dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

const vitals_list = [
  { title: 'height', units: 'inches' }, { title: 'weight', units: 'lbs' }, { title: 'bmi', units: null },
  { title: 'respiration', units: null }, { title: 'heart_rate', units: 'bpm' }, { title: 'temperature', units: 'oF' },
  { title: 'blood_pressure', units: 'systolic' }, { title: 'diastolic_blood_pressure', units: 'diastolic' }
]
const AppointmentDetailsPage = ({ appointment, procedures, patientInitialAppointment, patientAppointments, navs, activeUser, masterList }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const can_update_appointment = usePermissionCheck('update_appointment', false, [])
  const can_join_telehealth_call = usePermissionCheck('join_telehealth_call', false, [])
  const can_create_patient_vitals = usePermissionCheck('create_patient_vitals', false, [])
  const can_update_jurisdiction_discrepancy = usePermissionCheck('update_jurisdiction_discrepancy', false, [])
  const { Option } = Select;
  const dispatch = useDispatch()
  const [assessmentModal, setassessmentModal] = useState(false)
  const [editassessmentModal, seteditassessmentModal] = useState(false)
  const [woundModal, setwoundModal] = useState(false)
  const [creatingWound, setCreatingWound] = useState(false)
  const [creatingAppointment, setCreatingAppointment] = useState(false)
  const [assessmentWoundProcedure, setassessmentwoundProcedure] = useState('')
  const [assessmentWound, setassessmentwound] = useState(null)
  const [assessment, setassessment] = useState(null)
  const { TextArea } = Input;
  // const [appointmentLoading, setappointmentLoading] = useState(true)
  const [previousWounds, setpreviousWounds] = useState(null)
  const [form] = Form.useForm();
  const [billingform] = Form.useForm();

  const [appointmentWounds, setappointmentWounds] = useState([])
  const [woundAssessment, setWoundAssessment] = useState([])
  const [VisitModal, setVisit] = useState(false)
  const [visitStatusModal, setStatusModal] = useState(false)
  const [isPrinting, setPrinting] = useState(false)
  const [isLabPrinting, setLabPrinting] = useState(false)
  const [isAllograftPrinting, setAllograftPrinting] = useState(false)
  const [patient, setPatient] = useState()
  const [isExecuting, setisExecuting] = useState(false)
  const [pdfShowing, setPdfShowing] = useState(false);
  const [pdfLoading, setLoading] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [cancelingAppointment, setCancelingAppointment] = useState(false);
  const appointmentId = Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [cancellationForm] = Form.useForm();
  const [visitApprovalStatus, setVisitApprovalStatus] = useState(appointment?.visit_approval_status);
  const [typeOfKit, setTypeOfKit] = useState(appointment?.type_of_kit);
  const [notes, setNotes] = useState(appointment?.note?.notes);
  const [editNotes, setEditNotes] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = React.useState(null);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [editingForm, setEditingForm] = useState(false);
  const [ploading, setpLoading] = useState(false);
  const [wound_, setWound_] = useState(null);
  const [labTestModal, setlabTestModal] = useState(false);
  const [showdisaprovalreasonModal, setShowdisaprovalreasonModal] = useState(false);
  const [billingFormInitialValues, setBillingFormInitialValues] = useState({ ...appointment, notes: appointment?.note?.notes })
  const [disapprovalReason, setDisapprovalReason] = useState(false)
  const [assessmentTodeleteID, setassessmentTodeleteID] = useState(null)
  const [procedureTodelete, setprocedureTodelete] = useState(null)
  const [reasonStatus, setreasonStatus] = useState(null)
  const [reasonModal, setreasonModal] = useState(false)
  const [woundOnly, setwoundOnly] = useState(null)
  const [nextVisit, SetNextVisit] = useState(false)
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [newAppointmentStatus, setNewAppointmentStatus] = useState(appointment?.status);
  const [showStatusForm, setShowStatusForm] = useState(false);
  const [vitals, setvitals] = useState(null)
  const [open, setOpen] = useState(false);
  const [loadingProcedures, setloadingProcedures] = useState(false)

  const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_complete_appointment = usePermissionCheck('complete_appointment', false, [])
  const can_view_appointment = usePermissionCheck('view_appointment', false, [])
  const can_create_assessment = usePermissionCheck('create_assessment', false, [])
  const can_delete_assessment = usePermissionCheck('delete_assessment', false, [])
  const can_update_appointment_procedure = usePermissionCheck('update_appointment_procedure', false, [])
  const can_verify_appointment = usePermissionCheck('verify_appointment_for_billing_and_insurance', false, [])
  
  const GetOptions = (system_name) => {

    const { Option } = Select;
    return <>
      {masterList.find(item => item.system_name === system_name)?.options?.map(item => <Option
        value={item}>{item.toString().replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}</Option>) || []}
    </>;

  }
  useEffect(() => {
    //this means navigation from a patients initial appointment?. Reload
    (async () => {
      //console.log({ patientInitialAppointment, appointmentId, appointment })
      if (!!patientInitialAppointment && patientInitialAppointment?.id !== appointmentId) {
        try {
          await dispatch(fetchSpecificAppointment(appointmentId)).unwrap()
        } catch (e) {
          console.log(e)
        }
        if (can_view_appointment) {
          navigate(`/appointments/${appointmentId}`)
        }
      }
    })().catch(e => console.log(e))
    return emptyFun
  }, [appointmentId])

  const { message: vitalsMessage } = useSubscription(`view_patient_vitals/${appointment?.patient?.id}`, { qos: 2, nl: true });
  useEffect(() => {
    if (!!vitalsMessage) {
      //console.log('MQTT MESSAGE:  ', vitalsMessage)
      fetchvitals().catch(e => console.log(e))
    }
    return emptyFun
  }, [vitalsMessage])
  const { message: appointmentMessage } = useSubscription(`view_appointment/${appointment?.id}`, { qos: 2, nl: true });
  useEffect(() => {
    if (!!appointmentMessage) {
      //console.log('MQTT MESSAGE:  ', appointmentMessage)
      loadPageData(false).catch(e => console.log(e))
      dispatch(fetchSpecificAppointment(appointmentId))
    }
    return emptyFun
  }, [appointmentMessage])

  const approveAsessment = async () => {
    let defParam = { filterData: { wound: wound_.id } }
    await setisExecuting(true)
    const toastId = toast("Updating Assesment", { type: "info", autoClose: false });
    const response = await apiRequest.post(`/assessments/${woundAssessment.id}/status`, { status: "approved" }).catch(async function (err) {
      await setisExecuting(false)
      //console.log(err.response.data.message)
      toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
      await dispatch(getWoundEncounters(defParam))
    })
    if (response && response.data) {
      toast.update(toastId, { render: "Assessment Approved", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      await dispatch(getWoundEncounters(defParam))
    }
    await setisExecuting(false)
    setVisit(false)
    setStatusModal(false)
  }
  const generateReport = () => genReport(woundAssessment, setPrinting, setVisit, setStatusModal, setPdf, setLoading, setPdfShowing)
  const generateLabRequest = () => generateLabRequisition(woundAssessment, setLabPrinting, setVisit, setStatusModal, setPdf, setLoading, setPdfShowing)
  const generateAllograftReport = () => genAllograftReport(woundAssessment, setAllograftPrinting, setVisit, setStatusModal, setPdf, setLoading, setPdfShowing)
  const getMenus = (type) => {
    return [
      ...(type !== "new" ? [{ title: 'View Assessment', icon: <EyeOutlined /> }] : []),
      ...(((type === "new" && can_update_appointment_procedure) || can_delete_assessment) ? [{
        title: 'Delete',
        icon: <DeleteOutlined fontSize={"small"} style={{ color: 'grey' }} />
      }] : []),
      { title: 'Dismiss', icon: <CloseOutlined /> }]
  }
  const showPopconfirm = (selected) => setSelected(selected)
  const handleCancel = () => setSelected(null)

  async function handleMenuClick(e, wound_, procedure, type, woundOnly) {
    let menus = getMenus(type)
    //console.log('Menus: ', menus, e.key, procedure, type)
    setassessmentwoundProcedure(procedure?.procedure_type)
    await setWound_(wound_)
    switch (menus[e.key].title) {
      case 'View Assessment':
        await setEditingForm(true)
        // await setAllergiesForm(true)
        let toastId = getToastId('Loading latest assessment')
        try {
          let assessment = appointment?.previous_assessments.find(item => item.wound_id === wound_.id && item.procedure === procedure.procedure_type)
          let defParam = { filterData: { wound: wound_.id, procedure: procedure.procedure_type } }
          let queryString = querystring.stringify({ ...defParam['filterData'], limit: 1 })
          const encounters = await apiRequest.get(`/assessments/${assessment.id}`)
          let wAssessment = encounters.data.data
          await setWoundAssessment(wAssessment)
          // console.log('selectAssessment1')
          await dispatch(selectAssessment(wAssessment))
          updateToast(toastId, 'Success', true)
          if (!wAssessment) {
            updateToast(toastId, errMsg(e, true, null, 'No assessment was found for this wound'), false)
          } else {
            setVisit(true)
          }
        } catch (e) {
          Sentry.captureException(e);
          console.log(e)
          updateToast(toastId, errMsg(e, true, 'wound assessment'), false)
        }
        break
      case 'Delete':
        //console.log('Delete:  ', wound_)
        //Append 'wound' to differentiate onClick for wound/procedure and procedure based on woundOnly. i.e woundOnly means deleting entire wound and all associated procedures if new
        showPopconfirm(`${procedure?.procedure_type}${wound_.id}${woundOnly ? 'wound' : ''}`)
        break
      default:
        await setEditingForm(false)
    }
  }

  const menu = (wound, procedure, type, woundOnly) => {
    return <Menu onClick={(e) => handleMenuClick(e, wound, procedure, type, woundOnly)}>{getMenus(type).map((item, index) => <Menu.Item key={index}
      icon={item.icon}>{item.title}</Menu.Item>)}</Menu>
  }
  const editAssessment = (wound, procedure) => {
    if (procedure.procedure_type !== '') {
      //console.log(wound)
      showEditassessmnt(wound, procedure.procedure_type)
    }
  }
  const startAssessment = async (wound, procedure) => {
    // START ------------ close DME popConfirm if it was trigerred
    await setSelected(null)
    await setOpen(false)
    // END--------------------
    if (procedure.procedure_type !== '') {
      showassessmnt(wound, procedure.procedure_type)
    }
  }
  const confirm = () => {
    setOpen(false);
    // message.success('Next step.');
  };
  const AssessmentWoundProcedure = ({ type, procedure, wound }) => {
    let hasPreviousAssessments = appointment?.previous_assessments?.find(item => item?.wound_id === wound?.id && item?.procedure === procedure?.procedure_type)?.published_at
    const allowStart = isDMEEligible(wound)
    const cancelled = appointment?.status?.toLowerCase()?.includes('cancel')
    return <div className="flex-boy-row-space" style={{ width: "100%" }}>
      {!procedure.procedure_type ? <Select
        popupMatchSelectWidth={false}
        value={procedure.procedure_type_place_holder}
        style={{ flex: 1, marginRight: "20px" }}
        onChange={(e) => selectProcedreType(wound.id, procedure.id, e)}>

        {/* {appointment?.appointment_visit_type === "established visit" && wound.last_visit && !wound.procedures.map(proc => proc.procedure_type).some(x => x.includes('Biologics')) && !wound.procedures.map(proc => proc.procedure_type).includes("Biologics Application") && <Option value={"Biologics Application"}>Biologics Application</Option>} */}
        {/* {wound.procedures.find(item => (item.procedure_type === "Biologics" || item.procedure_type === "Biologics Application") && !item.is_new) && !wound.procedures.map(proc => proc.procedure_type).includes("Biologics Ordering") ? <Option value={"Biologics Ordering"}>Biologics Ordering</Option> : null} */}


        {wound.procedures.find(proc => proc.procedure_type === "Wound Evaluation" && !proc.is_new) ? procedures.filter(item => item.name !== "Plan Of Care" &&
          !wound.procedures.map(proc => proc.procedure_type).includes(item.name)).map((item, index) =>
            <Option key={index} value={item.name}>{item.name}</Option>) : <Option value={"Wound Evaluation"}>Wound Evaluation</Option>}

        {/* {procedures.filter(item => item.name !== "Lab" && !wound.procedures.map(proc => proc.procedure_type).includes(item.name)).map((item, index) => <Option key={index} value={item.name}>{item.name}</Option>)} */}
      
      </Select> : <p className={'mr-[20px] flex-1 text-start font-semibold'}>{procedure.procedure_type}</p>}
      {procedure.procedure_type && can_create_assessment ? type === "new" ?
        <Popconfirm
          title="Are you sure to start assessment?"
          description="It should take at least 21 days from the last DME order for the patient to be eligible for the next order. Would you still like to proceed?"
          onConfirm={() => startAssessment(wound, procedure)}
          open={open && selected === `${procedure.procedure_type}${wound.id}-start`}
          onOpenChange={async (newOpen) => {
            if (!newOpen) {
              // Just return since the popup state is managed manually
              return;
            }
            // Determining whether to directly start assessment or show DME warning message via the popConfirm
            // console.log({ allowStart, newOpen, selected });
            //Allow this to apply only for DME assessments
            if (allowStart || procedure.procedure_type !== 'DME') {
              startAssessment(wound, procedure); // next step
            } else {
              await setSelected(`${procedure.procedure_type}${wound.id}-start`)
              await setOpen(newOpen);
            }
          }}
          onCancel={async () => {
            // console.log('Cancelling')
            await setSelected(null)
            await setOpen(false)
          }}
          okText="Yes"
          cancelText="No"
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
          {containedButton(null, 'Start Assessment', false, "loading", { marginRight: 10, width: '136px', height: '40px', borderRadius: '4px', display: "flex", justifyContent: 'centre' },
            { disabled: cancelled })}
        </Popconfirm> :
        hasPreviousAssessments
          ? outlinedButton(() => editAssessment(wound, procedure), 'Edit Assessment', false,  { marginRight: 10, width: '136px',height: '40px', borderRadius: '4px', display: "flex", justifyContent: 'centre'})
          : containedButton(() => editAssessment(wound, procedure), 'Complete Draft   ', false, 'gray', { marginRight: 10, width: '136px',height: '40px', borderRadius: '4px', display: "flex", justifyContent: 'centre' }) : null}
      {!activeUser?.facilityProfile && <Dropdown dropdownRender={()=>menu(wound, procedure, type)} trigger={['click']} disabled={selected === `${procedure.procedure_type}${wound.id}`}>
        {appConfirm(
          <MoreOutlined />, selected === `${procedure.procedure_type}${wound.id}`, null, `Are you sure of deleting this ${hasPreviousAssessments ? 'assessment' : 'procedure'}?`, () => handleOk(wound.id, procedure), handleCancel, confirmLoading)}
          
      </Dropdown>}
    </div>
  }
  const showassessmnt = (wound, type) => {
    setassessmentwound(wound)
    setassessmentwoundProcedure(type)
    setassessmentModal(true)
  }
  const showEditassessmnt = async (wound, type) => {
    let assessment_ = appointment?.previous_assessments.find(item => item.wound_id === wound.id && item.procedure === type)
    // console.log({assessment_, wound})
    await setassessment(assessment_)
    await setassessmentwound(wound)
    await setassessmentwoundProcedure(type)
    // console.log('selectAssessment2')
    // console.log(assessment, woundAssessment)
    await dispatch(selectAssessment(!!assessment_ ? assessment_ : woundAssessment))
    //console.log(appointment?.previous_assessments, appointment?.previous_assessments.find(item => item.wound_id === wound.id && item.procedure === type))
    seteditassessmentModal(true)
  }

  function toString(obj) {
    return _.cloneDeepWith(obj, value => {
      return !_.isPlainObject(value) ? _.toString(value) : undefined;
    });
  }

  useEffect(() => {
    // console.log({appointment})
    loadPageData(false).catch(e => console.log(e))
    return emptyFun
  }, [appointment])


  const loadPageData = async (isMQ) => {
    if (!appointment) {
      navigate('/appointments')
    } else {
      // if (appointment?.status === "completed") {
      await fetchvitals().catch(e => console.log(e))
      // }
      await dispatch(fetchPatientAppointments(appointment?.patient.id))
      // setappointmentLoading(!isMQ)
      let previous_wounds_list = appointment?.previous_assessments ? [...new Set(appointment?.previous_assessments.map(item => item.wound_id))] : []

      let wounds = appointment?.wounds?.filter(item => !previous_wounds_list.includes(item.id))

      wounds = wounds?.map((item) => {
        let wound = { ...item, previous: false }
        wound.procedures = item.recommended_procedure.map(item => ({ id: uuidv4(), procedure_type: item, is_new: true }))
        return wound
      })
      let previous_wounds = appointment?.wounds?.filter(item => previous_wounds_list.includes(item.id)).map((item) => {
        let wound = { ...item, previous: true }
        wound.procedures = item.recommended_procedure.map(item => ({
          id: uuidv4(), procedure_type: item, is_new: !appointment?.previous_assessments.find(ass => ass.procedure === item && ass.wound_id === wound.id)
        }))

        return wound
      })
      setappointmentWounds(_.orderBy([...(wounds ? wounds : []), ...(previous_wounds ? previous_wounds : [])], "created_at", "desc"))
      setpreviousWounds(_.orderBy([...(wounds ? wounds : []), ...(previous_wounds ? previous_wounds : [])], "created_at", 'desc'))
      await setVisitApprovalStatus(appointment?.visit_approval_status)
      await setTypeOfKit(appointment?.type_of_kit)
    }
  }
  useEffect(() => {
    (async () => {
      await dispatch(fetchSpecificAppointment(appointmentId))
    })().catch(e => console.log(e))
    return emptyFun
  }, [])
  const fetchvitals = async () => {
    try {
      const response = await apiRequest.get(`/patient-vitals?appointment=${appointment?.id}`).catch(err => console.log(err))
      if (response && response.data && response.data.data && response.data.data.length) {
        setvitals(response.data.data[0])
        form.setFieldsValue({ ...response.data.data[0] })
        form.setFieldsValue({
          bmi: isNaN(((parseFloat(response.data.data[0].weight) / ((parseFloat(response.data.data[0].height) ** 2))) * 703).toFixed(2)) ? 0 : ((parseFloat(response.data.data[0].weight) / ((parseFloat(response.data.data[0].height) ** 2))) * 703).toFixed(2)
        })
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e)
      toastUp(errMsg(e, true, 'vitals'), false)
    }
  }
  const addProcedure = (woundId) => {
    let wounds = [...appointmentWounds]
    let index = wounds.findIndex(item => item.id === woundId)
    wounds[index].procedures.push({ id: uuidv4(), procedure_type: '', is_new: true })
    setappointmentWounds(wounds)

  }
  //Check if the wound has any procedure left else delete the entire wound.
  const removeProcedure = async (wound, procedure, woundOnly) => {
    try {
      //console.log('wound: ', wound)
      //console.log('procedure: ', procedure)
      let remainingProcedures = wound.procedures.filter(item => item.procedure_type !== procedure.procedure_type)
      //console.log('remainingProcedures: ', remainingProcedures)
      /*if (wound) {
        return
      }*/
      if (remainingProcedures.length > 0 && !woundOnly) {
        await updateWoundProcedures({ ...wound, procedures: remainingProcedures })
      } else if (woundOnly) {
        await apiRequest.delete(`/appointments/${appointment?.id}/wounds/${wound.id}`)
      } else {
        await updateWoundProcedures({ ...wound, procedures: remainingProcedures })
      }
      let wounds = [...appointmentWounds]
      let index = wounds.findIndex(item => item.id === wound.id)
      const procedureindex = wounds[index].procedures.findIndex(item => item.id === procedure.id)
      if (procedureindex > -1) {
        wounds[index].procedures.splice(procedureindex, 1);
      }
      setappointmentWounds(wounds)
    } catch (e) {
      Sentry.captureException(e);
      console.log(e)
      toastUp(errMsg(e, false, null, 'Unable to update assessment info'), false)
    }
  }

  const selectProcedreType = (wound, procedure, value) => {
    let wounds = [...appointmentWounds]
    let index = wounds.findIndex(item => item.id === wound)
    const procedureindex = wounds[index].procedures.findIndex(item => item.id === procedure)
    //console.log(wounds, procedureindex)
    if (procedureindex > -1) {
      let new_wound = JSON.parse(JSON.stringify(wounds[index]))
      wounds[index].procedures[procedureindex].procedure_type_place_holder = value
      new_wound.procedures[procedureindex].procedure_type = value
      // console.log(new_wound)
      updateWoundProcedures(new_wound)
    }
    // setappointmentWounds(wounds)
    setloadingProcedures(wounds[index])
  }


  const updateWoundProcedures = async (wound) => {
    let data = { recommended_procedure: wound.procedures.map(item => item.procedure_type), wound_id: wound.id }
    const toastId = getToastId("Updating wound procedures")
    try {
      await apiRequest.post(`/appointments/${appointment?.id}/procedures`, data)
      refetch()
      updateToast(toastId, "Update complete", true)
    } catch (e) {
      Sentry.captureException(e);
      console.log(e)

      refetch()
      updateToast(toastId, errMsg(e, false, null, 'Unable to update wound procedures'), false)


    }
  }


  const refetch = async () => {
    //console.log("refetching")
    const response = await apiRequest.get(`/appointments/${appointment?.id}`);
    const assessments = await apiRequest.get(`appointments/${appointment?.id}/assessments?page_size=150`);
    let data = response.data.data;
    data.previous_assessments = assessments.data.data;
    data.wounds = data.wounds || [];
    let appointment_ = data
    if (!appointment_) {
      navigate('/appointments')
    } else {
      // if (appointment?.status === "completed") {
      fetchvitals().catch(e => console.log(e))
      // }
      await dispatch(fetchPatientAppointments(appointment_.patient.id))
      // setappointmentLoading(!isMQ)
      let previous_wounds_list = appointment_.previous_assessments ? [...new Set(appointment_.previous_assessments.map(item => item.wound_id))] : []

      let wounds = appointment_.wounds.filter(item => !previous_wounds_list.includes(item.id))

      wounds = wounds.map((item) => {
        let wound = { ...item, previous: false }
        wound.procedures = item.recommended_procedure.map(item => ({ id: uuidv4(), procedure_type: item, is_new: true }))
        return wound
      })

      let previous_wounds = appointment_.wounds.filter(item => previous_wounds_list.includes(item.id)).map((item) => {
        let wound = { ...item, previous: true }
        wound.procedures = item.recommended_procedure.map(item => ({
          id: uuidv4(), procedure_type: item, is_new: !appointment_.previous_assessments.find(ass => ass.procedure === item && ass.wound_id === wound.id)
        }))
        return wound
      })
      setappointmentWounds(_.orderBy([...wounds, ...previous_wounds], "created_at", "desc"))
      setpreviousWounds(_.orderBy([...wounds, ...previous_wounds], "created_at", 'desc'))
      setloadingProcedures(null)
    }
  }
  const fetchPatient = async (id) => {
    const toastId = getToastId('Getting patient details')
    setpLoading(true)
    try {
      const { data } = await apiRequest.get(`/patients/${id}`)
      if (data && data.data) {
        let patient = data.data
        setPatient(patient)
        if (patient.id !== navs.patientDetailsOwner) {
          await dispatch(resetPatientModuleState())
        }
        await dispatch(selectPatient({ record: patient }))
        await dispatch(resetPatientNavs())
        dismissToast(toastId)
        setpLoading(false)
        navigate(`/patients/${id}`)
      }
    } catch (e) {
      Sentry.captureException(e);
      setpLoading(false)
      console.log(e)
      updateToast(toastId, errMsg(e, true, 'patient info'), false)
    }
  }
  const vitalsChange = (e) => {
    const height = form.getFieldValue('height');
    const weight = form.getFieldValue('weight');
    //console.log(e, parseFloat(weight), (parseFloat(height) ** 2))
    form.setFieldsValue({
      bmi: isNaN(((parseFloat(weight) / ((parseFloat(height) ** 2))) * 703).toFixed(2)) ? 0 : ((parseFloat(weight) / ((parseFloat(height) ** 2))) * 703).toFixed(2)
    })
  }
  const onVitalsFinish = async (values) => {
    const toastId = getToastId("Completing Appointment")
    try {
      await setCreatingAppointment(true)
      if (!appointmentWounds.length || appointmentWounds.some(e => !e.previous) || appointmentWounds.some(e => e.status !== "Healed" && e.procedures.some(item => item.is_new))) {
        await setCreatingAppointment(false)
        updateToast(toastId, !appointmentWounds.length ? "Appointment has no wounds." : "Please complete all wound assessments", false)
      } else {
        let data = { ...values }

        data.appointment_id = appointment?.id
        const vitalsresponse = await apiRequest.post('/patient-vitals', data)
        if (vitalsresponse && vitalsresponse.data.data) {
          let payload = { "status": "completed" }

          const statusresponse = await apiRequest.post(`/appointments/${appointment?.id}/status`, payload)
          await dispatch(fetchSpecificAppointment(appointment?.id))
          if (statusresponse && statusresponse.data.data) {
            updateToast(toastId, "Appointment complete", true)
            //appointment_completed
            ReactGA.event({
              category: "appointment_completed",
              action: "appointment_completed",
              label: "appointment_completed",
              value: 1
            });
          }
          await setCreatingAppointment(false)
        }
      }
    } catch (e) {
      Sentry.captureException(e);
      await setCreatingAppointment(false)
      updateToast(toastId, errMsg(e, false, null, 'Appointment completion failed'), false)
    }
  }
  const VitalsFinish = async (values) => {
    const toastId = getToastId("Saving Vitals")
    try {

      let data = { ...values }
      data.appointment_id = appointment?.id
      const vitalsresponse = await apiRequest.post('/patient-vitals', data)
      if (vitalsresponse && vitalsresponse.data.data) {
        updateToast(toastId, "Vitals Saved", true)
      }

    } catch (e) {
      Sentry.captureException(e);
      console.log(e)
      updateToast(toastId, errMsg(e, false, null, 'Vitals saving failed'), false)
    }
  }
  let isPublished = appointment?.previous_assessments?.some(item => !!item.published_at)
  const appointmentStatuses = can_update_appointment ? ['scheduled',
    ...(!appointmentWounds.some(item => item.procedures.some(procedure => !procedure.is_new)) ? ['rescheduled'] : []),
    // The appointment should not be cancelled once it has draft/published assessments. WPBTB-3316
    ...(!isPublished ? ['cancelled_by_patient', 'cancelled_by_wcc'] : []),
    ...(!isPublished ? ['missed_by_wcc', 'missed_by_patient'] : []),
    ...(can_complete_appointment ? ['completed'] : []),
    'in_progress', 'visited', 'confirmed'].filter(item => item !== appointment?.status) : []
  const statusesRequiringReason = ['rescheduled', 'cancelled_by_patient', 'cancelled_by_wcc', 'missed_by_wcc', 'missed_by_patient']
  const rescheduleAppointment = async (e) => {
    await setNewAppointmentStatus(appointmentStatuses[e.key]);
    if (!statusesRequiringReason.includes(appointmentStatuses[e.key])) {
      await updateAppointmentStatus({ "status": appointmentStatuses[e.key] })
    }
  }
  const updateAppointmentStatus = async (values_) => {
    const toastId = getToastId('Updating Appointment')

    let slot = {};
    if (!!values_.values?.date) {
      slot = {
        start_at: `${dayjs(values_.values.date).format("YYYY-MM-DD")} ${dayjs(values_.values.time_slot[0]).format("HH:mm:ss")}`,
        end_at: `${dayjs(values_.values.date).format("YYYY-MM-DD")} ${dayjs(values_.values.time_slot[1]).format("HH:mm:ss")}`
      };
    }
    let payload = { ...values_.values, ...slot, status: values_.status || newAppointmentStatus }
    // console.log({payload})
    delete payload?.time_slot
    // console.log({payload})
    try {
      await setUpdatingStatus(true)
      const statusresponse = await apiRequest.post(`/appointments/${appointment?.id}/status`, payload)
      await dispatch(fetchSpecificAppointment(appointment?.id))
      if (statusresponse && statusresponse.data.data) {
        updateToast(toastId, 'Appointment updated', true)
        cancellationForm.resetFields()
        setNewAppointmentStatus(payload.status);
      } else {
        updateToast(toastId, 'Update failed', false)
        if (!statusesRequiringReason.includes(payload.status)) {
          setNewAppointmentStatus(appointment?.status);
        }
      }
    } catch (err) {
      updateToast(toastId, 'Update failed', false)
      if (!statusesRequiringReason.includes(payload.status)) {
        setNewAppointmentStatus(appointment?.status);
      }
    } finally {
      await setUpdatingStatus(false)
    }
  }
  const com_menu = (<Menu onClick={rescheduleAppointment}>
    {appointmentStatuses.map((item, index) => <Menu.Item key={index}
      style={{ textTransform: 'capitalize' }}> {item.replace('-', '').replace(/_/g, ' ')}  </Menu.Item>)}
  </Menu>);

  const closeNextVisit = () => SetNextVisit(false)
  const declineAssessment = async (payload) => {
    let defParam = { filterData: { wound: wound_.id } }
    await setisExecuting(true)
    const toastId = toast("Updating Assessment", { type: "info", autoClose: false });
    let data = { notes: payload.notes, status: "rejected" }
    const response = await apiRequest.post(`/assessments/${woundAssessment.id}/status`, data).catch(async function (err) {
      toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
      await dispatch(getWoundEncounters(defParam))
    })
    if (response && response.data) {
      toast.update(toastId, { render: "Assessment Rejected", type: toast.TYPE.SUCCESS, autoClose: 5000 });
      await dispatch(getWoundEncounters(defParam))
    }
    await setisExecuting(false)
    setVisit(false)
    setStatusModal(false)
  }
  const IconFont = createFromIconfontCN({ scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js' });
  const openInitialAppointment = async () => {
    // console.log('Setting App: ', patientInitialAppointment)
    await dispatch(selectAppointment({ navigate, record: patientInitialAppointment }))
    if (can_view_appointment) {
      navigate(`/appointments/${patientInitialAppointment.id}`)
    }
  }


  const handleOk = async (id, procedure, woundOnly) => {
    let assessment_ = appointment?.previous_assessments.find(item => item.wound_id === id && item.procedure === procedure.procedure_type)
    if (assessment_) {
      setassessmentTodeleteID(assessment_.id)
      setprocedureTodelete(procedure)
      setwoundOnly(woundOnly)
      setreasonModal(true)
      setSelected(0)
    } else {
      await setConfirmLoading(true);
      await removeProcedure(wound_, procedure, woundOnly)
      await setSelected(null)
      await setConfirmLoading(false);
      await dispatch(fetchPatientAppointments(appointment?.patient.id))
      await dispatch(fetchSpecificAppointment(appointment?.id))
    }

  }


  const reasonFinish = async (values) => {
    await setConfirmLoading(true);
    await dispatch(deleteAssessment({ assessment_id: assessmentTodeleteID, ...values }))
    await removeProcedure(wound_, procedureTodelete, woundOnly)
    await setSelected(null)
    await setConfirmLoading(false);
    await dispatch(fetchPatientAppointments(appointment?.patient.id))
    await dispatch(fetchSpecificAppointment(appointment?.id))
    setreasonModal(false)
  }
  const dateFormat = 'YYYY-MM-DD';

  const disabledPastDates = (current) => {
    let customDate = dayjs(appointment?.start_at).startOf('day').add(1, 'd');
    return current < dayjs(customDate, dateFormat);
  }
  let cancellationInputFields = {
    fields: [
      //Show reason field only for statuses that require status from the backend
      ...(statusesRequiringReason.includes(newAppointmentStatus) ? [{
        label: `Reason`,
        name: 'status_reasons', required: true, type: 'textarea', fullRow: true,
        props: { style: { backgroundColor: '#F7F8F9' } }
      }] : []),
      //Show schedule Form only when rescheduling appointment
      ...(newAppointmentStatus?.toLowerCase().includes('rescheduled') ? [{
        label: 'Date', name: 'date', required: true, type: 'date', fullRow: false,
        props: { dateFormat, disabledDate: disabledPastDates }
      },
      {
        label: 'Time Slot', name: 'time_slot', required: true, type: 'time_range', fullRow: false,
        props: { minuteStep: 15, showTime: { format: 'hh:mm A', use12Hours: true } }
      }] : [])
    ]
  }
  const getBG = (item) => {
    if (!item?.last_dme_date) {
      // return '#494949'
      return '#287D3C'
    } else {
      let today = dayjs(new Date())
      let days = today.diff(dayjs(item?.last_dme_date, 'YYYY-MM-DD hh:mm:ss'), 'd');
      // console.log({days,date:item.date})
      return days >= 21 ? '#287D3C' : '#C21700';
    }
  }
  const isDMEEligible = (item) => {
    if (!item?.last_dme_date) {
      return true
    } else {
      let today = dayjs(new Date())
      let days = today.diff(dayjs(item?.last_dme_date, 'YYYY-MM-DD hh:mm:ss'), 'd');
      // console.log({days,date:item.date})
      return days >= 21;
    }
  }
  const getBiologicEligibility = (item) => {
    if (!!item?.biologic_eligibility) {
      return `Biologic: Eligible`
    } else if (!!item?.biologic_eligibility) {
      return `Biologic: Eligible`
    } else {
      return null
    }
  }
  const getIcon = (item) => {
    if (!item?.last_dme_date) {
      return <MinusCircleOutlined />
    } else {
      let today = dayjs(new Date())
      let days = today.diff(dayjs(item?.last_dme_date, 'YYYY-MM-DD hh:mm:ss'), 'd');
      // console.log({days,date:item?.last_dme_date})
      return days >= 21 ? <SmileOutlined /> : <WarningOutlined />;
    }
  }
  const getPeriod = (item) => {
    console.log({item})
    let last_dme_date = item?.last_dme_date
    let today = dayjs(new Date())
    let days = today.diff(last_dme_date, 'd');
    // console.log({ item, days })
    let message = <MinusOutlined />
    if (days === 0) {
      let hours = today.diff(last_dme_date, 'h')
      if (hours > 0) {
        message = `${today.diff(last_dme_date, 'h')} hour(s) ago`
      } else if (hours <= -1) {
        message = `In ${Math.abs(today.diff(last_dme_date, 'h'))} hour(s)`
      } else if (hours === 0) {
        message = `In ${Math.abs(today.diff(last_dme_date, 'm'))} minute(s)`
      } else {
        message = `${today.diff(last_dme_date, 'm')} minute(s) ago`
      }
    } else if (days < 0) {
      if (days <= -1) {
        message = `In ${today.diff(last_dme_date, 'd')} day(s)`
      } else {
        let hours = today.diff(last_dme_date, 'h')
        //console.log({ hours })
        if (hours <= -1) {
          message = `In ${Math.abs(today.diff(last_dme_date, 'h'))} hour(s)`
        } else {
          message = `In ${Math.abs(today.diff(last_dme_date, 'h'))} minute(s)`
        }
      }
    } else {
      message = `${today.diff(last_dme_date, 'd')} day(s) ago`
    }
    // return !item.period ? <MinusOutlined/> : message
    return message
  }
  const getOrderNumber = (item) => item.scenario_number ? item.scenario_number : !!item?.last_dme_date ? 'Unknown' : 'None'

  // dissaproval reason boolean



  const approvalStatusChange = async (e) => {
    setVisitApprovalStatus(e)
    if (e === 'Visit Not Approved' || e === "Jurisdiction Discrepancy") {
      setShowdisaprovalreasonModal(true)
      setDisapprovalReason(true)
    } else {
      let data = { ...appointment, visit_approval_status: e ? e : null, type_of_kit: typeOfKit }
      data = data.reason_visit_not_approved ? data : _.omit(data, ['reason_visit_not_approved'])
      await dispatch(updateAppointment({ data: { ...data, consultant_id: appointment.consultant.id, patient_id: appointment.patient.id }, appointment_id: appointment?.id }))
      setShowdisaprovalreasonModal(false)
      setDisapprovalReason(false)
    }
  }

  const onFinish = async (values) => {
    let data = { ...appointment, ...values }
    data = data.reason_visit_not_approved ? data : _.omit(data, ['reason_visit_not_approved'])

    await dispatch(updateAppointment({ data: { ...data, consultant_id: appointment.consultant.id, patient_id: appointment.patient.id }, appointment_id: appointment?.id }))

    setShowModal(false)
  }

  useEffect(() => {
    setBillingFormInitialValues({ ...appointment, notes: appointment?.note?.notes })
  }, [appointment])

  const typeOfKitChange = async (e) => {
    console.log(e)
    setTypeOfKit(e)
    let data = { ...appointment, type_of_kit: e ? e : null, visit_approval_status: visitApprovalStatus }

    // lodash omit reason_visit_not_approved from data if null

    data = data.reason_visit_not_approved ? data : _.omit(data, ['reason_visit_not_approved'])



    await dispatch(updateAppointment({ data: { ...data, consultant_id: appointment.consultant.id, patient_id: appointment.patient.id }, appointment_id: appointment?.id }))

  }



  // onreasonFinish
  const onReasonFinish = async (values) => {
    //console.log(values)
    let data = { ...appointment, ...values, visit_approval_status: visitApprovalStatus }

    await dispatch(updateAppointment({ data: { ...data, consultant_id: appointment.consultant.id, patient_id: appointment.patient.id }, appointment_id: appointment?.id }))
    setShowdisaprovalreasonModal(false)
  }

  // labTest Modal
  
  const createLabAssessment = () => {
    setlabTestModal(false)
    let wound = assessmentWound
    if (!assessmentWound.procedures.find(item => item.procedure_type.includes("Lab"))) {
      let wounds = [...appointmentWounds]
      let index = wounds.findIndex(item => item.id === wound)
      const procedureindex = wounds[index].procedures.findIndex(item => item.procedure_type.includes("Lab"))
      //console.log(wounds, procedureindex)
      if (procedureindex > -1) {
        let new_wound = JSON.parse(JSON.stringify(wounds[index]))
        wounds[index].procedures[procedureindex].procedure_type_place_holder = "Lab"
        new_wound.procedures[procedureindex].procedure_type = "Lab"
        // console.log(new_wound)
        updateWoundProcedures(new_wound)
      }
      // setappointmentWounds(wounds)
      setloadingProcedures(wounds[index])
    }
  }
  const generateLabReport = (report) => {
    setPdf(report)
    setPdfShowing(true)
  }

  return (
    (<div className="h-full w-full flex flex-col">
      {(!!woundAssessment && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} resourceType={'Assessment'}
        loading={false}
        resourceDescription={`${woundAssessment.appointment?.patient.name}'s ${woundAssessment.procedure} assessment`}
        defaultValues={{ auditableId: woundAssessment?.id, auditable: 'assessment', format: 'json' }} />}
      <PdfViewer pdf={pdf} setLoading={setLoading} loading={pdfLoading} onCancel={() => setPdfShowing(false)} visible={pdfShowing} />
      <VisitStatus visitStatusModal={visitStatusModal} setStatusModal={setStatusModal} isExecuting={isExecuting} declineAssessment={declineAssessment} />
      {(VisitModal && !!woundAssessment) &&
        <VisitDetails setShowAuditDialog={setShowAuditDialog}
        assessment={woundAssessment}
        generateLabReport={generateLabReport}
          setVisit={setVisit} approveAsessment={approveAsessment}
          VisitModal={VisitModal} seteditassessmentModal={seteditassessmentModal}
          isExecuting={isExecuting} isPrinting={isPrinting} isLabPrinting={isLabPrinting} 
          setStatusModal={setStatusModal} handlevisitOk={dialogCloser(setVisit)}
          handlevisitCancel={dialogCloser(setVisit)}
          generateReport={generateReport} generateLabRequest={generateLabRequest} isAllograftPrinting={isAllograftPrinting}
          generateAllograftReport={generateAllograftReport} />}
      {(!!appointment && !!previousWounds) ? <>
        <Modal
          title="Next Appointment"
          open={nextVisit}
          onOk={() => SetNextVisit(false)}
          onCancel={() => SetNextVisit(false)}
          destroyOnClose={true}
          closeIcon={modalClose(() => SetNextVisit(false))}
          maskClosable={false}
          footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([outlinedButton(closeNextVisit, 'Cancel'),
            formSubmitButton('appointmentForm', 'Create', isExecuting, 'Creating')])}
          </div>]}>
          <NextVisit setisExecuting={setisExecuting} closeNextVisit={closeNextVisit} />
        </Modal>
        {!!appointment && <Modal
          title={`Assessment on ${appointment?.patient.name}`}
          open={assessmentModal}
          destroyOnClose={true}
          onOk={() => setassessmentModal(false)}
          onCancel={() => setassessmentModal(false)}
          closeIcon={modalClose(() => setassessmentModal(false))}
          maskClosable={false}
          width={1500}
          footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([outlinedButton(() => setassessmentModal(false), 'Cancel'),
              formSubmitButton('appointmentForm', assessmentWoundProcedure === "Biologics Ordering" ? "Order Biologics" : 'Create Assessment', isExecuting, 'Creating')])}
          </div>]}>
          <AssessmentCard setassessmentModal={setassessmentModal} setisExecuting={setisExecuting}
            setlabTestModal={setlabTestModal}
            appointment={appointment} wound={assessmentWound}
            procedure={assessmentWoundProcedure} />
        </Modal>}
        {!!appointment && <Modal
          title={`Assessment on ${appointment?.patient.name}`}
          open={editassessmentModal}
          destroyOnClose={true}
          onOk={() => seteditassessmentModal(false)}
          onCancel={() => seteditassessmentModal(false)}
          closeIcon={modalClose(() => seteditassessmentModal(false))}
          maskClosable={false}
          width={1500}
          footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([outlinedButton(() => seteditassessmentModal(false), 'Cancel', isExecuting),
            formSubmitButton('updateappointmentForm', assessmentWoundProcedure === "Biologics Ordering" ? "Update Order" : 'Update Assessment', isExecuting, 'Updating')])}
          </div>]}>
          {(assessment || woundAssessment) && <EditAssessmentCard setassessmentModal={seteditassessmentModal} setisExecuting={setisExecuting} setVisit={setVisit}
            setlabTestModal={setlabTestModal}
            assessment={assessment || woundAssessment}
            appointment={appointment} wound={assessmentWound} procedure={assessmentWoundProcedure} />}
        </Modal>}
        <Modal
          title="Add wounds"
          open={woundModal}
          onOk={dialogCloser(setwoundModal)}
          onCancel={dialogCloser(setwoundModal)}
          closeIcon={modalClose(dialogCloser(setwoundModal))}
          maskClosable={false}
          destroyOnClose={true}
          // width={800}
          footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([outlinedButton(dialogCloser(setwoundModal), 'Cancel', creatingWound),
            formSubmitButton('new-wound', 'Create', creatingWound, 'Creating')])}
          </div>]}>
          <NewWound closeModal={dialogCloser(setwoundModal)} setCreatingWound={setCreatingWound} />
        </Modal>

        <Modal
          title={`Please add a Reason`}
          open={reasonModal}
          destroyOnClose={true}
          onOk={() => setreasonModal(false)}
          onCancel={() => setreasonModal(false)}
          closeIcon={modalClose(() => setreasonModal(false))}
          maskClosable={false}
          width={1200}
          footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([outlinedButton(() => {
              setreasonModal(false)
              setSelected(0)
            }, 'Cancel', isExecuting),
            formSubmitButton('reasonForm', 'Add Reason', isExecuting, 'Updating')])}
          </div>]}
        >
          <Form
            name="reasonForm"
            onFinish={reasonFinish}
            layout="vertical">
            <Form.Item
              style={{ width: '100%' }}
              label="Delete Reason"
              name="delete_reason"
              rules={[{ required: true, message: 'this field is required!' }]}
              wrapperCol={{ span: 24 }}
            >
              <Input autoComplete={'off'} />
            </Form.Item>
          </Form>
        </Modal>

        {/* A pop up after completion of the wound evaluation should appear:

“This wound is eligible for a lab test” with a dismiss button */}

        <Modal
          title={`Lab test`}
          open={labTestModal}
          destroyOnClose={true}
          onOk={() => setlabTestModal(false)}
          onCancel={() => setlabTestModal(false)}
          closeIcon={modalClose(() => setlabTestModal(false))}
          maskClosable={false}
         
          footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
            {horStack([containedButton(dialogCloser(setlabTestModal), 'Dismiss'),
              // outlinedButton(()=>createLabAssessment(), 'Create Lab asessment')
            ]
            )}
          </div>]}
          >
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4">
              <p className="text-lg font-semibold">This wound is eligible for a lab test</p>
           
            </div>
          
          </div>
        </Modal>

        
        {/*<HeaderTop child={getHeaderLeftNav(navigate, 'Appointment')} />*/}
        <div className="appointment-page-header bg-white mb-4">
          <div className="flex-boy-row-space">
            <div>
              <Headline6>Appointment Details</Headline6>
            </div>
          </div>
        </div>
        <Row gutter={8} className={'h-full'}>
          <Col xs={24} sm={24} md={24} lg={24} xl={16}>
            <div className="flex-boy-row-space bg-white rounded-t-lg" style={{ width: "100%" }}>
              {/* <Subtitle1>Wounds</Subtitle1> */}
              <p className='font-bold ml-4 px-2 text-lg mt-4'>Wounds</p>
              {can_update_appointment && appointment?.status !== "completed" ? addButton(dialogOpener(setwoundModal)) : null}
            </div>
            {appointmentWounds.map(item => {
              let bioEligible = getBiologicEligibility(item)
              return (
                <div key={item.id} className="shadow-card-box-no-padding">
                  <div className={'shadow-card-box-inner'}>
                    <div className="w-full rounded-lg" style={{backgroundColor: '#F7F8F9'}}>
                    <div className="flex-boy-row-space items-center" style={{ width: "100%" }}>
                      <p className={'text-[14px] font-bold flex-1 whitespace-nowrap my-auto text-start ml-4'}>{item.side} {item.position} {item.location}</p>
                      {horStack([
                        <div className="status-container" style={{backgroundColor: 'white'}}>
                          <div className="dot-circle-active" style={{ backgroundColor: item.status === "Unchanged" ? "grey" : item.status === "Improving" ? "blue" : "#ef8100;"}}/>
                          <p className={'text-[12px] font-semibold whitespace-nowrap my-auto'}>{item.active_appointment_status}</p>
                        </div>,
                        <>
                          
                          {item?.lab_eligibility !== null && <div className="status-container" style={{ backgroundColor: 'white' }}>
                            
                            <div className="dot-circle-active" style={{ backgroundColor: item.lab_eligibility ? "green" : "crimson" }}>

                            </div>
                            <p className={'text-[12px] font-semibold whitespace-nowrap my-auto'}> {item.lab_eligibility ? "Lab Eligible" : "Lab Not Eligible"} </p>
                          </div>}
                          
                        </>
                       ,
                        <>
                          {item.biologic_eligibility && <div className="status-container" style={{ backgroundColor: 'white' }}>
                            <div className="dot-circle-active" style={{ backgroundColor: item.biologic_eligibility ? "green" : "crimson" }}>

                            </div>
                            <p className={'text-[12px] font-semibold whitespace-nowrap my-auto'}>Biologic Eligible</p>
                          </div>}
                        </>,
                        (!item.procedures.some(procedure => !procedure.is_new)) && !activeUser?.facilityProfile &&
                        <Dropdown dropdownRender={()=>menu(item, item.procedures[0], "new", true)} trigger={['click']}
                                  disabled={selected === `${item.procedures[0]?.procedure_type}${item.id}wound`}>
                          {appConfirm(
                            <DeleteOutlined />, selected === `${item.procedures[0]?.procedure_type}${item.id}wound`, null, 'Are you sure of deleting this wound?', () => handleOk(item.id, item.procedures[0], true), handleCancel, confirmLoading)}
                        </Dropdown>
                      ])}
                    </div>
                      <div className={'text-white flex flexy-row gap-4 h-full items-center justify-center pl-4 w-full'}>
                        {!!item.last_dme_date &&
                        
                          <div
                            className={`${isDMEEligible(item) ? 'text-center bg-[#287D3C]' : 'bg-[#C21700]'} rounded-xl px-4 my-auto font-semibold text-xs py-0.5 w-full flex justify-between items-center`}>
                            <p className='mb-0'>DME: {getOrderNumber(item)}</p>
                            
                            {/*<p className='mb-0'>{getPeriod(item)}</p>*/}
                            <p className="mb-0">{isDMEEligible(item) ? dayjs(item.dme_eligibility_date).format('MM/DD/YYYY') : getPeriod(item)}</p>
                          </div>}
                        
                      </div>
                    </div>
                    <Divider />
                    {(item.procedures.find(proc => proc.procedure_type === "Wound Evaluation" && !proc.is_new) && item?.active_appointment_status !== "Healed") || (!item.procedures.find(proc => proc.procedure_type === "Wound Evaluation") && item?.active_appointment_status !== "Healed") ? item.procedures.map((procedure, index) =>
                        (
                          <div className="bg-green-800 w-full p-4 rounded-lg my-2" style={{ backgroundColor: '#F7F8F9' }}>
                            <AssessmentWoundProcedure key={index} procedure={procedure} wound={item} type={procedure.is_new ? "new" : 'existing'}/>
                          </div>
                        )) :
                      item.procedures.filter(item => item.procedure_type === "Wound Evaluation").map((procedure, index) => (
                        <div className="bg-green-800 w-full p-4 rounded-lg my-2" style={{ backgroundColor: '#F7F8F9' }}>
                          <AssessmentWoundProcedure key={index} procedure={procedure} wound={item} type={procedure.is_new ? "new" : 'existing'}/>
                        </div>
                      ))}
                    {(
                      (can_update_appointment_procedure && appointment?.status !== "completed" && item?.active_appointment_status !== "Healed" && item.procedures.some(proc => (proc.procedure_type === "Wound Evaluation" && !proc.is_new)))) ? addButton(() => addProcedure(item.id)) :
                      null}
                  </div>
                  {/*Do not show Last DME order No for assessments that do not have yet*/}
                  {/*{!!item?.last_dme_date && <div className={'treatment-scenario-flexy-row '} onClick={() => appLog({ currentWound: item })}>
                    <div className={'treatment-scenario-start'} style={{ backgroundColor: !item?.last_dme_date ? '#8d8d8d' : getBG(item) }}></div>
                    <div className={'backward-parallelogram'} style={{ backgroundColor: !item?.last_dme_date ? '#8d8d8d' : getBG(item) }}></div>
                    <div className={'treatment-scenario-holder'}>
                      <p className={'whitespace-nowrap truncate my-auto'}>Scenario No:</p></div>
                    // <div className={'parallelogram-gray'}></div>
                    <div className={'parallelogram px-4'} style={{ backgroundColor: '#8d8d8d', maxWidth: '40%' }}>
                      <p className={'whitespace-nowrap truncate m-auto'}>{getOrderNumber(item)}</p>
                    </div>
                    <div className={'parallelogram px-4'} style={{ backgroundColor: getBG(item), maxWidth: '20%' }}>
                      <p className={'whitespace-nowrap truncate m-auto'}>{getPeriod(item)}</p>
                    </div>
                    <div className={'treatment-scenario-end ps-3'} style={{ backgroundColor: getBG(item) }}>{getIcon(item)}</div>
                  </div>}*/}
                </div>);
            })}
            {can_create_patient_vitals && <div className="shadow-card-box">
              <Subtitle1>Vitals</Subtitle1>
              <Form
                name="vitals"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 16 }}
                onFinish={VitalsFinish}
                initialValues={{ ...vitals }}
                layout="vertical"
                form={form}
                style={{ width: "100%" }}>
                <Row gutter={16}>
                  {vitals_list.map((item, index) => (<Col key={index} span={8}>
                    <Form.Item
                      label={item.title === 'bmi' ? "BMI" : item.title === "diastolic_blood_pressure" ? "Blood Pressure (diastolic)" : `${_.startCase(item.title)} ${item.units ? `(${item.units})` : ''}`}
                      name={item.title}
                      rules={[{ required: false, message: 'this field is required!' }]}
                      wrapperCol={{ span: 24 }}>
                      <Input autoComplete={'off'} onChange={e => vitalsChange(e)} />
                    </Form.Item>
                  </Col>))}
                </Row>
                <Form.Item wrapperCol={{ span: 1 }} label="" name="scanned"
                  valuePropName="checked" initialValue={false}>
                  <Checkbox>Scanned</Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>}
            <div className={"shadow-card-box"}>
              <div className="flex-boy-row-space ps-0" style={{ width: "100%" }}>
                <Subtitle1>Next Appointment</Subtitle1>
                {can_update_appointment && addButton(dialogOpener(SetNextVisit))}
              </div>
              <div>
                {patientAppointments.filter(item => item.status === "scheduled" && dayjs(item.start_at).diff(dayjs(appointment?.start_at), "hours") > 1)[0] ?
                  <Subtitle1>{dayjs(_.sortBy(patientAppointments.filter(item => item.status === "scheduled" && dayjs(item.start_at).diff(dayjs(appointment?.start_at), "hours") > 1), 'start_at')[0].start_at).format("MM/DD/YYYY hh:mm A")}</Subtitle1> :
                  <p className="text-xs">No scheduled appointment yet.</p>}

              </div>
            </div>
            {(can_complete_appointment && appointment?.status === "scheduled" && dayjs(appointment?.start_at).diff(dayjs(), "days") < 1) ?
              containedButton(() => onVitalsFinish(form.getFieldsValue()), 'Complete appointment', creatingAppointment, 'Creating') : null}
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <div className="shadow-card-box" style={{ width: "100%" }}>
              <div className="mb-4">
                <Subtitle1>Appointment details</Subtitle1>
              </div>
              <Dropdown dropdownRender={()=>com_menu} trigger={['click']}>
                <div className={'appointment-status-box px-2 cursor-pointer'}
                  style={{ borderColor: getAppointmentIndicatorColor(newAppointmentStatus), backgroundColor: getAppointmentIndicatorColor(newAppointmentStatus) + '15' }}>
                  {getAppointmentIndicator(newAppointmentStatus)}
                  <Subtitle1>{!!newAppointmentStatus ? newAppointmentStatus.replace('-', '').replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()) : 'Unknown'}</Subtitle1>
                  <div className={'pe-2'} style={{ flexGrow: 1, textAlign: 'end' }}>
                    {updatingStatus ? <Spin size={"small"} spinning={updatingStatus} indicator={<LoadingOutlined style={{ color: 'grey' }} />} /> :
                      <CaretDownOutlined style={{ color: 'gray' }} />}
                  </div>
                </div>
              </Dropdown>
              {((statusesRequiringReason.includes(newAppointmentStatus) && newAppointmentStatus !== appointment?.status) || showStatusForm) &&
                (<>
                  <DynamicForm form={cancellationForm}
                    formName={"cancellationForm"}
                    formId={"cancellationForm"}
                    onDynamicFormEntriesResult={updateAppointmentStatus}
                    inputFields={cancellationInputFields}
                    phoneValue={null} setPhoneValue={null}
                    // defaultValues={newAppointmentStatus === appointment?.status ? { status_reasons: appointment?.status_reasons } : { status_reasons: undefined }}
                    formFooter={
                      <div
                        className="flex flex-row p-4 justify-evenly w-full">
                        {horStack([
                          textButton(async () => {
                            await cancellationForm.resetFields()
                            await setNewAppointmentStatus(appointment?.status)
                            await setShowStatusForm(false)
                          }, 'Cancel', false, null),
                          formSubmitButton('cancellationForm', !!appointment?.status_reasons ? '  Update  ' : '  Save  ', updatingStatus, !!appointment?.status_reasons ? '  Updating  ' : '  Saving  ')
                        ])}
                      </div>}
                  />
                  <Divider />
                </>)}
              {boldKeyCapitalizedValue('Date', dayjs(appointment?.start_at).format('MM-DD-YYYY'))}
              {boldKeyCapitalizedValueGrayBackground('Start time', dayjs(appointment?.start_at).format("hh:mm a"))}
              {boldKeyUntransformedValue('End time', dayjs(appointment?.end_at).format("hh:mm a"))}
              {boldKeyCapitalizedValueGrayBackground('Type', appointment?.appointment_visit_type)}
              {boldKeyCapitalizedValue('Assigned', appointment?.consultant.name)}
              {(statusesRequiringReason.includes(appointment?.status)) &&
                boldKeyCapitalizedValueGrayBackground('Status reason', <p>{appointment?.status_reasons || 'No reason provided'}<span className={'me-4'} />
                  <i onClick={() => setShowStatusForm(!showStatusForm)}
                    className={`cursor-pointer text-gray-500 ${showStatusForm ? 'fa-solid fa-xmark' : 'fas fa-pen'}`}></i></p>)}
              {(appointment?.telehealth_appointment && can_join_telehealth_call) && boldKeyCapitalizedValue('Call link', appointment?.call_link ? <a href={appointment?.call_link} target="_blank"
                style={{ color: getAppointmentIndicatorColor(newAppointmentStatus) }}>Click
                here to join. <i className="fas fa-external-link-alt"></i></a> : 'N/A')}

              {appointment?.telehealth_appointment && appointment?.call_recording_link && boldKeyCapitalizedValue('Call Recording link', appointment?.call_recording_link ? appointment?.call_recording_link.map(item => {
                return (<a href={appointment?.call_recording_link} target="_blank"
                  style={{ color: getAppointmentIndicatorColor(newAppointmentStatus) }}>
                  Call Recording Link <i className="fas fa-external-link-alt"></i></a>)
              }
                ) : 'N/A')}
            </div>
            <div className="shadow-card-box" style={{ minHeight: "100px" }}>
              <div className="mb-4">
                <Subtitle1>Visit Reason</Subtitle1>
              </div>
              <div>
                <p className="text-xs">{appointment?.visit_reason}</p>
              </div>
            </div>
            <div>
              {appointment ? (
                <>
                  <div className="shadow-card-box" style={{ minHeight: '100px' }}>
                    <div className="mb-4">
                      <Subtitle1>Visit Approval Status</Subtitle1>
                    </div>
                    <div className='w-full mb-6'>
                      <Select
                        disabled={!can_verify_appointment}
                        popupMatchSelectWidth={false}
                        placeholder="Visit Approval status"
                        style={{ width: '100%' }}
                        allowClear
                        value={visitApprovalStatus}
                        onChange={(value) => approvalStatusChange(value)}
                      >
                        {GetOptions('visit_approval_status')}
                      </Select>
                    </div>
                    {(appointment.visit_approval_status === 'Visit Not Approved' || (appointment.visit_approval_status === "Jurisdiction Discrepancy")) && <div className='mb-4 text-left w-full'>
                      <div className="flex justify-between w-full">
                        <Subtitle1 className="mb-2" style={{ marginBottom: '20px' }}>{appointment.visit_approval_status} Reason</Subtitle1>
                        {can_verify_appointment && <FontAwesomeIcon onClick={() => setShowdisaprovalreasonModal(true)} color="grey" icon={faPencilAlt} />}
                      </div>
                      <p>
                        {appointment.reason_visit_not_approved}
                      </p>
                    </div>}
                    <Modal
                      // title={`Reason ${visitApprovalStatus} `}
                      visible={showdisaprovalreasonModal}
                      onCancel={() => {
                          setVisitApprovalStatus(appointment.visit_approval_status || null)
                          setShowdisaprovalreasonModal(false)
                        }}
                      footer={[
                        <Button key="close" onClick={() => {
                          setVisitApprovalStatus(appointment.visit_approval_status || null)
                          setShowdisaprovalreasonModal(false)
                        }}>
                          Close
                        </Button>,
                        <Button key="submit" type="primary" htmlType="submit" form="disaproval-form">
                          Save Reason
                        </Button>
                      ]}>
                      <Form layout='vertical' onFinish={onReasonFinish} initialValues={{ reason_visit_not_approved: appointment.reason_visit_not_approved }} id="disaproval-form">
                        <Form.Item
                         rules={[{ required: true, message: 'this field is required!' }]}
                          name="reason_visit_not_approved"
                           label={`Reason ${visitApprovalStatus} `}
                        >
                          <TextArea
                           
                            disabled={!can_verify_appointment}
                            rows={6}
                            autoSize={{ minRows: 6 }}
                            defaultValue={notes}
                          />
                        </Form.Item>
                      </Form>
                    </Modal>
                  </div>
                  <div className="shadow-card-box" style={{ minHeight: '100px' }}>
                    <div className="mb-4">
                      <Subtitle1>Type of Kit to Order</Subtitle1>
                    </div>
                    <div className='w-full'>
                      <Select
                        disabled={!can_verify_appointment}
                        popupMatchSelectWidth={false}
                        placeholder="Type of kit to order"
                        style={{ width: '100%' }}
                        allowClear
                        value={typeOfKit}
                        onChange={(value) => typeOfKitChange(value)}
                      >
                        {GetOptions('type_of_kit')}
                      </Select>
                    </div>
                  </div>
                  <Modal
                    title="Notes"
                    visible={showModal}
                    onCancel={() => setShowModal(false)}
                    footer={[
                      <Button key="close" onClick={() => setShowModal(false)}>
                        Close
                      </Button>,
                      <Button key="submit" type="primary" htmlType="submit" form="notes-form">
                        Add
                      </Button>
                    ]}>
                    <Form onFinish={onFinish} id="notes-form">
                      <Form.Item name="notes">
                        <TextArea
                          disabled={!can_verify_appointment}
                          rows={6}
                          autoSize={{ minRows: 6 }}
                          defaultValue={notes}
                        />
                      </Form.Item>
                    </Form>
                  </Modal>
                  <AppointmentNotes appointment={appointment} />
                </>
              ) : (
                <div className="shadow-card-box flex-boy-row-space" style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <Spin tip="Loading appointment?..." />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </> : <div className="shadow-card-box flex-boy-row-space" style={{ alignItems: "center", justifyContent: "center" }}>
        <Spin tip="Loading appointment?..."></Spin>
      </div>}
    </div>)
  );
}
const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser,
  appointment: state.appointments.selectedAppointment,
  patientAppointments: state.patients.patientAppointments,
  patientInitialAppointment: state.appointments.patientInitialAppointment,
  procedures: state.appointments.procedures,
  navs: state.navs,
  loading: state.appointments.assessment_form_loading, woundEncounters: state.patients.woundEncounters,
  masterList: state.masters.masterListRaw,
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetailsPage)
