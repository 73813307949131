/* eslint-disable no-unused-vars */
import React from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Body2, Subtitle1 } from '../../general'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { selectFacility } from '../../../app/Reducers'
import {useLocation, useNavigate} from 'react-router-dom';

export const FacilityCard = ({ facility }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  return (<div onClick={() => {
    if (facility) {
      dispatch(selectFacility(facility))
      navigate(`/facilities/${facility.id}`)
    }
  }} className="flex-boy-column" style={{ width: "400px" }}>


    <div className="flex-boy-row">
      <div className="icon-circle">
        <FontAwesomeIcon size="1x" icon={['fas', 'star-of-life']} />
      </div>
      <div className="flex-boy-row-space" style={{ flex: 1 }}>
        {facility ? <div>
          <Subtitle1 style={{ textAlign: "left" }}>{facility.name}</Subtitle1>
          <Body2 color="grey">{facility.address}</Body2>
          <Body2>{facility.phone_no}</Body2>
        </div> : null}
        <FontAwesomeIcon size="1x" icon={['fas', 'chevron-right']} />
      </div>

    </div>


  </div>)
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(FacilityCard)
