import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Form, Input } from 'antd'
import { emptyFun, executeChunkFn } from '../../../../common/helpers'
import { createOrderNote, updateOrderNote } from '../../../../app/Reducers'



export const NewInternalNote = ({ order, closeModal, setIsSubmitting, editingForm, note }) => {
  const { TextArea } = Input
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState(editingForm ? { notes: note.note } : {});
  useEffect(() => {
    setInitialValues(editingForm ? { notes: note.note } : {})
    return emptyFun
  }, [note, editingForm])
  const onFinish = async (values) => {
    console.log(values)
    let payload, pl = { note: values.notes, treatment_order_id: order.id }
    if (editingForm) {
      payload = { data: pl, note_id: note.id }
    } else {
      payload = pl
    }
    console.log(payload)
    await executeChunkFn(dispatch, editingForm ? updateOrderNote : createOrderNote, payload, setIsSubmitting, closeModal)
  }
  return (<Form
    name="new-progress-note"
    onFinish={onFinish}
    labelCol={{ span: 16 }}
    initialValues={{ ...initialValues }}
    layout="vertical">
    <Form.Item name="notes" label="Notes" rules={[{ required: true, message: 'Missing notes' },{ max: 1000, message: 'Cannot exceed 1000 characters' }]}>
      <TextArea placeholder="Type here" rows={6} />
    </Form.Item>
  </Form>)
}
const mapStateToProps = (state) => ({  order: state.orders.selectedOrder })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(NewInternalNote)
