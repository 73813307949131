import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import dayjs from 'dayjs'
import "react-big-calendar/lib/css/react-big-calendar.css";
import AppointmentCard from './ScheduleCard';
import { status_colors } from '../../../configure/constants';

import AppointmentViewCard from './AppointmentViewCard';
import { Subtitle1 } from '../../../components';

import { Empty, Form, Input, Modal, Popconfirm } from 'antd';
import SheduleAppointment from './ScheduleAppointment';

import { deleteAppointment, deleteTimeBlocker } from '../../../app/Reducers/AppointmentsSlice';
import {
  closeButton,
  deleteButton,
  dialogCloser,
  editButton,
  emptyFun,
  formSubmitButton,
  horStack,
  modalClose,
  outlinedButton
} from "../../../common/helpers";

import _ from "lodash"
import { apiRequest } from '../../../app/Apis';
import { getToastId, updateToast } from '../../../app/Reducers/reducerUtils';
import TimeblockCard from './TimeblockCard';
import Timeblocker from './Timeblocker';
import TimeBlockViewCard from './TimeBlockViewCard';
import { LoadingIndicator } from 'stream-chat-react';
import {CloseOutlined, DeleteOutlined, EditOutlined, LoadingOutlined} from '@ant-design/icons';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
const localizer = dayjsLocalizer(dayjs)
export const MyBigCalendar = ({ scheduleRange, handleFilterAction, appointments, appConf, mode, date, setMode, setSelectedPeriod,  activeUser, setScheduleRange, timeblockers, showtimeBlocker, scheduleLoading }) => {
  var weekStart = dayjs(date).clone().startOf('isoWeek');
  var weekEnd = dayjs(date).clone().endOf('isoWeek');
  var monthStart = dayjs(date).clone().startOf('isoMonth');
  var monthEnd = dayjs(date).clone().endOf('isoMonth');
  const [events, setevents] = useState([])
  const [_range, setRange] = useState(mode === "month" ? [monthStart, monthEnd] : [weekStart, weekEnd])
  const [currentdate, setcurrentdate] = useState(date)
  const masterGlobalAccess = usePermissionCheck('master_global_access', false, [])
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_delete_appointment = usePermissionCheck('delete_appointment', false, [])

  useEffect(() => {
    let weekStart = dayjs(date).clone().startOf('isoWeek');
    let weekEnd = dayjs(date).clone().endOf('isoWeek');
    let monthStart = dayjs(date).startOf('month')
    let monthEnd = dayjs(date).endOf('month')

    if (mode === 'week' || mode === "month") {
      onRangeChange(mode === 'week' ? [weekStart, weekEnd] : [monthStart, monthEnd])
    }

    return emptyFun
  }, [mode, date])

  useEffect(() => {
    console.log(timeblockers)
    const apps = showtimeBlocker ? [...timeblockers] : [...appointments]
    setevents(apps.map(item => ({ ...item, allDay: item.all_day, start: dayjs(item.start_at).toDate(), end: dayjs(item.end_at).toDate() })))
    return emptyFun
  }, [appointments, timeblockers, showtimeBlocker])



  const CustomEvent = ({ event }) => (showtimeBlocker ? <TimeblockCard setevent={addtimeblockevent} appointment={event} mode={mode} color={status_colors[event.status]} /> : <AppointmentCard setevent={addevent} appointment={event} mode={mode} color={status_colors[event.status]} />);

  const addevent = (event) => {
    console.log(event)
    setevent(event)
    showModal()
  }

  const addtimeblockevent = (event) => {
    console.log(event)
    setevent(event)
    showBlockModal()
  }
  const onRangeChange = async (range) => {
    console.log('onRangeChange: ', range, monthStart, monthStart)
    setRange(range)
    // setScheduleRange([dayjs(range[0]).format("YYYY-MM-DD"), dayjs(range[1]).format("YYYY-MM-DD")])
    // handleFilterAction('filter', { 'scheduledForRange[start_at]': dayjs(range[0]).format("YYYY-MM-DD"), 'scheduledForRange[end_at]': dayjs(range[1]).format("YYYY-MM-DD") })

  }


  const { views, ...otherProps } = useMemo(() => ({
    views: { month: true, day: true, week: true, agenda: true },
    // ... other props
  }), [])

  const onShowMore = useCallback((events, date) => {
    setSelectedPeriod(dayjs(date).format("YYYY-MM-DD"))
    setMode("day")
  }, [])

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const showBlockModal = () => {
    setIsBlockModalOpen(true);
  };
  const handleBlockOk = () => {
    setIsBlockModalOpen(false);
  };
  const handleBlockCancel = () => {
    setIsBlockModalOpen(false);
  }
  const [event, setevent] = useState(null)
  const [editingForm, setEditingForm] = useState(false);
  const [isOpen, setOpen] = useState(false)
  const [editingBlockerForm, setBlockerEditingForm] = useState(true);
  const [isOpenBlocker, setOpenBlocker] = useState(false)
  const [isExecuting, setisExecuting] = useState(false)

  const dispatch = useDispatch()
  const [appointmentTodeleteID, setappointmentTodeleteID] = useState(null)
  const [reasonStatus, setreasonStatus] = useState(null)
  const [reasonModal, setreasonModal] = useState(false)
  const [selected, setSelected] = React.useState(null);
  const handleCanceldelete = () => setSelected(0);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const showPopconfirm = (id) => setSelected(id);
  const reasonFinish = async (values) => {
    await setConfirmLoading(true);
    await dispatch(deleteAppointment({ appointment_id: appointmentTodeleteID, ...values }));
    await setSelected(0);
    await setConfirmLoading(false);
    setreasonModal(false)
  }

  const Tabo = () => {
    return (
      <div className="p-2 flex flex-row justify-between w-full items-center">
        <p className={'my-auto font-semibold'}>Appointment</p>
        <div className="flex flex-row gap-2 items-center">
          {!activeUser?.facilityProfile &&  editButton(handleedit) }
          {masterGlobalAccess && can_delete_appointment && deleteButton(handledelete)}
          {closeButton(dialogCloser(setIsModalOpen),null,{type:'text'})}
        </div>

        {/*<CloseOutlined />*/}
      </div>
    )
  }


  const deleteconfirm = async () => {
    await dispatch(deleteTimeBlocker({ timeblock_id: event.id }))
    handleFilterAction("filter", {})
    handleBlockCancel()
  }

  const TaboTimeBlocker = () => {
    return (
      <div className="p-2 flex flex-row justify-between w-full items-center">
        <Subtitle1>Timeblocker</Subtitle1>
        <div className="flex flex-row gap-4">
          {editButton(handleeditblocker)}
          {((masterGlobalAccess && can_delete_appointment) || event?.consultant?.id === activeUser.id) &&
            <Popconfirm
              title="Are you sure to delete this time blocker?"
              onConfirm={deleteconfirm}
              okText="Yes"
              cancelText="No"
            >
              {deleteButton(handledeleteblocker)}
            </Popconfirm>
          }
        </div>

        {/*<CloseOutlined />*/}
      </div>
    )
  }




  const handledelete = () => {
    setappointmentTodeleteID(event.id)
    setreasonModal(true)
    setSelected(0)
  }
  const handleedit = async () => {
    const toastId = getToastId("Fetching appointment details")
    const response = await apiRequest.get(`/appointments/${event.id}`).catch(e => console.log(e))
    if (response?.data?.data) {
      updateToast(toastId, 'Fetched', true);
      await setevent(response?.data?.data)
      await handleCancel()
      await setEditingForm(true)
      await setOpen(true)
    }

  }
  const handledeleteblocker = () => {
    setappointmentTodeleteID(event.id)
  }
  const handleeditblocker = async () => {
    const toastId = getToastId("Fetching time blocker details")
    const response = await apiRequest.get(`/time-blocker/${event.id}`).catch(e => console.log(e))
    if (response?.data?.data) {
      updateToast(toastId, 'Fetched', true);
      await setevent(response?.data?.data)
      await handleBlockCancel()
      await setBlockerEditingForm(true)
      await setOpenBlocker(true)

    }

  }
  const eventPropGetter = (event, start, end, isSelected) => {
    return {
      style: {
        width: "auto",

      }
    };
  };
  return (<div className='bg-white h-full'>
    <Modal
      closable={false}
      closeIcon={null}
      title={Tabo()}
      destroyOnClose
      open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
      width={600}>
      <AppointmentViewCard appointment={event}/>
    </Modal>


    <Modal
      title={editingForm ? 'Edit appointment' : "New Appointment"}
      open={isOpen}
      destroyOnClose={true}
      onOk={dialogCloser(setOpen)}
      onCancel={dialogCloser(setOpen)}
      closeIcon={modalClose(dialogCloser(setOpen))}
      maskClosable={false}
      width={650}
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        {horStack([outlinedButton(dialogCloser(setOpen), 'Cancel', isExecuting),
        formSubmitButton('appointmentForm', editingForm ? 'Update' : 'Create', isExecuting, editingForm ? 'Updating' : 'Creating')])}
      </div>]}>
      <SheduleAppointment handleFilterAction={handleFilterAction} appointment={editingForm ? event : null} editingForm={editingForm} setisExecuting={setisExecuting} handleBlockCancel={handleBlockCancel} closeModal={dialogCloser(setOpen)} />
    </Modal>



    <Modal title={TaboTimeBlocker()} destroyOnClose open={isBlockModalOpen} onOk={handleBlockOk} onCancel={handleBlockCancel} closeIcon={<CloseOutlined className={'mt-4'}/>} width={600}>
      <TimeBlockViewCard appointment={event} />
    </Modal>


    <Modal
      title={editingBlockerForm ? 'Edit Blocker' : "New Appointment"}
      open={isOpenBlocker}
      destroyOnClose={true}
      onOk={dialogCloser(setOpenBlocker)}
      onCancel={dialogCloser(setOpenBlocker)}
      closeIcon={modalClose(dialogCloser(setOpen))}
      maskClosable={false}
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        {horStack([outlinedButton(dialogCloser(setOpenBlocker), 'Cancel', isExecuting),
        formSubmitButton('timeblockerForm', editingBlockerForm ? 'Update' : 'Create', isExecuting, editingBlockerForm ? 'Updating' : 'Creating')])}
      </div>]}>
      <Timeblocker appointment={editingBlockerForm ? event : null} editingForm={editingBlockerForm} setisExecuting={setisExecuting} closeModal={dialogCloser(setOpenBlocker)} />
    </Modal>
    <Modal
      title={`Please add a Reason`}
      open={reasonModal}
      destroyOnClose={true}

      onOk={() => setreasonModal(false)}
      onCancel={() => setreasonModal(false)}
      closeIcon={modalClose(() => setreasonModal(false))}
      maskClosable={false}
      width={1200}
      footer={[<div className="entity-filters flex-boy-row" style={{ justifyContent: 'flex-end' }}>
        {horStack([outlinedButton(() => {
          setreasonModal(false)
          setSelected(0)
        }, 'Cancel', isExecuting),
        formSubmitButton('reasonForm', 'Add Reason', isExecuting, 'Updating')])}
      </div>]}
    >
      <Form
        name="reasonForm"
        onFinish={reasonFinish}
        layout="vertical"
      >

        <Form.Item
          style={{ width: '100%' }}
          label="Delete Reason"
          name="delete_reason"
          rules={[{ required: true, message: 'this field is required!' }]}
          wrapperCol={{ span: 24 }}
        >
          <Input autoComplete={'off'} />
        </Form.Item>
      </Form>

    </Modal>
    <Calendar
      localizer={localizer}
      events={_.orderBy(events, "start")}

      date={mode === "agenda" ? dayjs(scheduleRange?.[0]).toDate() : date || dayjs().toDate()}
      views={views}
      toolbar={false}
      defaultDate={dayjs().toDate()}
      agendaRange={dayjs(scheduleRange?.[1]).diff(dayjs(scheduleRange?.[0]), 'days') + 1}
      eventPropGetter={eventPropGetter}
      dayLayoutAlgorithm="no-overlap"
      startAccessor="start"
      endAccessor="end"
      components={{
        event: CustomEvent,
        timeGutterHeader: () => <p>All Day</p>
      }}
      messages={{
        noEventsInRange: scheduleLoading ? <LoadingOutlined /> : <div className={'flexy-column'} style={{ justifyContent: "center" }}>
          <Empty image={require('../../../assets/empties/no_results.svg').default} imageStyle={{ height: '100%' }} description={""} /></div>
      }}

      // showAllEvents={false}
      step={10}        // duration of the slot
      timeslots={6}
      // onRangeChange={onRangeChange}
      onShowMore={onShowMore}
      view={mode}
    />
  </div>)
}
const mapStateToProps = (state) => ({ appointments: state.appointments.scheduledAppointments, appConf: state.appConf,
  timeblockers: state.appointments.timeblockers || [],
  activeUser: state.auth?.activeUser,
  scheduleLoading: state.appointments.scheduleLoading
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(MyBigCalendar)
