import React from "react";
import {Card, Typography} from "antd";
import {kebabMenus, keyValItemForReferral, keyValItemGreyForReferral} from "../../../common/helpers"
import KebabMenu from "../../../components/KebabMenu"

const {Title, Paragraph, Text} = Typography;

export default function InsuranceCard({insurance, removeInsuranceDetails, setEditInsurance, setInitialInsuranceData, setInsuranceForm,prospectData}) {
  
  const actionMenus = kebabMenus(true, false, true)
  
  async function kebabMenuClick(e, insurance) {
    // console.log(actionMenus[e.key].title)
    await setInitialInsuranceData(insurance);
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setEditInsurance(true);
        await setInsuranceForm(true);
        break
      case 'Delete':
        removeInsuranceDetails(insurance?.plan)
        break
      default:
    }
  }
  
  
  return (
    <Card>
      <div className="flex-boy-row-space" style={{width:'100%'}}>
        <Card.Meta style={{width:'100%'}}
          title={
          <div className={"flexy-row"} style={{width:'100%'}}>
            <Text style={{width:'80%'}} ellipsis={true}>{`Insurance Provider: ${insurance.insurance_provider || "N/A"}`}</Text>
            <KebabMenu
              menus={actionMenus}
              recordItem={insurance}
              handleMenuClick={kebabMenuClick}
              resource={'insurance'}
              showConfirmDialog={false}
            />
          </div>}
          description={
            <div>
              {keyValItemForReferral('Insurance Plan', insurance.plan || "_")}
              {keyValItemGreyForReferral('Policy Number', insurance.policy_number || "_")}
              {keyValItemForReferral('Policy Holder Relationship', insurance.policy_holder_relationship || "_")}
              {keyValItemGreyForReferral('Policy priority', insurance.priority|| "_")}
              {keyValItemForReferral('Policy Guarantor', insurance.guarantor_name  || "_")}
            </div>
          }
        />
      
        {/*<Button onClick={() => removeInsuranceDetails(insurance?.plan)}>*/}
        {/*  <FontAwesomeIcon icon={["fas", "trash"]} color="grey" />*/}
        {/*</Button>*/}
        {/*<Button onClick={EditData}>*/}
        {/*  <FontAwesomeIcon icon={["fas", "edit"]} color="grey" />*/}
        {/*</Button>*/}
      
      </div>
    </Card>
  )
};
