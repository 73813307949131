/* eslint-disable no-unused-vars */
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {errMsg, toastUp} from "./reducerUtils";
import {masters} from "./initialState";
import ReactGA from "react-ga4";
import {apiRequest} from "../Apis"

const initialState = { ...masters }
export const fetchMasterListFields = createAsyncThunk('/masterList/fields', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/masterlist_setting_form_fields`)
      const arr = response.data.data
      const result = arr.filter(item => item.system_name !== 'role' && item.system_name !== "status" && item.system_name !== 'procedure').reduce((acc, d) => {
        const found = acc.find(a => a.master_block === d.master_block);
        // console.log(found, acc)
        const { ...rest } = d
        if (!found) {
          acc.push({ master_block: d.master_block, sub_modules: [rest] }) // not found, so need to add data property
        } else {
          //acc.push({ name: d.name, data: [{ value: d.value }, { count: d.count }] });
          found.sub_modules.push(rest) // if found, that means data property exists, so just push new element to found.data.
        }
        return acc;
      }, []);
      
      const payload_data = result.map(item => {
        const rest_result = item.sub_modules.reduce((accp, b) => {
          const rest_found = accp.find(a => a.sub_module === b.sub_module)
          
          const { ...options } = b
          if (!rest_found) {
            
            accp.push({ sub_module: b.sub_module, items_list: [options] }) // not found, so need to add data property
          } else {
            
            rest_found.items_list.push(options) // if found, that means data property exists, so just push new element to found.data.
          }
          return accp;
          
        }, [])
        return { ...item, sub_modules: rest_result }
      })
      
      const payload = { masterListRaw: response.data.data, masterList: payload_data }
      //console.log({masterList: payload})
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'Master List'), false)
      reject(e)
    }
  })
})
export const updateFieldOptions = createAsyncThunk('/update_field', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/masterlist_setting_form_fields/${payload.id}`, { options: payload.options.map(item => item.toString()) })
      await thunkAPI.dispatch(fetchMasterListFields());
      toastUp("Field updated successfully", true)
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const createFieldOptions = createAsyncThunk('/update_field', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/masterlist_setting_form_fields/${payload.id}`, payload)
      await thunkAPI.dispatch(fetchMasterListFields());
      toastUp("Field updated successfully", true)
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})

export const fetchInsuranceProviders = createAsyncThunk('fetchInsuranceProviders', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/insurance/info`);
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'Insurance providers'), false)
      reject(e)
    }
  })
})
export const fetchBillingEntities = createAsyncThunk('fetchBillingEntities', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.masters.billingEntityPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const response = await apiRequest.get(`/billing-entity`,{params});
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'billing entities'), false)
      reject(e)
    }
  })
})
export const fetchCPTCodes = createAsyncThunk('fetchCPTCodes', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.masters.CPTCodePagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const response = await apiRequest.get(`/cpt-code`,{params});
      let data = response.data.data
      const payload = { data, pagination: { ...pagination, total: data?.meta?.total } }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'billing entities'), false)
      reject(e)
    }
  })
})
export const fetchPpeKits = createAsyncThunk('fetchPpeKits', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.masters.ppeKitsPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const response = await apiRequest.get(`/ppe-kits`,{params});
      let data = response.data.data
      const payload = { data, pagination: { ...pagination, total: data?.meta?.total } }
      resolve(payload)
    } catch (e) {
      toastUp(errMsg(e, true, 'PPE kits'), false)
      reject(e)
    }
  })
})
export const fetchWPRegions = createAsyncThunk('fetchWPRegions', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.masters.wpCitiesPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const { data } = await apiRequest.get(`/region-boundaries`,{params});
      const payload = { data: data.data, pagination: { ...pagination, total: data.meta.total } }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'WP regions'), false)
      reject(e)
    }
  })
})
export const fetchWPSubRegions = createAsyncThunk('fetchWPSubRegions', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.masters.wpSubRegionsPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const { data } = await apiRequest.get(`/sub-region-boundaries`,{params});
      const payload = { data: data.data, pagination: { ...pagination, total: data.meta?.total } }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'WP sub-regions'), false)
      reject(e)
    }
  })
})
export const fetchWPStates = createAsyncThunk('fetchWPStates', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.masters.wpStatesPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const { data } = await apiRequest.get(`/state-boundaries`,{params});
      const payload = { data: data.data, pagination: { ...pagination, total: data.meta?.total } }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'WP states'), false)
      reject(e)
    }
  })
})
export const fetchWPCities = createAsyncThunk('fetchWPCities', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.masters.wpCitiesPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const { data } = await apiRequest.get(`/city-boundaries`,{params});
      const payload = { data: data.data, pagination: { ...pagination, total: data.meta?.total } }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'WP cities'), false)
      reject(e)
    }
  })
})
export const fetchBillingRegions = createAsyncThunk('/billing_regions', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/billing-region`);
      //console.log("Billing Regions", response)
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'Billing Regions'), false)
      reject(e)
    }
  })
})
export const fetchBiologicProducts = createAsyncThunk('fetchBiologicProducts', async (requestParams, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let state = thunkAPI.getState();
      let pagination = requestParams?.pagination ? requestParams.pagination : state.masters.wpCitiesPagination;
      let params = requestParams?.['filterData'] ? { ...requestParams?.['filterData'] } : {}
      params.page = pagination.current
      const { data } = await apiRequest.get(`/biologic-products`,{params})
      let products = data.data
      //console.log({products})
      products = products.map(item => ({ ...item, name: `${item.company_name} ${item.product_name}` }))
      const payload = { data: products, pagination: { ...pagination, total: data.meta?.total } }
      resolve(payload)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'biologic products'), false)
      reject(e)
    }
  })
})

export const fetchBillingGroupPerState = createAsyncThunk('/billing_group_per_state', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/billing-entity?state=${payload}`);
      resolve(!!response.data.data[0] ? response.data.data : [])
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'Billing Groups'), false)
      reject(e)
    }
  })
})
export const fetchMedicalDirectors = createAsyncThunk('fetchMedicalDirectors', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      let queryString = !!payload ? new URLSearchParams(!!payload?.filterData ? payload?.filterData : payload).toString() : '';
      //console.log({queryString})
      const response = await apiRequest.get(`/state-signatories?${queryString}`);
      resolve(!!response.data.data[0] ? response.data.data : [])
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'Medical directors'), false)
      reject(e)
    }
  })
})
export const fetchStateBoundaries = createAsyncThunk('fetchStateBoundaries', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/state-boundaries`);
      resolve(!!response.data.data[0] ? response.data.data : [])
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'Medical directors'), false)
      reject(e)
    }
  })
})
export const fetchAvailableSignatoryStates = createAsyncThunk('fetchAvailableSignatoryStates', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.get(`/available-signatory-states`);
      resolve(!!response.data.data[0] ? response.data.data : [])
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, true, 'Available signatories'), false)
      reject(e)
    }
  })
})

export const createBillingRegion = createAsyncThunk('/create_billing_region', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/billing-region`, payload);
      //console.log("Billing Regions", response)
      toastUp("Billing Region created successfully", true)
      thunkAPI.dispatch(fetchBillingGroupPerState(payload.state))
      //billing_region_created 
      ReactGA.event({
        category: "billing_region_created",
        action: "billing_region_created",
        label: "billing_region_created",
        value: 1
      });
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const createSignatoryState = createAsyncThunk('createSignatoryState', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/available-signatory-states`, payload);
      toastUp("State Signatory created successfully", true)
      thunkAPI.dispatch(fetchAvailableSignatoryStates())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const createBiologicProduct = createAsyncThunk('createBiologicProduct', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/biologic-products`, payload);
      toastUp("Biologic product created successfully", true)
      thunkAPI.dispatch(fetchBiologicProducts())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})

export const createBillingGroup = createAsyncThunk('/create_billing_group', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/billing-entity`, payload);
      //console.log("Billing Group", response)
      toastUp("Billing Group created successfully", true)
      thunkAPI.dispatch(fetchBillingGroupPerState(payload.state))
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const createMedicalDirector = createAsyncThunk('createMedicalDirector', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/state-signatories`, payload);
      //console.log("Medical director", response)
      toastUp("Medical director created successfully", true)
      thunkAPI.dispatch(fetchMedicalDirectors({ state: payload.state }))
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const createInsuranceProvider = createAsyncThunk('createInsuranceProvider', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/insurance/info`, payload);
      toastUp("Insurance provider created successfully", true)
      thunkAPI.dispatch(fetchInsuranceProviders())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false, 'insurance provider'), false)
      reject(e)
    }
  })
})
export const createBillingEntity = createAsyncThunk('createBillingEntity', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/billing-entity`, payload);
      toastUp("Billing entity created successfully", true)
      thunkAPI.dispatch(fetchBillingEntities())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false, 'billing entity'), false)
      reject(e)
    }
  })
})
export const createCPTCode = createAsyncThunk('createCPTCode', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/cpt-code`, payload);
      toastUp("CPT code created successfully", true)
      thunkAPI.dispatch(fetchCPTCodes())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false, 'CPT code'), false)
      reject(e)
    }
  })
})
export const createPPEKit = createAsyncThunk('createPPEKit', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/ppe-kits`, payload);
      toastUp("PPE Kit created successfully", true)
      thunkAPI.dispatch(fetchInsuranceProviders())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false, 'PPE Kit'), false)
      reject(e)
    }
  })
})
export const createCity = createAsyncThunk('createCity', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.post(`/city-boundaries`, payload);
      toastUp("City created successfully", true)
      thunkAPI.dispatch(fetchWPCities())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false, 'city'), false)
      reject(e)
    }
  })
})
export const createBiologicKit = createAsyncThunk('createBiologicKit', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const { product_id, ...rest } = payload
    try {
      await apiRequest.post(`/biologic-products/${product_id}/biologic-kits`, rest);
      toastUp('Biologic kit created successfully', true)
      thunkAPI.dispatch(fetchBiologicProducts())
      resolve()
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})

export const editBillingGroup = createAsyncThunk('/edit_billing_group', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.put(`/billing-entity/${payload?.id}`, payload);
      //console.log("Edited Billing Group", response)
      toastUp("Billing Group edited successfully", true)
      thunkAPI.dispatch(fetchBillingGroupPerState(payload.state))
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const editMedicalDirector = createAsyncThunk('editMedicalDirector', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.put(`/state-signatories/${payload?.id}`, payload);
      //console.log("Edited Medical director", response)
      toastUp("Medical director edited successfully", true)
      thunkAPI.dispatch(fetchMedicalDirectors({ state: payload.state }))
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const editWCCSignature = createAsyncThunk('editWCCSignature', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      await apiRequest.post(`consultants/${payload.id}/signature`, { signature_id: payload.signature_id })
      toastUp("signature edited successfully", true)
      await thunkAPI.dispatch(fetchMedicalDirectors({ state: payload.state })).unwrap()
      resolve()
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const editInsuranceProvider = createAsyncThunk('editInsuranceProvider', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/insurance/info/${payload?.id}`, payload)
      toastUp("Insurance provider edited successfully", true)
      thunkAPI.dispatch(fetchInsuranceProviders())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const editBillingEntity = createAsyncThunk('editBillingEntity', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/billing-entity/${payload?.id}`, payload)
      toastUp("Billing entity edited successfully", true)
      await thunkAPI.dispatch(fetchBillingEntities())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const editCPTCode = createAsyncThunk('editCPTCode', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/cpt-code/${payload?.id}`, payload)
      toastUp("CPT Code edited successfully", true)
      await thunkAPI.dispatch(fetchCPTCodes())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const editPPEKit = createAsyncThunk('editPPEKit', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/ppe-kits/${payload?.id}`, payload)
      toastUp("PPE kit edited successfully", true)
      thunkAPI.dispatch(fetchInsuranceProviders())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const editCity = createAsyncThunk('editCity', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.patch(`/city-boundaries/${payload?.id}`, payload)
      toastUp("Insurance provider edited successfully", true)
      thunkAPI.dispatch(fetchInsuranceProviders())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const editBiologicProduct = createAsyncThunk('editBiologicProduct', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const { name, ...rest } = payload
    try {
      const response = await apiRequest.patch(`/biologic-products/${payload.id}/`, rest);
      toastUp("Biologic kit edited successfully", true)
      thunkAPI.dispatch(fetchBiologicProducts())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const editBiologicKit = createAsyncThunk('editBiologicKit', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const { product_id, name, ...rest } = payload
    try {
      const response = await apiRequest.patch(`/biologic-kits/${payload.id}`, rest);
      toastUp("Biologic kit edited successfully", true)
      thunkAPI.dispatch(fetchBiologicProducts())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})

export const deleteBillingGroup = createAsyncThunk('/delete_billing_group', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const {id,state}=payload
      const response = await apiRequest.delete(`/billing-entity/${id}`);
      //console.log("Deleted Billing Group", response)
      toastUp("Billing Group deleted successfully", true)
      thunkAPI.dispatch(fetchBillingGroupPerState(state))
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const deleteMedicalDirector = createAsyncThunk('deleteMedicalDirector', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/state-signatories/${payload.id}`);
      //console.log("Deleted Medical director", response)
      toastUp("Medical director deleted successfully", true)
      thunkAPI.dispatch(fetchMedicalDirectors({ state: payload.state }))
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const deleteInsuranceProvider = createAsyncThunk('deleteInsuranceProvider', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/insurance/info/${payload}`);
      //console.log("Deleted Billing Group", response)
      toastUp("Insurance provider deleted successfully", true)
      thunkAPI.dispatch(fetchInsuranceProviders())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const deleteBillingEntity = createAsyncThunk('deleteBillingEntity', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/billing-entity/${payload}`);
      //console.log("Deleted Billing Group", response)
      toastUp("Billing entity deleted successfully", true)
      thunkAPI.dispatch(fetchBillingEntities())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const deleteCPTCode = createAsyncThunk('deleteCPTCode', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/cpt-code/${payload}`);
      //console.log("Deleted Billing Group", response)
      toastUp("CPT Code deleted successfully", true)
      thunkAPI.dispatch(fetchCPTCodes())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const deletePPEKit = createAsyncThunk('deletePPEKit', async (id, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/ppe-kits/${id}`);
      toastUp("PPE Kit deleted successfully", true)
      thunkAPI.dispatch(fetchPpeKits())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const deleteCity = createAsyncThunk('deleteCity', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/city-boundaries/${payload}`);
      //console.log("Deleted Billing Group", response)
      toastUp("City deleted successfully", true)
      thunkAPI.dispatch(fetchWPCities())
      resolve()
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const deleteBiologicProduct = createAsyncThunk('deleteBiologicProduct', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await apiRequest.delete(`/biologic-products/${payload.id}`);
      toastUp("Product deleted successfully", true)
      thunkAPI.dispatch(fetchBiologicProducts())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})
export const deleteBiologicKit = createAsyncThunk('deleteBiologicKit', async (payload, thunkAPI) => {
  return new Promise(async (resolve, reject) => {
    const { product_id, id } = payload
    try {
      const response = await apiRequest.delete(`/biologic-kits/${id}`);
      toastUp("Product deleted successfully", true)
      thunkAPI.dispatch(fetchBiologicProducts())
      resolve(response.data)
    } catch (e) {
      //console.log(e)
      toastUp(errMsg(e, false), false)
      reject(e)
    }
  })
})

// export const getDefaultBillingGroup = createAsyncThunk('/default_billing_group', async (payload, thunkAPI) => {
//     return new Promise(async (resolve, reject) => {
//         try{
//             const response = await apiRequest.get(`/billing-region?state=${payload}&default_billing_group=yes`);
//             console.log("Default Billing Group", response)
//             resolve(response.data.data)
//         } catch (e) {
//             console.log(e)
//             toastUp(errMsg(e, true, 'Default Billing Group'), false)
//             reject(e)
//         }
//     })
// })

export const MasterSlice = createSlice({
  name: 'masterlist', initialState, // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    selectLocation: (state, action) => {
      state.location = action.payload
    }, resetInsuranceProviderPagination: (state) => {
      state.insuranceProviderPagination = initialState.insuranceProviderPagination
    }, resetBillingEntityPagination: (state) => {
      state.billingEntityPagination = initialState.billingEntityPagination
    }, resetCPTCodePagination: (state) => {
      state.CPTCodePagination = initialState.CPTCodePagination
    }, resetPpeKitsPagination: (state) => {
      state.ppeKitsPagination = initialState.ppeKitsPagination
    }, resetWpRegionsPagination: (state) => {
      state.wpRegionsPagination = initialState.wpRegionsPagination
    }, resetWpSubRegionsPagination: (state) => {
      state.wpSubRegionsPagination = initialState.wpSubRegionsPagination
    }, resetWpStatesPagination: (state) => {
      state.wpStatesPagination = initialState.wpStatesPagination
    }, resetWpCitiesPagination: (state) => {
      state.wpCitiesPagination = initialState.wpCitiesPagination
    },resetBiologicProductsPagination: (state) => {
      state.biologicProductsPagination = initialState.biologicProductsPagination
    }, updateOpenSettings: (state, action) => {
      state.openSettingsPath = [...action.payload]
    },
    doResetMasterListState: (state, action) => {
      Object.keys(initialState).map((key) => (state[key] = initialState[key]));
    },
  }, extraReducers: (builder)=>{
    builder.addCase(fetchMasterListFields.fulfilled, (state, action) => {
      //console.log(action.payload)
      state.masterList = [...action.payload.masterList]
      state.masterListRaw = action.payload.masterListRaw
    }).addCase(fetchBillingRegions.fulfilled, (state, action) => {
      state.billingRegions = action.payload.data
    }).addCase(fetchInsuranceProviders.fulfilled, (state, action) => {
      state.insuranceProviders = action.payload.data
    }).addCase(fetchBillingEntities.fulfilled, (state, action) => {
      state.billingEntities = action.payload.data
    }).addCase(fetchCPTCodes.fulfilled, (state, action) => {
      state.CPTCodes = action.payload.data
      state.CPTCodePagination = action.payload?.pagination
    }).addCase(fetchPpeKits.fulfilled, (state, action) => {
      console.log({ 'action.payload': action.payload })
      state.ppeKits = [...action.payload.data]
      state.ppeKitsPagination = action.payload?.pagination
    }).addCase(fetchWPRegions.fulfilled, (state, action) => {
      //console.log('action.payload: ',action.payload)
      state.wpRegions = action.payload.data
      state.wpRegionsPagination = action.payload.pagination
    }).addCase(fetchWPSubRegions.fulfilled, (state, action) => {
      state.wpSubRegions = action.payload.data
      state.wpSubRegionsPagination = action.payload.pagination
    }).addCase(fetchWPStates.fulfilled, (state, action) => {
      state.wpStates = action.payload.data
      state.wpStatesPagination = action.payload.pagination
    }).addCase(fetchWPCities.fulfilled, (state, action) => {
      state.wpCities = action.payload.data
      state.wpCitiesPagination = action.payload.pagination
    }).addCase(fetchBiologicProducts.fulfilled, (state, action) => {
      state.biologicProducts = action.payload.data
      state.biologicProductsPagination = action.payload.pagination
    }).addCase(fetchBillingGroupPerState.fulfilled, (state, action) => {
      state.billingGroups = action.payload
    }).addCase(fetchMedicalDirectors.fulfilled, (state, action) => {
      state.medicalDirectors = action.payload
    }).addCase(fetchStateBoundaries.fulfilled, (state, action) => {
      state.stateBoundaries = action.payload
    }).addCase(fetchAvailableSignatoryStates.fulfilled, (state, action) => {
      state.availableSignatoryStates = action.payload
    })
  }
});

export const {
  resetPpeKitsPagination,
  resetInsuranceProviderPagination,resetBillingEntityPagination,resetCPTCodePagination,
  updateOpenSettings,
  resetWpRegionsPagination,
  resetWpSubRegionsPagination,
  resetWpStatesPagination,
  resetWpCitiesPagination,
  doResetMasterListState,
  resetBiologicProductsPagination,
} = MasterSlice.actions
export default MasterSlice.reducer
