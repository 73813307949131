/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import mapboxgl from 'mapbox-gl';
import { getMapboxIdByStateName, mapboxToken } from "./utils/utils";
import { connect, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import ReactDOM from 'react-dom';

export const TerritorySelection = ({ dataSource, layers, sourceName, mapData, sales_territories, sales_regions, macs, zonalSelectionMenuVisible }) => {
    const dispatch = useDispatch()
    const mapContainer = useRef(null);
    const map = useRef(null);

    const [lng, setLng] = useState(-99.9);
    const [lat, setLat] = useState(45.5);
    const [zoom, setZoom] = useState(4);
    const [selectedStates, setSelectedStates] = useState([]);

    let previouslyHoveredFeatureId = null;
    mapboxgl.accessToken = mapboxToken;

    function setFeatureStates() {
        if (mapData) {
            for (const row of mapData) {
                map.current.setFeatureState({
                    source: sourceName,
                    sourceLayer: 'boundaries_admin_1',
                    id: row.mapbox_id,
                }, { hover: false, selected: false, name: row.name });
            }
        }
    }

    function setAfterLoad(event) {
        if (event.sourceId !== sourceName && !event.isSourceLoaded) return;

        setupLayers()
        if (zonalSelectionMenuVisible === 'Medicare MACS area') {
            // map.current.removeLayer("selected-states");
            setFeatureStateMacs()
        } else if (zonalSelectionMenuVisible === 'Sales and Marketing regions') {
            setFeatureStateForSalesRegions()
        } else if (zonalSelectionMenuVisible === 'Active, pending and inactive WP states') {

            setFeatureStateForTerritories()
        }

        map.current.off('sourcedata', setAfterLoad);
    }
    const FeaturePopup = ({ feature }) => {

        return (
            <div className="p-2">
                <p className="text-base font-semibold"> Feature Type: {feature.state.type}</p>

                <div className="bg-slate-100">
                    {/* {Object.keys(feature.state).map((key) => {
                        if (typeof feature.state[key] !== 'object' && !Array.isArray(feature.state[key])) {
                            return <p key={key} className="text-sm">{key}: {feature.state[key]}</p>;
                        }
                        return null;
                    })} */}

                    {feature.state.type === 'Medicare MACS area' && feature.state.company_name &&
                        <p className="font-semibold">Company:{feature.state.company_name}</p>}
                    <p className="font-semibold">State:{feature.state.name || feature.state.unit_code}</p>
                </div>
            </div>
        )
    }

    let currentPopup;
    let previouslyClickedStateId = null;
    const popupContainerRef = useRef(null);
    const popupContainer = document.createElement('div');
    popupContainerRef.current = popupContainer;


    useEffect(() => {
        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/light-v11',
            center: [lng, lat],
            zoom: zoom,
            projection: 'mercator',
        });


        map.current.on('load', () => {





            map.current.addSource(sourceName, {
                type: "vector",
                url: "mapbox://mapbox.boundaries-adm1-v4",
                promoteId: "mapbox_id",
            });

            map.current.addLayer({
                id: 'selectable-states',
                type: 'fill',
                source: sourceName,
                'source-layer': 'boundaries_admin_1',
                paint: {
                    'fill-color': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        'lightblue',
                        'rgba(255, 255, 255, 0)'
                    ],
                    'fill-opacity': 0.3,
                    'fill-outline-color': 'black',
                },
            }, 'waterway-label')
            



            map.current.addLayer({
                id: 'selected-states',
                type: 'fill',
                source: sourceName,
                'source-layer': 'boundaries_admin_1',
                paint: {
                    'fill-color': [
                        'case',
                        ['boolean', ['feature-state', 'selected'], false],
                        ['feature-state', 'color'],  // Use the feature's 'color' state property
                        'rgba(255, 255, 255, 0)'
                    ],
                    'fill-opacity': 0.7,
                    'fill-outline-color': 'black',
                }
            }, 'waterway-label');

            setupLayers()


            map.current.on('click', 'selected-states', (e) => {

                const feature = e.features[0];

                if (!feature.state.selected) return;
                const popupContainer = document.createElement('div');
                ReactDOM.render(<FeaturePopup feature={feature} />, popupContainer);

                if (currentPopup) {
                    currentPopup.remove();
                    currentPopup = null;
                }

                const popup = new mapboxgl.Popup({ closeButton: false })
                    .setLngLat(e.lngLat)
                    .setDOMContent(popupContainer);

                currentPopup = popup;
                currentPopup.addTo(map.current);
                previouslyClickedStateId = feature.id;
            });

            map.current.on('mouseleave', 'selected-states', () => {
                if (currentPopup) {
                    currentPopup.remove();
                    currentPopup = null;
                }
                previouslyClickedStateId = null;
            });




            map.current.on('sourcedata', setAfterLoad);


            setZoom(prev => prev);
        });

 

        return () => map.current.remove();
    }, []);



    useEffect(() => {
        // wait for map to load
        setupLayers()

        return () => {

        }
    }, [zonalSelectionMenuVisible])

    useEffect(() => {
        if (map.current) {
            console.log('style loaded 1')
            setupLayers();
        }
    }, [sales_territories, macs, sales_regions]);

    useEffect(() => {


        if (previouslyClickedStateId && map.current) {
            const feature = map.current.querySourceFeatures('selected-states').find(f => f.id === previouslyClickedStateId);
            if (feature) {
                const popupContainer = document.createElement('div');
                ReactDOM.render(<FeaturePopup feature={feature} />, popupContainer);

                if (currentPopup) {
                    currentPopup.remove();
                    currentPopup = null;
                }

                const popup = new mapboxgl.Popup({ closeButton: false })
                    .setLngLat(feature.geometry.coordinates[0])  // Adjust this if the coordinates array structure is different.
                    .setDOMContent(popupContainer);

                currentPopup = popup;
                currentPopup.addTo(map.current);
            }
        }
    }, [zonalSelectionMenuVisible]);



    const setupLayers = () => {

        if (map.current && map.current.isStyleLoaded()) {
            setFeatureStates()
            console.log('style loaded 2')
            if (zonalSelectionMenuVisible === 'Medicare MACS area') {
                console.log('style loaded 3')
               
              
                setFeatureStateMacs().then(() => {
                    console.log('Feature states have been set.');
                }).catch(error => {
                    console.error('An error occurred while setting feature states:', error);
                });
            } else if (zonalSelectionMenuVisible === 'Sales and Marketing regions') {
                setFeatureStateForSalesRegions()
            } else if (zonalSelectionMenuVisible === 'Active, pending and inactive WP states') {

                setFeatureStateForTerritories()
            }
        }
    }

    // This function can be async because it's using an async function internally
    async function setFeatureStateForTerritories() {
        for (const feature of sales_territories.data) {
            const id = await getMapboxIdByStateName(feature.state);
            if (id) {
                map.current.setFeatureState(
                    { source: sourceName, sourceLayer: 'boundaries_admin_1', id: id },
                    { selected: true, type: 'Active, pending and inactive WP states', color: feature.color_code, name: feature.name, ...feature }
                );

            } else {
                console.error(`Failed to get mapbox_id for state: ${feature.state}`);
            }
        }
    }

    async function setFeatureStateForSalesRegions() {
        for (const feature of sales_regions.data) {
            const id = await getMapboxIdByStateName(feature.name);
            if (id) {
                map.current.setFeatureState(
                    { source: sourceName, sourceLayer: 'boundaries_admin_1', id: id },
                    { selected: true, type: 'Sales and Marketing regions', color: feature.color_code, ...feature }
                );

            } else {
                console.error(`Failed to get mapbox_id for state: ${feature.state}`);
            }
        }
    }


    async function setFeatureStateMacs() {
        const flattened = [];

        macs.data.forEach(company => {
            let randomcolor = '#' + Math.floor(Math.random() * 16777215).toString(16);
            company.jurisdictions.forEach(jurisdiction => {
                jurisdiction.territories.forEach(territory => {
                    flattened.push({
                        company_name: company.company_name,
                        status: company.status,
                        jurisdiction_code: jurisdiction.jurisdiction_code,
                        claim_type: jurisdiction.claim_type,
                        start_date: jurisdiction.start_date,
                        end_date: jurisdiction.end_date,
                        territory_id: territory.id,
                        territory_type: territory.territory_type,
                        unit_code: territory.unit_code,
                        // randomcolor code for now
                        color_code: company.display_color_code || randomcolor,
                    });
                });
            });
        });


        for (const feature of flattened) {
            try {
                const id = await getMapboxIdByStateName(feature.unit_code);
                if (id) {
                    map.current.setFeatureState(
                        { source: sourceName, sourceLayer: 'boundaries_admin_1', id },
                        { selected: true, type: 'Medicare MACS area', color: feature.color_code, name: feature.company_name, ...feature }
                    );
                } else {
                    console.error(`Failed to get mapbox_id for unit code: ${feature.unit_code}`);
                }
            } catch (error) {
                console.error(`Error processing feature: ${error.message}`);
            }
        }
    }



    return <div>
        {/* <input type="text" className="absolute w-96 h-12 p-2 right-10 top-10 rounded-md bg-gray-50 shadow-md z-10" placeholder="Location search" /> */}
        <div className="h-screen w-screen absolute top-0 bottom-0" ref={mapContainer}></div>
    </div>
}

const mapStateToProps = (state) => ({
    sales_territories: state.map.sales_territories,
    macs: state.map.macs,
    sales_regions: state.map.sales_regions,
    zonalSelectionMenuVisible: state.map.zonalSelectionMenuVisible,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TerritorySelection)