import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {DatePicker, Form, Input, Select} from 'antd'
import {createVehicle, updateVehicle} from '../../../app/Reducers';
import dayjs from 'dayjs';
import {emptyFun, executeChunkFn, inputRow} from "../../../common/helpers";

const ConsultantVehicle = ({closeModal, consultant, vehicle, setisExecuting, makes}) => {
  const {Option} = Select;
  const [form] = Form.useForm()
  // let initialValues = vehicle ? vehicle : {default: true};
  // const [makes, setMakes] = useState([])
  const [models, setModels] = useState([])
  const initialValues = vehicle ? {
    ...vehicle, year: dayjs(vehicle.year)
  } : null;
  useEffect(() => {
    // fetchMakes()
    if (vehicle) {
      fetchModels(vehicle.make)
      
    }
    return emptyFun
  }, [])
  const onSelect = (value) => {
    fetchModels(value)
  }
  // const fetchMakes = async () => {
  //   const response = await axios.get(`https://vpic.nhtsa.dot.gov/api/vehicles/GetMakesForVehicleType/car?format=json`).catch(function (err) {
  //     console.log(err.response)
  //     // toast.update(toastId, { render: err.response.data.message, type: toast.TYPE.ERROR, autoClose: 5000 });
  //   })
  //   if (response && response.data && response.data.Results) {
  //     setMakes(response.data.Results)
  //   }
  // }
  const fetchModels = async (make) => {
    
    
    setModels(makes.find(item => item.make_name === make).models)
    
  }
  const dispatch = useDispatch()
  const onFinish = async (values) => {
    let payload = {data: {...values, consultant_id: consultant.id}, vehicle_id: vehicle ? vehicle.id : null}
    await executeChunkFn(dispatch, vehicle ? updateVehicle : createVehicle, payload, setisExecuting, closeModal)
  };
  const disabledFutureDates = (current) => current && current > dayjs(dayjs())
  return (<Form
    name="new-patient"
    form={form}
    id="consultantForm"
    preserve={false}
    labelCol={{span: 16}}
    initialValues={initialValues}
    layout="vertical"
    onFinish={onFinish}>
    <Form.Item
      name="make"
      label="Vehicle Make"
      rules={[{required: true, message: 'Missing Vehicle Make'}]}
    >
      {/* <Input autoComplete={'off'} placeholder="Vehicle Make" /> */}
      <Select popupMatchSelectWidth={false} onSelect={onSelect}>
        {makes.map(item => <Option key={item.make_name} value={item.make_name}>{item.make_name}</Option>)}
      </Select>
    </Form.Item>
    <Form.Item
      name="model"
      label="Model"
      rules={[{required: true, message: 'Missing Model'}]}
    >
      {/* <Input autoComplete={'off'}placeholder="Model" /> */}
      <Select popupMatchSelectWidth={false}>
        {models.map(item => <Option key={item} value={item}>{item}</Option>)}
      </Select>
    </Form.Item>
    {inputRow([
      <Form.Item
        style={{width: '100%'}}
        name="year"
        label="Year"
        rules={[{required: true, message: 'Missing Year'}]}>
        <DatePicker.YearPicker disabledDate={disabledFutureDates} style={{width: '100%'}}/>
      </Form.Item>,
      <Form.Item
        style={{width: '100%'}}
        name="fuel_type"
        label="Fuel Type"
        rules={[{required: true, message: 'Missing Fuel Type'}]}>
        <Select style={{width: 200, borderRadius: 6}} popupMatchSelectWidth={false}
                placeholder={'Select Fuel Type'}>
          <Option key={"Gasoline"} value="Gasoline">Gasoline</Option>
          <Option key={"Diesel"} value="Diesel">Diesel</Option>
          <Option key={"Bio-diesel"} value="Bio-diesel">Bio-diesel</Option>
          <Option key={"Ethanol"} value="Ethanol">Ethanol</Option>
          <Option key={"Electric"} value="Electric">Electric</Option>
        </Select>
      </Form.Item>
    ])}
    <div className="flex-boy-row-space">
      <Form.Item
        style={{width: '100%'}}
        rules={[{required: true, message: 'Missing Registration No.'}]}
        name="registration_no"
        label="Registration No."
      >
        <Input autoComplete={'off'} placeholder="Registration No."/>
      </Form.Item>
    
    </div>
  </Form>)
}
const mapStateToProps = (state) => ({
  makes: state.consultants.consultantVehicles
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ConsultantVehicle)
