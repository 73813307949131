import React, {useEffect, useState} from 'react';
import {Headline6} from "../../../components";
import {Col, Row} from "antd";
import dayjs from "dayjs";
import {connect, useDispatch} from "react-redux";

import {fetchPatientById, setPatientDetailsLoading, updatePatientDetailsOwner} from "../../../app/Reducers";
import {appMessage, dialogCloser, emptyFun, kebabMenus, boldKeyCapitalizedValue, boldKeyCapitalizedValueGrayBackground, skelBars, capitalizeFirstLetter, boldKeyUntransformedValue} from "../../../common/helpers";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";

import KebabMenu from "../../../components/KebabMenu";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import {apiRequest} from '../../../app/Apis';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useLocation, useNavigate} from 'react-router-dom';

function PatientInfoPane({ patient, setDetailsForm, navs, activeUser, ...props }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate();
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const { message } = useSubscription(`view_patient/${patient.id}`, { qos: 2, nl: true });
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient = usePermissionCheck('update_patient', false, [])
  const can_view_patient_ssn = usePermissionCheck('view_patient_ssn', false, [])
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await dispatch(fetchPatientById(Number(patient.id)))
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    (async () => {
      if (!patient || (patientId !== patient.id)) {
        await dispatch(setPatientDetailsLoading(true))
        await dispatch(fetchPatientById(Number(patientId)))
        await dispatch(setPatientDetailsLoading(false))
        await dispatch(updatePatientDetailsOwner(patientId))
      }
    })().catch(e => console.log(e))
    return emptyFun
  }, [patient])
  
  
  const actionMenus = kebabMenus((can_update_patient && !patient.deleted_at), can_view_audit_logs, false)
  
  async function kebabMenuClick(e) {
    switch (actionMenus[e.key].title) {
      case 'Edit':
        await setDetailsForm(true);
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      case 'Delete':
        appMessage('Operation not allowed', 'error')
        break
      default:
        await setShowAuditDialog(false);
        await setDetailsForm(false);
    }
  }
  
  async function getDefaultBillingGroup(state) {
    const response = await apiRequest.get(`/billing-region?state=${state}`)
    let billingInfo = response.data.data
    let defaultGroup;
    if (billingInfo === 0) {
      let billingGroup = billingInfo?.billing_groups
      console.log('billingInfo', billingInfo.data.data)
      defaultGroup = billingGroup?.find(item => item.is_default === 'true')
      return defaultGroup
    } else {
      defaultGroup = 'No Billing Group'
    }
    console.log('defaultGroup', defaultGroup)
    return defaultGroup
  }
  
  return (<div className="card-box">
    {showAuditDialog && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)}
                                        loading={false} resourceDescription={patient.name} resourceType={'Patient'}
                                        defaultValues={{ auditableId: patientId, auditable: 'patient', format: 'json' }}/>}
    <div className="flex-boy-row-space" style={{ width: "100%" }}>
      <Headline6>Patient Information</Headline6>
      {/*{( can_update_patient && !patient.deleted_at) ?
        <IconButton type={"button"} size={"small"} onClick={() => {
          setDetailsForm(true);
        }}> <EditOutlined
            fontSize={"small"} />
        </IconButton> : null}*/}
      <KebabMenu menus={actionMenus}
                 recordItem={patient}
                 handleMenuClick={kebabMenuClick} resource={'patient'} handleOk={null} handleCancel={null} confirmLoading={null}
                 showConfirmDialog={false}/>
    </div>
    {navs.patientDetailsLoading ? skelBars() : <Row gutter={[4, 4]} align={"stretch"} justify={"start"} style={{ width: '100%', margin: 0 }}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} flex={"auto"} style={{ width: '100%' }}>
        {boldKeyCapitalizedValue('Name', patient.name)}
        {boldKeyCapitalizedValueGrayBackground('Gender', capitalizeFirstLetter(patient.gender))}
        {boldKeyUntransformedValue('Date of birth', `${dayjs(patient.birth_date).format("MM/DD/YYYY")}(${dayjs().diff(dayjs(patient.birth_date), 'years')}y.o.)`)}
        {can_view_patient_ssn && boldKeyCapitalizedValueGrayBackground('SSN', patient.ssn || 'N/A')}
        {boldKeyCapitalizedValue('Patient ID', patient.id)}
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} flex={"auto"} style={{ width: '100%' }}>
        {boldKeyCapitalizedValueGrayBackground('Phone', patient.phone_no)}
        
        {boldKeyCapitalizedValue('Email', patient.email || 'N/A')}
        {boldKeyCapitalizedValueGrayBackground('Office:', patient?.medical_facility || 'N/A')}
        {boldKeyCapitalizedValue('Type', 'Patient')}
        {/* {boldKeyCapitalizedValueGrayBackground('Service Type', patient.service_type || 'N/A')} */}
        {boldKeyCapitalizedValueGrayBackground('Status', patient.status)}
        
        
        {/* {boldKeyCapitalizedValue('Default Billing Group' , getDefaultBillingGroup(patient?.state))} */}
        {/* {boldKeyCapitalizedValue('', patient.address_2)} */}
        {/* {!activeUser?.facilityProfile && boldKeyCapitalizedValue('Smartsheet ID', patient.smartsheet_id || 'N/A')} */}
        
        {/* <div className="flex-box-address" style={{width: "100%"}}>
          <Subtitle1 style={{textAlign: 'left', flex: 1}}>Address</Subtitle1>
          <Body2 color="black" style={{textAlign: 'left', flex: 1}}>{patient.address_1}
            <br/> <br/>{patient.address_2}
          </Body2>
        </div>*/}
      </Col>
    </Row>}
  </div>);
}

const mapStateToProps = (state) => ({
  wounds: state.patients.patientWounds, patient: state.patients.selectedPatient,
  appConf: state.appConf, navs: state.navs, activeUser: state.auth?.activeUser
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PatientInfoPane);
