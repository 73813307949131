import React from 'react';
import { Dropdown, Menu } from "antd";
import { appConfirm } from "../common/helpers";
import {MoreOutlined} from '@ant-design/icons';

function KebabMenu({ handleMenuClick, menus, recordItem, showConfirmDialog, handleOk, handleCancel, confirmLoading, resource }) {
  const menu = (item) => {
    // console.log('menu: ', item)
    return <Menu onClick={(e) => handleMenuClick(e, item)}>{menus.map((item, index) => <Menu.Item key={index} icon={item.icon}> {item.title}  </Menu.Item>)}</Menu>
  }
  return !!menus[0] ?(
    <Dropdown dropdownRender={()=>menu(recordItem)} trigger={['click']} disabled={showConfirmDialog} arrow>
      {appConfirm(
        <MoreOutlined className={'text-lg font-bold'}/>, showConfirmDialog, null, `Are you sure of deleting this ${resource}?`, () => handleOk(recordItem.id), handleCancel, confirmLoading)}
    </Dropdown>
  ):null;
}

export default KebabMenu;
