/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Subtitle1} from '../../../../components/general'
import {Button, Grid, Table} from "antd"

import dayjs from 'dayjs'
import {deletePatientTask, fetchPatientById, fetchPatientWounds, resetPatientWoundsPagination, selectPatientTask, selectWound} from "../../../../app/Reducers";
import {updatePatientWoundsFilters} from "../../../../app/Reducers/ConfigSlice";
import {
  dialogCloser,
  emptyFun,
  emptyTable,
  executeChunkFn,
  GetRawListOptions,
  getSkels,
  getWoundStatus,
  kebabMenus,
  makeAutoCompleteOptions,
  onTableChange,
  responsiveTbl
} from "../../../../common/helpers";
import {setPatientDetailsLoading, updatePatientDetailsOwner} from "../../../../app/Reducers/NavSlice";
import TableFilters from "../../../../components/InputComponents/TableFilters";
import {useSubscription} from "../../../../hooks/MqttHooks/useSubscription";
import {createFromIconfontCN} from '@ant-design/icons';
import DuplicateWounds from './DuplicateWounds';
import {usePermissionCheck} from '../../../../hooks/usePermissionCheck';
import KebabMenu from '../../../../components/KebabMenu';
import AuditLogViewer from '../../../../components/AuditLogs/AuditLogViewer';
import DynamicModalForm from '../../../../components/DynamicModalForm';
import {deleteReasonInputFields} from '../../../../common/inputUtils';
import * as Sentry from '@sentry/react';
import {errMsg, toastUp} from '../../../../app/Reducers/reducerUtils';
import {useLocation, useNavigate} from 'react-router-dom';

const { useBreakpoint } = Grid;
const PatientWounds = ({ wounds, patient, appConf, navs, patientWoundsPagination}) => {
  const location = useLocation()
  const navigate = useNavigate();
  const windowState=useBreakpoint()
  const dispatch = useDispatch()
  const [isVisible, setIsVisible] = useState(false);
  const [duplicateWoundsShowing, showDuplicateWounds] = useState(false);
  const [selectedWound, setSelectedWound] = useState(null);
  const [duplicateWoundIds, setDuplicateWoundIds] = useState([]);
  const patientId = patient?.id || Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const {message} = useSubscription(`view_patient_wounds/${patientId}`, {qos: 2, nl: true});
  
  console.log({wounds})
  const [isExecuting, setIsExecuting] = useState(false)
  const [selected, setSelected] = React.useState(null);
  const [isOpen, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [actionMenuRecord, setActionMenuRecord] = useState(null);
  const [isDynamicFormOpen, setDynamicFormOpen] = useState(false);
  const [formName, setFormName] = useState(undefined);
  const [defaultDynamicModalFormValues, setDefaultDynamicModalFormValues] = useState(undefined);
  
const can_view_audit_logs = usePermissionCheck('view_audit_logs', false, [])
  const can_update_patient_wound = usePermissionCheck('update_patient_wound', false, [])
  const can_delete_patient_wound = usePermissionCheck('delete_patient_wound', false, [])
  
  // console.log({wounds})
  useEffect(() => {
    if (!!message) {
      (async () => {
        console.log('MQTT MESSAGE:  ', message)
        await loadWounds(true)
      })().catch(e => console.log(e))
    }
    return emptyFun
  }, [message])
  useEffect(() => {
    (async () => {
      await loadWounds(false)
    })().catch(e => console.log(e))
    return emptyFun
  }, [])
  let onCell = (record, rowIndex) => ({
    onClick: event => {
      dispatch(selectWound({navigate, record: record}))
      navigate(`/wounds/${record.id}`)
    }
  })
  const actionMenus = kebabMenus(can_update_patient_wound, can_view_audit_logs, can_delete_patient_wound)
  let columns = [
    {
      onCell,
      title: 'Date Created', dataIndex: 'created_at', key: 'creation_date', fltr: {filterable: true, type: 'date'},
      render: (created_at) => (<Subtitle1 style={{textAlign: 'left'}}>{dayjs(created_at).format("MM/DD/YYYY")}</Subtitle1>)
    },
    {
      onCell,
      title: 'Wound',
      key: 'location',
      fltr: {filterable: true, type: 'autocomplete', options: makeAutoCompleteOptions(GetRawListOptions('wound_location'))},
      render: (record) => (<Subtitle1 style={{textAlign: 'left'}}>{record?.side} {record?.position} {record?.location} </Subtitle1>)
      
    },
    {
      onCell,
      title: 'Wound Status', dataIndex: 'status', key: 'status', fltr: {
        filterable: true, type: 'autocomplete',
        options: makeAutoCompleteOptions(['unchanged', 'improving', 'deteriorating', 'new', 'healed', 'resolved', 'stalled'])
      }, render: (status) => getWoundStatus(status?.toLowerCase())
    }, {
      title: 'Duplicate wounds',
      render: (_, record) => {
        // console.log({record})
        return record?.has_duplicates ? <Button onClick={async () => {
          await setSelectedWound(record)
          showDuplicateWounds(true)
        }} style={{color: '#3d91ff'}} type={'text'}>Merge wounds</Button> : null;
      }
    },
    {
      fltr: { filterable: false }, render: (record) => {
        return <KebabMenu
          menus={actionMenus}
          recordItem={record}
          handleMenuClick={kebabMenuClick}
          resource="wound"
          handleOk={() => handleOk(record)}
          showConfirmDialog={selected === record.id}
          handleCancel={handleCancel}
          confirmLoading={confirmLoading}
        />;
      }
    }]
  
  //Do not pass inputs as a useState variable, it causes props of useState values not to propagate state to form items
  let dynamicFormInputFields = { 'Delete Wound': deleteReasonInputFields }
  const handleOk = async (record) => {
    await setActionMenuRecord(record)
    await setFormName('Delete Wound');
    await setDefaultDynamicModalFormValues(!!record ? { id: record.id } : undefined);
    await setDynamicFormOpen(true);
    setSelected(0)
  };
  
  const handleCancel = () => setSelected(0);
  const showPopconfirm = (id) => setSelected(id);
  
  async function kebabMenuClick(e, record) {
    // console.log({actionMenus, e, record, title: actionMenus[e.key].title })
    await setActionMenuRecord(record)
    switch (actionMenus[e.key].title) {
      case 'Edit':
        //Not necessary
        break
      case 'Delete':
        showPopconfirm(record.id)
        break
      case 'Audit':
        setShowAuditDialog(true);
        break
      default:
    }
  }
  const loadWounds = async (isMQMessage) => {
    /*if (!!wounds[0] && !isMQMessage && wounds.every(item => item.patient_id === patientId)) {
      return
    }*/
    await dispatch(updatePatientWoundsFilters([appConf.patientWoundsFilters[0], appConf.patientWoundsFilters[1], !isMQMessage]))
    //In case of back navigations
    if (!patient || (patientId !== patient.id)) {
      await dispatch(setPatientDetailsLoading(!isMQMessage))
      await dispatch(fetchPatientById(Number(patientId)))
      await dispatch(setPatientDetailsLoading(false))
      await dispatch(updatePatientDetailsOwner(patientId))
    }
    await dispatch(fetchPatientWounds(appConf.patientWoundsFilters[0] ? appConf.patientWoundsFilters[1] : patient))
    await dispatch(updatePatientWoundsFilters([appConf.patientWoundsFilters[0], appConf.patientWoundsFilters[1], false]))
  }
  
  async function handleFilterAction(action, values) {
    await dispatch(resetPatientWoundsPagination())
    if (action === 'filter') {
      console.log('FILTERS:  ', JSON.stringify({...values, toLoad: 'patientWounds', endpoint: '/patients/1/wounds'}));
      let pl = {filterData: {patient: patientId, ...values}, pagination: patientWoundsPagination}
      await dispatch(updatePatientWoundsFilters([false, pl, true]))
      await dispatch(fetchPatientWounds(pl))
      await dispatch(updatePatientWoundsFilters([true, pl, false]))
    }
    if (action === 'reset'/* && appConf.patientWoundsFilters[0]*/) {
      await dispatch(updatePatientWoundsFilters([appConf.patientWoundsFilters[0], undefined, true]))
      await dispatch(fetchPatientWounds(patient))
      await dispatch(updatePatientWoundsFilters([false, undefined, false]))
    }
    // const response = await apiRequest.get(`/patients/${patient.id}/wounds`,{params:{...values, toLoad: 'patientWounds'}})
    setIsVisible(false)
  }
  
  
  /**
   * This function is handling returned form entries from the dynamic form
   * */
  const onDynamicFormEntriesResult = async (entries) => {
    //console.log({entries})
    try {
      switch (formName) {
        case 'Delete Wound':
          await setDynamicFormOpen(false);
          //Not necessary
          break;
        default:
          return
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      toastUp(errMsg(e, false, formName), false);
    }
  };
  return (<>
    {(!!actionMenuRecord && showAuditDialog) && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)} loading={false}
                                                                resourceDescription={`${actionMenuRecord?.side} ${actionMenuRecord?.position} ${actionMenuRecord?.location}`}
                                                                resourceType={'Patient Wound'}
                                                                defaultValues={{ auditableId: actionMenuRecord?.id, auditable: 'wound', format: 'json' }}/>}
    {(isDynamicFormOpen && !!formName) && (
      <DynamicModalForm
        setDynamicFormOpen={setDynamicFormOpen}
        isDynamicFormOpen={isDynamicFormOpen}
        inputFields={dynamicFormInputFields[formName]}
        onDynamicFormEntriesResult={onDynamicFormEntriesResult}
        closeModal={dialogCloser(setDynamicFormOpen)}
        isExecuting={isExecuting}
        defaultValues={defaultDynamicModalFormValues}
        formName={formName}
      />
    )}
    <div className="w-full">
      {!!selectedWound &&
        <DuplicateWounds showDuplicateWounds={showDuplicateWounds} duplicateWoundsShowing={duplicateWoundsShowing} selectedWound={selectedWound} setSelectedWound={setSelectedWound}/>}
      <Table
        title={() => (<TableFilters datasource={columns} setIsVisible={setIsVisible} isVisible={isVisible}
                                    handleFilterAction={handleFilterAction} filters={appConf.patientWoundsFilters[1]}
                                    showClear={appConf.patientWoundsFilters[0]} loading={appConf.patientWoundsFilters[2]}/>)}
        loading={appConf.patientWoundsFilters[2] || navs.patientDetailsLoading} size={'small'} {...responsiveTbl(windowState)} locale={{emptyText: emptyTable('patient wounds.')}}
        onChange={(pagination, filters, sorter) => onTableChange(pagination, filters, sorter, dispatch, fetchPatientWounds, appConf.patientWoundsFilters, updatePatientWoundsFilters, {patient: patientId})}
        {...getSkels((appConf.patientWoundsFilters[2] || navs.patientDetailsLoading), columns, wounds)} pagination={patientWoundsPagination}/>
    </div>
  </>)
}
const mapStateToProps = (state) => ({ wounds: state.patients.patientWounds, patient: state.patients.selectedPatient,
  appConf: state.appConf,
  patientWoundsPagination: state.patients.patientWoundsPagination,
  navs: state.navs
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(PatientWounds)
