import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Col, Row, Dropdown, Menu } from 'antd';
import { boldKeyCapitalizedValue, emptyDocuments, appLog } from '../../../common/helpers';
import {
  Body1,
  Headline6,
  RegularButton,
  Subtitle1,
} from '../../../components/general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Breadcrumb, Image } from 'antd';
import dayjs from 'dayjs';

export const PreviewFacilityPane = ({
  
  facility,
  consultants,
  manager,
  activeUser,
}) => {
  console.log('Office:  ', facility)
  const { menu } = Menu;
  const [selected, setSelected] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [doc, setDoc] = useState(null);
  const handleCancel = () => setSelected(0);
  return (
    <div>
      <div className='flex-boy-column'>
        <div className='card-box'>
          <div className='flex-boy-row-space' style={{ width: '100%' }}>
            <Headline6>Primary Information</Headline6>
          </div>

          <Row gutter={48} style={{ width: '100%' }}>
            <Col span={12}>
              {boldKeyCapitalizedValue('Name', facility?.name)}
              {!activeUser?.facilityProfile &&
                boldKeyCapitalizedValue(
                  'Sales Manager',
                  facility?.sales_managers?.map((item) => item.name).toString() || 'N/A'
                )}
              {!activeUser?.facilityProfile &&
                boldKeyCapitalizedValue(
                  'Client Success Manager',
                  facility?.client_success_managers
                    ?.map((item) => item).toString() || 'N/A'
                )}
              {boldKeyCapitalizedValue('Type', facility?.facility_type === 'other' ? facility?.specify : facility?.facility_type)}
              {boldKeyCapitalizedValue('ID', facility?.id)}
              {boldKeyCapitalizedValue('Address', facility?.address)}
              {boldKeyCapitalizedValue('Address 2', facility?.address_2)}
              {boldKeyCapitalizedValue('Referring Organization', facility?.referral_source)}

            </Col>
            <Col span={12}>
              {boldKeyCapitalizedValue('Contact Person', facility?.primary_contact_name)}
              {boldKeyCapitalizedValue('Phone Number', facility?.phone_no)}

              {boldKeyCapitalizedValue('Primary Email', facility?.email)}
              {boldKeyCapitalizedValue('Website', facility?.website)}
              {boldKeyCapitalizedValue(
                'Additional Emails',
                facility?.additional_emails?.toString() || 'N/A'
              )}
              {boldKeyCapitalizedValue(
                'Additional Phone Numbers',
                facility?.additional_phone_numbers?.toString() || 'N/A'
              )}
            </Col>
          </Row>
        </div>
        <div className='card-box'>
          <div className='flex-boy-row-space' style={{ width: '100%' }}>
            <Headline6>Office Documents</Headline6>
          </div>
          <Row gutter={48} style={{ width: '100%' }}>
            <Col span={12}>
              {!!facility?.documents && !!facility?.documents[0]
                ? facility?.documents.map((doc, index) => {
                  console.log('Office DOC:  ', doc);
                  return (
                    <Row key={index} span={12}>
                      <div
                        className='document-div'
                        style={{ flexGrow: 1, margin: 10 }}
                      >
                        <div
                          className='flex-boy-row-space'
                          style={{ width: '100%' }}
                        >
                          <Subtitle1
                            style={{
                              alignSelf: 'center',
                              textTransform: 'capitalize',
                            }}
                          >
                            {'Type: ' + doc.type}
                          </Subtitle1>
                        </div>
                        <div className='flex-doc-row-space'>
                          {doc.file &&
                            (doc.file.mime_type.includes('png') ||
                              doc.file.mime_type.includes('jp')) ? (
                            <Image
                              style={{ width: '100px' }}
                              fallback={
                                require('../../../assets/doc.png')
                              }
                              alt='doc'
                              src={doc.file.url}
                            />
                          ) : (
                            <img
                              style={{ width: '100px', cursor: 'pointer' }}
                              aria-disabled={loading}
                              onClick={() => {
                                setLoading(true);
                              }}
                              src={require('../../../assets/doc.png')}
                              alt='doc'
                            />
                          )}
                          <div
                            style={{
                              padding: '10px',
                              flex: 1,
                              textAlign: 'start',
                            }}
                          >
                            <Body1 color='black'>{`${doc?.title?.replace(
                              /_/g,
                              ' '
                            )}`}</Body1>
                            {doc.expires_at && (
                              <Body1 color='black'>{`Expiration Date: ${dayjs(
                                doc.expires_at
                              ).format('MM/DD/YYYY')}`}</Body1>
                            )}
                          </div>
                        </div>
                      </div>
                    </Row>
                  );
                })
                : emptyDocuments('Document')}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  consultants: state.consultants.consultants,
  activeUser: state.auth?.activeUser,
});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewFacilityPane);
