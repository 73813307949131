/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-webpack-loader-syntax */
import React, { useEffect, useRef, useState } from 'react'
// mapboxgl.workerClass = MapboxWorker
// mapboxgl.accessToken = mapb
import { connect, useDispatch } from 'react-redux'
// import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import * as turf from '@turf/turf'
import { mapboxToken } from '../../../../configure/constants'
import { Subtitle1 } from '../../../general';
import HeaderTop from '../../../HeaderTop';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// import './Map.css';
// import endPosIcon from '../../../../assets/mapbox-marker-icon-20px-red.png';
import myImage1 from '../../../../assets/icons/map-pin.svg';
import reshot from '../../../../assets/icons/reshot.png';
import patientIcon from '../../../../assets/icons/patient_location.png';
import starting_location from '../../../../assets/icons/starting_location.png';
// import appRoutesData from './approutes.json';
import { appLog, containedButton, emptyFun, executeChunkFn } from "../../../../common/helpers";
import { fetchAllConsultantTrips } from "../../../../app/Reducers/mapSlice";
import RouteMapSearch from "./RouteMapSearch";
import { useSubscription } from "../../../../hooks/MqttHooks/useSubscription";
import dayjs from "dayjs";
import * as Sentry from "@sentry/react";
import {toastUp} from '../../../../app/Reducers/reducerUtils';
import {useLocation, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CompassOutlined} from '@ant-design/icons';

const image1 = new Image(14.45, 20.48);
image1.src = myImage1;
const image2 = new Image(14.45, 20.48);
// image2.src = reshot;
mapboxgl.accessToken = mapboxToken


const getAllStartingPoints = (consultantTrips) => {
  let list = []
  for (const trip of consultantTrips) {
    let ConsultantTrip = trip.trip_details.find(item => item.progressStatus.toLowerCase() === 'start')
    let lastTripCoordinates = trip.trip_details[trip.trip_details.length - 1].endingPoint.geometry.coordinates
    if (!!ConsultantTrip) {
      list.push({
        ...ConsultantTrip,
        progressStatus: trip.progress_status.toLowerCase(),
        totalDistance: trip.distance_covered,
        updated_at: trip.updated_at,
        reference_number: trip.reference_number,
        distanceCovered: trip.distance_covered,
        remainingDistance: trip.remaining_distance,
        lastTripCoordinates
      })
    }
  }
  return list;
}
const TrackerMap = ({ consultantTrips, tripConsultant }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [viewport, setViewport] = useState({ width: "100%", height: "100%", latitude: 39.952597, longitude: -75.165169, zoom: 14 });

  const [lng, setLng] = useState(-87.65);
  const [lat, setLat] = useState(41.84);
  const [zoom, setZoom] = useState(11.5);
  const [arcs, setArcs] = useState([]);
  const [mapInitStarted, setMapInitStarted] = useState(false);
  const [mapLayers, setMapLayers] = useState([]);
  const [mapSources, setMapSources] = useState([]);
  const consultantId = Number(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  const [waitingToNavigateToConsultant, setWaitingToNavigateToConsultant] = useState(false);
  const [tripUpdate, setTripUpdate] = useState();
  const [consultant, setConsultant] = useState(!!consultantId ? tripConsultant : undefined);
  const [showActiveTrips, setShowActiveTrips] = useState(true);
  const [loadingTrips, setLoadingTrips] = useState(false);
  // const [tripRoutes, setTripRoutes] = useState([...appRoutesData]);
  const [tripRoutes, setTripRoutes] = useState((!!consultantTrips[0]) ? getAllStartingPoints(consultantTrips) : []);
  const [lonLat, setLonLat] = useState((!!tripRoutes[0] && !!tripRoutes[0].startingPoint) ? tripRoutes[0].startingPoint.geometry.coordinates : [-87.65, 41.84]);
  const [features, setFeatures] = useState({ "type": "FeatureCollection", features: [] });
  const [routArcs, setRoutArcs] = useState([]);
  //console.log({ consultantTrips })
  const { message } = useSubscription(`consultantNavigation/#`, { qos: 2, nl: true });
  useEffect(() => {
    // console.log('consultantTrips changed')
    setTripRoutes((!!consultantTrips[0]) ? getAllStartingPoints(consultantTrips) : []);
    return emptyFun
  }, [consultantTrips])
  useEffect(() => {
    //console.log({ tripConsultant, consultantId })
    console.log(!!consultantId)
    if (!!consultant && !!consultantId) {
      //This is navigation from the consultant summary page
      executeChunkFn(dispatch, fetchAllConsultantTrips, { consultant_id: consultant.id }, setLoadingTrips, null).catch(e => console.log(e))
    } else {
      //This is called when accessed from the geo map
      executeChunkFn(dispatch, fetchAllConsultantTrips, undefined, setLoadingTrips, null).catch(e => console.log(e))
    }
    return emptyFun
  }, [])
  useEffect(() => {
    console.log('MQ message0: ', message)
    if (!!message) {
      console.log('MQ message: ', message)
      if (!!message?.message && message.topic !== 'consultantNavigation/Mileage') {
        console.log('MQ message1: ', JSON.parse(message.message))
        let trip = JSON.parse(message.message)
        if (!trip?.data) {
          console.log('setTripUpdate: ', trip)
          setTripUpdate(trip)
        }
      } else {
        console.log('Malformed message found')
      }
    }
    return emptyFun
  }, [message])
  const initMap = () => {
    if (map.current /*|| !features.features[0]*/) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: lonLat,
      zoom: zoom
    });
    // Add navigation control (the +/- zoom buttons)
    map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
  }
  const cleanMap = async () => {
    if (map.current.hasImage('startpos_icon')) {
      let carFeatures = features.features.filter(item => item.properties.position === 'car')
      let routeLineFeatures = features.features.filter(item => item.properties.position.includes('route-'))
      // console.log('carFeatures:  ', carFeatures)
      // console.log('routeLineFeatures:  ', routeLineFeatures)
      let mapLayers = [...routeLineFeatures.map(item => `traffic-routes-layer-${item.properties.tripId}`), ...routeLineFeatures.map(item => `routearrows-${item.properties.tripId}`), 'route-end-layer', 'route-start-layer', ...carFeatures.map(item => `route-car-layer-${item.properties.tripId}`)]
      let mapSources = ['traffic-routes-data', ...carFeatures.map(item => `car-routes-data-${item.properties.tripId}`), ...routeLineFeatures.map(item => `route-line-data-${item.properties.tripId}`)]
      try {
        if (mapLayers[0]) {
          for (const layer of mapLayers) {
            // console.log('Removing layer:  ', layer)
            // console.log('Removing layer:  ', map.current.getLayer(layer))
            if (map.current.getLayer(layer)) {
              map.current.removeLayer(layer)
            }
          }
        }
        if (mapSources[0]) {
          for (const source of mapSources) {
            // console.log('Removing source:  ', source)
            // console.log('Removing source:  ', map.current.getSource(source))
            if (map.current.getSource(source)) {
              map.current.removeSource(source)
            }
          }
        }
      } catch (e) {
        Sentry.captureException(e);
        console.log(e)
      }
    }
  }
  useEffect(() => {
    initMap()
    // Clean up on unmount
    // return () => map.current.remove();
    // return emptyFun;
  }, [/*features*/]);

  //Add all initial features from lost of available trips
  useEffect(() => {
    (async () => {
      let features = { "type": "FeatureCollection", features: [] }
      // console.log('tripRoutes: ', tripRoutes)
      // await cleanMap()
      if (!tripRoutes[0]) {
        await setFeatures(features)
        await cleanMap()
        return emptyFun
      }
      let routArcs = []
      for (const item of tripRoutes) {
        if (!!item.data) {
          // console.log('Data found: ')
          continue
        }
        // console.log('Data found: ', !!item.data)
        // console.log('Route: ', item)
        await getFeatures(features, item, routArcs)
      }
      await setRoutArcs(routArcs)
      // console.log('features: ', features)
      await setFeatures(features)
    })().catch(e => console.log(e))
    return emptyFun
  }, [tripRoutes])
  const getFeatures = async (features, tripInfo, routArcs) => {
    // const features = {"type": "FeatureCollection", features: []}
    //Route Linestring
    let consultantRouteData = await getRoute(tripInfo.startingPoint.geometry.coordinates, tripInfo.endingPoint.geometry.coordinates).catch(e => console.log(e))
    if (!consultantRouteData?.geojson){
      console.log('NO GEOJSON DATA TO MAP 1')
      return
    }
    let consultantRoute = { ...consultantRouteData?.geojson }
    // Calculate the distance in kilometers between route start/end point.
    const lineDistance = turf.length(consultantRoute, { units: 'kilometers' });
    // console.log('lineDistance  :', lineDistance)
    const arc = [];

    // Number of steps to use in the arc and animation, more steps means
    // a smoother arc and animation, but too many steps will result in a
    // low frame rate

    const steps = (lineDistance * 1000) / 10;
    // Draw an arc between the `origin` & `destination`
    for (let i = 0; i < lineDistance; i += lineDistance / steps) {
      const segment = turf.along(consultantRoute, i, { units: 'kilometers' });
      arc.push(segment.geometry.coordinates);
    }
    // Update the route with calculated arc coordinates
    // consultantRoute.geometry.coordinates = arc;
    routArcs.push({ tripId: tripInfo.tripId, arc: arc, counter: 0, steps })
    consultantRoute.properties = {
      "position": `route-${tripInfo.tripId}`, totalDistance: tripInfo.startingPoint?.totalDistance || lineDistance, distanceCovered: tripInfo?.distanceCovered,
      progressStatus: tripInfo.progressStatus.toLowerCase(), tripId: tripInfo.tripId, legs: consultantRouteData.legs
    }
    // console.log('consultantRoute: ', consultantRoute)
    features.features.push(consultantRoute)
    //Route starting point
    let consultantRouteStart = turf.point(tripInfo.startingPoint.geometry.coordinates)
    consultantRouteStart.properties = {
      "position": "start",
      totalDistance: tripInfo.startingPoint?.totalDistance || lineDistance,
      distanceCovered: tripInfo?.distanceCovered,
      startingPointName: tripInfo.startingPoint.locationName,
      endingPointName: tripInfo.endingPoint.locationName,
      distanceUnit: tripInfo.endingPoint.distanceUnit,
      "marker-symbol": "startpos_icon",
      tripId: tripInfo.tripId,
      updated_at: tripInfo.updated_at,
      remainingDistance: tripInfo?.remainingDistance,
      progressStatus: tripInfo.progressStatus.toLowerCase(),
      legs: consultantRouteData.legs,
      consultant: tripInfo.consultant,
      patient: tripInfo.patient,
      reference_number: tripInfo.reference_number
    }
    // console.log('consultantRouteStart: ', consultantRouteStart)
    features.features.push({ ...consultantRouteStart })
    //Route car point
    // if (tripInfo.progressStatus.toLowerCase() === 'on') {
    // Update the route with calculated arc coordinates
    // consultantRoute.geometry.coordinates = arc;
    let consultantCarPoint = turf.point(tripInfo.lastTripCoordinates || tripInfo.startingPoint.geometry.coordinates)
    consultantCarPoint.properties = {
      "position": "car", totalDistance: tripInfo.startingPoint?.totalDistance || lineDistance, distanceCovered: tripInfo?.distanceCovered, distanceUnit: tripInfo.endingPoint.distanceUnit,
      startingPointName: tripInfo.startingPoint.locationName, endingPointName: tripInfo.endingPoint.locationName, "marker-symbol": 'reshot_icon', tripId: tripInfo.tripId,
      progressStatus: tripInfo.progressStatus.toLowerCase(), legs: consultantRouteData.legs, consultant: tripInfo.consultant, patient: tripInfo.patient, updated_at: tripInfo.updated_at,
      remainingDistance: tripInfo?.remainingDistance, reference_number: tripInfo.reference_number
    }
    features.features.push(consultantCarPoint)
    // }
    //Route ending point
    //Use last index of consultantRoute.geometry.coordinates in order to place the destination at the exact traffic route ending position
    let consultantRouteEnd = turf.point(consultantRoute.geometry.coordinates[consultantRoute.geometry.coordinates.length - 1])
    consultantRouteEnd.properties = {
      "position": "end",
      totalDistance: tripInfo.startingPoint?.totalDistance || lineDistance,
      distanceCovered: tripInfo?.distanceCovered,
      remainingDistance: tripInfo?.remainingDistance,
      startingPointName: tripInfo.startingPoint.locationName,
      endingPointName: tripInfo.endingPoint.locationName,
      "marker-symbol": "endpos_icon",
      tripId: tripInfo.tripId,
      reference_number: tripInfo.reference_number,
      progressStatus: tripInfo.progressStatus.toLowerCase(),
      legs: consultantRouteData.legs,
      consultant: tripInfo.consultant,
      patient: tripInfo.patient,
      updated_at: tripInfo.updated_at,
      distanceUnit: tripInfo.distanceUnit
    }
    // console.log('consultantRouteEnd: ', consultantRouteEnd)
    features.features.push(consultantRouteEnd)
    return features
  }
  // Initialize map when component mounts
  useEffect(() => {
    //Clear existing layers
    // console.log('features layer:  ', features)
    if (!map.current || !features.features[0]) return /*emptyFun;*/ // wait for map to initialize
    console.log('Qualifies')
    if (!!map.current?.hasImage('endpos_icon') === false) {//If map has not been loaded with the geojson data before
      console.log('=====> MAP INITIAL LOAD')
      loadMap(features)
    } else {
      console.log('=====> MAP ON LOAD')
      //Stop this operation if list of available trips had been loaded before. i.e. all other updates take place on tripUpdate change and are handled there
      // loadMap()
    }

  }, [features]);
  useEffect(() => {
    (async () => {
      console.log('checking tripUpdate', tripUpdate)
      if (!tripUpdate) {
        // console.log('tripUpdate undefined')
        return emptyFun
      }
      let consultantCarPoint
      // console.log('features.features: ',features.features)
      if (!!features.features[0]) {
        // console.log('features.features1: ',features.features)
        consultantCarPoint = features.features.find(item => item.properties.position === 'car' && item.properties.tripId === tripUpdate.tripId)
      }
      // console.log('consultantCarPoint', consultantCarPoint)
      //Check if it is an existing trip already
      if (!!consultantCarPoint) {
        //This block means this trip had been mapped before and exists in the current map
        // console.log('Existing car point found for', consultantCarPoint.properties.tripId)
        let consultantRouteEnd = features.features.find(item => item.properties.position === 'end' && item.properties.tripId === tripUpdate.tripId)
        let consultantRouteData = await getRoute(tripUpdate.endingPoint.geometry.coordinates, consultantRouteEnd.geometry.coordinates).catch(e => console.log(e))
        if (!consultantRouteData?.geojson){
          console.log('NO GEOJSON DATA TO MAP 2')
          return
        }
        let consultantRoute = { ...consultantRouteData?.geojson }
        consultantRoute.properties = {
          "position": `route-${tripUpdate.tripId}`,
          totalDistance: tripUpdate?.totalDistance || undefined,
          distanceCovered: tripUpdate?.distanceCovered || undefined,
          updated_at: tripUpdate?.updated_at,
          reference_number: consultantCarPoint.reference_number,
          progressStatus: tripUpdate.progressStatus.toLowerCase(),
          tripId: tripUpdate.tripId,
          legs: consultantRouteData.legs,
          consultant: tripUpdate?.consultant,
          patient: tripUpdate?.patient
        }
        let consultantCarPointIndex = features.features.indexOf(consultantCarPoint)
        // consultantCarPoint.previousGeometry = consultantCarPoint.geometry
        consultantCarPoint.geometry = tripUpdate.endingPoint.geometry
        let routeLineFromThisPointForward = turf.lineSlice(turf.point(tripUpdate.endingPoint.geometry.coordinates), turf.point(consultantRouteEnd.geometry.coordinates), consultantRoute);
        let routeLineFromThisPointBackward = turf.lineSlice(turf.point(tripUpdate.endingPoint.geometry.coordinates), turf.point(tripUpdate.startingPoint.geometry.coordinates), consultantRoute);
        let position10MetersBackward = turf.along(routeLineFromThisPointBackward, 10, { units: 'meters' })
        let position10MetersAhead = turf.along(routeLineFromThisPointForward, 10, { units: 'meters' })
        // let bearing = turf.bearing(position10MetersBackward, turf.point(tripUpdate.endingPoint.geometry.coordinates));
        let bearing = turf.bearing(turf.point(tripUpdate.endingPoint.geometry.coordinates), position10MetersAhead);
        // let bearing = turf.rhumbBearing(turf.point(tripUpdate.endingPoint.geometry.coordinates), turf.point(tripUpdate.startingPoint.geometry.coordinates), {final: true})
        // let bearing1 = turf.rhumbBearing(turf.point(tripUpdate.endingPoint.geometry.coordinates), turf.point(consultantCarPoint.geometry.coordinates), {final: true})
        //console.log({ consultantCarPoint, bearing/* bearing1*/ })
        consultantCarPoint.properties.bearing = bearing
        consultantCarPoint.properties.progressStatus = tripUpdate.progressStatus.toLowerCase()
        consultantCarPoint.properties.consultant = tripUpdate.consultant
        consultantCarPoint.properties.remainingDistance = tripUpdate?.remainingDistance || undefined
        consultantCarPoint.properties.patient = tripUpdate?.patient
        consultantCarPoint.properties.totalDistance = tripUpdate?.totalDistance || undefined
        consultantCarPoint.properties.distanceCovered = tripUpdate?.distanceCovered || undefined
        consultantCarPoint.properties.updated_at = tripUpdate?.updated_at
        consultantCarPoint.properties.distanceUnit = tripUpdate?.distanceUnit
        // features.features[consultantCarPointIndex] = consultantCarPoint
        // setFeatures(features)
        if (map.current.getSource(`car-routes-data-${tripUpdate.tripId}`)) {
          console.log('SETTING NEW CAR POSITION')
          requestAnimationFrame(() => {
            map.current.getSource(`car-routes-data-${tripUpdate.tripId}`).setData(consultantCarPoint);
          })
          //Re-routing
          map.current.getSource(`route-line-data-${tripUpdate.tripId}`).setData(consultantRoute);
        } else {
          console.log('No map layer for', consultantCarPoint.properties.tripId)
        }
      } else {
        // Then it must be a start command/New trip
        if (tripUpdate.progressStatus.toLowerCase() === 'start') {
          let features = { "type": "FeatureCollection", features: [] }
          let routArcs = []
          let newTripFeatures = await getFeatures(features, tripUpdate, routArcs)
          //console.log({ newTripFeatures })
          //Load map with only new trip features
          loadMap(newTripFeatures)
          features.features = [...features.features, ...newTripFeatures.features]
          await setRoutArcs(prevState => [...prevState, ...routArcs])
          await setFeatures(features)
          //Move to selected consultant if there was a filter by consultant applied
          if (waitingToNavigateToConsultant) {
            // console.log('Map should fly 1')
            setLonLat(tripUpdate.startingPoint.geometry.coordinates)
            map.current.flyTo({ center: tripUpdate.startingPoint.geometry.coordinates, essential: true, zoom: 14 });
            await setWaitingToNavigateToConsultant(false)
          }
        } else {
          console.log('None start command: ', tripUpdate.tripId, ' ', tripUpdate.progressStatus.toLowerCase())
        }
      }
      //Move to selected consultant if there was a filter by consultant applied
      if (waitingToNavigateToConsultant) {
        setLonLat(tripUpdate.endingPoint.coordinates)
        // console.log('Map should fly')
        map.current.flyTo({ center: tripUpdate.endingPoint.coordinates, essential: true, zoom: 14 });
        setWaitingToNavigateToConsultant(false)
      }
    })().catch(e => console.log(e))

    return emptyFun
  }, [tripUpdate])
  const loadMap = (features) => {
    console.log('loadMap called')
    if (!!map.current?.hasImage('startpos_icon')) {
      console.log('addMapData called')
      // console.log('addMapData called')
      addMapData(features)
    } else
      console.log('Mapping afresh')
    map.current.loadImage(starting_location, function (error, image) {
      if (error) throw error;
      if (!map.current.hasImage('route_navigator_icon')) {
        map.current.addImage('route_navigator_icon', image2);
      }
      if (!map.current.hasImage('startpos_icon')) {
        map.current.addImage('startpos_icon', image);
      }
      map.current.loadImage(patientIcon, function (error, image) {
        if (error) throw error;
        if (!map.current.hasImage('endpos_icon')) {
          map.current.addImage('endpos_icon', image);
        }
        map.current.loadImage(reshot, function (error, image) {
          if (error) throw error;
          if (!map.current.hasImage('reshot_icon')) {
            map.current.addImage('reshot_icon', image);
          }
          addMapData(features)
          // map.current.getSource('traffic-routes-data').setData(features);
          // console.log('Setting Routes Source')
          // centerMarker()
          /*} else {
            map.current.getSource('traffic-routes-data').setData(features);
            // centerMarker()
          }*/

        });
      });
    });
  }
  const addMapData = (features) => {
    /*if (map.current.getSource('traffic-routes-data')) {//Map loaded during map.load
      console.log('traffic-routes-data exists 😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡😡')
      return
    }*/
    console.log('addMapData fn')
    let layers = [], sources = [];
    // if (!map.current.getLayer('traffic-routes-layer')) {
    features.features.filter(item => item.properties.position === 'car').map(item => {
      sources.push(`car-routes-data-${item.properties.tripId}`)
      // console.log('Adding source:  ', `car-routes-data-${item.properties.tripId}`)
      if (!map.current.getSource(`car-routes-data-${item.properties.tripId}`)) {
        map.current.addSource(`car-routes-data-${item.properties.tripId}`, { type: 'geojson', data: item })
      } else {
        map.current.getSource(`car-routes-data-${item.properties.tripId}`).setData({ type: 'geojson', data: item });
      }
    })
    features.features.filter(item => item.properties.position.includes('route-')).map(item => {
      sources.push(`route-line-data-${item.properties.tripId}`)
      // console.log('Adding source:  ', `route-line-data-${item.properties.tripId}`)
      if (!map.current.getSource(`route-line-data-${item.properties.tripId}`)) {
        map.current.addSource(`route-line-data-${item.properties.tripId}`, { type: 'geojson', data: item })
      } else {
        map.current.getSource(`route-line-data-${item.properties.tripId}`).setData({ type: 'geojson', data: item });
      }
    })
    //Add data for all traffic routes data
    sources.push('traffic-routes-data')
    // console.log('Adding source: ', 'traffic-routes-data')
    if (!map.current.getSource('traffic-routes-data')) {
      map.current.addSource('traffic-routes-data', { type: 'geojson', data: features })
    } else {
      map.current.getSource('traffic-routes-data').setData(features);
    }
    //Add route lines layer
    features.features.filter(item => item.properties.position.includes('route-')).map(item => {
      layers.push(`traffic-routes-layer-${item.properties.tripId}`)
      if (!map.current.getLayer(`traffic-routes-layer-${item.properties.tripId}`)) {
        map.current.addLayer({
          id: `traffic-routes-layer-${item.properties.tripId}`,
          type: 'line',
          source: `route-line-data-${item.properties.tripId}`,
          layout: {
            'visibility': 'visible',
            'line-join': 'round',
            'line-cap': 'round'
            // "line-sort-key":1,
          },
          paint: {
            'line-color': '#038A96',
            // 'line-width': 6,
            'line-opacity': 1,
            'line-width': ['interpolate', ['linear'], ['zoom'], 12, 4, 22, 12]
          },
          'filter': ['==', 'position', `route-${item.properties.tripId}`]
        }, 'waterway-label');
      }
    })

    //Add layer for all origin points
    layers.push('route-start-layer')
    if (!map.current.getLayer('route-start-layer')) {
      map.current.addLayer({
        id: 'route-start-layer',
        source: 'traffic-routes-data',
        type: 'symbol',
        layout: {
          'visibility': 'visible',
          // "symbol-z-order": "auto",
          // "symbol-sort-key": 2,
          "icon-ignore-placement": true,
          'icon-size': 1,
          // "text-field":'Some text',
          'icon-image': ["get", "marker-symbol"],
          'icon-padding': 1,
          'icon-allow-overlap': true
        },
        // 'filter': ['in', 'position', 'end', 'start'],
        'filter': ['all', ['==', 'position', 'start'], ['!=', 'progressStatus', 'on']]
      });
    }
    //Add layer for all destination points
    layers.push('route-end-layer')
    if (!map.current.getLayer('route-end-layer')) {
      map.current.addLayer({
        id: 'route-end-layer',
        source: 'traffic-routes-data',
        type: 'symbol',
        layout: {
          'visibility': 'visible',
          // "symbol-z-order": "auto",
          // "symbol-sort-key": 2,
          "icon-ignore-placement": true,
          'icon-size': 1,
          // "text-field":'Some text',
          'icon-image': ["get", "marker-symbol"],
          'icon-padding': 1,
          'icon-allow-overlap': true
        },
        // 'filter': ['in', 'position', 'end', 'start'],
        'filter': ['all', ['==', 'position', 'end']]
      });
    }
    //Add directionality to route line
    features.features.filter(item => item.properties.position.includes('route-')).map(item => {
      layers.push(`routearrows-${item.properties.tripId}`)
      if (!map.current.getLayer(`routearrows-${item.properties.tripId}`)) {
        map.current.addLayer(
          {
            id: `routearrows-${item.properties.tripId}`,
            type: 'symbol',
            source: `route-line-data-${item.properties.tripId}`,
            layout: {
              'visibility': 'visible',
              // "symbol-z-order": "auto",
              // "symbol-sort-key": 3,
              'symbol-placement': 'line',
              'text-field': '▶',
              // 'text-size': ['interpolate', ['linear'], ['zoom'], 12, 24, 22, 60],
              'text-size': 16,
              'symbol-spacing': ['interpolate', ['linear'], ['zoom'], 12, 30, 22, 160],
              'text-keep-upright': false
              // 'text-allow-overlap': true,
              // 'text-ignore-placement': true,
            },
            paint: {
              'text-color': '#3887be',
              'text-halo-color': 'hsl(55, 11%, 96%)',
              'text-halo-width': 1
            },
            'filter': ['==', 'position', `route-${item.properties.tripId}`]
          }
          /*'waterway-label'*/
        );
      }
    })
    //Add car layer
    features.features.filter(item => item.properties.position === 'car').map(item => {
      layers.push(`route-car-layer-${item.properties.tripId}`)
      if (!map.current.getLayer(`route-car-layer-${item.properties.tripId}`)) {
        map.current.addLayer({
          id: `route-car-layer-${item.properties.tripId}`,
          source: `car-routes-data-${item.properties.tripId}`,
          type: 'symbol',
          layout: {
            'visibility': 'visible',
            // "symbol-z-order": "auto",
            // "symbol-sort-key": 4,
            'icon-size': 1,
            // "text-field":'Some text',
            'icon-image': ["get", "marker-symbol"],
            'icon-rotate': ['get', 'bearing'],
            'icon-rotation-alignment': 'map',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-padding': 1
          },
          // 'filter': ['==', 'position', 'car']
          'filter': ['all', ['==', 'position', 'car'], ['==', 'progressStatus', 'on']]
        });
      }
    })

    setMapLayers(layers)
    setMapSources(sources)
    // map.current.getSource('traffic-routes-data').setData(features);
    // console.log('Setting Routes Source')
    // centerMarker()
    /*} else {
      map.current.getSource('traffic-routes-data').setData(features);
      // centerMarker()
    }*/
    const layersOnclick = (e) => {
      //console.log({ e })
      const coordinates = e.features[0].geometry.coordinates.slice();
      // get the sidebar and add the instructions
      // console.log('e.features[0].properties: ', e.features[0].properties)
      // console.log('Consultant type: ', typeof e.features[0].properties.consultant)
      console.log('Legs: ', JSON.parse(e.features[0].properties.legs))
      let properties = e.features[0].properties
      let consultant = JSON.parse(e.features[0].properties.consultant)
      let patient = JSON.parse(e.features[0].properties.patient)
      let legs = JSON.parse(e.features[0].properties.legs)
      console.log('consultant:  ', consultant)
      console.log('patient:  ', patient)
      console.log('properties:  ', properties)
      const steps = legs[0].steps;
      // console.log('Steps:  ', steps)
      let tripInstructions = '';
      let tripInstructionsHtml = '';
      for (const step of steps) {
        tripInstructions += `<li>${step.maneuver.instruction}</li>`;
      }
      tripInstructionsHtml = `<div></div><p>
<h3 style="color:#EF8100;text-align:center; width: 100%;"><strong>Consultant: ${consultant.name || 'Unspecified'}<br/>${Math.floor(legs[0].duration / 60)} min ✇</strong></h3>
<!--<h3 style="text-align:left; width: 100%;"><strong>Trip ID: ${properties.tripId}</strong></h3>-->
<h3 style="text-align:left; width: 100%;"><strong>Reference No: ${properties?.reference_number?.toUpperCase()}</strong></h3>
<h4 style="text-align:left; width: 100%;"><strong>From: </strong> ${properties.startingPointName || 'Unmapped location'}</h4>
<h4 style="text-align:left; width: 100%;"><strong>To: </strong> ${properties.endingPointName || 'Unmapped location'}  </h4>
<h4 style="text-align:left; width: 100%;"><strong>Distance covered: </strong> ${properties?.distanceCovered || 'Unknown'} ${properties.distanceUnit || ''}</h4>
<h4 style="text-align:left; width: 100%;"><strong>Total Distance: </strong> ${(!!properties?.remainingDistance && !!properties?.distanceCovered) ? properties?.remainingDistance + properties?.distanceCovered :/*!!properties.totalDistance ? properties.totalDistance.toFixed(1) :*/ 'Not provided'} ${properties.distanceUnit || ''} </h4>
<h4 style="text-align:left; width: 100%;"><strong>Remaining Distance: </strong> ${['finished', 'cancelled'].includes(properties?.progressStatus) ? 0 : !!properties?.remainingDistance ? properties.remainingDistance : 'Unknown'} ${properties.distanceUnit || ''}</h4>
<h4 style="text-align:left; width: 100%;"><strong>Route summary: </strong> ${legs[0].summary}</h4>
<h4 style="color: ${properties.progressStatus === 'cancelled' ? '#EF8100' : properties.progressStatus === 'finished' ? 'green' : ''}; text-align:left; width: 100%;"><strong>Trip Status: ${properties.progressStatus}</strong></h4>
<h4 style="text-align:left; width: 100%;"><strong>Patient: ${patient.name || 'Unspecified'} </strong></h4>
<h4 style="text-align:center; width: 100%;"><strong>${dayjs(properties.updated_at).format("YYYY-MM-DD h:mm:ss a")} </strong></h4>
<hr style="margin-top:6px; margin-bottom: 16px; color: gainsboro;border-width: .5px">
<a style="color:black; border-color: gainsboro; padding: 6px 6px;text-align: center;text-decoration: none;display: inline-block; width: 100%; border-radius: 4px; font-weight: bold; border-style: solid" href="/#/patients/${patient.id}">View patient</a><br/>
<a style="color:white;background-color:#EF8100;padding: 6px 6px;text-align: center;text-decoration: none;display: inline-block; width: 100%; border-radius: 4px;margin-top: 10px; font-weight: bold;" href="/#/consultants/${consultant.id}">View Consultant</a>
</p></div>`;
      // Instrictions: </p><ol>${tripInstructions}</ol>`;
      // tripInstructionsHtml = `<p><strong>Trip duration: ${Math.floor(data.properties.legs[0].duration / 60)} min ✇ </strong></p><ol>${tripInstructions}</ol><strong>${e.features[0].properties.description}</strong>`;
      // Copy coordinates array.
      // console.log('event:  ', e)
      // const coordinates = e.features[0].geometry.coordinates.slice();
      const description = `<strong>${e.features[0].properties.description}</strong>`;
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // new mapboxgl.Popup().setLngLat(coordinates).setHTML(description).addTo(map.current);
      new mapboxgl.Popup().setLngLat(coordinates).setHTML(tripInstructionsHtml).addTo(map.current);
    }
    map.current.on('click', 'route-end-layer', layersOnclick);
    map.current.on('click', 'route-start-layer', layersOnclick);
    for (const layer of layers) {
      if (layer.startsWith('route-car-layer-')) {
        map.current.on('click', layer, layersOnclick);
        map.current.on('mouseenter', layer, () => map.current.getCanvas().style.cursor = 'pointer');
        map.current.on('mouseleave', layer, () => map.current.getCanvas().style.cursor = '');
      }
    }
    map.current.on('mouseenter', 'route-end-layer', () => map.current.getCanvas().style.cursor = 'pointer');
    map.current.on('mouseenter', 'route-start-layer', () => map.current.getCanvas().style.cursor = 'pointer');
    map.current.on('mouseleave', 'route-end-layer', () => map.current.getCanvas().style.cursor = '');
    map.current.on('mouseleave', 'route-start-layer', () => map.current.getCanvas().style.cursor = '');
    map.current.on('move', () => {
      // setLng(map.current.getCenter().lng.toFixed(4));
      // setLat(map.current.getCenter().lat.toFixed(4));
      setLonLat([map.current.getCenter().lng.toFixed(4), map.current.getCenter().lat.toFixed(4)])
      setZoom(map.current.getZoom().toFixed(2));
    });
    map.current.on('moveend', async () => {
      // get new center coordinates
      const { lng, lat } = map.current.getCenter();
      // fetch new data
      const results = await fetchFakeData(lng, lat);
      // update "random-points-data" source with new data
      // all layers that consume the "random-points-data" data source will be updated automatically
      if (map.current.getSource('traffic-routes-data')) {
        map.current.getSource('traffic-routes-data').setData(features);
      }
    });
  }

  function centerMarker() {
    console.log({tripRoutes})
    if (!!tripRoutes && !!tripRoutes[0]) {
      if (!!tripUpdate) {
        map.current.flyTo({ center: tripUpdate.endingPoint.geometry.coordinates, essential: true, zoom: 14 });
        return
      }
      if (!features.features[0]){
        toastUp('Trips mapping still in progress or no trip yet. Retry after some time')
        return;
      }
      let lastTripFeature = features.features.findLast(item => item.properties.position = 'car')
      if (!lastTripFeature) {
        lastTripFeature = features.features[0]
      }
      
      if (lastTripFeature) {
        console.log({ lastTripFeature1 :lastTripFeature})
        map.current.flyTo({center: lastTripFeature.geometry.coordinates, essential: true, zoom: 14});
      }
      
    }

  }

  function beginAnimation() {
    for (let i = 0; i < routArcs.length; i++) {
      let route = { ...routArcs[i] }
      animateCar(route, 0)
    }
  }

  function animateCar(route, stepIndex) {
    let car_geojson = { ...features.features.find(item => item.properties.tripId === route.tripId && item.properties.position === 'car') }
    let featureIndex = features.features.indexOf(car_geojson)
    // console.log('car_geojson: ', car_geojson)
    // console.log('route: ', route)
    let j = stepIndex
    // console.log('animateCar: ', route, " ", stepIndex, " ", route.arc.length)
    // setInterval(function () {
    if (j < route.arc.length) {
      let routePoint = route.arc[j]
      const start = routePoint;
      const end = (j === route.arc.length - 1) ? routePoint : route.arc[j + 1];
      if (!start || !end) return;
      // Update point geometry to a new position based on counter denoting
      // the index to access the arc
      // let carPointSource = turf.point(route.arc[route.counter]);
      car_geojson.geometry.coordinates = routePoint
      // Calculate the bearing to ensure the icon is rotated to match the route arc
      // The bearing is calculated between the current point and the next point, except
      // at the end of the arc, which uses the previous point and the current point

      car_geojson.properties.bearing = turf.rhumbBearing(turf.point(end), turf.point(start), { final: true })
      // console.log('car_geojson1: ', car_geojson)

      // Update the source with this new data
      //       console.log('Moving :', `car-routes-data-${route.tripId}`)
      //       console.log('route.counter:  ', j)
      //       console.log('route.steps:  ', route.steps)

      // features.features[featureIndex] = car_geojson
      // setFeatures(features)
      map.current.getSource(`car-routes-data-${route.tripId}`).setData(car_geojson);
      // Request the next frame of animation as long as the end has not been reached
      j += 1
      route.counter = j;
      // routArcs[i] = route
      // console.log('Moving1 :', `car-routes-data-${route.tripId}`)
      // console.log('route.counter1  :  ', j)
      // console.log('route.steps1   :  ', route.steps)
      if (j < route.steps) {
        setTimeout(() => requestAnimationFrame(() => {
          animateCar(route, j)
        }), 100);

      }
    }
    // }, 200);

  }

  const fetchFakeData = centerCoordinates => {
    const newFeaturesList = [];
    for (let i = 0; i < 20; i++) {
      const id = i;
      const { longitude, latitude } = getRandomCoordinate(centerCoordinates);
      newFeaturesList.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude]
        },
        properties: {
          id,
          name: `Random Point #${id}`,
          description: `description for Random Point #${id}`
        }
      });
    }

    return Promise.resolve({
      type: 'FeatureCollection',
      features: newFeaturesList
    });
  };
  /**
   * Generates a random point within 0.025 radius of map center coordinates.
   * @param {CoordinatePair} centerCoordinates - the {@link CoordinatePair} for the map center
   * @return {CoordinatePair} randomly generated coordinate pair
   */
  const getRandomCoordinate = ({ longitude: centerLon, latitude: centerLat }) => {
    const r = 0.025 * Math.sqrt(Math.random());
    const theta = Math.random() * 2 * Math.PI;
    const latitude = centerLat + r * Math.cos(theta);
    const longitude = centerLon + r * Math.sin(theta);
    return { longitude, latitude };
  };

  async function getRoute(start, end) {
    try {
      const query = await fetch(`https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${start[0]},${start[1]};${end[0]},${end[1]}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`, { method: 'GET' })
      const json = await query.json();
     /* console.log('getRoute:  ', json)
      console.log('start[0]:  ', start[0])
      console.log('start[1]:  ', start[1])
      console.log('end[0]: ', end[0])
      console.log('end[1]: ', end[1])*/
      const data = json.routes[0];
      // console.log('getRoute:  ', data)
      const route = data.geometry.coordinates;
      const routes_geojson = { geojson: { type: 'Feature', properties: {}, geometry: { type: 'LineString', coordinates: route } }, legs: data.legs }
      return routes_geojson
    } catch (e) {
      console.log(e)
      return null
    }
  }

  const handleConsultant = async (item) => {
    // console.log('handleConsultant: ', item)
    await setConsultant(item)
    await setWaitingToNavigateToConsultant(true)
    await executeChunkFn(dispatch, fetchAllConsultantTrips, { consultant_id: item.id }, setLoadingTrips, null)
  };
  const filterRoutes = async (is_ongoing) => {
    await setShowActiveTrips(is_ongoing)
    // console.log('setShowActiveTrips: ', is_ongoing)
    await executeChunkFn(dispatch, fetchAllConsultantTrips, { ...(!!consultant && { consultant_id: consultant.id }), is_ongoing }, setLoadingTrips, null)
  };
  const searchTrip = async (trip_id) => {
    // console.log('trip_id: ', trip_id)
    await executeChunkFn(dispatch, fetchAllConsultantTrips, { trip_id }, setLoadingTrips, null)
  };
  return (<div className="mapCont">
    <HeaderTop child={<Subtitle1>Consultant Tracking Map</Subtitle1>} />
    <RouteMapSearch handleConsultant={handleConsultant} filterRoutes={filterRoutes} loadingTrips={loadingTrips} showActiveTrips={showActiveTrips} searchTrip={searchTrip} />
    <div className="map-container" ref={mapContainer}>
      <div id="instructions" className="instructions"></div>
    </div>
    {/*<div className="overlay">
      <button id="replay" onClick={beginAnimation}>Simulate movement</button>
    </div>*/}
    {}
    <div style={{ position: 'absolute', bottom: 30, left: 10 }}>
      {containedButton(centerMarker, 'Zoom to last trip', false, null, null, { icon: <CompassOutlined /> })}
    </div>
    {process.env.REACT_APP_CURRENT_ENV !== 'production' && <div style={{ position: 'absolute', top: 80, left: 10 }}>
      {containedButton(beginAnimation, 'Simulate trip', false, null, null, null)}
    </div>}
    {/*<ConsultantMenu/>*/}
  </div>)
}

const mapStateToProps = (state) => ({
  consultants: state.map.consultants, tripConsultant: state.map.tripConsultant,
  patients: state.map.patients, facilities: state.map.facilities, consultantSchedule: state.map.consultantSchedule,
  visibleSchedule: state.map.visibleSchedule, selectedConslultant: state.map.selectedConslultant, consultantTrips: state.map.consultantTrips
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(TrackerMap)
