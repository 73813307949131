import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Form, Select} from 'antd';
import {createInsurancePlan, fetchBillingGroupPerState, fetchInsuranceProviders, updateInsurance} from '../../../../app/Reducers';
import {emptyFun, executeChunkFn, getPhoneParams, makeAutoCompleteOptions, makeSelectOptions} from '../../../../common/helpers';
import dayjs from 'dayjs';
import _ from 'lodash';
import DynamicForm from "../../../../components/DynamicForm";
import {apiRequest} from '../../../../app/Apis';
import {copayRules} from '../../../../common/inputUtils';

const InsuranceForm = ({ closeForm, patient, setisExecuting, insurance, editingForm, state, billingGroups, insuranceProviders }) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const dateFormat = 'MM/DD/YYYY';
  const [effectiveData, setEffectiveDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [provider, setProvider] = useState({ insurance_provider: undefined });
  const [insuranceProviders_, setInsuranceProviders_] = useState([]);
  const [billingGroups_, setBillingGroups_] = useState([]);
  const [phoneValue, setPhoneValue] = useState(!!insurance ? { ...getPhoneParams(insurance?.phone, 'phone') } : undefined);
  const [initialValues, setInitialValues] = useState(editingForm ? { ...insurance, ...(!!insurance?.phone && { ...getPhoneParams(insurance?.phone, 'phone') }) } : { default: true });
  const [defaultBilllingGroup, setdefaultBilllingGroup] = useState(null)
  const [defaultDynamicFormValues, setDefaultDynamicFormValues] = useState({
    ...initialValues, copay_due: parseFloat(initialValues.copay_due), effective_date: initialValues?.effective_date ? dayjs(initialValues?.effective_date) : null,
    end_date: initialValues?.end_date ? dayjs(initialValues?.end_date) : null,
    billing_group: initialValues.billing_group || defaultBilllingGroup,
    policy_holder_name: initialValues.policy_holder_name ? initialValues.policy_holder_name : initialValues?.policy_holder_relationship === 'Self' ? patient.name : null
  });
  useEffect(() => {
    setInitialValues(editingForm ? { ...insurance } : { default: true });
    console.log({ insurance })
    return emptyFun;
  }, [insurance, editingForm]);
  useEffect(() => {
    //console.log({phoneValue})
    return emptyFun;
  }, [phoneValue]);
  
  useEffect(() => {
    (async () => {
      
      await dispatch(fetchBillingGroupPerState(state))
      await dispatch(fetchInsuranceProviders())
      //console.log({initialValues});
    })()
    return emptyFun;
  }, []);
  useEffect(() => {
    if (!!insuranceProviders[0]) {
      // setInsuranceProviders_(insuranceProviders.map(item => ({value: item.name, label: item.name})))
      setInsuranceProviders_(makeAutoCompleteOptions(insuranceProviders.map(item => item.name)))
    }
    return emptyFun
  }, [insuranceProviders])
  // useEffect(() => {
  //   if (!!billingGroups_[0]) {
  //     setBillingGroups_(billingGroups.map(item => ({value: item.name, label: `${item.name}${item?.is_default ? ' - Default' : ''}`})))
  //   }
  //   return emptyFun
  // }, [billingGroups_])
  useEffect(() => {
    fetchBillingGroups()
    return emptyFun
  }, [patient])
  
  
  const fetchBillingGroups = async () => {
    const response = await apiRequest.get(`/billing-entity?state=${patient?.state}`);
    
    if (response && response.data && response.data.data) {
      setdefaultBilllingGroup(response.data.data.find(item => item.is_default)?.name)
      if (!initialValues.billing_group) {
        form.setFieldValue("billing_group", response.data.data.find(item => item.is_default)?.name)
      }
      setBillingGroups_(response.data.data.map(item => item.name))
      
    }
  }
  
  const onFinish = async (values_) => {
    //console.log({values_})
    let values = { ...values_.values, ...provider }
    if (!values.copay_due || values.copay_due === '') {
      delete values.copay_due;
    }
    let payload = { data: _.omitBy({ ...values, patient_id: patient.id }, _.isNil), ...(editingForm && { insurance_id: insurance.id }) };
    await executeChunkFn(dispatch, editingForm ? updateInsurance : createInsurancePlan, payload, setisExecuting, closeForm);
  };
  
  const effectiveDateChange = (value) => setEffectiveDate(value);
  const endDateChange = (value) => setEndDate(value);
  
  const disabledFutureDates = (current) => {
    let customDate = dayjs();
    let tooOld = dayjs('1869-12-31');
    return ((current && current > dayjs(customDate, dateFormat)) || (current && current < dayjs(tooOld, dateFormat)));
  };
  
  const disablePrevDates = (current) => {
    if (effectiveData) {
      let customDate = dayjs(effectiveData);
      return (current && current < dayjs(customDate, dateFormat))
    }
  }
  let providerInputFields = {
    fields: [
      {
        fullRow: true, label: 'Insurance Provider', name: 'insurance_provider', required: true, type: 'autocomplete', options: insuranceProviders_,
        props: { onSelect: (e) => setProvider({ insurance_provider: e }), allowClear: true }
      },
      { fullRow: true, label: "Insurance Plan", name: 'plan', required: false, type: 'text' },
      { fullRow: true, label: "Policy Number", name: 'policy_number', required: true, type: 'text' },
      {
        label: "Policy Holder Relationship", name: 'policy_holder_relationship', required: true,
        type: 'select', props: { allowClear: true,
          onSelect: (e) => {
            if (e === 'Self') {
              form.setFieldValue('policy_holder_name', patient.name || form.getFieldValue('policy_holder_name'));
            } else {
              form.setFieldValue('policy_holder_name', insurance?.policy_holder_name || undefined);
            }
          } }, options: makeSelectOptions(['Self', 'Spouse', 'Sibling'])
      },
      { label: "Policy Holder Name", name: 'policy_holder_name', required: true, type: 'text' },
      {
        label: "Guarantor Relationship", name: 'guarantor_relationship', required: true,
        type: 'select', props: {
          allowClear: true,
          onSelect: (e) => {
            if (e === 'Self') {
              form.setFieldValue('guarantor_name', insurance?.policy_holder_name || form.getFieldValue('policy_holder_name'));
            } else {
              form.setFieldValue('guarantor_name', insurance?.policy_holder_name || undefined);
            }
          }
        }, options: makeSelectOptions(['Self', 'Spouse', 'Sibling'])
      },
      {
        label: "Guarantor Name", name: 'guarantor_name', required: true, type: 'text',
        props: { disabled: form.getFieldValue('guarantor_relationship') === 'Self' }
      },
      
      { label: "Effective Date", name: 'effective_date', required: false, type: 'date', dateFormat, props: { onChange: effectiveDateChange, disabledDate: disabledFutureDates } },
      { label: "End Date", name: 'end_date', required: false, type: 'date', dateFormat, props: { onChange: endDateChange, disabledDate: disablePrevDates } },
      { label: 'Phone number', name: 'phone', required: false, type: 'phone' },
      { label: "Fax", name: 'fax', required: false, type: 'formatted_number_string', props: { format: "+# (###) ### ####" } },
      { label: 'Copay', name: 'copay_due', required: false, type: 'number', fiprops: { rules: copayRules }, props: { step: "0.01", stringMode: true } },
      {
        label: 'Priority', name: 'priority', required: true, type: 'select', props: { allowClear: true }, options: makeSelectOptions(['primary insurance', 'secondary insurance', 'tertiary insurance'])
      },
      { label: "Contact Person's Name", name: 'contact', required: false, type: 'text' }
    
    ]
  }
  return <DynamicForm form={form} formFooter={null} formName={"insuranceForm"} formId={"insuranceForm"} onDynamicFormEntriesResult={onFinish} inputFields={providerInputFields}
                      phoneValue={phoneValue} setPhoneValue={setPhoneValue} defaultValues={defaultDynamicFormValues}/>
};
const mapStateToProps = (state) => ({
  billingGroups: state.masters.billingGroups,
  insuranceProviders: state.masters.insuranceProviders
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(InsuranceForm);