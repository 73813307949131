import { Badge } from 'antd';
import { Avatar, useChatContext } from 'stream-chat-react';
export const DirectMessagingChannelPreview = ({ channel,unread }) => {
    const { client } = useChatContext();
    const members = Object.values(channel.state.members).filter(({ user }) => user?.id !== client.userID);
    const defaultName = 'Johnny Blaze';
    if (!members.length || members.length === 1) {
        const member = members[0];
        return (<div className='channel-preview__item single justify-between text-left mb-2'>
        <Avatar image={member?.user?.image} name={member?.user?.name || member?.user?.id} size={24}/>
          <span>{member?.user?.name || member?.user?.id || defaultName}</span>
          <Badge showZero={false} className=' text-grey' style={{ color: 'crimson' }} count={unread} color={unread === 0 ? "#EF8100" :"white"} />
      </div>);
    }
    return (<div className='channel-preview__item multi  justify-between mb-2 text-left'>
        <span>
          <Avatar image={members[0]?.user?.image} name={members[0]?.user?.name || members[0]?.user?.id} size={18}/>
        </span>
      <Avatar image={members[1].user?.image} name={members[1].user?.name || members[1].user?.id} size={18}/>
      <span>
        {members[0]?.user?.name || members[0]?.user?.id || defaultName},{' '}
        {members[1].user?.name || members[1].user?.id || defaultName}
      </span>
      <Badge showZero={false} className=' text-grey' style={{ color: 'crimson' }} count={unread} color={unread === 0 ? "#EF8100" : "white"} />
    </div>);
};
