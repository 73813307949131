import { useEffect, useState } from "react";
import TinyTable from "./TinyTable";
import { Divider, Spin, Segmented } from "antd";
import { Headline6 } from "../../../components";
import { apiRequest } from "../../../app/Apis";
import { emptyFun } from "../../../common/helpers";
import { LeftOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LocationDetails from "./LocationDetails";

export default function AppointmentLocation({ region }) {
  const [option, setOption] = useState("false");
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [navParams, setNavParams] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const [loadingAppointment, setLoadingAppointment] = useState(false);
  const [total, setTotal] = useState(null);
  const defaultOptions = [
    { label: 'By Region', value: 'region' },
    { label: 'By State', value: 'state' }
  ];
  function onChangeLocation(option) {
    setOption(option)
    console.log("Segment Option", option)
  }

  function sumAppointmentObjects(data) {
    let keysToSum = ['cancelled_by_client', 'cancelled_by_wcc', 'in_progress', 'missed', 'scheduled', 'completed'];
    let total = data?.reduce((acc, obj) => {
      keysToSum.forEach((key) => { acc += obj[key]; });
      return acc;
    }, 0);
    return total;
  }

  async function fetchAppointments(option, region) {
    setLoading(true)
    let queryString = '';
    if (!!region && region !== '' && region !== undefined) {
      queryString = `&region=${region}`;
    }
    const optionString = option === "state" ? `?group_by=state` : "?group_by=region" // this will be used to filter by state or region, by default the endpoint brings by region
    const response = await apiRequest.get(`/appointment_stats/location${optionString}${queryString}`)
    const res = response.data.data
    let newRes = res.filter((obj) => obj.label !== null && obj.label !== " Region")
    setAppointments(newRes)
    setLoading(false)
  }

 // data = data.filter(obj => obj.label !== null);
  async function fetchOneAppointment(option, value) {
    setLoadingAppointment(true)
    let str = value?.toLowerCase()
    let queryString = option === "state" ? `?state=${str}` : `?region=${str}` // this will be used to filter by state or region, by default the endpoint brings by region
    const response = await apiRequest.get(`/appointment_stats/${queryString}&format=year`)
    const res = response.data.data
    let newRes = res.filter((obj) => obj.label !== null && obj.label !== " Region")
    const tot = sumAppointmentObjects(newRes)
    console.log("Total", tot)
    setTotal(tot)
    setAppointment(newRes)
    setLoadingAppointment(false)
  }

  useEffect(() => {
    fetchAppointments("region")
    return emptyFun
  }, [])

  useEffect(() => {
    fetchAppointments(option)
    return emptyFun
  }, [option])

  useEffect(() => {
    fetchAppointments(option, region)
    return emptyFun
  }, [region])

  useEffect(() => {
    if (step === 1) {
      fetchOneAppointment(option, navParams?.label)
    }
    return emptyFun
  }, [step, navParams])

  return (
    <div className="stats-card-box">
      {step === 0 ?
        <div style={{ width: '100%', padding: '5px' }}>
          <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>Appointment by Location</Headline6>
        </div>
        :
        <div className='flex-boy-row-space' style={{ width: '100%' }}>
          <div className="flex-boy-row" style={{ cursor: 'pointer' }}>
            <LeftOutlined style={{ marginRight: 30, marginTop: 10 }} onClick={() => setStep(0)} />
            <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>{navParams?.label || 'Unknown'}</Headline6>
          </div>
          <FontAwesomeIcon color="grey" icon={['fas', 'poll']} />
        </div>
      }
      <Divider style={{ margin: '4px', color: '#E2E4EA', width: '100%' }} />

      {step === 0 ?
        <div className="">
          <div className="flex justify-start p-4">
            <Segmented options={defaultOptions} onChange={(value) => onChangeLocation(value)} />
          </div>
          <div>
            <Spin spinning={loading}>
              <TinyTable patients={appointments} type="appointments" option={option} setStep={setStep} setNavParams={setNavParams} />
            </Spin>
          </div>
        </div> :
        <div className="group">
          <div style={{ width: '100%', padding: '15px' }}>
            <Headline6 style={{ fontWeight: 'bold', fontSize: '16px' }}>Total Appointments</Headline6>
            <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>{total}</Headline6>
          </div>
          <Spin spinning={loadingAppointment}>
            {appointment && <LocationDetails option={option} setStep={setStep} type='appointments' data={appointment} />}
          </Spin>
        </div>
      }

    </div>
  )
}