import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tooltip, Divider, Empty } from 'antd';
import { Headline6, Subtitle1 } from '../../../../components';
import { getPatientsBarColorStatus, titleCase } from '../../../../common/helpers'

function Bar({ color, width }) {
  return (
    <Tooltip
      placement='top'
      title={`${width}%`}
      color={color}
      arrowPointAtCenter
    >
      <div style={{ width: '100%' }}>
        <div
          style={{
            backgroundColor: `${color}`,
            width: `${width}%`,
            height: '16px',
            borderRadius: '2px',
          }}
        />
      </div>
    </Tooltip>
  );
}

const calculatePercentage = (number, total) => {
  return ((number / total) * 100).toFixed(2);
};

function Chart({ title, percentage, color, total }) {
  return (
    <div className='flex-boy-row' style={{ padding: '0px', flexWrap: 'wrap' }}>
      <>
        <div
          className='flex-boy-column'
          style={{
            alignItems: 'start',
            justifyContent: 'center',
            width: '20%',
            paddingLeft: '10px',
          }}
        >
          <Subtitle1
            style={{
              fontFamily: 'Lato',
              fontSize: '13px',
              fontWeight: 'bold',
              textAlign: 'left',
            }}
          >
            {title}
          </Subtitle1>
        </div>
        <div
          className='flex-boy-column'
          style={{
            justifyContent: 'start',
            alignItems: 'start',
            flex: 1,
            marginLeft: '40px',
            marginTop: '20px',
            padding: '0px',
          }}
        >
          <Bar width={calculatePercentage(percentage, total)} color={color} />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Subtitle1
              style={{
                textAlign: 'center',
                marginLeft: '5px',
                fontSize: '13px',
                fontWeight: 'bold',
                fontFamily: 'Lato',
              }}
            >
              {percentage}
            </Subtitle1>
            <Subtitle1
              style={{
                textAlign: 'center',
                marginLeft: '5px',
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#827F86',
                fontFamily: 'Lato',
              }}
            >
              | {calculatePercentage(percentage, total)}%
            </Subtitle1>
          </div>
          <Divider style={{ margin: '4px', color: '#E2E4EA', width: '100%' }} />
        </div>
      </>
    </div>
  );
}

export default function PatientStats({ facilityStats }) {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [sum, setSum] = useState(null);

  const constructData = (data) => {
    const newData = {
      active: data?.active,
      'in-active': data['in-active'],
      healed: data?.healed,
      hospitalized: data?.hospitalized,
      cancelled: data?.cancelled,
      hold: data?.hold,
      discharged: data?.discharged,
      refused_treatment: data?.refused_treatment,
      deceased: data?.deceased,
      total: data?.total,
    };

    let dataFormat = [];

    for (let key in newData) {
      dataFormat.push({
        status: titleCase(key),
        percentage: data[key],
      });
    }

    let tot = dataFormat?.findIndex((item) => item.status === 'Total');
    setTotal(dataFormat[tot]?.percentage);
    dataFormat.splice(tot, 1);
    return dataFormat;
  };

  useEffect(() => {
    if (facilityStats) {
      setData(constructData(facilityStats?.patients_by_status));
      setSum(getSum(constructData(facilityStats?.patients_by_status)));
    }
  }, [facilityStats]);

  function getSum(data) {
    const values = data?.map((item) => item.percentage);
    return values?.reduce((a, b) => a + b);
  }
  return (
    <div style={{ width: '100%' }}>
      {sum ? (
        <div>
          {data?.map((item, index) => (
            <Chart
              key={index}
              title={item.status}
              percentage={item.percentage}
              color={getPatientsBarColorStatus(item.status)}
              total={total}
            />
          ))}
        </div>
      ) : (
        <Empty
          description='No Patient Data'
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
    </div>
  );
}
