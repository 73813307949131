import {useCallback, useState} from "react";
import {GetRawListOptions, makeAutoCompleteOptions} from '../common/helpers';
import _ from 'lodash';

export const useWoundLocationSearch = (deps) => {
  const [isRun, setIsRun] = useState(false);
  const [woundLocationLoading, setWoundLocationSearchLoading] = useState(false);
  const masterLocations = GetRawListOptions('wound_location')
  const masterLocationsSorted = masterLocations?.sort((a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) {
      return -1
    } else if (a.toLowerCase() > b.toLowerCase()) {
      return 1
    } else {
      return 0
    }
  })
  
  const [woundLocationOptions, setWoundLocationOptions] = useState(makeAutoCompleteOptions(masterLocationsSorted))
  const debouncedWLocationSearch = useCallback(_.debounce(async function (value) {
    // console.log({value})
    if (!value) {
      setWoundLocationOptions(makeAutoCompleteOptions(masterLocationsSorted))
    }
    await setWoundLocationSearchLoading(true)
    const locations = masterLocationsSorted.filter(item => item.toLowerCase().includes(value.toLowerCase()))
    if (locations[0]) {
      locations.sort((a, b) => {
        // console.log(a,': ', b)
        // console.log(a.indexOf(value), b.indexOf(value))
        if (a.toLowerCase().indexOf(value.toLowerCase()) < b.toLowerCase().indexOf(value.toLowerCase())) {
          return -1
        }
        if (a.toLowerCase().indexOf(value.toLowerCase()) > b.toLowerCase().indexOf(value.toLowerCase())) {
          return 1
        }
        return 0
      })
    }
    setWoundLocationOptions(makeAutoCompleteOptions(locations))
    await setWoundLocationSearchLoading(false)
  }, 1000), deps)
  return { woundLocationOptions, debouncedWLocationSearch, woundLocationLoading }
};