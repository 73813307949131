import { useEffect, useState } from "react";
import { Segmented, Divider, Empty, Spin, DatePicker } from "antd";
import { Headline6 } from "../../../components";
import { emptyFun } from "../../../common/helpers";
import { BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Bar, Tooltip } from "recharts";
import { apiRequest } from "../../../app/Apis";
import dayjs from "dayjs";

export default function AllAppointments({ region, setAppointmentTotal }) {
  const [option, setOption] = useState('week');
  const [appointments, setAppointments] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const dateFormat = 'YY-MM-DD';
  const [dateValues, setDateValues] = useState(null);
  const [endDate, setEndDate] = useState(dayjs().format(dateFormat));
  const [startDate, setStartDate] = useState(
    dayjs()
      .subtract(5, 'days')
      .format(dateFormat)
  );

  const { RangePicker } = DatePicker;
  const defaultOptions = [
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: 'Quarter', value: 'quarter' },
    { label: 'Year', value: 'year' },
  ];

  function onChangeLocation(option) {
    setOption(option);
  }
  const onChange = async (value) => {
    if (value !== null) {
      console.log(value);
      setDateValues(value);
      let start = dayjs(value[0])?.format(dateFormat);
      let end = dayjs(value[1])?.format(dateFormat);
      setStartDate(start);
      setEndDate(end);
    } else {
      setDateValues(null);
    }
  };

  const disabledFutureDates = (current) => current && current > dayjs(dayjs())

  async function constructData(data) {
    // the array is [{}]
    let chartData = [];
    for (let key of Object.keys(data)) {
      let name = key;
      let dataset = { name };
      let arr = data[key];
      for (let i = 0; i < arr.length; i++) {
        dataset = {
          ...dataset,
          [arr[i].status]: arr[i].count,
        }
      }
      chartData.push(dataset);
    }
    console.log("Chart Data DUmp", chartData)
    return chartData;
  }

  const sumProperties = (arr) => {
    return arr.reduce((acc, obj) => {
      return acc + Object.values(obj).slice(0, -1).reduce((a, b) => a + b);
    }, 0);
  }

  async function fetchAppointments(option, region, startDate, endDate) {
    setLoading(true);
    let queryString = '';
    let dateRange = '';
    if (!!region && region !== '' && region !== undefined) {
      queryString = `&region=${region}`;
    }
    if (!!startDate && startDate !== '' && startDate !== undefined && !!endDate && endDate !== '' && endDate !== undefined) {
      dateRange = `&dateRange[start_at]=${startDate}&dateRange[end_at]=${endDate}`;
    }
    console.log("Appointment Query String:", queryString)
    const response = await apiRequest.get(`/appointment_stats?format=${option}${queryString}${dateRange}`);
    const res = response.data.data;
    console.log("Appointment Stats", res)
    let su = sumProperties(res);
    setTotal(su);
    setAppointmentTotal(su);
    setAppointments(res);
    setLoading(false);
  }


  async function GetDateRange(option) {
    if (option === 'month') {
      let start = dayjs().startOf('month').format(dateFormat);
      let end = dayjs().endOf('month').format(dateFormat);
      setStartDate(start);
      setEndDate(end);
    } else if (option === 'quarter') {
      let start = dayjs().startOf('quarter').format(dateFormat);
      let end = dayjs().endOf('quarter').format(dateFormat);
      setStartDate(start);
      setEndDate(end);
    } else if (option === 'year') {
      let start = dayjs().startOf('year').format(dateFormat);
      let end = dayjs().endOf('year').format(dateFormat);
      setStartDate(start);
      setEndDate(end);
    } else {
      let start = dayjs().startOf('week').format(dateFormat);
      let end = dayjs().endOf('week').format(dateFormat);
      setStartDate(start);
      setEndDate(end);
    }
  }

  useEffect(() => {
    GetDateRange(option);
    fetchAppointments(option, region, startDate, endDate)
    return emptyFun
  }, [option, region])

  useEffect(() => {
    fetchAppointments(option, region, startDate, endDate)
    return emptyFun
  }, [endDate, startDate])

  return (
    <div className="stats-card-box-large" style={{ width: '100%' }}>
      <div style={{ width: '100%', padding: '5px' }}>
        <Headline6 style={{ fontWeight: 'bold', fontSize: '18px' }}>All Appointments</Headline6>
        <Divider style={{ margin: '4px', color: '#E2E4EA', width: '100%' }} />
      </div>
      <div className="flex-boy-row-space" style={{ width: '100%' }}>
        <div className="flex-boy-column">
          <Headline6>Total Appointments</Headline6>
          <Headline6 style={{ fontWeight: 'bold', fontSize: '20px' }}>{total || 0}</Headline6>
        </div>
        <Segmented options={defaultOptions} onChange={(value) => onChangeLocation(value)} />
      </div>
      {appointments ?
        <div style={{ width: "100%" }}>
          <Spin spinning={loading}>
            <ResponsiveContainer width={"100%"} height={550} style={{ paddingTop: '20px' }}>
              <BarChart data={appointments} width={300} height={300}>
                <XAxis dataKey={'label'} />
                <YAxis allowDecimals={false} axisLine={false} domain={[0, 100]} />
                <Tooltip />
                <CartesianGrid strokeDasharray="4 4" />
                <Bar dataKey='scheduled' fill='#2E5AAC' style={{ zIndex: 999 }} />
                <Bar dataKey='cancelled' fill='#FF5F5F' style={{ zIndex: 999 }} />
                <Bar dataKey='completed' fill='#50D2A0' style={{ zIndex: 999 }} />
                <Bar dataKey='missed' fill='#FF9F5F' style={{ zIndex: 999 }} />
                <Bar dataKey='cancelled_by_wcc' fill='#7785FF' label='Cancelled by WCC' style={{ zIndex: 999 }} />
                <Bar dataKey='cancelled_by_patient' fill='#83E8FF' label='Cancelled by Patient' style={{ zIndex: 999 }} />
                <Legend align="left" iconType='circle' style={{ marginLeft: '20px' }} />
              </BarChart>
            </ResponsiveContainer>
            <RangePicker
              style={{
                borderRadius: '7px',
                cursor: 'pointer',
                marginTop: '15px',
                float: 'right'
              }}
              onChange={(dateStrings) => onChange(dateStrings)}
              disabledDate={disabledFutureDates}
              onCalendarChange={(dateStrings) => setDateValues(dateStrings)}
              allowClear={true}
              format={dateFormat}
            />
          </Spin>        </div>
        : <Empty style={{ marginTop: '100px' }} />}
    </div >
  )
}
