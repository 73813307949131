import { combineReducers, configureStore } from '@reduxjs/toolkit';
import menuReducer from './Reducers/appWindow';
import appWindow from './Reducers/appWindow';
import mapReducer from './Reducers/mapSlice'
import authReducer from './Reducers/AuthSlice'
import patientsReducer from './Reducers/PatientSlice';
import ConsultantsReducer from './Reducers/ConsultantSlice';
import AppointmentsReducer from './Reducers/AppointmentsSlice';
import FacilitiesReducer from './Reducers/FacilitiesSlice'
import ConfigSlice from "./Reducers/ConfigSlice";
import MasterSlice from './Reducers/MasterSlice';
import NotificationSlice from './Reducers/NotificationSlice';
import NavSlice from "./Reducers/NavSlice";
import AuditLogSlice from "./Reducers/AuditLogSlice";
import ChatSlice from './Reducers/ChatSlice';
import OrdersReducer from './Reducers/OrdersSlice';
import OrganisationsReducer from './Reducers/OrganisationSlice';
import DepartmentReducer from './Reducers/DepartmentSlice';
import SettingsReducer from './Reducers/SettingSlice';
import BillingSlice from './Reducers/BillingSlice';
import ReportSlice from './Reducers/ReportSlice';

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { appointments, auditLogs, authState, chat, configState, consultants, facilities, mapState, masters, navs, notifications, orders, organisations, patients, tasks, settings, departments, billing } from "./Reducers/initialState";
import StatsSlice from './Reducers/StatsSlice';
import { generateString } from '../common/helpers';
import RemoveCookie from '../hooks/RemoveCookie';
import SetCookie from '../hooks/setCookie';
import GetCookie from '../hooks/GetCookie';

import TaskSlice from './Reducers/TaskSlice';
import {encryptTransform} from 'redux-persist-transform-encrypt';

const activeUser = GetCookie('activeUser')
let existingpass = GetCookie('storepass')
if (!existingpass) {
  let pass = generateString(24)
  RemoveCookie('storepass')
  SetCookie('storepass', pass)
}
const persistConfig = {
  key: 'root', version: 1, storage, blacklist: ['chat', 'videoIsOn'],
  transforms: [
    encryptTransform({
      secretKey: GetCookie('storepass'),
      onError: function (error) {
        console.log({error})
      }
    })
  ]
}
const allReducers = combineReducers({
  appConf: ConfigSlice, navs: NavSlice, auditLogs: AuditLogSlice, menu: menuReducer, map: mapReducer, auth: authReducer,
  patients: patientsReducer, consultants: ConsultantsReducer, appointments: AppointmentsReducer, orders: OrdersReducer, organisations: OrganisationsReducer,
  masters: MasterSlice,
  chat: ChatSlice,
  notifications: NotificationSlice,
  facilities: FacilitiesReducer, appWindow,
  stats: StatsSlice,
  tasks: TaskSlice,
  departments: DepartmentReducer,
  settings: SettingsReducer,
  billing: BillingSlice,
  reports: ReportSlice
})
export const preloadedState = {
  navs: {...navs}, auditLogs: {...auditLogs}, patients: {...patients}, map: {...mapState}, facilities: {...facilities,
    selectedFacility: activeUser ? JSON.parse(activeUser).facilityProfile : null,}, masters: {...masters}, notifications: {...notifications},
  consultants: {...consultants,
    selectedConsultant: activeUser ? JSON.parse(activeUser).consultantProfile : null,}, appConf: {...configState}, auth: {...authState,
    activeUser: activeUser ? JSON.parse(activeUser) : null,
    permissions: activeUser ? JSON.parse(activeUser).roles.map((item) => item.permissions).flat(1) : []}, appointments: {...appointments},
  organisations: {...organisations}, orders: {...orders}, chat: {...chat}, tasks: {...tasks}, departments: {...departments}, settings: {...settings}, billing: {...billing}
}
const persistedReducer = persistReducer(persistConfig, allReducers)
export const store = configureStore({
  reducer: persistedReducer, middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    // serializableCheck: {ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]},'
    immutableCheck: false,
    serializableCheck: false
  }),
  preloadedState,
  devTools: process.env.REACT_APP_CURRENT_ENV === 'staging'
})
