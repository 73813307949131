import React, {useEffect, useState} from 'react';
import {Headline6} from "../../../components";
import {Avatar, Col, Divider, Grid, Image, Row} from "antd";
import dayjs from "dayjs";
import {connect, useDispatch} from "react-redux";

import {fetchPatientById, fetchPatientInsurance, resetPatientModuleState, resetPatientNavs, selectPatient, setPatientDetailsLoading, updatePatientDetailsOwner} from "../../../app/Reducers";
import {dialogCloser, emptyFun, secondaryButton, skelBars} from "../../../common/helpers";
import AuditLogViewer from "../../../components/AuditLogs/AuditLogViewer";
import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import {dismissToast, errMsg, getToastId, updateToast} from '../../../app/Reducers/reducerUtils';
import {apiRequest} from '../../../app/Apis';
import * as Sentry from '@sentry/react';
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import {useNavigate} from 'react-router-dom';

function AppointmentPatientInfoPane({ patient, setDetailsForm, navs, activeUser,  insurances, appointment, ...props }) {
  const navigate=useNavigate()
  const [ploading, setpLoading] = useState(false);
  const dispatch = useDispatch()
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const can_view_patient_ssn = usePermissionCheck('view_patient_ssn', false, [])
  const patientId = appointment?.patient?.patientId
  // console.log({insurances,appointment})
  useEffect(() => {
    (async () => {
      if (!patientId){
        return
      }
      await dispatch(fetchPatientInsurance(patientId));
      
      if (!appointment?.patient || (patientId !== appointment?.patient?.id)) {
        await dispatch(setPatientDetailsLoading(true))
        await dispatch(fetchPatientById(Number(patientId)))
        await dispatch(setPatientDetailsLoading(false))
        await dispatch(updatePatientDetailsOwner(patientId))
        await dispatch(fetchPatientInsurance(patientId));
      }
    })().catch(e => console.log(e))
    return emptyFun
  }, [appointment?.patient])
  
  
  const fetchPatient = async (id) => {
    const toastId = getToastId('Getting patient details')
    setpLoading(true)
    try {
      const { data } = await apiRequest.get(`/patients/${id}`)
      if (data && data.data) {
        let patient = data.data
        if (patient.id !== navs.patientDetailsOwner) {
          await dispatch(resetPatientModuleState())
        }
        await dispatch(selectPatient({ record: patient }))
        await dispatch(resetPatientNavs())
        dismissToast(toastId)
        setpLoading(false)
        navigate(`/patients/${id}`)
      }
    } catch (e) {
      Sentry.captureException(e);
      setpLoading(false)
      console.log(e)
      updateToast(toastId, errMsg(e, true, 'patient info'), false)
    }
  }
  
  return (
    <div className="shadow-card-box h-full">
      {showAuditDialog && <AuditLogViewer visible={showAuditDialog} onCancel={dialogCloser(setShowAuditDialog)}
                                          loading={false} resourceDescription={appointment?.patient?.name} resourceType={'Patient'}
                                          defaultValues={{ auditableId: patientId, auditable: 'patient', format: 'json' }}/>}
      <div className="flex-boy-col items-center" style={{ width: "100%" }}>
        <Avatar size={64} src={<Image src={appointment?.patient?.profile_image_url || require("../../../assets/user.png")}/>}/>
        <div className="mb-2" style={{ textAlign: 'center' }}>
          <Headline6 style={{ textAlign: 'center' }}>{appointment?.patient?.name}</Headline6>
          {/*<Body2
            color="grey">{dayjs(appointment?.patient?.birth_date).format("MM/DD/YYYY")}({dayjs().diff(dayjs(appointment?.patient?.birth_date), 'years')}y.o.) {appointment?.patient?.gender}</Body2>*/}
        </div>
        <div className="flex flex-row justify-center w-full">
          {secondaryButton(() => fetchPatient(appointment?.patient.id), 'View Full Card', ploading, 'Loading')}
        </div>
      
      </div>
      <Divider/>
      {
          <Row gutter={[4, 4]} align={"stretch"} justify={"start"} style={{ width: '100%', margin: 0 }}>
        <Col className="text-left" xs={24} sm={24} md={24} lg={24} xl={24} flex={"auto"} style={{ width: '100%' }}>
          <div className=" my-2 flex flex-row justify-between w-full">
            <p className=" text-xs">PST Time zone</p>
            <p className=" text-xs">{dayjs().format("HH:MM A")}</p>
          </div>
          <p className="my-1 text-blue-500">{appointment?.patient?.phone_no} </p>
          <div className="my-4"></div>
          <p className="my-6  text-blue-500">{appointment?.patient?.email}</p>
          <div className="my-4"></div>
          <p className="mb-4  text-xs">{appointment?.patient?.address_1}, {appointment?.patient?.zip ? appointment?.patient?.zip + ',' : ''} {appointment?.patient?.city ? appointment?.patient?.city + ',' : ''} {appointment?.patient?.state}</p>
          <div className="my-4"></div>
          <p className="mb-4  text-xs">{appointment?.patient?.address_2}</p>
          <div className="my-4"></div>
          <div className=" mt-2 flex flex-row justify-between w-full">
            {can_view_patient_ssn && <p className="mb-3 text-xs">SSN:{appointment?.patient?.ssn || "N/A"}</p>}
            {/* {!activeUser.facilityProfile && <p className="mb-3 text-xs">Smartsheet ID:{appointment?.patient?.smartsheet_id || "N/A"}</p>} */}
            <p className="mb-3 text-xs">Patient ID: {appointment?.patient?.id}</p>
          </div>
          <div className="my-4"></div>
          <Divider/>
            <div className="my-4"></div>
            <p className="mb-3 text-xs">Office:</p>
            <p className=" mb-3 font-semibold text-md">{patient?.medical_facility || "N/A"}</p>
            <div className="my-4 text-xs">Office Primary Contact:</div>
            <p className="mb-3 text-xs">{appointment?.patient?.medical_facility_primary_contact}</p>

            <div className="my-4 text-xs">Office Phone Number:</div>
            <p className="mb-3 text-xs  text-blue-500">{appointment?.patient?.medical_facility_phone_number}</p>
            <div className="my-4"></div>
          {/* {boldKeyCapitalizedValue('State', appointment?.patient?.state)} */}
          <Divider/>
          {
            insurances.filter(item=>item.priority==='primary insurance').map(item=>{
              return(
                <>
                  <div className="my-4"></div>
                  <p className="mb-3 text-md font-semibold w-full mx-auto text-center">Primary Insurance Information:</p>
                  {/*<p className="mb-3 font-semibold">Yes</p>*/}
                  <p className="mb-3 text-xs">Insurance Provider</p>
                  <p className="mb-3 font-semibold">{item?.insurance_provider || 'Unknown'}</p>
                  <p className="mb-3 text-xs">Plan Name</p>
                  <p className="mb-3 font-semibold">{item?.plan || 'Unknown'}</p>
                  <Divider/>
                </>
              )
            })
          }
          {/*<div className="my-4"></div>
          <p className="mb-3 text-xs">Primary Insurance</p>
          <p className="mb-3 font-semibold">{insurances?.[0]?.insurance_provider || 'Unknown'}</p>
          <p className="mb-3 text-xs">Plan Name</p>
          <p className="mb-3 font-semibold">{insurances?.[0]?.plan || 'Unknown'}</p>
          <Divider/>*/}
        </Col>
      </Row>}
    </div>
  );
}

const mapStateToProps = (state) => ({ wounds: state.patients.patientWounds,
  appointment: state.appointments.selectedAppointment,
  // patient: state.appointments.selectedAppointmentPatient,
  appConf: state.appConf, navs: state.navs, activeUser: state.auth?.activeUser,
  insurances: state.patients.insurances,
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentPatientInfoPane);
