import React, {useEffect} from 'react'
import {connect, useDispatch} from 'react-redux'
import HeaderTop from '../../components/HeaderTop'
import {emptyFun, getHeaderLeftNav} from '../../common/helpers'
import { Breadcrumb } from 'antd'
import ConsultantInfoPane from './ConsultantInfoPane'
import ConsultantPage from './ConsultantPage'
import {fetchVehicles} from '../../app/Reducers';
import {useLocation, useNavigate} from 'react-router-dom';

export const NewConssultantCard = ({consultant}) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  useEffect(() => {
    if (!!consultant.id) {
      dispatch(fetchVehicles())
    }
    return emptyFun
  }, [consultant]);
    return (
      
        <div className="main-page flex flex-col">
            {consultant && <div>
                <HeaderTop child={getHeaderLeftNav(navigate, 'Consultant Card')} />
                
            </div>}
            <div className="grid grid-cols-5  flex-1 bg-gray-200">
                <div className="col-span-1 bg-gray-200 sticky">
                   <ConsultantInfoPane/>
                </div>
                <div className="col-span-4 bg-slate-200 h-full">
                    <ConsultantPage/>
                    </div>
                </div>
                </div>
  )
}

const mapStateToProps = (state) => ({
                    consultant: state.consultants.selectedConsultant,
                consultants: state.consultants.consultants, navs: state.navs
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(NewConssultantCard)