import React, {useEffect, useState} from 'react';
import {Headline6} from '../../../components';
import {boldKeyUntransformedValue, containedButton, editButton, emptyFun, responsiveTbl} from '../../../common/helpers';
import {connect, useDispatch} from 'react-redux';
import {deleteOffices, fetchConsultantOffices} from '../../../app/Reducers';

import {useSubscription} from "../../../hooks/MqttHooks/useSubscription";
import {usePermissionCheck} from '../../../hooks/usePermissionCheck';
import dayjs from 'dayjs';
import {Button, Divider, Grid, Table} from 'antd';
import {MergeCellsOutlined, UserOutlined} from '@ant-design/icons';
const { useBreakpoint } = Grid;

const ConsultantOffices = ({ offices, consultant, consultantStateLicences, setselectedsection, setconsultantForm }) => {
  const dispatch = useDispatch();
  const dateFormat = 'YYYY-MM-DD';
  const [selected, setSelected] = React.useState(0);
  const windowState = useBreakpoint();
  const handleCancel = () => setSelected(0);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [showAuditDialog, setShowAuditDialog] = useState(false);
  const [officeForm, setOfficeForm] = useState(false);
  const [office, setOffice] = useState(null);
  const [isExecuting, setisExecuting] = useState(false)
  const can_update_consultant = usePermissionCheck('update_consultant', false, [])
  const can_update_consultant_global = usePermissionCheck('update_consultant_global', false, [])
  const { message: officesMessage } = useSubscription(`view_consultant_offices/${consultant.id}`, { qos: 2, nl: true });
  useEffect(() => {
    
    dispatch(fetchConsultantOffices(consultant.id));
    
    return emptyFun;
  }, []);
  const closeModal = () => {
    setOffice(null)
    setOfficeForm(false)
  }
  const showPopconfirm = (office) => {
    setSelected(office.id)
  };
  
  async function deleteOffice(data) {
    let locations = offices.map(item => item.id)
    const index = locations.indexOf(data)
    if (index > -1) { // only splice array when item is found
      locations.splice(index, 1); // 2nd parameter means remove one item only
    }
    let payload = {
      consultant_id: consultant.id,
      data: {
        practice_locations: [...locations]
      }
    }
    
    dispatch(deleteOffices(payload))
  }
  
  const handleOk = async (item) => {
    
    await setConfirmLoading(true);
    await deleteOffice(item.id)
    await setSelected(null)
    await setConfirmLoading(false);
  };
  
  
  const columns = [
    { title: 'Licence name', dataIndex: 'license_name',render:(license_name,record)=>{
      return license_name?`${record.license_name} ${dayjs(record.expiry_date).isBefore(dayjs()) ?
        ' (⚠️ This licence has expired!)' : ''}`:'-'
      } },
    { title: 'Licence type', dataIndex: 'license_type',  },
    { title: 'Licence number', dataIndex: 'license_number',  },
    { title: 'Expiry date', dataIndex: 'expiry_date', render:(value=>dayjs(value,dateFormat).format('MM/DD/YYYY')),className:'unbreakable-table-column'  }
  ]
  return (
    <div className="card-box">
      <div className="flex-boy-row-space" style={{ width: '100%' }}>
        <Headline6>State licenses</Headline6>
        {can_update_consultant &&
          editButton(() => {
            setselectedsection('offices')
            setconsultantForm(true)
          })
          
        }
      </div>
      {consultantStateLicences?.[0] && consultantStateLicences?.map((item, index) => {
        return <div className={'flexy-column p-4 my-2'} key={index}>
          <div className={'w-full'}>
            <Table title={() => <p className={'font-bold text-md text-start'}>State: {item.state_abbreviation}</p>} bordered={false}
                   {...responsiveTbl(windowState)}
              scroll={{ x: 400 }}
                   columns={columns}
                   dataSource={item.licenses}
                   size={'small'}
                   pagination={false}
                   footer={null}
            />
          </div>
        </div>
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.auth?.activeUser,
  consultant: state.consultants.selectedConsultant,
  appointments: state.consultants.consultantSchedule,
  consultantStateLicences: state.consultants.consultantStateLicences,
  offices: state.consultants.offices
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ConsultantOffices);
